import { useLazyQuery } from "@apollo/client";
import { HStack, Skeleton, VStack, Text } from "native-base";
import { useEffect, useState } from "react";
import { stringOrDate } from "react-big-calendar";
import { CARESTUDIO_APOLLO_CONTEXT } from "../../../../../../constants/Configs";
import TaskQueries from "../../../../../../services/Task/TaskQueries";
import { Colors } from "../../../../../../styles/Colors";
import { getDateStrFromFormat } from "../../../../../../utils/DateUtils";
import { DATE_FORMATS } from "../../../../../../constants";



export const GetTasKNameAndDetailByTaskId = (props: {isHideIcon?:boolean, taskId: string, email?:stringOrDate }) => {

  const [isLoading, setIsLoading] = useState(true)
  const [task, setTasks] = useState<any>();

  const [ getTaskByIdApi ] = useLazyQuery(TaskQueries.GET_TASK_BY_ID, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    getTaskByIdApi({
      variables: {
        id: props?.taskId,
      },
    }).then((respInner) => {
      if (respInner?.data?.getTasks?.tasks?.length) {
        setTasks(respInner?.data?.getTasks?.tasks[0])
      }
      setIsLoading(false)
    });
  }, [props.taskId]);

  return (
          <HStack alignItems={'center'} flex={1} >
              {isLoading ? <Skeleton.Text flex={1} lines={1}></Skeleton.Text> :
              <>
                {task?.title && (
                  <HStack
                    flex={2}
                    alignContent="center"
                    justifyContent={'center'}
                    alignItems={'center'}>
                    <VStack flex={1}>
                      <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400}>
                        {task.title || ''}
                      </Text>
                      {
                        task?.assigneeUser?.name &&
                        <Text size={'smNormal'} color={Colors.FoldPixel.GRAY250}>
                          {`Assignee: ${task.assigneeUser?.name}`}
                        </Text>
                      }
                      {
                        task?.endDateTime &&
                        <Text size={'smNormal'} color={Colors.FoldPixel.GRAY250}>
                          {`Due Date: ${getDateStrFromFormat(
                            task.endDateTime || new Date(),
                            DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT
                          ) || ''}`}
                        </Text>
                      }
                    </VStack>
                  </HStack>
                )}
              </>
            }
          </HStack>
      );
};
