import { useContactCareProgramContext } from '../ContactCareProgram.context';
import { useCareProgramStatus } from './useCareProgramStatus';

export const useCareProgramEditability = () => {
  const { careProgramStatus } = useCareProgramStatus();
  const {state} = useContactCareProgramContext();
  const {contactCareProgramDetails, userMap} = state;

  const isAllowedToTakeActions = [
    careProgramStatus.inProgress, 
    careProgramStatus.newStatus
  ].includes(contactCareProgramDetails?.statusId as string);

  return {
    isAllowedToTakeActions
  };
};