import React from 'react';
import { useContactCareProgramContext } from '../../../ContactCareProgram.context';
import TCMBillingView from './TCMBillingView';
import { CARE_PROGRAM_TYPE_CODES, MLOV_CATEGORY } from '../../../../../../../constants/MlovConst';
import { getMlovCodeFromId, getMlovListByCategory } from '../../../../../../../utils/mlovUtils';
import AWVBillingView from './AWVBillingView';
import CCMBillingTable from './CCMBillingTable';

const CPBillingView: React.FC<{}> = () => {
    const {state: {contactCareProgramDetails}} = useContactCareProgramContext();
    const careProgramTypeId = contactCareProgramDetails?.payerCareProgram?.careProgramTypeId;
    const careProgramTypeList = getMlovListByCategory(MLOV_CATEGORY.CARE_PROGRAM_TYPES);
    const careProgramCode = getMlovCodeFromId(careProgramTypeList, careProgramTypeId || '');

    return (
        <>
            {careProgramCode === CARE_PROGRAM_TYPE_CODES.TCM && <TCMBillingView contactCareProgramId={contactCareProgramDetails?.id || ''}/>}
            {(careProgramCode === CARE_PROGRAM_TYPE_CODES.CCM || careProgramCode === CARE_PROGRAM_TYPE_CODES.ECM) && <CCMBillingTable />}
            {careProgramCode === CARE_PROGRAM_TYPE_CODES.AWV && <AWVBillingView contactCareProgramId={contactCareProgramDetails?.id || ''}/>}
        </>
    )
}

export default CPBillingView;
