import {useLazyQuery} from '@apollo/client';
import {useMutation} from '@apollo/client';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {ADD_OR_UPDATE_CARE_PROGRAM_CONSENT} from '../../../../services/ContactCareProgram/ContactCareProgram';
import {GET_CARE_PROGRAM_CONSENT} from '../../../../services/ContactCareProgram/ContactCareProgram';
import {
  IAddOrUpdateCareProgramConsent,
  ICareProgramConsent,
} from '../interface';
import {CARE_PROGRAM_CONSENT_SUBJECT_TYPE_CODE} from '../../../RightSideContainer/Contacts/Leads/LeadView/LeadTableView/Helper/CareProgramConst';
import { GetUsersByUserUuids } from '../../../../services/User/UserQueries';

export const useCareProgramConsent = () => {
  const [getCareProgramConsent] = useLazyQuery(GET_CARE_PROGRAM_CONSENT, {
    fetchPolicy: 'no-cache',
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });

  const [addOrUpdateCareProgramConsent] = useMutation(
    ADD_OR_UPDATE_CARE_PROGRAM_CONSENT,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );
  const [getUsersByUserUuids] = useLazyQuery(
    GetUsersByUserUuids,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const getCareProgramConsentData = async (params: ICareProgramConsent) => {
    const response = await getCareProgramConsent({
      variables: {
        subjectId: params.subjectId,
        subjectTypeCode: params.subjectTypeCode,
      },
    });
    return response?.data?.careProgramConsents?.[0];
  };

  const addOrUpdateConsent = async (params: IAddOrUpdateCareProgramConsent) => {
    const isCloseContactCareProgram =
      params?.subjectTypeCode ===
        CARE_PROGRAM_CONSENT_SUBJECT_TYPE_CODE.CONTACT && !params?.hasConsent;
    const response = await addOrUpdateCareProgramConsent({
      variables: {
        params: {
          subjectId: params.subjectId,
          subjectTypeCode: params.subjectTypeCode,
          consentTypeId: params.consentTypeId,
          hasConsent: params.hasConsent,
          isCloseContactCareProgram: isCloseContactCareProgram,
          ...(params?.consentNotes && {consentNotes: params.consentNotes}),
        },
      },
    });
    return response;
  };

  const getUsersDataByUserUuid = async (userUuids: string[]) => {
    const response = await getUsersByUserUuids({
      variables: {
        userUuids,
      },
    });
    return response?.data?.accountUsers?.[0]?.user;
  };

  return {
    getCareProgramConsentData,
    addOrUpdateConsent,
    getUsersDataByUserUuid
  };
};
