import {
  CARE_PROGRAM_STEP_STATUS_CODES,
  CARE_PROGRAM_TYPE_CODES,
  CONTACT_CARE_PROGRAM_STATUS_CODES,
} from '../../../../constants/MlovConst';
import {
  AddButtonAction,
  AttributeKey,
  IContactCareProgram,
  IContactCareProgramStep,
  IFormLogResponse,
  IStepViewConfig,
} from '../interface';
import CarePlanStatusComplete from '../../../../assets/svg/CarePlanStatusComplete';
import CarePlanStatusTodo from '../../../../assets/svg/CarePlanStatusTodo';
import CarePlanStatusSkipped from '../../../../assets/svg/CarePlanStatusSkipped';
import {forEachExtensiveFormComponent} from '../../Forms/FormBuilderWidget/AddOrUpdateForm/AddOrUpdateFormHelper';
import {FormComponentType} from '../../Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import {CARE_PROGRAM_STEP_TYPE} from '../../../common/MemebersView/constant';
import {IAdditionalAttribute} from '../../../../services/ContactCareProgram/interface';
import CarePlanStatusFailed from '../../../../assets/svg/CarePlanStatusFailed';
import {getFormattedDateString} from '../../../PersonOmniView/LeftContainer/OtherDetails/OtherDeatilsUtils';
import {getDateStrFromFormat} from '../../../../utils/DateUtils';
import {
  DISPLAY_DATE_FORMAT,
  DISPLAY_SLASH_DATE_FORMAT,
} from '../../../../constants';

export const groupStepsNested = (
  steps: IContactCareProgramStep[]
): IContactCareProgramStep[] => {
  const stepsMap = new Map<string, IContactCareProgramStep>();
  const rootSteps: IContactCareProgramStep[] = [];

  // First pass: Create a map of all steps and identify root steps
  steps.forEach((step) => {
    stepsMap.set(step.id, {...step, subSteps: []});
    if (!step.parentStepId) {
      rootSteps.push(stepsMap.get(step.id)!);
    }
  });

  // Second pass: Group child steps under their parents
  steps.forEach((step) => {
    if (step.parentStepId) {
      const parentStep = stepsMap.get(step.parentStepId);
      if (parentStep) {
        parentStep.subSteps = parentStep.subSteps || [];
        const currentStepType = stepsMap.get(step.id)?.careProgramStepType
          ?.code;

        const isOutreachParentStep =
          !!parentStep?.careProgramStepAdditionalAttributes?.find(
            (attr) =>
              attr.attributeKey === 'stepType' &&
              attr.attributeValue?.valueString ===
                CARE_PROGRAM_STEP_TYPE.OUTREACH
          );

        if (
          isOutreachParentStep &&
          [
            CARE_PROGRAM_STEP_TYPE.OUTREACH,
            CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_2DAY,
          ].includes(currentStepType || '')
        ) {
          // Find existing sub-step with either outreach or tcm_2_day type
          const existingSubStepIndex = parentStep.subSteps.findIndex(
            (subStep) =>
              [
                CARE_PROGRAM_STEP_TYPE.OUTREACH,
                CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_2DAY,
              ].includes(subStep.careProgramStepType?.code)
          );

          if (existingSubStepIndex === -1) {
            parentStep.subSteps.push(stepsMap.get(step.id)!);
          } else {
            const existingSubStep = parentStep.subSteps[existingSubStepIndex];
            const newStep = stepsMap.get(step.id)!;

            // Replace if new step has higher displaySequenceNumber
            if (
              existingSubStep.displaySequenceNumber <
              newStep.displaySequenceNumber
            ) {
              const minDisplaySequenceNumber = Math.min(
                ...parentStep.subSteps.map(
                  (subStep) => subStep.displaySequenceNumber
                )
              );
              newStep.displaySequenceNumber = minDisplaySequenceNumber;
              parentStep.subSteps[existingSubStepIndex] = newStep;
            }
          }
        } else {
          parentStep.subSteps.push(stepsMap.get(step.id)!);
        }
      }
    }
  });

  // Sort function for steps
  const sortSteps = (
    a: IContactCareProgramStep,
    b: IContactCareProgramStep
  ) => {
    if (a.sequenceNumber !== b.sequenceNumber) {
      return a.sequenceNumber - b.sequenceNumber;
    }
    return a.displaySequenceNumber - b.displaySequenceNumber;
  };

  // Sort root steps
  rootSteps.sort(sortSteps);

  // Recursively sort substeps
  const sortSubSteps = (step: IContactCareProgramStep) => {
    if (step.subSteps && step.subSteps.length > 0) {
      step.subSteps.sort(sortSteps);
      step.subSteps.forEach(sortSubSteps);
    }
  };

  rootSteps.forEach(sortSubSteps);

  return rootSteps;
};

export const getCareProgramNameByCode = (code: string, value: string) => {
  switch (code) {
    case CARE_PROGRAM_TYPE_CODES.CCM:
      return 'Chronic Care Management';
    case CARE_PROGRAM_TYPE_CODES.TCM:
      return 'Transitional Care Management';
    case CARE_PROGRAM_TYPE_CODES.ECM:
      return 'Enhanced Care Management';
    case CARE_PROGRAM_TYPE_CODES.AWV:
      return 'Annual Wellness Visit';
    default:
      return value;
  }
};

export const getSubstepIcon = (status: string) => {
  switch (status) {
    case CARE_PROGRAM_STEP_STATUS_CODES.COMPLETED:
    case CARE_PROGRAM_STEP_STATUS_CODES.DONE:
      return CarePlanStatusComplete;
    case CARE_PROGRAM_STEP_STATUS_CODES.TO_DO:
      return CarePlanStatusTodo;
    case CARE_PROGRAM_STEP_STATUS_CODES.SKIP:
      return CarePlanStatusSkipped;
    case CARE_PROGRAM_STEP_STATUS_CODES.FAIL:
      return CarePlanStatusFailed;
    default:
      return CarePlanStatusTodo;
  }
};

export const getFormDetails = (
  careProgramDetails: IContactCareProgram,
  subStepId: string
) => {
  const selectedStep = careProgramDetails?.contactCareProgramSteps?.find(
    (step) => step.id === subStepId
  );
  const formId = selectedStep?.careProgramStepAdditionalAttributes?.find(
    (attr) => attr.attributeKey === 'formId'
  )?.attributeValue?.valueString;
  const formLogId = careProgramDetails?.assessmentLogs?.find(
    (log) =>
      log.careProgramStepId === subStepId && log.resourceTypeCode === 'FORM_LOG'
  )?.resourceId;
  return {formId, formLogId};
};

const getStepLogByStepId = (
  stepId: string,
  stepLog: IContactCareProgram['stepsLog']
) => {
  return stepLog.find((log) => log.careProgramStepId === stepId);
};

export const getInitSelectedStepAndCollapseIds = (
  groupedSteps: IContactCareProgramStep[],
  stepLog: IContactCareProgram['stepsLog'],
  careProgramStatuses: string[]
) => {
  const allowedStatuses = careProgramStatuses;
  let initSelectedStepId = '';
  const expandStepIds: string[] = [];

  for (const step of groupedSteps) {
    if (step?.subSteps?.length) {
      const stepWithInProgressStatus = step.subSteps.find((subStep) => {
        const currStepLog = getStepLogByStepId(subStep.id, stepLog || []);
        if (currStepLog?.careProgramStepStatusId) {
          return allowedStatuses.includes(currStepLog?.careProgramStepStatusId);
        }
        return false;
      });

      if (stepWithInProgressStatus) {
        initSelectedStepId = stepWithInProgressStatus.id;
        expandStepIds.push(step.id);
        break; // Stop looping after finding the first in-progress step
      }
    }
  }

  return {
    initSelectedStepId,
    expandStepIds,
  };
};

export const getFormResponseProgressByAssessmentLogs = async (
  assessmentLogs: IContactCareProgram['assessmentLogs'],
  formResponsePromise: (formLogIds: string[]) => Promise<any>
) => {
  // Filter and map in one operation
  const formLogs = assessmentLogs
    .filter((log) => log.resourceTypeCode === 'FORM_LOG' && log.resourceId)
    .map((log) => ({
      formLogId: log.resourceId,
      stepId: log.careProgramStepId,
    }));

  if (!formLogs.length) {
    return new Map<string, number>();
  }

  const formLogResponses = await formResponsePromise(
    formLogs.map((log) => log.formLogId)
  );

  // Create map of progress percentages
  return new Map(
    formLogs.map((log) => {
      const formLog = formLogResponses?.formLogs?.find(
        (res: {id: string}) => res.id === log.formLogId
      );
      const percent =
        formLog?.formResponse?.foldPrefilledDataProgressPercentage;
      return [log.stepId, percent];
    })
  );
};

const fetchCareProgramInstaceFormLog = async (params: {
  formLogId: string | undefined;
  formId: string | undefined;
  formLogPromise: (formLogId: string) => Promise<any>;
  formByIdPromise: (formId: string) => Promise<any>;
}) => {
  const {formLogId, formId, formLogPromise, formByIdPromise} = params;
  try {
    let inputFormComponents: any[] = [];
    if (formLogId) {
      const response = await formLogPromise(formLogId);
      const formLog = response?.data?.formLogs[0];
      // use form components from formLog
      inputFormComponents =
        formLog?.formResponse?.formResponse?.components || [];
      // if formLog is not prefilled, fetch the form from formId
      if (!formLog?.formResponse && formId) {
        const formResponse = await formByIdPromise(formId);
        const formResponseData = formResponse?.data?.form;
        inputFormComponents = formResponseData?.components || [];
      }

      const {
        componentWiseProgress,
        formPrefilledByFoldPercent,
        submittedData,
        formComponents,
        metadataScore,
      } = processFormLog(formLog, inputFormComponents);

      return {
        componentWiseProgress,
        formPrefilledByFoldPercent,
        submittedData,
        formComponents,
        formLog,
        metadataScore,
      };
    }
    return {};
  } catch (error) {
    return {};
  }
};

const fetchPreviousFormLogInstance = async (params: {
  formId: string | undefined;
  formLogId: string | undefined;
  prevSubmittedFormLogPromise: (
    formId: string,
    formLogId: string
  ) => Promise<any>;
}) => {
  const {formId, formLogId, prevSubmittedFormLogPromise} = params;
  if (!formId || !formLogId) {
    return {};
  }
  const response = await prevSubmittedFormLogPromise(formId, formLogId);
  const formLog = response?.data?.formLogs[0];
  const {
    componentWiseProgress,
    formPrefilledByFoldPercent,
    submittedData,
    formComponents,
    metadataScore,
  } = processFormLog(formLog);

  return {
    componentWiseProgress,
    formPrefilledByFoldPercent,
    submittedData,
    formComponents,
    formLog,
    metadataScore,
  };
};

export const fetchFormResponseFromFormOrFormLog = async (params: {
  formId: string | undefined;
  formLogId: string | undefined;
  fetchPreviousFormLog: boolean;
  formLogPromise: (formLogId: string) => Promise<any>;
  formByIdPromise: (formId: string) => Promise<any>;
  prevSubmittedFormLogPromise: (
    formId: string,
    formLogId: string
  ) => Promise<any>;
}): Promise<{
  newInstanceForm: IFormLogResponse;
  previouslyFilledFormInstance: IFormLogResponse | undefined;
}> => {
  const {
    formId,
    formLogId,
    fetchPreviousFormLog,
    formLogPromise,
    formByIdPromise,
    prevSubmittedFormLogPromise,
  } = params;
  const promises: Promise<any>[] = [];
  promises.push(
    fetchCareProgramInstaceFormLog({
      formLogId,
      formId,
      formLogPromise,
      formByIdPromise,
    })
  );

  if (fetchPreviousFormLog && formId && formLogId) {
    promises.push(
      fetchPreviousFormLogInstance({
        formId,
        formLogId,
        prevSubmittedFormLogPromise,
      })
    );
  }

  const responses = await Promise.all(promises);

  const previouslyFilledFormInstance: IFormLogResponse | undefined =
    Boolean(responses[1]?.formLog?.formResponse) &&
    Object.keys(responses[1]?.formLog?.formResponse?.formResponse || {})
      .length > 0
      ? responses[1]
      : undefined;

  if (previouslyFilledFormInstance) {
    previouslyFilledFormInstance['previousFormLogIsExisting'] = Boolean(
      responses[1]?.formLog?.formResponse
    );
  }

  const newInstanceForm = responses[0];

  return {
    newInstanceForm,
    previouslyFilledFormInstance,
  };
};

export const processFormLog = (
  formLog: any,
  newformComponents?: any[]
): IFormLogResponse => {
  let componentWiseProgress: Record<string, number> = {};
  let formPrefilledByFoldPercent = 0;
  const submittedData: any = {};
  let formComponents: any[] = newformComponents || [];
  if (formLog?.formResponse?.foldPrefilledDataProgressByComponent) {
    componentWiseProgress =
      formLog?.formResponse?.foldPrefilledDataProgressByComponent;
    formPrefilledByFoldPercent = parseFloat(
      formLog?.formResponse?.foldPrefilledDataProgressPercentage || '0'
    );
  }
  formComponents =
    formLog?.formResponse?.formResponse?.components || newformComponents || [];

  forEachExtensiveFormComponent(formComponents, (component) => {
    if (component.type === FormComponentType.BUTTON) {
      component.disableOnInvalid = false;
    } else if (
      component.selectedValue ||
      component.selectedValue == false ||
      component.selectedValue == 0
    ) {
      submittedData[component.key] = component.selectedValue;
    }
  });

  return {
    componentWiseProgress,
    formPrefilledByFoldPercent,
    submittedData,
    formComponents,
    formLog,
    metadataScore: formLog?.formResponse?.metadataScore,
  };
};

export const canShowAddButtonWithAction = (
  step: IContactCareProgramStep
): AddButtonAction | undefined => {
  const actionDetails = step.careProgramStepAdditionalAttributes?.find(
    (attr) => attr.attributeKey === 'supportedActions'
  );
  const supportedActions = actionDetails?.attributeValue?.valueJson || [];
  if (
    supportedActions.includes(CARE_PROGRAM_STEP_TYPE.ASSESSMENT) ||
    supportedActions.includes(CARE_PROGRAM_STEP_TYPE.USER_ASSESSMENT)
  ) {
    return AddButtonAction.SEND_FORM;
  }
  if (supportedActions.includes(CARE_PROGRAM_STEP_TYPE.BILLING)) {
    return AddButtonAction.BILLING;
  }
  if (
    supportedActions.includes(CARE_PROGRAM_STEP_TYPE.ECM_OUTREACH) ||
    supportedActions.includes(CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_14DAY) ||
    supportedActions.includes(CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_2DAY) ||
    supportedActions.includes(CARE_PROGRAM_STEP_TYPE.OUTREACH)
  ) {
    return AddButtonAction.ADD_OUTREACH;
  }
  return undefined;
};

//  KEPT FOR FUTURE USE
// export const processInsertStepResponse = (
//   response: AddCareProgramStepResponse,
//   contactCareProgramDetails: IContactCareProgram
// ): Pick<
//   IContactCareProgram,
//   'assessmentLogs' | 'stepsLog' | 'contactCareProgramSteps'
// > => {
//   let existingAssessmentLogs = contactCareProgramDetails?.assessmentLogs || [];

//   const existingAssessmentLogsMap = new Map(
//     existingAssessmentLogs.map((log) => [log.id, log.id])
//   );

//   let existingStepLogs = contactCareProgramDetails?.stepsLog || [];

//   const existingStepLogsMap = new Map(
//     existingStepLogs.map((log) => [log.id, log.id])
//   );

//   let existingSteps = contactCareProgramDetails?.contactCareProgramSteps || [];

//   const existingStepsMap = new Map(
//     existingSteps.map((step) => [step.id, step.id])
//   );

//   response.careProgramSteps.forEach((newStepToInsert) => {
//     const assessmentLog = newStepToInsert?.assesmentLogs;
//     const stepLog = newStepToInsert?.stepLogs;
//     const additionalAttributes = newStepToInsert?.additionalAttributes;

//     const newStep: IContactCareProgramStep = {
//       id: newStepToInsert.id,
//       title: newStepToInsert.title,
//       careProgramStepType: newStepToInsert.careProgramStepType,
//       careProgramSubSteps: newStepToInsert.careProgramSubSteps,
//       careProgramStepAdditionalAttributes: additionalAttributes,
//       sequenceNumber: newStepToInsert.sequenceNumber,
//       displaySequenceNumber: newStepToInsert.displaySequenceNumber,
//       parentStepId: newStepToInsert.parentStepId,
//     };

//     // Push new step to steps array overwrite if already exists
//     if (!existingStepsMap.has(newStep.id)) {
//       existingSteps.push(newStep);
//     } else {
//       existingSteps = existingSteps.map((step) =>
//         step.id === newStep.id ? newStep : step
//       );
//     }

//     // Push new assessment log to assessment logs array overwrite if already exists
//     if (!existingAssessmentLogsMap.has(assessmentLog?.id)) {
//       existingAssessmentLogs.push(assessmentLog);
//     } else {
//       existingAssessmentLogs = existingAssessmentLogs.map((log) =>
//         log.id === assessmentLog?.id ? assessmentLog : log
//       );
//     }

//     // Push new step log to step logs array overwrite if already exists
//     if (!existingStepLogsMap.has(stepLog?.id)) {
//       existingStepLogs.push(stepLog);
//     } else {
//       existingStepLogs = existingStepLogs.map((log) =>
//         log.id === stepLog?.id ? stepLog : log
//       );
//     }
//   });

//   return {
//     assessmentLogs: existingAssessmentLogs,
//     stepsLog: existingStepLogs,
//     contactCareProgramSteps: existingSteps,
//   };
// };

export const getStepAddSuccessMessage = (actionCode: AddButtonAction) => {
  switch (actionCode) {
    case AddButtonAction.SEND_FORM:
      return 'Assessment added successfully';
    default:
      return 'Step added successfully';
  }
};

export const checkIfPrevFormAcceptedOrDeclined = (
  additionalAttributes: IAdditionalAttribute[]
) => {
  const prevFormAcceptedOrDeclined = additionalAttributes?.find(
    (attr) => attr?.attributeKey === AttributeKey.FORM_LOG_STATUS
  );
  const value = prevFormAcceptedOrDeclined?.attributeValue?.valueJson;

  return Boolean(value?.action);
};

export const getAdditionalAttributeFromStep = (
  additionalAttributes: IAdditionalAttribute[],
  attributeKey: AttributeKey
) => {
  return additionalAttributes?.find(
    (attr) => attr?.attributeKey === attributeKey
  );
};

export const getNewAdditionalAttributes = (
  prevAdditionalAttributes: IAdditionalAttribute[],
  newAdditionalAttributes: IAdditionalAttribute
) => {
  const existingAdditionalAttributesSet = new Set(
    prevAdditionalAttributes.map((attr) => attr.id)
  );

  const isUpdateExistingAttribute = existingAdditionalAttributesSet.has(
    newAdditionalAttributes.id
  );

  if (isUpdateExistingAttribute) {
    return prevAdditionalAttributes.map((attr) =>
      attr.id === newAdditionalAttributes.id ? newAdditionalAttributes : attr
    );
  }

  return [...prevAdditionalAttributes, newAdditionalAttributes];
};

export const DUMMY_OUTREACH_STEP_ID = 'DUMMY_OUTREACH_STEP_ID' as const;

export const getStepViewConfig = (params: {
  step: IContactCareProgramStep;
  stepStatusCode: string;
  programStatusCode: string;
}): IStepViewConfig => {
  const {step, stepStatusCode, programStatusCode} = params;
  const isStepInProgress =
    CARE_PROGRAM_STEP_STATUS_CODES.IN_PROGRESS === stepStatusCode ||
    CARE_PROGRAM_STEP_STATUS_CODES.TO_DO === stepStatusCode;
  const isCareProgramActive = [
    CONTACT_CARE_PROGRAM_STATUS_CODES.IN_PROGRESS,
    CONTACT_CARE_PROGRAM_STATUS_CODES.NEW,
  ].includes(programStatusCode);
  const stepTypeCode = step?.careProgramStepType?.code;
  const config: Omit<IStepViewConfig, 'displayTitle'> = {
    showProgress: false,
    showSequenceNumber: false,
  };

  switch (stepTypeCode) {
    case CARE_PROGRAM_STEP_TYPE.ASSESSMENT:
    case CARE_PROGRAM_STEP_TYPE.USER_ASSESSMENT:
      config.showProgress = true;
      break;

    case CARE_PROGRAM_STEP_TYPE.OUTREACH:
    case CARE_PROGRAM_STEP_TYPE.ECM_OUTREACH:
    case CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_14DAY:
    case CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_2DAY:
      config.showSequenceNumber = false;
      break;
  }

  if (isStepInProgress === false || isCareProgramActive === false) {
    config.showProgress = false;
  }

  const displayTitle = step
    ? getStepDisplayTitle({step, stepConfig: config})
    : '';
  return {
    ...config,
    displayTitle,
  };
};

const getStepDisplayTitle = (params: {
  step: IContactCareProgramStep;
  stepConfig: Omit<IStepViewConfig, 'displayTitle'>;
}) => {
  const {step, stepConfig} = params;
  const title = step.title;
  const sequenceNumber = step.displaySequenceNumber;
  const stepTypeCode = step?.careProgramStepType?.code;

  let displayTitle = title;

  switch (stepTypeCode) {
    case CARE_PROGRAM_STEP_TYPE.ASSESSMENT:
    case CARE_PROGRAM_STEP_TYPE.USER_ASSESSMENT:
      break;
    case CARE_PROGRAM_STEP_TYPE.OUTREACH:
    case CARE_PROGRAM_STEP_TYPE.ECM_OUTREACH:
    case CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_14DAY:
    case CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_2DAY:
      displayTitle = getOutreachStepDisplayTitle({step});
      break;
  }

  if (
    stepConfig.showSequenceNumber &&
    sequenceNumber &&
    Number(sequenceNumber) > 0
  ) {
    return `${displayTitle} ${sequenceNumber - 1}`;
  }

  return displayTitle;
};

const getOutreachStepDisplayTitle = (params: {
  step: IContactCareProgramStep;
}) => {
  const {step} = params;
  const outreachActivities = step.contactOutreachActivities?.[0] || undefined;
  if (!outreachActivities || !outreachActivities.outreachDateTime) {
    return step.title;
  }
  const formattedDate = getDateStrFromFormat(
    outreachActivities.outreachDateTime,
    DISPLAY_SLASH_DATE_FORMAT
  );
  return formattedDate;
};
