import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {Drawer, InputNumber, notification, Skeleton, Checkbox, Tooltip, Modal, Button as AntdButton} from 'antd';
import parse from 'html-react-parser';
import {Liquid} from 'liquidjs';
import {
  Box,
  Button,
  Center,
  CheckIcon,
  Divider,
  FormControl,
  HStack,
  Icon,
  Pressable,
  Select,
  Spacer,
  Spinner,
  Stack,
  Text,
  useMediaQuery,
  useToast,
  View,
  VStack,
} from 'native-base';
import {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import ReactQuill from 'react-quill';
import {useNavigate} from 'react-router-dom';
import {
  BUTTON_TYPE,
  CUSTOM_AUTOMATION_URL,
  DASHBOARD_PERMISSION_ACTION,
  DATE_FORMATS,
  DISPLAY_DATE_FORMAT,
  ENTITY_EVENTS_TITLE_SUBTITLE,
  ENTITY_EVENTS_TYPE,
  getInActiveContactError,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
} from '../../../../../../constants';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../../constants/Configs';
import {
  APPOINTMENT_PARTICIPANT_STATUS_CODES,
  APPOINTMENT_PARTICIPANT_TYPE_CODES,
  APPOINTMENT_STATUS_CODES,
  APPOINTMENT_TYPE_VISIT_TYPE,
  LOCATION_TYPE_CODES,
  MLOV_CATEGORY,
  MLOV_CODES,
  NOTE_SUBJECT_TYPE_CODES,
  USER_ROLE_CODES,
} from '../../../../../../constants/MlovConst';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import {ILocation, IMlov, IUser} from '../../../../../../Interfaces';
import {
  AppointmentQueries,
  FormsQueries,
  ScheduleEventQueries,
  ScheduleQueries,
  UserQueries,
} from '../../../../../../services';
import ContactsQueries from '../../../../../../services/Contacts/ContactsQueries';
import {IContactPracticeLocations, ITimezone} from '../../../../../../services/Location/interfaces';
import {GetScheduleAccessByUserId} from '../../../../../../services/UserScheduleAccess/UserScheduleAccessQueries';
import {Colors} from '../../../../../../styles';
import {testID, TestIdentifiers} from '../../../../../../testUtils';
import {
  canChangeAppointmentDuration,
  getAccountUUID,
  getBooleanFeatureFlag,
  getUserUUID,
  isActiveContact,
  isLicensedStatePatientMatchingEnabled,
  isVirtualLocationDisabled,
  isVirtualLocationEnabledInAvailability,
  navigateToNewSettingTab,
  skipSecondaryUsersForCareTeamTypeAppointmentType,
} from '../../../../../../utils/commonUtils';
import {
  DATE_UNIT_CONST,
  addDaysInDate,
  currentMonth,
  currentYear,
  detectMonthYearChange,
  getCurrentTimeZone,
  getCustomDateRangeForAppointments,
  getDateObject,
  getDateObjectByTimeZone,
  getDateStrFromFormat,
  getDateStrFromFormatWithTimezone,
  getDateStrFromMomentObj,
  getDefaultEndDate,
  getDefaultRSVPExpiryDateTime,
  getDefaultStartDate,
  getDiffInMinutes,
  getMomentObj,
  getMomentObjFromDateStrAndFormat,
  isBetweenDate,
  isCurrentDateInFutureComparedToOther,
  isDateInNextMonth,
  isPastDay,
  isSameDate,
  isSameDay,
  isPastDayOfCalendar
} from '../../../../../../utils/DateUtils';
import {
  getContactTypeId,
  getMlovCodeFromId,
  getMlovId,
  getMlovIdFromCode,
  getMlovListFromCategory,
  getMlovObjectFromCode,
  getMlovObjectFromId,
  getMlovValueFromId,
} from '../../../../../../utils/mlovUtils';
import {IAppointmentType} from '../../../../../RightSideContainer/AccountSettings/AppointmentTypes/Interfaces';
import {AddOrUpdateLead} from '../../../../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateLead';
import {ContentTypes} from '../../../../../RightSideContainer/ContentManagement/ContentManagementConsts';
import {
  getAccountMergeTagData,
  getTemplateCategories,
  getTemplateCategoryList,
  getTemplates,
} from '../../../../../RightSideContainer/ContentManagement/ContentManagementUtils';
import {getFormattedEmailTemplateData} from '../../../../../RightSideContainer/ContentManagement/EmailTemplates/EmailTemplatesUtils';
import {DisplayText} from '../../../../DisplayText/DisplayText';
import {ModalActionDatePicker} from '../../../../ModalActionCommonComponent/ModalActionDatePicker';
import {ModalActionSelect} from '../../../../ModalActionCommonComponent/ModalActionSelect';
import {ModalActionTitle} from '../../../../ModalActionTitle/ModalActionTitle';
import PatientSearchAndSelect from '../../../../PatientSearch/PatientSearchAndSelect';
import ReasonForVisitSearch from '../../../../ReasonForVisitSearch/ReasonForVisitSearch';
import {TimezoneSelect} from '../../../../TimezoneSelect/TimezoneSelect';
import {
  CalendarSlot,
  IAppointmentDetail,
  ICalendarWidgetMetaData,
} from '../../../CalendarWidgetInterfaces';
import {processCalendarWidgetUsersData} from '../../../CalendarWidgetUtils';
import {ParticipantType} from '../../../ParticipantAutoComplete/ParticipantEnum';
import {IParticipantSearch} from '../../../ParticipantAutoComplete/ParticipantInterfaces';
import {
  getAppointmentDateTimeWithTimeZone,
  getAppointmentTimeWithTimeZone,
} from '../../BookAppointment/BookAppointmentHelper';
import {AppointmentAction, FormStatus, SelectSlotsBy} from './AppointmentBookingEnums';
import {
  CONSENT_FORMS,
  getAllUserRolesFromUserList,
  getApplicableContactLocations,
  getAppointmentActionSuccessToastMessage,
  getAppointmentBookingDataForSave,
  getAppointmentNote,
  getAppointmentPatientInstruction,
  getAppointmentTypeForSelectedUser,
  getAppointmentUserById,
  getContactId,
  getLocationCode,
  getLocationsByAllowedLocationIdAndLocationGroupId,
  getOneOffVisitAppointmentType,
  getParticipantsFromAppointmentGroup,
  getPrimaryAndSecondaryUsersForAppointmentType,
  getPrimaryContactName,
  getUniquePracticeLocations,
  getUpdatedErrorMessages,
  getUsersFilteredBasedOnLocation,
  getUsersMatchingRoles,
  isContactAndUserPracticeLocationSame,
  isInvalidBooking,
  isVirtualAppointmentType,
  RTE_MODULES,
  validateExpireDateTime,
} from './AppointmentBookingHelper';
import {
  IAppointmentBookingState,
  IAppointmentNote,
  IAppointmentTypeWiseUsersMap,
  IAppointmentUserDetail,
  IContact,
  IParticipantData,
  IRestrictedUserAppointmentTypes,
  IRestrictedUserAppointmentTypesList,
  ISlot,
  IUserAndAppointmentTypeMap,
  IUserPracticeLocation,
  UserWiseSlotMap,
} from './AppointmentBookingIntefaces';
import {
  APPOINTMENT_BOOKING_VIEW_CODES,
  MAX_FUTURE_DATE_RANGE,
  ONE_OFF_VISIT_APPT_CODE,
} from './AppointmentConstant';
import AppointmentDetail from './AppointmentDetail';
import SlotsGroup from './SlotsGroup';
import WorkFlowListByEntityEvents from './WorkFlowListByEntityEvents';
import './styles.css';
import {ITemplateCategory} from '../../../../../RightSideContainer/ContentManagement/EmailTemplates/interfaces';
import {isWeb} from '../../../../../../utils/platformCheckUtils';
import { TaskCheckList } from '../../../../AppointmentTaskCheckList/TaskCheckList';
import { IAppointmentTask } from '../../../../../../services/Appointment/AppointmentInterface';
import {USER_ACCESS_PERMISSION } from '../../../../../RightSideContainer/UserAccess/UserAccessPermission';
import { MAIN_MENU_CODES } from '../../../../../SideMenuBar/SideBarConst';
import { ModalActionAntSelect } from '../../../../ModalActionCommonComponent/ModalActionAntSelect';
import { getCurrentEHR } from '../../../../../PersonOmniView/MiddleContainer/PatientNotes/PatientNotesHelper';
import ReasonForVisitFreeText from '../../../../../PublicPages/AppointmentBookingWidget/ReasonForVisitFreeText';
import { APPOINTMENT_COLOR_INDICATOR_SIZE } from '../../../../../RightSideContainer/AccountSettings/AppointmentTypes/AppointmentTypeConst';
import CustomAppointmentTypeSelect from './CustomAppointmentTypeSelect/CustomAppointmentTypeSelect';
import Feather from 'react-native-vector-icons/Feather';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { ModalActionTimePicker } from '../../../../ModalActionCommonComponent/ModalActionTimePicker';
import TeamQueries from '../../../../../../services/Team/TeamQueries';
import { AppointmentAvailabilityCode } from '../../../../../RightSideContainer/AccountSettings/AppointmentTypes/constants';
import { AppointmentUserSelect } from '../../../../../RightSideContainer/AccountSettings/AppointmentTypes/AppointmentUserSelect/AppointmentUserSelect';
import { GET_CARE_TEAM } from '../../../../../../services/CareTeam/CareTeamQueries';
import moment, { Moment } from 'moment';
import PatientAndGroupSearchSelect from '../../../../PatientSearch/PatientAndGroupSearchSelect';
import useGetBatchedAccountUsers from '../../../../../CustomHooks/useGetBatchedAccountUsers';
import useReccuringEvent from './hooks/useReccuringEvent/useReccuringEvent';
import ReccuringEventView from './ReccuringEventView/ReccuringEventView';
import { ReccuringEventType } from './ReccuringEventView/Interfaces';
import { IReccuringEventAPI } from './hooks/useReccuringEvent/interface';
import { checkAbilityAccess, checkAccountConfigAbilityAccess, getOrgAbilites } from '../../../../../../utils/capabilityUtils';
import { FHIR_RESOURCE } from '../../../../../../constants/FhirConstant';
import { FoldButton } from '../../../../../CommonComponents/FoldButton/FoldButton';
import EventName from './EventName';
import _, { isDate } from 'lodash';
import CustomRSVPExpiry from './CustomRSVPExpiry/CustomRSVPExpiry';
import { usePractitionerIdentifierFilter } from './hooks/usePractitionerIdentifierFilter/usePractitionerIdentifierFilter';
import { LeftOutlined } from '@ant-design/icons';
import FoldPermitCan from '../../../../../CommonComponents/FoldPermitCan/FoldPermitCan';
import useEHRCapabilities from '../../../../../../screens/BusinessStudio/useEHRCapabilities';
import {usePermissions} from '../../../../../CustomHooks/usePermissions';
import SelectSlotBy from './SelectSlotByView/SelectSlotByView';
import {AppointmentDataProvider} from './contexts/AppointmentDataContext';
import ReasonForVisitView from './components/ReasonForVisitView';
import StaffInstructionView from './components/StaffInstructionView';
import TimeZoneSelectV2 from './SelectSlotByView/components/TimeZoneSelectV2/TimeZoneSelectV2';
import {GET_USERS_AVAILABLE_SLOTS_FOR_DATE_RANGE} from '../../../../../../services/Appointment/AppointmentQueries';
import {useContactsLocation} from '../../../../../CustomHooks/useContactsLocation';
import FeatureFlags from '../../../../../../constants/FeatureFlags.enums';

export interface IRescheduleData {
  isRsvpEnabled: boolean,
  id: string;
  appointmentTypeId: string;
  previousDate: Date;
  selectedUser?: {
    id: string;
    userId: string;
  };
  selectedSecondaryUserIds?: string[];
  notes: {content: string; id: string, subjectTypeId?: string}[];
  participants: IParticipantSearch[];
  originalParticipantList: IParticipantData[];
  selectedLocation: any;
  reasonForVisit: any;
  accountLocationId: string;
  locationTypeId: string;
  tasks?: IAppointmentTask[];
  startDateTime?: string;
  endDateTime?: string;
  isReccurentAppointment: boolean,
  seriesId?:string,
  invites?: any[]
  reccurenceData: IReccuringEventAPI;
  name?: string;
  reference?:any;
  description?: string;
  duration?: number;
  locationGroupId?: string;
}


const AppointmentBooking = (props: {
  slotSelected?: CalendarSlot;
  headers?: any;
  isVisible: boolean;
  onCancel: () => void;
  onComplete: (data?: any) => void;
  defaultParticipants?: IParticipantSearch[];
  rescheduleData?: IRescheduleData;
  bookAppointmentMeta?: {
    selectedUser: IUser;
    selectedLocation: ILocation;
  };
  isGroupAppointmentEnable?: boolean;
  editType?: AppointmentAction;
  disAllowToRedirectAvailableSlot?: boolean;
  disablePatientProspectSearch?: boolean;
  disableSelectSlotBy?: boolean;
  disablePrimaryUserSelect?: boolean;
  appointmentVisitType?: string;
  onlyAtClinicAppointmentEnabled?: boolean;
  disableAppointmentReccurence?: boolean;
  onAppointmentBooked?: (appointmentId: any) => void;
}) => {
  const allowToEditReccurenceConfig = props?.editType !== AppointmentAction.editOccurence;
  const allowToToggleReccurence = !props?.rescheduleData?.id;
  const isGroupAppointmentEnable = props?.isGroupAppointmentEnable || false;
  const [activeTooltip, setActiveTooltip] = useState<string>('');
  const toast = useToast();

  const appointmentDateQuery = props?.appointmentVisitType && props?.onlyAtClinicAppointmentEnabled
    ? ScheduleEventQueries.GET_APPOINTMENT_TYPES_BY_VISIT_TYPE
    : ScheduleEventQueries.GET_APPOINTMENT_TYPES;

  const accountId = getAccountUUID();
  const loggedInUserId = getUserUUID();
  const intl = useIntl();
  const navigateToScreen = useNavigate();
  const [placeholder, setPlaceholder] = useState<any>(
    'Search patients, prospects or groups'
  );
  const mlovData = useContext(CommonDataContext);
  const isMultiTenancyEnabled = getBooleanFeatureFlag(mlovData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const isSideCarContext = !!mlovData.sidecarContext?.isSidecar;
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);
  const visitTypeList =  getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.APPOINTMENT_VISIT_TYPE
  );
  const acceptedParticipantStatusId = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.APPOINTMENT_PARTICIPANT_STATUS,
    APPOINTMENT_PARTICIPANT_STATUS_CODES.ACCEPTED
  )
  const accpetedInvites = props?.rescheduleData?.invites?.filter?.(
    (participant) => participant.statusId === acceptedParticipantStatusId
  );
  const userSettings = mlovData.userSettings;

  const isAtClinicScheduleDisabled =
    userSettings['is_at_clinic_schedule_disabled']?.value === 'True' || false;
  const disallowToScheduleForOtherLocation =
    userSettings['disallow_to_schedule_for_other_location']?.value === 'True' ||
    false;
  const displayContactLocation = userSettings['is_allow_virtual_location_for_schedule_availability']?.value === 'True' || false;
  const skipSecondaryUsersForCareTeamType = skipSecondaryUsersForCareTeamTypeAppointmentType(userSettings);

  // MARK: Permissions
  const {check} = usePermissions();
  const permissionConfig = check(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    DASHBOARD_PERMISSION_ACTION.CALENDAR
  );
  const {
    loading: accountUserLoading,
    error: accountUsersError,
    userList: accountUserList,
  } = useGetBatchedAccountUsers();


  const allowedLocationIdsForLoggedInUser = permissionConfig?.allowedLocationIds;
  const isNewSettingTabEnabled = getBooleanFeatureFlag(userSettings, FeatureFlags.IS_NEW_SETTING_TAB_ENABLED);

  const userRoles =
    getMlovListFromCategory(mlovData.MLOV, MLOV_CATEGORY.USER_ROLES) || [];
  const [isScheduleBack, setIsScheduleback] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const minDuration = 10;
  const maxDuration = 120;
  const RSVP_EXPIRY_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
  const [categoryList, setCategoryList] = useState<ITemplateCategory[]>([]);
  // For add Patient
  const currentContactType = 'CUSTOMER';
  const contactTypeUuid = getContactTypeId(
    currentContactType
  );
  const {getContactsLocations} = useContactsLocation({
    contactUuid: '',
    skipCall: true
  });
  const [allMemberState, setAllMemberState] = useState({
    contactTypeUuid: contactTypeUuid,
    contactType: currentContactType,
    addContactType: currentContactType,
    addContactTypeUuid: contactTypeUuid,
    practiceLocations: {},
    isPatientIsActive: true,
    contactData: {} as any,
  });
  const [openAddPatientModal, setOpenAddPatientModal] = useState(false);
  const [confirmUpdateGroupAppointment, setConfirmUpdateGroupAppointment] =
    useState<boolean>(false);
  const appointmentStatusList =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.APPOINTMENT_STATUS
    ) || [];
  const APPOINTMENT_STATUS_IDS = {
    pending: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.PENDING
    ),
    scheduled: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.SCHEDULED
    ),
    rescheduled: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.RESCHEDULED
    ),
  };

  const scheduleLocationTypeList =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.SCHEDULE_LOCATION_TYPE
    ) || [];

  const appointmentParticipantStatusList =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.APPOINTMENT_PARTICIPANT_STATUS
    ) || [];

  const appointmentParticipantType =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.APPOINTMENT_PARTICIPANT_TYPE
    ) || [];
  const APPOINTMENT_PARTICIPANT_TYPE_IDS = {
    patient: getMlovIdFromCode(
      appointmentParticipantType,
      APPOINTMENT_PARTICIPANT_TYPE_CODES.PATIENT
    ),
    primaryUser: getMlovIdFromCode(
      appointmentParticipantType,
      APPOINTMENT_PARTICIPANT_TYPE_CODES.PRIMARY_USER
    ),
    secondaryUser: getMlovIdFromCode(
      appointmentParticipantType,
      APPOINTMENT_PARTICIPANT_TYPE_CODES.SECONDARY_USER
    ),
  };

  const noteSubjectTypeList = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.NOTE_SUBJECT_TYPE
  ) || [];

  const getNote = (notes: any[], subjectTypeCode: string) => {
    if (!notes?.length) {
      return {} as IAppointmentNote;
    }
    const subjectTypeId = getMlovIdFromCode(noteSubjectTypeList, subjectTypeCode);
    switch (subjectTypeCode) {
      case NOTE_SUBJECT_TYPE_CODES.APPOINTMENT_NOTES:
        return getAppointmentNote(notes, subjectTypeId);
      case NOTE_SUBJECT_TYPE_CODES.PATIENT_INSTRUCTION:
        return getAppointmentPatientInstruction(notes, subjectTypeId);
    }
    return {} as IAppointmentNote;
  }

  const headers = props.headers || {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  };

  const getSubtitle = () => {
    if(!!props?.rescheduleData?.id && !props?.rescheduleData?.isReccurentAppointment){
      return '';
    }
    if (props?.editType === AppointmentAction.editSeries) {
      return 'Changes will be applicable for the entire series';
    } else if (props?.editType === AppointmentAction.editOccurence) {
      return 'Changes will be applicable only for the current occurrence';
    }
    return '';
  }

  const getDefaultSelectedUser = () => {
    if (props.rescheduleData?.selectedUser) {
      return props.rescheduleData?.selectedUser?.userId;
    }
    if (props.bookAppointmentMeta?.selectedUser?.uuid) {
      return props.bookAppointmentMeta.selectedUser.uuid;
    }

    return '';
  };

  const getDefaultSelectedLocation = () => {
    if (!!props.rescheduleData && props?.rescheduleData?.accountLocationId) {
      return props?.rescheduleData?.accountLocationId;
    }
    if (props.bookAppointmentMeta?.selectedLocation?.uuid) {
      return props.bookAppointmentMeta?.selectedLocation.uuid;
    }
    return undefined;
  };

  const filteredScheduleLocationTypeList = isAtClinicScheduleDisabled
    ? scheduleLocationTypeList.filter(
        (locationType) => locationType?.code !== LOCATION_TYPE_CODES.AT_CLINIC
      )
    : props?.onlyAtClinicAppointmentEnabled
      ? scheduleLocationTypeList.filter(
          (locationType) => locationType?.code === LOCATION_TYPE_CODES.AT_CLINIC
        )
      : scheduleLocationTypeList;


  const originalSelectedDate = props.rescheduleData
    ? getDateObjectByTimeZone(props.rescheduleData.startDateTime, getCurrentTimeZone()) || new Date()
    : props.slotSelected
    ? (props.slotSelected.start as Date)
    : new Date();

  const defaultData: IAppointmentBookingState = {
    appointmentTypeId: props.rescheduleData?.appointmentTypeId || '',
    selectedPrimaryUserId: getDefaultSelectedUser(),
    selectedSecondaryUserIds: props.rescheduleData?.selectedSecondaryUserIds || [],
    participants: props.rescheduleData?.participants || [
      ...(props.defaultParticipants || []),
    ],
    startDateTime: '',
    endDateTime: '',
    selectedDate: originalSelectedDate,
    selectedEndDate: props.rescheduleData
      ? undefined
      : props.slotSelected
      ? (props.slotSelected.end as Date)
      : undefined,
    name: '',
    slots: [],
    hideSlots: false,
    errors: {},
    primaryUserList: [],
    secondaryUserList: [],
    selectedAppointmentType: undefined,
    appointmentTypeList: [],
    allAppointmentTypeList: [],
    selectedSlot: undefined,
    consentForms: [],
    slotLoading: false,
    selectedAccountLocationId: getDefaultSelectedLocation(),
    selectedPracticeLocationId: props.rescheduleData?.selectedLocation?.uuid,
    selectedUserLocations: [],
    reasonForVisit: props.rescheduleData?.reasonForVisit || undefined,
    workflowList: [],
    bookAppointmentMeta: props?.bookAppointmentMeta,
    scheduleLocationTypeList: filteredScheduleLocationTypeList,
    selectedLocationType: props?.rescheduleData?.locationTypeId
      ? getMlovObjectFromId(
          props?.rescheduleData?.locationTypeId,
          filteredScheduleLocationTypeList
        )
      : {
          id: undefined,
          value: undefined,
        },
    selectedLocationTypeId: undefined,
    fetchedSlotsForNextDay: false,
    calendarWidgetMetaData: {} as ICalendarWidgetMetaData,
    isLocationLoading: false,
    isPracticeAvailabilitySchedulePresent: true,
    isRsvpEnabled: props?.rescheduleData ? !!props?.rescheduleData?.isRsvpEnabled : false,
    duration: props.rescheduleData?.duration || 30,
    isShowTimeZonePopover: false,
    selectedPatientLocation: undefined,
    selectedContact: undefined,
    dayWiseSlotMap: new Map<string, ISlot[]>(),
    dayWiseAvailability: new Map<string, ISlot[]>(),
    // userWiseAppointmentType: new Map<string, IUserAndAppointmentTypeMap>(),
    appointmentTypeWiseUsers: new Map<string, IAppointmentTypeWiseUsersMap>(),
    isVisible: props?.isVisible,
    isBookedAppointmentLoading: false,
    dateFilterChange: false,
    appointmentFilterChange: false,
    appointmentNote: getNote(props?.rescheduleData?.notes || [], NOTE_SUBJECT_TYPE_CODES.APPOINTMENT_NOTES),
    patientInstruction: getNote(props?.rescheduleData?.notes || [], NOTE_SUBJECT_TYPE_CODES.PATIENT_INSTRUCTION),
    enabledAppointmentNote: false,
    tasks: props.rescheduleData?.tasks || [],
    isSpecificTimeView: false,
    accountLocationList: [],
    userListProcessing: false,
    isRsvpExpiryEnabled: !!props?.rescheduleData?.reference?.expiryDateTime || false,
    rsvpExpiryDateTime: props?.rescheduleData?.reference?.expiryDateTime || '',
    expiryDateTimeUnit: props?.rescheduleData?.reference?.expiryDateTimeUnit || {
      value: '10',
      unit: DATE_UNIT_CONST[0],
    },
    eventName: { code: undefined, displayName: props?.rescheduleData?.name || '' },
    description: props?.rescheduleData?.description || '',
    disAllowToRedirectAvailableSlot: props?.disAllowToRedirectAvailableSlot || false,
    selectSlotByView: props?.rescheduleData?.reference?.slotView || SelectSlotsBy.Provider,
    userWiseSlotMap: new Map(),
    selectedLocationGroupId: props?.rescheduleData?.locationGroupId || undefined,
  };
  // const [templateData, setTemplateData] = useState<any>();
  const [appointmentBookingState, setAppointmentBookingState] =
    useState<IAppointmentBookingState>(defaultData);
  const [isReccuring,setIsReccuring] = useState(props?.rescheduleData?.isReccurentAppointment || false);
  const [restrictedUserAppointmentTypes, setRestrictedUserAppointmentTypes] =
    useState<IRestrictedUserAppointmentTypes>({
      list: [],
      show: false,
    });
  const [submitting, setSubmitting] = useState(false);
  const [slotRange, setSlotRange] = useState({
    slotStartDate: '' as string,
    slotEndDate: '' as string,
    selectedMonth: currentMonth(),
    selectedYear: currentYear(),
  });
  const currentEHR = getCurrentEHR(
    appointmentBookingState?.selectedAccountLocationId,
    ''
  );
  const recurringAppointmentConfig = useReccuringEvent(
    ReccuringEventType.APPOINTMENT,
    getMomentObj(appointmentBookingState?.selectedDate),
    !!props?.rescheduleData?.id
      ? props?.rescheduleData?.reccurenceData
      : undefined,
    props?.editType,
    props?.rescheduleData as any
  );
  const isBookByProvider = appointmentBookingState?.selectSlotByView === SelectSlotsBy.Provider;
  const ehrCapabilities = useEHRCapabilities({locationId: appointmentBookingState?.selectedAccountLocationId});
  const disAllowVirtualLocation = isVirtualLocationDisabled(userSettings, ehrCapabilities);
  const canChangeDuration = canChangeAppointmentDuration(ehrCapabilities);

  const [selectedViewCode, setSelectedViewCode] = useState(
    APPOINTMENT_BOOKING_VIEW_CODES.BOOKING_VIEW
  );

  const canCreatePatient = checkAccountConfigAbilityAccess(FHIR_RESOURCE.PATIENT, 'canCreate')?.isEnabled;

  const [currentAppointmentId, setCurrentAppointmentId] = useState();
  const [selectedEvents, setSelectedEvents] = useState({});

  const isLicensedStateMatchingEnabled = isLicensedStatePatientMatchingEnabled(userSettings);
  const {
    practitionerAPILoading,
    getFilteredUsersBasedOnLicensedState,
  } = usePractitionerIdentifierFilter();

  const getAccountLocations = useQuery(TeamQueries.GetLocations, {
    fetchPolicy: 'no-cache',
    variables: {
      accountUuid: accountId,
    },
    onCompleted: (data: any) => {
      if (data?.accountLocations?.length) {
        setAppointmentBookingState((prev) => ({
          ...prev,
          accountLocationList: data?.accountLocations,
        }));
      }
    },
    onError: (error) => {
    },
  });

  const scheduleAccessUserData = useQuery(GetScheduleAccessByUserId, {
    fetchPolicy: 'no-cache',
    skip: accountUserLoading,
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    variables: {
      userId: loggedInUserId,
    },
    onCompleted: (data: any) => {
      const widgetMetaData: ICalendarWidgetMetaData =
        processCalendarWidgetUsersData(
          data?.userScheduleAccesses,
          accountUserList,
          false,
          getDefaultSelectedUser(),
          {
            currentEHR: currentEHR,
            rescheduleUserId: props.rescheduleData?.selectedUser?.userId || '',
            locationGroupId: props.rescheduleData?.locationGroupId || '',
          },
          loggedInUserId,
        );
      setAppointmentBookingState((prev) => ({
        ...prev,
        calendarWidgetMetaData: widgetMetaData,
      }));
    },
    onError: (error) => {

    },
  });

  const [getCareTeam] = useLazyQuery(GET_CARE_TEAM, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  // const getUserRoleMatchingAppointmentList = (
  //   roleId: string,
  //   appointmentTypes: IAppointmentType[]
  // ): IAppointmentType[] => {
  //   return (appointmentTypes || []).filter((appointmentType) => {
  //     if (appointmentType?.appointmentTypeGroup?.length) {
  //       const data = (appointmentType?.appointmentTypeGroup.filter((group) => group?.roleId === roleId))
  //       return data.length > 0 && data
  //     }
  //     return false;
  //   });
  // };

  // const filterUserWiseAppointmentType = (
  //   appointmentTypes: IAppointmentType[]
  // ) => {
  //   const userWiseAppointmentTypeMap: Map<string, IUserAndAppointmentTypeMap> =
  //     new Map<string, IUserAndAppointmentTypeMap>();
  //   const appointmentUserList: IUser[] = [];

  //   (
  //     appointmentBookingState?.calendarWidgetMetaData.accountUsers ||
  //     [] ||
  //     []
  //   ).forEach((selectedUser) => {
  //     let appointmentTypeList: IAppointmentType[] = [];
  //     const userRoleMlov: IMlov[] = [];
  //     (selectedUser?.userRoles || []).forEach((selectedUserRole) => {
  //       const roleId = selectedUserRole?.userRole?.userRole?.id;
  //       if (roleId) {
  //         const userRole = selectedUserRole?.userRole?.userRole;
  //         userRoleMlov.push({
  //           categoryId: '',
  //           code: userRole?.code || '',
  //           id: userRole?.id || '',
  //           value: userRole?.value || '',
  //         });
  //         const roleWiseAppointmentTypes =
  //           getUserRoleMatchingAppointmentList(roleId, appointmentTypes) || [];
  //         appointmentTypeList = appointmentTypeList.concat(
  //           roleWiseAppointmentTypes || []
  //         );
  //       }
  //     });
  //     appointmentTypeList = [...new Map(appointmentTypeList.map(item => [item['id'], item])).values()]
  //     if (userRoleMlov?.length) {
  //       appointmentTypeList.push(getOneOffVisitAppointmentType(userRoleMlov));
  //     }
  //     if (appointmentTypeList?.length) {
  //       appointmentUserList.push(selectedUser);
  //       userWiseAppointmentTypeMap.set(selectedUser?.uuid, {
  //         userDetail: selectedUser,
  //         appointmentTypes: appointmentTypeList,
  //       });
  //     }
  //   });
  //   return {userWiseAppointmentTypeMap, appointmentUserList};
  // };

  const getAppointmentTypeWiseMap = (appointmentTypes: IAppointmentType[]) => {
    const appointmentTypeWiseUsers: Map<string, IAppointmentTypeWiseUsersMap> = new Map<string, IAppointmentTypeWiseUsersMap>();
    const usersWithAccess = appointmentBookingState?.calendarWidgetMetaData.accountUsers || [];
    const appointmentTypeList = [...appointmentTypes];
    const primaryAvailableUsers = (props?.bookAppointmentMeta?.selectedUser && props?.disablePrimaryUserSelect) ? [props?.bookAppointmentMeta?.selectedUser] : usersWithAccess;

    // Existing appointment type handling
    appointmentTypes.forEach((appointmentType) => {
      if (appointmentType.id) {
        const { availablePrimaryUsers: unfilteredPrimaryUsers, availableSecondaryUsers: unfilteredSecondaryUsers } = getPrimaryAndSecondaryUsersForAppointmentType(appointmentType, primaryAvailableUsers ,usersWithAccess, { skipSecondaryUsersForCareTeamType });

        let availablePrimaryUsers = unfilteredPrimaryUsers;
        let availableSecondaryUsers = unfilteredSecondaryUsers;

        if (isMultiTenancyEnabled) {
          // Filter logic for multi-tenancy
          const filterUsersByAllowedLocations = (users: IUser[]) => {
            return users.filter(user => {
              return user.userPracticeLocations?.some(location =>
                allowedLocationIdsForLoggedInUser.includes(location.accountLocation?.uuid || '')
              );
            });
          };

          availablePrimaryUsers = filterUsersByAllowedLocations(unfilteredPrimaryUsers);
          availableSecondaryUsers = filterUsersByAllowedLocations(unfilteredSecondaryUsers);
        }

        if (availablePrimaryUsers.length) {
          appointmentTypeWiseUsers.set(appointmentType.id, {
            availabilityTypeCode: appointmentType.availabilityTypeCode || AppointmentAvailabilityCode.ROLE,
            appointmentType,
            availablePrimaryUsers,
            availableSecondaryUsers,
          });
        }
      }
    });
    if (!props?.isGroupAppointmentEnable) {
    // One off appointment type handling
    const rolesOfUsersWithAccess = getAllUserRolesFromUserList(usersWithAccess);
    const userList = getUsersMatchingRoles(usersWithAccess, rolesOfUsersWithAccess.map(item => item.id));
    if (rolesOfUsersWithAccess?.length && userList.length && !props?.appointmentVisitType) {
      const oneOffAppointmentType = getOneOffVisitAppointmentType(rolesOfUsersWithAccess);
      appointmentTypeList.push(oneOffAppointmentType);
      appointmentTypeWiseUsers.set(oneOffAppointmentType.code || '', {
        availabilityTypeCode: AppointmentAvailabilityCode.ROLE,
        appointmentType: oneOffAppointmentType,
        availablePrimaryUsers: userList,
        availableSecondaryUsers: userList,
      });
    }
   }
    return {appointmentTypeWiseUsers, appointmentTypeList};
  }

  const formatToAppointmentDetailUser = (accountUserList: IUser[], selectedContact?: IContact): IAppointmentUserDetail[] => {
    const appointmentUserList: IAppointmentUserDetail[] = [];
    // const contactAccountLocationId = appointmentBookingState?.contactAccountLocation?.accountLocation?.uuid;
    const contactAvailableLocations = getApplicableContactLocations(selectedContact, appointmentBookingState.accountLocationList);
    (accountUserList || []).forEach((user) => {
      if (contactAvailableLocations.length && (disallowToScheduleForOtherLocation || displayContactLocation)) {
        const location = (user?.userPracticeLocations || []).find(
          (userPracticeLocation) => {
            return isContactAndUserPracticeLocationSame(contactAvailableLocations, userPracticeLocation as unknown as IUserPracticeLocation);
          }
        );
        if (!location) {
          return;
        }
      }
      appointmentUserList.push({
        id: user.id,
        name: user.name,
        uuid: user.uuid,
        userPracticeLocations: user?.userPracticeLocations || [],
        userRoles: user?.userRoles || [],
      });
    });
    return appointmentUserList;
  };

  const getAppointmentTypeByIdOrCode = (
    appointmentTypeList: IAppointmentType[],
    appointmentTypeId?: string,
    appointmentTypeCode?: string
  ) => {
    if (appointmentTypeId) {
      return (
        (appointmentTypeList || []).find((appointmentType) => {
          return appointmentType.id === appointmentTypeId;
        }) || ({} as IAppointmentType)
      );
    } else {
      return (
        (appointmentTypeList || []).find((appointmentType) => {
          return appointmentType?.code === appointmentTypeCode;
        }) || ({} as IAppointmentType)
      );
    }
  };

  const getRescheduleAppointmentDetails = (
    selectedUserAppointmentTypeList: IAppointmentType[],
    appointmentTypeId?: string,
  ) => {
    let selectedAppointmentType: IAppointmentType = {} as IAppointmentType;
    if (isOneOffVisitReschedule()) {
      selectedAppointmentType = getAppointmentTypeByIdOrCode(
        selectedUserAppointmentTypeList,
        undefined,
        ONE_OFF_VISIT_APPT_CODE
      );
    } else {
      selectedAppointmentType = getAppointmentTypeByIdOrCode(
        selectedUserAppointmentTypeList,
        appointmentBookingState.appointmentTypeId || appointmentTypeId,
        undefined
      );
    }
    return selectedAppointmentType;
  };

  const getSelectedAppointmentTypeMap = (map: Map<string, IAppointmentTypeWiseUsersMap>, appointmentType: IAppointmentType) => {
    if (appointmentType.id) {
      return map.get(appointmentType.id);
    } else if (appointmentType.code) {
      return map.get(appointmentType.code);
    }
  }
  // MARK: Appt Type API CALL
  const appointmentTypeAPIData = useQuery(
    appointmentDateQuery,
    {
      skip: scheduleAccessUserData.loading || accountUserLoading || getAccountLocations.loading,
      variables: {
        searchString: `%%`,
        categoryCodes: [
          props?.isGroupAppointmentEnable ? MLOV_CODES.GROUP_SESSION : MLOV_CODES.ONE_ON_ONE_APPOINTMENT,
        ],
        locationGroupId:
          !!props.rescheduleData && !props?.isGroupAppointmentEnable
            ? props.rescheduleData?.locationGroupId
            : undefined,
        visitType: props?.appointmentVisitType,
        locationType: LOCATION_TYPE_CODES.AT_CLINIC,
      },
      fetchPolicy: 'no-cache',
      ...headers,
      onCompleted: async (data: any) => {
        setAppointmentBookingState((prev) => ({
          ...prev,
          allAppointmentTypeList: data.appointmentTypes || [],
        }));
        const sortedAppointmentTypes = data?.appointmentTypes?.sort(
          (a: IAppointmentType, b: IAppointmentType) => {
            return a?.eventName?.localeCompare(b?.eventName);
          }
        );

        const {appointmentTypeWiseUsers, appointmentTypeList} =
          getAppointmentTypeWiseMap(sortedAppointmentTypes);

        const selectedUserAppointmentTypeList = appointmentTypeList;
        let selectedAppointmentType: IAppointmentType = {} as IAppointmentType;
        let selectedLocationType: any = undefined;
        let selectedPrimaryUserId: string = getDefaultSelectedUser();
        let selectedSecondaryUserIds: string[] = [];

        // Set secondary users in case of reschedule workflow
        if (
          isRescheduleWorkflow() &&
          props.rescheduleData?.selectedSecondaryUserIds?.length
        ) {
          selectedSecondaryUserIds =
            props.rescheduleData.selectedSecondaryUserIds;
        }
        // For reschedule workflow find the appointment type
        if (isRescheduleWorkflow()) {
          selectedAppointmentType = getRescheduleAppointmentDetails(
            selectedUserAppointmentTypeList,
            props.rescheduleData?.appointmentTypeId
          );
          // For selected user find the matching appointment type
        } else if (selectedPrimaryUserId) {
          const matchingAppointmentType = getAppointmentTypeForSelectedUser(
            appointmentTypeWiseUsers,
            selectedPrimaryUserId
          );
          if (matchingAppointmentType) {
            selectedAppointmentType = matchingAppointmentType;
          }
        }
        // If no matching appointment type then use first appointment type
        if (
          !selectedAppointmentType ||
          Object.keys(selectedAppointmentType).length === 0
        ) {
          const [firstAppointmentTypeId] = appointmentTypeWiseUsers.keys();
          const appointmentTypeData = appointmentTypeWiseUsers.get(
            firstAppointmentTypeId
          );
          const firstAppointmentType = appointmentTypeData?.appointmentType;
          if (firstAppointmentType) {
            selectedAppointmentType = firstAppointmentType;
            selectedPrimaryUserId =
              appointmentTypeData.availablePrimaryUsers?.[0]?.uuid;
          }
        }

        if (props.editType === AppointmentAction.convertAWV) {
          const firstAweAppointmentType = selectedUserAppointmentTypeList?.find(
            (appointmentType: IAppointmentType) =>
              appointmentType.visitType?.code ===
              APPOINTMENT_TYPE_VISIT_TYPE.AWV
          );
          if (firstAweAppointmentType && firstAweAppointmentType?.id) {
            selectedAppointmentType = firstAweAppointmentType;
            const appointmentTypeData = appointmentTypeWiseUsers.get(
              firstAweAppointmentType?.id || ''
            );
            selectedPrimaryUserId =
              appointmentTypeData?.availablePrimaryUsers?.[0]?.uuid || '';
          }
        }

        const selectedAppointmentData = getSelectedAppointmentTypeMap(
          appointmentTypeWiseUsers,
          selectedAppointmentType
        );
        let selectedPracticeLocationId: string | undefined = '';
        let isVirtualVisit = false;
        if (
          isRescheduleWorkflow() &&
          appointmentBookingState.selectedLocationType &&
          appointmentBookingState.selectedLocationType?.code
        ) {
          const locationType = getMlovObjectFromCode(
            appointmentBookingState.selectedLocationType?.code,
            scheduleLocationTypeList
          );
          const locationTypeCode = locationType?.code || undefined;
          selectedLocationType = locationType;
          isVirtualVisit =
            locationTypeCode && locationTypeCode === LOCATION_TYPE_CODES.VIRTUAL
              ? true
              : false;
        } else {
          isVirtualVisit =
            selectedAppointmentType?.locationType?.code ===
            LOCATION_TYPE_CODES.VIRTUAL;
        }

        const locationList = getUniquePracticeLocations(appointmentBookingState?.calendarWidgetMetaData?.accountUsers as any[]);

        const locations = isMultiTenancyEnabled
          ? locationList.filter((location) =>
              // HERE FILTERING FOR ALLOWED LOCATIONS
              location?.accountLocation?.uuid
                ? allowedLocationIdsForLoggedInUser.includes(
                    location.accountLocation.uuid
                  )
                : false
            )
          : locationList;

        const selectedLocationId = isRescheduleWorkflow()
          ? props.rescheduleData?.accountLocationId
          : appointmentBookingState.selectedAccountLocationId ||
            getDefaultSelectedLocation();
        const matchedLocation = locations.find(
          (location) => location?.accountLocation?.uuid === selectedLocationId
        );

        if (matchedLocation?.accountLocation?.practiceLocation?.uuid) {
          selectedPracticeLocationId = matchedLocation?.uuid;
        } else {
          selectedPracticeLocationId = locations?.length
            ? locations[0]?.uuid
            : undefined;
        }

        const userAllLocationList = getLocationList(
          isVirtualVisit,
          disallowToScheduleForOtherLocation,
          locations,
          appointmentBookingState.selectedContact
        );

        const userLocationList = isMultiTenancyEnabled
          ? userAllLocationList.filter((location) =>
              // HERE FILTERING FOR ALLOWED LOCATIONS GROUPS
              location?.accountLocation?.uuid
                ? allowedLocationIdsForLoggedInUser.includes(
                    location.accountLocation.uuid
                  )
                : false
            )
          : userAllLocationList;

        const userSelectedPracticeLocationId =
          getUserSelectedPracticeLocationId(
            isVirtualVisit,
            locations,
            selectedPracticeLocationId
          );
        const selectedAccountLocationId = getSelectedAccountLocationId(
          isVirtualVisit,
          locations,
          userSelectedPracticeLocationId
        );
        const primaryUserList = formatToAppointmentDetailUser(
          selectedAppointmentData?.availablePrimaryUsers || []
        );
        let secondaryUserList = formatToAppointmentDetailUser(
          selectedAppointmentData?.availableSecondaryUsers || []
        );
        if (selectedAccountLocationId) {
          secondaryUserList = getUsersFilteredBasedOnLocation(
            secondaryUserList,
            selectedAccountLocationId
          );
          selectedSecondaryUserIds = selectedSecondaryUserIds.filter(
            (userId) => {
              return secondaryUserList.some((item) => item.uuid === userId);
            }
          );
        }

        let locationTypeList: IMlov[] = [];
        const isBothLocationTypeEnabled =
          selectedAppointmentType?.isPatientFacingLocationType || false;
        if (
          isOneOffVisitReschedule() ||
          isBothLocationTypeEnabled ||
          !selectedAppointmentType?.id
        ) {
          locationTypeList = scheduleLocationTypeList;
        } else {
          const selectedAppointmentTypeLocationTypeCode =
            selectedAppointmentType?.locationType?.code;
          locationTypeList = scheduleLocationTypeList.filter(
            (scheduleLocationType) => {
              return (
                selectedAppointmentTypeLocationTypeCode &&
                scheduleLocationType?.code ===
                  selectedAppointmentTypeLocationTypeCode
              );
            }
          );
        }
        if (props?.onlyAtClinicAppointmentEnabled) {
          locationTypeList = locationTypeList.filter(locationType => {
            return locationType?.code === LOCATION_TYPE_CODES.AT_CLINIC;
          });
        }
        const isPrefilPatient = isMultiTenancyEnabled &&
          !!props?.defaultParticipants && props.defaultParticipants?.length > 0;
        let selectedContactId: string | undefined = '';
        let newSelectedAccountLocationId: string | undefined = '';
        let newSelectedPracticeLocationId: string | undefined = '';
        let locationGroupId: string | undefined = '';
        if (isMultiTenancyEnabled) {
        if (isPrefilPatient) {
          const contactUUID = props.defaultParticipants?.filter(
            (participant) => participant.type === ParticipantType.patient
          )?.[0]?.key;
          if (contactUUID) {
            const locations = await getContactsLocations(contactUUID);
            locationGroupId = locations?.[0]?.accountLocation?.locationGroupId;
            selectedContactId = contactUUID;
            newSelectedAccountLocationId =
              locations?.[0]?.accountLocation?.uuid;
            newSelectedPracticeLocationId =
              locations?.[0]?.practiceLocationUuid;
          }
        } else if (isRescheduleWorkflow() && !isGroupAppointmentEnable) {
          const contactUUID = props.rescheduleData?.participants?.filter(
            (participant) => participant.type === ParticipantType.patient
          )?.[0]?.key;
          if (contactUUID) {
            const locations = await getContactsLocations(contactUUID);
            locationGroupId = locations?.[0]?.accountLocation?.locationGroupId;
            selectedContactId = contactUUID;
          }
        }
        }

        setAppointmentBookingState((prev) => ({
          ...prev,
          scheduleLocationTypeList: locationTypeList,
          selectedUserLocations: userLocationList,
          // selectedPrimaryUserId: selectedPrimaryUserId,
          selectedSecondaryUserIds: selectedSecondaryUserIds,
          appointmentTypeList:
            (isPrefilPatient ||
              (isRescheduleWorkflow() && !isGroupAppointmentEnable)) &&
            locationGroupId
              ? selectedUserAppointmentTypeList.filter(
                  (item) => item.locationGroupId === locationGroupId
                )
              : selectedUserAppointmentTypeList,
          primaryUserList: primaryUserList,
          secondaryUserList: secondaryUserList,
          appointmentTypeWiseUsers: appointmentTypeWiseUsers,
          ...((isPrefilPatient ||
            (isRescheduleWorkflow() && !isGroupAppointmentEnable)) && {
            selectedAccountLocationId: newSelectedAccountLocationId,
            selectedPracticeLocationId: newSelectedPracticeLocationId,
            selectedLocationGroupId: locationGroupId,
            primaryContactId: selectedContactId,
          }),
        }));
        if (isRescheduleWorkflow() || !isMultiTenancyEnabled) {
          appointmentTypeSelected(
            selectedAppointmentType,
            selectedLocationType,
            true,
            {
              appointmentTypeWiseUsers,
              selectedAccountLocationId: selectedAccountLocationId,
              selectedPracticeLocationId: userSelectedPracticeLocationId,
              selectedSecondaryUserIds,
            }
          );
        }
      },
    }
  );

  // // const isPreviewCodeSelected = () => {
  // //   return selectedViewCode === APPOINTMENT_BOOKING_VIEW_CODES.PREVIEW_VIEW;
  // // };

  const getScheduleBtnText = () => {
    return isRescheduleWorkflow() ? 'save' : 'book';
  };

  // const handleNeNextBtnClick = () => {
  //   const errorMessages = getUpdatedErrorMessages(appointmentBookingState);
  //   setAppointmentBookingState((prev) => ({
  //     ...prev,
  //     errors: errorMessages,
  //   }));
  //   if (!isInvalidBooking(appointmentBookingState, { disallowToScheduleForOtherLocation, disAllowVirtualLocation })) {
  //     setSelectedViewCode(APPOINTMENT_BOOKING_VIEW_CODES.PREVIEW_VIEW);
  //   } else {
  //     const message =
  //       errorMessages.name || errorMessages.patient || errorMessages.slots;
  //     if (message.length > 0) {
  //       setShowError(true);
  //       setTimeout(() => setShowError(false), 4000);
  //       showToast(toast, message, ToastType.error);
  //     }
  //   }
  // };

  const [bookAppointment] = useMutation(AppointmentQueries.BOOK_APPOINTMENT);

  const [getAvailableSlots] = useLazyQuery(
    ScheduleQueries.GET_AVAILABLE_SLOTS_FOR_DATE_RANGE,
    {
      ...headers,
      fetchPolicy: 'no-cache',
      onError: (error) => {
        setAppointmentBookingState((prev) => ({
          ...prev,
          slots: [],
          slotLoading: false,
        }));
      },
    }
  );
  const [getAvailableSlotsByUser] = useLazyQuery(
    GET_USERS_AVAILABLE_SLOTS_FOR_DATE_RANGE,
    {
      ...headers,
      fetchPolicy: 'no-cache',
      onError: (error) => {
        setAppointmentBookingState((prev) => ({
          ...prev,
          slots: [],
          slotLoading: false,
        }));
        setRestrictedUserAppointmentTypes({
          list: [],
          show: false
        })
      },
    }
  );

  const [getContactPracticeLocationsByUuids] = useLazyQuery(
    ContactsQueries.GET_CONTACT_PRACTICE_LOCATIONS_BY_UUIDS,
    {
      fetchPolicy: 'no-cache',
    }
  );


  // const [getFormAggregate, contactConsentQuery] = useLazyQuery(
  const [getFormAggregate] = useLazyQuery(
    FormsQueries.GET_FORMS_AGGREGATE_FOR_CONTACT,
    {
      fetchPolicy: 'no-cache',
      ...headers,
      onCompleted: (data: any) => {
        if (data && data.forms && data.forms.length > 0) {
          setAppointmentBookingState((prev) => {
            const formList = prev.consentForms;
            formList.forEach((form) => {
              const aggregateData = data.forms.filter(
                (response: any) => response.id === form.id
              );
              if (aggregateData.length > 0) {
                form.status =
                  aggregateData[0].formResponses_aggregate &&
                  aggregateData[0].formResponses_aggregate.aggregate &&
                  aggregateData[0].formResponses_aggregate.aggregate.count > 0
                    ? FormStatus.received
                    : FormStatus.pending;
              }
            });
            return {
              ...prev,
              consentForms: [...formList],
            };
          });
        }
      },
    }
  );

  const consentFormData = useQuery(FormsQueries.GET_FORMS_WITH_NAMES, {
    variables: {
      formNames: CONSENT_FORMS,
    },
    fetchPolicy: 'no-cache',
    ...headers,
    onCompleted: (data) => {
      if (data.forms && data.forms.length > 0) {
        const formData = data.forms.map((form: any) => {
          return {
            id: form.id,
            name: form.name,
            isSelected: true,
            status: FormStatus.pending,
          };
        });
        setAppointmentBookingState((prev) => ({
          ...prev,
          consentForms: formData,
        }));
      }
    },
  });

  // const getTemplatesByCategoryCode = () => {
  //   let templateCategoryCode = 'APPT_CONFIRMATION';
  //   if (appointmentBookingState?.isRsvpEnabled) {
  //     templateCategoryCode = 'APPT_PENDING';
  //   }
  //   const path = `${ContentTypes.emails.path}?category=${templateCategoryCode}`;
  //   /*
  //   getTemplates(path)
  //     .then((response) => {
  //       return getFormattedEmailTemplateData(response);
  //     })
  //     .then((formattedTemplates) => {
  //       if (formattedTemplates.length > 0) {
  //         const template = formattedTemplates[0];
  //         setTemplateData(template);
  //       }
  //     })
  //     .catch((error) => {
  //     });
  //     */
  // };

  // account specific merge tags
  // const accountMergeTags = getAccountMergeTagData();

  // const getMergeTags = (category: string, categories: ITemplateCategory[]) => {
  //   const mergeTagsByCategory = categories.find(
  //     (item) => item.name === category
  //   )?.mergeTags;
  //   return {...mergeTagsByCategory, global: accountMergeTags};
  // };

  // const getPreviewHtml = (
  //   templateData: any,
  //   appointmentBookingState: IAppointmentBookingState
  // ): string => {
  //   const engine = new Liquid();
  //   const patientFullName = appointmentBookingState.participants
  //     ? appointmentBookingState.participants[0]?.label?.split(' ')
  //     : [];

  //   const patientFullNameText = patientFullName
  //     ? `${patientFullName[0]} ${patientFullName[patientFullName.length - 1]}`
  //     : '';

  //   const providerName =
  //     appointmentBookingState.primaryUserList.find((user) => {
  //       return user.uuid === appointmentBookingState.selectedPrimaryUserId;
  //     })?.name || '';

  //   let locationValue = '';
  //   if (appointmentBookingState.selectedLocationTypeId) {
  //     locationValue = getMlovValueFromId(
  //       scheduleLocationTypeList,
  //       appointmentBookingState.selectedLocationTypeId
  //     );
  //   }
  //   const locationName =
  //     appointmentBookingState.selectedUserLocations.find((location) => {
  //       return (
  //         location.uuid === appointmentBookingState.selectedPracticeLocationId
  //       );
  //     })?.accountLocation?.practiceLocation?.name ||
  //     locationValue ||
  //     appointmentBookingState?.selectedAppointmentType?.locationType?.value ||
  //     '';

  //   const reasonForVisit =
  //     appointmentBookingState.reasonForVisit?.displayName || '';

  //   const appointmentStartTime =
  //     appointmentBookingState.selectedSlot?.startDateTime || '';

  //   const selectedTimezone = getSelectedTimezone();

  //   let formattedAppointmentDate = getDateStrFromFormatWithTimezone(
  //     new Date(appointmentStartTime),
  //     selectedTimezone,
  //     DATE_FORMATS.DISPLAY_DATE_FORMAT
  //   );

  //   formattedAppointmentDate =
  //     formattedAppointmentDate !== 'Invalid Date'
  //       ? formattedAppointmentDate
  //       : '';

  //   const startTime = appointmentBookingState.selectedSlot?.startDateTime || '';
  //   const endTime = appointmentBookingState.selectedSlot?.endDateTime || '';
  //   const appointmentDisplayTime = getAppointmentTimeWithTimeZone(
  //     startTime,
  //     endTime,
  //     selectedTimezone
  //   );
  //   const appointmentDateTimeText = getAppointmentDateTimeWithTimeZone(
  //     startTime,
  //     endTime,
  //     selectedTimezone
  //   );

  //   const mergeTags: Record<string, any> = {
  //     patient: {
  //       firstName: patientFullName ? patientFullName[0] : '',
  //       lastName: patientFullName
  //         ? patientFullName[patientFullName.length - 1]
  //         : '',
  //     },
  //     provider: {
  //       name: providerName,
  //     },
  //     appointmentTimeText: appointmentDateTimeText,
  //     locationText: locationName,
  //     to: {
  //       name: patientFullNameText,
  //     },
  //     from: {
  //       name: providerName,
  //     },
  //     appointment: {
  //       date: formattedAppointmentDate,
  //       location: locationName,
  //       reason: reasonForVisit,
  //       time: appointmentDisplayTime,
  //     },
  //     global: getAccountMergeTagData(),
  //   };
  //   const finalMergeTag = {
  //     ...getMergeTags(templateData?.templateCategory, categoryList),
  //     ...mergeTags,
  //   };
  //   const tpl = engine.parse(templateData?.templateHtml || '');
  //   return engine.renderSync(tpl, finalMergeTag);
  // };

  useEffect(() => {
    if (appointmentBookingState.primaryContactId) {
      getFormAggregate({
        variables: {
          contactId: appointmentBookingState.primaryContactId,
          formIds: appointmentBookingState.consentForms.map((form) => form.id),
        },
      });
    }
  }, [appointmentBookingState.primaryContactId]);

  const getAllSelectedUserIds = () => {
    const userList: string[] = [];
    if (isBookByProvider) {
      // Existing appointment type handling
      if (appointmentBookingState.selectedPrimaryUserId) {
        userList.push(appointmentBookingState.selectedPrimaryUserId);
      }
      return [...userList, ...appointmentBookingState.selectedSecondaryUserIds];
    } else {
      appointmentBookingState.primaryUserList.forEach((user) => {
        const locations = user.userPracticeLocations || [];
        const selectedLocationId =
          appointmentBookingState.selectedAccountLocationId;
        if (
          locations.some(
            (location: IUserPracticeLocation) =>
              location.accountLocation?.uuid === selectedLocationId
          )
        ) {
          userList.push(user.uuid);
        }
      });
      return userList;
    }

  }

  // DIRTY HACK: EFFECT ONLY FOR PROVIDER BASED APPOINTMENT
  useEffect(() => {
    if (isBookByProvider) {
      fetchAppointmentSlots(
        appointmentBookingState.selectedDate,
        appointmentBookingState.selectedAppointmentType,
        getAllSelectedUserIds(),
        appointmentBookingState.selectedContact,
        appointmentBookingState.selectedAccountLocationId,
        appointmentBookingState.isSpecificTimeView
      );
    }
  }, [
    appointmentBookingState.selectedPrimaryUserId,
    appointmentBookingState.selectedSecondaryUserIds,
    appointmentBookingState.selectedAppointmentType,
    appointmentBookingState.selectedAccountLocationId,
    appointmentBookingState.selectedPracticeLocationId,
    appointmentBookingState.selectedTimezone,
    appointmentBookingState.duration,
    appointmentBookingState.selectedLocationTypeId,
    appointmentBookingState.isSpecificTimeView,
    slotRange?.selectedMonth,
    slotRange?.selectedYear,
    appointmentBookingState.selectSlotByView,
  ]);

  // DIRTY HACK: EFFECT ONLY FOR BY DATE BASED APPOINTMENT
  useEffect(() => {
    if (isBookByProvider) {
      return;
    }
    fetchAppointmentSlots(
      appointmentBookingState.selectedDate,
      appointmentBookingState.selectedAppointmentType,
      getAllSelectedUserIds(),
      appointmentBookingState.selectedContact,
      appointmentBookingState.selectedAccountLocationId,
      appointmentBookingState.isSpecificTimeView,
    );
  }, [
    appointmentBookingState.selectedSecondaryUserIds,
    appointmentBookingState.selectedAppointmentType,
    appointmentBookingState.selectedAccountLocationId,
    appointmentBookingState.selectedPracticeLocationId,
    appointmentBookingState.selectedTimezone,
    appointmentBookingState.duration,
    appointmentBookingState.selectedLocationTypeId,
    appointmentBookingState.isSpecificTimeView,
    appointmentBookingState.selectSlotByView
  ]);

  useEffect(() => {
    if (disallowToScheduleForOtherLocation) {
      fetchAppointmentSlots(
        appointmentBookingState.selectedDate,
        appointmentBookingState.selectedAppointmentType,
        getAllSelectedUserIds(),
        appointmentBookingState.selectedContact,
        appointmentBookingState.selectedAccountLocationId,
        appointmentBookingState.isSpecificTimeView,
      );
    }
  }, [
    appointmentBookingState.selectedPrimaryUserId,
    appointmentBookingState.selectedSecondaryUserIds,
    appointmentBookingState.selectedAppointmentType,
    appointmentBookingState.selectedAccountLocationId,
    appointmentBookingState.selectedPracticeLocationId,
    appointmentBookingState.selectedTimezone,
    appointmentBookingState.duration,
    appointmentBookingState.selectedContact,
    appointmentBookingState.participants,
    appointmentBookingState.isSpecificTimeView,
    appointmentBookingState.selectSlotByView
  ]);

  useEffect(() => {
    getTemplateCategories()
      .then((data) => {
        return getTemplateCategoryList(data);
      })
      .then((list) => {
        setCategoryList(list);
      })
      .catch((error) => {});
    const onSubmitDataEvent = (event: any) => {
      const keyName = event.key;
      if (event.shiftKey && keyName === 'Enter') {
        submitData();
      }
    }
    if (isWeb() && window) {
      window.addEventListener('keydown', onSubmitDataEvent,false);
    }
    return () => {
      if (isWeb() && window) {
        window.removeEventListener('keydown', onSubmitDataEvent);
      }
    }
  }, []);

  const handleSpecificTimeAndSlotClick = () => {
    const isSpecificTimeView = !appointmentBookingState.isSpecificTimeView;
    const momentDate = getMomentObj(appointmentBookingState.selectedSlot?.startDateTime || appointmentBookingState.selectedDate);
    if (isSpecificTimeView) {
      handleSlotSelection({
        startDateTime: momentDate.tz(getSelectedTimezone()).toISOString(),
        endDateTime: momentDate.add(appointmentBookingState.duration, 'minutes').tz(getSelectedTimezone()).toISOString(),
      });
    } else {
      handleSpecificTimeToSlotChange();
    }
    setAppointmentBookingState((prev) => ({
      ...prev,
      isSpecificTimeView: isSpecificTimeView,
    }));
  }

  const updateSlotDateAndTimeForSpecificTime = (date: string | Date, duration: number, selectedSlot?: ISlot) => {
    if (!(appointmentBookingState.isSpecificTimeView && selectedSlot)) {
      return;
    }
    const momentDate = getMomentObj(date);
    const momentTime = getMomentObj(selectedSlot.startDateTime);
    const hour = momentTime.get('hour');
    const minutes = momentTime.get('minutes');
    momentDate.set('hour', hour);
    momentDate.set('minutes', minutes);
    momentDate.set('seconds', 0);
    handleSlotSelection({
      startDateTime: momentDate.tz(getSelectedTimezone()).toISOString(),
      endDateTime: momentDate.add(duration, 'minutes').tz(getSelectedTimezone()).toISOString(),
    });
  }

  const handleSpecificTimeToSlotChange = () => {
    const dayWiseSlots = appointmentBookingState.dayWiseSlotMap;
    let availableDaySlots: ISlot[] = [];
    if (dayWiseSlots?.size) {
      const momentSelectedDate = getMomentObj(appointmentBookingState.selectedDate);
      if (appointmentBookingState.selectedSlot?.startDateTime) {
        const momentSlotStartDate = getMomentObj(appointmentBookingState.selectedSlot.startDateTime);
        momentSelectedDate.set('hour', momentSlotStartDate.get('hour'));
        momentSelectedDate.set('minutes', momentSlotStartDate.get('minutes'));
        momentSelectedDate.set('seconds', momentSlotStartDate.get('seconds'));
      }
      const slotStartDate = momentSelectedDate.toDate();
      const slotEndDate = appointmentBookingState.selectedSlot?.endDateTime;
      const currentSelectDateStr = getDateStrFromFormat(
        slotStartDate,
        DATE_FORMATS.DISPLAY_DATE_FORMAT
      );
      // If specific time option is selected instead of slots then we do not want to take user to next available slot day
      // Thus setting current date to availableSlotDayKey id specific time is selected
      const availableSlotDayKey = getAvailableTimeSlotsDate(
        dayWiseSlots,
        currentSelectDateStr,
        true
      );
      if (availableSlotDayKey) {
        availableDaySlots = dayWiseSlots?.get(availableSlotDayKey) || [];
        let matchedSlot = availableDaySlots?.find((slot: any) => {
          return (
            isBetweenDate(
              slotStartDate,
              slot.startDateTime,
              slot.endDateTime,
              '[)'
            ) ||
            (slotEndDate &&
              isBetweenDate(
                slotEndDate,
                slot.startDateTime,
                slot.endDateTime,
                '()'
              ))
          );
        });
        matchedSlot = matchedSlot || availableDaySlots?.[0];
        if (matchedSlot) {
          handleSlotSelection(matchedSlot);
        }
      } else {
        setAppointmentBookingState((prev) => ({
          ...prev,
          selectedSlot: undefined,
          startDateTime: '',
          endDateTime: '',
        }));
      }
    } else {
      setAppointmentBookingState((prev) => ({
        ...prev,
        selectedSlot: undefined,
        startDateTime: '',
        endDateTime: '',
      }));
    }
  }

  const handleSlotSelection = (slot: ISlot, user?: IAppointmentUserDetail) => {
    const isUserChanged = !!user?.uuid;

    setAppointmentBookingState((prev) => ({
      ...prev,
      selectedSlot: slot,
      startDateTime: slot.startDateTime,
      endDateTime: slot.endDateTime,
      errors: appointmentBookingState.errors.slots
        ? getUpdatedErrorMessages({
            ...prev,
            selectedSlot: slot,
            startDateTime: slot.startDateTime,
            endDateTime: slot.endDateTime,
          })
        : prev.errors,
      ...(isUserChanged && {
        selectedPrimaryUserId: user?.uuid,
        appointmentFilterChange: true,
        dateFilterChange: false,
      })
    }));
  };

  const getAppointmentName = (appointment: IAppointmentDetail): JSX.Element => {
    const primaryContactName = getPrimaryContactName(appointment);
    const textColor =
      getLocationCode(appointment, scheduleLocationTypeList) === 'VIRTUAL'
        ? 'info.600'
        : appointment.isBlockedInterval
        ? 'secondary.600'
        : 'primary.600';
    if (primaryContactName) {
      return (
        <VStack>
          <HStack alignItems="flex-start" space={1}>
            <Text fontSize="xs" fontWeight={600} color={textColor}>
              {primaryContactName}
            </Text>
            <Spacer />
          </HStack>
          <Text style={{fontSize: 11}} color={textColor}>
            {appointment.name}
            {' (' +
              getMlovValueFromId(appointmentStatusList, appointment.statusId) +
              ')'}
          </Text>
        </VStack>
      );
    } else {
      return (
        <Text fontSize="xs" color={textColor}>
          {appointment.name}
        </Text>
      );
    }
  };

  const [getLastBookedAppointmentData, latestBookedAppointmentQuery] =
    useLazyQuery(AppointmentQueries.GET_APPOINTMENT_BY_ID_WITH_GROUP, {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      onCompleted: (data: any) => {
        if (
          data &&
          data.appointments &&
          data.appointments.length &&
          data.appointments[0].id
        ) {
          const appointment = data.appointments[0];
          const updatedEvents = {
            id: appointment.id,
            title: getAppointmentName(appointment),
            start: getDateObject(appointment.startDateTime),
            end: getDateObject(appointment.endDateTime),
            statusCode: getMlovCodeFromId(
              appointmentStatusList,
              appointment.statusId
            ),
            detail: appointment,
            primaryContactName: getPrimaryContactName(appointment),
            locationCode: getLocationCode(
              appointment,
              scheduleLocationTypeList
            ),
          };

          if (!latestBookedAppointmentQuery.loading) {
            setAppointmentBookingState((prev) => {
              return {
                ...prev,
                isVisible: false,
              };
            });
            setSelectedEvents(updatedEvents);
            setSelectedViewCode(APPOINTMENT_BOOKING_VIEW_CODES.SUMMARY_VIEW);
          }
        } else {
          setSelectedEvents({});
        }
        setSubmitting(false);
      },
      onError: (error: any) => {
        setSubmitting(false);
      },
    });

  const getAppointmentData = (appointmentId: any) => {
    getLastBookedAppointmentData({
      variables: {appointmentId: appointmentId},
    });
  };

  // MARK: Main API CALL
  const appointmentSubmitCall = (reccurenceData: any) => {
    if (confirmUpdateGroupAppointment) {
      setConfirmUpdateGroupAppointment(false);
    }
    setSubmitting(true);
    const isGroupAppointment = appointmentBookingState?.selectedAppointmentType?.category?.code === MLOV_CODES.GROUP_SESSION;
    let statusId = undefined;
    if (isGroupAppointment) {
      statusId = APPOINTMENT_STATUS_IDS.scheduled;
    } else {
      statusId = appointmentBookingState?.isRsvpEnabled ? APPOINTMENT_STATUS_IDS.pending : APPOINTMENT_STATUS_IDS.scheduled;
    }
    const data = getAppointmentBookingDataForSave(
      appointmentBookingState,
      statusId,
      appointmentParticipantStatusList,
      disallowToScheduleForOtherLocation,
      isReccuring,
      props.rescheduleData,
      {APPOINTMENT_PARTICIPANT_TYPE_IDS},
      reccurenceData?.data,
      props?.editType === AppointmentAction.editSeries
    );
    bookAppointment({
      variables: {
        data,
      },
      ...headers,
      onCompleted: (resp) => {
        props?.onAppointmentBooked?.(resp?.createBookedAppointments[0].id);
        setCurrentAppointmentId(resp?.createBookedAppointments[0].id);
        getAppointmentData(resp?.createBookedAppointments[0].id);
        //props.onComplete(resp?.createBookedAppointments[0].id);
        setIsScheduleback(false);
        setConfirmUpdateGroupAppointment(false);
        if (props.editType && !isGroupAppointmentEnable && isReccuring) {
          notification.success({
            message: getAppointmentActionSuccessToastMessage(props.editType),
          });
        }
      },
    })
      .catch((error:any) => {
        const errorCode = error?.networkError?.result?.message?.extensions?.errorCode;
        notification.destroy();
        notification.error({
          message: errorCode === 'APPT_MEMBER_LIMIT_REACHED'
            ? intl.formatMessage({ id: 'groupAppointmentMemberLimitExceeded' })
            : intl.formatMessage({ id: 'apiErrorMsg' }),
        });
        setSubmitting(false);
        setConfirmUpdateGroupAppointment(false)
      })

  }

  const submitData = (fromModal?: boolean) => {
    if (!allMemberState.isPatientIsActive) {
      const message = getInActiveContactError(allMemberState.contactData);
      notification.error({
        message,
      });
      setAppointmentBookingState((prev: any) => ({
        ...prev,
        primaryContactId: null,
        participants: [],
      }));
      return;
    }
    // MARK: Validation error messages
    const errorMessages = getUpdatedErrorMessages(appointmentBookingState, props?.isGroupAppointmentEnable);
    setAppointmentBookingState((prev) => ({
      ...prev,
      errors: errorMessages,
    }));
    let reccurenceData:any = {
      error:false
    };
    if(isReccuring){
      reccurenceData = recurringAppointmentConfig.formatDataForSave(
        appointmentBookingState.selectedTimezone?.timezone ||
          getCurrentTimeZone()
      );
    }
    // MARK: Validation fn call
    if (
      !isInvalidBooking(appointmentBookingState, reccurenceData.error, {
        disallowToScheduleForOtherLocation,
        disAllowVirtualLocation,
        isGroupAppointmentEnable,
      })
    ) {
      // Invoke confirmation modal for rescheduling/updating Group appointment
      if (
        !fromModal &&
        isGroupAppointmentEnable &&
        appointmentBookingState?.isRsvpEnabled &&
        isRescheduleWorkflow() &&
        accpetedInvites &&
        accpetedInvites?.length > 0
      ) {
        setConfirmUpdateGroupAppointment(true);
        return;
      }
      appointmentSubmitCall(reccurenceData);
    } else {
      if(reccurenceData?.error){
        notification.error({
          message: 'Recurrence information is not valid. Please check.',
        });
      }
      const message =
        errorMessages.name ||
        errorMessages.patient ||
        errorMessages.slots ||
        (props.isGroupAppointmentEnable ? errorMessages.rsvpExpiry : '')  ||
        (!props?.isGroupAppointmentEnable
          ? errorMessages.reason
          : errorMessages.description || errorMessages.eventName) ||
        errorMessages.location
      if (message.length > 0) {
        notification.destroy();
        notification.error({
          message,
        });
      }
    }
  };

  // const showFetchingNextDaySlots = (currentDate: Date, nextDate: Date) => {
  //   notification.destroy();
  //   const currentDateStr = getDateStrFromFormat(currentDate, 'Do MMM, YYYY');
  //   const nextDateStr = getDateStrFromFormat(nextDate, 'Do MMM, YYYY');
  //   notification.info({
  //     message: `No slots found for ${currentDateStr}. Checking slot availability for ${nextDateStr}`,
  //     duration: 5.0,
  //   });
  // };

  const getDayWiseSlotsFromResponse = (slotList: any[]): ISlot[] => {
    if (slotList && slotList?.length) {
      return slotList.filter((slot: ISlot) => {
        return isCurrentDateInFutureComparedToOther(
          slot.startDateTime,
          new Date()
        );
      });
    }
    return [] as ISlot[];
  };

  const getSlotDate = ()=> {
    const defaultStartDate = getDefaultStartDate();
    const defaultEndDate = getDefaultEndDate();
    if (isRescheduleWorkflow()) {
      if (
        isDateInNextMonth(
          getMomentObj(appointmentBookingState.selectedDate)
        )
      ) {
        return {
          slotStartDate: getMomentObj(
            appointmentBookingState.selectedDate
          ).startOf('month'),
          slotEndDate: getMomentObj(
            appointmentBookingState.selectedDate
          ).endOf('month'),
        };
      } else {
        // if same or prev months
        return {
          slotStartDate: getMomentObj(new Date()).startOf('month'),
          slotEndDate: getMomentObj(new Date()).endOf('month'),
        };
      }
    } else {
      if (isDateInNextMonth(getMomentObj(appointmentBookingState.selectedDate))) {
        return {
          slotStartDate: getMomentObj(appointmentBookingState.selectedDate).startOf('month'),
          slotEndDate: getMomentObj(appointmentBookingState.selectedDate).endOf('month'),
        };
      }
      return {
        slotStartDate: slotRange?.slotStartDate || defaultStartDate,
        slotEndDate: slotRange?.slotEndDate || defaultEndDate,
      }
    }
  }

  const getAvailableTimeSlotsDate = (
    dayWiseSlots: Map<string, ISlot[]>,
    dayKey: string,
    disableNextDateSuggestions: boolean,
  ) => {
    if (dayWiseSlots?.size) {
      const slots: ISlot[] = dayWiseSlots.get(dayKey) || [];
      if (slots?.length) {
        return dayKey;
      }
      // If disableNextDateSuggestions is true then do not check for other dates, return undefined
      if (disableNextDateSuggestions) {
        return undefined;
      }
      let availableSlotDayKey: string | undefined = undefined;
      dayWiseSlots.forEach((slotCount, key) => {
        if (slotCount?.length > 0 && !availableSlotDayKey) {
          availableSlotDayKey = key;
        }
      });
      return availableSlotDayKey;
    }
    return undefined;
  };

  const showNextAvailableDaySlots = () => {
    notification.destroy();
    notification.info({
      message: `Taking you to next available slot`,
      duration: 5.0,
    });
  };


  const fetchAppointmentSlotsByUser = async (queryVariables: any) => {
    const slotResponse = await getAvailableSlotsByUser({
      variables: {
        data: queryVariables,
      },
    });
    const userWiseSlotMap: UserWiseSlotMap = new Map();
    const responseSlot = slotResponse?.data?.getAvailableSlots?.slots || {};
    if (Object.keys(responseSlot)?.length) {
      Object.keys(responseSlot).forEach((key) => {
        userWiseSlotMap.set(key, responseSlot[key]?.slots);
      });
    }
    setAppointmentBookingState((prev) => ({
      ...prev,
      userWiseSlotMap: userWiseSlotMap,
      slotLoading: false,
    }));
    setRestrictedUserAppointmentTypes({
      list: [],
      show: false
    })
  }

  const fetchAppointmentSlots = (
    date: Date,
    appointmentType: IAppointmentType | undefined,
    userIds: string[],
    selectedContact?: IContact,
    accountLocationId?: string,
    isSpecificTimeView?: boolean,
  ) => {
    const isVirtualVisit = isVirtualAppointmentType(
      appointmentType,
      appointmentBookingState.selectedLocationType
    );

    const isVirtualLocation = (isVirtualVisit && isVirtualLocationEnabledInAvailability(userSettings))  || isVirtualVisit && !disAllowVirtualLocation && !disallowToScheduleForOtherLocation;
    let locationsId: any = accountLocationId;

    // if (disallowToScheduleForOtherLocation) {
    //   if (contactAccountLocation?.accountLocation?.uuid) {
    //     isVirtualLocation = false;
    //     locationsId = contactAccountLocation?.accountLocation?.uuid;
    //   } else if (
    //     !contactAccountLocation?.accountLocation?.uuid &&
    //     appointmentBookingState.selectedAccountLocationId
    //   ) {
    //     locationsId = appointmentBookingState.selectedAccountLocationId;
    //     isVirtualLocation = false;
  //   } else {
    //     locationsId = undefined;
    //     isVirtualLocation = true;
    //   }
    // } else {
    //   if (isVirtualLocation) {
    //     locationsId = undefined;
    //   }
    // }

    if (disallowToScheduleForOtherLocation) {
      if (appointmentBookingState.selectedAccountLocationId) {
        locationsId = appointmentBookingState.selectedAccountLocationId;
      }
    }

    if (
      !appointmentType ||
      appointmentType?.duration === 0 ||
      !appointmentBookingState.duration ||
      !(userIds.length) ||
      (!locationsId && !isVirtualLocation)
    ) {
      setAppointmentBookingState((prev) => ({
        ...prev,
        slots: [],
        ...(!prev.isSpecificTimeView && {selectedSlot: undefined}),
        ...(!prev.isSpecificTimeView && {startDateTime: ''}),
        ...(!prev.isSpecificTimeView && {endDateTime: ''}),
        // REST PREV SLOTS IF NOT MAKING CALLS
        dayWiseSlotMap: new Map<string, ISlot[]>(),
        userWiseSlotMap: new Map(),
      }));
      return;
    }

    setAppointmentBookingState((prev) => ({
      ...prev,
      slots: [],
      ...(!prev.isSpecificTimeView && { startDateTime: '' }),
      ...(!prev.isSpecificTimeView && { endDateTime: '' }),
      slotLoading: true,
      dayWiseSlotMap: new Map<string, ISlot[]>(),
      userWiseSlotMap: new Map(),
      hideSlots: false,
      errors: {...prev.errors, slots: ''},
    }));

    let contactIds: string[] = [];

    if (appointmentBookingState?.participants?.length) {
      contactIds = appointmentBookingState.participants
        .filter((participant) => participant.type === ParticipantType.patient)
        .map((participant) => participant.value);
    }
    // locationId is always required
    if (!locationsId) {
      setAppointmentBookingState((prev) => ({
        ...prev,
        slots: [],
        ...(!prev.isSpecificTimeView && { selectedSlot: undefined }),
        ...(!prev.isSpecificTimeView && { startDateTime: '' }),
        ...(!prev.isSpecificTimeView && { endDateTime: '' }),
        // REST PREV SLOTS IF NOT MAKING CALLS
        dayWiseSlotMap: new Map<string, ISlot[]>(),
        userWiseSlotMap: new Map(),
        slotLoading: false,
      }));
      return;
    }

    if (appointmentBookingState.selectSlotByView === SelectSlotsBy.Date) {
      fetchAppointmentSlotsByUser({
        slotStartDate: getSlotDate()?.slotStartDate,
        slotEndDate: getSlotDate()?.slotEndDate,
        userIds: userIds,
        ...(appointmentType.code !== ONE_OFF_VISIT_APPT_CODE && { appointmentTypeId: appointmentType.id }),
        ...(contactIds?.length > 0 && {contactIds: contactIds}),
        duration: isSpecificTimeView ? -1 : (appointmentBookingState?.duration || appointmentType?.duration),
        locationId: isVirtualLocation ? undefined : locationsId,
        isVirtualLocation: isVirtualLocation,
        timezone: getSelectedTimezone(),
        ...(isRescheduleWorkflow() && props.rescheduleData?.id && { rescheduleAppointmentId: props.rescheduleData.id }),
        secondaryUserIds: appointmentBookingState.selectedSecondaryUserIds
      });
      return;
    }

    getAvailableSlots({
      variables: {
        data: {
          slotStartDate: getSlotDate()?.slotStartDate,
          slotEndDate: getSlotDate()?.slotEndDate,
          userIds: userIds,
          ...(appointmentType.code !== ONE_OFF_VISIT_APPT_CODE && { appointmentTypeId: appointmentType.id }),
          ...(contactIds?.length > 0 && {contactIds: contactIds}),
          duration: isSpecificTimeView ? -1 : (appointmentBookingState?.duration || appointmentType?.duration),
          locationId: isVirtualLocation ? undefined : locationsId,
          isVirtualLocation: isVirtualLocation,
          timezone: getSelectedTimezone(),
          ...(isRescheduleWorkflow() && props.rescheduleData?.id && { rescheduleAppointmentId: props.rescheduleData.id }),
        },
      },
    })
      .then((response) => {
        if (
          response?.data?.getAvailableSlots?.slots &&
          Object.keys(response.data.getAvailableSlots.slots).length > 0
        ) {
          const isLocationNotMatchWithAppointmentType = response?.data?.getAvailableSlots?.isLocationNotMatchWithAppointmentType;
          const restrictedUserAppointmentTypes: IRestrictedUserAppointmentTypesList[] =
            response?.data?.getAvailableSlots?.restrictedUserAppointmentTypes;
          if(restrictedUserAppointmentTypes && restrictedUserAppointmentTypes.length){
            setRestrictedUserAppointmentTypes({
              list: restrictedUserAppointmentTypes,
              show: restrictedUserAppointmentTypes.length > 0
            })
          } else {
            setRestrictedUserAppointmentTypes({
              list: [],
              show: false
            })
          }
          let availableDaySlots: ISlot[] = [];
          const dayWiseSlots: Map<string, ISlot[]> = new Map<string, ISlot[]>();
          const dayWiseSlotCount: Map<string, number> = new Map<
            string,
            number
          >();
          const responseSlot = response?.data?.getAvailableSlots?.slots || {};
          if (isSpecificTimeView) {
            Object.keys(responseSlot).forEach((key: string) => {
              const dayKey = key.split('/').join('-');
              const slotList = responseSlot[key] || [];
              dayWiseSlots.set(dayKey, slotList);
            });
            setAppointmentBookingState((prev) => ({
              ...prev,
              slotLoading: false,
              dayWiseAvailability: dayWiseSlots,
            }));
            return;
          }
          if (Object.keys(responseSlot)?.length) {
            const appointmentDateKey = Object.keys(responseSlot).sort(
              (prev: string, next: string) => {
                const date1 = new Date(prev);
                const date2 = new Date(next);
                return date1.getTime() - date2.getTime();
              }
            );

            appointmentDateKey.forEach((key: string) => {
              const dayKey = key.split('/').join('-');
              const slotList = getDayWiseSlotsFromResponse(
                responseSlot[key] || []
              );
              dayWiseSlots.set(dayKey, slotList);
              dayWiseSlotCount.set(dayKey, slotList.length);
            });
          }
          if (dayWiseSlots?.size) {
            const compareDate = appointmentBookingState.selectedDate;
            // if (!appointmentBookingState.dateFilterChange && appointmentBookingState.appointmentFilterChange) {
            //     compareDate = new Date();
            // }
            const currentSelectDateStr = getDateStrFromFormat(
              compareDate,
              DATE_FORMATS.DISPLAY_DATE_FORMAT
            );
            // If specific time option is selected instead of slots then we do not want to take user to next available slot day
            // Thus setting current date to availableSlotDayKey id specific time is selected
            const disAllowToMoveNextAvailableSlot: boolean = appointmentBookingState.isSpecificTimeView || (appointmentBookingState.disAllowToRedirectAvailableSlot || false);
            const availableSlotDayKey = getAvailableTimeSlotsDate(
              dayWiseSlots,
              currentSelectDateStr,
              disAllowToMoveNextAvailableSlot
            );
            const selectedDateStr = getDateStrFromFormat(
              appointmentBookingState.selectedDate,
              DATE_FORMATS.DISPLAY_DATE_FORMAT
            );
            if (availableSlotDayKey) {
              availableDaySlots = dayWiseSlots?.get(availableSlotDayKey) || [];
              const selectedDate = getDateObject(availableSlotDayKey);
              const slotSelectDate = getDateObject(selectedDateStr);
              if (!isSameDate(selectedDate, slotSelectDate)) {
                showNextAvailableDaySlots();
              }
              setAppointmentBookingState((prev) => ({
                ...prev,
                slots: availableDaySlots,
                slotLoading: false,
                selectedDate,
                isPracticeAvailabilitySchedulePresent: true,
                dayWiseSlotMap: dayWiseSlots,
                isLocationNotMatchWithAppointmentType
              }));
              if (!appointmentBookingState.isSpecificTimeView || !appointmentBookingState.selectedSlot?.startDateTime) {
                let matchedSlot = availableDaySlots?.find((slot: any) => {
                  return (
                    isBetweenDate(
                      appointmentBookingState.selectedSlot?.startDateTime || appointmentBookingState.selectedDate,
                      slot.startDateTime,
                      slot.endDateTime,
                      '[)'
                    ) ||
                    (appointmentBookingState.selectedEndDate &&
                      isBetweenDate(
                        appointmentBookingState.selectedEndDate,
                        slot.startDateTime,
                        slot.endDateTime,
                        '()'
                      ))
                  );
                });
                matchedSlot = matchedSlot || availableDaySlots?.[0];
                if (matchedSlot) {
                  handleSlotSelection(matchedSlot);
                }
              }
            } else {
              setAppointmentBookingState((prev) => ({
                ...prev,
                slots: [],
                ...(!prev.isSpecificTimeView && { selectedSlot: undefined }),
                ...(!prev.isSpecificTimeView && { startDateTime: '' }),
                ...(!prev.isSpecificTimeView && { endDateTime: '' }),
                slotLoading: false,
                dayWiseSlotMap: dayWiseSlots,
                isLocationNotMatchWithAppointmentType
              }));
            }
          } else {
            if (isSpecificTimeView) {
              resetDayWiseAvailability();
              return;
            }
            setAppointmentBookingState((prev) => ({
              ...prev,
              slots: [],
              ...(!prev.isSpecificTimeView && { selectedSlot: undefined }),
              ...(!prev.isSpecificTimeView && { startDateTime: '' }),
              ...(!prev.isSpecificTimeView && { endDateTime: '' }),
              slotLoading: false,
              dayWiseSlotMap: dayWiseSlots,
            }));
          }
        } else if (!response?.data?.getAvailableSlots?.availabilityAggregate) {
          const isLocationNotMatchWithAppointmentType = response?.data?.getAvailableSlots?.isLocationNotMatchWithAppointmentType
          if (isSpecificTimeView) {
            resetDayWiseAvailability();
            return;
          }
          setAppointmentBookingState((prev) => ({
            ...prev,
            slots: [],
            slotLoading: false,
            isPracticeAvailabilitySchedulePresent: false,
            dayWiseSlotMap: new Map<string, ISlot[]>(),
            isLocationNotMatchWithAppointmentType
          }));
        } else {
          if (isSpecificTimeView) {
            resetDayWiseAvailability();
            return;
          }
          setAppointmentBookingState((prev) => {
            return {
              ...prev,
              slots: [],
              slotLoading: false,
              isPracticeAvailabilitySchedulePresent: true,
              dayWiseSlotMap: new Map<string, ISlot[]>(),
            };
          });
        }
      })
      .catch(() => {
        if (isSpecificTimeView) {
          resetDayWiseAvailability();
          return;
        }
        setAppointmentBookingState((prev) => ({
          ...prev,
          slots: [],
          slotLoading: false,
          dayWiseSlotMap: new Map<string, ISlot[]>(),
        }));
      });
  };

  const resetDayWiseAvailability = () => {
    setAppointmentBookingState((prev) => ({
      ...prev,
      slotLoading: false,
      dayWiseAvailability: new Map<string, ISlot[]>(),
    }));
  }

  // const dateSelected = (date: Date) => {
  //   setAppointmentBookingState((prev) => ({
  //     ...prev,
  //     selectedDate: date,
  //     errors: {...prev.errors, slots: ''},
  //     hideSlots: false,
  //   }));
  // };

  // const getUserPracticeLocationById = (
  //   userList: any[],
  //   accountLocationId?: any,
  //   userLocationId?: any
  // ) => {
  //   const selectedPrimaryUserId =
  //     appointmentBookingState.selectedPrimaryUserId || userList?.[0]?.uuid;
  //   let userPracticeLocationId = undefined;
  //   (userList || []).find((user) => {
  //     if (user.uuid === selectedPrimaryUserId && user?.userPracticeLocations.length) {
  //       const practiceLocation = getLocationByAccountOrUserPracticeLocationId(
  //         user.userPracticeLocations,
  //         accountLocationId,
  //         userLocationId
  //       );
  //       if (practiceLocation?.uuid) {
  //         userPracticeLocationId = practiceLocation.uuid;
  //         return;
  //       }
  //     }
  //   });
  //   return userPracticeLocationId;
  // };

  // const getLocationByAccountOrUserPracticeLocationId = (
  //   userPracticeLocations: any[],
  //   accountLocationId: string,
  //   userLocationId: string
  // ) => {
  //   return userPracticeLocations.find((practiceLocation) => {
  //     if (userLocationId) {
  //       return (
  //         userLocationId ===
  //         practiceLocation.accountLocation?.practiceLocation?.uuid
  //       );
  //     }
  //     if (
  //       accountLocationId &&
  //       practiceLocation?.accountLocation?.practiceLocation?.name
  //     ) {
  //       return practiceLocation?.accountLocation?.uuid === accountLocationId;
  //     }
  //   });
  // };

  const getAppointmentTypeMapKey = (appointmentType?: IAppointmentType) => {
    if (appointmentType?.code === ONE_OFF_VISIT_APPT_CODE) {
      return appointmentType?.code;
    } else if (appointmentType?.id) {
      return appointmentType.id;
    }
    return '';
  }

  const appointmentTypeSelected = (
    type: IAppointmentType,
    defaultLocationType?: any,
    isInitialSetup?: boolean,
    additionalInfo?: {
      appointmentTypeWiseUsers?: Map<string, IAppointmentTypeWiseUsersMap>,
      selectedPracticeLocationId?: string;
      selectedAccountLocationId?: string;
      selectedSecondaryUserIds?: string[];
    }
  ) => {
    let selectedScheduleLocationType: any = undefined;
    // if (isOneOffVisitReschedule()) {
    //   let locationTypeCode = LOCATION_TYPE_CODES.VIRTUAL;
    //   if (props.rescheduleData?.selectedLocation) {
    //     locationTypeCode = LOCATION_TYPE_CODES.AT_CLINIC;
    //   }
    //   selectedScheduleLocationType = (
    //     appointmentBookingState.scheduleLocationTypeList || []
    //   ).find((scheduleType) => {
    //     return scheduleType.code === locationTypeCode;
    //   });
    // }

    if (defaultLocationType?.id) {
      selectedScheduleLocationType = defaultLocationType;
    } else {
      selectedScheduleLocationType = (
        appointmentBookingState.scheduleLocationTypeList || []
      ).find((scheduleType) => {
        return scheduleType.id === type?.locationTypeId;
      });
    }

    if (
      !selectedScheduleLocationType &&
      appointmentBookingState.scheduleLocationTypeList?.length
    ) {
      selectedScheduleLocationType =
        appointmentBookingState.scheduleLocationTypeList[0];
    }

    let duration = type?.duration || 30;
    if (
      isInitialSetup &&
      isRescheduleWorkflow() &&
      props.rescheduleData?.startDateTime &&
      props.rescheduleData?.endDateTime
    ) {
      duration = getDiffInMinutes(props.rescheduleData.startDateTime, props.rescheduleData.endDateTime);
    }

    let appointmentDescription = '';
    if (isInitialSetup) {
      appointmentDescription = appointmentBookingState?.description || type?.description || '';
    } else {
      appointmentDescription = type?.description || '';
    }
    updateSlotDateAndTimeForSpecificTime(appointmentBookingState.selectedDate, duration, appointmentBookingState.selectedSlot);
    setAppointmentBookingState((prev) => {
      // const accountUsers: IAppointmentUserDetail[] = formatAccountUserData(
      //   type,
      //   appointmentBookingState.contactAccountLocation
      // );
      // const selectedUser: IUser = findUserInAccountUsers(
      //   accountUsers as IUser[],
      //   appointmentBookingState.selectedPrimaryUserId
      // );
      // const selectedPrimaryUserId = selectedUser?.uuid
      //   ? selectedUser.uuid
      //   : accountUsers?.[0]?.uuid;

      // const participants = getParticipantsFromAppointmentGroup(
      //   prev.participants,
      //   prev.primaryUserList,
      //   prev.selectedAppointmentType?.appointmentTypeGroup
      // );
      // let locations = getSelectedUserLocations(selectedPrimaryUserId, accountUsers);
      // if (!locationId && locations?.length) {
      //   locationId = locations[0]?.uuid;
      // }

      // const isVirtualVisit = type?.locationType?.code === LOCATION_TYPE_CODES.VIRTUAL;

      // if (isVirtualVisit) {
      //   locationId = undefined;
      //   locations = [];
      // }
      const isGroupAppointment = prev?.selectedAppointmentType?.category?.code === MLOV_CODES.GROUP_SESSION;
      return {
        ...prev,
        name: type?.eventName,
        appointmentTypeId: type?.id || '',
        // userList: accountUsers,
        // selectedPrimaryUserId: selectedPrimaryUserId,
        // selectedPracticeLocationId: isVirtualVisit ? undefined : locationId,
        // selectedAccountLocationId: getAccountLocationIdFromPracticeLocationId(locationId, locations),
        primaryContactId: getContactId(prev.participants),
        // participants,
        // selectedUserLocations: locations,
        selectedLocationType: selectedScheduleLocationType,
        selectedLocationTypeId: selectedScheduleLocationType?.id,
        selectedAppointmentType: type,
        isRsvpEnabled: isRescheduleWorkflow() ? prev.isRsvpEnabled : !!type?.isRsvpEnabled,
        duration: duration,
        description: isRescheduleWorkflow() ? prev.description : appointmentDescription
        // Reset selected secondary users when role based appointment type is selected
        // ...((!type.availabilityTypeCode || type.availabilityTypeCode === AppointmentAvailabilityCode.ROLE) && {
        //   selectedSecondaryUserIds: [],
        // }),
      };
    });
    updateSelectableLocations(
      type,
      appointmentBookingState.selectedPrimaryUserId,
      appointmentBookingState.participants,
      selectedScheduleLocationType,
      { appointmentType: true },
      {
        appointmentTypeWiseUsers: additionalInfo?.appointmentTypeWiseUsers,
        selectedAccountLocationId: additionalInfo?.selectedAccountLocationId,
        selectedPracticeLocationId: additionalInfo?.selectedPracticeLocationId,
        selectedSecondaryUserIds: additionalInfo?.selectedSecondaryUserIds,
      },
    );
  };

  const getAccountLocationIdFromPracticeLocationId = (
    practiceLocationId?: string,
    locations?: any[]
  ) => {
    let selectedAccountLocationId = null;
    if (practiceLocationId) {
      const userSelectedLocation = (
        locations ||
        appointmentBookingState.selectedUserLocations ||
        []
      ).find((userLocation) => {
        return userLocation.uuid === practiceLocationId;
      });
      if (userSelectedLocation && userSelectedLocation?.accountLocation?.uuid) {
        selectedAccountLocationId = userSelectedLocation?.accountLocation?.uuid;
      }
    }
    return selectedAccountLocationId;
  };

  const getSelectedLocationName = () => {
    const locations = appointmentBookingState.selectedUserLocations || [];
    const locationId = appointmentBookingState.selectedAccountLocationId;
    const isVirtualVisit = appointmentBookingState.selectedLocationType?.code ==
    LOCATION_TYPE_CODES.VIRTUAL;
    if (
      isVirtualVisit &&
      ((locationId && isVirtualLocationEnabledInAvailability(userSettings)) ||
        (!disAllowVirtualLocation && !disallowToScheduleForOtherLocation))
    ) {
      return 'Virtual Appointment';
    }
    if (locationId) {
      const userSelectedLocation = locations.find((userLocation) => {
        return userLocation?.accountLocation?.uuid === locationId;
      });
      if (userSelectedLocation?.accountLocation?.practiceLocation?.name) {
        return userSelectedLocation.accountLocation.practiceLocation.name;
      }
    }
    if (disAllowVirtualLocation) {
      return undefined;
    }
    return appointmentBookingState.selectedLocationType?.code ==
      LOCATION_TYPE_CODES.VIRTUAL
      ? 'Virtual Appointment'
      : undefined;
  };

  // const getUserRole = () => {
  //   if (
  //     appointmentBookingState?.selectedAppointmentType?.appointmentTypeGroup
  //       ?.length
  //   ) {
  //     const roleId =
  //       appointmentBookingState.selectedAppointmentType?.appointmentTypeGroup[0]
  //         .roleId;
  //     if (roleId) {
  //       return getMlovValueFromId(userRoles, roleId);
  //     }
  //   }
  // };

  const resetContent = () => {
    setAppointmentBookingState(defaultData);
  };

  const isRescheduleWorkflow = (): boolean => {
    return !!props.rescheduleData;
  };

  // const emailPreviewWidth = `${screen.width * 0.33}% `;
  const componentLoading =
    appointmentTypeAPIData.loading ||
    scheduleAccessUserData.loading ||
    accountUserLoading ||
    consentFormData.loading ||
    getAccountLocations.loading ||
    practitionerAPILoading ;

  const getSelectedUserLocations = (
    selectedPrimaryUserId: string,
    userList: IAppointmentUserDetail[]
  ): IUserPracticeLocation[] => {
    return getUniquePracticeLocations(userList);
  };

  // const getScheduleAccessUsersRoles = (accountUsers: IUser[]): IMlov[] => {
  //   const roleIds: IMlov[] = [];
  //   (accountUsers || []).forEach((user) => {
  //     (user.userRoles || []).forEach((selectedRole: any) => {
  //       const userRoleId = selectedRole.userRole?.userRole?.id;
  //       if (roleIds.indexOf(userRoleId) == -1) {
  //         const roleMlov: IMlov = getRoleMlov(userRoleId);
  //         if (roleMlov?.id) {
  //           roleIds.push(roleMlov);
  //         }
  //       }
  //     });
  //   });
  //   return roleIds;
  // };

  // const getRoleMlov = (userRoleId: string): IMlov => {
  //   return (
  //     (userRoles || []).find((userRole) => {
  //       return userRole.id === userRoleId;
  //     }) || ({} as IMlov)
  //   );
  // };

  // const getAppointmentTypeListByUserRole = (
  //   appointmentTypes: IAppointmentType[],
  //   roleMlov: IMlov[]
  // ): IAppointmentType[] => {
  //   let filterAppointmentTypes: IAppointmentType[] = [];
  //   if (appointmentTypes.length) {
  //     filterAppointmentTypes = appointmentTypes.filter((appointmentType) => {
  //       return isAppointmentTypeMatchWithUserRole(roleMlov, appointmentType);
  //     });
  //   }
  //   filterAppointmentTypes.push(getOneOffVisitAppointmentType(roleMlov));
  //   return filterAppointmentTypes;
  // };

  // const isAppointmentTypeMatchWithUserRole = (
  //   roleMlov: IMlov[],
  //   appointmentType: IAppointmentType
  // ) => {
  //   return (roleMlov || []).some((role: IMlov) => {
  //     if (appointmentType?.appointmentTypeGroup?.length) {
  //       const roleId = appointmentType.appointmentTypeGroup[0].roleId;
  //       return role.id === roleId;
  //     }
  //   });
  // };

  // const findUserInAccountUsers = (
  //   accountUsers: IUser[],
  //   userId: string
  // ): IUser => {
  //   return (
  //     (accountUsers || []).find((user) => {
  //       return user.uuid === userId;
  //     }) || ({} as IUser)
  //   );
  // };

  // const getAppointmentTypeByUserRole = (
  //   user: IUser,
  //   appointmentTypes: IAppointmentType[]
  // ): IAppointmentType => {
  //   return (
  //     (appointmentTypes || []).find((appointmentType: IAppointmentType) => {
  //       if (appointmentType?.appointmentTypeGroup?.length) {
  //         const roleId = appointmentType.appointmentTypeGroup[0].roleId;
  //         return isMatchUserRoleWithAppointmentType(roleId, user);
  //       }
  //     }) || ({} as IAppointmentType)
  //   );
  // };

  // const isMatchUserRoleWithAppointmentType = (
  //   appointmentTypeRoleId: string | undefined,
  //   user: IUser
  // ): boolean => {
  //   return (user.userRoles || []).some((userRole) => {
  //     return userRole.userRole?.userRole?.id === appointmentTypeRoleId;
  //   });
  // };

  const formatAccountUserDataByContactLocation = (
    userList: IAppointmentUserDetail[],
    selectedContact?: IContact,
  ): IAppointmentUserDetail[] => {
    const appointmentUserList: IAppointmentUserDetail[] = [];
    const contactAvailableLocations = getApplicableContactLocations(selectedContact, appointmentBookingState.accountLocationList);
    (userList || []).forEach((user) => {
      if (contactAvailableLocations.length && disallowToScheduleForOtherLocation) {
        const location = (user?.userPracticeLocations || []).find(
          (userPracticeLocation) => {
            return (isContactAndUserPracticeLocationSame(contactAvailableLocations, userPracticeLocation as unknown as IUserPracticeLocation));
          }
        );
        if (!location) {
          return;
        }
      }
      appointmentUserList.push(user);
    });
    return appointmentUserList;
  };

  const updateSelectableLocations = async (
    appointmentType: IAppointmentType | undefined,
    selectedPrimaryUserId: string,
    participants: any[],
    selectedLocationType: IMlov | undefined,
    changed: {
      patient?: boolean;
      appointmentType?: boolean;
      user?: boolean;
      locationType?: boolean;
    },
    additionalInfo?: {
      appointmentTypeWiseUsers?: Map<string, IAppointmentTypeWiseUsersMap>,
      selectedPracticeLocationId?: string;
      selectedAccountLocationId?: string;
      selectedSecondaryUserIds?: string[]
    }
  ) => {
    if (!appointmentType) {
      return;
    }
    setAppointmentBookingState((prev) => ({...prev, userListProcessing: true }));
    const mapKey = getAppointmentTypeMapKey(appointmentType);
    const mapData = (additionalInfo?.appointmentTypeWiseUsers || appointmentBookingState.appointmentTypeWiseUsers).get(mapKey);
    let newPrimaryUserList = formatToAppointmentDetailUser(mapData?.availablePrimaryUsers || []);
    let newSecondaryUserList = formatToAppointmentDetailUser(mapData?.availableSecondaryUsers || []);
    let locationTypeList: IMlov[] = [];
    const isBothLocationTypeEnabled = appointmentType?.isPatientFacingLocationType || false;
    if (isBothLocationTypeEnabled || !appointmentType?.id) {
      locationTypeList = scheduleLocationTypeList;
    } else {
      const selectedAppointmentTypeLocationTypeCode = appointmentType?.locationType?.code;
      locationTypeList = scheduleLocationTypeList.filter(scheduleLocationType => {
        return selectedAppointmentTypeLocationTypeCode && scheduleLocationType?.code === selectedAppointmentTypeLocationTypeCode;
      });
    }
    if(appointmentType.code === ONE_OFF_VISIT_APPT_CODE){
      locationTypeList = filteredScheduleLocationTypeList
    }
    let isSelectedLocationTypeMatch = false;
    if (appointmentBookingState?.selectedLocationType) {
      isSelectedLocationTypeMatch = (locationTypeList || []).some(locationType => {
        return locationType?.id === appointmentBookingState?.selectedLocationType?.id;
      });
    }
    if (props?.onlyAtClinicAppointmentEnabled) {
      locationTypeList = locationTypeList.filter(locationType => {
        return locationType?.code === LOCATION_TYPE_CODES.AT_CLINIC;
      });
    }
    const isVirtualVisit = isVirtualAppointmentType(
      appointmentType,
      (!isSelectedLocationTypeMatch && locationTypeList?.length) ? locationTypeList[0] : selectedLocationType
    );
    const isVirtualLocation = isVirtualVisit && !disAllowVirtualLocation && !disallowToScheduleForOtherLocation;

    let selectedPracticeLocationId = additionalInfo?.selectedPracticeLocationId;
    let selectedPatientLocation: string | undefined = undefined;
    let selectedContact: IContact | undefined = undefined;
    let selectedSecondaryUserIds: string[] = additionalInfo?.selectedSecondaryUserIds || appointmentBookingState.selectedSecondaryUserIds;
    const patientContactId = participants?.find(
      (participant) => participant.type === ParticipantType.patient
    )?.value || '';
    const isCareTeamBasedAppointmentType = appointmentType?.availabilityTypeCode === AppointmentAvailabilityCode.CARE_TEAM;
    const isProviderBasedAppointmentType = appointmentType?.availabilityTypeCode === AppointmentAvailabilityCode.PROVIDER;
    const shouldUpdateForCareTeamBasedAptType = patientContactId && isCareTeamBasedAppointmentType && (changed.appointmentType || changed.patient);
    const shouldUpdateForProviderBasedAptType = isProviderBasedAppointmentType && changed.appointmentType;
    if (patientContactId && isCareTeamBasedAppointmentType) {
      const careTeamResponse = await getCareTeam({variables: {
        contactId: patientContactId
      }});
      const careTeamUsers = careTeamResponse?.data?.careTeams?.[0]?.userPool?.userPoolUsers?.map((user: any) => user.userId) || [];
      newPrimaryUserList = newPrimaryUserList.filter(item => item.uuid && careTeamUsers.includes(item.uuid));
      newSecondaryUserList = newSecondaryUserList.filter(item => item.uuid && careTeamUsers.includes(item.uuid));
      if (shouldUpdateForCareTeamBasedAptType) {
        selectedPrimaryUserId = !newPrimaryUserList.some(item => item.uuid && item.uuid === selectedPrimaryUserId) ? newPrimaryUserList?.[0]?.uuid : selectedPrimaryUserId;
        selectedSecondaryUserIds = newSecondaryUserList.filter(item => item.uuid !== selectedPrimaryUserId).map(item => item.uuid);
      }
    } else if (shouldUpdateForProviderBasedAptType) {
      selectedSecondaryUserIds = newSecondaryUserList.map(item => item.uuid);
      const isSelectedUserAvailableInList = newPrimaryUserList.some(item => item.uuid === selectedPrimaryUserId);
      if (!isSelectedUserAvailableInList) {
        selectedPrimaryUserId = newPrimaryUserList?.[0]?.uuid;
      }
    }
    let locations = getSelectedUserLocations(
      selectedPrimaryUserId,
      newPrimaryUserList
    );

    if (participants?.length) {
      const patientTypeParticipant = participants.find(
        (participant) => participant.type === ParticipantType.patient
      );
      if (patientTypeParticipant) {
        const contacts = await getContactPracticeLocationsByUuids({
          variables: {
            contactUuids: [patientTypeParticipant.value],
          },
        });

        selectedContact = contacts?.data.contacts?.[0];
        const contactAvailableLocations = getApplicableContactLocations(selectedContact, appointmentBookingState.accountLocationList);
        if (disallowToScheduleForOtherLocation && contactAvailableLocations.length) {
          const userList = formatAccountUserDataByContactLocation(newPrimaryUserList, selectedContact);
          const selectUserFromList = (userList || []).find((user) => {
            return user.uuid === selectedPrimaryUserId;
          });
          if (!selectUserFromList?.uuid) {
            selectedPrimaryUserId = userList?.[0]?.uuid;
          } else {
            selectedPrimaryUserId = selectUserFromList?.uuid;
          }
          locations = getUniquePracticeLocations(appointmentBookingState?.calendarWidgetMetaData?.accountUsers as any[]);

          locations = locations.filter((location) => {
            return isContactAndUserPracticeLocationSame(contactAvailableLocations, location);
          });

          const selectedAccountLocationId = additionalInfo?.selectedAccountLocationId || appointmentBookingState.selectedAccountLocationId || getDefaultSelectedLocation();
          const practiceLocation = locations.find((location) => {
            const accountLocationId = location?.accountLocation?.uuid;
            if (selectedAccountLocationId === accountLocationId) {
              return true;
            }
          });

          if (practiceLocation) {
            selectedPatientLocation = practiceLocation.accountLocation?.practiceLocation?.uuid;
            selectedPracticeLocationId = practiceLocation?.uuid;
          } else {
            selectedPatientLocation = locations?.[0]?.accountLocation?.practiceLocation?.uuid;
            selectedPracticeLocationId = locations?.[0]?.uuid;
          }
        }
      }
    }

    let defaultPrimaryUser: IUser | null = null;
    const userList = isLicensedStateMatchingEnabled ? getFilteredUsersBasedOnLicensedState(newPrimaryUserList, selectedContact) : formatAccountUserDataByContactLocation(newPrimaryUserList, selectedContact);
    const selectUserFromList = (userList || []).find((user) => {
      return user.uuid === selectedPrimaryUserId;
    });
    if (!selectUserFromList?.uuid) {
      defaultPrimaryUser = userList?.[0];
      selectedPrimaryUserId = userList?.[0]?.uuid;
    } else {
      selectedPrimaryUserId = selectUserFromList?.uuid;
      defaultPrimaryUser = selectUserFromList;
    }
    if (!(disallowToScheduleForOtherLocation && contactAvailableLocations.length) || isLicensedStateMatchingEnabled) {
      locations = getSelectedUserLocations(
        selectedPrimaryUserId,
        userList,
      );
    }

    if (!selectedPracticeLocationId && !isVirtualLocation && locations.length) {
      selectedPracticeLocationId = locations[0].uuid;
      selectedPatientLocation = locations?.[0]?.accountLocation?.practiceLocation?.uuid;
    }
    const userLocationList = getLocationList(
      isVirtualLocation,
      disallowToScheduleForOtherLocation,
      locations,
      selectedContact,
    );
    // check user selected locations avaialable
    const defaultUserSelectedLocationIds: string[] = [];
    if (selectedPrimaryUserId) {
      let userPracticeLocations = [];
      if (selectUserFromList?.userPracticeLocations?.length) {
        userPracticeLocations = selectUserFromList?.userPracticeLocations || [];
      } else {
        userPracticeLocations = defaultPrimaryUser?.userPracticeLocations || [];
      }
      (userPracticeLocations || []).forEach((userPracticeLocation: any) => {
        if (userPracticeLocation?.accountLocation?.uuid) {
          defaultUserSelectedLocationIds.push(userPracticeLocation?.accountLocation?.uuid);
        }
      });
    }
    let userSelectedPracticeLocation = undefined;
    if (defaultUserSelectedLocationIds.length > 0 && userLocationList?.length > 0) {
      userSelectedPracticeLocation = (userLocationList || []).find(
        (location) => {
          if (props.bookAppointmentMeta?.selectedLocation?.uuid) {
            return location?.accountLocation?.uuid === props.bookAppointmentMeta?.selectedLocation?.uuid;
          }
          if (location?.accountLocation?.uuid && defaultUserSelectedLocationIds.includes(location?.accountLocation?.uuid || '')) {
            return true;
          }
        }
      );
    }
    if (!userSelectedPracticeLocation) {
      userSelectedPracticeLocation = (userLocationList || []).find(
        (location) => {
          return location?.accountLocation?.uuid === (additionalInfo?.selectedAccountLocationId || appointmentBookingState.selectedAccountLocationId || getDefaultSelectedLocation());
        }
      );
    }
    selectedPracticeLocationId = userSelectedPracticeLocation?.uuid ||
      (userLocationList?.length ? userLocationList[0]?.uuid : undefined);
    const selectedAccountLocationId =
      getAccountLocationIdFromPracticeLocationId(
        selectedPracticeLocationId,
        userLocationList
      );

    // let appointmentTypeList = appointmentBookingState.appointmentTypeList || [];
    // let selectedAppointmentType = appointmentType || appointmentBookingState.selectedAppointmentType || undefined;
    // const filterSelectedUserId = selectUserFromList?.uuid || (userList?.length ? userList[0].uuid : '');
    // if (filterSelectedUserId) {
    //   const userAndAppointmentMap: IUserAndAppointmentTypeMap = appointmentBookingState.userWiseAppointmentType.get(filterSelectedUserId) || ({} as IUserAndAppointmentTypeMap);
    //   appointmentTypeList = userAndAppointmentMap?.appointmentTypes || [];
    //   const isSameAppointmentAvailable = (appointmentTypeList || []).some(appointmentType => {
    //     return (appointmentType?.id && appointmentType?.id === selectedAppointmentType?.id) ||
    //        (appointmentType?.code && appointmentType?.code === selectedAppointmentType?.code);
    //   });
    //   if (!isSameAppointmentAvailable) {
    //     selectedAppointmentType = appointmentTypeList?.[0];
    //   }
    // }

    newSecondaryUserList = getFilteredUsersBasedOnLicensedState(newSecondaryUserList, selectedContact);
    if (selectedAccountLocationId) {
      newSecondaryUserList = getUsersFilteredBasedOnLocation(newSecondaryUserList, selectedAccountLocationId);
      selectedSecondaryUserIds = selectedSecondaryUserIds.filter((userId) => {
        return newSecondaryUserList.some(item => item.uuid === userId);
      });
    }
    selectedSecondaryUserIds = selectedSecondaryUserIds.filter((userId) => {
      return userId !== selectedPrimaryUserId;
    });
    const isGroupAppointment = appointmentBookingState?.selectedAppointmentType?.category?.code === MLOV_CODES.GROUP_SESSION;
    setAppointmentBookingState((prev) => ({
      ...prev,
      scheduleLocationTypeList: locationTypeList,
      selectedPracticeLocationId: selectedPracticeLocationId,
      selectedAccountLocationId: selectedAccountLocationId,
      selectedContact: selectedContact,
      selectedUserLocations: userLocationList,
      selectedSecondaryUserIds: selectedSecondaryUserIds,
      selectedPatientLocation: selectedPatientLocation,
      isRsvpEnabled: prev.selectedSecondaryUserIds.length ? false : (prev.isRsvpEnabled || false),
      primaryUserList: userList,
      secondaryUserList: newSecondaryUserList,
      ...((defaultPrimaryUser?.uuid && defaultUserSelectedLocationIds.length) && { selectedPrimaryUserId: defaultPrimaryUser.uuid }),
      errors: prev.errors.patient
        ? getUpdatedErrorMessages({
            ...prev,
            selectedPrimaryUserId: selectedPrimaryUserId,
            participants: participants,
          })
        : prev.errors,
      userListProcessing: false,
      ...(!isSelectedLocationTypeMatch && locationTypeList?.length && { 
          selectedLocationType: locationTypeList[0],
          selectedLocationTypeId: locationTypeList?.[0]?.id
        })
    }));
  };

  const getLocationList = (
    isVirtualVisit: boolean,
    disallowToScheduleForOtherLocation: boolean,
    locations: IUserPracticeLocation[],
    selectedContact: IContact | undefined
  ) => {
    let locationList = [];
    if (disallowToScheduleForOtherLocation || displayContactLocation) {
      locationList = filterUserLocationsByContactState(
        selectedContact,
        locations
      );
    } else {
      locationList = locations;
    }
    if (isMultiTenancyEnabled) {
      return getLocationsByAllowedLocationIdAndLocationGroupId({
        allowedLocationIdsForLoggedInUser,
        locationList,
        locationGroupId: appointmentBookingState?.selectedLocationGroupId as string,
      })
    }
    return locationList;
  };

  const getSelectedAccountLocationId = (
    isVirtualVisit: boolean,
    locations: IUserPracticeLocation[],
    selectedPracticeLocationId: string | undefined
  ) => {
    if (isVirtualVisit || !locations?.length) {
      return undefined;
    } else {
      return getAccountLocationIdFromPracticeLocationId(
        selectedPracticeLocationId,
        locations
      ) || locations?.[0]?.accountLocation?.uuid;
    }
  };

  const filterUserLocationsByContactState = (
    selectedContact: IContact | undefined,
    userPracticeLocations: IUserPracticeLocation[]
  ) => {
    const contactAvailableLocations = getApplicableContactLocations(selectedContact, appointmentBookingState.accountLocationList);
    if (userPracticeLocations?.length && contactAvailableLocations.length) {
      return userPracticeLocations.filter((userPracticeLocation) => {
        const isStateSame = isContactAndUserPracticeLocationSame(contactAvailableLocations, userPracticeLocation);
        return isStateSame;
      });
    }
    return userPracticeLocations || [];
  };

  const getUserSelectedPracticeLocationId = (
    isVirtualVisit: boolean,
    locations: IUserPracticeLocation[],
    selectedPracticeLocationId: string | undefined
  ) => {
    if (!locations?.length) {
      return undefined;
    } else if (isVirtualVisit) {
      return undefined;
    } else {
      return selectedPracticeLocationId || locations?.[0]?.uuid;
    }
  };

  const isOneOffVisitReschedule = () => {
    return props.rescheduleData?.id && !props.rescheduleData?.appointmentTypeId;
  };

  const isPatientAndSelectedLocationSame = (): boolean => {
    if (
      contactAvailableLocations.length &&
      appointmentBookingState.selectedUserLocations?.length &&
      appointmentBookingState.selectedPracticeLocationId &&
      appointmentBookingState.selectedAccountLocationId
    ) {
      const userSelectedLocation = (
        appointmentBookingState.selectedUserLocations || []
      ).find((userLocation) => {
        return (
          userLocation.uuid ===
          appointmentBookingState.selectedPracticeLocationId
        );
      });
      return contactAvailableLocations.some((item) => {
        return item.practiceLocation?.uuid &&
        item.practiceLocation?.uuid === userSelectedLocation?.accountLocation?.practiceLocation?.uuid;
      })
    }
    return true;
  };

  const selectedLocationName = getSelectedLocationName();
  const contactAvailableLocations = getApplicableContactLocations(appointmentBookingState?.selectedContact, appointmentBookingState.accountLocationList);

  function getSelectedTimezone() {
    return (
      appointmentBookingState.selectedTimezone?.timezone || getCurrentTimeZone()
    );
  }
  const showErrorToast = () => {
    const message =
      appointmentBookingState.errors.name ||
      appointmentBookingState.errors.patient ||
      appointmentBookingState.errors.slots;
    if (message.length > 0 && showError) {
      return (
        <Box
          bg="error.500"
          px="2"
          py="1"
          rounded="sm"
          mb={5}
          style={{marginTop: 40}}
        >
          {message}
        </Box>
      );
    }
  };
  const getHeaderTitle = () => {
    if (selectedViewCode === APPOINTMENT_BOOKING_VIEW_CODES.SUMMARY_VIEW) {
      return 'Schedule Summary';
    } else if (props?.isGroupAppointmentEnable) {
      return 'Group Appointment';
    } else {
      return 'Schedule Appointment';
    }
  };

  const isCalendarMonthYearChange = (selectedMonth: number, selectedYear: number)=> {
    return detectMonthYearChange(selectedMonth,selectedYear,slotRange?.selectedMonth,slotRange?.selectedYear)
  }

  const handleAppointmentDateChange = (date: any) => {
    const selectedMonth = moment(date).month() + 1;
    const selectedYear = moment(date).year();
    if (isCalendarMonthYearChange(selectedMonth,selectedYear)) {
      const startDate = getDateStrFromMomentObj(
        moment(date).startOf('month'),
        DATE_FORMATS.DISPLAY_DATE_FORMAT
      );
      const endDate = getDateStrFromMomentObj(
        moment(date).endOf('month'),
        DATE_FORMATS.DISPLAY_DATE_FORMAT
      );
      setSlotRange((prev)=>{
        return {
          ...prev,
          slotStartDate: startDate,
          slotEndDate: endDate,
          selectedMonth: selectedMonth,
          selectedYear: selectedYear
        }
      })
    }
    if (date) {
      recurringAppointmentConfig.onChangeData('startDate',date);
      const dateStr = getDateStrFromMomentObj(
        date,
        DATE_FORMATS.DISPLAY_DATE_FORMAT
      );
      const slots = appointmentBookingState.dayWiseSlotMap.get(dateStr);
      updateSlotDateAndTimeForSpecificTime(date, appointmentBookingState.duration, appointmentBookingState.selectedSlot);
      setAppointmentBookingState((prev) => ({
        ...prev,
        dateFilterChange: true,
        selectedDate: date,
        slots: slots || [],
        // selectedSlot also needs to be reset if we are resetting startDateTime and endDateTime
        ...(!prev.isSpecificTimeView && { selectedSlot: undefined }),
        ...(!prev.isSpecificTimeView && { startDateTime: '' }),
        ...(!prev.isSpecificTimeView && { endDateTime: '' }),
        hideSlots: false,
        }));
    }
  }

  const renderAppointmentDateElm = (): JSX.Element => {
    return (
      <View flex={0.5}>
        <ModalActionDatePicker
          className='custom-date-picker'
          isRequired={true}
          label={'appointmentDate'}
          defaultValue={getMomentObj(appointmentBookingState.selectedDate)}
          format={DISPLAY_DATE_FORMAT}
          onChange={handleAppointmentDateChange}
          disabledDate={(current: any) => {
            return (
              current &&
              isPastDayOfCalendar(
                current,
                appointmentBookingState.selectedTimezone?.timezone ||
                  getCurrentTimeZone()
              )
            );
          }}
          value={getMomentObj(appointmentBookingState.selectedDate)}
          customStyle={{flex: 1,
            heigh: 32,
            borderColor: Colors.FoldPixel.GRAY250,
            borderRadius: 4,
          }}
        />
      </View>
    );
  };

  const range = (start: number, end: number) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const getInitialRSVPExpiryTime = () => {
    return getDefaultRSVPExpiryDateTime(
      appointmentBookingState?.endDateTime,
    );
  }

  const getRSVPExpiryTimeValue = () => {
    if(appointmentBookingState.rsvpExpiryDateTime){
      return getMomentObj(appointmentBookingState?.rsvpExpiryDateTime)
    } else {
      if (getMomentObj(appointmentBookingState.endDateTime).isValid()) {
        return moment(appointmentBookingState.endDateTime);
      } else {
        return getMomentObj(new Date()).add(2, 'hours');
      }
    }
  }

  const renderRsvpExpiryDateElm = (): JSX.Element => {
    const invalid = appointmentBookingState.errors.rsvpExpiry;
    return (
      <View flex={1} ml={1}>
        <CustomRSVPExpiry
          selectedUnit={
            appointmentBookingState?.expiryDateTimeUnit?.unit}
          handleValueChange={(value) => {
            setAppointmentBookingState((prev) => ({
              ...prev,
              expiryDateTimeUnit: {
                ...prev.expiryDateTimeUnit,
                value: value,
              },
            }));
          }}
          error={invalid}
          value={appointmentBookingState?.expiryDateTimeUnit?.value}
          handleUnitChange={(unit) => {
            setAppointmentBookingState((prev) => ({
              ...prev,
              expiryDateTimeUnit: {
                ...prev.expiryDateTimeUnit,
                unit: unit,
              },
            }));

          }}
          valueFormatter={valueFormatter}
        />
      </View>
    );
  };


  const renderDescriptionVisitElm = (): JSX.Element => {
    return (
      <Stack>
        <ReasonForVisitFreeText
          isShowError={!!appointmentBookingState.errors.description}
          showLabel
          customLabel={intl.formatMessage({ id: 'description' })}
          customPlaceHolder='Describe the event here'
          value={appointmentBookingState.description}
          onChange={(description) => {
            setAppointmentBookingState((prev) => ({
              ...prev,
              description: description.displayName,
            }));
          }}
          numberOfLines={5}
          isRequired
          errorMessage={appointmentBookingState.errors.description}
        />
      </Stack>
    );
  };


  const renderAppointmentTypeElem = (): JSX.Element => {
    return (
      <View flex={1} justifyContent={'flex-end'}>
        <CustomAppointmentTypeSelect
          filterOption={(input: string, option: any) => {
            return (option?.title || '')
              .toLowerCase()
              .includes(input.toLowerCase());
          }}
          allowClear={false}
          showSearch={true}
          isDisabled={
            (props?.isGroupAppointmentEnable && isRescheduleWorkflow()) ||
            !appointmentBookingState.appointmentTypeList?.length ||
            (isMultiTenancyEnabled &&
              ((props?.isGroupAppointmentEnable &&
                !!appointmentBookingState.primaryContactId) ||
                (!props?.isGroupAppointmentEnable &&
                  !appointmentBookingState.primaryContactId)))
          }
          labelInValue={true}
          isRequired={true}
          onChange={(value: any) => {
            const selected = appointmentBookingState.appointmentTypeList.filter(
              (type) => {
                return type.id === value.key;
              }
            );
            if (selected.length > 0) {
              appointmentTypeSelected(selected[0]);
            }
          }}
          value={
            Object.keys(appointmentBookingState?.selectedAppointmentType || {})
              .length > 0
              ? appointmentBookingState.selectedAppointmentType
              : undefined
          }
          data={appointmentBookingState.appointmentTypeList}
          optionProps={{
            key: 'id',
            value: 'eventName',
            label: 'eventName',
          }}
          extraStyle={{flex: 1}}
        />
        {!appointmentBookingState.appointmentTypeList?.length &&
        appointmentBookingState.primaryContactId ? (
          <Text
            style={{
              color: '#71717a',
            }}
          >
            No appointment types available
          </Text>
        ) : (
          renderVisitType()
        )}
      </View>
    );
  };

  const renderTimeSlotTimeZoneElm = (): JSX.Element => {

    return (
      <TimeZoneSelectV2
        className="appointment-timezone"
        showLabel={false}
        showSelectBorder={false}
        width={180}
        memorizeTimezone={true}
        selectColor="transparent"
        fontColor="#825AC7"
        toShowSelectDropdownArrowSvg={true}
        isRequired={false}
        onChange={(timezone?: ITimezone) => {
          setAppointmentBookingState((prev: any) => ({
            ...prev,
            selectedTimezone: timezone,
            isShowTimeZonePopover: !prev.isShowTimeZonePopover,
          }));
        }}
      />
    );
  };


  const renderEventName = (): JSX.Element => {
    return (
      <Stack space={2}>
        <EventName
          isShowError={true}
          showLabel
          customLabel={intl.formatMessage({ id: 'eventName' })}
          customPlaceHolder='Enter Event Name'
          value={appointmentBookingState.eventName?.displayName}
          onChange={(eventName) => {
            setAppointmentBookingState((prev) => ({
              ...prev,
              eventName,
              errors: prev.errors.eventName
                ? getUpdatedErrorMessages({
                  ...prev,
                  eventName: eventName
                })
                : prev?.errors
            }));
          }}
          numberOfLines={2}
          isRequired={false}
          errorMessage={appointmentBookingState.errors.eventName}

        />
      </Stack>

    )
  }

  const renderPatientOrProspectElem = (): JSX.Element => {
    // MARK: Patient Search
    return (
      <Stack space={2}>
        <View flex={1}>
          <PatientAndGroupSearchSelect
            className={isGroupAppointmentEnable ? "custom-user-box" : "custom-select-box"}
            style={{
              height: !props?.isGroupAppointmentEnable ? 32 : undefined,
            }}
            locationIds={
              isMultiTenancyEnabled ? allowedLocationIdsForLoggedInUser : undefined
            }
            isMultiSelect={props?.isGroupAppointmentEnable || false}
            showEmail
            showPhoneNumber
            isProspect={true}
            showTagInMultiline={true}
            rightLabelTitle={(!canCreatePatient || isRescheduleWorkflow() || isSideCarContext) ? '' : 'Add New Patient'}
            onRightLabelClick={() => setOpenAddPatientModal(true)}
            isInvalid={!!appointmentBookingState.errors.patient}
            showErrorBorder={!!appointmentBookingState.errors.patient}
            isRequired={!isGroupAppointmentEnable}
            label={props?.isGroupAppointmentEnable ? 'selectAppointmentPatientsAndGroups' : 'selectAppointmentPatients'}
            disableUserSearch
            disableGroupSearch={props?.isGroupAppointmentEnable ? false : true}
            isDisabled={isRescheduleWorkflow() || props?.disablePatientProspectSearch}
            onFocus={() => setPlaceholder('Start Typing to search...')}
            onBlur={() => setPlaceholder(props?.isGroupAppointmentEnable ? 'Search patients, prospects or groups' : 'Search patients or prospects')}
            placeholder={placeholder}
            errors={{
              patient: appointmentBookingState.errors.patient
            }}
            showError={!!appointmentBookingState.errors.patient}
            value={
              appointmentBookingState.participants
            }
            onChange={(participant: any) => {
              if (Array.isArray(participant) && participant?.length) {
                const newParticipants: IParticipantSearch[] = [];
                  participant.map((allParticipants: IParticipantSearch) => {
                    const contactData = allParticipants?.contactData;
                    if (
                      allParticipants?.type !== ParticipantType.group &&
                      contactData?.uuid
                    ) {
                      const formatParticipant = {
                        key: contactData?.uuid,
                        value: contactData?.uuid,
                        label: contactData?.name,
                        type: allParticipants?.type,
                      } as IParticipantSearch;
                      newParticipants.push(formatParticipant);
                      const currentStatus = isActiveContact(contactData);
                      if (!currentStatus) {
                        setAllMemberState((prev) => {
                          return {
                            ...prev,
                            isPatientIsActive: currentStatus,
                            contactData: contactData,
                          };
                        });
                      }
                    } else {
                      const formatParticipant = {
                        key: allParticipants?.value,
                        value: allParticipants?.value,
                        label: allParticipants.count? `${allParticipants.label} (${allParticipants.count} ${allParticipants.count>1?'members':`member`})` :allParticipants.label ,
                        type: allParticipants?.type,
                      } as IParticipantSearch;

                      newParticipants.push(formatParticipant);
                    }
                  });
                setAppointmentBookingState((prev) => ({
                  ...prev,
                  primaryContactId: getContactId(newParticipants),
                  participants: newParticipants,
                }));
                updateSelectableLocations(
                  appointmentBookingState.selectedAppointmentType,
                  appointmentBookingState.selectedPrimaryUserId,
                  newParticipants,
                  appointmentBookingState.selectedLocationType,
                  {patient: true}
                );
                return;
              }
              const contactData = participant?.label?.props?.contactData;
              const currentStatus = isActiveContact(contactData);
              const contactLocationId = contactData?.contactPracticeLocations?.[0]?.accountLocation?.uuid;
              setAllMemberState((prev) => {
                return {
                  ...prev,
                  isPatientIsActive: currentStatus,
                  contactData: contactData,
                };
              });
              if (contactData?.uuid) {
                const selectedLocation = (
                  mlovData.accountLocationListWithEHR || []
                ).find(
                  (item) => item.uuid === contactLocationId
                );
                const selectedLocationGroupId = selectedLocation?.locationGroupId;
                const selectedPracticeLocationId = selectedLocation?.practiceLocation?.uuid;
                const selectedAccountLocationId = selectedLocation?.uuid;

                const formatParticipant = {
                  key: contactData?.uuid,
                  value: contactData?.uuid,
                  label: contactData?.name,
                  type: ParticipantType.patient,
                } as IParticipantSearch;

                const newAppointmentTypeList = isMultiTenancyEnabled
                  ? appointmentBookingState.allAppointmentTypeList.filter(
                      (item) =>
                        item.locationGroupId === selectedLocationGroupId ||
                        item.locationGroupId === null
                    )
                  : appointmentBookingState.allAppointmentTypeList;

                const isPrevSelectedAppointmentExistsInNewList =
                  isMultiTenancyEnabled &&
                  !!appointmentBookingState?.selectedAppointmentType?.id &&
                  newAppointmentTypeList.find(
                    (item) =>
                      item.id ===
                      appointmentBookingState.selectedAppointmentType?.id
                  );

                setAppointmentBookingState((prev) => ({
                  ...prev,
                  primaryContactId: formatParticipant?.key,
                  participants: [formatParticipant],
                  selectedLocationGroupId,
                  selectedAccountLocationId: isMultiTenancyEnabled ? selectedAccountLocationId : undefined,
                  selectedPracticeLocationId,
                  appointmentTypeList: newAppointmentTypeList || [],
                  ...(isMultiTenancyEnabled &&
                    !isPrevSelectedAppointmentExistsInNewList && {
                      selectedAppointmentType: undefined,
                    }),
                }));

                updateSelectableLocations(
                  appointmentBookingState.selectedAppointmentType,
                  appointmentBookingState.selectedPrimaryUserId,
                  [formatParticipant],
                  appointmentBookingState.selectedLocationType,
                  { patient: true },
                );
              } else {
                setAppointmentBookingState((prev) => ({
                  ...prev,
                  primaryContactId: undefined,
                  participants: [],
                  selectedLocationGroupId: undefined,
                  selectedAccountLocationId: undefined,
                  selectedPracticeLocationId: undefined,
                  appointmentTypeList: prev.allAppointmentTypeList || [],
                  ...(isMultiTenancyEnabled && {
                    selectedAppointmentType: undefined,
                    selectedPrimaryUserId: undefined,
                    selectedLocationType: undefined,
                  }),
                }));
                updateSelectableLocations(
                  appointmentBookingState.selectedAppointmentType,
                  appointmentBookingState.selectedPrimaryUserId,
                  [],
                  appointmentBookingState.selectedLocationType,
                  { patient: true },
                );
              }
            }}
          />
           {isGroupAppointmentEnable && (
            <HStack alignItems={'center'} mt={1}>
               <Feather name="info" size={12} color={Colors.Custom.Gray500} style={{marginTop: 2}} />
              <Text ml={1} fontSize={'xs'} color={Colors.Custom.Gray500}>Members can be added to the group later</Text>
            </HStack>
          )}
        </View>
      </Stack>
    );
  };



  const renderLocationElm = (): JSX.Element => {
    // MARK: Location Search
    if (appointmentBookingState.isLocationLoading) {
      return (
        <View {...testID(TestIdentifiers.lazyLoading)} width="full">
          <Skeleton active />
        </View>
      );
    }
    const isDisabled =
      appointmentBookingState.selectedUserLocations.length === 0 ||
      (isMultiTenancyEnabled &&
        (appointmentBookingState.selectedAppointmentType?.id === undefined ||
          !appointmentBookingState?.primaryContactId)) ||
        appointmentBookingState.userListProcessing;
    return (
      <FormControl flex={1} justifyContent={"flex-start"}>
        <ModalActionAntSelect
          label={'Location'}
          isRequired
          placeholder={appointmentBookingState?.userListProcessing ? "Loading..." : "Select Location"}
          className="custom-select-box"
          disabled={isDisabled}
          isDisabled={isDisabled}
          showSearch={true}
          labelInValue={true}
          filterOption={(input: string, option: any) => {
            return (option?.children || '').toLowerCase().includes(input.toLowerCase());
          }}
          value={
          appointmentBookingState?.userListProcessing
            ? undefined
            : appointmentBookingState.selectedUserLocations.find(
                (item) =>
                  item.uuid ===
                  appointmentBookingState.selectedPracticeLocationId
              )?.accountLocation?.practiceLocation?.name || ''
          }
          onChange={(value: any) => {
            const practiceLocationId = value.key;
            setAppointmentBookingState((prev) => {
              const selectedAccountLocationId =
                getAccountLocationIdFromPracticeLocationId(
                  practiceLocationId
                );
              const mapKey = getAppointmentTypeMapKey(prev.selectedAppointmentType);
              const mapData = prev.appointmentTypeWiseUsers.get(mapKey);
              let newSecondaryUserList = formatToAppointmentDetailUser(mapData?.availableSecondaryUsers || []);
              let selectedSecondaryUserIds = prev.selectedSecondaryUserIds;
              if (selectedAccountLocationId) {
                newSecondaryUserList = getUsersFilteredBasedOnLocation(newSecondaryUserList, selectedAccountLocationId);
                selectedSecondaryUserIds = selectedSecondaryUserIds.filter((userId) => {
                  return newSecondaryUserList.some(item => item.uuid === userId);
                })
              }

              const primaryUserId: string | null = getPrimaryUserByLocationId({
                prevSelectedPrimaryUserId: prev.selectedPrimaryUserId,
                selectedAccountLocationId, 
                availablePrimaryUsers: mapData?.availablePrimaryUsers || [],
              });


              return {
                ...prev,
                selectedPracticeLocationId: practiceLocationId,
                selectedAccountLocationId: selectedAccountLocationId,
                selectedSecondaryUserIds: selectedSecondaryUserIds,
                secondaryUserList: newSecondaryUserList,
              ...(primaryUserId && { selectedPrimaryUserId: primaryUserId }),
                errors: prev.errors.patient
                  ? getUpdatedErrorMessages({
                      ...prev,
                      selectedPracticeLocationId: practiceLocationId,
                    })
                  : prev.errors,
              };
            });
          }}
          data={appointmentBookingState.selectedUserLocations.map((item) => {
            return {
              key: item.uuid,
              value: item.uuid,
              label: item.accountLocation?.practiceLocation?.name,
            }
          })}
          optionProps={{
            key: 'key',
            label: 'label',
            value: 'value',
          }}
          customStyle={{flex: 1, width: '100%',  height: 32,}}
        />
        {/* {disallowToScheduleForOtherLocation &&
          appointmentBookingState.contactAccountLocation && (
            <HStack space={4} alignItems="center">
              <View flex={2}>
                <Select
                  placeholder="Select Location"
                  isDisabled={isRescheduleWorkflow()}
                  selectedValue={
                    appointmentBookingState.contactAccountLocation
                      ?.accountLocation?.uuid
                  }
                  onValueChange={(practiceLocationId: string) => {
                  }}
                >
                  {getSelectedUser() &&
                    [appointmentBookingState.contactAccountLocation].map(
                      (location: IUserPracticeLocation, index: any) => {
                        return (
                          <Select.Item
                            label={
                              location?.accountLocation?.practiceLocation
                                ?.name || ''
                            }
                            value={location?.accountLocation?.uuid || ''}
                            key={location?.accountLocation?.uuid + index}
                          />
                        );
                      }
                    )}
                </Select>
              </View>
            </HStack>
          )} */}
      {!appointmentBookingState?.userListProcessing && <View>
        {getLocationMessage() && (
          <Text marginTop={0.5} fontSize="xs" color="gray.500">
            {getLocationMessage()}
          </Text>
        )}
        {!appointmentBookingState.selectedUserLocations?.length ? (
          <Text fontSize="xs" color="error.500">
            {getNoLocationMessage()}
            <Pressable
              onPress={() => {
                onSettingPress('/admin/account/teamMembers');
              }}
            >
              <Text
                fontStyle="italic"
                textDecorationLine={'underline'}
                color="primary.500"
              >
                set location
              </Text>
            </Pressable>
          </Text>
        ) : null}
        {appointmentBookingState.selectedUserLocations?.length > 0 &&
          !isPatientAndSelectedLocationSame() && (
            <Text fontSize="xs" color="gray.500">
              Selected location is different than patient location.
            </Text>
          )}
      </View>}
      </FormControl>
    );
  };

  const getLocationMessage = () => {
    if (displayContactLocation && appointmentBookingState.selectedContact?.name) {
      if (appointmentBookingState.selectedUserLocations.length && appointmentBookingState.selectedContact?.name) {
        return `(Associated location with ${appointmentBookingState.selectedContact?.name})`;
      }
      return undefined;
    }
  };

  const getNoLocationMessage = () => {
    const userName = appointmentBookingState.primaryUserList.filter(
      (userItem: any) => userItem.id === appointmentBookingState.selectedPrimaryUserId
    )?.[0]?.name;
    if (displayContactLocation && appointmentBookingState?.selectedContact?.name) {
      const locationName = contactAvailableLocations?.[0]?.practiceLocation?.name;
      return `${userName} is not associated with ${locationName ? locationName : ' patient location '}`
    }
    if (!appointmentBookingState.primaryContactId || !appointmentBookingState.selectedAppointmentType?.id) {
      return "";
    }

    return `No Location is available.`;
  };

  const renderLocationTypeElm = (): JSX.Element => {
    return (
      <View flex={0.5}>
        <ModalActionAntSelect
          className="custom-select-box"
          customStyle={{
            height: 32,
          }}
          label={'locationType'}
          labelInValue={true}
          isRequired={false}
          allowClear={false}
          showSearch={true}
          disabled={(isRescheduleWorkflow() && isGroupAppointmentEnable) || (isMultiTenancyEnabled && !appointmentBookingState.primaryContactId) || props?.onlyAtClinicAppointmentEnabled}
          isDisabled={(isRescheduleWorkflow() && isGroupAppointmentEnable) || (isMultiTenancyEnabled && !appointmentBookingState.primaryContactId) || props?.onlyAtClinicAppointmentEnabled}
          filterOption={(input: string, option: any) => {
            return (option?.children || '').toLowerCase().includes(input.toLowerCase());
          }}
          placeholder="Select"
          value={appointmentBookingState.selectedLocationType?.value || ''}
          onChange={(selectedValue: any) => {
            const value = selectedValue.key;
            // cannot select same option again
            if(value === appointmentBookingState.selectedLocationTypeId){
              return;
            }
            const selected =
              appointmentBookingState.scheduleLocationTypeList.find((type) => {
                return type.id === value;
              });
            setAppointmentBookingState((prev) => {
              return {
                ...prev,
                selectedLocationType: selected,
                selectedLocationTypeId: value,
                selectedPracticeLocationId:
                  selected?.code === LOCATION_TYPE_CODES.VIRTUAL
                    ? undefined
                    : prev.selectedPracticeLocationId,
              }
            });
            updateSelectableLocations(
              appointmentBookingState.selectedAppointmentType,
              appointmentBookingState.selectedPrimaryUserId,
              appointmentBookingState.participants,
              selected,
              { locationType: true },
            );
          }}
          data={appointmentBookingState.scheduleLocationTypeList}
          optionProps={{
            key: 'id',
            label: 'value',
            value: 'id',
          }}
          extraStyle={{flex: 1}}
        />
      </View>
    );
  };

  const renderLocationAndVisitType = (): JSX.Element => {
    return (
      <Stack direction={['column', 'column', 'row']} space={2} flex={1}>
        {/* {renderLocationTypeElm()} */}
        {renderLocationElm()}
      </Stack>
    );
  };

  const valueFormatter = (
    value: string | number | undefined,
    info: {userTyping: boolean; input: string}
  ): string => {
    const data = `${value}`;
    if (data.includes('.') && !info.userTyping) {
      return `${value}`.replace('\\.0*$', '');
    }
    return `${value}`;
  };

  const canShowLocationField = () => {
    const isVirtualVisit = isVirtualAppointmentType(
      appointmentBookingState.selectedAppointmentType,
      appointmentBookingState.selectedLocationType
    );
    const isVirtualLocation =
      isVirtualVisit &&
      !disAllowVirtualLocation &&
      !disallowToScheduleForOtherLocation;
    return (
      !appointmentBookingState.isLocationLoading &&
      appointmentBookingState.selectedAppointmentType &&
      (appointmentBookingState.selectedAppointmentType?.appointmentTypeGroup
        ?.length > 0 ||
        appointmentBookingState.selectedAppointmentType.availabilityTypeCode ===
          AppointmentAvailabilityCode.PROVIDER) &&
      (!isVirtualLocation ||
        (disallowToScheduleForOtherLocation &&
          contactAvailableLocations.length) ||
        (isVirtualLocation &&
          disallowToScheduleForOtherLocation &&
          !contactAvailableLocations.length))
    );
  };


  const renderDurationElm = (): JSX.Element => {
    return (
      <FormControl flex={0.5}>
        <FormControl.Label>
          <DisplayText
            size={'smRegular'}
            textLocalId={'duration'}
            extraStyles={{
              color: Colors.FoldPixel.GRAY250,
              fontWeight: 500,
              fontSize: 14,
            }}
          />
          <Text color="error.500">*</Text>
          {/* <Text marginLeft={2} fontSize="xs" color="gray.500">
            {`(Slot duration range from ${minDuration}-${maxDuration} mins)`}
          </Text> */}
        </FormControl.Label>
        <InputNumber
          type={'number'}
          className="custom-input-number"
          disabled={!canChangeDuration || (isMultiTenancyEnabled && appointmentBookingState.selectedAppointmentType?.id === undefined)}
          style={{
            width: '100%',
            padding: 2,
            fontSize: 12,
            borderRadius: 8,
            height: 32,
          }}
          value={appointmentBookingState.duration}
          min={minDuration}
          max={maxDuration}
          precision={0}
          step={1}
          addonAfter={<Text>mins</Text>}
          formatter={valueFormatter}
          onChange={(text: any) => {
            if (text >= minDuration && text <= maxDuration) {
              updateSlotDateAndTimeForSpecificTime(
                appointmentBookingState.selectedDate,
                text,
                appointmentBookingState.selectedSlot
              );
              setAppointmentBookingState((prev) => {
                return {
                  ...prev,
                  duration: text,
                };
              });
            }
          }}
        />
      </FormControl>
    );
  };

  const getSecondaryUserDisabledState = () => {
    const baseCondition = appointmentBookingState.userListProcessing ||
    !appointmentBookingState.selectedAccountLocationId ||
    !appointmentBookingState.appointmentTypeId;

    if (!isBookByProvider) {
      return !appointmentBookingState.selectedPrimaryUserId || baseCondition;
    }
    return baseCondition;
  }

  const renderSecondaryUsers = () => {
    const secondaryUsers = appointmentBookingState.secondaryUserList
      .filter(
        (item) =>
        {
          const doesNotExistInPrimaryUserList = Boolean(
            appointmentBookingState.selectedPrimaryUserId !== item.uuid &&
              item.uuid
          );
          // is available at selected location
          const locations =
            item.userPracticeLocations as IUserPracticeLocation[];
          const isAvailableAtSelectedLocation = locations.some(
            (location) =>
              location.accountLocation?.uuid ===
              appointmentBookingState.selectedAccountLocationId
          );
          return (
            doesNotExistInPrimaryUserList && isAvailableAtSelectedLocation
          );

        }
      )
      .map((item) => accountUserList.find((user) => user.uuid === item.uuid));
    let message = '';
    if (canShowLocationField() && selectedLocationName) {
      if (
        appointmentBookingState.selectedAppointmentType
          ?.availabilityTypeCode === AppointmentAvailabilityCode.CARE_TEAM ||
        appointmentBookingState.selectedAppointmentType
          ?.availabilityTypeCode === AppointmentAvailabilityCode.PROVIDER
      ) {
        message = `(List of users which are configured in appointment type and available at ${selectedLocationName})`;
      } else {
        message = `(List of users which are available at ${selectedLocationName})`;
      }
    }

    return (
      <View flex={0.5}>
        <FormControl>
          <HStack>
            <FormControl.Label>
              <DisplayText
                size={'smRegular'}
                textLocalId="secondaryAppointmentUsers"
                extraStyles={{
                  color: Colors.FoldPixel.GRAY250,
                }}
              />
            </FormControl.Label>
            <Tooltip
              title={intl.formatMessage({
                id: 'secondaryAppointmentUserLabelInfo',
              })}
              placement={'top'}
              destroyTooltipOnHide={true}
            >
              <Feather
                name="info"
                size={16}
                color={Colors.Custom.Gray500}
                style={{marginTop: 2, marginLeft: -10}}
              />
            </Tooltip>
          </HStack>
          <AppointmentUserSelect
            value={appointmentBookingState.selectedSecondaryUserIds}
            showError={false}
            userList={secondaryUsers as IUser[]}
            placeholder={'Select secondary users'}
            isMultiSelect={true}
            isLoading={appointmentBookingState.userListProcessing}
            isDisabled={getSecondaryUserDisabledState()}
            onChange={(list: string[]) => {
              const isGroupAppointment =
                appointmentBookingState?.selectedAppointmentType?.category
                  ?.code === MLOV_CODES.GROUP_SESSION;
              setAppointmentBookingState((prev) => ({
                ...prev,
                selectedSecondaryUserIds: list,
                appointmentFilterChange: true,
                dateFilterChange: false,
                isRsvpEnabled: isGroupAppointment
                  ? appointmentBookingState.isRsvpEnabled
                  : list.length
                  ? false
                  : appointmentBookingState.isRsvpEnabled,
              }));
            }}
          />
        </FormControl>
        {!!message && (
          <HStack marginTop={1} flex={1}>
            <Text fontSize={12} color={Colors.Custom.Gray500}>
              {message}
            </Text>
          </HStack>
        )}
        {!appointmentBookingState.secondaryUserList?.length &&
          disallowToScheduleForOtherLocation &&
          contactAvailableLocations.length > 0 &&
          selectedLocationName && (
            <Text fontSize="xs" color="error.500">
              {`Please go to Admin -> Team Management and add ${selectedLocationName} for required team member.`}
            </Text>
          )}
      </View>
    );
  };

  const renderPrimaryUser = () => {
    const primaryUsers = appointmentBookingState.primaryUserList
      .filter(
        (item) =>
           {
             const doesnotExistInSecondaryUserList =
               !appointmentBookingState.selectedSecondaryUserIds.includes(
                 item.uuid
               );

             // is available at selected location
             const locations =
               item.userPracticeLocations as IUserPracticeLocation[];
             const isAvailableAtSelectedLocation = locations.some(
               (location) =>
                 location.accountLocation?.uuid ===
                 appointmentBookingState.selectedAccountLocationId
             );

             return (
               doesnotExistInSecondaryUserList && isAvailableAtSelectedLocation
             );
           }
      )
      .map((item) => accountUserList.find((user) => user.uuid === item.uuid));

    const isDisabled = appointmentBookingState.userListProcessing || !appointmentBookingState.selectedAccountLocationId || !appointmentBookingState.appointmentTypeId || props?.disablePrimaryUserSelect;
    return (
      <View flex={0.5}>
        <FormControl
          isInvalid={appointmentBookingState.errors.selectedPrimaryUserId}
        >
          <HStack>
            <FormControl.Label isRequired>
              <DisplayText
                size={'smRegular'}
                textLocalId="primaryAppointmentUser"
                extraStyles={{
                  color: Colors.FoldPixel.GRAY250,
                }}
              />
            </FormControl.Label>
            <Tooltip
              title={intl.formatMessage({
                id: 'primaryAppointmentUserLabelInfo',
              })}
              placement={'top'}
              destroyTooltipOnHide={true}
            >
              <Feather
                name="info"
                size={16}
                color={Colors.Custom.Gray500}
                style={{marginTop: 2, marginLeft: -10}}
              />
            </Tooltip>
          </HStack>
          <AppointmentUserSelect
            value={
              appointmentBookingState.selectedPrimaryUserId
                ? [appointmentBookingState.selectedPrimaryUserId]
                : []
            }
            showError={!!appointmentBookingState.errors.selectedPrimaryUserId}
            userList={primaryUsers as IUser[]}
            placeholder={'Select primary user'}
            isMultiSelect={false}
            isLoading={appointmentBookingState.userListProcessing}
            isDisabled={isDisabled}
            onChange={(list: string[]) => {
              const selectedValue = list?.[0] || '';
              setAppointmentBookingState((prev) => ({
                ...prev,
                selectedPrimaryUserId: selectedValue,
                appointmentFilterChange: true,
                dateFilterChange: false,
                ...(selectedValue === '' && { dayWiseSlotMap: new Map<string, ISlot[]>() }),
              }));
            }}
          />
          {!!appointmentBookingState.errors.selectedPrimaryUserId && (
            <FormControl.ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {appointmentBookingState.errors.selectedPrimaryUserId}
            </FormControl.ErrorMessage>
          )}
        </FormControl>
        {!appointmentBookingState.primaryUserList?.length &&
          disallowToScheduleForOtherLocation &&
          contactAvailableLocations.length > 0 &&
          selectedLocationName && (
            <Text fontSize="xs" color="error.500">
              {`Please go to Admin -> Team Management and add ${selectedLocationName} for required team member.`}
            </Text>
          )}
      </View>
    );
  };

  const renderRSVPElm = (): JSX.Element => {
    if (
      !isGroupAppointmentEnable && // NOTE: In case of group appointment, RSVP is always visible
      (appointmentBookingState.selectedSecondaryUserIds.length ||
        appointmentBookingState.selectedAppointmentType
          ?.availabilityTypeCode === AppointmentAvailabilityCode.PROVIDER ||
        appointmentBookingState.selectedAppointmentType
          ?.availabilityTypeCode === AppointmentAvailabilityCode.CARE_TEAM)
    ) {
      return <></>;
    }
    const isDisabled = isRescheduleWorkflow()
      ? isReccuring
      : false;

    return (
      <Stack space={4} flex={1}>
        <FormControl>
          <View
            borderColor={Colors.FoldPixel.GRAY250}
            borderWidth={0.5}
            borderRadius={4}
            padding={2}
          >
            <HStack
              key={'rsvpEnabled'}
              flex={1}
              flexWrap={'wrap'}
              space={2}
              alignItems={'center'}
            >
              <Checkbox
                className={isDisabled ? 'rsvp-checkbox' : ''}
                disabled={isDisabled}
                data-testid={'rsvp-checkbox'}
                style={{
                  marginTop: 0.5,
                  marginRight: 2,
                }}
                checked={appointmentBookingState.isRsvpEnabled}
                onChange={(e) => {
                  setAppointmentBookingState((prev) => {
                    return {
                      ...prev,
                      isRsvpEnabled: e.target.checked,
                    };
                  });
                }}
                value={'IsRsvpEnabled'}
              />
              <VStack>
                <DisplayText
                  textLocalId={'requestResponse'}
                  extraStyles={{
                    color: Colors.FoldPixel.GRAY400,
                    fontWeight: 500,
                    fontSize: 14,
                  }}
                />
                <Text fontSize="xs" color={Colors.FoldPixel.GRAY250}>
                  {`(${isGroupAppointmentEnable ? `Members` : `Member`} will be asked to accept or decline this appointment)`}
                </Text>
              </VStack>
            </HStack>

            {props?.isGroupAppointmentEnable && !appointmentBookingState.slotLoading && appointmentBookingState.isRsvpEnabled  ? (
              <HStack
                key={'setExpiryForRsvp'}
                flex={1}
                space={2}
                mt={2}
                alignItems={'flex-start'}
              >
                <Checkbox
                  style={{
                    marginTop: 2,
                    marginRight: 2,
                  }}
                  disabled={appointmentBookingState.endDateTime ? false : true}
                  checked={appointmentBookingState.isRsvpExpiryEnabled}
                  onChange={(e) => {
                    setAppointmentBookingState((prev) => {
                      const rsvpExpiryDateTime = getDefaultRSVPExpiryDateTime(
                        appointmentBookingState?.endDateTime,
                      );
                      const formattedString = rsvpExpiryDateTime.format(
                        RSVP_EXPIRY_DATE_FORMAT
                      );
                      return {
                        ...prev,
                        isRsvpExpiryEnabled: e.target.checked,
                        rsvpExpiryDateTime: e.target.checked
                          ? formattedString
                          : '',
                      };
                    });
                  }}
                  value={'setExpiryForRsvp'}
                />
                <VStack flex={1} mt={1}>
                  <DisplayText
                    textLocalId={'setExpiryForRsvp'}
                    extraStyles={{
                      color: Colors.Custom.Gray700,
                      fontWeight: 500,
                      fontSize: 14,
                    }}
                  />
                </VStack>

                {appointmentBookingState.isRsvpExpiryEnabled ? (
                  <HStack
                    flex={1}
                    justifyContent={'center'}

                  >
                    <Text fontSize="xs" color="gray.500" mt={2}>
                      {'Within'}
                    </Text>
                    {renderRsvpExpiryDateElm()}
                  </HStack>
                ) : (
                  <View height={44} />
                )}
              </HStack>
            ) : (
              <></>
            )}
          </View>
        </FormControl>
      </Stack>
    );
  };

  const renderPatientInstruction = (): JSX.Element => {
    return (
      <Stack space={2} direction={['column', 'column', 'row']} flex={1}>
        <FormControl>
          <FormControl.Label ml={0}>
            <DisplayText
              size={'smRegular'}
              extraStyles={{
                color: Colors.FoldPixel.GRAY250,
                fontWeight: 500,
                fontSize: 14,
              }}
              textLocalId={'appointmentInstruction'}
            />
          </FormControl.Label>
          <VStack>
          <ReactQuill
            className="appointment-notes"
            theme="snow"
            value={appointmentBookingState?.patientInstruction?.content}
            onChange={(value: string) => {
              setAppointmentBookingState((prev) => {
                const note = prev.patientInstruction || {} as IAppointmentNote;
                note.content = value;
                return {
                  ...prev,
                  patientInstruction: note,
                };
              });
            }}
            modules={RTE_MODULES}
            placeholder={intl.formatMessage({
              id: 'enterPatientInstructionsHere',
            })}
            data-testid={placeholder}

          />
          </VStack>
        </FormControl>
      </Stack>
    );
  };



  const renderRestrictedUserAppointmentTypes = () => {
    if(restrictedUserAppointmentTypes.list.length > 0){
      return (
        <VStack mt={2}>
          <HStack alignItems="center">
            <Feather
              name="info"
              color={Colors.Custom.Gray500}
              size={14}
              style={{
                marginRight: 4,
              }}
            />
            <Text color={Colors.Custom.Gray500}>
             {intl.formatMessage({id:'providerBlockedWithAnotherType'})}
            </Text>
            <Spacer />
            <Text
              fontWeight={'bold'}
              color={Colors.Custom.mainPrimaryPurple}
              onPress={() =>
                setRestrictedUserAppointmentTypes((prev) => ({
                  ...prev,
                  show: !prev.show,
                }))
              }
            >
              {`${
                restrictedUserAppointmentTypes.show ? 'Hide' : 'Show'
              } `}
            </Text>
          </HStack>
          {restrictedUserAppointmentTypes.show && (
            <VStack>
              {restrictedUserAppointmentTypes.list.map((appt) => (
                <HStack ml={5} alignItems={'center'} key={appt.id}>
                  <Feather
                    name="calendar"
                    color={Colors.Custom.Gray500}
                    size={12}
                    style={{
                      marginRight: 4,
                    }}
                  />
                  <Text color={Colors.Custom.Gray500}>{appt.eventName}</Text>
                </HStack>
              ))}
            </VStack>
          )}
        </VStack>
      );
    }
    return <></>
  }

  const renderSlotElm = (): JSX.Element => {
    const isVirtualVisit = isVirtualAppointmentType(appointmentBookingState.selectedAppointmentType, appointmentBookingState.selectedLocationType);
    const isVirtualLocation = isVirtualVisit && !disAllowVirtualLocation && !disallowToScheduleForOtherLocation;
    if (
      appointmentBookingState.selectedAppointmentType &&
      (
        appointmentBookingState.selectedAppointmentType?.appointmentTypeGroup?.length > 0 ||
        appointmentBookingState.selectedAppointmentType.availabilityTypeCode === AppointmentAvailabilityCode.PROVIDER
      ) &&
      !appointmentBookingState.isLocationLoading &&
      (appointmentBookingState.selectedUserLocations?.length > 0 ||
        (isVirtualLocation &&
          disallowToScheduleForOtherLocation &&
          appointmentBookingState.selectedUserLocations?.length > 0) ||
        (isVirtualLocation &&
          !disallowToScheduleForOtherLocation))
    ) {
      // const hasAvailability = getAvailabilityOfSelectedSlot();
      return (
        <Stack flex={1} marginY={2}>
          <FormControl isInvalid={appointmentBookingState.errors.slots}>
            <HStack flex={1} space={2} alignItems={'center'}>
              <HStack>
                  <DisplayText
                    size={'smMedium'}
                    extraStyles={{
                      fontWeight: 500,
                      fontSize: 16,
                    }}
                    textLocalId={appointmentBookingState.isSpecificTimeView ? 'specificTime' : 'timeSlots'}
                  />
                <Text color="error.500">*</Text>
              </HStack>
              <HStack flex={4} space={1} alignItems={'center'} justifyContent={'flex-end'}>
                <Pressable
                  onPress={handleSpecificTimeAndSlotClick}
                >
                  <HStack alignItems={'center'} space={1}>
                    <Feather name="clock" color={Colors.Custom.mainPrimaryPurple} size={12} />
                    <Text color={Colors.Custom.mainPrimaryPurple} fontSize={12}>
                      {appointmentBookingState.isSpecificTimeView ? 'See time slots' : 'Pick a specific time'}
                    </Text>
                  </HStack>
                </Pressable>
              </HStack>
            </HStack>
            <HStack marginTop={2} space={2} alignItems={'center'}>
              <Feather name="alert-circle" size={16} color={Colors.Custom.Gray500}  />
              {/* {selectedLocationName && (
                <Text
                  fontSize="xs"
                  color="gray.500"
                  flexShrink={1}
                >
                  {appointmentBookingState.isSpecificTimeView
                    ? ''
                    : `Showing slots for ${selectedLocationName}`}
                </Text>
              )} */}
            </HStack>
            {!appointmentBookingState.isSpecificTimeView && appointmentBookingState.selectedSecondaryUserIds.length > 0 && (
              <HStack alignItems={'center'} space={2} my={2}>
                <Feather name="alert-circle" size={16} color={Colors.Custom.Gray500} style={{marginTop: 2}} />
                <Text fontSize={12} color={Colors.Custom.Gray500} width="full">
                  {'Showing time slots as per availability of all selected providers'}
                </Text>
              </HStack>
            )}
            {!appointmentBookingState.isSpecificTimeView && (
            <View flex={1} px={0} flexDirection="row" flexWrap="wrap">
              {appointmentBookingState.slotLoading && (
                <View {...testID(TestIdentifiers.lazyLoading)} width="full">
                  <Skeleton active />
                </View>
              )}
              {!appointmentBookingState.slotLoading &&
                !appointmentBookingState.hideSlots &&
                appointmentBookingState.slots &&
                // appointmentBookingState.selectedSlot &&
                appointmentBookingState.slots.length > 0 && (
                  <SlotsGroup
                    selectedSlotDate={appointmentBookingState.selectedDate}
                    selectedEndDate={appointmentBookingState?.selectedEndDate}
                    selectedSlot={appointmentBookingState.selectedSlot}
                    selectedTimezone={getSelectedTimezone()}
                    userSlots={appointmentBookingState.slots}
                    handleSlotSelection={handleSlotSelection}
                  />
                )}
              {/* {(!appointmentBookingState.slotLoading && !appointmentBookingState.slots.length) && renderTimeSlotErrors()} */}

            </View>
            )}
            {appointmentBookingState.isSpecificTimeView && (
              <VStack space={2}>
                <ModalActionTimePicker
                  label={''}
                  customStyle={{marginTop: 8}}
                  isHideOkButton={true}
                  value={appointmentBookingState.selectedSlot?.startDateTime ?
                    getMomentObj(appointmentBookingState.selectedSlot?.startDateTime).tz(getSelectedTimezone()) :
                    undefined
                  }
                  format={DATE_FORMATS.TIME_SELECT_FORMAT}
                  onSelect={(date: any) => {
                    updateSlotDateAndTimeForSpecificTime(
                      appointmentBookingState.selectedDate,
                      appointmentBookingState.duration,
                      {
                        startDateTime: date.tz(getSelectedTimezone()).toISOString(),
                        endDateTime: date.add(appointmentBookingState.duration, 'minutes').tz(getSelectedTimezone()).toISOString()
                      }
                    );
                  }}
                 />
                 {appointmentBookingState.slotLoading && (
                  <HStack space={2} alignItems={'center'}>
                    <Spinner size={'sm'} color={Colors.Custom.Gray500} />
                    <Text fontSize={12} color={Colors.Custom.Gray500} width="full">
                      {intl.formatMessage({ id: 'checkingSlotAvailabilityMsg' })}
                    </Text>
                  </HStack>
                 )}
                {/* {!appointmentBookingState.slotLoading &&
                  !hasAvailability && (
                  <HStack alignItems={'flex-start'} space={2}>
                    <Feather name="info" size={16} color={Colors.Custom.Gray500} style={{marginTop: 2}} />
                    <Text fontSize={12} color={Colors.Custom.Gray500} width="full">
                      {intl.formatMessage({ id: 'specificTimeOverlapMsg' })}
                    </Text>
                  </HStack>
                )} */}
              </VStack>
            )}
            {!appointmentBookingState.slotLoading &&
              appointmentBookingState.errors.slots && (
                <Text fontSize="xs" color="error.500">
                  {appointmentBookingState.errors.slots}
                </Text>
              )}
          </FormControl>
        </Stack>
      );
    }
    return <></>;
  };


  const renderWorkflowElm = (): JSX.Element => {
    if (
      appointmentBookingState?.primaryContactId &&
      appointmentBookingState?.primaryContactId.length
    ) {
      return (
        <FoldPermitCan
        resource={MAIN_MENU_CODES.AUTOMATION}
        action={USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code}
        component={<Stack flex={1}>
        <WorkFlowListByEntityEvents
          workflowlist={appointmentBookingState?.workflowList}
          isScheduledBack={isScheduleBack}
          contactIds={appointmentBookingState?.primaryContactId}
          addUrl={CUSTOM_AUTOMATION_URL.SCHEDULE_APPOINTMENT}
          title={ENTITY_EVENTS_TITLE_SUBTITLE.SCHEDULE_APPOINTMENT_TITLE}
          subtitle={
            ENTITY_EVENTS_TITLE_SUBTITLE.SCHEDULE_APPOINTMENT_SUB_TITLE
          }
          entityEventList={ENTITY_EVENTS_TYPE.APPOINTMENT_BOOKING}
          onValueChanage={(eventEntityList: any) => {
            setAppointmentBookingState((prev) => ({
              ...prev,
              workflowList: eventEntityList,
            }));
          }}
        ></WorkFlowListByEntityEvents>
      </Stack>}
      />
      );
    }
    return <></>;
  };

  const renderModeOfAppointmentAndDuration = (): JSX.Element => {
    return (
      <Stack direction={['column', 'column', 'row']} space={2} flex={1}>
        {renderLocationTypeElm()}
        {renderDurationElm()}
      </Stack>
    );
  };


  const renderReccuringAppointment = (): JSX.Element => {
    return (
      <ReccuringEventView
        editType={props?.editType}
        allowedToEdit={allowToEditReccurenceConfig && !props?.disableAppointmentReccurence}
        allowedToToggle={allowToToggleReccurence && !props?.disableAppointmentReccurence}
        type={ReccuringEventType.APPOINTMENT}
        isEnabled={isReccuring}
        onToggle={(value:boolean) => {
          setIsReccuring(value);
        }}
        config={recurringAppointmentConfig}
      />
    );
  }

  const renderReasonForVisitElm = (): JSX.Element => {
    return (
      <Stack>
        <ReasonForVisitFreeText
          isShowError={!!appointmentBookingState?.errors?.reason}
          showLabel
          customLabel={intl.formatMessage({ id: 'reasonForVisit' })}
          customPlaceHolder='Enter Reason for Visit'
          value={appointmentBookingState.reasonForVisit?.displayName}
          onChange={(reasonForVisit) => {
            setAppointmentBookingState((prev) => ({
              ...prev,
              reasonForVisit,
            }));
          }}
          numberOfLines={1}
          isRequired
          errorMessage={appointmentBookingState?.errors?.reason}
        />
      </Stack>
    );
  };


  const renderVisitType = (): JSX.Element => {
    let visitTypeId =
      appointmentBookingState.selectedAppointmentType?.visitTypeId;
    if (!visitTypeId) {
      const defaultVisitType = (visitTypeList || []).find((visitType) => {
        return visitType?.code == APPOINTMENT_TYPE_VISIT_TYPE.ROUTINE;
      });
      visitTypeId = defaultVisitType?.id;
    }
    const displayValue = visitTypeList.find(
      (item) => item.id === visitTypeId
    )?.value;
    if (displayValue) {
      return (
        <Text marginTop={0.5} fontSize="xs" color="gray.500">
          {intl.formatMessage({
            id: 'visitType',
          })}{`: ${displayValue}`}
        </Text>
      );
    }
    return <></>
  }

  const onClickOfBackButton = () => {
    props.onCancel();
    resetContent();
  }

  const backBtn = () => ({
    show:
      selectedViewCode ===
      APPOINTMENT_BOOKING_VIEW_CODES.SUMMARY_VIEW
        ? false
        : true,
    id: 1,
    btnText: isSideCarContext ? 'back' : 'cancel',
    size: 'sm',
    textColor: Colors.Custom.mainSecondaryBrown,
    variant: BUTTON_TYPE.SECONDARY,
    isTransBtn: false,
    onClick: () => onClickOfBackButton(),
  });

  const onSettingPress = (path: string) => {
    const settingsPath =  path;
    if (isNewSettingTabEnabled) {
      navigateToNewSettingTab(settingsPath);
    } else {
      props.onCancel();
      navigateToScreen(settingsPath);
    }
  }

  const getPrimaryUserByLocationId = (
    params :{
      prevSelectedPrimaryUserId: string | null;
      selectedAccountLocationId :string;
      availablePrimaryUsers: IUser[];
    }
  ): string | null => {
    const { selectedAccountLocationId, availablePrimaryUsers, prevSelectedPrimaryUserId } = params;
    let primaryUserId = prevSelectedPrimaryUserId;
    const primaryUserList = formatToAppointmentDetailUser(availablePrimaryUsers || []);
    if (selectedAccountLocationId && primaryUserList?.length > 0) {
      // check primary user location match with selected location
      if (prevSelectedPrimaryUserId) {
        const findPrimaryUser = primaryUserList.find(user => user.uuid === prevSelectedPrimaryUserId);
        if (!findPrimaryUser?.uuid) {
          primaryUserId = null;
        } else {
         const isLocationMatch = (findPrimaryUser.userPracticeLocations || []).some(userPracticeLocation => {
           return userPracticeLocation?.accountLocation?.uuid === selectedAccountLocationId;
         });
         if (!isLocationMatch) {
          primaryUserId = null;
         }
        }
      }
      // if previous primary user not exist, then check other  user from userlist location match with selected location
      if(!primaryUserId) {
        const matchUserWithSelectedLocation =  primaryUserList.find(primaryUser => { 
          return (primaryUser.userPracticeLocations || []).some(userPracticeLocation => {
            return userPracticeLocation?.accountLocation?.uuid === selectedAccountLocationId;
          });
        });
        if (matchUserWithSelectedLocation?.uuid) {
          primaryUserId = matchUserWithSelectedLocation.uuid;
        }
      }
    }
    return primaryUserId;
  }

  return (
    <>
      <Drawer
        className='appointment-booking-drawer'
        headerStyle={{borderWidth: 0, marginBottom: 0}}
        destroyOnClose
        placement="right"
        onClose={() => {
          if (
            selectedViewCode === APPOINTMENT_BOOKING_VIEW_CODES.SUMMARY_VIEW
          ) {
            props.onComplete();
          } else {
            props.onCancel();
          }
          resetContent();
        }}
        visible={appointmentBookingState.isVisible}
        closable
        width={
          isSideCarContext
            ? '100%'
            : isIPadScreen || isIPadMiniScreen
            ? '70%'
            : '42%'
        }
        mask={isSideCarContext ? false : true}
        title={
          <VStack>
            <ModalActionTitle
              title={
                isRescheduleWorkflow() ? 'Edit Appointment' : getHeaderTitle()
              }
              titleColor={''}
              titleSize={24}
              leftBackButton={
                isSideCarContext ? (
                  <AntdButton
                    onClick={() => onClickOfBackButton()}
                    type="text"
                    icon={<LeftOutlined />}
                  />
                ) : undefined
              }
              buttonList={[
                {...backBtn(), show: !isSideCarContext},
                {
                  isDisabled:
                    submitting ||
                    appointmentBookingState.isBookedAppointmentLoading,
                  isLoading:
                    submitting ||
                    appointmentBookingState.isBookedAppointmentLoading,
                  show:
                    selectedViewCode ===
                    APPOINTMENT_BOOKING_VIEW_CODES.SUMMARY_VIEW
                      ? false
                      : true,
                  id: 3,
                  btnText:
                    selectedViewCode ===
                    APPOINTMENT_BOOKING_VIEW_CODES.SUMMARY_VIEW
                      ? 'done'
                      : getScheduleBtnText(),
                  size: 'sm',
                  textColor: Colors.Custom.mainPrimaryPurple,
                  variant: BUTTON_TYPE.PRIMARY,
                  isTransBtn: false,
                  onClick: () => {
                    if (
                      submitting ||
                      appointmentBookingState.isBookedAppointmentLoading
                    ) {
                      return;
                    }
                    if (
                      selectedViewCode ===
                      APPOINTMENT_BOOKING_VIEW_CODES.SUMMARY_VIEW
                    ) {
                      props.onComplete();
                      resetContent();
                      return;
                    }
                    submitData();
                  },
                },
                {
                  show:
                    selectedViewCode ===
                    APPOINTMENT_BOOKING_VIEW_CODES.SUMMARY_VIEW
                      ? true
                      : false,
                  id: 4,
                  btnText: 'done',
                  size: 'sm',
                  variant: BUTTON_TYPE.PRIMARY,
                  isTransBtn: false,
                  onClick: () => {
                    props.onComplete(currentAppointmentId);
                  },
                },
              ]}
            />
            {getSubtitle().length > 0 && (
              <Text color={Colors.Custom.Gray500}>
                <Icon
                  mr={2}
                  as={AntIcon}
                  color={Colors.Custom.Gray500}
                  name="infocirlceo"
                  size="smMedium"
                />
                {getSubtitle()}
              </Text>
            )}
          </VStack>
        }
      >
        {/* MARK: Main view */}
        {showError && showErrorToast()}
        {!componentLoading &&
          selectedViewCode === APPOINTMENT_BOOKING_VIEW_CODES.BOOKING_VIEW && (
            <VStack px={0}>
              <AppointmentDataProvider
                value={{
                  appointmentBookingState: appointmentBookingState,
                  accountUserList: accountUserList,
                  slotRange: slotRange,
                  rescheduleData: props.rescheduleData,
                  handleChangeAppointmentbookingState: (
                    state
                  ) => {
                    setAppointmentBookingState(prev => ({
                      ...prev,
                      ...state,
                    }));
                  },
                  handleRestrictedUserDataChange: (data) =>
                    setRestrictedUserAppointmentTypes(data),
                  updateSelectableLocations,
                  isRescheduleWorkflow,
                  handleSlotSelection,
                  handleAppointmentDateChange,
                  handleSpecificTimeAndSlotClick,
                  updateSlotDateAndTimeForSpecificTime,
                  renderPrimaryUser,
                  renderSecondaryUsers,
                  getSelectedLocationName: getSelectedLocationName
                }}
              >
                <VStack space={2} height={'100%'} overflow="scroll" flex={1}>
                  {props?.isGroupAppointmentEnable && renderEventName()}
                  {renderPatientOrProspectElem()}
                  {!props?.isGroupAppointmentEnable && renderReasonForVisitElm()}
                  {renderAppointmentTypeElem()}
                  {renderModeOfAppointmentAndDuration()}
                  {props?.isGroupAppointmentEnable &&
                    renderDescriptionVisitElm()}
                  {renderLocationAndVisitType()}
                  <SelectSlotBy timeZoneView={renderTimeSlotTimeZoneElm()} disableSelectSlotBy={props?.disableSelectSlotBy} />
                  {!appointmentBookingState.slotLoading &&
                    renderRestrictedUserAppointmentTypes()}
                  {renderReccuringAppointment()}
                  {renderRSVPElm()}
                  {renderPatientInstruction()}
                  <StaffInstructionView />
                  {renderWorkflowElm()}
                </VStack>
              </AppointmentDataProvider>
            </VStack>
          )}

        {openAddPatientModal && (
          <AddOrUpdateLead
            practiceLocationObj={allMemberState.practiceLocations}
            invocationCode={''}
            shouldInvokeCallback={true}
            onFormCompleteAction={(actionCode: string, actionData: any) => {
              /**
               * Adding new created patient to existing participants
               */

              if (actionData) {
                const newParticipants: IParticipantSearch[] = [
                  ...appointmentBookingState.participants,
                ];
                /**
                 * Check if there is any patient added in the list if yes then replace it
                 */
                const checkPatientIndex = newParticipants.findIndex(
                  (newParticipantItem: IParticipantSearch) =>
                    newParticipantItem.type === ParticipantType.patient
                );
                if (checkPatientIndex >= 0) {
                  newParticipants.splice(checkPatientIndex, 1);
                }
                newParticipants.push({
                  key: actionData?.reference?.contactUuid,
                  value: actionData?.reference?.contactUuid,
                  label: actionData?.data?.name[0]?.text,
                  type: ParticipantType.patient,
                });
                setAppointmentBookingState((prev) => ({
                  ...prev,
                  primaryContactId: getContactId(newParticipants),
                  participants: newParticipants,
                }));
                updateSelectableLocations(
                  appointmentBookingState.selectedAppointmentType,
                  appointmentBookingState.selectedPrimaryUserId,
                  newParticipants,
                  appointmentBookingState.selectedLocationType,
                  {patient: true}
                );
              }

              setOpenAddPatientModal(false);
            }}
            personType={allMemberState.addContactType}
            personTypeUuid={allMemberState.addContactTypeUuid}
            // alert={alert}
            isEmployeeView={true}
          />
        )}

        {/* {selectedViewCode === APPOINTMENT_BOOKING_VIEW_CODES.PREVIEW_VIEW && (
          <>
            {templateData?.templateHtml && (
              <VStack maxWidth={emailPreviewWidth}>
                {templateData?.templateHtml &&
                  parse(getPreviewHtml(templateData, appointmentBookingState))}
              </VStack>
            )}
          </>
        )} */}

        {componentLoading &&
          selectedViewCode === APPOINTMENT_BOOKING_VIEW_CODES.BOOKING_VIEW && (
            <View height={654} {...testID(TestIdentifiers.lazyLoading)}>
              <Skeleton active />
            </View>
          )}
        {!componentLoading &&
          selectedViewCode === APPOINTMENT_BOOKING_VIEW_CODES.BOOKING_VIEW &&
          appointmentBookingState.primaryContactId &&
          !appointmentBookingState?.allAppointmentTypeList?.length && (
            <Center
              height={654}
              width="full"
              justifyContent="center"
              alignItems="center"
            >
              <Text fontWeight={200} fontSize="lg" textAlign="center">
                Looks like you have't created any appointment types.
                <Text>
                  {'\n'}Please click{' '}
                  <Pressable
                    onPress={() => {
                      onSettingPress('/admin/schedule/types');
                    }}
                  >
                    <Text color="primary.500">here</Text>
                  </Pressable>{' '}
                  to create your first appointment type and then come back
                  again.
                </Text>
              </Text>
            </Center>
          )}
        {!componentLoading &&
          selectedViewCode === APPOINTMENT_BOOKING_VIEW_CODES.BOOKING_VIEW &&
          !appointmentBookingState?.allAppointmentTypeList?.length &&
          appointmentBookingState.primaryContactId
          && (
            <Center
              height={654}
              width="full"
              justifyContent="center"
              alignItems="center"
            >
              <Text fontWeight={200} fontSize="lg" textAlign="center">
                Looks like you have't created any appointment types for
                available user roles.
                <Text>
                  {'\n'}Please click{' '}
                  <Pressable
                    onPress={() => onSettingPress('/admin/schedule/types')}
                  >
                    <Text color="primary.500">here</Text>
                  </Pressable>{' '}
                  to create appointment type and then come back again.
                </Text>
              </Text>
            </Center>
          )}
      </Drawer>
      {selectedViewCode === APPOINTMENT_BOOKING_VIEW_CODES.SUMMARY_VIEW && (
        <AppointmentDetail
          hideAction={true}
          isAppointmentSummary={true}
          reasonsForVisitList={[appointmentBookingState.reasonForVisit]}
          event={selectedEvents}
          isVisible={
            selectedViewCode === APPOINTMENT_BOOKING_VIEW_CODES.SUMMARY_VIEW
          }
          onStatusChange={() => {
            //
          }}
          onClose={() => {
            props.onCancel();
            resetContent();
          }}
          onComplete={props.onComplete}
          appointmentTypeList={appointmentBookingState.appointmentTypeList}
          drawerWidth={isSideCarContext ? '100%' : undefined}
        />
      )}
      <Modal
        key={`${confirmUpdateGroupAppointment}`}
        open={confirmUpdateGroupAppointment}
        footer={null}
        closable
        closeIcon={<></>}
      >
        <VStack>
          <Text fontWeight={'bold'} fontSize={18}>
            Update appointment
          </Text>
          <Text>{`This appointment update will be applicable to all ${accpetedInvites?.length} confirmed participant(s). Are you sure you want to continue?`}</Text>
          <HStack space={2} mt={4}>
            <Spacer />
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                onPress: () => setConfirmUpdateGroupAppointment(false),
              }}
              customProps={{
                btnText: (
                  <DisplayText
                    textLocalId={'dontUpdate'}
                    size={'smBold'}
                    extraStyles={{
                      color: Colors.Custom.mainSecondaryBrown,
                    }}
                  />
                ),
                withRightBorder: false,
              }}
            ></FoldButton>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  // we never allow recurring appointment with group appointment so passing recurring data and {}
                  submitData(true);
                },
              }}
              customProps={{
                btnText: (
                  <DisplayText
                    textLocalId={'update'}
                    size={'smBold'}
                    extraStyles={{
                      color: Colors.Custom.mainPrimaryPurple,
                    }}
                  />
                ),
                withRightBorder: false,
              }}
            ></FoldButton>
          </HStack>
        </VStack>
      </Modal>
    </>
  );
};

export default AppointmentBooking;
