import {Menu, Popover} from 'antd';
import Picker from 'emoji-picker-react';
import {
  FormControl,
  HStack,
  IconButton,
  Text,
  TextArea,
  Tooltip,
  VStack,
  View,
} from 'native-base';
import {useState} from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import {Colors} from '../../../../../styles';
import {getAccountMergeTagData} from '../../ContentManagementUtils';
import {AddOrUpdateSMSTemplatesBodyProps} from '../interfaces';
import {SMSPreview} from '../SMSPreview';
import {
  getMenuItems,
  getParseBody,
  getSelectedMergeTag,
  insertAtIndex,
} from '../SMSTemplatesUtils';
import {styles} from './AddOrUpdateSMSTemplatesStyles';
import {SMS_BODY_MAX_LENGTH} from '../../ContentManagementConsts';
import {useIntl} from 'react-intl';

const {ErrorMessage, Label, HelperText} = FormControl;

export enum SMSBodyViewCode {
  Edit = 'Edit',
  Preview = 'Preview',
}

const AddOrUpdateSMSTemplatesBody = (
  props: AddOrUpdateSMSTemplatesBodyProps
) => {
  const {
    smsTemplateData,
    errors,
    setSmsTemplateData,
    isHideLabel,
    isInputDisabled,
    useString,
    onChangeBody,
  } = props;
  const [cursorPosition, setCursorPosition] = useState(0);
  const intl = useIntl();

  const [state, setState] = useState({
    viewCode: SMSBodyViewCode.Edit,
  });

  const handleViewCodeChange = () => {
    if (state.viewCode === SMSBodyViewCode.Edit) {
      setState((prev) => {
        return {
          ...prev,
          viewCode: SMSBodyViewCode.Preview,
        };
      });
    } else {
      setState((prev) => {
        return {
          ...prev,
          viewCode: SMSBodyViewCode.Edit,
        };
      });
    }
  };

  const accountMergeTags = getAccountMergeTagData({hideLogo: true});

  const getMergeTags = () => {
    if (smsTemplateData?.category) {
      return {
        ...smsTemplateData?.category?.mergeTags,
        global: accountMergeTags,
      };
    }
    return {
      global: accountMergeTags,
    };
  };

  const insertMergeTags = (mergeTagString: string, index: number) => {
    const body = smsTemplateData?.body || '';
    const insertedBody = insertAtIndex(body, index, mergeTagString);
    if (insertedBody.length > SMS_BODY_MAX_LENGTH) {
      return;
    }
    if (useString && onChangeBody) {
      onChangeBody(insertedBody);
    } else {
      setSmsTemplateData((prev: any) => {
        return {
          ...prev,
          body: insertedBody,
        };
      });
    }
  };

  const onMenuItemSelected = (info: any) => {
    const mergeTagString = getSelectedMergeTag(info.keyPath);
    insertMergeTags(mergeTagString, cursorPosition);
  };

  const popoverContent = () => {
    return (
      <Menu
        items={getMenuItems(getMergeTags())}
        selectable={false}
        onClick={(info) => {
          onMenuItemSelected(info);
        }}
      />
    );
  };

  const emojiPopoverContent = () => {
    return (
      <Picker
        onEmojiClick={(event, data) => {
          insertMergeTags(data.emoji, cursorPosition);
        }}
      />
    );
  };

  return (
    <View mt={0} mx={0} flex={1}>
      <FormControl
        style={styles.formContainer}
        isRequired
        isInvalid={errors?.body}
      >
        <HStack style={styles.mergeTagPopoverView}>
          {!isHideLabel && <Label>Body</Label>}
          <Popover content={popoverContent} trigger={'hover'}>
            <IconButton
              _hover={{
                backgroundColor: Colors.primary['100'],
              }}
              backgroundColor={Colors.primary['100']}
              style={{
                marginBottom: 5,
                marginLeft: 4,
              }}
              icon={
                <MaterialCommunityIcon
                  name="code-json"
                  size={16}
                  color="black"
                />
              }
            />
          </Popover>
          <Popover content={emojiPopoverContent} trigger={'hover'} style={{}}>
            <IconButton
              _hover={{
                backgroundColor: Colors.primary['100'],
              }}
              style={{
                marginBottom: 5,
                marginLeft: 4,
              }}
              backgroundColor={Colors.primary['100']}
              icon={<AntIcon name="smileo" size={16} color="black" />}
            />
          </Popover>
          <Tooltip
            label={state.viewCode === SMSBodyViewCode.Edit ? 'Preview' : 'Edit'}
            placement="top"
          >
            <IconButton
              _hover={{
                backgroundColor: Colors.primary['100'],
              }}
              style={{
                marginBottom: 5,
                marginLeft: 4,
              }}
              backgroundColor={Colors.primary['100']}
              onPress={() => {
                handleViewCodeChange();
              }}
              icon={<AntIcon name="laptop" size={16} color={'black'} />}
            />
          </Tooltip>
        </HStack>

        {state.viewCode === SMSBodyViewCode.Edit && (
          <VStack>
            <TextArea
              placeholder='Enter SMS Body'
              placeholderTextColor={props.isAutomationView ? Colors.FoldPixel.GRAY250 : undefined}
              backgroundColor={props.isAutomationView ? 'white' : undefined}
              borderWidth={props.isAutomationView ? 0.5 : 1}
              isDisabled={isInputDisabled || false}
              size={'md'}
              flex={1}
              maxLength={SMS_BODY_MAX_LENGTH}
              onSelectionChange={(e) => {
                setCursorPosition(e.nativeEvent.selection.start);
              }}
              value={smsTemplateData?.body || ''}
              onChangeText={(body) => {
                if (body?.trim().length == 0) {
                  setSmsTemplateData((prev: any) => {
                    return {
                      ...prev,
                      body: undefined,
                    };
                  });
                } else {
                  if (useString && onChangeBody) {
                    onChangeBody(body);
                  } else {
                    setSmsTemplateData((prev: any) => {
                      return {
                        ...prev,
                        body: body,
                        template: {
                          ...prev?.template,
                          id: null
                        },
                        smsBodyUpdatedAt : new Date()
                      };
                    });
                  }
                }
              }}
            />
            {errors?.body && (
              <ErrorMessage
                _text={{
                  fontSize: 'xs',
                  color: 'error.500',
                  fontWeight: 500,
                }}
              >
                {errors?.body}
              </ErrorMessage>
            )}
            <HelperText
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
              }}
            >
              <Text
                style={{
                  color: Colors.muted[500],
                  fontSize: 12,
                  fontWeight: '400',
                  lineHeight: 18,
                }}
              >
                {intl.formatMessage({id: 'smsLengthInfoText'})}
              </Text>
              <Text
                style={{
                  color: Colors.muted[500],
                  fontSize: 12,
                  fontWeight: '400',
                  lineHeight: 18,
                }}
              >
                {`${
                  (smsTemplateData?.body || '').length
                }/${SMS_BODY_MAX_LENGTH}`}
              </Text>
            </HelperText>
          </VStack>
        )}
      </FormControl>

      {state.viewCode === SMSBodyViewCode.Preview && (
        <SMSPreview
          bodyText={
            getParseBody({
              bodyString: smsTemplateData?.body || '',
              mergeTags: getMergeTags(),
            }) || 'Body'
          }
        />
      )}
    </View>
  );
};

export default AddOrUpdateSMSTemplatesBody;
