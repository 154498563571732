import React from 'react';
import {Path, Svg} from 'react-native-svg';
import {ICommonSvgProps} from './interfaces';

const DeclinedCircleSvg = (props: ICommonSvgProps) => {
  return (
    <Svg width="12" height="12" fill="none" viewBox="0 0 12 12">
      <Path fill="#D92D20" d="M0 6a6 6 0 1 1 12 0A6 6 0 1 1 0 6"></Path>
      <Path
        fill="#fff"
        d="M8.236 4.236a.333.333 0 1 0-.472-.472zM3.764 7.764a.333.333 0 0 0 .472.472zm.472-4a.333.333 0 1 0-.472.472zm3.528 4.472a.333.333 0 0 0 .472-.472zm0-4.472-2 2 .472.472 2-2zm-2 2-2 2 .472.472 2-2zm-2-1.528 2 2 .472-.472-2-2zm2 2 2 2 .472-.472-2-2z"
      ></Path>
    </Svg>
  );
};

export default React.memo(DeclinedCircleSvg);
