import { CARE_PROGRAM_STEP_STATUS } from '../../../../../constants/MlovConst';
import {CARE_PROGRAM_STEP_TYPE} from '../../../../common/MemebersView/constant';
import {IContactCareProgramStep, IMlov} from '../../interface';
import AssessmentView from './components/AssessmentView';
import CPBillingView from './components/BillingView/CPBillingView';
import DischargeRefferals from './components/DischargeRefferals';
import OutreachView from './components/OutreachView';

export const getContentView = (params: {
  selectedStep: IContactCareProgramStep;
}): React.FC => {
  const {selectedStep} = params;
  const viewCode = selectedStep?.careProgramStepType?.code;
  let ContentView: React.FC;

  switch (viewCode) {
    case CARE_PROGRAM_STEP_TYPE.ASSESSMENT:
    case CARE_PROGRAM_STEP_TYPE.USER_ASSESSMENT:
    case CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_14DAY:
    case CARE_PROGRAM_STEP_TYPE.ANNUAL_VISIT:
    case CARE_PROGRAM_STEP_TYPE.APPOINTMENT:
      ContentView = AssessmentView;
      break;
    case CARE_PROGRAM_STEP_TYPE.BILLING:
      ContentView = CPBillingView;
      break;
    case CARE_PROGRAM_STEP_TYPE.REFERRAL:
      ContentView = DischargeRefferals;
      break;
    case CARE_PROGRAM_STEP_TYPE.ECM_OUTREACH:
    case CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_2DAY:
    case CARE_PROGRAM_STEP_TYPE.OUTREACH:
      ContentView = OutreachView;
      break;
    case CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_14DAY:
    case CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_2DAY:
      ContentView = OutreachView;
      break;
    default:
      ContentView = () => <div>No View Found</div>;
  }
  return ContentView;
};
