import {
  Box,
  Button,
  Center,
  Divider,
  HStack,
  Input,
  Pressable,
  ScrollView,
  Spacer,
  Spinner,
  Text,
  useMediaQuery,
  View,
  VStack,
} from 'native-base';
import React, { useContext, useEffect, useState, useMemo } from 'react';
import Feather from 'react-native-vector-icons/Feather';
import { Colors } from '../../../../../../styles';
import { getTitle } from '../AddOrUpdateTemplate/AddOrUpdateTemplateHelper';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { notification, Popover, Tooltip } from 'antd';
import PinnedIcon from '../../../../../../assets/Icons/PinnedIcon';
import PinIcon from '../../../../../../assets/Icons/PinIcon';
import { getCurrentEHR } from '../../PatientNotesHelper';
import {EHRName} from '../../interfaces';
import ModalActionBtn from '../../../../../common/ModalActionBtn/ModalActionBtn';
import {IFormListState} from '../DocumentationView';
import CreateForm from '../CreatePatientNote';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../../constants/Configs';
import FormsQueries, { GET_FORM_CATEGORIES, GET_NOTES_FORM, SEARCH_FORMS_WITH_LOCATION } from '../../../../../../services/Forms/FormsQueries';
import { IFormCategory } from '../../../../../RightSideContainer/Forms/interfaces';
import { getFormList } from '../DocumentationViewHelper';
import { getResourceAbilities } from '../../../../../../utils/capabilityUtils';
import { FHIR_RESOURCE } from '../../../../../../constants/FhirConstant';
import { getUserUUID, isMultiTenancyEnabled } from '../../../../../../utils/commonUtils';
import { getMlovIdFromCode, getMlovListFromCategory } from '../../../../../../utils/mlovUtils';
import { BUTTON_TYPE, MLOV_CATEGORY, SMALL_WINDOW, SMALL_WINDOW_1700 } from '../../../../../../constants';
import { USER_PREFERENCE_CODE } from '../../../../../../constants/MlovConst';
import { CommonDataContext } from '../../../../../../context/CommonDataContext';
import { useIntl } from 'react-intl';
import { clipText } from '../AddOrUpdateTemplate/helper';
import { DisplayText } from '../../../../../common/DisplayText/DisplayText';
import { FoldButton } from '../../../../../CommonComponents/FoldButton/FoldButton';
import { SearchBar } from '../../../../../common/SearchBar';
import { isWeb } from '../../../../../../utils/platformCheckUtils';
import { CustomHeaderBackArrow } from '../../../../../NativeComponents/CustomHeaderBackArrow';
import CustomSelect from '../../../../../common/CustomComponents/CustomSelect/CustomSelect';
import {
  getEhrConfig,
  isDefaultNoteAllowed,
} from '../../../CareTimeline/CareTimelineUtils';
import {debounce} from 'lodash';
import { testID } from '../../../../../../testUtils';
export const GLOBAL_INPUT_BORDER_RADIUS = 18;
import BackArrowSvg from '../../../../../common/Svg/SideCarSvg/BackArrowSvg';
import SelectDownArrowSvg from '../../../../../common/Svg/SelectDownArrowSvg';
import Stack from '../../../../../common/LayoutComponents/Stack';
import PinnedIconWithoutBadge from '../../../../../../assets/Icons/PinnedIconWithoutBadge';
import PinIconWithoutBadge from '../../../../../../assets/Icons/PinIconWithoutBadge';
import { StyleSheet } from 'react-native';
import { SM_CONFIG } from '../../../../../../theme/FontConfig/TextFontSizeConfig';

const styles = StyleSheet.create({
  templateItem: {
    alignItems: 'center',
    borderRadius: 4,
    paddingHorizontal: 8,
    paddingVertical: 8,
  },
  selectedTemplateItem: {
    backgroundColor: Colors.Custom.purpleBackgroundContainer,
    borderColor: Colors.Custom.Primary200,
    borderWidth: 0.5,
  },
  hoveredTemplateItem: {
    backgroundColor: Colors.FoldPixel.GRAY50,
  },
  templateItemText: {
    flex: 1,
    fontSize: 14,
  },
  selectedTemplateItemText: {
    color: Colors.Custom.Primary300,
  },
  unselectedTemplateItemText: {
    color: Colors.FoldPixel.GRAY400,
  },
  showMoreButton: {
    marginTop: 4,
    marginLeft: 8,
  },
  showMoreButtonText: {
    color: Colors.Custom.Primary300,
  },
  showMoreButtonText2: {
    color: Colors.Custom.PurpleColor
  },
  noTemplatesText: {
    color: Colors.Custom.Gray200,
    paddingVertical: 20,
    textAlign: 'center',
  },
  createNewTemplateButton: {
    paddingHorizontal: 16,
    paddingVertical: 12,
  },
  createNewTemplateText: {
    color: Colors.Custom.Primary300,
    fontSize: 14,
  },
  createNewTemplateIcon: {
    paddingRight: 16,
  },
  headerContainer: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.Custom.Gray200,
    width: '100%',
    paddingTop: 16,
    paddingBottom: 16,
    paddingHorizontal: 8,
    backgroundColor: Colors.Custom.Gray100,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  headerContainerSidecar: {
    paddingTop: 12,
    paddingBottom: 12,
    paddingHorizontal: 0,
    backgroundColor: Colors.Custom.White,
  },
  backButton: {
    backgroundColor: Colors.Custom.Gray100,
    height: 40,
    width: 40,
    borderRadius: 30,
    marginLeft: 10,
  },
  noteTitleInput: {
    marginLeft: 10,
    flex: 1,
  },
  noteTitleErrorText: {
    color: Colors.danger[600],
    fontWeight: '500',
    fontSize: 14,
  },
  createForm: {
    margin: -20,
  },
  templateSelectionButton: {
    borderRadius: 6,
  },
  headerContainer2: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
});

const antdStyles: Record<string, React.CSSProperties> = {
  popoverOverlay: {
    borderRadius: 8,
  },
};

const Header = (props: {
  title: JSX.Element;
  backButtonDisabled?: boolean;
  headerAction?: JSX.Element;
  form: {
    id: string;
    name: string;
  };
  showTemplateSelection: boolean;
  currentTemplateId?: string;
  defaultNote?: {
    id: string;
    formId: string;
  };
  showNoteTitleInput?: boolean;
  isTaskOrNoteLabelComponentExist?: boolean;
  showBackButton?: boolean;
  disableDefaultTemplateSelection?: boolean;
  backButtonAction: () => void;
  handleSelectedTemplateChange?: (templateId: string, templateName?: string, isFold?: boolean) => void;
  handleNewlyCreatedNote?: (noteId: string, templateName?: string) => void;
  handleDefaultNoteStateChange?: (templateId: string) => void;
  onNoteTitleChange?: (title: string) => void;
  navigation?: any;
  noteTitle?: string;
  showNoteTitleErrorText?: boolean
  locationList?: string[];
  locationId?: string;
  isNewView?: boolean;
}) => {
  const {
    title,
    backButtonDisabled,
    headerAction,
    form,
    showTemplateSelection,
    currentTemplateId,
    defaultNote,
    navigation,
    backButtonAction,
    handleSelectedTemplateChange,
    handleNewlyCreatedNote,
    handleDefaultNoteStateChange,
    showNoteTitleErrorText,
    locationId,
    isTaskOrNoteLabelComponentExist
  } = props;
  const intl = useIntl();
  const mlovData = useContext(CommonDataContext);
  const isEnabledMultiTenancy = isMultiTenancyEnabled(mlovData.userSettings);
  const [noteTitleValue, setNoteTitleValue] = useState(props?.noteTitle);
  const isSidecarContext = mlovData.sidecarContext?.isSidecar;
  const resourceAbilities = getResourceAbilities(
    FHIR_RESOURCE.DOCUMENT_REFERENCE,
    '',
    locationId
  );
  const elationFormData = {
    categories: resourceAbilities?.allowedCategories,
    noteComponents: resourceAbilities?.allowedNoteComponents,
  };
  const userPreferenceList = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.USER_PREFERENCE_TYPE
  );
  const userPreferenceId = getMlovIdFromCode(
    userPreferenceList,
    USER_PREFERENCE_CODE.DEFAULT_FORM
  );
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    { maxWidth: SMALL_WINDOW },
    { maxWidth: SMALL_WINDOW_1700 },
  ]);
  const isSmallScreen = isIPadMiniScreen || isIPadScreen;
  const foldVisitNoteWithEncountersEnabled = resourceAbilities?.foldVisitNoteEnabled || false
  const currentEHR = getCurrentEHR(locationId, '');
  const userUUID = getUserUUID();
  const isFold = currentEHR === EHRName.FOLD;
  const isElation = currentEHR === EHRName.ELATION;
  const isAthena = currentEHR === EHRName.ATHENA;
  const ehrConfig = getEhrConfig(currentEHR);
  const [createForm, setCreateForm] = useState(false);
  const [formList, setFormList] = useState<IFormListState[]>([]);
  const [elationFormList, setElationFormList] = useState<IFormListState[]>([]);
  const renderNewView = isSidecarContext || props?.isNewView;

  const MAX_NOTES_TEMPLATE_SHOW_COUNT = 5;
  const [componentState, setComponentState] = useState({
    foldNoteTemplateList: [...formList],
    ehrNoteTemplateList: [...elationFormList],
    showMoreFoldNotes: false,
    showMoreEhrNotes: false,
  });

  const [soapNoteCategoryId, setSoapNoteCategoryId] = useState('');
  const [isActionPopoverVisible, setIsActionPopoverVisible] = useState(false);
  const [loadingId, setLoadingId] = useState('');
  const [actionSheetVisible, setActionSheetVisible] = useState(false);
  const showActionSheet = () => setActionSheetVisible(true);
  const hideActionSheet = () => setActionSheetVisible(false);
  const [
    createOrUpdatePatientDefaultNote,
    { loading: updateDefaultFormLoading },
  ] = useMutation(FormsQueries.CREATE_USER_DEFAULT_NOTE_FORM, {
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });

  useEffect(() => {
    props?.onNoteTitleChange?.(props?.noteTitle || '');
    setNoteTitleValue(props?.noteTitle);
  }, [props?.noteTitle]);

  const [
    getAllNotesFrom,
    { loading: allNotesLoading, refetch: refetchAllNotes },
  ] = useLazyQuery(SEARCH_FORMS_WITH_LOCATION, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
    onCompleted: (data: any) => {
      const formData = data?.searchForms?.forms.filter(
        (item: any) => item.id === defaultNote?.formId
      );
      if (formData.length > 0) {
        formData.push(
          ...data?.searchForms?.forms.filter((item: any) => item.id !== defaultNote?.formId)
        );
        setFormList(formData);
      } else {
        setFormList(data?.searchForms?.forms);
      }
    },
  });
  const { loading: allCategoriesLoading } = useQuery(GET_FORM_CATEGORIES, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
    onError(error) {
    },
    onCompleted(data) {
      const noteCategoryId = data?.formCategories?.filter(
        (category: IFormCategory) => category.code === 'SOAP'
      )?.[0]?.id;
      setSoapNoteCategoryId(noteCategoryId);
      getAllNotesFrom({
        variables: {
          params: {
            categoryIds: noteCategoryId,
            name: '%%',
            offset: 0,
            // have kept this 100 as we are not using API search
            limit: 100,
            locationIds: isEnabledMultiTenancy ? (props.locationList || []) : [],
          }
        },
      });
    },
  });
  const showCreateFormModal = (show: boolean) => {
    setCreateForm(show);
  };
  const handleDefaultNoteChange = (noteId: string) => {
    setLoadingId(noteId);
    const data = {
      object: {
        userId: userUUID,
        userPreferencesTypeId: userPreferenceId,
        preferencesJson: JSON.stringify({ defaultForm: noteId }),
        id: defaultNote?.id ? defaultNote?.id : undefined,
      },
    };
    createOrUpdatePatientDefaultNote({
      variables: data,
      onCompleted: (data: any) => {
        if (data.createUserPreference.id) {
          handleDefaultNoteStateChange?.(noteId);
          setLoadingId('');
          notification.success({
            message: 'Default note updated successfully',
          });
        }
      },
      onError: (error: any) => {
        setLoadingId('');
        notification.error({
          message: 'Error in updating default note',
        });
      },
    });
  };
  const templateListLoading = allNotesLoading || allCategoriesLoading;
  useEffect(() => {
    if (isElation) {
      const data = getFormList(isElation, elationFormData);
      setElationFormList(data);
    }
  }, []);
  useEffect(() => {
    if (defaultNote?.formId !== '' && formList.length > 0) {
      if (formList[0].id !== defaultNote?.formId) {
        setFormList((prevFormList: any) => {
          const defaultNoteItem = prevFormList.filter(
            (item: any) => item.id === defaultNote?.formId
          );
          const otherItems = prevFormList.filter(
            (item: any) => item.id !== defaultNote?.formId
          );
          if (defaultNoteItem.length > 0) {
            return [defaultNoteItem[0], ...otherItems];
          } else {
            return prevFormList;
          }
        });
      }
    }
  }, [defaultNote?.formId]);

  const toggleShowMoreFoldNotes = () => {
    setComponentState((prev) => ({
      ...prev,
      showMoreFoldNotes: !prev.showMoreFoldNotes,
    }));
  };

  const toggleShowMoreElationNotes = () => {
    setComponentState((prev) => ({
      ...prev,
      showMoreEhrNotes: !prev.showMoreEhrNotes,
    }));
  };

  useEffect(() => {
    setComponentState((prev) => ({
      ...prev,
      foldNoteTemplateList: [...formList],
      ehrNoteTemplateList: [...elationFormList],
    }));
  }, [formList?.length, elationFormList?.length]);

  const renderTemplateItemView = (item: IFormListState, index: number, isFold: boolean) => {
    if (
      !(isFold ? componentState.showMoreFoldNotes : componentState.showMoreEhrNotes) &&
      index >= MAX_NOTES_TEMPLATE_SHOW_COUNT
    ) {
      return <></>;
    }
    const isSelectedTemplate = currentTemplateId === item.id;

    return (
      <Pressable
        key={`${item.id}_${index}`}
        pt={1}
        cursor={!isSelectedTemplate ? 'pointer' : undefined}
        disabled={isSelectedTemplate}
        onPress={() => handleSelectedTemplateChange?.(item.id, item.name, isFold)}
      >
        {({ isHovered }) => {
          return (
            <Stack
              direction='row'
              style={{
                alignItems:'center',
                borderRadius: 4,
                ...(isHovered && !isSelectedTemplate ? {
                  backgroundColor: Colors.FoldPixel.GRAY50
                } : {}),
                ...(isSelectedTemplate ? {
                  backgroundColor: Colors.Custom.purpleBackgroundContainer,
                  borderColor: Colors.Custom.Primary200,
                  borderWidth: 0.5,
                } : {}),
                paddingHorizontal: 8,
                paddingVertical: 8,
              }}
            >
              <Text
                flex={1}
                selectable={false}
                fontSize={14}
                color={isSelectedTemplate ? Colors.Custom.Primary300 : Colors.FoldPixel.GRAY400}
              >
                {item.name}
              </Text>
              {isFold && !props?.disableDefaultTemplateSelection && (
                <>
                  {loadingId === item?.id && updateDefaultFormLoading ? (
                    <Spinner size="sm" />
                  ) : (
                    <Pressable
                      onPress={() =>
                        !(defaultNote?.formId === item.id) &&
                        handleDefaultNoteChange(item.id)
                      }
                    >
                      {defaultNote?.formId === item.id ? (
                        <Text color={Colors.Custom.Primary300}>
                          <PinnedIconWithoutBadge customStrokeColor={Colors.Custom.Primary300} />
                        </Text>
                      ) : (
                        <PinIconWithoutBadge customStrokeColor={isSelectedTemplate ? Colors.Custom.Primary300 : Colors.Custom.GRAY350} />
                      )}
                    </Pressable>
                  )}
                </>
              )}
            </Stack>
          );
        }}
      </Pressable>
    );
  }

  const renderTemplateSelectionView = () => {
    let view: JSX.Element = <></>;
    if (isWeb()) {
      view = (
        <Popover
          overlayInnerStyle={antdStyles.popoverOverlay}
          overlayClassName="note-template"
          placement="bottom"
          content={
            <View width={350}>
              <View paddingX={2} paddingY={2}>
                <SearchBar
                  placeholderText="Search"
                  onChange={(searchString) => {
                    const filteredFoldNoteTemplateList = formList?.filter((item) => item.name.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()));
                    const filteredEhrNoteTemplateList = elationFormList?.filter((item) => item.name.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()));
                    setComponentState((prev) => ({
                      ...prev,
                      foldNoteTemplateList: filteredFoldNoteTemplateList,
                      ehrNoteTemplateList: filteredEhrNoteTemplateList,
                    }));
                  }}
                  height={35}
                />
              </View>
              <ScrollView maxH={500} paddingX={2} paddingBottom={2}>
                {templateListLoading && (
                  <Center h="100%">
                    <Spinner size={'lg'} />
                  </Center>
                )}
                {isElation && !templateListLoading && foldVisitNoteWithEncountersEnabled && (
                  <Text
                    color={Colors.Custom.GRAY350}
                    fontSize={12}
                    marginLeft={2}
                  >
                    {"FOLD NOTE TEMPLATES"}
                  </Text>
                )}
                {!templateListLoading && foldVisitNoteWithEncountersEnabled && (
                  componentState?.foldNoteTemplateList?.map((item, index) => (
                    renderTemplateItemView(item, index, true)
                  ))
                )}
                {!templateListLoading && foldVisitNoteWithEncountersEnabled && componentState?.foldNoteTemplateList?.length === 0 && (
                  <Text
                    color={Colors.Custom.Gray200}
                    paddingY={5}
                    textAlign={'center'}
                  >
                    {intl.formatMessage({ id: 'noTemplatesFound' })}
                  </Text>
                )}
                {!templateListLoading && foldVisitNoteWithEncountersEnabled && componentState?.foldNoteTemplateList?.length > MAX_NOTES_TEMPLATE_SHOW_COUNT && (
                  <Pressable
                    mt={1}
                    onPress={toggleShowMoreFoldNotes}
                    {...testID("FoldNoteTemplateShowMoreButton")}
                    ml={2}
                  >
                    <Text
                      color={Colors.Custom.Primary300}
                    >
                      {componentState.showMoreFoldNotes ? 'Show Less' : 'Show More'}
                    </Text>
                  </Pressable>
                )}
                {isElation && foldVisitNoteWithEncountersEnabled && <Divider my={2} />}
                {isElation && (
                  <Text
                    color={Colors.Custom.GRAY350}
                    fontSize={12}
                    marginLeft={2}
                  >
                    {"EHR NOTE TEMPLATES"}
                  </Text>
                )}
                {isElation && componentState?.ehrNoteTemplateList?.map((item, index) => (
                  renderTemplateItemView(item, index, false)
                ))}
                {isElation && componentState?.ehrNoteTemplateList?.length === 0 && (
                  <Text
                    color={Colors.Custom.Gray200}
                    paddingY={5}
                    textAlign={'center'}
                  >
                    {intl.formatMessage({ id: 'noTemplatesFound' })}
                  </Text>
                )}
                {isElation && componentState?.ehrNoteTemplateList?.length > MAX_NOTES_TEMPLATE_SHOW_COUNT && (
                  <Pressable
                    mt={1}
                    onPress={toggleShowMoreElationNotes}
                    {...testID("EhrNoteTemplateShowMoreButton")}
                  >
                    <Text style={styles.showMoreButtonText2}>
                      {componentState.showMoreEhrNotes ? 'Show Less' : 'Show More'}
                    </Text>
                  </Pressable>
                )}
              </ScrollView>
              {(foldVisitNoteWithEncountersEnabled && !isSidecarContext ) && (
                <>
                  <Divider />
                  <Pressable
                    paddingX={4}
                    paddingY={3}
                    onPress={() => showCreateFormModal(true)}
                  >
                    <HStack my={2} alignItems="center">
                      <Text
                        color={Colors.Custom.Primary300}
                        fontSize={14}
                      >
                        {intl.formatMessage({ id: 'create_new_template' })}
                      </Text>
                      <Spacer />
                      <View paddingRight={4}>
                        <AntIcon
                          name="right"
                          size={12}
                          color={Colors.Custom.PurpleColor}
                        />
                      </View>
                    </HStack>
                  </Pressable>
                </>
              )}
            </View>
          }
        >
          <Button
            rightIcon={
              renderNewView ? (
                <HStack alignItems={'center'}>
                  <Divider
                    height={'16px'}
                    orientation="vertical"
                    width={'1px'}
                    marginRight={2}
                  />
                  <SelectDownArrowSvg size={8} customStrokeColor={Colors.Custom.Gray500}/>
                </HStack>
              ) : (
                <AntIcon name="down" size={12} />
              )
            }            mx={2}
            backgroundColor={Colors.Custom.BackgroundColor}
            borderColor={renderNewView ? Colors.Custom.Gray400 : Colors.Custom.PurpleColor}
            _hover={{
              backgroundColor: renderNewView ? Colors.Custom.Gray100 : Colors.Custom.PurpleColor,
              _text: {
                color: renderNewView ? Colors.Custom.Gray500 : 'white',
                ...SM_CONFIG.smRegular
              },
            }}
            height={'36px'}
            maxW={'1/3'}
            _text={{
              color: renderNewView ? Colors.Custom.Gray500 : Colors.Custom.PurpleColor,
              fontWeight: 500,
            }}
            borderWidth={1}
            style={styles.templateSelectionButton}
          >
            {form.id ? clipText(form.name, isSmallScreen ? 10 : 20) : 'Select Template'}
          </Button>
        </Popover>
      )
    } else {
      view = (
        <Box minH={54} mt={4}>
          <CustomSelect
            label=""
            placeholder="Select Template"
            value={form.id}
            options={isElation ? elationFormList : formList}
            isDisabled={isElation ? !elationFormList.length : !formList.length}
            optionProps={{key: 'id', value: 'id', label: 'name'}}
            styles={{
              labelColor: Colors.Custom.Gray700,
              borderColor: Colors.Custom.Gray300,
              borderRadius: GLOBAL_INPUT_BORDER_RADIUS,
              height: 50,
            }}
            customDropDownIcon={
              <Feather
                name="chevron-down"
                size={20}
                color={Colors.Custom.Gray200}
              />
            }
            customRenderOption={(item: IFormListState) => {
              return (
                <HStack alignItems={'center'} minW={'full'}>
                  <Text>{item.name}</Text>
                </HStack>
              );
            }}
            onSelect={(option) => {
              handleSelectedTemplateChange?.(option.id);
            }}
          />
        </Box>
      );
    }
    return view;
  }
  return (
    <VStack>
      <VStack
        borderBottomWidth={1}
        borderBottomColor={Colors.Custom.Gray200}
        w="100%"
        paddingTop={renderNewView ? 3 : 4}
        paddingBottom={renderNewView ? 3 : 4}
        paddingX={renderNewView ? 0 : 2}
        backgroundColor={renderNewView ? Colors.Custom.White : Colors.Custom.Gray100}
        style={styles.headerContainer2}
      >
        <HStack alignItems="center" paddingX={renderNewView ? 3 : 0}>
          {!isWeb() && props.showBackButton && (
            <CustomHeaderBackArrow
              onPress={() => {
                navigation?.goBack();
              }}
              iconType={''}
            />
          )}
          {renderNewView && (
            <Pressable
              onPress={() => {
                backButtonAction();
              }}
            >
              <BackArrowSvg size={24} />
            </Pressable>
          )}
          {title}
          {isWeb() && showTemplateSelection && !renderNewView && renderTemplateSelectionView()}
          <Spacer />
          <HStack space={2} alignItems="center">
            {headerAction}
          </HStack>
          {isWeb() ? (
            <>
              <CreateForm
                showModal={createForm}
                onCancel={() => {
                  showCreateFormModal(false);
                }}
                quickNoteCreation={true}
                noteCategoryId={soapNoteCategoryId}
                handleNewlyCreatedNote={(noteId, templateName?: string) => {
                  refetchAllNotes();
                  handleNewlyCreatedNote?.(noteId, templateName);
                }}
                style={styles.createForm}
                patientLocations={props?.locationList}
              />

              {props.showBackButton && !renderNewView && (
                <Button
                  backgroundColor={Colors.Custom.Gray100}
                  _hover={{bg: Colors.primary['100'] + '7a' || ''}}
                  style={styles.backButton}
                  disabled={backButtonDisabled}
                  onPress={() => {
                    backButtonAction();
                  }}
                >
                  <AntIcon name="close" size={18} />
                </Button>
              )}
            </>
          ) : (
            <></>
          )}
        </HStack>

        {renderNewView && <Divider height={'1px'} marginTop={3}/>}

        <HStack alignItems={"center"} marginTop={3} paddingLeft={renderNewView ? 2 : 0} paddingRight={renderNewView ? 3 : 0}>
          {renderNewView && showTemplateSelection && renderTemplateSelectionView()}
          {renderNewView && showTemplateSelection && props?.showNoteTitleInput && <Divider height={5} width={'1px'} backgroundColor={Colors.Custom.GRAY150}/>}
          {props?.showNoteTitleInput ? (
          <View style={styles.noteTitleInput}>
            <Input
              isDisabled={!!noteTitleValue && isTaskOrNoteLabelComponentExist}
              defaultValue={noteTitleValue}
              value={isTaskOrNoteLabelComponentExist ? noteTitleValue : undefined}
              onChangeText={debounce((text) => {
                props?.onNoteTitleChange?.(text);
              }, 500)}
              flex={1}
              fontWeight={"normal"}
              borderColor={Colors.Custom.Gray400}
              borderRadius={6}
              background={'white'}
              placeholder="Enter note title here"
            />
            {showNoteTitleErrorText && (
              <DisplayText
                size={'smMedium'}
                extraStyles={{
                  color: Colors.danger[600],
                  fontWeight: 500,
                  fontSize: 14,
                }}
                textLocalId={"mandatoryNoteTitleFieldError"}
                testID={'MandatoryNoteTitleFieldError'}
              />
            )}
          </View>
        ) : null}
        </HStack>


        {!isWeb() && showTemplateSelection && renderTemplateSelectionView()}
      </VStack>
    </VStack>
  );
};

export default Header;

