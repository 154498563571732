import {Colors} from '../../../../../styles/Colors';
import {
  Box,
  Center,
  FlatList,
  Flex,
  HStack,
  Text,
  VStack,
  Button,
  Pressable,
  useMediaQuery,
  Divider,
  View,
  Skeleton,
} from 'native-base';
import {useIntl} from 'react-intl';
import {Tooltip, Collapse, Popover, Drawer, Button as AntButton} from 'antd';
import {LeftOutlined, RightOutlined} from '@ant-design/icons';
import React, {useContext, useEffect, useState, useMemo} from 'react';
import {Dimensions, StyleSheet} from 'react-native';
import {
  tooltipInnerOverlayStyle,
  tooltipOverlayStyle,
  tooltipPlacement,
} from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import {
  getEncounterDataById,
  getVisitSummary,
} from '../../../../../services/CommonService/AidBoxService';
import {
  EncounterStatus,
  VisitType,
  visitTypesWithCodes,
} from './VisitSummaryConstants';
import {IVisitData, IVisitElement, IVisitInfo} from './interfaces';
import {
  addMissingMonths,
  getDurationFromMinutes,
  getVisitCode,
  getVisitTypeByCode,
} from './VisitSummaryHelper';
import {
  getCurrentYearEndtDate,
  getCurrentYearStartDate,
  getDateStrFromFormat,
  getNextYearEndDate,
  getNextYearStartDate,
  getPreviousYearEndDate,
  getPreviousYearStartDate,
} from '../../../../../utils/DateUtils';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {
  BUTTON_TYPE,
  DATE_FORMATS,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
} from '../../../../../constants';
import {getAccountUUID} from '../../../../../utils/commonUtils';
import ViewLinkedNoteSvg from '../../../../common/Svg/ViewLinkedNoteSvg';
import NoDataFoundWithCustomIcon from '../../../../common/NoDataFound/NoDataFoundWithCustomIcon';
import InfoCircleSvg from '../../../../common/Svg/InfoCircleSvg';
import PlannedStatusSvg from '../../../../common/Svg/PlannedStatusSvg';
import ArrivedStatusSvg from '../../../../common/Svg/ArrivedStatusSvg';
import TriagedStatusSvg from '../../../../common/Svg/TriagedStatusSvg';
import InProgressSvg from '../../../../common/Svg/InProgressSvg';
import FinishedStatusSvg from '../../../../common/Svg/FinishedStatusSvg';
import CancelledStatusSvg from '../../../../common/Svg/CancelledStatusSvg';
import EnteredInErrorStatusSvg from '../../../../common/Svg/EnteredInErrorStatusSvg';
import UnknownStatusSvg from '../../../../common/Svg/UnknownStatusSvg';
import OnLeaveStatusSvg from '../../../../common/Svg/OnLeaveStatusSvg.tsx';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {testID, TestIdentifiers} from '../../../../../testUtils';
import {NoVisitFoundSvg} from '../../../../../assets/Icons/NoVisitFoundSvg';

interface IVisitSummaryProps {
  personData: any;
}

const antdStyles: Record<string, React.CSSProperties> = {
  leftOutlined: {
    padding: 5,
    marginRight: 10,
  },
  rightOutlined: {
    padding: 5,
    marginLeft: 5,
  },
};

export const VisitSummary = (props: IVisitSummaryProps) => {
  const intl = useIntl();
  const {Panel} = Collapse;
  const mlovData = useContext(CommonDataContext);
  const isSidecarContext = !!mlovData?.sidecarContext?.isSidecar;

  const [responseSummaryData, setResponseSummaryData] = useState<IVisitData[]>(
    []
  );
  const [lastAnnualVisitDate, setLastAnnualVisitDate] = useState('');
  const accountUuid = getAccountUUID();
  const [visitSummaryData, setVisitSummaryData] = useState<IVisitElement[]>([]);
  const [filteredVisitSummaryData, setFilteredVisitSummaryData] = useState<
    IVisitElement[]
  >([]);
  const [graphHeight, setGraphHeight] = useState<number>(180);
  const [selectedVisitCode, setSelectedVisitCode] = useState<string>('all');
  const [fromDate, setFromDate] = useState(getCurrentYearStartDate());
  const [toDate, setToDate] = useState(getCurrentYearEndtDate());
  const {width: screenWidth} = Dimensions.get('window');
  const ITEM_WIDTH = screenWidth / 29;
  const SIDE_CAR_ITEM_WIDTH = screenWidth/12;
  const VISIT_SUMMARY = 'VISIT_SUMMARY';
  const [componentState, setComponentState] = useState<{
    activePanel: string[];
    isOpen: boolean;
    encounterDuration: string;
    signedUrl?: string;
    encounterName?: string;
    encounterStartDate?: string;
    encounterStatus?: string;
    visitType?: string;
  }>({
    activePanel: [VISIT_SUMMARY],
    isOpen: false,
    encounterDuration: '',
    signedUrl: '',
    encounterName: '',
    encounterStartDate: '',
    encounterStatus: '',
    visitType: '',
  });
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState<number>(currentYear);
  const [loading, setLoading] = useState(false);
  const contactId = props.personData?.id;
  const patientId = props.personData?.patientId;

  const getHeaderWithSubText = (header: string, dateText: string) => {
    if (!dateText) {
      return <></>;
    }
    return (
      <VStack flexWrap={'wrap'} style={styles.headerContainer} flex={0.5}>
        <HStack flexWrap={'wrap'}>
          <Text style={styles.headerText}>
            {header}
            {' : '}
          </Text>
          <Text style={styles.subHeaderText}>{dateText}</Text>
        </HStack>
      </VStack>
    );
  };

  const getBackgroundAndBorderColor = (visitType: string) => {
    let backgroundColor: string;
    let borderColor: string;

    switch (visitType) {
      case VisitType.OP:
        backgroundColor = '#D3FFF4';
        borderColor = '#00C595';
        break;
      case VisitType.ER:
        backgroundColor = '#E6DBFF';
        borderColor = '#6941C7';
        break;
      case VisitType.Speciality:
        backgroundColor = '#FFF1CB';
        borderColor = '#DD9F00';
        break;
      case VisitType.IP:
        backgroundColor = '#FFE4F8';
        borderColor = '#A60A7A';
        break;
      case VisitType.others:
        backgroundColor = '#EFF2F3';
        borderColor = '#607D8B';
        break;
      default:
        backgroundColor = '#F3F9ED';
        borderColor = '#8BC34A';
    }
    return {backgroundColor, borderColor};
  };

  useEffect(() => {
    getData();
  }, [fromDate]);

  const getData = async () => {
    setLoading(true);
    const bodyData = {
      resourceCode: 'VISITS_SUMMARY',
      contactId: contactId,
      appliedFilter: [
        {
          filterCode: 'DATE_RANGE',
          filterValue: {
            fromDate: fromDate,
            toDate: toDate,
          },
        },
      ],
    };
    try {
      const summaryDataResponse = await getVisitSummary(bodyData);
      const summaryData = summaryDataResponse?.data?.result;
      if (summaryDataResponse?.data?.lastAnnualVisitDate) {
        setLastAnnualVisitDate(summaryDataResponse?.data?.lastAnnualVisitDate);
      }
      if (summaryData?.length !== 0) {
        setResponseSummaryData(summaryData);
        const visitsGroupedByDate = getVisitsGroupedByMonthAndYear(summaryData);
        const chartDataWithMissingMonths: IVisitElement[] = addMissingMonths(
          visitsGroupedByDate,
          selectedYear.toString()
        );
        const chartDatawithNotesViewCheck = await getChartDataWithNotesViewKey(
          chartDataWithMissingMonths
        );
        setVisitSummaryData(chartDataWithMissingMonths);
        setFilteredVisitSummaryData(chartDatawithNotesViewCheck);
        const hasMoreThanThreeVisits = chartDataWithMissingMonths.some(
          (item) => item?.visits?.length > 3
        );
        if (hasMoreThanThreeVisits) {
          setGraphHeight(250);
        }
      } else {
        setFilteredVisitSummaryData([]);
        setResponseSummaryData([]);
      }
    } catch (error) {
      console.error('Error fetching visit summary:', error);
      setFilteredVisitSummaryData([]);
      setResponseSummaryData([]);
    } finally {
      setLoading(false);
    }
  };

  const getChartDataWithNotesViewKey = async (visits: any[]) => {
    const promises = [];
    for (const visit of visits) {
      if (visit?.visits && visit?.visits.length > 0) {
        for (const summary of visit?.visits) {
          for (const visitDetail of summary?.visitSummary) {
            if (visitDetail?.resource_id) {
              const body = {
                patientId: patientId,
                accountUuid: accountUuid,
                encounterResourceId: visitDetail.resource_id,
              };
              const promise = getEncounterDataById(body)
                .then((response: any) => {
                  if (response.status >= 200 && response.status < 300) {
                    visitDetail.isNoteView = true;
                    visitDetail.signedUrl = response?.data?.signedUrl;
                  } else {
                    visitDetail.isNoteView = false;
                  }
                })
                .catch(() => {
                  visitDetail.isNoteView = false;
                });

              promises.push(promise);
            }
          }
        }
      }
    }
    await Promise.allSettled(promises);
    return visits;
  };
  const getVisitsGroupedByMonthAndYear = (visits: any[]) => {
    const groupedVisits: any = [];

    visits?.forEach((visit) => {
      const date = new Date(visit?.visitSummary?.[0]?.encounter_start_date);
      const year = date?.getFullYear();
      const month = date?.toLocaleString('default', {month: 'long'});

      const key = `${month} ${year}`;
      const existingGroup = groupedVisits?.find(
        (group: any) =>
          group?.monthYear === key && group?.monthYear?.includes(selectedYear)
      );

      if (existingGroup) {
        existingGroup?.visits?.push(visit);
      } else {
        // Create a new group object
        groupedVisits.push({
          monthYear: key,
          visits: [visit],
        });
      }
    });
    return groupedVisits;
  };

  const getUniqueVisitCodesWithTotalCount = (visits: IVisitData[]) => {
    const uniqueVisitCodes: IVisitInfo[] = [];

    visits.forEach((visit: any) => {
      const {visitCount} = visit;
      const visitCode = getVisitCode(visit.visitType);
      const existingVisitIndex = uniqueVisitCodes.findIndex(
        (item) => item.visitCode === visitCode
      );
      if (existingVisitIndex === -1) {
        // If not found, add a new object
        uniqueVisitCodes.push({
          visitType: visit.visitType,
          visitCode: visitCode,
          totalVisitCount: visitCount,
        });
      } else {
        uniqueVisitCodes[existingVisitIndex].totalVisitCount += visitCount;
      }
    });

    visitTypesWithCodes.forEach((code) => {
      const existingVisitIndex = uniqueVisitCodes.findIndex(
        (item) => item.visitCode === code.visitCode
      );
      if (existingVisitIndex === -1) {
        uniqueVisitCodes.push({
          visitType: code.visitType,
          visitCode: code.visitCode,
          totalVisitCount: 0,
        });
      }
    });

    return uniqueVisitCodes;
  };

  const getVisitCountButtons = (visits: IVisitData[]) => {
    const visitCountButtons: JSX.Element[] = [];
    const visitsGroupedByType = getVisitsGroupedByVisitType(visits);
    visitsGroupedByType?.forEach((visit) => {
      const visitColors = getBackgroundAndBorderColor(visit.visitType);
      visitCountButtons.push(
        <Popover
          overlayInnerStyle={{
            bottom: 3,
            borderRadius: 16,
            padding: 0,
          }}
          content={getPopOverContent(visit)}
          trigger="hover"
          placement="top"
          overlayStyle={{
            borderRadius: 8,
          }}
          {...testID(TestIdentifiers?.popOver)}
        >
          <Button
            onPress={() => {}}
            style={[
              styles.visitTypeCount,
              {
                backgroundColor: visitColors.backgroundColor,
                borderColor: visitColors.borderColor,
              },
            ]}
            {...testID(TestIdentifiers?.visitPopOver)}
          >
            <Text style={{color: visitColors.borderColor}}>
              {visit?.monthsTotalVisitCount}
            </Text>
          </Button>
        </Popover>
      );
    });
    return visitCountButtons;
  };

  const getVisitsGroupedByVisitType = (visits: IVisitData[]) => {
    const groupedVisits = visits?.reduce((acc: any, visit: any) => {
      const {visitType} = visit;
      if (!acc[visitType]) {
        acc[visitType] = [];
      }
      acc[visitType].push(visit);
      return acc;
    }, {});
    const result = [];
    for (const visitType in groupedVisits) {
      if (Object.hasOwnProperty.call(groupedVisits, visitType)) {
        const group = groupedVisits[visitType];
        const monthsTotalVisitCount = group?.reduce(
          (total: number, visit: any) => total + visit?.visitCount,
          0
        );
        const visitDates = group.map(
          (visit: any) => visit?.visitSummary?.[0]?.encounter_start_date
        );
        const newObj = {
          visitType: group[0]?.visitType,
          visitMonth: group[0]?.visitMonth,
          visitSummary: group[0]?.visitSummary,
          monthsTotalVisitCount,
          visitDates,
        };

        result.push(newObj);
      }
    }
    return result;
  };
  const getStatusColorAndIcon = (status: string) => {
    switch (status) {
      case EncounterStatus.planned:
        return {
          color: Colors.Custom.PrimaryColor,
          icon: <PlannedStatusSvg />,
        };
      case EncounterStatus.arrived:
        return {
          color: Colors.Custom.PrimaryColor,
          icon: <ArrivedStatusSvg />,
        };
      case EncounterStatus.triaged:
        return {
          color: Colors.Custom.SuccessColor,
          icon: <TriagedStatusSvg />,
        };
      case EncounterStatus.inProgress:
        return {
          color: Colors.Custom.orangeMessageTopBar,
          icon: <InProgressSvg />,
        };
      case EncounterStatus.onleave:
        return {color: Colors.Custom.ErrorColor, icon: <OnLeaveStatusSvg />};
      case EncounterStatus.finished:
        return {
          color: Colors.Custom.SuccessColor,
          icon: <FinishedStatusSvg />,
        };
      case EncounterStatus.cancelled:
        return {
          color: Colors.Custom.ErrorColor,
          icon: <CancelledStatusSvg />,
        };
      case EncounterStatus.enteredInError:
        return {
          color: Colors.Custom.ErrorColor,
          icon: <EnteredInErrorStatusSvg />,
        };
      case EncounterStatus.unknown:
        return {color: Colors.Custom.Gray500, icon: <UnknownStatusSvg />};
      default:
        return {color: Colors.Custom.Gray500, icon: null};
    }
  };

  const onClose = () => {
    setComponentState((prev) => ({
      ...prev,
      isOpen: false,
      signedUrl: '',
      encounterDuration: '',
      encounterName: '',
      encounterStartDate: '',
      encounterStatus: '',
      visitType: '',
    }));
  };

  const getFilteredChartWithVisitCode = (newVisitCode: string) => {
    setSelectedVisitCode(newVisitCode);
    if (selectedVisitCode !== newVisitCode) {
      const filteredData = visitSummaryData.map((item) => {
        // If the visits array is empty, keep the object
        if (item.visits.length === 0) {
          return {...item};
        }
        // Filter the visits array to include only the objects with the specified visitCode
        const filteredVisits = item?.visits?.filter((visit) =>
          visit?.visitType?.includes(newVisitCode)
        );
        // Return a new object with the filtered visits array
        return {...item, visits: filteredVisits};
      });
      setFilteredVisitSummaryData(filteredData);
    } else {
      setSelectedVisitCode('all');
      setFilteredVisitSummaryData(visitSummaryData);
    }
  };

  const getDrillDownDrawer = () => {
    return (
      <>
        {componentState?.isOpen && !!componentState?.signedUrl && (
          <Drawer
            placement="right"
            open={componentState?.isOpen && !!componentState?.signedUrl}
            closable
            mask={isSidecarContext ? false : true}
            width={
              isSidecarContext
                ? '100%'
                : isIPadScreen || isIPadMiniScreen
                ? '75%'
                : 936
            }
            title={
              <ModalActionTitle
                title={'notesPreview'}
                titleColor={''}
                leftBackButton={
                  isSidecarContext ? (
                    <AntButton
                      onClick={() => onClose()}
                      type="text"
                      icon={<LeftOutlined />}
                      {...testID(TestIdentifiers?.backBtn)}
                    />
                  ) : undefined
                }
                buttonList={[
                  {
                    show: !isSidecarContext,
                    id: 1,
                    btnText: 'close',
                    textColor: Colors.Custom.mainSecondaryBrown,
                    variant: BUTTON_TYPE.SECONDARY,
                    isTransBtn: false,
                    onClick: () => {
                      onClose();
                    },
                  },
                ]}
              />
            }
          >
            {getNoteViewContent()}
            <iframe
              width={'100%'}
              height={850}
              src={componentState?.signedUrl}
            ></iframe>
          </Drawer>
        )}
      </>
    );
  };

  const getNoteViewContent = () => {
    return (
      <VStack style={styles.noteViewContainer}>
        <Text style={styles.noteViewTitle}>{`${componentState.visitType} • ${componentState.encounterStartDate} (${componentState.encounterDuration})`}</Text>
        <Text style={styles.noteViewEncounterName}>{`${componentState.encounterName}`}</Text>
        <HStack>
          <Text
            style={styles.noteViewStatus}
            color={Colors.Custom.SuccessColor}
          >{`${componentState.encounterStatus}`}</Text>
        </HStack>
      </VStack>
    );
  };

  const getPopOverContent = (visit: any) => {
    return (
      <VStack>
        {visit?.visitSummary?.map((summary: any, index: number) => {
          const duration = getDurationFromMinutes(summary?.encounter_duration);
          const encounterStartDate = getDateStrFromFormat(
            summary?.encounter_start_date,
            DATE_FORMATS.DISPLAY_DATE_FORMAT
          );
          const statusKey =
            summary?.encounter_status as keyof typeof EncounterStatus;
          const statusKeyToDisplay = EncounterStatus[statusKey];
          const {color, icon} = getStatusColorAndIcon(statusKeyToDisplay);
          return (
            <React.Fragment key={index}>
              <Text style={styles.popoverTitle}>
                {`${visit?.visitType} • ${encounterStartDate} (${duration})`}
              </Text>
              <Text style={styles.popoverEncounterName}>{`${summary?.encounter_name}`}</Text>
              <HStack alignItems={'center'}>
                {icon}
                <Text style={[styles.popoverStatus, {color: color}]}>
                  {`${statusKeyToDisplay}`}
                </Text>
              </HStack>
              {summary?.isNoteView && (
                <Pressable
                  onPress={() => {
                    setComponentState((prev) => ({
                      ...prev,
                      isOpen: true,
                      signedUrl: summary?.signedUrl,
                      encounterDuration: duration,
                      encounterName: summary?.encounter_name,
                      encounterStartDate: encounterStartDate,
                      encounterStatus: EncounterStatus[statusKey],
                      visitType: visit?.visitType,
                    }));
                  }}
                  {...testID(TestIdentifiers?.viewNotes)}
                >
                  <ViewLinkedNoteSvg />
                </Pressable>
              )}
              {index < visit?.visitSummary?.length - 1 && <Divider my={2} />}
            </React.Fragment>
          );
        })}
      </VStack>
    );
  };

  const renderLeftButton = () => {
    return (
      <LeftOutlined
        onClick={() => {
          setFromDate((prev) => {
            return getPreviousYearStartDate(prev);
          });
          setToDate((prev) => {
            return getPreviousYearEndDate(prev);
          });
          setSelectedYear(selectedYear - 1);
          setLoading(true);
          setSelectedVisitCode('all');
        }}
        style={antdStyles.leftOutlined}
        {...testID(TestIdentifiers?.leftArrow)}
      />
    );
  };

  const renderRightButton = () => {
    return (
      <RightOutlined
        onClick={() => {
          if (selectedYear === currentYear) {
            return;
          } else {
            setSelectedYear(selectedYear + 1);
            setFromDate((prev) => {
              return getNextYearStartDate(prev);
            });
            setToDate((prev) => {
              return getNextYearEndDate(prev);
            });
            setLoading(true);
            setSelectedVisitCode('all');
          }
        }}
        style={{
          padding: 5,
          marginLeft: 5,
          opacity: selectedYear === currentYear ? 0.5 : 1,
        }}
        {...testID(TestIdentifiers?.rightArrow)}
      />
    );
  };

  const renderVisitCountElem = ({item}: {item: IVisitInfo}) => {
    const visitColors = getBackgroundAndBorderColor(item.visitType);
    return (
      <Tooltip title={getVisitTypeByCode(item.visitCode)}>
      <Pressable
        onPress={() => {
          if (item?.totalVisitCount) {
            getFilteredChartWithVisitCode(item?.visitType);
          }
        }}
        {...testID(TestIdentifiers?.visitCode)}
        opacity={
          selectedVisitCode === 'all'
            ? 1
            : item?.visitType === selectedVisitCode
            ? 1
            : 0.5
        }
      >
        <HStack space={1} style={styles.visitCountHStack}>
          <Box
            style={[
              styles.colorCode,
              {
                backgroundColor: visitColors.borderColor,
                borderColor: visitColors.backgroundColor,
              },
            ]}
          />
          <Text>{item?.visitCode}</Text>
          {loading ? (
            <Skeleton size="5" rounded="full" />
          ) : (
            <Text>({item.totalVisitCount})</Text>
          )}
        </HStack>
      </Pressable>
      </Tooltip>
    );
  };

  const collapseStyles = useMemo(() => {
    return {
      borderWidth: isSidecarContext ? 0.5 : 0,
      borderColor: isSidecarContext ? undefined : Colors.Custom.ContainerBGColor,
      borderRadius: isSidecarContext ? undefined : 16,
    };
  }, [isSidecarContext]);

  return (
    <Box
      style={styles.container}
      shadow="0px 4px 8px -2px #10182819, 0px 2px 4px -2px #101828f"
      borderRadius={8}
      backgroundColor={Colors.Custom.ContainerBGColor}
    >
      <Collapse
        className="llm-collapse"
        defaultActiveKey={[VISIT_SUMMARY]}
        expandIconPosition="end"
        collapsible="header"
        expandIcon={({isActive}) => {
          const tooltipText = isActive
            ? intl.formatMessage({id: 'collapse'})
            : intl.formatMessage({id: 'expand'});
          return (
            <Tooltip
              title={tooltipText}
              placement={tooltipPlacement}
              showArrow={false}
              overlayStyle={tooltipOverlayStyle}
              overlayInnerStyle={tooltipInnerOverlayStyle}
            >
              <RightOutlined rotate={isActive ? 90 : 0} />
            </Tooltip>
          );
        }}
        style={collapseStyles}
        {...testID(TestIdentifiers?.expandCollapse)}
      >
        <Panel
          forceRender
          key={VISIT_SUMMARY}
          header={
            <HStack
              alignItems={'center'}
              bgColor={Colors.Custom.BackgroundColor}
              borderTopRadius={16}
            >
              <Text style={styles.panelHeaderText} size={'lgMedium'}>
                {intl.formatMessage({id: 'visitSummary'})}
              </Text>
            </HStack>
          }
        >
          <Box style={styles.panelContent}>
            <Box>
              <VStack space={5}>
                <Box style={styles.yearSelectorContainer}>
                  <Box style={styles.yearSelector}>
                    {renderLeftButton()}
                    <Text style={styles.yearText}>{selectedYear}</Text>
                    {renderRightButton()}
                  </Box>
                  <FlatList
                    horizontal
                    data={getUniqueVisitCodesWithTotalCount(
                      responseSummaryData
                    )?.filter((item) => item.totalVisitCount > 0)}
                    renderItem={renderVisitCountElem}
                    persistentScrollbar
                  />
                </Box>
                <VStack style={styles.chartContainer}>
                  {loading ? (
                    <FlatList
                      horizontal
                      keyExtractor={(item, index) => index.toString()}
                      data={Array(12).fill({})} // Array of empty objects to render skeletons
                      renderItem={() => (
                        <Flex
                          h={graphHeight}
                          w={isSidecarContext ? SIDE_CAR_ITEM_WIDTH : ITEM_WIDTH}
                          alignItems={'flex-end'}
                          style={styles.chartItemContainer}
                        >
                          <Center
                            h={graphHeight}
                            w={'100%'}
                            justifyContent={'flex-end'}
                            style={styles.chartItemInnerContainer}
                          >
                            <Skeleton size="5" rounded="full" />
                            <Center
                              h={'40px'}
                              width={'100%'}
                              style={styles.chartItemMonthContainer}
                            >
                              <Text
                                style={styles.chartItemMonthText}
                                fontWeight={400}
                              >
                                {/* Placeholder for monthYear */}
                              </Text>
                            </Center>
                          </Center>
                        </Flex>
                      )}
                    />
                  ) : filteredVisitSummaryData.length > 0 ? (
                    <FlatList
                      horizontal
                      keyExtractor={(item, index) => index.toString()}
                      data={filteredVisitSummaryData}
                      renderItem={({item}) => (
                        <Flex
                          h={graphHeight}
                          w={isSidecarContext ? SIDE_CAR_ITEM_WIDTH : ITEM_WIDTH}
                          alignItems={'flex-end'}
                          style={styles.chartItemContainer}
                        >
                          <Center
                            h={graphHeight}
                            w={'100%'}
                            justifyContent={'flex-end'}
                            style={styles.chartItemInnerContainer}
                          >
                            {getVisitCountButtons(item.visits)}
                            <Center
                              h={'40px'}
                              width={'100%'}
                              style={styles.chartItemMonthContainer}
                            >
                              <Text
                                style={styles.chartItemMonthText}
                                fontWeight={400}
                              >
                                {item?.monthYear?.substring(0, 3)}
                              </Text>
                            </Center>
                          </Center>
                        </Flex>
                      )}
                    />
                  ) : (
                    <View
                      padding={3}
                      alignItems={'center'}
                      justifyContent={'center'}
                    >
                      <NoVisitFoundSvg />
                      <Text style={styles.noVisitsText}>No Visits Found</Text>
                    </View>
                  )}
                </VStack>
              </VStack>
            </Box>
            {loading ? (
              <Skeleton rounded="md"/>
            ) : (
              <Box style={styles.lastAnnualVisitContainer}>
                {getHeaderWithSubText('Last Annual Visit', lastAnnualVisitDate)}
              </Box>
            )}
          </Box>
        </Panel>
      </Collapse>
      {getDrillDownDrawer()}
    </Box>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 10,
  },
  headerContainer: {
    borderWidth: 0,
  },
  headerText: {
    fontSize: 16,
  },
  subHeaderText: {
    fontSize: 16,
    color: Colors.FoldPixel.GRAY300,
  },
  colorCode: {
    height: 10,
    width: 10,
    borderWidth: 1,
    borderRadius: 10,
  },
  visitTypeCount: {
    height: 25,
    width: 25,
    borderWidth: 1,
    borderRadius: 5,
  },
  popoverOverlayInner: {
    bottom: 3,
    borderRadius: 16,
    padding: 0,
  },
  popoverOverlay: {
    borderRadius: 8,
  },
  visitCountElem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 10,
  },
  visitCountHStack: {
    alignItems: 'center',
    paddingRight: 10,
  },
  panelHeaderText: {
    color: Colors.FoldPixel.GRAY400,
  },
  panelContent: {
    padding: 15,
  },
  yearSelectorContainer: {
    marginTop: 10,
    flexDirection: 'row',
  },
  yearSelector: {
    flexDirection: 'row',
    marginRight: 4,
  },
  yearText: {
    fontWeight: '500',
    fontSize: 14,
    width: 37,
  },
  chartContainer: {
    borderLeftWidth: 0,
    borderBottomColor: '#DEDEDE',
    marginLeft: -15,
    marginRight: -15,
  },
  chartItemContainer: {
    borderRightWidth: 1,
    borderColor: '#EDEDED',
    justifyContent: 'flex-end',
    backgroundColor: '#FCFAFF',
  },
  chartItemInnerContainer: {
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderColor: '#EDEDED',
  },
  chartItemMonthContainer: {
    borderTopWidth: 1,
    borderColor: '#EDEDED',
    marginTop: 10,
  },
  chartItemMonthText: {
    color: '#6F7A90',
  },
  noVisitsContainer: {
    padding: 3,
    alignItems: 'center',
    justifyContent: 'center',
  },
  noVisitsText: {
    color: Colors.FoldPixel.GRAY300,
    fontSize: 12,
    marginTop: 2,
  },
  lastAnnualVisitContainer: {
    display: 'flex',
    flex: 1,
    marginTop: 10,
  },
  noteViewContainer: {
    borderWidth: 1,
    borderColor: Colors.Custom.Gray200,
    borderRadius: 8,
    padding: 4,
    marginBottom: 4,
    backgroundColor: Colors.Custom.Gray100,
  },
  noteViewTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 1,
  },
  noteViewEncounterName: {
    fontSize: 14,
    marginBottom: 1,
  },
  noteViewStatus: {
    fontSize: 14,
    color: Colors.Custom.SuccessColor,
  },
  popoverTitle: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  popoverEncounterName: {
    fontSize: 12,
  },
  popoverStatus: {
    fontSize: 12,
    marginLeft: 2,
  },
});
