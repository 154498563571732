import React, {useCallback, useEffect, useMemo} from 'react';
import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {
  CreateContactOutreachActivity,
  GetContactOutreachActivities,
} from '../../../../services/ContactOutreach/ContactOutreachQueries';
import { getMlovListFromCategory, getMlovObjectFromId } from '../../../../utils/mlovUtils';
import { MLOV_CATEGORY } from '../../../../constants';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import {
  CARE_PROGRAM_OUTREACH_MODE_CODES,
  CARE_PROGRAM_TYPES,
  OUT_REACH_OUTCOME_CATEGORY_CODES,
  OUTREACH_STATUS_CODES,
  TASK_STATUS,
} from '../../../../constants/MlovConst';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../constants/Configs';
import { PatientNotesLogModeOfContact } from '../../constants';
import { IFormattedContactOutreachActivity } from './interfaces';
import CareProgramStepOutreachUtils from '../../../common/CareProgramStepOutreach/CareProgramStepOutreachUtils';
import {GET_USERS_WITH_IDS} from '../../../../services/User/UserQueries';
import {getUserData} from '../../../../utils/commonUtils';
import {formatOutreachData} from './ContactOutreachActivitiesHelper';
import {AddOrUpdateContactOutreachActivityInput, IContactCareProgram, IContactCareProgramStep} from '../../../RightSideContainer/ContactCareProgram/interface';
import {getMomentObj} from '../../../../utils/DateUtils';
import useOutreachStepOutComes from '../../../common/CareProgramStepOutreach/useOutreachStepOutComes';
import useOutreachStatus from '../../../common/CareProgramStepOutreach/useOutreachStatus';

export type HiddenFields = 'communicationModeId' | 'outcomeId' | 'careProgramTypeId';
export interface IUseContactOutreachActivity {
  contactUuid: string;
  hiddenFields?: HiddenFields[];
  isCareManagement?: boolean;
  selectStepWhenEditingOldActivity?: (stepId: string) => void;
  customOnSaveHandler?: (outreachData: AddOrUpdateContactOutreachActivityInput) => Promise<any>;
  careProgramTypeId?: string;
  contactCareProgramId?: string;
}

export interface IContaxtOutreachActivityData {
  statusId: string;
  typeId: string;
  communicationModeId: string;
  outcomeId: string;
  note: string;
  outreachDateTime: string;
  careProgramTypeId: string;
  contactId: string;
  outreachActivities: IFormattedContactOutreachActivity[];
  offset: number;
  loading: boolean;
  showMoreLoading: boolean;
  totalActivityCount: number;
  showMoreActivity: boolean;
  id?: string;
}

export const useContactOutreachActivity = (
  params: IUseContactOutreachActivity
) => {
  const LIMIT = 10;
  const mlovData = React.useContext(CommonDataContext);
  const isCareManagement = Boolean(params.isCareManagement);
  const {categoryWiseList, outreachStepOutComes} = useOutreachStepOutComes();
  const [addOrUpdateContactOutreachActivity] = useMutation(
    CreateContactOutreachActivity,
    {
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
    }
  );

  const [getUserByIds] = useLazyQuery(GET_USERS_WITH_IDS, {
    fetchPolicy: 'no-cache',
  });

  const outreachStatus = useOutreachStatus();

  const outreachModeMlov =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CARE_PROGRAM_OUTREACH_MODE
    ) || [];

  const manualOutreachModeType = outreachModeMlov?.find((mlov) => {
    if (mlov.code === CARE_PROGRAM_OUTREACH_MODE_CODES.MANUAL) return true;
  });

  let careProgramTypesMlov =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CARE_PROGRAM_TYPES
    ) || [];

  careProgramTypesMlov = careProgramTypesMlov.filter((mlov) => {
    if (mlov.code === CARE_PROGRAM_TYPES.ECM) {
      return false;
    }
    return true;
  });

  const outreachCommunicationModes = useMemo(
    () =>
      getMlovListFromCategory(
        mlovData.CARE_STUDIO_MLOV,
        MLOV_CATEGORY.CONTACT_OUTREACH_COMMUNICATION_MODE
      ) || [],
    [mlovData.CARE_STUDIO_MLOV]
  );

  const defaultData = React.useRef<IContaxtOutreachActivityData>({
    statusId: outreachStatus?.pending || '',
    typeId: manualOutreachModeType?.id || '',
    communicationModeId: outreachCommunicationModes[0]?.id || '',
    outcomeId: '',
    note: '',
    outreachDateTime: getMomentObj(new Date()).toISOString(),
    careProgramTypeId: careProgramTypesMlov[0]?.id || '',
    contactId: params.contactUuid,
    outreachActivities: [],
    offset: 0,
    loading: false,
    showMoreLoading: false,
    totalActivityCount: 0,
    showMoreActivity: false,
    id: undefined,
  });

  const [data, setData] = React.useState<IContaxtOutreachActivityData>(
    defaultData.current
  );

  useEffect(() => {
    clearDataAndFetchActivities();
  }, [data.careProgramTypeId]);

  const clearDataAndFetchActivities = () => {
    setData((prev) => {
      return {
        ...prev,
        outreachActivities: [],
      };
    });
    fetchActivities(0);
  };

  const [activeMode, setActiveMode] = React.useState<
    PatientNotesLogModeOfContact | string
  >(
    outreachCommunicationModes[0]?.code || PatientNotesLogModeOfContact.GENERAL
  );

  const [saveButtonLoading, setSaveButtonLoading] = React.useState(false);
  const userData = getUserData();

  const validateForm = (newData: IContaxtOutreachActivityData) => {
    let valid: boolean;
    if (newData?.note?.length && newData?.outcomeId?.length) {
      valid = true;
    } else {
      valid = false;
    }

    return valid;
  };

  const [getOutreachActivity] = useLazyQuery(GetContactOutreachActivities, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
  });

  const resetData = () => {
    setData((prev) => {
      return {
        ...defaultData.current,
        careProgramTypeId: prev.careProgramTypeId,
        outreachActivities: prev.outreachActivities,
        outreachDateTime: getMomentObj(new Date()).toISOString(),
        communicationModeId: outreachCommunicationModes[0]?.id || '',
      };
    });
    setActiveMode(outreachCommunicationModes[0]?.code || PatientNotesLogModeOfContact.GENERAL);
  };

  const fetchMoreActivities = () => {
    fetchActivities();
  };

  const getOutreachActivityWhereCondition = () => {
    const whereCondition = {
      contactId: {_eq: params.contactUuid},
      careProgramTypeId: {
        _eq: params.careProgramTypeId || data.careProgramTypeId,
      },
      ...(params.contactCareProgramId && {
        careProgramSteps: {
          contactCareProgram: {
            id: {
              _eq: params.contactCareProgramId,
            },
          },
        },
      }),
    };
    return whereCondition;
  }

  const fetchActivities = async (offset?: number) => {
    try {
      setData((prev) => ({
        ...prev,
        loading: true,
      }));
      const currentOffset = offset !== undefined ? offset : data.offset;
      const outreachActivityResponse = await getOutreachActivity({
        variables: {
          whereCondition: getOutreachActivityWhereCondition(),
          limit: LIMIT,
          offset: currentOffset,
        },
      });

      const availableUsers =
        outreachActivityResponse?.data?.ContactOutreachActivities?.map(
          (item: any) => item.createdBy
        );

      const totalCount =
        outreachActivityResponse?.data?.aggregateContactOutreachActivity
          ?.aggregate?.count;

      const userResponse = availableUsers?.length
        ? await getUserByIds({
            variables: {
              userIds: availableUsers,
            },
          })
        : undefined;

      const userMap = CareProgramStepOutreachUtils.getUserMapByUUID(
        userResponse?.data?.users || [],
        userData
      );

      const formattedData = formatOutreachData(
        mlovData,
        outreachActivityResponse?.data?.ContactOutreachActivities,
        userMap
      );

      setData((prev) => {
        const updatedActivities = [
          ...prev.outreachActivities,
          ...formattedData,
        ];
        return {
          ...prev,
          outreachActivities: updatedActivities,
          offset: currentOffset + LIMIT,
          loading: false,
          showMoreActivity:
            totalCount > LIMIT && updatedActivities.length < totalCount,
        };
      });
    } catch (error) {
      setData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
     }
  };



  const saveActivity = async () => {
    setSaveButtonLoading(() => true);

    const payload: AddOrUpdateContactOutreachActivityInput = {
      id: data.id || undefined,
      statusId: data.statusId,
      typeId: data.typeId,
      communicationModeId: data.communicationModeId,
      outcomeId: data.outcomeId,
      note: data.note,
      outreachDateTime: data.outreachDateTime,
      careProgramTypeId: data.careProgramTypeId,
      contactId: params.contactUuid,
    };

    const response = params.customOnSaveHandler && isCareManagement
      ? await params.customOnSaveHandler(payload)
      : await addOrUpdateContactOutreachActivity({
          variables: {
            data: payload,
          },
        });

    if (response?.data?.addOrUpdateContactOutreachActivity?.id) {
      resetData();
      clearDataAndFetchActivities();
    }
    setSaveButtonLoading(() => false);
  };

  const handleCareProgramTypeChange = useCallback((value: string) => {
    setData((prev) => {
      return {...prev, careProgramTypeId: value};
    });
  }, []);

  // Note: This is done to prefill the form for outreach when editing an old activity
  const handleEditOutreachActivity = useCallback(
    (outreachActivity: IFormattedContactOutreachActivity) => {
      const communicationMode = outreachCommunicationModes.find(
        (mlov) => mlov.code === outreachActivity?.communicationMode
      );
      setData((prev) => ({
        ...prev,
        outreachDateTime: outreachActivity.outreachData.outreachDateTime,
        communicationModeId: communicationMode?.id as string,
        outcomeId: outreachActivity?.outcomeMlov?.id,
        note: outreachActivity.note,
        id: outreachActivity.id,
      }));
      setActiveMode(communicationMode?.code as PatientNotesLogModeOfContact);
      // Note: This is done to select the relevant step in the care management view sidebar
      if (params.selectStepWhenEditingOldActivity) {
        params.selectStepWhenEditingOldActivity(outreachActivity.resourceId);
      }
    },
    [params.selectStepWhenEditingOldActivity]
  );

  return {
    data,
    careProgramTypesMlov,
    outreachCommunicationModes,
    saveButtonDisabled: validateForm(data) === false,
    saveButtonLoading,
    activeMode,
    setActiveMode,
    fetchActivities,
    setData,
    saveActivity,
    validateForm,
    resetData,
    outreachActivities: data.outreachActivities,
    fetchMoreActivities,
    loading: data.loading,
    showMoreActivity: data.showMoreActivity,
    handleCareProgramTypeChange,
    hideCareProgramType: params.hiddenFields?.includes('careProgramTypeId'),
    isCareManagement,
    handleEditOutreachActivity,
    isUpdate: Boolean(data.id),
  };
};
