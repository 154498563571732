import React, {useRef} from 'react';
import {testID} from '../../../../testUtils';
import './ContactCareProgramView.scss';
import HeaderView from './HeaderView';
import SidebarView from './CPSidebarView';
import MainContentView from './MainContentView/MainContentView';
import {
  ContactCareProgramComponentStatus,
  IContactCareProgram
} from '../interface';
import {View} from 'react-native';
import {Divider} from 'native-base';
import {Colors} from '../../../../styles';
import {Wrapper} from './components/Wrapper';
import {ContactCareProgramProvider} from './ContactCareProgram.context';
import {
  getCareProgramNameByCode
} from './ContactCareProgramView.utils';
import useContactCareProgram from './useContactCareProgram';
import CPKeyPointsView from './components/CPKeyPointsView';
import {ScrollView} from 'react-native-gesture-handler';
interface IContactCareProgramViewProps {
  contactCareProgramId: string;
}

export const ContactCareProgramView: React.FC<IContactCareProgramViewProps> = (
  props: IContactCareProgramViewProps
) => {
  const {contactCareProgramId} = props;
  const {
    componentState,
    dispatch,
    onUpdateCareProgramStatus,
    handleStepClick,
    handleHeaderAction,
    refetchContactCareProgramDetails,
  } = useContactCareProgram({
    contactCareProgramId,
  });
  const {contactCareProgramDetails} = componentState;
  const contentScrollRef = useRef<ScrollView>(null);

  const scrollToTop = () => {
    if (contentScrollRef.current) {
      contentScrollRef.current.scrollTo({y: 0, animated: true});
    }
  }
  const careProgramTypeCode= contactCareProgramDetails?.payerCareProgram?.careProgramType?.value;

  return (
    <>
      <ContactCareProgramProvider
        value={{
          dispatch: dispatch,
          state: componentState,
          onUpdateCareProgramStatus: (action) =>
            onUpdateCareProgramStatus(action, {
              careProgramId: contactCareProgramId,
              contactId: contactCareProgramDetails?.contactId || '',
            }),
          // Pls only use for complex cases where you need to refetch master data
          refetchMasterData: refetchContactCareProgramDetails,
          scrollContentViewToTop: scrollToTop,
        }}
      >
        {(() => {
          switch (componentState.status) {
            case ContactCareProgramComponentStatus.ERROR:
              return <></>;
            case ContactCareProgramComponentStatus.EMPTY:
              return <></>;
            default:
              return (
                <View
                  style={{
                    borderColor: Colors.Custom.Gray200,
                    backgroundColor: Colors.Custom.White,
                    height: '100%',
                    flex: 1,
                  }}
                  {...testID('main-view')}
                >
                  <Wrapper>
                    <HeaderView
                      componentLoading={
                        componentState.status ===
                        ContactCareProgramComponentStatus.LOADING
                      }
                      statusId={
                        componentState.contactCareProgramDetails
                          ?.statusId as string
                      }
                      careProgramTypeCode={
                        componentState.contactCareProgramDetails
                          ?.payerCareProgram?.careProgramType?.code || ''
                      }
                      careProgramName={getCareProgramNameByCode(
                        componentState.contactCareProgramDetails
                          ?.payerCareProgram?.careProgramType?.code || '',
                        componentState.contactCareProgramDetails
                          ?.payerCareProgram?.careProgramType?.value || ''
                      )}
                      careProgramId={
                        componentState.contactCareProgramDetails?.id || ''
                      }
                      contactId={
                        componentState.contactCareProgramDetails?.contactId ||
                        ''
                      }
                      careProgramAssigneeId={
                        componentState.contactCareProgramDetails?.assigneeId ||
                        ''
                      }
                      dispatch={handleHeaderAction}
                      careProgramStartDate={componentState.contactCareProgramDetails?.createdOn || ''}
                    />
                  </Wrapper>
                  <Divider />
                  <CPKeyPointsView
                    contactCareProgramId={contactCareProgramId}
                    careProgramTypeCode={careProgramTypeCode}
                    isLoading={componentState.status === ContactCareProgramComponentStatus.LOADING}
                  />
                  <Divider />
                  <div className="main-content-wrapper">
                    <SidebarView
                      contactCareProgramDetails={
                        componentState.contactCareProgramDetails as IContactCareProgram
                      }
                      onStepClick={handleStepClick}
                    />
                    <MainContentView ref={contentScrollRef} />
                  </div>
                </View>
              );
          }
        })()}
      </ContactCareProgramProvider>
    </>
  );
};
