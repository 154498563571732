import React from 'react';
import Svg, {Path} from 'react-native-svg';

const ClockCircleOutlinedSvg = () => {
  return (
    <Svg width="17" height="16" fill="none" viewBox="0 0 17 16">
      <Path
        stroke="#6F7A90"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.75 5.333V8l1.667 1.667m5-1.667A6.667 6.667 0 1 1 2.084 8a6.667 6.667 0 0 1 13.333 0"
      />
    </Svg>
  );
};

export default React.memo(ClockCircleOutlinedSvg);
