import {Viewer} from '@toast-ui/react-editor';
import {DatePicker, DatePickerProps, Popover} from "antd";
import locale from "antd/es/date-picker/locale/de_DE";
import {Content} from "antd/lib/layout/layout";
import moment from "moment";
import {Center, Divider, HStack, Image, Spinner, Text, useToast, View, VStack} from "native-base";
import React, {useRef, useState} from "react";
import {useIntl} from "react-intl";
import {Pressable} from "react-native";
import Feather from 'react-native-vector-icons/Feather';
import '../../../../../components/common/ChatUI/MessageBox/MessageViewer.css';
import {AUTO_RESPONSE_TYPES, BUTTON_TYPE} from "../../../../../constants";
import {COMMON_ACTION_CODES, CONVERSATION_ACTION_CODES} from "../../../../../constants/ActionConst";
import {Colors} from "../../../../../styles";
import {getAccountId, getAccountUUID, getUserId, getUserUUID} from "../../../../../utils/commonUtils";
import {showToast, ToastType} from "../../../../../utils/commonViewUtils";
import DeleteButtonSvg from "../../../../common/Svg/DeleteButtonSvg";
import EditLogSvg from "../../../../common/Svg/EditLogSvg";
import EditSvg from "../../../../common/Svg/EditSvg";
import RescheduleSvg from "../../../../common/Svg/RescheduleSvg";
import SendButtonSvg from "../../../../common/Svg/SendButtonSvg";
import SendScheduleMsgSvg from "../../../../common/Svg/sendScheduleMsgSvg";
import {FoldButton} from "../../../../CommonComponents/FoldButton/FoldButton";
import {capitalizeFirstLatter} from "../../../ContentManagement/CampaignManagement/Helper";
import {DISPLAY_CONVERSATION_SIDEBAR_ICON} from "../ConversationChannelNew/ConversationSidebarUtils";
import {deleteScheduleMessageApi, reScheduleMessageApi} from "../ConversationChannelTabs/CreateSmsConversationDrawer/SmsConversationServices";
import {SCHEDULE_MESSAGE_STATUS, SCHEDULE_OPTIONS_CONST, SCHEDULE_OPTIONS_LIST} from "../ConversationConst";
import {TimeType} from "../ConversationContainer/ConversationConst";
import {getScheduleMsgTime} from "../ConversationContainer/ConversationUtils";
import {IScheduleMessageInterface} from "../interfaces";
import {styles} from "./ContactSearchDrawerStyles";
import ScheduleEditView from './ScheduleMessage/ScheduleEditView';
import { disabledDateTime, getDiffInHours, isPastDay } from '../../../../../utils/DateUtils';
import MenuHorizontalIcon from '../../../../common/Svg/MenuHorizontalIcon';
import { testID } from '../../../../../testUtils';

export const ScheduleMessageList = (props:any) => {
  const {scheduleMessageData} = props;
  const [stateData,setStateData] = useState({
    showMoreOption: false,
    selectedMsg: {} as IScheduleMessageInterface,
    showSchedulePopover: false,
    loading: false
  })
  const userId = getUserId();
  const intl = useIntl();
  const datePickerRef:any = useRef(null);
  const accountUUID = getAccountUUID();
  const accountId = getAccountId();
  const userUuid = getUserUUID()
  const msgToast = useToast();
  const getChannelType = (channelType: string) =>{
    switch (channelType) {
      case AUTO_RESPONSE_TYPES.SMS:
        return <>{DISPLAY_CONVERSATION_SIDEBAR_ICON(CONVERSATION_ACTION_CODES.START_SMS, Colors.Custom.Gray500)}</>
      case AUTO_RESPONSE_TYPES.EMAIL:
        return <>{DISPLAY_CONVERSATION_SIDEBAR_ICON(CONVERSATION_ACTION_CODES.START_EMAIL, Colors.Custom.Gray500)}</>
      case AUTO_RESPONSE_TYPES.CHAT:
        return <>{DISPLAY_CONVERSATION_SIDEBAR_ICON(CONVERSATION_ACTION_CODES.START_GROUP_CHAT, Colors.Custom.Gray500)}</>
    }
  }
  const timeGreaterThan = -120000

  const datePickerFooter = () => {
    return (
      <View position={'absolute'}>
        <FoldButton
          nativeProps={{
            variant: BUTTON_TYPE.PRIMARY,
            onPress: () => {
              handleVisibleChange()
            },
            borderWidth: 0
          }}
          customProps={{
            btnText: intl.formatMessage({id: 'cancel'}),
          }}
        ></FoldButton>
      </View>
    )
  }


  const handleReScheduleMessage = async (workflowEventId:string, dateString: string) => {
    const body = {
      nextExecutionDate: dateString,
      workFlowEventId: workflowEventId,
      data: {
        accountId: accountId,
        accountUUID: accountUUID,
        scheduleMessageId: stateData?.selectedMsg?.id,
      },
      conversationId: stateData?.selectedMsg?.conversationUuid
    }
    const rescheduleResponse = await reScheduleMessageApi(body);
    if (rescheduleResponse?.data?.id) {
      setStateData((prev)=>{
        return {
          ...prev,
          loading: false,
        }
      })
      showToast(
        msgToast,
        intl.formatMessage({id: 'reScheduleSuccess'}),
        ToastType.success
      );
      props?.refetchScheduleData()
    } else {
      setStateData((prev)=>{
        return {
          ...prev,
          loading: false,
        }
      })
      showToast(
        msgToast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error
      );
    }
  }

  const onOk = (value: DatePickerProps['value']) => {
    setStateData((prev)=>{
      return {
        ...prev,
        loading: true,
      }
    })
    const dateString = moment.utc(value).format();
    const currentTime = moment(new Date());
      const duration = moment.duration(currentTime.diff(dateString));
      if (duration.asMilliseconds() >= timeGreaterThan) {
        showToast(
          msgToast,
          intl.formatMessage({id: 'selectTimeInFuture'}),
          ToastType.error
        );
        setStateData((prev)=>{
          return {
            ...prev,
            loading: false,
          }
        })
        return;
      }
    handleVisibleChange();
    handleReScheduleMessage(stateData?.selectedMsg?.workflowEventId, dateString)
  };

  const getActionIcons = (action: string) => {
    switch (action) {
      case SCHEDULE_OPTIONS_CONST.RESCHEDULE:
        return <RescheduleSvg
          customStrokeColor={Colors.Custom.Gray500}
          isEnabled={true}
        />
      case SCHEDULE_OPTIONS_CONST.SEND:
        return <SendButtonSvg
          customStrokeColor={Colors.Custom.Gray500}
          isEnabled={true}
      />
      case SCHEDULE_OPTIONS_CONST.DELETE:
        return <DeleteButtonSvg
          customStrokeColor={Colors.Custom.Gray500}
          isEnabled={true}
      />
      case SCHEDULE_OPTIONS_CONST.EDIT_LOG:
        return <EditLogSvg
          customStrokeColor={Colors.Custom.Gray500}
          isEnabled={true}
      />
      case SCHEDULE_OPTIONS_CONST.EDIT:
        return <EditSvg
          customColor={Colors.Custom.Gray500}
      />
    }
  }


  const reScheduleMessage = (message: IScheduleMessageInterface) => {
    setStateData((prev)=>{
      return {
        ...prev,
        showMoreOption: false,
        showSchedulePopover: true,
        selectedMsg: message,
      }
    })
  }

  const sendMessage = () => {
    setStateData((prev)=>{
      return {
        ...prev,
        showMoreOption: false,
      }
    })
    const dateString = moment.utc(Date.now()).format();
    const sendScheduleMessageBody ={
      content: stateData?.selectedMsg?.content,
      scheduledAt: dateString,
      scheduleMessageId: stateData?.selectedMsg?.id,
      workFlowEventId: stateData?.selectedMsg?.workflowEventId,
      conversationId: stateData?.selectedMsg?.conversationUuid
    }
    props?.handleSendMessage(sendScheduleMessageBody)
  }

  const handleDeleteMessage = async (message:IScheduleMessageInterface) => {
    setStateData((prev)=>{
      return {
        ...prev,
        showMoreOption: false,
        selectedMsg: message,
        loading: true
      }
    })
    const body = {
      workFlowEventId: message?.workflowEventId,
      scheduleMessageId: stateData?.selectedMsg?.id,
      conversationId: stateData?.selectedMsg?.conversationUuid
    }
    const deleteScheduleResponse = await deleteScheduleMessageApi(body);
    if (deleteScheduleResponse?.data?.id) {
      setStateData((prev)=>{
        return {
          ...prev,
          loading: false
        }
      })
      showToast(
        msgToast,
        intl.formatMessage({id: 'deleteSuccess'}),
        ToastType.success
      );
      props?.refetchScheduleData()
    } else {
      setStateData((prev)=>{
        return {
          ...prev,
          loading: false
        }
      })
      showToast(
        msgToast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error
      );
    }
  }

  const showLogs = () => {
    handleVisibleChange()
  }

  const handlePopoverAction = (action: string, message: IScheduleMessageInterface) => {
    switch (action) {
      case SCHEDULE_OPTIONS_CONST.RESCHEDULE:
        reScheduleMessage(message)
        break;
      case SCHEDULE_OPTIONS_CONST.SEND:
        sendMessage()
        break;
      case SCHEDULE_OPTIONS_CONST.DELETE:
        handleDeleteMessage(message)
        break;
      case SCHEDULE_OPTIONS_CONST.EDIT_LOG:
        showLogs()
        break;
      case SCHEDULE_OPTIONS_CONST.EDIT:
       props.onFormCompleteAction?.(COMMON_ACTION_CODES.EDIT, message)
       setStateData((prev)=>{
        return {
          ...prev,
          showMoreOption: false,
        }
      })
      break;
    }
  }

  const showAction = (message: IScheduleMessageInterface, action: string) => {
    switch (action) {
      case SCHEDULE_OPTIONS_CONST.RESCHEDULE:
      case SCHEDULE_OPTIONS_CONST.EDIT:
        if (message?.status !== SCHEDULE_MESSAGE_STATUS.SUCCESS) {
          return true;
        }
        return false;
      case SCHEDULE_OPTIONS_CONST.SEND:
        if (message?.status !== SCHEDULE_MESSAGE_STATUS.SUCCESS) {
          return true;
        }
        return false;
      case SCHEDULE_OPTIONS_CONST.DELETE:
        if (message?.status !== SCHEDULE_MESSAGE_STATUS.SUCCESS) {
          return true;
        }
        return false;
      case SCHEDULE_OPTIONS_CONST.EDIT_LOG:
        return true;
      default :
        return false;
    }
  }

  const moreOptionsElements = (message: IScheduleMessageInterface) => {
    const actionOptionList =
      message.accountUser?.user?.uuid === userUuid
        ? SCHEDULE_OPTIONS_LIST
        : SCHEDULE_OPTIONS_LIST?.filter((item) => {
            return item !== SCHEDULE_OPTIONS_CONST.EDIT;
          });
    return (
      <>
        {actionOptionList.map((action,index)=>{
          return <>
            { showAction(message,action) && <View my={3} mx={1}>
              <Pressable
                onPress={() => {
                  handlePopoverAction(action,message)
                }}
              >
                <HStack alignItems={'center'} space={3}>
                  <View height={'20px'} width={'20px'}>
                    {getActionIcons(action)}
                  </View>
                  <Text color={Colors.Custom.Gray500}>{action}</Text>
                </HStack>
                {index !== SCHEDULE_OPTIONS_LIST.length - 1 && <Divider mt={2} />}
              </Pressable>
            </View>}
          </>
        })}
      </>
    );
  };

  const handleVisibleChange = () => {
    setStateData((prev)=>{
      return {
        ...prev,
        showMoreOption: false,
        showSchedulePopover: false,
        selectedMsg: {} as IScheduleMessageInterface
      }
    })
  }

  const renderItem = (message: IScheduleMessageInterface) => {
    return (
      <View key={message.id} marginBottom={8}>
        <HStack
          justifyContent={'space-between'}
          borderBottomWidth={1}
          paddingBottom={2}
          borderColor={Colors.Custom.Gray200}
        >
          <HStack space={2}>
            <View
              justifyItems={'center'}
              alignItems={'center'}
              alignSelf={'center'}
              justifySelf={'center'}
              height={'24px'}
              width={'24px'}
            >
              {getChannelType(message?.channelType)}
            </View>
            <Text fontSize={16} fontWeight={600}>
              {message?.channelType === AUTO_RESPONSE_TYPES.SMS
                ? message?.channelType
                : capitalizeFirstLatter(message?.channelType)}
            </Text>
          </HStack>
          <HStack space={2} alignItems={'center'}>
            {message?.status === 'failed' && (
              <HStack alignItems={'center'} space={1}>
                <Feather
                  name="alert-circle"
                  style={styles.articleCircleIcon}
                />
                <Text color={Colors.danger[600]}>{'Failed'}</Text>
              </HStack>
            )}
            <View ref={datePickerRef} position={'absolute'}>
              <DatePicker
                className="popover-class"
                open={
                  stateData?.showSchedulePopover &&
                  message?.id === stateData?.selectedMsg?.id
                }
                showTime={{format: 'HH:mm'}}
                renderExtraFooter={() => <>{datePickerFooter()}</>}
                disabledDate={(current: any) => {
                  return current && isPastDay(current);
                }}
                onOk={onOk}
                disabledTime={disabledDateTime}
                locale={{
                  ...locale,
                  lang: {
                    ...locale.lang,
                    ok: 'Schedule Send',
                  },
                }}
              />
            </View>
            {message?.status !== SCHEDULE_MESSAGE_STATUS.SUCCESS && (
              <Popover
                overlayInnerStyle={{
                  bottom: 3,
                  borderRadius: 16,
                  padding: 0,
                }}
                content={moreOptionsElements(message)}
                trigger="click"
                placement="bottom"
                visible={
                  stateData?.showMoreOption &&
                  stateData?.selectedMsg?.id === message?.id
                }
                onVisibleChange={handleVisibleChange}
                overlayStyle={{borderRadius: 8, width: 200}}
              >
                <Pressable
                  onPress={() => {
                    setStateData((prev) => {
                      return {
                        ...prev,
                        showMoreOption: true,
                        selectedMsg: message,
                      };
                    });
                  }}
                >
                  <View {...testID('image')}>
                    <MenuHorizontalIcon height={18} width={18}/>
                  </View>
                </Pressable>
              </Popover>
            )}
          </HStack>
        </HStack>
        <>
          <HStack px={1} marginTop={4} space={2} alignItems={'center'}>
            <Text color={'#825AC7'} fontWeight={500}>
              {userId === message?.senderId
                ? 'You'
                : message?.accountUser?.user?.name || ''}
            </Text>
            <View>
              <HStack space={1} alignItems={'center'} height={'100%'}>
                <View height={'16px'} width={'16px'}>
                  <SendScheduleMsgSvg
                    customStrokeColor={
                      message?.status === SCHEDULE_MESSAGE_STATUS.SCHEDULED
                        ? Colors.Custom.Gray500
                        : message?.status === SCHEDULE_MESSAGE_STATUS.SUCCESS
                        ? Colors.success[500]
                        : Colors.error[600]
                    }
                    isEnabled={true}
                  />
                </View>
                <Text fontSize={12} color={Colors.Custom.Gray500}>
                  {`Scheduled for ${getScheduleMsgTime(
                    message?.scheduledAt,
                    TimeType.timeAgoFullDate
                  )}`}
                </Text>
              </HStack>
            </View>
          </HStack>
          {message.isEditModeOn ? (
            <ScheduleEditView
              message={message}
              onFormCompleteAction={props.onFormCompleteAction}
            />
          ) : (
            <HStack
              mt={1}
              p={4}
              borderWidth={1}
              borderColor={Colors.Custom.Gray200}
              borderRadius={6}
            >
              <Content className="link-message link-font-size">
                <Viewer
                  linkAttributes={{
                    target: '_blank',
                  }}
                  extendedAutolinks={true}
                  initialValue={message?.content}
                />
              </Content>
            </HStack>
          )}
        </>
      </View>
    );
  };

  return (
    <>
      {stateData?.loading && <Spinner size="lg" style={styles.spinnerStyle} />}
      {scheduleMessageData?.length ?
      <>
        {scheduleMessageData?.map((message:IScheduleMessageInterface)=>{
          return renderItem(message)
        })}
      </> :
      <>
        <VStack flex={1}>
          <Center
            alignItems={'center'}
            justifyContent={'center'}
            alignContent={'center'}
            height={'100%'}
          >
            <Text fontSize={16} fontWeight={500} color={Colors.FoldPixel.GRAY400}>
              {'No Schedule Logs'}
            </Text>
            <Image
              mt={4}
              resizeMode="cover"
              style={styles.noConversationImage}
              source={require('../../../../../assets/images/noConversation.png')}
            />
          </Center>
        </VStack>
      </>
      }
    </>
  )
}