import React, {useCallback, useMemo} from 'react';
import {testID} from '../../../../testUtils';
import {View, Text} from 'react-native';
import {Colors} from '../../../../styles';
import Stack from '../../../common/LayoutComponents/Stack';
import CrossIconSvg from '../../../common/Svg/CrossIconSvg';
import {useContactCareProgramContext} from './ContactCareProgram.context';
import {getCareProgramNameByCode} from './ContactCareProgramView.utils';
import Feather from 'react-native-vector-icons/Feather';
import {useNavigate} from 'react-router-dom';
import CPStatusBadge from './components/CPStatusBadge';
import FoldButtonV2, {
  ButtonType,
} from '../../../PersonOmniView/MiddleContainer/CarePlan/components/FoldButtonV2';
import {CARE_PROGRAM_TYPE_CODES, CONTACT_CARE_PROGRAM_STATUS_CODES} from '../../../../constants/MlovConst';
import useCareProgramStatusAction, {
  CareProgramStatusAction,
} from './hooks/useCareProgramStatusAction';
import {
  ContactCareProgramReducerAction,
  IContactCareProgramReducerAction,
} from './reducer';
import {
  getMlovCodeFromId,
} from '../../../../utils/mlovUtils';
import {useCareProgramStatus} from './hooks/useCareProgramStatus';
import CareProgramAssignmentView from './components/CareProgramAssignmentView';
import {Divider, Skeleton} from 'antd';
import TimeLoggingComponent from '../../TeamInbox/Conversations/MessagingContactDetails/TimeLoggingComponent';
import { useCareProgramEditability } from './hooks/useCareProgramEditability';
import { useCustomToast } from '../../../Toast/ToastProvider';
import { useCareProgramBillingCheck } from './MainContentView/components/BillingView/useCareProgramBillingCheck';
import { ToastType } from '../../../../utils/commonViewUtils';
import { useIntl } from 'react-intl';

interface IContactCareProgramHeaderViewProps {
  statusId: string;
  careProgramTypeCode: string;
  careProgramName: string;
  careProgramId: string;
  contactId: string;
  componentLoading: boolean;
  careProgramAssigneeId?: string;
  dispatch: (action: ContactCareProgramReducerAction, payload: any) => void;
  careProgramStartDate: string;
}

const HeaderView: React.FC<IContactCareProgramHeaderViewProps> = (
  props: IContactCareProgramHeaderViewProps
) => {
  const {
    statusId,
    careProgramTypeCode,
    careProgramName,
    careProgramId,
    contactId,
    componentLoading,
    careProgramAssigneeId,
    dispatch,
    careProgramStartDate
  } = props;
  const navigate = useNavigate();
  const intl = useIntl();
  const {careProgramStatusList} = useCareProgramStatus();
  const { checkBillingStatus } = useCareProgramBillingCheck();
  const toast = useCustomToast();
  const {isAllowedToTakeActions} = useCareProgramEditability();
  const statusCode = getMlovCodeFromId(careProgramStatusList, statusId);
  const {loadingAction, handleAction} = useCareProgramStatusAction();

  const handleClose = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const canComplete = [CONTACT_CARE_PROGRAM_STATUS_CODES.IN_PROGRESS].includes(
    statusCode as string
  );

  const handleMarkAsComplete = useCallback(async () => {
    if (careProgramTypeCode === CARE_PROGRAM_TYPE_CODES.CCM || careProgramTypeCode === CARE_PROGRAM_TYPE_CODES.ECM) {
      const hasBilling = await checkBillingStatus(careProgramId);
      if (!hasBilling) {
        toast({
          message: intl.formatMessage({id: 'pleaseGenerateBillBeforeCompleting'}),
          toastType: ToastType.error,
        });
        return;
      }
    }
    const newStatusId = await handleAction(CareProgramStatusAction.COMPLETE, {
      careProgramId,
      contactId,
    });
    if (newStatusId) {
      dispatch(
        ContactCareProgramReducerAction.UPDATE_CARE_PROGRAM_STATUS,
        newStatusId
      );
      dispatch(ContactCareProgramReducerAction.SHOW_COMPLETED_VIEW, {});
    }
  }, [handleAction, dispatch]);

  const handleCareProgramAssignee = (assigneeId?: string) => {
    if (assigneeId) {
      dispatch(
        ContactCareProgramReducerAction.UPDATE_CARE_PROGRAM_ASSIGNEE,
        assigneeId
      );
    }
  };

  const completeActionLabel = useMemo(() => {
    if (careProgramTypeCode === CARE_PROGRAM_TYPE_CODES.AWV) {
      return 'Complete Visit';
    }
    return 'Complete Program';
  }, [careProgramTypeCode]);

  return (
    <div className="flex flex-row justify-between items-center header-view">
      <div className="flex flex-row">
        {componentLoading ? (
          <Skeleton.Input
            active
            style={{
              width: 500,
              borderRadius: 8,
            }}
          />
        ) : (
          <div className="flex flex-row items-center">
            <Text style={{fontSize: 14, fontWeight: '500', marginRight: 4}}>
              {careProgramName
                ? `${careProgramName} (${careProgramTypeCode})`
                : ''}
            </Text>
            <CPStatusBadge statusCode={statusCode} />
            <CareProgramAssignmentView
              contactId={contactId}
              contactCareProgramId={careProgramId}
              careProgramAssigneeId={careProgramAssigneeId}
              onAssigneeActionPerformed={handleCareProgramAssignee}
            />
          </div>
        )}
      </div>

      <div className="flex flex-row items-center">
      {isAllowedToTakeActions &&
      (careProgramTypeCode === CARE_PROGRAM_TYPE_CODES.CCM || careProgramTypeCode === CARE_PROGRAM_TYPE_CODES.ECM) &&
      ![CONTACT_CARE_PROGRAM_STATUS_CODES.COMPLETED, CONTACT_CARE_PROGRAM_STATUS_CODES.CLOSED, CONTACT_CARE_PROGRAM_STATUS_CODES.AUTO_CLOSED].includes(statusCode) && (
        <TimeLoggingComponent contactUuid={contactId} contactCareProgramId={careProgramId} careProgramStartDate={careProgramStartDate}/>
        )}
      <Divider type="vertical" style={antStyles.dividerStyle} />
        { canComplete && (
          <FoldButtonV2
            label={completeActionLabel}
            isDisabled={!canComplete}
            buttonType={ButtonType.tertiary}
            isLoading={loadingAction === CareProgramStatusAction.COMPLETE}
            onPress={handleMarkAsComplete}
          />
        )}
        <div className="hover-pointer" onClick={handleClose}>
          <Feather name="x" size={24} color={Colors.Custom.Gray500} />
        </div>
      </div>
    </div>
  );
};

const antStyles: Record<string, React.CSSProperties> = {
  dividerStyle: {
    backgroundColor: Colors.FoldPixel.GRAY50,
    alignSelf: 'center',
    height: 32,
  },
};

export default React.memo(HeaderView);
