import {Collapse, Drawer, Input, Tabs} from 'antd';
import {HStack, Icon, Modal, ScrollView, Text, useMediaQuery, View, VStack} from 'native-base';
import {FC, useContext, useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {
  FlowType,
  WorkflowContext,
} from '../../../../../context/WorkflowContext';
import {NodeCategory} from '../../../../common/CircleIconView/CircleIconView';
import NewLibNodeCard from '../../LibNodeCard/NewLibNodeCard';
import {workflowTriggerStyle} from '../../Workflow/Styles';
import {runFoldAutomationSvg} from '../../../../common/Svg/NewWorkflowTableViewSvg/NewWorkflowTableViewSvg';
import './../../Workflow/Styles.css';
import {SearchOutlined} from '@ant-design/icons';
import {DragEvent} from 'react';
import { Colors } from '../../../../../styles';
import { useSearchParams } from 'react-router-dom';
import { SMALL_WINDOW } from '../../../../../constants';
import { ZapGradientIcon } from '../../../../common/CircleIconView/CustomAutomationIcon';
import { reactStyles, styles } from '../../workflowStyles';

const CATEGORY = {
  OPERATION: 'OPERATION',
  FILTER: 'FILTER',
  CONDITION: 'CONDITION'
}
export interface INode {
  id?: string;
  title: string;
  nodeType: string;
  description?: string;
  masterId?: string;
}
export interface ILibNodeDetail {
  code: string;
  display: string;
  nodeList: INode[];
  description?: string;
}

const onDragStart = (event: DragEvent, node: INode) => {
  event.dataTransfer.setData('application/reactflow', JSON.stringify(node));
  event.dataTransfer.effectAllowed = 'move';
};

const NewOutputModelV2: FC<{
  allOutputNodeList: any;
  parentDisplayName?: any;
  getOutputNodeList: any;

  onPress?: any;
  canDo?: boolean;
  setIsEdit?: (isOpen: boolean) => void;
  isAddedByFold?: boolean;

  sourceHandle?: string;
}> = ({
  onPress,
  sourceHandle,
  getOutputNodeList,
  parentDisplayName,
  allOutputNodeList,
  setIsEdit,
  canDo,
  isAddedByFold,
}) => {
  const [searchText, setSearchText] = useState('');
  const [hasMatchingConditionNodes, sethasMatchingConditionNodes] = useState(false)
  const [hasMatchingActionNodes, sethasMatchingActionNodes] = useState(false);
  const workflowContext = useContext(WorkflowContext);
  const nodeMasterDataMap = workflowContext.nodeMasterDataMap;
  const intl = useIntl();

  const [selectableNodeTypeList, setSelectableNodeTypeList] =
    useState(allOutputNodeList);

  const [nodeByCategory, setNodeByCategory]  = useState<any>({});
  const rowLength = 1;
  const placeHolderList: any = [];
  let index = 0;

  useEffect(() => {
    if(workflowContext?.focusOnSelectNextNode?.isFocusOnSelectNextNode){
    const nodeType = workflowContext?.focusOnSelectNextNode?.nodeType;
    let outputNodeList = nodeType
      ? JSON.parse(JSON.stringify(nodeMasterDataMap[nodeType]?.outputNodeList || [])) || []
      : [];

    const sourceHandleOutputNodeList =  workflowContext?.focusOnSelectNextNode?.sourceHandle
      ? nodeMasterDataMap[workflowContext?.focusOnSelectNextNode?.sourceHandle]?.outputNodeList || []
      : [];

    sourceHandleOutputNodeList.forEach((sourceHandleOutputNodeType?:string)=>{
      if(sourceHandleOutputNodeType && outputNodeList.indexOf(sourceHandleOutputNodeType) == -1) {
        outputNodeList.push(sourceHandleOutputNodeType)
      }
    })

    const mergeParentNodeOutputList =
      workflowContext.flowType !== FlowType.careJourney;

    
    outputNodeList = getOutputNodeList
      ? getOutputNodeList(
          workflowContext?.focusOnSelectNextNode?.sourceId,
          outputNodeList,
          false,
          mergeParentNodeOutputList
        )
      : outputNodeList;

    outputNodeList = outputNodeList.filter((id: string) => {
      return id != nodeType || id == 'AutomationTrigger';
    });
    
    setSelectableNodeTypeList(outputNodeList);
  } else {
    setSelectableNodeTypeList(allOutputNodeList);
  }
  }, [workflowContext.focusOnSelectNextNode?.isFocusOnSelectNextNode]);

  while (index < rowLength) {
    placeHolderList.push(
      <View paddingX={2} backgroundColor={'white'} flex={1}></View>
    );
    index = index + 1;
  }

  const onPressOutputNode = (data: any, isEdit: boolean) => {
    const outputNodeType = data.node;
    if(!selectableNodeTypeList.includes(outputNodeType)){
      return
    }

    const disableEdit = nodeMasterDataMap[outputNodeType]?.disableEdit;
    data.isEdit = !disableEdit;
    onPress(data);
  };


  const [actionNodes, setActionNodes] = useState<any>([])

  useEffect(()=>{
    const processedMap: any = {};
    const nodeByCategory: any = {}
    allOutputNodeList.forEach((nodeType: string | number) => {
      if (
        nodeMasterDataMap[nodeType] &&
        nodeMasterDataMap?.[nodeType].displayName &&
        !nodeMasterDataMap[nodeType]?.isDeprecated &&
        nodeMasterDataMap[nodeType]?.category?.code &&
        !processedMap[nodeType]
      ) {
        processedMap[nodeType] = nodeType;
        if (!nodeByCategory[nodeMasterDataMap[nodeType].category?.code]) {
          nodeByCategory[nodeMasterDataMap[nodeType].category?.code] = {
            list: [[]],
            ...nodeMasterDataMap[nodeType].category,
          };
        }
        let index =
          nodeByCategory[nodeMasterDataMap[nodeType]?.category?.code].list
            .length - 1;
        if (
          nodeByCategory[nodeMasterDataMap[nodeType]?.category?.code].list[index]
            .length >= rowLength
        ) {
          nodeByCategory[nodeMasterDataMap[nodeType]?.category?.code].list.push(
            []
          );
          index = index + 1;
        }
        nodeByCategory[nodeMasterDataMap[nodeType]?.category?.code].list[
          index
        ].push(nodeType);
      }
    });
    setNodeByCategory(nodeByCategory)

    const hasMatchingConditionNodes = nodeByCategory[CATEGORY.FILTER]?.list
    .flat()
    .some((outputNodeType: string) =>
      (nodeMasterDataMap?.[outputNodeType]?.displayName || outputNodeType)
        .toLowerCase()
        .includes(searchText.toLowerCase())
    );
    sethasMatchingConditionNodes(hasMatchingConditionNodes)


    const actionNodes = Object.keys(nodeByCategory).filter(category => ![CATEGORY.OPERATION, CATEGORY.FILTER].includes(category));
    let hasMatchingActionNodes = false;
    actionNodes.map((category) => {
      hasMatchingActionNodes = hasMatchingActionNodes || nodeByCategory[category]?.list
      .flat()
      .some((outputNodeType: string) =>
      (nodeMasterDataMap?.[outputNodeType]?.displayName || outputNodeType)
      .toLowerCase()
      .includes(searchText.toLowerCase())
      );
    })
    setActionNodes(actionNodes)
    sethasMatchingActionNodes(hasMatchingActionNodes);


  }, [searchText, selectableNodeTypeList])



  const getOperationNodes = () => {
    const finalNode = (
      <HStack space={3} width="full">
        {nodeByCategory['OPERATION'] && (
          <VStack flex={1} backgroundColor={'white'} key={'OPERATION'}>
            {/* <View style={ { marginLeft: 5 }}>
                <Text size="smMedium" color="#000000">
                  {nodeByCategory['OPERATION'].display}
                </Text>
                <Text size="smNormal" color={Colors.Custom.Gray500}>
                  {`${nodeByCategory['OPERATION'].list.reduce((a: number, b: string[]) => a + b.length, 0)} blocks available`}
                </Text>
              </View> */}
            <HStack
              flex={1}
              style={styles.view}
            >
              {nodeByCategory['OPERATION'].list.map(
                (subList: string[], subIndex: number) => (
                  <HStack flex={1} key={subIndex}>
                    {subList.map(
                      (outputNodeType: string, itemIndex: number) => (
                        <View
                          flex={1}
                          style={{
                            borderRightWidth:
                              subIndex ===
                              nodeByCategory['OPERATION']?.list?.length - 1
                                ? 0
                                : 1,
                            borderColor: Colors.FoldPixel.GRAY200,
                          }}
                          // paddingTop={itemIndex === 0 ? 2 : 1}
                          // paddingBottom={itemIndex === subList.length - 1 ? 2 : 1}
                          key={outputNodeType + 'OPERATION' + itemIndex}
                        >
                          <NewLibNodeCard
                            isFirstNode={subIndex === 0}
                            isLastNode={nodeByCategory[CATEGORY.OPERATION]?.list?.length - 1 === subIndex}
                            isNodeEnabled={selectableNodeTypeList.includes(outputNodeType)}
                            title={
                              nodeMasterDataMap?.[outputNodeType]
                                ?.displayName || outputNodeType
                            }
                            iconInfo={
                              nodeMasterDataMap?.[outputNodeType]?.iconInfo
                            }
                            nodeCategory={
                              nodeMasterDataMap?.[outputNodeType]?.category
                                ?.code || NodeCategory.moment
                            }
                            isOperationNode={true}
                            onClick={() => {
                              onPressOutputNode(
                                {
                                  sourceHandle: sourceHandle,
                                  node: outputNodeType,
                                  metaData: {},
                                },
                                true
                              );
                            }}
                          />
                        </View>
                      )
                    )}
                    {placeHolderList
                      .filter(
                        (element: any, itemIndex: number) =>
                          itemIndex >= subList.length
                      )
                      .map((element: any, itemIndex: number) => (
                        <View flex={1} key={itemIndex}>
                          {element}
                        </View>
                      ))}
                  </HStack>
                )
              )}
            </HStack>
          </VStack>
        )}
      </HStack>
    );
    return finalNode;
  };



  const GetConditionNodes = () => {
    if (!hasMatchingConditionNodes) {
      return null;
    }
    return (
      <ScrollView style={styles.scrollViewStyle}>
        <VStack space={3} width="full">
          {nodeByCategory['FILTER'] && (
            <VStack
              flex={1}
              backgroundColor={'#white'}
              key={'FILTER'}
              borderRadius={'8px'}
            >
              <View
                style={[workflowTriggerStyle.workflowSubtitle, {padding: 6}]}
              >
                <Text size="smMedium" color="#000000" textTransform={'uppercase'}>
                  {nodeByCategory['FILTER'].display}
                </Text>
                {/* <Text size="smNormal" color={Colors.Custom.Gray500}>
                  {`${nodeByCategory['OPERATION'].list.reduce((a: number, b: string[]) => a + b.length, 0)} blocks available`}
                </Text> */}
              </View>
              <View
                style={styles.view}
                backgroundColor={Colors.FoldPixel.GRAY200}
              >
                {nodeByCategory['FILTER'].list.map(
                  (subList: string[], subIndex: number) => (
                    <HStack flex={1} key={subIndex}>
                      {subList
                        .filter((outputNodeType: string) =>
                          (
                            nodeMasterDataMap?.[outputNodeType]?.displayName ||
                            outputNodeType
                          )
                            .toLowerCase()
                            .includes(searchText.toLowerCase())
                        )
                        .map((outputNodeType: string, itemIndex: number) => (
                          <View
                            flex={1}
                            // style={ {
                            //   borderColor: Colors.FoldPixel.GRAY200,
                            //   borderBottomWidth:
                            //     subIndex ===
                            //     nodeByCategory['FILTER']?.list?.length - 1
                            //       ? 0
                            //       : 1,
                            // }}
                            key={outputNodeType + 'FILTER' + itemIndex}
                          >
                            <NewLibNodeCard
                              isFirstNode={subIndex === 0}
                              isLastNode={nodeByCategory[CATEGORY.FILTER]?.list?.length - 1 === subIndex}
                              title={
                                nodeMasterDataMap?.[outputNodeType]
                                  ?.displayName || outputNodeType
                              }
                              isNodeEnabled={selectableNodeTypeList.includes(outputNodeType)}
                              iconInfo={
                                nodeMasterDataMap?.[outputNodeType]?.iconInfo
                              }
                              nodeCategory={
                                nodeMasterDataMap?.[outputNodeType]?.category
                                  ?.code || NodeCategory.moment
                              }
                              isConditionNode={true}
                              onClick={() => {
                                onPressOutputNode(
                                  {
                                    sourceHandle: sourceHandle,
                                    node: outputNodeType,
                                    metaData: {},
                                  },
                                  true
                                );
                              }}
                            />
                          </View>
                        ))}
                      {placeHolderList
                        .filter(
                          (element: any, itemIndex: number) =>
                            itemIndex >= subList.length
                        )
                        .map((element: any, itemIndex: number) => (
                          <View flex={1} key={itemIndex}>
                            {element}
                          </View>
                        ))}
                    </HStack>
                  )
                )}
              </View>
            </VStack>
          )}
        </VStack>
      </ScrollView>
    );
  };



  const shouldRenderCategory = (sortedList: string[][]) => {
    return sortedList.some((subList: string[]) => {
      return subList.some((outputNodeType: any) => {
        return doesNodeMatchSearch(outputNodeType)
    })})
  };

  const doesNodeMatchSearch = (outputNodeType: string) => {
    return (
      (nodeMasterDataMap?.[outputNodeType]?.displayName || outputNodeType)
      .toLowerCase()
      .includes(searchText.toLowerCase())
    )
  };

  const sortByPriority = (nodeTypeA: number, nodeTypeB: number) => {
    const priorityA = nodeMasterDataMap?.[nodeTypeA]?.nodeSequencePriority || 0;
    const priorityB = nodeMasterDataMap?.[nodeTypeB]?.nodeSequencePriority || 0;
    return priorityB - priorityA;
  };

  const getSortedList = (category : string) =>{
    return (
      nodeByCategory[category].list.sort(sortByPriority)
    )
  }


  const RenderCategory = (props:any) => {
    const {
      category,
      index,
      sortedList
    } = props

    const renderCategory = shouldRenderCategory(sortedList)
    if (renderCategory) {
      return (
        <VStack flex={1} backgroundColor={'white'} key={category + index} borderRadius={'8px'}>
          {renderCategory && (
            <View style={[workflowTriggerStyle.workflowSubtitle, { padding: 6 }]}>
              <Text size="smMedium" color={Colors.FoldPixel.GRAY400} textTransform={'uppercase'}>
                {nodeByCategory[category].display}
              </Text>
            </View>
          )}
          <View
            style={{
              borderColor: Colors.FoldPixel.GRAY200,
              borderRadius: 8,
              borderWidth: renderCategory ? 1 : 0,
              backgroundColor: Colors.FoldPixel.PRIMARY100,
            }}
          >
            <MapSortedList
              sourceHandle={sourceHandle}
              onPress={onPress}
              sortedList={sortedList}
              category={category}
              doesNodeMatchSearch={doesNodeMatchSearch}
              nodeByCategory={nodeByCategory}
              nodeMasterDataMap={nodeMasterDataMap}
            />
          </View>
        </VStack>
      );
    }
    return <></>;
  };
  // const MapSortedList = (props: any) => {
  //   const {
  //     sourceHandle,
  //     onPress,
  //     showModalData,
  //     setShowModalData,
  //     sortedList,
  //     category,
  //     doesNodeMatchSearch,
  //     nodeByCategory,
  //     nodeMasterDataMap
  //   } = props;

  //   const [renderedNodes, setRenderedNodes] = useState(<></>)

  //   const calculateNewValue = () => {
  //     const renderedNodes: any = (sortedList || []).map((subList: string[], subIndex: number) => (
  //       <HStack flex={1} key={subIndex}>
  //         {subList
  //           .filter(outputNodeType => {
  //             return doesNodeMatchSearch(outputNodeType)
  //           })
  //           .map((outputNodeType, itemIndex) => (
  //             <View
  //               flex={1}
  //               // style={ {
  //               //   borderColor: Colors.FoldPixel.GRAY200,
  //               //   borderBottomWidth: subIndex === (nodeByCategory[category]?.list?.length - 1) ? 0 : 1,
  //               // }}
  //               key={outputNodeType + category + itemIndex + '_subIndex_' + subIndex}
  //             >
  //               <NewLibNodeCard
  //                 tooltip={(nodeMasterDataMap?.[outputNodeType]?.tooltip)}
  //                 isFirstNode={subIndex === 0}
  //                 isLastNode={sortedList?.length - 1 === subIndex}
  //                 isNodeEnabled={selectableNodeTypeList.includes(outputNodeType)}
  //                 title={(nodeMasterDataMap?.[outputNodeType])?.displayName || outputNodeType}
  //                 iconInfo={(nodeMasterDataMap?.[outputNodeType])?.iconInfo}
  //                 nodeCategory={(nodeMasterDataMap?.[outputNodeType])?.category?.code || NodeCategory.moment}
  //                 isActionNode={true}
  //                 onClick={() => {
  //                   onPressOutputNode(
  //                     {
  //                       sourceHandle: sourceHandle,
  //                       node: outputNodeType,
  //                       metaData: {},
  //                     },
  //                     true
  //                   );
  //                 }}
  //               />
  //             </View>
  //           ))}
  //       </HStack>
  //     ))
  //     setRenderedNodes(renderedNodes)
  //   }

  //   useEffect(() => {
  //     console.log("HII sortedList", sortedList)
  //     calculateNewValue()
  //   }, [sortedList])

  //   return <>{renderedNodes}</>
  // }


  const MapSortedList = (props: any) => {
    const {
      sourceHandle,
      onPress,
      showModalData,
      setShowModalData,
      sortedList,
      category,
      doesNodeMatchSearch,
      nodeByCategory,
      nodeMasterDataMap
    } = props;

    const renderedNodes = useMemo(() => {
      return (sortedList || []).map((subList: string[], subIndex: number) => (
        <HStack flex={1} key={subIndex}>
          {subList
            .filter(outputNodeType => doesNodeMatchSearch(outputNodeType))
            .map((outputNodeType, itemIndex) => (
              <View
                flex={1}
                // style={ {
                //   borderColor: Colors.FoldPixel.GRAY200,
                //   borderBottomWidth: subIndex === (nodeByCategory[category]?.list?.length - 1) ? 0 : 1,
                // }}
                key={`${outputNodeType}${category}${itemIndex}_subIndex_${subIndex}`}
              >
                <NewLibNodeCard
                  tooltip={nodeMasterDataMap?.[outputNodeType]?.tooltip}
                  isFirstNode={subIndex === 0}
                  isLastNode={sortedList?.length - 1 === subIndex}
                  isNodeEnabled={selectableNodeTypeList.includes(outputNodeType)}
                  title={nodeMasterDataMap?.[outputNodeType]?.displayName || outputNodeType}
                  iconInfo={nodeMasterDataMap?.[outputNodeType]?.iconInfo}
                  nodeCategory={nodeMasterDataMap?.[outputNodeType]?.category?.code || NodeCategory.moment}
                  isActionNode={true}
                  onClick={() => {
                    onPressOutputNode(
                      {
                        sourceHandle,
                        node: outputNodeType,
                        metaData: {},
                      },
                      true
                    );
                  }}
                />
              </View>
            ))}
        </HStack>
      ))
    }, [sortedList, category, doesNodeMatchSearch, nodeByCategory, nodeMasterDataMap]);

    return <>{renderedNodes}</>
  }

  const [isSmallScreen] = useMediaQuery([
    {maxWidth: SMALL_WINDOW},
  ]);
  const smallScreen = isSmallScreen

  const GetActionNodes = () => {
    if(!hasMatchingActionNodes) {
      return null;
    }

    return (
      <ScrollView
        style={{
          maxHeight:
            (workflowContext.flowType == FlowType.careJourney) ?
              ( smallScreen?
                window.innerHeight * 0.4 :
                window.innerHeight* 0.5
              )
              :
              smallScreen ?
                window.innerHeight * .550 :
                window.innerHeight * 0.580,

          width:
            (workflowContext.flowType == FlowType.careJourney) ?
              '100%' :
              '101.5%'
        }}
      >
        <VStack width="full">
          {actionNodes.map((category:any, index:number ) => {
            const items = getSortedList(category)
            if(!items.length ){
              return <></>
            }
            return (
              <RenderCategory
                category={category}
                index={index}
                key={category}
                sortedList={items}
              />
            )
          })}
        </VStack>
      </ScrollView>
    );
  };
  const [tabColumns, setTabColumns] = useState<any>([])
  useEffect(()=>{
    const tabColumns = []
    if(hasMatchingActionNodes){
      tabColumns.push(
        {
          key: '1',
          label: 'Actions',
          children: <GetActionNodes />,
        }
      )
    }

    if(hasMatchingConditionNodes){
      tabColumns.push(
        {
          key: '2',
          label : 'Conditions',
          children: <GetConditionNodes />
        })
    }
    setTabColumns(tabColumns)

  }, [hasMatchingActionNodes, actionNodes, hasMatchingConditionNodes])



  return (
    <div className="workflow-antd-input">
      <VStack space={5} style={styles.bgWhitePadding10}>
        <VStack space={2}>
          <View paddingX={'6px'}>
            {false && <Text style={workflowTriggerStyle.workflowSubtitle}>
              {'RUN AUTOMATION'}
            </Text>}
          </View>
          <View
            flex={10}
            flexDirection={'row'}
            gap={'8px'}
            borderRadius={'8px'}
            style={styles.view2}
            backgroundColor={Colors.Custom.Primary50}
          >
            <View
              flex={1}
              marginY={'12px'}
              marginLeft={'12px'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <ZapGradientIcon/>
            </View>

            <View paddingY={2} alignSelf={'center'} flex={8} paddingRight={2}>
              <Text size={'smRegular'} color={Colors.FoldPixel.GRAY400}>
                { workflowContext.flowType == FlowType.careJourney ?  intl.formatMessage({id:  'postPrescriptionOfCareJourney' }) : parentDisplayName}
              </Text>
            </View>
          </View>
        </VStack>
        <HStack>{getOperationNodes()}</HStack>

        <View>
          <Input
            style={reactStyles.inputStyle}
            placeholder="Search Condition or Action"
            width={'100%'}
            className="custom-antd-input-focus"
            value={searchText}
            prefix={<SearchOutlined />}
            allowClear={true}
            onChange={(text) => {
              setSearchText(text.target.value);
            }}
          ></Input>
        </View>

        <View>
          <Tabs
            type="card"
            tabPosition="top"
            defaultActiveKey="1"
            items={tabColumns}
          ></Tabs>
        </View>
      </VStack>
    </div>
  );
};

export default NewOutputModelV2;
