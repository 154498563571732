import React, { useState } from 'react';
import { Text, TouchableOpacity } from 'react-native';
import { Stack, Collapse } from 'native-base';
import { reasonForPrioritizationStyles } from './ReasonForPrioritizationStyles';
import { Colors } from '../../../../styles';
import DownArrowFilledSvg from '../../../../assets/Icons/DownArrowFilledSvg';
import UpArrowFilledSvg from '../../../../assets/Icons/UpArrowFilledSvg';

export const ReasonForPrioritization = React.memo(({ reasonForPrioritization }: any) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const displayedReasons = reasonForPrioritization.slice(0, 3);
  const remainingReasons = reasonForPrioritization.slice(3);

  return (
    <Stack
      direction="column"
      style={reasonForPrioritizationStyles.reasonForPrioritizationContainer}
    >
      <Stack
        direction="column"
        style={reasonForPrioritizationStyles.reasonForPrioritizationView}
      >
        {displayedReasons?.map((reason: string, index: number) => (
          <Text style={reasonForPrioritizationStyles.reasonText} key={index}>
            • {reason}
          </Text>
        ))}
        {remainingReasons?.length > 0 && (
          <Collapse isOpen={isExpanded}>
            {remainingReasons?.map((reason: string, index: number) => (
              <Text style={reasonForPrioritizationStyles.reasonText} key={index}>• {reason}</Text>
            ))}
          </Collapse>
        )}
        {remainingReasons.length > 0 && (
          <TouchableOpacity style={{ marginTop: 8 }} onPress={toggleExpand}>
            <Text style={reasonForPrioritizationStyles.moreText}>
              {isExpanded ? 'View Less' : 'View More'} {isExpanded ? <UpArrowFilledSvg customStrokeColor={Colors.Custom.Primary300} /> : <DownArrowFilledSvg customStrokeColor={Colors.Custom.Primary300} />}
            </Text>
          </TouchableOpacity>
        )}
      </Stack>
    </Stack>
  );
});
