import {HStack, Text, View} from 'native-base';
import {Colors} from '../../../styles';
import {styles} from './CustomAntTabStyles';
import React from 'react';
import {Tabs, Tooltip} from 'antd';
import Feather from 'react-native-vector-icons/Feather';
import {useIntl} from 'react-intl';

const {TabPane} = Tabs;

export interface FHTab {
  text: string;
  code: string;
  shouldShowInfoIcon?: boolean;
  toolTipMessage?: string;
}
interface IFHCustomAntTabProps {
  tabsArray: FHTab[];
  selectedCode: string;
  centered?: boolean;
  containerStyle?: any;
  tabBarExtraContent?: any;
  onTabSelect: (code: string) => void;
}

const FHCustomAntTab = (props: IFHCustomAntTabProps) => {
  const intl = useIntl();
  const {tabsArray, selectedCode, onTabSelect} = props;

  return (
    <View
      style={[{
        paddingHorizontal: 4,
        backgroundColor: '#fff',
      }, props?.containerStyle || {}]}
    >
      <div className="fhform-tab">
        <Tabs
          centered={props.centered}
          defaultActiveKey={selectedCode}
          tabBarExtraContent={props.tabBarExtraContent}
          onChange={(key: string) => {
            onTabSelect(key);
          }}
          tabBarStyle={{marginLeft: 0, paddingLeft: 0}}
          style={{marginLeft: 0}}
          className="customAntTab"
        >
          {tabsArray.map((tabTextArrayItem: FHTab) => (
            <TabPane
              tab={
                <HStack
                  space={1}
                  style={{flexDirection: 'row', alignItems: 'center'}}
                >
                  <Text color={Colors.FoldPixel.GRAY400}>
                    {tabTextArrayItem.text}
                  </Text>
                  {
                    tabTextArrayItem.shouldShowInfoIcon &&
                    <Tooltip
                    title={intl.formatMessage({id: tabTextArrayItem.toolTipMessage})}
                    placement="top"
                  >
                    <View style={{alignSelf: 'center'}}>
                      <Feather
                        name="info"
                        style={{
                          fontSize: 18,
                          color: Colors.Custom.PrimaryColor,
                        }}
                      />
                    </View>
                  </Tooltip>
                  }
                </HStack>
              }
              key={tabTextArrayItem.code}
            ></TabPane>
          ))}
        </Tabs>
      </div>
    </View>
  );
};

export default FHCustomAntTab;
