import React, {useMemo} from 'react';
import {Progress, Tooltip} from 'antd';
import {Colors} from '../../../../styles';
import {
  getStepViewConfig,
  getSubstepIcon,
} from './ContactCareProgramView.utils';

import {getMlovObjectFromId} from '../../../../utils/mlovUtils';
import useCareProgramStepStatus from './hooks/useCareProgramStepStatus';
import {testID} from '../../../../testUtils';
import {IContactCareProgramStep, IMlov} from '../interface';
import {useContactCareProgramContext} from './ContactCareProgram.context';
import SideBarRightView from './components/SideBarRightView';
import {useSelectedCareProgramStatus} from './hooks/useSelectedCareProgramStatus';

const SideBarStep = (props: {
  isActiveStep: boolean;
  onSelectStep: () => void;
  step: IContactCareProgramStep;
}) => {
  const {isActiveStep, onSelectStep, step} = props;
  const {id, title, subSteps, careProgramStepAdditionalAttributes} = step;
  const {
    state: {contactCareProgramDetails, stepProgressMap},
  } = useContactCareProgramContext();
  const {programStatusCode} = useSelectedCareProgramStatus();

  const statusId = useMemo(
    () =>
      contactCareProgramDetails?.stepsLog?.find(
        (step) => step.careProgramStepId === id
      )?.careProgramStepStatusId,
    [contactCareProgramDetails?.stepsLog, id]
  );

  const {careProgramStepStatusList} = useCareProgramStepStatus();

  const statusMlov = getMlovObjectFromId(
    statusId as string,
    careProgramStepStatusList
  );

  const SubstepIcon = getSubstepIcon(statusMlov?.code as string);
  const status = statusMlov?.value;

  const isReadOnly = careProgramStepAdditionalAttributes.some(
    (attribute) =>
      attribute.attributeKey === 'readOnly' &&
      attribute.attributeValue.valueBoolean
  );

  const stepDisplayConfig = getStepViewConfig({
    step,
    stepStatusCode: statusMlov?.code as string,
    programStatusCode,
  });

  return (
    <div
      className={`sidebar-item-collapsible sidebar-item-child ${
        isActiveStep ? 'active-step' : 'cursor-pointer'
      }`}
      onClick={onSelectStep}
      {...testID(`${title}`)}
    >
      {isActiveStep && <div className={`active-step-indicator`} />}
      {SubstepIcon && (
        <div>
          <Tooltip title={status}>
            <SubstepIcon />
          </Tooltip>
        </div>
      )}
      <div className='sidebar-step-content-container'>
      <div className="flex flex-col flex-1">
        <div className='step-title'>{stepDisplayConfig.displayTitle}</div>
        {stepDisplayConfig.showProgress && (
          <Progress
            percent={stepProgressMap?.get(id) || 0}
            showInfo={false}
            strokeColor={Colors?.Custom?.SuccessColor}
            size="small"
          />
        )}
      </div>
      <SideBarRightView
        step={step}
        stepStatusId={statusId as string}
        stepLogs={contactCareProgramDetails?.stepsLog || []}
      />
      </div>
    </div>
  );
};

export default React.memo(SideBarStep);
