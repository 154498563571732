import {Spacer, Text} from 'native-base';
import Stack from '../../../common/LayoutComponents/Stack';
import {styles} from './ContactOutreachActivitiesStyles';
import {Pressable,View, useWindowDimensions} from 'react-native';
import {IFormattedContactOutreachActivity} from './interfaces';
import {
  PatientNotesLogModeOfContact,
  getPatientNotesLogModeOfContactTabIcon,
} from '../../constants';
import {Colors} from '../../../../styles';
import UserSvgV2 from '../../../common/Svg/UserSvgV2';
import {getOutComeColorByOutComeCode} from '../../../common/CareProgramStepOutreach/CareProgramStepOutreachUtils';
import RenderHTML from 'react-native-render-html';
import {useMemo, useState} from 'react';
import { SMALL_WINDOW_1500 } from '../../../../constants';
import {Button} from 'antd';
import EditSvgV2 from '../../../common/Svg/AutomationActionIcons/EditSvgV2';
import { ShowWorkflowExecutionCommunication } from '../../../RightSideContainer/Workflow/Workflow/WorkflowTableView/Helper/WorkflowStatusTable';

interface IContactOutreachActivityItemProps {
  isLastItem: boolean;
  outreachActivity: IFormattedContactOutreachActivity;
  onEdit?: (outreachActivity: IFormattedContactOutreachActivity) => void;
}

export const ContactOutreachActivityItem = (
  props: IContactOutreachActivityItemProps
) => {
  const {isLastItem, outreachActivity, onEdit} = props;
  const Icon = getPatientNotesLogModeOfContactTabIcon(
    outreachActivity.communicationMode as PatientNotesLogModeOfContact
  );
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isEmailOrSms = outreachActivity?.communicationMode === PatientNotesLogModeOfContact.EMAIL || outreachActivity?.communicationMode === PatientNotesLogModeOfContact.SMS;
  const isAutomation = outreachActivity?.outreachData?.reference?.workflowExecutionId && outreachActivity?.outreachData?.reference?.communicationLogId;

  const color = getOutComeColorByOutComeCode(outreachActivity?.outcomeMlov);
  const { width } = useWindowDimensions();
  const isSmallScreen = width < SMALL_WINDOW_1500;

  const memoizedRenderHTML = useMemo(
    () => (
      <RenderHTML
        source={{
          html: outreachActivity.note || '',
        }}
        enableExperimentalMarginCollapsing={true}
        enableExperimentalBRCollapsing={true}
        baseStyle={{
          whiteSpace: 'pre',
          overflow: 'hidden',
          padding: 0,
          marginBottom: -10,
          marginTop: 0,
          color: Colors.FoldPixel.GRAY300
        }}
      />
    ),
    [outreachActivity.note]
  );

  return (
    <Stack direction="row" style={styles.outreachActivityItemView}>  
    {isDrawerOpen &&
          <ShowWorkflowExecutionCommunication
          isShow={isDrawerOpen} 
          workflowExecutionId={outreachActivity?.outreachData?.reference?.workflowExecutionId}
          onClose={() => {
            setIsDrawerOpen(false);
          }} 
          isLoading={false} 
          setIsLoading={() => {}} 
          itemId={outreachActivity?.outreachData?.reference?.communicationLogId}
          />
      } 
      <View style={styles.timelineViewContainer}>
        <View style={styles.timeLineTopLineView} />
        <View style={styles.timeLineDotView} />
        {!isLastItem && <View style={styles.timeLineBottomLineView} />}
      </View>

      <Stack direction="column" style={{flex: 1}}>
        <Stack direction="row" style={styles.timeLineTitleViewContainer}>
          <Text style={styles.outreachTitleText}>{'Patient Outreach'}</Text>
          {onEdit && (
            <>
              <Spacer />
              <Button onClick={() => onEdit(outreachActivity)} type="text" icon={<EditSvgV2 size={24} />} />
            </>
          )}
        </Stack>
        <Stack direction="column" style={styles.timeLineDetailView}>
          <Stack direction="row">
            <Stack direction="row">
              <View style={styles.outreachCommunicationModeViewIcon}>
                <Icon
                  width={12}
                  height={12}
                  customStrokeColor={Colors.FoldPixel.GRAY300}
                />
              </View>

              <Text style={styles.outreachSubText}>
                {outreachActivity.communicationModeText}
              </Text>
            </Stack>
            <Stack direction="row">
              <Text style={styles.outreachSubText}> • </Text>
              <View style={styles.outreachCommunicationModeViewIcon}>
                <UserSvgV2></UserSvgV2>
              </View>
              <Text size={'xsNormal'} style={styles.outreachSubText}>
                {outreachActivity.userName}
              </Text>
            </Stack>
            <Text size={'xsNormal'} style={styles.outreachSubText}>
              {' '}
              {`• ${outreachActivity.formattedDate}`}
            </Text>
          </Stack>
          <Stack direction="column">
            <Text style={styles.inabilityToContactText} color={color}>
              {outreachActivity.outcomeText}
            </Text>
            <View style={{width: isSmallScreen ? 450 : 550}}>
              {outreachActivity.note ? memoizedRenderHTML : <></>}
            </View>
          </Stack>
          {isEmailOrSms && isAutomation && (
            <Stack direction="row" style={{marginTop: 8}}>
              <Pressable onPress={() => setIsDrawerOpen(true)}>
                <View style={styles.viewBoxContainer}>
                  <Icon
                    width={16}
                    height={16}
                    customStrokeColor={Colors.FoldPixel.GRAY300}
                  />
                  <Text style={styles.viewEmailText}>
                    {`View ${outreachActivity.communicationModeText}`}
                  </Text>
                </View>
              </Pressable>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
