import { Stack, View, ScrollView } from 'native-base';
import React, { useState } from 'react';
import InsightsReferralOrdersView from '../../../../../SideCar/components/InsightsReferralOrdersView';
import { usePersonOmniViewContext } from '../../../../../PersonOmniView/PersonOmniView.context';
import { useContactCareProgramContext } from '../../ContactCareProgram.context';
import useCareProgramStepStatus from '../../hooks/useCareProgramStepStatus';



const DischargeRefferals: React.FC = () => {
  const { state } = useContactCareProgramContext();
  const {formattedData: formattedContactData} = usePersonOmniViewContext();
  const [showInsightsReferralOrders, setShowInsightsReferralOrders] = useState(false);
  const {careProgramStepStatus} = useCareProgramStepStatus();
  const selectedStepLog = state.contactCareProgramDetails?.stepsLog.find(stepLog => stepLog.careProgramStepId === state.selectedStepId);
  const isReadOnlyView = selectedStepLog?.careProgramStepStatusId === careProgramStepStatus?.done || selectedStepLog?.careProgramStepStatusId === careProgramStepStatus?.skip;

  return (
    <ScrollView style={{marginHorizontal: 24, marginBottom: 86}}>
    <Stack direction="column" mt={4} px={20}>
       <InsightsReferralOrdersView
          formattedContactData={formattedContactData}
          contactData={formattedContactData?.contactData}
          onBack={() => {
            setShowInsightsReferralOrders(false);
        }}
          isReadOnlyView={isReadOnlyView}
          isCareManagementView={true}
        />
    </Stack>
    </ScrollView>
  );
};

export default DischargeRefferals;
