import {FormControl, HStack, Select, Text, View} from 'native-base';
import React from 'react';
import {Colors} from '../../../styles';
import {DisplayText} from '../DisplayText/DisplayText';
import { InputStyle } from '../../RightSideContainer/Contacts/Leads/LeadView/LeadViewStyle';
import { testID } from '../../../testUtils';
import { INPUT_TEXT_CONFIG } from '../../../theme/FontConfig/TextFontSizeConfig';
interface IModalActionSelect {
  label?: string;
  extraLabel?: boolean | string | undefined;
  extraLabelText?: string;
  isRequired?: boolean | undefined;
  isInvalid?: string | boolean | any;
  selectedValue?: string | undefined;
  accessibilityLabel?: string;
  placeholder?: string;
  endIcon?: any;
  onValueChange: (value: any) => void;
  isDisabled?: any;
  data: any;
  selectHeight?: string | number;
  selectWidth?: string | number;
  customStyle?: any;
  selectItemProps: ISelectItemProps;
  errors?: string | boolean;
  errorText?: string | boolean;
  selectedItem?: any;
  defaultValue?: any;
  marginBottom?: string | number;
  marginTop?: string | number;
  changeBackground?: boolean
}
interface ISelectItemProps {
  key: string;
  label: string;
  value: string;
}

export const ModalActionSelect = (props: IModalActionSelect) => {
  const {
    label,
    extraLabel,
    extraLabelText,
    isRequired,
    selectedValue,
    accessibilityLabel,
    placeholder,
    endIcon,
    onValueChange,
    isDisabled,
    selectItemProps,
    data,
    customStyle,
    errors,
    errorText,
    isInvalid,
    selectedItem,
    defaultValue,
    marginBottom,
    marginTop,
    changeBackground
  } = props;

  const getWidth = () => {
    if (customStyle?.flex) {
      return '100%';
    } else {
      return 300;
    }
  };

  return (
    <>
      <View marginBottom={marginBottom} marginTop={marginTop} flex={1}>
        <FormControl isRequired={isRequired} isInvalid={errors || isInvalid}>
          {label && (
            <HStack>
              <FormControl.Label ml={0}>
                {label && (
                  <DisplayText
                    size={'smRegular'}
                    extraStyles={{
                      color: Colors.FoldPixel.GRAY250,
                      fontWeight: 400,
                      fontSize: 14,
                    }}
                    textLocalId={label}
                  />
                )}
              </FormControl.Label>
              {extraLabel && (
                <Text mt={0.5} ml={-2} fontSize="xs" color="gray.500">
                  {extraLabelText}
                </Text>
              )}
            </HStack>
          )}
          <Select
            key={props.selectedValue}
            width={customStyle?.width ? customStyle.width : getWidth()}
            height={customStyle?.height ? customStyle.height : 10}
            fontSize={customStyle?.fontSize || INPUT_TEXT_CONFIG.intNormal.fontSize}
            fontWeight={customStyle?.fontWeight || ''}
            style={{minHeight: customStyle?.minHeight || ''}}
            backgroundColor={customStyle?.backgroundColor || 'transparent'}
            borderRadius={
              customStyle?.borderRadius ? customStyle.borderRadius : 8
            }
            borderColor={
              customStyle?.borderColor ? customStyle?.borderColor : 'gray.200'
            }
            _light={{
              backgroundColor: changeBackground ? '' : ''
            }}
            selectedValue={props.selectedValue}
            data-testid={placeholder}
            defaultValue={defaultValue}
            accessibilityLabel={accessibilityLabel}
            placeholder={placeholder}
            placeholderTextColor={InputStyle.placeholderTextColor}
            _selectedItem={{
              endIcon: endIcon ? endIcon : '',
            }}
            onValueChange={(itemValue) => {
              onValueChange(itemValue);
            }}
            isDisabled={isDisabled}
          >
            {data &&
              data.map((singleData: any, index: number) => {
                if (selectItemProps) {
                  return (
                    <Select.Item
                      key={singleData && singleData[selectItemProps.key] || index}
                      label={singleData && singleData[selectItemProps.label] || ''}
                      value={singleData && singleData[selectItemProps.value] || ''}
                      aria-label={singleData[selectItemProps.label]}
                    />
                  );
                } else {
                  return (
                    <Select.Item
                      key={singleData?.id}
                      label={singleData?.value}
                      value={singleData?.id}
                    />
                  );
                }
              })}
          </Select>
          {errors && (
            <FormControl.ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {errorText}
            </FormControl.ErrorMessage>
          )}
        </FormControl>
      </View>
    </>
  );
};
