import { StyleSheet } from "react-native";
import { Colors } from "../../../styles/Colors";
export const styles = StyleSheet.create({
  spinnerStyle: {
    marginVertical: 0,
    marginHorizontal: 'auto',
    color: 'black',
    zIndex: 1000,
    // backgroundColor: '#0000000f',
    height: '50%',
    width: '50%',
    padding: 0,
  },
  modal : {
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  modalContent: {
    borderRadius: 16,
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: Colors.Custom.CardBorderColor,
  },
  scrollView: {
    maxHeight: 400,
  },
  fromText: {
    color: Colors.FoldPixel.GRAY250,
    marginLeft: 4
  },
  numberListItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 6,
    paddingBottom: 8,
    borderBottomWidth: 1,
    borderColor: Colors.Custom.Gray200,
    justifyContent: 'space-between',
  },
  numberListItemContent: {
    flex: 1,
  },
  nameText: {
    marginHorizontal: 4,
    color: Colors.Custom.Gray700,
    marginTop: -2
  },
  phoneNumberText: {
    marginHorizontal: 4,
    color: Colors.FoldPixel.GRAY250,
  },
  actionButtonsContainer: {
    flexDirection: 'row',
  },
  actionButton: {
    marginHorizontal: 4,
    zIndex: 999,
  },
  actionButton2: {
    marginHorizontal: 4,
  },
  actionButtonIcon: {
    width: 40,
    height: 40,
  },
  actionButtonIcon2: {
    width: 36,
    height: 36,
  },
})
