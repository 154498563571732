import React from 'react';
import {Path, Svg} from 'react-native-svg';
import { ICommonSvgProps } from './interfaces';

const CheckGreenCircleSvg = (props: ICommonSvgProps) => {
  return (
    <Svg width="12" height="12" fill="none" viewBox="0 0 12 12">
      <Path
        fill={props?.customStrokeColor ? props?.customStrokeColor : "#12B76A"}
        d="M.167 6a5.833 5.833 0 1 1 11.666 0A5.833 5.833 0 0 1 .167 6"
      />
      <Path
        stroke={props?.customStrokeColor ? props?.customStrokeColor : "#12B76A"}
        strokeWidth="0.333"
        d="M.167 6a5.833 5.833 0 1 1 11.666 0A5.833 5.833 0 0 1 .167 6Z"
      />
      <Path
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="0.667"
        d="m3.667 6.03 1.237 1.375a.333.333 0 0 0 .484.013l2.751-2.751"
      />
    </Svg>
  );
};

export default React.memo(CheckGreenCircleSvg);
