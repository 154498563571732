import { Collapse } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from 'moment';
import { Button, FlatList, HStack, Icon, IconButton, Skeleton, Text, View, VStack, Spinner } from "native-base";
import { useContext, useEffect, useState } from "react";
import AntIcon from 'react-native-vector-icons/AntDesign';
import { useNavigate } from "react-router-dom";
import { WORKFLOW_TAGS } from "../../../../../../constants";
import { FlowType, WorkflowContext } from "../../../../../../context/WorkflowContext";
import { Colors } from "../../../../../../styles";
import { DisplayText } from "../../../../../common/DisplayText/DisplayText";
import { getWorkflowUrlFromFlowType } from "../../../workflowUtils";
import { getWorkflowExecutionData } from "../../AddOrUpdateWorkflow/WorkflowApi";
import GetContactPhotoAndNameByContactId from "./GetContactPhotoAndNameByContactId";
import { GetOrderNameAndDetailById } from "./GetOrderNameAndDetailById";
import { GetTasKNameAndDetailByTaskId } from "./GetTasKNameAndDetailByTaskId";
import WorkflowExecutionLevelInfoByTag from "./WorkflowExecutionLevelInfoByTag";
import WorkflowPersonLevelEmailInfoByExecutionIdV1 from "./WorkflowPersonLevelEmailInfoByExecutionIdV1";
import { ShowWorkflowExecutionCommunication } from "./WorkflowStatusTable";
import { IShowServiceLogType } from "../../../FlowComponent/StateNodes/interface";
import { isAccountConfigEnabled } from "../../../../../../utils/configUtils";
import { CONFIG_CODES } from "../../../../../../constants/AccountConfigConst";
import { UserLocationContext } from "../../../../../../context/UserLocationContext";
import { accountLocationUuidListByUserPracticeLocationList, isLoginUserBusinessOwner } from "../../../../../../utils/commonUtils";
import { CommonDataContext } from "../../../../../../context/CommonDataContext";
import { reactStyles, styles } from "../../../workflowStyles";
const { Panel } = Collapse;

const WorkflowExecutionLevelLog = (props: { isShowViewAllButton?: boolean, setIsShowViewAllButton?: any, flowType?: any, isShowPersonLevelCommunicationInfo: boolean, height?: number | string, width?: number | string, workflowMasterId: string, limit?: number, offset?: number, locationIds?: string[]}): JSX.Element => {
  const [limit] = useState(props.limit || 10);
  const [total, setTotal] = useState(limit + 1);
  const [offset, setOffset] = useState(props.offset || 0);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [workflowExecutionDataList, setWorkflowExecutionDataList] = useState<any>([])
  const [showServiceLogs, setShowServiceLogs] = useState<IShowServiceLogType>({isShow: false, workflowExecutionId: ''});
  const [isExecutionCommunicationLoading, setIsExecutionCommunicationLoading] = useState(false);
  const isShowViewAllAutomationAnalytic = isAccountConfigEnabled(CONFIG_CODES.IS_SHOW_VIEW_ALL_AUTOMATION_ANALYTIC)
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  const isBusinessOwner = isLoginUserBusinessOwner();
  const userLocationContextData  = useContext(UserLocationContext)
  const commonContext = useContext(CommonDataContext)
  const userPracticeLocations =  accountLocationUuidListByUserPracticeLocationList(commonContext?.userPracticeLocations);

  useEffect(() => {
    loadWorkflowExecutionData(false)
  }, [limit, offset])

  const getResourceType = () => {
    if (props?.flowType === 'TASK') {
      return 'TASK';
    }
    if (props?.flowType === 'ORDER') {
      return 'ORDER';
    }
    return 'CONTACT';
  }
  const loadWorkflowExecutionData = async (isReset: boolean) => {
    setIsLoading(true)

    try {
      const response = await getWorkflowExecutionData({
        limit: limit,
        workflowMasterId: props.workflowMasterId,
        offset: isReset ? 0 : offset,
        resourceType: getResourceType(),
        isMsoEnabled: isMsoEnabled,
        isBusinessOwner: isBusinessOwner,
        locationIds: userPracticeLocations || []
      });

      const total = response?.aggregateWorkflowExecutionLog?.aggregate?.count || 0;
      setTotal(total);
      setWorkflowExecutionDataList((prev: any) => {
        const list = [...prev, ...response.workflowExecutionLogs]
        if (props.setIsShowViewAllButton && list.length && !props.isShowViewAllButton) {
          props.setIsShowViewAllButton(true);
        }
        return list
      });
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  };

  const getUniqueResourceIdInItem = (item: any, flowType?: string) => {
    const resourceIdList: any = [];
    (item?.resourceLevelLog || []).forEach((log: any) => {
        if (log?.resourceId && resourceIdList.indexOf(log?.resourceId) == -1 && (!Number.isNaN(Number(log?.resourceId)) ||  (log.resourceType === 'TASK' && props.flowType == 'TASK'))) {
          resourceIdList.push(log?.resourceId)
        }
    })
    return resourceIdList;
  }

  const getDisplayDateElement = (item: any, justifyContent: string) => {
    return <HStack alignItems={'center'} justifyContent={justifyContent} flex={1} width={props.width || 600} paddingTop={2}>
      <Text size={'lgMedium'} color={Colors.FoldPixel.GRAY400} >{moment(item?.createdOn).format('MMM DD YYYY' + ' hh:mm A')}</Text>
    </HStack>
  }

  const onWorkflowExecutionCommunicationClose = () => {
    setShowServiceLogs((prev: IShowServiceLogType)=>{
      const idObj: IShowServiceLogType = {...prev};
      idObj.isShow = false;
      return idObj;
    });
  }

  const onSingleIconClick = (workflowExecutionId: string, itemId?: string) => {
    setShowServiceLogs((prev: IShowServiceLogType) => {
      const idObj: IShowServiceLogType = {
        workflowExecutionId: workflowExecutionId,
        isShow: true,
        itemId: itemId
      }

      return idObj;
    });
  }


  return <View flex={1} width={'100%'}>
    {showServiceLogs?.isShow && showServiceLogs?.workflowExecutionId ? 
      <ShowWorkflowExecutionCommunication
        isShow={showServiceLogs.isShow} 
        workflowExecutionId={showServiceLogs.workflowExecutionId} 
        onClose={onWorkflowExecutionCommunicationClose} 
        isLoading={isExecutionCommunicationLoading} 
        setIsLoading={setIsExecutionCommunicationLoading} 
        itemId={showServiceLogs?.itemId}
        /> : <></>
    }
    {workflowExecutionDataList?.length || isLoading ? <></> : <>No Execution History</>}
    {workflowExecutionDataList?.length > 1 ? <HStack padding={5}>
      <HStack flex={8}></HStack>
      
      { isShowViewAllAutomationAnalytic && <HStack flex={1}><Button
        justifyContent={'center'}
        borderWidth={1}
        backgroundColor={
          Colors.secondary['100']
        }
        borderColor={
          Colors.secondary['800']
        }

        size={'sm'}
        colorScheme={'muted'}
        variant={''}
        onPress={() => {
          const navigationPath = getWorkflowUrlFromFlowType(props.flowType);

          navigate(
            navigationPath + '/execution/audit' +
            '/' +
            props?.workflowMasterId + '/graph' +
            '?flowType=' +
            props.flowType + '&currentTab=' + WORKFLOW_TAGS.WORKFLOW + '&isExecutionLog=true'
          );
        }}
        leftIcon={(
          <Icon
            as={AntIcon}
            name={'plus'}
            color={Colors.secondary['800']}
            size="4"
          />
        )}
        style={styles.buttonStyles}
      >
        <DisplayText
          extraStyles={{
            color: Colors.secondary['800'] || '',
            display: 'flex',
            alignItems: 'center',
          }}
          textLocalId={'View All'}
        />
      </Button>
      </HStack> }
    </HStack> : <></>}
    <FlatList
      flex={1}
      width={'100%'}
      numColumns={1}
      onEndReachedThreshold={0.2}
      ListFooterComponent={isLoading ? <Skeleton.Text lines={4}></Skeleton.Text> : <></>}
      onEndReached={() => {

        setOffset((prev) => {
          const newOffset = prev + limit
          if (total > newOffset) {
            return newOffset
          } else {
            return prev;
          }

        });
      }}
      height={props.height || 700}
      contentContainerStyle={{ flexGrow: 1, overflow: 'scroll' }}
      data={workflowExecutionDataList}
      renderItem={(data: any) => {
        const item = data?.item || {};
        const uniqueResourceIdList = getUniqueResourceIdInItem(item, props.flowType) || [];
        return (<HStack
          style={styles.flexWrapAlignItemsCenter}
          flex={1}
          width={'100%'}
          justifyContent={'left'} alignItems={'center'}
        >
          <Collapse

            style={reactStyles.marginBottom12}
            defaultActiveKey={[]}
            expandIconPosition={'right'}

          >
            <Panel

              header={
                <VStack alignItems={'center'} justifyContent={'center'}>
                  <HStack paddingLeft={5} justifyContent={'flex-start'} alignItems={'flex-start'} >
                    <HStack>{!!uniqueResourceIdList.length && getDisplayDateElement(item, 'flex-start')}</HStack>
                    <HStack justifyContent={'flex-end'} alignItems={'center'}>
                      {<IconButton
                        colorScheme="gray"
                        key={item.id + 'list'}
                        variant="ghost"
                        onPress={() => {
                          const navigationPath = getWorkflowUrlFromFlowType(props.flowType || FlowType.patients);

                          navigate(
                            navigationPath + '/execution/audit' +
                            '/' +
                            props.workflowMasterId + '/graph' +
                            '?flowType=' +
                            props.flowType + '&workflowId=' + (item?.workflowId || '') + '&workflowExecutionId=' + (item?.workflowExecutionId || '') + '&currentTab=' + WORKFLOW_TAGS.WORKFLOW + '&isExecutionLog=true'
                          );
                        }}
                        icon={<Icon as={AntIcon} name="barschart" size="4" color="green.400" />}
                      />}
                      { isExecutionCommunicationLoading &&
                        showServiceLogs?.workflowExecutionId === item?.workflowExecutionId &&
                        !showServiceLogs?.itemId ?
                        <Spinner size={'sm'} /> :
                        <View flex={1} justifyContent={'center'} flexDirection={'row'}>
                          <IconButton
                            icon={<ExclamationCircleOutlined />}
                            onPress={() => {
                              setShowServiceLogs((prev: IShowServiceLogType) => {
                                const workflowExecutionId = item?.workflowExecutionId;
                                const idObj: IShowServiceLogType = {
                                  workflowExecutionId: workflowExecutionId,
                                  isShow: true
                                }

                                return idObj;
                              });
                            }}
                          />
                        </View>
                      }
                    </HStack>
                  </HStack>
                  <HStack alignItems={'center'} justifyContent={'center'}>
                    <VStack>

                      <VStack flex={1} width={props.width || 600} paddingTop={2}>
                        {uniqueResourceIdList.length ?
                          uniqueResourceIdList.map((resourceId: any) => {
                            if (props?.flowType === 'TASK' && resourceId) {
                              return <HStack paddingY={2}><GetTasKNameAndDetailByTaskId taskId={resourceId}></GetTasKNameAndDetailByTaskId></HStack>
                            } else if (props?.flowType === 'ORDER' && resourceId) {
                              return <HStack paddingY={2}><GetOrderNameAndDetailById orderId={resourceId}></GetOrderNameAndDetailById></HStack>
                            } else {
                              return resourceId ? <HStack paddingY={2}><GetContactPhotoAndNameByContactId contactId={resourceId}></GetContactPhotoAndNameByContactId></HStack>
                              : <></>
                            }
                          }) : getDisplayDateElement(item, 'flex-start')
                        }
                      </VStack>
                      <HStack paddingTop={2}>

                        <HStack flex={1} width={props.width || 600} marginLeft={-5}>

                          <WorkflowExecutionLevelInfoByTag marginLeft={5} totalSms={item?.totalSmsSent?.aggregate?.count} totalPushNotification={item?.totalPushNotificationSent?.aggregate?.count} totalEmail={item?.totalEmailSent?.aggregate?.count || 0} ></WorkflowExecutionLevelInfoByTag>
                        </HStack>

                      </HStack>
                    </VStack>

                  </HStack>
                </VStack>}
              key={item?.workflowExecutionId}
              extra={
                <View >

                </View>
              }
            >
              <VStack flex={1} width={'100%'} >
                {
                  props.isShowPersonLevelCommunicationInfo && 
                  <WorkflowPersonLevelEmailInfoByExecutionIdV1 
                    total={item?.totalEmailSend?.aggregate?.count || 1} 
                    workflowExecutionId={item?.workflowExecutionId} 
                    setShowServiceLogs={onSingleIconClick} 
                    showServiceLogs={showServiceLogs}
                    isIconLoading={isExecutionCommunicationLoading}
                    locationIds={props?.locationIds}
                  />
                }
                {/* {
                  (item?.totalEmailSent?.aggregate?.count || item?.totalSmsSent?.aggregate?.count || item?.totalPushNotificationSent?.aggregate?.count) ? <></> : <View padding={5}>No Email/Notification/SMS Sent</View>
                } */}
              </VStack>
            </Panel>
          </Collapse>

        </HStack>)
      }}
    />
  </View>
}
export default WorkflowExecutionLevelLog;
