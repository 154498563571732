import React from 'react';
import { Radio, Space } from 'antd';
import { Stack } from 'native-base';

interface RadioOption {
  value: string;
  label: string;
}

interface RadioButtonPopoverProps {
  title?: string;
  currentValue: string;
  options: RadioOption[];
  onSave: (value: string) => Promise<void>;
  onCancel?: () => void;
}

const SelectOptionPopover: React.FC<RadioButtonPopoverProps> = ({
  currentValue,
  options,
  onSave,
}) => {
  const getInitialValue = () => {
    const option = options.find(opt => opt.label === currentValue);
    return option ? option.value : currentValue;
  };
  const [selectedValue, setSelectedValue] = React.useState(getInitialValue());


  const handleChange = async (e: any) => {
    const newValue = e.target.value;
    setSelectedValue(newValue);
    await onSave(newValue);
  };

  return (
    <Stack space={4} width="150px" >      
      <Radio.Group 
        onChange={handleChange} 
        value={selectedValue}
      >
        <Space direction="vertical">
          {options.map((option) => (
            <Radio 
              key={option.value} 
              value={option.value}
            >
              {option.label}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    </Stack>
  );
};

export default SelectOptionPopover;