import React, {useState} from 'react';
import {View, StyleSheet, Pressable} from 'react-native';
import {Colors} from '../../../styles';
import {FilterWrapperComponent} from '../../common/CareDashboard/CareDashboardTopBar/FilterWrapperComponent';
import {Text} from 'native-base';
import ClearActionIcon from '../../common/Svg/ClearActionIcon';
import ProviderSelectForReview from '../../PersonOmniView/MiddleContainer/CarePlan/components/ProviderSelectForReview';
import {ICareManagementFilterView} from './interface';
import {CONTACT_CARE_PROGRAM_FITER_ACTION_CODES} from '../CareManagementBilling/CareManagementConstants';
import {CONTACT_CARE_PROGRAM_STATUS_VALUES} from '../../../constants/MlovConst';
import {useIntl} from 'react-intl';
import CareManagementDateFilter from './CareManagementDateFilter';

const CareManagementFilterView = (props: ICareManagementFilterView) => {
  const {
    contactUuid,
    onFilterActionPerformed,
    assigneeFilterData,
    selectedStatusFilter,
    selectedDateFilter,
  } = props;

  const intl = useIntl();

  const [showAssigneePopover, setShowAssigneePopover] = useState(false);
  const [showStatusPopover, setShowStatusPopover] = useState(false);

  const handleClearAll = () => {
    onFilterActionPerformed(CONTACT_CARE_PROGRAM_FITER_ACTION_CODES.CLEAR_ALL);
  };

  return (
    <View style={styles.filterContainer}>
      <View style={styles.filterRow}>
        <FilterWrapperComponent
          label="Assigned to"
          value={assigneeFilterData?.name ? [assigneeFilterData?.name] : []}
          filterTextStyle={styles.filterTextStyle}
          contentMinHeight={35}
          filterValueTextStyle={styles.filterValueTextStyle}
          onClose={() => setShowAssigneePopover(false)}
          popoverStyle={{borderRadius: 8}}
          dropdownMarginLeft={0}
          isControlledPopover={true}
          open={showAssigneePopover}
          onOpenChange={setShowAssigneePopover}
        >
          <View style={{flex: 1, width: 300}}>
            <ProviderSelectForReview
              selectedProviderId=""
              hideCancelButton
              hidePCPUser
              showAllRolesOfUsers
              contactId={contactUuid}
              titleString="Select Assignee"
              onCareProgramAssigneeChange={(item) => {
                onFilterActionPerformed(
                  CONTACT_CARE_PROGRAM_FITER_ACTION_CODES.ASSIGNEE_FILTER_CHANGE,
                  item
                );
                setShowAssigneePopover(false);
              }}
              flatlistMaxHeight={200}
            />
          </View>
        </FilterWrapperComponent>

        <FilterWrapperComponent
          label="Status"
          value={
            selectedStatusFilter?.value ? [selectedStatusFilter?.value] : []
          }
          filterTextStyle={styles.filterTextStyle}
          filterValueTextStyle={styles.filterValueTextStyle}
          contentMinHeight={35}
          onClose={() => setShowStatusPopover(false)}
          popoverStyle={{borderRadius: 8}}
          isControlledPopover={true}
          open={showStatusPopover}
          onOpenChange={setShowStatusPopover}
        >
          <View>
            {Object.entries(CONTACT_CARE_PROGRAM_STATUS_VALUES).map(
              ([key, value]) => (
                <Pressable
                  key={key}
                  onPress={() => {
                    onFilterActionPerformed(
                      CONTACT_CARE_PROGRAM_FITER_ACTION_CODES.STATUS_CHANGE,
                      {code: key, value: value}
                    );
                    setShowStatusPopover(false);
                  }}
                >
                  <Text style={styles.statusListStyle}>{value}</Text>
                </Pressable>
              )
            )}
          </View>
        </FilterWrapperComponent>

        <CareManagementDateFilter
          selectedDateFilter={selectedDateFilter}
          onFilterActionPerformed={onFilterActionPerformed}
        />

        <Pressable style={styles.clearButton} onPress={handleClearAll}>
          <ClearActionIcon />
          <Text style={styles.clearButtonText}>Clear All</Text>
        </Pressable>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  filterContainer: {
    paddingVertical: 8,
    paddingHorizontal: 4,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Colors.Custom.Gray300,
  },
  filterRow: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 8,
    flex: 1,
  },
  clearButton: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 'auto',
    paddingRight: 8,
  },
  clearButtonText: {
    color: Colors.Custom.Primary300,
    marginLeft: 4,
    fontSize: 14,
  },
  filterTextStyle: {
    fontSize: 14,
    marginRight: 2,
    fontWeight: '500',
    color: Colors.Custom.Gray500,
    borderRadius: 4,
  },
  filterValueTextStyle: {
    fontSize: 14,
    fontWeight: '500',
    color: Colors.Custom.Gray700,
  },
  statusListStyle: {
    color: Colors.Custom.Gray500,
    padding: 4,
    paddingVertical: 8,
    fontSize: 14,
    fontWeight: '500',
  },
  datePickerStyle: {
    marginTop: 4,
    padding: 4,
  },
});

export default CareManagementFilterView;
