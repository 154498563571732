import React, {useMemo, useRef} from 'react';
import styles from './ProgramCompletionView.module.css';
import StatusItem from './StatusItem';
import {useContactCareProgramContext} from '../../../ContactCareProgram.context';
import {getCardMainTitle} from '../../../../../CareManagementView/CareManagementUtils';
import {useContactCareProgramBilling} from '../BillingView/useContactCareProgramBilling';
import {Image} from 'native-base';
import CareProgramIcon from '../../../../../../../assets/svg/CareProgramIcon';
import BillingIcon from '../../../../../../../assets/svg/BillingIcon';
import Confetti from 'react-confetti'
import {useContainerDimensions} from '../../../../../../CustomHooks/ContainerDimensionHook';


interface ProgramCompletionViewProps {}

const ProgramCompletionView: React.FC<ProgramCompletionViewProps> = () => {
  const componentRef = useRef<HTMLDivElement>(null);
  const { width, height } = useContainerDimensions(componentRef);
  const {state} = useContactCareProgramContext();
  const {
    hookState: {attributesAndCodes, totalBilling},
  } = useContactCareProgramBilling({
    showOnlyCompleted: true,
  });
  const {contactCareProgramDetails} = state;
  const careProgramTypeCode =
    contactCareProgramDetails?.payerCareProgram?.careProgramType?.code;
  const careProgramDisplayValue =
    contactCareProgramDetails?.payerCareProgram?.careProgramType?.value;
  const totalDuration = useMemo(
    () =>
      attributesAndCodes.reduce((acc, curr) => acc + curr.durationInMinutes, 0),
    [attributesAndCodes]
  );

  // Temp change for testing, to be replace with SVG
  const statusItems = [
    {
      icon: <CareProgramIcon />,
      text: `${careProgramTypeCode} Completed`,
      alt: 'Completed Icon',
    },
    {
      icon: <BillingIcon />,
      text: `$${totalBilling} Sent to EHR for Billing`,
      alt: 'Billing Icon',
    },
  ];

  const getTitle = () => {
    return getCardMainTitle(careProgramTypeCode || '', careProgramDisplayValue || '');
  };
  return (
    <section className={styles.completionArea} ref={componentRef}>
      <Confetti width={width} height={height} recycle={false} numberOfPieces={400} />
      <header className={styles.headerContent}>
        <Image
          width={'200px'}
          height={'160px'}
          source={require('../../../../../../../assets/images/Done.gif')}
          alt={'Success Icon'}
          alignItems={'center'}
        />
        <h1 className={styles.title}>{getTitle()} Completed</h1>
        <p className={styles.subtitle}>{`Total Time: ${totalDuration} mins`}</p>
      </header>
      <div className={styles.statusContainer}>
        {statusItems.map((item, index) => (
          <StatusItem
            key={index}
            icon={item.icon}
            text={item.text}
            alt={item.alt}
          />
        ))}
      </div>
    </section>
  );
};

export default ProgramCompletionView;
