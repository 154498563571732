import { Stack, Text, VStack, View, } from 'native-base';
import { useCallback, useEffect, useRef, useState } from 'react';
import { DATE_FORMATS } from '../../../../constants/StringConst';
import { IUserPracticeLocation } from '../../../../services/Location/interfaces';
import {
  getCurrentTimeZoneAbbr,
  getDateStrFromFormat,
} from '../../../../utils/DateUtils';
import { getUserData, getUserUUID, isAvergentClient } from '../../../../utils/commonUtils';
import { getAddressLine } from '../../../RightSideContainer/EmployerManagement/Helper';
import { COMPONENT_KEYS } from '../../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import FHForm from '../../../RightSideContainer/Forms/FHFormio/FHForm/FHForm';
import { forEachExtensiveFormComponent, getFlatListedFormComponents, getFlatListedNonHiddenFormComponents } from '../../../RightSideContainer/Forms/FormBuilderWidget/AddOrUpdateForm/AddOrUpdateFormHelper';
import { findUserById } from '../PatientNotes/PatientNotesHelper';
import { reactStyles } from '../PatientNotes/PatientNotesStyles';
import DetailPreview from '../PersonDetailsView/DetailPreview/DetailPreview';
import { cloneDeep } from 'lodash';

export interface IPrintPatientNoteProps {
  components: any[] | undefined;
  answers: any;
  optionData: any;
  isPreviewMode: boolean;
  readOnly: boolean;
  formattedContactData: any;
  personData: any;
  appointmentData: any;
  notesData: any;
  accountUserList: any;
  header: any;
  onRenderComponent: (error?: any) => void;
}

export const PatientNotePrintPreviewV2 = (props: IPrintPatientNoteProps) => {
  const {notesData, appointmentData, components, answers, optionData, accountUserList} = props;
  const userUUID = getUserUUID();
  const isAvergentPatientNote = isAvergentClient();
  const timeoutRef = useRef<NodeJS.Timeout>();

  const [componentState, setComponentState] = useState<{isPreviewMode: boolean, readOnly: true, isFormReady: boolean, loading: boolean, formLoadingStatus: {[index: string]: boolean}, selectedComponentKeys: {[index: string]: boolean}, formSelectedValues: any}>({
    loading: false,
    selectedComponentKeys: {},
    formLoadingStatus: {},
    formSelectedValues: {},
    isFormReady: false,
    isPreviewMode: true,
    readOnly: true,
  });

  const onNoteFormReady = useCallback(() => {
    setComponentState(prev => ({...prev, isFormReady: true}));
  }, []);

  useEffect(()=> {
    return () => {
      if(timeoutRef?.current) {
        clearTimeout(timeoutRef?.current);
      }
    }
  }, [])

  useEffect(() => {
    let isAllFormComponentsLoaded = true;
    if (!componentState.loading) {
      const filterComponents: any[] = [];
      const clonedComponents = cloneDeep(components || []);
      const flatListComponents = getFlatListedFormComponents(clonedComponents|| []);
      getFlatListedNonHiddenFormComponents(clonedComponents || [], flatListComponents, filterComponents);
      (filterComponents || []).forEach((component: any) => {
        const componentKey = component.type;
        if (componentKey && COMPONENT_KEYS.includes(componentKey)) {
          if (!componentState.formLoadingStatus?.hasOwnProperty(componentKey)) {
            isAllFormComponentsLoaded = false;
          } else {
            const componentLoadingStatus = componentState.formLoadingStatus[componentKey];
            if (componentLoadingStatus) {
              isAllFormComponentsLoaded = false;
            }
          }
        }
      });
    }
    if (Object.keys(componentState.formLoadingStatus)?.length && isAllFormComponentsLoaded) {
      isAllFormComponentsLoaded = !(Object.keys(componentState.formLoadingStatus) || []).some((componentKey: any) => {
        return componentState.formLoadingStatus[componentKey];
      });
    }
    if (isAllFormComponentsLoaded && componentState.isFormReady &&  props?.onRenderComponent && typeof props?.onRenderComponent == 'function') {
      //currently components are rerendering after form ready so till we get the solution adding timeout
      timeoutRef.current = setTimeout(() => {
        props?.onRenderComponent();
      }, 500);
    }
  }, [componentState.formLoadingStatus, componentState.loading, componentState.isFormReady]);

  const updateLoadingStatus = useCallback((componentKey: string, isLoading: boolean) => {
    if (componentKey) {
      setComponentState((prev) => {
        const formLoadingStatus = {
          ...prev.formLoadingStatus,
          [componentKey]: isLoading
        };
        const isAnyFormLoading = !!(Object.values(formLoadingStatus || {}).find(isFormLoading => isFormLoading));
        return {
          ...prev,
          loading: isAnyFormLoading,
          formLoadingStatus: {
            ...formLoadingStatus,
          }
        };
      });
    }
  }, []);

  const [intakeOptions] = useState({
    ...optionData,
    updateLoadingStatus,
  });

  const renderSignedByInfo = () => {
    const signedByUserName = notesData?.signedByUserName;
    const timezoneAbbr = getCurrentTimeZoneAbbr();
    if (signedByUserName && notesData?.signedDate) {
      return (
        <div className="page-break">
            <span className='font-size-18 font-weight-16' style={reactStyles.fontWeightBold}>
              {`Signed by ${signedByUserName} on ${notesData?.signedDate} (${timezoneAbbr})`}
            </span>
        </div>
      );
    }
  };

  const renderVisitInfo = () => {
    if (!appointmentData?.id) {
      return <></>;
    }
    return (
      <VStack>
        <DetailPreview titleLocalId={`Visit details`}>
          <View>
            <Text>
              {`Date Of Visit: ${getDateStrFromFormat(
                notesData.createdDate,
                DATE_FORMATS.MESSAGE_DATE_FORMAT,
              )}`}
            </Text>
            {!!props.notesData.linkedAppointmentId && (
              <Text fontSize={16}>
                {`Reason For Visit : ${
                  props.appointmentData?.reasonForVisit?.displayName || '-'
                }`}
              </Text>
            )}
          </View>
        </DetailPreview>
      </VStack>
    );
  };

  const renderProviderInfo = () => {
    const providerData = getUserData();
    const loggedInUser = findUserById(userUUID, accountUserList);
    const appointmentAccountLocationUuid = appointmentData?.accountLocationId;
    let practiceLocation = []

    if (appointmentAccountLocationUuid) {
      practiceLocation = loggedInUser?.userPracticeLocations?.find((item: IUserPracticeLocation) =>
          item?.accountLocation?.uuid === appointmentAccountLocationUuid
      ) || null;
    }
    const providerPhone = loggedInUser?.persons?.personContactDetails?.reduce((acc: string | null, contact: any) => {
      const phoneDetail: any | undefined = contact.contactDetails?.find((detail: any) => detail?.relationType?.code === 'phone');
      return phoneDetail ? phoneDetail.value : acc;
    }, null);

    const practiceLocationData = practiceLocation?.accountLocation?.practiceLocation
    return (
      <div className="page-break" style={reactStyles.flexP5}>
        <Stack direction={'column'}>
          <Stack direction={'row'}>
            <Stack direction={'column'} flex={5} alignItems="flex-start">
              {practiceLocationData && (
                <span className="font-size-16">{`${getAddressLine(practiceLocationData)
                  }`}</span>
              )}
              <Stack direction={'row'} flexWrap={'wrap'}>
                {!!providerData?.email && (
                  <span className="font-size-16"> {`${providerData.email}`}</span>
                )}
                 {(!!providerPhone && !!providerData?.email) &&(
                  <span className="font-size-16"> {`, `}</span>)}
                {!!providerPhone && (
                  <span className="font-size-16"> {`${providerPhone}`}</span>
                )}
              </Stack>

              {providerData?.name && (
                <span className="font-size-16">
                  {`Provider : ${providerData.name}`} </span>
              )}
            </Stack>
          </Stack>
        </Stack>
      </div>
    );
  };

  return (
      <div className="note-preview-styles custom-form-styles">
        {!isAvergentPatientNote && (
          <>
            <div style={reactStyles.displayFlex}>
              {renderProviderInfo()}
            </div>
            {renderVisitInfo()}
          </>
        )}
        <FHForm
          components={components}
          submittedResponse={answers}
          optionData={intakeOptions}
          isPreviewMode={componentState.isPreviewMode}
          readOnly={componentState.readOnly}
          onFormReady={onNoteFormReady}
        />
        {renderSignedByInfo()}
      </div>
  );
};

