import React, { useContext, useEffect, useMemo, useState } from 'react';
import {Pressable, Text, View, ViewStyle} from 'react-native';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import Stack from '../../../../common/LayoutComponents/Stack';
import {CINICAL_SECTIONS_FILTER_TABS} from '../ClinicalSectionConstants';
import {styles} from './ClinicalSectionFilterTabsStyles';
import Feather from 'react-native-vector-icons/Feather';
import {Colors} from '../../../../../styles';
import {IClinicalSectionFilterTab} from './interfaces';
import {isWeb} from '../../../../../utils/platformCheckUtils';
import { useIntl } from 'react-intl';
import { Badge, Skeleton } from 'native-base';
import { Tooltip, Popover } from 'antd';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import DownArrowFilledSvg from '../../../../../assets/Icons/DownArrowFilledSvg';

interface IClinicalSectionFilterTabsProps {
  onTabClick: (codde: string) => void;
  selectedTabCode?: string;
  tabs: IClinicalSectionFilterTab[];
  useEvenlyDistributedTabs?: boolean;
  useNewDesign?: boolean;
  maxVisibleTabs?: number;
}

export const ClinicalSectionFilterTabs = (
  props: IClinicalSectionFilterTabsProps
) => {
  const intl = useIntl();
  const mlovData = useContext(CommonDataContext);
  const isSideCarContext = !!mlovData.sidecarContext?.isSidecar;
  const {useNewDesign, maxVisibleTabs = props.tabs.length} = props;
  const [tabs, setTabs] = useState(props.tabs);
  const [visibleTabs, setVisibleTabs] = useState(tabs.length > maxVisibleTabs ? tabs.slice(0, maxVisibleTabs) : tabs);
  const [isShowMoreOption, setIsShowMoreOption] = useState(props.tabs.length > maxVisibleTabs);
  const [isOpen, setIsOpen] = useState(false);

  const remainingTabs = useMemo(() => {
    return props.tabs.filter(tab => !visibleTabs.find(visibleTab => tab.code === visibleTab.code));
  }, [props.tabs, visibleTabs]);

  useEffect(() => {
    if (props.selectedTabCode && maxVisibleTabs) {
      const selectedTabIndex = tabs.findIndex(tab => tab.code === props.selectedTabCode);
      if (selectedTabIndex >= maxVisibleTabs) {
        const newVisibleTabs = [...tabs];
        const [selectedTab] = newVisibleTabs.splice(selectedTabIndex, 1);
        newVisibleTabs.splice(maxVisibleTabs - 1, 0, selectedTab);
        setVisibleTabs(newVisibleTabs.slice(0, maxVisibleTabs));
        const remainingTabs = newVisibleTabs.filter(tab => !tabs.find(visibleTab => tab.code === visibleTab.code));
        setTabs([...newVisibleTabs, ...remainingTabs]);
      }
    }
  }, [props.selectedTabCode]);

  const containerStyle = React.useMemo<ViewStyle>(() => ({
    backgroundColor: useNewDesign ? undefined : Colors.Custom.Gray100,
    padding: 2,
    borderRadius: 4,
    alignItems: useNewDesign ? 'center' : undefined,
  }), [useNewDesign]);

  const tabStyle = React.useMemo(() => ({
    flex: isWeb() ? (props.useEvenlyDistributedTabs ? 1 : undefined) : 1,
  }), [props.useEvenlyDistributedTabs]);

  const renderTab = (code: string, label?: string, icon?: JSX.Element | undefined, showIconToRight?: boolean, tabContainsCount?: boolean, tabItemCountLoading?: boolean, tabItemCount?: number, countTooltip?: string, isMoreOption?: boolean) => {
    return (
      <Pressable
        onPress={() => {
          props.onTabClick(code);
          setIsOpen(false);
        }}
      >
        <Stack
          direction={showIconToRight ? 'row' : 'column'}
          style={
            code === props.selectedTabCode
              ? useNewDesign
                ? styles.selectedTabNewDesign
                : styles.selectedTab
              : isMoreOption ? styles.extraSelectedTab : styles.tab
          }
        >
          {label &&
            (isWeb() ? (
              <Stack direction={'row'}>
                <DisplayText
                  textLocalId={label}
                  extraStyles={{
                    color:
                      code === props.selectedTabCode
                        ? Colors.FoldPixel.GRAY400
                        : Colors.FoldPixel.GRAY300,
                    ...(!isSideCarContext ? {fontSize: 16} : {})   
                  }}
                />
                {tabContainsCount &&
                  (tabItemCountLoading ? (
                    <Skeleton.Text
                      alignContent={'center'}
                      width={3}
                      justifyContent={'center'}
                      alignItems={'center'}
                      lines={0.5}
                      startColor={Colors.Custom.Gray400}
                      style={styles.skeletonText}
                      // marginLeft={2}
                    ></Skeleton.Text>
                  ) : (
                    !!tabItemCount &&
                    tabItemCount > 0 && (
                      <Tooltip title={countTooltip}>
                        <Badge
                          style={styles.badge}
                          fontSize={10}
                          fontWeight={500}
                          _text={{
                            color: Colors.Custom.Gray700,
                          }}
                          borderWidth={0.5}
                          borderColor={Colors.FoldPixel.GRAY100}
                          alignSelf="center"
                          rounded="md"
                          justifyContent={'center'}
                          ml={1}
                        >
                          {tabItemCount}
                        </Badge>
                      </Tooltip>
                    )
                  ))}
              </Stack>
            ) : (
              <>
                <Text style={styles.skeletonText2}>
                  {intl.formatMessage({id: label})}
                </Text>
                {tabContainsCount &&
                  (tabItemCountLoading ? (
                    <Skeleton.Text
                      alignContent={'center'}
                      width={3}
                      justifyContent={'center'}
                      alignItems={'center'}
                      lines={0.5}
                    ></Skeleton.Text>
                  ) : (
                    !!tabItemCount &&
                    tabItemCount > 0 && (
                      <Badge
                        fontSize={10}
                        fontWeight={500}
                        _text={{
                          color: Colors.Custom.Gray700,
                        }}
                        borderWidth={1}
                        borderColor={Colors.Custom.Gray400}
                        alignSelf="center"
                        rounded="md"
                        justifyContent={'center'}
                        paddingX={0}
                        paddingY={0}
                        ml={1}
                      >
                        {tabItemCount}
                      </Badge>
                    )
                  ))}
              </>
            ))}
          {icon}
        </Stack>
      </Pressable>
    );
  };



  const moreOptionsElements = () => {
    return remainingTabs.map((filterTab) => {
      const latestUpdatedTab = props.tabs.find((t)=> t.code === filterTab.code) || filterTab;

      return (
        <View
          key={`more_tabs_${filterTab.code}`}
          style={tabStyle}
        >
          {renderTab(
            latestUpdatedTab?.code,
            latestUpdatedTab?.label,
            latestUpdatedTab?.icon,
            latestUpdatedTab?.showIconToRight,
            latestUpdatedTab?.tabContainsCount,
            latestUpdatedTab?.tabCountLoading,
            latestUpdatedTab?.tabCount,
            latestUpdatedTab?.countTooltip,
            true
          )}
        </View>
      );
    });
  };

  return (
    <Stack
      direction="row"
      style={containerStyle}
    >
      {visibleTabs.map((filterTab) => {
        const latestUpdatedTab = props.tabs.find((t)=> t.code === filterTab.code) || filterTab;
        return (
          <View
            key={`tabs_${filterTab.code}`}
            style={tabStyle}
          >
            {renderTab(
              latestUpdatedTab?.code,
              latestUpdatedTab?.label,
              latestUpdatedTab?.icon,
              latestUpdatedTab?.showIconToRight,
              latestUpdatedTab?.tabContainsCount,
              latestUpdatedTab?.tabCountLoading,
              latestUpdatedTab?.tabCount,
              latestUpdatedTab?.countTooltip,
            )}
          </View>
        );
      })}
      {isShowMoreOption && (
        <Popover
          open={isOpen}
          onOpenChange={(visible) => setIsOpen(visible)}
          content={moreOptionsElements()}
          placement="bottom"
          trigger="click"
        >
          <Pressable onPress={() => setIsOpen(!isOpen)} style={styles.showMoreTabsButton}>
            <DownArrowFilledSvg size={10}/>
          </Pressable>
        </Popover>
      )}
    </Stack>
  );
};

