import {Tooltip, Popover} from 'antd';
import {
  Box,
  Button,
  Divider,
  HStack,
  Icon,
  Image,
  Input,
  Pressable,
  Spinner,
  Text,
  View,
} from 'native-base';
import {useIntl} from 'react-intl';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {cloneDeep, debounce} from 'lodash';
import {ITopBarData} from '../common/CareDashboard/CareDashboardTopBar/CareDashboardTopBar';
import {getAccountUUID, getCarePlanProps} from '../../utils/commonUtils';
import {TaskPanelType, TaskPanelTypeCode} from '../TaskCard/TaskEnum';
import {getDateStrFromFormat} from '../../utils/DateUtils';
import {BUTTON_TYPE, DATE_FORMATS} from '../../constants';
import {
  IPropertyDetails,
  PropertyType,
} from '../common/CareDashboard/CareDashboardUtils/CareDashboardUtils';
import {
  MLOV_CATEGORY,
  TASK_STATUS,
  TASK_STATUS_CODES,
} from '../../constants/MlovConst';
import {CommonDataContext} from '../../context/CommonDataContext';
import {
  getMlovId,
  getMlovListByCategory,
  getMlovObjectFromCode,
  getMlovValue,
} from '../../utils/mlovUtils';
import {Colors} from '../../styles';
import {ParticipantType} from '../common/CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import Feather from 'react-native-vector-icons/Feather';
import AddOrUpdateTask from '../common/AddTask/AddOrUpdateTask';
import FilterDrawer from '../RightSideContainer/TeamInbox/Conversations/MessagingContactDetails/FilterDrawer';
import PatientTaskManager from '../TaskModule/PatientTaskManager/PatientTaskManager';
import {
  MultiSelectAction,
  TaskNavigateContext,
  TaskViewType,
} from '../TaskModule/TaskInterfaces';
import PlusIcon from '../common/Svg/PlusSvg';
import {getFormDataFromLeadData} from '../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import Stack from '../common/LayoutComponents/Stack';
import {SidecarPatientInfoBanner} from './SidecarPatientInfoBanner';
import {ScrollView} from 'react-native';
import {Dimensions} from 'react-native';
import {COMMON_ACTION_CODES} from '../../constants/ActionConst';
import {EventBus} from '../../utils/EventBus';
import {
  TASK_EVENTS,
  TASK_MODULE_CODE,
} from '../common/CareDashboard/CareDashboardConstants';
import CustomTabsView from '../common/CareDashboard/CareDashboardTopBar/CustomTabsView';
import SideCarSearchIconSvg from '../common/Svg/SideCarSearchIconSvg';
import { TestIdentifiers, testID } from '../../testUtils';
import SideCarFilterIconSvg from '../common/Svg/SideCarFilterIconSvg';
import useGetBatchedAccountUsers from '../CustomHooks/useGetBatchedAccountUsers';
import { useLazyQuery } from '@apollo/client';
import { IUserPool } from '../common/MemberShip/interface';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../constants/Configs';
import TaskPoolQueries from '../../services/TaskPool/TaskPoolQueries';
import CrossIconSvg from '../common/Svg/CrossIconSvg';
import SearchIconSvg from '../common/Svg/TimelineSvg/SearchIconSvg';
import CarePlan from '../PersonOmniView/MiddleContainer/CarePlan/CarePlan';
import { ICarePlanProps } from '../PersonOmniView/MiddleContainer/CarePlan/interfaces';

const SidecarPatientTaskManager = (props: any) => {
  const {
    taskTopBarData,
    contactData,
    formattedContactData,
    contactType,
    onClose,
    patientTaskScrollHeight
  } = props;

  const [filterCount, setFilterCount] = useState<number | null>(1);
  const [isShowFilterDrawer, setIsShowFilterDrawer] = useState<boolean>(false);
  const [isShowAddTaskDrawer, setIsShowAddTaskDrawer] = useState(false);
  const [isShowSearchBar, setIsShowSearchBar] = useState(false);
  const [viewType, setViewType] = useState(TaskViewType.list);
  const {height} = Dimensions.get('window');
  const headerContainerRef: any = useRef();
  const [isNavigatingToCarePlan, setIsNavigatingToCarePlan] = useState(false);

  const carePlanProps = getCarePlanProps(formattedContactData, contactData, setIsNavigatingToCarePlan);

  const [propertyDetails] = useState<IPropertyDetails>({
    propertyCodes: TASK_STATUS_CODES,
    propertyType: PropertyType.status,
  });

  const [bulkTaskIntroOpen, setBulkTaskIntroOpen] = useState(false);
  const [bulkActionEnabled, setBulkActionEnabled] = useState(false);

  const mlovData = useContext(CommonDataContext);

  const [topBarData, setTopBarData] = useState<ITopBarData>({
    start: getDateStrFromFormat(
      taskTopBarData.start || new Date(),
      DATE_FORMATS.DISPLAY_DATE_FORMAT
    ),
    end: getDateStrFromFormat(
      taskTopBarData.end || new Date(),
      DATE_FORMATS.DISPLAY_DATE_FORMAT
    ),
    selectedSortUuid: 'endDateTime',
    selectedSortType: 'endDateTime',
    selectedSortValueAscending: true,
    addTask: false,
    hideActions: true,
    panelType: TaskPanelType.INTERNAL,
  });

  const patientTask = {
    title: 'Patient Task',
    key: TaskPanelTypeCode.PATIENT,
  };
  const assignedToStaff = {
    title: 'My Tasks',
    key: TaskPanelTypeCode.INTERNAL,
  };

  const [tabList, setTabList] = useState([assignedToStaff, patientTask ]);

  const initialTaskEntityTypes =
    getMlovListByCategory(MLOV_CATEGORY.TASK_ENTITY_TYPE) || [];
  const initialPriorityType =
    getMlovListByCategory(MLOV_CATEGORY.TASK_PRIORITY) || [];
  const taskStatusMlovs = getMlovListByCategory(MLOV_CATEGORY.TASK_STATUS);
  const acceptedStatusMlov = getMlovObjectFromCode(
    TASK_STATUS.ACCEPTED,
    taskStatusMlovs
  );
  const missedStatusMlov = getMlovObjectFromCode(
    TASK_STATUS_CODES.MISSED,
    taskStatusMlovs
  );
  const allStatusValue = getMlovValue(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'all'
  );

  const allStatusUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'all'
  );

  const {loading: batchedUserLoading, userList} = useGetBatchedAccountUsers();
  const taskPoolTypeId = getMlovId(mlovData.CARE_STUDIO_MLOV, 'UserPoolType', 'task_user_pool');
  const [userPoolLoading, setUserPoolLoading] = useState(false);
  const [masterUsersPoolList, setMasterUsersPoolList] = useState<IUserPool[]>([]);

  const handleCreateCarePlanClick = () => {
    setIsNavigatingToCarePlan(true);
  }

  const [getTaskPoolQuery] = useLazyQuery(TaskPoolQueries.GetTaskPool, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      if (data?.getUserPools?.userPools?.length) {
        setMasterUsersPoolList(data?.getUserPools?.userPools);
      }
      setUserPoolLoading(false);
    },
    onError: (error: any) => {
      setUserPoolLoading(false);
      console.error('Error in get userTask pool', error);
    },
  });

  const onTaskEvent = useCallback((data: {action: MultiSelectAction}) => {
    if (data?.action) {
      switch (data.action) {
        case MultiSelectAction.disableMultiSelect:
          setBulkActionEnabled(false);
          break;

        case MultiSelectAction.enableMultiSelect:
          setBulkActionEnabled(true);
          break;
      }
    }
  }, []);

  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();

    setFilter((prev: any) => ({
      ...prev,
      priorityTypeList: initialPriorityType,
      taskEntityTypeList: initialTaskEntityTypes,
      selectedStatusCodes: [acceptedStatusMlov?.code, missedStatusMlov?.code],
      formData: {
        selectedStatusList: [
          {...acceptedStatusMlov, value: 'Not Completed'},
          {...missedStatusMlov, value: 'missed'},
        ],
        selectedDueDate: allStatusValue,
        selectedDueDateUuid: allStatusUuid,
        start: getDateStrFromFormat(
          new Date(),
          DATE_FORMATS.DISPLAY_DATE_FORMAT
        ),
        end: getDateStrFromFormat(new Date(), DATE_FORMATS.DISPLAY_DATE_FORMAT),
      },
    }));

    setUserPoolLoading(true);
    getTaskPoolQuery({
      variables: {params: {userPoolTypeId: taskPoolTypeId}},
    });

    eventBus.addEventListener(
      TASK_EVENTS.ON_MULTI_TASK_STATE_CHANGE,
      onTaskEvent,
      {moduleCode: TASK_MODULE_CODE}
    );
    return () => {
      eventBus.removeEventListenerByEventName(
        TASK_EVENTS.ON_MULTI_TASK_STATE_CHANGE,
        onTaskEvent,
        {moduleCode: TASK_MODULE_CODE}
      );
    };
  }, []);

  const [filter, setFilter] = useState({});

  const getNewTaskMember = () => {
    return {
      value: formattedContactData?.contactUUID,
      label: formattedContactData?.name,
      key: formattedContactData?.contactUUID,
      type: ParticipantType.patient,
      contactData: formattedContactData?.contactData,
      data: formattedContactData?.contactData,
    };
  };

  const getNewTaskAssignee = () => {
    return {
      value: formattedContactData?.contactUUID,
      label: formattedContactData?.name,
      key: formattedContactData?.contactUUID,
      type: ParticipantType.patient,
      contactData: formattedContactData?.contactData,
      data: formattedContactData?.contactData,
    };
  };

  const renderTasksTypeTabs = () => {
    return (
      <CustomTabsView
        canNavigate={false}
        tabsList={tabList}
        isDisabled={false}
        minScreenSupportWidth={700}
        isAddMaxTab={true}        
        tabListWrapperStyle={{marginHorizontal: 0}}
        numberOfElements={2}
        onTabClick={(tabItem, index?: number) => {
          let panelType = tabItem.key;
          if (tabItem.key === TaskPanelTypeCode.PATIENT) {
            panelType = TaskPanelType.PATIENT;
          } else panelType = TaskPanelType.INTERNAL;
          setTopBarData((prev) => ({
            ...prev,
            panelType: panelType,
          }));
          props?.updatePanelType(panelType);
        }}
        renderSubTabUI={true}
      />
    );
  };

  const onSearch = (text: string) => {
    setFilter((prev: any) => ({
      ...prev,
      formData: {
        ...(prev.formData || {}),
        searchString: text,
      },
    }));
  }

  const showLoading = batchedUserLoading || userPoolLoading;

  return (
    <View style={{backgroundColor: Colors.FoldPixel.GRAY50}}>
    {!props?.hidePatientInfoBanner && 
    <>
      <HStack
        display={'flex'}
        marginBottom={2}
        justifyContent={'space-between'}
      >
        <HStack paddingY={2} paddingX={4} flex={1} ref={headerContainerRef}>
          <SidecarPatientInfoBanner
            handleCreateCarePlanClick={handleCreateCarePlanClick}
            contactData={contactData}
            headerContainerRef={headerContainerRef}
          />
        </HStack>
        
      </HStack>
      <Divider />
      </>
    }

      {isNavigatingToCarePlan ? (
        <CarePlan {...(carePlanProps as ICarePlanProps)} />
      ) : (
      <>
      <Stack
        direction="row"
        style={{
          justifyContent: 'space-between',
          alignItems: 'center', 
          minHeight: 40,
          marginTop: 8,        
        }}
      >
        {!isShowSearchBar && renderTasksTypeTabs()}
        <HStack
          style={{
            alignItems: 'center',
            flex: 1,
          }}
        >
          <HStack space={2} alignItems={'center'} flex={1} justifyContent={'flex-end'}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                width: isShowSearchBar ? '100%' : '18%',
                justifyContent: 'flex-end',
                paddingRight: 16
              }}
            >
              {!isShowSearchBar && (
                <HStack
                  justifyContent={'flex-end'}
                  justifyItems={'center'}
                  alignItems={'center'}
                  alignContent={'center'}
                  flex={1}
                >
                  <Pressable
                    onPress={() => setIsShowFilterDrawer(!isShowFilterDrawer)}
                    style={[
                      {
                        width: 22,
                        height: 20,
                        justifyContent: 'center',
                      },
                    ]}
                    {...testID(TestIdentifiers.filterButton)}
                  >
                    <Tooltip
                      title={'Filter'}
                      overlayStyle={{whiteSpace: 'pre-line'}}
                    >
                      {(filterCount || 0) > 0 && (
                        <View
                          style={{
                            position: 'absolute',
                            top: -5,
                            right: -7,
                            zIndex: 1000,
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 2,
                            width: 14,
                            height: 14,
                            backgroundColor: Colors.Custom.badgeColorOrange
                          }}
                        >
                          <Text
                            style={{
                              color: Colors.Custom.White,
                              fontWeight: '600',                              
                              fontSize: 10,
                              textAlign: 'center',
                            }}
                          >
                            {filterCount}
                          </Text>
                        </View>
                      )}
                      <View>
                        <SideCarFilterIconSvg />
                      </View>
                    </Tooltip>
                  </Pressable>
                  <View
                    style={{
                      width: 1,
                      height: 20,
                      backgroundColor: Colors.Custom.Gray200,
                      marginLeft: 12,
                      marginRight: 8,
                    }}
                  />
                </HStack>
              )}
              {isShowSearchBar && (
                <View style={{ paddingLeft: 16, width: '100%' }}>
                  <Input
                    placeholder={'Search tasks by title or description'}
                    onChangeText={debounce(onSearch, 500)}
                    width={'100%'}
                    height={'36px'}
                    leftElement={
                      <View marginLeft={2} marginRight={1}>
                        <SearchIconSvg
                          size={18}
                          customStrokeColor={Colors.FoldPixel.GRAY200}
                        />
                      </View>
                    }
                    placeholderTextColor={Colors.FoldPixel.GRAY300}
                    backgroundColor={Colors.Custom.White}
                    rightElement={
                      <Pressable
                        height={'30px'}
                        width={'40px'}
                        alignItems={"center"}
                        justifyContent={"center"}
                        onPress={()=> {
                          if (isShowSearchBar) {
                            onSearch('');
                          }
                          setIsShowSearchBar(!isShowSearchBar);
                        }}>
                        <CrossIconSvg customStrokeColor={Colors.FoldPixel.GRAY300} size={20}/>
                      </Pressable>
                   }
                  />
                </View>
              )}
              {!isShowSearchBar && (<Pressable
                onPress={() => {
                  if (isShowSearchBar) {
                    onSearch('');
                  }
                  setIsShowSearchBar(!isShowSearchBar);
                }}
                {...testID(TestIdentifiers.searchBtn)}
              >
                {!isShowSearchBar ? (
                  <SideCarSearchIconSvg />
                ) : <></>}
              </Pressable>)}
            </View>
          </HStack>
        </HStack>
      </Stack>
      {showLoading && (
        <View
          style={{
            margin: 16,
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
          }}
        >
          <Spinner size={'lg'} />
        </View>
      )}
      {!isShowFilterDrawer && !showLoading && (
        <ScrollView style={{height: patientTaskScrollHeight, marginHorizontal: 16, marginTop: 16}}>
          <PatientTaskManager
            personData={getFormDataFromLeadData(contactData, mlovData)}
            filters={filter}
            topBarData={topBarData}
            viewType={viewType}
            userList={userList}
            masterUsersPoolList={masterUsersPoolList}
            onFiltersUpdate={(filters) => {
              setFilter(filters);
            }}
            restrictPatientOrLeadSelectionTo={getNewTaskMember() as any}
            onTaskCountUpdate={(taskCount) => {
              const internalTasksCount = taskCount.find(
                (item) => item.code === TaskPanelTypeCode.INTERNAL
              )?.count;
              const patientTasksCount = taskCount.find(
                (item) => item.code === TaskPanelTypeCode.PATIENT
              )?.count;
              setTabList((prev) => {
                return [                 
                  {
                    ...assignedToStaff,
                    count: internalTasksCount,
                  },
                  {
                    ...patientTask,
                    count: patientTasksCount,
                  },
                ];
              });
            }}
          />
        </ScrollView>
      )}
      {isShowFilterDrawer && !showLoading && (
        <FilterDrawer
          showFilterInline
          showFilterAsDropDown
          isShowFilterDrawer
          handleShowFilterDrawer={(isClearAll = false) => {
            setIsShowFilterDrawer(false);
            if (isClearAll) {
              setFilter({
                formData: {
                  selectedDueDate: allStatusValue,
                  selectedDueDateUuid: allStatusUuid,
                },
              });
            }
          }}
          handleSubmit={(filterData: any, totalCount: number | null) => {
            setFilter(filterData);
            setIsShowFilterDrawer(false);
            setFilterCount(totalCount);
          }}
          propertyDetails={propertyDetails}
          filterData={cloneDeep(filter)}
        />
      )}
      {isShowAddTaskDrawer && (
        <AddOrUpdateTask
          saveBtnText={'Save'}
          successMessage={'taskAddedMsg'}
          member={getNewTaskMember() as any}
          assignee={getNewTaskAssignee() as any}
          isVisible={isShowAddTaskDrawer}
          onComplete={(data) => {
            setIsShowAddTaskDrawer(false);
          }}
          onCancel={() => {
            setIsShowAddTaskDrawer(false);
          }}
          restrictPatientOrLeadSelectionTo={getNewTaskMember() as any}
          onNoteRedirect={() => {
            if (
              props?.onNoteRedirect &&
              typeof props?.onNoteRedirect === 'function'
            ) {
              props?.onNoteRedirect();
            }
          }}
        />
      )}
      </>
      )}
    </View>
  );
};

export default SidecarPatientTaskManager;
