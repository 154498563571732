import React from 'react';
import {Path, Svg} from 'react-native-svg';

const BackSpaceSvg = (props: {strokeColor?: string; size?: number, style? :{}}) => {
  const strokeColor = props.strokeColor || "#6F7A90";
  const size = props.size || 24;
  return (
    <Svg
    width="12"
    height="12"
    fill="none"
    viewBox="0 0 12 12"
    style = {props?.style}
  >
    <Path
      fill="#8C5AE2"
      d="m1.745 7.59.42-.27zm0-3.18-.42-.27zM6.889 10v.5zm0-8v-.5zM5.571 2v.5zM2.085 3.88l.421.27zM5.571 10v-.5zM2.085 8.12l-.42.27zm6.019-3.016a.5.5 0 0 0-.708-.708zM4.896 6.896a.5.5 0 1 0 .708.708zm.708-2.5a.5.5 0 1 0-.708.708zm1.792 3.208a.5.5 0 1 0 .708-.708zM5.571 2.5h1.318v-1H5.571zm1.318 7H5.571v1h1.318zM2.506 7.85l-.34-.53-.842.54.34.53zm-.34-3.17.34-.53-.841-.54-.34.53zm0 2.64c-.254-.396-.423-.66-.533-.878C1.529 6.234 1.5 6.109 1.5 6h-1c0 .321.095.604.24.89.138.277.342.592.584.97zm-.842-3.18c-.242.378-.446.693-.585.97C.595 5.395.5 5.678.5 6h1c0-.11.029-.234.133-.442.11-.218.279-.482.533-.878zM10.5 6c0 .957-.001 1.624-.07 2.126-.068.487-.19.745-.38.93l.697.717c.412-.401.59-.907.673-1.51.081-.587.08-1.335.08-2.263zm-3.61 4.5c.955 0 1.72.001 2.32-.077.612-.08 1.127-.252 1.537-.65l-.698-.717c-.192.187-.463.309-.97.375-.519.068-1.207.069-2.19.069zm0-8c.982 0 1.67.001 2.19.069.506.066.777.188.97.375l.697-.717c-.41-.398-.925-.57-1.537-.65C8.61 1.5 7.845 1.5 6.89 1.5zM11.5 6c0-.928.001-1.676-.08-2.263-.083-.603-.261-1.109-.673-1.51l-.698.717c.19.185.313.443.38.93.07.502.071 1.169.071 2.126zM5.571 1.5c-.548 0-.992 0-1.357.033-.376.035-.703.11-1.016.278l.475.88c.152-.082.335-.134.633-.162.308-.028.699-.029 1.265-.029zM2.506 4.15c.3-.467.507-.788.694-1.026.18-.229.321-.351.473-.433l-.475-.88c-.313.168-.552.401-.783.694-.224.285-.46.653-.75 1.105zM5.571 9.5c-.566 0-.957 0-1.265-.03-.298-.027-.48-.079-.633-.16l-.475.88c.313.168.64.242 1.016.277.365.034.81.033 1.357.033zM1.665 8.39c.29.452.526.82.75 1.105.23.293.47.526.783.694l.475-.88c-.152-.082-.292-.204-.473-.433-.187-.238-.394-.559-.694-1.026zm5.731-3.994-1.25 1.25.708.708 1.25-1.25zm-1.25 1.25-1.25 1.25.708.708 1.25-1.25zm-1.25-.542 1.25 1.25.708-.708-1.25-1.25zm1.25 1.25 1.25 1.25.708-.708-1.25-1.25z"
    ></Path>
  </Svg>
  );
};

export default BackSpaceSvg;
