import React, {useContext, useEffect, useState} from 'react';
import {Pressable, View} from 'react-native';
import Stack from '../../LayoutComponents/Stack';
import {Skeleton, Text, useToast} from 'native-base';
import {Colors} from '../../../../styles';
import {IEFaxMessageBox} from './interfaces';
import {useLazyQuery} from '@apollo/client';
import ContactsQueries from '../../../../services/Contacts/ContactsQueries';
import {IContact} from '../../../RightSideContainer/TeamInbox/Conversations/interfaces';
import EyeOpenSvg from '../../Svg/WidgetsSvg/EyeOpenSvg';
import {PERSON_TYPES} from '../../../../constants/StringConst';
import PlusIcon from '../../Svg/PlusSvg';
import AttachPatientProfileToEFaxDrawer from '../../../RightSideContainer/TeamInbox/Conversations/ConversationChannelTabs/CreateEFaxConversationDrawer/AttachPatientProfileToEFaxDrawer';
import {CONVERSATION_ACTION_CODES} from '../../../../constants/ActionConst';
import {
  downloadAttachments,
  getAttachments,
} from '../../../RightSideContainer/TeamInbox/Conversations/ConversationChannelTabs/CreateEFaxConversationDrawer/CreateEFaxConversationDrawerUtils';
import FileIconSvg from '../../Svg/FileIconSvg';
import DownloadSvg from '../../Svg/DownloadSvg';
import UserSvg from '../../Svg/UserSvg';
import {getBase64} from '../../../PersonOmniView/LeftContainer/RecentActivity/RecentReport/ReportUtils';
import {IEmailAttachmentResponse} from '../../EmailDrawerCommonV2/interfaces';
import {ToastType, showToast} from '../../../../utils/commonViewUtils';
import CustomPatientBanner from '../../../RightSideContainer/TeamInbox/Conversations/ConversationChannelTabs/CreateEFaxConversationDrawer/CustomPatientBanner';
import MessagingContactDetailsDrawer from '../../../RightSideContainer/TeamInbox/Conversations/MessagingContactDetails/MessagingContactDetailsDrawer';
import ReplaceActionSvg from '../../Svg/PersonActionSvgIcons/ReplaceActionSvg';
import {useIntl} from 'react-intl';
import { EFAX_SOURCE } from '../../../RightSideContainer/TeamInbox/Conversations/ConversationChannelTabs/ConversationChannelTabConst';
import { Tooltip } from 'antd';
import { TestIdentifiers, testID } from '../../../../testUtils';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { getViewInEHRLink, redirectToNewTab } from '../../../SideCar/SidecarUtils';
import { getEHRUrl } from '../../../../utils/capabilityUtils';

const EFaxMessageBox = (props: IEFaxMessageBox) => {
  const conversationMessage = props?.conversationMessage;
  const subject = conversationMessage?.contentAttributes?.subject;
  const toast = useToast();
  const intl = useIntl();
  const isMessagePositionLeft = props?.messagePosition === 'left';
  const commonData = useContext(CommonDataContext);
  const isSideCarContext = commonData.sidecarContext?.isSidecar;
  const [state, setState] = useState({
    contactData: {} as IContact | any,
    showAddPatientView: false,
    attachments: [] as any,
    patientLoading: false,
    showPatientDetailsView: false,
  });

  useEffect(() => {
    getPatientsDetails(
      conversationMessage?.contentAttributes?.patientRegarding
    );
    if (conversationMessage?.uuid) {
      getAttachmentByMessage(conversationMessage?.uuid);
    }
  }, [conversationMessage?.contentAttributes?.patientRegarding]);

  const [getContactDetails] = useLazyQuery(
    ContactsQueries.GET_CONTACT_BY_UUID,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const getPatientsDetails = async (contactUuid: string) => {
    try {
      setState((prev) => {
        return {
          ...prev,
          patientLoading: true,
        };
      });
      if (contactUuid) {
        const response = await getContactDetails({
          variables: {
            contactId: contactUuid,
          },
        });
        setState((prev) => {
          return {
            ...prev,
            patientLoading: false,
            contactData: response?.data?.contacts[0],
          };
        });
      } else {
        setState((prev) => {
          return {
            ...prev,
            patientLoading: false,
            contactData: undefined,
          };
        });
      }
    } catch (e) {
      setState((prev) => {
        return {
          ...prev,
          patientLoading: false,
          contactData: undefined,
        };
      });
    }
  };

  const getAttachmentByMessage = async (messageUuid: string) => {
    const response = await getAttachments({
      messageUuid: messageUuid,
    });
    setState((prev) => {
      return {
        ...prev,
        attachments: response,
      };
    });
  };

  const onActionPerformed = (actionCode: string, actionData?: any) => {
    switch (actionCode) {
      case CONVERSATION_ACTION_CODES.DRAWER_CLOSE:
        setState((prev) => {
          return {
            ...prev,
            showAddPatientView: false,
            showPatientDetailsView: false,
          };
        });
        break;

      case CONVERSATION_ACTION_CODES.PATIENT_REGARDING_UPDATE:
        if (conversationMessage) {
          conversationMessage.contentAttributes = actionData;
        }
        getPatientsDetails(
          conversationMessage?.contentAttributes?.patientRegarding
        );
        break;
    }
  };

  const downloadAttachment = async (data: {
    attachment: IEmailAttachmentResponse;
  }) => {
    showToast(toast, 'Downloading...', ToastType.info);
    try {
      const file: any = {};
      const response = await downloadAttachments({
        attachmentId: data.attachment.crmAttachments.id,
      });
      file.blob = response.data;
      file.blobUrl = URL.createObjectURL(file.blob);
      file.preview = await getBase64(response.data);
      const link = document.createElement('a');
      link.href = file.blobUrl;
      link.download = data.attachment.crmAttachments.name;
      link.click();
    } catch (error) {
      showToast(toast, 'Failed to download attachment', ToastType.error);
    }
  };

  const openPatientProfile = () => {
    if (isSideCarContext) {
      const contactData = state.contactData;
      const ehrPatientId =  contactData?.patient?.patientId || '';
      const ehrURL = getEHRUrl(contactData?.contactPracticeLocations?.[0]?.accountLocation?.uuid, '', commonData);
      const openInEhrUrl = getViewInEHRLink(ehrPatientId, ehrURL);
      if (openInEhrUrl) {
        window.open(openInEhrUrl, '_blank', 'noopener,noreferrer');
      } else {
        const isCustomer =
          contactData?.contactType?.contactType?.code == PERSON_TYPES.CUSTOMER;
        redirectToNewTab(
          `/members/${isCustomer ? 'patient' : 'prospect'}/${contactData?.id}`
        );
      }
    } else {
      setState((prev) => {
        return {
          ...prev,
          showPatientDetailsView: true,
        };
      });
    }
  }

  const getPatientBannerView = (): JSX.Element => {
    return (
      <Stack
        direction="row"
        style={{
          paddingVertical: 4,
          alignItems: 'center',
          flex: 1,
          marginTop: 4,
        }}
      >
        <Stack
          direction="row"
          style={{
            flex: 1,
            alignItems: 'center',
            borderWidth: 1,
            borderRadius: 8,
            borderColor: isMessagePositionLeft
              ? Colors.Custom.Gray200
              : Colors.Custom.White,
            padding: 8,
          }}
        >
          <Stack direction="row" style={{flex: 0.6, alignItems: 'center'}}>
            {state.contactData &&
            props?.conversationMessage?.contentAttributes ? (
              <>
                <CustomPatientBanner
                  contactData={state.contactData}
                  isMessagePositionLeft={isMessagePositionLeft}
                />
              </>
            ) : (
              <Stack
                direction="row"
                style={{
                  alignItems: 'center',
                }}
              >
                <View
                  style={{
                    backgroundColor: Colors.Custom.PrimaryColor100,
                    padding: 2,
                    borderRadius: 6,
                    borderWidth: 1,
                    borderColor: Colors.Custom.PrimaryColor200,
                  }}
                  {...testID('image')}
                >
                  <UserSvg
                    customStrokeColor={Colors.Custom.mainPrimaryPurple}
                    width={15}
                    height={15}
                  />
                </View>
                <Text
                  textOverflow={'ellipsis'}
                  numberOfLines={1}
                  style={{
                    color: isMessagePositionLeft
                      ? Colors.Custom.Gray500
                      : Colors.Custom.White,
                    fontSize: 14,
                    paddingLeft: 6,
                  }}
                  {...testID('AddPatientContext')}
                >
                  {intl.formatMessage({id: 'addPatientContext'})}
                </Text>
              </Stack>
            )}
          </Stack>

          <Stack
            direction="row"
            style={{
              paddingLeft: 15,
              justifyContent: 'flex-end',
              flex: 0.4,
              alignItems: 'center',
            }}
          >
            {state?.contactData && (
              <View
                style={{
                  paddingRight: 5,
                }}
              >
                <Tooltip title={'View Patient Profile'}>
                  <Pressable
                    onPress={() => {
                      openPatientProfile();
                    }}
                    {...testID(TestIdentifiers.showPasswordBtn)}
                  >
                    <EyeOpenSvg
                      customStrokeColor={
                        isMessagePositionLeft
                          ? Colors.Custom.Gray400
                          : Colors.Custom.White
                      }
                    />
                  </Pressable>
                </Tooltip>
              </View>
            )}
            <View
              style={{
                borderLeftWidth: 1,
                borderLeftColor: Colors.Custom.Gray200,
              }}
            >
              <Tooltip title={state?.contactData ? 'Edit Patient Context' : 'Add Patient Context'}>
                <Pressable
                  style={{paddingLeft: 5}}
                  onPress={() => {
                    if (conversationMessage?.contentAttributes?.attachmentSource === EFAX_SOURCE.PATIENT_PROFILE) {
                      showToast(
                        toast,
                        intl.formatMessage({id: 'eFaxFromPatientProfile'}),
                        ToastType.info
                      )
                    } else{
                      setState((prev) => {
                        return {
                          ...prev,
                          showAddPatientView: true,
                        };
                      });
                    }
                  }}
                  {...testID(state?.contactData ? 'EditPatientContext' : 'AddPatientContext')}
                >
                  {state?.contactData ? (
                    <ReplaceActionSvg
                      customStrokeColor={
                        isMessagePositionLeft
                          ? Colors.Custom.Gray400
                          : Colors.Custom.White
                      }
                    />
                  ) : (
                    <PlusIcon
                      defaultColor={
                        isMessagePositionLeft
                          ? Colors.Custom.Gray400
                          : Colors.Custom.White
                      }
                      height={18}
                      width={18}
                    />
                  )}
                </Pressable>
              </Tooltip>
            </View>
          </Stack>
        </Stack>
      </Stack>
    );
  };

  const getMessageAttachmentView = (): JSX.Element => {
    return state.attachments?.map((item: any) => {
      return (
        <Stack
          direction="row"
          style={{
            alignItems: 'center',
            marginVertical: 4,
            backgroundColor: isMessagePositionLeft
              ? Colors.Custom.PrimaryColor100
              : Colors.Custom.White,
            padding: 8,
            borderRadius: 8,
            borderWidth: isMessagePositionLeft ? 0.5 : 0,
            borderColor: isMessagePositionLeft
              ? Colors.Custom.mainPrimaryPurple
              : Colors.Custom.White,
          }}
        >
          <Stack
            direction="row"
            style={{
              flex: 0.8,
              alignItems: 'center',
            }}
          >
            <FileIconSvg
              customStrokeColor={
                isMessagePositionLeft
                  ? Colors.Custom.mainPrimaryPurple
                  : Colors.Custom.Gray400
              }
            />
            <Text
              size={'smRegular'}
              numberOfLines={1}
              style={{
                paddingLeft: 5,
                color: isMessagePositionLeft
                  ? Colors.Custom.mainPrimaryPurple
                  : Colors.Custom.Gray500,
              }}
              {...testID('EFaxContentAttachmentName')}
            >
              {item?.crmAttachments?.name}
            </Text>
          </Stack>
          <Stack
            direction="row"
            style={{
              justifyContent: 'flex-end',
              flex: 0.2,
              alignItems: 'center',
            }}
          >
            <Pressable
              style={{
                borderLeftWidth: 1,
                borderLeftColor: Colors.Custom.Gray300,
                paddingLeft: 5,
              }}
              onPress={() => {
                downloadAttachment({attachment: item});
              }}
              {...testID(TestIdentifiers.downloadBtn)}
            >
              <DownloadSvg
                customColor={
                  isMessagePositionLeft
                    ? Colors.Custom.mainPrimaryPurple
                    : Colors.Custom.Gray400
                }
                customStrokeWidth="1"
              />
            </Pressable>
          </Stack>
        </Stack>
      );
    });
  };

  const updatePatientContext = () => {
    const attachmentIds = state?.attachments?.map((item: any) => {
      return item?.crmAttachments?.id;
    });
    return (
      <AttachPatientProfileToEFaxDrawer
        messageUuid={conversationMessage?.uuid}
        visible={state.showAddPatientView}
        onActionPerformed={onActionPerformed}
        contactData={state?.contactData}
        conversationMessage={conversationMessage}
        attachmentIds={attachmentIds}
        conversationUuid={props?.conversationUuid}
      />
    );
  };

  return (
    <View {...testID('EFaxMessageContent')}>
      <Stack
        direction="column"
        style={{
          backgroundColor: isMessagePositionLeft
            ? Colors.Custom.White
            : Colors.Custom.mainPrimaryPurple,
          padding: 4,
        }}
      >
        <Stack direction="column">
          {subject && (
            <Text
              size={'smRegular'}
              style={{
                color: isMessagePositionLeft
                  ? Colors.Custom.Gray600
                  : Colors.Custom.White,
                paddingVertical: 2,
              }}
              {...testID('Subject')}
            >{`Subject: ${subject}`}</Text>
          )}
          <Text
            size={'smRegular'}
            style={{
              color: isMessagePositionLeft
                ? Colors.Custom.Gray600
                : Colors.Custom.White,
              paddingVertical: 2,
            }}
            {...testID('EFaxContent')}
          >
            {conversationMessage?.content}
          </Text>
          {getMessageAttachmentView()}
          {state.patientLoading ? (
            <View style={{padding: 6}} {...testID(TestIdentifiers.lazyLoading)}>
              <Skeleton.Text lines={2} />
            </View>
          ) : (
            getPatientBannerView()
          )}
        </Stack>
      </Stack>
      {state.showAddPatientView && (
        updatePatientContext()
      )}
      {state.showPatientDetailsView && (
        <MessagingContactDetailsDrawer
          contactData={state.contactData}
          isDrawerVisible={state.showPatientDetailsView}
          contactType={PERSON_TYPES.CUSTOMER}
          onSideBarActionPerformed={(action?: any) => {
            if (action == CONVERSATION_ACTION_CODES.DRAWER_CLOSE) {
              onActionPerformed(action);
            }
          }}
          borderLessView={true}
        />
      )}
    </View>
  );
};

export default EFaxMessageBox;
