import {StyleSheet} from 'react-native';
import {Colors} from '../../../styles';
import {isWeb} from '../../../utils/platformCheckUtils';
import { SM_CONFIG } from '../../../theme/FontConfig/TextFontSizeConfig';

export const styles = StyleSheet.create({
  mainContainer: {
    paddingVertical: 12,
    flexDirection: 'row',
    overflow: 'hidden',
    justifyContent: 'space-between',
    paddingHorizontal: isWeb() ? 4 : 10,
  },
  iconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '5%',
    height: 40,
  },
  textContainer: {
    flexDirection: 'column',
    padding: 8,
    alignItems: 'flex-start',
    borderRadius: 8,
    backgroundColor: Colors.Custom.Gray100,
    width: '90%',
  },
  line: {
    width: 2,
    height: '100%',
    backgroundColor: Colors.Custom.Gray100,
  },
  iconSubContainer: {
    borderRadius: 100,
    height: 32,
    width: 32,
    backgroundColor: 'white',
    borderColor: Colors.Custom.Gray200,
    borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  textSubContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  text: {
    color: Colors.FoldPixel.GRAY400,
    ...SM_CONFIG.smRegular,
    fontWeight: '500',
    lineHeight: 21,
    ...(isWeb() ? {} : {width: 200}),
  },
  time: {
    color: Colors.Custom.Gray400,
    fontSize: 12,
    fontWeight: '600',
    lineHeight: 21,
    letterSpacing: 1.2,
    textTransform: 'uppercase',
  },
});
