import React, {useMemo} from 'react';
import {IContactCareProgram, IContactCareProgramStep} from '../../interface';
import {CARE_PROGRAM_STEP_TYPE} from '../../../../common/MemebersView/constant';
import {getExpiryTimerElement} from '../OutreachViewUtils';
import {IStepsLog} from '../../../../../services/ContactCareProgram/interface';

const SideBarRightView: React.FC<{
  step: IContactCareProgramStep;
  stepLogs: IStepsLog[];
  stepStatusId: string;
}> = (props) => {
  const {step, stepStatusId, stepLogs} = props;

  const stepLog = useMemo(
    () => stepLogs.find((log) => log.careProgramStepId === step.id),
    [stepLogs, step.id]
  );
  const expiresOn = stepLog?.expiresOn;
  let elem = null;
  switch (step.careProgramStepType?.code) {
    case CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_14DAY:
    case CARE_PROGRAM_STEP_TYPE.OUTREACH:
    case CARE_PROGRAM_STEP_TYPE.ECM_OUTREACH:
    case CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_2DAY:
      const expiryElem = expiresOn ? (
        getExpiryTimerElement(
          new Date(expiresOn),
          stepStatusId as string,
          step?.careProgramStepType?.code
        )
      ) : (
        <></>
      );
      elem = expiryElem;
      break;
    default:
      break;
  }

  return elem;
};

export default SideBarRightView;
