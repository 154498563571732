import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {
  Button,
  HStack,
  Icon,
  View,
  VStack,
  Text,
  Pressable,
  Spacer,
  FormControl,
  Select,
  IconButton,
  Box,
  Divider,
  FlatList,
  Spinner,
} from 'native-base';
import { Collapse, Tooltip } from 'antd';
import PAMISearch, {
  PAMISearchType,
} from '../../../../../../common/PAMISearch/PAMISearch';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {SURGICAL_HISTORY_STATUS_CODE} from '../../../../../../../constants/MlovConst';
import Feather from 'react-native-vector-icons/Feather';
import {useIntl} from 'react-intl';
import {CommonDataContext} from '../../../../../../../context/CommonDataContext';
import {
  FormContext,
  FormViewType,
  IFormCommonData,
  IFormComponentProps,
} from '../../CustomWrapper/CustomWrapper';
import {
  fhirAddOrUpdate,
  getResourceContentWithHeaders,
  acceptIgnorePatientReportedRecords,
  deleteResource,
} from '../../../../../../../services/CommonService/AidBoxService';
import FormComponentLoader from '../../CustomWrapper/FormComponentLoader/FormComponentLoader';
import {
  getRecordListFormatted,
  getFHIRTemplate,
  getHistoryName,
  isInvalid,
  SurgicalHistoryFields,
  getSampleData,
} from './AddOrUpdateSurgicalHistoryHelper';
import {
  IAddOrUpdateSurgicalHXState,
  ISurgicalHistory,
  ISurgicalHXComponentValue,
} from '../interfaces';
import {FHIR_RESOURCE, PatientRecordActions, RESOURCE_BLOCK_MESSAGE_ID} from '../../../../../../../constants/FhirConstant';
import {PossibleValue} from '../../../../../../../Interfaces';
import FormComponentError from '../../CustomWrapper/FormComponentError/FormComponentError';
import {
  ADD_UPDATE_EVENTS,
  componentKeys,
  FormError,
  getHeaderButtons,
  tooltipInnerOverlayStyle,
  tooltipOverlayStyle,
  tooltipPlacement,
  getSectionNoteDetails,
  getHeaderViewAllButton,
} from '../../CustomWrapper/CustomComponentHelper';
import {v4 as uuidv4} from 'uuid';
import {Colors} from '../../../../../../../styles';
import SectionLoadingIndicator from '../../CustomWrapper/SectionLoadingIndicator/SectionLoadingIndicator';
import CustomDetailTableView, {
  ICustomDetailColumn,
} from '../../CustomWrapper/CustomDetailTableView/CustomDetailTableView';
import {DatePicker, Drawer, Popover, notification} from 'antd';
import {BUTTON_TYPE, DISPLAY_DATE_FORMAT, HTTP_ERROR_CODE} from '../../../../../../../constants';
import {
  getDateStrFromFormat,
  getDisplayMonths,
  getMomentObjectWithDateStringAndFormat,
  getMomentObjFromFormat,
  isFutureDate,
} from '../../../../../../../utils/DateUtils';
import CompactListView from '../../CustomWrapper/CompactListView/CompactListView';
import {getStatusElementByStatus} from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailTables/DetailTableUtils';
import {DetailViewCollapse} from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailViewCollapse';
import {EmptyStateSvg} from '../../../../../../common/Svg';
import {EventBus} from '../../../../../../../utils/EventBus';
import CustomButton from '../../CustomWrapper/CustomButton/CustomButton';
import { DisplayText } from '../../../../../../common/DisplayText/DisplayText';
import ModalActionBtn from '../../../../../../common/ModalActionBtn/ModalActionBtn';
import PatientReportedTableView from '../../CustomWrapper/PatientReportedTableView/PatientReportedTableView';
import { InputType } from '../../../../../../../utils/capabilityUtils';
import { useContainerDimensions } from '../../../../../../CustomHooks/ContainerDimensionHook';
import { cloneDeep } from 'lodash';
import { ModalActionTitle } from '../../../../../../common/ModalActionTitle/ModalActionTitle';
import { CUSTOM_COMPONENT_STYLE } from '../../constants';
import { EHRName } from '../../../../../../PersonOmniView/MiddleContainer/PatientNotes/interfaces';
import {FoldButton} from '../../../../../../CommonComponents/FoldButton/FoldButton';
import DetailPreview, { ICustomComponentPreviewData } from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailPreview/DetailPreview';
import { RightOutlined } from '@ant-design/icons';
import SectionLevelDetails from '../../CustomWrapper/SectionLevelDetails/SectionLevelDetails';
import { IFormValidationOutput } from '../../CustomWrapper/interfaces';
import { getOrganizationCapabilites } from '../../../../../TeamInbox/Conversations/MessagingContactDetails/ContactDetailsTabs/ActivityTimeLine/ActivityAdditionalInfo/CommonComponent/FormResponse/FormUtils';
import useBulkActionPatientReportedRecords from '../../../../../../CustomHooks/useBulkActionPatientReportedRecords';
import PatientIntakeConfirm from '../../../../../../common/PatientIntakeConfirm/PatientIntakeConfirm';
import RecentReportedViewHeader from '../../../../../../common/RecentReportedViewHeader/RecentReportedViewHeader';
import { getAcceptedAndIgnoredRecordNames } from '../../../../../../common/PatientIntakeConfirm/utils';
import { renderSectionBulletList } from '../../../../../../common/CommonUtils/CommonUtils';
import { filterAndSkipDuplicates } from '../../CustomComponentUtils';
import { reactStyles } from '../../../../Analytics/style';

const AddOrUpdateSurgicalHistory = (props: IFormComponentProps) => {
  const intl = useIntl();
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const organizationCapability = getOrganizationCapabilites(
    contextData.organizationCapability,
    FHIR_RESOURCE.CLINICAL_DATA
  );
  const isPatientForm = contextData.formContext === FormContext.patientForm;
  const isPatientCarePlan = contextData.formContext === FormContext.patientCarePlan;
  const isPatientNote = contextData.formContext === FormContext.patientNotes;
  const isPatientProfile = contextData.formContext === FormContext.patientProfile;
  const isCompactView = contextData.componentView === FormViewType.compact;
  const isRecentReported = contextData.formContext === FormContext.recentReportedView;
  const isPreviewMode = contextData.isPreviewMode || false;
  const isAllowToShare = props?.component?.allowToShare != undefined &&  props?.component?.allowToShare === false ? false : true;
  const componentRef = useRef();
  const drawerDetailRef = useRef();
  const { width } = useContainerDimensions(componentRef);
  const { width: drawerDetailWidth, resetDimension } = useContainerDimensions(drawerDetailRef);
  const isMobileScreen = isCompactView ? drawerDetailWidth <= 480 : width <= 480;
  const [componentValue, setComponentValue] =
    useState<ISurgicalHXComponentValue>(
      props.component?.selectedValue || {surgicalHistory: []}
    );
  const bulkAction = useBulkActionPatientReportedRecords();
  const isCanvas = contextData.capabilities?.ehrName === EHRName.CANVAS;
  const [componentState, setComponentState] =
    useState<IAddOrUpdateSurgicalHXState>({
      loading: false,
      formError: contextData.formError,
      collapsed: false,
      showSavingIndicator: false,
      editModalVisible: false,
      editModalCollapse: false,
      showPatientReportedRecords: false,
      selectedPatientReportedRecord: undefined,
      selectedItemToMap: undefined,
      patientReported: [],
      sectionNote: '',
      activePanels: [],
    });

    const { Panel } = Collapse;
  const [importFromChartLoading, setImportFromChartLoading] = useState<boolean>(false);

  const getFieldCapabilities = () => {
    return contextData.capabilities?.abilities?.keyAllowedOperations;
  };

  const dateFormat = getFieldCapabilities()?.performedDateTime?.format;

  const validateData = (currentData: ISurgicalHXComponentValue): IFormValidationOutput => {
    // If no patient id is not found means it's lead and for lead, the section is not applicable. So validation should always return true
    if (componentState.formError === FormError.noPatientIdFound) {
      return {isValid: true, message: ''};
    }
    const hasMissingData = currentData.surgicalHistory.some((item) => {
      return isInvalidRecord(item);
    });
    return { isValid: !hasMissingData, message: hasMissingData ? `${props.component.label}: Please fill all the mandatory fields` : '' };
  };

  const isInvalidRecord = (item: ISurgicalHistory) => {
    const config = getFieldCapabilities();
    return (
      isInvalid(SurgicalHistoryFields.status, item, true, config) ||
      isInvalid(SurgicalHistoryFields.performedDateTime, item, true, config)
    );
  };

  const getStatusList = (): PossibleValue[] => {
    return getFieldCapabilities()?.status?.possibleValues || [];
  };

  const getStatusFromCode = (code: string) => {
    const list = getStatusList();
    return list.find((status: any) => {
      return status.code === code;
    });
  };

  const canAddNew = (): boolean => {
    if(isPatientProfile && organizationCapability?.canCreate === false){
      return false
    }
    return (
      contextData.isBuilderMode ||
      ((contextData.capabilities?.abilities?.allowedOperations?.canCreate ||
        false) &&
        !props.disabled)
    );
  };
  const canDelete = (): boolean => {
    if (isPatientCarePlan) {
      return true;
    }
    if(isPatientProfile && organizationCapability?.canDelete === false){
      return false
    }
    return (
      contextData.capabilities?.abilities?.allowedOperations?.canDelete || false
    );
  };
  const canUpdate = (): boolean => {
    if(isPatientProfile && organizationCapability?.canUpdate === false){
      return false
    }
    return (
      (contextData.capabilities?.abilities?.allowedOperations?.canUpdate ||
        false) &&
      !props.disabled &&
      !isPatientForm
    );
  };

  const onDetailChange = (data: ISurgicalHistory, index: number) => {
    onRecordChange(data);
    setComponentValue((prev) => {
      const list = prev.surgicalHistory;
      list[index] = data;
      return {
        ...prev,
        surgicalHistory: list,
      };
    });
  };
  const removeFromLocalList = (uniqueId?: string) => {
    const index = componentValue.surgicalHistory.findIndex(
      (item) => item?.uniqueId === uniqueId
    );
    componentValue.surgicalHistory.splice(index, 1);
    setComponentValue((prev) => ({
      ...prev,
      surgicalHistory: [...componentValue.surgicalHistory],
    }));
  };
  const onDelete = (
    data: ISurgicalHistory,
    index: number,
    isLocalDelete: boolean
  ) => {
    if (!isLocalDelete && data.id) {
      deleteResource(
        FHIR_RESOURCE.PROCEDURE,
        `${data.id}`,
        !contextData.hasLoggedInContext,
        contextData.headers,
        {
          patientId: contextData.patientId,
          locationId: contextData.locationId,
        },
        (response) => {
          if (response?.success) {
            removeFromLocalList(data?.uniqueId);
            broadcastUpdateEvent();
          }
        },
        (error) => {

          setComponentState((prev) => ({
            ...prev,
            loading: false,
            showSavingIndicator: false,
            formError: FormError.existingDataAPIFail,
          }));
        },
      );
    } else {
      removeFromLocalList(data?.uniqueId);
    }
  };

  const addNewItem = (item: any) => {
    const data: ISurgicalHistory = {
      surgicalHistory: item,
      name: getHistoryName(item),
      status: getStatusFromCode(SURGICAL_HISTORY_STATUS_CODE.COMPLETED),
      uniqueId: uuidv4(),
      isFreeTextRecord: false,
    };
    componentValue.surgicalHistory.splice(0, 0, data);
    onRecordChange(data);
    setComponentValue({
      surgicalHistory: [...componentValue.surgicalHistory],
    });
  };

  const onRecordChange = (record: ISurgicalHistory) => {
    if (contextData.backgroundSaveEnabled) {
      addOrUpdateRecord(record);
    }
  };

  const isFieldDisabled = (item: ISurgicalHistory) => {
    return props.disabled || (!canUpdate() && !!item.id);
  };

  const isInvalidFieldData = (
    field: SurgicalHistoryFields,
    item: ISurgicalHistory
  ) => {
    return isInvalid(field, item, true, getFieldCapabilities());
  };

  const isOpenChoice = () => {
    const fields = getFieldCapabilities();
    return fields?.code?.inputType == InputType.openChoice;
  }

  const isFreeText = () => {
    const fields = getFieldCapabilities();
    return fields?.code?.inputType == InputType.freeText;
  }

  const getSearchComponent = (fullWidth?: boolean): JSX.Element | undefined => {
    return (
      <Pressable
        width={'100%'}
        paddingRight={fullWidth ? 6 : 0}
        onPress={(event) => event.stopPropagation()}
      >
        <View
          flex={1}
          style={!isMobileScreen ? {width: fullWidth ? '100%' : 350} : {}}
        >
          <PAMISearch
            addNewOptionEnabled={isPatientForm || contextData.isBuilderMode || isOpenChoice() || isFreeText()}
            skipAPICall={isFreeText()}
            clearOnSelection
            searchType={PAMISearchType.procedure}
            additionalHeaders={contextData.headers}
            placeholder={isFreeText() ? 'Type and add new procedure' : 'Search and add new procedure'}
            isShowError={false}
            onChange={(value) => {
              if (value) {
                addNewItem(value);
              }
            }}
            locationId={contextData.locationId}
          />
        </View>
      </Pressable>
    );
  };

  const getHeaderRightView = (isCompact: boolean): JSX.Element | undefined => {
    if (!canAddNew()) {
      if (isCompact) {
        return getHeaderViewAllButton(() => {
          setComponentState((prev) => ({...prev, editModalVisible: true}));
        }, 'View all');
      } else {
        return;
      }
    }


    if (isCompact) {
      return getHeaderButtons(() => {
        setComponentState((prev) => ({...prev, editModalVisible: true}));
      });
    } else if (!isMobileScreen) {
      return (
        <HStack space={2} alignItems="center">
          <Spacer />
          {componentState.showSavingIndicator && <SectionLoadingIndicator />}
        </HStack>
      );
    }
  };

  const isFieldEnabled = (field: SurgicalHistoryFields) => {
    if (props.component?.enabledFields) {
      return props.component?.enabledFields[field];
    }
    return IsFieldVisible(field);
  };

  const IsFieldVisible = (field: SurgicalHistoryFields) => {
    const config = getFieldCapabilities();
    let isDisplayField = false;
    switch(field) {
      case SurgicalHistoryFields.code:
        if (config && config['code']) {
          isDisplayField = config['code']?.isHidden ? false : true;
        }
        break;
      case SurgicalHistoryFields.status:
        if (config && config['status']) {
          isDisplayField = config['status']?.isHidden ? false : true;
        }
        break;
      case SurgicalHistoryFields.performedDateTime:
        if (config && config['performedDateTime']) {
          isDisplayField = config['performedDateTime']?.isHidden ? false : true;
        }
        break;
    }
    return isDisplayField;

  }
  const canShowDeleteBtn = (itemId?: string): boolean => {
    if(isPatientNote){
      return true;
    }
    if (isPatientForm) {
      return !itemId;
    }
    return canDelete() || contextData.isBuilderMode;
  };
const getDeleteFunction = (item: ISurgicalHistory, index: number) => {
  const isLocalDelete =
    (isPatientForm && !item.id) ||
    contextData.isBuilderMode ||
    !contextData.backgroundSaveEnabled;

  if (isLocalDelete) {
    onDelete(item, index, true);
  } else if (canDelete()) {
    onDelete(item, index, false);
  }
};
  const getColumns = (): ICustomDetailColumn[] => {
    const fields = getFieldCapabilities();
    const columns: ICustomDetailColumn[] = [
      {
        title: intl.formatMessage({id: 'name'}),
        flex: 3,
        render: (item: ISurgicalHistory) => {
          return <Text>{item.name}</Text>;
        },
      },
    ];
    if (isFieldEnabled(SurgicalHistoryFields.status)) {
      const statusList = getStatusList();
      const label = intl.formatMessage({id: 'status'});
      columns.push({
        title: label,
        flex: 2,
        render: (item: ISurgicalHistory, index: number) => {
          const isDisabled = isFieldDisabled(item);
          const isInvalid = isInvalidFieldData(
            SurgicalHistoryFields.status,
            item
          );
          return (
            <FormControl isInvalid={isInvalid && !isDisabled}>
              <Select
                placeholder={label}
                selectedValue={item.status?.code}
                isDisabled={isDisabled}
                onValueChange={(value: string) => {
                  item.status = statusList.find((status: any) => {
                    return status.code === value;
                  });
                  onDetailChange(item, index);
                }}
              >
                {statusList.map((option: any, index: number) => {
                  return (
                    <Select.Item
                      key={`${option.id}_${index}`}
                      value={option.code}
                      label={option.display}
                    />
                  );
                })}
              </Select>
            </FormControl>
          );
        },
      });
    }
    if (isFieldEnabled(SurgicalHistoryFields.performedDateTime)) {
      const isOnlyYearFormat = dateFormat === 'YYYY';
      const label = intl.formatMessage({id: 'performedDate'});
      columns.push({
        title: label,
        flex: 2,
        render: (item: ISurgicalHistory, index: number) => {
          const isDisabled = isFieldDisabled(item);
          const isInvalid = isInvalidFieldData(
            SurgicalHistoryFields.performedDateTime,
            item
          );
          return (
            <DatePicker
              picker={isOnlyYearFormat ? 'year' : 'date'}
              disabled={isDisabled}
              placeholder={label}
              format={isOnlyYearFormat ? dateFormat : DISPLAY_DATE_FORMAT}
              disabledDate={(current: any) => {
                return current && isFutureDate(current);
              }}
              value={
                item.performedDateTime
                  ? getMomentObjectWithDateStringAndFormat(
                      item.performedDateTime,
                      dateFormat || ''
                    )
                  : undefined
              }
              style={reactStyles.height40px}
              className={isInvalid && !isDisabled ? 'field-error' : ''}
              onChange={(value) => {
                item.performedDateTime = dateFormat
                  ? value?.format(dateFormat)
                  : value?.toISOString();
                onDetailChange(item, index);
              }}
            />
          );
        },
      });
    }
    const actionsAvailable = canShowDeleteBtn();
    if (!props.disabled && actionsAvailable) {
      columns.push({
        title:  intl.formatMessage({id: 'actions'}),
        flex: 0.8,
        render: (item: ISurgicalHistory, index: number) => {
          return (
            <HStack alignItems={'center'}>
              <Spacer />
              {canShowDeleteBtn(item.id) ? (
                <Pressable
                  onPress={() => getDeleteFunction(item, index)}
                  borderWidth={0.3}
                  backgroundColor={Colors.Custom.Base50}
                  borderColor={Colors.Custom.ActionBtnBorder}
                  px={2}
                  py={1}
                  rounded="full"
                  shadow={2}
                >
                  <Feather
                    name={
                      canDelete() || contextData.isBuilderMode ? 'trash-2' : 'x'
                    }
                    size={20}
                    color={Colors.Custom.PurpleColor}
                  />
                </Pressable>
              ) : null}
            </HStack>
          );
        },
      });
    }
    return columns;
  };

  const getSectionAdditionalView = (isCompactView: boolean) => {
    const fieldCapabilities = getFieldCapabilities();
    if (fieldCapabilities?.sectionNote) {
      return (
        <VStack paddingX={isCompactView ? 4 : 0} paddingTop={isCompactView ? 3 : 1} paddingBottom={isCompactView ? 2 : 0}>
          <SectionLevelDetails
            organizationCapabilites={organizationCapability}
            patientId={contextData.patientId || ''}
            accountLocationId={contextData.locationId}
            fieldCapabilities={fieldCapabilities}
            resource={FHIR_RESOURCE.PROCEDURE}
            note={componentState.sectionNote || ''}
            onChange={(text) => setComponentState(prev => ({...prev, sectionNote: text}))}
          />
        </VStack>
      );
    }
    return;
  }

  const getDetailView = (isCompact: boolean, isPatientReported: boolean): JSX.Element => {
    if (!componentValue.surgicalHistory.length && !isCompact && !isPatientReported) {
      return (
        <VStack paddingBottom={5} alignItems={'center'}>
          <EmptyStateSvg titleId="noSurgicalHistory" />
          <HStack space={2} alignItems="center">
            <Spacer />
            {componentState.showSavingIndicator && <SectionLoadingIndicator />}
            {canAddNew() && getSearchComponent()}
          </HStack>
        </VStack>
      );
    }

    if (isCompact) {
      return (
        <>
        <CompactListView
          hasPatientReportedRecords={componentState.patientReported.length > 0}
          onViewPatientReportedClick={() => {
            setComponentState((prev) => ({...prev, showPatientReportedRecords: true}));
          }}
          dataSource={componentValue.surgicalHistory}
          showMoreMaxCount={contextData.showMoreMaxCount}
          rowViewForItem={(item: ISurgicalHistory) => {
            let displayDate: JSX.Element | undefined;
            let displayStatus: JSX.Element | undefined;
            let displayDateItem = '';

            if (item.performedDateTime) {
              const date = getMomentObjectWithDateStringAndFormat(
                item.performedDateTime,
                dateFormat || ''
              );

              /**
               * Difference in Months
               */
              displayDateItem = getDisplayMonths(
                getMomentObjFromFormat(date, DISPLAY_DATE_FORMAT),
                getMomentObjFromFormat(new Date(), DISPLAY_DATE_FORMAT)
              );

              displayDate = (
                <Text size={'smMedium'} color={Colors.Custom['Gray500']}>
                  {displayDateItem}
                </Text>
              );
            }

            if (item.status?.code) {
              displayStatus = getStatusElementByStatus(item.status.code);
            }

            return (
              <VStack key={item.id}>
                <HStack space={1} alignItems="center">
                  <Text color={Colors.FoldPixel.GRAY400}>
                    {!displayDateItem
                      ? item.name
                      : `${item.name} (${displayDateItem})`}
                  </Text>
                  <Spacer />
                  {/* {displayStatus} */}
                </HStack>
                {/* {displayDate} */}
              </VStack>
            );
          }}
        />
        {getSectionAdditionalView(true)}
        </>
      );
    } else if (isPatientReported) {
      return (
        <PatientReportedTableView
          dataSource={componentState.patientReported}
          rowViewForItem={(item: ISurgicalHistory, index: number) => {
            const displayStatus = item.status?.display || item.status?.code;
            let displayDateItem = '';

            if (item.performedDateTime) {
              const date = getMomentObjectWithDateStringAndFormat(
                item.performedDateTime,
                dateFormat || ''
              );
              /**
               * Difference in Months
               */
              displayDateItem = getDisplayMonths(
                getMomentObjFromFormat(date, DISPLAY_DATE_FORMAT),
                getMomentObjFromFormat(new Date(), DISPLAY_DATE_FORMAT)
              );
            }

            return (
              <VStack key={item.id}>
                <HStack space={1} alignItems="start">
                  <VStack space={1} flex={1}>
                    <Text color={Colors.Custom['Gray900']} width="full">
                      {!displayDateItem
                        ? `${item.name}`
                        : `${item.name} (${displayDateItem})`}
                    </Text>
                    {displayStatus && (
                      <Text color={Colors.Custom['Gray500']}>
                        {displayStatus}
                      </Text>
                    )}
                  </VStack>
                  {organizationCapability?.canCreate && (
                    <HStack space={2}>
                      {/* Show Match to action only when record is free text or procedure accepts free text only */}
                      {item.isFreeTextRecord && !isFreeText() && (
                        <Popover
                          key={item.id || index}
                          overlayInnerStyle={{
                            bottom: 3,
                            borderRadius: 16,
                            padding: 0,
                          }}
                          content={
                            <View minWidth={300} maxW={400} padding={2}>
                              <VStack space={8}>
                                <Text size="xlMedium">Match to Procedure</Text>
                                <VStack space={1}>
                                  <DisplayText
                                    textLocalId={'procedure'}
                                    size="smMedium"
                                  />
                                  <PAMISearch
                                    searchType={PAMISearchType.procedure}
                                    additionalHeaders={contextData.headers}
                                    placeholder="Search Procedure"
                                    isShowError={false}
                                    onChange={(value) => {
                                      setComponentState((prev) => ({
                                        ...prev,
                                        selectedItemToMap: value,
                                      }));
                                    }}
                                    locationId={contextData.locationId}
                                  />
                                </VStack>
                                <HStack>
                                  <Spacer />
                                  <Button.Group space={2}>
                                    <FoldButton
                                      nativeProps={{
                                        variant: BUTTON_TYPE.PRIMARY,
                                        onPress: () => {
                                          setComponentState((prev) => {
                                            const list = prev.patientReported;
                                            const index = list.findIndex(
                                              (value) =>
                                                value.uniqueId === item.uniqueId
                                            );
                                            if (index > -1) {
                                              list[index].surgicalHistory =
                                                componentState.selectedItemToMap;
                                              list[index].name = getHistoryName(
                                                componentState.selectedItemToMap
                                              );
                                              list[index].isFreeTextRecord = false;
                                            }
                                            return {
                                              ...prev,
                                              patientReported: list,
                                              selectedItemToMap: undefined,
                                              selectedPatientReportedRecord:
                                                undefined,
                                            };
                                          });
                                        },
                                        isDisabled:
                                          !componentState.selectedItemToMap,
                                      }}
                                      customProps={{
                                        btnText: intl.formatMessage({
                                          id: 'continue',
                                        }),
                                        withRightBorder: false,
                                      }}
                                    ></FoldButton>
                                  </Button.Group>
                                </HStack>
                              </VStack>
                            </View>
                          }
                          title=""
                          trigger="click"
                          visible={
                            componentState.selectedPatientReportedRecord ==
                            item.id
                          }
                          onVisibleChange={(visible) => {
                            setComponentState((prev) => ({
                              ...prev,
                              selectedPatientReportedRecord: visible
                                ? item.id
                                : undefined,
                            }));
                          }}
                          placement={'bottom'}
                        >
                          <CustomButton
                            title="Match to Procedure"
                            isDisabled={componentState.showSavingIndicator}
                            onPress={() => {
                              setComponentState((prev) => ({
                                ...prev,
                                selectedPatientReportedRecord: item.id,
                              }));
                            }}
                          />
                        </Popover>
                      )}
                      <CustomButton
                        title="Accept"
                        isDisabled={componentState.showSavingIndicator}
                        onPress={() => {
                          const isCurrentFreeText =
                            !item.surgicalHistory?.coding?.length;
                          if (
                            isCurrentFreeText &&
                            !isOpenChoice() &&
                            !isFreeText()
                          ) {
                            notification.error({
                              message:
                                'This free text procedure must be matched to database before it can be added to EHR',
                            });
                          } else {
                            handlePatientReportedActions(
                              PatientRecordActions.accept,
                              item
                            );
                          }
                        }}
                      />
                      <CustomButton
                        title="Discard"
                        isDisabled={componentState.showSavingIndicator}
                        onPress={() => {
                          handlePatientReportedActions(
                            PatientRecordActions.deny,
                            item
                          );
                        }}
                      />
                    </HStack>
                  )}
                </HStack>
              </VStack>
            );
          }}
        />
      );
    }

    return (
      <VStack flex={1} space={4}>
        <VStack space={2}>
          {canAddNew() && !isMobileScreen && (
            <HStack width={'100%'} margin={3} space={2} alignItems="center">
              {getSearchComponent(true)}
            </HStack>
          )}
          <CustomDetailTableView
            dataSource={componentValue.surgicalHistory}
            columns={getColumns()}
            isPatientNote={isPatientNote}
            isBuilderMode={contextData.isBuilderMode}
          />
        </VStack>
      </VStack>
    );
  };

  const onAddOrUpdateError = (error: any) => {
    const errorMessage =
      error?.response?.status === HTTP_ERROR_CODE.METHOD_NOT_ALLOWED
        ? intl.formatMessage({
            id: RESOURCE_BLOCK_MESSAGE_ID,
          })
        : error?.response?.data?.message;
    setComponentState((prev) => ({
      ...prev,
      showSavingIndicator: false,
      ...(errorMessage && {customError: errorMessage}),
      ...(!errorMessage && {nonBlockingFormError: FormError.addUpdateFail}),
    }));
  }

  const addOrUpdateRecord = (record: ISurgicalHistory) => {
    if (!isInvalidRecord(record)) {
      setComponentState((prev) => ({...prev, showSavingIndicator: true}));
      const fhirData = getFHIRTemplate({
        ...record,
        patientId: contextData.patientId || '',
        tenantId: contextData.tenantId || '',
      });
      fhirAddOrUpdate(
        FHIR_RESOURCE.PROCEDURE,
        record.id,
        fhirData,
        {
          patientId: contextData.patientId,
          locationId: contextData.locationId,
        },
        (response) => {
          broadcastUpdateEvent();
          let keepShowingSavingIndicator = false;
          if (!record.id) {
            keepShowingSavingIndicator = true;
            getExistingData({patientId: contextData.patientId || '', skipLoader: true});
          }
          if (response?.data?.id) {
            updateDataFromAPIToList(response.data, record);
          }
          setComponentState((prev) => ({
            ...prev,
            ...(!keepShowingSavingIndicator && {showSavingIndicator: false}),
            nonBlockingFormError: undefined,
          }));
        },
        (error) => {
          onAddOrUpdateError(error);
          // If new record is failed which is not saved before then only remove from local list
          if (!record.id) {
            removeFromLocalList(record.uniqueId);
          }
        }
      );
    }
  };

  const handleSubmitAllAction = async () => {
    await bulkAction.handleBulkAction({
       resource: FHIR_RESOURCE.PROCEDURE,
       action: PatientRecordActions.accept,
       getFilteredRecords: () => {
         const structuredRecords = componentState.patientReported.filter(
           (record) => !record.isFreeTextRecord
         );
         const FHIRformatedRecords = structuredRecords.map((record) =>
           getFHIRTemplate({
             ...record,
             patientId: contextData.patientId || '',
             tenantId: contextData.tenantId || '',
           })
         );
         return FHIRformatedRecords;
       },
       patientId: contextData.patientId || '',
       accountLocationUuid: contextData.locationId || '',
       onComplete: (response) => {
        broadcastUpdateEvent();
        if (response?.data?.id || response?.data?.success) {
          getExistingData({patientId: contextData.patientId || '', skipLoader: true});
        }
       },
       onError: onAddOrUpdateError,
     });
   };

  const handlePatientReportedActions = (action: PatientRecordActions, record: ISurgicalHistory) => {
    setComponentState((prev) => ({...prev, showSavingIndicator: true}));
    const fhirData = getFHIRTemplate({
      ...record,
      patientId: contextData.patientId || '',
      tenantId: contextData.tenantId || '',
    });
    acceptIgnorePatientReportedRecords(
      FHIR_RESOURCE.PROCEDURE,
      action,
      record.id,
      fhirData,
      {
        patientId: contextData.patientId,
        locationId: contextData.locationId,
      },
      (response) => {
        broadcastUpdateEvent();
        let keepShowingSavingIndicator = false;
        if (response?.data?.id || response?.data?.success) {
          if (action === PatientRecordActions.accept) {
            keepShowingSavingIndicator = true;
            // Here we only remove the specific Accepted entry so that it doesnot un-match the other matched records.
            setComponentState((prev) => ({
              ...prev,
              patientReported: prev.patientReported.filter(
                (item) => item.id !== record.id
              ),
              showSavingIndicator: false
            }));
          } else {
            updatePatientReportedDataFromAPIToList(response?.data, record, action);
          }
        }
        setComponentState((prev) => ({
          ...prev,
          ...(!keepShowingSavingIndicator && {showSavingIndicator: false}),
          nonBlockingFormError: undefined,
        }));
      },
      onAddOrUpdateError
    );
  }

  const updateDataFromAPIToList = (data: any, record: ISurgicalHistory) => {
    if (record.id) return;

    setComponentValue((prev) => {
      const list = prev.surgicalHistory;
      const index = list.findIndex(
        (value) => value.uniqueId === record.uniqueId
      );
      if (index > -1) {
        list[index].id = data.id;
      }
      return {
        ...prev,
        surgicalHistory: list,
      };
    });
  };

  const updatePatientReportedDataFromAPIToList = (data: any, record: ISurgicalHistory, action: PatientRecordActions) => {
    setComponentState((prev) => {
      const list = prev.patientReported;
      const index = list.findIndex(
        (value) => value.uniqueId === record.uniqueId
      );
      if (index > -1) {
        list.splice(index, 1);
      }
      return {
        ...prev,
        patientReported: list,
        showPatientReportedRecords: list.length > 0
      };
    });
    if (action === PatientRecordActions.accept && data?.id) {
      setComponentValue((prev) => {
        const list = prev.surgicalHistory;
        record.id = data.id;
        list.splice(0, 0, record);
        return {
          ...prev,
          surgicalHistory: list,
        };
      });
    }
  };

  const getExistingData = (params: {patientId: string, skipLoader?: boolean, manualImport?: boolean}) => {
    const { patientId, skipLoader, manualImport } = params;
    if (!skipLoader) {
      setComponentState((prev) => ({...prev, loading: true}));
    }
    getResourceContentWithHeaders(
      FHIR_RESOURCE.PROCEDURE,
      `patient=${patientId}${isRecentReported ? `&patientReportedOnly=true` : ''}`,
      !contextData.hasLoggedInContext,
      contextData.headers,
      (response) => {
        handleExistingDataResponse(response, manualImport);
      },
      (error) => {

        setComponentState((prev) => ({
          ...prev,
          loading: false,
          showSavingIndicator: false,
          formError: FormError.existingDataAPIFail,
        }));
      },
      contextData.locationId,
      !isPatientForm
    );
  };

  const handleExistingDataResponse = (response: any, manualImport? :boolean) => {
    if (isPatientForm) {
      if (response?.entry) {
        const formattedData = getRecordListFormatted(response.entry);
        const sectionNote = getSectionNoteDetails(response.meta?.extension || []).sectionNote;
        const newRecordsAddedInForm = props.component?.selectedValue?.surgicalHistory || [];
        setComponentValue((prev) => ({
          ...prev,
          surgicalHistory: [...newRecordsAddedInForm, ...formattedData],
        }));
        contextData?.updateFormPrefilledByFoldProgress?.(componentKeys.SURGICAL_HISTORY, { surgicalHistory: [...formattedData] });
        setComponentState((prev) => ({
          ...prev,
          loading: false,
          sectionNote: sectionNote,
          showSavingIndicator: false,
          activePanels: formattedData?.length ? [componentKeys.SURGICAL_HISTORY] : [],
          formError: !response?.entry
            ? FormError.existingDataAPIFail
            : prev.formError,
        }));
      }
    } else {
      const patientReportedRecords = response?.patientReported?.data?.entry || [];
      const ehrRecords = response?.resource?.data?.entry || [];
      const sectionNote = getSectionNoteDetails(response?.resource?.data?.meta?.extension || []).sectionNote;
      let patientReportedFormattedRecords: ISurgicalHistory[] = [];
      if (patientReportedRecords || ehrRecords) {
        let ehrFormattedRecords: ISurgicalHistory[] = [];
        if (patientReportedRecords?.length) {
          patientReportedFormattedRecords = getRecordListFormatted(patientReportedRecords);
        }
        if (ehrRecords?.length) {
          ehrFormattedRecords = getRecordListFormatted(ehrRecords);
          if (manualImport) {
             ehrFormattedRecords = filterAndSkipDuplicates<ISurgicalHistory>(
               componentValue.surgicalHistory,
               ehrFormattedRecords
             );
          };
        }
        setComponentValue((prev) => ({
          ...prev,
          surgicalHistory: [...ehrFormattedRecords]
        }));
        contextData?.updateFormPrefilledByFoldProgress?.(componentKeys.SURGICAL_HISTORY, { surgicalHistory: [...ehrFormattedRecords] });
      }
      setImportFromChartLoading(false);
      setComponentState((prev) => ({
        ...prev,
        loading: false,
        sectionNote: sectionNote,
        showSavingIndicator: false,
        ...(!patientReportedFormattedRecords.length && {showPatientReportedRecords: false}),
        patientReported: patientReportedFormattedRecords,
        activePanels: ehrRecords?.length || patientReportedFormattedRecords?.length ? [componentKeys.SURGICAL_HISTORY] : [],
        formError: !ehrRecords || !patientReportedRecords
          ? FormError.existingDataAPIFail
          : prev.formError,
      }));
    }
  }

  const broadcastUpdateEvent = () => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent(
      ADD_UPDATE_EVENTS.SURGICAL_HISTORY,
      contextData.componentView
    );
  };

  const fetchImportData = () => {
    if (isPatientNote && !isPreviewMode && contextData.patientId) {
      setImportFromChartLoading(true);
      getExistingData({patientId: contextData.patientId, skipLoader: true, manualImport: true});
    }
  };

  const onEventReceived = useCallback(
    (data) => {
      if (data != contextData.componentView && contextData?.patientId && !isPatientNote) {
        getExistingData({patientId: contextData.patientId, skipLoader: true});
      }
    },
    [contextData]
  );

  useEffect(() => {
    if (isRecentReported) {
      contextData?.updateLoadingStatus?.(componentKeys.SURGICAL_HISTORY, componentState.loading, !!componentState.patientReported.length);
      return;
    }
    const isDataAvailable = !!componentValue.surgicalHistory.length || !!componentState.patientReported.length;
    contextData?.updateLoadingStatus?.(componentKeys.SURGICAL_HISTORY, componentState.loading, isDataAvailable);
  }, [componentState.loading]);

  useEffect(() => {
    if (componentState.editModalVisible) {
      resetDimension();
    }
  }, [componentState.editModalVisible]);

  useEffect(() => {
    if (contextData.isBuilderMode) {
      setComponentValue(getSampleData());
      return;
    }
    const patientId = contextData?.patientId;
    if (patientId && !isPatientNote && (!props.component?.selectedValue || (isPatientForm && !props.disabled))) {
      getExistingData({patientId});
    }
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(
      ADD_UPDATE_EVENTS.SURGICAL_HISTORY,
      onEventReceived
    );
    return () => {
      eventBus.removeEventListener(onEventReceived);
    };
  }, []);

  useEffect(() => {
    let list = componentValue.surgicalHistory;
    if (!canUpdate()) {
      list = componentValue.surgicalHistory.filter((item) => {
        return !item.id;
      });
    }
    props.onChange(cloneDeep({surgicalHistory: list, total: componentValue.surgicalHistory.length}));
  }, [componentValue]);

  const {acceptRecord, ignoredRecords} =
              getAcceptedAndIgnoredRecordNames(componentState.patientReported);

  props.validateRef.current = validateData;

  const renderSubHeader = (isCompactView:boolean) => {
    return isCanvas ? (
      <HStack alignItems={"center"} maxWidth='100%' >
        <Box>
        <Feather name="info" color={Colors.Custom.Gray500} />
        </Box>
        <Text ml={1} color={Colors.Custom.Gray500} fontSize="xs">
         Data added under Perform section in canvas will show up here
        </Text>
      </HStack>
    ) : (
      <></>
    );
  };
  if (isPreviewMode) {
    const label = intl.formatMessage({id: 'performedDate'});
    return (
      <>
        {componentState.loading && (
          <View padding={4}>
            <FormComponentLoader />
          </View>
        )}
        {!componentState.loading && !componentState.formError && (
          <>
            {componentValue.surgicalHistory.length > 0 && (
              <div className={`${isAllowToShare ? 'page-break' : 'disallow-to-share'}`}>
              <DetailPreview titleLocalId={props.component.label}>
                <FlatList
                  data={componentValue.surgicalHistory}
                  keyExtractor={(item) => item.id || JSON.stringify(item)}
                  renderItem={({item}) => (
                    <VStack my={0.5}>
                      <Text fontWeight={600}>{item.name}</Text>
                      <HStack ml={2} alignItems='center'>
                        <Text>{item.status && `${intl.formatMessage({id: 'status'})}: ${item.status.display}`}</Text>
                        {item.performedDateTime && item.status && <Text> • </Text>}
                        {
                          item.performedDateTime &&
                          <Text>
                            {`${
                              item.performedDateTime &&
                              `${label}: ${getDateStrFromFormat(
                                item.performedDateTime,
                                DISPLAY_DATE_FORMAT
                              )}`
                            }`}
                          </Text>
                        }
                      </HStack>
                    </VStack>
                  )}
                />
              </DetailPreview>
              </div>
            )}
          </>
        )}
      </>
    );
  }
  return (
    <View ref={componentRef}>
      {isCompactView ? (
        <VStack background={Colors.Custom.BackgroundColor}>
          <Collapse
            activeKey={componentState.activePanels}
            onChange={(activePanels) => {
              setComponentState((prev) => ({...prev, activePanels: activePanels as string[]}));
            }}
            expandIconPosition="end"
            expandIcon={({ isActive }) => {
              const tooltipText = isActive ? intl.formatMessage({id: 'collapse'}) : intl.formatMessage({id:'expand'});
              return (
                <Tooltip title={tooltipText} placement={tooltipPlacement} showArrow={false} overlayStyle={tooltipOverlayStyle} overlayInnerStyle={tooltipInnerOverlayStyle}>
                  <RightOutlined rotate={isActive ? 90 : 0} />
                </Tooltip>
              )
            }}
            style={reactStyles.collapseStyle}>
            <Panel forceRender key={componentKeys.SURGICAL_HISTORY} header={
              <HStack
                alignItems={'center'}
                marginRight={4}
                justifyContent="space-between"
              >
                <HStack alignItems={'center'} space={2}>
                  <VStack>
                    <Text
                      size={'lgMedium'}
                      color={Colors.FoldPixel.GRAY400}
                      fontWeight={500}
                      fontSize={18}
                    >
                      {props.component.label}
                    </Text>
                    {renderSubHeader(isCompactView)}
                  </VStack>
                </HStack>
                <View>{getHeaderRightView(isCompactView)}</View>
              </HStack>
            }>
              {componentState.loading && (
                <View padding={4}>
                  <FormComponentLoader />
                </View>
              )}
              {!componentState.loading && componentState.customError && (
                 <FormComponentError error={FormError.custom} customError={componentState.customError} />
               )}
              {!componentState.loading && componentState.formError && (
                <FormComponentError error={componentState.formError} />
              )}
              {!componentState.loading && componentState.nonBlockingFormError && (
                <FormComponentError error={componentState.nonBlockingFormError} />
              )}
              {!componentState.loading &&
                !componentState.formError &&
                getDetailView(isCompactView, false)}
              <Divider />
            </Panel>
          </Collapse>
        </VStack>
      ) : (
        <>
        {isRecentReported ? (
          <RecentReportedViewHeader
          loading={componentState.loading}
          noData={!componentState.patientReported.length}
          title={props.component.label}
          buttonOnPress={() => {
            setComponentState((prev) => ({
              ...prev,
              showPatientReportedRecords: true,
            }));
          }}
          />
        ) :
        <DetailViewCollapse
          btnList={[
            {
              icon: importFromChartLoading ? (
                <Spinner />
              ) : (
                <Feather
                  name="download"
                  size={20}
                  color={Colors.Custom.mainPrimaryPurple}
                />
              ),
              onIconBtnClick: fetchImportData,
              label: intl.formatMessage({id: 'importFromChart'}),
              tooltipLabel: intl.formatMessage({id: 'importFromChartOverrideInfo'}),
              isCustomButton: true,
              hide: !(isPatientNote && !isPreviewMode),
              disabled: importFromChartLoading,
            }
          ]}
          textLocalId={props.component.label}
          toggled={!componentState.collapsed}
          subHeader={renderSubHeader(false)}
          headerRightView={getHeaderRightView(isCompactView)}
          headerStyle={{marginTop: 3}}
          extraTextStyles={
            isPatientNote ? {fontWeight: 'bold', fontSize: 16} : {}
          }
          extraPanelStyles={
            isPatientNote
              ? {borderColor: '#D0D5DD', ...CUSTOM_COMPONENT_STYLE}
              : {}
          }
          onToggled={() => {
            setComponentState((prev) => ({
              ...prev,
              collapsed: !prev.collapsed,
            }));
          }}
        >
          <VStack>
            {canAddNew() &&
              isMobileScreen &&
              componentValue.surgicalHistory.length > 0 && (
                <VStack marginY={4} space={2} flex={1} marginX={2}>
                  {getSearchComponent()}
                  {componentState.showSavingIndicator && (
                    <SectionLoadingIndicator />
                  )}
                </VStack>
              )}
            {componentState.loading && (
              <View padding={4}>
                <FormComponentLoader />
              </View>
            )}
            {!componentState.loading && componentState.customError && (
              <FormComponentError error={FormError.custom} customError={componentState.customError} />
            )}
            {!componentState.loading && componentState.formError && (
              <FormComponentError error={componentState.formError} />
            )}
            {!componentState.loading && componentState.nonBlockingFormError && (
              <FormComponentError error={componentState.nonBlockingFormError} />
            )}
            {!componentState.loading &&
              !componentState.formError &&
              getDetailView(isCompactView, false)}
          </VStack>
        </DetailViewCollapse>
        }

        </>
      )}
      <Drawer
        title={
          <>
          <ModalActionTitle
            isHeadNotSticky
            title={'Surgical History'}
            buttonList={[
              {
                onClick: () => {
                  setComponentState((prev) => ({
                    ...prev,
                    editModalVisible: false,
                  }));
                },
                show: true,
                id: 1,
                btnText: intl.formatMessage({
                  id: 'close',
                }),
                size: 'sm',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
              },
            ]}
          />
          {getSectionAdditionalView(false)}
          </>
        }
        destroyOnClose
        placement="right"
        onClose={() => {
          setComponentState((prev) => ({...prev, editModalVisible: false}));
        }}
        open={componentState.editModalVisible}
        closable
        width="50%"
      >
        <DetailViewCollapse
          btnList={[]}
          subHeader={renderSubHeader(false)}
          textLocalId={props.component.label}
          toggled={!componentState.editModalCollapse}
          headerRightView={getHeaderRightView(false)}
          onToggled={() => {
            setComponentState((prev) => ({
              ...prev,
              editModalCollapse: !prev.editModalCollapse,
            }));
          }}
        >
          <VStack ref={drawerDetailRef}>
            {canAddNew() &&
              isMobileScreen &&
              componentValue.surgicalHistory.length > 0 && (
                <VStack marginY={4} space={2} flex={1} marginX={2}>
                  {getSearchComponent()}
                  {componentState.showSavingIndicator && (
                    <SectionLoadingIndicator />
                  )}
                </VStack>
              )}
            {componentState.loading && <FormComponentLoader />}
            {!componentState.loading && componentState.customError && (
              <FormComponentError error={FormError.custom} customError={componentState.customError} />
            )}
            {!componentState.loading && componentState.formError && (
              <FormComponentError error={componentState.formError} />
            )}
            {!componentState.loading && componentState.nonBlockingFormError && (
              <FormComponentError error={componentState.nonBlockingFormError} />
            )}
            {!componentState.loading &&
              !componentState.formError &&
              getDetailView(false, false)}
          </VStack>
        </DetailViewCollapse>
      </Drawer>
      <Drawer
        title={
          <ModalActionTitle
            isHeadNotSticky
            title={'Patient Reported Surgical History'}
            buttonList={[
              {
                isLoading: componentState.showSavingIndicator,
                isDisabled: componentState.showSavingIndicator,
                onClick: () => {
                  setComponentState((prev) => ({
                    ...prev,
                    showPatientReportedRecords: false,
                    selectedVitalsForBulkActions:
                      componentState.patientReported.map(
                        (item) => item.id || ''
                      ),
                  }));
                },
                show: true,
                id: 1,
                btnText: intl.formatMessage({
                  id: componentState.showSavingIndicator ? 'Saving' : 'close',
                }),
                size: 'sm',
                textColor: componentState.showSavingIndicator
                  ? Colors.primary[800]
                  : Colors.danger[800],
                variant: componentState.showSavingIndicator
                  ? BUTTON_TYPE.TERTIARY
                  : BUTTON_TYPE.SECONDARY,
              },
              {
                isDisabled: componentState.showSavingIndicator || bulkAction.loading,
                isLoading: bulkAction.loading,
                onClick: () => bulkAction.handleConfirmation(true),
                show: organizationCapability?.canCreate && componentState.patientReported.filter(item => !item.isFreeTextRecord).length > 0,
                id: 2,
                btnText: intl.formatMessage({
                  id: 'acceptAll'
                }),
                size: 'sm',
                textColor: componentState.showSavingIndicator || bulkAction.loading
                  ? Colors.primary[800]
                  : Colors.danger[800],
                variant: BUTTON_TYPE.PRIMARY,
              },
            ]}
          />
        }
        destroyOnClose
        placement="right"
        onClose={() => {
          setComponentState((prev) => ({
            ...prev,
            showPatientReportedRecords: false,
          }));
        }}
        visible={componentState.showPatientReportedRecords}
        closable
        width="50%"
      >
        <VStack>
          {componentState.loading && <FormComponentLoader />}
          {!componentState.loading && componentState.nonBlockingFormError && (
            <FormComponentError error={componentState.nonBlockingFormError} />
          )}
          {!componentState.loading && componentState.customError && (
            <FormComponentError error={FormError.custom} customError={componentState.customError} />
          )}
          {!componentState.loading && componentState.formError && (
            <FormComponentError error={componentState.formError} />
          )}
          {!componentState.loading &&
            !componentState.formError &&
            getDetailView(false, true)}
        </VStack>
      </Drawer>
       {bulkAction.confirm && (
        <PatientIntakeConfirm
          acceptCount={acceptRecord.length}
          title='Accept Patient Reported Data'
          onOk={() => handleSubmitAllAction()}
          onCancel={() => bulkAction.handleConfirmation(false)}
          renderRecords={() => {
            return (
              <>
                {ignoredRecords.length > 0 && (
                  <View>
                    <Text mt={2.5}>
                      Following data reported by patient is unstructured and has
                      not been matched to a structured record. These will not be
                      updated in the EHR.
                    </Text>
                    {renderSectionBulletList(
                      `Unmatched records (${ignoredRecords.length} / ${componentState.patientReported.length})`,
                      ignoredRecords,
                      undefined,
                      {
                        backgroundColor: Colors.Custom.Red100,
                        borderColor: Colors.Custom.ErrorColor,
                      }
                    )}
                  </View>
                )}
                {acceptRecord.length > 0 && (
                  <View>
                    <Text mt={2.5}>Following records will be updated in the EHR.</Text>
                    {renderSectionBulletList(
                      `Matched records (${acceptRecord.length} / ${componentState.patientReported.length}) `,
                      acceptRecord
                    )}
                  </View>
                )}
              </>
            );
          }}
        />
      )}
    </View>
  );
};

export default AddOrUpdateSurgicalHistory;
