import {HStack, Text, Tooltip, View, VStack} from 'native-base';
import {ITeamMemberProfileProps} from '../..';
import {GROUP_MEMBER_TYPE} from '../../../../../../constants';
import {Colors} from '../../../../../../styles';
import {getUserRoles} from '../../../../../../utils/commonUtils';
import {capitalizeText} from '../../../../../common/ContactRelationView/ContactRelationUtils';
import {DisplayCardAvatar} from '../../../../../common/DisplayCard/DisplayCardAvatar';
import {TeamMemberEmail} from '../TeamMemberEmail';
import {styles} from './TeamMemberProfileStyles';
import { USER_ROLE_CODES } from '../../../../../../constants/MlovConst';
import { Pressable } from 'react-native';
import { Tooltip as AntTooltip } from 'antd';

const TeamMemberProfile = (props: ITeamMemberProfileProps) => {
  const {
    fullName,
    singleUserData,
    showActiveStatus,
    showInvitedStatus,
    profileUrl,
    phone,
    onProfileClick,
    externalUserName,
    isUserDeleted,
    userType
  } = props;
  // const capitalizedFullName = capitalizeText(fullName);

  let userRoles: string[] = [];
  if (singleUserData) {
    userRoles = getUserRoles(singleUserData || {});
  }

  const isActiveUser = singleUserData?.accountUsers?.[0]?.isActive;
  const isInvited = singleUserData?.isInvited;
  const isExternalUser = !!singleUserData?.externalUserIds?.length

  return (
    <HStack style={styles.profileStack} flex={1}>
      <View style={styles.avatarView} width={12}>
        <DisplayCardAvatar
          avatarStyle={{
            avatarSize: '12',
            width: 48,
            height: 48,
          }}
          userData={{
            userId: singleUserData?.id || '',
            userType: userType ?? GROUP_MEMBER_TYPE.USER,
            roles: userRoles,
            userName: fullName,
            imgSrc: profileUrl,
            isUserDeleted: isUserDeleted
          }}
          isLetterAvatarShow
        />
      </View>
      <VStack flex={8}>
        <View style={styles.fullnameView} flexDirection={"row"}>
          <AntTooltip  title={fullName} placement="topLeft">
            <Pressable 
              onPress={onProfileClick}
              style={{
                width: props?.width ? props?.width : '100%'
              }}
            >
              <Text size={'smMedium'} style={styles.fullNameTxt} noOfLines={1}>
                {fullName} 
              </Text>
            </Pressable>
          </AntTooltip>
          {isExternalUser &&  <Text style={styles.ehrText} noOfLines={1}>EHR </Text>}
          {externalUserName && <AntTooltip title={`Mapped with EHR User - ${externalUserName}`} placement="top">
            <Pressable 
              onPress={onProfileClick}
            >
              <Text style={styles.ehrText} noOfLines={1}>
                EHR 
              </Text>
            </Pressable>
          </AntTooltip>}
        </View>
        {phone && <View style={styles.fullnameView} color={Colors.Custom.Gray400}> <Text>{phone}</Text></View>}
        {userRoles.includes(USER_ROLE_CODES.EMPLOYER) &&
          singleUserData?.employerId?.length ? (
            <Text
              style={[
                styles.fullNameTxt,
                {fontSize: 10, color: Colors.Custom?.Gray600},
              ]}
              noOfLines={1}
            >
              {singleUserData.employerId}
            </Text>
          ) : <></>}
        <TeamMemberEmail email={singleUserData?.email || ''} onProfileClick={onProfileClick}/>

        {!isActiveUser && showActiveStatus && (
          <View style={styles.fullnameView}>
            <Text style={styles.isActiveText} noOfLines={1}>
              User is no longer active
            </Text>
          </View>
        )}
        {isInvited && showInvitedStatus && (
          <View
            mt={1}
            width={'fit-content'}
            borderRadius={16}
            px={3}
            bgColor={'#EFF6FF'}
          >
            <Text color={'green.700'}>Invited</Text>
          </View>
        )}
      </VStack>
    </HStack>
  );
};

export default TeamMemberProfile;
