import { CARE_PROGRAM_TYPE_CODES } from "../../../../../../../constants/MlovConst";

export const CONDITION_STATUS = {
  INCOMPLETE: 'incomplete',
  LOADING: 'loading',
  COMPLETED: 'completed'
} as const;

export enum SEND_CLAIM_CONDITION_CODES {
  ADD_BILLING_PRACTITIONER = 'ADD_BILLING_PRACTITIONER',
  ADD_EXTERNAL_BILLING_PRACTITIONER = 'ADD_EXTERNAL_BILLING_PRACTITIONER',
  SPECIFY_ICD_DIAGNOSIS = 'SPECIFY_ICD_DIAGNOSIS', 
  ADD_TOTAL_BILLABLE_TIME = 'ADD_TOTAL_BILLABLE_TIME',
  ADD_PRIMARY_INSURANCE_DETAILS = 'ADD_PRIMARY_INSURANCE_DETAILS',
  COMPLETE_MANDATORY_STEPS = 'COMPLETE_MANDATORY_STEPS',
  GIVE_CARE_PROGRAM_CONSENT = 'GIVE_CARE_PROGRAM_CONSENT'
}

export const SEND_CLAIM_CONDITION_CODE_MAP = {
  ADD_BILLING_PRACTITIONER: {
    code: SEND_CLAIM_CONDITION_CODES.ADD_BILLING_PRACTITIONER,
    description: 'addBillingPractitioner',
  },
  ADD_EXTERNAL_BILLING_PRACTITIONER: {
    code: SEND_CLAIM_CONDITION_CODES.ADD_EXTERNAL_BILLING_PRACTITIONER,
    description: 'addExternalBillingPractitioner',
  },
  SPECIFY_ICD_DIAGNOSIS: {
    code: SEND_CLAIM_CONDITION_CODES.SPECIFY_ICD_DIAGNOSIS,
    description: 'specifyIcdDiagnosis',
  },
  ADD_TOTAL_BILLABLE_TIME: {
    code: SEND_CLAIM_CONDITION_CODES.ADD_TOTAL_BILLABLE_TIME,
    description: 'addTotalBillableTime',
  },
  ADD_PRIMARY_INSURANCE_DETAILS: {
    code: SEND_CLAIM_CONDITION_CODES.ADD_PRIMARY_INSURANCE_DETAILS,
    description: 'addPrimaryInsuranceDetails',
  },
  COMPLETE_MANDATORY_STEPS: {
    code: SEND_CLAIM_CONDITION_CODES.COMPLETE_MANDATORY_STEPS,
    description: 'completeMandatorySteps',
  },
  GIVE_CARE_PROGRAM_CONSENT: {
    code: SEND_CLAIM_CONDITION_CODES.GIVE_CARE_PROGRAM_CONSENT,
    description: 'giveCareProgramConsent',
  }
}


const allConditionArray = [
  SEND_CLAIM_CONDITION_CODE_MAP.ADD_BILLING_PRACTITIONER,
  SEND_CLAIM_CONDITION_CODE_MAP.ADD_EXTERNAL_BILLING_PRACTITIONER,
  SEND_CLAIM_CONDITION_CODE_MAP.ADD_TOTAL_BILLABLE_TIME,
  SEND_CLAIM_CONDITION_CODE_MAP.COMPLETE_MANDATORY_STEPS,
  SEND_CLAIM_CONDITION_CODE_MAP.GIVE_CARE_PROGRAM_CONSENT
] as {code: string, description: string}[]

const allConditionExceptBillableTimeAndConsent = allConditionArray.filter(condition => condition.code !== SEND_CLAIM_CONDITION_CODE_MAP.ADD_TOTAL_BILLABLE_TIME.code && condition.code !== SEND_CLAIM_CONDITION_CODE_MAP.GIVE_CARE_PROGRAM_CONSENT.code)

export const SEND_CLAIM_CONDITION_MAP = {
  [CARE_PROGRAM_TYPE_CODES.CCM]: allConditionArray,
  [CARE_PROGRAM_TYPE_CODES.TCM]: allConditionExceptBillableTimeAndConsent,
  [CARE_PROGRAM_TYPE_CODES.AWV]: allConditionExceptBillableTimeAndConsent,
}
