import {useState} from 'react';
import {CARE_STUDIO_NEST_URL} from '../../constants/Configs';
import CareStudioService from '../../services/CommonService/CareStudioService';
import {SUBMIT_FORM_RESPONSE} from '../../services/Forms/FormsQueries';
import {print} from 'graphql';
import {PERFORM_ACTION_ON_CARE_PROGRAM} from '../../services/CareProgram/CareProgramQueries';
import {ICareProgramActionsPayload} from '../RightSideContainer/ContactCareProgram/interface';

enum Status {
  NONE = 'none',
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
}

const useCareProgramFormSubmitRestAPI = () => {
  const [status, setStatus] = useState<Status>(Status.NONE);
  // to acheive singleton pattern
  const [careStudioService] = useState(() =>
    CareStudioService.getCareStudioServiceInstance()
  );

  const submitForm = async (variables: ICareProgramActionsPayload): Promise<string> => {
    const body = {
      query: print(PERFORM_ACTION_ON_CARE_PROGRAM),
      variables: {
        params: variables,
      },
    };
    setStatus(Status.LOADING);
    try {
      const response = await careStudioService.careStudioGraphqlService.post(``, body);
      setStatus(Status.SUCCESS);
      return response.data?.data?.performCareProgramActions?.autoSaveResponse?.id;
    } catch (error) {
      setStatus(Status.ERROR);
      return '';
    }
  };

  return {
    submitForm,
    isLoading: status === Status.LOADING,
    isSuccess: status === Status.SUCCESS,
    isError: status === Status.ERROR,
  };
};

export default useCareProgramFormSubmitRestAPI;
