import { useLazyQuery } from '@apollo/client';
import { GET_CONTACT_CARE_TEAM_MEMBER_TYPE_BY_MEMBER_TYPE_ID } from '../../../../services/CareTeam/CareTeamQueries';
import { getPatientChronicConditions } from '../../../../services/CommonService/AidBoxService';
import { ICareProgramAdditionalData } from '../interface';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../constants/Configs';
import { GET_APPOINTMENT_BY_ID, GET_FUTURE_APPOINTMENT_DATA } from '../../../../services/Appointment/AppointmentQueries';
import {
  ADDITIONAL_DATA_CODE,
  CARE_PROGRAM_VIEW,
} from '../../CareManagementBilling/CareManagementConstants';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../../utils/mlovUtils';
import { useContext } from 'react';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { DISPLAY_SLASH_DATE_FORMAT, MLOV_CATEGORY } from '../../../../constants';
import {
  CARE_TEAM,
  CARE_TEAM_MEMBER_TYPE
} from '../../../../constants/MlovConst';
import React from 'react';
import {
  GET_CARE_PROGRAM_LAST_ACTIVITY_LOGS,
} from '../../../../services/ContactCareProgram/ContactCareProgram';
import { useCareProgramStatus } from '../../ContactCareProgram/ContactCareProgramView/hooks/useCareProgramStatus';
import { useCareProgramEditability } from '../../ContactCareProgram/ContactCareProgramView/hooks/useCareProgramEditability';
import {
  formatKeyPointData,
  getAwvTypeValue,
  getComplexityValue,
  getWhereConditionForUpcomingAppointmentData,
} from '../CareManagementUtils';
import { getAccountUUID, getUserUUID } from '../../../../utils/commonUtils';
import {
  getCompletedDayLapse,
  getDayLapse,
} from '../../../common/CareProgramPanel/CareProgramUtils';
import { getDateStrFromFormat } from '../../../../utils/DateUtils';
import { useCareProgramConsent } from '../../../../components/common/CareProgramConsent/CustomHooks/useCareProgramConsent';
import { CARE_PROGRAM_CONSENT_SUBJECT_TYPE_CODE } from '../../Contacts/Leads/LeadView/LeadTableView/Helper/CareProgramConst';
import { useContactCareProgramContext } from '../../ContactCareProgram/ContactCareProgramView/ContactCareProgram.context';
import { ADDITIONAL_ATTRIBUTE_KEY, CARE_PROGRAM_STEP_TYPE } from '../../../common/MemebersView/constant';
export interface IKeyPointData {
  display: string;
  code: string;
  referenceData: any;
  view?: CARE_PROGRAM_VIEW;
}

export const useCareProgramAdditionalData = () => {
  const commonDataContext = useContext(CommonDataContext);
  const tenantId = getAccountUUID();
  const loggedInUserId = getUserUUID();
  const {careProgramStatus, careProgramStatusList} = useCareProgramStatus();
  const {getCareProgramConsentData} = useCareProgramConsent();
  const {isAllowedToTakeActions} = useCareProgramEditability();
  const {state} = useContactCareProgramContext();
  const {contactCareProgramDetails} = state;
  const appointmentStatusList =
    getMlovListFromCategory(
      commonDataContext.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.APPOINTMENT_STATUS
    ) || [];

  const careTeamList =
    getMlovListFromCategory(
      commonDataContext.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.PRIMARY_CARE_TEAM
    ) || [];
  const careTeamTypeId = getMlovIdFromCode(careTeamList, CARE_TEAM.CODE);
  const careTeamMemberTypesList =
    getMlovListFromCategory(
      commonDataContext.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CARE_TEAM_MEMBER_TYPE
    ) || [];
  const careManagerTypeId = getMlovIdFromCode(
    careTeamMemberTypesList,
    CARE_TEAM_MEMBER_TYPE.CARE_MANAGER
  );
  const pcpUserTypeId = getMlovIdFromCode(
    careTeamMemberTypesList,
    CARE_TEAM_MEMBER_TYPE.PCP
  );

  const [loading, setLoading] = React.useState(false);



  const [getContactCareTeamMemberTypeByMemberTypeId] = useLazyQuery(
    GET_CONTACT_CARE_TEAM_MEMBER_TYPE_BY_MEMBER_TYPE_ID,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const [getCareProgramLastActivityLogs] = useLazyQuery(
    GET_CARE_PROGRAM_LAST_ACTIVITY_LOGS,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const [getFutureAppointmentData] = useLazyQuery(GET_FUTURE_APPOINTMENT_DATA, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  const [getAppointmentById] = useLazyQuery(GET_APPOINTMENT_BY_ID, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  const getAdditionalAttributes = async (additionalAttributeKey: string) => {
    const additionalAttributeData =
      contactCareProgramDetails?.additionalAttributes?.find(
        (attribute: any) => attribute.attributeKey === additionalAttributeKey
      );
    return additionalAttributeData;
  };

  const getAWVAppointmentData = async (additionalAttributeKey: string) => {
    const appointmentStepData =
      contactCareProgramDetails?.contactCareProgramSteps?.find(
        (steps: any) => [CARE_PROGRAM_STEP_TYPE.ANNUAL_VISIT, CARE_PROGRAM_STEP_TYPE.APPOINTMENT, CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_14DAY].includes(steps?.careProgramStepType?.code)
      );
    
    const additionalAttributeData = appointmentStepData?.careProgramStepAdditionalAttributes?.find(  
      (attribute: any) => attribute.attributeKey === additionalAttributeKey
    );
    if (additionalAttributeData?.attributeValue?.valueString) {
      const appointmentData = await getAppointmentById({
        variables: {appointmentId: additionalAttributeData?.attributeValue?.valueString}
      });
      return appointmentData?.data;
    }
    return null;
  };

  const getPatientAdditionalData = async (
    params: ICareProgramAdditionalData
  ) => {
    try {
      const {
        patientId,
        location,
        contactUuid,
        additionalDataCodes,
        appointmentStartDate,
        contactCareProgramId,
        userMap,
      } = params;
      setLoading(true);
      const results: Partial<Record<ADDITIONAL_DATA_CODE, any>> = {};
      const promiseList: Promise<any>[] = [];
      let pcpAndAppointmentPromise: Promise<void> | null = null;
      let pcpUserId: string | undefined;
      let appointmentData: any;

      for (const dataCode of additionalDataCodes) {
        switch (dataCode) {
          case ADDITIONAL_DATA_CODE.CHRONIC_CONDITIONS:
            if (patientId && location) {
              const chronicConditionsPromise = getPatientChronicConditions(
                [{patientId, location}],
                true
              ).then((response) => {
                results[ADDITIONAL_DATA_CODE.CHRONIC_CONDITIONS] =
                  response?.data;
              });
              promiseList.push(chronicConditionsPromise);
            }
            break;
          case ADDITIONAL_DATA_CODE.PATIENT_CONSENT:
            if (contactCareProgramId) {
              const consentPromise = getCareProgramConsentData({
                subjectId: contactCareProgramId,
                subjectTypeCode:
                  CARE_PROGRAM_CONSENT_SUBJECT_TYPE_CODE.CONTACT_CARE_PROGRAM,
              }).then((response) => {
                results[ADDITIONAL_DATA_CODE.PATIENT_CONSENT] = response;
              });
              promiseList.push(consentPromise);
            }
            break;

          case ADDITIONAL_DATA_CODE.LAST_UPDATED:
          case ADDITIONAL_DATA_CODE.CLOSED:
          case ADDITIONAL_DATA_CODE.AUTO_CLOSED:
          case ADDITIONAL_DATA_CODE.COMPLETED:
            const lastUpdatedPromise = getCareProgramLastActivityLogs({
              variables: {
                contactCareProgramId: contactCareProgramId,
              },
            }).then((response) => {
              results[ADDITIONAL_DATA_CODE.LAST_UPDATED] =
                response?.data?.getActivityLogs?.activityLogs?.[0];
            });
            promiseList.push(lastUpdatedPromise);
            break;
          case ADDITIONAL_DATA_CODE.COMPLEXITY:
            results[ADDITIONAL_DATA_CODE.COMPLEXITY] = getComplexityValue(
              contactCareProgramDetails?.complexity || '',
              commonDataContext.CARE_STUDIO_MLOV
            );
            break;
          case ADDITIONAL_DATA_CODE.PCP_AND_CARE_MANAGER:
          case ADDITIONAL_DATA_CODE.FUTURE_APPOINTMENT:
            pcpAndAppointmentPromise = (async () => {
              const careTeamData =
                await getContactCareTeamMemberTypeByMemberTypeId({
                  variables: {
                    contactId: contactUuid,
                    careTeamTypeId: careTeamTypeId,
                    careTeamMemberTypeId: [pcpUserTypeId, careManagerTypeId],
                  },
                });

              results[ADDITIONAL_DATA_CODE.PCP_AND_CARE_MANAGER] =
                careTeamData?.data;

              const pcpUserData =
                careTeamData?.data?.careTeams?.[0]?.careTeamMemberType?.find(
                  (careTeamMemberType: any) =>
                    careTeamMemberType?.memberType?.code ===
                    CARE_TEAM_MEMBER_TYPE.PCP
                );

              pcpUserId = pcpUserData?.userId;

              if (pcpUserId) {
                const whereCondition =
                  getWhereConditionForUpcomingAppointmentData(
                    pcpUserId,
                    contactUuid,
                    appointmentStatusList,
                    appointmentStartDate
                  );
                const futureAppointmentData = await getFutureAppointmentData({
                  variables: {whereCondition: whereCondition},
                });
                appointmentData = futureAppointmentData?.data;
                results[ADDITIONAL_DATA_CODE.FUTURE_APPOINTMENT] =
                  appointmentData;
              }
            })();
            promiseList.push(pcpAndAppointmentPromise);
            break;

          case ADDITIONAL_DATA_CODE.AWV_TYPE:
            const awvType = await getAdditionalAttributes(
              ADDITIONAL_ATTRIBUTE_KEY.AWV_TYPE
            );
            if (awvType?.attributeValue?.valueString) {
              results[ADDITIONAL_DATA_CODE.AWV_TYPE] = getAwvTypeValue(
                awvType?.attributeValue?.valueString,
                commonDataContext.CARE_STUDIO_MLOV
              );
            }
            break;
          case ADDITIONAL_DATA_CODE.DISCHARGE_DATE:
          case ADDITIONAL_DATA_CODE.DAY_LAPSED:
          case ADDITIONAL_DATA_CODE.CLOSED_DATE:
            const dischargeDateValue = await getAdditionalAttributes(
              ADDITIONAL_ATTRIBUTE_KEY.DISCHARGE_DATE
            );
            if (dischargeDateValue?.attributeValue?.valueString) {
              const dischargeDate = getDateStrFromFormat(
                dischargeDateValue.attributeValue?.valueString,
                DISPLAY_SLASH_DATE_FORMAT
              );
              results[ADDITIONAL_DATA_CODE.DISCHARGE_DATE] = dischargeDate;
              const endDateTime = contactCareProgramDetails?.endDateTime;
              if (
                endDateTime &&
                contactCareProgramDetails?.statusId &&
                [
                  careProgramStatus.completed,
                  careProgramStatus.autoClosed,
                  careProgramStatus.closed,
                ].includes(contactCareProgramDetails?.statusId) &&
                additionalDataCodes.includes(ADDITIONAL_DATA_CODE.CLOSED_DATE)
              ) {
                const endDateTime = contactCareProgramDetails?.endDateTime;
                results[ADDITIONAL_DATA_CODE.CLOSED_DATE] =
                  getCompletedDayLapse(
                    endDateTime,
                    dischargeDate,
                    careProgramStatus,
                    contactCareProgramDetails?.statusId
                  );
              } else if (
                additionalDataCodes.includes(ADDITIONAL_DATA_CODE.DAY_LAPSED)
              ) {
                results[ADDITIONAL_DATA_CODE.DAY_LAPSED] = getDayLapse(
                  dischargeDateValue.attributeValue?.valueString || ''
                );
              }
              
            }
            break;

          case ADDITIONAL_DATA_CODE.HOSPITAL_NAME:
            const hospitalName = await getAdditionalAttributes(
              ADDITIONAL_ATTRIBUTE_KEY.FACILITY
            );
            if (hospitalName?.attributeValue?.valueString) {
              results[ADDITIONAL_DATA_CODE.HOSPITAL_NAME] =
                hospitalName?.attributeValue?.valueString;
            }
            break;
          
          case ADDITIONAL_DATA_CODE.AWV_APPOINTMENT_DATE:
            const awvAppointmentData = await getAWVAppointmentData(ADDITIONAL_ATTRIBUTE_KEY.APPOINTMENT_ID);
            if (awvAppointmentData) {
              results[ADDITIONAL_DATA_CODE.AWV_APPOINTMENT_DATE] = awvAppointmentData;
            }
            break;
        }
      }

      if (promiseList.length > 0) {
        await Promise.allSettled(promiseList);
      }
      setLoading(false);

      const keyPoints: IKeyPointData[] = [];
      additionalDataCodes.forEach((code) => {
        const keyPoint = formatKeyPointData({
          dataCode: code,
          data: results[code],
          careProgramStatusList,
          userMap,
          loggedInUserId,
          isAllowedToTakeActions,
        });
        if (keyPoint) {
          keyPoints.push(keyPoint);
        }
      });
      return keyPoints;
    } catch (error) {
      return {};
    }
  };

  return {
    loading,
    getPatientAdditionalData,
  };
};
