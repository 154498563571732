import {Popover} from 'antd';
import {HStack, Pressable, Text, View} from 'native-base';
import {useEffect, useRef, useState} from 'react';
import {ILabelDataView} from '../../../../common/FilterView/interfaces';
import {useContainerDimensions} from '../../../../CustomHooks/ContainerDimensionHook';

const TagsColumnView = (props: any) => {
  const {labelData, bgColor, color} = props;
  const [stateData, setStateData] = useState({
    moreActionPopup: false,
    loading: false,
  });
  const componentRef = useRef();
  const {width, resetDimension} = useContainerDimensions(componentRef);
  const [maxTagsToShow, setLabelToShow] = useState<string[]>([]);
  const getMaxWidth = (labels: string[]) => {
    const maxTagsToShow: string[] = [];
    let currentWidth = 0;
    const maxWidth = width / 10;
    if (labels?.length === 1) {
      setLabelToShow(labels);
      return;
    } else {
      for (const label of labels) {
        if (currentWidth + label.length <= maxWidth) {
          currentWidth = currentWidth + label.length;
          maxTagsToShow.push(label);
        } else {
          if (maxTagsToShow?.length === 0) {
            maxTagsToShow.push(label);
          }
          break;
        }
      }
      setLabelToShow(maxTagsToShow);
    }
  };
  const handleMoreActionVisibleChange = (visible: any) => {
    setStateData((prev) => {
      return {
        ...prev,
        moreActionPopup: visible,
      };
    });
  };
  const renderSingleTag = (name: string) => {
    return (
      <View mt={1} borderRadius={16} px={3} bgColor={bgColor ? bgColor :'#EFF6FF'} mr={1}>
        <Text size={'smRegular'} color={ color ? color : 'blue.700'}>{name}</Text>
      </View>
    );
  };
  useEffect(() => {
    getMaxWidth(labelData)

  }, [])

  return (
    <HStack
      ref={componentRef}
      alignItems={'center'}
      maxWidth={props.hideAddMoreButton ? width : '100%'}
      flexWrap={'wrap'}
      overflow={'auto'}
    >
      <View
        maxWidth={'100%'}
        style={{flexDirection: 'row', alignItems: 'center'}}
      >
        {maxTagsToShow && maxTagsToShow.length ? (
          maxTagsToShow.map((tagItem: string, index: number) => {
            return tagItem ? (
              <View key={`${tagItem}_${index}`}>
                {renderSingleTag(tagItem)}
              </View>
            ) : (
              <></>
            );
          })
        ) : (
          <Text>- -</Text>
        )}
      </View>
      {labelData?.length - maxTagsToShow?.length > 0 && (
        <Popover
          overlayInnerStyle={{padding: 0, borderRadius: 12}}
          overlayClassName="patient-search-select-styles"
          className="patient-search-select-styles"
          overlayStyle={{padding: 0}}
          overlay={{padding: 0}}
          style={{padding: 0, borderRadius: 12}}
          placement={props?.popOverConfig?.placement || 'bottomLeft'}
          trigger={props?.popOverConfig?.trigger || 'click'}
          visible={stateData.moreActionPopup}
          onVisibleChange={handleMoreActionVisibleChange}
          content={
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: 330,
              }}
            >
              <View
                style={{flexDirection: 'row', alignItems: 'center'}}
                flexWrap={'wrap'}
                overflow={'auto'}
                maxWidth={'90%'}
              >
                {labelData &&
                  labelData.length &&
                  labelData.slice(maxTagsToShow.length).map((tagItem: string, index: number) => {
                    return tagItem ? (
                      <View key={`${tagItem}_${index}`}>
                        {renderSingleTag(tagItem)}
                      </View>
                    ) : (
                      <></>
                    );
                  })}
              </View>
            </View>
          }
        >
          <Pressable
            onPress={() => {
              setStateData((prev) => {
                return {
                  ...prev,
                  moreActionPopup: true,
                };
              });
            }}
          >
            <Text
              size={'smRegular'}
              fontWeight={500}
              marginTop={1}
              marginLeft={1}
              color={ color ? color : '#825AC7'}
            >
              {`+${labelData?.length - maxTagsToShow?.length} more`}
            </Text>
          </Pressable>
        </Popover>
      )}
    </HStack>
  );
};

export default TagsColumnView;
