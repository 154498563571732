import { StyleProp, StyleSheet, ViewStyle } from 'react-native';
import { Colors } from '../../../styles/Colors';

export const styles = StyleSheet.create({
  width70p: {
    width: '70%'
  },
  width30pHeight400: {
    width: '30%',
    height: 400
  },
  paddingLeftTopBottom2: {
    paddingLeft: 2,
    paddingTop: 2,
    paddingBottom: 2
  },
  marginLeft12: {
    marginLeft: 12
  },
  paddingLeftTopBottom2JustifyCenter: {
    paddingLeft: 2,
    paddingTop: 2,
    paddingBottom: 2,
    justifyContent: 'center'
  },
  flexWrapAlignItemsCenter: {
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  box36: {
    width: 36,
    height: 36
  },
  buttonStyles: {
    paddingHorizontal: 14,
    paddingVertical: 8,
    borderRadius: 50,
    height: 36,
    marginLeft: 16,
  },
  newOutputModelHStack: {
    borderColor: '#E4E7EC',
    borderWidth: 1,
    borderRadius: 8,
    backgroundColor: Colors.FoldPixel.GRAY50
  },
  marginRight25: {
    marginRight: 25
  },
  marginTop4: {
    marginTop: 4
  },
  marginTop16: {
    marginTop: 16
  },
  rowAlignItemsCenter: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  buttonStyle: {
    paddingHorizontal: 14,
    paddingVertical: 8,
    borderRadius: 50,
    height: 36,
    marginLeft: 16,
  },
  justifyContentSpaceBetweenAlignItemsCenterMv10: {
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 10
  },
  pressableStyle: {
    minWidth: 123,
    maxWidth: 'fit-content'
  },
  padding6: {
    padding: 6
  },
  padding12: {
    padding: 12
  },
  viewStyle1: {
    borderColor: Colors.FoldPixel.PRIMARY300,
    borderWidth: 1,
    backgroundColor: Colors.FoldPixel.PRIMARY100,
    width: '100%',
    padding: 16,
  },
  height36borderRadius4: {
    height: 36,
    borderRadius: 4
  },
  colorError: {
    color: Colors.Custom.ErrorColor
  },
  view: {
    // borderColor: Colors.FoldPixel.GRAY200,
    borderRadius: 8,
    borderWidth: 1,
    backgroundColor: '#FDE8EF',
  },
  view1: {
    borderColor: Colors.FoldPixel.GRAY200,
    borderWidth: 1,
    borderRadius: 8,
    backgroundColor: '#F6F7F8',
  },
  marginRight10: {
    marginRight: 10
  },
  mapStateView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 5,
  },
  fontSize14: {
    fontSize: 14
  },
  view2: {
    borderColor: Colors.Custom.PrimaryColor200,
    borderWidth: 1,
    // backgroundColor: Colors.Custom.Primary50,
    width: '100%',
    height: '42px',
    backgroundImage: 'linear-gradient(237.73deg, #D2EBEF 0.27%, #F5E4FE 102.44%)',
  },
  marginLeft5: {
    marginLeft: 5
  },
  view4: {
    borderBottomColor: Colors.Custom.Gray100,
    borderBottomWidth: 2,
  },
  bgWhitePadding10: {
    backgroundColor: '#ffffff',
    padding: 10
  },
  scrollViewStyle: {
    maxHeight: window.innerHeight * 0.6
  },
  scrollView: {
    maxHeight: window.innerHeight * 0.2
  },
  text1: {
    fontWeight: 'bold',
    fontSize: 14
  },
  bottomView: {
    height: '32px',
    justifyContent: 'center',
    paddingHorizontal: '8px',
    borderRadius: 4,
  },
  sideMenuView: {
    borderWidth: 0.5,
    height: 16,
    borderColor: Colors.FoldPixel.GRAY200,
  },
  addedByFoldViewStyle: {
    backgroundColor: Colors.secondary[100],
    paddingHorizontal: 10,
    borderRadius: 5,
    marginBottom: 15,
    justifyContent: 'space-between',
  },
  justifyContentFlexStartAlignItemsCenterDirectionRow: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
  },
  alignItemsCenter: {
    alignItems: 'center'
  },
  fullWidth: {
    width: '100%'
  },
  colorTransparent: {
    color: 'transparent'
  },
  borderRadius8: {
    borderRadius: 8
  },
  text: {
    lineHeight: 14.4,
    color: Colors.FoldPixel.GRAY400
  },
  addedByFoldStyle: {
    backgroundColor: Colors.secondary[100],
    paddingHorizontal: 10,
    borderRadius: 5,
    marginBottom: 15,
    justifyContent: 'space-between',
  },
  marginMinus25: {
    margin: -25
  },
  marginTop18: {
    marginTop: 18
  },
  height40: {
    height: 40
  },
  fullWidthCentered: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fontSize12ColorWhite: {
    fontSize: 12,
    color: 'white'
  },
  centered: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  zIndex1000colorWhite: {
    zIndex: 1000,
    color: 'white'
  },
  cardView: {
    paddingVertical: 20,
    paddingHorizontal: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  bgWhite: {
    backgroundColor: '#ffffff'
  },
  colorPrimary400fontSize20: {
    color: Colors.primary[400],
    fontSize: 20
  },
  justifyContentSpaceBetweenPadding20: {
    justifyContent: 'space-between',
    padding: 20
  },
  zIndex1000: {
    zIndex: 1000
  },
  addCustomBtnIcon: {
    color: Colors.Custom.mainPrimaryPurple,
    fontWeight: '400'
  },
  buttonGroupStyle: {
    backgroundColor: Colors.Custom.BackgroundColor,
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  marginBottom12: {
    marginBottom: 12
  },
  marginVertical4: {
    marginVertical: 4
  },
  marginVertical12: {
    marginVertical: 12
  },
  text2: {
    color: 'black',
    fontSize: 18,
    fontFamily: 'Manrope',
    fontWeight: '600',
    lineHeight: 27,
    marginBottom: '24px',
  },
  selectStyle: {
    flex: 1, 
    padding: 10, 
    width: '350px'
  },
  bgTransparentCentered: {
    // backgroundColor: Colors.FoldPixel.TRANSPARENT,
    justifyContent: 'center',
    alignItems: 'center'
  },
  bgGray150: {
    backgroundColor: Colors.FoldPixel.GRAY150
  },
  view5: {
    // backgroundColor: Colors.FoldPixel.TRANSPARENT,
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 60
  },
  buttonStyle1: {
    borderStyle: 'solid',
    borderColor: Colors.FoldPixel.GRAY300,
    borderWidth: 0.5,
    backgroundColor: Colors.Custom.MonochromeWhite,
    height: '32px',
    justifyContent: 'center',
    borderRadius: 4,
    alignItems: 'center',
    padding: '8px',
    width: '136px',
  },
  textStyle2: {
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 16.8,
    color: Colors.FoldPixel.GRAY200,
    textAlign: 'center',
  },
  buttonStyle2: {
    height: '32px',
    justifyContent: 'center',
    borderRadius: 4,
    alignItems: 'center',
    padding: '8px',
    width: '136px',
    borderWidth: 0.5,
    borderColor: Colors.FoldPixel.GRAY100
  },
  textStyle1: {
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 19.2,
    textAlign: 'center',
  },
  view6: {
    height: '32px',
    justifyContent: 'center',
    borderRadius: 4,
    minWidth: 60,
    alignItems: 'center'
  },
  viewDataPressable: {
    flexDirection: 'row',
    borderRadius: 8,
    padding: 2,
    alignItems: 'center',
    maxWidth:'fit-content'
  },
  viewDataPressableHover: {
    backgroundColor: '#F9F5FF',
  },
  divider: {
    width: 0.5,
    height: 16,
    marginLeft: 6,
    marginRight: 6,
    backgroundColor: '#D7C0FF'
},
});

export const reactStyles: Record<string, React.CSSProperties> = {
  marginBottom12: {
    marginBottom: 12
  },
  heightAuto: {
    height: 'auto'
  },
  marginRight3: {
    marginRight: 3
  },
  bgGray150: {
    backgroundColor: Colors.FoldPixel.GRAY150
  },
  maxWidth520: {
    maxWidth: '520px'
  },
  height40: {
    height: '40px'
  },
  height36fontSize13borderRadius4: {
    height: 36,
    fontSize: 13,
    borderRadius: 4
  },
  fullWidthMarginTop15: { 
    width:'full',
    marginTop: 15 
  },
  width156Height32: {
    width: '156px',
    height: '32px'
  },
  full: {
    width: '100%',
    height: '100%'
  },
  height40fontSize13: {
    height: 40,
    fontSize: 13
  },
  handleStyle: {
    width: '100%',
    height: '20px',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    borderRadius: '3px',
  },
  handleStyle2: {
    position: 'absolute',
    top: '-15px',
    width: '40px',
    height: '20px',
    borderWidth: 0,
    backgroundColor: 'transparent',
    borderRadius: '3px',
  },
  handleStyle3: {
    width: 20,
    height: 20,
    position: 'absolute',
    top: '-10px',
    borderWidth: 0,
    backgroundColor: 'transparent',
    borderRadius: '3px',
  },
  handleStyle4: {
    position: 'absolute',
    top: '50%',
    width: '25px',
    height: '25px',
    borderWidth: 0,
    backgroundColor: 'transparent',
    borderRadius: 0,
  },
  handleStyle5: {
    top: '-15px',
    width: '25px',
    height: '25px',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
  },
  inputNumberStyle: {
    width: '100%',
    height: 36,
    fontSize: 13,
    borderRadius: '10px',
    alignItems: 'center',
    padding: 2
  },
  height36font13: {
    height: 36,
    fontSize: 13
  },
  positionAbsolute: {
    position: 'absolute'
  },
  colorGray300: {
    color: Colors.FoldPixel.GRAY300
  },
  colorGray200: {
    color: Colors.FoldPixel.GRAY200
  },
  height36borderRadius10: {
    height: 36,
    borderRadius: '10px'
  },
  textOverflowEllipsis: {
    textOverflow: 'ellipsis'
  },
  borderRadius10: {
    borderRadius: '10px'
  },
  fullWidthHeight36: {
    width: '100%',
    height: 36
  },
  antSelectStyle: {
    backgroundColor: 'white',
    borderWidth: 0.5,
    height: '36px',
    fontSize: '14px'
  },
  colorErrorMarginTop10: {
    color: Colors.Custom.ErrorColor,
    marginTop: 10
  },
  fullWidth: {
    width: '100%'
  },
  darkText: {
    color: '#888'
  },
  inputNumberStyle2: {
    width: '100%',
    height: 36,
    fontSize: 13
  },
  checkboxStyle: {
    marginTop: 0.5,
    marginRight: 2,
  },
  view4: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 10px'
  },
  inputNumberStyle3: {
    height: 36,
    fontSize: 13,
    borderRadius: '10px'
  },
  switchStyle: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'left'
  },
  maxWidth400px: {
    maxWidth: '400px'
  },
  inputStyle: {
    borderRadius: 8,
    height: 40,
    borderColor: Colors.FoldPixel.GRAY200
  },
  marginLeft3em: {
    marginLeft: '3em'
  },
  timelineItemStyle: {
    color: '#98A2B3',
    fontSize: 14,
    fontFamily: 'Manrope',
    fontWeight: '600',
    textTransform: 'uppercase',
    letterSpacing: 1.4,
    wordWrap: 'break-word',
    marginTop: '16px',
    marginBottom: '16px',
  },
  colorTransparent: {
    color: 'transparent'
  },
  text1: {
    color: '#98A2B3',
    fontSize: 12,
    fontFamily: 'Manrope',
    fontWeight: '600',
    textTransform: 'uppercase',
    letterSpacing: 1.2,
    wordWrap: 'break-word',
  },
  fontSize16ColorGray300: {
    fontSize: 16, 
    color: Colors.Custom.Gray300
  },
  caretRightOutlined: {
    color: '#8C5AE2',
    fontSize: 10,
  },
};
