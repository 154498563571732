import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  tabStyles: {
    paddingHorizontal: 12,
    paddingVertical: 15,
    justifyContent: 'center',
    marginRight: 8,
    alignItems: 'center',
    fontSize: 14,
  },
  tabsContainer: {
    marginBottom: 12,
  },
  backButtonStyle: {
    height: 40,
    width: 40,
    borderRadius: 30,
    marginLeft: -11,
  },
});
