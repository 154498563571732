import {useContext} from 'react';
import {CommonDataContext} from '../../../../../../../context/CommonDataContext';
import {isContactConsentRequired} from '../../../../../../../utils/commonUtils';
import CareProgramStepOutreachUtils from '../../../../../../common/CareProgramStepOutreach/CareProgramStepOutreachUtils';
import {usePersonOmniViewContext} from '../../../../../../PersonOmniView/PersonOmniView.context';
import {getMlovListFromCategory} from '../../../../../../../utils/mlovUtils';
import {MLOV_CATEGORY} from '../../../../../../../constants';
import {StepOutreachLogModeOfContact} from '../../../../../../../services/ContactCareProgram/interface';
import useOutreachStepOutComes from '../../../../../../common/CareProgramStepOutreach/useOutreachStepOutComes';

export const useOutreach = () => {
  const isConsentRequired = isContactConsentRequired();
  const mlovData = useContext(CommonDataContext);
  const {categoryWiseList: outreachStepOutComesCategoryWiseList} = useOutreachStepOutComes();
  const {data: contact} = usePersonOmniViewContext();
  const consentCheck = CareProgramStepOutreachUtils.isConsentRequiredAndGiven(
    contact,
    isConsentRequired
  );
  const availableContactModesForContact =
    CareProgramStepOutreachUtils.getEnabledContactModes(contact, consentCheck);

  const allContactModes =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CONTACT_OUTREACH_COMMUNICATION_MODE
    ) || [];  


  const enabledContactModes = allContactModes.filter((mode) =>
    availableContactModesForContact.includes(
      mode.code as StepOutreachLogModeOfContact
    )
  );

  return {
      enabledContactModes,
      outreachStepOutComesCategoryWiseList,
    };
};
