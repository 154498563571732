import {StyleSheet} from 'react-native';
import {Colors} from '../../../styles/Colors';

export const styles = StyleSheet.create({
  cardTitleContainer: {
    flex: 1,
    paddingRight: 16,
  },
  cardTitle: {
    fontWeight: '400',
    color: Colors.FoldPixel.GRAY400,
    paddingRight: 4,
  },
  cardSubtitle: {
    color: Colors.FoldPixel.GRAY300,
  },
  mr8: {
    marginRight: '8px'
  },
  button: {
    backgroundColor: Colors.Custom.IconColorWhite,
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderRadius: 4,
    borderColor: Colors.Custom.Gray300,
    borderWidth: 1,
    marginRight: 8,
    height: 32,
  },
  buttonHover: {
    backgroundColor: Colors.Custom.Gray100,
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderRadius: 4,
    borderColor: Colors.Custom.Gray300,
    borderWidth: 1,
    marginRight: 8,
    height: 32,
  },
  buttonText: {
    fontSize: 14,
    fontWeight: '500',
    color: Colors.FoldPixel.GRAY300,
  },
  card: {
    backgroundColor: Colors.Custom.IconColorWhite,
    borderRadius: 8,
    padding: 12,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 6,
  },
  menuHorizontalIcon: {
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 8,
  },
  outerContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
  },
  container: {
    flex: 0.6,
  },
  topBarContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  tab: {
    paddingHorizontal: 8,
    margin: 8,
    paddingVertical: 4,
  },
  selectedTab: {
    backgroundColor: Colors.Custom.FontColorWhite,
    borderRadius: 4,
  },
  tabText: {
    color: Colors.FoldPixel.GRAY300,
    fontSize: 14,
  },
  selectedTabText: {
    color: Colors.Custom.Gray700,
    fontWeight: '400',
  },
  spacer: {
    flex: 1,
  },
  addButton: {
    flexDirection: 'row',
    backgroundColor: Colors.Custom.IconColorWhite,
    paddingVertical: 4,
    paddingHorizontal: 8,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    borderColor: Colors.Custom.Gray300,
    borderWidth: 1,
  },
  addButtonText: {
    color: Colors.Custom.Gray500,
    paddingLeft: 4,
    fontSize: 14,
    fontWeight: '500',
  },
  popoverItem: {
    padding: 8,
    fontWeight: '400',
    color: Colors.FoldPixel.GRAY400,
  },
  filterButton: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  columnFlex: {
    flex: 1,
    flexDirection: 'column',
  },
  rowFlex: {
    flex: 1,
    flexDirection: 'row',
  },
  cardDescriptionContainer: {
    flex: 1,
    paddingRight: 16,
  },
  centerAlign: {
    alignItems: 'center',
  },
});

export const antStyles: Record<string, React.CSSProperties> = {
  dividerStyle: {
    backgroundColor: Colors.Custom.GRAY150,
    alignSelf: 'center',
    alignItems: 'center',
    height: 16,
    marginRight: 8,
  },
  skeletonStyle: {
    height: 10,
    borderRadius: 4,
    marginTop: 6,
    marginLeft: 16,
  },
  popoverOverlayInnerStyle: {
    overflow: 'hidden',
    borderRadius: 8,
    padding: 8,
  },
  horizontalDivider: {
    marginTop: 4,
    marginBottom: 4,
    color: Colors.Custom.GRAY150,
  },
  horizontalCardDivider: {
    marginTop: 8,
    marginBottom: 8,
    color: Colors.Custom.GRAY150,
  },
  datePickerStyle: {
    height: 44,
    width: 125,
    borderColor: Colors.Custom.Gray300,
    borderRadius: 8,
    fontWeight: 400,
    fontSize: 16,
  },
  cardCheckbox: {
    paddingRight: 8,
    borderRadius: 4,
  },
  progressContainer: {
    marginRight: 4,
  }
};
