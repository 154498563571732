import React from 'react';
import {Path, Svg} from 'react-native-svg';

const CheckCircleSvg = () => {
  return (
    <Svg width="11" height="10" fill="none" viewBox="0 0 11 10">
      <Path
        fill="#12B76A"
        d="M4.325 4.925a.4.4 0 1 0-.566.566zm.55 1.117-.283.282a.4.4 0 0 0 .566 0zm2.366-1.8a.4.4 0 1 0-.565-.567zM9.267 5A3.767 3.767 0 0 1 5.5 8.767v.8A4.567 4.567 0 0 0 10.067 5zM5.5 8.767A3.767 3.767 0 0 1 1.734 5h-.8A4.567 4.567 0 0 0 5.5 9.567zM1.734 5A3.767 3.767 0 0 1 5.5 1.233v-.8A4.567 4.567 0 0 0 .933 5zM5.5 1.233A3.767 3.767 0 0 1 9.267 5h.8A4.567 4.567 0 0 0 5.5.433zM3.76 5.491l.833.833.566-.565-.833-.834zm1.399.833 2.083-2.083-.565-.566L4.592 5.76z"
      ></Path>
    </Svg>
  );
};

export default React.memo(CheckCircleSvg);
