import { useLazyQuery } from "@apollo/client";
import { Collapse } from "antd";
import { HStack, Skeleton, Text, VStack } from "native-base";
import { useEffect, useState } from "react";
import { GROUP_MEMBER_TYPE } from "../../../../../../constants";
import { UserQueries } from "../../../../../../services";
import { getProfileImage } from "../../../../../../services/ProfileImage/ProfileImage";
import { Colors } from "../../../../../../styles";
import { DisplayCardAvatar } from "../../../../../common/DisplayCard/DisplayCardAvatar";
import { styles } from "../../../workflowStyles";
const { Panel } = Collapse;
const GetUserPhotoAndNameByUserId = (props: { userId: number }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [user, setUser] = useState<any>()
  const [getUserById] = useLazyQuery(
    UserQueries.GET_CONTACTS_BY_ID,
    {
      fetchPolicy: 'no-cache',
      onError: (error: any) => {

      },
    }
  );

  const [profileImgSrc, setProfileImgSrc] = useState('')
  // const loadProfileImage = async (name: string, email: string) => {
  //   const userImageRes: any = await getProfileImage(
  //     name || '',
  //     email || ''
  //   );
  //   if (userImageRes?.data?.avatar_url) {
  //     setProfileImgSrc(userImageRes?.data?.avatar_url.includes('404')
  //       ? ''
  //       : userImageRes?.data?.avatar_url)
  //   }
  // }
  useEffect(() => {
    getUserById({
      variables: {
        userId: props.userId,
      },
    }).then((respInner) => {
      if (respInner?.data?.user) {
        setUser(respInner?.data?.user)
        // loadProfileImage(respInner?.data?.user?.name, respInner?.data?.user?.email)
      }
      setIsLoading(false)
    });
  }, [props.userId])
  return <HStack alignItems={'center'} flex={1} >
    {isLoading ?
      <Skeleton.Text flex={1} lines={1}></Skeleton.Text> :
      <>
        <DisplayCardAvatar
          avatarStyle={{
            avatarSize: '12',
          }}
          isLetterAvatarShow={true}
          userData={{
            userId: user?.id,
            userType: GROUP_MEMBER_TYPE.USER,
            roles: user.userRoles,
            imgSrc: profileImgSrc || '',
            userName: user?.name
          }}
        />
        <VStack style={styles.marginLeft12}>
          <Text size={'dtMedium'} color={Colors.FoldPixel.GRAY400}>
            {(
              (user?.name || '')
            ).trim()}
          </Text>
          <Text size={'xsNormal'} color={Colors.FoldPixel.GRAY250}>
            {user?.email}
          </Text>
        </VStack>
      </>}
  </HStack>
}
export default GetUserPhotoAndNameByUserId;
