import * as React from 'react';

export interface ITaskViewContext {
  isRenderCompactView?: boolean;
  isShowGrayBackground?: boolean; 
}

export const TaskViewContext = React.createContext<ITaskViewContext>({
  isRenderCompactView: false,
  isShowGrayBackground: false,
});
