import { Colors } from "../../../../../../../styles";
import { Modal } from "antd";
import { ReactNode, useEffect, useState } from "react";
import Stack from "../../../../../../common/LayoutComponents/Stack";
import { modalActionDialogStyles } from "../../../../../../common/ModalActionDialog/styles";
import { Text, View, Button, Spinner } from "native-base";
import CustomConfirmationModal from "../../../../../../common/CustomConfirmationModal/CustomConfirmationModal";
import BillSvg from "../../../../../../common/Svg/GlobalActionSvg/BillSvg";
import CheckRoundIcon from "../../../../../../../assets/Icons/CheckRoundIcon";
import CrossCircleSvg from "../../../../../../common/Svg/CrossCircleSvg";
import { useIntl } from "react-intl";
import InfoSvg from "../../../../../../common/Svg/InfoSvg";
import LightbulbMinimalistic from "../../../../../../common/Svg/LightbulbMinimalistic";
import { IConditionsView, ISingleStepView } from "./interface";
import { useSendClaimToEhrCheck } from "./useSendClaimToEhrCheck";
import { CONDITION_STATUS } from "./SendClaimConditionConsts";


const SingleStepView = (props: ISingleStepView) => {
  const [icon, setIcon] = useState<ReactNode>(null);

  useEffect(() => {
    switch (props.status) {
      case CONDITION_STATUS.COMPLETED:
        setIcon(<CheckRoundIcon />);
        break;
      case CONDITION_STATUS.INCOMPLETE:
        setIcon(<CrossCircleSvg />);
        break;
      case CONDITION_STATUS.LOADING:
        setIcon(<Spinner size={'sm'}/>);
        break;
    }
  }, [props.status]);
  

  const {formatMessage} = useIntl();
  const stepName = formatMessage({id: props.description});
  return (
    <Stack direction='row' space={4} style={{alignItems: 'center'}}>
      <View>
        {icon}
      </View>
      <Text fontSize={14} color={Colors.FoldPixel.GRAY200}>{stepName}</Text>
    </Stack>
  )
}

const DisclaimerView = (props: {disclaimer: string}) => {
  return (
    <Stack direction='row' space={4} style={{alignItems: 'flex-start', backgroundColor: Colors.FoldPixel.GRAY50, padding: 6, borderRadius: 8, borderColor: Colors.FoldPixel.GRAY100, borderWidth: 0.5}}>
      <LightbulbMinimalistic />
      <Text fontSize={12} color={Colors.FoldPixel.GRAY400}>{props.disclaimer}</Text>
    </Stack>
  )
}

const ConditionsView = (props: IConditionsView) => {
  const {formatMessage} = useIntl();
  const disclaimerString = formatMessage({id: props.disclaimer});
  return (
    <Stack direction='column' space={12} style={{ margin: 16}}>
      {props.conditions.map((condition) => (
        <SingleStepView {...condition} />
      ))}
      <DisclaimerView disclaimer={disclaimerString} />
    </Stack>
  )
}

export const SendClaimModalView = (props: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const {sendClaimConditionsState, areAllConditionsComplete} = useSendClaimToEhrCheck();
  const okButtonStyle = areAllConditionsComplete ? {
    backgroundColor: Colors.Custom.Primary300,
    borderColor: Colors.Custom.Primary300,
  } : {
    backgroundColor: Colors.FoldPixel.GRAY50,
    borderColor: Colors.FoldPixel.GRAY100,
  };

  const okButtonTextStyle = areAllConditionsComplete ? {
    color: Colors.Custom.ContainerBGColor,
  } : {
    color: Colors.FoldPixel.GRAY150,
  };
  return (
    <CustomConfirmationModal
      subtitle={'sendBillToEhrMsg'}
      customCancelBtnText={'Cancel'}
      customOkBtnText={'Send to EHR'}
      isOpen={props.isOpen}
      headerText={'sendBillToEhr'}
      customIcon={<BillSvg />}
      width={360}
      customOkBtnTextStyle={okButtonTextStyle}
      customOkBtnStyle={okButtonStyle}
      onCancel={props.onClose}
      onConfirm={() => {
        if (areAllConditionsComplete) {
          props.onConfirm();
          setIsLoading(true);
        }
      }}
      modalContent={
        <ConditionsView conditions={Object.values(sendClaimConditionsState)} disclaimer={'sendBillDisclaimer'} />
      }
    />
  )
}