import {useLazyQuery} from '@apollo/client';
import {Select, Spin} from 'antd';
import {Text} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {CLOUD_TELEPHONY_APOLLO_CONTEXT} from '../../../constants/Configs';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {CloudTelephonyQueries, UserQueries} from '../../../services';
import {getAccountId, getAccountUUID, getAllowedUserAccountLocationUuids, isLoggedInUserWorkFlowOrCustomerSuccess, isLoginUserBusinessOwner} from '../../../utils/commonUtils';
import {getVirtualNumberFormattedList} from '../../RightSideContainer/CloudTelephony/CloudTelephonyUtils';
import {IVirtualPhoneNumberResp} from '../../RightSideContainer/CloudTelephony/interfaces';
import {IUsersResponse} from '../../RightSideContainer/Contacts/TeamMembers/interfaces';
import {IIVirtualNumberSearch} from './interface';
import {isAccountConfigEnabled} from '../../../utils/configUtils';
import {CONFIG_CODES} from '../../../constants/AccountConfigConst';
import UserPracticeLocationQueries from '../../../services/Location/UserPracticeLocationQueries';
import {USER_ACCESS_PERMISSION} from '../../RightSideContainer/UserAccess/UserAccessPermission';
import {MAIN_MENU_CODES} from '../../SideMenuBar/SideBarConst';
import { WorkflowContext } from '../../../context/WorkflowContext';
import { GLOBAL_ROLE_CODES } from '../../../constants/MlovConst';
import { CaretDownOutlined } from '@ant-design/icons';
import { reactStyles } from '../../RightSideContainer/Workflow/workflowStyles';

const VirtualPhoneListSearch = (props:IIVirtualNumberSearch)=> {
  const {onSelection} = props;
  const accountId = getAccountId();
  const accountUuid = getAccountUUID()
  const commonData = useContext(CommonDataContext);
  const mlovData = commonData.CLOUD_TELEPHONY_MLOV;
  const CommunicationType = mlovData?.CommunicationType;
  const assigneesTypeList = mlovData['VirtualNumberAssignee'] || [];
  const isAllowedAllLocation = isLoginUserBusinessOwner() || isLoggedInUserWorkFlowOrCustomerSuccess();
  const isCommunicationLocationHandlingEnabled = !isAllowedAllLocation && isAccountConfigEnabled(
    CONFIG_CODES.ENABLE_COMMUNICATION_LOCATION_HANDLING
  );
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  const workflowContext = useContext(WorkflowContext);
  const allowedUserAccountLocationUuids = getAllowedUserAccountLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.CALL
  );
  const [stateData, setStateData] = useState({
    tableListData: [] as any,
    loading: false,
    selectedValue: ''  as any
  });
  const getValueOfDropdown = (value: any) => {
    if (value) {
      const isNumberPresent = stateData.tableListData.filter((number: any) => {
        return number?.phoneNumber?.phoneNumber === value;
      })
      if (!isNumberPresent?.length) {
        return value + '(Deleted)';
      } else {
        return value;
      }
    }
  }

  const [GET_VIRTUAL_PHONE_NUMBER] = useLazyQuery<IVirtualPhoneNumberResp>(
    CloudTelephonyQueries.GET_VIRTUAL_PHONE_NUMBER,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );

  const [GET_ALL_DEFAULT_NUMBER_FOR_ACCOUNT] = useLazyQuery(
    CloudTelephonyQueries.GET_ALL_DEFAULT_NUMBER_FOR_ACCOUNT,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );

  const [GET_USER_PRACTICE_LOCATION_BASED_VIRTUAL_PHONE_NUMBERS] = useLazyQuery<IVirtualPhoneNumberResp>(
    CloudTelephonyQueries.GET_USER_PRACTICE_LOCATION_BASED_VIRTUAL_PHONE_NUMBERS,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [GetUsers] = useLazyQuery(
    UserQueries.GET_USERS_FOR_CALENDAR_V2, {
      fetchPolicy: 'no-cache',
    }
  );

  const userboolExp = isMsoEnabled
    ? {
      _or: [
        {
          userPracticeLocations: {
            isDeleted: {
              _eq: false
            },
            accountLocation: {
              uuid: {
                _in: workflowContext.locationIds,
              },
            },
          },
        },
        {
          userRoles: {
            userRole: {
              userRole: {
                code: { _in: [GLOBAL_ROLE_CODES.GLOBAL_ADMIN] }
              }
            }
          }
        }
      ]
    }
    : {};


  const [getUsersData] = useLazyQuery<IUsersResponse>(UserQueries.GET_USERS, {
    variables: {
      searchString: '%%',
      accountId: getAccountId(),
    },
  });
  const getNumbersList = async () => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    let responses:any;
    if (isMsoEnabled) {
      let filteredUsers:string[] = [];
      const userResp = await GetUsers({
        variables: {
          accountUuid: accountUuid,
          ...(userboolExp && { userboolExp })

        },
      });
      if (userResp?.data?.users) {
        filteredUsers =
        userResp?.data?.users?.map(
          (value: {id: string; uuid: string}) => {
            return value?.uuid;
          }
        );
      }
      filteredUsers.push(...workflowContext.locationIds || []);

      responses = await GET_USER_PRACTICE_LOCATION_BASED_VIRTUAL_PHONE_NUMBERS({
        context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
        variables: {
          accountUuid: accountUuid,
          assigneeIds: filteredUsers,
          searchString: '%%',
        },
      });
    } else {
      responses = await GET_VIRTUAL_PHONE_NUMBER({
        context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
        variables: {
          accountUuid: accountUuid,
          searchString: '%%',
        },
      });
    }
    const userDataList = await getUsersData();
    const allDefaultForAccount = await GET_ALL_DEFAULT_NUMBER_FOR_ACCOUNT({
      context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
      variables: {
        accountUuid: accountUuid,
        tenantId: accountUuid
      },
    });
    const virtualNumberList = getVirtualNumberFormattedList(
      responses?.data?.virtualPhoneNumberAssignees || [],
      assigneesTypeList,
      userDataList.data?.users || [],
      [],
      allDefaultForAccount?.data?.numberDefaultCommunications || [],
      CommunicationType
    );
    virtualNumberList.forEach((virtualNumber: any) => {
      const label = `${virtualNumber.phoneNumber?.name} (${virtualNumber.phoneNumber?.phoneNumber})`;
      virtualNumber.label = label;
    });
    setStateData((prev) => {
      return {
        ...prev,
        tableListData: virtualNumberList || [],
        loading: false,
      };
    });
  };
  useEffect(()=> {
    getNumbersList()
  },[])
  const modifiedValue = getValueOfDropdown(props.value);
  return (
    <Select
      suffixIcon={
        <CaretDownOutlined
          style={reactStyles.colorGray300}
        />
      }
      className={props?.isShowError && !props?.value ? 'field-error' : ''}
      dropdownMatchSelectWidth={3}
      size="large"
      allowClear
      showSearch={true}
      filterOption={(input, option) =>
        (option!.children as unknown as string)
          .toLowerCase()
          .includes(input.toLowerCase())
      }
      value={modifiedValue ? modifiedValue : undefined}
      onChange={(value: any[], data: any) => {
        onSelection({key:data?.key, value: data?.value})
      }}
      placeholder="Search number"
      // loading={componentState.loading}
      notFoundContent={stateData.loading && <Spin size="small" />}
      style={{height: '36px'}}
      loading={stateData.loading}
    >
      {stateData.tableListData.map((number: any) => {
        return (
          <Select.Option
            key={`${number.id}`}
            value={number?.phoneNumber?.phoneNumber}
          >
            {number.label}
          </Select.Option>
        );
      })}
    </Select>
  );
}

export default React.memo(VirtualPhoneListSearch)
