import {IKeyOperation} from '../../../../../Interfaces';
import {EHR_CAPABILITY_CONST} from '../../../../../utils/capabilityUtils';

export enum CommunicationField {
  email = 'email',
  phoneNumber = 'phoneNumber',
  addressLine1 = 'addressLine1',
  addressLine2 = 'addressLine2',
  zipCode = 'zipCode',
  state = 'state',
  city = 'city',
}

export enum PatientField {
  firstName ,
  lastName ,
  birthDate,
  gender ,
  birthSex,
}

export enum PatientIdentifierField {
  externalId,
  birthDate,
}

export enum EmergencyContactField {
  firstName = 'firstName',
  lastName = 'lastName',
  email  = 'email',
  phoneNumber = 'phoneNumber',
  relationType = 'relationType',
}

export enum MedicationFields {
  medicationCodeableConcept,
  status,
  effectivePeriod,
  informationSource,
  statusReason,
  discontinuedDate,
  stopDate,
  note,
  sig
}

export enum AllergyFields {
  code,
  onSetDateTime,
  clinicalStatus,
  reaction,
  criticality,
  note,
  type,
}

export enum ImmunizationFields {
  immunization,
  occurrenceDateTime,
  performer,
  lotNumber,
  reasonCode,
  manufacturer,
  expirationDate,
  doseQuantity,
  doseQuantityUnit,
  vaccineCode,
}

export enum SurgicalHistoryFields {
  code,
  status,
  performedDateTime,
}

export enum ConditionFields {
  code,
  note,
  onSetDateTime,
  clinicalStatus,
  conditionType,
  onsetDateTime
}

export enum PastMedicalHistoryFields {
  code,
}

export enum BarrierFields {
  title
}

export enum InterventionFields {
  type,
  title,
  executionAfter
}

export enum DischargeDetailsFields {
  admissionDate = 'admissionDate',
  dischargeDate = 'dischargeDate',
  lengthOfStay = 'lengthOfStay',
  lengthOfStayUnit = 'lengthOfStayUnit',
  dischargeDisposition = 'dischargeDisposition',
  admissionType = 'admissionType',
  hospitalName = 'hospitalName',
  primaryDiagnosis = 'primaryDiagnosis',
  dischargeDiagnosis = 'dischargeDiagnosis',
}

export const ATHENA_STATUS = {
  ACTIVE: 'Active',
  HISTORICAL: 'Historical',
}

export const CustomComponentKey = {
  PATIENT_COMMUNICATION: 'patientCommunication',
  PATIENT_ADDRESS: 'patientAddress',
  PATIENT_DEMOGRAPHICS: 'patientDemographics',
  EMERGENCY_CONTACT: 'emergencyContact',
  MEDICATION: 'medications',
  ALLERGY: 'allergies',
  IMMUNIZATION: 'immunizations',
  SURGICAL_HISTORY: 'surgicalHistory',
  PROBLEM: 'conditions',
  CHIEF_COMPLAINT: 'chiefComplaint',
  DIAGNOSIS: 'diagnosis',
  PAST_MEDICAL_HISTORY: 'pastMedicalHistory',
  PATIENT_IDENTIFIER: 'patientIdentifier',
  BARRIER: 'barrier',
};

export const CustomComponentRequiredField = {
patientCommunication: [CommunicationField.zipCode],
  patientAddress: [CommunicationField.zipCode],
  patientDemographics: [
    PatientField.firstName,
    PatientField.lastName,
    PatientField.birthDate,
  ],
  emergencyContact: [
    EmergencyContactField.firstName,
    EmergencyContactField.lastName,
    EmergencyContactField.phoneNumber,
    EmergencyContactField.relationType,
  ],
  medications: [
    MedicationFields.medicationCodeableConcept,
    MedicationFields.status,
    MedicationFields.effectivePeriod,
    MedicationFields.discontinuedDate,
    MedicationFields.stopDate,
    MedicationFields.note,
  ],
  allergies: [
    AllergyFields.code,
    AllergyFields.clinicalStatus,
    AllergyFields.criticality,
    AllergyFields.note,
    AllergyFields.onSetDateTime,
    AllergyFields.reaction,
    AllergyFields.type,
  ],
  immunizations: [],
  surgicalHistory: [],
  conditions: [],
  patientIdentifier: [
    PatientIdentifierField.externalId,
    PatientIdentifierField.birthDate
  ]
};

export const CustomComponentDefaultField = {
  patientCommunication: [
    CommunicationField.email,
    CommunicationField.phoneNumber,
    CommunicationField.addressLine1,
    CommunicationField.addressLine2,
    CommunicationField.zipCode,
    CommunicationField.state,
    CommunicationField.city,
  ],
  patientAddress: [
    CommunicationField.addressLine1,
    CommunicationField.addressLine2,
    CommunicationField.zipCode,
    CommunicationField.state,
    CommunicationField.city,
  ],
  patientDemographics: [
    PatientField.firstName,
    PatientField.lastName,
    PatientField.birthDate,
    PatientField.gender,
    PatientField.birthSex,
  ],
  emergencyContact: [
    EmergencyContactField.firstName,
    EmergencyContactField.lastName,
    EmergencyContactField.email,
    EmergencyContactField.phoneNumber,
    EmergencyContactField.relationType,
  ],
  medications: [],
  allergies: [],
  immunizations: [],
  conditions: [],
};

export const getComponentField = (
  componentKey: string,
  keyAllowedOperations?: IKeyOperation,
) => {
  let fields: any[] = [];
  switch (componentKey) {
    case CustomComponentKey.PATIENT_COMMUNICATION:
      fields = CustomComponentDefaultField.patientCommunication;
      break;
    case CustomComponentKey.EMERGENCY_CONTACT:
      fields = CustomComponentDefaultField.emergencyContact;
      break;
    case CustomComponentKey.PATIENT_ADDRESS:
      fields = CustomComponentDefaultField.patientAddress;
      break;
    case CustomComponentKey.PATIENT_DEMOGRAPHICS:
      fields = CustomComponentDefaultField.patientDemographics;
      if (keyAllowedOperations && typeof keyAllowedOperations === 'object') {
        if (
          keyAllowedOperations['birthSex'] &&
          keyAllowedOperations['birthSex'].isHidden
        ) {
          fields = fields.filter((field) => {
            return field !== PatientField.birthSex;
          });
        }
        if (
          keyAllowedOperations['gender'] &&
          keyAllowedOperations['gender'].isHidden
        ) {
          fields = fields.filter((field) => {
            return field !== PatientField.gender;
          });
        }
      }
      break;
    case CustomComponentKey.MEDICATION:
      fields = getMedicationFields(keyAllowedOperations);
      break;
    case CustomComponentKey.ALLERGY:
      fields = getAllergyFields(keyAllowedOperations);
      break;
    case CustomComponentKey.IMMUNIZATION:
      fields = getImmunizationFields(keyAllowedOperations);
      break;
    case CustomComponentKey.SURGICAL_HISTORY:
      fields = getSurgicalHistoryFields(keyAllowedOperations);
      break;
    case CustomComponentKey.PROBLEM:
    case CustomComponentKey.DIAGNOSIS:
      fields = getProblemFields(keyAllowedOperations);
      break;
    case CustomComponentKey.PAST_MEDICAL_HISTORY:
      fields = getProblemFields(keyAllowedOperations);
      break;
  }
  return fields;
};

export const isRequiredField = (
  componentKey: string,
  field?: any,
  keyAllowedOperations?: IKeyOperation,
) => {
  switch (componentKey) {
    case CustomComponentKey.PATIENT_COMMUNICATION:
      return CustomComponentRequiredField.patientCommunication.includes(field);
    case CustomComponentKey.EMERGENCY_CONTACT:
      return CustomComponentRequiredField.emergencyContact.includes(field);
    case CustomComponentKey.PATIENT_ADDRESS:
      return CustomComponentRequiredField.patientAddress.includes(field);
    case CustomComponentKey.PATIENT_DEMOGRAPHICS:
      if (
        keyAllowedOperations &&
        typeof keyAllowedOperations === 'object' &&
        field === PatientField.birthSex &&
        keyAllowedOperations['birthSex']
      ) {
        return keyAllowedOperations['birthSex'].isRequired;
      }
      return CustomComponentRequiredField.patientDemographics.includes(field);
    case CustomComponentKey.MEDICATION:
      return getMedicationRequiredFields(field, keyAllowedOperations);
    case CustomComponentKey.ALLERGY:
      return getAllergyRequiredFields(field, keyAllowedOperations);
    case CustomComponentKey.SURGICAL_HISTORY:
      return getSurgicalHistoryRequiredFields(field, keyAllowedOperations);
    case CustomComponentKey.IMMUNIZATION:
      return getImmunizationRequiredFields(field, keyAllowedOperations);
    case CustomComponentKey.PROBLEM:
    case CustomComponentKey.DIAGNOSIS:
      return getProblemRequiredFields(field, keyAllowedOperations);
    case CustomComponentKey.PAST_MEDICAL_HISTORY:
      return getPastMedicalHistoryRequiredFields(field,keyAllowedOperations);
    case CustomComponentKey.PATIENT_IDENTIFIER:
      return CustomComponentRequiredField.patientIdentifier.includes(field);
  }
  return false;
};

const getMedicationFields = (keyAllowedOperations?: IKeyOperation) => {
  const fieldArray: any[] = [];
  if (
    keyAllowedOperations &&
    typeof keyAllowedOperations === 'object' &&
    Object.keys(keyAllowedOperations).length
  ) {
    Object.keys(keyAllowedOperations).forEach(key => {
      if (keyAllowedOperations[key] && !keyAllowedOperations[key].isHidden) {
        switch (key) {
          case 'status':
            fieldArray.push(MedicationFields.status);
            break;
          case 'effectivePeriod':
            fieldArray.push(MedicationFields.effectivePeriod);
            break;
          case 'informationSource':
            fieldArray.push(MedicationFields.informationSource);
            break;
          case 'discontinuedDate':
            fieldArray.push(MedicationFields.discontinuedDate);
            break;
          case 'stopDate':
            fieldArray.push(MedicationFields.stopDate);
            break;
          case 'note':
            fieldArray.push(MedicationFields.note);
            break;
        }
      }
    });
  }
  return fieldArray;
};

const getMedicationRequiredFields = (
  field?: MedicationFields,
  keyAllowedOperations?: IKeyOperation,
) => {
  let isRequiredField: boolean | undefined;
  if (
    keyAllowedOperations &&
    typeof keyAllowedOperations === 'object' &&
    Object.keys(keyAllowedOperations).length
  ) {
    switch (field) {
      case MedicationFields.status:
        isRequiredField = keyAllowedOperations['status']
          ? keyAllowedOperations['status'].isRequired
          : false;
        break;
      case MedicationFields.effectivePeriod:
        isRequiredField = keyAllowedOperations['effectivePeriod']
          ? keyAllowedOperations['effectivePeriod'].isRequired
          : false;
        break;
      case MedicationFields.medicationCodeableConcept:
        isRequiredField = keyAllowedOperations['medicationCodeableConcept']
          ? keyAllowedOperations['medicationCodeableConcept'].isRequired
          : false;
        break;
      case MedicationFields.informationSource:
        isRequiredField = keyAllowedOperations['informationSource']
          ? keyAllowedOperations['informationSource'].isRequired
          : false;
        break;
      case MedicationFields.discontinuedDate:
        isRequiredField = keyAllowedOperations['discontinuedDate']
          ? keyAllowedOperations['discontinuedDate'].isRequired
          : false;
        break;
      case MedicationFields.stopDate:
        isRequiredField = keyAllowedOperations['stopDate']
          ? keyAllowedOperations['stopDate'].isRequired
          : false;
        break;
      case MedicationFields.note:
        isRequiredField = keyAllowedOperations['note']
          ? keyAllowedOperations['note'].isRequired
          : false;
        break;
    }
  }
  return isRequiredField;
};

const getAllergyFields = (keyAllowedOperations?: IKeyOperation) => {
  const fieldArray: any[] = [];
  if (
    keyAllowedOperations &&
    typeof keyAllowedOperations === 'object' &&
    Object.keys(keyAllowedOperations).length
  ) {
    Object.keys(keyAllowedOperations).forEach(key => {
      if (keyAllowedOperations[key] && !keyAllowedOperations[key].isHidden) {
        switch (key) {
          case 'clinicalStatus':
            fieldArray.push(AllergyFields.clinicalStatus);
            break;
          case 'criticality':
            fieldArray.push(AllergyFields.criticality);
            break;
          case 'note':
            fieldArray.push(AllergyFields.note);
            break;
          case 'onsetDateTime':
            fieldArray.push(AllergyFields.onSetDateTime);
            break;
          case 'reaction':
            fieldArray.push(AllergyFields.reaction);
            break;
        }
      }
    });
  }
  return fieldArray;
};

const getAllergyRequiredFields = (
  field: AllergyFields,
  keyAllowedOperations?: IKeyOperation,
) => {
  let isRequiredField: boolean | undefined;
  if (
    keyAllowedOperations &&
    typeof keyAllowedOperations === 'object' &&
    Object.keys(keyAllowedOperations).length
  ) {
    switch (field) {
      case AllergyFields.code:
        isRequiredField = keyAllowedOperations['code']
          ? keyAllowedOperations['code'].isRequired
          : false;
        break;
      case AllergyFields.clinicalStatus:
        isRequiredField = keyAllowedOperations['clinicalStatus']
          ? keyAllowedOperations['clinicalStatus'].isRequired
          : false;
        break;
      case AllergyFields.criticality:
        isRequiredField = keyAllowedOperations['criticality']
          ? keyAllowedOperations['criticality'].isRequired
          : false;
        break;
      case AllergyFields.note:
        isRequiredField = keyAllowedOperations['note']
          ? keyAllowedOperations['note'].isRequired
          : false;
        break;
      case AllergyFields.onSetDateTime:
        isRequiredField = keyAllowedOperations['onsetDateTime']
          ? keyAllowedOperations['onsetDateTime'].isRequired
          : false;
        break;
      case AllergyFields.reaction:
        isRequiredField = keyAllowedOperations['reaction']
          ? keyAllowedOperations['reaction'].isRequired
          : false;
        break;
    }
  }
  return isRequiredField;
};

const getImmunizationFields = (keyAllowedOperations?: IKeyOperation) => {
  const fieldArray: any[] = [];
  if (
    keyAllowedOperations &&
    typeof keyAllowedOperations === 'object' &&
    Object.keys(keyAllowedOperations).length
  ) {
    Object.keys(keyAllowedOperations).forEach(key => {
      if (keyAllowedOperations[key] && !keyAllowedOperations[key].isHidden) {
        switch (key) {
          case 'occurrenceDateTime':
            fieldArray.push(ImmunizationFields.occurrenceDateTime);
            break;
          case 'performer':
            fieldArray.push(ImmunizationFields.performer);
            break;
          case 'doseQuantity':
            fieldArray.push(ImmunizationFields.doseQuantity);
            break;
          case 'doseQuantityUnit':
            fieldArray.push(ImmunizationFields.doseQuantityUnit);
            break;
        }
      }
    });
  }
  return fieldArray;
};

const getImmunizationRequiredFields = (
  field: ImmunizationFields,
  keyAllowedOperations?: IKeyOperation,
) => {
  let isRequiredField: boolean | undefined;
  if (
    keyAllowedOperations &&
    typeof keyAllowedOperations === 'object' &&
    Object.keys(keyAllowedOperations).length
  ) {
    switch (field) {
      case ImmunizationFields.occurrenceDateTime:
        isRequiredField = keyAllowedOperations['occurrenceDateTime']
          ? keyAllowedOperations['occurrenceDateTime'].isRequired
          : false;
        break;
      case ImmunizationFields.performer:
        isRequiredField = keyAllowedOperations['performer']
          ? keyAllowedOperations['performer'].isRequired
          : false;
        break;
      case ImmunizationFields.doseQuantity:
        isRequiredField = keyAllowedOperations['doseQuantity']
          ? keyAllowedOperations['doseQuantity'].isRequired
          : false;
        break;
      case ImmunizationFields.doseQuantityUnit:
        isRequiredField = keyAllowedOperations['doseQuantityUnit']
          ? keyAllowedOperations['doseQuantityUnit'].isRequired
          : false;
        break;
      case ImmunizationFields.vaccineCode:
        isRequiredField = keyAllowedOperations['vaccineCode']
          ? keyAllowedOperations['vaccineCode'].isRequired
          : false;
        break;
    }
  }
  return isRequiredField;
};

const getSurgicalHistoryFields = (keyAllowedOperations?: IKeyOperation) => {
  const fieldArray: any[] = [];
  if (
    keyAllowedOperations &&
    typeof keyAllowedOperations === 'object' &&
    Object.keys(keyAllowedOperations).length
  ) {
    Object.keys(keyAllowedOperations).forEach(key => {
      if (keyAllowedOperations[key] && !keyAllowedOperations[key].isHidden) {
        switch (key) {
          case 'status':
            fieldArray.push(SurgicalHistoryFields.status);
            break;
          case 'performedDateTime':
            fieldArray.push(SurgicalHistoryFields.performedDateTime);
            break;
        }
      }
    });
  }
  return fieldArray;
};

const getSurgicalHistoryRequiredFields = (
  field: SurgicalHistoryFields,
  keyAllowedOperations?: IKeyOperation,
) => {
  let isRequiredField: boolean | undefined;
  if (
    keyAllowedOperations &&
    typeof keyAllowedOperations === 'object' &&
    Object.keys(keyAllowedOperations).length
  ) {
    switch (field) {
      case SurgicalHistoryFields.status:
        isRequiredField = keyAllowedOperations['status']
          ? keyAllowedOperations['status'].isRequired
          : false;
        break;
      case SurgicalHistoryFields.code:
        isRequiredField = keyAllowedOperations['code']
          ? keyAllowedOperations['code'].isRequired
          : false;
        break;
      case SurgicalHistoryFields.performedDateTime:
        isRequiredField = keyAllowedOperations['performedDateTime']
          ? keyAllowedOperations['performedDateTime'].isRequired
          : false;
        break;
    }
  }
  return isRequiredField;
};

const getProblemFields = (keyAllowedOperations?: IKeyOperation) => {
  const fieldArray: any[] = [];
  if (
    keyAllowedOperations &&
    typeof keyAllowedOperations === 'object' &&
    Object.keys(keyAllowedOperations).length
  ) {
    Object.keys(keyAllowedOperations).forEach(key => {
      if (keyAllowedOperations[key] && !keyAllowedOperations[key].isHidden) {
        switch (key) {
          case 'note':
            fieldArray.push(ConditionFields.note);
            break;
          case 'onsetDateTime':
            fieldArray.push(ConditionFields.onSetDateTime);
            break;
          case 'clinicalStatus':
            fieldArray.push(ConditionFields.clinicalStatus);
            break;
          case 'type':
            fieldArray.push(ConditionFields.conditionType);
            break;
        }
      }
    });
  }
  return fieldArray;
};


const getProblemRequiredFields = (
  field: ConditionFields,
  keyAllowedOperations?: IKeyOperation,
) => {
  let isRequiredField: boolean | undefined;
  if (
    keyAllowedOperations &&
    typeof keyAllowedOperations === 'object' &&
    Object.keys(keyAllowedOperations).length
  ) {
    switch (field) {
      case ConditionFields.note:
        isRequiredField = keyAllowedOperations['note']
          ? keyAllowedOperations['note'].isRequired
          : false;
        break;
      case ConditionFields.code:
        isRequiredField = keyAllowedOperations['code']
          ? keyAllowedOperations['code'].isRequired
          : false;
        break;
      case ConditionFields.onSetDateTime:
        isRequiredField = keyAllowedOperations['onsetDateTime']
          ? keyAllowedOperations['onsetDateTime'].isRequired
          : false;
        break;
      case ConditionFields.clinicalStatus:
        isRequiredField = keyAllowedOperations['clinicalStatus']
          ? keyAllowedOperations['clinicalStatus'].isRequired
          : false;
        break;
      case ConditionFields.conditionType:
        isRequiredField = keyAllowedOperations['type']
          ? keyAllowedOperations['type'].isRequired
          : false;
        break;
    }
  }
  return isRequiredField;
};

const getPastMedicalHistoryRequiredFields = (
  field: ConditionFields,
  keyAllowedOperations?: IKeyOperation,
) => {
  let isRequiredField: boolean | undefined;
  if (
    keyAllowedOperations &&
    typeof keyAllowedOperations === 'object' &&
    Object.keys(keyAllowedOperations).length
  ) {
    switch (field) {
      case ConditionFields.code:
        isRequiredField = keyAllowedOperations['code']
          ? keyAllowedOperations['code'].isRequired
          : false;
        break;
    }
  }
  return isRequiredField;
};

export const getFieldDisplayName = (
  componentKey: string,
  field: any,
  keyAllowedOperations?: IKeyOperation,
) => {
  let displayName = '';
  switch (componentKey) {
    case CustomComponentKey.PATIENT_COMMUNICATION:
    case CustomComponentKey.PATIENT_ADDRESS:
      displayName = getCommunicationFieldName(field);
      break;
    case CustomComponentKey.PATIENT_DEMOGRAPHICS:
      displayName = getPatientFieldName(field, keyAllowedOperations);
      break;
    case CustomComponentKey.EMERGENCY_CONTACT:
      displayName = getEmergencyContactFieldName(field);
      break;
    case CustomComponentKey.MEDICATION:
      displayName = getMedicationFieldName(field);
      break;
    case CustomComponentKey.ALLERGY:
      displayName = getAllergyFieldName(field, keyAllowedOperations);
      break;
    case CustomComponentKey.IMMUNIZATION:
      displayName = getImmunizationFieldName(field, keyAllowedOperations);
      break;
    case CustomComponentKey.SURGICAL_HISTORY:
      displayName = getSurgicalFieldName(field, keyAllowedOperations);
      break;
    case CustomComponentKey.PROBLEM:
    case CustomComponentKey.DIAGNOSIS:
      displayName = getProblemFieldName(field, keyAllowedOperations);
      break;
    case CustomComponentKey.PAST_MEDICAL_HISTORY:
      displayName = getPastMedicalHistoryFieldName(field, keyAllowedOperations)
      break;
  }
  return displayName;
};

const getPatientFieldName = (
  field: PatientField,
  keyAllowedOperations?: IKeyOperation,
) => {
  let displayName = '';
  switch (field) {
    case PatientField.firstName:
      displayName = 'First Name';
      break;
    case PatientField.lastName:
      displayName = 'Last Name';
      break;
    case PatientField.birthDate:
      displayName = 'Date of birth';
      break;
    case PatientField.gender:
      displayName =
        keyAllowedOperations?.gender?.label ||
        EHR_CAPABILITY_CONST.DEFAULT_GENDER_IDENTITY_LABEL;
      break;
    case PatientField.birthSex:
      displayName =
        keyAllowedOperations?.birthSex?.label ||
        EHR_CAPABILITY_CONST.DEFAULT_BIRTH_SEX_LABEL;
      break;
  }
  return displayName;
};

const getCommunicationFieldName = (field: CommunicationField) => {
  let displayName = '';
  switch (field) {
    case CommunicationField.email:
      displayName = 'Email';
      break;
    case CommunicationField.phoneNumber:
      displayName = 'Mobile No';
      break;
    case CommunicationField.addressLine1:
      displayName = 'Address Line1';
      break;
    case CommunicationField.addressLine2:
      displayName = 'Address Line2';
      break;
    case CommunicationField.city:
      displayName = 'City';
      break;
    case CommunicationField.state:
      displayName = 'State';
      break;
    case CommunicationField.zipCode:
      displayName = 'Zip Code';
      break;
  }
  return displayName;
};

const getEmergencyContactFieldName = (field: EmergencyContactField) => {
  let displayName = '';
  switch (field) {
    case EmergencyContactField.email:
      displayName = 'Email';
      break;
    case EmergencyContactField.phoneNumber:
      displayName = 'Mobile No';
      break;
    case EmergencyContactField.relationType:
      displayName = 'Relation Type';
      break;
    case EmergencyContactField.firstName:
      displayName = 'First Name';
      break;
    case EmergencyContactField.lastName:
      displayName = 'Last Name';
      break;
  }
  return displayName;
};

const getMedicationFieldName = (field: MedicationFields) => {
  let displayName = '';
  switch (field) {
    case MedicationFields.medicationCodeableConcept:
      displayName = 'Medication Open Choice';
      break;
    case MedicationFields.status:
      displayName = 'Status';
      break;
    case MedicationFields.effectivePeriod:
      displayName = 'Started Medication On';
      break;
    case MedicationFields.informationSource:
      displayName = 'Practitioner';
      break;
    case MedicationFields.discontinuedDate:
      displayName = 'Discontinued Date';
      break;
    case MedicationFields.stopDate:
      displayName = 'Stop Date';
      break;
  }
  return displayName;
};

const getAllergyFieldName = (
  field: AllergyFields,
  keyAllowedOperations?: IKeyOperation,
) => {
  let displayName = '';
  const onsetDateFormat = keyAllowedOperations?.onsetDateTime?.format;
  const isOnlyYearFormat = onsetDateFormat === 'YYYY';
  switch (field) {
    case AllergyFields.code:
      displayName = 'Allergy Open Choice';
      break;
    case AllergyFields.clinicalStatus:
      displayName = 'Status';
      break;
    case AllergyFields.criticality:
      displayName = 'Criticality';
      break;
    case AllergyFields.onSetDateTime:
      displayName = isOnlyYearFormat ? 'Since Year' : 'Since Date';
      break;
    case AllergyFields.reaction:
      if (
        keyAllowedOperations &&
        typeof keyAllowedOperations === 'object' &&
        keyAllowedOperations['reaction'].disableMultiple
      ) {
        displayName = 'Reaction';
      } else {
        displayName = 'Reactions';
      }
      break;
    case AllergyFields.note:
      displayName = 'Notes';
      break;
  }
  return displayName;
};

const getImmunizationFieldName = (
  field: ImmunizationFields,
  keyAllowedOperations?: IKeyOperation,
) => {
  let displayName = '';
  switch (field) {
    case ImmunizationFields.doseQuantity:
      displayName = 'Dose Quantity';
      break;
    case ImmunizationFields.doseQuantityUnit:
      displayName = 'Dose Quantity Unit';
      break;
    case ImmunizationFields.occurrenceDateTime:
      displayName = 'Date Administered';
      break;
    case ImmunizationFields.performer:
      displayName = 'Practitioner';
      break;
    case ImmunizationFields.vaccineCode:
      displayName = 'Immunization Open Choice';
      break;
  }
  return displayName;
};

const getSurgicalFieldName = (
  field: SurgicalHistoryFields,
  keyAllowedOperations?: IKeyOperation,
) => {
  let displayName = '';
  switch (field) {
    case SurgicalHistoryFields.status:
      displayName = 'Status';
      break;
    case SurgicalHistoryFields.code:
      displayName = 'Surgical Open Choice';
      break;
    case SurgicalHistoryFields.performedDateTime:
      displayName = 'Performed Date';
      break;
  }
  return displayName;
};

const getProblemFieldName = (
  field: ConditionFields,
  keyAllowedOperations?: IKeyOperation,
) => {
  let displayName = '';
  const onsetDateFormat = keyAllowedOperations?.onsetDateTime?.format;
  const isOnlyYearFormat = onsetDateFormat === 'YYYY';
  switch (field) {
    case ConditionFields.code:
      displayName = 'Problem Open Choice';
      break;
    case ConditionFields.note:
      displayName = 'Notes';
      break;
    case ConditionFields.onSetDateTime:
      displayName = isOnlyYearFormat ? 'Since Year' : 'Since Date';
      break;
    case ConditionFields.clinicalStatus:
      displayName = 'Status';
      break;
    case ConditionFields.conditionType:
      displayName = 'Type';
      break;
  }
  return displayName;
};

const getPastMedicalHistoryFieldName = (
  field: PastMedicalHistoryFields,
  keyAllowedOperations?: IKeyOperation,
) => {
  let displayName = '';
  switch (field) {
    case PastMedicalHistoryFields.code:
      displayName = 'Problem Open Choice';
      break;
  }
  return displayName;
};

export const ALLOW_OVERRIDE_COMPONENT = {
  label: 'Keep fields editable for member',
  tableView: false,
  key: 'allowToEdit',
  type: 'checkbox',
  input: true,
};

export const filterAndSkipDuplicates = <Type extends {id?: string}>(prevData: Type[], newData: Type[]) => {
  const finalNewData:Type[] = [];
  const idSet = new Set<string | undefined>();

  prevData.forEach((prevItem) => {
    const newVersionOfEntry = newData.find(
      (newItem) => newItem.id === prevItem.id
    );
    if (newVersionOfEntry) {
      finalNewData.push(newVersionOfEntry);
      idSet.add(newVersionOfEntry?.id);
    } else {
      finalNewData.push(prevItem);
      idSet.add(prevItem.id);
    }
  });

  newData.forEach((newItem) => {
    const isAlreadyAdded = idSet.has(newItem.id);
    if (!isAlreadyAdded) {
      finalNewData.push(newItem);
    }
  });

  return finalNewData;

}
