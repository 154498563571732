import {useMemo, useState} from 'react';
import {usePersonOmniViewContext} from '../../../../PersonOmniView/PersonOmniView.context';
import {useContactCareProgramContext} from '../ContactCareProgram.context';
import {useCareProgramStatus} from '../hooks/useCareProgramStatus';
import {CARE_PROGRAM_STEP_TYPE} from '../../../../common/MemebersView/constant';
import {useMainContentContext} from './MainContentView.context';
import useCareProgramStepStatus from '../hooks/useCareProgramStepStatus';
import {
  StepAction,
  useCareProgramStepActions,
} from '../hooks/useCareProgramStepActions';
import {ContactCareProgramReducerAction} from '../reducer';
import {getContentHeaderActionList} from './ContentHeader.utils';
import {IContactCareProgramStep} from '../../interface';
import {IStepsLog} from '../../../../../services/ContactCareProgram/interface';
import {useSelectedStepInCareProgram} from './components/hooks/useSelectedStepInCareProgram';
import { useCareProgramEditability } from '../hooks/useCareProgramEditability';
import { EventBus } from '../../../../../utils/EventBus';
import { CARE_PROGRAM_EVENTS } from '../../useCPEventhandler';
import { CareProgramStatusAction } from '../hooks/useCareProgramStatusAction';

export const useCareProgramContentHeaderAction = () => {
  const {state, dispatch, onUpdateCareProgramStatus} = useContactCareProgramContext();
  const {contactCareProgramDetails, userMap} = state;
  const {careProgramStatus} = useCareProgramStatus();
  const {formattedData: formattedContactData} = usePersonOmniViewContext();
  const {isAllowedToTakeActions} = useCareProgramEditability();
  const {selectedStep: activeStep} = useSelectedStepInCareProgram();
  const additionalAttribute = activeStep?.careProgramStepAdditionalAttributes;
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const activeStepCode = activeStep?.careProgramStepType?.code;
  const isBillingStep = activeStepCode === CARE_PROGRAM_STEP_TYPE.BILLING;
  const isAssessmentStep = activeStepCode === CARE_PROGRAM_STEP_TYPE.ASSESSMENT;
  const {mainContentContextState} = useMainContentContext();
  const title = activeStep?.title;
  const {careProgramStepStatus, careProgramStepStatusList} = useCareProgramStepStatus();

  const {
    handleContentAction,
    isLoading,
    dropdownActionList,
    isModalOpen,
    closeModal,
    totalMinutes,
  } = useCareProgramStepActions({
    selectedStepCode: activeStepCode as string,
    extraParams: {
      contactId: formattedContactData?.contactUUID as string,
      patientId: formattedContactData?.patientId as string,
    },
    additionalAttributes: additionalAttribute || [],
    formResponseForAssessmentStep: mainContentContextState.formResponseForAssessmentStep,
  });


  const handleTimeLog = () => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent(CARE_PROGRAM_EVENTS.OPEN_TIME_LOGGING_DRAWER, {
      contactCareProgramId: contactCareProgramDetails?.id,
    });
    closeModal();
  };

  const handleHeaderStatusActions = async (action: StepAction) => {
    const newStepLogStatusId = await handleContentAction(action, {
      careProgramDetails: contactCareProgramDetails,
      stepId: state.selectedStepId as string,
    });
    if (newStepLogStatusId) {
      dispatch?.({
        type: ContactCareProgramReducerAction.UPDATE_STEP_STATUS,
        payload: {
          newStepLogStatusId,
          id: state.selectedStepId,
          skipMoveToNextStep:
            [StepAction.SEND_CLAIM, StepAction.REVIEW].includes(action) &&
            isBillingStep,
          statusesToCalculateNextStep: [
            careProgramStepStatus.in_progress,
            careProgramStepStatus.to_do,
          ],
        },
      });
    }
  };

  const handleHeaderDropdownActions = async (action: StepAction) => {
    setIsDropdownVisible(false);
    await handleContentAction(action, {
      careProgramDetails: contactCareProgramDetails,
      stepId: state.selectedStepId as string,
    });
  };

  const isDropdownLoading = useMemo(
    () => dropdownActionList.some((action) => isLoading === action.action),
    [dropdownActionList, isLoading]
  );

  const isDropdownDisabled =
    isDropdownLoading || mainContentContextState.headerSavingIndicator;
  const isActionDisabled = Boolean(
    isLoading || mainContentContextState.headerSavingIndicator
  );

  const selectedStepLog = useMemo(
    () =>
      state.contactCareProgramDetails?.stepsLog.find(
        (stepLog) => stepLog.careProgramStepId === state.selectedStepId
      ),
    [state.contactCareProgramDetails?.stepsLog.length, state.selectedStepId]
  );


  const isCareProgramStatusCompleted = !isAllowedToTakeActions;
  const isStepSkippedOrRejected = [careProgramStepStatus.skip, careProgramStepStatus.rejected].includes(selectedStepLog?.careProgramStepStatusId as string);

  const actionList = getContentHeaderActionList({
    activeStep: activeStep as IContactCareProgramStep,
    selectedStepLog: selectedStepLog as IStepsLog,
    selectedStepId: state.selectedStepId as string,
    careProgramStepStatus,
    activeStepCode: activeStepCode as string,
    careProgramStatusId: contactCareProgramDetails?.statusId as string,
    careProgramTypeCode: contactCareProgramDetails?.payerCareProgram?.careProgramType?.code as string,
    careProgramStatus: careProgramStatus
  });

  const handleDropdownVisibleChange = (visible: boolean) => {
    setIsDropdownVisible(visible);
  };

  const updateCareProgramStatus = async () => {
    if (contactCareProgramDetails?.statusId === careProgramStatus.newStatus) {
      await onUpdateCareProgramStatus(CareProgramStatusAction.IN_PROGRESS);
    }
  }

  return {
    actionList,
    isDropdownLoading,
    isDropdownDisabled,
    isActionDisabled,
    title,
    dropdownActionList: (isCareProgramStatusCompleted || isStepSkippedOrRejected) ? [] : dropdownActionList,
    activeStep,
    mainContentContextState,
    isLoading,
    isDropdownVisible,
    userMap,
    handleHeaderStatusActions,
    handleHeaderDropdownActions,
    handleDropdownVisibleChange,
    stepStatusCode: careProgramStepStatusList?.find(status => status.id === selectedStepLog?.careProgramStepStatusId)?.code,
    showSubtitleMessage: isAssessmentStep,
    isModalOpen,
    closeModal,
    handleTimeLog,
    totalMinutes,
    updateCareProgramStatus
  };
};
