/**
 To add icons for web, we need to inject them into style tag as follows
    <style type="text/css">{`
      @font-face {
        font-family: 'FontAwesome';
        src: url(${require('react-native-vector-icons/Fonts/FontAwesome.ttf')}) format('truetype');
      }
    `}</style>

 To support all other icons, add those fonts as @font-face in iconsStyle

 Github: https://www.npmjs.com/package/react-native-vector-icons
 Icon directory: https://oblador.github.io/react-native-vector-icons/

 Supported fonts:
    1. AntDesign by AntFinance (297 icons)
    2. Entypo by Daniel Bruce (411 icons)
    3. EvilIcons by Alexander Madyankin & Roman Shamin (v1.10.1, 70 icons)
    4. Feather by Cole Bemis & Contributors (v4.28.0, 285 icons)
    5. FontAwesome by Dave Gandy (v4.7.0, 675 icons)
    6. FontAwesome 5 by Fonticons, Inc. (v5.13.0, 1588 (free) 7842 (pro) icons)
    7. Fontisto by Kenan Gündoğan (v3.0.4, 615 icons)
    8. Foundation by ZURB, Inc. (v3.0, 283 icons)
    9. Ionicons by Iconic Framework (v5.0.1, 1227 icons)
    10. MaterialIcons by Google, Inc. (v4.0.0, 1547 icons)
    11. MaterialCommunityIcons by MaterialDesignIcons.com (v5.3.45, 5346 icons)
    12. Octicons by Github, Inc. (v8.4.1, 184 icons)
    13. Zocial by Sam Collins (v1.0, 100 icons)
    14. SimpleLineIcons by Sabbir & Contributors (v2.4.1, 189 icons)
*/

/**
 To use icons in view
  1. Import vector icons you need
      import Icon from 'react-native-vector-icons/FontAwesome';

  2. Check if selected icon lib (FontAwesome) is added below in iconsStyle

  3. Use it as
      <Icon name="rocket" size={30} color="#900" />

      OR

      <Icon.Button name="facebook" backgroundColor="#3b5998">
      Login with Facebook
      </Icon.Button>

      OR

      Check
      Github: https://www.npmjs.com/package/react-native-vector-icons
 */
export const iconsStyle = `
    @font-face {
      font-family: 'AntDesign';
      src: url(${require('react-native-vector-icons/Fonts/AntDesign.ttf')}) format('truetype');
    }

    @font-face {
      font-family: 'Ionicons';
      src: url(${require('react-native-vector-icons/Fonts/Ionicons.ttf')}) format('truetype');
    }

    @font-face {
      font-family: 'Octicons';
      src: url(${require('react-native-vector-icons/Fonts/Octicons.ttf')}) format('truetype');
    }

    @font-face {
      font-family: 'Fontisto';
      src: url(${require('react-native-vector-icons/Fonts/Fontisto.ttf')}) format('truetype');
    }

    @font-face {
      font-family: 'MaterialCommunityIcons';
      src: url(${require('react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf')}) format('truetype');
    }

    @font-face {
      font-family: 'Feather';
      src: url(${require('react-native-vector-icons/Fonts/Feather.ttf')}) format('truetype');
    }
    @font-face {
      font-family: 'FontAwesome5_Brands';
      src: url(${require('react-native-vector-icons/Fonts/FontAwesome5_Brands.ttf')}) format('truetype');
    }
    @font-face {
      font-family: 'FontAwesome5_Regular';
      src: url(${require('react-native-vector-icons/Fonts/FontAwesome5_Regular.ttf')}) format('truetype');
    }
    @font-face {
      font-family: 'FontAwesome5_Solid';
      src: url(${require('react-native-vector-icons/Fonts/FontAwesome5_Solid.ttf')}) format('truetype');
    }
    @font-face {
      font-family: 'MaterialIcons';
      src: url(${require('react-native-vector-icons/Fonts/MaterialIcons.ttf')}) format('truetype');
    }
    @font-face {
      font-family: 'MaterialCommunityIcons';
      src: url(${require('react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf')}) format('truetype');
    }
    @font-face {
      font-family: 'Ionicons';
      src: url(${require('react-native-vector-icons/Fonts/Ionicons.ttf')}) format('truetype');
    }


    @font-family: 'Manrope-ExtraLight';
      src: url(${require('../assets/fonts/Manrope-ExtraLight.ttf')}) format('truetype');
    }
    @font-face {
      font-family: 'Manrope-Light';
      src: url(${require('../assets/fonts/Manrope-Light.ttf')}) format('truetype');
    }
    @font-face {
      font-family: 'Manrope-Regular';
      src: url(${require('../assets/fonts/Manrope-Regular.ttf')}) format('truetype');
    }
    @font-face {
      font-family: 'Manrope-Medium';
      src: url(${require('../assets/fonts/Manrope-Medium.ttf')}) format('truetype');
    }
    @font-face {
      font-family: 'Manrope-SemiBold';
      src: url(${require('../assets/fonts/Manrope-SemiBold.ttf')}) format('truetype');
    }
    @font-face {
      font-family: 'Manrope-Bold';
      src: url(${require('../assets/fonts/Manrope-Bold.ttf')}) format('truetype');
    }
    @font-face {
      font-family: 'Inter_18pt-Black';
      src: url(${require('../assets/Font/Inter/static/Inter_18pt-Black.ttf')}) format('truetype');
    }
    @font-face {
      font-family: 'Inter_18pt-BlackItalic';
      src: url(${require('../assets/Font/Inter/static/Inter_18pt-BlackItalic.ttf')}) format('truetype');
    }
    @font-face {
      font-family: 'Inter_18pt-Bold';
      src: url(${require('../assets/Font/Inter/static/Inter_18pt-Bold.ttf')}) format('truetype');
    }
    @font-face {
      font-family: 'Inter_18pt-BoldItalic';
      src: url(${require('../assets/Font/Inter/static/Inter_18pt-BoldItalic.ttf')}) format('truetype');
    }
    @font-face {
      font-family: 'Inter_18pt-ExtraBold';
      src: url(${require('../assets/Font/Inter/static/Inter_18pt-ExtraBold.ttf')}) format('truetype');
    }
    @font-face {
      font-family: 'Inter_18pt-ExtraBoldItalic';
      src: url(${require('../assets/Font/Inter/static/Inter_18pt-ExtraBoldItalic.ttf')}) format('truetype');
    }
    @font-face {
      font-family: 'Inter_18pt-ExtraLight';
      src: url(${require('../assets/Font/Inter/static/Inter_18pt-ExtraLight.ttf')}) format('truetype');
    }
    @font-face {
      font-family: 'Inter_18pt-ExtraLightItalic';
      src: url(${require('../assets/Font/Inter/static/Inter_18pt-ExtraLightItalic.ttf')}) format('truetype');
    }
    @font-face {
      font-family: 'Inter_18pt-Italic';
      src: url(${require('../assets/Font/Inter/static/Inter_18pt-Italic.ttf')}) format('truetype');
    }
    @font-face {
      font-family: 'Inter_18pt-Light';
      src: url(${require('../assets/Font/Inter/static/Inter_18pt-Light.ttf')}) format('truetype');
    }
    @font-face {
      font-family: 'Inter_18pt-LightItalic';
      src: url(${require('../assets/Font/Inter/static/Inter_18pt-LightItalic.ttf')}) format('truetype');
    }
    @font-face {
      font-family: 'Inter_18pt-Medium';
      src: url(${require('../assets/Font/Inter/static/Inter_18pt-Medium.ttf')}) format('truetype');
    }
    @font-face {
      font-family: 'Inter_18pt-MediumItalic';
      src: url(${require('../assets/Font/Inter/static/Inter_18pt-MediumItalic.ttf')}) format('truetype');
    }
    @font-face {
      font-family: 'Inter_18pt-Regular';
      src: url(${require('../assets/Font/Inter/static/Inter_18pt-Regular.ttf')}) format('truetype');
    }
    @font-face {
      font-family: 'Inter_18pt-SemiBold';
      src: url(${require('../assets/Font/Inter/static/Inter_18pt-SemiBold.ttf')}) format('truetype');
    }
    @font-face {
      font-family: 'Inter_18pt-SemiBoldItalic';
      src: url(${require('../assets/Font/Inter/static/Inter_18pt-SemiBoldItalic.ttf')}) format('truetype');
    }
    @font-face {
      font-family: 'Inter_18pt-Thin';
      src: url(${require('../assets/Font/Inter/static/Inter_18pt-Thin.ttf')}) format('truetype');
    }
    @font-face {
      font-family: 'Inter_18pt-ThinItalic';
      src: url(${require('../assets/Font/Inter/static/Inter_18pt-ThinItalic.ttf')}) format('truetype');
    }

`;

export default {iconsStyle};
