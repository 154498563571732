import {gql} from '@apollo/client';

export const CreateContactOutreachActivity = gql`
  mutation addOrUpdateContactOutreachActivity(
    $data: AddOrUpdateContactOutreachActivityInput!
  ) {
    addOrUpdateContactOutreachActivity(params: $data) {
      id
      outreachStepId
    }
  }
`;

export const GetContactOutreachActivities = gql`
    query GetContactOutreachActivities(
    $whereCondition:  contactOutreachActivity_bool_exp!
    $limit: Int!
    $offset: Int!
  ) {
    ContactOutreachActivities(
      where: $whereCondition
      limit: $limit
      offset: $offset
      order_by: {createdOn: desc}
    ) {
      id
      note
      statusId
      typeId
      resourceType
      resourceId
      communicationModeId
      outcomeId
      note
      outreachDateTime
      careProgramTypeId
      contactId
      createdBy
      reference
    }
     aggregateContactOutreachActivity(
      where: $whereCondition
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const getContactOutreachActivityCount = gql`
  query ($contactId: uuid, $careProgramTypeId: uuid) {
    aggregateContactOutreachActivity(
      where: {
        contactId: {_eq: $contactId}
        careProgramTypeId: {_eq: $careProgramTypeId}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;


