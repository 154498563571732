import {useLazyQuery} from '@apollo/client';
import {Pressable, Spinner, Text, Tooltip, View, VStack} from 'native-base';
import React, {useCallback, useEffect, useState, useMemo} from 'react';
import {StyleSheet} from 'react-native';
import EditSvg from '../../../../assets/Icons/EditSvg';
import {DATE_FORMATS, RIGHT_SIDE_CONTAINER_CODE} from '../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import StickyNotesApis, {
  CreateStickyNotes,
  UpdateStickyNotes,
} from '../../../../services/StickyNotes/StickyNotesApis';
import {Colors} from '../../../../styles';
import {getAccountUUID} from '../../../../utils/commonUtils';
import {getDateAndTimeOnLocalFormate} from '../../../../utils/DateUtils';
import {isWeb} from '../../../../utils/platformCheckUtils';
import {AddNoteView} from '../../../common/AddNoteView';
import {AddActionView} from '../../../common/Buttons/AddActionView';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import {NoContactNotesSvg} from '../../../common/Svg';
import CalenderIcon from '../../../common/Svg/CalenderIcon';
import TaskIconOther from '../../../common/Svg/TaskIconOther';
import {styles} from './OtherDetailsStyles';
import { EventBus } from '../../../../utils/EventBus';
import { CUSTOM_COMMON_EVENT_CODES } from '../../../../constants/WebSocketConst';

const StickyNotes = (props: any) => {
  const {contactId, contactUuid} = props;
  const accountUuid = getAccountUUID();
  const [getStickyNote] = useLazyQuery(StickyNotesApis.GetStickyNotes);
  const [stateData, setStateData] = useState({
    selectedView: '',
    selectedData: {} as any,
    noteData: {} as any,
    loading: false,
  });

  const addStickyNotes = async (note: string) => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const noteResponseData = await CreateStickyNotes(
      contactId,
      contactUuid,
      note
    ).catch((err) => {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    });
    if (noteResponseData?.data?.contactInfo?.stickyNotes?.length) {
      getStickyNotes(true);
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
  };

  const updateStickyNotes = async (note: string) => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const noteResponseData = await UpdateStickyNotes(
      contactId,
      contactUuid,
      note,
      stateData.noteData.noteUuid
    ).catch((err) => {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    });
    if (noteResponseData?.data?.contactInfo) {
      getStickyNotes(true);
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
  };

  const onActionPerformed = (actionCode: string, actionData?: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.AddNoteAction:
        addStickyNotes(actionData.content);
        setStateData((prev) => {
          return {
            ...prev,
            selectedView: '',
            selectedData: '',
          };
        });
        break;
      case COMMON_ACTION_CODES.EditNoteAction:
        updateStickyNotes(actionData.content);
        setStateData((prev) => {
          return {
            ...prev,
            selectedView: '',
            selectedData: '',
          };
        });
        break;
      case COMMON_ACTION_CODES.ADD_NOTE:
        setStateData((prev) => {
          return {
            ...prev,
            selectedView: RIGHT_SIDE_CONTAINER_CODE.CONTACT_ADD_NOTE,
            selectedData: stateData.noteData,
          };
        });
        break;
      default:
        setStateData((prev) => {
          return {
            ...prev,
            selectedView: '',
            selectedData: '',
          };
        });
        break;
    }
  };

  const getStickyNotes = async (sendBroadcast?: boolean) => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const response = await getStickyNote({
      variables: {
        contactUuid: contactUuid,
        accountUuid: accountUuid,
      },
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }).catch((err) => {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    });
    if (response?.data?.stickyNotes?.length) {
      const note = response.data?.stickyNotes[0];
      if (sendBroadcast) {
        const eventBus = EventBus.getEventBusInstance();
        eventBus.broadcastEvent(
          CUSTOM_COMMON_EVENT_CODES.UPDATE_STICKY_NOTES,
          {
            noteData: note,
          }
        );
      }
      setStateData((prev) => {
        return {
          ...prev,
          noteData: note,
          loading: false,
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          noteData: {},
          loading: false,
        };
      });
    }
  };
  useEffect(() => {
    getStickyNotes();
  }, [contactId]);

  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(
      CUSTOM_COMMON_EVENT_CODES.UPDATE_STICKY_NOTES,
      onUpdateStickyNotes
    );
    return () => {
      eventBus.removeEventListener(onUpdateStickyNotes);
    };
  }, []);

  const onUpdateStickyNotes = useCallback(
    (data: any) => {
      const note = data?.noteData;
      setStateData((prev) => {
        return {
          ...prev,
          noteData: note,
        };
      });
    },
    [contactId]
  );

  const renderContent = () => {
    return (
      <>
        {stateData.loading ? (
          <Spinner size="lg" style={styles.spinnerStyle} />
        ) : (
          <></>
        )}
        <View
          style={stickyNoteStyles.noteContainer}
          borderColor={Colors.secondary['200']}
          backgroundColor={'#FEFCF6'}
        >
          {stateData?.noteData?.noteUuid ? (
            <VStack flex={1} justifyContent={'center'}>
              <Text size={'mdMedium'} color={Colors.FoldPixel.GRAY400}>
                {stateData?.noteData?.contactNote?.content}
              </Text>
              <View style={stickyNoteStyles.noteInfoContainer}>
                <Text style={stickyNoteStyles.noteInfoText} size={'smMedium'}>
                  {stateData?.noteData?.updatedByUser?.name} • {' '}
                </Text>
                <Text style={stickyNoteStyles.noteInfoText} size={'smMedium'}>
                  {getDateAndTimeOnLocalFormate(
                    stateData?.noteData?.updatedAt,
                    DATE_FORMATS.STICKY_NOTE_DATE,
                  )}
                </Text>
              </View>
            </VStack>
          ) : (
            <Pressable onPress={() => {
              onActionPerformed(COMMON_ACTION_CODES.ADD_NOTE);
            }}>
              <View>
                <DisplayText textLocalId='emptyStickNotes' extraStyles={{color: Colors.Custom.Gray500}} size='smNormal' />
              </View>
            </Pressable>
          )}
        </View>
      </>
    );
  };
  return (
    <>
      <View backgroundColor={Colors.Custom.Gray50}>
        <View style={stickyNoteStyles.headerContainer}>
          <View style={stickyNoteStyles.headerContent}>
            <View
              style={stickyNoteStyles.flexRow}
            >
              <View style={stickyNoteStyles.iconContainer}>
                <TaskIconOther />
              </View>
              <DisplayText size={'lgMedium'} textLocalId={'stickyNotes'} extraStyles={{color: Colors.FoldPixel.GRAY300 || ''}} />
            </View>
            {true && (
              <Tooltip label={stateData.noteData?.noteUuid ? 'Update Sticky Notes' : 'Add Sticky Notes'} placement={'top'}>
                <Pressable
                  style={stickyNoteStyles.addButton}
                  onPress={() => {
                    onActionPerformed(COMMON_ACTION_CODES.ADD_NOTE);
                  }}
                >
                  {stateData.noteData?.noteUuid ? (
                    <EditSvg />
                  ) : (
                    <AddActionView />
                  )}
                </Pressable>
              </Tooltip>
            )}
          </View>
        </View>
        {renderContent()}
      </View>
      {stateData.selectedView === RIGHT_SIDE_CONTAINER_CODE.CONTACT_ADD_NOTE ? (
        <AddNoteView
          isStickyNote={true}
          titleText={props?.titleText}
          selectedData={stateData.selectedData}
          onFormActionPerformed={(actionCode: string, actionData: any) => {
            onActionPerformed(actionCode, actionData);
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

const stickyNoteStyles = StyleSheet.create({
  headerContainer: {
    padding: 24,
  },
  headerContent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  iconContainer: {
    marginRight: 10,
  },
  addButton: {
    width: 36,
    height: 36,
  },
  noteContainer: {
    // backgroundColor: '#FEFCF6',
    marginHorizontal: 24,
    borderRadius: 8,
    boxShadow: `0px 1px 2px rgba(16, 24, 40, 0.06)`,
    paddingHorizontal: 16,
    paddingVertical: 20,
    borderWidth: 1,
  },
  noteInfoContainer: {
    flexDirection: 'row',
    marginTop: 10,
    alignItems: 'center',
  },
  noteInfoText: {
    color: Colors.Custom.Gray500,
  },
  emptyNoteText: {
    color: Colors.Custom.Gray500,
  },
  flexRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 4,
  }
});

export default React.memo(StickyNotes);
