import React, {useEffect, useMemo} from 'react';
import {useContactCareProgramContext} from '../../ContactCareProgram.context';
import {AssessmentAction, useAssessment} from './hooks/useAssessment';
import {FHForm} from '../../../../Forms/FHFormio';
import {ScrollView} from 'react-native';
import {Divider, Skeleton} from 'antd';
import {useIntl} from 'react-intl';
import WriteInfoBackBanner from '../../../../../PersonOmniView/MiddleContainer/CareTimeline/components/WriteInfoBackBanner';
import {Text} from 'native-base';
import Stack from '../../../../../common/LayoutComponents/Stack';
import {Colors} from '../../../../../../styles';
import {getDiffInDays} from '../../../../../../utils/DateUtils';

const AssessmentView: React.FC = () => {
  const {
    status,
    formComponents,
    formResponse,
    intakeOptions,
    disableForm,
    isPreviousFormInstanceExists,
    prevFormInstanceUpdatedOn,
    handleOnChange,
    handleAcceptPreviousFormInstanceAction,
  } = useAssessment();
  const intl = useIntl();

  const daysDiff = prevFormInstanceUpdatedOn ? getDiffInDays(new Date(), new Date(prevFormInstanceUpdatedOn)) : undefined;
  
  return (
    <ScrollView style={{marginHorizontal: 24, marginBottom: 86}}>
      {(() => {
        if (status === 'loading') {
          return (
            <div className="flex flex-col">
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </div>
          );
        }

        if (status === 'loaded') {
          return (
            <div className="content-view">
              {isPreviousFormInstanceExists && (
                <WriteInfoBackBanner
                  message={
                    prevFormInstanceUpdatedOn
                      ? `This is a recent version of this screening, dated ${prevFormInstanceUpdatedOn} ${
                          daysDiff ? `(${daysDiff} days old)` : ''
                        }`
                      : 'This is recent version of the screening'
                  }
                >
                  <Stack
                    direction="row"
                    space={2}
                    style={{alignItems: 'center'}}
                  >
                    <Text
                      style={{color: Colors.FoldPixel.INFO_COLOR}}
                      onPress={() =>
                        handleAcceptPreviousFormInstanceAction(
                          AssessmentAction.FORM_RESPONSE_DECLINED
                        )
                      }
                    >
                      Create new
                    </Text>
                    <Divider type="vertical" />
                    <Text
                      style={{color: Colors.FoldPixel.INFO_COLOR}}
                      onPress={() =>
                        handleAcceptPreviousFormInstanceAction(
                          AssessmentAction.FORM_RESPONSE_ACCEPTED
                        )
                      }
                    >
                      Accept
                    </Text>
                  </Stack>
                </WriteInfoBackBanner>
              )}
              <FHForm
                canUpdateOnChangeRef
                isPreviewMode={false}
                optionData={intakeOptions}
                components={formComponents}
                submittedResponse={formResponse}
                readOnly={disableForm}
                onChange={handleOnChange}
              />
            </div>
          );
        }

        if (status === 'empty') {
          return (
            <div>
              <span>{intl.formatMessage({id: 'noDataMsg'})}</span>
            </div>
          );
        }

        if (status === 'error') {
          return (
            <div>
              <span>{intl.formatMessage({id: 'apiErrorMsg'})}</span>
            </div>
          );
        }

        return <></>;
      })()}
    </ScrollView>
  );
};

export default AssessmentView;
