import { Select as AntSelect, Spin } from 'antd';
import { debounce } from 'lodash';
import { HStack, Skeleton, VStack, View , Text} from 'native-base';
import { useEffect, useState } from 'react';
import { ISearchFieldProps } from '../../../Interfaces';
import { FlowType } from '../../../context/WorkflowContext';
import { getAccountUUID } from '../../../utils/commonUtils';
import PatientCareJourneyDetail from '../../RightSideContainer/Journeys/JourneysOfCare/PatientCareJourneyDetail/PatientCareJourneyDetail';
import { getWorkflowList } from '../../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import { CaretDownOutlined } from '@ant-design/icons';
import { Colors } from '../../../styles/Colors';
import { useSearchParams } from 'react-router-dom';
import { isAccountConfigEnabled } from '../../../utils/configUtils';
import { CONFIG_CODES } from '../../../constants/AccountConfigConst';
export interface IWorkflowSearchFieldProps extends ISearchFieldProps {
  flowType: FlowType;
  triggerCondition?:string;
  isActive?:boolean;
  checkAllowed?:boolean;
  filteredLocationList?: string[];
}

const WorkflowSearch = (props: IWorkflowSearchFieldProps) => {
  const { isShowError, onChange, filteredLocationList } = props;
  const [value, setValue] = useState(props.value);
  const [loading, setLoading] = useState(true);
  const [activityData, setActivityData] = useState<any[]>([]);
  const [originalData, setOriginalData] = useState<any[]>([]);
  const accountUuid = getAccountUUID()
  const [searchParams, setSearchParams] = useSearchParams();
  const [flowType] = useState<any>(searchParams?.get('flowType') || FlowType.careJourney);
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED)
  const isAllowedSelect = (item: {
    flowTypeMap: {flowType: string}[];
    isEnabled: boolean;
  }) => {
    const allowedFlowType = (item?.flowTypeMap || []).some(
      (data: {flowType: string}) => {
        return data.flowType === flowType ||  data.flowType  === FlowType.patients;
      }
    );
    if (!allowedFlowType) {
      return {reason: 'Not supported type.', isValid: false};
    }
    if (!item?.isEnabled) {
      return {reason: 'Not active', isValid: false};
    }
    return {reason: '', isValid: true};
  };


  const createLocationQueryObject = (locationList: string[]) => {
    if (locationList.length > 0) {
      const result = locationList.map(locationId => ({
        workflowLocations: {
          locationId: {
            _in: [locationId]
          }
        }
      }));
      return result
    }
    return []
  }

  useEffect(() => {
    setLoading(true);
    getWorkflowList(
      isMsoEnabled,
      createLocationQueryObject(filteredLocationList || []),
      '',
      props.flowType,
      accountUuid,
      (dataList: any) => {
        dataList = dataList || [];
        const finalList = dataList.map((data: any) => {
          return {
            id: data.id,
            workflowMasterId: data.workflowMasterId,
            name: data.name,
            isEnabled: data.isEnabled,
            flowTypeMap: data.flowTypeMap || [],
            selectionInfo: isAllowedSelect(data)
          };
        });
        finalList.sort((first:any, second:any)=>{
          return first?.selectionInfo?.isValid === second?.selectionInfo?.isValid ? 0 : (first?.selectionInfo?.isValid ?  -1 : 1)
        })
        setOriginalData(finalList);
        setActivityData(finalList);
        setLoading(false);
      },
      (error: any) => {

        setLoading(false);
        setOriginalData([]);
      },false, [], props.triggerCondition, props.isActive
    );
  }, []);

  const onSearch = (searchString: string) => {
    if (searchString.length > 0) {
      const filteredData = originalData.filter((item) => {
        return item.name.toLowerCase().includes(searchString.toLowerCase());
      });
      setActivityData([...filteredData]);
    } else {
      setActivityData([...originalData]);
    }
  };

  const getDataFromId = (id: any) => {
    const matchedData = activityData.filter((item) => {
      return item.id === id;
    });
    if (matchedData.length > 0) {
      return {
        id: matchedData[0].id,
        name: matchedData[0].name,
        workflowMasterId: matchedData[0].workflowMasterId,
      };
    }
  };

  const isFound = (activityData || []).some((data:any)=>{
    return data?.workflowMasterId == value?.workflowMasterId
  });

  if(!isFound && activityData?.length && value?.workflowMasterId){
    setValue(undefined)
  }
  const [helpText, setHelpText] = useState('')
  useEffect(()=>{
    const isFound = (activityData || []).some((data:any)=>{
      return data?.workflowMasterId == value?.workflowMasterId
    });
    if(props?.value?.workflowMasterId && !isFound && !helpText && activityData?.length){
      setHelpText((props?.value?.name || '') + ' automation is deleted. Please select other automation')
    } else if(isFound && helpText){
      setHelpText('')
    }
  },[props?.value?.workflowMasterId, isFound, activityData?.length])



  return (
    <>
      <VStack width={'100%'}>
      {loading && (

        <View>
          <Skeleton rounded="md" />
        </View>
      )}
      {!loading && (
        <HStack alignItems={'center'} space={4}>
        <View >
          <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400}>
            Active Micro Automations
          </Text>
        </View>

        <AntSelect
          suffixIcon={
            <CaretDownOutlined
              style={{
                color: Colors.FoldPixel.GRAY300
              }}
            />
          }
          size="large"
          showSearch
          allowClear
          filterOption={false}
          value={value ? value.id : undefined}
          onSearch={debounce(onSearch, 500)}
          onChange={(value: any[], data: any) => {
            if (data && data.value) {
              setValue(getDataFromId(data?.value))
              onChange(getDataFromId(data.value));
            } else {
              setValue(undefined)
              onChange(undefined);
            }
          }}
          placeholder="Search Automation"
          loading={loading}
          notFoundContent={loading && <Spin size="small" />}
          style={{ height: '40px', width:  '100%' }}
          className={isShowError && !value ? 'field-error' : ''}
        >
          {activityData.map((item, index) => {
            const { isValid, reason } = item?.selectionInfo;
            return (
              <AntSelect.Option disabled={ props.checkAllowed && !isValid } tool id key={`${item.id}_${index}`} value={item.id}>
                {item.name} {reason ? ' ( '+reason + ' )' : ''}
              </AntSelect.Option>
            );
          })}
        </AntSelect>
        </HStack>
      )}
      {helpText}
      </VStack>
{  value?.workflowMasterId && isFound &&
  <HStack height={400} width={'100%'} marginTop={30}>
  <PatientCareJourneyDetail
          key={value.workflowMasterId}
          title={'Automation details'}
          visible={
             value.workflowMasterId
          }
          isIgnorePopup={true}
          flowType={FlowType.MICRO_AUTOMATION}
          patientCareJourney={{
            id: '',
            careJourneyId: value.workflowMasterId,
            careJourneyVersionId: value.workflowMasterId,
            workflowConfig: null,
            careJourney: {
              title: ' ', // pass white space
              duration: 0,
              description: ' ', // pass white space
              durationUnitId: ''
            },
          }}
          showJourneyTab={true}
          showActivityTab={false}
          onClose={() => {
          }}
        /> </HStack>
      }
    </>
  );
};

export default WorkflowSearch;
