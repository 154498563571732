import React from 'react';
import {useLazyQuery} from '@apollo/client';
import {Select, Spin} from 'antd';
import {debounce} from 'lodash';
import {Box, View, Text, useToast} from 'native-base';
import {useCallback, useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Colors} from '../../../../../../styles';
import {ILabelTag} from '../../interfaces';
import {DisplayText} from '../../../../../common/DisplayText/DisplayText';
import {ITagViewProps} from '../../interfaces';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import TagQueries from '../../../../../../services/Tags/TagQueries';
import {formatGetCategoryId} from '../../../Tags/Helper/formatTagsData';
import CustomUserTag from './CustomUserTag';
import { IMlov } from '../../../../../../Interfaces';
import { TAGGABLE_TYPE } from '../../../../../common/FilterView/FilterConst';
import { ToastType, showToast } from '../../../../../../utils/commonViewUtils';
import { testID, TestIdentifiers } from '../../../../../../testUtils';
import { placeholder } from 'jodit/esm/plugins/placeholder/placeholder';
const {Option} = Select;

const TagSearchAndSelect = (props: ITagViewProps) => {
  const {handleChange, handleDeselect, selectedTagLabels, onClear} = props;
  const mlovData = useContext(CommonDataContext);
  const intl = useIntl();

  const [componentState, setComponentState] = useState<{
    tagLabelsList: ILabelTag[];
    labelsLoading: boolean;
    searchString: string;
  }>({
    tagLabelsList: [],
    searchString: '',
    labelsLoading: false,
  });

  const getSearchtagLabel = useCallback(
    debounce((value) => {
      onSearchtagLabel(value);
    }, 500),
    []
  );

  const selectedCategory: IMlov = formatGetCategoryId(
    mlovData.MLOV.LabelType,
    TAGGABLE_TYPE.CONTACT
  );

  const [getPatientTags] = useLazyQuery(TagQueries.GetLabelsByFilter, {
    variables: {
      category: selectedCategory,
      searchString: `%%`,
      limit: 10,
      offset: 0,
    },
    fetchPolicy: 'no-cache',
  });

  const toast = useToast();

  const tagRender = (tagProps: { value: number }): JSX.Element => {
    const onPreventMouseDown = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const selectedTags = selectedTagLabels.selectedTags?.filter((item: ILabelTag) => {
      return item.id === tagProps.value;
    });
    return (
      <CustomUserTag
        label={selectedTags[0].title}
        onPreventMouseDown={onPreventMouseDown}
        value={selectedTags[0].id}
        color={selectedTags[0].color}
        backgroundColor={selectedTags[0].color + '40'}
        handleDeselect={handleDeselect}
      />
    );
  };

  const getLabelOptions = (): JSX.Element[] | JSX.Element => {
    if (componentState.tagLabelsList.length > 0) {
      return (componentState.tagLabelsList || []).map((label: ILabelTag) => {
        return (
          <Option key={label.id} value={label.id} id={label.title}>
            {label.title}
          </Option>
        );
      });
    } else {
      return (
        <Option key={'noData'} value={'noData'}>
          {intl.formatMessage({id: 'NoDataSelect'})}
        </Option>
      );
    }
  };

  const onSearchtagLabel = async (searchString: string): Promise<void> => {
    try {
      const tagLabelParams = {
        limit: 10,
        offset: 0,
        searchString: `%${searchString?.trim()}%`,
        category: selectedCategory,
      };
      const apiResponse = await getPatientTags({variables: tagLabelParams});
      const tagLabels = apiResponse?.data?.labels || [];
      setComponentState((prev) => {
        return {
          ...prev,
          labelsLoading: false,
          tagLabelsList: tagLabels,
        };
      });
    } catch (error) {
      showToast(toast, intl.formatMessage({id: 'errorOnFetchTasks'}), ToastType.error);
      setComponentState((prev) => ({
        ...prev,
        labelsLoading: false,
        tagLabelsList: []
      }));
    }
  };

  const getTagsData = async (): Promise<void> => {
    try {
      setComponentState((prev) => {
        return {
          ...prev,
          labelsLoading: true,
        };
      });
      const apiResponse = await getPatientTags();
      const tagLabels = apiResponse?.data?.labels || [];

      setComponentState((prev) => {
        return {
          ...prev,
          labelsLoading: false,
          tagLabelsList: tagLabels,
        };
      });
    } catch (error) {
      showToast(toast, intl.formatMessage({id: 'errorOnFetchTasks'}), ToastType.error);
      setComponentState((prev) => ({
        ...prev,
        labelsLoading: false,
        tagLabelsList: []
      }));
    }
  };

  useEffect(() => {
    getTagsData();
  }, []);

  return (
    <>
      <View>
        <View style={{marginBottom: 12}}>
          <DisplayText
            size={'smRegular'}
            extraStyles={{
              color: Colors.Custom.Gray500,
              fontWeight: 500,
              fontSize: 14,
            }}
            textLocalId={'Tags'}
          />
        </View>
        <View style={{marginBottom: 12}}>
          <Select
            tagRender={tagRender}
            value={
              selectedTagLabels.tag?.length
                ? selectedTagLabels.tag
                : []
            }
            mode="multiple"
            allowClear
            placeholder={'Search Tags'}
            {...testID(TestIdentifiers.tags)}
            showSearch={true}
            searchValue={componentState.searchString}
            filterOption={false}
            onClear={onClear}
            onSelect={(value: number | string) => {
              if (value !== 'noData') {
                const tags = componentState.tagLabelsList?.filter(
                  (item: ILabelTag) => {
                    return item.id == value;
                  }
                );
                handleChange(tags);
                setComponentState((prev) => {
                  return {
                    ...prev,
                    searchString: '',
                  };
                });
              }           
            }}
            onDeselect={(value: number) => {
              handleDeselect(value);
            }}
            onSearch={(value) => {
              setComponentState((prev) => {
                return {
                  ...prev,
                  searchString: value.trimStart(),
                  labelsLoading: true,
                };
              });
              getSearchtagLabel(value);
            }}
            loading={componentState.labelsLoading}
            notFoundContent={
              componentState.labelsLoading ? <Spin size="large" /> : <></>
            }
            style={{width: '100%'}}
            onBlur={() => {
              setComponentState((prev) => {
                return {
                  ...prev,
                  searchString: '',
                };
              });
            }}
          >
            {getLabelOptions()}
          </Select>
        </View>
      </View>
    </>
  );
};

export default TagSearchAndSelect;
