import {StyleSheet} from 'react-native';
import { Colors } from '../../../../../../../styles/Colors';

export const antStyle: Record<string, React.CSSProperties> = {
  input: {
    borderWidth: 1,
    borderColor: Colors.Custom.Gray200,
    borderRadius: 8,
    height: 32,
    background: Colors.Custom.White,
    color: Colors.Custom.Gray500,
  } as React.CSSProperties,
  textArea: {
    height: 60,
    borderRadius: 8,
    borderColor: Colors.Custom.Gray200,
    background: Colors.Custom.White,
    color: Colors.Custom.Gray500,
    padding: 12,
    resize: 'none' as const,
  },
  radioGroup: {
    display: 'flex',
    gap: 32,
  },
  formItem: {
    marginBottom: 8,
    flex: 1,
    padding: 0
  },
  formMultiItem: {
    marginBottom: 8,
    flex: 0.3,
  },
  datePicker: {
    width: '100%',
  },
  inputGroup: {
    display: 'flex',
    height: 32,
    alignItems: 'center'
  },
  inputNumber: {
    width: '60%',
    color: Colors.Custom.Gray500,
  },
  daysSelect: {
    width: '40%',
    alignItems: 'center',
    color: Colors.Custom.Gray500,
  },
  selectOption: {
    color: Colors.Custom.Gray500,
  },
};

export const styles = StyleSheet.create({
  sectionTitle: {
    fontSize: 14,
    color: Colors.Custom.Gray900,
    fontWeight: '500',
    marginBottom: 12,
  },
  scrollView: {
    marginHorizontal: 24,
    marginBottom: 86,
  },
  contentView: {
    flex: 0.6,
  },
  threeColumnGrid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    flex: 1,
  },
  label: {
    color: Colors.FoldPixel.GRAY250,
  },
  radioLabel: {
    fontSize: 14,
    color: Colors.Custom.GRAY350,
    marginLeft: 8,
  },
});
