import { useLazyQuery, useQuery } from '@apollo/client';
import { Select as AntSelect, DatePicker, InputNumber, Tooltip, Checkbox, TreeSelect, Tag, Collapse, Input as AntInput,  Drawer, Divider } from 'antd';
import { debounce } from 'lodash';
import {
  Button,
  CheckIcon,
  FormControl,
  HStack,
  Icon,
  IconButton,
  Input, Pressable, Select, Image,
  Stack,
  Text,
  VStack,
  View
} from 'native-base';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Dimensions } from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { useSearchParams } from 'react-router-dom';
import {
  BLOOD_PRESSURE_LOINC,
  DATE_FORMATS,MLOV_CATEGORY,
  UNITY_AI
} from '../../../../../constants';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../constants/Configs';
import { FHIR_RESOURCE } from '../../../../../constants/FhirConstant';
import { APPOINTMENT_STATUS_FOR_AUTOMATON, CARE_PLAN_STATUS_CODES, CARE_PROGRAM_TYPES} from '../../../../../constants/MlovConst';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import {
  FlowType,
  IWorkflowContextData,
  WorkflowContext
} from '../../../../../context/WorkflowContext';
import { ComponentsQueries, FormsQueries, TaskQueries } from '../../../../../services';
import UserPracticeLocationQueries from '../../../../../services/Location/UserPracticeLocationQueries';
import TagQueries from '../../../../../services/Tags/TagQueries';
import { Colors } from '../../../../../styles/Colors';
import { getMomentObj } from '../../../../../utils/DateUtils';
import { getLabelForKey, getPossibleValuesByOperationName, getResourceAbilities } from '../../../../../utils/capabilityUtils';
import { getUserUUID } from '../../../../../utils/commonUtils';
import {
  getCareStudioMlovListByCategory,
  getMlovListByCategory,
  getMlovListFromCategory,
  getMlovValueFromId
} from '../../../../../utils/mlovUtils';
import { AnalyteStatusSelect } from '../../../../common/AnalyteStatusSelect/AnalyteStatusSelect';
import AppointmentTypeSearch from '../../../../common/AppointmentTypeSearch/AppointmentTypeSearch';
import MultiSelectAppointmentTypeSearch from '../../../../common/AppointmentTypeSearch/MultiSelectAppointmentTypeSearch';
import { ParticipantType } from '../../../../common/CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import { MultiUserSearch } from '../../../../common/CalendarWidget/UserAutoComplete/MultiUserSearch';
import UserAutoComplete from '../../../../common/CalendarWidget/UserAutoComplete/UserAutoComplete';
import ContactTypeSearch from '../../../../common/ContactTypeSearch/ContactTypeSearch';
import ContentSearch, {
  ContentType
} from '../../../../common/ContentSearch/ContentSearch';
import CustomTabsView from '../../../../common/CustomTabsView/CustomTabsView';
import { DisplayText } from '../../../../common/DisplayText/DisplayText';
import FormSearch from '../../../../common/FormSearch/FormSearch';
import IPPhoneSearchList from '../../../../common/IPPhoneSearchList/IPPhoneSearchList';
import ImmunizationSearch from '../../../../common/ImmunizationSearch/ImmunizationSearch';
import InstructionField from '../../../../common/InstructionField/InstructionField';
import EmployerSearch from '../../../../common/LabSearch/EmployerSearch';
import LabSearch from '../../../../common/LabSearch/LabSearch';
import MedicationSearch from '../../../../common/MedicationSearch/MedicationSearch';
import { ModalActionSelect } from '../../../../common/ModalActionCommonComponent/ModalActionSelect';
import MultipleFormSearch from '../../../../common/MultipleFormSearch/MultipleFormSearch';
import NutritionSearch from '../../../../common/NutritionSearch/NutritionSearch';
import { OrderTaskFields } from '../../../../common/OrderTask/OrderTaskFields';
import PatientSearchAndSelect from '../../../../common/PatientSearch/PatientSearchAndSelect';
import PhysicalActivitySearch from '../../../../common/PhysicalActivitySearch/PhysicalActivitySearch';
import { RichTextEditor, TEXT_ONLY_MODULES } from '../../../../common/RichTextEditor/RichTextEditor';
import { TagdropdownSelect } from '../../../../common/TagdropdownSelect';
import OptedOutJourneyTaskField from '../../../../common/Tasks/TaskPool/OptedOutJourneyTaskField';
import { PrescriptionOrderTaskField } from '../../../../common/Tasks/TaskPool/PrescriptionOrderTaskField';
import TaskLabelSearch from '../../../../common/Tasks/TaskPool/TaskLabelSearch';
import TaskPoolSearch from '../../../../common/Tasks/TaskPool/TaskPoolSearch';
import NotificationTemplateSearch from '../../../../common/TemplateSearch/NotificationTemplateSearch';
import SmsTemplateSearch from '../../../../common/TemplateSearch/SmsTemplateSearch';
import TemplateSearch from '../../../../common/TemplateSearch/TemplateSearch';
import UploadButton from '../../../../common/Upload/Upload';
import VirtualPhoneListSearch from '../../../../common/VirtualPhoneListSearch/VirtualPhoneListSearch';
import VirtualNumberSearch from '../../../../common/VirtualPhoneNumberSearch/VirtualPhoneNumberSearch';
import VitalSearch from '../../../../common/VitalSearch/VitalSearch';
import WebinarSearch from '../../../../common/WebinarSearch/WebinarSearch';
import WorkflowSearch from '../../../../common/WorkflowSearch/WorkflowSearch';
import AccountLocationSearchAndSelect from '../../../AccountSettings/AppointmentTypes/AccountLocationSearchAndSelect';
import { formatGetCategoryId, formatGetTags } from '../../../Contacts/Tags/Helper/formatTagsData';
import EmployerDropdownList from '../../../ContentManagement/CampaignManagement/CampaignSegmentForm/EmployerDropdownList';
import { CapabilityResource } from '../../../Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import { generateUniqueKey } from '../../../Forms/FormBuilderWidget/RiskScoreConfiguration/RiskScoreHelper';
import { GENERAL_RISK_SCORE_GROUP_ID, GENERAL_RISK_SCORE_GROUP_NAME } from '../../../Forms/FormBuilderWidget/RiskScoreConfiguration/SectionWiseRiskScoreView';
import { CareJourneySearch } from '../../../Journeys/JourneysOfCare/PatientCareJourney/CareJourneySearch';
import { ActivityUserInputField } from '../../UserInputFields/ActivityUserInputField';
import { AssignToUserRoleOrTaskPoolField } from '../../UserInputFields/AssignToUserRoleOrTaskPoolField';
import { FrequencyUserInputField } from '../../UserInputFields/FrequencyUserInputField';
import { TaskPoolSearchField } from '../../UserInputFields/TaskPoolSearchField';
import ValueAndUnitField, { ValueAndUnitFieldForEmailFrequency } from '../../UserInputFields/ValueAndUnitField/ValueAndUnitField';
import { IReminderData } from '../../Workflow/AddOrUpdateWorkflow/IWorkflow';
import {
  DURATION_END_OF_CARE_PLAN_UNIT,
  TRIGGER_START_OF_CARE_PLAN_UNIT,
  TRIGGER_SUFFIX_TEXT
} from './FlowNodeHelper';
import {
  IComparisonOperator,
  IComparisonOperatorElement,
  ICustomPromptElement,
  IInputElement,
  INodeInputField,
  IOutreachNumberInputElement
} from './FlowNodeInterface';
import ShowFilterTotalCountInputField from './ShowFilterTotalCountInputField';
import { IVirtualNumberAssignee, IVirtualPhoneNumber } from './interface';
import { VitalUnit } from '../../../../../utils/VitalUtils';
import { convertCmToFeetInchesObject, convertVitalValues, feetAndInchesToInches } from '../../../../../utils/vitalUnitConversions';
import JourneyAutomationTaskAssignee from '../../../../common/JourneyAutomationTaskAssignee';
import { CaretDownOutlined, CloseOutlined } from '@ant-design/icons';
import { COMPARISON_OPERATOR, USER_SEARCH_TYPE } from '../../../constants';
import { CONDITION_FOR_SEND_EMAIL_TO_FIELD, NODE_TYPE } from '../FlowComponentConstants';
import { CustomFieldFilter } from '../../../Contacts/CustomField/CustomFieldFilter';
import { ICustomAttributesFilters } from '../../../Contacts/CustomField/interface';
import { TaskPoolAndUserSearch } from '../../../../common/Tasks/TaskPool/TaskPoolAndUserSearch';
const { Option } = AntSelect;
const { Panel } = Collapse;
const { TextArea } = AntInput;
//import './NodeInputFieldStyles.css';
import './../../Workflow/Styles.css'
import {cssPropertiesAntD, unityAiCustomPrompt} from './styles'
import {trimSpace} from '../../../Contacts/ContactsUtils';
import { GroupSearch } from '../../../../common/GroupSearch';
import { POP_GROUP_CONTACT_TYPES } from '../../../../../constants/RuleEngineConst';
import { testID } from '../../../../../testUtils';
import OnCallScheduleSearch from '../../../OnCallSchedule/OnCallScheduleSearch';
import { CONFIG_CODES } from '../../../../../constants/AccountConfigConst';
import { isAccountConfigEnabled } from '../../../../../utils/configUtils';
import MultiplePackageSearch from '../../../../common/MultiplePackageSearch/MultiplePackageSearch';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { ProviderDirectorySearch } from './ProviderDirectorySearch';
import { ISearchScheduleOnSelectProps } from '../../../OnCallSchedule/interface';
import useAddRoleAndPermission from '../../../UserAccess/CustomHook/useCreateRoleAndPermissions';
import TrashBinIcon2024 from '../../../../../assets/Icons/TrashBinIcon2024';
import { reactStyles, styles } from '../../workflowStyles';
import { GET_CARE_PLAN_FORMS } from '../../../../../services/Forms/FormsQueries';
import FHForm from '../../../Forms/FHFormio/FHForm/FHForm';
import EyeIcon from '../../../../../assets/Icons/EyeIcon';
import useFormOptions from '../../../ContactCareProgram/ContactCareProgramView/hooks/useFormOptions';
import { IMlov } from '../../../../../Interfaces';
import { InputStyle } from '../../../Contacts/Leads/LeadView/LeadViewStyle';

export const INPUT_FIELD_KEY = {
  isSkipEmail : 'isSkipEmail',
  sendVia: 'sendVia',
  communicationViaNode: 'communicationViaNode',
  educationContent: "educationContent",
  priority: "priority",
  patientFacingTitle: "patientFacingTitle",
  emailTemplate: "emailTemplate"
}

export const NODE_TYPES = {
  FILTER_FORM: 'FilterForm'
} as const;


export const INPUT_FIELD_TYPE = {
  INPUT: 'Input',
  GENERAL_PUSH_NOTIFICATION_TEMPLATE_SEARCH:
    'GENERAL_PUSH_NOTIFICATION_TEMPLATE_SEARCH',
  TAG_DROPDOWN_SELECT: 'TAG_DROPDOWN_SELECT',
  GENERAL_TEMPLATE_SEARCH: 'GENERAL_TEMPLATE_SEARCH',
  GENERAL_SMS_TEMPLATE_SEARCH: 'GENERAL_SMS_TEMPLATE_SEARCH',
  INPUT_NUMBER: 'InputNumber',
  SEND_EMAIL_TO: 'SEND_EMAIL_TO',
  TASK_TYPE: 'TASK_TYPE',
  SELECT: 'Select',
  DEBOUNCE_SELECT: 'DebounceSelect',
  TEXT: 'Text',
  RICH_TEXT_EDITOR: 'RICH_TEXT_EDITOR',
  NUMBER: 'Number',
  TIME_RANGE: 'TimeRange',
  AUTOMATION_SEARCH: 'AUTOMATION_SEARCH',
  DATE_PICKER: 'DatePicker',
  TIME_PICKER: 'TimePicker',
  TRIGGER: 'Trigger',
  TAGGER_TYPE_SEARCH: 'TAGGER_TYPE_SEARCH',
  MLOV_SEARCH: 'MLOV_SEARCH',
  MLOV_MULTIPLE_SEARCH: 'MLOV_MULTIPLE_SEARCH',
  RESOURCE_ABILITIES_SEARCH: 'RESOURCE_ABILITIES_SEARCH',
  TAG_RESOURCE_SEARCH: 'TAG_RESOURCE_SEARCH',
  DURATION: 'Duration',
  REMINDER: 'Reminder',
  TAG_SEARCH: 'TAG_SEARCH',
  FORM_OUTCOME_MATCH: 'FORM_OUTCOME_MATCH',
  MED_SEARCH: 'MED_SEARCH',
  NUTRITION_SEARCH: 'NUTRITION_SEARCH',
  VITAL_SEARCH: 'VITAL_SEARCH',
  EXERCISE_SEARCH: 'EXERCISE_SEARCH',
  LAB_TEST_SEARCH: 'LAB_TEST_SEARCH',
  SHOW_FILTER_TOTAL_COUNT: 'SHOW_FILTER_TOTAL_COUNT',
  CONTENT_SEARCH: 'CONTENT_SEARCH',
  EMPLOYER_SEARCH: 'EMPLOYER_SEARCH',
  MULTIPLE_EMPLOYER_SEARCH: 'MULTIPLE_EMPLOYER_SEARCH',
  PRACTICE_LOCATION_SEARCH: 'PRACTICE_LOCATION_SEARCH',
  FORM_SEARCH: 'FORM_SEARCH',
  FORMS_SEARCH: 'FORMS_SEARCH',
  PACKAGES_SEARCH: 'PACKAGES_SEARCH',
  APPOINTMENT_TYPE_SEARCH: 'APPOINTMENT_TYPE_SEARCH',
  APPOINTMENT_TYPE_SEARCH_ALL: 'APPOINTMENT_TYPE_SEARCH_ALL',
  IMMUNIZATION_SEARCH: 'IMMUNIZATION_SEARCH',

  CONDITIONAL: 'Conditional',
  RISK_SCORE_CONDITIONAL: 'RiskScoreConditional',
  USER_SEARCH: 'USER_SEARCH',
  USER_ROLE_SEARCH: 'USER_ROLE_SEARCH',
  VITAL_CONDITIONAL: 'VitalConditional',
  MOMENT_SEARCH: 'MOMENT_SEARCH',
  CONTACT_TYPE_SEARCH: 'CONTACT_TYPE_SEARCH',

  FORM_OUTPUT_DISPLAY_TEMPLATE: 'FormOutputDisplayTemplate',
  FREQUENCY: 'FREQUENCY',
  VALUE_AND_UNIT: 'VALUE_AND_UNIT',
  EMAIL_FREQUENCY: 'EMAIL_FREQUENCY',
  ACTIVITY_TARGET: 'ACTIVITY_TARGET',
  PHONE_NUMBER_BY_TYPE: 'PHONE_NUMBER_BY_TYPE',
  ORDER_TASK: 'ORDER_TASK',
  TASK_POOL_SEARCH: 'TASK_POOL_SEARCH',
  ASSIGNED_TO_USER_ROLE_OR_TASK_POOL: 'ASSIGNED_TO_USER_ROLE_OR_TASK_POOL',
  ASSIGNED_TO_CARE_JOURNEY_USER_ROLE_OR_TASK_POOL: 'ASSIGNED_TO_CARE_JOURNEY_USER_ROLE_OR_TASK_POOL',
  AUDIO_URL: 'AUDIO_URL',
  ANALYTE_RESULT_STATUS_MULTIPLE: 'ANALYTE_RESULT_STATUS_MULTIPLE',
  POP_GROUP_SEARCH: 'POP_GROUP_SEARCH',
  APPOINTMENT_SCHEDULE_DATE_RANGE: 'appointmentScheduleDateRange',
  USER_POOL_SEARCH_ALL: 'USER_POOL_SEARCH_ALL',
  TASK_LABEL_SEARCH_ALL: 'TASK_LABEL_SEARCH_ALL',
  ORDER_TASK_FIELD: 'ORDER_TASK_FIELD',
  ASSIGNED_TO_USER_ROLE_OR_TASK_POOL_OR_ASSIGNEE: 'ASSIGNED_TO_USER_ROLE_OR_TASK_POOL_OR_ASSIGNEE',
  VISIT_NOTE_TEMPLATE_SEARCH: 'VISIT_NOTE_TEMPLATE_SEARCH',
  VISIT_NOTE_OUTCOME_MATCH: 'VISIT_NOTE_OUTCOME_MATCH',
  APPOINTMENT_TYPE_SEARCH_IN_APPOINTMENT_FILTER: 'APPOINTMENT_TYPE_SEARCH_IN_APPOINTMENT_FILTER',
  CARE_JOURNEY_SEARCH_ALL: 'CARE_JOURNEY_SEARCH_ALL',
  PRACTICE_LOCATION_SEARCH_ALL: 'PRACTICE_LOCATION_SEARCH_ALL',
  GENERATE_TASK_OPTED_OUT_JOURNEY: 'GENERATE_TASK_OPTED_OUT_JOURNEY',
  GROUP_APPOINTMENT_WEBINAR: 'groupAppointmentWebinar',
  READONLY_INFO_FIELD: 'READONLY_INFO_FIELD',
  JOURNEY_AUTOMATION_ASSIGNEE_TYPE: 'CARE_JOURNEY_TASK_ASSIGNEE',
  APPOINTMENT_SERIES_REFERENCE: 'APPOINTMENT_SERIES_REFERENCE',
  PRIMARY_CARE_PROVIDER_MULTIPLE_SEARCH: 'PRIMARY_CARE_PROVIDER_MULTIPLE_SEARCH',
  CUSTOM_ATTRIBUTE_FILTER : 'CUSTOM_ATTRIBUTE_FILTER',
  BILLING_PRACTITIONER_MULTIPLE_SEARCH : 'BILLING_PRACTITIONER_MULTIPLE_SEARCH',
  UNITY_AI_CUSTOM_FIELD : 'UNITY_AI_CUSTOM_FIELD',
  UNITY_AI_CONTEXT: 'UNITY_AI_CONTEXT',
  UNITY_AI_OUTPUT: 'UNITY_AI_OUTPUT',
  UNITY_AI_FILTER: 'UNITY_AI_FILTER',
  UNITY_AI_FIELD : 'UNITY_AI_FIELD',
  UNITY_AI_CHECKBOX: 'UNITY_AI_CHECKBOX',
  MULTIPLE_FORM_REUSE_COMPONENTS: 'MULTIPLE_FORM_REUSE_COMPONENTS',
  PATIENT_REFERRAL_SEARCH: 'PATIENT_REFERRAL_SEARCH',
  CHECKBOX: 'CHECKBOX',
  CARE_PLAN_TEMPLATE_SEARCH: 'CARE_PLAN_TEMPLATE_SEARCH',
  CARE_PLAN_STATUS_CUSTOM_OBJECT: 'CARE_PLAN_STATUS_CUSTOM_OBJECT',
  CARE_PROGRAM_COMPLEXITY_TYPE: 'CARE_PROGRAM_COMPLEXITY_TYPE',
  CARE_PROGRAM_OUTREACH_NUMBER: 'CARE_PROGRAM_OUTREACH_NUMBER'
};

function NodeInputField(props: INodeInputField) {
  const workflowContext = useContext(WorkflowContext);
  const userInputField = props.userInputField;
  const elementProps = {
    isAddNew: userInputField.isAddNew,
    isShowError: props.isShowError,
    toValue: userInputField.toValue,
    userInputFieldList: props.userInputFieldList,
    fieldType: userInputField.fieldType,
    possibleValueList: userInputField.possibleValueList,
    value: userInputField.value,
    elementProperty: userInputField.elementProperty,
    nodeMetaData: workflowContext.nodeMetaData,
    displayName: userInputField.displayName,
    optional: userInputField.optional,
    onNewAdd: props.onNewAdd,
    possibleActivityMeasureParams: userInputField.possibleActivityMeasureParams,
    possiblePeriodList: userInputField.possiblePeriodList,
  };

  const [comparisonOperator, setComparisonOperator] = useState<
    IComparisonOperator | undefined
  >(
    userInputField.selectedComparisonOperator ||
    (userInputField.defaultOperator && (userInputField?.value != undefined || userInputField?.defaultValue )
      ? { displayName: '', value: userInputField.defaultOperator }
      : undefined)
  );
  const patientCapability = getResourceAbilities(
    CapabilityResource.patient,
    '',
    workflowContext?.locationIds?.[0]
  );


  useEffect(() => {
    const userInputFieldCopy = {
      ...userInputField,
      selectedComparisonOperator: comparisonOperator,
    };
    props.setUserInputField(userInputFieldCopy);
  }, [comparisonOperator?.value]);

  const [vitalUnitName, setVitalUnitName] = useState('');
  useEffect(() => {
    if (userInputField?.value?.displayUnit || userInputField?.value?.units?.[0]) {
      const newVitalUnitName = getVitalUnitName(userInputField?.value.displayUnit || userInputField.value.units[0]);
      setVitalUnitName(newVitalUnitName);
    }
  }, [userInputField?.value?.displayUnit, userInputField?.value?.units?.[0]]);

  return (
    (!userInputField?.checkPatientCapabilityAllowedOperation || (!patientCapability?.keyAllowedOperations?.[userInputField.key]?.isHidden)) ?
    <div className='workflow-condition-font-size workflow-ant-input-number'>
      <HStack alignItems="start" padding={4} borderBottomWidth={0.5} borderBottomColor={'#E4E7EC'} >
        <View
          flex={1}
          flexDirection={
            !!userInputField.isShowComparisonOperator &&
              !props.userInputField?.isHideOperator
              ? 'column'
              : 'row'
          }
        >
          <HStack flex={7} alignItems="center">
          {userInputField.fieldType == 'ORDER_TASK' ?
            (
              false
            ) : userInputField.fieldType === INPUT_FIELD_TYPE.CHECKBOX?
            <></>
            : props.isDisplayLabel ? (
              <HStack
              flex={userInputField.isShowComparisonOperator? 5 : 2.4}
              marginRight={2}
              >
                <Tooltip
                    title={userInputField?.elementProperty?.tooltip || ''}
                  >
                <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400} flex={3} >
                {userInputField?.category &&
                    userInputField.fieldType ==
                      INPUT_FIELD_TYPE.RESOURCE_ABILITIES_SEARCH
                      ? getLabelForKey(
                          FHIR_RESOURCE.PATIENT,
                          userInputField?.category || '',
                          '',
                          workflowContext?.locationIds?.[0]
                        ) || userInputField?.displayName
                      : userInputField?.displayName}
                  {!userInputField.optional && <Text color="error.500">*</Text>}
                </Text>
                </Tooltip>
              </HStack>
            ) : (
              <></>
            )}
            {
              userInputField.isShowComparisonOperator &&
              !props.userInputField?.isHideOperator &&
              (
                <View marginY={1} marginRight={4} flex={4}>
                  {
                    <ComparisonOperatorElement
                      isViewOnly={props.isViewOnly}
                      isShowError={props.isShowError}
                      operatorList={userInputField?.operatorList || []}
                      value={comparisonOperator}
                      onChange={(value: any) => {
                        setComparisonOperator(value);
                      }}
                    ></ComparisonOperatorElement>
                  }
                </View>
              )
            }
            <Stack flexDirection="column" flex={7} space={5}>
              <View  flex={1} pointerEvents={props.isViewOnly ? 'none' : 'auto' }>
                <GetInputElement
                  userInputFieldKey={userInputField.key}
                  rootNodeType={props.rootNodeType}
                  getPreviousImmediateNode={props.getPreviousImmediateNode}
                  isViewOnly={props.isViewOnly}
                  userInputField={userInputField}
                  value={elementProps.value}
                  isAddNew={elementProps.isAddNew}
                  onNewAdd={elementProps.onNewAdd}
                  nodeMetaData={elementProps.nodeMetaData}
                  nodeType={props.nodeType}
                  fieldType={elementProps.fieldType}
                  displayName={elementProps.displayName}
                  isShowError={elementProps.isShowError}
                  userInputFieldList={elementProps.userInputFieldList}
                  possibleValueList={elementProps.possibleValueList}
                  elementProperty={elementProps.elementProperty}
                  optional={elementProps.optional}
                  possibleActivityMeasureParams={
                    elementProps.possibleActivityMeasureParams
                  }
                  possiblePeriodList={elementProps.possiblePeriodList}
                  onChange={(value: any, displayValue: string) => {
                    const newData = {
                      ...userInputField,
                      value: value,
                      displayValue: displayValue,
                    };
                    let selectedComparisonOperator = userInputField?.selectedComparisonOperator;
                    if (value && !comparisonOperator && userInputField?.operatorList?.length) {
                      selectedComparisonOperator = userInputField.operatorList[0];
 
                    } else if (value == null || value === undefined || value === '') {
                      selectedComparisonOperator = undefined;
                    }
                    newData.selectedComparisonOperator = selectedComparisonOperator;
                    props.setUserInputField(newData);
                    setComparisonOperator(selectedComparisonOperator)
                  }}
                  prevImmediateNode={props.prevImmediateNode}
                  isAutomationView={true}
                  locationId={workflowContext?.locationIds?.[0]}
                  locationIdList={workflowContext?.locationIds}
                ></GetInputElement>
              </View>
              {'BTW' == userInputField.selectedComparisonOperator?.value &&
                userInputField.isShowComparisonOperator &&
                !props.userInputField?.isHideOperator && (
                  <View flex={1} pointerEvents={props.isViewOnly ? 'none' : 'auto' }>
                    <GetInputElement
                      userInputFieldKey={userInputField.key}
                      rootNodeType={props.rootNodeType}
                      nodeType={props.nodeType}
                      userInputField={userInputField}
                      isToValue={true}
                      onNewAdd={elementProps.onNewAdd}
                      userInputFieldList={elementProps.userInputFieldList}
                      isAddNew={elementProps.isAddNew}
                      possibleValueList={elementProps.possibleValueList}
                      elementProperty={elementProps.elementProperty}
                      optional={elementProps.optional}
                      value={elementProps.toValue}
                      nodeMetaData={elementProps.nodeMetaData}
                      fieldType={elementProps.fieldType}
                      displayName={elementProps.displayName}
                      isShowError={elementProps.isShowError}
                      possibleActivityMeasureParams={
                        elementProps.possibleActivityMeasureParams
                      }
                      possiblePeriodList={elementProps.possiblePeriodList}
                      onChange={(value: any, displayValue: string) => {
                        const newData = { ...userInputField, toValue: value };
                        props.setUserInputField(newData);
                      }}
                      prevImmediateNode={props.prevImmediateNode}
                      isAutomationView={true}
                      locationId={workflowContext?.locationIds?.[0]}
                    ></GetInputElement>
                  </View>
                )}
            </Stack>
          </HStack>
        </View>
      </HStack>

      {userInputField.fieldType === INPUT_FIELD_TYPE.VITAL_SEARCH &&
        (vitalUnitName != '') &&
        userInputField.value &&
        userInputField.value.units &&
        userInputField.value.units.length > 0 && (
          <HStack space={2} alignItems="center"  paddingX={4} paddingY={4} borderBottomWidth={0.5} borderColor={'#E4E7EC'}>
            {props.isDisplayLabel ? (
              <Text fontWeight={300} fontSize={16} flex={3}>
                Vital Unit
              </Text>
            ) : (
              <></>
            )}
            <View flex={8}>
              <Text>{vitalUnitName}</Text>
            </View>
          </HStack>
        )}
    </div> : <></>
  );
}



export default NodeInputField;

const getVitalUnitName = (unit:string) => {
  switch (unit) {
    case VitalUnit.ft_inches:
      return 'ft inches';
    default:
      return unit;
  }
}

const GetInputElement = (props: IInputElement) => {
  const prevImmediateNode = props.prevImmediateNode;
  switch (props.fieldType) {
    case INPUT_FIELD_TYPE.RICH_TEXT_EDITOR:
      return getRichTextEditorInputField(props);
    case INPUT_FIELD_TYPE.TEXT:
      return getTextInputField(props);
    case INPUT_FIELD_TYPE.DEBOUNCE_SELECT:
      return getDebounceSelectElement(props);
    case INPUT_FIELD_TYPE.SELECT:
      return getSelectElement(props);
    case INPUT_FIELD_TYPE.DATE_PICKER:
      return getDatePickerElement(props);
    case INPUT_FIELD_TYPE.NUMBER:
      return getNumberInputField(props);
    case INPUT_FIELD_TYPE.APPOINTMENT_SCHEDULE_DATE_RANGE:
      return getAppointmentScheduleDateRangeInputField(props);
    case INPUT_FIELD_TYPE.TIME_RANGE:
      return getTimeRangeInputField(props);
    case INPUT_FIELD_TYPE.TRIGGER:
      return getDurationInputField({
        ...props,
        possibleValueList: props.nodeMetaData.triggerUnits,
      });
    case INPUT_FIELD_TYPE.DURATION:
      return getDurationInputField({
        ...props,
        possibleValueList: props.nodeMetaData.durationUnits,
      });
    case INPUT_FIELD_TYPE.REMINDER:
      return getReminderInputField(props);

    case INPUT_FIELD_TYPE.TASK_TYPE:
      return  getTaskType(props)
    case INPUT_FIELD_TYPE.MED_SEARCH:
      return getMedicationSearchField(props);
    case INPUT_FIELD_TYPE.NUTRITION_SEARCH:
      return getNutritionSearchField(props);
    case INPUT_FIELD_TYPE.FORM_SEARCH:
      return getFormSearchField(props);
    case INPUT_FIELD_TYPE.GENERAL_TEMPLATE_SEARCH:
      return generalTemplateSearch(props);
    case INPUT_FIELD_TYPE.GENERAL_SMS_TEMPLATE_SEARCH:
      return generalSMSTemplateSearch(props);
    case INPUT_FIELD_TYPE.GENERAL_PUSH_NOTIFICATION_TEMPLATE_SEARCH:
      return generalPushNotificationTemplateSearch(props);
    case INPUT_FIELD_TYPE.FORMS_SEARCH:
      return getFormsSearchField(props);
    case INPUT_FIELD_TYPE.PACKAGES_SEARCH:
      return getPackagesSearchField(props);
    case INPUT_FIELD_TYPE.FORM_OUTCOME_MATCH:
      return getFormsOutcomeMatchField(props);
    case INPUT_FIELD_TYPE.VITAL_SEARCH:
      return getVitalSearchField(props);
    case INPUT_FIELD_TYPE.EXERCISE_SEARCH:
      return getPhysicalActivitySearchField(props);
    case INPUT_FIELD_TYPE.APPOINTMENT_TYPE_SEARCH_ALL:
      return getAppointmentTypeSearchField(props, true);
    case INPUT_FIELD_TYPE.APPOINTMENT_TYPE_SEARCH:
      return getAppointmentTypeSearchField(props, props?.nodeType === 'OnAppointmentAddOrUpdateFilter');
    case INPUT_FIELD_TYPE.CONTENT_SEARCH:
      return getContentSearchField(props, ContentType.patientEducation);
    case INPUT_FIELD_TYPE.IMMUNIZATION_SEARCH:
      return getImmunizationSearchField(props);
    case INPUT_FIELD_TYPE.CONDITIONAL:
      return getConditionalField(props);
    case INPUT_FIELD_TYPE.RISK_SCORE_CONDITIONAL:
      return getRiskScoreConditionalField(props, prevImmediateNode);
    case INPUT_FIELD_TYPE.USER_SEARCH:
      return getUserSearchField(props);
    case INPUT_FIELD_TYPE.CONTACT_TYPE_SEARCH:
      return getContactTypeSearchField(props);
    case INPUT_FIELD_TYPE.TAGGER_TYPE_SEARCH:
      return getTaggerType(props);
    case INPUT_FIELD_TYPE.MLOV_SEARCH:
      return getMlovSearchElement(props, false);
      case INPUT_FIELD_TYPE.RESOURCE_ABILITIES_SEARCH:
        return getResourceAbilitiesSearch(props);
    case INPUT_FIELD_TYPE.MLOV_MULTIPLE_SEARCH:
      return getMlovSearchElement(props, true)
      case INPUT_FIELD_TYPE.RESOURCE_ABILITIES_SEARCH:
        return getResourceAbilitiesSearch(props);
    case INPUT_FIELD_TYPE.TAG_RESOURCE_SEARCH:
      return getTagResource(props);
    case INPUT_FIELD_TYPE.TAG_SEARCH:
      return getTag(props);
    case INPUT_FIELD_TYPE.USER_ROLE_SEARCH:
      return getUserRoleSearchField(props);
    case INPUT_FIELD_TYPE.VITAL_CONDITIONAL:
      return getVitalConditionalField(props, prevImmediateNode);
    case INPUT_FIELD_TYPE.LAB_TEST_SEARCH:
      return getLabSearchField(props);
    case INPUT_FIELD_TYPE.PHONE_NUMBER_BY_TYPE:
      return getPhoneNumberSearchField(props);
    case INPUT_FIELD_TYPE.EMPLOYER_SEARCH:
      return getEmployerSearchField(props);
    case INPUT_FIELD_TYPE.MULTIPLE_EMPLOYER_SEARCH:
      return getEmployerListSearchField(props);

    case INPUT_FIELD_TYPE.PRACTICE_LOCATION_SEARCH:
      return <GetPracticeLocationSearchElement {...props}/>;
    case INPUT_FIELD_TYPE.MOMENT_SEARCH:
    case INPUT_FIELD_TYPE.AUTOMATION_SEARCH:
      return getWorkflowSearchField(props);
    case INPUT_FIELD_TYPE.FREQUENCY:
      return FrequencyUserInputField(props);
    case INPUT_FIELD_TYPE.VALUE_AND_UNIT:
      return ValueAndUnitField(props);
    case INPUT_FIELD_TYPE.EMAIL_FREQUENCY:
      return ValueAndUnitFieldForEmailFrequency(props);
    case INPUT_FIELD_TYPE.TAG_DROPDOWN_SELECT:
      return tagDropdownSelect(props);
    case INPUT_FIELD_TYPE.ACTIVITY_TARGET:
      return ActivityUserInputField(props);
    case INPUT_FIELD_TYPE.SEND_EMAIL_TO:
      return getSendEmailToField(props);
    case INPUT_FIELD_TYPE.ORDER_TASK:
      return getOrderTaskFields(props);
    case INPUT_FIELD_TYPE.ASSIGNED_TO_USER_ROLE_OR_TASK_POOL:
      return AssignToUserRoleOrTaskPoolField(props);
    case INPUT_FIELD_TYPE.TASK_POOL_SEARCH:
      return TaskPoolSearchField(props);
    case INPUT_FIELD_TYPE.AUDIO_URL:
      return getInputAndUploadField(props);
    case INPUT_FIELD_TYPE.SHOW_FILTER_TOTAL_COUNT:
      return props?.rootNodeType== 'ForAllPatientWithFilter' ? showFilterTotalCount(props) : <></>;
    case INPUT_FIELD_TYPE.ANALYTE_RESULT_STATUS_MULTIPLE:
      return AnalyteStatusSelect(props);
    case INPUT_FIELD_TYPE.USER_POOL_SEARCH_ALL:
      return renderTaskPoolSearch(props);
    case INPUT_FIELD_TYPE.TASK_LABEL_SEARCH_ALL:
      return renderTaskLabelSearch(props);
    case INPUT_FIELD_TYPE.ORDER_TASK_FIELD:
      return renderPrescriptionOrderField(props)
    case INPUT_FIELD_TYPE.ASSIGNED_TO_USER_ROLE_OR_TASK_POOL_OR_ASSIGNEE:
      return AssignToUserRoleOrTaskPoolField(props, true);
    case INPUT_FIELD_TYPE.VISIT_NOTE_OUTCOME_MATCH:
      return getFormsOutcomeMatchField(props, true);
    case INPUT_FIELD_TYPE.VISIT_NOTE_TEMPLATE_SEARCH:
      return getFormSearchField(props, true);
    case INPUT_FIELD_TYPE.APPOINTMENT_TYPE_SEARCH_IN_APPOINTMENT_FILTER:
      return getMultiSelectAppointmentTypeSearchField(props);
    case INPUT_FIELD_TYPE.PRACTICE_LOCATION_SEARCH_ALL:
      return renderAccountLocationSearch(props);
    case INPUT_FIELD_TYPE.CARE_JOURNEY_SEARCH_ALL:
      return renderCareJourneySearch(props);
    case INPUT_FIELD_TYPE.POP_GROUP_SEARCH:
      return renderPopGroupSearch(props);
    case INPUT_FIELD_TYPE.GENERATE_TASK_OPTED_OUT_JOURNEY:
      return renderOptedOutPatientJourneyTaskField(props);
    case INPUT_FIELD_TYPE.GROUP_APPOINTMENT_WEBINAR:
      return getWebinarSearch(props)
    case INPUT_FIELD_TYPE.READONLY_INFO_FIELD:
      return renderInstruction(props)
    case INPUT_FIELD_TYPE.JOURNEY_AUTOMATION_ASSIGNEE_TYPE:
      return renderJourneyAutomationTaskAssignee(props)
    case INPUT_FIELD_TYPE.ASSIGNED_TO_CARE_JOURNEY_USER_ROLE_OR_TASK_POOL:
      return AssignToUserRoleOrTaskPoolField(props)
    case INPUT_FIELD_TYPE.APPOINTMENT_SERIES_REFERENCE:
      return getAppointmentSeriesInputField(props)
    case INPUT_FIELD_TYPE.PRIMARY_CARE_PROVIDER_MULTIPLE_SEARCH:
      return renderCareTeamMemberTypeProviders(props, 'Select Primary Care Providers');
    case INPUT_FIELD_TYPE.CUSTOM_ATTRIBUTE_FILTER:
      return renderCustomAttributeFilter(props);
    case INPUT_FIELD_TYPE.BILLING_PRACTITIONER_MULTIPLE_SEARCH:
      return renderCareTeamMemberTypeProviders(props, 'Select Care Manager')
    case INPUT_FIELD_TYPE.UNITY_AI_CUSTOM_FIELD:
      return renderUnityAiFields(props);
    case INPUT_FIELD_TYPE.UNITY_AI_OUTPUT:
      return getUnityAiOutput(props);
    case INPUT_FIELD_TYPE.UNITY_AI_CONTEXT:
      return getUnityAiContext(props);
    case INPUT_FIELD_TYPE.UNITY_AI_CHECKBOX:
      return renderUnityAICheckBox(props);
    case INPUT_FIELD_TYPE.PATIENT_REFERRAL_SEARCH:
      return ProviderDirectorySearch(props);
    case INPUT_FIELD_TYPE.UNITY_AI_FILTER:
      return getUnityAiFilter(props);
    case INPUT_FIELD_TYPE.CHECKBOX:
      return renderUnityAICheckBox(props);
    case INPUT_FIELD_TYPE.MULTIPLE_FORM_REUSE_COMPONENTS:
      return renderReusableComponentSelect(props);
    case INPUT_FIELD_TYPE.CARE_PLAN_TEMPLATE_SEARCH:
      return renderSelectCarePlansDropdown(props);
    case INPUT_FIELD_TYPE.CARE_PLAN_STATUS_CUSTOM_OBJECT:
      return renderSelectCarePlanStatus(props);
    case INPUT_FIELD_TYPE.CARE_PROGRAM_COMPLEXITY_TYPE:
      return getCareProgramComplexity(props);
    case INPUT_FIELD_TYPE.CARE_PROGRAM_OUTREACH_NUMBER:
      return getCareProgramOutreachNumber(props);
    default:
      return <></>;
  }
};

const showFilterTotalCount = (props: IInputElement) => {
  return <ShowFilterTotalCountInputField sourceHandle={'out'} getPreviousImmediateNode={props.getPreviousImmediateNode} prevImmediateNode={props.prevImmediateNode} value={props.value} onChange={props.onChange} userInputFieldList={props.userInputFieldList}></ShowFilterTotalCountInputField>
};

const getRichTextEditorInputField = (props: IInputElement) => {
  return (

    <HStack borderColor={ props.isShowError && !props.value ? 'red.500' : '' } borderRadius={'5px'} borderWidth={ props.isShowError && !props.value ?  '1px' : '0px'}>
    <RichTextEditor
      placeholder='Please Enter Task Description'
      style={{flex:1, backgroundColor:'white', placeholderColor : Colors.FoldPixel.GRAY_PLACEHOLDER}}
      valueStr={props.value}
      modules={TEXT_ONLY_MODULES}
      onChangesValue={(value: string) => {
        props?.onChange?.(value)
      }}
    />
    </HStack>
  );
};

const getTextInputField = (props: IInputElement) => {
  return (
    <FormControl isInvalid={props.isShowError && !props.value}>
      <Input
        placeholder='Enter Task Title'
        placeholderTextColor={Colors.FoldPixel.GRAY250}
        fontSize={InputStyle.placeHolderFontsize}
        backgroundColor={'white'}
        borderWidth={0.5}
        _focus={{borderColor : Colors.FoldPixel.PRIMARY_FOCUS}}
        {...props?.elementProperty}
        value={props.value}
        onChangeText={(text) => props.onChange(text)}
      />
    </FormControl>
  );
};

const getInputAndUploadField = (props: IInputElement) => {
  const intl = useIntl();
  const errorMessage = props.value ? 'mp3FileErrorMsg' : 'audioUrlErrorMsg';
  return (
    <HStack flex={1}>
      <FormControl flex={0.7} isInvalid={props.isShowError}>
        <Input
          {...props?.elementProperty}
          value={props.value}
          onChangeText={(text) => props.onChange(text)}
        />
        {props.isShowError && (
          <FormControl.ErrorMessage
            _text={{
              fontSize: 'xs',
              color: 'error.500',
              fontWeight: 500,
            }}
          >
            {intl.formatMessage({id: errorMessage})}
          </FormControl.ErrorMessage>
        )}
      </FormControl>
      <HStack flex={0.3} marginLeft={1} justifyContent={'flex-start'} alignContent={'flex-start'}>
        <Text padding={2} alignSelf={'flex-start'} justifySelf={'flex-start'}>Or</Text>
        {getAttachment(props)}
      </HStack>
    </HStack>
  );
};

const getAttachment = (props: IInputElement) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <UploadButton
      onUpload={(value?: string) => {
        if (value) {
          props.onChange(value);
        }
      }}
    />
  );
};

export const getTimeRangeInputField = (props: IInputElement) => {
  const [value, setValue] = useState(props.value);
  const [possibleValueList] = useState(props.possibleValueList || []);
  const elementProperty = props?.elementProperty || {};
  return (
    <HStack flex={1}>
      <View paddingRight={1} flex={5}>
        {
          value?.unit?.code != 'Custom' &&
          <InputNumber
            min={0}
            style={reactStyles.inputNumberStyle}
            value={value?.count}
            type="number"
            placeholder='Enter Number'
            className={props.isShowError && !props?.value?.count ? 'field-error' : ''}
            onChange={(count) => {
              if (count == undefined) {

                setValue((value: any)=>{
                  const newValue = {...value};
                  delete newValue.count;
                  return newValue
                });

              } else {
                setValue({ ...value, count });
              }
            }}
            onBlur={() => {
              props.onChange(value);
            }}
            {...props.elementProperty}
          />
        }
        {
          value?.unit?.code == 'Custom' &&
          <DatePicker
            {...elementProperty}
            className={props.isShowError && !props?.value?.dateString ? 'field-error' : ''}
            placeholder='Select Date'
            format={DATE_FORMATS.DISPLAY_DATE_FORMAT}
            style={reactStyles.height36font13}
            value={props?.value?.dateString ? getMomentObj(props?.value?.dateString) : undefined}
            onChange={(date: any, dateString: string) => {
              if (date) {
                const dateObj = new Date(date);
                let hour = 0, minute = 0;
                if (props.isToValue) {
                  hour = 23;
                  minute = 59
                }
                dateObj.setHours(hour)
                dateObj.setMinutes(minute)
                dateString = dateObj.toISOString()
                const newValue = { ...value, dateString: dateString }
                setValue(newValue);
                props.onChange(newValue);
              } else {

                setValue((value: any)=>{
                  const newValue = {...value};
                  delete newValue.dateString;
                  props.onChange(newValue);
                  return newValue;

                });
              }
            }}

          />
        }
      </View>
      <View flex={5} paddingRight={1}>
        <AntSelect
          suffixIcon={
            <CaretDownOutlined
              style={reactStyles.colorGray300}
            />
          }
          status={props.isShowError && !props?.value?.unit?.code ? 'error' : ''}
          allowClear={true}
          onClear={() => {
            props.onChange(undefined)
          }}
          {...elementProperty}
          placeholder='Select Unit'
          value={props?.value?.unit?.code}
          showSearch={true}
          filterOption={(input, option: any) => {
            return (
              option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          }}
          onChange={(itemValue) => {
            let displayValue = itemValue;

            if(itemValue){
              possibleValueList.some((unit) => {
                if (unit.value === itemValue) {
                  displayValue = unit.displayName;
                }
              });
              const newValue = { ...value, unit: { code: itemValue, displayValue } };
              setValue(newValue);
              props.onChange(newValue);
            } else {

              setValue((value: any)=>{
                const newValue = {...value};
                delete newValue.unit;
                props.onChange(newValue);
                return newValue;
              });

            }
          }}
        >
          {possibleValueList.map((data) => {
            return (
              <Option value={data.value} title={data.displayName} key={data.value}>{data.displayName}</Option>
            );
          })}

        </AntSelect>

        {/* <Select
          height={'36px'}
          fontSize={'13px'}
          selectedValue={props?.value?.unit?.code}
          onValueChange={(itemValue) => {
            let displayValue = itemValue;
            possibleValueList.some((unit) => {
              if (unit.value === itemValue) {
                displayValue = unit.displayName;
              }
            });
            const newValue = { ...value, unit: { code: itemValue, displayValue } };
            setValue(newValue);
            props.onChange(newValue);
          }}
          {...elementProperty}
        >
          {possibleValueList.map((data) => {
            return (
              <Select.Item
                key={data.value}
                label={data.displayName}
                value={data.value}
              />
            );
          })}
        </Select> */}
      </View>
    </HStack>
  );
};

const getNumberInputField = (props: IInputElement) => {
  const [value, setValue] = useState(props.value);

  return (
    <View>
      <InputNumber
        min={0}
        style={reactStyles.height36borderRadius10}
        value={value}
        type="number"
        className={
          props.isShowError && !props.value && props.value !== 0
            ? 'field-error'
            : ''
        }
        onChange={(value) => {
          setValue(value);
        }}
        onBlur={() => {
          props.onChange(value);
        }}
        {...props.elementProperty}
      />
    </View>
  );
};

const ComparisonOperatorElement = (props: IComparisonOperatorElement) => {
  const isShowError = !!props.isShowError;
  const [operatorList] = useState(props.operatorList || []);
  const elementProperty = props?.elementProperty || {};
  if (props.value?.value && !props.value?.displayName) {
    operatorList.some((item) => {
      if (props.value?.value == item?.value) {
        props.onChange({ ...item })
      }
    })
  }
  return (
    <FormControl isInvalid={isShowError && !props.value} pointerEvents={props.isViewOnly ? 'none' : 'auto'}>


      <AntSelect
      style={reactStyles.textOverflowEllipsis}
        suffixIcon={
          <CaretDownOutlined
            style={reactStyles.colorGray300}
          />
        }
        // onFocus={()=>{}}
        // className='workflow-antd-input'
        status={props.isShowError && !props.value ? 'error' : ''}
        allowClear={true}
        onClear={() => {
          props.onChange(undefined)
        }}
        placeholder={'Select'}
        {...elementProperty}
        value={props?.value?.value}
        showSearch={true}
        filterOption={(input, option: any) => {
          return (
            option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
          );
        }}
        onChange={(itemValue) => {
          let displayValue = itemValue;
          if (itemValue) {
            operatorList.some((unit) => {
              if (unit.value === itemValue) {
                displayValue = unit.displayName;
              }
            });
            props.onChange({ displayName: displayValue, value: itemValue });
          } else {
            props.onChange(undefined);
          }
        }}
      >
        {operatorList.map((data) => {
          return (
            <Option value={data.value} key={data.value} title={data.displayName}>{data.displayName}</Option>
          );
        })}

      </AntSelect>
      {/* <Select
        height={'36px'}
        fontSize={'13px'}
        selectedValue={props?.value?.value}
        onValueChange={(itemValue) => {
          let displayValue = itemValue;
          operatorList.some((unit) => {
            if (unit.value === itemValue) {
              displayValue = unit.displayName;
            }
          });
          props.onChange({ displayName: displayValue, value: itemValue });
        }}
        {...elementProperty}
      >
        {operatorList.map((data) => {
          return (
            <Select.Item
              key={data.value}
              label={data.displayName}
              value={data.value}
            />
          );
        })}
      </Select> */}
    </FormControl>
  );
};

const getDatePickerElement = (props: IInputElement) => {
  const isShowError = props.isShowError;
  const elementProperty = props?.elementProperty || {};
  return (
    <FormControl isInvalid={isShowError && !props.value}>
      <DatePicker
        style={reactStyles.borderRadius10}
        {...elementProperty}
        format={DATE_FORMATS.DISPLAY_DATE_FORMAT}
        value={props.value ? getMomentObj(props.value) : undefined}
        onChange={(date: any, dateString: string) => {
          props.onChange({ value: dateString });
        }}
      />
    </FormControl>
  );
};

const getSelectElement = (props: IInputElement) => {
  const intl = useIntl();
  const isShowError = props.isShowError;
  const workflowContext = useContext(WorkflowContext);
  const possibleValueList = (props.possibleValueList || []).filter((value) => {
    if (value?.flowTypeList?.length) {
      if (value?.flowTypeList?.indexOf?.(workflowContext.flowType) !== -1) {
        return true;
      } else {
        return false;
      }
    } else if (value?.disAllowForFlowTypeList?.length && value?.disAllowForFlowTypeList?.indexOf?.(workflowContext.flowType) !== -1) {
      return false;
    }
    return true;
  });
  const elementProperty = props?.elementProperty || {};
  const placeholder = props?.elementProperty?.placeholder || 'Select Option'
  const [isSelected , setIsSelected] = useState(false)
  const [warningMessage, setWarningMessage] = useState('');
  const handleSelected = (itemValue:string)=>{
    if(itemValue){
      setIsSelected(true)
      if (itemValue == 'NO' && props.userInputFieldKey == 'alreadyAssignedPackage') {
        setWarningMessage(intl.formatMessage({id: 'duplicateJourneys'}),)
      } else {
        setWarningMessage('');
      }
    }
    else{
      setWarningMessage('');
      setIsSelected(false)
    }
  }
  return (
    <FormControl isInvalid={isShowError && !props.value}>
      <AntSelect
        suffixIcon={
          <CaretDownOutlined
            style={reactStyles.colorGray300}
          />
        }
        status={(isShowError && (props.value === undefined || props?.value === '')) ? 'error' : ''}
        allowClear={true}
        onClear={() => {
          props.onChange(undefined, undefined)
        }}
        {...elementProperty}
        placeholder={placeholder}
        value={(props.value !== undefined && props.value !== '') ? props.value: undefined}
        showSearch={true}
        filterOption={(input, option: any) => {
          return (
            option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
          );
        }}
        onChange={(itemValue) => {
          let displayValue = itemValue;
          possibleValueList.some((unit) => {
            if (unit.value === itemValue) {
              displayValue = unit.displayName;
            }
          });

          props.onChange(itemValue, displayValue);
          handleSelected(itemValue)
        }}
      >
        {possibleValueList.map((data) => {
          return (
            <Option value={data.value} key={data.value} title={data.displayName}>{data.displayName}</Option>
          );
        })}

      </AntSelect>
      {warningMessage && <div style={reactStyles.colorErrorMarginTop10}>{warningMessage}</div>}
      {/* <Select
        height={'36px'}
        fontSize={'14px'}
        selectedValue={props.value}
        onValueChange={(itemValue) => {
          let displayValue = itemValue;
          possibleValueList.some((unit) => {
            if (unit.value === itemValue) {
              displayValue = unit.displayName;
            }
          });
          props.onChange(itemValue, displayValue);
        }}
        {...elementProperty}
      >
        {possibleValueList.map((data) => {
          return (
            <Select.Item
              fontSize={'14px'}
              key={data.value}
              label={data.displayName}
              value={data.value}
            />
          );
        })}
      </Select> */}
    </FormControl>
  );
};

const getDebounceSelectElement = (props: IInputElement) => {
  const isShowError = props.isShowError;
  const [possibleValueList] = useState(
    (props.possibleValueList || []).map((data: any) => {
      const newData = { ...data };
      newData.label = data.displayName;
      return newData;
    })
  );
  const [defaultPossibleValueList, setDefaultPossibleValueList] =
    useState(possibleValueList);
  const elementProperty = props?.elementProperty || {};
  const addNewPrefix = `Add new ${props.userInputField?.displayName} :`;
  return (
    <FormControl isInvalid={isShowError && !props.value}>
      <AntSelect
        suffixIcon={
          <CaretDownOutlined
            style={reactStyles.colorGray300}
          />
        }
        status={isShowError && !props.value ? 'error' : ''}
        value={props.value}
        labelInValue={true}
        filterOption={true}
        onSearch={(searchKey: string) => {
          const isMatch = props.possibleValueList?.some((data) => {
            return data.displayName === searchKey;
          });
          const newList = [...possibleValueList];
          if (!isMatch && props.isAddNew && searchKey) {
            newList.push({ value: searchKey, label: addNewPrefix + searchKey });
          }
          setDefaultPossibleValueList(newList);
        }}
        showSearch={true}
        {...elementProperty}
        onChange={(newValue) => {
          if (props.isAddNew && newValue?.label?.indexOf(addNewPrefix) != -1) {
            props.onNewAdd && props.onNewAdd(newValue, props.userInputField);
            setDefaultPossibleValueList(possibleValueList);
          } else {
            props.onChange && props.onChange(newValue.value);
          }
        }}
        options={defaultPossibleValueList}
        maxTagCount={'responsive'}
        dropdownRender={(menu) => <>{menu}</>}
      />
    </FormControl>
  );
};

const getDurationInputField = (props: IInputElement) => {
  const [value, setValue] = useState(props.value);

  const [searchParams, setSearchParams] = useSearchParams();
  const [flowType] = useState<any>(searchParams?.get('flowType') || '');
  useEffect(() => {
    props.onChange(value);
  }, [value]);

  const unitList = props.possibleValueList?.filter((possibleValue)=>{ return possibleValue?.value != 'End' || flowType === FlowType.careJourney || !flowType}) || [];

  const isInvalidValue = () => {
    return !(value.value >= 0);
  };

  return (
    <HStack space={2} alignItems="center">
      {value.unit !== TRIGGER_START_OF_CARE_PLAN_UNIT &&
        value.unit !== DURATION_END_OF_CARE_PLAN_UNIT && (
          <FormControl
            isInvalid={props.isShowError && isInvalidValue()}
            flex={0.7}
          >
            <InputNumber
              placeholder='Enter Value'
              min={0}
              value={value.value}
              type="number"
              className={
                props.isShowError && isInvalidValue() ? 'field-error' : ''
              }
              onChange={(text) => {
                setValue((prev: any) => ({ ...prev, value: text }));
              }}
              style={reactStyles.fullWidthHeight36}
            />
          </FormControl>
        )}
      <FormControl
        isInvalid={props.isShowError && !value.unit}
        flex={props.fieldType === INPUT_FIELD_TYPE.TRIGGER ? 1 : 2}
      >
        <AntSelect
          suffixIcon={
            <CaretDownOutlined
              style={reactStyles.colorGray300}
            />
          }
          style={reactStyles.antSelectStyle}
          value={value.unit}
          onChange={(itemValue) => {
            if (itemValue === TRIGGER_START_OF_CARE_PLAN_UNIT || itemValue === DURATION_END_OF_CARE_PLAN_UNIT) {
              setValue({ value: undefined, unit: itemValue });
            } else {
              setValue((prev: any) => ({ ...prev, unit: itemValue }));
            }
          }}
        >
          {unitList &&
            unitList.map((data: any) => (
              <AntSelect.Option key={data.value} label={data.displayName} value={data.value}>
                {data.displayName}
              </AntSelect.Option>
            ))}
        </AntSelect>
      </FormControl>
      {props.fieldType === INPUT_FIELD_TYPE.TRIGGER && (
        <Text>{flowType == FlowType.careJourney || !flowType ? TRIGGER_SUFFIX_TEXT : 'after current node execution'}</Text>
      )}
    </HStack>
  );
};

const getReminderInputField = (props: IInputElement) => {
  const [reminderList, setReminderList] = useState<IReminderData[]>(
    props.value
  );
  const userInputField: any = props?.userInputField;
  const allowedReminder = userInputField?.allowedReminder || [
    { code: 'BEFORE', display: 'Before' },
    { code: 'AFTER', display: 'After' },
  ];

  useEffect(() => {
    props.onChange(reminderList);
  }, [reminderList]);

  return (
    <VStack space={4}>
      {reminderList.map((reminderData, index) => {
        reminderData.key = reminderData.key || generateUniqueKey();

        return (
          <HStack key={reminderData.key}>
            <ReminderRow
              allowedReminder={allowedReminder}
              value={reminderData}
              unitList={props.nodeMetaData.reminderUnits}
              isShowError={props.isShowError}
              showDelete={reminderList.length > 1}
              onChange={(updatedData) => {
                setReminderList((prev) => {
                  const newState = [...prev];
                  newState[index] = updatedData;
                  return newState;
                });
              }}
              onDelete={() => {
                setReminderList((prevState) => {
                  const newStateValue = [...prevState];
                  newStateValue.splice(index, 1);
                  return newStateValue;
                });
              }}
            />
          </HStack>
        );
      })}
      <HStack>
        <Button
          size="sm"
          variant="outline"
          colorScheme="muted"
          leftIcon={<Icon as={AntIcon} name="plus" size="4" />}
          onPress={() => {
            setReminderList([
              ...reminderList,
              { offset:   allowedReminder && allowedReminder?.length ? allowedReminder[0]?.code : 'BEFORE', value: 1, unit: 'Hours' },
            ]);
          }}
        >
          Add
        </Button>
      </HStack>
      <Text color={Colors.FoldPixel.GRAY400}>Note: Time cannot be less than 3 minutes</Text>
    </VStack>
  );
};

const ReminderRow = (props: {
  value: IReminderData;
  unitList: any[];
  isShowError: boolean;
  allowedReminder: any;
  showDelete: boolean;
  onChange: (value: any) => void;
  onDelete: () => void;
}) => {
  const [value, setValue] = useState(props.value);
  const offsetList = props.allowedReminder;



  useEffect(() => {
    props.onChange(value);
  }, [value]);

  const handleValueChange = (newValue: number) => {
    if (value.unit === 'Minutes' && newValue < 3) {
      setValue((prev) => ({ ...prev, value: 3 }));
    } else {
      setValue((prev) => ({ ...prev, value: newValue }));
    }
  };

  const handleUnitChange = (newUnit: string) => {
    setValue((prev) => {
      let updatedValue = prev.value;
      if (newUnit === 'Minutes' && prev.value < 3) {
        updatedValue = 3;
      }
      return { ...prev, unit: newUnit, value: updatedValue };
    });
  };

  return (
    <HStack flex={1} space={4} alignItems="center">
      <Button.Group isAttached>
        {offsetList.map((offsetData: any) => {
          return (
            <Button
              key={offsetData.code}
              onPress={() =>
                setValue((prev) => ({ ...prev, offset: offsetData.code }))
              }
              alignSelf="center"
              variant={value.offset === offsetData.code ? 'solid' : 'outline'}
            >
              {offsetData.display}
            </Button>
          );
        })}
      </Button.Group>
      <FormControl isInvalid={props.isShowError && !value.value} flex={1}>
        <InputNumber
          min={0}
          height={'36px'}
          value={value.value}
          type="number"
          onChange={(text) => {
            handleValueChange(Number(text));
          }}
          className={props.isShowError && !value.value ? 'field-error' : ''}
          style={reactStyles.fullWidthHeight36}
        />
      </FormControl>
      <FormControl isInvalid={props.isShowError && !value.unit} flex={2}>
        <AntSelect
          suffixIcon={
            <CaretDownOutlined
              style={reactStyles.colorGray300}
            />
          }
          status={props.isShowError && !value.unit ? 'error' : ''}
          value={value.unit}
          showSearch={true}
          filterOption={(input, option: any) => {
            return (
              option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          }}
          onChange={(itemValue) => {
            handleUnitChange(itemValue);
          }}
        >
          {props.unitList.map((data) => {
            return (
              <Option value={data.value} key={data.value} title={data.displayName}>{data.displayName}</Option>
            );
          })}

        </AntSelect>
        {/* <Select
          height={'36px'}
          fontSize={'13px'}
          selectedValue={value.unit}
          onValueChange={(itemValue) => {
            setValue((prev) => ({ ...prev, unit: itemValue }));
          }}
        >
          {props.unitList &&
            props.unitList.map((data: any) => {
              return (
                <Select.Item
                  key={data.value}
                  label={data.displayName}
                  value={data.value}
                />
              );
            })}
        </Select> */}
      </FormControl>
      {props.showDelete && (
        <IconButton
          flex={0.5}
          onPress={() => {
            props.onDelete();
          }}
          borderRadius="xl"
          size="sm"
          variant="ghost"
          colorScheme="muted"
          icon={<Icon as={AntIcon} name="delete" size="4" />}
        />
      )}
    </HStack>
  );
};






const getMedicationSearchField = (props: IInputElement) => {
  return (
    <View>
      <MedicationSearch
        value={props.value}
        isShowError={props.isShowError}
        onChange={(value) => {
          props.onChange(value);
        }}
        locationId={props?.locationId}
      />
    </View>
  );
};

const getNutritionSearchField = (props: IInputElement) => {
  return (
    <View>
      <NutritionSearch
        value={props.value}
        isShowError={props.isShowError}
        onChange={(value) => {
          props.onChange(value);
        }}
      />
    </View>
  );
};

const getFormsSearchField = (props: IInputElement) => {
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED)
  const workflowContext = useContext(WorkflowContext);
  const isFormWithHealthComponent = props.value && Array.isArray(props.value) ?
    props.value.some((item) => item.isHealthComponentIncluded) :
    false;
  const intl = useIntl();
  const message = intl.formatMessage({ id: 'genericHealthComponentFormInfoMessageForLead' });
  return (
    <View>
      <MultipleFormSearch
        value={props.value}
        isShowError={props.isShowError}
        isShowProviderRequestForm={props.nodeType === NODE_TYPES.FILTER_FORM}
        infoMessage={isFormWithHealthComponent ? message : undefined}
        onChange={(value) => {
          props.onChange(value);
        }}
        formLocations={workflowContext?.locationIntersections || []}
        useExactMatchForFormLocations={isMsoEnabled}
        disableInlineFormEdit={isMsoEnabled}
        disableAddNewForm={isMsoEnabled}
      />
    </View>
  );
};

const getPackagesSearchField = (props: any) => {
  const workflowContext = useContext(WorkflowContext);
  return (
    <View>
      <MultiplePackageSearch
        value={props.value}
        isShowError={props.isShowError}
        onChange={(value) => {
          props.onChange(value);
        }}
        workFlowLocations={workflowContext?.locationIds}
      />
    </View>
  );
};

const getFormsOutcomeMatchField = (props: IInputElement, isVisitNoteTemplate?: boolean) => {
  let formList: any = [];
  let defaultSelectedFormId = props?.value?.form?.id;

  const [getFormMetadata] = useLazyQuery(FormsQueries.GET_FORM_METADATA, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
  });

  if (props.prevImmediateNode) {
    const nodeData = props.prevImmediateNode;
    const isMatched = (nodeData?.data?.metaData?.userInputFieldList || []).some(
      (inputNode: any) => {
        if (inputNode.fieldType === INPUT_FIELD_TYPE.FORM_SEARCH || inputNode.fieldType === INPUT_FIELD_TYPE.VISIT_NOTE_TEMPLATE_SEARCH) {
          formList = inputNode?.value?.id ? [inputNode.value] : [];
          if (defaultSelectedFormId) {
            defaultSelectedFormId = inputNode?.value?.id;
          }
        }
      }
    );
  }

  if (!formList?.length && props.getPreviousImmediateNode && props?.prevImmediateNode?.id) {
    const nodeData = props.getPreviousImmediateNode(props.prevImmediateNode.id);
    const isMatched = (nodeData?.data?.metaData?.userInputFieldList || []).some(
      (inputNode: any) => {
        if (inputNode.fieldType === INPUT_FIELD_TYPE.FORMS_SEARCH || inputNode.fieldType === INPUT_FIELD_TYPE.VISIT_NOTE_TEMPLATE_SEARCH) {
          formList = inputNode?.value || [];
          return true;
        }
      }
    );
  }

  const [selectedFormId, setSelectedForm] = useState(
    defaultSelectedFormId || undefined
  );
  const [selectedState, setSelectedState] = useState(
    props?.value?.state || undefined
  );
  const [initialSelectedState] = useState(
    props?.value?.state || undefined
  );
  const [initialSelectedRiskScoreGroupIdState] = useState(
    props?.value?.riskScoreGroupId || undefined
  );
  const [stateList, setStateList] = useState<any[]>([]);

  const [selectedRiskScoreGroupIdState, setSelectedRiskScoreGroupIdState] = useState(
    props?.value?.riskScoreGroupId || undefined
  );
  const [riskScoreGroupList, setRiskScoreGroupList] = useState([]);

  useEffect(() => {
    if (selectedFormId) {
      getFormMetadata({
        variables: {
          formId: selectedFormId,
        },
      }).then((response) => {
        const metadata = response?.data?.form?.metadata;
        let generalRiskScoreGroup;
        const riskScoreGroupList = (metadata[0]?.metadata || []).map((group: any) => {
          if (!group) {
            return {};
          }
          if (!group.groupId) {
            generalRiskScoreGroup = group;
          }
          const name = group.groupId ? group.name : GENERAL_RISK_SCORE_GROUP_NAME;
          const id = group.groupId ? group.groupId : GENERAL_RISK_SCORE_GROUP_ID;
          return {
            ...group,
            name: name,
            id,
          }
        });
        setRiskScoreGroupList(riskScoreGroupList);

        if (props?.value?.state && !props?.value?.riskScoreGroupId && generalRiskScoreGroup) {
          selectedRiskScoreGroupIdState(GENERAL_RISK_SCORE_GROUP_ID);
        }
      });
    } else {
      props.onChange?.(undefined);
    }
  }, [selectedFormId]);

  useEffect(() => {
    if (!selectedRiskScoreGroupIdState || !riskScoreGroupList?.length) {
      props.onChange?.(undefined);
      return;
    }

    const group = findRiskScoreGroupById(riskScoreGroupList, selectedRiskScoreGroupIdState);

    const states: any[] = [...(group?.states || []), { state: 'Any', from: 0, to: 0 }];
    setStateList(states);

    if (selectedRiskScoreGroupIdState === initialSelectedRiskScoreGroupIdState && initialSelectedState) {
      const state = states.find((state) => {
        return (state?.state === initialSelectedState)
      });
      if (state) {
        setSelectedState(state.state);
        formList.some((form: any) => {
          if (form?.id === selectedFormId) {
            const formInfo = {
              id: form.id,
              name: form.name,
            };
            props.onChange({
              form: formInfo,
              riskScoreGroupId: selectedRiskScoreGroupIdState,
              state: state,
            });
            return true;
          }
        });
      }
      setSelectedState(state?.state || undefined);
    } else {
      setSelectedState(undefined);
    }
  }, [selectedRiskScoreGroupIdState, riskScoreGroupList]);

  function findRiskScoreGroupById(groups: any[], groupId: string) {
    return (groups || []).find?.((group) => {
      if (groupId === GENERAL_RISK_SCORE_GROUP_ID && !group.groupId) {
        return true;
      } else if (groupId && groupId === group.groupId) {
        return true;
      }
    });
  }

  return (
    <>
      <HStack>
        <HStack flex={2.4}>
          <Text fontWeight={300} fontSize={16} flex={2} marginTop={1}>
            {isVisitNoteTemplate ? 'Note Template' : 'Form'}
            {<Text color="error.500">*</Text>}
          </Text>
        </HStack>
        <HStack flex={7}>
          <View marginY={1} flex={1}>
            <ModalActionSelect
              isRequired={true}
              selectedValue={selectedFormId || undefined}
              placeholder={isVisitNoteTemplate ? 'Select template' : 'Select Form' }
              endIcon={<CheckIcon size="5" />}
              onValueChange={(value: string) => {
                if (selectedFormId != value) {
                  setSelectedForm(value);
                  setSelectedState(undefined);
                  props.onChange(undefined);
                }
              }}
              data={formList}
              selectItemProps={{
                key: 'id',
                label: 'name',
                value: 'id',
              }}
              customStyle={{ flex: 1 }}
            />
          </View>
        </HStack>
      </HStack>
      {selectedFormId && (
        <View style={styles.marginTop18}>
          <HStack>
            <HStack flex={2.4}>
              <Text fontWeight={300} fontSize={16} flex={2} marginTop={1}>
                {'Score Group'}
                {<Text color="error.500">*</Text>}
              </Text>
            </HStack>
            <HStack flex={7}>
              <ModalActionSelect
                isRequired={true}
                selectedValue={selectedRiskScoreGroupIdState || undefined}
                placeholder="Select score group"
                endIcon={<CheckIcon size="5" />}
                onValueChange={(value: string) => {
                  setSelectedRiskScoreGroupIdState(value);
                  if (!value) {
                    props.onChange(undefined);
                    return;
                  }
                }}
                data={riskScoreGroupList}
                selectItemProps={{
                  key: 'id',
                  label: 'name',
                  value: 'id',
                }}
                customStyle={{ flex: 1 }}
              />
            </HStack>
          </HStack>
        </View>
      )}
      {
        selectedFormId && selectedRiskScoreGroupIdState && (
          <View style={styles.marginTop18}>
            <HStack>
              <HStack flex={2.4}>
                <Text fontWeight={300} fontSize={16} flex={2} marginTop={1}>
                  {'Score Interpretations'}
                  {<Text color="error.500">*</Text>}
                </Text>
              </HStack>
              <HStack flex={7}>
                <ModalActionSelect
                  isRequired={true}
                  selectedValue={selectedState || undefined}
                  placeholder="Select Score Interpretations"
                  endIcon={<CheckIcon size="5" />}
                  onValueChange={(value: string) => {
                    setSelectedState(value);
                    if (!value) {
                      props.onChange(undefined);
                      return;
                    }
                    formList.some((form: any) => {
                      if (form?.id === selectedFormId) {
                        const formInfo = {
                          id: form.id,
                          name: form.name,
                        };
                        props.onChange({
                          form: formInfo,
                          riskScoreGroupId: selectedRiskScoreGroupIdState,
                          state: value,
                        });
                        return true;
                      }
                    });
                  }}
                  data={stateList}
                  selectItemProps={{
                    key: 'state',
                    label: 'state',
                    value: 'state',
                  }}
                  customStyle={{ flex: 1 }}
                />
              </HStack>
            </HStack>
          </View>
        )}
    </>
  );
};
const getFormSearchField = (props: IInputElement, isVisitNoteTemplate?: boolean) => {
  const isFormWithHealthComponent = props.value?.isHealthComponentIncluded;
  const intl = useIntl();
  const message = intl.formatMessage({ id: 'genericHealthComponentFormInfoMessageForLead' });
  const workflowContext = useContext(WorkflowContext);
  return (
    <View>
      <FormSearch
        value={props.value}
        isShowError={props.isShowError}
        infoMessage={isFormWithHealthComponent ? message : undefined}
        isVisitNoteTemplate={isVisitNoteTemplate}
        isShowProviderRequestForm={props.nodeType === NODE_TYPES.FILTER_FORM}
        onChange={(value: any) => {
          props.onChange(value);
        }}
        formLocations={workflowContext?.locationIntersections || []}
      />
    </View>
  );
};





const multipleCommunicationInput = (props: IInputElement) => {
  const { height } = Dimensions.get('window');
  const [selectedMenu, setSelectedMenu] = useState(-1)
  let communicationViaNodeValue;
  const COMMUNICATION_VIA = {
    SEND_SMS_IF_NOT_EMAIL_ID: 'SEND_SMS_IF_NOT_EMAIL_ID',
    SMS_EMAIL: 'SMS_EMAIL',
    SMS: 'SMS',
    EMAIL: 'EMAIL'
  }

  const isCommunicationViaNode = (props?.userInputFieldList || []).some((inputField) => {
    if (inputField?.key === 'communicationViaNode') {
      communicationViaNodeValue = inputField.value;
      return true
    }
  })
  if(communicationViaNodeValue === 'APP'){
    return <> </>
  }
  const getSmsTemplateValue = () => {
    if (props.value?.sms) {
      const sms = props.value?.sms;
      return { ...sms,formList:props.value?.formList, appointmentType: props.value?.appointmentType, formFrequency:props.value?.formFrequency, isSkipOnEmptyFormList: props.value?.isSkipOnEmptyFormList }
    } else {
      return { formList:props.value?.formList, appointmentType: props.value?.appointmentType, formFrequency:props.value?.formFrequency, isSkipOnEmptyFormList: props.value?.isSkipOnEmptyFormList }
    }
  }
  const tagsTabList = [
    ...(communicationViaNodeValue == COMMUNICATION_VIA.SMS ? [] : [{
      key: 'EMAIL',
      title: 'Email',
      tabContainerElem: () => {
        return (
          <View
            key={'Email'}
          >
            <TemplateSearch
              userInputFieldList={props.userInputFieldList}
              defaultTemplateCategory={props.userInputField?.defaultTemplateCategory}
              isHideTemplateCategory={props.userInputField?.isHideTemplateCategory}
              isHideDefaultInput={props.userInputField?.isHideDefaultInput}
              value={props.value}
              isShowError={!props?.value?.template?.attributes?.name}
              onChange={(value) => {
                props.onChange(value);
              }}
              isAutomationView={true}
            />
          </View>
        );
      }
    }]),
    ...(communicationViaNodeValue == COMMUNICATION_VIA.EMAIL ? [] : [{
      key: 'SMS',
      title: 'SMS',
      tabContainerElem: () => {
        return (
          <View
            key={'SMS'}
          >
            <SmsTemplateSearch
              userInputFieldList={props.userInputFieldList}
              defaultTemplateCategory={props.userInputField?.defaultTemplateCategory}
              isHideTemplateCategory={props.userInputField?.isHideTemplateCategory}
              isHideDefaultInput={props.userInputField?.isHideDefaultInput}
              value={getSmsTemplateValue()}
              isShowError={props.isShowError}
              isAllowEdit={true}
              onChange={(value, isTextEdit) => {
                if(isTextEdit){
                  delete value?.template
                }
                const newSubValue = {
                  formList: value?.formList,
                  appointmentType: value?.appointmentType,
                  formFrequency: value?.formFrequency,
                  isSkipOnEmptyFormList: value?.isSkipOnEmptyFormList
                }
                const newValue = { ...(props.value || {}), sms: value, ...newSubValue };
                props.onChange(newValue);
              }}
              isAutomationView={true}
            />
          </View>
        );
      },
    }])

  ]

  return <CustomTabsView
    key={communicationViaNodeValue ? communicationViaNodeValue :tagsTabList.length}
    contentTopMargin={0}
    minScreenSupportWidth={1450}
    canNavigate={false}
    tabsList={tagsTabList}
    bottomOffset={150}
    onTabClick={(tabItem) => {
      //
    }}
  />
}

const generalTemplateSearch = (props: IInputElement) => {
  const workflowContext = useContext(WorkflowContext);
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED)
  let isCommunicationViaNode = false;
  let sendViaField: any = undefined;
  let skipEmailTemplate = false;
  (props?.userInputFieldList || []).forEach((inputField) => {
    if (inputField?.key === 'communicationViaNode') {
      isCommunicationViaNode = true
    }
    if (inputField?.key === 'sendVia') {
      sendViaField = inputField;
    }
  });
  if (sendViaField && sendViaField?.value === 'APP') {
    skipEmailTemplate = true;
  }
  return (
    <View>
      {isCommunicationViaNode ? multipleCommunicationInput(props) :
        <TemplateSearch
          skipEmailTemplate={skipEmailTemplate}
          userInputFieldList={props.userInputFieldList}
          defaultTemplateCategory={props.userInputField?.defaultTemplateCategory}
          isHideTemplateCategory={props.userInputField?.isHideTemplateCategory}
          isHideDefaultInput={props.userInputField?.isHideDefaultInput}
          value={props.value}
          isShowError={props.isShowError}
          onChange={(value) => {
            props.onChange(value);
          }}
          isAutomationView={true}
          filterLocationIds={workflowContext?.locationIntersections}
          useExactMatchForFormLocations={isMsoEnabled}
        />
      }
    </View>
  );
};

const generalSMSTemplateSearch = (props: IInputElement) => {
  return (
    <View>
      <SmsTemplateSearch
        isAllowEdit={true}
        value={props.value}
        isShowError={props.isShowError}
        onChange={(value) => {
          props.onChange(value);
        }}
        isAutomationView={true}
      />
    </View>
  );
};

const generalPushNotificationTemplateSearch = (props: IInputElement) => {
  return (
    <View>
      <NotificationTemplateSearch
        value={props.value}
        isShowError={props.isShowError}
        onChange={(value) => {
          props.onChange(value);
        }}
        isAutomationView={true}
      />
    </View>
  );
};

const getVitalSearchField = (props: IInputElement) => {
  return (
    <View>
      <VitalSearch
        value={props.value}
        isShowError={props.isShowError}
        onChange={(value) => {
          props.onChange(value);
        }}
        locationId={props?.locationId}
      />
    </View>
  );
};

const getLabSearchField = (props: IInputElement) => {
  return (
    <View>
      <LabSearch
        value={props.value}
        isShowError={props.isShowError}
        onChange={(value) => {
          props.onChange(value);
        }}
        isAutomationView={true}
      />
    </View>
  );
};
const onChangeSelection = (virtualNumberData: any) => {
  const newValueArray: IVirtualPhoneNumber[] = [];
  virtualNumberData.forEach((element: IVirtualNumberAssignee) => {
    const newValue = {
      phoneNumber: undefined,
      virtualPhoneNumber: element?.name || '',
      userUuid: element?.uuid,
    };
    newValueArray.push(newValue);
  });
  const displayValue = newValueArray.map((element: IVirtualPhoneNumber) => {
    if (element.virtualPhoneNumber && element.virtualPhoneNumber.length > 0) {
      return element.virtualPhoneNumber;
    }
  }).toString() || '';
  return { newValueArray, displayValue };
}

const getUnionOfListsByValue = (
  latestList: any[],
  possibleValueList: any[]
) => {
  const map = new Map<string, any>();
  possibleValueList.forEach((item) => map.set(item?.value, item));
  latestList.forEach((item) => map.set(item?.value, item));

  const unionList = Array.from(map.values());

  return unionList;
};

const getPhoneNumberSearchField = (props: IInputElement) => {
  const intl = useIntl();
  const isShowError = props.isShowError;
  const workflowContext = useContext(WorkflowContext);
  let possibleValueList = props.possibleValueList || [];
  const elementProperty = props?.elementProperty || {};
  const [value, setValue] = useState(() => {
    return {
      ...props.value,
      phoneType: {
        value: props.value,
      }
    }
  });
  const isMsoEnabled = isAccountConfigEnabled(
    CONFIG_CODES.IS_MSO_ENABLED
  );

  let latestList: any[] = [];
  if (props?.nodeType && props?.userInputFieldKey) {
    workflowContext?.nodeMasterDataMap?.[
      props.nodeType
    ]?.userInputFieldList?.some((userInputField: any) => {
      if (props.userInputFieldKey === userInputField.key) {
        latestList = userInputField.possibleValueList;
        return true;
      }
    });

    const unionOfLists = getUnionOfListsByValue(
      latestList,
      possibleValueList
    );
    possibleValueList = unionOfLists;
  }

  return (
    <View flex={1}>
      <HStack flex={1}>
        <HStack flex={2.4}>
          <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400} flex={2} marginTop={1}>
            {'Phone Type'}
            {<Text color="error.500">*</Text>}
          </Text>
        </HStack>
        <HStack flex={7}>
          <View marginY={1} flex={1}>
            <FormControl isInvalid={isShowError && !props?.value?.phoneType?.value}>
              <AntSelect
                suffixIcon={
                  <CaretDownOutlined
                    style={reactStyles.colorGray300}
                  />
                }
                style={reactStyles.height36font13}
                value={props.value?.phoneType?.value || props?.value}
                onChange={(itemValue) => {
                  let displayValue = itemValue;
                  possibleValueList.some((unit) => {
                    if (unit.value === itemValue) {
                      displayValue = unit.displayName;
                    }
                  });
                  const newValue = {
                    ...value,
                    phoneType: {
                      value: itemValue,
                      displayName: displayValue,
                    },
                  };
                  if (
                    itemValue == 'onCallScheduleUsers' &&
                    newValue?.onCallScheduleId &&
                    newValue?.onCallScheduleName
                  ) {
                    props.onChange(
                      newValue,
                      newValue?.onCallScheduleName
                    );
                  } else {
                    props.onChange(newValue);
                  }
                  setValue(newValue);
                }}
                {...elementProperty}
              >
                {possibleValueList.map((data) => (
                  <Option key={data.value} value={data.value}>
                    {data.displayName}
                  </Option>
                ))}
              </AntSelect>
            </FormControl>
          </View>
        </HStack>
      </HStack>
      {(props.value?.phoneType?.value || props?.value) == 'mobile' && (
        <HStack flex={1} marginTop={2}>
          <HStack flex={2.4}>
            <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400} flex={2} marginTop={2.5}>
              {'Recipient'}
              {<Text color="error.500">*</Text>}
            </Text>
          </HStack>
          <HStack flex={7}>
            <View marginTop={2} flex={1}>
              <FormControl flex={1} isInvalid={props.isShowError && !value?.code ? true : false}>
                <Input
                  placeholder="Enter Phone Number"
                  style={styles.height40}
                  // value={props.value}
                  type="number"
                  value={props.value?.phoneNumber}
                  onChangeText={(phoneNumber) => {
                    const newValue = {
                      ...value,
                      virtualPhoneNumber: undefined,
                      phoneNumber: phoneNumber,
                    };
                    props.onChange(newValue);
                    setValue(newValue);
                  }}
                  onBlur={(value) => {
                    // props.onChange(value);
                  }}
                  {...props.elementProperty}
                />
              </FormControl>
            </View>
          </HStack>
        </HStack>
      )}
      {props.value?.phoneType?.value == 'webPhone' && (
        <HStack flex={1} marginTop={2}>
          <HStack flex={2.4}>
            <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400} flex={2} marginTop={2.5}>
              {'Recipient'}
              {<Text color="error.500">*</Text>}
            </Text>
          </HStack>
          <HStack flex={7} marginTop={2}>
            <View flex={1}>
              <VirtualNumberSearch
                value={(props.value?.userUuid && !props.value?.virtualNumberList) ? props.value?.userUuid : props.value}
                isShowError={props.isShowError}
                userSearchType={
                  isMsoEnabled
                    ? USER_SEARCH_TYPE.SELECTED_LOCATION_BASED
                    : USER_SEARCH_TYPE.CURRENT_USER_LOCATION_BASED
                }
                locationIds = {workflowContext?.locationIds || []}
                onChange={(virtualNumberData) => {
                  if (virtualNumberData) {
                    const { newValueArray, displayValue } = onChangeSelection(virtualNumberData);
                    value.virtualNumberList = newValueArray;
                    props.onChange(value, displayValue);
                  } else {
                    value.virtualNumberList = null;
                    props.onChange(value, null);
                  }
                  setValue(value);
                }}
              />
            </View>
          </HStack>
        </HStack>
      )}
      {props.value?.phoneType?.value == 'deskPhone' && (
        <>
          <HStack flex={1} marginTop={2}>
            <HStack flex={2.4}>
              <Text fontWeight={300} fontSize={16} flex={2} marginTop={2.5}>
                {'Number'}
                {<Text color="error.500">*</Text>}
              </Text>
            </HStack>
            <HStack flex={7} marginTop={2}>
              <View flex={1}>
                <VirtualPhoneListSearch
                  isShowError={props?.isShowError}
                  value={props.value?.virtualNumber}
                  onSelection={(virtualNumberData) => {
                    const newValue = {
                      ...value,
                      virtualNumberId: virtualNumberData?.key,
                      virtualNumber: virtualNumberData?.value
                    };
                    props.onChange(newValue);
                    setValue(newValue)
                  }}
                />
              </View>
            </HStack>
          </HStack>
          {props.value?.virtualNumberId ? <HStack flex={1} marginTop={2}>
            <HStack flex={2.4}>
              <Text fontWeight={300} fontSize={16} flex={2} marginTop={2.5}>
                {'IP Phone'}
                {<Text color="error.500">*</Text>}
              </Text>
            </HStack>
            <HStack flex={7} marginTop={2}>
              <View flex={1}>
                <IPPhoneSearchList
                  isShowError={props?.isShowError}

                  phoneNumberId={props.value?.virtualNumberId}
                  value={props.value?.ipPhoneSipUri}
                  onSelection={(phoneData) => {
                    const deskPhoneType = phoneData?.value == 'ALL' ? 'ALL' : 'SINGLE'
                    const newValue = {
                      ...value,
                      ipPhoneSipUri: phoneData?.value,
                      deskPhoneType: deskPhoneType,
                      phoneName: phoneData.key
                    };
                    props.onChange(newValue, phoneData.key);
                    setValue(newValue)
                  }}
                />
              </View>
            </HStack>
          </HStack> : <></>}

        </>
      )}

      {props.value?.phoneType?.value == 'onCallScheduleUsers' && (
        <HStack flex={1} marginTop={2}>
          <HStack flex={2.4}>
            <Text fontWeight={300} fontSize={16} flex={2} marginTop={2.5}>
              {intl.formatMessage({id: "personOnCall"}) }
              {<Text color="error.500">*</Text>}
            </Text>
          </HStack>
          <HStack flex={7} marginTop={2}>
            <View flex={1}>
              <OnCallScheduleSearch
                isShowError={props?.isShowError}
                value={props.value?.onCallScheduleId}
                selectedPhoneTreeType={workflowContext?.phoneTreeType}
                onSelect={(selectedSchedule: ISearchScheduleOnSelectProps | null) => {
                  const scheduleName = selectedSchedule?.scheduleData?.scheduleName || '';
                  const newValue = {
                    ...value,
                    onCallScheduleId: selectedSchedule?.scheduleId || '',
                    onCallScheduleName: scheduleName
                  };
                  props.onChange(newValue, scheduleName);
                  setValue(newValue);
                }}
              />
            </View>
          </HStack>
        </HStack>
      )}
    </View>
  );
};

const getEmployerSearchField = (props: IInputElement) => {
  return (
    <View>
      <EmployerSearch
        value={props.value}
        isShowError={props.isShowError}
        onChange={(value) => {
          props.onChange(value);
        }}
      />
    </View>
  );
};

const getEmployerListSearchField = (props: IInputElement) => {
  return (
    <View>
      <EmployerDropdownList
        isShowError={props.isShowError}
        defaultValue={props?.value?.idList}
        onChange={(actionData: any, extraData) => {
          if (actionData && actionData.length) {
            props.onChange({ idList: actionData, employersNameList: extraData })
          } else if(props.userInputField?.selectedComparisonOperator?.value == COMPARISON_OPERATOR.ANY ) {
            props.onChange({ idList: actionData, employersNameList: extraData })
          } else {
            props.onChange(undefined);
          }
        }}
        selectedComparisonOperatorValue={props.userInputField?.selectedComparisonOperator?.value}
      />
    </View>
  );
};



const getPhysicalActivitySearchField = (props: IInputElement) => {
  return (
    <View>
      <PhysicalActivitySearch
        value={props.value}
        isShowError={props.isShowError}
        onChange={(value) => {
          props.onChange(value);
        }}
      />
    </View>
  );
};

const getAppointmentTypeSearchField = (props: IInputElement, isShowAllAppointmentType: boolean) => {
  const userInputField: any = props?.userInputField;
  const workflowContext = useContext(WorkflowContext);
  return (
    <View>
      <AppointmentTypeSearch
        allowClearAppointmentType={true}
        isShowAllAppointmentType={isShowAllAppointmentType}
        isHideInternalLabel={userInputField?.isHideInternalLabel}
        isHideUser={userInputField?.isHideUser}
        optional={userInputField?.optional}
        value={props.value}
        allowUserMultiSelect={true}
        isShowError={props.isShowError}
        onChange={(value) => {
          props.onChange(value);
        }}
        isAutomationView={true}
        filterLocationIds={workflowContext?.locationIntersections || []}
      />
    </View>
  );
};

const getWebinarSearch = (props: IInputElement) => {
  return (
    <View>
      <WebinarSearch
        value={props?.value}
        isShowError={props.isShowError}
        onChange={(value: any) => {
          props.onChange(value);
        }}
        locationId={props?.locationId}
      />
    </View>
  )
}

const renderInstruction = (props: IInputElement) => {
  return (
  <View>
    <InstructionField value={props?.value}/>
  </View>
  )
}

const getContentSearchField = (props: IInputElement, type: ContentType) => {
  return (
    <View>
      <ContentSearch
        value={props.value}
        isShowError={props.isShowError}
        type={type}
        onChange={(value) => {
          props.onChange(value);
        }}
        isAutomationView={true}
      />
    </View>
  );
};

const getImmunizationSearchField = (props: IInputElement) => {
  return (
    <View>
      <ImmunizationSearch
        value={props.value}
        isShowError={props.isShowError}
        onChange={(value) => {
          props.onChange(value);
        }}
        locationId={props?.locationId}
      />
    </View>
  );
};

const GetPracticeLocationSearchElement = (props: IInputElement) => {
  const [value, setValue] = useState<any>(props.value);
  const [practiceLocationList, setPracticeLocation] = useState([]);
  const [practiceLocationOptionList, setPracticeLocationOptionList] = useState<any>([]);
  const userUuid = getUserUUID();
  const workflowContext = useContext(WorkflowContext);
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  useQuery(UserPracticeLocationQueries.GetUserPracticeLocations, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    variables: {
      userUuid: userUuid,
    },
    onCompleted: (data) => {
      setPracticeLocation(data?.accountLocations || []);
    },
  });
  useEffect(() => {
    if (value) {
      props.onChange(value);
    } else {
      props.onChange(undefined);
    }

  }, [value]);

  useMemo(()=>{
    const accountLocations = workflowContext?.locationIds
    ? workflowContext?.locationIds?.map((locationId: string) => ({ locationId }))
    : [];
    const filteredLocations = isMsoEnabled
      ? practiceLocationList?.filter((location: any) =>
          accountLocations?.some((loc) => loc.locationId === location?.uuid)
        )
      : practiceLocationList;
    setPracticeLocationOptionList(
      filteredLocations?.map((location: any) => (
          <Option
            value={location?.practiceLocation?.uuid}
            title={location?.practiceLocation?.name || ''}
          >
            {location?.practiceLocation?.name || ''}
          </Option>
        ))
    );
  }, [practiceLocationList, workflowContext?.locationIds]);

  return (
    <View>
      <AntSelect
        suffixIcon={
          <CaretDownOutlined
            style={reactStyles.colorGray300}
          />
        }
        allowClear={true}
        status={props.isShowError && !props?.value?.practiceLocation?.uuid ? 'error' : ''}
        onClear={() => {
          setValue(undefined)
        }}
        placeholder={'Select Location'}
        value={practiceLocationList.length ? props?.value?.practiceLocation?.uuid : undefined}
        showSearch={true}
        filterOption={(input, option: any) => {
          return (
            option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
          );

        }}
        onChange={(itemValue) => {
          practiceLocationList.some((location: any) => {
            if (location?.practiceLocation?.uuid === itemValue && itemValue) {
              setValue({ ...location });
              return true;
            }
          });
        }}
      >
        {
          practiceLocationOptionList
        }

      </AntSelect>
      {/* <Select
        height={'36px'}
        fontSize={'13px'}
        selectedValue={props?.value?.practiceLocation?.uuid}
        accessibilityLabel="Select Location"
        placeholder="Select Location"
        _selectedItem={{
          endIcon: <CheckIcon size="5" />,
        }}
        onValueChange={(itemValue) => {
          practiceLocationList.some((location: any) => {
            if (location?.practiceLocation?.uuid === itemValue) {
              setValue({ ...location });
              return true;
            }
          });
        }}
      >
        {practiceLocationList?.map((location: any, index: number) => {
          return (
            <Select.Item
              key={index}
              label={location?.practiceLocation?.name}
              value={location?.practiceLocation?.uuid}
            />
          );
        })}
      </Select> */}
    </View>
  );
};

const getMlovSearchElement = (props: IInputElement, isMlovMultipleSelect:boolean) => {
  const [value, setValue] = useState<any | undefined>(props?.value);
  if (!props?.userInputField?.category) {
    return <></>;
  } else {
    let mlovList =
      getMlovListByCategory(props.userInputField.category, false) ||
      getCareStudioMlovListByCategory(props.userInputField.category, false);
    // const [value] = useState(props?.value);
    if (props?.userInputField?.category === MLOV_CATEGORY.APPOINTMENT_STATUS) {
      mlovList = (mlovList || []).filter(mlov => {
        return APPOINTMENT_STATUS_FOR_AUTOMATON.includes(mlov.code);
      });
    }


    useEffect(() => {
      if(!isMlovMultipleSelect){
      let newValue = value ? { ...value } : undefined;
      if (value?.code && !value?.value) {
        mlovList.some((item) => {
          if (item?.code == value.code) {
            newValue = { ...item }
            return true;
          }
        })
      }
      if (value) {
        props.onChange(newValue);
      } else {
        props.onChange(undefined);
      }
    } else {
      props.onChange(value);
    }
    }, [value]);

    const possibleValueList = props.possibleValueList || [];
    possibleValueList.forEach((mlov1: any) => {
      const isPresent = mlovList.some((mlov2: any) => {
        return mlov1?.code == mlov2?.code;
      })
      if (!isPresent) {
        mlovList.push(mlov1)
      }
    })

    const filterMlovOptions  = useMemo(() => {
      const { notAllowedListMap } = props?.userInputField || {};
      const rootNodeType = props?.rootNodeType || '';

      if (notAllowedListMap) {
        const defaultIgnoreList = notAllowedListMap?.default || [];
        const defaultFilterOptions = mlovList.filter((obj: any) => {
          return !defaultIgnoreList.includes(obj?.code);
        });
        const rootNodeIgnoreList = notAllowedListMap?.rootNodeType?.[rootNodeType] || [];
        const finalFilterOptions = defaultFilterOptions?.filter((obj: any) => {
          return !rootNodeIgnoreList.includes(obj?.code);
        });
        return finalFilterOptions;
      }
      return mlovList;
    }, [props?.userInputField?.notAllowedListMap, props?.rootNodeType]);

    const personGenderList = filterMlovOptions;
    const placeholder =  props?.elementProperty?.placeholder || 'Select Option'
    return (
      <View>
        <AntSelect
          suffixIcon={
            <CaretDownOutlined
              style={reactStyles.colorGray300}
            />
          }
          allowClear={true}
          onClear={() => {
            setValue(undefined)
          }}
          {...(props.elementProperty||{})}
          placeholder={placeholder}
          status={props.isShowError && !value?.code ? 'error' : ''}
          value = {personGenderList?.length?(isMlovMultipleSelect? (value||[])?.map((object:any) => object?.code) : value?.code) : undefined}
          // value={personGenderList.length ? value?.code : undefined}
          showSearch={true}
          filterOption={(input, option: any) => {
            return (
              option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );

          }}
          onChange={(itemValue) => {
            if(isMlovMultipleSelect)
            {
              const selectedOptions:any = []
              personGenderList.forEach((tagger: any)=>{
                if( itemValue.indexOf(tagger.code) != -1){
                  selectedOptions.push({... tagger})
                }
              });
              if(selectedOptions?.length){
                setValue(selectedOptions);
              }else{
                setValue(undefined);
              }
            }
            else
            {
              let newValue = undefined;
              personGenderList.some((tagger: any) => {
                if (tagger.code === itemValue) {
                  newValue = tagger;
                }
              });
              setValue(newValue);
            }
          }}
          mode = {isMlovMultipleSelect? "multiple" : undefined}
        >
          {personGenderList &&
            personGenderList.map((data: any) => {
              return (
                <Option value={data.code} key={data.id} title={data.value || ''}>{data.value || ''}</Option>
              );
            })}

        </AntSelect>
        {/* <Select
          fontSize={'13px'}
          height={'36px'}
          selectedValue={value?.code}
          onValueChange={(itemValue) => {
            let newValue = undefined;
            personGenderList.some((tagger: any) => {
              if (tagger.code === itemValue) {
                newValue = tagger;
              }
            });
            setValue(newValue);
          }}
        >
          {personGenderList &&
            personGenderList.map((data: any) => {
              return (
                <Select.Item
                  key={data.code}
                  label={data.value}
                  value={data.code}
                />
              );
            })}
        </Select> */}
      </View>
    );
  }
};

const getTaggerType = (props: IInputElement) => {
  const taggerTypeList = getMlovListByCategory(MLOV_CATEGORY.TAGGER_TYPE);
  // const [value] = useState(props?.value);
  const [value, setValue] = useState<any | undefined>(props?.value);

  useEffect(() => {
    props.onChange(value);
  }, [value]);

  return (
    <View>
      <AntSelect
        suffixIcon={
          <CaretDownOutlined
            style={reactStyles.colorGray300}
          />
        }
        style={reactStyles.height36font13}
        value={value?.code}
        onChange={(itemValue) => {
          let newValue = undefined;
          taggerTypeList.some((tagger: any) => {
            if (tagger.code === itemValue) {
              newValue = tagger;
            }
          });
          setValue(newValue);
        }}
      >
        {taggerTypeList &&
          taggerTypeList.map((data: any) => (
            <Option key={data.code} value={data.code} label={data.value}>
              {data.value}
            </Option>
          ))}
      </AntSelect>
    </View>
  );
};

const getTagResource = (props: IInputElement) => {
  const labelTypeList = getMlovListByCategory(MLOV_CATEGORY.LABEL_TYPE);
  const [value, setValue] = useState(props?.value);
  useEffect(() => {
    props.onChange(value);
  }, [value]);

  return (
    <VStack space={4} alignItems="center">
      <FormControl
        isInvalid={props.isShowError && !props.value.categoryId}
        flex={2}
      >
        <HStack>
          <View flex={7}>
            <AntSelect
              suffixIcon={
                <CaretDownOutlined
                  style={reactStyles.colorGray300}
                />
              }
              style={reactStyles.height36font13}
              value={props.value?.id}
              onChange={(itemValue) => {
                let newTagValue = undefined;
                labelTypeList.some((tagData) => {
                  if (tagData?.id == itemValue) {
                    newTagValue = tagData;
                  }
                });
                setValue(newTagValue);
              }}
            >
              {labelTypeList &&
                labelTypeList.map((data) => (
                  <Option key={data.id} value={data.id} label={data.value}>
                    {data.value}
                  </Option>
                ))}
            </AntSelect>
          </View>
        </HStack>
      </FormControl>
    </VStack>
  );
};


const getTaskType = (props: IInputElement) => {
  const mlovData = useContext(CommonDataContext);
  const userInputFieldList = props.userInputFieldList || [];
  const [value, setValue] = useState(props.value);
  const [currentCategoryId, setCurrentCategoryId] = useState();

  let isSet = false;

  isSet = userInputFieldList.some((userInputField) => {
    if (userInputField.fieldType === INPUT_FIELD_TYPE.TAG_RESOURCE_SEARCH) {
      if (userInputField?.value?.id != currentCategoryId) {
        setCurrentCategoryId(userInputField?.value?.id);
      }
      return true;
    }
  });

  if (!isSet && !currentCategoryId) {
    const selectedTagCategoryId: any = formatGetCategoryId(
      mlovData.MLOV.LabelType,
      'Contact'
    );
    setCurrentCategoryId(selectedTagCategoryId)
  }

  const [searchString, setSearchString] = useState('')

  const [taskTypes, setTaskTypes] = useState([props.value]);

  const [getTaskTypeByFilter] = useLazyQuery(TaskQueries.GET_TASK_TYPE, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  const [isTaskTypeLoading, setIsTaskTypeLoading] = useState(false);

  useEffect(() => {
    if (value) {
      props.onChange(value);
    } else {
      props.onChange(undefined);
    }
  }, [value]);

  const searchTaskTypesBySearchString = ()=>{
    setIsTaskTypeLoading(true);
    return getTaskTypeByFilter({
      variables: { searchString: `%${(searchString || '')}%` },
      onCompleted: (data) => {

        setTaskTypes(data.getTaskTypes?.taskTypes || []);
        setIsTaskTypeLoading(false);
      },
    });

  }

  useEffect(()=>{
    searchTaskTypesBySearchString()
  }, [searchString])




  return (
    <VStack space={4} alignItems="center">
      <FormControl isInvalid={props.isShowError && !props?.value}>
        <HStack>
          <View flex={7}>

            <AntSelect
              suffixIcon={
                <CaretDownOutlined
                  style={reactStyles.colorGray300}
                />
              }
              placeholder={props?.elementProperty?.placeholder || 'Select Type'}
              allowClear={true}
              status={props.isShowError && !(props?.value) ? 'error' : ''}
              onClear={() => {
                props.onChange(undefined, undefined)
              }}
              value={value?.id}
              loading={isTaskTypeLoading}
              showSearch={true}
              onSearch={debounce((searchString) => {
                setSearchString(searchString)
              }, 300)}
              filterOption={false}
              onChange={(itemValue) => {
                let value = '';
                if (itemValue) {
                  taskTypes.some((data: any) => {
                    if (data?.id === itemValue) {
                      value = data?.value;
                    }
                  });
                  setValue({ id: itemValue, value });
                } else {
                  setValue(undefined)
                }
              }}
            >
              {taskTypes && taskTypes.map((data: any) => {
                return (
                  <Option value={data.id} key={data.id} id={data.id}>{data.value}</Option>
                );
              })}

            </AntSelect>
          </View>
        </HStack>
      </FormControl>
    </VStack>
  );
};

const getTag = (props: IInputElement) => {
  const mlovData = useContext(CommonDataContext);
  const userInputFieldList = props.userInputFieldList || [];
  const [value, setValue] = useState(props.value);
  const [currentCategoryId, setCurrentCategoryId] = useState();

  let isSet = false;

  isSet = userInputFieldList.some((userInputField) => {
    if (userInputField.fieldType === INPUT_FIELD_TYPE.TAG_RESOURCE_SEARCH) {
      if (userInputField?.value?.id != currentCategoryId) {
        setCurrentCategoryId(userInputField?.value?.id);
      }
      return true;
    }
  });

  if (!isSet && !currentCategoryId) {
    const selectedTagCategoryId: any = formatGetCategoryId(
      mlovData.MLOV.LabelType,
      'Contact'
    );
    setCurrentCategoryId(selectedTagCategoryId)
  }

  const [tags, setTags] = useState([props.value]);

  const [getLabelsByFilter] = useLazyQuery(TagQueries.GetLabelsByFilter, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
  });

  const [isTagLoading, setIsTagLoading] = useState(false);

  useEffect(() => {
    if (value) {
      props.onChange(value);
    } else {
      props.onChange(undefined);
    }
  }, [value]);


  return (
    <VStack space={4} alignItems="center">
      <FormControl isInvalid={props.isShowError && !props?.value}>
        <HStack>
          <View flex={7}>

            <AntSelect
              suffixIcon={
                <CaretDownOutlined
                  style={reactStyles.colorGray300}
                />
              }
              placeholder={'Search tags'}
              allowClear={true}
              status={props.isShowError && !(props?.value) ? 'error' : ''}
              onClear={() => {
                props.onChange(undefined, undefined)
              }}
              value={value?.id}
              loading={isTagLoading}
              showSearch={true}
              onSearch={debounce((searchString) => {
                setIsTagLoading(true);
                return getLabelsByFilter({
                  variables: { category: currentCategoryId, searchString: `%${(searchString || '')}%` },
                  onCompleted: (data) => {

                    setTags(formatGetTags(data.labels));
                    setIsTagLoading(false);
                  },
                });
              }, 300)}
              filterOption={false}
              onChange={(itemValue) => {
                let title = '';
                if (itemValue) {
                  tags.some((data: any) => {
                    if (data?.id === itemValue) {
                      title = data?.title;
                    }
                  });
                  setValue({ id: itemValue, title });
                } else {
                  setValue(undefined)
                }
              }}
            >
              {tags && tags.map((data: any) => {
                return (
                  <Option value={data.id} key={data.id} title={data.title}>{data.title}</Option>
                );
              })}

            </AntSelect>
          </View>
        </HStack>
      </FormControl>
    </VStack>
  );
};

const getUserSearchField = (props: IInputElement) => {
  const currentValue = Object.keys(props.value || {}).length? props.value  : undefined;
  return (
    <View>
      <UserAutoComplete
        selectedValue={currentValue}
        isInvalid={props.isShowError && !currentValue}
        onChange={(value) => {
          props.onChange(value);
        }}
      />
    </View>
  );
};

const getContactTypeSearchField = (props: IInputElement) => {
  return (
    <View>
      <ContactTypeSearch
        value={props.value}
        onChange={(value: any) => {
          props.onChange(value);
        }}
      />
    </View>
  );
}

const getEmailReceiverOption = (props: IInputElement) => {
  let nodeType = props?.nodeType;
  const rootNodeType = props?.rootNodeType
  const currentNodeType = nodeType;
  const workflowContext = useContext(WorkflowContext);
  const sendEmailActorOptionList: any = []
  let prevImmediateNode = props.prevImmediateNode;
  if (workflowContext?.nodeMasterDataMap) {

    while (nodeType) {
      // IsOnlyAllowedCurrentOptionList:
      if (workflowContext?.nodeMasterDataMap[nodeType]?.SendEmailActorOptionList) {
        workflowContext?.nodeMasterDataMap[nodeType]?.SendEmailActorOptionList.forEach((data1: any) => {
          if (NODE_TYPE.WORKFLOW_ADD_TASK_V2 == currentNodeType && rootNodeType === NODE_TYPE.ON_PATIENT_TASK_ADD_OR_UPDATE && data1.value !== 'SELECT_USER') {
            return;
          }
          if (NODE_TYPE.WORKFLOW_ADD_TASK_V2 == currentNodeType && rootNodeType === NODE_TYPE.ON_PROVIDER_TASK_ADD_OR_UPDATE && data1.value !== 'MATCHED_MEMBER') {
            return;
          }
          const isMatched = sendEmailActorOptionList.some((data2: any) => {
            return data1.value === data2.value || (NODE_TYPE.WORKFLOW_ADD_TASK_V2 == currentNodeType && ['CARE_JOURNEY_ASSIGNED_CARE_TEAM', 'ALL_TASK_POOL_MEMBER', 'MEMBER_CARE_TEAM', 'MULTIPLE_USER_SEARCH'].includes(data1.value));
          });
          if (!isMatched) {
            sendEmailActorOptionList.push(data1);
          }
        })
      }
      nodeType = prevImmediateNode?.data?.nodeType;
      if (prevImmediateNode && props?.getPreviousImmediateNode) {
        prevImmediateNode = props?.getPreviousImmediateNode(prevImmediateNode?.id)
      }
    }
  }
  return sendEmailActorOptionList;
}

const getUnityAiContextOptions = (props: IInputElement) => {
  let nodeType = props?.nodeType;
  const workflowContext = useContext(WorkflowContext);
  const unityAiContextList: any = [];
  let prevImmediateNode = props.prevImmediateNode;

  const listKey = props?.listKey || UNITY_AI.CONTEXT;

  if (workflowContext?.nodeMasterDataMap) {
    while (nodeType) {
      const nodeData = workflowContext?.nodeMasterDataMap[nodeType];

      const unityAiList = nodeData?.UnityAiContextList?.[listKey];

      if (unityAiList?.items?.length > 0) {
        unityAiList.items.forEach((newItem: any) => {
          const isDuplicate = unityAiContextList.some((existingItem: any) => {
            return existingItem?.key === newItem?.key;
          });

          if (!isDuplicate) {
            unityAiContextList.push(newItem);
          }
        });
      }

      nodeType = prevImmediateNode?.data?.nodeType;
      if (prevImmediateNode && props?.getPreviousImmediateNode) {
        prevImmediateNode = props?.getPreviousImmediateNode(prevImmediateNode?.id);
      }
    }
  }
  return unityAiContextList;
};

const getUnityAiPromptOptions = (props: IInputElement) => {
  let nodeType = props?.nodeType;
  const workflowContext = useContext(WorkflowContext);
  const unityAiContextList: any = [];
  let prevImmediateNode = props.prevImmediateNode;

  if (workflowContext) {
    while (prevImmediateNode) {
      nodeType = prevImmediateNode?.data?.nodeType;
      const userInputFieldList = prevImmediateNode?.data?.metaData?.userInputFieldList;

      if (userInputFieldList) {
        const unityOutputField = userInputFieldList.find(
          (field: any) => field?.key == UNITY_AI.UNITY_OUTPUT
        );
        const unityAICustomField = userInputFieldList.find(
          (field: any) => field?.key == UNITY_AI.UNITY_AI_CUSTOM
        );

        if (unityOutputField || unityAICustomField) {
          const unityOutputValue = unityOutputField?.value || [];
          const unityAICustomValue = unityAICustomField?.value || {};
          return {
            unityOutput: unityOutputValue,
            unityAICustom: unityAICustomValue
          };
        }
      }
      if (prevImmediateNode && props?.getPreviousImmediateNode) {
        prevImmediateNode = props?.getPreviousImmediateNode(prevImmediateNode?.id);
      } else {
        break;
      }
    }
  }
  return unityAiContextList;
};


const getUnityAiOutput = (props: IInputElement) => {
  const [selectedValues, setSelectedValues] = useState<any[]>(
    props?.userInputField?.value && props?.userInputField?.value.length > 0
      ? props?.userInputField?.value
      : []
  );
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  let unityAiOptionList = getUnityAiContextOptions({ ...props, listKey: UNITY_AI.OUTPUT });

  const allowedUnityAiOptionList = props?.userInputField?.elementData?.allowedUnityAiOptionList || [];
  if (allowedUnityAiOptionList?.length) {
    unityAiOptionList = unityAiOptionList?.filter((option1: any) =>
      allowedUnityAiOptionList.some((option2: { value: string }) => option2?.value === option1?.value)
    );
  }

  const handleCheckboxChange = (item: any, selectionType: string, isChecked: boolean) => {
    let updatedSelectedValues = [...selectedValues];

    if (selectionType === UNITY_AI.SINGLE) {
      updatedSelectedValues = isChecked ? [item] : [];
    } else if (selectionType === UNITY_AI.MULTI) {
      if (isChecked) {
        updatedSelectedValues.push(item);
      } else {
        updatedSelectedValues = updatedSelectedValues?.filter(selectedItem => selectedItem?.key !== item?.key);
      }
    }

    setSelectedValues(updatedSelectedValues);
    props.onChange(updatedSelectedValues);
  };

  const handleDropdownChange = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleRemoveTag = (removedTag: any) => {
    const updatedSelectedValues = selectedValues?.filter(item => item.key !== removedTag.key);
    setSelectedValues(updatedSelectedValues);
    props.onChange(updatedSelectedValues);
  };

  return (
    <AntSelect
      mode="multiple"
      open={dropdownOpen}
      placeholder="Select items"
      value={selectedValues?.map(item => item?.key)}
      onDropdownVisibleChange={handleDropdownChange}
      dropdownRender={() => (
        <div>
          {unityAiOptionList?.map((item: any) => {
            const selectionType = item?.selectionType || UNITY_AI.MULTI;
            const isChecked = selectedValues?.some(selectedItem => selectedItem?.key === item?.key);

            return (
              <div key={item.key} style={reactStyles.view4}>
                <Checkbox
                  checked={isChecked}
                  onChange={(e) => handleCheckboxChange(item, selectionType, e.target.checked)}
                >
                  {item.displayName}
                </Checkbox>
                <span style={reactStyles.darkText}>
                  {selectionType === UNITY_AI.SINGLE ? UNITY_AI.SINGLE_SELECT : UNITY_AI.MULTI_SELECT}
                </span>
              </div>
            );
          })}
        </div>
      )}
    >
      {selectedValues?.map(selectedItem => (
        <Tag key={selectedItem?.key} closable onClose={() => handleRemoveTag(selectedItem)}>
          {selectedItem?.displayName}
        </Tag>
      ))}
    </AntSelect>
  );
};

const getUnityAiContext = (props: IInputElement) => {
  const [selectedValues, setSelectedValues] = useState<Record<string, string[]>>(props?.userInputField?.value || {});
  const [dropdownValue, setDropdownValue] = useState<string[]>([]);
  const [activePanels, setActivePanels] = useState<string[]>([]);
  const unityAiOptionList = getUnityAiContextOptions({ ...props, listKey: UNITY_AI.CONTEXT });

  useEffect(() => {
    const initialSelection = props?.userInputField?.value || {};
    const parentDropdownValue = Object.keys(initialSelection)?.map(key => {
        const selectedCount = initialSelection[key]?.length;
        return selectedCount > 0
          ? `${unityAiOptionList?.find((item:any) => item?.key === key)?.displayName} (${selectedCount})`
          : '';
      })
      .filter(item => item);
    setSelectedValues(initialSelection);
    setDropdownValue(parentDropdownValue);
  }, [props?.userInputField?.value]);

  const handleCheckboxChange = (key: string, subItemKey: string, isChecked: boolean) => {
    setSelectedValues((prevState) => {
      const updatedSelection: Record<string, string[]> = { ...prevState };
      if (!updatedSelection[key]) {
        updatedSelection[key] = [];
      }

      const selectionType = unityAiOptionList?.find((item: any) => item?.key === key)?.subItems?.selectionType;

      if (selectionType === UNITY_AI.SINGLE) {
        if (isChecked) {
          updatedSelection[key] = [subItemKey];
        } else if (updatedSelection[key].includes(subItemKey)) {
          updatedSelection[key] = [];
        }
      } else {
        if (isChecked) {
          updatedSelection[key]?.push(subItemKey);
        } else {
          updatedSelection[key] = updatedSelection[key]?.filter(item => item !== subItemKey);
        }
      }

      const updatedDropdownValue = Object.keys(updatedSelection).map(parentKey => {
        const selectedCount = updatedSelection[parentKey].length;
        return selectedCount > 0
          ? `${unityAiOptionList.find((item:any) => item.key === parentKey)?.displayName} (${selectedCount})`
          : '';
      }).filter(item => item);

      setDropdownValue(updatedDropdownValue);
      props.onChange(updatedSelection);
      return updatedSelection;
    });
  };

  const handleSelectAllChange = (key: string, isChecked: boolean) => {
    setSelectedValues((prevState) => {
      const updatedSelection: Record<string, string[]> = { ...prevState };
      const subItems = unityAiOptionList?.find((item: any) => item?.key === key)?.subItems?.items;

      if (isChecked) {
        updatedSelection[key] = subItems?.map((subItem: any) => subItem?.key);
      } else {
        updatedSelection[key] = [];
      }

      const updatedDropdownValue = Object.keys(updatedSelection)?.map(parentKey => {
        const selectedCount = updatedSelection[parentKey]?.length;
        return selectedCount > 0
          ? `${unityAiOptionList?.find((item:any) => item?.key === parentKey)?.displayName} (${selectedCount})`
          : '';
      })?.filter(item => item);

      setDropdownValue(updatedDropdownValue);
      props.onChange(updatedSelection);
      return updatedSelection;
    });
  };

  const handleRemoveTag = (removedTag: string) => {
    const parentKeyToRemove = unityAiOptionList?.find((item:any) => {
      const parentDisplay = `${item?.displayName}`;
      return removedTag?.startsWith(parentDisplay);
    })?.key;

    if (!parentKeyToRemove) return;
    const newDropdownValue = dropdownValue?.filter(tag => tag !== removedTag);
    setDropdownValue(newDropdownValue);

    const updatedSelection: Record<string, string[]> = { ...selectedValues };
    updatedSelection[parentKeyToRemove] = [];

    setSelectedValues(updatedSelection);
    props.onChange(updatedSelection);
  };

  const handlePanelChange = (key: string | string[]) => {
    setActivePanels(typeof key === 'string' ? [key] : key);
  };

  return (
    <View>
      <AntSelect
        mode="multiple"
        style={cssPropertiesAntD.contextSelect}
        clearIcon={false}
        placeholder="Select items"
        value={dropdownValue}
        onDeselect={(tag) => {
          handleRemoveTag(tag);
        }}
        onChange={setDropdownValue}
        dropdownRender={(menu: any) => (
          <Collapse
            activeKey={activePanels}
            onChange={handlePanelChange}
            style={cssPropertiesAntD.contextSelectPanel}
          >
            {unityAiOptionList?.map((item: any) => {
              const selectionType = item?.subItems?.selectionType === UNITY_AI.SINGLE ? UNITY_AI.SINGLE_SELECT : UNITY_AI.MULTI_SELECT;

              return (
                <Panel
                  key={item?.key}
                  header={
                    <HStack alignItems={'center'} justifyContent="space-between" width="100%">
                      <Text>{item?.displayName}</Text>
                      {(activePanels.includes(item?.key) && selectionType === UNITY_AI.MULTI_SELECT)   && (
                        <Checkbox
                          onChange={(e) => handleSelectAllChange(item.key, e.target.checked)}
                          checked={
                            selectedValues[item?.key]?.length === item?.subItems?.items?.length
                          }
                        >
                          Select All
                        </Checkbox>
                      )}
                    </HStack>
                  }
                >
                <View overflowY={'scroll'} maxHeight={200} >
                  {item?.subItems?.items?.map((subItem: any) => (
                    <View key={subItem?.key} padding={3}>
                      <Checkbox
                        checked={selectedValues[item?.key]?.includes(subItem?.key) || false}
                        onChange={(e) => handleCheckboxChange(item?.key, subItem?.key, e.target.checked)}
                      >
                        {subItem?.displayName}
                      </Checkbox>
                    </View>
                  ))}
                </View>
                </Panel>
              );
            })}
          </Collapse>
        )}
      >
        {dropdownValue?.map(tag => (
          <Tag key={tag} closable onClose={() => handleRemoveTag(tag)}>
            {tag}
          </Tag>
        ))}
      </AntSelect>
    </View>
  );
};

const getUnityAiFilter = (props: IInputElement) => {
  const [selectedValues, setSelectedValues] = useState<Record<string, any>>(props?.userInputField?.value || {});
  const dataFormatInfo: any = props?.userInputField?.elementData?.dataFormatInformation || {}
  const unityAiOptionList = getUnityAiPromptOptions({ ...props });
  const unityOutputList = unityAiOptionList?.unityOutput?.map((output: any) => ({
    displayName: output?.displayName,
    key: output?.key,
    dataType: output.outputType?.type,
  })) || [];
  const unityAICustom = unityAiOptionList?.unityAICustom?.map((custom: any) => ({
    displayName: custom?.key,
    key: custom?.key,
    prompt: custom?.prompt,
    dataType: custom?.dataType,
  })) || [];

  const combinedUnityAiOptionList = [...unityOutputList, ...unityAICustom];

  const handleInputChange = useCallback(
    (key: string, value: any, dataType:any) => {
      const updatedValues = {
        ...selectedValues,
        [key]: {
          ...selectedValues[key],
          value: value,
          comparisonOperator: selectedValues[key]?.comparisonOperator || dataFormatInfo[dataType]?.comparisonOperators?.[0]?.value,
        },
      };
      setSelectedValues(updatedValues);
      props.onChange(updatedValues);
    },
    [selectedValues, props]
  );

  const handleDropdownChange = useCallback(
    (key: string, value: string, dataType: string) => {
      const updatedValues = {
        ...selectedValues,
        [key]: {
          ...selectedValues[key],
          comparisonOperator: value,
          dataType: dataType,
        },
      };
      setSelectedValues(updatedValues);
      props.onChange(updatedValues);
    },
    [selectedValues, props]
  );

  const renderInputField = (item: any) => {
    const { key, dataType, displayName } = item;
    const dataInfo = dataFormatInfo[dataType];

    const renderSelect = (options: any[], valueKey: string, onChange: any) => (
      <AntSelect
        value={selectedValues[key]?.[valueKey] || options?.[0]?.value}
        onChange={(value) => onChange(value)}
        style={cssPropertiesAntD.filterNodeInput}
      >
        {options.map((option: any) => (
          <Option key={option?.value} value={option?.value}>
            {option?.displayName}
          </Option>
        ))}
      </AntSelect>
    );

    const renderInput = () => (
      <AntInput
        type={dataType === UNITY_AI.INTEGER ? 'number' : 'text'}
        placeholder={dataInfo?.placeholder}
        value={selectedValues[key]?.value || ''}
        onChange={(e) => {
          const inputValue = e.target?.value;
          const sanitizedValue = dataType === UNITY_AI.STRING ? inputValue.replace(/[^a-zA-Z0-9 ]/g, '') : inputValue;
          handleInputChange(key, sanitizedValue, dataType);
        }}
        style={cssPropertiesAntD.filterNodeInput}
      />
    );

    return (
      <HStack key={key} space={4} alignItems="center">
        <Text width={70}>{displayName || ''} :</Text>
        {renderSelect(dataInfo?.comparisonOperators, 'comparisonOperator', (value:any) =>
          handleDropdownChange(key, value, dataType)
        )}
        {(dataType === UNITY_AI.INTEGER || dataType === UNITY_AI.STRING) && renderInput()}
        {dataType === UNITY_AI.BOOLEAN &&
          renderSelect(dataInfo?.possibleValueList, 'value', (value:any) => handleInputChange(key, value, dataType))}
      </HStack>
    );
  };

  return (
    <VStack space={5}>
      {combinedUnityAiOptionList?.map((item: any) => renderInputField(item))}
    </VStack>
  );
};

const getSendEmailToField = (props: IInputElement) => {
  const isMsoEnabled = isAccountConfigEnabled(
    CONFIG_CODES.IS_MSO_ENABLED
  );
  const workflowContext = useContext(WorkflowContext);
  let emailReceiverOptionList = getEmailReceiverOption(props);
  const allowedSendEmailActorOptionList =
    props?.userInputField?.elementData?.allowedSendEmailActorOptionList || [];

  const notAllowedSendEmailActorOptionList = props?.userInputField?.elementData?.notAllowedSendEmailActorOptionList || [];
  if(notAllowedSendEmailActorOptionList?.length){
    emailReceiverOptionList = emailReceiverOptionList.filter((option1: any) => {
      return !notAllowedSendEmailActorOptionList.some(
        (option2: {value: string}) => {
          return option2?.value == option1?.value;
        }
      );
    });
  }
  if (allowedSendEmailActorOptionList?.length) {
    emailReceiverOptionList = emailReceiverOptionList.filter((option1: any) => {
      return allowedSendEmailActorOptionList.some(
        (option2: {value: string}) => {
          return option2?.value == option1?.value;
        }
      );
    });
  }
  const nodeType = props?.nodeType;
  const currentNodeType = nodeType;
  const isWorkFlowAddTaskNode = currentNodeType === NODE_TYPE.WORKFLOW_ADD_TASK_V2;
  const showLeadTaskMessageBelowField = isWorkFlowAddTaskNode && Object.values(CONDITION_FOR_SEND_EMAIL_TO_FIELD)?.includes(props?.value?.condition);
  if (!isWorkFlowAddTaskNode) {
    emailReceiverOptionList = (emailReceiverOptionList || []).filter((option: any) => {
      return option?.value !== 'TASK_POOL_USER';
    })
  }
  // filter out care giver option for work flow add task node
  if (isWorkFlowAddTaskNode) {
    emailReceiverOptionList = (emailReceiverOptionList || []).filter((option: any) => {
      return option?.value !== 'CARE_GIVER';
    });
  }

  const additionalOptionList =
    props?.userInputField?.elementData?.additionalOptionList || [];
  additionalOptionList.forEach(
    (option: {value: string; displayName: string}) => {
      const isAlreadyPresent = emailReceiverOptionList.some(
        (existingOption: {value: string; displayName: string}) => {
          return option?.value === existingOption?.value;
        }
      );
      if (!isAlreadyPresent) {
        emailReceiverOptionList.push(option);
      }
    }
  );
  return (
    <View>
      <AntSelect
        suffixIcon={
          <CaretDownOutlined
            style={reactStyles.colorGray300}
          />
        }
        allowClear={true}
        onClear={() => {
          props.onChange(undefined, undefined)
        }}
        status={props.isShowError && !props.value?.condition ? 'error' : ''}
        value={props.value?.condition}
        showSearch={true}
        placeholder={'Select Option'}
        filterOption={(input, option: any) => {
          return (
            option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
          );
        }}
        onChange={(itemValue, info: any) => {
          props.onChange({ condition: itemValue, label: info?.title });
        }}
      >
        {emailReceiverOptionList.map((data: any) => {
          return (
            <Option value={data.value} key={data.value} email={data.email} title={data.displayName}>{data.displayName}</Option>
          );
        })}

      </AntSelect>
      {
        showLeadTaskMessageBelowField && (
          <DisplayText
            textLocalId={'notCreatingTaskForLeads'}
            size={'xsMedium'}
            extraStyles={{
              color: Colors.Custom.Gray500,
              marginLeft: 4,
              marginTop: 2,
            }}
          />
        )
      }
      {
        props.value?.condition === 'SELECT_MEMBER_OR_USER' &&
        <VStack marginTop={5} width={'full'}>
          <PatientSearchAndSelect
            label={'channelPatientOrMember'}
            value={props.value?.member ? props.value?.member?.label + ' (' + (props.value?.member?.email ? props.value?.member?.email + ', ' : 'No email, ') + props.value?.member?.type + ')' : ''}
            showEmail={true}
            isProspect={true}
            disableUserSearch={false}
            isDisabled={false}
            placeholder="Search Member or Staff"
            disableLeadsSearch={isWorkFlowAddTaskNode}
            userSearchType={
              isMsoEnabled
                ? USER_SEARCH_TYPE.SELECTED_LOCATION_BASED
                : USER_SEARCH_TYPE.CURRENT_USER_LOCATION_BASED
            }
            onChange={(participant: any, data: any) => {
              // alert(JSON.stringify(data))
              if (data) {
                const member = { key: data?.key, email: data?.email, label: data?.label, value: data?.value, type: data?.type };
                props.onChange({ ...(props.value || {}), member: member });
              } else {
                props.onChange({ ...(props.value || {}), member: undefined });
              }
            }}
            {...testID('patientSearchAndSelect')}
            accountLocationUuids = {workflowContext?.locationIds || []}
          />
        </VStack>
      }
      {
        props.value?.condition === 'SELECT_USER' &&
        <VStack marginTop={5} width={'full'}>
          <UserAutoComplete
            isRequired={true}
            label={'channelUser'}
            selectedValue={props.value?.member}
            isInvalid={props?.isShowError && !props.value?.member}
            isDisabled={false}
            userSearchType= {
              isMsoEnabled
              ? USER_SEARCH_TYPE.SELECTED_LOCATION_BASED
              : USER_SEARCH_TYPE.DEFAULT
            }
            onChange={(user) => {
              if (user) {
                const member = { key: user?.key, email: user?.data?.email, label: user?.label, value: user?.value, type: ParticipantType.staff };
                props.onChange({ ...(props.value || {}), member: member });
              } else {
                props.onChange({ ...(props.value || {}), member: undefined });
              }
            }}
            locationIds={workflowContext?.locationIds || []}
          />
        </VStack>
      }

      {
        props.value?.condition === 'MULTIPLE_USER_SEARCH' &&
        <VStack marginTop={5} width={'full'}>
          <MultiUserSearch
            placeholder={'taskPoolUsers'}
            selectedValue={props?.value?.idList}
            isDisabled={false}
            userSearchType= {
              isMsoEnabled
              ? USER_SEARCH_TYPE.SELECTED_LOCATION_BASED
              : USER_SEARCH_TYPE.DEFAULT
            }
            onChange={(value: any, userNames: any) => {
              if (value?.length) {
                props.onChange({ ...(props.value || {}), idList: value, userNames });
              } else {
                props.onChange({ ...(props.value || {}), idList: [] });
              }
            }}
            locationIds={workflowContext?.locationIds || []}
          />
        </VStack>
      }
      {
        (
          props.value?.condition === 'SELECT_CARE_JOURNEY_USER_ROLE'
          || props.value?.condition === 'CARE_JOURNEY_ASSIGNED_USER_ROLES'
          || props.value?.condition === 'MEMBER_CARE_TEAM_USER_ROLES'
        ) &&
        <VStack marginTop={5} width={'full'}>
          <FormControl
            isRequired={true}
            isInvalid={props.isShowError && !props.value?.roleId}
          >
            <FormControl.Label>
              <DisplayText
                size={'smMedium'}
                extraStyles={{
                  color: Colors.Custom.Gray500,
                }}
                textLocalId={'userRole'}
              />
            </FormControl.Label>
            {
              getUserRoleSearchField({
                ...props,
                onChange: (value: any) => {
                  props.onChange({
                    ...(props.value || {}),
                    ...(value || {})
                  });
                },
              })
            }
          </FormControl>
        </VStack>
      }
      {
        props.value?.condition === 'TASK_POOL_USER' &&
        <VStack marginTop={5} width={'full'}>
          <TaskPoolAndUserSearch
            {...props}
            onPoolChange={(taskPoolId?: string, userId?: string, displayName?: string) => {
                props.onChange({ ...(props.value || {}), taskPoolId: taskPoolId, userId: userId, displayName: displayName});
            }}
          />
        </VStack>
      }
      { emailReceiverOptionList.length < 1 ?
          <Text size={'xsNormal'} color={Colors.FoldPixel.STATUS_ERROR}>
            {'Make sure the node is linked to a parent node to enable the send email node to receive the node context.'}
          </Text>
          : <></>
      }
    </View>
  );
};

const getUserRoleSearchField = (props: IInputElement) => {
  const [value, setValue] = useState(props.value);

  const [accountUserRoles, setAccountUserRoles] = useState<
    {value: string; label: string}[]
  >([]);

  const [accountUserRoleIdMap, setAccountUserRoleIdMap] = useState<
    {value: string; label: string}[]
  >([]);

  const {getAccountRoles} = useAddRoleAndPermission({
    fetchRoleCategory: [
      MLOV_CATEGORY.USER_ROLES,
      MLOV_CATEGORY.GLOBAL_USER_ROLES,
    ],
  });

  const fetchAllAccountRole = async () => {
    const accountRolesResponse = await getAccountRoles();
    const accountUserRoleIdMapData: any = {};
    setAccountUserRoles(
      (accountRolesResponse || []).map(
        (accountRole: {id: string; roleName: string}) => {
          accountUserRoleIdMapData[accountRole?.id] = accountRole?.roleName || ''
          return {
            value: accountRole?.id,
            label: accountRole.roleName,
          };
        }
      )
    );
    setAccountUserRoleIdMap(accountUserRoleIdMapData)
  };

  useEffect(() => {
    fetchAllAccountRole();
  }, []);


  useEffect(() => {
    props.onChange(value);
  }, [value]);

  return (
    <View>
      <FormControl isInvalid={props.isShowError && !value.roleId} flex={2}>
        <AntSelect
          suffixIcon={
            <CaretDownOutlined
              style={reactStyles.colorGray300}
            />
          }
          disabled={!accountUserRoles?.length}
          style={reactStyles.height36font13}
          value={accountUserRoles?.length ? value.roleId : undefined}
          onChange={(itemValue, data) => {
            setValue({
              roleId: itemValue,
              name: accountUserRoleIdMap[itemValue]?.label,
            });
          }}
        >
          {accountUserRoles &&
            accountUserRoles.map((data: any) => (
              <Option key={data.value} value={data.value} label={data.label}>
                {data.label}
              </Option>
            ))}
        </AntSelect>
      </FormControl>
    </View>
  );
};

const getConditionalField = (props: IInputElement) => {
  const isFeetInch = props.displayUnit === VitalUnit.ft_inches;
  const [value, setValue] = useState(props.value);
  const conditionList = props.possibleValueList;
  useEffect(() => {
    props.onChange(value);
  }, [value]);
  if (props.disAllowToRender) {
    return <></>;
  }
  return (
    <VStack>
      <HStack space={4} alignItems="center">
        <FormControl isInvalid={props.isShowError && !value.condition} flex={2}>

          <AntSelect
            suffixIcon={
              <CaretDownOutlined
                style={reactStyles.colorGray300}
              />
            }
            status={props.isShowError && !value.condition ? 'error' : ''}
            allowClear={true}
            onClear={() => {
              props.onChange(undefined, undefined)
            }}
            value={value.condition}
            showSearch={true}
            filterOption={(input, option: any) => {
              return (
                option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
              );
            }}
            onChange={(itemValue) => {
              setValue((prev: any) => ({ ...prev, condition: itemValue }));
            }}
          >
            {conditionList && conditionList.map((data) => {
              return (
                <Option value={data.value} key={data.value} title={data.displayName}>{data.displayName}</Option>
              );
            })}

          </AntSelect>

          {/* <Select
            height={'36px'}
            fontSize={'13px'}
            selectedValue={value.condition}
            onValueChange={(itemValue) => {
              setValue((prev: any) => ({ ...prev, condition: itemValue }));
            }}
          >
            {conditionList &&
              conditionList.map((data: any) => {
                return (
                  <Select.Item
                    key={data.value}
                    label={data.displayName}
                    value={data.value}
                  />
                );
              })}
          </Select> */}
        </FormControl>

        <FormControl isInvalid={props.isShowError && !value.convertedFromValue} flex={1} alignItems="center" flexDir={'row'}>
          <InputNumber
            min={0}
            height={'36px'}
            value={value.convertedFromValue || value.from}
            type="number"
            className={props.isShowError && !value.convertedFromValue ? 'field-error' : ''}
            onChange={(text) => {
              setValue((prev: any) => ({ ...prev, convertedFromValue: text }));
            }}
            style={reactStyles.inputNumberStyle2}
          />
          {isFeetInch && <Text>ft</Text>}
          </FormControl>
          {isFeetInch &&
           // we show this duplicate component to handle 2nd field for inch
          <FormControl isInvalid={props.isShowError && !value.convertedFromValue1} flex={1} alignItems="center" flexDir={'row'}>
            <InputNumber
              min={0}
              height={'36px'}
              value={value.convertedFromValue1}
              type="number"
              className={props.isShowError && !value.convertedFromValue1 ? 'field-error' : ''}
              onChange={(text) => {
                setValue((prev: any) => ({ ...prev, convertedFromValue1: text }));
              }}
              style={reactStyles.inputNumberStyle2}
            />
             {isFeetInch && <Text ml={2}>in</Text>}
          </FormControl>
          }
        {value.condition === 'BTW' && (
          <>
          <FormControl isInvalid={props.isShowError && !value.convertedToValue} flex={1} flexDir={'row'} alignItems="center">
            <InputNumber
              min={0}
              height={'36px'}
              value={value.convertedToValue || value.to}
              type="number"
              className={props.isShowError && !value.convertedToValue ? 'field-error' : ''}
              onChange={(text) => {
                setValue((prev: any) => ({ ...prev, convertedToValue: text }));
              }}
              style={reactStyles.fullWidthHeight36}
              />
               {isFeetInch && <Text>ft</Text>}
          </FormControl>
          {isFeetInch &&
          // we show this duplicate component to handle 2nd field for inch
          <FormControl isInvalid={props.isShowError && !value.convertedToValue1} flex={1} alignItems="center" flexDir={'row'}>
            <InputNumber
              min={0}
              height={'36px'}
              value={value.convertedToValue1}
              type="number"
              className={props.isShowError && !value.convertedToValue1 ? 'field-error' : ''}
              onChange={(text) => {
                setValue((prev: any) => ({ ...prev, convertedToValue1: text }));
              }}
              style={reactStyles.inputNumberStyle2}
            />
            {isFeetInch && <Text ml={2}>in</Text>}
          </FormControl>
          }
          </>
        )}
        {!!props.unit && !isFeetInch && <Text >{props?.displayUnit || props.unit}</Text>}
      </HStack>
    </VStack>
  );
};

const getVitalConditionalField = (
  props: IInputElement,
  prevImmediateNode?: any
) => {
  const [value, setValue] = useState(props.value || {});
  const conditionList = props.possibleValueList;
  let unit = '';
  let displayUnit = '';
  let decimalPrecision = 0;
  let isBP = false;
  let vitalName = props.displayName;
  const systolicName = 'Systolic';
  const diastolicName = 'Diastolic';

  const isVitalSearchNodeInPeer = (props?.userInputFieldList || []).some((inputField) => {
    return inputField.fieldType === INPUT_FIELD_TYPE.VITAL_SEARCH;
  });

  let isWrongNodeConnected = isVitalSearchNodeInPeer ? false : true;

  if (
    isVitalSearchNodeInPeer ||
    (prevImmediateNode &&
    prevImmediateNode.data &&
    prevImmediateNode.data.metaData &&
    prevImmediateNode.data.metaData.userInputFieldList?.length > 0)
  ) {
    const inputFields = isVitalSearchNodeInPeer ? props?.userInputFieldList : prevImmediateNode.data.metaData.userInputFieldList;
    const vitalField = inputFields.filter((field: any) => {
      return field.fieldType === INPUT_FIELD_TYPE.VITAL_SEARCH;
    });
    const foundVital = vitalField[0]?.value;
    if (vitalField.length > 0 && foundVital) {
      isWrongNodeConnected = false;
      isBP = foundVital.loinc === BLOOD_PRESSURE_LOINC;
      vitalName = foundVital.name;
      if (foundVital.units && foundVital.units.length > 0) {
        unit = foundVital.units[0];
      }
      if (foundVital?.displayUnit) {
        displayUnit = foundVital?.displayUnit;
      }
      if (vitalField[0]?.value?.decimalPrecision) {
        decimalPrecision = foundVital?.decimalPrecision;
      }
    }
  }
  const isFeetInch = displayUnit === VitalUnit.ft_inches;
  useEffect(() => {
    let finalValue: any = {};
    if (isBP) {
      if (value.systolic) {
        value.systolic.unit = unit;
        value.systolic.vitalName = systolicName;
      }
      if (value.diastolic) {
        value.diastolic.unit = unit;
        value.diastolic.vitalName = diastolicName;
      }
      finalValue = {
        vitalName: vitalName,
        systolic: value.systolic,
        diastolic: value.diastolic,
      };
    } else {
      if(!!displayUnit && unit !== displayUnit){
        // custom handling for feet inch
        if (isFeetInch) {
          const fromInches = feetAndInchesToInches(value.convertedFromValue,value.convertedFromValue1);
          const fromValueInUnit = convertVitalValues(fromInches,VitalUnit.inches,unit,decimalPrecision || 0);
          const toInches = feetAndInchesToInches(value.convertedToValue,value.convertedToValue1);
          const toValueInUnit = convertVitalValues(toInches,VitalUnit.inches,unit,decimalPrecision || 0);
          value.unit = unit;
          value.vitalName = vitalName;
          value.systolic = undefined;
          value.diastolic = undefined;
          finalValue = {
            ...value,
            from: fromValueInUnit,
            to: toValueInUnit,
            displayUnit: displayUnit,
          }
          props.onChange(finalValue);
          return;
        }
        // calc value in unit from displayUnit to unit
        const fromValueInUnit = convertVitalValues(
          value.convertedFromValue,
          displayUnit,
          unit,
          decimalPrecision || 0
          );
        // calc value in unit from displayUnit to unit
        const toValueInUnit = convertVitalValues(
          value.convertedToValue,
          displayUnit,
          unit,
          decimalPrecision || 0
        );
        value.unit = unit;
        value.vitalName = vitalName;
        value.systolic = undefined;
        value.diastolic = undefined;
        finalValue = {
          ...value,
          displayUnit: displayUnit,
          // actual values in unit
          from: fromValueInUnit,
          to: toValueInUnit,
          // store formated value in display unit
          convertedFromValue: value.convertedFromValue,
          convertedToValue: value.convertedToValue
        }

        props.onChange(finalValue);
        return;
      }
      if (value) {
        value.displayUnit = displayUnit;
        value.unit = unit;
        value.vitalName = vitalName;
        value.systolic = undefined;
        value.diastolic = undefined;
        // if same displayUnit & Unit keep value in from & to
        value.from = value.convertedFromValue || value.from;
        value.to = value.convertedToValue || value.to;
        finalValue = value;
      }
    }

    props.onChange(finalValue);
  }, [value]);

  return (
    <>
    <HStack>
      <HStack flex={2.4}>
        <Text fontWeight={300} fontSize={16} flex={2} marginTop={1}>
          {isBP ? `${systolicName}/${diastolicName}` : vitalName}
          {!props.optional && <Text color="error.500">*</Text>}
        </Text>
      </HStack>
      <HStack flex={7} space={2}>
        <VStack flex={1} space={2}>
          <View marginY={1} flex={1} key={isBP ? `${systolicName}/${diastolicName}` : vitalName}>
            {getConditionalField({
                nodeType: props.nodeType,
                value: isBP ? value.systolic || {} : value || {},
                isShowError: props.isShowError,
                fieldType: props.fieldType,
                nodeMetaData: props.nodeMetaData,
                possibleValueList: conditionList,
                unit: unit,
                displayUnit: displayUnit,
                onChange: (value: any) => {
                  setValue(isBP ? {systolic: value} : value);
                },
              })}
          </View>
          <View marginY={1} flex={1} key={`${diastolicName}${vitalName}`}>
            {
              getConditionalField({
                disAllowToRender: !isBP,
                nodeType: props.nodeType,
                value: value.diastolic || {},
                isShowError: props.isShowError,
                fieldType: props.fieldType,
                nodeMetaData: props.nodeMetaData,
                possibleValueList: conditionList,
                unit: unit,
                displayUnit: displayUnit,
                onChange: (value: any) => {
                  setValue((prev: any) => ({ ...prev, diastolic: value }));
                }
              })
            }
          </View>
        </VStack>
      </HStack>
    </HStack>
    {isWrongNodeConnected && (
      <Text color="error.500" marginTop={2}>
        Abnormal vital node can only be connected next to Vital node.
      </Text>
    )}
    </>
  );
}

const getRiskScoreConditionalField = (
  props: IInputElement,
  prevImmediateNode?: any
) => {
  const [value, setValue] = useState(props.value || {});
  let unitList: string[] = [];
  let isWrongNodeConnected = true;

  if (
    prevImmediateNode &&
    prevImmediateNode.data &&
    prevImmediateNode.data.metaData &&
    prevImmediateNode.data.metaData.userInputFieldList
  ) {
    const inputFields = prevImmediateNode.data.metaData.userInputFieldList;
    const formField = inputFields.filter((field: any) => {
      return field.fieldType === INPUT_FIELD_TYPE.FORM_SEARCH;
    });
    if (
      formField.length > 0 &&
      formField[0].value &&
      formField[0].value.metadata &&
      formField[0].value.metadata.length > 0 &&
      formField[0].value.metadata[0].metadata &&
      formField[0].value.metadata[0].metadata.length > 0 &&
      formField[0].value.metadata[0].metadata[0].states &&
      formField[0].value.metadata[0].metadata[0].states.length > 0
    ) {
      unitList = formField[0].value.metadata[0].metadata[0].states;
      isWrongNodeConnected = false;
    } else {
    }
  }

  useEffect(() => {
    props.onChange(value);
  }, [value]);

  return (
    <HStack space={4} width={230} alignItems="center">
      <FormControl isInvalid={props.isShowError && !value.condition} flex={2}>
        <AntSelect
          suffixIcon={
            <CaretDownOutlined
              style={reactStyles.colorGray300}
            />
          }
          style={reactStyles.height36font13}
          value={value.condition || ''}
          onChange={(itemValue) => {
            setValue((prev: any) => ({ ...prev, condition: itemValue }));
          }}
        >
          {unitList &&
            unitList.map((state: any, index) => (
              <Option key={index} value={state.state} label={state.state}>
                {state.state}
              </Option>
            ))}
        </AntSelect>
        {isWrongNodeConnected && (
          <Text color="error.500" marginTop={2}>
            Please select a form which has risk interpretations configured in
            Assessment node connected to this Condition node.
          </Text>
        )}
      </FormControl>
    </HStack>
  );
};

export const RequiredSourceNodeError = (props: { requiredNodes: string[] }) => {
  return (
    <VStack>
      <Text color="error.500">
        This node can only be connected next to following nodes
      </Text>
      {props.requiredNodes.map((node, index) => {
        return (
          <Text color="error.500" key={`${node}_${index}`}>
            {'  -  '}
            {node}
          </Text>
        );
      })}
    </VStack>
  );
};

const getWorkflowSearchField = (props: IInputElement) => {
  const workflowContext = useContext(WorkflowContext)
  const locationUuids = workflowContext?.locationIntersections || []


  return (
    <View>
      <WorkflowSearch
       triggerCondition={'MicroAutomation'}
        value={props.value}
        isShowError={props.isShowError}
        flowType={FlowType.MICRO_AUTOMATION}
        checkAllowed={true}
        onChange={(value) => {
          props.onChange(value);
        }}
        filteredLocationList={locationUuids}
      />
    </View>
  );
};

const tagDropdownSelect = (props: IInputElement) => {
  return (
    <View>
      <TagdropdownSelect
        isSkipHeight={true}
        value={props.value ? props.value : undefined}
        isShowError={props.isShowError}
        onChange={(value: any) => {
          if (value && value.length) {
            props.onChange(value);
          } else {
            props.onChange(undefined);
          }
        }}
      ></TagdropdownSelect>
    </View>
  );
};

const getOrderTaskFields = (props: IInputElement) => {
  const inputField = (props.userInputFieldList || []).find(userInputField => {
    return userInputField.key === 'entity' &&
      (userInputField.fieldType === INPUT_FIELD_TYPE.MED_SEARCH || userInputField.fieldType === INPUT_FIELD_TYPE.LAB_TEST_SEARCH
        || userInputField.fieldType === INPUT_FIELD_TYPE.IMMUNIZATION_SEARCH)
  });

  const frequencyField = (props.userInputFieldList || []).find(userInputField => {
    return userInputField.fieldType === INPUT_FIELD_TYPE.FREQUENCY
  });
  return (
    <View>
      <OrderTaskFields
        value={props.value}
        isShowError={props.isShowError}
        onChange={(value: any) => {
          props.onChange(value);
        }}
        orderInputField={inputField}
        frequencyField={frequencyField}
        isAutomationView = {true}
      />
    </View>
  );
};


const getAppointmentScheduleDateRangeInputField = (props: IInputElement) => {
  const [value, setValue] = useState(props.value);
  const elementProperty = props?.elementProperty || {};
  const [possibleValueList] = useState(props.possibleValueList || []);
  useEffect(()=>{
    if(!props?.userInputField?.selectedComparisonOperator?.value){
      props.onChange(undefined)
    }
    else if('SAME_DATE' == props?.userInputField?.selectedComparisonOperator?.value && props?.userInputField?.selectedComparisonOperator?.value){
      props.onChange({isSameDay:true, count:undefined});
    } else if(value?.isSameDay){
        const newValue = {...value}
        delete newValue.isSameDay;
        props.onChange(newValue);
    }
  },[props?.userInputField?.selectedComparisonOperator?.value])
  return ('SAME_DATE' != props?.userInputField?.selectedComparisonOperator?.value && props?.userInputField?.selectedComparisonOperator?.value ?  <HStack flex={1}>
      <View paddingRight={1}>
        {
          value?.unit?.code != 'Custom' &&
          <InputNumber
            key={props.value?.count}
            min={0}
            style={reactStyles.inputNumberStyle3}
            value={props.value?.count}
            type="number"
            className={props.isShowError && !props.value ? 'field-error' : ''}
            onChange={(count) => {
              if(count == undefined){
                setValue({ ...value, count: undefined });
              }else{
                setValue({ ...value, count });
              }
            }}
            onBlur={() => {
              props.onChange(value);
            }}
            {...elementProperty}
          />
        }
      </View>
      <View flex={5} paddingRight={1}>
        <AntSelect
          suffixIcon={
            <CaretDownOutlined
              style={reactStyles.colorGray300}
            />
          }
          status={props.isShowError && !props?.value?.unit?.code ? 'error' : ''}
          allowClear={true}
          onClear={() => {
            props.onChange({...props?.value, unit: undefined })
          }}
          {...elementProperty}
          value={props?.value?.unit?.code}
          showSearch={true}
          filterOption={(input, option: any) => {
            return (
              option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          }}
          onChange={(itemValue) => {
            let displayValue = itemValue;
            possibleValueList.some((unit) => {
              if (unit.value === itemValue) {
                displayValue = unit.displayName;
              }
            });
            const newValue = { ...value, unit: { code: itemValue, displayValue } };
            setValue(newValue);
            props.onChange(newValue);
          }}
        >
          {possibleValueList.map((data) => {
            return (
              <Option value={data.value} title={data.displayName} key={data.value}>{data.displayName}</Option>
            );
          })}

        </AntSelect>
        </View>
    </HStack> : <></>
  );
};




const getResourceAbilitiesSearch = (props: IInputElement) => {
  if (!props?.userInputField?.category) {
    return <></>;
  } else {
    const workflowContext = useContext(WorkflowContext);
    const operationName = props?.userInputField?.category;
    let resourceAbilities:any = [];
    if(operationName) {
      resourceAbilities = getPossibleValuesByOperationName(
        operationName,
        '',
        workflowContext?.locationIds?.[0]
      );
    }

    const [value, setValue] = useState<any | undefined>(props?.value);

    useEffect(() => {
      let newValue = value ? { ...value } : undefined;
      if (value?.code && !value?.value) {
        resourceAbilities.some((item:any) => {
          if (item?.code == value.code) {
            newValue = { ...item }
            return true;
          }
        })
      }
      if (value) {
        props.onChange(newValue);
      } else {
        props.onChange(undefined);
      }
    }, [value]);

    const possibleValueList = props.possibleValueList || [];
    possibleValueList.forEach((mlov1: any) => {
      const isPresent = resourceAbilities.some((mlov2: any) => {
        return mlov1?.code == mlov2?.code;
      })
      if (!isPresent) {
        resourceAbilities.push(mlov1)
      }
    })
    const personGenderList = resourceAbilities;
    const placeholder =  props?.elementProperty?.placeholder || 'Select Option'
    return (
      <View>
        <AntSelect
          suffixIcon={
            <CaretDownOutlined
              style={reactStyles.colorGray300}
            />
          }
          allowClear={true}
          onClear={() => {
            setValue(undefined)
          }}
          {...(props.elementProperty||{})}
          placeholder = { placeholder}
          status={props.isShowError && !value?.code ? 'error' : ''}
          value={personGenderList.length ? value?.code : undefined}
          showSearch={true}
          filterOption={(input, option: any) => {
            return (
              option?.display.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );

          }}
          onChange={(itemValue) => {
            let newValue = undefined;
            personGenderList.some((tagger: any) => {
              if (tagger.code === itemValue) {
                newValue = tagger;
              }
            });
            setValue(newValue);
          }}
        >
          {personGenderList &&
            personGenderList.map((data: any) => {
              return (
                <Option value={data.code} key={data.code} display={data.display || ''}>{data.display || ''}</Option>
              );
            })}

        </AntSelect>
      </View>
    );
  }
};


const renderTaskPoolSearch = (props: IInputElement) => {
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED)
  const workflowContext = useContext(WorkflowContext)
  const workflowLocations = workflowContext?.locationIds
  return (
    <TaskPoolSearch
      defaultValue={props?.value?.idList}
      isShowError={props?.isShowError}
      onChange={(value: any, poolName: any) => {
        if (value && value?.length) {
          props.onChange({ idList: value, poolName })
        } else {
          props.onChange(undefined);
        }
      }}
      useAbsoluteLocations={isMsoEnabled}
      locationIdList={workflowLocations || []}
    />
  )
};

const renderTaskLabelSearch = (props: IInputElement) => {
  return (
    <TaskLabelSearch
      defaultValue={props?.value?.idList}
      isShowError={props?.isShowError}
      onChange={(value: any, labelName: any) => {
        if (value && value?.length) {
          props.onChange({ idList: value, labelName })
        } else {
          props.onChange(undefined);
        }
      }}
    />
  )
};

const renderPrescriptionOrderField = (props: IInputElement) => {
  const orderActionCondition = props?.value?.orderActionCondition;
  const taskInfo = props?.value?.taskInfo;
  return (
    <PrescriptionOrderTaskField
      orderActionCondition={orderActionCondition}
      taskInfo={taskInfo}
      isShowError={props?.isShowError}
      onChange={(value: any) => {
        props.onChange(value);
      }}
    />
  )
}

const getVisitNoteSearchField = (props: IInputElement) => {
  const isFormWithHealthComponent = props.value?.isHealthComponentIncluded;
  const intl = useIntl();
  const message = intl.formatMessage({ id: 'genericHealthComponentFormInfoMessageForLead' });
  const workflowContext = useContext(WorkflowContext);
  return (
    <View>
      <FormSearch
        value={props.value}
        isShowError={props.isShowError}
        infoMessage={isFormWithHealthComponent ? message : undefined}
        onChange={(value: any) => {
          props.onChange(value);
        }}
        formLocations={workflowContext?.locationIntersections || []}
      />
    </View>
  );
};

const getMultiSelectAppointmentTypeSearchField = (props: IInputElement) => {
  return (
    <View>
      <MultiSelectAppointmentTypeSearch
        appointmentTypeIds={props?.value?.idList}
        isShowError={props?.isShowError}
        onChange={(value: any, eventName: any) => {
          if (value && value?.length) {
            props.onChange({ idList: value, eventName })
          } else {
            props.onChange(undefined);
          }
        }}
      />
    </View>
  );
};

// search and multi select for care journey
const renderCareJourneySearch = (props: IInputElement) => {
  const workflowContext = useContext(WorkflowContext)
  const locationUuids = workflowContext?.locationIntersections || []
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED)
  return (
    <CareJourneySearch
      defaultValue={props?.value?.idList}
      isShowError={props?.isShowError}
      onChange={(value: any, journeyName: any) => {
        if (value && value?.length) {
          props.onChange({ idList: value, journeyName })
        } else {
          props.onChange(undefined);
        }
      }}
      searchAbsoluteLocations={isMsoEnabled}
      locationIdList={locationUuids}
    />
  )
};
// search and multi select for pop groups
const renderPopGroupSearch = (props: IInputElement) => {
  const workflowContext = useContext(WorkflowContext)
  const locationids = workflowContext?.locationIds || []
  return (
    <GroupSearch
      selectedGroups={props?.value?.idList}
      selectedGroupOptions={props?.value?.selectedOptions || []}
      contactType={POP_GROUP_CONTACT_TYPES.PATIENT}
      locationids={locationids}
      onSelectGroups={(value, options) => {
        if (value?.length && options?.length) {
          const displayValue = options?.map?.((option) => option.name)?.join(', ') || '';
          props.onChange({ idList: value, selectedOptions: options }, displayValue);
        } else {
          props.onChange(undefined);
        }
      }}
    />
  )
};

// search and multi select for location
const renderAccountLocationSearch = (props: IInputElement) => {
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED)
  const workflowContext = useContext(WorkflowContext)
  const workflowLocations = workflowContext?.locationIds

  return (
    <AccountLocationSearchAndSelect
      defaultValue={props?.value?.idList}
      isShowError={props?.isShowError}
      onChange={(value: any, locationName: any) => {
        if (value && value?.length) {
          props.onChange({ idList: value, locationName })
        } else {
          props.onChange(undefined);
        }
      }}
      isMsoEnabled = {isMsoEnabled}
      locationIdList = {workflowLocations || []}
    />
  )
};

const renderOptedOutPatientJourneyTaskField = (props: IInputElement) => {
  return OptedOutJourneyTaskField(props);
}

const renderJourneyAutomationTaskAssignee = (props: IInputElement) => {
  return (<JourneyAutomationTaskAssignee
    assigneeData={props?.value}
    isShowError={props?.isShowError}
    onChange={(value: any) => {
      props.onChange(value);
    }}
  />);
}

const getAppointmentSeriesInputField = (props: IInputElement) => {
  const elementProperty = props?.elementProperty || {};
  interface IAppointmentFilter {
    filterStatus: string;
    isAppliedFortSeriesAppointment: boolean,
    appointmentSeriesConfiguration: {
      occurrenceFrequency: string
    }
  }
  const [appointmentFilterValue, setAppointmentFilterValue] = useState<IAppointmentFilter>(props?.value || {
    filterStatus: 'NO',
    isAppliedFortSeriesAppointment: false,
    appointmentSeriesConfiguration: {
      occurrenceFrequency: 'FIRST'
    }
  });

  const [possibleValueList] = useState(props?.possibleValueList || []);
  useEffect(()=>{
    if(!props?.userInputField?.selectedComparisonOperator?.value){
      props.onChange(undefined)
    }
    else if('NO' == props?.userInputField?.selectedComparisonOperator?.value && props?.userInputField?.selectedComparisonOperator?.value){
      props.onChange({
        isAppliedFortSeriesAppointment:false,
        filterStatus:'NO',
        appointmentSeriesConfiguration: {
          occurrenceFrequency: 'FIRST'
        }
      });
    } else if(appointmentFilterValue?.isAppliedFortSeriesAppointment){
        props.onChange(appointmentFilterValue);
    }
  },[props?.userInputField?.selectedComparisonOperator?.value])
  return ('NO' != props?.userInputField?.selectedComparisonOperator?.value && props?.userInputField?.selectedComparisonOperator?.value ?  <HStack flex={1}>
      <View flex={5} paddingRight={1}>
        <AntSelect
          suffixIcon={
            <CaretDownOutlined
              style={reactStyles.colorGray300}
            />
          }
          status={props.isShowError && !props?.value?.appointmentSeriesConfiguration?.occurrenceFrequency ? 'error' : ''}
          {...elementProperty}
          value={props?.value?.appointmentSeriesConfiguration?.occurrenceFrequency}
          filterOption={(input, option: any) => {
            return (
              option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          }}
          onChange={(itemValue) => {
            props.onChange({
              ...appointmentFilterValue,
              isAppliedFortSeriesAppointment: true,
              appointmentSeriesConfiguration: {
                occurrenceFrequency: itemValue
              }
            });
            setAppointmentFilterValue((prev) => {
              return {
                ...prev,
                appointmentSeriesConfiguration: {
                  occurrenceFrequency: itemValue
                }
              }
            });
          }}
        >
          {possibleValueList.map((data) => {
            return (
              <Option value={data.value} title={data.displayName} key={data.value}>{data.displayName}</Option>
            );
          })}

        </AntSelect>
        </View>
    </HStack> : <></>
  );
};

const renderCareTeamMemberTypeProviders = (props: IInputElement, placeholder: string) => {
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED)
  const workflowContext = useContext(WorkflowContext);
  return (
    <View>
      <MultiUserSearch
        placeholder={placeholder}
        selectedValue={props?.value?.idList}
        isDisabled={false}
        userSearchType= {
          isMsoEnabled
          ? USER_SEARCH_TYPE.SELECTED_LOCATION_BASED
          : USER_SEARCH_TYPE.DEFAULT
        }
        locationIds={workflowContext?.locationIds || []}
        onChange={(value: any, userNames: any) => {
          if (value?.length) {
            props.onChange({ ...(props.value || {}), idList: value, userNames });
          } else {
            props.onChange({ ...(props.value || {}), idList: undefined, userNames: undefined });
          }
        }}
      />
    </View>
  );
};

const renderCustomAttributeFilter = (props: IInputElement) => {
  return (
    <CustomFieldFilter
      customAttributes={props?.value?.customAttributes?.length ? (trimSpace(props?.value.customAttributes)) : []}
      onReset={false}
      onDetailChange={(customAttributes: ICustomAttributesFilters[]) => {
        if (customAttributes?.length) {
          if (customAttributes?.length > 0) {
            props.onChange({ ...(props.value || {}), customAttributes: customAttributes })
          }
        }
        else {
          props.onChange(undefined)
        }

      }}
    />
  )
}

const renderSingleUnityAiFields = (
  props: IInputElement,
  key: string | undefined = undefined,
  value: string | undefined = undefined,
  index = -1,
  dataType: string | undefined = undefined,
  onChange: (value: string | undefined, displayValue: string | undefined, dataType: string | undefined, index: number, isDelete: boolean) => void
) => {
  const dataTypes = props?.userInputField?.elementData?.outputDataTypes || []

  return (
    <FormControl marginBottom={5} isInvalid={props.isShowError && (!key || !value)} key={index}>
      <VStack space={2} alignItems={'center'} width={'100%'}>
        <HStack width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
          <AntInput
            placeholder="Enter Key"
            style={cssPropertiesAntD.customPromptInput}
            maxLength={10}
            showCount
            {...props?.elementProperty}
            value={key}
            onChange={(e) => {
              const text=e.target.value
              const alphanumericText = text.replace(/[^a-zA-Z0-9]/g, '');
              onChange(value, alphanumericText, dataType, index, false);
            }}
          />
          <AntSelect
            suffixIcon={<CaretDownOutlined width={10} height={10} />}
            allowClear
            filterOption={false}
            value={dataType || undefined}
            dropdownRender={(menu) => <>{menu}</>}
            onChange={(selectedValue) => onChange(value, key, selectedValue, index, false)}
            placeholder="Select Datatype"
          >
            {dataTypes?.map((type:any) => (
              <Option key={type?.key} value={type?.type}>
                {type?.displayName}
              </Option>
            ))}
          </AntSelect>

          <Pressable
            style={unityAiCustomPrompt.addBtnDelete}
            onPress={() => onChange(value, key, dataType, index, true)}
          >
           <TrashBinIcon2024/>
          </Pressable>
        </HStack>

        <TextArea
          placeholder="Enter Prompt"
          style={cssPropertiesAntD.customPropmtTextArea}
          maxLength={200}
          showCount
          {...props?.elementProperty}
          value={value}
          onChange={(e) => onChange(e.target.value, key, dataType, index, false)}
        />
      </VStack>
    </FormControl>
  );
};

const renderUnityAiFields = (props: IInputElement) => {

  const [customPromptArray, setCustomPromptArray] = useState<ICustomPromptElement[]>(props?.userInputField?.value || []);

  const onChange = useCallback((value: string | undefined, displayValue: string | undefined, dataType: string | undefined, index: number, isDelete = false) => {
    const fieldList = [...customPromptArray];
    if (index === -1) {
      fieldList.push({
        key: displayValue,
        prompt: value,
        dataType: dataType,
      });
    } else if (isDelete) {
      fieldList.splice(index, 1);
    } else {
      if (fieldList.length > index) {
        fieldList[index] = {
          ...fieldList[index],
          key: displayValue,
          prompt: value,
          dataType: dataType,
        };
      }
    }

    props.onChange(fieldList);
    setCustomPromptArray(fieldList);
  }, [customPromptArray, props]);


  return (
    <VStack space={1}>
      {props?.userInputField?.value?.map((field: any, ind: number) => {
        return renderSingleUnityAiFields(
          props,
          field?.key,
          field?.prompt,
          ind,
          field?.dataType,
          onChange
        );
      })}

      <View style={ unityAiCustomPrompt.addCustomBtnView }>
        <Pressable
          style = { unityAiCustomPrompt.addCustomBtn }
          onPress={() => onChange(undefined, undefined, undefined, -1)}
        >
          <AntDesign
            style={styles.addCustomBtnIcon}
            size={16}
            name="plus"
          />
          <Text style={unityAiCustomPrompt.addCustomBtnText}>Add</Text>
        </Pressable>
      </View>
    </VStack>
  );
};

const renderUnityAICheckBox = (props: IInputElement) => {
  return (
    <HStack space={2}>
      <Checkbox
        disabled={false}
        style={reactStyles.checkboxStyle}
        checked={props?.userInputField?.value}
        onChange={(e) => {
          // console.log(`CHECKBOX Vaue -> ${e.target.checked}`);
          props.onChange(e.target.checked, props?.userInputField?.displayValue);
        }}
      />
      <Text>{props?.userInputField?.displayValue}</Text>
    </HStack>
  );
}

const renderReusableComponentSelect = (props: IInputElement) => {

  const [loading, setLoading] = useState(true);
  const [components, setComponents] = useState([]);
  const [selectedComponents, setSelectedComponents] = useState<string[]>(props?.value || []);

  const [customComponentData, { data, loading: queryLoading }] = useLazyQuery(
    ComponentsQueries.GET_ALL_REUSABLE_COMPONENTS,
    { fetchPolicy: 'no-cache', context: { service: CARESTUDIO_APOLLO_CONTEXT } }
  );

  useEffect(() => {
    const fetchComponents = async () => {
      await customComponentData().then((response: any) => {
        if (
          response?.data &&
          response?.data?.components &&
          response?.data?.components?.length
        ) {
          setComponents(response?.data?.components);
        }
        setLoading(false);
      });
    };

    fetchComponents();
  }, []);

  const handleChange = (value: string[]) => {
    setSelectedComponents(value);
    props?.onChange(value)
  };

  const filterOption = (input: string, option: any) => {
    return option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }

  return (
    <HStack>
      <AntSelect
        suffixIcon={
          <CaretDownOutlined
            style={reactStyles.colorGray300}
          />
        }
        loading={queryLoading}
        mode='multiple'
        allowClear
        showSearch
        style={reactStyles.fullWidth}
        placeholder={props?.elementProperty?.placeholder || 'Select components'}
        value={queryLoading ? [] : selectedComponents}
        onChange={handleChange}
        filterOption={filterOption}
      >
        {components.map((component: any) => (
          <Option key={component?.component?.key} value={component?.component?.key}>
            {component?.name}
          </Option>
        ))}
      </AntSelect>
    </HStack>
  );
}


export const renderSelectCarePlansDropdown = (props: IInputElement) => {
  const [carePlanOptions, setCarePlanOptions] = useState([]);
  const [selectedCarePlanOptions, setSelectedCarePlanOptions] = useState(props?.value || []);
  const [selectedFormDetails, setSelectedFormDetails] = useState<any>({});
  const [showForm, setShowForm] = useState(false);
  const [formIdToView, setFormIdToView] = useState<string | null>(null);
  const [isSelectDisabled, setIsSelectDisabled] = useState(false);
  const intl = useIntl();
  const {intakeOptions} = useFormOptions({})

  const [getFormById] = useLazyQuery(FormsQueries.GET_FORM_BY_ID, {
    fetchPolicy: 'no-cache',
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
  });

  const { data, loading: queryLoading } = useQuery(GET_CARE_PLAN_FORMS, {
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
    fetchPolicy: 'no-cache',
  });

  const onTemplateSelect = useCallback(
    async (formId: string) => {
      if (selectedFormDetails[formId]) {
        setFormIdToView(formId);
        setShowForm(true);
        return;
      }
      try {
        setIsSelectDisabled(true);
        const { data: formResponse } = await getFormById({ variables: { id: formId } });
        setSelectedFormDetails((prev: any) => ({
          ...prev,
          [formId]: formResponse?.form || {},
        }));
        setFormIdToView(formId);
        setShowForm(true);
      } catch (error) {
        setIsSelectDisabled(false);
      }
    },
    [selectedFormDetails, getFormById]
  );

  const handleChange = useCallback(
    (options: any[]) => {
      setShowForm(false);
      setSelectedCarePlanOptions(options);
      props?.onChange?.(options);
    },
    [props?.onChange]
  );

  const filterOption = useCallback(
    (input: string, option: any) => option?.children.toLowerCase().includes(input.toLowerCase()),
    []
  );

  useEffect(() => {
    if (data?.forms) {
      setCarePlanOptions(data.forms);
    }
  }, [data?.forms]);

  const handleRemoveTag = (value: string) => {
    const updatedOptions = selectedCarePlanOptions.filter(
      (option: any) => { return option?.value !== value}
    );
    setSelectedCarePlanOptions((prev:any)=>{
      return {
        ...prev,
        updatedOptions
      }
    });
    props?.onChange(updatedOptions);
  };

  const tagRender = useMemo(
    () => (option: any) => (
      <HStack alignItems='center' backgroundColor={Colors.FoldPixel.GRAY50} margin={1}>
        <Text>{option.label}</Text>
        <Tooltip title={intl.formatMessage({ id: 'viewForm' })} zIndex={10000}>
          <Pressable
            onPress={(e) => {
              e.stopPropagation()
              e.preventDefault()
              onTemplateSelect(option.value);
            }}
          >
            <EyeIcon />
          </Pressable>

        </Tooltip>
        {/* <Divider type='vertical'/>
        <CloseOutlined
        style={{ marginLeft: '8px', color: 'rgba(0, 0, 0, 0.45)', cursor: 'pointer' }}
        onClick={(e) => {
          e.stopPropagation();
          handleRemoveTag(option.value);
        }}
      /> */}
      </HStack>
    ),
    [onTemplateSelect]
  );

  return (
    <FormControl isRequired isInvalid={props?.isShowError}>
      <HStack>
        <AntSelect
          tagRender={tagRender}
          mode="multiple"
          suffixIcon={<CaretDownOutlined style={{ color: '#B0B0B0' }} />}
          labelInValue
          status={props.isShowError && !props.value.length ? 'error' : ''}
          loading={queryLoading}
          showSearch
          disabled={isSelectDisabled}
          style={{ width: '100%' }}
          placeholder={props?.elementProperty?.placeholder || 'Select Care Plan Templates'}
          value={queryLoading ? [] : selectedCarePlanOptions}
          onChange={handleChange}
          filterOption={filterOption}
        >
          {carePlanOptions.map((option: any) => (
            <Option key={option.id} value={option.value}>
              {option.name}
            </Option>
          ))}
        </AntSelect>

        {showForm && formIdToView && selectedFormDetails[formIdToView] && (
          <Drawer
            open={showForm}
            onClose={() => {
              setShowForm(false);
              setIsSelectDisabled(false);
            }}
            afterOpenChange={() => setIsSelectDisabled(false)}
            width="40%"
            zIndex={10000}
          >
            <FHForm
              optionData={intakeOptions}
              components={selectedFormDetails[formIdToView]?.components || []}
              readOnly
            />
          </Drawer>
        )}
      </HStack>
    </FormControl>
  );
};

export const renderSelectCarePlanStatus = (props: IInputElement) => {
  const [value, setValue] = useState({
    ...props.value
  })
  const intl = useIntl()

  useEffect(() => {
    setValue((prev: any) => {
      return {
        ...prev,
        taskTitle: prev?.taskTitle || 'Care Plan Review',
        taskDescription: prev?.taskDescription || 'The task is created and assigned for reviewing care plan for the patient'
      };
    });
  }, [value?.carePlanStatus]);

  return (
    <VStack>
      <FormControl isRequired>
        <HStack space={2}>
          <HStack flex={2.4}>
            <FormControl.Label isRequired>
              <Text fontWeight={300} fontSize={'16px'} flex={2} marginTop={1}>
                {'Select Status'}
              </Text>
            </FormControl.Label>
          </HStack>
          <HStack flex={7}>
            <View marginY={1} flex={1}>

              {getMlovSearchElement(
                {
                  ...props,
                  value: value?.carePlanStatus,
                  onChange: (value: any) => {
                    if (value) {

                      setValue((oldValue: any) => {
                        const newValue = {
                          ...oldValue,
                          carePlanStatus: value
                        }
                        props.onChange(newValue)
                        return newValue;
                      })
                    } else {
                      const newValue = undefined;
                      setValue(newValue)
                      props.onChange(newValue)
                    }
                  }
                },
                false
              )}
            </View>
          </HStack>
        </HStack>
      </FormControl>

      {
        value &&
        value?.carePlanStatus &&
        value?.carePlanStatus?.code === CARE_PLAN_STATUS_CODES.IN_REVIEW &&

        <FormControl>
          <HStack marginTop={5}>
            <HStack flex={2.4} marginRight={2}>
              <FormControl.Label isRequired>
                <Text fontWeight={300} fontSize={16} flex={2} marginTop={1}>
                  {'Select users for review'}
                </Text>
              </FormControl.Label>
            </HStack>
            <HStack flex={7}>
              <View marginY={1} flex={1}>

                {getSendEmailToField(
                  {
                    ...props,
                    value: value?.selectedOptionForReview,
                    onChange: (value: any) => {

                      setValue((oldValue: any) => {
                        const newValue = {
                          ...oldValue,
                          selectedOptionForReview: value
                        }
                        props.onChange(newValue)
                        return newValue;
                      })
                    }
                  }
                )}
              </View>
            </HStack>
          </HStack>

            <HStack marginTop={5}>
              <HStack flex={2.4} marginRight={2}>
                <FormControl.Label isRequired>
                  <Text fontWeight={300} fontSize={16} flex={2} marginTop={1}>
                    {intl.formatMessage({id: 'enterTaskTitle'})}
                  </Text>
                </FormControl.Label>
              </HStack>
              <HStack flex={7}>
                <View marginY={1} flex={1}>

                  {
                    getTextInputField({
                      ...props,
                      value: value?.taskTitle || undefined,
                      onChange: (value: any) => {
                        setValue((oldValue: any) => {
                          const newValue = {
                            ...oldValue,
                            taskTitle: value
                          }
                          props.onChange(newValue)
                          return newValue;
                        })
                      }
                    })
                  }
                </View>
              </HStack>
            </HStack>

            <HStack marginTop={5}>
              <HStack flex={2.4} marginRight={2}>
                <FormControl.Label>
                  <Text fontWeight={300} fontSize={16} flex={2} marginTop={1}>
                  {intl.formatMessage({id: 'enterTaskDescription'})}
                  </Text>
                </FormControl.Label>
              </HStack>
              <HStack flex={7}>
                <View marginY={1} flex={1}>
                  {
                    getTextInputField({
                      ...props,
                      value: value?.taskDescription || undefined,
                      onChange: (value: any) => {
                        setValue((oldValue: any) => {
                          const newValue = {
                            ...oldValue,
                            taskDescription: value
                          }
                          props.onChange(newValue)
                          return newValue;
                        })
                      }
                    })
                  }
                </View>
              </HStack>
            </HStack>
        </FormControl>
      }
    </VStack>
  )
}

const getCareProgramComplexity = (props: IInputElement) => {
  const intl = useIntl();
  const isShowError = props.isShowError;
  const userInputFieldList = props?.userInputFieldList
  const workflowContext = useContext(WorkflowContext);
  let isDisabled = true
  if (userInputFieldList?.[0]?.key === 'careProgramTypes' && userInputFieldList[0]?.value?.code === CARE_PROGRAM_TYPES.TCM || userInputFieldList?.[0]?.value?.code === CARE_PROGRAM_TYPES.CCM) {
    isDisabled = false
  }
  const possibleValueList = (props.possibleValueList || []).filter((value) => {
    if (value?.flowTypeList?.length) {
      if (value?.flowTypeList?.indexOf?.(workflowContext.flowType) !== -1) {
        return true;
      } else {
        return false;
      }
    } else if (value?.disAllowForFlowTypeList?.length && value?.disAllowForFlowTypeList?.indexOf?.(workflowContext.flowType) !== -1) {
      return false;
    }
    return true;
  });
  const elementProperty = props?.elementProperty || {};
  const placeholder = props?.elementProperty?.placeholder || 'Select Option'
  const [isSelected , setIsSelected] = useState(false)
  const handleSelected = (itemValue:string)=>{
    if(itemValue){
      setIsSelected(true)
    }
    else{
      setIsSelected(false)
    }
  }
  useEffect(() => {
    if (isDisabled) {
      props?.onChange(undefined, undefined);
    }
  }, [isDisabled]);
  return (
    <FormControl isInvalid={isShowError && !props.value}>
      <AntSelect
        disabled={isDisabled}
        suffixIcon={
          <CaretDownOutlined
            style={reactStyles.colorGray300}
          />
        }
        status={(isShowError && (props.value === undefined || props?.value === '')) ? 'error' : ''}
        allowClear={true}
        onClear={() => {
          props.onChange(undefined, undefined)
        }}
        {...elementProperty}
        placeholder={placeholder}
        value={(props.value !== undefined && props.value !== '') ? props.value: undefined}
        showSearch={true}
        filterOption={(input, option: any) => {
          return (
            option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
          );
        }}
        onChange={(itemValue) => {
          let displayValue = itemValue;
          possibleValueList.some((unit) => {
            if (unit.value === itemValue) {
              displayValue = unit.displayName;
            }
          });

          props.onChange(itemValue, displayValue);
          handleSelected(itemValue)
        }}
      >
        {possibleValueList.map((data) => {
          return (
            <Option value={data.value} key={data.value} title={data.displayName}>{data.displayName}</Option>
          );
        })}

      </AntSelect>
    </FormControl>
  );
};



const getCareProgramOutreachNumber = (props: IInputElement) => {
  const [value, setValue] = useState<IOutreachNumberInputElement>(props.value as IOutreachNumberInputElement);
  const typeMlovList = getMlovListByCategory(props.userInputField?.typeMlovCategory || '', false) || getCareStudioMlovListByCategory(props.userInputField?.typeMlovCategory || '', false);

  const statusMlovList = getMlovListByCategory(props.userInputField?.statusMlovCategory || '', false) || getCareStudioMlovListByCategory(props.userInputField?.statusMlovCategory || '', false);

  const communicationModeMlovList = getMlovListByCategory(props.userInputField?.communicationModeMlovCategory || '', false) || getCareStudioMlovListByCategory(props.userInputField?.communicationModeMlovCategory || '', false);


  const renderSelect = (
    displayValue: string | undefined,
    placeholder: string | undefined, 
    options: any[],
    width = '40%',
    type: 'type' | 'status' | 'communicationMode'
  ) => {
    const handleChange = (selectValue: string) => {
      const selected = options.find(item => item.value === selectValue);
      setValue((prev: any) => ({
        ...prev,
        [type]: selected
      }));
    };

    return (
      <AntSelect
        style={{...reactStyles.height36borderRadius10, width}}
        value={displayValue}
        placeholder={placeholder}
        onChange={handleChange}
        allowClear
        onClear={() => {
          setValue((prev: any) => ({
            ...prev,
            [type]: undefined
          }));
          props.onChange(value);
        }}
        onBlur={() => {
          props.onChange(value);
        }}
      >
        {options.map((option) => (
          <AntSelect.Option key={option.value} value={option.value}>
            {option.value}
          </AntSelect.Option>
        ))}
      </AntSelect>
    );
  };

  return (
    <Stack direction={'column'} space={2}>
      <Stack direction={'row'} space={2}>
        <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400} marginTop={1} width={'33.33%'}>
          {props.userInputField?.displayName}
        </Text>
        <AntSelect
          disabled={props.isViewOnly}
          style={{...reactStyles.textOverflowEllipsis, width: '33.33%'}}
          suffixIcon={<CaretDownOutlined style={reactStyles.colorGray300} />}
          status={
            props.isShowError && !value?.selectedComparisonOperator
              ? 'error'
              : ''
          }
          allowClear={true}
          onClear={() => {
            setValue((prev: any) => ({
              ...prev,
              selectedComparisonOperator: undefined,
            }));
          }}
          placeholder={
            props.userInputField?.elementProperty
              ?.comparisonOperatorPlaceholder
          }
          value={value?.selectedComparisonOperator?.value}
          showSearch={true}
          filterOption={(input, option: any) => {
            return (
              option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          }}
          onChange={(itemValue) => {
            let displayValue = itemValue;
            const operatorList = props.userInputField?.operatorList || [];
            operatorList.some((unit) => {
              if (unit.value === itemValue) {
                displayValue = unit.displayName;
              }
            });
            setValue((prev: any) => ({
              ...prev,
              selectedComparisonOperator: {
                displayName: displayValue,
                value: itemValue,
              },
            }));
          }}
          onBlur={() => {
            props.onChange(value);
          }}
        >
          {(props.userInputField?.operatorList || []).map((data) => {
            return (
              <AntSelect.Option
                value={data.value}
                key={data.value}
                title={data.displayName}
              >
                {data.displayName}
              </AntSelect.Option>
            );
          })}
        </AntSelect>
        <InputNumber
          min={0}
          style={{...reactStyles.height36borderRadius10, width: '33.33%'}}
          value={value?.number}
          type="number"
          onBlur={() => {
            props.onChange(value);
          }}
          onChange={(value) => {
            setValue((prev: any) => ({
              ...prev,
              number: value,
            }));
          }}
          placeholder={props.userInputField?.elementProperty?.numberPlaceholder}
        />
      </Stack>
      <Stack direction={'row'} space={2}>
        {renderSelect(
          value?.type?.value,
          props.userInputField?.elementProperty?.typePlaceholder,
          typeMlovList,
          '33.33%',
          'type'
        )}
        {renderSelect(
          value?.status?.value,
          props.userInputField?.elementProperty?.statusPlaceholder,
          statusMlovList,
          '33.33%',
          'status'
        )}
        {renderSelect(
          value?.communicationMode?.value,
          props.userInputField?.elementProperty?.communicationModePlaceholder,
          communicationModeMlovList,
          '33.33%',
          'communicationMode'
        )}
      </Stack>
    </Stack>
  );
}
