import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const CarePlanStatusFailed = () => (
  <Svg
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <Path fill="#FCEAE9" d="M.5 8a7.5 7.5 0 1 1 15 0 7.5 7.5 0 0 1-15 0"></Path>
    <Path
      stroke="#D92D20"
      d="M.5 8a7.5 7.5 0 1 1 15 0 7.5 7.5 0 0 1-15 0Z"
    ></Path>
    <Path
      fill="#D92D20"
      d="M11.023 5.687a.5.5 0 1 0-.707-.708zm-6.04 4.626a.5.5 0 1 0 .707.707zm.706-5.334a.5.5 0 0 0-.707.708zm4.627 6.04a.5.5 0 0 0 .707-.706zm0-6.04L7.649 7.646l.707.707 2.667-2.666zM7.649 7.646l-2.667 2.667.708.707 2.666-2.667zm-2.667-1.96L7.65 8.354l.707-.707L5.69 4.98zM7.65 8.354l2.667 2.667.707-.707-2.667-2.667z"
    ></Path>
  </Svg>
);

export default React.memo(CarePlanStatusFailed);
