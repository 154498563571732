import { Drawer, Popover, Timeline, Tooltip } from "antd";
import { useMediaQuery, View, Text, HStack, Pressable, Button, VStack, Divider, Spinner, Box, useToast } from "native-base";
import { BUTTON_TYPE, IPAD_MINI_WIDTH, IPAD_WIDTH } from "../../../../constants";
import { Colors } from "../../../../styles/Colors";
import { ModalActionTitle } from "../../../common/ModalActionTitle/ModalActionTitle";
import { TranscriptionProps } from "./interface";
import AntIcon from 'react-native-vector-icons/AntDesign';
import React, { useEffect, useState } from "react";
import { getUserData, getUserFullName, getUserUUID } from "../../../../utils/commonUtils";
import { AddNoteView } from "../../../common/AddNoteView";
import Feather from "react-native-vector-icons/Feather";
import { DisplayText } from "../../../common/DisplayText/DisplayText";
import { styles, reactStyles } from './TranscptionActionsStyle';
import TasksSvg from "../../../common/Svg/SideMenu/TasksSvg";
import AddOrUpdateTask from "../../../common/AddTask/AddOrUpdateTask";
import { ParticipantType } from "../../../common/CalendarWidget/ParticipantAutoComplete/ParticipantEnum";
import { getTranscriptionDataApi, getTranscriptionDataFromAwsApi } from "../Conversations/ConversationChannelNew/ConversationAPIService";
import { parseTwilioRecordingUrl } from "../CallLogs/CallLogsUtils";
import { ITranscriptionParams } from "../CallLogs/interface";
import { useIntl } from "react-intl";
import { TwilioRecordingUrlPrefix } from "../../../../constants/Configs";
import { ToastType, showToast } from "../../../../utils/commonViewUtils";
import { StyleSheet } from "react-native";

const { Item } = Timeline;

const TranscriptionsView = (props: TranscriptionProps) => {
    const {
      callLogEntry,
      isVisible,
      onClose,
      contactInfo,
      conversationMessage,
      selectedConversation,
      onClinicalNotesOpen,
    } = props;
    const userUUID = getUserUUID();
    const userData = getUserData();
    const userFullName = getUserFullName();
    const toast = useToast();
    const intl = useIntl();
    const [state, setState] = useState({
        addTaskOpen: false,
        addNotesOpen: false,
        isOpen: false,
        callLogEntry: callLogEntry || {} as any,
        isLoading: false,
    });
    const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
        { maxWidth: IPAD_WIDTH },
        { maxWidth: IPAD_MINI_WIDTH },
    ]);

    useEffect(() => {
        if (callLogEntry?.transcription?.length) {
            setState((prev) => {
                return {
                    ...prev,
                    callLogEntry: props.callLogEntry,
                };
            });
        } else if (
          callLogEntry.recording?.length
            ? callLogEntry.recording?.includes(TwilioRecordingUrlPrefix)
            : callLogEntry.callrecord?.includes(TwilioRecordingUrlPrefix)
        ) {
          getTranscriptionDataFromTwilio();
        } else {
          getCallTranscriptionData();
        }
    }, [callLogEntry])

    const getCallTranscriptionData = async ()=> {
        setState((prev) => {
            return {
                ...prev,
                isLoading: true,
            };
        });
        const transcriptionParams = {
          callSid: callLogEntry.callSid,
          ...parseTwilioRecordingUrl(
            callLogEntry?.recording
              ? callLogEntry.recording
              : callLogEntry.callrecord || ''
          ),
        } as ITranscriptionParams;

        if (
          !transcriptionParams.accountSid &&
          !transcriptionParams.recordingSid
        ) {
          showToast(
            toast,
            intl.formatMessage({id: 'apiErrorMsg'}),
            ToastType.error
          );
          setState((prev) => {
            return {
                ...prev,
                isLoading: false,
                callLogEntry: {
                    ...callLogEntry,
                    transcription: '',
                }
            };
        });
          return;
        }
        const transcriptionRes= await getTranscriptionDataFromAwsApi(transcriptionParams)
        .catch((err)=> {
            setState((prev) => {
                return {
                    ...prev,
                    isLoading: false,
                    callLogEntry: {
                        ...callLogEntry,
                        transcription: '',
                    }
                };
            });
        })
        if (transcriptionRes?.data) {
          setState((prev) => {
            return {
              ...prev,
              isLoading: false,
              callLogEntry: {
                ...callLogEntry,
                transcription: transcriptionRes.data?.transcriptText,
              },
            };
          });
        }
    }

    const getTranscriptionDataFromTwilio = async ()=> {
        setState((prev) => {
            return {
                ...prev,
                isLoading: true,
            };
        });
        const callId= callLogEntry?.id
        const transcriptionRes= await getTranscriptionDataApi(callId)

        if(transcriptionRes?.data){
            setState((prev) => {
                return {
                    ...prev,
                    isLoading: false,
                    callLogEntry: {
                        ...state.callLogEntry,
                        transcription: transcriptionRes?.data?.data.transcription,
                    }
                };
            });
        }
    }

    const handleVisibleChange = (visible: any) => {
        setState((prev) => {
            return {
                ...prev,
                isOpen: visible,
            };
        });
    };

    const content = (
        <VStack style={componentStyles.contentVStack}>
            <Pressable
                onPress={() => {
                    setState((prev) => {
                        return {
                            ...prev,
                            addTaskOpen: !state.addNotesOpen,
                            isOpen: !state.isOpen,
                        };
                    });
                }}
            >
                <HStack style={[styles.mainMenuContent, styles.firstMenu]}>
                    <View size={18} alignSelf={'center'}>
                        <TasksSvg defaultColor={Colors.secondary['800']}></TasksSvg>
                    </View>
                    <Text style={styles.mainMenuTxt}>
                        <DisplayText size={'smRegular'} extraStyles={{color: Colors.FoldPixel.GRAY400}} textLocalId={'Create Task'} />
                    </Text>
                </HStack>
                <Divider my={1} style={styles.dividerStyle} />
            </Pressable>

        {(state.callLogEntry?.contact?.contactId ||
          contactInfo?.contactData?.id) && (
          <>
            <Pressable
                onPress={() => {
                    setState((prev) => {
                        return {
                            ...prev,
                            addNotesOpen: !state.addNotesOpen,
                            isOpen: !state.isOpen,
                        };
                    });
                }}
            >
                <HStack style={[styles.mainMenuContent, styles.firstMenu, styles.lastMenu]}>
                    <AntIcon
                        name="filetext1"
                        style={styles.antIcon}
                        size={20}
                    />
                    <Text style={styles.mainMenuTxt}>
                        <DisplayText size={'smRegular'} extraStyles={{color: Colors.FoldPixel.GRAY400}} textLocalId={'Create Notes'} />
                    </Text>
                </HStack>
            </Pressable>

            {selectedConversation?.uuid && conversationMessage?.uuid && (
              <Pressable
                onPress={() => {
                  setState((prev) => {
                    return {
                      ...prev,
                      isOpen: !state.isOpen,
                    };
                  });
                  onClinicalNotesOpen?.(state.callLogEntry.transcription || '');
                }}
              >
                <HStack
                  style={[
                    styles.mainMenuContent,
                    styles.firstMenu,
                    styles.lastMenu,
                  ]}
                >
                  <AntIcon
                    name="filetext1"
                    style={styles.antIcon}
                    size={20}
                  />
                  <Text style={styles.mainMenuTxt}>
                    <DisplayText
                      size={'smMedium'}
                      textLocalId={'Clinical Note'}
                    />
                  </Text>
                </HStack>
              </Pressable>
            )}
          </>
        )}
      </VStack>
    );

    return (
        <Drawer
            destroyOnClose
            placement="right"
            onClose={() => {
                onClose();
            }}
            visible={isVisible}
            closable={false}
            width={isIPadScreen || isIPadMiniScreen ? '70%' : '30%'}
            title={ <ModalActionTitle
              title={'Transcription'}
              titleColor={''}
              buttonList={[
                  {
                      show: true,
                      id: 1,
                      btnText: 'cancel',
                      textColor: Colors.Custom.mainSecondaryBrown,
                      variant: BUTTON_TYPE.SECONDARY,
                      isTransBtn: false,
                      onClick: () => {
                          onClose()
                      },
                  },
              ]}
          />}
        >
            {state.isLoading ?
                <Spinner size="sm" />
                :
                <>
                    {state?.callLogEntry?.transcription && state?.callLogEntry?.transcription !== '#NO_TRANSCRIPTION' ?
                        <Item pending dot={<span></span>}>
                            <HStack flex={1} shadow={'2'} style={componentStyles.transcriptionHStack}>
                                <Text flex={1} size={'smNormal'} color={Colors.Custom.Gray500}>
                                    {state.callLogEntry?.transcription || ''}
                                </Text>
                                <View style={componentStyles.moreButtonView}>
                                    <Popover
                                        key={state.callLogEntry?.id}
                                        overlayInnerStyle={reactStyles.popover}
                                        overlayStyle={reactStyles.padding0}
                                        overlay={reactStyles.padding0}
                                        style={reactStyles.popover}
                                        placement={'left'}
                                        trigger="click"
                                        visible={state.isOpen}
                                        onVisibleChange={handleVisibleChange}
                                        content={content}
                                    >
                                        <Button
                                            onPress={() => {
                                                setState((prev) => {
                                                    return {
                                                        ...prev,
                                                        isOpen: true,
                                                    };
                                                });
                                            }}
                                            style={componentStyles.moreButton}
                                        >
                                            <Feather
                                                name="more-vertical"
                                                style={componentStyles.featherIcon}
                                            />
                                        </Button>
                                    </Popover>
                                </View>
                            </HStack>
                        </Item>
                        :
                        <View mt={20}>
                            <Text flex={1} size={'mdMedium'} color={Colors.FoldPixel.GRAY250}>
                                {'No transcription available'}
                            </Text>
                        </View>
                    }
                </>
            }
          {state.addTaskOpen && (
            <AddOrUpdateTask
              extraData={{
                description: state.callLogEntry?.transcription || '',
              }}
              saveBtnText={'Send'}
              successMessage={'taskSendMsg'}
              assignee={{
                value: userUUID,
                label: userFullName,
                key: userUUID,
                details: userData,
                type: ParticipantType.staff,
              }}
              isVisible={state.addTaskOpen}
              onComplete={() => {
                setState((prev) => {
                  return {
                    ...prev,
                    addTaskOpen: false,
                  };
                });
              }}
              onCancel={() => {
                setState((prev) => {
                  return {
                    ...prev,
                    addTaskOpen: false,
                  };
                });
              }}
            />
          )}

        {state.addNotesOpen && (
          <AddNoteView
            selectedData={{content: state.callLogEntry?.transcription || ''}}
            contactId={state.callLogEntry?.contact?.contactId || contactInfo?.contactData?.id}
            onFormActionPerformed={(actionCode: string) => {
              setState((prev) => {
                return {
                  ...prev,
                  addNotesOpen: false,
                };
              });
            }}
          />
        )}
      </Drawer>
    );
}

const componentStyles = StyleSheet.create({
    contentVStack: {
        padding: 0,
        borderRadius: 16,
    },
    antIcon: {
        color: Colors.secondary['800'],
    },
    transcriptionHStack: {
        padding: 10,
        borderRadius: 16,
        backgroundColor: Colors.Custom.Gray50,
    },
    moreButtonView: {
        marginRight: 0,
        marginTop: 0,
    },
    popoverOverlayInner: {
        padding: 0,
        borderRadius: 16,
    },
    popoverOverlay: {
        padding: 0,
    },
    popover: {
        padding: 0,
        borderRadius: 16,
    },
    moreButton: {
        backgroundColor: 'transparent',
    },
    featherIcon: {
        marginLeft: 2,
        marginRight: 2,
        fontSize: 14.29,
        color: Colors.Custom.Gray600,
    },
});

export default TranscriptionsView;
