import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {notification, Skeleton} from 'antd';
import _, {cloneDeep, isEqual} from 'lodash';
import {
  Box, Divider, FormControl,
  HStack,
  Icon,
  Input,
  Select,
  Spacer,
  Spinner,
  Text,
  useMediaQuery,
  useToast,
  View,
  VStack,
  WarningOutlineIcon
} from 'native-base';
import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {Dimensions, Pressable} from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {useLocation, useNavigate} from 'react-router-dom';
import {ADD_NEW_REASON_FOR_VISIT, ANALYTICS_RESOURCE, BUTTON_TYPE, FORM_CONTACT_PERMISSION_SUBJECT, IPAD_MINI_WIDTH, IPAD_WIDTH, MLOV_CATEGORY} from '../../../../../constants';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../constants/Configs';
import {FORM_STATUS_CODE} from '../../../../../constants/MlovConst';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {EHRWiseLocations, IEhrCapability, IMlovByCategory} from '../../../../../Interfaces';
import {ComponentsQueries, FormsQueries} from '../../../../../services';
import {getQuestionnaire} from '../../../../../services/CommonService/AidBoxService';
import {GET_FORM_CATEGORIES, UPDATE_NOTE_SHARE_WITH_EHR, UPDATE_NOTE_SHOWN_TO_PATIENT} from '../../../../../services/Forms/FormsQueries';
import {GET_ALL_REASON_FOR_VISIT, GET_MDM_DATA} from '../../../../../services/MDM/MDMQueries';
import {Colors} from '../../../../../styles';
import {testID, TestIdentifiers} from '../../../../../testUtils';
import {
  getAccountUUID,
  getFormPrintURL,
  getUserActionObjectFromActionCode,
  getUserUUID,
  isEnableCareProgram,
  isMultiTenancyEnabled
} from '../../../../../utils/commonUtils';
import {
  getMlovIdFromCode,
  getMlovListFromCategory
} from '../../../../../utils/mlovUtils';
import FHCustomSwitch from '../../../../common/CustomSwtich/FHCustomSwitch';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import InfoSvg from '../../../../common/Svg/InfoSvg';
import {ITableTopBarProps, TableTopBar} from '../../../../common/TableTopBar';
import {handleCreateFormAction} from '../../../../common/UsageLevel/UsageLevelHandlers';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {FHIRQuestionnaireObject, Question, Resource} from '../../../../PersonOmniView/MiddleContainer/PersonDetailsView/Questionnaire/interfaces';
import { getTypeByQuestion } from '../../../../PersonOmniView/MiddleContainer/PersonDetailsView/Questionnaire/QuestionnaireUtils';
import PublicFormView from '../../../../PublicPages/PublicForm/PublicFormView';
import {FHFormBuilder, Utils} from '../../FHFormio';
import { getQuestionListFromQuestionnaire } from '../../FHFormio/CustomComponents/SocialHistory/AddOrUpdateSocialHistory/AddOrUpdateSocialHistoryHelper';
import {IForm, IFormCategory} from '../../interfaces';
import {
  errorMessages,
  FormRequiredField,
  isInvalid,
  isValidForm
} from '../FormErrorHandler';
import {
  IFormMetaData,
  IRiskScoreData
} from '../RiskScoreConfiguration/RiskScoreConfiguration';
import {ALLOWED_QUESTION_TYPE_IN_RISK_SCORE, generateUniqueKey, getComponentByQuestionType, getComponentId, isRiskScoreEnabledHealthComponent, isRiskScoreTypeQuestionsExist} from '../RiskScoreConfiguration/RiskScoreHelper';
import {SectionWiseRiskScoreView} from '../RiskScoreConfiguration/SectionWiseRiskScoreView';
import WebPreview, {PREVIEW_TYPE} from '../WebPreview/WebPreview';
import {
  addCustomComponentsInBuilderConfig,
  forEachExtensiveFormComponent,
  getUpdateComponentsAfterChange,
  getUpdateMetadataAfterChange,
  isSubmitButtonComponent,
  processFormLocations,
  removeDuplicateComponents,
  updateBuilderComponentsBasedOnCapabilities
} from './AddOrUpdateFormHelper';
import { FormContext } from '../../FHFormio/CustomComponents/CustomWrapper/CustomWrapper';
import ConfirmOnExitOrReload from '../../../../common/ConfirmExitWindow/ConfirmOnExitOrReload';
import { FormComponents } from '../../../../PublicPages/PublicForm/PublicFormHelper';
import { ToastType, showToast } from '../../../../../utils/commonViewUtils';
import { checkCarePlanCategoryForm, checkNoteCategoryForm } from '../FormList/FormListUtils';
import { FORM_CATEGORY_TYPES } from '../../FormsConstants';
import ShowToPatientSwitch from '../../../../PersonOmniView/MiddleContainer/CareTimeline/components/ShowToPatientSwitch';
import { HealthComponents } from '../../FHFormio/Builder/SupportedComponents';
import { IUserSettingsByCode } from '../../../../../services/UserSettings/interfaces';
import { getIsShareWithEHRFlag } from '../../../../PersonOmniView/MiddleContainer/PatientNotes/components/AddOrUpdateTemplate/helper';
import FormEHRAndLocationSelect from './components/FormEHRAndLocationSelect';
import {INTEGRATION_BASE_URL} from '../../../../../constants/BaseUrlConst';
import useLazyAxiosQuery from '../../../../../lib/useLazyAxiosQuery';
import useEHRCapabilities from '../../../../../screens/BusinessStudio/useEHRCapabilities';
import {findArrayIntersection} from '../../../../../utils/arrUtils';
import useEhrAndLocation from './hooks/useEhrAndLocation';
import ConfigurationPopover from './components/ConfigurationPopover';
import Stack from '../../../../common/LayoutComponents/Stack';
import { getEhrConfig } from '../../../../../utils/capabilityUtils';
import { styles } from '../../Analytics/style';

enum FormScreenContext {
  editForm = 'EditForm',
  riskScore = 'Score',
  preview = 'Preview',
}

export interface IFormLocation {
  locationId: string;
  id?: string;
  isDeleted?: boolean;
}

const AddOrUpdateForm = (props: {
  quickNoteCreation?: boolean;
  isQuickForm?: boolean;
  noteCategoryId?: string;
  location?: any;
  onClose?: () => void;
  onAdd?: (response: any) => any;
  onUpdate?: (response: any) => any;
  handleNewlyCreatedNote?: (id: string, templateName?: string) => void;
  isFromEmpDashboard?: boolean;
  categoryCode?: string;
  heightOffset?: number;
  isEHRDisabled?: boolean;
  allowedLocationList?: string[];
  nonRemovableLocations?: Set<string>;
}) => {
  const [categoryList, setCategoryList] = useState<any>();
  useQuery(GET_FORM_CATEGORIES, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
    onCompleted: (response) => {
      setCategoryList(response?.formCategories || []);
    },
  });
  const {
    quickNoteCreation,
    noteCategoryId,
    onClose,
    handleNewlyCreatedNote,
    isFromEmpDashboard,
  } = props;
  const navigate = useNavigate();
  const location = props.location || useLocation();

  if (
    isFromEmpDashboard !== undefined &&
    !isFromEmpDashboard &&
    quickNoteCreation !== undefined &&
    !quickNoteCreation &&
    !location?.state
  ) {
    navigate('/admin/contentManagement/forms', {replace: true});
  }
  const getLocationStateVars = () => {
    const state = location?.state as any;
    return {
      selectedForm: state?.selectedForm,
      addFormDetail: state?.addFormDetail,
      isAddExisting: state?.isAddExisting,
      isUpdate: state?.isUpdate,
      isClone: state?.isClone,
      customEhrCapabilities: state?.addFormDetail?.customEhrCapabilities,
      selectedEhrAndLocations: state?.addFormDetail?.ehrAndLocationSelect,
    };
  };
  const {selectedForm, addFormDetail, isAddExisting, isUpdate, isClone , selectedEhrAndLocations, customEhrCapabilities} =
    getLocationStateVars();
  const formLogCount = selectedForm?.formLogCount || 0;
  const isNoteCategoryForm = checkNoteCategoryForm(selectedForm?.formCategory);
  const isCarePlanCategoryForm = checkCarePlanCategoryForm(selectedForm?.formCategory);

  const intl = useIntl();
  const toast = useToast();
  const contextData = useContext(CommonDataContext);
  const accountLocationListWithEHR = contextData.accountLocationListWithEHR || [];
  const isCareProgramEnabled = isEnableCareProgram(contextData.userSettings);
  const isEnabledMultiTenancy = isMultiTenancyEnabled(contextData.userSettings);
  const screenHeight = Dimensions.get('window').height;
  const accountUUID = getAccountUUID();
  const userUuid = getUserUUID();
  const userActionObject = getUserActionObjectFromActionCode(
    'CREATE_A_NEW_FORM_USING_HEALTH_COMPONENT'
  );
  const level = userActionObject.level || '';
  const [screenType, setScreenType] = useState(PREVIEW_TYPE.DESKTOP);
  const [screenContext, setScreenContext] = useState<FormScreenContext>(
    FormScreenContext.editForm
  );
  const [componentState, setComponentState] = useState<{
    showErrors: boolean;
    loading: boolean;
    formLoading: boolean;
  }>({
    showErrors: false,
    loading: false,
    formLoading: selectedForm?.id ? true : false,
  });
  const [submitFormLoading, setSubmitFormLoading] = useState<boolean>(false);
  const [formName, setFormName] = useState(
    addFormDetail?.formName || ''
  );
  const [formCategory, setFormCategory] = useState(
    addFormDetail?.formCategoryId ||
      selectedForm?.categoryId ||
      (quickNoteCreation ? noteCategoryId : '')
  );
  const [isShownToPatient, setIsShownToPatient] = useState<{
    currValue: boolean,
    prevValue: boolean,
  }>({
    currValue: isClone ? addFormDetail.isShownToPatient : false,
    prevValue: false,
  });
  const [isShareWithEhr, setIsShareWithEhr] = useState<{
    currValue: boolean,
    prevValue: boolean,
  }>({
    currValue: getIsShareWithEHRFlag(addFormDetail?.isShareWithEHR),
    prevValue: getIsShareWithEHRFlag(addFormDetail?.isShareWithEHR),
  });

  const [isDefaultForm, setDefaultForm] = useState<boolean>(
    selectedForm?.isDefault || false
  );
  const [submitFormComponent, setSubmitFormComponent] = useState<any>();
  const [originalFormName, setOriginalFormName] = useState(
    !isClone ? formName : undefined
  );
  const [isFormNameAlreadyTakenState, setIsFormNameAlreadyTakenState] =
    useState({
      isTaken: false,
      loading: false,
      error: false,
    });
  //const [formResource, setFormResource] = useState('');
  const [formMetadata, setFormMetadata] = useState<IFormMetaData>({
    metadata: [],
  });
  const commonData = useContext(CommonDataContext);
  const ehrList = commonData.accountEhrList || [];


  const [formComponents, setFormComponents] = useState<any[]>([]);
  const [prevFormComponents, setPrevFormComponents] = useState<any[]>([]);
  const {
    locationList,
    ehrAndLocationSelect,
    setEhrAndLocationSelect,
    checkIfTheLoggedInUserHasAccessToAllFormLocations,
  } = useEhrAndLocation({
    isEHRDisabled: props?.isEHRDisabled,
    allowedLocationList: props?.allowedLocationList,
    nonRemovableLocations: props?.nonRemovableLocations,
    selectedEhrAndLocations,
  })
  const ehrConfig = getEhrConfig(ehrAndLocationSelect?.locationIdList?.[0]?.locationId,'');

  const mlovData = useContext(CommonDataContext);

  const ehrCapabilities = useEHRCapabilities({
    locationId: ehrAndLocationSelect?.locationIdList?.[0]?.locationId
  });

  // const [ehrCapabilitiesBySelectedLocation, setEhrCapabilitiesBySelectedLocation] = useState<IEhrCapability[]>([]);
  const [formBuilderOptions, setFormBuilderOptions] = useState<{
    accountUUID: string;
    ehrCapabilities: IEhrCapability[];
    questionnaireList: Resource[];
    reasonForVisitData: any[];
    userSettings: IUserSettingsByCode,
    CARE_STUDIO_MLOV: IMlovByCategory,
    CLOUD_TELEPHONY_MLOV: IMlovByCategory,
    MLOV: IMlovByCategory,
  }>({
    CARE_STUDIO_MLOV: commonData.CARE_STUDIO_MLOV || {},
    CLOUD_TELEPHONY_MLOV: commonData.CLOUD_TELEPHONY_MLOV || {},
    MLOV: commonData.MLOV || {},
    accountUUID,
    ehrCapabilities: (customEhrCapabilities || ehrCapabilities || []),
    questionnaireList: [],
    reasonForVisitData: [],
    userSettings: commonData.userSettings,
  });

  const [isSmallHeaderView, setIsSmallHeaderView] = useState(false);


  useEffect(() => {
    setIsSmallHeaderView(screenContext !== FormScreenContext.editForm);
  }, [screenContext])

  // this state is used for set health component sub component and other component - do not use for rendering
  const [allFormComponent, setAllFormComponent] = useState<any[]>([]);
  const [addForm] = useMutation(FormsQueries.ADD_NEW_FORM);
  const [updateForm] = useMutation(FormsQueries.UPDATE_FORM_BY_ID);
  let updateFormData:any = undefined;

  const formStatusList =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.FORM_STATUS
    ) || [];
  const draftStatusId = getMlovIdFromCode(
    formStatusList,
    FORM_STATUS_CODE.DRAFT
  );

  const customComponentData = useQuery(
    ComponentsQueries.GET_ALL_REUSABLE_COMPONENTS,
    {fetchPolicy: 'no-cache', context: {service: CARESTUDIO_APOLLO_CONTEXT}}
  );

  const [getFormsCountForName] = useLazyQuery(
    FormsQueries.GET_FORMS_COUNT_FOR_NAME,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const [updateNoteShowFlag] = useMutation(UPDATE_NOTE_SHOWN_TO_PATIENT, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });

  const [updateNoteShareWithEhrFlag] = useMutation(UPDATE_NOTE_SHARE_WITH_EHR, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });

  const getDefaultReasonForVisit = useQuery(GET_MDM_DATA, {
    fetchPolicy: 'no-cache',
    variables: {
      type: 'REASON_FOR_VISIT',
    },
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
    onCompleted: (response: any) => {
      if (response?.mdm?.length > 0 && response.mdm[0].data?.length > 0) {
        const data = response.mdm[0].data;
        setFormBuilderOptions((prev) => {
          if (prev.reasonForVisitData.length) {
            prev.reasonForVisitData = [...prev.reasonForVisitData, ...data];
          } else {
            prev.reasonForVisitData = data;
          }
          return {...prev};
        });
      }
    },
    onError: (error) => {

    },
  });

  const isNoteCategoryFormSelected = useMemo(() => {
    const selectedCategory = addFormDetail?.categoryList?.find(
      (category: {id: string}) => formCategory === category.id
    );
    return selectedCategory?.code === FORM_CATEGORY_TYPES.NOTES;
   }, [formCategory])

   const isCarePlanCategorySelected = useMemo(() => {
    const selectedCategory = addFormDetail?.categoryList?.find(
      (category: {id: string}) => formCategory === category.id
    );
    return selectedCategory?.code === FORM_CATEGORY_TYPES.CARE_PLAN;
   }, [formCategory])


  const builderConfig = useMemo(() => {
    let builderConfig = Utils.getBuilderConfig();
    builderConfig = updateBuilderComponentsBasedOnCapabilities(
      builderConfig,
      ehrCapabilities || [],
      {
        isCareProgramEnabled: isCareProgramEnabled,
        isCarePlanCategorySelected: isCarePlanCategorySelected
      }
    );
    if (customComponentData?.data?.components?.length) {
      builderConfig = addCustomComponentsInBuilderConfig(
        customComponentData.data.components,
        builderConfig
      );
    }
    return builderConfig;
  }, [ehrCapabilities, isCareProgramEnabled, isCarePlanCategorySelected, customComponentData?.data?.components]);

  const getAccountReasonForVisit = useQuery(GET_ALL_REASON_FOR_VISIT, {
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      if (data?.accountReasonForVisits.length) {
        setFormBuilderOptions((prev) => {
          const reasonForVisits = prev.reasonForVisitData || [];
          (data?.accountReasonForVisits).forEach(
            (accountReasonForVisit: any) => {
              reasonForVisits.push({
                code: Math.random(),
                displayName: accountReasonForVisit?.reasonForVisit?.displayName,
                type: ADD_NEW_REASON_FOR_VISIT,
              });
            }
          );
          prev.reasonForVisitData = reasonForVisits;
          return {...prev};
        });
      }
    },
  });

  const filterSubHealthComponent = (components: any[] = []) => {
    return (components).filter(component => {
      return !component?.isHealthComponentField;
    })
  };

  const processFormLocationsAndEhr = useCallback((updateFormData: {
    formLocations: {
      locationId: string;
    }[];
  }) => {
     // HERE WE ALSO FILTER OUT NON ACCESS LOCATIONS
     const existingLocationIds: string[] = [];
     const accessBasedFilteredExistingLocationIds: Set<string> =
       new Set<string>();

     updateFormData.formLocations.forEach((location) => {
       existingLocationIds.push(location.locationId);
       if (props.allowedLocationList?.includes(location.locationId)) {
         accessBasedFilteredExistingLocationIds.add(location.locationId);
       }
     });



     const ehrCode = accountLocationListWithEHR.find(
       (location) =>
         location.uuid ===
         accessBasedFilteredExistingLocationIds.values().next().value
     )?.ehrInfo?.ehrCode;

     const useHasAccessToAllLocation = checkIfTheLoggedInUserHasAccessToAllFormLocations(existingLocationIds, ehrCode as string);

     setEhrAndLocationSelect((prev) => ({
       ...prev,
       locationIdList: updateFormData.formLocations?.filter(
         (location: {locationId: string}) => {
           const hasAccess = accessBasedFilteredExistingLocationIds?.has(
             location.locationId
           );
           return hasAccess;
         }
       ),
       ehrCode: ehrCode as string,
       isNonAccessLocationExist: !useHasAccessToAllLocation,
       prevLocation: updateFormData.formLocations,
     }));
  }, [accountLocationListWithEHR, props.allowedLocationList]);

  const formUpdateData = useQuery(FormsQueries.GET_FORM_BY_ID, {
    fetchPolicy: 'no-cache',
    skip: !selectedForm?.id,
    variables: {
      id: selectedForm?.id,
    },
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    onCompleted: (data: any) => {
      if (data && data.form) {
        updateFormData = _.cloneDeep(data.form);
        if (isAddExisting || isClone) {
          if (updateFormData?.formMetadata?.metadata) {
            updateFormData.formMetadata.id = undefined;
            updateFormData.formMetadata.metadata =
              updateFormData.formMetadata.metadata.map(
                (metaDataItem: IRiskScoreData) => ({
                  ...metaDataItem,
                  fields: metaDataItem.fields.map((fieldItem) => ({
                    componentId: fieldItem.componentId,
                    referenceId: generateUniqueKey(),
                  })),
                })
              );
          }

          let totalFieldsArray: any = [];
          updateFormData?.formMetadata?.metadata?.forEach(
            (metaDataItem: IRiskScoreData) => {
              if (metaDataItem.fields && metaDataItem.fields.length > 0) {
                totalFieldsArray = totalFieldsArray.concat(metaDataItem.fields);
              }
            }
          );


          forEachExtensiveFormComponent(
            updateFormData.components,
            (comp: any) => {
              const filterComponentFields = totalFieldsArray.filter(
                (fieldItem: any) => fieldItem.componentId === comp.componentId
              );

              if (filterComponentFields.length > 0) {
                comp.referenceId = filterComponentFields[0].referenceId;
                comp.componentId = undefined;
                comp.formComponentId = undefined;
              } else {
                comp.referenceId = undefined;
                comp.componentId = undefined;
                comp.formComponentId = undefined;
              }
            }
          );

          if (updateFormData?.formMetadata?.metadata) {
            updateFormData.formMetadata.metadata =
              updateFormData.formMetadata.metadata.map(
                (metaDataItem: IRiskScoreData) => ({
                  ...metaDataItem,
                  fields: metaDataItem.fields.map((fieldItem) => ({
                    componentId: fieldItem.referenceId,
                  })),
                })
              );
          }
          // ALWAYS CARRY FWD ONLY ALLOWED LOCATION IN CASE OF CLONE Only in case of multi tenancy
          if (isEnabledMultiTenancy) {
            updateFormData.formLocations = addFormDetail?.formLocations;
          }
        }

        if (!isEqual(prevFormComponents, updateFormData.components)) {
          const finalUpdatedFormData = {...updateFormData};
          const isExistSubmitButton = finalUpdatedFormData.components.some(
            (componentItem: any) => isSubmitButtonComponent(componentItem)
          );

          if (!isAddExisting && !isClone) {
            if (isExistSubmitButton) {
              setSubmitFormComponent(
                finalUpdatedFormData.components.filter(
                  (componentItem: any) => isSubmitButtonComponent(componentItem)
                )?.[0]
              );
            }
          }

          if (isExistSubmitButton) {
            // Is submit button is there the remove it from the component list which will be shown as a list in form builder
            // And send submit button component while submitting
            setAllFormComponent(cloneDeep(finalUpdatedFormData.components || []));
            finalUpdatedFormData.components =
              finalUpdatedFormData.components.filter(
                (componentItem: any) => !(componentItem?.isHealthComponentField || isSubmitButtonComponent(componentItem))
              );
          }

          setPrevFormComponents([...finalUpdatedFormData.components]);
          setComponentState((prev) => ({...prev, formLoading: false}));
        } else if (updateFormData?.components?.length){
          setAllFormComponent([...updateFormData?.components]);
          setPrevFormComponents([...filterSubHealthComponent(updateFormData.components)]);
        }

        if (formName !== updateFormData.name) {
          if (!isAddExisting && !isClone) {
            setFormName(updateFormData.name);
            setOriginalFormName(updateFormData.name);
          }
          // setFormCategory(updateFormData.categoryId);
          // setDefaultForm(updateFormData.isDefault);
        }
        setIsShownToPatient(prev => ({
          ...prev,
          currValue: updateFormData?.isShownToPatient as boolean,
          prevValue: updateFormData?.isShownToPatient as boolean,
        }));
        setIsShareWithEhr(prev => ({
          ...prev,
          currValue: getIsShareWithEHRFlag(updateFormData?.isShareWithEHR),
          prevValue: getIsShareWithEHRFlag(updateFormData?.isShareWithEHR),
        }));
        setFormCategory(updateFormData.categoryId);
        setDefaultForm(updateFormData.isDefault);
        if (isEnabledMultiTenancy) {
          processFormLocationsAndEhr(updateFormData);
        }
        /*if (
            formResource !== updateFormData.resourceTypeId &&
            updateFormData.resourceTypeId
          ) {
            setFormResource(updateFormData.resourceTypeId);
          }*/
        if (
          updateFormData.formMetadata &&
          !isEqual(formMetadata, updateFormData.formMetadata)
        ) {
          setFormMetadata(updateFormData.formMetadata);
        }
      }
      setComponentState((prev) => ({...prev, formLoading: false}));
    },
    onError: () => {
      setComponentState((prev) => ({...prev, formLoading: false}));
    },
  });
  const loading =
    customComponentData.loading ||
    componentState.loading ||
    componentState.formLoading ||
    formUpdateData.loading;

  const isFormNameAlreadyTaken = async (formName: string) => {
    const trimmedFormName = formName.trim();
    if (!trimmedFormName) {
      setIsFormNameAlreadyTakenState({
        isTaken: false,
        loading: false,
        error: false,
      });
      return false;
    }
    setIsFormNameAlreadyTakenState((prevState) => ({
      ...prevState,
      error: false,
    }));
    try {
      const queryData = await getFormsCountForName({
        variables: {
          searchString: trimmedFormName,
        },
      });

      if (queryData.error) {
        setIsFormNameAlreadyTakenState((prevState) => ({
          ...prevState,
          error: true,
        }));
        throw queryData.error;
      }

      if (queryData.data?.aggregateForms?.aggregate?.count === 0) {
        setIsFormNameAlreadyTakenState((prevState) => ({
          ...prevState,
          isTaken: false,
          loading: false,
        }));
        return false;
      }
      setIsFormNameAlreadyTakenState((prevState) => ({
        ...prevState,
        isTaken: true,
        loading: false,
      }));
      return true;
    } catch (error) {
      setIsFormNameAlreadyTakenState((prevState) => ({
        ...prevState,
        loading: false,
        error: true,
      }));
      const errorMessage = `Error while fetching forms count for name: ${trimmedFormName}`;
      showToast(
        toast,
        intl.formatMessage({id: 'errorMsg'}),
        ToastType.error,
      );
    }
  };

  const onFormNameChange = async (value: string) => {
    setFormName(value);
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (!formName || formName === originalFormName) {
        setIsFormNameAlreadyTakenState({
          isTaken: false,
          loading: false,
          error: false,
        });
        return false;
      }
      setIsFormNameAlreadyTakenState({
        isTaken: false,
        loading: true,
        error: false,
      });
      isFormNameAlreadyTaken(formName);
    }, 500);
    return () => clearTimeout(timerId);
  }, [formName, originalFormName]);

  const isValid = async () => {
    const updatedMetadata = getUpdateMetadataAfterChange(
      formComponents,
      formMetadata
    );

    let isFormNameTaken = false;
    if (isFormNameAlreadyTakenState.loading) {
      isFormNameTaken = (await isFormNameAlreadyTaken(formName)) || true;
    } else {
      isFormNameTaken = isFormNameAlreadyTakenState.isTaken;
    }

    if (!formCategory) {
      showToast(
        toast,
        'Please select category',
        ToastType.info,
      );
      return false;
    }

    if (isFormNameTaken && formName && !isFormNameAlreadyTakenState.error) {
      showToast(
        toast,
        'Form name already exist. Please enter another form name.',
        ToastType.error,
      );
      return false;
    }

    let patientIdetifierComponentFound = false;
    let isAllComponentHasLabel = true;

    forEachExtensiveFormComponent(formComponents, (component: any) => {
      if(component.key === FormComponents.PATIENT_IDENTIFIER){
        patientIdetifierComponentFound = true;
      }
      if (component?.type && component.hasOwnProperty('label') && (!component?.label || component?.label?.trim().length === 0)) {
        isAllComponentHasLabel = false;
      }
    });

    if (!isAllComponentHasLabel) {
      showToast(
        toast,
        'Label field cannot be empty for form components.',
        ToastType.error,
      );
      return false;
    }

    const providerRequestFormCategory = categoryList?.find(
      (category: any) =>
        category.code === FORM_CATEGORY_TYPES.PROVIDER_REQUEST_FORM
    );

    if(patientIdetifierComponentFound){
      if (providerRequestFormCategory?.id !== formCategory) {
        showToast(
          toast,
          intl.formatMessage({id: 'formPatientIdentifierComponentError'}),
          ToastType.error,
          4000
        );
        return false;
      }
    }

    if(providerRequestFormCategory?.id === formCategory) {
      let healthComponentFound = false;

      forEachExtensiveFormComponent(formComponents, (component: any) => {
        if(HealthComponents.includes(component.key)){
          healthComponentFound = true;
        }
      });

      if(healthComponentFound){
        showToast(
          toast,
          intl.formatMessage({id: 'patientIdentifierHealthComponentError'}),
          ToastType.error,
          4000
        );
        return false;
      }
    }

    setFormMetadata(updatedMetadata);
    const updatedFormComponents = checkSubmitButtonComponents(formComponents);
    const data = isValidForm(formName, updatedMetadata, updatedFormComponents, ehrAndLocationSelect.ehrCode, ehrAndLocationSelect.locationIdList, isEnabledMultiTenancy);

    if (!data.isValid) {
      notification.destroy();
      notification.error({
        message: data.error,
      });
    }

    return data.isValid;
  };

  const onCompletion = () => {
    if (isFromEmpDashboard) {
      props.onClose?.();
    } else {
      props.quickNoteCreation || props.isQuickForm
        ? onClose && onClose()
        : navigate(-1);
    }
  };

  const getPromiseList = (formId: any) => {
    const promiseList: any[] = [];
    if (!(!isClone && isShownToPatient.currValue === isShownToPatient.prevValue)) {
      promiseList.push(updateNoteShowFlag({
        variables: {
          subjectTypeId: formId,
          subjectTypeCode: FORM_CONTACT_PERMISSION_SUBJECT.Form,
          isShownToPatient: isShownToPatient.currValue,
        },
      }));
    }

    if (!(isShareWithEhr.currValue === isShareWithEhr.prevValue)) {
      promiseList.push(updateNoteShareWithEhrFlag({
        variables: {
          subjectTypeId: formId,
          subjectTypeCode: FORM_CONTACT_PERMISSION_SUBJECT.Form,
          isShareWithEHR: isShareWithEhr.currValue,
        },
      }));
    }
    return promiseList;
  }

  const onSubmit = async () => {
    setComponentState((prev) => ({...prev, showErrors: true}));
    setIsSmallHeaderView(false);
    if (await isValid()) {
      const metaData =
        formMetadata.metadata.length > 0
          ? formMetadata.metadata[0].fields.length > 0
            ? formMetadata.metadata
            : []
          : [];
      const uniqueComponents = removeDuplicateComponents(formComponents || [], new Map());
      forEachExtensiveFormComponent(uniqueComponents, (components: any) => {
        if (components.type === 'image') {
          components.imageURL = {
            signed: '',
            unsigned: '',
          };
        }
        if (components?.type === FormComponents.SOCIAL_HISTORY && !components?.enabledQuestionnaires) {
          components.enabledQuestionnaires = getDefaultEnableQuestionnaireList();
        }
      });
      const formAddData = {
        data: {
          name: formName ? formName.trim() : formName,
          categoryId: formCategory,
          isDefault: false,
          /*resourceTypeId:
            formResource && formResource.length > 0 ? formResource : null,*/
          components: uniqueComponents,
          formMetadata: {id: formMetadata.id, metadata: metaData},
          statusId: selectedForm?.statusId || draftStatusId,
          // If non access location exist then set empty array
          locations: ehrAndLocationSelect?.isNonAccessLocationExist
            ? []
            : processFormLocations(
                ehrAndLocationSelect.prevLocation || [],
                ehrAndLocationSelect.locationIdList
              ),
        },
      };

      if (selectedForm?.id && !isAddExisting && !isClone) {
        const updatedFormAddData = {...formAddData};
        updatedFormAddData.data.components = checkSubmitButtonComponents(
          formAddData.data.components,
          true
        );
        const requestData = {...updatedFormAddData.data, id: selectedForm?.id};
        updateForm({
          variables: {
            data: requestData,
          },
          context: {service: CARESTUDIO_APOLLO_CONTEXT},
          onCompleted: async (response) => {
            Promise.all(getPromiseList(selectedForm?.id));
            if (props.onUpdate) {
              props.onUpdate(requestData);
            } else {
              onCompletion();
            }
            setSubmitFormLoading(false);
          },
          onError(error) {
            setSubmitFormLoading(false);
            showToast(
              toast,
              error.message ||intl.formatMessage({id: 'apiErrorMsg'}),
              ToastType.error,
            );
          },
        });
      } else {
        const updatedFormAddData = {...formAddData};
        updatedFormAddData.data.components = checkSubmitButtonComponents(
          formAddData.data.components
        );

        // if (props.isAddExisting) {
        //   let totalFieldsArray: any = [];
        //   updatedFormAddData.data.formMetadata.metadata.forEach(
        //     (metaDataItem: IRiskScoreData) => {
        //       if (metaDataItem.fields && metaDataItem.fields.length > 0) {
        //         totalFieldsArray = totalFieldsArray.concat(metaDataItem.fields);
        //       }
        //     }
        //   );


        //   forEachExtensiveFormComponent(
        //     updatedFormAddData.data.components,
        //     (comp: any) => {
        //       const filterComponentFields = totalFieldsArray.filter(
        //         (fieldItem: any) => fieldItem.componentId === comp.componentId
        //       );

        //       if (filterComponentFields.length > 0) {
        //         comp.referenceId = filterComponentFields[0].referenceId;
        //         comp.componentId = undefined;
        //         comp.formComponentId = undefined;
        //       } else {
        //         comp.referenceId = undefined;
        //         comp.componentId = undefined;
        //         comp.formComponentId = undefined;
        //       }
        //     }
        //   );

        //   updatedFormAddData.data.formMetadata.id = undefined;
        //   updatedFormAddData.data.formMetadata.metadata =
        //     updatedFormAddData.data.formMetadata.metadata.map(
        //       (metaDataItem: IRiskScoreData) => ({
        //         ...metaDataItem,
        //         fields: metaDataItem.fields.map((fieldItem) => ({
        //           componentId: fieldItem.referenceId,
        //         })),
        //       })
        //     );
        // }

        addForm({
          variables: updatedFormAddData,
          onCompleted: async (data) => {
            Promise.all(getPromiseList(data?.createFormBuilder?.id));
            handleCreateFormAction({
              accountUuid: accountUUID,
              userUuid,
              level,
            }).then((r) => {
              if (props.onAdd) {
                const formCategoryObj = categoryList?.find(
                  (category: any) =>
                    category.id === formCategory
                );
                props.onAdd({
                  name: updatedFormAddData?.data?.name,
                  id: data?.createFormBuilder?.id,
                  formCategory: formCategoryObj
                });
              } else if (quickNoteCreation) {
                notification.success({
                  message: 'Template Added Successfully.',
                  duration: 1.5,
                });

                handleNewlyCreatedNote &&
                  handleNewlyCreatedNote(data.createFormBuilder.id, updatedFormAddData?.data?.name);
                onClose && onClose();
              } else {
                onCompletion();
              }
              setSubmitFormLoading(false);
            });
          },
          onError(error) {
            setSubmitFormLoading(false);
            showToast(
              toast,
              error.message ||intl.formatMessage({id: 'apiErrorMsg'}),
              ToastType.error,
            );
          },
          context: {service: CARESTUDIO_APOLLO_CONTEXT},
        });
      }
      if (isFromEmpDashboard) {
        props.onClose?.();
      }
    } else {
      setSubmitFormLoading(false);
    }
  };

  const updateSelectedForm = (form: IForm, isSelected: boolean) => {
    const formAddData = {
      data: {
        name: form.name,
        categoryId: form.categoryId,
        isDefault: true,
      },
    };
    updateForm({
      variables: {data: {...formAddData.data, id: form?.id}},
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      onCompleted: () => {
        setDefaultForm(false);
        showToast(
          toast,
          `Update ${form.name} form successfully`,
          ToastType.success,
        );
      },
      onError(error) {
        showToast(
          toast,
          intl.formatMessage({id: 'apiErrorMsg'}),
          ToastType.error,
        );
      },
    });
  };

  const getCustomerListTopBarButtons = (): ITableTopBarProps => {
    return {
      title: '',
      headerElement: getHeaderElement(),
      alignSearchBarFlexEnd: false,
      hStackMinHight: 80,
      buttonList: [],
      hideSearchBar: true,
      fullWidthHeader: true,
    };
  };
  const getFormBuilderTopBar = (): ITableTopBarProps => {
    return {
      title: '',
      headerElement: getFormBuilderHeaderElement(),
      alignSearchBarFlexEnd: false,
      hStackMinHight: 80,
      buttonList: [],
      hideSearchBar: true,
      fullWidthHeader: true,
    };
  };

  const onPrintForm = (form: any) => {
    const printUrl = getFormPrintURL(form, accountUUID);
    window.open(printUrl, '_blank', 'noopener,noreferrer');
  };

  const checkSubmitButtonComponents = (components: any, isEdit = false) => {
    const newComponents = [...components];
    const isExistSubmitButton = components.some(
      (componentItem: any) => componentItem.key === 'submit'
    );

    if (!isExistSubmitButton) {
      // If submit button component is not there then add it while editing the components
      if (submitFormComponent && isEdit) {
        submitFormComponent.disableOnInvalid = false;
        newComponents.push(submitFormComponent);
      } else {
        newComponents.push({
          type: 'button',
          label: 'Submit',
          key: 'submit',
          size: 'md',
          block: false,
          action: 'submit',
          disableOnInvalid: false,
          theme: 'primary',
        });
      }
    }

    return newComponents;
  };

  const takenOrError: boolean = isFormNameAlreadyTakenState.isTaken|| isFormNameAlreadyTakenState.error

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const isSmallScreen = isIPadMiniScreen || isIPadScreen;
  const isDisabledFormCategoryField = ()=> {
    if (quickNoteCreation) {
      return true;
    }
    if(formLogCount > 0 && isNoteCategoryForm){
      return true;
    }
    if (isCarePlanCategorySelected) {
      return true;
    }
    return (
      props.categoryCode === ANALYTICS_RESOURCE.MEMBER_SATISFACTION ||
      props.categoryCode === ANALYTICS_RESOURCE.USER_SATISFACTION ||
      props.categoryCode === ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION ||
      props.categoryCode === ANALYTICS_RESOURCE.EMPLOYER_SATISFACTION
    );
  }
  const allAccountLocationList = useMemo(
    () =>
      mlovData.accountLocationListWithEHR?.map((location) => ({
          locationUUID: location.uuid,
          practiceLocationName: location.practiceLocation?.name,
        })) || [],
    [mlovData.accountLocationListWithEHR?.length, props?.allowedLocationList?.length]
  );

  const {
    data: ehrCapabilitiesQuery,
    loading: ehrCapabilitiesQueryLoading,
    error: ehrCapabilitiesQueryError,
    executeFetch: fetchEHRCapabilities,
  } = useLazyAxiosQuery(`${INTEGRATION_BASE_URL}/config/capability`)

  const onEhrAndLocationSelect = (locationIdList: IFormLocation[], ehrCode: string) => {
    const isLocationListPresent = locationIdList.length !== 0;
    const isLocationListChanged = locationIdList.length !== ehrAndLocationSelect.locationIdList.length;
    const isLocationIdPresent = locationIdList?.[0]?.locationId.length > 0;
    const isEHRCodeChanged = ehrCode !== ehrAndLocationSelect.ehrCode;


    if (!isLocationListPresent) {
      setFormBuilderOptions((prev) => ({...prev, ehrCapabilities: []}));
    }

    setEhrAndLocationSelect(prev => ({
      ...prev,
      locationIdList: isEHRCodeChanged ? [] :locationIdList,
      ehrCode,
    }));
  }

  useEffect(() => {
    setFormBuilderOptions((prev) => {
      return {
        ...prev,
        ehrCapabilities: ehrCapabilities,
      };
    });
  }, [ehrAndLocationSelect.locationIdList?.[0]?.locationId]);

  const renderLocationElm = (): JSX.Element => {
    if (!isEnabledMultiTenancy) {
      return <></>;
    }
    return (
      <ConfigurationPopover
        error={
          componentState.showErrors &&
          (ehrAndLocationSelect.locationIdList.length === 0 ||
            ehrAndLocationSelect.ehrCode === '')
        }
      >
        <FormEHRAndLocationSelect
          isLocationDisabled={ehrAndLocationSelect.isNonAccessLocationExist}
          containerStyles={{
            flexDirection: 'column',
          }}
          nonRemovableLocations={props?.nonRemovableLocations}
          ehrList={ehrList}
          locationList={locationList.map((item) => ({
            locationUUID: item.uuid,
            practiceLocationName: item.practiceLocation?.name,
            uuid: item.uuid,
          }))}
          enabledFields={{
            ehr: true,
            locations: true,
          }}
          selectedEHRCode={ehrAndLocationSelect.ehrCode}
          selectedLocationId={ehrAndLocationSelect.locationIdList}
          onChange={onEhrAndLocationSelect}
          isEHRDisabled={!props?.isEHRDisabled}
          showErrors={componentState.showErrors}
          ehrContainerStyles={{
            marginTop: 2,
            marginBottom: 2,
            maxWidth: '100%',
          }}
          locationContainerStyles={{
            marginTop: 2,
            marginBottom: 2,
            maxWidth: '100%',
          }}
        />
      </ConfigurationPopover>
    );
  }

  const getHeaderElement = (): JSX.Element => {
    const isCategoryError = componentState.showErrors && isInvalid(FormRequiredField.category, formCategory);
    let headerMarginBottom = 2;
    if (isCategoryError) {
      headerMarginBottom = 8;
    }

    return (
      <HStack
        alignItems="center"
        width="100%"
        space={1}
        marginTop={2}
        flex={1}
        paddingX={5}
        marginBottom={headerMarginBottom}
        justifyContent={'space-between'}
      >
            <Stack direction='row' space={2} style={styles.alignItemsFlexEnd}>
            <View marginBottom={isCategoryError ? -7 : 0}>
              <FormControl isInvalid={isCategoryError}>
                <FormControl.Label isRequired>
                  <DisplayText size={'smRegular'} extraStyles={{color: Colors.FoldPixel.GRAY250}} textLocalId="selectTemplate" />
                </FormControl.Label>
                <HStack space={4}>
                  <View flex={2} style={{width: isSmallScreen ? 100 : 250}}>
                    <Select
                      placeholder="Select Category"
                      isDisabled={
                        isDisabledFormCategoryField()
                          ? true
                          : false
                      }
                      selectedValue={formCategory}
                      onValueChange={(value: string) => {
                        setIsShownToPatient(prev => ({
                          ...prev,
                          currValue: false,
                        }));
                        setFormCategory(value);
                      }}
                      style={{
                        width: isSmallScreen ? 100 : 250,
                      }}
                    >
                      {(addFormDetail?.categoryList?.length
                        ? filterFormCategories(addFormDetail?.categoryList)
                        : filterFormCategories(categoryList)
                      )?.map((category: IFormCategory, index: number) => {
                        return (
                          <Select.Item
                            label={category.name}
                            value={category.id}
                            key={(category.id || '') + index}
                          />
                        );
                      })}
                    </Select>
                  </View>
                </HStack>
                {isCategoryError && (
                  <FormControl.ErrorMessage>
                    {errorMessages.category}
                  </FormControl.ErrorMessage>
                )}
              </FormControl>
            </View>
            {renderLocationElm()}
            </Stack>

            <View marginTop={5}>
              <FHCustomSwitch
                btnTextArray={[
                  {
                    text: intl.formatMessage({id: 'editForm'}),
                    code: FormScreenContext.editForm,
                  },
                  {
                    text: intl.formatMessage({id: 'riskScore'}),
                    code: FormScreenContext.riskScore,
                  },
                  {
                    text: intl.formatMessage({id: 'preview'}),
                    code: FormScreenContext.preview,
                  },
                ]}
                onBtnSelect={(code) => {
                  if (code === FormScreenContext.riskScore) {
                    generateRiskScoreEnabledComponent();
                  } else {
                    setScreenContext(code as FormScreenContext);
                  }
                }}
                selectedCode={screenContext}
              />
            </View>
        <HStack space={2} marginTop={3}>
          {
            !quickNoteCreation && selectedForm?.id && (
              <>
                <View marginTop={3.5}>
                  <Pressable
                    onPress={() => {
                      onPrintForm(selectedForm);
                    }}
                  >
                    <Icon
                      as={AntIcon}
                      name={'printer'}
                      size={"6"}
                      color={Colors.Custom.Gray500}
                    />
                  </Pressable>
                </View>
                <View marginTop={3}
                  style={styles.view1}
                  backgroundColor={Colors.Custom.separatorPipeBackgroundColor}
                ></View>
              </>
            )
          }
          <FoldButton
            nativeProps={{
              variant: BUTTON_TYPE.SECONDARY,
              onPress: () => {
                onCompletion();
              },
              isDisabled: submitFormLoading || loading
            }}
            customProps={{
              btnText: !isSmallScreen ? intl.formatMessage({id: 'cancel'}) : '',
            }}
          ></FoldButton>

          <FoldButton
            nativeProps={{
              variant: BUTTON_TYPE.PRIMARY,
              onPress: () => {
                if (submitFormLoading || loading || isFormNameAlreadyTakenState.loading) {
                  return;
                }
                setSubmitFormLoading(true);
                onSubmit();
              },
              leftIcon: (
                <Icon
                  as={AntIcon}
                  name={'save'}
                  size="4"
                  color={Colors.Custom.mainPrimaryPurple}
                />
              ),
              isDisabled: submitFormLoading || loading || isFormNameAlreadyTakenState.loading,
              disabled: submitFormLoading || loading || isFormNameAlreadyTakenState.loading,
            }}
            customProps={{
              btnText: !isSmallScreen ? intl.formatMessage({id: 'save'}) : '',
            }}
          ></FoldButton>
        </HStack>
      </HStack>
    );
  };

  const getSyncWithEHRView = () => {
    return (
      <ShowToPatientSwitch
        key={'syncWithEhrSwitch'}
        value={isShareWithEhr.currValue}
        onChange={(value) => {
          setIsShareWithEhr((prev) => ({
            ...prev,
            currValue: value
          }))
          if(value) {
            showToast(
              toast,
              intl.formatMessage({id: 'noteSentToEhr'}),
              ToastType.success,
              3000
            );
          }
        }}
        message={intl.formatMessage({id: 'syncWithEhr'})}
      />
    );
  }

  const getFormBuilderHeaderElement = (): JSX.Element => {
    const isFormNameError = takenOrError || isFormNameAlreadyTakenState.loading || (componentState.showErrors && isInvalid(FormRequiredField.formName, formName));
    const isCategoryError = componentState.showErrors && isInvalid(FormRequiredField.category, formCategory);

    let headerMarginBottom = 2;
    if (isFormNameError || isCategoryError) {
      headerMarginBottom = 8;
    }

    return (
      <HStack
        alignItems="center"
        width="100%"
        space={1}
        marginTop={2}
        flex={1}
      >
        <View
          flex={quickNoteCreation ? 3.1 : 3}
          // flexShrink={1}
          // flexBasis={0}
          style={styles.view2}
        ></View>
        <HStack
          flexGrow={props.isQuickForm ? 5.4 : 6}
          flexShrink={1}
          flexBasis={0}
          flex={props.isQuickForm ? 5.4 : 6}
          space={2}
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <View flex={1} paddingX={quickNoteCreation ? 0 : 4}>
            <FormControl
              isInvalid={
                isFormNameAlreadyTakenState.error ||
                isFormNameAlreadyTakenState.isTaken ||
                (componentState.showErrors &&
                  isInvalid(FormRequiredField.formName, formName))
              }
            >
              {/* <FormControl.Label isRequired>
                <DisplayText textLocalId="name" />
              </FormControl.Label> */}
              <HStack space={2} >
                <Input
                  _focus={{borderColor: Colors.Custom.formInputFocusBorderColor}}
                  isRequired
                  value={formName}
                  flex={1}
                  placeholder={`Enter ${
                    quickNoteCreation ? 'Template' : 'Form'
                  } Name`}
                  autoComplete="off"
                  onChangeText={(value) => {
                    onFormNameChange(value);
                  }}
                  minWidth={isSmallScreen ? 100 : 250}
                  {...testID(TestIdentifiers.formName)}
                />
                {(quickNoteCreation && !ehrConfig.isFold) ? (
                  <HStack width={164}>
                    {getSyncWithEHRView()}
                  </HStack>
                ) : isNoteCategoryFormSelected ? (
                  <HStack justifyContent={'flex-end'} space={4} mr={1.5}>
                    {!ehrConfig.isFold && getSyncWithEHRView()}
                    <ShowToPatientSwitch
                      key={'shareWithPatientSwitch'}
                      value={isShownToPatient.currValue}
                      onChange={(value) =>
                        setIsShownToPatient((prev) => ({
                          ...prev,
                          currValue: value,
                        }))
                      }
                      message={intl.formatMessage({id: 'shareWithPatient'})}
                    />
                  </HStack>
                ) : <></>}
              </HStack>
              {isFormNameAlreadyTakenState.loading && (
                <HStack marginTop={2} flex={1}>
                  <Spinner
                    size="sm"
                    accessibilityLabel={`Checking ${
                      quickNoteCreation ? 'Template' : 'Form'
                    } name availability`}
                  />
                  <Text marginLeft={1} fontSize="xs">{`Checking ${
                    quickNoteCreation ? 'Template' : 'Form'
                  } name availability`}</Text>
                </HStack>
              )}
              {isFormNameAlreadyTakenState.error && (
                <FormControl.ErrorMessage
                  flex={1}
                  leftIcon={<WarningOutlineIcon size="xs" />}
                >
                  {`Something went wrong while checking ${
                    quickNoteCreation ? 'Template' : 'Form'
                  } name availability.`}
                </FormControl.ErrorMessage>
              )}
              {isFormNameAlreadyTakenState.isTaken && (
                <FormControl.ErrorMessage
                  flex={1}
                  leftIcon={<WarningOutlineIcon size="xs" />}
                  fontSize={'xs'}
                  w={300}
                >
                  {`${
                    quickNoteCreation ? 'Template' : 'Form'
                  } name already exist. Please enter another ${
                    quickNoteCreation ? 'Template' : 'Form'
                  } name`}
                </FormControl.ErrorMessage>
              )}
              {componentState.showErrors &&
                isInvalid(FormRequiredField.formName, formName) && (
                  <FormControl.ErrorMessage flex={1}>
                    {quickNoteCreation
                      ? errorMessages.noteName
                      : errorMessages.formName}
                  </FormControl.ErrorMessage>
                )}
            </FormControl>
          </View>
        </HStack>
        {isSmallHeaderView && (
          <View
            flex={3}
            // flexShrink={1}
            // flexBasis={0}
            style={styles.view}
          ></View>
        )}
      </HStack>
    );
  };

  useEffect(() => {
    setComponentState((prev) => ({...prev, loading: true}));
    getQuestionnaire(locationList?.[0]?.uuid)
      .then((response) => {
        if (response?.data?.entry?.length) {
          setFormBuilderOptions((prev) => ({
            ...prev,
            questionnaireList: response.data.entry,
          }));
        }
        setComponentState((prev) => ({...prev, loading: false}));
      })
      .catch(() => {
        setComponentState((prev) => ({...prev, loading: false}));
      });
    if (noteCategoryId) {
      setFormCategory(noteCategoryId);
    }
  }, []);

  const getComponentById = (healthComponent: any, question: Question, questionnaire: FHIRQuestionnaireObject) => {
    return (allFormComponent || []).find(component => {
      return (component?.componentId || component?.referenceId) && component?.isHealthComponentField && component?.questionId === question?.id &&
        component?.questionnaireId === questionnaire.id && component?.parentComponentId === getComponentId(healthComponent)
    });
  };

  const getHealthComponentRiskScoreComponent = (healthComponent: any) => {
    const newFormComponents: any[] = [];
    const enabledQuestionnairesObj = healthComponent?.enabledQuestionnaires ? cloneDeep(healthComponent.enabledQuestionnaires || {}) : undefined;
      (formBuilderOptions.questionnaireList || []).forEach((questionnaireObj, index) => {
        const questionnaireId =  questionnaireObj?.resource?.id  || -1;
        if (enabledQuestionnairesObj && Object.keys(enabledQuestionnairesObj)?.length
          && enabledQuestionnairesObj.hasOwnProperty(questionnaireId) && !enabledQuestionnairesObj[questionnaireId]) {
            return;
        }
        const questionList = getQuestionListFromQuestionnaire(questionnaireObj?.resource);
        const isRiskScoreFieldTypeQuestion = isRiskScoreTypeQuestionsExist(questionList || []);
        if (isRiskScoreFieldTypeQuestion) {
          (questionList || []).forEach((question: Question, questionIndex: number) => {
            const type = getTypeByQuestion(question);
            if (ALLOWED_QUESTION_TYPE_IN_RISK_SCORE.includes(type)) {
              const selectedHealthComponent = getComponentById(healthComponent, question, questionnaireObj?.resource);
              if (selectedHealthComponent?.id) {
                newFormComponents.push(selectedHealthComponent);
              } else {
                const sequenceNumber = ((formComponents?.length || 0) + (newFormComponents?.length || 0) + 1);
                const questionnaire = questionnaireObj?.resource;
                const params = { questionnaire, question, healthComponent, sequenceNumber, type: question?.type, answerOptions: question?.answerOptions, repeats: question?.repeats || false };
                newFormComponents.push(getComponentByQuestionType(params));
              }
            }
          });
        }
      });
    return newFormComponents;
  };

  const generateRiskScoreEnabledComponent = () => {
    let riskScoreComponent: any[] = [];
    (formComponents || []).forEach(component => {
      if (isRiskScoreEnabledHealthComponent(component)) {
        riskScoreComponent.push(component);
        const componentList = getHealthComponentRiskScoreComponent(component);
        riskScoreComponent = riskScoreComponent.concat(componentList || []);
      } else {
        riskScoreComponent.push(component);
      }
    });
    if (riskScoreComponent?.length) {
      setAllFormComponent(riskScoreComponent);
      setFormComponents(riskScoreComponent);
      setScreenContext(FormScreenContext.riskScore);
    } else {
      setScreenContext(FormScreenContext.riskScore);
    }
  };

  const getDefaultEnableQuestionnaireList = () => {
    const questionnaireMap: {[key: string]: boolean} = {};
    (formBuilderOptions.questionnaireList || []).forEach((item: any) => {
      questionnaireMap[`${item.resource.id}`] = true
    });
    return questionnaireMap;
  };

  const filterFormCategories = (formCategories: IFormCategory[]) => {
    //filtered the note form category if form sent to patients and current form category is not the note category
    return formCategories?.filter(
      (formCategory: IFormCategory) => {
        const filterNoteCategory = formLogCount > 0 && !isNoteCategoryForm;
        const filterCarePlanCategory = !isCarePlanCategorySelected;
        const skipNoteCategory = formCategory.code !== FORM_CATEGORY_TYPES.NOTES;
        const skipCarePlanCategory = formCategory.code !== FORM_CATEGORY_TYPES.CARE_PLAN;

        if (filterNoteCategory && filterCarePlanCategory) {
          return (
            skipNoteCategory &&
            skipCarePlanCategory
          );
        } else if (filterNoteCategory) {
          return skipNoteCategory;
        } else if (filterCarePlanCategory) {
          return skipCarePlanCategory;
        }
        return true;
      }
    );
  }

  const onFormBuilderSchemaChange = useCallback((schema: any) => {
    if (screenContext !== FormScreenContext.editForm) {
      return;
    }
    getUpdateComponentsAfterChange(schema.components);
    let riskScoreComponent: any[] = [];
    (schema.components || []).forEach((component:any) => {
      if (isRiskScoreEnabledHealthComponent(component)) {
        riskScoreComponent.push(component);
        const componentList =
          getHealthComponentRiskScoreComponent(component);
        riskScoreComponent = riskScoreComponent.concat(
          componentList || []
        );
      } else {
        riskScoreComponent.push(component);
      }
    });
    setFormComponents(riskScoreComponent);
  }, [screenContext]);

  const onEditComponent = useCallback(() => {
    setIsSmallHeaderView(true)
  }, []);

  const onRemoveComponent = useCallback((isSelectedComponentRemoved) => {
    setIsSmallHeaderView(!isSelectedComponentRemoved)
    }, []);

  const onScreenTypeChange = useCallback((type) => {
    setScreenType(type);
  }, []);

  const showEditor = useMemo(() =>
    isEnabledMultiTenancy ?
    (ehrAndLocationSelect.locationIdList.length > 0 && ehrAndLocationSelect.ehrCode && formBuilderOptions.ehrCapabilities?.length > 0 && !ehrCapabilitiesQueryLoading) :
    true, [ehrAndLocationSelect, formBuilderOptions, ehrCapabilitiesQueryLoading]);

  return (
    <View>
      {/* {(!quickNoteCreation && !props.isQuickForm) && (
        <View backgroundColor={'white'}>
          <TitleSubtitleView
            titleLabelId={isUpdate ? 'updateForm' : 'createForm'}
          />
        </View>
      )} */}
      <View
        paddingX={0}
        // paddingTop={2}
        backgroundColor="white"
        borderRadius={8}
      >
        {!loading && (
          <TableTopBar {...getCustomerListTopBarButtons()} />
        )}
      </View>
      <Divider />
      <ConfirmOnExitOrReload>
      <>
        {!loading && !submitFormLoading && !ehrCapabilitiesQueryLoading && (
        <Box overflow="hidden">
          <View
            borderColor={Colors.Custom.BorderColor}
            borderBottomWidth={0.5}
            backgroundColor={'#fff'}
            height={
              // screenContext === FormScreenContext.editForm &&
              // !loading &&
              // (prevFormComponents.length > 0 || selectedForm?.id)
              //   ? screenHeight - 260
              //   : screenHeight - 260
                screenHeight - 200 - (props.heightOffset || 0)
            }
            overflow={'hidden'}
          >
            {!loading && (
              <div style={screenContext === FormScreenContext.editForm ? {} : {display: 'none'}}>
              <View margin={0}>
                {
                showEditor ?
                <>
                {getFormBuilderHeaderElement()}
                <FHFormBuilder
                  builderConfig={builderConfig}
                  defaultComponents={prevFormComponents}
                  options={formBuilderOptions}
                  onChange={onFormBuilderSchemaChange}
                  onEditComponent={onEditComponent}
                  onRemoveComponent={onRemoveComponent}
                />
                </> : (
                      <View
                        justifyContent={'center'}
                        alignItems="center"
                        marginTop={10}
                      >
                        <Text>Please select location and ehr.</Text>
                      </View>
                    )}

              </View>
              </div>
            )}
            {screenContext === FormScreenContext.riskScore && !loading && (
              // <RiskScoreConfiguration
              //   showErrors={componentState.showErrors}
              //   components={formComponents}
              //   riskScoreData={formMetadata}
              //   onChange={(data) => {
              //     setFormMetadata(data);
              //   }}
              // />
              <SectionWiseRiskScoreView
                showErrors={componentState.showErrors}
                components={formComponents}
                formMetadata={formMetadata}
                onChange={(data) => {
                  setFormMetadata(data);
                }}
                questionnaireList={formBuilderOptions.questionnaireList}
              />
            )}
            {screenContext === FormScreenContext.preview && !loading && (
              <View height={'80%'}>
                <WebPreview onScreenTypeChange={onScreenTypeChange}>
                  <PublicFormView
                    formData={{
                      name: formName ? formName.trim() : formName,
                      components: [
                        ...formComponents,
                        {
                          type: 'button',
                          label: 'Submit',
                          key: 'submit',
                          size: 'md',
                          block: false,
                          action: 'submit',
                          disableOnInvalid: true,
                          theme: 'primary',
                        },
                      ],
                      noData: false,
                      id: selectedForm?.id,
                    }}
                    optionData={formBuilderOptions}
                    hideBorder={screenType !== PREVIEW_TYPE.DESKTOP}
                    extraStyles={
                      screenType === PREVIEW_TYPE.DESKTOP
                        ? {
                            marginX: {
                              base: 0,
                              lg: 20,
                            },
                          }
                        : {
                            marginX: {
                              base: 0,
                              lg: 0,
                            },
                            marginY: {
                              base: 0,
                              lg: 0,
                            },
                            padding: 2,
                          }
                    }
                  />
                </WebPreview>
              </View>
            )}
          </View>
          {FormScreenContext.editForm === screenContext && (
            <HStack
              alignSelf={'center'}
              alignItems={'center'}
              justifyContent="center"
              w="100%"
              marginTop={2}
            >
              <InfoSvg customColor={'#F37232'} />
              <Text size={'smMedium'} style={styles.text}>
                Please do not request for any financial information (credit card
                details etc) using these forms
              </Text>
            </HStack>
          )}
        </Box>
        )}
      {(loading || submitFormLoading || ehrCapabilitiesQueryLoading) && (
        <View margin={4} {...testID(TestIdentifiers.lazyLoading)}>
          <Skeleton active />
        </View>
      )}
      </>
      </ConfirmOnExitOrReload>
    </View>
  );
};

export default React.memo(AddOrUpdateForm);
