import React from 'react';
import { useLazyQuery } from '@apollo/client';
import { Select, Spin, Tag, Tooltip, notification } from 'antd';
import { debounce } from 'lodash';
import { Button, FormControl, HStack, Icon, Pressable, Spinner, Text, View, VStack } from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import AntIcon from 'react-native-vector-icons/AntDesign';
import FeatherIcon from 'react-native-vector-icons/Feather';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { PatientQueries, TaskQueries, UserQueries } from '../../../services';
import ContactsQueries from '../../../services/Contacts/ContactsQueries';
import { Colors } from '../../../styles';
import { getAccountUUID, getBooleanFeatureFlag, getUserData, getUserUUID, isActiveContact, isSearchTextNumeric } from '../../../utils/commonUtils';
import { getContactTypeId, getMlovId } from '../../../utils/mlovUtils';
import { IAccountData } from '../../RightSideContainer/Contacts/TeamMembers/EditAccount/interface';
import { isAddMemberAllowed, isPatientDetailViewAllowed, USER_ACCESS_PERMISSION } from '../../RightSideContainer/UserAccess/UserAccessPermission';
import { ParticipantType } from '../CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import {
  IAutoCompleteData,
  IParticipantSearch,
  IUserLocation
} from '../CalendarWidget/ParticipantAutoComplete/ParticipantInterfaces';
import { DisplayText } from '../DisplayText/DisplayText';
import MemberInfoListItem from '../MemberInfoListItem/MemberInfoListItem';
import UserInfoItem from '../UserInfoItem/UserInfoItem';
import { getContactSearchActionQuery, getSearchQuery } from './PatientSearchUtils';
import './patientSearchAndSelectStyles.css';
import { CreateAndDialNewNumber, formatPhoneNumber } from '../../RightSideContainer/CloudTelephony/ActiveNumbersTable/SelectPatientCallDrawer';
import { IContact } from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import PlusSvg from '../Svg/PlusSvg';
import { OPTION_SELECTION_TYPE, PARENT_CODE, getInActiveContactError } from '../../../constants';
import { checkAbilityAccess, checkAccountConfigAbilityAccess, getOrgAbilites } from '../../../utils/capabilityUtils';
import { FHIR_RESOURCE } from '../../../constants/FhirConstant';
import {withMiniContactViewHOC} from '../../MiniContactViewHOC';
import { IUserPatientSearchItem } from '../CustomComponents/CustomUserPatientSearch/CustomUserPatientSearch.native';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../constants/Configs';
import { GET_TASKS_GROUP_BY_ASSIGNEE } from '../../../services/Task/TasksQueries';
import { GLOBAL_ROLE_CODES, TASK_STATUS, USER_ROLE_CODES } from '../../../constants/MlovConst';
import { SelectCommonPlacement } from 'antd/lib/_util/motion';
import { TestIdentifiers, testID } from '../../../testUtils';
import { USER_SEARCH_TYPE } from '../../RightSideContainer/constants';
import UserPracticeLocationQueries from '../../../services/Location/UserPracticeLocationQueries';
import { getLocationUuidFromPracticeLocations } from '../CustomUserSearch/CustomUserSearchUtils';
import { MAIN_MENU_CODES } from '../../SideMenuBar/SideBarConst';
import FeatureFlags from '../../../constants/FeatureFlags.enums';
import { isAccountConfigEnabled } from '../../../utils/configUtils';
import { CONFIG_CODES } from '../../../constants/AccountConfigConst';
import { StyleSheet } from 'react-native';
const {Option, OptGroup} = Select;
interface IPatientSearchAndSelect {
  isProspect?: boolean;
  defaultValue?: IParticipantSearch[];
  disableUserSearch?: boolean;
  disableLeadsSearch?: boolean;
  disablePatientSearch?:boolean;
  allowSingleSelectInCategory?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  showError?: boolean;
  isInvalid?: boolean;
  isRequired?: boolean;
  label?: string;
  patientsOptionLabel?: string;
  onChange: (participants: IParticipantSearch[], data?:any) => void;
  rightLabelTitle?: string;
  onRightLabelClick?: () => void;
  value?: any;
  mode?: any;
  allowAddNew?: boolean;
  addNewLabel?: string;
  border?: boolean;
  customStyle?: any;
  employerId?: string;
  contactData?: any;
  showEmail?: boolean;
  onFocus?: any;
  onBlur?: any;
  showPatientProfileLink?: boolean;
  onPatientProfileIconClick?: () => void;
  showTagInMultiline?: boolean;
  showPhoneNumber?: boolean;
  allowClear?: boolean;
  accountData?: IAccountData;
  showCurrentUserAtTop?: boolean;
  labelInfo?: string;
  labelColor?: string;
  offSuffixIcon?:boolean;
  isFromCreateSmsConversation?:boolean;
  poolSelected?: any;
  selectedPoolUsers?: any;
  defaultAssigneeInSelectedPool?: any;
  errors?: any;
  showErrorBorder?: boolean;
  searchPatientWithEmail?: boolean;
  closeSelect?: boolean;
  setCloseSelect?: () => void;
  parentCode?: string;
  openContactInWindowOrOpenContactIdDrawer?:(contactId?:string)=>void;
  showOnlyLoggedInPatients?: boolean;
  restrictPatientOrLeadSelectionTo?: IUserPatientSearchItem;
  disableChangeOfSelect?: boolean;
  handleOnClear?: boolean;
  dropdownPopupMinWidth?: number;
  showUserPendingTasksCount?: boolean;
  getPendingTaskCountOnFocus?: boolean;
  placement?: SelectCommonPlacement;
  isDefaultSearch?: boolean;
  navigateOrOpenLeadInWindow?: (contactId?: string | number) => void;
  isSidecarTaskLabelSelect?: boolean;
  className?: any;
  userSearchType?: keyof typeof USER_SEARCH_TYPE;
  accountLocationUuids?: string[];
  resourceType?: string;
  userLocations?: any[];
}

const PatientSearchAndSelect = (props: IPatientSearchAndSelect) => {
  const intl = useIntl();
  const accountId = getAccountUUID();
  const userUuid = getUserUUID();
  const [offsets, setOffsets] = useState({
    userOffset: 0,
    patientOffset: 0,
    leadOffset: 0
  });
  const pageSize = 20;
  const [loadMoreIterationLead, setloadMoreIterationLead] =  useState(1);
  const [loadMoreIterationUser, setloadMoreIterationUser] =  useState(1);
  const [loadMoreIterationPatient, setloadMoreIterationPatient] =  useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED)
  const [searchText, setSearchText] = useState('');
  // const [value, setValue] = useState<IParticipantSearch[]>(props.defaultValue);
  const [patientLoading, setPatientLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [patientData, setPatientData] = useState<IParticipantSearch[]>([]);
  const [staffData, setStaffData] = useState<IParticipantSearch[]>([]);
  const [userData, setUserData] = useState<IParticipantSearch[]>([]);
  const [userLocationData, setUserLocationData] = useState<IUserLocation>({
    locationUuids: [],
  });
  const [loadMoreLoading, setLoadMoreLoading] = useState({
    patient: false,
    user: false,
    lead: false
  });
  const [pendingTasksCountByAssigneeId, setPendingTasksCountByAssigneeId] = useState<Record<string, number>>({});
  const [leadData, setLeadData] = useState<IParticipantSearch[]>([]);
  const userUUID = getUserUUID();
  const currentUserData = getUserData();
  const mlovData = useContext(CommonDataContext);
  const isMultiTenancyEnabled = getBooleanFeatureFlag(mlovData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const accountThemeConfig: any = mlovData.accountThemeConfig || {};
  const { selectedPoolUsers, defaultAssigneeInSelectedPool, showCurrentUserAtTop, parentCode, handleOnClear, getPendingTaskCountOnFocus} = props;

  const leadContactType = getContactTypeId('LEAD');
  const customerContactType = getContactTypeId(
    'CUSTOMER'
  );

  const canCreatePatient = checkAccountConfigAbilityAccess(FHIR_RESOURCE.PATIENT, 'canCreate')?.isEnabled;

  const createNewEntry = async (serachText: string) => {
    const numberToCreate = '+1' + searchText;
    const newData = await CreateAndDialNewNumber(numberToCreate, accountId)
    props.onChange(newData?.data?.data?.createContactPerson?.contact)
  };

  const filterPoolUsers = (usersData: any[]) => {
    if (showCurrentUserAtTop || selectedPoolUsers?.length || defaultAssigneeInSelectedPool?.key) {
      return (usersData || []).filter(user => {
        if (defaultAssigneeInSelectedPool?.key === user?.value) {
          return false;
        }
        if (user?.value === userUUID && showCurrentUserAtTop) {
          return false;
        }
        const isUserInPoolList = (selectedPoolUsers || []).some((poolUser: any) => {
          return poolUser?.key == user?.value;
        });
        return isUserInPoolList ? false : true;
      })
    }
    return usersData;
  }

  const [
    getTasksGroupByAssigneeId,
    getTasksGroupByAssigneeQuery,
  ] = useLazyQuery<{
    getTasks: {  tasksByGroup: { aggregate: Array<{ assigneeId: string, count: number; }> } },
  }>(GET_TASKS_GROUP_BY_ASSIGNEE, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
    onCompleted: (response) => {
      if (response?.getTasks?.tasksByGroup?.aggregate?.length) {
        const pendingTasksCountByAssigneeId: Record<string, number> = {};

        response?.getTasks?.tasksByGroup?.aggregate?.forEach((aggregate) => {
          if (aggregate?.assigneeId && aggregate.count > -1) {
            pendingTasksCountByAssigneeId[aggregate.assigneeId] = aggregate.count;
          }
        });

        setPendingTasksCountByAssigneeId((prev) => ({...prev, ...pendingTasksCountByAssigneeId}));
      }
    },
    onError: () => {
      //
    }
  });

  const fetchTasksGroupByAssigneeId = (assigneeIds: string[]) => {
    if (props.showUserPendingTasksCount && assigneeIds?.length) {
      getTasksGroupByAssigneeId({
        variables: {
          params: {
            assigneeIds: assigneeIds,
            groupBy: [ 'assigneeId' ],
            statusCodesNotIn: [ TASK_STATUS.COMPLETED ],
          },
        },
      });
    }
  }

  const [getUserPracticeLocation] = useLazyQuery(
    UserPracticeLocationQueries.GetMyPracticeLocations,
    {
      fetchPolicy: 'no-cache',
      variables: {
        userUuid: userUuid,
      },
    }
  );

  const [getUsers] = useLazyQuery( props?.resourceType ? UserQueries.GET_USERS_BY_RESOURCE_CODES : UserQueries.SEARCH_USER_BY_TEXT, {
      onCompleted: (data: any) => {
        let usersData: any[] = [];
        const users = data?.searchUsers?.users || data?.users;
        if (users && users.length > 0) {
          usersData = users.map((user: any): IParticipantSearch => {
            return {
              label: user.name,
              value: user.uuid,
              key: user.uuid,
              email: user?.email,
              type: ParticipantType.staff,
              contactData: user,
            };
          });
        }
        setUserData(prev => [...prev, ...usersData]);
        const filterUsers = filterPoolUsers(usersData);
        setStaffData(prev => [...prev, ...filterUsers]);
        setUserLoading(false);
        setLoadMoreLoading(prev => ({
          ...prev,
          user: false
        }));

        if (props.showUserPendingTasksCount) {
          const assigneeIds = filterUsers?.map((user) => {
            return user.value;
          });
          assigneeIds.push(userUUID);
          fetchTasksGroupByAssigneeId(assigneeIds);
        }
        // setIsOpen(true);
      },
      onError: (error) => {

        setStaffData([]);
        setUserData([]);
        setUserLoading(false);
        setLoadMoreLoading(prev => ({
          ...prev,
          user: false
        }));
        // setIsOpen(true);
      },
    });

  const [getUsersByLocationUuid] = useLazyQuery(
    UserQueries.SEARCH_USER_BY_TEXT_BY_LOCATION_UUID,
    {
      onCompleted: (data: any) => {
        let usersData: any[] = [];
        if (data && data.users && data.users.length > 0) {
          usersData = data.users.map((user: any): IParticipantSearch => {
            return {
              label: user.name,
              value: user.uuid,
              key: user.uuid,
              email: user?.email,
              type: ParticipantType.staff,
              contactData: user,
            };
          });
        }
        setUserData((prev) => [...prev, ...usersData]);
        const filterUsers = filterPoolUsers(usersData);
        setStaffData((prev) => [...prev, ...filterUsers]);
        setUserLoading(false);
        setLoadMoreLoading((prev) => ({
          ...prev,
          user: false,
        }));

        if (props.showUserPendingTasksCount) {
          const assigneeIds = filterUsers?.map((user) => {
            return user.value;
          });
          assigneeIds.push(userUUID);
          fetchTasksGroupByAssigneeId(assigneeIds);
        }
        // setIsOpen(true);
      },
      onError: (error) => {
        setStaffData([]);
        setUserData([]);
        setUserLoading(false);
        setLoadMoreLoading((prev) => ({
          ...prev,
          user: false,
        }));
        // setIsOpen(true);
      },
    }
  );

  const getLocationUuids = async () => {
    let locationData: any = undefined;
    if (props.userLocations?.length) {
      locationData = {userPracticeLocations: props.userLocations || []};
    } else {
      const response = await getUserPracticeLocation();
      locationData = {userPracticeLocations: response?.data || []};
    }
    const locationUuids = getLocationUuidFromPracticeLocations(locationData);
    if (locationUuids?.length) {
      setUserLocationData((prev) => {
        return {
          ...prev,
          locationUuids: locationUuids,
        };
      });
      return locationUuids;
    }
  };

  const [getContactsByAction] = useLazyQuery(
    PatientQueries.GLOBAL_CONTACTS_SEARCH_BY_ACTION,
    {
      onCompleted: (data: any) => {
        let contactsData: any[] = [];
        if (data && (data.contacts || data?.searchContacts?.contacts) && (data.contacts?.length || data.searchContacts?.contacts?.length) > 0) {
          const contacts = data.contacts || data?.searchContacts?.contacts;
          contactsData = contacts.map(
            (contact: any): IParticipantSearch => {
              return {
                label: contact.name,
                value: contact.uuid,
                key: contact.uuid,
                email: contact?.email,
                type: ParticipantType.leads,
                contactId: contact.uuid,
                contactData: contact,
              };
            }
          );
        }
        setLeadData(prev => [...prev, ...contactsData]);
        setLoadMoreLoading(prev => ({
          ...prev,
          lead: false
        }));
        setUserLoading(false);
        // setIsOpen(true);
      },
      onError: (error) => {

        setLeadData([]);
        setUserLoading(false);
        setLoadMoreLoading(prev => ({
          ...prev,
          lead: false
        }));
        // setIsOpen(true);
      },
    }
  );
  const [getContacts] = useLazyQuery(
    props?.isDefaultSearch ? ContactsQueries.SEARCH_CONTACTS_WITH_GLOBAL_SEARCH_WITH_ORDERBY  : ContactsQueries.SEARCH_CONTACTS_WITH_GLOBAL_SEARCH,
    {
      onCompleted: (data: any) => {
        let contactsData: any[] = [];
        if (data && data.contacts && data.contacts.length > 0) {
          contactsData = data.contacts.map(
            (contact: any): IParticipantSearch => {
              return {
                label: contact.name,
                value: contact.uuid,
                key: contact.uuid,
                email: contact?.email,
                type: ParticipantType.leads,
                contactId: contact.uuid,
                contactData: contact,
              };
            }
          );
        }
        setLeadData(prev => [...prev, ...contactsData]);
        setLoadMoreLoading(prev => ({
          ...prev,
          lead: false
        }));
        setUserLoading(false);
        // setIsOpen(true);
      },
      onError: (error) => {

        setLeadData([]);
        setUserLoading(false);
        setLoadMoreLoading(prev => ({
          ...prev,
          lead: false
        }));
        // setIsOpen(true);
      },
    }
  );
  const [getPatientsByAction] = useLazyQuery(
    PatientQueries.GLOBAL_CONTACTS_SEARCH_BY_ACTION,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data: any) => {
        const patientUpdatedData = getUpdatedPatientData(data);
        let updatedData: any[] = [];
        if (patientUpdatedData) {
          updatedData = patientUpdatedData.filter(
            (patient: IParticipantSearch) => {
              return patient.label;
            }
          );
        }
        setPatientData((prev) => [...prev, ...updatedData]);
        setPatientLoading(false);
        setLoadMoreLoading((prev) => ({
          ...prev,
          patient: false,
        }));
        // setIsOpen(true);
      },
      onError: (error) => {
        setPatientData([]);
        setPatientLoading(false);
        setLoadMoreLoading((prev) => ({
          ...prev,
          patient: false,
        }));
        // setIsOpen(true);
      },
    }
  );
  const [getPatients] = useLazyQuery(
    props?.isDefaultSearch ? PatientQueries.SEARCH_PATIENT_IN_GLOBAL_SEARCH_WITH_ORDERBY : PatientQueries.SEARCH_PATIENT_IN_GLOBAL_SEARCH,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data: any) => {
        const patientUpdatedData = getUpdatedPatientData(data);
        let updatedData: any[] = [];
        if (patientUpdatedData) {
          updatedData = patientUpdatedData.filter(
            (patient: IParticipantSearch) => {
              return patient.label;
            }
          );
        }
        setPatientData(prev=> [...prev, ...updatedData]);
        setPatientLoading(false);
        setLoadMoreLoading(prev => ({
          ...prev,
          patient: false
        }));
        // setIsOpen(true);
      },
      onError: (error) => {

        setPatientData([]);
        setPatientLoading(false);
        setLoadMoreLoading(prev => ({
          ...prev,
          patient: false
        }));
        // setIsOpen(true);
      },
    }
  );
  const getUpdatedPatientData = (data: any): any | undefined => {
    const fetchedContacts = data?.searchContacts?.contacts || data.contacts;
    if (fetchedContacts && fetchedContacts.length > 0) {
      let patientList;
      if (props?.showOnlyLoggedInPatients) {
        patientList = fetchedContacts.filter((contact: any) => {
          if (contact?.additionalAttributes?.loggedInTime) {
            return true;
          }
          return false;
        })
      } else {
        patientList = fetchedContacts;
      }
      return patientList.map((contact: any): IParticipantSearch => {
        return {
          label: contact.name,
          value: contact.uuid,
          key: contact.uuid,
          email: contact?.email,
          type: ParticipantType.patient,
          contactId: contact.id,
          contactData: contact,
        };
      });
    }
  };

  const getWhereClauseForUserSearch = (searchString: string) => {

    return {
      accountUsers: { isActive: { _eq: true } },
      name: { _ilike: `%${searchString}%` },
      isDeleted: { _eq: false },
      _not: {
        userRoles: {
          userRole: {
            userRole: { code: { _in: ["WORKFLOW", "CUSTOMER_SUCCESS"] } }
          }
        }
      }
    }
  };

  const getSearchParams = (searchString: string, offset?: number, accountLocationUuids?: string[]) => {
    return {
      isActive: true,
      name: searchString ? `%${searchString}%` : '',
      limit: pageSize,
      offset: offset ? offset : offsets.userOffset,
      orderBy: { name: 'asc' },
      userRoleCodesNotIn: [USER_ROLE_CODES.WORFLOW_USER, USER_ROLE_CODES.CUSTOMER_SUCCESS, USER_ROLE_CODES.WORKFLOW],
      ...(isMultiTenancyEnabled && {
        permittedFor: {
          allOf: accountLocationUuids?.map((locationId) => {
            return {
              resourceCode: `${USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code}/${MAIN_MENU_CODES.TASKS}`,
              accountLocationId: locationId
            }
          })
        },
      })
    };
  };

  const searchUsers = (searchString: string, offset?: number) => {
    if (!!offset && offset !== 0) {
      setOffsets(prev => ({
        ...prev,
        userOffset: offsets.userOffset + pageSize
      }));
    }
    if (searchString !== '') {
      setUserLoading(true);
    }
    if( searchString === '' && offset !== undefined){
      setLoadMoreLoading(prev => ({
        ...prev,
        user: true
      }));
    }
    setloadMoreIterationUser(1);
    setIsOpen(true);
    const userboolExp = props?.userSearchType === USER_SEARCH_TYPE.SELECTED_LOCATION_BASED && isMsoEnabled
    ? {
        _and: [
          {
            userRoles: {
              userRole: {
                userRole: {
                  code: { _in: [GLOBAL_ROLE_CODES.GLOBAL_ADMIN] }
                }
              }
            }
          },
          {
            name: { _ilike: `%${searchString}%` }
          }
        ]
      }
    : {};
    if (!props.disableUserSearch) {
      if (
        props?.userSearchType &&
        props?.userSearchType === USER_SEARCH_TYPE.SELECTED_LOCATION_BASED
      ) {
        getUsersByLocationUuid({
          variables: {
            searchString: `%${searchString}%`,
            limit: pageSize,
            offset: offset ? offset : offsets.userOffset,
            accountId: accountId,
            locationUuids: props?.accountLocationUuids,
            boolExp: userboolExp
          },
        });
      } else {
        if (props?.resourceType) {
          getUsers({
            variables: {
              params: getSearchParams(searchString, offset, props?.accountLocationUuids)
            },
          });
        } else {
          getUsers({
            variables: {
              limit: pageSize,
              offset: offset ? offset : offsets.userOffset,
              searchString: `%${searchString}%`,
              isActive: true,
            },
          });
      }
    }
  }
}
  const searchLeads = (searchString: string, offset?: number) => {
    if (!!offset && offset !== 0) {
      setOffsets(prev => ({
        ...prev,
        leadOffset: offsets.leadOffset + pageSize
      }));
    }
    if (searchString !== '') {
      setUserLoading(true);
    }
    if( searchString === '' && offset !== undefined){
      setLoadMoreLoading(prev => ({
        ...prev,
        lead: true
      }));
    }
    setIsOpen(true);
    setloadMoreIterationLead(1);
    if (!props.disableLeadsSearch) {
      // const where = getSearchQuery({
      //   searchString,
      //   contactTypeId: customerContactType,
      //   employerId: props.employerId,
      //   isFromCreateSmsConversation: props.isFromCreateSmsConversation,
      //   searchPatientWithEmail: props.searchPatientWithEmail,
      // });
      const searchContactFilter = getContactSearchActionQuery({
        searchString,
        contactTypeNotId: customerContactType,
        employerId: props.employerId,
        isFromCreateSmsConversation: props.isFromCreateSmsConversation,
        searchPatientWithEmail: props.searchPatientWithEmail,
        accountLocationIds: props?.accountLocationUuids,
        ...(props?.isDefaultSearch
          ? {
              orderBy: {
                name: 'asc',
              },
            }
          : {}),
      });
      getContactsByAction({
        variables: {
          params: searchContactFilter
        }
      })
    }
  }

  const searchPatients = (searchString: string, offset?: number) => {
    if (!!offset && offset !== 0 ) {
      setOffsets(prev => ({
        ...prev,
        patientOffset: offsets.patientOffset + pageSize
      }));
    }
    if (searchString !== '') {
      setPatientLoading(true);
    }
    if( searchString === '' && offset !== undefined){
      setLoadMoreLoading(prev => ({
        ...prev,
        patient: true
      }));
    }
    setloadMoreIterationPatient(1);
    setIsOpen(true);
    // const where = getSearchQuery({
    //   searchString,
    //   contactTypeId: customerContactType,
    //   employerId: props.employerId,
    //   isFromCreateSmsConversation: props.isFromCreateSmsConversation,
    //   searchPatientWithEmail: props.searchPatientWithEmail,
    //   isPatient: true,
    // });
    const searchContactFilter = getContactSearchActionQuery({
      searchString,
      contactTypeId: customerContactType,
      employerId: props.employerId,
      isFromCreateSmsConversation: props.isFromCreateSmsConversation,
      searchPatientWithEmail: props.searchPatientWithEmail,
      accountLocationIds: props?.accountLocationUuids,
      ...(props?.isDefaultSearch
        ? {
            orderBy: {
              name: 'asc',
            },
          }
        : {}),
    });
    if (searchContactFilter && !props.disablePatientSearch) {
      searchContactFilter.limit = pageSize
      searchContactFilter.offset = offset ? offset : offsets.patientOffset,
      getPatientsByAction({
        variables: {
          params: searchContactFilter
        }
      })
    }
    else {
      // if searchString is a date(birth date) but not a valid date or if it is a future date, then no need to run query
      setPatientData([]);
      setPatientLoading(false);
      setLoadMoreLoading(prev => ({
        ...prev,
        patient: false
      }));
    }
  }

  const searchPatient = (searchString: string) => {
    setPatientData([]);
    setStaffData([]);
    setUserData([]);
    setLeadData([]);
    setSearchText(searchString);
    if (props?.isDefaultSearch) {
      searchPatients(searchString, 0);
      if (!props.disableUserSearch || !props.disableLeadsSearch) {
        if (!props.disableUserSearch) {
          searchUsers(searchString, 0);
        }
        if (!props.disableLeadsSearch) {
          searchLeads(searchString, 0);
        }

      }
    }
    if (searchString.length > 0) {
      searchPatients(searchString);
      if (!props.disableUserSearch || !props.disableLeadsSearch) {
        if (!props.disableUserSearch) {
          searchUsers(searchString, 0);
        }
        if (!props.disableLeadsSearch) {
          searchLeads(searchString, 0);
        }
      }
    } else {
      setIsOpen(false);
      setOffsets(prev => ({
        ...prev,
        patientOffset: 0,
        leadOffset: 0,
        userOffset: 0
      }));
    }
  };

  const isNameMatchedToSearchText = (name: string, searchText: string) => {
    if (!searchText) {
      return true;
    }
    return name?.toLocaleLowerCase()?.includes(searchText?.trim()?.toLocaleLowerCase());
  };

  const getFilteredPoolUsersBySearchText = (searchText: string) => {
    return selectedPoolUsers?.filter?.((user: {label: string}) =>
      isNameMatchedToSearchText(user?.label, searchText)
    );
  };

  const getOptions = (): IAutoCompleteData[] => {
    const options: IAutoCompleteData[] = [];
    const employerStaff = staffData.filter((user: IParticipantSearch) => {
      return user?.contactData?.userRoles.some((item) => {
       return item?.userRole?.userRole?.code === 'EMPLOYER';
      });
    });
    const otherStaff = staffData.filter((user: IParticipantSearch) => {
      return !user?.contactData?.userRoles.some((item) => {
       return item?.userRole?.userRole?.code === 'EMPLOYER';
      });
    });
    const filteredPoolUsers = searchText
      ? getFilteredPoolUsersBySearchText(searchText)
      : selectedPoolUsers;
    if(filteredPoolUsers?.length){
      options.push({
        label: (
          <Text fontSize="xs" color="gray.400">
            {intl.formatMessage({id: 'usersInSelectedTaskPool'})}
          </Text>
        ),
        key: 'PoolUsers',
        options: filteredPoolUsers
      })
    }
    if (staffData.length > 0) {
      options.push({
        label: (
          <Text fontSize="xs" color="gray.400">
            Staff
          </Text>
        ),
        key: 'Staffs',
        options: otherStaff,
      });
    }
    if (staffData.length > 0 && (loadMoreIterationUser * pageSize) === userData.length) {
      options.push({
        label: (
          <Pressable onPress={() => { searchUsers('', offsets.userOffset + pageSize); setloadMoreIterationUser(loadMoreIterationUser + 1) }}>
            <Text fontSize={12} color={Colors.Custom.mainPrimaryPurple} alignSelf={'center'} fontWeight={400}>
              Load More
            </Text>
          </Pressable>
        ),
        key: 'LoadMoreStaff',
        options: [],
      });
    }
    if (staffData.length > 0 && loadMoreLoading.user) {
      options.push({
        label: (
          <Spinner />
        ),
        key: 'LoadMoreStaffLoader',
        options: [],
      });
    }
    if (!props?.restrictPatientOrLeadSelectionTo && patientData.length > 0) {
      options.push({
        label: (
          <Text fontSize="xs" color="gray.400">
            {props.patientsOptionLabel || 'Patients'}
          </Text>
        ),
        key: 'Patients',
        options: patientData,
      });
    }
    if (!props?.restrictPatientOrLeadSelectionTo && patientData.length > 0 && (loadMoreIterationPatient * pageSize) === patientData.length) {
      options.push({
        label: (
          <Pressable onPress={() => { searchPatients('', offsets.patientOffset + pageSize); setloadMoreIterationPatient(loadMoreIterationPatient + 1)}}>
            <Text fontSize={12} color={Colors.Custom.mainPrimaryPurple} alignSelf={'center'} fontWeight={400}>
              Load More
            </Text>
          </Pressable>
        ),
        key: 'LoadMorePatient',
        options: [],
      });
    }
    if ( patientData.length > 0  && loadMoreLoading.patient) {
      options.push({
        label: (
          <Spinner />
        ),
        key: 'LoadMorePatientLoader',
        options: [],
      });
    }
    if (leadData.length > 0) {
      options.push({
        label: (
          <Text fontSize="xs" color="gray.400" letterSpacing='sm'>
            Prospects
          </Text>
        ),
        key: 'Prospects',
        options: leadData,
      });
    }
    if (leadData.length > 0 && (loadMoreIterationLead * pageSize) === leadData.length) {
      options.push({
        label: (
          <Pressable onPress={() => { searchLeads('', offsets.leadOffset + pageSize); setloadMoreIterationLead(loadMoreIterationLead + 1) }}>
            <Text fontSize={12} color={Colors.Custom.mainPrimaryPurple} alignSelf={'center'} fontWeight={400}>
              Load More
            </Text>
          </Pressable>
        ),
        key: 'LoadMoreLeads',
        options: [],
      });
    }
    if (leadData.length > 0 && loadMoreLoading.lead) {
      options.push({
        label: (
          <Spinner />
        ),
        key: 'LoadMoreLeadsLoader',
        options: [],
      });
    }
    if (employerStaff.length > 0) {
      options.push({
        label: (
          <Text fontSize="xs" color="gray.400">
            {'Employers'}
          </Text>
        ),
        key: 'Employers',
        options: employerStaff,
      });
    }
    return options;
  };

  const getTagClassName = (tagValue: any, tagLabel: string) => {
    const tagList =
      props.defaultValue &&
      props.defaultValue.filter((data: IParticipantSearch) => {
        return data.value === tagValue && data.label === tagLabel;
      });
    if (tagList && tagList.length > 0) {
      const tag = tagList[0];
      switch (tag.type) {
        case ParticipantType.patient:
          return 'patient-tag';
        case ParticipantType.staff:
          return 'staff-tag';
        case ParticipantType.leads:
          return 'contact-tag';
        default:
          break;
      }
    }
    return 'patient-tag';
  };

  const getLabelName = (ids: string[]) => {
    if (props.isProspect) {
      const name = getOptions()?.map((group) => {
        return group.options
          .filter((item) => ids.includes(item.value))
          .map((item) => item.label);
      });
      if (name?.length) {
        return [name];
      }
    } else {
      const name = patientData
        .filter((item) => ids.includes(item.value))
        .map((item) => item.label);
      return name;
    }
  };

  const getPatientName = (tagProps: any) => {
    return tagProps?.label?.props?.contactData?.name || '';
  };

  const tagRender = (tagProps: any) => {
    const onPreventMouseDown = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const selectedTags =
      props.defaultValue &&
      props.defaultValue.filter(
        (participant) => participant.value === tagProps.value
      );
    const selectedTag =
      selectedTags && selectedTags.length > 0 ? selectedTags[0] : undefined;
      const label = props.isProspect ? tagProps.value && getLabelName([tagProps.value])?.[0] : getPatientName(tagProps);
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={selectedTag?.isDisabled ? false : tagProps.closable}
        onClose={tagProps.onClose}
        style={reactStyles.marginRight3}
        className={getTagClassName(tagProps.value, tagProps.label)}
      >
        {label}
      </Tag>
    );
  };

  const handleValueChange = (values: IParticipantSearch[]) => {
    const existingValue = props.defaultValue;
    let result: IParticipantSearch[] = [];

    values.forEach((selectedValue: IParticipantSearch) => {
      const matchedData =
        existingValue && getMatchedValue(selectedValue.value, existingValue);
      if (matchedData) {
        result.push(matchedData);
      } else if (!props.disableUserSearch || !props.disableLeadsSearch) {
        const matchedPatient = getMatchedValue(selectedValue.value, patientData);
        if (matchedPatient) {
          if (props.allowSingleSelectInCategory) {
            result = result.filter(
              (value) => value.type !== ParticipantType.patient
            );
          }
          result.push(matchedPatient);
        }

        const matchedStaff = getMatchedValue(selectedValue.value, staffData);
        if (matchedStaff) {
          if (props.allowSingleSelectInCategory) {
            result = result.filter(
              (value) => value.type !== ParticipantType.staff
            );
          }
          result.push(matchedStaff);
        }

        const matchedLead = getMatchedValue(selectedValue.value, leadData);
        if (matchedLead) {
          if (props.allowSingleSelectInCategory) {
            result = result.filter(
              (value) => value.type !== ParticipantType.leads
            );
          }
          result.push(matchedLead);
        }
      } else {
        result.push(selectedValue);
      }
    });

    props.onChange(result);
  };

  const getMatchedValue = (
    key: string,
    list: IParticipantSearch[]
  ): IParticipantSearch | undefined => {
    const matchedData = list.filter((item) => {
      return item.key === key;
    });
    if (matchedData.length > 0) {
      return matchedData[0];
    }
    return undefined;
  };

  useEffect(() => {
    searchPatient('');
    getLocationUuids();
    if (props.value?.type === ParticipantType.patient && props.value?.contactData) {
      const patientUpdatedData = getUpdatedPatientData({ contacts: [ props.value.contactData ] });
      let updatedData = [];
      if (patientUpdatedData) {
        updatedData = patientUpdatedData.filter(
          (patient: IParticipantSearch) => {
            return patient.label;
          }
        );
      }
      setPatientData(updatedData);
    }


    if (props?.mode?.toLowerCase() === OPTION_SELECTION_TYPE.MULTIPLE.toLowerCase()) {
      const updatedData = (props?.value || [])?.filter(
        (patient: IParticipantSearch) => patient?.label
      );
      setPatientData(updatedData);
    }
  }, []);

  useEffect(() => {
    if (!getPendingTaskCountOnFocus && (props.defaultAssigneeInSelectedPool?.key || props.selectedPoolUsers?.length)) {
      const assigneeIds: string[] = [];
      if (props.defaultAssigneeInSelectedPool?.key) {
        assigneeIds.push(props.defaultAssigneeInSelectedPool.key);
      }
      if (props.selectedPoolUsers?.length) {
        props.selectedPoolUsers.forEach((poolUser: any) => {
          if (poolUser?.key) {
            assigneeIds.push(poolUser.key);
          }
        });
      }

      fetchTasksGroupByAssigneeId(assigneeIds);
    }
  }, [props.defaultAssigneeInSelectedPool, props.selectedPoolUsers]);

  const resetSearchedData = () => {
    if (patientData.length !== 0) {
      setPatientData([]);
    };
    if (staffData.length !== 0) {
      setStaffData([]);
    };
    if (userData.length !== 0) {
      setUserData([]);
    };
    if (leadData.length !== 0) {
      setLeadData([]);
    };
    if (searchText !== '') {
      setSearchText('');
    };
  };

  useEffect(() => {
    resetSearchedData();
  }, [props.accountLocationUuids]);

  const redirectToProfile = (contactId?: number | string, contactTypeId?: string)=> {
    if (!contactId) {
      return;
    }
    if (contactTypeId === customerContactType) {
      props?.openContactInWindowOrOpenContactIdDrawer?.(contactId.toString());
    } else {
      props?.navigateOrOpenLeadInWindow?.(contactId.toString());
    }
  }
  const isPatientDetailViewAllowedConfig = isPatientDetailViewAllowed(mlovData.currentUserRoles,mlovData.userPermissions);
  const showRedirectIcon = ()=> {
    return isPatientDetailViewAllowedConfig;
  }

  const renderPatientData = (patient: IParticipantSearch, index: number) => (
    <Option key={`${patient.key}_${index}`} value={patient.key} label={patient.label} email={patient?.email}>
      {renderPatientOrProspectElement(patient, false)}
    </Option>
  );

  const renderPatientOrProspectElement = (prospectOrPatientData: IParticipantSearch | IUserPatientSearchItem, isProspect?: boolean, showingRestrictedMemberAtTop?: boolean) => {
    return (
      isProspect ?
        <MemberInfoListItem
          showDateOfBirth={true}
          showEmail={props?.showEmail || false}
          showPhoneNumber={props?.showPhoneNumber || false}
          contactData={prospectOrPatientData?.contactData}
          isPatientSearchCard
          showTagging
          showTagInMultiline={props?.showTagInMultiline}
          showRedirectIcon={showRedirectIcon()}
          onRedirectToProfile= {()=> {
            const isActive = isActiveContact(prospectOrPatientData?.contactData);
            if (!isActive) {
              const message = getInActiveContactError(prospectOrPatientData?.contactData);
              notification.error({
                message,
              });
              return;
            }
            const contactId = prospectOrPatientData?.contactData?.id || prospectOrPatientData?.contactId;
            const contactTypeId = prospectOrPatientData?.contactData?.contactType?.contactType?.id;
            redirectToProfile(contactId, contactTypeId);
          }}
          isSidecarTaskLabelSelect={props?.isSidecarTaskLabelSelect}
        />
        :
        <MemberInfoListItem
          showDateOfBirth={true}
          contactData={prospectOrPatientData?.contactData}
          isPatientSearchCard
          showPhoneNumber={props?.showPhoneNumber || false}
          showEmail={props?.showEmail || false}
          showTagging
          showRedirectIcon={showRedirectIcon()}
          onRedirectToProfile= {()=> {
            const isActive = isActiveContact(prospectOrPatientData?.contactData);
            if (!isActive) {
              const message = getInActiveContactError(prospectOrPatientData?.contactData);
              notification.error({
                message,
              });
              return;
            }
            const contactId = prospectOrPatientData?.contactData?.id || prospectOrPatientData?.contactId;
            const contactTypeId = prospectOrPatientData?.contactData?.contactType?.contactType?.id;
            redirectToProfile(contactId, contactTypeId);
          }}
          isSidecarTaskLabelSelect={props?.isSidecarTaskLabelSelect}
        />
    )
  }

 const cleanedText = searchText.replace(/[\(\)\-\s]/g, '');

 const isAllDataEmpty = () => patientData.length === 0 && staffData.length === 0 && leadData.length === 0 && selectedPoolUsers?.length === (0 || undefined) && !userLoading && !patientLoading;

 const isSearchTextEmpty = () => searchText === '';

 const getSelectedValue = () => {
   if (props?.mode?.toLowerCase() === OPTION_SELECTION_TYPE.MULTIPLE.toLowerCase()) {
     if (props.value?.length) {
       return props.value?.map((item: any) => item?.value);
     } else {
       return undefined;
     }
   } else if (props.value) {
     return props.value;
   } else {
     return props.defaultValue;
   }
 };

 const memorizedSelectStyle = React.useMemo<React.CSSProperties>(()=> {
  return {
    borderRadius: props?.customStyle?.borderRadius || 8,
    height: 40,
    backgroundColor: props?.customStyle?.backgroundColor || Colors.Custom.White,
    borderColor: props?.customStyle?.borderColor || Colors.Custom.alertsDescriptionColor,
  }
 },[props?.customStyle?.borderRadius, props?.customStyle?.backgroundColor, props?.customStyle?.borderColor])

  return (
      <View>
        <FormControl
          isInvalid={props.showError || props.isInvalid}
          isDisabled={props.isDisabled}
        >
          {props.label && (
            <HStack
              space={1}
              marginBottom={props?.customStyle?.marginBottom || 0}
              justifyContent={'space-between'}
              alignItems="center"
            >
              <HStack>
              <FormControl.Label
                marginTop={props.rightLabelTitle ? 2 : 0}
                marginLeft={0}
                marginBottom={0}
                {...testID(props.label || '')}
              >
                <HStack marginBottom={0}>
                  <DisplayText
                    size={'smRegular'}
                    extraStyles={{
                      color: Colors.FoldPixel.GRAY250,
                      fontWeight: 500,
                      fontSize: 14,
                    }}
                    textLocalId={props.label || ''}
                  />
                  {props?.isRequired ? (
                    <DisplayText
                      size={'smMedium'}
                      extraStyles={{
                        color: Colors.danger[600],
                        fontWeight: 500,
                        fontSize: 14,
                      }}
                      textLocalId={'*'}
                      {...testID(TestIdentifiers.isRequiredInput)}
                    />
                  ) : null}
                  {props?.labelInfo && props?.labelInfo?.length > 0 ? (
                    <Tooltip
                      title={props?.labelInfo}
                      placement={'top'}
                      destroyTooltipOnHide={true}
                    >
                      <Icon
                        mt={1}
                        mx={2}
                        as={AntIcon}
                        color={Colors.Custom.Gray500}
                        name="infocirlceo"
                        size="smMedium"
                        {...testID('image')}
                      />
                    </Tooltip>
                  ) : null}
                </HStack>
              </FormControl.Label>
              {props.showPatientProfileLink && (
                <Pressable onPress={props.onPatientProfileIconClick}
                  {...testID(TestIdentifiers.viewProfileClick)}>
                  <HStack space={2} alignItems="center">
                    <Text fontSize="xs" color={Colors.secondary[500]}
                    {...testID(TestIdentifiers.viewProfile)}>
                      View Profile
                    </Text>
                    <Icon
                      as={FeatherIcon}
                      name="external-link"
                      size="xs"
                      color={Colors.secondary[500]}
                      {...testID('image')}
                    />
                  </HStack>
                </Pressable>
              )}
              </HStack>
              {isAddMemberAllowed() && props.rightLabelTitle && (
                <Button
                  style={styles.height36}
                  color={Colors.secondary['800']}
                  variant="link"
                  size={'lg'}
                  onPress={() => {
                    props.onRightLabelClick && props.onRightLabelClick();
                  }}
                >
                  <DisplayText
                    size={'smMedium'}
                    extraStyles={{
                      color: Colors.Custom.Gray700,
                      fontWeight: 500,
                      fontSize: 14,
                      textDecorationLine: 'underline',
                    }}
                    textLocalId={`+ ${
                      props.rightLabelTitle || props.addNewLabel
                    }`}
                  />
                </Button>
              )}
            </HStack>
          )}
          <Select
            className={props?.className}
            status={
              props?.showErrorBorder && (props.isInvalid || props.showError)
                ? 'error'
                : undefined
            }
            {...(props?.isSidecarTaskLabelSelect ? { popupClassName: 'sidecar-task-drawer-label' } : {})} // added this class to override default margin given to Ant's <OptGroup />
            placement={props.placement ? props.placement : undefined}
            style={memorizedSelectStyle}
            bordered={props?.border}
            dropdownStyle={{
              borderRadius: 8,
              ...(props.dropdownPopupMinWidth && { minWidth: props.dropdownPopupMinWidth }),
            }}
            showSearch={true}
            size={'large'}
            {...testID(TestIdentifiers.searchMember)}
            allowClear={props.allowClear === false ? props.allowClear : true}
            labelInValue={true}
            filterOption={false}
            disabled={props.isDisabled || props?.disableChangeOfSelect}
            value={getSelectedValue()}
            defaultValue={props.defaultValue}
            open={isOpen}
            suffixIcon={!props.offSuffixIcon}
            onFocus={() => {
              if(props?.isDefaultSearch){
                searchPatient('');
              }
              if (!props?.closeSelect) {
                setIsOpen(true);
                props?.onFocus?.();
                const assigneeIdsForPendingTaskCount: string[] = (getPendingTaskCountOnFocus && selectedPoolUsers?.length) ? selectedPoolUsers?.map((user: { key: any; }) => user?.key) : [userUUID];
                if (props?.defaultAssigneeInSelectedPool?.key) {
                  assigneeIdsForPendingTaskCount.push(props?.defaultAssigneeInSelectedPool?.key);
                }
                fetchTasksGroupByAssigneeId(assigneeIdsForPendingTaskCount);
              }
              props?.setCloseSelect && props?.setCloseSelect();
            }}
            onBlur={() => {
              setIsOpen(false);
              setOffsets(prev => ({
                ...prev,
                patientOffset: 0,
                leadOffset: 0,
                userOffset: 0
              }));
              props?.onBlur?.();
            }}
            onSearch={debounce(searchPatient, 600)}
            onChange={(value: IParticipantSearch[], data: any) => {
              if (props?.mode === 'multiple') {
                handleValueChange(value);
              } else {
                props.onChange(value, data);
                setIsOpen(false);
                setOffsets(prev => ({
                  ...prev,
                  patientOffset: 0,
                  leadOffset: 0,
                  userOffset: 0
                }));
              }
            }}
            onSelect={() => {
              if (props?.mode !== 'multiple') {
                setIsOpen(false);
                setOffsets(prev => ({
                  ...prev,
                  patientOffset: 0,
                  leadOffset: 0,
                  userOffset: 0
                }));
              }
            }}
            placeholder={props.placeholder}
            loading={patientLoading || userLoading}
            mode={props.mode ? props.mode : undefined}
            tagRender={props.mode === 'multiple' ? tagRender : undefined}
            // notFoundContent={
            //   (patientLoading || userLoading) && <Spin size="small" />
            // }
            onClear={()=> {
              if(handleOnClear) {
                setSearchText('');
                setPatientData([]);
                setStaffData([]);
                setUserData([]);
                setLeadData([]);
              }
            }}
            notFoundContent={
              patientLoading || userLoading ? (
                <Spin size="small" />
              ) : parentCode === PARENT_CODE.SMS_CONVERSATION_DRAWER &&
                isSearchTextNumeric(searchText) &&
                cleanedText.length === 10 ? (
                <Pressable
                  onPress={() => {
                    setIsOpen(false);
                    props.onChange([], cleanedText);
                  }}
                >
                  <Text>
                    {`Can you confirm to send sms to +1${formatPhoneNumber(
                      searchText
                    )}`}
                  </Text>
                </Pressable>
              ) : (
                <View>
                  {(props.rightLabelTitle || props.allowAddNew) &&
                  searchText &&
                  canCreatePatient ? (
                    <Button
                      style={styles.height36}
                      color={Colors.secondary['800']}
                      variant={'link'}
                      size={'lg'}
                      onPress={() => {
                        props.onRightLabelClick && props.onRightLabelClick();
                      }}
                    >
                      {`+ ${props.rightLabelTitle || props.addNewLabel}`}
                    </Button>
                  ) : searchText ? (
                    <Text color={Colors.FoldPixel.GRAY400}>No Data Found</Text>
                  ) : (
                    intl.formatMessage({id: 'NoDataSelect'})
                  )}
                </View>
              )
            }
            maxTagCount={'responsive'}
            data-testid={TestIdentifiers.selectDropdown}
          >
            {(props.showCurrentUserAtTop || !!props?.restrictPatientOrLeadSelectionTo) && !userLoading && !patientLoading ? (
              <>
                {props.showCurrentUserAtTop &&
                  isNameMatchedToSearchText(
                    currentUserData.name,
                    searchText
                  ) && (
                  <Option
                    key={userUUID}
                    label={currentUserData.name || ''}
                    value={userUUID}
                    email={currentUserData.email}
                    type={ParticipantType.staff}
                  >
                    <UserInfoItem
                      option={{
                        key: userUUID,
                        label: currentUserData.name || '',
                        contactData: currentUserData as any,
                        value: userUUID,
                        type: ParticipantType.staff,
                      }}
                      contactData={currentUserData}
                      showPendingTaskCount={props.showUserPendingTasksCount}
                      pendingTaskCount={pendingTasksCountByAssigneeId[userUUID]}
                      isPendingTaskCountLoading={getTasksGroupByAssigneeQuery?.loading}
                      isSidecarTaskLabelSelect={props?.isSidecarTaskLabelSelect}
                    />
                  </Option>
                )}
                {!!props?.restrictPatientOrLeadSelectionTo &&
                  isNameMatchedToSearchText(
                    props?.restrictPatientOrLeadSelectionTo?.label || '',
                    searchText
                  ) && (
                  <OptGroup
                    key={props?.restrictPatientOrLeadSelectionTo?.key}
                    label={props?.restrictPatientOrLeadSelectionTo?.type === ParticipantType.leads ? 'Prospect' : ParticipantType.patient}
                  >
                    <Option
                      key={props?.restrictPatientOrLeadSelectionTo?.key}
                      label={props?.restrictPatientOrLeadSelectionTo?.label || ''}
                      value={props?.restrictPatientOrLeadSelectionTo?.key}
                      email={props?.restrictPatientOrLeadSelectionTo?.contactData?.email || ''}
                      type={props?.restrictPatientOrLeadSelectionTo?.type}
                    >
                      {renderPatientOrProspectElement(
                        props?.restrictPatientOrLeadSelectionTo,
                        props?.restrictPatientOrLeadSelectionTo?.type !== ParticipantType.patient,
                        true
                      )}
                    </Option>
                  </OptGroup>
                )}
                {isAllDataEmpty() && (
                  <Option disabled>
                    <VStack>
                      <Text color={Colors.Custom.Gray400} fontSize={12} p={2}>
                        {isSearchTextEmpty()
                          ? !!props?.restrictPatientOrLeadSelectionTo ? 'Select “Myself” or enter staff’s name to start searching' :
                            'Select “Myself” or enter staff / member’s name to start searching'
                          : 'No Data Found'}
                      </Text>
                    </VStack>
                  </Option>
                )}
              </>
            ) : null}

            {defaultAssigneeInSelectedPool &&
              isNameMatchedToSearchText(
                defaultAssigneeInSelectedPool?.label || '',
                searchText
              ) &&
              !userLoading &&
              !patientLoading && (
              <OptGroup
                label={intl.formatMessage({
                  id: 'defaultAssigneeInSelectedTaskPool',
                })}
              >
                <Option
                  key={defaultAssigneeInSelectedPool?.key}
                  label={defaultAssigneeInSelectedPool?.label ?? ''}
                  value={defaultAssigneeInSelectedPool?.key}
                  type={ParticipantType.staff}
                >
                  <UserInfoItem
                    option={defaultAssigneeInSelectedPool}
                    contactData={defaultAssigneeInSelectedPool?.contactData}
                    showPendingTaskCount={props.showUserPendingTasksCount}
                    pendingTaskCount={pendingTasksCountByAssigneeId[defaultAssigneeInSelectedPool?.key]}
                    isPendingTaskCountLoading={getTasksGroupByAssigneeQuery?.loading}
                    isSidecarTaskLabelSelect={props?.isSidecarTaskLabelSelect}
                  />
                </Option>
              </OptGroup>
            )}

            {(!userLoading && !patientLoading) && (props?.isProspect
              ? getOptions().map((group) => (
                  <OptGroup key={group.key} label={group.label}>
                    {group.options.map((option, index) => (
                      <Option
                        key={`${option.key}_${index}`}
                        value={option.key}
                        label={option.label}
                        type={option.type}
                        email={option?.email}
                      >
                        {group.key === 'Prospects' ||
                        group.key === 'Patients' ? (
                          renderPatientOrProspectElement(option, true)
                        ) : (
                          <UserInfoItem
                            option={option}
                            contactData={option.contactData}
                            showPendingTaskCount={props.showUserPendingTasksCount}
                            pendingTaskCount={pendingTasksCountByAssigneeId[option.key]}
                            isPendingTaskCountLoading={getTasksGroupByAssigneeQuery?.loading}
                            isSidecarTaskLabelSelect={props?.isSidecarTaskLabelSelect}
                          />
                        )}
                      </Option>
                    ))}
                  </OptGroup>
                ))
              : patientData.map((patient, index) =>
                  renderPatientData(patient, index)
                ))}
          </Select>
          <View>
            {props?.errors?.patient && (
              <Text {...testID(TestIdentifiers.errorMessage)} color={'error.500'} fontWeight="500" fontSize={'xs'}>
                {props?.errors?.patient}
              </Text>
            )}
          </View>
        </FormControl>
      </View>
  );
};

export default withMiniContactViewHOC(PatientSearchAndSelect);

const styles = StyleSheet.create({
  height36: {
    height: 36,
  },
});

const reactStyles: Record<string, React.CSSProperties> = {
  marginRight3: {
    marginRight: 3,
  },
};
