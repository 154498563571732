import {Popover, Tooltip} from 'antd';
import {Content} from 'antd/lib/layout/layout';
import {
  Badge,
  Button,
  HStack,
  Pressable,
  Spinner,
  Text,
  useMediaQuery,
  View,
  VStack,
} from 'native-base';
import {useContext, useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {IPAD_MINI_WIDTH, IPAD_WIDTH} from '../../../../constants';
import {Colors} from '../../../../styles';
import {styles} from './CustomTabsViewStyles';
import {ICustomTabsView, ITabsList} from './interfaces';
import Fontisto from 'react-native-vector-icons/Fontisto';
import {validate as isValidUuid} from 'uuid';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import Chevron from '../../Svg/Chevron';
import { TASK_TABS } from '../CareDashboardConstants';
import { StyleSheet } from 'react-native';
import { isRenderTasksCompactView } from '../../../../utils/commonUtils';

const CustomTabsView = (props: ICustomTabsView) => {
  const {
    basePath,
    tabsList,
    onTabClick,
    canNavigate,
    tabListWrapperStyle,
    rightView,
    isDisabled,
    minScreenSupportWidth,
    isAddMaxTab,
    leftOffset,
    isCompactView,
    renderSubTabUI,
  } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const mlovData = useContext(CommonDataContext);
  const pathList = location?.pathname?.split('/') || [];
  const lastPathStr = pathList[pathList?.length - 1];
  const taskPattern = /\/tasks\/([0-9a-f-]+)\/comment\/([0-9a-f-]+)/;
  const taskIdMatch = location?.pathname?.match(taskPattern);
  const taskId = taskIdMatch?.length ? taskIdMatch?.[1] : '';
  const commentId = taskIdMatch?.length ? taskIdMatch?.[2] : '';
  const isSidecarContext = isCompactView || mlovData.sidecarContext?.isSidecar;
  const isRenderCompactView = isCompactView || isSidecarContext || isRenderTasksCompactView();
  const mentionedTab = useMemo(() => tabsList.find((item) => {
    return item.key === TASK_TABS.MENTIONED;
  }), [tabsList.length]);
  const selectedPathItem =
    tabsList.find((item) => {
      return item.path === lastPathStr;
    }) || ((isValidUuid(taskId) && isValidUuid(commentId)) && mentionedTab) || tabsList[0];
  const [selectedTab, setSelectedTab] = useState(selectedPathItem);
  const activeTab = (): ITabsList => {
    return canNavigate ? selectedPathItem : selectedTab;
  };

  const numberOfElements = props.numberOfElements || 1;

  const [tabListState, setTabListState] = useState<ITabsList[]>(tabsList);

  const navigateToTabPath = (tabItem: ITabsList) => {
    let finalPath = tabItem?.path || '';
    if (basePath) {
      finalPath = basePath + '/' + (tabItem?.path || '');
    }
    if (isValidUuid(taskId) && isValidUuid(commentId)) {
      navigate(finalPath, {
        state: {
          taskId: taskId,
          commentId: commentId,
        }
      });
    } else {
      navigate(finalPath);
    }
  };

  useEffect(() => {
    if (
      canNavigate &&
      selectedPathItem?.path &&
      basePath &&
      lastPathStr != selectedPathItem.path
    ) {
      navigateToTabPath(selectedPathItem);
    }
  });

  useEffect(() => {
    if (canNavigate && selectedPathItem?.path && basePath) {
      onTabClick && onTabClick(selectedPathItem);
    }
  }, []);

  useEffect(() => {
    if(props.selectedTabKey) {
      const selectedTab = tabsList.find(item => item.key === props.selectedTabKey);
      if(selectedTab) {
        setSelectedTab(selectedTab);
      }
    }
  }, [props.selectedTabKey]);

  const onTabClickWrapper = (tabItem: ITabsList, index?: number, lastIndexInVisibleList?: number) => {
    if (props.onTabClick) {
      tabsList.some((tab, i) => {
        const isMatched = canNavigate
          ? tab.path == tabItem.path
          : tab.key == tabItem.key;
        if (isMatched) {
          props.onTabClick?.(tabItem, index, lastIndexInVisibleList);
          return true;
        }
      });
    }
  };

  useEffect(() => {
    const tempList = tabsList.filter((item) =>
      canNavigate
        ? item.path == selectedPathItem.path
        : item.key == selectedTab.key
    );
    tabsList.some((item) => {
      if (!canNavigate) {
        if (item.key != selectedTab.key && tabsList.length < numberOfElements) {
          tempList.push(item);
          return false;
        }
        return item.key !== selectedTab.key;
      } else {
        if (
          item.path != selectedPathItem.path &&
          tabsList.length < numberOfElements
        ) {
          tempList.push(item);
          return false;
        }
        return item.path !== selectedPathItem.path;
      }
    });
    setTabListState(tempList);
  }, [selectedTab.key, selectedPathItem.path]);

  useEffect(() => {
    if (
      selectedPathItem.key === selectedTab.key &&
      selectedPathItem.title !== selectedTab.title
    ) {
      setSelectedTab(selectedPathItem);
    }
  }, [selectedPathItem.title]);

  const [isOpen, setIsOpen] = useState(false);

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);
  let isLessThanMinScreenSupportWidth = false;
  if(minScreenSupportWidth != undefined){
    [isLessThanMinScreenSupportWidth] = useMediaQuery([
      {maxWidth: minScreenSupportWidth},
    ]);
  }
  const isSmallScreen = isIPadMiniScreen || isIPadScreen || isLessThanMinScreenSupportWidth

  let maxTab = 1;
  const innerWidth = props?.innerWidth || window?.innerWidth;
  if (isAddMaxTab && window && window?.innerWidth) {
    maxTab = Math.floor((innerWidth-(leftOffset || 300)  ) / 100);
    if (maxTab <= 0) {
      maxTab = 1;
    }
  }
  
  const isShowMoreOption = isSmallScreen && (tabsList?.length > numberOfElements || tabsList?.length > maxTab)
  const moreOptionsElements = () => {

    const tempList = isSidecarContext ? tabsList.slice(maxTab) : tabsList.filter((item) => {
      return !tabListState.some((tab) => {
        return canNavigate ? item.path == tab.path : tab.key == item.key;
      });
    })
    return (
      <VStack >
        {tempList.map((tabItem: ITabsList, index: number) => {
          const uniqueKey = `MoreOption_${tabItem.path || ''}_${tabItem.key || ''}_${index}_${tabItem.title}`;
          return (
            <HStack key={uniqueKey}>
              <Pressable
                // style={[tabListWrapperStyle ? tabListWrapperStyle : {},]}
                key={`Pressable_${uniqueKey}`}
                isDisabled={tabItem.isDisabled || isDisabled}
                onPress={() => {
                  setIsOpen(!isOpen);
                  if (canNavigate && tabItem?.path) {
                    navigateToTabPath(tabItem);
                  } else {
                    setSelectedTab({...selectedTab, ...tabItem});
                  }
                  const currentTabsList = getTabList();
                  const lastIndexInVisibleList = currentTabsList.length - 1;
                  onTabClickWrapper(tabItem, index, lastIndexInVisibleList);
                }}
              >
                <VStack
                  style={[styles.tabStyles,{paddingBottom:4}]}
                  color={
                    activeTab().title === tabItem.title
                      ? '#fff'
                      : Colors.Custom.Gray500
                  }
                  backgroundColor={
                    activeTab().title === tabItem.title
                      ? Colors.primary[300]
                      : '#fff'
                  }
                >
                  <HStack alignItems={'center'} space={2}>
                    <Text
                      color={
                        activeTab().title === tabItem.title
                          ? isRenderCompactView ? Colors.FoldPixel.PRIMARY300 : '#825AC7'
                          : isRenderCompactView ? Colors.FoldPixel.GRAY300 : Colors.Custom.Gray500
                      }
                    >
                      {tabItem.title}
                    </Text>
                    {props?.isLoading ? <Spinner size={'sm'}/>:
                    <>
                      {tabItem?.count && tabItem?.count > 0 ? (
                        <Tooltip title={tabItem?.countTooltip || ""}>
                          <Badge
                                fontWeight={500}
                                backgroundColor={Colors.FoldPixel.GRAY50}
                                _text={{
                              color: Colors.FoldPixel.GRAY300,
                              fontSize: isRenderCompactView ? 10 : 12,
                            }}
                            alignSelf="center"
                            rounded={isRenderCompactView ? '' : "full"}
                            borderRadius={isRenderCompactView ? 4 : null}
                            justifyContent={'center'}
                            paddingX={2}
                            paddingY={1}
                            style={ isRenderCompactView ? {
                              borderColor: Colors.FoldPixel.GRAY200,
                              borderWidth: .5,
                            } : {}}
                          >
                            {tabItem.count}
                          </Badge>
                        </Tooltip>
                      
                    ) : null}
                    </>}
                  </HStack>
                </VStack>
              </Pressable>
            </HStack>
          );
        })}
      </VStack>
    );
  };

  const getTabList = () => {
    if (isShowMoreOption) {
      return isSidecarContext ? tabsList.slice(0, maxTab) : tabListState;
    }
    return tabsList;
  };

  const handleVisibleChange = (visible: any) => {
    setIsOpen(visible);
  };

  const getActiveTabBackgroundColor = (tabItem: ITabsList) => {
    if(renderSubTabUI) {
      return activeTab().title === tabItem.title ? Colors.Custom.White : undefined;
    }
    return undefined;
  }

  
  const getActiveTabBorderBottomColor = (tabItem: ITabsList) => {
    if (renderSubTabUI) {
      return undefined;
    }
    return activeTab().title === tabItem.title
      ? tabItem.backgroundColor
        ? tabItem.backgroundColor
        : isRenderCompactView
        ? Colors.FoldPixel.PRIMARY300
        : Colors.primary[300]
      : '#fff';
  };

  const getActiveTabBorderColor = (tabItem: ITabsList) => {
    if(renderSubTabUI) {
      return activeTab().title === tabItem.title ? Colors.Custom.shadeGray : undefined;
    }
    return undefined;
  }

  const getActiveTabBorderWidth = (tabItem: ITabsList) => {
    if(renderSubTabUI) {
      return activeTab().title === tabItem.title ? 0.5 : undefined;
    }
    return undefined;
  }

  const getActiveTabBottomBorderWidth = (tabItem: ITabsList) => {
    if(renderSubTabUI) {
      return activeTab().title === tabItem.title ? 0.5 : 0;
    }
    return activeTab().title === tabItem.title ? 2 : 0;
  }

  const getActiveTextColor = (tabItem: ITabsList) => {
    if(renderSubTabUI) {
      return activeTab().title === tabItem.title ? Colors.Custom.Gray700 : Colors.FoldPixel.GRAY250;
    }
    return activeTab().title === tabItem.title
    ? isRenderCompactView ? Colors.FoldPixel.PRIMARY300 : '#825AC7'
    : isRenderCompactView ? Colors.FoldPixel.GRAY300 : Colors.Custom.Gray500
  }

  return (
    <View style={customStyles.container}>
      <HStack
        alignItems={'center'}
        style={[
          customStyles.tabListWrapper,
          tabListWrapperStyle ? tabListWrapperStyle : {},
        ]}
      >
        {getTabList()?.map((tabItem: ITabsList, index: number) => {
          const uniqueKey = `CustomTab_${tabItem.path || ''}_${tabItem.key || ''}_${index}_${tabItem.title}`;
          return (
            <HStack style={customStyles.tabItemContainer} key={uniqueKey}>
              <Pressable
                key={`Pressable_${uniqueKey}`}
                isDisabled={tabItem.isDisabled || isDisabled}
                onPress={() => {
                  if (canNavigate && tabItem?.path) {
                    navigateToTabPath(tabItem);
                  } else {
                    setSelectedTab({...selectedTab, ...tabItem});
                  }
                  onTabClickWrapper(tabItem, index);
                }}
              >
                <VStack
                  key={`VStack_${uniqueKey}`}
                  style={styles.tabStyles}
                  color={
                    activeTab().title === tabItem.title
                    ? tabItem.backgroundColor
                      ? tabItem.backgroundColor
                      : Colors.primary[300]
                    : Colors.Custom.Gray500
                  }
                  fontWeight={ activeTab().title === tabItem.title ? 700 : 600}
                  borderBottomWidth={
                    getActiveTabBottomBorderWidth(tabItem)
                  }
                  backgroundColor={getActiveTabBackgroundColor(tabItem)}
                  borderBottomColor={
                    getActiveTabBorderBottomColor(tabItem)
                  }
                  borderWidth={getActiveTabBorderWidth(tabItem)}
                  borderColor={getActiveTabBorderColor(tabItem)}
                  borderRadius={renderSubTabUI ? 4 : undefined}
                  paddingX={renderSubTabUI ? 2 : undefined}
                  paddingY={renderSubTabUI ? 1.5 : undefined}
                  marginRight={renderSubTabUI ? 1 : undefined}
                >
                  <HStack alignItems={'center'} space={2}>
                    <Text
                      size={'smRegular'}
                      color={
                        getActiveTextColor(tabItem)
                      }
                    >
                      {tabItem.title}
                    </Text>
                    {props?.isLoading ? <Spinner key={`Spinner_${uniqueKey}`} size={'sm'}/>:
                    <>
                      {tabItem?.count && tabItem?.count > 0 ? (
                      <Tooltip title={tabItem?.countTooltip || ""}>
                        <Badge
                          key={`Badge_${uniqueKey}`}
                          fontWeight={500}
                          backgroundColor={Colors.FoldPixel.GRAY50}
                          _text={{
                            color: Colors.FoldPixel.GRAY300,
                            fontSize: isRenderCompactView ? 10 : 12,
                          }}
                          alignSelf="center"
                          rounded={isRenderCompactView ? '' : "full"}
                          borderRadius={isRenderCompactView ? 4 : null}
                          justifyContent={'center'}
                          paddingX={2}
                          paddingY={1}
                          style={ isRenderCompactView ? customStyles.sidecarBadge : {}}
                        >
                          {tabItem.count}
                        </Badge>
                      </Tooltip>
                    ) : null}
                    </>}
                    
                  </HStack>
                </VStack>
              </Pressable>
            </HStack>
          );
        })}
        {isShowMoreOption && (
          <Popover
            content={moreOptionsElements}
            trigger="click"
            overlayInnerStyle={{padding: 0, borderRadius: 16}}
            overlayStyle={{padding: 0}}
            overlay={{padding: 0}}
            style={{padding: 0, borderRadius: 16}}
            placement={'bottom'}
            visible={isOpen}
            onVisibleChange={handleVisibleChange}
          >
          <Button
            style={[
              customStyles.moreButton,
              (isRenderCompactView || isSidecarContext) ? customStyles.sidecarMoreButton : customStyles.defaultMoreButton,
            ]}
            onPress={() => {
              setIsOpen(!isOpen);
            }}
          >
            {(isRenderCompactView || isSidecarContext) ? <Chevron /> : <Fontisto
              name="chevron-down"
              size={18}
              color={Colors.secondary['800']}
            />}
          </Button>
          </Popover>
        )}

        {rightView && <Content>{rightView()}</Content>}
      </HStack>
    </View>
  );
};

const customStyles = StyleSheet.create({
  container: {
    alignSelf: 'flex-end',
  },
  tabListWrapper: {
    width: '100%',
    paddingLeft: 16,
  },
  tabItemContainer: {
    alignItems: 'center',
  },
  sidecarBadge: {
    borderColor: Colors.FoldPixel.GRAY200,
    borderWidth: 0.5,
  },
  popoverOverlayInner: {
    padding: 0,
    borderRadius: 16,
  },
  popoverOverlay: {
    padding: 0,
  },
  popover: {
    padding: 0,
    borderRadius: 16,
  },
  moreButton: {
    height: 16,
    width: 16,
  },
  sidecarMoreButton: {
    transform: [{ rotate: '90deg' }],
    backgroundColor: 'transparent',
  },
  defaultMoreButton: {
    borderRadius: 18,
    borderWidth: 1,
    borderColor: Colors.secondary['200'],
    backgroundColor: Colors.secondary['100'],
  },
});

export default CustomTabsView;
