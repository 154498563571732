import {Stack, Text, View, Input} from 'native-base';
import {FormError} from '../CustomWrapper/CustomComponentHelper';
import FormComponentError from '../CustomWrapper/FormComponentError/FormComponentError';
import FormComponentLoader from '../CustomWrapper/FormComponentLoader/FormComponentLoader';
import {
  IFormValidationOutput,
  IHealthComponent,
} from '../CustomWrapper/interfaces';
import {
  IFormCommonData,
  IFormComponentProps,
} from '../CustomWrapper/CustomWrapper';
import {useContext, useEffect, useRef, useState} from 'react';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import {Colors} from '../../../../../../styles';
import {Radio} from 'antd';
import {cloneDeep} from 'lodash';
import {styles} from './AwvHistoryStyles';
import {ModalActionDatePicker} from '../../../../../common/ModalActionCommonComponent/ModalActionDatePicker';
import {DATE_FORMATS, DISPLAY_DATE_FORMAT} from '../../../../../../constants';
import {
  getFormattedDate,
  getMomentObj,
  getStartOfDay,
} from '../../../../../../utils/DateUtils';

interface IAwvHistoryComponentState extends IHealthComponent {}

export interface IAwvComponentValue {
  lastAwvVisitDate?: string;
  pastAwvVisitsCount?: string;
}

const AwvHistoryComponent = (props: IFormComponentProps) => {
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const componentRef = useRef();

  const [componentValue, setComponentValue] = useState<IAwvComponentValue>(
    props.component?.selectedValue || {}
  );

  const PAST_AWV_HISTORY_COUNTS = [
    {
      value: 'none',
      label: 'None',
    },
    {
      value: '1',
      label: '1',
    },
    {
      value: '2',
      label: '2',
    },
    {
      value: 'moreThan2',
      label: 'More than 2',
    },
    {
      value: 'unknown',
      label: "I'm not sure",
    },
  ];

  const isPreviewMode = contextData.isPreviewMode || false;

  const [componentState, setComponentState] =
    useState<IAwvHistoryComponentState>({
      loading: false,
      formError: contextData.formError,
      collapsed: contextData.defaultCollapse,
      showSavingIndicator: false,
      editModalVisible: false,
      editModalCollapse: false,
      showPatientReportedRecords: false,
      selectedPatientReportedRecord: undefined,
      selectedItemToMap: undefined,
      activePanels: [],
    });

  const handleLastAwvChange = (value: string) => {
    if (value) {
      const convertedValue = getStartOfDay(value).toISOString();
      setComponentValue((prev) => ({
        ...prev,
        lastAwvVisitDate: getStartOfDay(value).toISOString(),
      }));
    }
  };

  const isValidData = () => {
    return true;
  };

  const validateData = (
    currentData: IAwvComponentValue
  ): IFormValidationOutput => {
    setComponentState((prev) => ({
      ...prev,
      showErrors: true,
    }));

    return {
      isValid: isValidData(),
      message: `${props.component.label}: Please enter Annual Wellness Visit History details`,
    };
  };

  props.validateRef.current = validateData;

  useEffect(() => {
    props.onChange(cloneDeep(componentValue));
  }, [componentValue]);

  const getUtcToLocalFormattedDate = () => {
    if(componentValue.lastAwvVisitDate) {
      const formattedDate = getFormattedDate(
        componentValue.lastAwvVisitDate,
        DATE_FORMATS.DISPLAY_DATE_FORMAT
      );    
      return getMomentObj(formattedDate);
    }
  }
  
  const getDetailView = (): JSX.Element => {
    return (
      <Stack>
        <View>
          <Text style={styles.awvTitle}>{'Annual Wellness Visit History'}</Text>
        </View>
        <Stack direction="column" space={3} style={styles.questionsContainer}>
          <Text>When was your last Annual Wellness Visit (AWV)?</Text>
          <ModalActionDatePicker
            className="date-picker-background-color"
            changeBackground={true}
            label={''}
            format={DISPLAY_DATE_FORMAT}
            placeholder={DISPLAY_DATE_FORMAT}
            onChange={(value: any, dateString: string) => {
              handleLastAwvChange(dateString);
            }}
            value={
              componentValue.lastAwvVisitDate
                ? getUtcToLocalFormattedDate()
                : null
            }
            customStyle={{
              height: 36,
              borderRadius: 4,
              borderColor: Colors.Custom.BorderColor,
              color: Colors.Custom.ErrorColor,
              marginTop: 0.1,
            }}
            extraStyle={{
              flex: 1.0,
            }}
          />

          <Text marginTop={1}>
            How many Annual Wellness Visits (AWVs) have you completed?
          </Text>

          <Radio.Group
            value={componentValue.pastAwvVisitsCount}
            onChange={(e) => {
              setComponentValue((prev) => ({
                ...prev,
                pastAwvVisitsCount: e.target.value,
              }));
            }}
          >
            <Stack direction="column" space={2}>
              {PAST_AWV_HISTORY_COUNTS.map((pastVisitHistory) => {
                return (
                  <Radio value={pastVisitHistory.value}>
                    {pastVisitHistory.label}
                  </Radio>
                );
              })}
            </Stack>
          </Radio.Group>
        </Stack>
      </Stack>
    );
  };

  const getPastVisitHistoryCountLabel = (pastAwvVisitsCount: string) => {
    return PAST_AWV_HISTORY_COUNTS?.find(
      (pastVisitHistory) => pastVisitHistory.value === pastAwvVisitsCount
    )?.label;
  };

  if (isPreviewMode) {
    const awvVisitDate = componentValue.lastAwvVisitDate
      ? getFormattedDate(
          componentValue.lastAwvVisitDate,
          DATE_FORMATS.DISPLAY_DATE_FORMAT
        )
      : '-';
    const awvVistPastHistoryCount = componentValue.pastAwvVisitsCount
      ? getPastVisitHistoryCountLabel(componentValue.pastAwvVisitsCount)
      : '-';
    return (
      <Stack direction="column">
        <Text>When was your last Annual Wellness Visit (AWV)?</Text>
        <Text style={styles.awvHistoryAnswerText}>{awvVisitDate}</Text>
        <Text marginTop={2}>
          How many Annual Wellness Visits (AWVs) have you completed?
        </Text>
        <Text style={styles.awvHistoryAnswerText}>
          {awvVistPastHistoryCount}
        </Text>
      </Stack>
    );
  }

  return (
    <View ref={componentRef}>
      {componentState.loading && <FormComponentLoader />}
      {!componentState.loading && componentState.customError && (
        <FormComponentError
          error={FormError.custom}
          customError={componentState.customError}
        />
      )}
      {!componentState.loading && componentState.formError && (
        <FormComponentError error={componentState.formError} />
      )}
      {!componentState.loading && componentState.nonBlockingFormError && (
        <FormComponentError error={componentState.nonBlockingFormError} />
      )}
      {!componentState.loading && !componentState.formError && getDetailView()}
    </View>
  );
};

export default AwvHistoryComponent;
