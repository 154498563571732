import React from 'react';
import {IFormComponentProps} from '../../CustomWrapper/CustomWrapper';
import CareProgramAppointmentView from './CareProgramAppointmentView';

const CareProgramAppointmentWrapper: React.FC<IFormComponentProps> = (props) => {
  const contactData = {
    uuid: props?.options?.contactUuid,
    name: props?.options?.contactName,
  }
  return (
    <>
      <CareProgramAppointmentView
        onChange={props?.onChange}
        validateRef={props?.validateRef}
        component={props?.component}
        contactData={contactData}
        handleUpdateCareProgram={props?.options?.handleUpdateCareProgram}
      />
    </>
  );
};

export default CareProgramAppointmentWrapper;
