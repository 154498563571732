import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {useContactCareProgramContext} from '../../../ContactCareProgram.context';
import FormsQueries, {
  GET_FORM_BY_ID,
  GET_FORM_LOG,
  GET_FORM_RESPONSE_BY_FORM_LOG_ID,
} from '../../../../../../../services/Forms/FormsQueries';
import {useLazyQuery, useMutation} from '@apollo/client';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../../../constants/Configs';
import {usePersonOmniViewContext} from '../../../../../../PersonOmniView/PersonOmniView.context';
import {
  getFormComponentsDataForProgress,
  getPercentageScore,
} from '../../../../../../PublicPages/PublicForm/PublicFormHelper';
import {IExtensiveComponentData} from '../../../../../../PublicPages/PublicForm/interface';
import {cloneDeep, debounce} from 'lodash';
import {forEachExtensiveFormComponent} from '../../../../../Forms/FormBuilderWidget/AddOrUpdateForm/AddOrUpdateFormHelper';
import {IFormPrefilledByFoldProgressState} from '../../../../../../PublicPages/PublicForm/PublicFormInterfaces';
import {AttributeKey, IAddOrUpdateCareProgramAttributesPayload, ICareProgramActionsPayload, ICareProgramData, IContactCareProgram, IContactCareProgramStep, IFormLogResponse, IFormScore} from '../../../../interface';
import {
  checkIfPrevFormAcceptedOrDeclined,
  fetchFormResponseFromFormOrFormLog,
  getAdditionalAttributeFromStep,
  getFormDetails,
} from '../../../ContactCareProgramView.utils';
import {FormComponentType} from '../../../../../Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import useFormOptions from '../../../hooks/useFormOptions';
import {useCareProgramStatus} from '../../../hooks/useCareProgramStatus';
import {ContactCareProgramReducerAction} from '../../../reducer';
import {useMainContentContext} from '../../MainContentView.context';
import useCareProgramFormSubmitRestAPI from '../../../../../../CustomHooks/useCareProgramFormSubmitRestAPI';
import {CareProgramStatusAction} from '../../../hooks/useCareProgramStatusAction';
import {useCustomToast} from '../../../../../../Toast/ToastProvider';
import {ToastType} from '../../../../../../../utils/commonViewUtils';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../../../../../utils/mlovUtils';
import {CommonDataContext} from '../../../../../../../context/CommonDataContext';
import {DATE_FORMATS, DISPLAY_DATE_FORMAT, DISPLAY_SLASH_DATE_FORMAT, MLOV_CATEGORY} from '../../../../../../../constants';
import {FORM_STATUS_CODE} from '../../../../../../../constants/MlovConst';
import useCareProgramStepStatus from '../../../hooks/useCareProgramStepStatus';
import {ADD_OR_UPDATE_STEP_ADDITIONAL_ATTRIBUTES} from '../../../../../../../services/CareProgram/CareProgramQueries';
import {getDateStrFromFormat} from '../../../../../../../utils/DateUtils';
import {IAdditionalAttribute} from '../../../../../../../services/ContactCareProgram/interface';
import {StepAction, useCareProgramStepActions} from '../../../hooks/useCareProgramStepActions';
import {useSelectedStepInCareProgram} from './useSelectedStepInCareProgram';
import {FormContext} from '../../../../../Forms/FHFormio/CustomComponents/CustomWrapper/CustomWrapper'
import { useCareProgramAdditionalAttribute } from './useCareProgramAdditionalAttribute';
import { EventBus } from '../../../../../../../utils/EventBus';
import { CARE_PROGRAM_EVENTS } from '../../../../useCPEventhandler';
export enum AssessmentAction {
    FORM_RESPONSE_ACCEPTED = StepAction.FORM_RESPONSE_ACCEPTED,
    FORM_RESPONSE_DECLINED = StepAction.FORM_RESPONSE_DECLINED,
  }

export const useAssessment = () => {
  const {dispatch, state, onUpdateCareProgramStatus, refetchMasterData} =
    useContactCareProgramContext();
  const {formattedData} = usePersonOmniViewContext();
  const {careProgramStatus} = useCareProgramStatus();
  const {careProgramStepStatus} = useCareProgramStepStatus();
  const mlovData = useContext(CommonDataContext);
  const {updateMainContentContextState} = useMainContentContext();
  const toast = useCustomToast();
  const {selectedStep} = useSelectedStepInCareProgram();
  const isFirstRender = useRef(true);
  const {handleUpdateCareProgram} = useCareProgramAdditionalAttribute();

  const [progress, setProgress] = useState(0);
  const componentStateRef = useRef<IExtensiveComponentData>({
    components: [],
    total: 0,
  });
  const careProgramData: ICareProgramData = {
    careProgramId: state.contactCareProgramDetails?.id || '',
    careProgramTypeId: state.contactCareProgramDetails?.payerCareProgram?.careProgramTypeId || '',
    selectedStep: selectedStep as IContactCareProgramStep,
  }
  const {intakeOptions} = useFormOptions({
    accountLocationUuid: formattedData?.accountLocationUuid,
    contactId: formattedData?.contactUUID,
    contactUuid: formattedData?.contactUUID,
    contactCareProgramId: state.contactCareProgramDetails?.id,
    contactName: formattedData?.name,
    careProgramData,
    formContext: FormContext.patientCarePlan,
    handleUpdateCareProgram
  });
  const [draftChanges, setDraftChanges] = useState<any>();
  const stepId = state.selectedStepId;
  const [formResponseId, setFormResponseId] = useState<string | undefined>();
  const [assessmentState, setAssessmentState] = useState<{
    status: 'loading' | 'loaded' | 'error' | 'empty' | undefined;
    formComponents: any[];
    formResponse: any;
    formId: string;
    formLogId: string;
    subjectId: string;
    sourceId: string;
    isPreviousFormInstanceExists: boolean;
    isFormSubmitted: boolean;
    prevFormInstanceData: IFormLogResponse | undefined;
    metadataScore?: IFormScore[] | undefined;
  }>({
    status: undefined,
    formComponents: [],
    formResponse: null,
    formId: '',
    formLogId: '',
    subjectId: '',
    sourceId: '',
    isPreviousFormInstanceExists: false,
    isFormSubmitted: false,
    prevFormInstanceData: undefined,
  });
  const [
    formPrefilledByFoldProgressState,
    setFormPrefilledByFoldProgressState,
  ] = useState<IFormPrefilledByFoldProgressState>({
    componentWiseProgress: {},
    disallowProgressUpdates: true,
    formPrefilledByFoldPercent: 0,
  });

  const careProgramStatusId = state.contactCareProgramDetails?.statusId as string;

  const {handleContentAction} = useCareProgramStepActions({
    selectedStepCode: selectedStep?.careProgramStepType?.code as string,
    additionalAttributes: selectedStep?.careProgramStepAdditionalAttributes || [],
    extraParams: {
      contactId: formattedData?.contactUUID as string,
      patientId: formattedData?.patientUuid as string,
    },
  });

  const selectedStepLog = state.contactCareProgramDetails?.stepsLog.find(
    (stepLog) => stepLog.careProgramStepId === stepId
  );

  const additionalAttributes = selectedStep?.careProgramStepAdditionalAttributes;
  const isPreviousFormInstanceDisabled = additionalAttributes
      ? getAdditionalAttributeFromStep(additionalAttributes, AttributeKey.PREVIOUS_FORM_INSTANCE_DISABLED)
      : false;

  const isStepCompleted =
    selectedStepLog?.careProgramStepStatusId === careProgramStepStatus?.done;

  const isSkippedStep = selectedStepLog?.careProgramStepStatusId === careProgramStepStatus?.skip;

  const formStatusMlovList = useMemo(
    () =>
      getMlovListFromCategory(
        mlovData.CARE_STUDIO_MLOV,
        MLOV_CATEGORY.FORM_STATUS
      ),
    [mlovData?.CARE_STUDIO_MLOV]
  );
  const submittedStatusId = useMemo(
    () => getMlovIdFromCode(formStatusMlovList, FORM_STATUS_CODE.SUBMITTED),
    [formStatusMlovList?.length]
  );

  const isCareProgramInProgress = [careProgramStatus.inProgress, careProgramStatus.newStatus].includes(careProgramStatusId);
  const isNewCareProgram = careProgramStatusId === careProgramStatus.newStatus;

  const isFormReadOnly =
    !isCareProgramInProgress ||
    assessmentState.isPreviousFormInstanceExists ||
    assessmentState.isFormSubmitted ||
    isStepCompleted ||
    isSkippedStep ||
    assessmentState.status === 'loading';

  const [getForm] = useLazyQuery(GET_FORM_RESPONSE_BY_FORM_LOG_ID, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  const [getPrevFormSubmittedByContact] = useLazyQuery(GET_FORM_LOG, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  const [getFormById] = useLazyQuery(GET_FORM_BY_ID, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  const [addOrUpdateCareProgramAttributes] = useMutation(ADD_OR_UPDATE_STEP_ADDITIONAL_ATTRIBUTES, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  const {
    submitForm,
    isLoading: isFormSubmitLoading,
    isSuccess,
    isError,
  } = useCareProgramFormSubmitRestAPI();

  const debouncedSave = useCallback(
    debounce(async (newData) => {
      setDraftChanges({...newData});
    }, 500),
    [setDraftChanges]
  );

  const onChange = useCallback((formUpdates: any) => {
    debouncedSave(formUpdates.data);
  }, []);

  const onAnyActionTaken = () => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent(CARE_PROGRAM_EVENTS.CARE_PROGRAM_ACTION_TAKEN, {
      syncCode: `care-program-action-taken`,
    });
  };

  const submitFormResponse = async (data: any) => {
    updateMainContentContextState({headerSavingIndicator: true});
    onAnyActionTaken();
    const responseId = await submitForm(data);
    if (responseId && !formResponseId) {
      setFormResponseId(responseId);
    }
    updateMainContentContextState({headerSavingIndicator: false});
    // Update Care Program Status to In Progress if it is not already in progress when form is submitted
    if (isNewCareProgram) {
      onUpdateCareProgramStatus(CareProgramStatusAction.IN_PROGRESS);
    }
  };

  const checkIfFetchPreviousFormLog = useCallback(() => {
    if (isStepCompleted || isSkippedStep) {
      return false;
    }

    const isPrevFormAcceptedOrDeclined = additionalAttributes
      ? checkIfPrevFormAcceptedOrDeclined(additionalAttributes)
      : false;

    return !isPrevFormAcceptedOrDeclined;

  }, [
    additionalAttributes,
    isStepCompleted,
    isSkippedStep
  ])

  //   Fetch Form
  const fetchForm = useCallback(async () => {
    try {
      setAssessmentState((prev) => ({...prev, status: 'loading'}));

        // Form Log
      const {formLogId, formId} = getFormDetails(
        state.contactCareProgramDetails as IContactCareProgram,
        stepId as string
      );



      const {newInstanceForm, previouslyFilledFormInstance} =
        await fetchFormResponseFromFormOrFormLog({
          formId,
          formLogId,
          formLogPromise: (formLogId) => getForm({variables: {formLogId}}),
          formByIdPromise: (formId) => getFormById({variables: {id: formId}}),
          prevSubmittedFormLogPromise: (formId: string, formLogId: string) =>
            getPrevFormSubmittedByContact({
              variables: {
                where: {
                  contactId: {_eq: formattedData?.contactUUID},
                  formId: {_eq: formId},
                  id: {
                    _nin: [formLogId],
                  },
                  statusId: {_eq: submittedStatusId},
                },
              },
            }),
            fetchPreviousFormLog: checkIfFetchPreviousFormLog(),
        });

      const isFormSubmitted =
        submittedStatusId === newInstanceForm?.formLog?.statusId;

      const previousFormLogIsExisting = Boolean(
        previouslyFilledFormInstance?.formLog?.formResponse
      );
      const finalFormLog = previouslyFilledFormInstance || newInstanceForm;

      const {submittedData, formLog, formComponents} = finalFormLog;

      setAssessmentState((prev) => ({
        ...prev,
        ...(submittedData && Object.keys(submittedData)?.length
          ? {formResponse: {data: submittedData}}
          : {}),
        formComponents:
          Array.isArray(formComponents)
            ? formComponents.filter((component: any) => component.type !== FormComponentType.BUTTON)
            : [],
        subjectId: formLog?.subjectId,
        sourceId: formLog?.sourceId,
        formId: formId as string,
        formLogId: formLogId as string,
        isPreviousFormInstanceExists: !isPreviousFormInstanceDisabled && (previousFormLogIsExisting || false),
        isFormSubmitted: isFormSubmitted || false,
        prevFormInstanceData: previouslyFilledFormInstance,
        status: 'loaded',
        metadataScore: finalFormLog?.metadataScore
      }));

      dispatch?.({
        type: ContactCareProgramReducerAction.SET_FORM_SCORE,
        payload: {id: stepId, scores: finalFormLog?.metadataScore},
      });


      const data = getFormComponentsDataForProgress(
        formComponents || [],
        intakeOptions
      );
      componentStateRef.current = data;
    } catch (error) {
      console.log('error', error);
      setAssessmentState((prev) => ({...prev, status: 'error'}));
    } finally {
      setAssessmentState((prev) => ({...prev, status: 'loaded'}));
    }
  }, [
    isStepCompleted,
    isSkippedStep,
    stepId,
    formattedData?.contactUUID,
    state.contactCareProgramDetails,
    checkIfFetchPreviousFormLog
  ]);

  // FORM
  const updateProgress = (formData: any) => {

    const isEmptyFormResponseOrComponentData =
      !componentStateRef.current?.components?.length ||
      !Object.keys(formData?.data || {})?.length;

    const percent = isEmptyFormResponseOrComponentData
      ? 0
      : getPercentageScore(componentStateRef.current, formData?.data);

    setFormPrefilledByFoldProgressState((prev) => ({
      ...prev,
      formPrefilledByFoldPercent: percent,
    }));
    if (percent > 0) {
      setProgress(percent);
      dispatch?.({
        type: ContactCareProgramReducerAction.SET_STEP_PROGRESS_MAP,
        payload: {id: stepId, progress: percent},
      });
    }
  };

  const addOrUpdateCareProgramAttributesMutation = useCallback(async (action: AssessmentAction):Promise<boolean> => {
    try {
      if (
        !state.contactCareProgramDetails?.id ||
        !stepId
      ) {
        return false;
      }
      const params: IAddOrUpdateCareProgramAttributesPayload = {
        careProgramAttributes: [
          {
            contactCareProgramId: state.contactCareProgramDetails?.id,
            subjectId: stepId,
            subjectTypeCode: "CARE_PROGRAM_STEP",
            additionalAttributes: [
              {
                attributeKey: AttributeKey.FORM_LOG_STATUS,
                attributeValueDataTypeCode: 'json',
                attributeValue: {
                  valueJson: {
                    action: action,
                    formLogId:
                      action === AssessmentAction.FORM_RESPONSE_ACCEPTED
                        ? assessmentState.prevFormInstanceData?.formLog?.id
                        : undefined,
                  },
                },
                isDeleted: false,
              },
            ],
          },
        ],
      };

      const response = await addOrUpdateCareProgramAttributes({
        variables: {params},
      });
      const newAdditionalAttributes = response?.data
        ?.addOrUpdateCareProgramAttributes
        ?.additionalAttributes as IAdditionalAttribute[];

      dispatch?.({
        type: ContactCareProgramReducerAction.UPDATE_CARE_PROGRAM_STEP_ADDITIONAL_ATTRIBUTES,
        payload: {additionalAttribute: newAdditionalAttributes[0], subjectTypeCode: "CARE_PROGRAM_STEP"},
      });

      return Boolean(newAdditionalAttributes?.length);
    } catch (error) {
      toast({
        message: 'Something went wrong',
        toastType: ToastType.error,
      });
      return false;
    }
  }, [
    state.contactCareProgramDetails?.id,
    stepId,
    assessmentState.sourceId,
    assessmentState.prevFormInstanceData?.formLog?.id
  ]);

  const handleAcceptPreviousFormInstanceAction = async (
    action: AssessmentAction
  ) => {
    // This is simulation of loading state
    setAssessmentState((prev) => ({...prev, status: 'loading'}));
    const [mutationResult, auditLogResponse] = await Promise.allSettled([
      addOrUpdateCareProgramAttributesMutation(action),
      handleContentAction(action as unknown as StepAction, {
        careProgramDetails: state.contactCareProgramDetails,
        stepId: stepId as string,
      })
    ]);

    if (mutationResult.status !== 'fulfilled') {
      setAssessmentState((prev) => ({...prev, status: 'loaded'}));
      return;
    }
    setAssessmentState((prev) => {
      const newState: typeof assessmentState = {...prev};
      if (action === AssessmentAction.FORM_RESPONSE_DECLINED) {
        newState.formResponse = null;
      }
      return {
        ...newState,
        status: 'loaded',
        prevFormInstanceData: undefined,
        isPreviousFormInstanceExists: false,
      };
    });
    toast({
      message:
        action === AssessmentAction.FORM_RESPONSE_ACCEPTED
          ? 'Form accepted successfully'
          : 'New form instance created',
      toastType: ToastType.success,
    });
  };

  const handleOnChange = useCallback(
    (data: any) => {
      if (onChange) {
        onChange(data);
        updateProgress(data);
      }
    },
    [isFormReadOnly]
  );

  useEffect(() => {
    fetchForm();
  }, []);

  useEffect(() => {
    if (draftChanges && assessmentState.formId) {
      const updatedComponents = cloneDeep(assessmentState.formComponents);
      forEachExtensiveFormComponent(updatedComponents, (component) => {
        if (
          component.key &&
          (draftChanges[component.key] || draftChanges[component.key] == 0)
        ) {
          component.selectedValue = draftChanges[component.key];
        }
      });
      const formData: any = {
        formId: assessmentState.formId,
        // eventId: props.params.eventId,
        formResponse: {components: updatedComponents},
        reference: {},
        // patientId: formattedData?.patientId,
        contactId: formattedData?.contactUUID,
        subjectId: assessmentState.subjectId,
        sourceId: assessmentState.sourceId,
        isDraft: true,
        formResponseId: formResponseId,
        foldPrefilledDataProgressByComponent: {
          ...(formPrefilledByFoldProgressState?.componentWiseProgress || {}),
        },
        foldPrefilledDataProgressPercentage:
          formPrefilledByFoldProgressState?.formPrefilledByFoldPercent || 0,
        formLogId: assessmentState.formLogId,
      };
      console.log('updateing main content state', formData);
      updateMainContentContextState({formResponseForAssessmentStep: formData});

      const payload: ICareProgramActionsPayload = {
        actionTypeCode: StepAction.AUTO_SAVE,
        careProgramParams: {
          contactCareProgramId: state.contactCareProgramDetails?.id || '',
          contactCareProgramStepId: stepId || '',
        },
        formResponseParams: formData,
      };
      if (isFirstRender.current) {
        isFirstRender.current = false;
        return;
      }

      if (!isFormReadOnly) {
        submitFormResponse(payload);
      }
    }
  }, [draftChanges]);

  useEffect(() => {
    return () => {
      // clear ref
      componentStateRef.current = {
        components: [],
        total: 0,
      };
    };
  }, []);

  const prevFormInstanceUpdatedOn = useMemo(
    () =>
      assessmentState.prevFormInstanceData?.formLog?.updatedOn
        ? getDateStrFromFormat(
            assessmentState.prevFormInstanceData?.formLog?.updatedOn,
            DISPLAY_SLASH_DATE_FORMAT
          )
        : undefined,
    [assessmentState.prevFormInstanceData?.formLog?.updatedOn]
  );

  return {
    ...assessmentState,
    isPreviousFormInstanceDisabled,
    intakeOptions,
    handleOnChange,
    disableForm: isFormReadOnly,
    formSubmitLoading: isFormSubmitLoading,
    handleAcceptPreviousFormInstanceAction,
    prevFormInstanceUpdatedOn,
  };
};
