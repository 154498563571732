import { useLazyQuery } from '@apollo/client';
import { Spin, Tag } from 'antd';
import { debounce } from 'lodash';
import { Button, Text, View, VStack } from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { PatientQueries, UserQueries } from '../../../../services';
import ContactsQueries from '../../../../services/Contacts/ContactsQueries';
import { Colors } from '../../../../styles';
import { getAccountUUID, getUserPracticeLocationUuids, isAllowToShowExternalPatientId, isLoginUserBusinessOwner } from '../../../../utils/commonUtils';
import { getContactTypeId, getMlovId } from '../../../../utils/mlovUtils';
import { ModalActionAntSelect } from '../../ModalActionCommonComponent/ModalActionAntSelect';
import { getContactSearchActionQuery, getSearchQuery } from '../../PatientSearch/PatientSearchUtils';
import { ParticipantType } from './ParticipantEnum';
import { IAutoCompleteData, IParticipantSearch } from './ParticipantInterfaces';
import { isAccountConfigEnabled } from '../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../constants/AccountConfigConst';
import { getLocationIntersectionList } from '../../../RightSideContainer/Workflow/workflowUtils';

const ParticipantAutoComplete = (props: {
  defaultValue: IParticipantSearch[];
  disableUserSearch?: boolean;
  disableLeadsSearch?: boolean;
  allowSingleSelectInCategory?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  isShowWithEmail?:boolean;
  showError: boolean;
  isInvalid?: boolean;
  isRequired?: boolean;
  label?: string;
  patientsOptionLabel?: string;
  onChange: (participants: IParticipantSearch[]) => void;
  rightLabelTitle?: string;
  onRightLabelClick?: () => void;
  workflowLocations?: string[]
}) => {
  const intl = useIntl();
  const accountId = getAccountUUID();
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  // const [value, setValue] = useState<IParticipantSearch[]>(props.defaultValue);
  const [patientLoading, setPatientLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [patientData, setPatientData] = useState<IParticipantSearch[]>([]);
  const [staffData, setStaffData] = useState<IParticipantSearch[]>([]);
  const [leadData, setLeadData] = useState<IParticipantSearch[]>([]);

  const mlovData = useContext(CommonDataContext);
  const leadContactType = getContactTypeId('LEAD');
  const customerContactType = getContactTypeId(
    'CUSTOMER'
  );
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED)
  const userSettings = mlovData.userSettings;
  const allowToShowExternalPatientId = isAllowToShowExternalPatientId(userSettings);
  const isBusinessOwner = isLoginUserBusinessOwner()
  const userPracticeLocationUuids = getUserPracticeLocationUuids()

  const [getUsers] = useLazyQuery(UserQueries.SEARCH_USER_BY_TEXT, {
    onCompleted: (data: any) => {
      let usersData = [];
      if (data && data.users && data.users.length > 0) {
        usersData = data.users.map((user: any): IParticipantSearch => {
          return {
            label: props.isShowWithEmail ? <VStack><Text>{user.name}</Text>{ user.email ? <Text color={Colors.muted[500]}>{user.email}</Text> : <></>}</VStack> : user.name,
            value: user.uuid,
            key: user.uuid,
            type: ParticipantType.staff,
          };
        });
      }
      setStaffData(usersData);
      setUserLoading(false);
      // setIsOpen(true);
    },
    onError: (error) => {

      setStaffData([]);
      setUserLoading(false);
      // setIsOpen(true);
    },
  });

  const [getContacts] = useLazyQuery(
    ContactsQueries.SEARCH_CONTACTS_WITH_GLOBAL_SEARCH,
    {
      onCompleted: (data: any) => {
        let contactsData = [];
        if (data && data.contacts && data.contacts.length > 0) {
          contactsData = data.contacts.map(
            (contact: any): IParticipantSearch => {
              return {
                label:  props.isShowWithEmail ? <VStack><Text>{contact.name}</Text>{ contact.email ? <Text color={Colors.muted[500]}>{contact.email}</Text> : <></>}</VStack> : contact.name,
                value: contact.uuid,
                key: contact.uuid,
                type: ParticipantType.leads,
                contactId: contact.uuid,
              };
            }
          );
        }
        setLeadData(contactsData);
        setUserLoading(false);
        // setIsOpen(true);
      },
      onError: (error) => {

        setLeadData([]);
        setUserLoading(false);
        // setIsOpen(true);
      },
    }
  );

  const [searchPatientsV2] = useLazyQuery(PatientQueries.SEARCH_PATIENT_QUERY_V2, {
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      const patientUpdatedData = getUpdatedPatientData(data);
      let updatedData = [];
      if (patientUpdatedData) {
        updatedData = patientUpdatedData.filter(
          (patient: IParticipantSearch) => {
            return patient.label;
          }
        );
      }
      setPatientData(updatedData);
      setPatientLoading(false);
      // setIsOpen(true);
    },
    onError: (error) => {

      setPatientData([]);
      setPatientLoading(false);
      // setIsOpen(true);
    },
  });

  const getUpdatedPatientData = (data: any): any | undefined => {
    if (data && data.searchContacts?.contacts && data.searchContacts.contacts.length > 0) {
      const patientList = data.searchContacts.contacts;
      return patientList.map((contact: any): IParticipantSearch => {
        return {
          label: props.isShowWithEmail ? 
            <VStack>
              <Text>{contact.name}</Text>
              {allowToShowExternalPatientId && contact.patient?.patientId ? <Text color={Colors.muted[500]}>#{contact.patient?.patientId}</Text> : <></>}
              {contact.email ? <Text color={Colors.muted[500]}>{contact.email}</Text> : <></>}
            </VStack> 
            : 
            <VStack>
              <Text>{contact.name}</Text>
              {allowToShowExternalPatientId && contact.patient?.patientId ? <Text color={Colors.muted[500]}>#{contact.patient?.patientId}</Text> : <></>}
            </VStack>,
          value: contact.uuid,
          key: contact.uuid,
          type: ParticipantType.patient,
          contactId: contact.id,
        };
      });
    }
  };

  const searchPatient = (searchString: string) => {
    setPatientData([]);
    setSearchText(searchString);

    setPatientLoading(true);
    setIsOpen(true);
    
    const locationIds =
    (isMsoEnabled)
      ? props?.workflowLocations
      : []
    const searchContactFilter = getContactSearchActionQuery({
      searchString,
      contactTypeId: customerContactType,
      accountLocationIds: locationIds,
    });
    searchContactFilter.limit = 10;
    searchContactFilter.offset = 0;
    searchPatientsV2({
      variables: {
        params: searchContactFilter
      },
    });
    if (!props.disableUserSearch || !props.disableLeadsSearch) {
      setStaffData([]);
      setLeadData([]);
      setUserLoading(true);
      setIsOpen(true);
      if (!props.disableUserSearch) {
        getUsers({
          variables: {
            searchString: `%${searchString}%`,
            limit: 10,
            accountId: accountId,
          },
        });
      }
      if (!props.disableLeadsSearch) {
        const where = getSearchQuery({
          searchString,
          contactTypeId: customerContactType,
          userLocationUuids: locationIds
        });
        getContacts({
          variables: {
            ...where,
            limit: 10
          },
        });
      }
    }
  };

  const getOptions = (): IAutoCompleteData[] => {
    const options: IAutoCompleteData[] = [];
    if (patientData.length > 0) {
      options.push({
        label: (
          <Text fontSize="xs" color="gray.500">
            {props.patientsOptionLabel || 'Patients'}
          </Text>
        ),
        key: 'Patients',
        options: patientData,
      });
    }
    if (staffData.length > 0) {
      options.push({
        label: (
          <Text fontSize="xs" color="gray.500">
            Staff
          </Text>
        ),
        key: 'Staffs',
        options: staffData,
      });
    }
    if (leadData.length > 0) {
      options.push({
        label: (
          <Text fontSize="xs" color="gray.500">
            Prospects
          </Text>
        ),
        key: 'Prospects',
        options: leadData,
      });
    }
    return options;
  };

  const getTagClassName = (tagValue: any, tagLabel: string) => {
    const tagList = props.defaultValue.filter((data: IParticipantSearch) => {
      return data.value === tagValue && data.label === tagLabel;
    });
    if (tagList.length > 0) {
      const tag = tagList[0];
      switch (tag.type) {
        case ParticipantType.patient:
          return 'patient-tag';
        case ParticipantType.staff:
          return 'staff-tag';
        case ParticipantType.leads:
          return 'contact-tag';
        default:
          break;
      }
    }
    return 'patient-tag';
  };

  const tagRender = (tagProps: any) => {
    const onPreventMouseDown = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const selectedTags = props.defaultValue.filter(
      (participant) => participant.value === tagProps.value
    );
    const selectedTag = selectedTags.length > 0 ? selectedTags[0] : undefined;
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={selectedTag?.isDisabled ? false : tagProps.closable}
        onClose={tagProps.onClose}
        style={{marginRight: 3}}
        className={getTagClassName(tagProps.value, tagProps.label)}
      >
        {tagProps.label}
      </Tag>
    );
  };

  const handleValueChange = (values: IParticipantSearch[]) => {
    const existingValue = props.defaultValue;
    let result: IParticipantSearch[] = [];

    values.forEach((selectedValue: IParticipantSearch) => {
      const matchedData = getMatchedValue(selectedValue.key, existingValue);
      if (matchedData) {
        result.push(matchedData);
      } else {
        const matchedPatient = getMatchedValue(selectedValue.key, patientData);
        if (matchedPatient) {
          if (props.allowSingleSelectInCategory) {
            result = result.filter(
              (value) => value.type !== ParticipantType.patient
            );
          }
          result.push(matchedPatient);
        }

        const matchedStaff = getMatchedValue(selectedValue.key, staffData);
        if (matchedStaff) {
          if (props.allowSingleSelectInCategory) {
            result = result.filter(
              (value) => value.type !== ParticipantType.staff
            );
          }
          result.push(matchedStaff);
        }

        const matchedLead = getMatchedValue(selectedValue.key, leadData);
        if (matchedLead) {
          if (props.allowSingleSelectInCategory) {
            result = result.filter(
              (value) => value.type !== ParticipantType.leads
            );
          }
          result.push(matchedLead);
        }
      }
    });

    props.onChange(result);
  };

  const getMatchedValue = (
    key: string,
    list: IParticipantSearch[]
  ): IParticipantSearch | undefined => {
    const matchedData = list.filter((item) => {
      return item.key === key;
    });
    if (matchedData.length > 0) {
      return matchedData[0];
    }
    return undefined;
  };

  return (
    <>
      {/* <Select
        allowClear
        labelInValue
        filterOption={false}
        // defaultValue={props.defaultValue}
        value={props.defaultValue}
        disabled={props.isDisabled || false}
        open={isOpen}
        // onClick={() => setIsOpen((isOpen) => !isOpen)}
        // onKeyDown={(e) => {
        //   if (e.key === 'Enter') return;
        //   setIsOpen(true);
        // }}
        onFocus={() => setIsOpen(true)}
        onBlur={() => setIsOpen(false)}
        onSearch={debounce(searchPatient, 500)}
        onChange={(value: IParticipantSearch[]) => {
          handleValueChange(value);
          setIsOpen(false);
        }}
        onSelect={() => setIsOpen(false)}
        placeholder={
          props.placeholder || intl.formatMessage({id: 'searchPeople'})
        }
        loading={patientLoading || userLoading}
        mode="multiple"
        tagRender={tagRender}
        notFoundContent={
          (patientLoading || userLoading) && <Spin size="small" />
        }
        options={getOptions()}
        className={
          props.showError
            ? 'participant-auto-complete field-error'
            : 'participant-auto-complete'
        }
      />  */}
      <ModalActionAntSelect
        allowClear={true}
        labelInValue={true}
        filterOption={false}
        isInvalid={props.isInvalid}
        isRequired={props.isRequired}
        label={props.label}
        rightLabelTitle={props.rightLabelTitle}
        onRightLabelClick={props.onRightLabelClick}
        value={props.defaultValue}
        disabled={props.isDisabled || false}
        isOpen={isOpen}
        onFocus={() => {

          const debouncedSearch = debounce((searchTerm) => searchPatient(searchTerm), 500);
          debouncedSearch('');
        }}
        onBlur={() => setIsOpen(false)}
        onSearch={debounce(searchPatient, 500)}
        onChange={(value: IParticipantSearch[]) => {
          handleValueChange(value);
          setIsOpen(false);
        }}
        onSelect={() => setIsOpen(false)}
        placeholder={
          props.placeholder || intl.formatMessage({id: 'searchPeople'})
        }
        loading={patientLoading || userLoading}
        mode={'multiple'}
        tagRender={tagRender}
        notFoundContent={
          patientLoading || userLoading ? (
            <Spin size="small" />
          ) : (
            <View>
              {props.rightLabelTitle && searchText ? (
                <Button
                  style={{
                    height: 36,
                  }}
                  color={Colors.secondary['800']}
                  variant="link"
                  size={'lg'}
                  onPress={() => {
                    props.onRightLabelClick && props.onRightLabelClick();
                  }}
                >
                  {`+ ${props.rightLabelTitle}`}
                </Button>
              ) : searchText ? (
                <Text>No Data Found</Text>
              ) : null}
            </View>
          )
        }
        options={(patientLoading || userLoading) ? []: getOptions()}
        extraStyle={{flex: 1}}
        errors={props.showError}
        errorText={props.showError}
      />
    </>
  );
};

export default ParticipantAutoComplete;
