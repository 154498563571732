import React from 'react';
import Svg, {
  Circle,
  Path,
  Defs,
  RadialGradient,
  LinearGradient,
  Stop,
} from 'react-native-svg';

const CalenderDashedCircleSvg = () => {
  return (
    <Svg width="102" height="102" fill="none" viewBox="0 0 102 102">
      <Circle
        cx="51"
        cy="51"
        r="50"
        stroke="url(#paint0_radial_7843_29110)"
        strokeDasharray="1.46 1.46"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
      />
      <Circle
        cx="51"
        cy="50.999"
        r="42.667"
        stroke="url(#paint1_radial_7843_29110)"
        strokeDasharray="1.46 1.46"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
      />
      <Path
        fill="url(#paint2_linear_7843_29110)"
        d="M15.855 51.102c0-19.33 15.67-35 35-35s35 15.67 35 35-15.67 35-35 35-35-15.67-35-35"
      />
      <Path
        fill="#8A94A8"
        d="M42.216 37.484a.799.799 0 0 0 1.597 0zm1.597-2.457a.799.799 0 1 0-1.597 0zm14.375 2.457a.799.799 0 0 0 1.597 0zm1.597-2.457a.799.799 0 1 0-1.597 0zm-23.958 9.85a.799.799 0 0 0 0 1.597zm30.347 1.597a.799.799 0 0 0 0-1.598zm-18.368-8.191h6.389v-1.598h-6.389zM66.174 50.59v3.276h1.597V50.59zM54.195 66.173h-6.389v1.597h6.389zM35.827 53.866V50.59H34.23v3.276zm11.98 12.307c-3.036 0-5.23-.002-6.902-.233-1.648-.227-2.67-.662-3.434-1.445l-1.143 1.115c1.108 1.136 2.527 1.66 4.359 1.912 1.807.25 4.13.248 7.12.248zM34.23 53.866c0 3.067-.002 5.445.24 7.294.245 1.87.753 3.316 1.858 4.45l1.143-1.115c-.766-.786-1.194-1.843-1.417-3.543-.226-1.72-.227-3.975-.227-7.086zm31.944 0c0 3.11-.002 5.366-.227 7.086-.223 1.7-.651 2.757-1.417 3.543l1.143 1.115c1.105-1.134 1.613-2.58 1.858-4.45.242-1.849.24-4.227.24-7.294zM54.195 67.77c2.989 0 5.312.002 7.12-.248 1.832-.252 3.25-.776 4.358-1.912l-1.143-1.115c-.764.782-1.786 1.218-3.434 1.445-1.672.23-3.866.233-6.901.233zm0-29.487c3.035 0 5.229.002 6.901.232 1.648.227 2.67.663 3.434 1.446l1.143-1.115c-1.108-1.136-2.526-1.66-4.359-1.913-1.807-.25-4.13-.248-7.119-.248zM67.771 50.59c0-3.067.002-5.446-.24-7.294-.245-1.87-.753-3.317-1.858-4.45L64.53 39.96c.766.786 1.194 1.843 1.417 3.542.225 1.72.227 3.975.227 7.087zM47.806 36.685c-2.988 0-5.312-.001-7.12.248-1.831.252-3.25.777-4.358 1.913l1.143 1.115c.764-.783 1.786-1.219 3.434-1.446 1.672-.23 3.866-.232 6.901-.232zM35.826 50.59c0-3.11.002-5.365.228-7.086.223-1.7.651-2.756 1.417-3.542l-1.143-1.115c-1.105 1.133-1.613 2.58-1.858 4.45-.242 1.848-.24 4.227-.24 7.294zm7.987-13.105v-2.457h-1.597v2.457zm15.972 0v-2.457h-1.597v2.457zm-23.958 8.99h30.347v-1.598H35.827z"
      />
      <Defs>
        <RadialGradient
          id="paint0_radial_7843_29110"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(-90.639 61.896 11.587)scale(78.4672)"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#A1AABA"></Stop>
          <Stop offset="1" stopColor="#E4E7EC"></Stop>
        </RadialGradient>
        <RadialGradient
          id="paint1_radial_7843_29110"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(-90.639 60.256 9.928)scale(66.9587)"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#A1AABA"></Stop>
          <Stop offset="1" stopColor="#E4E7EC"></Stop>
        </RadialGradient>
        <LinearGradient
          id="paint2_linear_7843_29110"
          x1="26"
          x2="74"
          y1="24"
          y2="84.75"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#F9FAFC"></Stop>
          <Stop offset="1" stopColor="#F1F2F3"></Stop>
        </LinearGradient>
      </Defs>
    </Svg>
  );
};

export default React.memo(CalenderDashedCircleSvg);
