import React, { useMemo } from 'react';
import { Path, Svg } from 'react-native-svg';
import { Colors } from '../../styles';
import { ICommonSvgProps } from '../../components/common/Svg/interfaces';

const DownArrowFilledSvg = React.memo((props: ICommonSvgProps) => {
  const svgContent = useMemo(() => (
    <Svg width={props.size || "6"} height={props.size || "6"} viewBox="0 0 6 6" fill="none">
      <Path 
        d="M3 4L5 2H1L3 4Z" 
        fill={props.customStrokeColor || "#6F7A90"} 
        stroke={props.customStrokeColor || "#6F7A90"} 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
    </Svg>
  ), [props.customStrokeColor]);

  return svgContent;
});

export default DownArrowFilledSvg;
