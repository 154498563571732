import {ILibNode} from '../Workflow/AddOrUpdateWorkflow/IWorkflow';
import {ILibNodeDetail} from './FlowSideBar';

export const allAudioTypes = ".aac, .adt, .adts, .aif, .aifc, .aiff, .asf, .au, .m3u, .m4a, .m4b, .mid, .midi, .mp2, .mp3, .rmi, .snd, .wav, .wax, .wma";

export const getUpdatedNodeData = (
  nodeData: ILibNode[],
  nodeMasterDataMap: any
): ILibNodeDetail[] => {
  const finalData: ILibNodeDetail[] = [];
  nodeData.forEach((libNode) => {
    finalData.push({
      code: libNode.code,
      display: libNode.display,
      nodeList: libNode.nodeList
        .filter((nodeType) => {
          return (
            nodeMasterDataMap[nodeType] &&
            !nodeMasterDataMap[nodeType].isDeprecated
          );
        })
        .map((nodeType) => {
          const node = nodeMasterDataMap[nodeType];
          return {
            title: node.displayName,
            nodeType: node.type,
            description: node.description,
          };
        }),
    });
  });
  return finalData;
};

export const capitalizeWords = (str: string) => {
  if (!str) return '';
  return str
    .toLowerCase() // Convert the entire string to lowercase first
    .replace(/\b\w/g, (char) => char.toUpperCase());
};