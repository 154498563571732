import { Text, View } from 'native-base';
import { StyleSheet, TouchableOpacity } from 'react-native';
import Feather from 'react-native-vector-icons/Feather';
import { Colors } from '../../../../../../styles';

export const WorkflowStatus = ({ status, value, isActive , onClick, iconColor}: any) => {
  
  return (
    <TouchableOpacity onPress={onClick}>
      <View style={isActive ? styles.activeContainer: styles.container} >
        <View style={styles.icon} background={iconColor}/>
        <Text size={'smRegular'} style={styles.text}>{status}</Text>
        <Text size={'mdMedium'} style={styles.count}>{`(${value})`}</Text>
        <View style={styles.arrow}>
        <Feather
              name="chevron-right"
              size={20}
              color={Colors.Custom.Gray500}
          />
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  activeContainer: {
    width: '100%',
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 8,
    paddingBottom: 8,
    borderLeftWidth: 2,
    borderLeftColor: '#475467',
    backgroundColor: '#F2F4F7',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  container: {
    width: '100%',
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 8,
    paddingBottom: 8,
    
    backgroundColor: 'white',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 8, // Adjust this value based on your layout
  },
  icon: {
    width: 16,
    height: 16,
    borderRadius: 9999,
  },
  text: {
    flex: 1,
    color: Colors.FoldPixel.GRAY400,
    fontWeight: '500',
    lineHeight: 20,
    wordWrap: 'break-word',
    marginLeft: 8,
  },
  count: {
    color: Colors.FoldPixel.GRAY400,
    fontWeight: '400',
    lineHeight: 27,
    wordWrap: 'break-word',
    marginRight: 8,
  },
  arrow: {
    width: 20,
    height: 20,
  },
  arrowLine: {
    width: 5,
    height: 10,
    left: 7.5,
    top: 5,
    position: 'absolute',
    borderWidth: 1.5,
    borderColor: '#667085',
  },
});


