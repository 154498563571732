import { useLazyQuery } from '@apollo/client';
import {Drawer} from 'antd';
import {
  HStack,
  Select,
  Skeleton,
  Text,
  useToast,
  View,
  VStack,
} from 'native-base';
import React, {useEffect, useState} from 'react';
import {BUTTON_TYPE, PATIENT_CONSENT_ERROR} from '../../../constants';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../constants/Configs';
import { CareJourneyQueries } from '../../../services';
import BaseService from '../../../services/CommonService/BaseService';
import {Colors} from '../../../styles';
import {getAccountUUID, isContactConsentRequired} from '../../../utils/commonUtils';
import {ITemplateCategory} from '../../RightSideContainer/ContentManagement/EmailTemplates/interfaces';
import AddOrUpdateSMSTemplatesBody from '../../RightSideContainer/ContentManagement/SMSTemplates/AddOrUpdateSMSTemplates/AddOrUpdateSMSTemplatesBody';
import {SingleSMSTemplate} from '../../RightSideContainer/ContentManagement/SMSTemplates/interfaces';
import {getFormattedCarePlans, ICarePlan} from '../EmailPopupView/EmailPopupViewUtils';
import FormSearch from '../FormSearch/FormSearch';
import {ModalActionSelect} from '../ModalActionCommonComponent/ModalActionSelect';
import {ModalActionTitle} from '../ModalActionTitle/ModalActionTitle';
import {SMSDrawerProps, SMSDrawerState} from './interfaces';
import {styles} from './SMSDrawerStyles';
import {getContactDataHaveConsent, getWithoutConsentPatientsFromSmsData, SMSDrawerController} from './SMSDrawerUtils';
import { useIntl } from 'react-intl';
import { useContactsLocation } from '../../CustomHooks/useContactsLocation';

const SMSDrawer = (props: SMSDrawerProps) => {
  const {onCompleteAction, isOpen} = props;
  const isConsentRequired = isContactConsentRequired();
  const data = getContactDataHaveConsent(isConsentRequired, props?.data);
  const [state, setState] = useState<SMSDrawerState>({
    categoryList: [] as ITemplateCategory[],
    templateList: [] as SingleSMSTemplate[],
    selectedCategory: {} as ITemplateCategory,
    selectedTemplate: {} as SingleSMSTemplate,
    templatesLoading: false,
    categoryLoading: false,
    submitting: false,
    smsBody: '',
    error: '',
    smsData: {},
    carePlans: [],
    selectedCarePlan: {} as ICarePlan,
  });
  const toast = useToast();
  const accountUuid = getAccountUUID();
  const intl = useIntl();
  const withoutConsentPatientsList = getWithoutConsentPatientsFromSmsData(props?.data);
  const formInfoMessage = intl.formatMessage({ id: 'genericHealthComponentFormInfoMessageForLead' });

  const baseService = BaseService.getSharedInstance().axios;

  const {loading: contactLocationLoading, data: contactLocations  } = useContactsLocation({contactUuid: props?.data?.[0]?.contactUuid || ''});

  const getFormLocations = () => {
    const formLocationIds: string[] = [];
    (contactLocations || [])?.forEach((location: any) => {
      if (location?.accountLocation?.uuid) {
        formLocationIds.push(location?.accountLocation?.uuid);
      }
    });
    return formLocationIds;
  }

  const {
    isSendButtonDisabled,
    showCarePlansDropdown,
    showFormDropdown,
    showError,
    showSMSBody,
    getSMSTemplatesData,
    setTemplateBody,
    handleOnClose,
    handleOnSubmit,
    setCategory,
    setTemplate,
    setCarePlan,
    setFormData,
    onMount,
  } = new SMSDrawerController({
    data,
    state,
    baseService,
    toast,
    accountUuid,
    setState,
    onCompleteAction,
  });

  const [searchCareJourneys] = useLazyQuery(
    CareJourneyQueries.GET_CARE_JOURNEYS_BY_TITLE,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  useEffect(() => {
    onMount();
    searchCareJourneys({
      variables: {
        searchString: `%%`,
      },
    }).then((response) => {
      const formattedCarePlans = getFormattedCarePlans(response);
      setState((prev) => {
        return {
          ...prev,
          carePlans: formattedCarePlans,
        };
      });
    })
    .catch((error: any) => {

    });
  }, []);

  useEffect(() => {
    getSMSTemplatesData();
  }, [state.selectedCategory.code]);

  return (
    <Drawer
      width={'28vw'}
      visible={isOpen}
      onClose={() => {
        handleOnClose();
      }}
      title={
        <ModalActionTitle
          title={'Send SMS'}
          titleColor={''}
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: 'cancel',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                handleOnClose();
              },
            },
            {
              show: true,
              id: 2,
              btnText: 'Send',
              isDisabled: isSendButtonDisabled,
              textColor: Colors.Custom.mainPrimaryPurple,
              variant: BUTTON_TYPE.PRIMARY,
              isTransBtn: false,
              isLoading: state.submitting,
              onClick: () => {
                handleOnSubmit();
              },
            },
          ]}
        />
      }
    >
      <VStack>
        {showError && (
          <HStack>
            <Text
              style={{
                color: Colors.error['500'],
              }}
            >
              {state.error}
            </Text>
          </HStack>
        )}

        <HStack style={{marginVertical: 8, justifyContent: 'flex-start'}}>
          <View marginRight={5}>
            <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>To:</Text>
          </View>
          <HStack flexWrap={'wrap'} width={'25vw'}>
            {data?.map((item) => {
              return (
                <Text key={item.id}>
                  {`${item.name} (${item.phoneNumber}), `}
                </Text>
              );
            })}
          </HStack>
        </HStack>
        {isConsentRequired &&
          withoutConsentPatientsList?.length ? (
            <HStack pl={1} mb={2} mt={-2}>
              <Text fontSize={12} color={Colors.Custom.Gray500}>
                {`Please give consent for `}
                <Text
                  fontSize={12}
                  color={Colors.Custom.Gray500}
                  fontWeight={700}
                >
                  {withoutConsentPatientsList}
                </Text>
                {' ' + PATIENT_CONSENT_ERROR}
              </Text>
            </HStack>
          ) : <></>}
        <HStack style={styles.row}>
          <View style={styles.label}>
            <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>Category</Text>
          </View>
          <View style={styles.dropdown}>
            <Select
              style={{flex: 1}}
              onValueChange={(value) => {
                setCategory(value);
              }}
              selectedValue={state.selectedCategory.code || ''}
            >
              {state.categoryList.map((item) => {
                return (
                  <Select.Item
                    label={item.name}
                    value={item.code}
                    key={item.code}
                  />
                );
              })}
            </Select>
          </View>
        </HStack>

        {state.templatesLoading ? (
          <Skeleton.Text lines={2} />
        ) : (
          <HStack style={styles.row}>
            <View style={styles.label}>
              <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>Templates</Text>
            </View>
            <View style={styles.dropdown}>
              <Select
                selectedValue={
                  (state.selectedTemplate.id || '').toString() || ''
                }
                style={{flex: 1}}
                onValueChange={(value) => {
                  setTemplate(value);
                }}
              >
                {state.templateList.map((item) => {
                  return (
                    <Select.Item
                      label={item.name || ''}
                      value={item.id.toString()}
                      key={item.id}
                    />
                  );
                })}
              </Select>
            </View>
          </HStack>
        )}

        {showCarePlansDropdown && (
          <HStack style={styles.row}>
            <View style={styles.label}>
              <Text>Care Journeys</Text>
            </View>
            <View style={styles.dropdown}>
              <ModalActionSelect
                placeholder="Choose a Care Journey"
                onValueChange={(id: string) => {
                  setCarePlan(id);
                }}
                data={state.carePlans}
                selectItemProps={{
                  key: 'id',
                  label: 'title',
                  value: 'id',
                }}
                customStyle={{flex: 1}}
              />
            </View>
          </HStack>
        )}

        {showFormDropdown && (
          <HStack style={styles.row}>
            <View style={styles.label}>
              <Text>Form</Text>
            </View>
            <View style={styles.dropdown}>
              {!contactLocationLoading && (
                <FormSearch
                  isShowError={false}
                  infoMessage={state?.smsData?.form?.isHealthComponentIncluded ? formInfoMessage : undefined}
                  onChange={(value) => {
                    setFormData(value);
                  }}
                  formLocations={getFormLocations()}
                />
              )}
            </View>
          </HStack>
        )}

        {showSMSBody && (
          <HStack style={styles.row}>
            <View style={styles.label}>Body</View>
            <View style={styles.dropdown}>
              <AddOrUpdateSMSTemplatesBody
                isHideLabel
                smsTemplateData={state.selectedTemplate}
                setSmsTemplateData={setTemplateBody}
                useString
                onChangeBody={(body) => {
                  setTemplateBody(body);
                }}
              />
            </View>
          </HStack>
        )}
      </VStack>
    </Drawer>
  );
};

export default SMSDrawer;
