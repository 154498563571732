import {
  // Badge,
  Box,
  Text,
  // Text,
  View,
} from 'native-base';
import React from 'react';
import {ITagComponentProps} from '.';
import {styles} from './TagComponentStyles';

const TagComponent = (props: ITagComponentProps) => {
  const {tagText, tagColor, tagStyle, tagTextStyle, tagTextColor} = props;

  return (
    <View flex={1} flexDirection={'row'}>
      <Box
        width={'fit-content'}
        style={[
          tagStyle,
          styles.tagContainer,
          {backgroundColor: tagColor + '40'},
        ]}
      >
        <Text size={'xsNormal'} color={tagTextColor ? tagTextColor : tagColor} style={[tagTextStyle]}>{tagText}</Text>
      </Box>
    </View>
  );
};

export default TagComponent;
