import { StyleSheet } from 'react-native';
import { Colors } from '../../../../styles/Colors';
import { isWeb } from '../../../../utils/platformCheckUtils';

export const styles = StyleSheet.create({
  sidecarGraphView: { 
    marginVertical: 10,
    marginHorizontal: 16
  },
  defaultGraphView: {
    paddingX: 4,
    paddingBottom: 4,
    marginTop: 2,
  },
  collapsedView: {
    borderRadius: 8,
    backgroundColor: Colors.Custom.White,
    overflow: 'hidden',
  },
  collapsedHeader: {
    alignItems: 'center',
    marginVertical: 16,
    marginHorizontal: 18,
  },
  collapsedHeaderText: {
    color: '#0e0333',
    fontSize: 20,
    fontWeight: '600',
    fontFamily: 'Manrope-Bold',
    marginLeft: 3,
  },
  collapsedViewDivider: {
    margin: 0,
  },
  loaderContainer: {
    padding: 16,
  },
  webViewStyles: {
    height: isWeb() ? 200 : undefined,
    width: isWeb() ? 200 : undefined,
    margin: 16,
    alignSelf:'flex-end'
  },
  headerContainer: {
    padding: 16,
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderBottomColor: Colors.Custom.Gray200,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  hStackStyle: {
    height: '79vh'
  },
  flex0P4: {
    flex: 0.4
  },
  view9: {
    height: 'auto',
    width: 0.5,
    // backgroundColor: Colors.Custom.Gray200
  },
  alignItemsCenter: {
    alignItems: 'center'
  },
  datePickerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 12,
    borderWidth: 1,
    borderColor: '#e4e7ec',
    borderRadius: 8,
    backgroundColor: '#fff',
    overflow: 'hidden',
    height: 40
  },
  scrollViewStyle: {
    maxHeight:'65vh'
  },
  stackStyles2: {
    flex: 0.6,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  divider: {
    backgroundColor: Colors.FoldPixel.GRAY150,
    height: 0.5,
  },
  marginLeft10: {
    marginLeft: 10
  },
  paddingHorizontal8: {
    paddingHorizontal: 8
  },
  stackStyles1: {
    paddingBottom: 12,
    marginHorizontal: 4
  },
  viewStyles: {
    borderWidth: 1,
    borderRadius: 12,
    borderColor: Colors.FoldPixel.GRAY150,
    marginTop: 12,
  },
  paddingTop2: {
    paddingTop: 2
  },
  centered: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  pressableStyle1: {
    width: 36,
    height: 36,
    justifyContent: 'center',
    alignItems: 'center'
  },
  box24: {
    width: 24,
    height: 24
  },
  countWithTextContainer: {
    paddingHorizontal: 16,
    paddingVertical: 20,
    borderWidth: 1,
    borderColor: Colors.Custom.Gray200,
    borderRadius: 8,
    marginBottom: 12,
    flex: 1
  },
  pieChartContainer: {
    paddingHorizontal: 16,
    paddingVertical: 12,
    borderWidth: 1,
    borderColor: Colors.Custom.Gray200,
    borderRadius: 8,
    flex: 1,
    marginBottom: 12,
    marginHorizontal: 12
  },
  lineChartContainer: {
    paddingHorizontal: 16,
    paddingVertical: 12,
    borderWidth: 1,
    borderColor: Colors.Custom.Gray200,
    borderRadius: 8,
    flex: 1,
    marginBottom: 12
  },
  formResponseContainer: {
    padding: 16,
    backgroundColor: '#ffffff',
    borderWidth: 1,
    borderColor: Colors.Custom.Gray200,
    borderRadius: 6,
    marginBottom: 16,
    flexDirection: 'row'
  },
  pieChartHeader: {
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  pieChartHeaderText: {
    flexDirection: 'column'
  },
  pieChartContainer2: { flexDirection: 'row', alignItems: 'center', justifyContent: 'center' },
  pieChartHeaderText2: {
    flexDirection: 'column',
    justifyContent: 'center'
  },
  pieChartHeaderText3: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
    marginLeft: 15
  },
  fontSize12: {
    fontSize: 12
  },
  fontSize16: {
    fontSize: 16
  },
  marginRight6: {
    marginRight: 6
  },
  marginLeft8: {
    marginLeft: 8
  },
  flexDirectionRow: {
    flexDirection: 'row'
  },
  infoIcon: {
    fontSize: 16,
    color: Colors.Custom.Gray500,
  },
  bgTrasparantAndPaddingHorizontal0: {
    backgroundColor: 'transparent',
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  height100: {
    height: '100%'
  },
  maxWidth60: {
    maxWidth: '60%'
  },
  marginLeft4: {
    marginLeft: 4
  },
  formCountCardText: {
    position: 'absolute',
    bottom: 10,
    right: 10
  },
  textStyle: {
    marginRight: 6,
    color: Colors.Custom.Gray400,
    marginBottom: 8,
  },
  width100: {
    width: '100%'
  },
  alignItemsFlexStart: {
    alignItems: 'flex-start',
    marginRight: 16,
  },
  padding6: {
    padding: 6,
  },
  flex1: {
    flex: 1,
  },
  marginVertical12: {
    marginVertical: 12,
  },
  flexDirectionRowFlex1: {
    flexDirection: 'row',
    flex: 1,
  },
  textStyle1: {
    fontSize: 12,
    width: 'fit-content',
    paddingHorizontal: 6,
    paddingTop: 2,
    paddingBottom: 4,
    color: Colors.FoldPixel.GRAY300,
    backgroundColor: Colors.FoldPixel.GRAY50,
    borderRadius: 4,
    marginTop: 6
  },
  textStyle2: {
    flex: 1,
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    color: Colors.Custom.Gray700
  },
  flex1AllCentered: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerContainer2: {
    // backgroundColor: Colors.Custom.Gray200,
    padding: 8,
    alignItems: 'center',
    borderRadius: 4,
  },
  flexDirectionColumn: {
    flexDirection: 'column',
  },
  formResponseContainer2: {
    // backgroundColor: Colors.Custom.Gray50,
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderRadius: 6,
    marginBottom: 8,
    flex: 1,
    maxWidth: '70%',
  },
  alignItemsFlexStartMarginRight16: {
    alignItems: 'flex-start',
    marginRight: 16
  },
  marginRight6FontSize18: {
    marginRight: 6,
    fontSize: 18,
  },
  text2: {
    marginRight: 6,
    marginBottom: 8,
    fontSize: 14,
    color: Colors.Custom.Gray400,
  },
  formResponseContainer3: {
    // backgroundColor: Colors.Custom.Gray100,
    borderRadius: 6,
    paddingVertical: 8,
    paddingHorizontal: 12,
    flexDirection: 'column',
    marginBottom: 8
  },
  flexDirectionRowJustifySpaceBetweenAlignItemsCenter: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  marginRight10: {
    marginRight: 10
  },
  flexDirectionRowAlignItemsCenter: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  colorGray400Padding4PaddingTop0FontSize12MarginLeft20: {
    color: Colors.Custom.Gray400,
    padding: 4,
    paddingTop: 0,
    fontSize: 12,
    marginLeft: 20
  },
  flexDirectionRowAlignItemsCenterFlex1: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1
  },
  marginLeft8FontSize14: {
    marginLeft: 8,
    fontSize: 14
  },
  viewStyle: {
    width: '100%',
    marginVertical: 8,
    height: 1,
    // backgroundColor: Colors.Custom.Gray200
  },
  marginLeft8FontSize16: {
    marginLeft: 8,
    fontSize: 16
  },
  drawerContentContainer: {
    padding: 16,
    overflow: 'scroll',
    height: '85%'
  },
  viewStyle2: {
    borderWidth: 1,
    borderColor: Colors.Custom.Gray200,
    borderRadius: 8,
    padding: 12,
    marginRight: 12,
    marginBottom: 12,
    width: 300,
    height: 150
  },
  viewStyle3: {
    marginBottom: 12,
    borderBottomColor: Colors.Custom.Gray200,
    borderBottomWidth: 1,
    paddingBottom: 12
  },
  width50: {
    width: '50%'
  },
  viewStyle1: {
    backgroundColor: Colors.Custom.MonochromeWhite,
    padding: 16,
    flex: 1,
  },
  vStackStyle: {
    marginHorizontal: 12,
    marginBottom: 12,
    marginTop: 16
  },
  pressable1: {
    width: 36,
    height: 36,
    justifyContent: 'center',
    alignItems: 'center'
  },
  vStackStyle1: {
    marginTop: 12,
    marginHorizontal: 8
  },
  marginVertical10: {
    marginVertical: 10
  },
  viewStyle4: {
    minWidth: 300,
    maxWidth: 500,
    padding: 4
  },
  height35: {
    height: 35
  },
  view10: {
    borderColor: Colors.Custom.Gray100,
    borderWidth: 2,
    borderRadius: 16,
  },
  marginLeft2: {
    marginLeft: 2
  },
  text: {
    color: '#F37232',
    marginLeft: 8
  },
  alignItemsFlexEnd: {
    alignItems: 'flex-end'
  },
  stackStyling: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 40,
  },
  view1: {
    width: 1,
    height: 20,
    // backgroundColor: Colors.Custom.separatorPipeBackgroundColor,
    marginHorizontal: 10,
  },
  feather: {
    marginLeft: 2,
    marginTop: 2
  },
  marginRight4MarginTop4: {
    marginRight: 4,
    marginTop: 4
  },
  view11: {
    paddingHorizontal: 8,
    paddingTop: 8,
  },
  stackStyles3: {
    borderTopWidth: 0.5,
    borderColor:Colors.Custom.Gray200,
    backgroundColor:Colors.Custom.Gray50,
    padding: 12,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
  },
  relative: {
    position: 'relative'
  },
  view13: {
    alignSelf: isWeb() ? 'center' : 'flex-start',
    marginLeft: !isWeb() ? 6 : 0
  },
  colorGray300: {
    color: Colors.FoldPixel.GRAY300,
  },
  view12: {
    marginLeft: 8,
    marginBottom: 4,
    marginRight: 8
  },
  view2: {
    maxWidth: 370,
    paddingHorizontal: 10,
    height: 64
  },
  view: {
    maxWidth: '20vw',
    height: 64,
  },
  width78height24: {
    width: 78,
    height: 24
  },
  stackStyle1: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 40,
  },
  alignItemsCenterMarginRight6: {
    alignItems: 'center',
    marginRight: 6
  },
  stackStyle: {
    flex: 1,
    justifyContent: 'space-between',
    marginTop: 12,
    marginLeft: 12,
    marginRight: 8,
  },
  flex1AlignItemsCenter: {
    flex: 1,
    alignItems: 'center'
  },
  draggableIconSvgStyle: {
    marginLeft: 8,
    marginRight: 12
  },
  stackStyles: {
    justifyContent: 'space-between',
    flex: 1,
    alignItems: 'center',
    paddingLeft: 8,
    paddingVertical: 8,
    backgroundColor: Colors.Custom.White
  },
  textStyle3: {
    color: Colors.Custom.Gray500,
    fontSize: 14,
  },
  textStyle4: {
    color: Colors.Custom.Gray500,
    fontSize: 14,
    fontWeight: 'normal',
  },
  textStyle5: {
    fontSize: 16,
    color: Colors.Custom.Gray900,
    fontWeight: '500',
  },
  textStyle6: {
    color: Colors.Custom.Gray500,
    fontSize: 14,
    minWidth: 70
  },
  viewStyles1: {
    width: 1,
    height: 20,
    // backgroundColor: Colors.Custom.Gray300,
    marginRight: -10,
    marginLeft: -10,
  },
  width100padding15: {
    width: '100%',
    padding: 15
  },
  marginTop16: {
    marginTop: 16
  },
  viewStyles2: {
    width: 1,
    height: 20,
    marginLeft: 7,
    marginRight: -2,
    backgroundColor: Colors.Custom.Gray300,
    marginHorizontal: 2
  },
  stack: {
    flex: 1,
    justifyContent: 'flex-end',
    marginTop: 8,
    marginRight: 8,
  },
  stack1: {
    paddingHorizontal: 16,
    paddingBottom: 16
  },
  fontWeight600: {
    fontWeight: '600'
  },
  marginTop20: {
    marginTop: 20
  },
  cannedResponseViewStyle: {
    height:  100,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 999999999999999,
  },
  fontSize16FontWeight400:{
    fontSize: 16,
    fontWeight: '400'
  },
  flex1justifyContentFlexEnd: {
    flex: 1,
    justifyContent: 'flex-end'
  },
  box36centered: {
    width: 36,
    height: 36,
    justifyContent: 'center',
    alignItems: 'center'
  },
  collapsedViewContainer: {
    backgroundColor: Colors.Custom.White,
    borderRadius: 8,
    overflow: 'hidden',
  },
  collapsedViewHeader: {
    alignItems: 'center',
    marginVertical: 16,
    marginHorizontal: 18,
  },
  collapsedViewHeaderContent: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  collapsedViewHeaderText: {
    color: '#0e0333',
    fontSize: 20,
    fontWeight: "600",
    fontFamily: 'Manrope-Bold',
    marginLeft: 3,
  },
  collapsedViewContent: {
    paddingVertical: 12,
    paddingHorizontal: 20,
  },
});

export const reactStyles: Record<string, React.CSSProperties> = {
  displayBlock: {
    display: 'block'
  },
  maxWidth500flex1: {
    maxWidth: '500px',
    flex: 1
  },
  collapseStyle3: {
    borderRadius: 0,
    borderWidth: 0,
    padding: 0,
    background: '#f9fafb'
  },
  height36px: {
    height: '36px'
  },
  inputNumberStyle: {
    height: 36,
    borderRadius: 4,
    maxWidth: 72
  },
  collapseStyle5: {
    borderRadius: 5,
    borderWidth: 0,
    padding: 0,
    background: '#f9fafb'
  },
  select: {
    backgroundColor: Colors.Custom.White
  },
  width125px: {
    width: '125px'
  },
  marginTopMinus40: {
    marginTop: '-40px'
  },
  margin16: {
    margin: 16
  },
  padding16: {
    padding: 16
  },
  noBorderWidth: {
    borderWidth: 0
  },
  displayFlex: {
    display: 'flex'
  },
  heightAuto: {
    height: 'auto'
  },
  minWidth700: {
    minWidth: '700px'
  },
  height40px: {
    height: '40px'
  },
  div: {
    marginBottom: '10px'
  },
  collapseStyle: {
    borderRadius: 0,
    borderWidth: 0,
    padding: 0,
    background: '#f9fafb'
  },
  collapseStyle1Mt16: {
    borderRadius: 0,
    borderWidth: 0,
    padding: 0,
    background: 'Colors.Custom.Gray100',
    marginTop: 16,
  },
  collapseStyle1: {
    borderRadius: 0,
    borderWidth: 0,
    padding: 0,
    background: 'Colors.Custom.Gray100',
  },
  collapseStylePtV16: {
    borderRadius: 0,
    borderWidth: 0,
    paddingTop: 4,
    paddingBottom: 4,
    background: 'Colors.Custom.Gray100',
  },
  popoverStyle: {
    padding: 0,
    borderRadius: 16,
    minWidth: 200
  },
  padding0BorderRadius16: {
    padding: 0,
    borderRadius: 16
  },
  padding0: {
    padding: 0
  },
  relative: {
    position: 'relative'
  },
  collapseStyle2: {
    borderRadius: 0,
    borderWidth: 0,
    padding: 0,
    background: 'Colors.Custom.Gray100',
    marginTop: 16,
  },
  collapseStyle7: {
    borderRadius: 0,
    borderWidth: 0,
    padding: 0,
    background: 'Colors.Custom.Gray100',
    marginTop: 0,    
  },
  collapseStyle4: {
    borderRadius: 0,
    borderWidth: 0,
    padding: 0,
    background: '#f9fafb'
  },
  flex1: {
    flex: 1
  },
  collapseStyle6: {
    borderRadius: 0,
    borderWidth: 0,
    padding: 0,
  },
  borderRadius6height36: {
    borderRadius: 6,
    height: 36
  },
  inputNumberStyles: {
    width: '70px',
    borderRadius: 6,
    height: 36
  },
}
