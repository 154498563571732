
import {
  CARE_PROGRAM_SUB_STEP_CODES,
  FORM_SOURCE,
} from '../../../../../constants/MlovConst';
import {IMlov} from '../../../../../Interfaces';
import {getMlovIdFromCode} from '../../../../../utils/mlovUtils';
import {
  ICareProgramActionsPayload,
  IContactCareProgram,
  IContactCareProgramStep,
} from '../../interface';
import {getFormDetails} from '../ContactCareProgramView.utils';
import {
  IUseCareProgramStepActionsParams,
  StepAction,
} from './useCareProgramStepActions';

export const getPayLoadForStatusAction = (
  action: StepAction,
  params: IUseCareProgramStepActionsParams,
  additionalParams: {
    contactCareProgramSteps: IContactCareProgramStep[];
    careProgramSubStepList: IMlov[];
    isAssessmentStep: boolean;
  }
) => {
  let formLogId, formId;
  const {careProgramDetails, stepId} = params;
  const {contactCareProgramSteps, careProgramSubStepList, isAssessmentStep} =
    additionalParams;
  const step = contactCareProgramSteps?.find((step) => step.id === stepId);

  const payload: any = {
    careProgramStepId: step?.id,
    contactCareProgramId: careProgramDetails?.id,
    careProgramSubStepId: '',
    payload: undefined,
  };

  switch (action) {
    case StepAction.REVIEW:
      const reviewedSubStepTypeId = getMlovIdFromCode(
        careProgramSubStepList,
        CARE_PROGRAM_SUB_STEP_CODES.REVIEWED
      );
      const reviewedSubStep = step?.careProgramSubSteps.find(
        (item) => item.careProgramSubStepType.id === reviewedSubStepTypeId
      );
      payload.careProgramSubStepId = reviewedSubStep?.id as string;
      break;
    case StepAction.SKIP:
      const skippedSubStepTypeId = getMlovIdFromCode(
        careProgramSubStepList,
        CARE_PROGRAM_SUB_STEP_CODES.SKIPPED
      );
      const skipSubStep = step?.careProgramSubSteps.find(
        (item) => item.careProgramSubStepType.id === skippedSubStepTypeId
      );
      payload.careProgramSubStepId = skipSubStep?.id as string;
      break;
    case StepAction.COMPLETE:
    case StepAction.SEND_CLAIM:
      const completedSubStepTypeId = getMlovIdFromCode(
        careProgramSubStepList,
        CARE_PROGRAM_SUB_STEP_CODES.COMPLETED
      );
      const completedSubStep = step?.careProgramSubSteps.find(
        (item) => item.careProgramSubStepType.id === completedSubStepTypeId
      );
      payload.careProgramSubStepId = completedSubStep?.id as string;
  }

  if (isAssessmentStep) {
    const formInfo = getFormLogId(params);

    formLogId = formInfo?.formLogId;
    formId = formInfo?.formId;

    if (!formLogId || !formId) {
      return {};
    }

    payload.payload = {
      stepCode: step?.careProgramStepType?.code,
      resourceId: formLogId,
      resourceTypeCode: 'FORM_LOG',
    };
  }

  return payload;
};

const getFormLogId = (params: IUseCareProgramStepActionsParams) => {
  const {careProgramDetails, stepId} = params;
  return getFormDetails(careProgramDetails as IContactCareProgram, stepId);
};

export const getPayLoadForOtherActions = (
  action: StepAction,
  params: IUseCareProgramStepActionsParams,
  additionalParams: {
    contactCareProgramSteps: IContactCareProgramStep[];
    careProgramSubStepList: IMlov[];
    isAssessmentStep: boolean;
    contactId: string;
    careProgramDetails: IContactCareProgram;
    formSourceList: IMlov[];
    currentUserUuid: string;
    stepId: string;
  }
): {params: ICareProgramActionsPayload} | undefined => {
  const {
    contactId,
    formSourceList,
    currentUserUuid,
    careProgramDetails,
    stepId,
  } = additionalParams;

  if (!careProgramDetails || !stepId || !contactId) {
    return;
  }

  const basePayload: {params: ICareProgramActionsPayload} = {
    params: {
      careProgramParams: {
        contactCareProgramId: careProgramDetails?.id,
        contactCareProgramStepId: stepId,
      },
      actionTypeCode: action,
    },
  };

  switch (action) {
    case StepAction.SEND_TO_PATIENT:
      const form = getFormDetails(
        careProgramDetails as IContactCareProgram,
        stepId
      );
      if (!form?.formId) {
        return;
      }
      basePayload.params.formLogLinkParams = {
        contactId,
        formId: form?.formId,
        sourceId: getMlovIdFromCode(formSourceList, FORM_SOURCE.CARE_PLAN),
        requestedByUserId: currentUserUuid,
        subjectId: crypto.randomUUID(),
      };
      break;
    default:
      break;
  }

  return basePayload;
};


export const getSuccessMessageForAction = (action: StepAction) => {
  switch (action) {
    case StepAction.SEND_TO_PATIENT:
      return 'Form sent to patient successfully';
    default:
      return '';
  }
};
