import { StyleSheet } from 'react-native';
import { Colors } from "../../../../../styles/Colors";

export const styles = StyleSheet.create({
  container: {
    padding: 8,
    borderRadius: 8,
    borderColor: Colors.Custom.Gray300,
    borderWidth: 0.5,
  },
  zipCodeContainer: {
    padding: 4,
  },
  labelText: {
    color: Colors.Custom.Gray500,
    fontWeight: '400',
    fontSize: 14,
  },
  avatarContainer: {
    alignItems: 'center',
  },
  avatar: {
    alignItems: 'center',
    borderRadius: 6,
    borderWidth: 1,
    marginRight: 8,
  },
  avatarText: {
    fontSize: 14,
    textAlign: 'center',
    padding: 1,
  },
  addressText: {
    fontSize: 12,
    color: Colors.FoldPixel.GRAY250,
  },
  tagIcon: {
    marginRight: 5,
  },
  checkbox: {marginRight: 8, padding: 2, height: 20, width: 20},
  inclusionExclusionContainer: {
    flex: .5,
    padding: 4
  },
  providerDirectoryContainer: {
    flex: 1,
    padding: 4
  },
  userBadge: {
    paddingVertical: '2px',
    paddingHorizontal: '6px',
    marginVertical: 4,
    marginHorizontal: 4,
    backgroundColor: Colors.FoldPixel.GRAY100,
    alignSelf: 'center',
  },
  userName: {
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 16.8,
    color: Colors.FoldPixel.GRAY300,
    marginRight: 4,
  },
});
