import {Colors} from '../../../styles/Colors';
import {Pressable, View, Text} from 'react-native';
import {useIntl} from 'react-intl';
import {EMAIL_INBOX_USER_VIEW} from './PreserenceConst';
import {IEmailInboxUserView, IEmailPreferenceView, IEmailPreferenceViewState, IUserPreferenceData} from './PreferencesInterface';
import {styles} from './PreferenceStyle';
import {useContext, useEffect, useState} from 'react';
import {getUserUUID} from '../../../utils/commonUtils';
import {EventBus} from '../../../utils/EventBus';
import {ICustomToast} from '../../RightSideContainer/Contacts/CustomField/interface';
import {CUSTOM_FIELD_TOAST_ERROR_DURATION} from '../../RightSideContainer/Contacts/CustomField/CustomFieldConst';
import {useToast} from '../../Toast/ToastProvider';
import {ToastType} from '../../../utils/commonViewUtils';
import {CUSTOM_COMMON_EVENT_CODES} from '../../../constants/WebSocketConst';
import {addUpdateUserPreference} from './PreferenceService';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {CommonDataAction} from '../../CommonDataProvider/interface';
import './PreferenceSetting.css'


export const EmailPreferenceView = (props: IEmailPreferenceView) => {
  const intl = useIntl();
  const [stateData, setStateData] = useState<IEmailPreferenceViewState>({
    selectedEmailInboxUserView: props?.selectedEmailInboxUserView,
    preferenceData: {} as IUserPreferenceData,
    preferenceDataId: undefined,
    loading: false,
    userPreferenceLoading: false,
  })
  const commonData = useContext(CommonDataContext);
  const toast = useToast();
  const userUuid = getUserUUID();

  const showToast = (toastData: ICustomToast) => {
    toast({
      toastType: toastData?.toastType,
      message: toastData?.message,
      duration: toastData?.duration || CUSTOM_FIELD_TOAST_ERROR_DURATION,
      closeAllPrevToast: toastData?.closeAllPrevToast || false,
    })
  }

  const setPreference = async (view: IEmailInboxUserView) => {
    const prevJSON = stateData?.preferenceData || {};
    const newJSON = {
      ...prevJSON,
      userPreference: {
        emailInboxUserView: view?.code
      }
    };
    const data = {
      object: {
        userId: userUuid,
        userPreferencesTypeId: props?.userPreferenceId,
        preferencesJson: JSON.stringify(newJSON),
        id: stateData?.preferenceDataId ? stateData?.preferenceDataId : undefined,
      },
    };
    setStateData((prev) => {
      return {
        ...prev,
        loading: true
      }
    })
    try {
      const response = await addUpdateUserPreference(data);
      setStateData((prev) => {
        return {
          ...prev,
          loading: false
        }
      })
      if (response?.data?.userPreferences?.[0]?.id) {
        setStateData((prev) => {
          return {
            ...prev,
            selectedEmailInboxUserView: view,
          }
        })
        props?.onActionPerformed(COMMON_ACTION_CODES.EMAIL_PREFERENCE_CHANGE, view);
        commonData?.dispatch?.({
          type: CommonDataAction.UPDATE_EMAIL_SIGNATURES,
          payload: response?.data,
        });
        const eventBus = EventBus.getEventBusInstance();
        eventBus.broadcastEvent(CUSTOM_COMMON_EVENT_CODES.USER_PREFERENCE_UPDATED, {
          selectedEmailInboxUserView: view?.code,
        });
        showToast({
          toastType: ToastType.success,
          message: intl.formatMessage({id: 'preferenceSuccess'}),
          duration: CUSTOM_FIELD_TOAST_ERROR_DURATION,
          closeAllPrevToast: true,
        })
      }
    } catch (error: any) {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false
        }
      })
      showToast({
        toastType: ToastType.error,
        message: error?.response?.data?.message || intl.formatMessage({id: 'apiErrorMsg'}),
        duration: CUSTOM_FIELD_TOAST_ERROR_DURATION,
        closeAllPrevToast: true,
      })
    }
  }

  useEffect(() => {
    setStateData((prev) => {
      return {
        ...prev,
        preferenceData: props?.preferenceData,
        preferenceDataId: props?.preferenceDataId,
        selectedEmailInboxUserView: props?.selectedEmailInboxUserView,
      }
    })
  },[props?.selectedEmailInboxUserView])

  return (
    <View>
      <View>
        <View
          style={{
            borderWidth: 1,
            borderColor: Colors.Custom.Gray200,
            borderRadius: 12,
          }}
        >
          <View
            style={{
              padding: 10
            }}
          >
            <Text
              style={{
                fontSize: 14,
                fontWeight: '500',
                color: Colors.FoldPixel.GRAY400
              }}
            >
              {intl.formatMessage({id: 'emailInboxView'})}
            </Text>
            <Text
              style={{
                fontSize: 12,
                color: Colors.FoldPixel.GRAY250,
                fontWeight: '400',
                marginTop: 4,
              }}
            >
              {intl.formatMessage({id: 'emailInboxPreferenceMsg'})}
            </Text>
          </View>
          <View
            style={{
              borderBottomWidth: 1,
              borderBottomColor: Colors.Custom.Gray200,
            }}
          />
          <div className={stateData?.loading ? "email-inbox-preference" : ""}>
            <View
              style={{
                paddingHorizontal: 10,
                paddingVertical: 4,
              }}
            >
              {EMAIL_INBOX_USER_VIEW?.map?.((view: IEmailInboxUserView) => {
                return (
                  <Pressable
                    key={view.code}
                    style={styles.radioButton}
                    onPress={() => {
                      setPreference(view);
                    }}
                  >
                    <View style={stateData?.selectedEmailInboxUserView?.code === view?.code ? styles.radioCircleSelected :  styles.radioCircle}>
                      {stateData?.selectedEmailInboxUserView?.code === view?.code && <View style={styles.selectedRadioCircle} />}
                    </View>
                    <Text style={{
                      marginLeft: 10,
                      fontSize: 14,
                      color: Colors.FoldPixel.GRAY400,
                    }}
                    >
                      {view?.title}
                    </Text>
                  </Pressable>
                )
              })}
            </View>
          </div>
        </View>
      </View>
    </View>
  );
};
