import React from 'react';
import {ClipPath, Defs, G, Path, Svg} from 'react-native-svg';

const QuestionCircleSvg = () => {
  return (
    <Svg width="10" height="10" fill="none" viewBox="0 0 10 10">
      <G clipPath="url(#clip0_8048_210653)">
        <Path
          stroke="#6F7A90"
          strokeLinecap="round"
          strokeWidth="0.8"
          d="M4.217 3.698a.781.781 0 1 1 1.179.673c-.198.117-.397.295-.397.525v.52m0 1.042v.209M9.165 5A4.167 4.167 0 1 1 .832 5a4.167 4.167 0 0 1 8.333 0Z"
        ></Path>
      </G>
      <Defs>
        <ClipPath id="clip0_8048_210653">
          <Path fill="#fff" d="M0 0h10v10H0z"></Path>
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default React.memo(QuestionCircleSvg);
