import moment from 'moment';
import { Box, HStack, Text, VStack } from 'native-base';
import React from 'react';
import { StyleSheet } from 'react-native';
import { getMessageDisplayTimeAgo } from '../../../utils/DateUtils';
import { IActionMsgProps } from './interface';
import { TestIdentifiers, testID } from '../../../testUtils';
import { Colors } from '../../../styles/Colors';
import { isWeb } from '../../../utils/platformCheckUtils';

const ActionMsg = (props: IActionMsgProps) => {
  const { msgString, dateStr } = props;
  return (
    <Box
      style={styles.container}
      {...testID(TestIdentifiers.actionMsgHeader)}
    >
      <HStack style={styles.actionMsgBox}>
        <VStack style={styles.vStack}>
          <HStack style={styles.hStack}>
            <Text
              {...testID(TestIdentifiers.actionMsgHeaderString)}
              mr={1}
              size={'xsNormal'}
              color={Colors.FoldPixel.GRAY250}
              style={styles.msgText}
            >
              {msgString}
            </Text>
            <Text
              {...testID(TestIdentifiers.actionMsgHeaderDate)}
              mr={1}
              size={'xsNormal'}
              color={Colors.FoldPixel.GRAY250}
            >
              {getMessageDisplayTimeAgo(moment(dateStr).format())}
            </Text>
          </HStack>
        </VStack>
      </HStack>
    </Box>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  actionMsgBox: {
    color: Colors.Custom.Gray200,
    marginBottom: 4,
    paddingVertical: isWeb() ? 4 : 6,
    paddingHorizontal:  isWeb() ? 8 : 12,
    position: 'relative',
  },
  vStack: {
    justifyContent: 'flex-end',
  },
  hStack: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  msgText: {
    textAlign: 'center',
  },
});

export default React.memo(ActionMsg);
