import {Select, Spin} from 'antd';
import {Text} from 'native-base';
import React, {useEffect, useState} from 'react';
import BaseService from '../../../services/CommonService/BaseService';
import {IIPPhoneSearchList} from './interface';
import { CaretDownOutlined } from '@ant-design/icons';
import { reactStyles } from '../../RightSideContainer/Workflow/workflowStyles';

const IPPhoneSearchList = (props: IIPPhoneSearchList) => {
  const axios = BaseService.getSharedInstance().axios;
  const {phoneNumberId, onSelection} = props;
  const [stateData, setStateData] = useState({
    linkedPhoneList: [],
    loading: false,
    selectedValue: props.value as any,
  });
  const getLinkedPhonesDetails = async () => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const res = await axios
      .get(`/cloud-telephony/sip/phone/${phoneNumberId}/credential`)
      .catch(() => {
        setStateData((prev) => {
          return {
            ...prev,
            loading: false,
          };
        });
      });
    if (res?.data?.length) {
      let ipPhoneList: any = [
        {
          username: 'all',
          password: '',
          ringOnIncoming: true,
          sipUri: 'ALL',
          phoneName: 'All Phones set for incoming',
        },
      ];
      const response = res?.data?.length ? res?.data : [];
      ipPhoneList = [...ipPhoneList, ...response];
      setStateData((prev) => {
        return {
          ...prev,
          linkedPhoneList: ipPhoneList,
          loading: false,
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          linkedPhoneList: [],
          selectedValue: ''
        };
      });
    }
  };
  useEffect(() => {
    getLinkedPhonesDetails();
  }, [phoneNumberId]);
  return (
    <Select
      suffixIcon={
        <CaretDownOutlined
          style={reactStyles.colorGray300}
        />
      }
      className={props?.isShowError && !props?.value ? 'field-error': ''}
      dropdownMatchSelectWidth={3}
      size="large"
      allowClear
      showSearch={false}
      filterOption={(input, option) =>
        (option!.children as unknown as string)
          .toLowerCase()
          .includes(input.toLowerCase())
      }
      value={stateData?.selectedValue ? stateData?.selectedValue : null}
      onChange={(value: any[], data: any) => {
        setStateData(prev=> {
          return {
            ...prev,
            selectedValue: data?.value
          }
        })
        onSelection({key: data.key, value: data?.value});
      }}
      placeholder="Search IP Phones"
      // loading={componentState.loading}
      notFoundContent={stateData.loading && <Spin size="small" />}
      style={{height: '36px'}}
      loading={stateData.loading}
    >
      {stateData?.linkedPhoneList?.map((phone: any) => {
        return (
          <Select.Option key={`${phone.username}`} value={phone.sipUri}>
            {phone.phoneName}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default React.memo(IPPhoneSearchList);
