import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { styles } from '../../../workflowStyles';
import { Divider, Pressable, Text } from "native-base";
import { Colors } from "../../../../../../styles/Colors";

export const NavigateButton = (props: {
  navigateLink: string;
  displayText: string;
  data: string;
  suffixIcon: any;
  newWindow: boolean;
  customStyles?: any;
  onPressCallback?: any;
}) => {
  const { navigateLink, displayText, data, suffixIcon, customStyles, onPressCallback, newWindow } = props;
  const navigate = useNavigate();
  const [onHover, setOnHover] = useState(false);

  return (
    <Pressable
      onHoverIn={() => setOnHover(true)}
      onHoverOut={() => setOnHover(false)}
      style={[
        styles.viewDataPressable,
        onHover && styles.viewDataPressableHover,
        props?.customStyles?.pressableStyle,
      ]}

      onPress={() => {
        if(navigateLink && data && newWindow){
          window.open(`${window.location.origin}/#${navigateLink}`, '_blank');
        }
        else if(navigateLink && data) {
          navigate(navigateLink);
        }
        if (onPressCallback) {
          onPressCallback();
        }
      }}>
      <Text
        style={[
          {
            fontSize: 14,
            lineHeight: 21,
            marginLeft: 0,
            fontWeight: '400',
            color: Colors.Custom.PurpleColor,
          },
          customStyles?.textStyle,
        ]}
      >
        {displayText}
      </Text>
      <Divider style={[styles.divider, customStyles?.dividerStyle]} />
      {suffixIcon}
    </Pressable>
  )
}