import { Dimensions, StyleSheet } from 'react-native';
import { Colors } from '../../styles/Colors';

export const styles = StyleSheet.create({
  flexDirectionRowAlignItemsCenter: {
    flexDirection: 'row', 
    alignItems: 'center'
  },
  marginRight4: {
    marginRight: 4
  },
  fontSize16MarginBottom2: {
    fontSize: 16, 
    marginBottom: 2
  },
  fontSize14MarginBottom4: {
    fontSize: 14, 
    marginBottom: 4
  },
  width100: {
    width: '100%'
  },
  pressableStyle: {
    borderWidth: 1,
    borderColor: '#825AC7',
    borderRadius: 8,
    paddingHorizontal: 4,
    paddingVertical: 8
  },
  color825AC7: {
    color: '#825AC7'
  },
  paddings: {
    paddingHorizontal: 4,
    paddingVertical: 8
  },
  editProfileStyle: {
    color: '#825AC7',
    marginTop: 5,
    backgroundColor: '#FDFAFF',
    paddingVertical: 4,
    width: 100,
    borderWidth: 1,
    borderRadius: 14,
    borderColor: '#EFE0FC',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  colorGray500: {
    color: Colors.Custom.Gray500
  },
  text: {
    color: '#FFA477',
    marginEnd: 1,
    marginStart: 1,
  },
  text0: {
    // color: '#FFA477',
    marginEnd: 1,
    marginStart: 1,
    color: Colors.FoldPixel.GRAY300
  },
  text2: {
    color: Colors.FoldPixel.GRAY250,
    marginEnd: 1,
    marginStart: 1,
  },
  marginLeft4: {
    marginLeft: 4
  },
  text3: {
    color: Colors.Custom.Gray500,
    fontSize: 12,
  },
  text4: {
    color: Colors.Custom.Gray500,
    fontSize: 16,
    marginBottom: 4,
  },
  width20Height20MarginRight5: {
    width: 20,
    height: 20,
    marginRight: 5
  },
  marginLeft16: {
    marginLeft: 16
  },
  marginRight2MarginLeftMinus4: {
    marginRight: 2,
    marginLeft: -4
  },
  positionRelative: {
    position: 'relative'
  },
  flexColumnAlignItemsCenter: {
    flexDirection: 'column',
    alignItems: 'center'
  },
  flex1: {
    flex: 1
  },
  alignItemsCenterJustifyContentCenter: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  width36Height36: {
    width: 36,
    height: 36
  },
  box34PositionAbsoluteRight0: {
    width: 36,
    height: 36,
    position: 'absolute',
    right: 0
  },
  marginTop16: {
    marginTop: 16
  },
  rafScoreContainer: {
    marginLeft: 16,
    marginTop: 4
  }

})

export const reactStyles: Record<string, React.CSSProperties> = {
}