import {DatePicker, Drawer, Timeline, Checkbox, Badge as AntdBadge, notification, Tooltip, Popover} from 'antd';
import {
  Badge,
  Box,
  Button,
  Divider,
  HStack,
  Icon,
  Pressable,
  Text,
  View,
  VStack,
  useMediaQuery,
  Spinner,
} from 'native-base';
import {useIntl} from "react-intl";
import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {Colors} from '../../../../../styles';
import './timelineAntd.scss';
import {ITask} from '../../../../common/CareDashboard/CareDashboardInterfaces';
import {capitalize, cloneDeep, isArray, isEmpty} from 'lodash';
import {
  getAgeValue,
  getDateStrFromFormat,
  getFormattedDate,
} from '../../../../../utils/DateUtils';
import {
  IPropertyDetails,
  PropertyType,
} from '../../../../common/CareDashboard/CareDashboardUtils/CareDashboardUtils';
import TaskDetailCard from '../../../../TaskView/TaskDetailCard';
import {getAccountUUID, getPatientName, getUserFullName, getUserUUID, isActiveContact, isString} from '../../../../../utils/commonUtils';
import {ITopBarData} from '../../../../common/CareDashboard/CareDashboardTopBar/CareDashboardTopBar';
import {BUTTON_TYPE, DATE_FORMATS, getInActiveContactError, GROUP_MEMBER_TYPE, PERSON_TYPES, SMALL_WINDOW_1400} from '../../../../../constants';
import {
  MLOV_CATEGORY,
  TASK_STATUS,
  TASK_STATUS_CODES,
} from '../../../../../constants/MlovConst';
import {
  getMlovId,
  getMlovListByCategory,
  getMlovObjectFromCode,
  getMlovValue,
} from '../../../../../utils/mlovUtils';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import FilterDrawer from './FilterDrawer';
import { useLazyQuery } from '@apollo/client';
import { ParticipantType } from '../../../../common/CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import { IAllUserPoolSelect } from '../../../../common/CareDashboard/CareDashboardWidget/UserAutoComplete';
import TaskPoolQueries, { GetUsersTaskAccess, GetUsersTaskPoolAccess } from '../../../../../services/TaskPool/TaskPoolQueries';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../constants/Configs';
import { PatientInfoBanner } from '../../../../PatientInfoBanner/PatientInfoBanner';
import { getContactTypeValue } from '../MessagingWindow/MessagingUtils';
import { useNavigate } from 'react-router-dom';
import { FoldButton } from '../../../../CommonComponents/FoldButton/FoldButton';
import PlusIcon from '../../../../common/Svg/PlusSvg';
import Feather from 'react-native-vector-icons/Feather';
import AddOrUpdateTask from '../../../../common/AddTask/AddOrUpdateTask';
import { withMiniContactViewHOC } from '../../../../MiniContactViewHOC';
import { TaskPanelType } from '../../../../TaskCard/TaskEnum';
import { ILoginUserData } from '../../../../../Interfaces';
import SortTaskIcon from '../../../../../assets/Icons/SortTask';
import SortByTaskSortType from '../../../../common/CommonFilters/SortByTaskSortType';
import { getEhrConfig } from '../../../../../utils/capabilityUtils';
import { getCurrentPatientDeceasedData} from '../../../../../services/CommonService/AidBoxService';
import { IContact } from '../interfaces';
import PatientTaskManager from '../../../../TaskModule/PatientTaskManager/PatientTaskManager';
import { TaskNavigateContext } from '../../../../TaskModule/TaskInterfaces';
import useGetBatchedAccountUsers from '../../../../CustomHooks/useGetBatchedAccountUsers';
import { IUserPool } from '../../../../common/MemberShip/interface';
import { StyleSheet } from 'react-native';

interface ITaskDrawerProps {
  open: boolean;
  onClose: () => void;
  taskList?: ITask[];
  getBottomView?: any;
  updateTaskStatus?: (task: ITask) => void;
  tasksByStartDate?: {
    taskDetails: {[startDate: string]: Array<ITask>};
    startDates: Array<string>;
  };
  formattedContactData?: any;
  handleTaskRefresh?: (isNewTaskAdded: boolean) => void;
  getTasksByDateRange?: (start?: string, end?: string) => void;
  taskTopBarData: ITopBarData;
}

const TasksDrawer = (props: any) => {
  const {
    open,
    onClose,
    // taskList,
    // topBarData,
    // getBottomView,
    // updateTaskStatus,
    // getTasksByDateRange,
    formattedContactData,
    taskTopBarData,
    contactData,
    contactType,
    showEditProfileOnPatientInfoBanner,
    showDetailProfile,
    handleTaskRefresh,
    panelType,
    restrictPatientOrLeadSelectionTo,
    locationId
  } = props;
  const currentUserUUID = getUserUUID();
  const loggedInUserName = getUserFullName();
  const isFromPatientTaskPanel = props.panelType === TaskPanelType.PATIENT;
  const isFromPatientContextTaskPanel = props.panelType === TaskPanelType.INTERNAL;
  const GLOBAL_PADDING = '24px';
  const intl = useIntl()
  const navigate = useNavigate();
  const [isShowAddTaskDrawer, setIsShowAddTaskDrawer] = useState(false);
  const [showSortPopover, setShowSortPopover] = useState(false);
  const [stateData, setStateData] = useState({
    deceasedDate :''
  });
  const [topBarData, setTopBarData] = useState<ITopBarData>({
    start: getDateStrFromFormat(taskTopBarData.start || new Date(), DATE_FORMATS.DISPLAY_DATE_FORMAT),
    end: getDateStrFromFormat(taskTopBarData.end || new Date(), DATE_FORMATS.DISPLAY_DATE_FORMAT),
    selectedSortUuid: 'endDateTime',
    selectedSortType: 'endDateTime',
    selectedSortValueAscending: true,
    addTask: false,
    hideActions: true,
    panelType: panelType
  });
  const [propertyDetails, setPropertyDetails] = useState<IPropertyDetails>({
    propertyCodes: TASK_STATUS_CODES,
    propertyType: PropertyType.status,
  });
  const [filterCount, setFilterCount] = useState<number | null>(1);
  const [isShowFilterDrawer, setIsShowFilterDrawer] = useState<boolean>(false);
  const [filter, setFilter] = useState({});
  const [isTaskAdded, setIsTaskAdded] = useState(false);

  const mlovData = useContext(CommonDataContext);
  const userData = mlovData.userData || ({} as ILoginUserData);
  const [userTaskPoolAccess, setUserTaskPoolAccess] = useState(false);
  const [userPoolSelect, setAllUserPoolSelect] = useState<IAllUserPoolSelect[]>([]);
  const loggedInUserId = getUserUUID();
  const allStatusUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'all'
  );
  const allStatusValue = getMlovValue(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'all'
  );
  const accessUserTypeId = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'UserPoolType',
    'access_user_task'
  );

  const accessUserTaskPoolTypeId = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'UserPoolType',
    'task_user_pool'
  );

  const [isSmallWindow] = useMediaQuery([
    {maxWidth: SMALL_WINDOW_1400},
  ]);

  const {loading: batchedUserLoading, userList} = useGetBatchedAccountUsers();

  const taskPoolTypeId = getMlovId(mlovData.CARE_STUDIO_MLOV, 'UserPoolType', 'task_user_pool');
  const [userPoolLoading, setUserPoolLoading] = useState(false);
  const [masterUsersPoolList, setMasterUsersPoolList] = useState<IUserPool[]>([]);

  const [getTaskPoolQuery] = useLazyQuery(TaskPoolQueries.GetTaskPool, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      if (data?.getUserPools?.userPools?.length) {
        setMasterUsersPoolList(data?.getUserPools?.userPools);
      }
      setUserPoolLoading(false);
    },
    onError: (error: any) => {
      setUserPoolLoading(false);
    },
  });

  useEffect(() => {
    if (!batchedUserLoading && userList.length) {
      getUserTaskPoolAccess();
    }
  },
  [batchedUserLoading]);

  const [getAllTaskAccessUserTaskPools] = useLazyQuery(GetUsersTaskAccess, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  const [ getUserTaskPoolAccess, getUserTaskPoolAccessAPI ] = useLazyQuery(GetUsersTaskPoolAccess, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    //skip: accountUserQueryLoading,
    variables: {
      // userId: accountUsers.map(
      //   (accountUserItem: any) => accountUserItem.userId
      // ),
      userId: [loggedInUserId],
      userPoolTypeId: accessUserTypeId,
    },
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      // fetching all account users amd filtering login user data
      const filterUserPoolAccessData: any = data.userPoolAccesses?.filter(
        (dataItem: any) => dataItem.userId === loggedInUserId
      );

      if (filterUserPoolAccessData && filterUserPoolAccessData.length) {
        // logged In user have the other pool user access
        const userPoolAccessUsersData =
          filterUserPoolAccessData[0]?.user_pool?.userPoolUsers;
        const loggedInUser = userList.filter(
          (accountItem) => accountItem.uuid === loggedInUserId
        );
        if (loggedInUser && loggedInUser.length) {
          userPoolAccessUsersData.unshift(loggedInUser[0]);
        }

        // flag to manage dropdown whether there are multiple users to handle or not
        if (userPoolAccessUsersData && userPoolAccessUsersData.length > 1) {
          setUserTaskPoolAccess(true);
        } else {
          setUserTaskPoolAccess(false);
        }
        //userDataPostProcessing(userPoolAccessUsersData);
        fetchAllTaskPoolAccessUserPool(userPoolAccessUsersData);
      } else {
        // logged In user do not have the other pool user access
        setUserTaskPoolAccess(false);
        fetchAllTaskPoolAccessUserPool([
          {
            id: '',
            userId: loggedInUserId,
            isDeleted: false,
          },
        ]);
      }
    },
    onError: (error: any) => {

    },
  });

  const fetchAllTaskPoolAccessUserPool = async (users: any[]) => {
    try {
      const userIds = users.map((userItem: any) => userItem?.userId);
      const taskPoolData = await getAllTaskAccessUserTaskPools({
        variables: {
          userId: userIds,
          userPoolTypeId: accessUserTaskPoolTypeId,
        },
      });
      const newAllUserPool: IAllUserPoolSelect[] = [];
      taskPoolData.data?.userPoolUsers?.map((userPoolItem: any) => {
        const userPoolId = userPoolItem?.userPoolId;
        const userPoolName = userPoolItem?.userPool?.name;
        const userPoolUsers = userPoolItem?.userPool?.userPoolUsers;

        const findIndex = newAllUserPool.findIndex(
          (userPoolItem: IAllUserPoolSelect) =>
            userPoolName === userPoolItem.userPoolName
        );
        if (findIndex < 0) {
          newAllUserPool.push({
            userPoolId,
            userPoolName,
            userPoolUserIds: userPoolUsers.map(
              (userPoolUserItem: any) => userPoolUserItem.userId
            ),
          });
        }
      });
      setAllUserPoolSelect(newAllUserPool)

    } catch (error) {
    } finally {
    }
  };

  const GetTitleForDrawer = (): JSX.Element => {
    return (
      <HStack
        p={6}
        px={GLOBAL_PADDING}
        // pb={'40px'}
        alignItems="center"
        justifyContent={'space-between'}
      >
        <Text size={'xslMedium'} color={Colors.FoldPixel.GRAY400}>
        {isFromPatientContextTaskPanel
            ? intl.formatMessage({id: 'internalTask'})
            : intl.formatMessage({id: 'patientTask'})}
        </Text>
        <HStack space={2}>
          {/* <CustomSwitchListBoard restStyles={{marginHorizontal: 0}} /> */}
          <FoldButton
            nativeProps={{
              variant: BUTTON_TYPE.PRIMARY,
              onPress: () => {
                setIsShowAddTaskDrawer(true);
                setIsTaskAdded(false);
              },
              leftIcon: (
                <PlusIcon
                />
              ),
            }}
            customProps={{
              btnText: intl.formatMessage({ id: 'addTask' }),
            }}
          ></FoldButton>

          <FoldButton
            nativeProps={{
              variant: BUTTON_TYPE.PRIMARY,
              onPress: () => {
                onClose()
              },
            }}
            customProps={{
              btnText: intl.formatMessage({ id: 'done' }),
            }}
          ></FoldButton>

        </HStack>
      </HStack>
    );
  };

  const initialTaskEntityTypes =
    getMlovListByCategory(MLOV_CATEGORY.TASK_ENTITY_TYPE) || [];
  const initialPriorityType =
    getMlovListByCategory(MLOV_CATEGORY.TASK_PRIORITY) || [];

  const getSubtitleText = (contactData: any) :JSX.Element => {
    const ageValue = getAgeValue(contactData?.person?.birthDate,stateData.deceasedDate);
    return (
      <HStack>
        <Tooltip title={ageValue} placement="top" color={Colors.Custom.Gray700}>
          <Text>
            {getAgeValue(contactData?.person?.birthDate,stateData.deceasedDate) &&
              getDateStrFromFormat(
                contactData?.person?.birthDate,
                DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT
              )}
          </Text>
        </Tooltip>
        <Text>{contactData?.person?.birthDate && ' | '}</Text>
        <Text>
          {contactData?.contactType?.contactType &&
            getContactTypeValue(contactData.contactType.contactType)}
        </Text>
      </HStack>
    );
  };
  const taskStatusMlovs = getMlovListByCategory(MLOV_CATEGORY.TASK_STATUS);
  const acceptedStatusMlov = getMlovObjectFromCode(TASK_STATUS.ACCEPTED, taskStatusMlovs);
  const missedStatusMlov = getMlovObjectFromCode(TASK_STATUS_CODES.MISSED,taskStatusMlovs);

  useEffect(() => {
    setFilter((prev: any) => ({
      ...prev,
      priorityTypeList: initialPriorityType,
      taskEntityTypeList: initialTaskEntityTypes,
      selectedStatusCodes: [acceptedStatusMlov?.code, missedStatusMlov?.code],
      formData: {
        selectedStatusList: [{...acceptedStatusMlov, value: 'Not Completed'}, {...missedStatusMlov, value: 'missed'}],
        selectedDueDate: allStatusValue,
        selectedDueDateUuid: allStatusUuid,
        start: getDateStrFromFormat(
          new Date(),
          DATE_FORMATS.DISPLAY_DATE_FORMAT
        ),
        end: getDateStrFromFormat(
          new Date(),
          DATE_FORMATS.DISPLAY_DATE_FORMAT
        ),
      }
    }));
    setUserPoolLoading(true);
    getTaskPoolQuery({
      variables: {params: {userPoolTypeId: taskPoolTypeId}},
    });
  }, []);

  const getNewTaskAssignee = useMemo(() => {
    if(isFromPatientContextTaskPanel){
      return {
        value: currentUserUUID,
        label: loggedInUserName,
        key: currentUserUUID,
        type: ParticipantType.staff,
        details: userData,
      }
    } else {
      return {
        value: formattedContactData?.contactUUID,
        label: formattedContactData?.name,
        key: formattedContactData?.contactUUID,
        type: ParticipantType.patient,
        contactData: formattedContactData?.contactData,
        data: formattedContactData?.contactData,
      }
    }
  }, [isFromPatientContextTaskPanel, currentUserUUID, loggedInUserName, formattedContactData]);

  const getNewTaskMember = useMemo(() => {
    return {
      value: formattedContactData?.contactUUID,
      label: formattedContactData?.name,
      key: formattedContactData?.contactUUID,
      type: ParticipantType.patient,
      contactData: formattedContactData?.contactData,
      data: formattedContactData?.contactData,
    };
  }, [formattedContactData]);

  const ehrConfig = getEhrConfig(locationId, '');
  const getPatientDeceasedData = async (contactData: IContact) => {
    const deceasedDatePatient = await getCurrentPatientDeceasedData(contactData);
    setStateData((prev)=> {
      return{
        ...prev,
        deceasedDate: deceasedDatePatient
      }
    });
  };
  useEffect(() => {
    ehrConfig.isAthena &&
    getPatientDeceasedData(contactData)
  }, [contactData?.patient?.patientId]);

  const onNavigate = useCallback((context: TaskNavigateContext) => {
    if (context === TaskNavigateContext.note) {
      if (props?.onNoteRedirect && typeof props?.onNoteRedirect === 'function') {
        props?.onNoteRedirect();
      }
    }
  }, [props?.onNoteRedirect]);


  const handleShowFilterDrawer = useCallback((isClearAll = false) => {
    setIsShowFilterDrawer(false);
    if (isClearAll) {
      setFilter({
        formData: {
          selectedDueDate: allStatusValue,
          selectedDueDateUuid: allStatusUuid,
        }
      });
    }
  }, [allStatusValue, allStatusUuid]);

  const onHandleFilterSubmit = useCallback((filterData: any, totalCount: number | null) => {
    setFilter(filterData);
    setIsShowFilterDrawer(false);
    setFilterCount(totalCount)
  }, []);

  const onPatientProfileIconClick = useCallback(() => {
    const currentStatus = isActiveContact(contactData)
    if (!currentStatus) {
      const message = getInActiveContactError(contactData)
      notification.error({
        message,
        placement: 'top'
      });
      return
    }
    if (contactType?.code == PERSON_TYPES.CUSTOMER || contactData?.contactType?.contactType?.code === PERSON_TYPES.CUSTOMER) {
      props?.openContactInWindowOrOpenContactIdDrawer(contactData.id);
    } else {
      window.open(
        `/#/members/prospect/${contactData.id}`,
        '_blank',
        'noopener,noreferrer'
      );
    }
  }, [contactData, contactType]);

  const onShowDetails = useCallback(() => {
    const currentStatus = isActiveContact(contactData)
    if (!currentStatus) {
      const message = getInActiveContactError(contactData)
      notification.error({
        message,
        placement: 'top'
      });
      return
    }
    if (contactType?.code == PERSON_TYPES.CUSTOMER || contactData?.contactType?.contactType?.code === PERSON_TYPES.CUSTOMER) {
      props?.navigateOrOpenContactIdDrawer?.(contactData.id, {
        state: {contactData: contactData},
      });
    } else {
      navigate(`/members/prospect/${contactData.id}`, {
        state: {contactData: contactData},
      });
    }
  }, [contactData, contactType]);

  const showLoading = batchedUserLoading || userPoolLoading;

  return (
    <Drawer
      headerStyle={{padding: 0}}
      title={GetTitleForDrawer()}
      open={open}
      onClose={onClose}
      width={isSmallWindow ? '85%' : '75%'}
      bodyStyle={{
        paddingLeft: GLOBAL_PADDING,
        paddingRight: GLOBAL_PADDING,
        paddingTop: 0,
        overflow: 'hidden',
      }}
      contentWrapperStyle={{
        borderTopLeftRadius: 16,
        borderBottomLeftRadius: 16,
      }}
    >
        <HStack display={'flex'} flex={1} marginBottom={2}>
          <HStack width={"40%"} flex={0.5} paddingBottom={2}>
            <PatientInfoBanner
              contactActiveStatus={contactData?.isActive}
              bannerType={'bigBanner'}
              isHorizontal={true}
              isSelected={false}
              headerString={
                isString(contactData.name)
                  ? contactData.name
                  : getPatientName(contactData)
              }
              genderCode={
                contactData?.person?.gender?.code ||
                contactData?.person?.sexAtBirth?.code
              }
              userId={contactData?.uuid}
              userType={
                contactData?.patient?.patientUuid
                  ? GROUP_MEMBER_TYPE.PATIENT
                  : GROUP_MEMBER_TYPE.CONTACT
              }
              deceasedDate ={stateData.deceasedDate}
              subtitleElement={getSubtitleText(contactData) || '-'}
              hideConversationTypeImage={true}
              loggedInTime={contactData?.additionalAttributes?.loggedInTime}
              contactData={contactData}
              showDetailProfile={(showDetailProfile !== undefined) ? showDetailProfile : !showEditProfileOnPatientInfoBanner}
              showEditProfile={showEditProfileOnPatientInfoBanner}
              name={isString(contactData.name) ? contactData.name : getPatientName(contactData)}
              onShowDetails={onShowDetails}
              onPatientProfileIconClick={onPatientProfileIconClick}
            />
          </HStack>
          <HStack space={2} mb={5} flex={0.5} alignItems={'center'} marginRight= {filterCount? 10 : '25px'} justifyContent={'flex-end'}>
            <Box alignSelf={'center'} mb={1}>
              <HStack
                space={1}
                paddingRight={filterCount ? 1 : 0}
                paddingTop={2}
                borderRadius={'6px'}
                alignItems={'center'}
              >
                <Popover
                  overlayInnerStyle={{
                    bottom: 3,
                    borderRadius: 16,
                    padding: 0,
                  }}
                  content={
                    <SortByTaskSortType
                      selectedSortType={topBarData?.selectedSortType}
                      returnFilterItems={(actionCode: string, rawData: any) => {
                        setTopBarData((prev) => ({ ...prev, selectedSortType: rawData?.sortType, selectedSortUuid: rawData?.sortTypeUuid, selectedSortValueAscending: !prev.selectedSortValueAscending }));
                        setShowSortPopover(!showSortPopover)
                      }}
                      selectedSortValueAscending={topBarData?.selectedSortValueAscending}
                    />
                  }
                  title=""
                  trigger="click"
                  open={showSortPopover}
                  onOpenChange={(status: boolean) => setShowSortPopover(status)}
                  placement={'bottom'}
                >
                  <Tooltip title="Sort">
                    <Pressable onPress={() => { setShowSortPopover(!showSortPopover) }}>
                      <Icon
                        color={"#825AC7"}
                        as={<SortTaskIcon color={topBarData?.selectedSortType !== 'None' ? '#825AC7' : ''} />}
                        name={'sort'}
                        size="4"
                        onPress={() => setShowSortPopover(!showSortPopover)}
                      />
                    </Pressable>
                  </Tooltip>
                </Popover>
              </HStack>
            </Box>
            <View
              alignSelf={'center'}
              style={styles.divider}
            ></View>
            <Box alignSelf={'center'}>
              <Pressable
                onPress={() => {
                  setIsShowFilterDrawer(true);
                }}
                marginTop={0.5}
              >
              <HStack
                space={1}
                paddingRight={filterCount ? 1 : 0}
                borderRadius={'6px'}
                alignItems={'center'}
              >
                <Tooltip title="Filter">
                  <Icon
                    color={'#667085'}
                    as={Feather}
                    name={'filter'}
                    size="4"
                  />
                </Tooltip>
                {filterCount ? (
                  <Text color={'#825AC7'}>
                    {filterCount ? filterCount : ''}
                  </Text>
                ) : null}
              </HStack>
            </Pressable>
            </Box>
          </HStack>
        </HStack>
      <Divider/>
      <View overflow="auto">
      {showLoading && (
        <View
          style={styles.loadingContainer}
        >
          <Spinner size={'lg'} />
        </View>
      )}
    {!showLoading && (
        <PatientTaskManager
          personData={formattedContactData}
          filters={filter}
          userList={userList}
          masterUsersPoolList={masterUsersPoolList || []}
          topBarData={topBarData}
          restrictPatientOrLeadSelectionTo={restrictPatientOrLeadSelectionTo}
          onNavigate={onNavigate}
        />
    )}
      </View>
    {isShowAddTaskDrawer && (
      <AddOrUpdateTask
        saveBtnText={'Save'}
        successMessage={'taskAddedMsg'}
        member={getNewTaskMember as any}
        assignee={getNewTaskAssignee as any}
        isVisible={isShowAddTaskDrawer}
        onComplete={(data) => {
          setIsShowAddTaskDrawer(false);
          if (isFromPatientContextTaskPanel ? (data?.addOrUpdateTask?.contactId === formattedContactData?.contactUUID) : (data?.addOrUpdateTask?.assigneeId === formattedContactData?.contactUUID)) {
            setIsTaskAdded(true);
          }
        }}
        onCancel={() => {
          setIsShowAddTaskDrawer(false);
          setIsTaskAdded(false);
        }}
        restrictPatientOrLeadSelectionTo={restrictPatientOrLeadSelectionTo}
        onNoteRedirect={() => {
          if (props?.onNoteRedirect && typeof props?.onNoteRedirect === 'function') {
            props?.onNoteRedirect();
          }
        }}
      />
      )}
      {isShowFilterDrawer &&
        <FilterDrawer
          isShowFilterDrawer={isShowFilterDrawer}
          handleShowFilterDrawer={handleShowFilterDrawer}
          handleSubmit={onHandleFilterSubmit}
          propertyDetails={propertyDetails}
          filterData={cloneDeep(filter)}
        />
      }
    </Drawer>
  );
};

const styles = StyleSheet.create({
  divider: {
    width: 1,
    height: 20,
    backgroundColor: Colors.Custom.Gray200,
    marginHorizontal: 8,
  },
  timelineTaskItemBox: {
    flex: 1,
    flexShrink: 0,
    flexBasis: 'auto',
  },
  loadingContainer: {
    margin: 16,
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
});

export default withMiniContactViewHOC(TasksDrawer);
