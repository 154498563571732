import {useMutation} from '@apollo/client';
import {DatePicker, Drawer, Select} from 'antd';
import {
  Box,
  Button,
  HStack,
  Icon,
  Input,
  Spacer,
  Text,
  TextArea,
  useToast,
  View,
  VStack,
} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {
  BUTTON_TYPE,
  DATE_FORMATS,
  MLOV_CATEGORY,
} from '../../../../../../constants';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../../constants/Configs';
import {
  APPOINTMENT_PARTICIPANT_STATUS_CODES,
  APPOINTMENT_STATUS_CODES,
  APPOINTMENT_TYPE_CATEGORY_CODES,
  LOCATION_TYPE_CODES,
} from '../../../../../../constants/MlovConst';
import {IMlovByCategory} from '../../../../../../Interfaces';
import {AppointmentQueries} from '../../../../../../services';
import {Colors} from '../../../../../../styles';
import {
  addTimeToDate,
  getDateStrFromFormat,
  getDiffInMinutes,
  getMomentObj,
  isPastDay,
} from '../../../../../../utils/DateUtils';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
  getMlovValueFromCode,
} from '../../../../../../utils/mlovUtils';
import {FoldButton} from '../../../../../CommonComponents/FoldButton/FoldButton';
import {DisplayText} from '../../../../DisplayText/DisplayText';
import ModalActionBtn from '../../../../ModalActionBtn/ModalActionBtn';
import { ModalActionTitle } from '../../../../ModalActionTitle/ModalActionTitle';
import {IAppointmentDetail} from '../../../CalendarWidgetInterfaces';
import {AppointmentAvailableSlots} from './AppointmentAvailableSlots';
import {IParticipantData, ISlot} from './AppointmentBookingIntefaces';
import ProposedTimeBanner from './ProposedTimeBanner';
import { getConfigDataFromCode, isVirtualLocationDisabled, isVirtualLocationEnabledInAvailability } from '../../../../../../utils/commonUtils';
import { CONFIG_CODES } from '../../../../../../constants/AccountConfigConst';
import { IDefaultFormTheme } from '../../../../../RightSideContainer/Forms/interfaces';
import { isExpiredDateTime, isMemberLimitExceeded } from '../../../../../PublicPages/AppointmentRSVPWidget/AppointmentRsvpUtils';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { ToastType, showToast } from '../../../../../../utils/commonViewUtils';
import { ParticipantType } from '../../../ParticipantAutoComplete/ParticipantEnum';
import {isVirtualAppointmentType} from './AppointmentBookingHelper';
import {IUserSettingsByCode} from '../../../../../../services/UserSettings/interfaces';

interface IProps {
  appointmentDetails: IAppointmentDetail;
  participant: IParticipantData;
  loggedInParticipantId: string;
  careStudioMlovData: IMlovByCategory;
  headers?: {[index: string]: string};
  showProposeTimeBanner?: boolean;
  isGroupAppointmentWidget: boolean;
  onSendResponseSuccess: (code: string) => void;
  isFromRSVPWidget?: boolean;
  currentParticipantType?: string;
  currentParticipantContactId?: string;
  isReccurentAppointment?: boolean;
  accountSettings: IUserSettingsByCode;

}

interface IParticipantStatusOption {
  value: string;
  label: string;
  key: string;
}

interface IAppointmentRSVPState {
  selectedParticipantStatus?: IParticipantStatusOption[];
  declinedReason?: string;
  selectedProposedDate: string;
  selectedProposedTimeSlot?: ISlot;
  availableSlots?: ISlot[];
  isSlotLoading?: boolean;
  showFormErrors?: boolean;
  isLoading?: boolean;
  showDeclineReasonConfirmation: boolean;
  updateParticipantStatusErrorCode?: string;
}

export function AppointmentRSVP(props: IProps) {
  const defaultState: IAppointmentRSVPState = {
    selectedParticipantStatus: [],
    selectedProposedDate: props.appointmentDetails.startDateTime,
    showDeclineReasonConfirmation: false
  };

  const [appointmentRSVPState, setAppointmentRSVPState] =
    useState<IAppointmentRSVPState>(defaultState);

  const intl = useIntl();
  const toast = useToast();

  const defaultThemeConfig = getConfigDataFromCode(CONFIG_CODES.DEFAULT_THEME);
  let configJson = undefined;
  let formThemeConfig: IDefaultFormTheme | undefined = {} as IDefaultFormTheme;

  if (
    defaultThemeConfig?.accountConfigurations &&
    defaultThemeConfig?.accountConfigurations?.length
  ) {
    configJson = JSON.parse(
      defaultThemeConfig?.accountConfigurations[0]?.value
    );
    formThemeConfig = configJson?.form || undefined;
  } else if (defaultThemeConfig?.defaultValue) {
    configJson = JSON.parse(defaultThemeConfig?.defaultValue);
    formThemeConfig = configJson?.form || undefined;
  }
  // const primaryColor = formThemeConfig?.topBar?.backgroundColor || Colors.Custom.PrimaryBtnColor;
  const primaryColor = Colors.Custom.mainPrimaryPurple;

  function showErrorPopup() {
    showToast(toast, intl.formatMessage({id: 'apiErrorMsg'}), ToastType.error);
  }

  const headers: {[index: string]: string} = {};

  if (props.headers?.accountId) {
    headers['account-uuid'] = props.headers.accountId;
  }

  if (
    props?.isFromRSVPWidget &&
    (props.currentParticipantType === ParticipantType.patient ||
      props.currentParticipantType === ParticipantType.leads)
  ) {
    headers['x-user-type'] = 'CONTACT';
    headers['User-Uuid'] = props.currentParticipantContactId || '';
  }
  const [updateParticipantStatus] = useMutation(
    AppointmentQueries.UPDATE_PARTICIPANT_STATUS,
    {
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
        headers: {...headers},
      },
    }
  );

  const proposedTime = props.appointmentDetails.proposedTime;
  const declinedReason = props.appointmentDetails.declinedReason;

  const participantName =
    props.participant?.contact?.name || props.participant.user?.name;
  const participantId = getParticipantUniqueId(props.participant);

  const appointmentStatusList =
    getMlovListFromCategory(
      props.careStudioMlovData,
      MLOV_CATEGORY.APPOINTMENT_STATUS
    ) || [];

  const participantStatusList =
    getMlovListFromCategory(
      props.careStudioMlovData,
      MLOV_CATEGORY.APPOINTMENT_PARTICIPANT_STATUS
    ) || [];

  const APPOINTMENT_STATUS_IDS = {
    pending: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.PENDING
    ),
    proposed: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.PROPOSED
    ),
    booked: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.BOOKED
    ),
    decline: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.DECLINED
    ),
    checkedIn: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.CHECKED_IN
    ),
    checkedOut: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.CHECKED_OUT
    ),
    cancelled: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.CANCELLED
    ),
    rescheduled: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.RESCHEDULED
    ),
  };

  const PARTICIPANT_STATUS_IDS = {
    accepted: getMlovIdFromCode(
      participantStatusList,
      APPOINTMENT_PARTICIPANT_STATUS_CODES.ACCEPTED
    ),
    declined: getMlovIdFromCode(
      participantStatusList,
      APPOINTMENT_PARTICIPANT_STATUS_CODES.DECLINED
    ),
    needsAction: getMlovIdFromCode(
      participantStatusList,
      APPOINTMENT_PARTICIPANT_STATUS_CODES.NEEDS_ACTION
    ),
  };

  const participantStatusOptions: IParticipantStatusOption[] = [
    {
      value: APPOINTMENT_PARTICIPANT_STATUS_CODES.ACCEPTED,
      key: PARTICIPANT_STATUS_IDS.accepted,
      label: props?.isReccurentAppointment
        ? intl.formatMessage({id: 'acceptAll'})
        : getMlovValueFromCode(
            participantStatusList,
            APPOINTMENT_PARTICIPANT_STATUS_CODES.ACCEPTED
          ),
    },
    {
      value: APPOINTMENT_PARTICIPANT_STATUS_CODES.DECLINED,
      key: PARTICIPANT_STATUS_IDS.declined,
      label: props?.isReccurentAppointment
        ? intl.formatMessage({id: 'declineAll'})
        : getMlovValueFromCode(
            participantStatusList,
            APPOINTMENT_PARTICIPANT_STATUS_CODES.DECLINED
          ),
    },
    {
      value: APPOINTMENT_PARTICIPANT_STATUS_CODES.NEEDS_ACTION,
      key: PARTICIPANT_STATUS_IDS.needsAction,
      label: intl.formatMessage({id: 'proposeNewSlot'}),
    },
  ];

  const providerUser = (props.appointmentDetails?.participants || []).find(
    (participant) => {
      if (participant?.user?.uuid) return true;
    }
  );

  const providerUserUuid = providerUser?.user?.uuid || undefined;

  const appointmentStatusId = props.appointmentDetails.statusId;

  const isAppointmentStatusBooked =
    appointmentStatusId === APPOINTMENT_STATUS_IDS.booked;

  const isAppointmentStatusPending =
    appointmentStatusId === APPOINTMENT_STATUS_IDS.pending;

  const isAppointmentStatusDecline =
    appointmentStatusId === APPOINTMENT_STATUS_IDS.decline;

  const isAppointmentStatusProposed =
    appointmentStatusId === APPOINTMENT_STATUS_IDS.proposed;

  const participantStatusId = props.participant.statusId;

  const isParticipantStatusPending = !participantStatusId;

  const isParticipantStatusDeclined =
    participantStatusId === PARTICIPANT_STATUS_IDS.declined;

  const isParticipantStatusAccepted = participantStatusId === PARTICIPANT_STATUS_IDS.accepted;

  const selectedParticipantStatusId = appointmentRSVPState
    .selectedParticipantStatus?.length
    ? appointmentRSVPState.selectedParticipantStatus[0].key
    : undefined;

  const isSelectedParticipantStatusDeclined =
    selectedParticipantStatusId === PARTICIPANT_STATUS_IDS.declined;

  const isSelectedParticipantStatusProposed =
    selectedParticipantStatusId === PARTICIPANT_STATUS_IDS.needsAction;

  let isInitiator = false;
  let isParticipantLoggedInUser = false;

  const isParticipantInitiator = props.participant.isInitiator || false;

  if (
    props.participant.id &&
    props.participant.id === props.loggedInParticipantId
  ) {
    isParticipantLoggedInUser = true;
    isInitiator = isParticipantInitiator;
  }

  const isExpiredAppointments = isExpiredDateTime(props.appointmentDetails?.reference?.expiryDateTime ? new Date(props.appointmentDetails?.reference?.expiryDateTime) : undefined);
  const isMemberLimitExceed = isMemberLimitExceeded(props.appointmentDetails, PARTICIPANT_STATUS_IDS.accepted);
  const isGroupAppointment = props.appointmentDetails?.appointmentType?.category?.code === APPOINTMENT_TYPE_CATEGORY_CODES.GROUP_SESSION;
  const isAppointmentInPast = new Date(props.appointmentDetails?.endDateTime).getTime() < new Date()?.getTime();

  const canParticipantTakeAction =
    !isAppointmentInPast
    && isParticipantLoggedInUser
    && (
      (isAppointmentStatusPending && isParticipantStatusPending)
      || (isGroupAppointment && isParticipantStatusPending && isAppointmentStatusBooked)
      || (isAppointmentStatusProposed && isInitiator)
    )
    && (!isExpiredAppointments && !isMemberLimitExceed);

  const canShowRSVPComponent =
    !appointmentRSVPState.updateParticipantStatusErrorCode
    && (
      (isGroupAppointment && isAppointmentStatusBooked) ||
      isAppointmentStatusPending ||
      isAppointmentStatusProposed ||
      isAppointmentStatusDecline
    );

  const canShowRSVPSelect = canParticipantTakeAction;

  const canShowProposedDateTimeSelect =
    canParticipantTakeAction &&
    isSelectedParticipantStatusProposed &&
    providerUserUuid &&
    !isGroupAppointment;

  const canShowDeclinedReasonInput =
    canParticipantTakeAction && isSelectedParticipantStatusDeclined;

  const canShowProposedTimeText =
    isAppointmentStatusProposed &&
    isParticipantLoggedInUser &&
    isInitiator &&
    proposedTime &&
    !isGroupAppointment;

  const canShowDeclinedReasonText = isAppointmentStatusDecline && isParticipantStatusDeclined;

  const canShowAppointmentInPastErrorText = isAppointmentInPast;

  const canShowAwaitingApprovalFrom =
    !isParticipantLoggedInUser &&
    !isAppointmentInPast &&
    participantName &&
    ((isAppointmentStatusPending && isParticipantStatusPending) ||
      (isAppointmentStatusProposed && isParticipantInitiator));

  const hideAcceptDeclineReasonText = isMemberLimitExceed
        ? `Appointment registrations are full. Please contact ${'clinic'} support staff to join this meeting`
        : isExpiredAppointments
        ? `Appointment link is expired. Please contact ${'clinic'} support staff to join this meeting`
        : `Please contact ${'clinic'} support staff to join this meeting`;

  const canShowParticipantAlreadyResponded =
    !canShowRSVPSelect &&
    isParticipantLoggedInUser &&
    isGroupAppointment &&
    !isAppointmentInPast &&
    !isExpiredAppointments &&
    isAppointmentStatusBooked &&
    (isParticipantStatusDeclined || isParticipantStatusAccepted)

  const appointmentDuration = getDiffInMinutes(
    props.appointmentDetails.startDateTime,
    props.appointmentDetails.endDateTime
  );

  let rsvpOptionList = canParticipantTakeAction ? participantStatusOptions : [];

  if (isGroupAppointment || (isAppointmentStatusProposed && isParticipantLoggedInUser && isInitiator) || props?.isReccurentAppointment) {
    rsvpOptionList = rsvpOptionList.filter(
      (item) => item.value !== APPOINTMENT_PARTICIPANT_STATUS_CODES.NEEDS_ACTION
    );
  }

  function getParticipantUniqueId(participant: IParticipantData) {
    if (!participant) return undefined;
    if (participant.id) return participant.id;
    if (participant.user) return participant.user.uuid || undefined;
    if (participant.contact) return participant.contact.uuid || undefined;
    return undefined;
  }

  function handleOnSendRSVPResponse(action?: string, isApplyForSeries?: boolean) {
    let selectedStatus = appointmentRSVPState?.selectedParticipantStatus?.[0]?.value
    if (action) {
      selectedStatus = action
    }
    setAppointmentRSVPState((prevState) => ({
      ...prevState,
      showFormErrors: true,
    }));
    const appointmentDetails = props.appointmentDetails;

    if (!appointmentDetails?.participants?.length) {
      showErrorPopup();
      return;
    }

    const apiInputData: any = {
      id: appointmentDetails.id,
      isApplyForSeries: isApplyForSeries,
    };

    let isValid = true;
    apiInputData.participants = appointmentDetails.participants.filter((participant) => {
      const uniqueId = getParticipantUniqueId(participant)
      return uniqueId === props.loggedInParticipantId;
    }).map(
      (participant) => {
        if (
          !participantId ||
          participantId !== getParticipantUniqueId(participant)
        ) {
          return participant;
        }

        if (!canParticipantTakeAction) {
          return participant;
        }

        const newParticipant = JSON.parse(
          JSON.stringify(participant)
        ) as IParticipantData;

        if (action) {
          const selectedAction = participantStatusOptions.find(
            (item) => item.value === action
          );
          newParticipant.statusId = selectedAction?.key;
        } else {
          if (!appointmentRSVPState?.selectedParticipantStatus?.length) {
            isValid = false;
            return newParticipant;
          }
          newParticipant.statusId =
            appointmentRSVPState.selectedParticipantStatus[0].key;
        }

        newParticipant.isStatusUpdated = true;

        switch (selectedStatus) {
          case APPOINTMENT_PARTICIPANT_STATUS_CODES.ACCEPTED:
            if (
              isParticipantLoggedInUser &&
              isInitiator &&
              isAppointmentStatusProposed &&
              appointmentDetails?.proposedTime
            ) {
              apiInputData.startDateTime = appointmentDetails.proposedTime;
              apiInputData.endDateTime = addTimeToDate(
                appointmentDetails.proposedTime,
                appointmentDuration,
                'MINUTE'
              ).toISOString();
            } else if (
              isParticipantLoggedInUser &&
              !isInitiator &&
              (isAppointmentStatusProposed || isAppointmentStatusPending)
            ) {
              apiInputData.statusId = APPOINTMENT_STATUS_IDS.booked;
              // apiInputData.startDateTime = props.appointmentDetails.startDateTime;
            }
            break;
          case APPOINTMENT_PARTICIPANT_STATUS_CODES.DECLINED:
            if (appointmentRSVPState.declinedReason)
              apiInputData.declinedReason = appointmentRSVPState.declinedReason;
            break;
          case APPOINTMENT_PARTICIPANT_STATUS_CODES.NEEDS_ACTION:
            if (
              !appointmentRSVPState.selectedProposedTimeSlot ||
              !appointmentRSVPState.selectedProposedDate
            ) {
              isValid = false;
              return newParticipant;
            }
            apiInputData.proposedTime =
              appointmentRSVPState.selectedProposedTimeSlot.startDateTime;
            break;
        }

        return {
          ...newParticipant,
          contactId: newParticipant.contact?.uuid,
          ...(newParticipant?.user?.uuid && { userId: newParticipant?.user?.uuid})
        };
      }
    );

    if (!isValid) {
      return;
    }

    apiInputData.participants.forEach((item: any) => {
      delete item.reference;
    });

    setAppointmentRSVPState((prevState) => ({...prevState, isLoading: true}));

    updateParticipantStatus({
      variables: {
        data: apiInputData,
      },
    })
      .then(() => {
        setAppointmentRSVPState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
        props.onSendResponseSuccess(selectedStatus as string);
      })
      .catch((error) => {
        const errorCode = error?.networkError?.result?.message?.extensions?.errorCode;
        setAppointmentRSVPState((prevState) => ({
          ...prevState,
          updateParticipantStatusErrorCode: errorCode,
          isLoading: false,
        }));
        if (!errorCode) {
          showErrorPopup();
        }
      });
  }

  const isVirtualVisit = isVirtualAppointmentType(
    props?.appointmentDetails?.appointmentType as any,
    props?.appointmentDetails?.locationType as any
  );
  const disAllowVirtualLocation = isVirtualLocationDisabled(
    props?.accountSettings,
    []
  );

  const isVirtualAppointment = () => {
   const disallowToScheduleForOtherLocation =
     props?.accountSettings?.['disallow_to_schedule_for_other_location']
       ?.value === 'True' || false;

   const isVirtualLocation =
     (isVirtualVisit &&
       isVirtualLocationEnabledInAvailability(props?.accountSettings)) ||
     (isVirtualVisit &&
       !disAllowVirtualLocation &&
       !disallowToScheduleForOtherLocation);
   return isVirtualLocation;  
  };

  const getAppointmentLocation = () => {
    const locationIds = [];
    if (props.appointmentDetails?.accountLocationId) {
      locationIds.push(props.appointmentDetails?.accountLocationId);
    }
    return locationIds;
  };

  if (!isGroupAppointment) {
    return (
      <>
        {canShowRSVPComponent && !props?.showProposeTimeBanner && (
          <VStack space={1}>
            {canParticipantTakeAction && (
              <HStack space={5}>
                <VStack space={1}>
                  {canShowRSVPSelect && (
                    <View flex={1}>
                      <Select
                        size="large"
                        allowClear={false}
                        showSearch={false}
                        labelInValue={true}
                        filterOption={false}
                        placeholder={'Select response'}
                        value={appointmentRSVPState.selectedParticipantStatus}
                        onChange={(value: any) => {
                          setAppointmentRSVPState((prevState) => ({
                            ...prevState,
                            selectedParticipantStatus: [value],
                          }));
                        }}
                        style={{height: '40px', width: '180px'}}
                      >
                        {rsvpOptionList.map((item) => (
                          <Select.Option key={item.key} value={item.value}>
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </View>
                  )}

                  {!!(
                    canShowRSVPSelect &&
                    appointmentRSVPState.showFormErrors &&
                    !appointmentRSVPState.selectedParticipantStatus?.length
                  ) && (
                    <View width="full">
                      <Text color="error.500">Please select response</Text>
                    </View>
                  )}

                  {canShowProposedDateTimeSelect && (
                    <View marginTop={2}>
                      <DatePicker
                        style={{height: '40px'}}
                        allowClear={false}
                        defaultValue={getMomentObj(
                          new Date(appointmentRSVPState.selectedProposedDate)
                        )}
                        format={DATE_FORMATS.DISPLAY_DATE_FORMAT}
                        disabledDate={(current: any) =>
                          current && isPastDay(current)
                        }
                        onChange={(date: any) => {
                          setAppointmentRSVPState((prevState) => ({
                            ...prevState,
                            selectedProposedDate: date,
                            showFormErrors: false
                          }));
                        }}
                      />
                    </View>
                  )}
                </VStack>
                <FoldButton
                  nativeProps={{
                    isLoading: appointmentRSVPState.isLoading,
                    variant: BUTTON_TYPE.PRIMARY,
                    onPress: () => {
                      const selectedResponse =
                        appointmentRSVPState.selectedParticipantStatus?.[0]
                          ?.value;
                      if (
                        selectedResponse ===
                        APPOINTMENT_PARTICIPANT_STATUS_CODES.DECLINED
                      ) {
                        setAppointmentRSVPState((prev) => ({
                          ...prev,
                          showDeclineReasonConfirmation: true,
                        }));
                      } else {
                        handleOnSendRSVPResponse(selectedResponse, props.isReccurentAppointment);
                      }
                    },
                    alignSelf: 'flex-start',
                  }}
                  customProps={{
                    btnText: (
                      <DisplayText
                        textLocalId={'Send Response'}
                        size={'smBold'}
                        extraStyles={{
                          color: Colors.Custom.mainPrimaryPurple,
                        }}
                      />
                    ),
                    withRightBorder: false,
                  }}
                ></FoldButton>
              </HStack>
            )}
            {canShowProposedTimeText && (
              <Text>
                {'Proposed Time: ' +
                  getDateStrFromFormat(
                    proposedTime,
                    `${DATE_FORMATS.DISPLAY_DATE_FORMAT} ${DATE_FORMATS.DISPLAY_TIME_FORMAT}`
                  )}
              </Text>
            )}
            {canShowDeclinedReasonText && (
              <Text>{'Decline Reason: ' + (declinedReason || '-')}</Text>
            )}
            {canShowAppointmentInPastErrorText && (
              <Text>{'This link is no longer valid or expired.'}</Text>
            )}
            {canShowAwaitingApprovalFrom && (
              <Text>{`Awaiting response from ${participantName}`}</Text>
            )}
            {/* {canShowDeclinedReasonInput && (
              <Input
                placeholder={intl.formatMessage({id: 'optionalDeclinedReason'})}
                value={appointmentRSVPState.declinedReason}
                onChangeText={(value: string) => {
                  setAppointmentRSVPState((prevState) => ({
                    ...prevState,
                    declinedReason: value || value.trim(),
                  }));
                }}
                marginTop={2}
              />
            )} */}
            {canShowProposedDateTimeSelect && (
              <View marginTop={2}>
                <AppointmentAvailableSlots
                  headers={headers}
                  rescheduleAppointmentId={props.appointmentDetails.id}
                  appointmentTypeId={props?.appointmentDetails?.appointmentType?.id}
                  isVirtualLocation={isVirtualAppointment()}
                  locationIds={getAppointmentLocation()}
                  date={appointmentRSVPState.selectedProposedDate}
                  duration={appointmentDuration}
                  userId={providerUserUuid as string}
                  showFormErrors={appointmentRSVPState.showFormErrors}
                  onSlotSelection={(slot: ISlot | undefined) => {
                    setAppointmentRSVPState((prevState) => ({
                      ...prevState,
                      selectedProposedTimeSlot: slot,
                    }));
                  }}
                />
              </View>
            )}
          </VStack>
        )}
        {props?.showProposeTimeBanner && (
          <ProposedTimeBanner
            appointmentDetails={props.appointmentDetails}
            isLoading={appointmentRSVPState?.isLoading || false}
            handleAccept={() =>
              handleOnSendRSVPResponse(
                APPOINTMENT_PARTICIPANT_STATUS_CODES.ACCEPTED
              )
            }
            handleDecline={() =>
              setAppointmentRSVPState((prev) => ({
                ...prev,
                showDeclineReasonConfirmation: true,
                selectedParticipantStatus: participantStatusOptions.filter(
                  (item) =>
                    item.value === APPOINTMENT_PARTICIPANT_STATUS_CODES.DECLINED
                ),
              }))
            }
          />
        )}
        <Drawer
          title={<ModalActionTitle title="declineAppointment" />}
          destroyOnClose
          placement="right"
          onClose={() => {
            setAppointmentRSVPState((prev) => ({
              ...prev,
              showDeclineReasonConfirmation: false,
            }));
          }}
          visible={
            canShowDeclinedReasonInput &&
            appointmentRSVPState.showDeclineReasonConfirmation
          }
          closable={false}
          width={450}
        >
          <VStack space={6}>
            <VStack marginBottom={3} space={2}>
              <View>
                <Text>{intl.formatMessage({id: 'declineReasonMessage'})}</Text>
              </View>
              <View>
                <TextArea
                  numberOfLines={3}
                  placeholder={intl.formatMessage({id: 'optionalDeclinedReason'})}
                  value={appointmentRSVPState.declinedReason}
                  onChangeText={(value: string) => {
                    setAppointmentRSVPState((prevState: any) => ({
                      ...prevState,
                      declinedReason: value || value.trim(),
                    }));
                  }}
                  marginTop={2}
                />
              </View>
            </VStack>
            <HStack space={2}>
              <Spacer />
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.SECONDARY,
                  onPress: () => {
                    setAppointmentRSVPState((prev) => ({
                      ...prev,
                      showDeclineReasonConfirmation: false,
                    }));
                  },
                }}
                customProps={{
                  btnText: (
                    <DisplayText
                      textLocalId={'cancel'}
                      size={'smBold'}
                      extraStyles={{
                        color: Colors.Custom.mainSecondaryBrown,
                      }}
                    />
                  ),
                  withRightBorder: false,
                }}
              ></FoldButton>
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.SECONDARY,
                  onPress: () => {
                    handleOnSendRSVPResponse(
                      APPOINTMENT_PARTICIPANT_STATUS_CODES.DECLINED,
                      props?.isReccurentAppointment
                    );
                    setAppointmentRSVPState((prev) => ({
                      ...prev,
                      showDeclineReasonConfirmation: false,
                    }));
                  },
                }}
                customProps={{
                  btnText: (
                    <DisplayText
                      textLocalId={'decline'}
                      size={'smBold'}
                      extraStyles={{
                        color: Colors.Custom.mainSecondaryBrown,
                      }}
                    />
                  ),
                  withRightBorder: false,
                }}
              ></FoldButton>
            </HStack>
          </VStack>
        </Drawer>
      </>
    )
  }
  return (
    <View flex={1}>
      {canShowRSVPComponent && !props?.showProposeTimeBanner && (
        <VStack space={1}>
          {canParticipantTakeAction && (
            <VStack space={1}>
              {canShowRSVPSelect && (
                <VStack>
                  <View flex={1}>
                    <Text mt={5} fontSize={18} fontWeight={700} color={'black'}>
                      {'Please RSVP to confirm your attendance'}
                    </Text>
                    <Button.Group
                      space={2}
                      mt={3}
                      justifyContent={'flex-start'}
                      width={'100%'}
                    >
                      <ModalActionBtn
                        btnText={props?.isReccurentAppointment ? "Accept all" : "Accept"}
                        loading={appointmentRSVPState?.isLoading && appointmentRSVPState.selectedParticipantStatus?.[0].value === APPOINTMENT_PARTICIPANT_STATUS_CODES.ACCEPTED}
                        btnStype={BUTTON_TYPE.PRIMARY}
                        customStyle={{
                          backgroundColor: primaryColor,
                          width: 100,
                          marginRight: 5,
                        }}
                        textColor={'white'}
                        onClickAction={() => {
                          setAppointmentRSVPState((prev) => ({
                            ...prev,
                            selectedParticipantStatus:
                              participantStatusOptions.filter(
                                (item) =>
                                  item.value ===
                                  APPOINTMENT_PARTICIPANT_STATUS_CODES.ACCEPTED
                              ),
                          }));
                          setTimeout(() => {
                            handleOnSendRSVPResponse(
                              APPOINTMENT_PARTICIPANT_STATUS_CODES.ACCEPTED,
                              props?.isReccurentAppointment
                            );
                          }, 10);
                        }}
                      />
                      <ModalActionBtn
                        btnText={props?.isReccurentAppointment ? "Decline all" : "Decline"}
                        loading={appointmentRSVPState?.isLoading && appointmentRSVPState.selectedParticipantStatus?.[0].value === APPOINTMENT_PARTICIPANT_STATUS_CODES.DECLINED}
                        btnStype={BUTTON_TYPE.SECONDARY}
                        customStyle={{
                          borderColor: primaryColor,
                          width: 100,
                          marginLeft: 5,
                        }}
                        textColor={primaryColor}
                        onClickAction={() => {
                          setAppointmentRSVPState((prev) => ({
                            ...prev,
                            selectedParticipantStatus:
                              participantStatusOptions.filter(
                                (item) =>
                                  item.value ===
                                  APPOINTMENT_PARTICIPANT_STATUS_CODES.DECLINED
                              ),
                          }));
                          setTimeout(() => {
                            handleOnSendRSVPResponse(
                              APPOINTMENT_PARTICIPANT_STATUS_CODES.DECLINED,
                              props?.isReccurentAppointment
                            );
                          }, 10);
                        }}
                      />
                    </Button.Group>
                  </View>
                </VStack>
              )}

              {!!(
                canShowRSVPSelect &&
                appointmentRSVPState.showFormErrors &&
                !appointmentRSVPState.selectedParticipantStatus?.length
              ) && (
                <View width="full">
                  <Text color="error.500">Please select response</Text>
                </View>
              )}
            </VStack>
          )}
          {!canShowAwaitingApprovalFrom && (isExpiredAppointments || isMemberLimitExceed) && (
            <VStack alignItems="center" justifyContent="center" space={4}>
              <HStack space={8} alignItems="center" justifyContent="center">
                <View marginTop={1}>
                  <Icon
                    as={AntIcon}
                    name="infocirlceo"
                    size="12"
                    color="success.500"
                  />
                </View>
                <View size="xsMedium" flex={1}>
                  <Text>{hideAcceptDeclineReasonText}</Text>
                </View>
              </HStack>
            </VStack>
          )}
        </VStack>
      )}
      {
        canShowParticipantAlreadyResponded &&
        <VStack alignItems="center" justifyContent="center" space={4}>
          <HStack space={8} alignItems="center" justifyContent="center">
            <View marginTop={1}>
              <Icon
                as={AntIcon}
                name="checkcircleo"
                size="12"
                color="success.500"
              />
            </View>
            <View size="xsMedium" flex={1}>
              <Text>You have already submitted the response</Text>
            </View>
          </HStack>
        </VStack>
      }
      {
        appointmentRSVPState.updateParticipantStatusErrorCode && (
          <VStack alignItems="center" justifyContent="center" space={4}>
            <HStack space={8} alignItems="center" justifyContent="center">
              <View marginTop={1}>
                <Icon
                  as={AntIcon}
                  name="closecircleo"
                  size="12"
                  color="error.500"
                />
              </View>
              <View size="xsMedium" flex={1}>
                {
                  appointmentRSVPState.updateParticipantStatusErrorCode === 'APPT_MEMBER_LIMIT_REACHED' &&
                  <Text>{'Appointment registrations are full. Please contact clinic support staff to join this meeting'}</Text>
                }
                {
                  appointmentRSVPState.updateParticipantStatusErrorCode === 'APPT_EXP_DATE_TIME_REACHED' &&
                  <Text>{'Appointment link is expired. Please contact clinic support staff to join this meeting'}</Text>
                }
              </View>
            </HStack>
          </VStack>
        )
      }
    </View>
  );
}
