import {useLazyQuery} from '@apollo/client';
import {Pressable, Tooltip, View} from 'native-base';
import React, {useCallback, useEffect, useState} from 'react';
import {Platform} from 'react-native';
import {CONTACT_NOTES_VIEW} from '../../../constants';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {NoteQueries} from '../../../services';
import {Colors} from '../../../styles';
import {EventBus} from '../../../utils/EventBus';
import {isWeb} from '../../../utils/platformCheckUtils';
import {AddNoteView} from '../AddNoteView';
import {AddActionView} from '../Buttons/AddActionView';
import {DisplayText} from '../DisplayText/DisplayText';
import {NoContactNotesSvg} from '../Svg';
import TaskIconOther from '../Svg/TaskIconOther';
import ContactNoteReadOnlyView from './ContactNoteReadOnlyView';
import ContactNotesListView from './ContactNotesList';
import {styles} from './ContactNotesViewStyles';
import { CONVERSATION_LOCAL_EVENT_CODES } from '../../../constants/WebSocketConst';

interface IContacNotesView {
  contactId: any;
  style?: any;
  isDefaultClose?: boolean;
  shouldShowAddNotes?: any;
  onClose?: any;
}

const ContactNotesView = (props: IContacNotesView) => {
  const {contactId, onClose} = props;
  const [selectedView, setSelectedView] = useState(
    CONTACT_NOTES_VIEW.NOTES_LIST_VIEW
  );
  const [contactNotesState, setContactNotesState] = useState({
    contactNotesList: [] as any[],
    loading: true,
  });
  const [selectedData, setSelectedData] = useState({} as any);

  useEffect(() => {
    if (props.shouldShowAddNotes) {
      onViewChangeActionPerformed(COMMON_ACTION_CODES.ADD);
    }
  }, [props.shouldShowAddNotes]);

  const onViewChangeActionPerformed = (
    actionCode: string,
    rowData?: any
  ): any => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.ROW_CLICKED:
        setSelectedData(rowData);
        setSelectedView(CONTACT_NOTES_VIEW.NOTE_READ_ONLY_VIEW);
        break;
      case COMMON_ACTION_CODES.EDIT:
        setSelectedData(rowData);
        setSelectedView(CONTACT_NOTES_VIEW.ADD_NOTE);
        break;
      case COMMON_ACTION_CODES.ADD:
        setSelectedData(rowData);
        setSelectedView(CONTACT_NOTES_VIEW.ADD_NOTE);
        onClose?.();
        break;
      case COMMON_ACTION_CODES.CANCEL:
        setSelectedData(rowData);
        setSelectedView(CONTACT_NOTES_VIEW.NOTES_LIST_VIEW);
        onClose?.();
        break;
      case COMMON_ACTION_CODES.COMPLETED:
        setSelectedData(rowData);
        setSelectedView(CONTACT_NOTES_VIEW.NOTES_LIST_VIEW);
        const eventBus = EventBus.getEventBusInstance();
        eventBus.broadcastEvent(
          'ADD_OR_UPDATE_NOTES',
          COMMON_ACTION_CODES.COMPLETED
        );
        break;
      default:
        setSelectedData(rowData);
        setSelectedView(CONTACT_NOTES_VIEW.NOTES_LIST_VIEW);
        break;
    }
  };

  const [getContactList] = useLazyQuery<any>(NoteQueries.GetNotesByContactId, {
    fetchPolicy: 'no-cache',
  });

  const onNotesAddOrUpdate = useCallback(() => {
    getContactNotesData();
  }, [selectedView === COMMON_ACTION_CODES.COMPLETED, contactId]);

  const getContactNotesData = async () => {
    setContactNotesState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const responseData = await getContactList({
      variables: {
        contactId: contactId,
      },
    });
    if (responseData?.data?.notes?.length) {
      const filterOutStickyNotes = responseData?.data?.notes?.filter((note: any)=>{
        return note?.contactStickyNotes?.id ? false : true
      })
      setContactNotesState((prev) => {
        return {
          ...prev,
          contactNotesList: filterOutStickyNotes,
          loading: false,
        };
      });
    } else {
      setContactNotesState((prev) => {
        return {
          ...prev,
          contactNotesList: [],
          loading: false,
        };
      });
    }
  };
  useEffect(() => {
    getContactNotesData();
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener('ADD_OR_UPDATE_NOTES', onNotesAddOrUpdate);
    eventBus.addEventListener(CONVERSATION_LOCAL_EVENT_CODES.ADD_CONTACT_NOTE,updateContactNoteList);
    return () => {
      eventBus.removeEventListener(onNotesAddOrUpdate)
      eventBus.removeEventListener(updateContactNoteList);
    };
  }, [contactId]);

  const updateContactNoteList = (contactNoteData: any) => {
    setContactNotesState((prev) => {
      const updatedList = [contactNoteData?.contactNoteData?.createNote, ...prev.contactNotesList]
      return {
        ...prev,
        contactNotesList: updatedList,
      };
    });
  }

  const renderContent = () => {
    return (
      <View
      style={[
        {backgroundColor: '#ffffff', marginHorizontal: 24, borderRadius: 8,
        // @ts-expect-error: Let's ignore a compile error like this unreachable code
        boxShadow: `0px 1px 2px rgba(16, 24, 40, 0.06)`,
        paddingHorizontal: 16,
      },
      ]}
    >
        {contactNotesState.contactNotesList.length > 0 && (
          <ContactNotesListView
            loading={contactNotesState.loading}
            contactNotesList={contactNotesState.contactNotesList}
            onNotesListActionPerform={onViewChangeActionPerformed}
            contactId={contactId}
          />
        )}
        {!contactNotesState.loading &&
          contactNotesState.contactNotesList.length === 0 && (
            <View>
              <NoContactNotesSvg titleId="noContactNotes" />
            </View>
          )}
      </View>
    );
  };

  const getLeadViewFromSelectedCode = () => {
    switch (selectedView) {
      case CONTACT_NOTES_VIEW.NOTES_LIST_VIEW:
      case CONTACT_NOTES_VIEW.NOTE_READ_ONLY_VIEW:
      case CONTACT_NOTES_VIEW.ADD_NOTE:
        return (
          <>
            {/* <CommonCollapse
              isDefaultClose={props.isDefaultClose}
              textLocalId="contactNotes"
              showArrow={false}
              header={
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                  <View style={{marginRight: 10}}>
                    <TaskIconOther />
                  </View>
                  <DisplayText
                    size={'lgMedium'}
                    extraStyles={{color: Colors.Custom.Gray900}}
                    textLocalId={'contactNotes'}
                  />
                </View>
              }
              btnList={[
                {
                  icon: <AddActionView />,
                  tooltipLabel: 'Add Note',
                  onIconBtnClick: () => {
                    onViewChangeActionPerformed(COMMON_ACTION_CODES.ADD);
                  },
                },
              ]}
              style={props.style ? props.style : {marginBottom: 6}}
            >

            </CommonCollapse> */}
            <View
              style={{
                backgroundColor: Colors.Custom.Gray50,
              }}
            >
              <View style={{padding: 16}}>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginLeft: 9,
                    }}
                  >
                    <View style={{marginRight: 10}}>
                      <TaskIconOther />
                    </View>
                    <DisplayText
                      size={'lgMedium'}
                      textLocalId={'contactNotes'}
                      extraStyles={{color: Colors.FoldPixel.GRAY300 || ''}}
                    />
                  </View>
                  <Tooltip label={'Add Note'} placement={'top'}>
                    <Pressable
                      onPress={() => {
                        onViewChangeActionPerformed(COMMON_ACTION_CODES.ADD);
                      }}
                    >
                      <AddActionView />
                    </Pressable>
                  </Tooltip>
                </View>
              </View>
              {renderContent()}
            </View>
            {selectedView === CONTACT_NOTES_VIEW.NOTE_READ_ONLY_VIEW &&
              isWeb() && (
                <ContactNoteReadOnlyView
                  onDeleteCompleteAction={onViewChangeActionPerformed}
                  selectedNote={selectedData}
                />
              )}

            {selectedView === CONTACT_NOTES_VIEW.ADD_NOTE && (
              <AddNoteView
                selectedData={selectedData}
                contactId={contactId}
                onFormActionPerformed={(actionCode: any) => {
                  onViewChangeActionPerformed(actionCode);
                }}
              />
            )}
            {Platform.OS === 'web' && (
              <>
                {selectedView === CONTACT_NOTES_VIEW.NOTE_READ_ONLY_VIEW && (
                  <ContactNoteReadOnlyView
                    onDeleteCompleteAction={onViewChangeActionPerformed}
                    selectedNote={selectedData}
                  />
                )}
              </>
            )}
            {selectedView === CONTACT_NOTES_VIEW.ADD_NOTE && (
              <AddNoteView
                selectedData={selectedData}
                contactId={contactId}
                onFormActionPerformed={(actionCode: any) => {
                  onViewChangeActionPerformed(actionCode);
                }}
              />
            )}
          </>
        );
    }
  };
  return <>{getLeadViewFromSelectedCode()}</>;
};
export default ContactNotesView;
