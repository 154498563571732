import {FormControl, HStack, VStack} from 'native-base';
import React from 'react';
import {ISearchFieldProps} from '../../../Interfaces';
import {Colors} from '../../../styles/Colors';
import {DisplayText} from '../../common/DisplayText/DisplayText';
import PageBreakWrapper from './PageBreakWrapper';
import { TestIdentifiers, testID } from '../../../testUtils';
import CustomTextAreaField from '../../RightSideContainer/Forms/FHFormio/CustomComponents/TextAreaComponent/CustomTextAreaField';

interface IReasonForVisitFreeText extends ISearchFieldProps {
  macroTemplatesEnabled?: boolean;
}

const ReasonForVisitFreeText = (props: IReasonForVisitFreeText) => {
  const {
    value,
    isShowError,
    isDisabled,
    isRequired,
    customLabel,
    customPlaceHolder,
    customStyles,
    numberOfLines,
    errorMessage,
    showLabel,
    onChange,
    labelStyles,
    macroTemplatesEnabled,
  } = props;

  return (
    <PageBreakWrapper>
    <VStack>
      <FormControl
        paddingTop={2}
        paddingBottom={4}
        isRequired={isRequired}
        isDisabled={isDisabled}
        isInvalid={isShowError}
      >
      {showLabel && (
        <FormControl.Label marginLeft={0}>
            <HStack space={1} alignItems={'center'}>
              <DisplayText
                size={'smRegular'}
                extraStyles={{
                  color: Colors.FoldPixel.GRAY250,
                  fontWeight: '400',
                  fontSize: 14,
                  ...labelStyles
                }}
                textLocalId={customLabel || 'reasonForVisit' || ''}
                testID={`${TestIdentifiers.reasonForVisit}Label`}
              />
            </HStack>
        </FormControl.Label>
      )}
        <CustomTextAreaField
          value={value}
          macroTemplatesEnabled={macroTemplatesEnabled}
          isRTE={false}
          disabled={isDisabled}
          numberOfLines={numberOfLines || 3}
          placeholder={customPlaceHolder}
          showError={isShowError}
          additionalProps={{
            ...testID(TestIdentifiers.reasonForVisit),
            width: '98%',
            rounded: "lg",
            borderWidth: customStyles?.backgroundColor || 1,
            backgroundColor: customStyles?.backgroundColor || '#f9f9f954',
            borderRadius: customStyles?.borderRadius || 8,
            borderColor: customStyles?.borderColor || Colors.Custom.BorderColor,
            totalLines: numberOfLines || 3,
            fontSize: customStyles?.fontSize || 12,
            _focus: {
              borderColor: Colors.Custom.BorderColor,
              style: {
                borderColor: Colors.Custom.BorderColor,
              },
            },
            fontWeight: '300',
          }}
          onValueChange={(text) => {
            onChange({code: undefined, displayName: text});
          }}
        />
        {!!errorMessage && (
          <FormControl.ErrorMessage
            _text={{
              fontSize: 'xs',
              color: 'error.500',
              fontWeight: '500',
            }}
          >
            {errorMessage}
          </FormControl.ErrorMessage>
        )}
      </FormControl>
    </VStack>
    </PageBreakWrapper>
  );
};

export default ReasonForVisitFreeText;
