import React from 'react';
import { Path, Svg } from 'react-native-svg';

const RightArrowSvg = () => {
  return (
    <Svg width="7" height="6" viewBox="0 0 7 6" fill="none">
    <Path d="M4.5 3L2.5 1L2.5 5L4.5 3Z" fill="#D0D6E1" stroke="#D0D6E1" stroke-linecap="round" stroke-linejoin="round"/>
    </Svg>
  );
};

export default React.memo(RightArrowSvg);