import React from 'react';
import {Path, Svg} from 'react-native-svg';
import { Colors } from '../../styles';
import { ICommonSvgProps } from '../../components/common/Svg/interfaces';

export const DoubleTickIconSvg = (props: ICommonSvgProps) => {
  const size = props.size || 16;
  return (
    <Svg width={size} height={size} viewBox="0 0 17 16" fill="none">
      <Path
        d="M3.16675 8.59994L5.26199 10.9999L10.5001 4.99994M13.8335 5.04164L8.11909 11.0416L7.83354 10.6666"
        stroke={props.customStrokeColor || Colors.FoldPixel.GRAY300}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </Svg>
  );
};
