import {StyleSheet} from 'react-native';
import { Colors } from '../../../../../styles/Colors';
import { INPUT_TEXT_CONFIG, SM_CONFIG } from '../../../../../theme/FontConfig/TextFontSizeConfig';
export const styles = StyleSheet.create({
  infoOutlineIcon: {
    position: 'absolute',
    width: '100%',
    top: 16,
    left: 0,
    backgroundColor: '#fff',
    paddingLeft: 24,
    zIndex: 1,
    color: Colors.Custom.Gray400,
    fontSize: 14
  },
  viewPressable: {
    flexGrow: 1,
    marginHorizontal: 20,
  },
  sideBarcontainer: {
    width: '20%',
  },
  container: {
    width: '88vw',
  },
  tagContainer: {
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 7,
    paddingRight: 7,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 30,
    margin: 4
  },
  cancelIcon: {height: 8, width: 8},
  beta: {
    color: Colors.FoldPixel.GRAY200,
    fontFamily: 'Courier',
    textAlign: 'center',
  },
  poweredByUnity: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  poweredByUnity2: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'space-between',
    paddingVertical: 5,
    borderBottomLeftRadius: 9,
    borderBottomRightRadius: 9,
  },
  marginVertical10: {
    marginVertical: 10
  },
  marginLeft8: {
    marginLeft: 8
  }

});

export const InputStyle = {
  placeHolderFontsize: INPUT_TEXT_CONFIG.intNormal.fontSize,
  placeHolderFontWeight: 500,
  placeholderTextColor: Colors.FoldPixel.PLACEHOLDERCOLOR,
}