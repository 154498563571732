import React, {useState, useEffect, useRef} from 'react';
import {View, Text, Pressable, StyleSheet} from 'react-native';
import LogTimeSvg from '../Svg/LogTimeSvg';
import {Colors} from '../../../styles/Colors';
import Stack from '../LayoutComponents/Stack';
import {useIntl} from 'react-intl';
import StartTimerSvg from '../Svg/StartTimerSvg';
import { antStyles } from '../../RightSideContainer/CareManagementView/CareManageMentViewStyles';
import { Divider } from 'antd';
import { EventBus } from '../../../utils/EventBus';
import CustomConfirmationModal from '../CustomConfirmationModal/CustomConfirmationModal';
import WarningSvgV2 from '../Svg/WarningSvgV2';
import { CARE_PROGRAM_EVENTS } from '../../RightSideContainer/ContactCareProgram/useCPEventhandler';

export enum TimerState {
  // In this state, start time and log manually should be shown
  INITIAL = 'INITIAL',
  // In this state, stopwatch is running, and pause and stop should be shown
  RUNNING = 'RUNNING',
  // In this state, stopwatch is paused, and resume and stop should be shown
  PAUSED = 'PAUSED',
  // In this state, stopwatch is stopped, logged time and reset should be shown
  STOPPED = 'STOPPED',
}

export interface IStopwatch {
  defaultTimerState: TimerState;
  onTimeChange?: (minutes: number, seconds: number) => void;
  onTimerStateChange?: (timerState: TimerState, time?: {minutes: number, seconds: number}) => void;
  isLoggedTimeCleared?: boolean;
  defaultTime?: {
    minutes: number;
    seconds: number;
  };
  previousRecordedTime?: {
    minutes: number;
    seconds: number;
  };
  syncCode?: string;
  onLoggedTimePress?: () => void;
}

const Stopwatch = (props: IStopwatch) => {
  const { defaultTimerState, isLoggedTimeCleared, defaultTime, previousRecordedTime, syncCode, onTimerStateChange, onLoggedTimePress } = props;
  const initialMinutes = (isLoggedTimeCleared ? defaultTime?.minutes : previousRecordedTime?.minutes) || 0;
  const initialSeconds = (isLoggedTimeCleared ? defaultTime?.seconds : previousRecordedTime?.seconds) || 0;

  const [timerState, setTimerState] = useState(defaultTimerState);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);
  const calculatedTime = initialMinutes * 60 + initialSeconds;
  const [time, setTime] = useState(calculatedTime);
  const timeRef = useRef(calculatedTime);
  const timerIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const isManuallyStopped = useRef(false);
  const intl = useIntl();
  const minutes = Math.floor(timeRef.current / 60);
  const seconds = timeRef.current % 60;

  const handleStopTimer = () => {
    if (timerState === TimerState.INITIAL || timerState === TimerState.PAUSED) {
      return;
    }
    const minutes = Math.floor(timeRef.current / 60);
    const seconds = timeRef.current % 60;
    updateTimerState(TimerState.STOPPED, {minutes, seconds});
  };

  const handleStartTimer = () => {
    if (!isManuallyStopped.current && timerState !== TimerState.PAUSED) {
      updateTimerState(TimerState.RUNNING);
    }
  };

  useEffect(() => {
    if (isLoggedTimeCleared) {
      timeRef.current = 0;
      setTime(timeRef.current || 0);
    }
  }, [isLoggedTimeCleared]);

  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(
      CARE_PROGRAM_EVENTS.STOP_TIMER,
      handleStopTimer
    );
    eventBus.addEventListener(
      CARE_PROGRAM_EVENTS.CARE_PROGRAM_ACTION_TAKEN,
      handleStartTimer,
    );
    return () => {
      eventBus.removeEventListener(handleStopTimer);
      eventBus.removeEventListener(handleStartTimer);
    }
  }, [handleStopTimer, handleStartTimer]);

  useEffect(() => {
   updateTimerState(defaultTimerState);
  }, [defaultTimerState])

  useEffect(() => {
    if (timerState === TimerState.RUNNING) {
      timerIntervalRef.current = setInterval(() => {
        const prevTime = timeRef.current;
        const newTime = prevTime + 1;
        const minutes = Math.floor(newTime / 60);
        const seconds = newTime % 60;
        props.onTimeChange?.(minutes, seconds);
        timeRef.current = newTime;
        setTime(timeRef.current || 0);
      }, 1000);
    }
    return () => {
      if (timerIntervalRef.current) clearInterval(timerIntervalRef.current);
    };
  }, [timerState]);

  useEffect(() => {
    return () => {
      const minutes = Math.floor(timeRef.current / 60);
      const seconds = timeRef.current % 60;
      updateTimerState(TimerState.STOPPED, {minutes, seconds});
      if (timerIntervalRef.current) clearInterval(timerIntervalRef.current);
    };
  }, []);

  const handleResetConfirmation = () => {
    setIsDeleteConfirmationModalOpen(true);
  }

  const updateTimerState = (newTimerState: TimerState, time?: {minutes: number, seconds: number}) => {
    if (newTimerState !== timerState) {
      setTimerState(newTimerState);
      onTimerStateChange?.(newTimerState, time);
    }
  }

  const handlePauseResume = () => {
    updateTimerState(timerState === TimerState.PAUSED ? TimerState.RUNNING : TimerState.PAUSED);
  };

  const handleStop = () => {
    isManuallyStopped.current = true;
    updateTimerState(TimerState.STOPPED, {minutes, seconds});
  };

  const handleStart = () => {
    isManuallyStopped.current = false;
    updateTimerState(TimerState.RUNNING);
  }

  const startTimeElement = (
    <Pressable onPress={handleStart}>
      <Stack
        style={{
          padding: 8,
          alignItems: 'center',
          width: 'fit-content',
        }}
        space={4}
        direction={'row'}
      >
        <StartTimerSvg />
        <Text
          style={{
            fontWeight: '500',
            fontSize: 14,
            color: Colors.FoldPixel.PRIMARY300,
          }}
        >
          {intl.formatMessage({id: 'startTime'})}
        </Text>
      </Stack>
    </Pressable>
  );

  const runningTimeElement = (
    <Stack
      style={styles.runningTimeStack}
      space={4}
      direction={'row'}
    >
      <LogTimeSvg strokeColor={Colors.FoldPixel.STATUS_DARK_SUCCESS} />
      <Text style={styles.timeText}>
        {minutes.toString().padStart(2, '0')}:
        {seconds.toString().padStart(2, '0')}
      </Text>
      <Text style={styles.pointText}> • </Text>
      <Pressable onPress={handlePauseResume}>
        <Text style={styles.actionText}>
          {intl.formatMessage({ id: timerState === TimerState.PAUSED ? 'resume' : 'pause' })}
        </Text>
      </Pressable>
      <Divider type="vertical" style={antStyles.dividerStyle} />
      <Pressable onPress={handleStop}>
        <Text style={styles.stopText}>
          {intl.formatMessage({ id: 'stop' })}
        </Text>
      </Pressable>
    </Stack>
  );

  const stoppedElement = (
    <Stack
      style={styles.loggedTimeStack}
      space={4}
      direction={'row'}
    >
    <Pressable
      onPress={onLoggedTimePress}
      style={styles.loggedTimeButton}
    >
      <StartTimerSvg />
      <Text style={styles.loggedTimeText}>
        {minutes.toString().padStart(2, '0')}:
        {seconds.toString().padStart(2, '0')}
      </Text>
      </Pressable>
      <Text style={styles.pointText}> • </Text>
      <Pressable onPress={() => handleResetConfirmation()}>
        <Text style={styles.resetText}>
          {intl.formatMessage({id: 'reset'})}
        </Text>
      </Pressable>
    </Stack>
  );

  return (
    <View>
      {timerState === TimerState.INITIAL && startTimeElement}
      {timerState === TimerState.RUNNING && runningTimeElement}
      {timerState === TimerState.PAUSED && runningTimeElement}
      {timerState === TimerState.STOPPED && stoppedElement}
      <CustomConfirmationModal
        isOpen={isDeleteConfirmationModalOpen}
        onCancel={() => setIsDeleteConfirmationModalOpen(false)}
        onConfirm={() => {
          updateTimerState(TimerState.INITIAL, {minutes: 0, seconds: 0});
          setIsDeleteConfirmationModalOpen(false);
        }}
        headerText={'clearTime'}
        customIcon={<WarningSvgV2 strokeColor={Colors.Custom.crossIconColor} />}
        modalContent={
          <View style={styles.confirmationModalContent}>
            <Text style={styles.confirmationModalText}>
              {intl.formatMessage({ id: 'clearTimeConfirmationMessage' })}
            </Text>
          </View>
        }
      />
    </View>
  );
};

const styles = StyleSheet.create({
  pointText: {
    fontWeight: '500',
    fontSize: 18,
    color: Colors.FoldPixel.GRAY300,
  },
  pauseText: {
    fontWeight: '500',
    fontSize: 14,
    color: Colors.FoldPixel.GRAY300,
  },
  runningTimeStack: {
    padding: 8,
    alignItems: 'center',
    borderRadius: 4,
    width: 'fit-content',
  },
  timeText: {
    fontWeight: '500',
    fontSize: 14,
    color: Colors.FoldPixel.STATUS_DARK_SUCCESS
  },
  actionText: {
    fontWeight: '500',
    fontSize: 14,
    color: Colors.FoldPixel.GRAY300,
  },
  stopText: {
    fontWeight: '500',
    fontSize: 14,
    color: Colors.Custom.Danger700,
  },
  confirmationModalContent: {
    marginTop: 2,
  },
  confirmationModalText: {
    color: Colors.Custom.Gray400,
    fontSize: 14,
    lineHeight: 16.8,
    fontWeight: '400',
    textAlign: 'center',
  },
  resetText: {
    fontWeight: '500',
    fontSize: 14,
    color: Colors.FoldPixel.GRAY300,
  },
  loggedTimeStack: {
    padding: 8,
    alignItems: 'center',
    borderRadius: 4,
    width: 'fit-content',
  },
  loggedTimeButton: {
    flexDirection: 'row',
  },
  loggedTimeText: {
    color: Colors.FoldPixel.PRIMARY300,
    marginHorizontal: 3,
  },
});

export default React.memo(Stopwatch);
