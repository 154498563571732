import {useLazyQuery, useMutation} from '@apollo/client';
import {Drawer, Image, Modal, Progress, Upload, Tooltip, notification} from 'antd';
import type {RcFile} from 'antd/es/upload';
import type {UploadFile} from 'antd/es/upload/interface';
import {
  HStack,
  Icon,
  IconButton,
  InfoOutlineIcon,
  Pressable,
  Spacer,
  Spinner,
  Text,
  useToast,
  View,
  VStack,
} from 'native-base';
import {useContext, useEffect, useMemo, useState} from 'react';
import {BUTTON_TYPE, MLOV_CATEGORY, documentCaptionDuplicateError, documentCaptionError, IMAGING_REPORT, ATTACHMENT_TYPE, BASE64_EXTENSIONS} from '../../../../../constants';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../constants/Configs';
import {
  CARE_PROGRAM_CONSENT_TYPE,
  CARE_PROGRAM_STEP_TYPE,
  CONSENT_DOCUMENT_SUB_CATEGORY_CODES,
  CONSENT_DOCUMENT_SUB_CATEGORY_LIST,
  CONSENT_RESOURCE_TYPE_CODES,
  DOCUMENT_SUBJECT_TYPE_CODES,
  DOCUMENT_TYPE_CODES,
} from '../../../../../constants/MlovConst';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import CareStudioService from '../../../../../services/CommonService/CareStudioService';
import * as DocumentQueries from '../../../../../services/Document/DocumentQueries';
import {Colors} from '../../../../../styles';
import {showToast, ToastType} from '../../../../../utils/commonViewUtils';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../../../utils/mlovUtils';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {ModalActionInput} from '../../../../common/ModalActionCommonComponent/ModalActionInput';
import {ModalActionSelect} from '../../../../common/ModalActionCommonComponent/ModalActionSelect';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {IMediaLibraryData} from '../../../../RightSideContainer/ContentManagement/MediaLibrary/interfaces';
import './UploadPatientDocumentStyle.css';
import {
  IDocument,
  IDocumentAttachment,
} from '../../../../../services/Document/DocumentInterfaces';
import AntIcon from 'react-native-vector-icons/AntDesign';
import Ionicons from 'react-native-vector-icons/Ionicons';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {isWeb} from '../../../../../utils/platformCheckUtils';
import { DocumentViewer } from '../../../../common/DocumentViewer/DocumentViewer';
import { ALLOWED_MIME_TYPES_FOR_ALL_DOCUMENTS, ALLOWED_MIME_TYPES_FOR_IMAGING_REPORT, getMediaTypeFromMimeTypeForImagingReport, MEDIA_TYPES, MIME_TYPES, WORD_DOC_MIME_TYPES } from '../../../../common/DocumentViewer/DocumentViewerHelper';
import { CARE_PLAN_SUB_CATEGORY, DOCUMENT_CATEGORY_CODES } from '../../../MiddleContainer/Documents/DocumentCategoryConstants';
import {CareJourneyQueries} from '../../../../../services';
import {checkDuplicateCaption} from '../PatientDocumentsNative/DocumentScreenUtils';
import {debounce} from 'lodash';
import ContactCareProgram from '../../../../../services/ContactCareProgram/ContactCareProgram';
import { getImagingReportCreatePostJSON, isUnsupportedImagingFilesType} from './UploadDocumentUtils';
import { IPersonData } from '../../../MiddleContainer/interfaces';
import { postImagingReport } from '../../../../../services/CommonService/AidBoxService';
import { getEhrConfig } from '../../../../../utils/capabilityUtils';
import { useIntl } from 'react-intl';
import { StyleSheet } from 'react-native';
import { IContact } from '../../../../common/CreateChannelView/interface';

declare let document: any;
if (!document) document = {};

export const UPLOAD_ACCEPTED_FILE_TYPES = [
  MIME_TYPES.PDF,
  MIME_TYPES.ODT,
  MIME_TYPES.DOC,
  MIME_TYPES.DOCX,
  ATTACHMENT_TYPE.IMAGE_SLASH,
].join(',');

export const IMAGING_REPORT_UPLOAD_ACCEPTED_FILE_TYPES = [
  MIME_TYPES.PDF,
  ATTACHMENT_TYPE.IMAGE_SLASH,
].join(',');

export function getBase64(file: RcFile): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

export function getArrayBuffer(file: RcFile): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onloadend = () => resolve(reader.result as ArrayBuffer);
    reader.onerror = (error) => reject(error);
  });
}

type IDocumentCategory = {
  id: string;
  isDefault: boolean;
  code: string;
  value: string;
  description?: string;
}

type IProps = {
  contactId: string;
  document?: IDocument;
  isVisible: boolean;
  onCancel: () => void;
  onUpload: (data: any, documentTypeId?: string) => void;
  isCareJourneyDocument?: boolean;
  documentsCategories?: IDocumentCategory[];
  selectedCategoryId?: string;
  careJourneyDocuments?: IDocument[];
  careJourneyId?: string;
  personData?: IPersonData | IContact;
};

type IComponentState = {
  selectedDocumentTypeId?: string;
  subCategory?: string;
  caption?: string;
  notes?: string;
  fileList: UploadFile<any>[];
  secondFileList: UploadFile<any>[];
  progress: number;
  isLoading: boolean;
  showErrors: boolean;
  uploadMaxCount: number;
  uploadAcceptedFileTypes: string;
  previewObj?: {url: string; type: string; fileMediaData: IMediaLibraryData};
  isPreviewVisible: boolean;
  previewTitle?: string;
  previewImage?: any;
  uploadAsSingleDocument: boolean;
  isFileLoading: {[index: string]: boolean};
  carePlanDocumentTypeId: string;
  isDuplicateCaption?: boolean;
  debounceLoading?: boolean;
};

export function UploadPatientDocument(props: IProps) {
  const [componentState, setComponentState] = useState<IComponentState>({
    selectedDocumentTypeId: props.document?.documentType?.id || props.document?.documentTypeId || props?.selectedCategoryId || undefined,
    caption: props.document?.caption || '',
    notes: props.document?.notes || '',
    fileList: props.document?.documentAttachments?.[0]?.attachment
      ? [
          {
            id: props.document?.documentAttachments[0].attachment.id,
            uid: props.document?.documentAttachments[0].attachment.id,
            name: props.document?.documentAttachments[0].attachment.name,
            type: props.document?.documentAttachments[0].attachment.type,
            status: 'done',
          } as any,
        ]
      : [],
    secondFileList: props.document?.documentAttachments?.[1]?.attachment
      ? [
          {
            id: props.document?.documentAttachments[1].attachment.id,
            uid: props.document?.documentAttachments[1].attachment.id,
            name: props.document?.documentAttachments[1].attachment.name,
            type: props.document?.documentAttachments[1].attachment.type,
            status: 'done',
          } as any,
        ]
      : [],
    progress: 0,
    isLoading: false,
    showErrors: false,
    uploadMaxCount: 1,
    uploadAcceptedFileTypes: UPLOAD_ACCEPTED_FILE_TYPES,
    isPreviewVisible: false,
    previewTitle: undefined,
    uploadAsSingleDocument:
      props.document?.documentAttachments?.length &&
      props.document.documentAttachments.length > 1
        ? false
        : true,
    isFileLoading: {},
    carePlanDocumentTypeId: '',
    isDuplicateCaption: false,
    debounceLoading: false,
    subCategory: props.document?.subCategory,
  });
  const intl = useIntl();
  const imagingReportId = useMemo(() => {
    return props?.documentsCategories
      ? props.documentsCategories.find(category => category.code === DOCUMENT_CATEGORY_CODES.IMAGING_REPORTS)?.id
      : '';
  }, [props?.documentsCategories?.length]);
  const ehrConfig = getEhrConfig(props?.personData?.accountLocationUuid, '');
  const mlovData = useContext(CommonDataContext);
  const isSideCarContext = mlovData?.sidecarContext?.isSidecar;
  const documentSubjectTypeMlovs =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.DOCUMENT_SUBJECT_TYPE
    ) || [];
  const careProgramTypeList = getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CARE_PROGRAM_TYPES
    ) || [];
  const careProgramConsentTypeList = getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CONTACT_CARE_PROGRAM_CONSENT_TYPE
    ) || [];
  const careProgramStepTypeList = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.CARE_PROGRAM_STEP_TYPE
  ) || [];

  const personalIdsDocumentTypeId = props?.documentsCategories?.find((category) => category.code === DOCUMENT_CATEGORY_CODES?.PERSONAL_IDS)?.id
  const consentFormDocumentTypeId = props?.documentsCategories?.find(category => category?.code === DOCUMENT_CATEGORY_CODES?.CONSENT_FORMS)?.id;
  const contactDocumentSubjectTypeId = getMlovIdFromCode(
    documentSubjectTypeMlovs,
    DOCUMENT_SUBJECT_TYPE_CODES.CONTACT
  );
  const careJourneyDocumentSubjectTypeId = getMlovIdFromCode(
    documentSubjectTypeMlovs,
    DOCUMENT_SUBJECT_TYPE_CODES.CARE_JOURNEY
  );

  const [getDocumentCategories] = useLazyQuery(
    CareJourneyQueries.GET_DOCUMENT_CATEGORIES_BY_CATEGORY_ID,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const [createDocumentQuery] = useMutation(
    DocumentQueries.CREATE_CONTACT_DOCUMENT,
    {
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
    }
  );

  const [updateDocumentQuery] = useMutation(
    DocumentQueries.UPDATE_CONTACT_DOCUMENTS,
    {
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
    }
  );

  const [createOrUpdateDocumentAttachmentsQuery] = useMutation(
    DocumentQueries.CREATE_OR_UPDATE_CONTACT_DOCUMENT_ATTACHMENTS,
    {
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
    }
  );

  const [getCareJourneyDocuments] = useLazyQuery(
    DocumentQueries.GET_CARE_JOURNEY_DOCUMENTS_NAME,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  )

  const [getCareProgramConsentStep] = useLazyQuery(ContactCareProgram.GET_STEP_STATUS_OF_LATEST_CARE_PROGRAM, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });

  const [updateConsentLog] = useMutation(ContactCareProgram.UPDATE_CONTACT_CARE_PROGRAM_CONSENT, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
  });

  const getCareJourneyDocumentsByName = async (name: string)=> {
    const careJourneyDocuments = await getCareJourneyDocuments({
      variables: {
        params: {
          subjectId: props?.careJourneyId,
          documentTypeId:  componentState?.carePlanDocumentTypeId,
          limit: 100,
          offset: 0,
          searchString: name,
          orderBy: {
              caption: "desc"
          }
        }
      }
    })
    if (careJourneyDocuments?.data?.getDocuments?.documents?.length) {
      return careJourneyDocuments?.data?.getDocuments?.documents.some((document:{caption:string})=>{
        return document?.caption.trim().toLowerCase() === name.trim().toLowerCase()
      })
    }
    return false
  }

  const toast = useToast();
  const careStudioInstance = CareStudioService.getCareStudioServiceInstance();
  const fileUploadService = careStudioInstance.fileUploadService;

  async function uploadFile() {
    if (!componentState.showErrors) {
      setComponentState((prev) => ({...prev, showErrors: true}));
    }
    if (isFormInValid()) {
      return;
    }
    setComponentState((prev) => ({...prev, isLoading: true}));

    const attachmentIds = await createAttachments();
    if (props?.isCareJourneyDocument) {
      props?.onUpload(attachmentIds)
      return;
    }
    createOrUpdateDocument(attachmentIds);
  }

  async function createAttachments() {
    const formData = new FormData();
    let files = [
      ...componentState.fileList,
      ...(componentState.uploadAsSingleDocument
        ? []
        : componentState.secondFileList),
    ];
    files = files.filter((file) => !(file as any).id);

    if (!files?.length) {
      return [];
    }

    files.forEach((file: any) => formData.append('files', file.originFileObj));

    try {
      const res = await fileUploadService.post('', formData, {
        onUploadProgress: (progressEvent) => {
          const percent = Math.floor(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          setComponentState((prev) => ({...prev, progress: percent}));

          if (percent === 100) {
            setTimeout(
              () => setComponentState((prev) => ({...prev, progress: 0})),
              3000
            );
          }
        },
      });
      const attachmentIds = res?.data?.ids || [];
      if (props?.isCareJourneyDocument) {
        return files.map((file)=>{
          return {
            id:'',
            uid: file?.uid,
            caption: componentState?.caption,
            notes: componentState?.notes || '',
            documentTypeId: componentState?.carePlanDocumentTypeId,
            subjectId:'',
            subjectTypeId: careJourneyDocumentSubjectTypeId,
            isDeleted: false,
            documentAttachments: [{
              id: '',
              isDeleted: false,
              attachmentId: attachmentIds?.[0],
              attachment: {
                id: attachmentIds?.[0],
                name: file?.name,
                type: file?.type
              },
            }],
          }
        });
      }
      return attachmentIds;
    } catch (err) {
      setComponentState((prev) => ({...prev, isLoading: false}));
      showToast(
        toast,
        'Something went wrong. Please try again later',
        ToastType.error
      );
    }
  }

  async function createOrUpdateDocumentAttachments(
    data?: IDocumentAttachment[]
  ) {
    if (data?.length) {
      return await createOrUpdateDocumentAttachmentsQuery({
        variables: {
          data: data,
        },
      });
    }
  }

  const handlePostImagingReport = async () => {
    let extension;
    const rawBase64String = await getBase64(componentState?.fileList?.[0]?.originFileObj as RcFile);
    const contentType = getMediaTypeFromMimeTypeForImagingReport(componentState?.fileList?.[0]?.type || '');
    switch (contentType) {
      case MEDIA_TYPES.PDF:
        extension = BASE64_EXTENSIONS.PDF;
        break;
      case MEDIA_TYPES.JPEG:
        extension = BASE64_EXTENSIONS.JPEG;
        break;
      case MEDIA_TYPES.JPG:
        extension = BASE64_EXTENSIONS.JPG;
        break;
      case MEDIA_TYPES.PNG:
        extension = BASE64_EXTENSIONS.PNG;
        break;
      default:
        extension = BASE64_EXTENSIONS.JPEG;
        break;
    }
    const base64DataUploadedDoc = rawBase64String.replace(extension, '');
    const requestBody = getImagingReportCreatePostJSON({
      base64Data: base64DataUploadedDoc || '',
      contentType: contentType || '',
      captionName: componentState?.caption || '',
      patientId: props?.personData?.patientId || '',
    });
    try {
      const response = await postImagingReport(requestBody);
      setComponentState((prev) => ({...prev, isLoading: false}));
      props?.onUpload(document, componentState?.selectedDocumentTypeId);
    } catch (error) {
      setComponentState((prev) => ({...prev, isLoading: false}));
      notification.error({
        message: intl.formatMessage({ id: 'errorMsg' }),
        duration: 3.0,
        placement: 'top',
      });
    }
  }

  function extractDeletedDocumentAttachments() {
    const deletedDocumentAttachments: IDocumentAttachment[] = [];
    if (props.document) {
      props.document?.documentAttachments?.forEach((documentAttachment) => {
        const attachmentId = documentAttachment?.attachment?.id;
        if (!attachmentId) {
          return;
        }
        const files = [
          ...componentState.fileList,
          ...(componentState.uploadAsSingleDocument
            ? []
            : componentState.secondFileList),
        ];
        const file = files.find((file) => {
          return attachmentId === (file as any).id;
        });

        if (!file) {
          deletedDocumentAttachments.push(documentAttachment);
        }
      });
    }
    return deletedDocumentAttachments;
  }

  async function createOrUpdateDocument(attachmentIds: string[]) {
    const documentAttachments: any = attachmentIds.map((attachmentId) => ({
      attachmentId: attachmentId,
    }));

    try {
      if (ehrConfig.isAthena && componentState.selectedDocumentTypeId === imagingReportId) {
        handlePostImagingReport();
        return;
      }
      const response = props.document
        ? await updateDocument(documentAttachments)
        : await createDocument(documentAttachments);

      setComponentState((prev) => ({...prev, isLoading: false}));

      if (response?.data?.document) {
        const document: IDocument = response.data.document;
        if (document?.documentAttachments) {
          document.documentAttachments = document.documentAttachments?.filter(
            (documentAttachment) => {
              return !documentAttachment.isDeleted;
            }
          );
        }
        return props.onUpload(document, componentState?.selectedDocumentTypeId);
      } else {
        showToast(toast, 'Something went wrong', ToastType.error);
      }
    } catch (error) {

      showToast(toast, 'Something went wrong', ToastType.error);
      setComponentState((prev) => ({...prev, isLoading: false}));
    }
  }

  async function createDocument(documentAttachments: any) {
    const carePlanDocumentId = props?.documentsCategories?.find(category => category?.code === DOCUMENT_CATEGORY_CODES?.CARE_PLANS)?.id;
    const response = await createDocumentQuery({
      variables: {
        data: {
          ...(props.document?.id && {id: props.document.id}),
          caption: componentState.caption || '',
          documentTypeId: componentState.selectedDocumentTypeId,
          subjectId: props.contactId,
          subjectTypeId: contactDocumentSubjectTypeId,
          notes: componentState.notes || '',
          documentAttachments: {
            data: documentAttachments,
          },
          ...(carePlanDocumentId === componentState.selectedDocumentTypeId && {subCategory: CARE_PLAN_SUB_CATEGORY.CARE_JOURNEY}),
          ...(consentFormDocumentTypeId === componentState.selectedDocumentTypeId && {subCategory: componentState.subCategory})
        },
      },
    });
    const documentId = response.data?.document?.id;
    if (
      documentId &&
      componentState.subCategory &&
      [
        CONSENT_DOCUMENT_SUB_CATEGORY_CODES.CCM,
        CONSENT_DOCUMENT_SUB_CATEGORY_CODES.TCM,
        CONSENT_DOCUMENT_SUB_CATEGORY_CODES.ECM
      ].includes(componentState.subCategory)
      ) {
      await addOrUpdateCareProgramConsent(documentId, componentState.subCategory);
    }

    return response;
  }

  async function updateDocument(documentAttachments: any) {
    const deletedDocumentAttachments = extractDeletedDocumentAttachments();
    if (deletedDocumentAttachments) {
      documentAttachments = documentAttachments.concat(
        deletedDocumentAttachments.map((deletedDocumentAttachment) => {
          return {
            id: deletedDocumentAttachment.id,
            attachmentId: deletedDocumentAttachment.attachment?.id,
            isDeleted: true,
          };
        })
      );
    }

    documentAttachments.forEach((documentAttachment: any) => {
      documentAttachment.documentId = props.document?.id;
    });

    await createOrUpdateDocumentAttachments(documentAttachments);
    const carePlanDocumentId = props?.documentsCategories?.find(category => category?.code === DOCUMENT_CATEGORY_CODES?.CARE_PLANS)?.id;
    const response = await updateDocumentQuery({
      variables: {
        id: {id: props.document?.id},
        data: {
          ...(props.document?.id && {id: props.document.id}),
          caption: componentState.caption || '',
          documentTypeId: componentState.selectedDocumentTypeId,
          subjectId: props.contactId,
          subjectTypeId: contactDocumentSubjectTypeId,
          notes: componentState.notes || '',
          ...(carePlanDocumentId === componentState.selectedDocumentTypeId && {subCategory: CARE_PLAN_SUB_CATEGORY.CARE_JOURNEY}),
          ...(consentFormDocumentTypeId === componentState.selectedDocumentTypeId && {subCategory: componentState.subCategory}),
          ...(![carePlanDocumentId, consentFormDocumentTypeId].includes(componentState.selectedDocumentTypeId) && { subCategory: null })
        },
      },
    });

    const documentId = response.data?.document?.id;
    if (
      documentId &&
      componentState.subCategory &&
      [
        CONSENT_DOCUMENT_SUB_CATEGORY_CODES.CCM,
        CONSENT_DOCUMENT_SUB_CATEGORY_CODES.TCM,
        CONSENT_DOCUMENT_SUB_CATEGORY_CODES.ECM
      ].includes(componentState.subCategory)
      ) {
      await addOrUpdateCareProgramConsent(documentId, componentState.subCategory);
    }

    return response;
  }

  const addOrUpdateCareProgramConsent = async (documentId: string, careProgramTypeCode: string) => {
    try {
      const careProgramTypeId = getMlovIdFromCode(careProgramTypeList, careProgramTypeCode);
      const writtenConsentTypeId = getMlovIdFromCode(careProgramConsentTypeList, CARE_PROGRAM_CONSENT_TYPE.WRITTEN);
      const consentStepTypeId = getMlovIdFromCode(careProgramStepTypeList, CARE_PROGRAM_STEP_TYPE.CONSENT);

      const consentResponse = await getCareProgramConsentStep({
        variables: {
          params: {
            careProgramStepTypeId: consentStepTypeId,
            contactId: props.contactId,
            careProgramTypeId: careProgramTypeId
          }
        }
      });
      const hasConsent = consentResponse.data.getStepLatestContactCareProgram?.consentLog?.hasConsent || false;

      if (!hasConsent) {
        const response = await updateConsentLog({
          variables: {
            params: {
              contactId: props.contactId,
              careProgramTypeId: careProgramTypeId,
              payload: {
                stepCode: CARE_PROGRAM_STEP_TYPE.CONSENT,
                resourceId: documentId,
                resourceTypeCode: CONSENT_RESOURCE_TYPE_CODES.DOCUMENT,
                hasConsent: true,
                consentTypeId: writtenConsentTypeId,
              }
            }
          },
        });
        // if (!response.data?.updatePendingConsentOfContactCareProgram?.id) {
        //   notification.error({
        //     message: 'Something went wrong!',
        //     duration: 3.0,
        //     placement: 'top'
        //   });
        // }
      }
    } catch (error) {

      // notification.error({
      //   message: 'Something went wrong!',
      //   duration: 3.0,
      //   placement: 'top'
      // })
    }
  }

  function onDocumentTypeChange(selectedDocumentTypeId: string) {
    setComponentState((prev) => {
      let uploadAcceptedFileTypes = (ehrConfig.isAthena && componentState.selectedDocumentTypeId === imagingReportId) ? IMAGING_REPORT_UPLOAD_ACCEPTED_FILE_TYPES : UPLOAD_ACCEPTED_FILE_TYPES;
      let fileList = prev.fileList;
      let secondFileList = prev.secondFileList;
      let uploadAsSingleDocument = true;

      if (selectedDocumentTypeId === personalIdsDocumentTypeId) {
        uploadAcceptedFileTypes = 'image/*';
        uploadAsSingleDocument = false;
      }

      if (
        [
          componentState.selectedDocumentTypeId,
          selectedDocumentTypeId,
        ].includes(personalIdsDocumentTypeId)
      ) {
        fileList = [];
        secondFileList = [];
      }
      const isConsentFormType = selectedDocumentTypeId === consentFormDocumentTypeId;
      const subCategory = CONSENT_DOCUMENT_SUB_CATEGORY_LIST.filter(item => item.value === prev.subCategory)?.[0]?.value || CONSENT_DOCUMENT_SUB_CATEGORY_CODES.OTHER;
      return {
        ...prev,
        selectedDocumentTypeId,
        fileList,
        secondFileList,
        uploadAcceptedFileTypes,
        uploadAsSingleDocument,
        ...(isConsentFormType && { subCategory }),
      };
    });
  }

  async function handlePreview(file: UploadFile) {
    if (componentState.isFileLoading[(file as any).uid]) {
      return;
    }

    let fileUrl = '';

    if ((file as any).id && (!(file as any).blobUrl || WORD_DOC_MIME_TYPES.includes(file.type as string))) {
      await downloadFileAsBlob(file);
    }

    if (!(file as any).id && file?.type && WORD_DOC_MIME_TYPES.includes(file?.type)) {
      await convertToPdf(file);
    }

    const fileMediaData: IMediaLibraryData = {
      ...file,
      lastUpdated: file.lastModified?.toString() || '',
      mime: file.type as string,
      name: file.name,
      size: file.size as number,
    };

    if (!(file as any).blobUrl && !file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    if (file.type) {
      fileUrl = (file as any).blobUrl || file.preview || URL.createObjectURL(file.originFileObj as any);
    }

    setComponentState((prev) => ({
      ...prev,
      isPreviewVisible: true,
      previewTitle: file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1),
      ...(file.type && fileUrl &&
        {
          previewObj: {
            url: fileUrl || '',
            type: file.type as string,
            fileMediaData: fileMediaData,
          },
        }
      ),
    }));
  }

  function handlePreviewCancel(value = false) {
    setComponentState((prev) => ({
      ...prev,
      previewObj: undefined,
      isPreviewVisible: value,
      previewTitle: undefined,
      // previewImage: undefined,
    }));
  }

  function isFormInValid() {
    if (props?.isCareJourneyDocument) {
      if (!componentState.fileList?.length || !componentState?.caption || componentState?.isDuplicateCaption) {
        return true;
      }
    } else {
      if (
        !componentState.selectedDocumentTypeId ||
        !componentState.fileList?.length || !componentState.caption
      ) {
        return true;
      }
      if (
        !componentState.uploadAsSingleDocument &&
        !componentState.secondFileList?.length
      ) {
        return true;
      }
    }
    const files = [
      ...componentState.fileList,
      ...(componentState.uploadAsSingleDocument
        ? []
        : componentState.secondFileList),
    ];
    const invalidFile = files.find((file) => {
      if ((file as any).id) return false;
      if (!file.size) return true;
      if (file.size / 1000 / 1000 > 20) {
        return true
      }
    });
    if (invalidFile) {
      return true;
    }
    const isUnsupportedImagingFileType = isUnsupportedImagingFilesType(files);
    const isDocOrDocXFile = files.some((file) => {
      if (
        file?.type &&
        (WORD_DOC_MIME_TYPES.includes(file?.type))
      ) {
        return true;
      }
    });
    if (ehrConfig.isAthena &&
      componentState.selectedDocumentTypeId ===
        imagingReportId &&
        (isDocOrDocXFile ||
          isUnsupportedImagingFileType)
    ) {
      notification.error({
        message: intl.formatMessage({ id: 'imagingDocSupportingFilesMsg' }),
        duration: 3.0,
        placement: 'top',
      });
      return true;
    }
    else if (isUnsupportedImagingFileType && !isDocOrDocXFile) {
      notification.error({
        message: intl.formatMessage({ id: 'allDocumentsSupportingFilesMsg' }),
        duration: 3.0,
        placement: 'top',
      });
      return true;
    }
    return false;
  }

  async function downloadFileAsBlob(file: any, downloadFile = false) {
    const attachmentId: string = (file as any).id;
    const service = careStudioInstance.careStudioAxiosService;
    setComponentState((prev) => {
      prev.isFileLoading[file.uid] = true;
      return {...prev, isFileLoading: prev.isFileLoading};
    });

    try {
      if (!file.blobUrl || WORD_DOC_MIME_TYPES.includes(file.type)) {
        const response = await service.get(`/attachment/${attachmentId}/file?isPreview=${downloadFile === false}`, {
          responseType: 'blob',
        });

        file.blob = response.data;
        file.blobUrl = URL.createObjectURL(file.blob);
        file.preview = await getBase64(response.data);
      }

      if (downloadFile) {
        if (isWeb()) {
          const link = document.createElement('a');
          link.href = file.blobUrl;
          link.download = file.name;
          link.click();
        } else {
          showToast(toast, 'Unable to download file', ToastType.error);
        }
      }

      setComponentState((prev) => {
        prev.isFileLoading[file.uid] = false;
        return {...prev, isFileLoading: prev.isFileLoading};
      });
    } catch (error) {

      showToast(toast, 'Error while opening file', ToastType.error);
      setComponentState((prev) => {
        prev.isFileLoading[file.uid] = false;
        return {...prev, isFileLoading: prev.isFileLoading};
      });
    }
  }

  async function convertToPdf(file: any) {
    const service = careStudioInstance.careStudioAxiosService;
    setComponentState((prev) => {
      prev.isFileLoading[file.uid] = true;
      return {...prev, isFileLoading: prev.isFileLoading};
    });

    try {
      const formData = new FormData();
      formData.append('file', file.originFileObj);
      const response = await service.post(`/attachment/convert-to-pdf`, formData, {
        responseType: 'blob',
      });

      file.blob = response.data;
      file.blobUrl = URL.createObjectURL(file.blob);
      file.preview = await getBase64(response.data);

      // if (downloadFile) {
      //   if (isWeb()) {
      //     const link = document.createElement('a');
      //     link.href = file.blobUrl;
      //     link.download = file.name;
      //     link.click();
      //   } else {
      //     showToast(toast, 'Unable to download file', ToastType.error);
      //   }
      // }

      setComponentState((prev) => {
        prev.isFileLoading[file.uid] = false;
        return {...prev, isFileLoading: prev.isFileLoading};
      });
    } catch (error) {

      showToast(toast, 'Error while opening file', ToastType.error);
      setComponentState((prev) => {
        prev.isFileLoading[file.uid] = false;
        return {...prev, isFileLoading: prev.isFileLoading};
      });
    }
  }

  function getIconByFileType(documentType?: string) {
    if (documentType === 'application/pdf') {
      return (
        <MaterialCommunityIcons
          name="file-pdf-box"
          size={32}
          color={Colors.primary['300']}
        />
      );
    } else if (documentType?.startsWith('image/')) {
      return (
        <MaterialCommunityIcons
          name="file-image"
          size={26}
          color={Colors.primary['300']}
        />
      );
    } else if (documentType && WORD_DOC_MIME_TYPES.includes(documentType)) {
      return (
        <MaterialCommunityIcons
          name="file-word"
          size={26}
          color={Colors.primary['300']}
        />
      );
    }
    return (
      <Ionicons name="document-text" size={26} color={Colors.primary['300']} />
    );
  }

  const getTitleErrorText = ()=> {
    if (!componentState?.caption) {
      return documentCaptionError
    } else if (componentState?.isDuplicateCaption) {
      return documentCaptionDuplicateError
    }
    return '';
  }

  const checkForDuplicateCaption = async (text: string)=> {
    if (text) {
      setComponentState((prev)=>{
        return {
          ...prev,
          debounceLoading: true
        }
      })
      const isDuplicateCaptionInList = checkDuplicateCaption(props?.careJourneyDocuments,text);
      if (isDuplicateCaptionInList) {
        setComponentState((prev) => ({...prev, isDuplicateCaption: true, caption: text, debounceLoading: false}));
        return;
      }
      if (props?.careJourneyId) {
        const isCaptionAlreadyExist = await getCareJourneyDocumentsByName(text || '');
        if (isCaptionAlreadyExist && isDuplicateCaptionInList) {
          setComponentState((prev) => ({...prev, isDuplicateCaption: true, caption: text, debounceLoading: false}));
          return;
        }
      }
    }
    setComponentState((prev) => ({...prev, isDuplicateCaption: false,caption: text, debounceLoading: false}));
  }

  const debounceCheckForDuplicateCaption = debounce(checkForDuplicateCaption,300)

  const onBeforeUpload = (file: any) => {
    const isUnsupportedImagingFileType =
      file?.type && !ALLOWED_MIME_TYPES_FOR_IMAGING_REPORT.includes(file.type);
    const isUnsupportedDocumentFileType =
      file?.type && !ALLOWED_MIME_TYPES_FOR_ALL_DOCUMENTS.includes(file.type);
    if (
      ehrConfig.isAthena &&
      componentState.selectedDocumentTypeId === imagingReportId &&
      isUnsupportedImagingFileType
    ) {
      notification.error({
        message: intl.formatMessage({ id: 'imagingDocSupportingFilesMsg' }),
        duration: 3.0,
        placement: 'top',
      });
      return true;
    } else {
      if (isUnsupportedDocumentFileType) {
        notification.error({
          message: intl.formatMessage({ id: 'allDocumentsSupportingFilesMsg' }),
          duration: 3.0,
          placement: 'top',
        });
        return true;
      }
    }
    return false;
  };

  const documentTypeMlovs =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.DOCUMENT_TYPE
    ) || [];

  useEffect(()=>{
    if(!props?.documentsCategories) {
    getDocumentCategories({
      variables: {
        params: {
          categoryId: documentTypeMlovs?.[0]?.categoryId
        }
      }
    })
    .then((response) => {
      if (response?.data?.getDocumentCategories) {
        const carePlanDocumentCategory = response?.data?.getDocumentCategories.filter((category:any) => {
          return category?.code === DOCUMENT_TYPE_CODES?.CARE_PLAN
        })
        setComponentState((prev) => {
          return {
            ...prev,
            carePlanDocumentTypeId: carePlanDocumentCategory?.[0]?.id || ''
          }
        })
      }
    })
  }
  },[])

  return (
    <>
      <Drawer
        keyboard={false}
        destroyOnClose
        placement="right"
        onClose={() => {
          props.onCancel?.();
        }}
        visible={props.isVisible}
        closable
        width={isSideCarContext ? '100%' : 750}
        title={
          <>
            <ModalActionTitle
              title={'patientDocumentListViewUploadViewTitle'}
              titleColor={''}
              buttonList={[
                {
                  show: true,
                  id: 1,
                  btnText: 'cancel',
                  size: 'sm',
                  textColor: Colors.Custom.mainSecondaryBrown,
                  variant: BUTTON_TYPE.SECONDARY,
                  isTransBtn: false,
                  onClick: () => {
                    props.onCancel?.();
                  },
                },
                {
                  show: true,
                  isLoading: componentState.isLoading,
                  id: 1,
                  btnText: 'patientDocumentListViewUploadViewUploadBtn',
                  textColor: Colors.Custom.mainPrimaryPurple,
                  variant: BUTTON_TYPE.PRIMARY,
                  isTransBtn: false,
                  isDisabled: componentState.debounceLoading,
                  onClick: () => {
                    uploadFile();
                  },
                },
              ]}
            />
          </>
        }
      >
        <VStack space={6}>
          {!props?.isCareJourneyDocument ? <View>
            <ModalActionSelect
              label={'patientDocumentListViewSelectDocumentType'}
              isRequired={true}
              isDisabled={componentState.isLoading}
              placeholder="Select document type"
              selectedValue={componentState.selectedDocumentTypeId || undefined}
              onValueChange={(value: string) => {
                onDocumentTypeChange(value);
              }}
              data={props?.documentsCategories}
              selectItemProps={{
                key: 'id',
                label: 'value',
                value: 'id',
              }}
              customStyle={{flex: 1}}
              errors={
                componentState.showErrors &&
                !componentState.selectedDocumentTypeId
              }
              errorText={'Please select document type'}
            />
          </View> :
          <View>
            <ModalActionInput
              fieldIs={'input'}
              isDisabled={componentState.isLoading}
              label={'Title'}
              leftMargin={'0'}
              placeholder={'Add Caption To Document'}
              isRequired={true}
              onChangeText={(text: any) => {
                debounceCheckForDuplicateCaption(text);
              }}
              extraStyle={{flex: 1}}
              errors={
                componentState?.isDuplicateCaption || (componentState.showErrors &&
                (!componentState.caption || componentState?.caption?.trim()?.length === 0))
              }
              errorText={getTitleErrorText()}
            />
          </View>}

          {componentState.selectedDocumentTypeId === consentFormDocumentTypeId &&
            !props?.isCareJourneyDocument &&
          (
            <View>
              <ModalActionSelect
                label={'patientDocumentListViewSelectDocumentSubCategory'}
                isRequired={true}
                isDisabled={componentState.isLoading}
                placeholder="Select document sub category"
                selectedValue={componentState.subCategory || undefined}
                onValueChange={(value: string) => {
                  setComponentState((prev) => ({...prev, subCategory: value}));
                }}
                data={CONSENT_DOCUMENT_SUB_CATEGORY_LIST}
                selectItemProps={{
                  key: 'value',
                  label: 'label',
                  value: 'value',
                }}
                customStyle={{flex: 1}}
                errors={
                  componentState.showErrors &&
                  !componentState.subCategory
                }
                errorText={'Please select document sub category'}
              />
            </View>
          )}

          <View>
            <View>
              <HStack space={2} alignContent={'center'}>
                {[
                  componentState.fileList,
                  ...(componentState.uploadAsSingleDocument
                    ? []
                    : [componentState.secondFileList]),
                ].map((fileList, index) => {
                  let fileListKey = 'fileList';
                  if (index === 1) {
                    fileListKey = 'secondFileList';
                  }
                  return (
                    <View flex={1}>
                      <VStack>
                        {!fileList?.length && (
                            <Upload
                              key={fileListKey}
                              disabled={componentState.isLoading}
                              maxCount={componentState.uploadMaxCount}
                              // style={styles.uploadContainer}
                              listType="picture"
                              className="patient-document-upload-container"
                              // sty
                              onPreview={handlePreview}
                              beforeUpload={() => false}
                              onChange={(info) => {
                                const isInvalidFile = onBeforeUpload(info.file);
                                if (!isInvalidFile) {
                                  const tempFileList = [...info.fileList];
                                  setComponentState((prev) => ({
                                    ...prev,
                                    [fileListKey]: tempFileList,
                                  }));
                                }
                              }}
                              onRemove={(file) => {
                                setComponentState((prev) => {
                                  const prevFileList = (prev as any)[
                                    fileListKey
                                  ];
                                  const fileIndex = prevFileList.indexOf(file);
                                  const newFileList = prevFileList.slice();
                                  newFileList.splice(fileIndex, 1);
                                  return {
                                    ...prev,
                                    [fileListKey]: newFileList,
                                  };
                                });
                              }}
                              fileList={fileList}
                              accept={(ehrConfig.isAthena && componentState.selectedDocumentTypeId === imagingReportId) ? IMAGING_REPORT_UPLOAD_ACCEPTED_FILE_TYPES : componentState.uploadAcceptedFileTypes}
                            >
                              {fileList &&
                                componentState.uploadMaxCount >
                                  fileList.length && (
                                  <HStack
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                  >
                                    <Icon
                                      as={AntIcon}
                                      name="upload"
                                      size={19}
                                    />
                                    <Text
                                      style={styles.uploadText}
                                    >
                                      {!componentState.uploadAsSingleDocument &&
                                        index === 0 &&
                                        'Upload front side'}
                                      {!componentState.uploadAsSingleDocument &&
                                        index === 1 &&
                                        'Upload back side'}
                                      {componentState.uploadAsSingleDocument &&
                                        'Browse documents'}
                                    </Text>
                                  </HStack>
                                )}
                            </Upload>
                        )}
                        {fileList?.length > 0 &&
                          fileList.map((file, index) => {
                            const isFileSizeGreaterThan20MB = (file?.size && file.size / 1000 / 1000 > 20);
                            return (
                              file && (
                                <VStack width={'100%'}>
                                  <View
                                    key={fileListKey}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    borderWidth={2}
                                    borderStyle={'dashed'}
                                    padding={3}
                                    height={50}
                                    borderColor={Colors.primary['200']}
                                    borderRadius={5}
                                  >
                                    <Pressable
                                      width={'100%'}
                                      onPress={() => {
                                        handlePreview(file);
                                      }}
                                    >
                                      <HStack
                                        width={'100%'}
                                        space={2}
                                        marginRight={3}
                                        alignItems={'center'}
                                        justifyContent={'center'}
                                      >
                                        <View
                                          width={8}
                                          alignItems={'center'}
                                          justifyContent={'center'}
                                        >
                                          {getIconByFileType(file.type)}
                                        </View>

                                        <Text
                                          size={isWeb() ? 'smMedium' : 'sm'}
                                          noOfLines={1}
                                        >
                                          {file.name}
                                        </Text>

                                        <Spacer />

                                        {!componentState.isLoading && (
                                          <HStack>
                                            {
                                              componentState.isFileLoading[file.uid] &&
                                              <Spinner size="sm" />
                                            }

                                            {
                                              !componentState.isFileLoading[file.uid] && (
                                              <Tooltip  title={!isFileSizeGreaterThan20MB ? "View Document" : undefined} >
                                                <IconButton
                                                  isDisabled={isFileSizeGreaterThan20MB || false}
                                                  disabled={isFileSizeGreaterThan20MB || false}
                                                  width={8}
                                                  colorScheme="gray"
                                                  variant="ghost"
                                                  onPress={() =>
                                                    handlePreview(file)
                                                  }
                                                  icon={
                                                    <Icon
                                                      as={AntIcon}
                                                      name="eye"
                                                      size={4}
                                                      color={
                                                        Colors.Custom.Gray600
                                                      }
                                                    />
                                                  }
                                                />
                                              </Tooltip>
                                            )}

                                            {
                                              (file as any).id && !componentState.isFileLoading[file.uid] && (
                                              <Tooltip title="Download Document">
                                                <IconButton
                                                  width={8}
                                                  colorScheme="gray"
                                                  variant="ghost"
                                                  onPress={() =>
                                                    downloadFileAsBlob(
                                                      file,
                                                      true
                                                    )
                                                  }
                                                  icon={
                                                    <Icon
                                                      as={AntIcon}
                                                      name="download"
                                                      size={4}
                                                      color={
                                                        Colors.Custom.Gray600
                                                      }
                                                    />
                                                  }
                                                />
                                              </Tooltip>
                                            )}

                                            {
                                              <Tooltip title="Delete Document">
                                                <IconButton
                                                  width={8}
                                                  colorScheme="gray"
                                                  variant="ghost"
                                                  onPress={() => {
                                                    setComponentState(
                                                      (prev) => {
                                                        const fileList = (
                                                          prev as any
                                                        )[fileListKey];
                                                        fileList.splice(
                                                          index,
                                                          1
                                                        );
                                                        return {
                                                          ...prev,
                                                          [fileListKey]:
                                                            fileList,
                                                        };
                                                      }
                                                    );
                                                  }}
                                                  icon={
                                                    <Icon
                                                      as={AntIcon}
                                                      name="delete"
                                                      size={4}
                                                      color={
                                                        Colors.Custom.Gray600
                                                      }
                                                    />
                                                  }
                                                />
                                              </Tooltip>
                                            }
                                          </HStack>
                                        )}
                                      </HStack>
                                    </Pressable>
                                  </View>
                                    <View>
                                      <Text
                                        fontSize="xs"
                                        color="error.500"
                                        marginTop={2}
                                      >
                                        {!file?.size &&
                                          !(file as any)?.id &&
                                          'Please upload valid file'}
                                        {file?.size &&
                                          file.size / 1000 / 1000 > 20 &&
                                          'File size is greater than 20 MB'}
                                      </Text>
                                    </View>
                                </VStack>
                              )
                            );
                          })}
                        {componentState.showErrors && (
                          <View>
                            <Text fontSize="xs" color="error.500" marginTop={2}>
                              {!fileList?.length && 'Please upload file'}
                            </Text>
                          </View>
                        )}
                      </VStack>
                    </View>
                  );
                })}
              </HStack>

              {
                componentState.previewTitle &&
                componentState.previewObj?.type &&
                componentState.isPreviewVisible &&
                <DocumentViewer
                  fileName={componentState.previewTitle}
                  fileType={componentState.previewObj.type}
                  onClose={handlePreviewCancel}
                  fileUrl={componentState.previewObj.url}
                  isOpen={true}
                />
              }

              {componentState.progress > 0 ? (
                <Progress percent={componentState.progress} />
              ) : null}
            </View>

            <View marginTop={3}>
              <HStack alignItems={'center'}>
                <HStack space={1}>
                  <InfoOutlineIcon
                    style={styles.infoIcon}
                    size="xs"
                  />
                  {componentState.uploadAsSingleDocument && (
                    <DisplayText
                      extraStyles={{
                        color: Colors.info[700] as string,
                        fontSize: '13px',
                      }}
                      textLocalId={(ehrConfig.isAthena && componentState.selectedDocumentTypeId === imagingReportId) ?'patientDocumentsUploadSupportImagingReportFiles' : "patientDocumentsUploadSupportAllFiles"}
                    />
                  )}
                  {!componentState.uploadAsSingleDocument && (
                    <DisplayText
                      extraStyles={{
                        color: Colors.info[700] as string,
                        fontSize: '13px',
                      }}
                      textLocalId={(ehrConfig.isAthena && componentState.selectedDocumentTypeId === imagingReportId) ?'patientDocumentsUploadSupportImagingReportFiles' : "patientDocumentsUploadSupportAllFiles"}
                    />
                  )}
                </HStack>
                <Spacer />
                {(componentState.selectedDocumentTypeId ===
                  personalIdsDocumentTypeId) && !props?.isCareJourneyDocument && (
                  <Pressable
                    onPress={() =>
                      setComponentState((prev) => ({
                        ...prev,
                        uploadAsSingleDocument: !prev.uploadAsSingleDocument,
                      }))
                    }
                  >
                    {componentState.uploadAsSingleDocument && (
                      <Text color={Colors.Custom.Blue700} underline>
                        Upload as multiple files
                      </Text>
                    )}
                    {!componentState.uploadAsSingleDocument && (
                      <Text color={Colors.Custom.Blue700} underline>
                        Upload as single file
                      </Text>
                    )}
                  </Pressable>
                )}
              </HStack>
            </View>
          </View>

          {!props?.isCareJourneyDocument ? <View>
            <ModalActionInput
              fieldIs={'input'}
              isDisabled={componentState.isLoading}
              label={'patientDocumentListViewAddCaptionTitle'}
              leftMargin={'0'}
              placeholder={'Add Caption To Document'}
              isRequired={true}
              value={componentState.caption}
              onChangeText={(text: any) => {
                setComponentState((prev) => ({...prev, caption: text}));
              }}
              extraStyle={{flex: 1}}
              errors={
                componentState.showErrors &&
                (!componentState.caption || componentState?.caption?.trim()?.length === 0)
              }
              errorText={'Please add caption to document'}
            />
          </View> :
          <View>
            {/* <ModalActionInput
              fieldIs={'input'}
              isDisabled={componentState.isLoading}
              label={'Note'}
              leftMargin={'0'}
              placeholder={'Add note to document'}
              value={componentState.notes}
              onChangeText={(text: any) => {
                setComponentState((prev) => ({...prev, notes: text}));
              }}
              extraStyle={{flex: 1}}
            /> */}
          </View>}

          {/* <View>
            <ModalActionInput
              fieldIs={'textArea'}
              isDisabled={componentState.isLoading}
              label={'patientDocumentListViewAddNotesTitle'}
              leftMargin={'0'}
              placeholder={'Enter notes'}
              isRequired={false}
              value={componentState.notes}
              onChangeText={(text) => {
                setComponentState((prev) => ({...prev, notes: text}));
              }}
              extraStyle={{flex: 1, Height: 108}}
            />
          </View> */}
        </VStack>
      </Drawer>
    </>
  );
}

const styles = StyleSheet.create({
  uploadText: {
    marginLeft: 10,
  },
  infoIcon: {
    color: Colors.info[700] as string,
    marginTop: '3px',
  },
});
