import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useLazyQuery} from '@apollo/client';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../constants/Configs';
import ContactCareProgram from '../../../../../services/ContactCareProgram/ContactCareProgram';
import {BillingCategories, CPTCodeRecord} from '../MainContentView/components/BillingView/BillingInterface';
import {getAccountUUID} from '../../../../../utils/commonUtils';
import {getAwvTypeValue} from '../../../CareManagementView/CareManagementUtils';
import { useCPEventHandlers } from '../../useCPEventhandler';
import { AWV_BILLING_CATEGORY_TITLES } from '../../../CareManagementBilling/CareManagementConstants';
import { CARE_PROGRAM_EVENT_CODES } from '../../../Contacts/Leads/LeadView/LeadTableView/Helper/CareProgramConst';

interface UseAWVBillingProps {
  contactCareProgramId: string;
  commonDataContext: any;
}

export const useAWVBilling = ({
  contactCareProgramId,
  commonDataContext,
}: UseAWVBillingProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCode, setSelectedCode] = useState<string>('');
  const tenantId = getAccountUUID();

  const [getBillableCPTCodes, {error, data: billableData}] = useLazyQuery(
    ContactCareProgram.GET_BILLABLE_CPT_CODES,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    }
  );

  const billingCategories = useMemo(() => {
    const records = billableData?.getBillableCPTCodes?.records || [];
    return records.reduce((acc: BillingCategories, code: CPTCodeRecord) => {
      if (!acc[code.category as keyof BillingCategories]) {
        acc[code.category as keyof BillingCategories] = {
          codes: [],
          total: 0
        };
      }
      acc[code.category as keyof BillingCategories].codes.push(code);
      acc[code.category as keyof BillingCategories].total += code.amount;
      return acc;
    }, {});
  }, [billableData]);

  const [getCareProgramAttributes, {data: attributesData}] = useLazyQuery(
    ContactCareProgram.GET_CONTACT_CARE_PROGRAM_ADDITIONAL_ATTRIBUTES,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    }
  );

  const awvCodes = useMemo(() => {
    return (
      billableData?.getBillableCPTCodes?.records?.filter(
        (code: CPTCodeRecord) => code.category === AWV_BILLING_CATEGORY_TITLES.AWV
      ) || []
    );
  }, [billableData]);

  useEffect(() => {
    if (attributesData && awvCodes.length > 0) {
      const awvType =
        attributesData?.careProgramAdditionalAttributes?.[0]?.attributeValue
          ?.valueString;
      const awvTypevalue = getAwvTypeValue(
        awvType,
        commonDataContext.CARE_STUDIO_MLOV
      );
      const matchingCode = awvCodes.find(
        (code: CPTCodeRecord) => code.title === awvTypevalue
      );
      if (matchingCode) {
        setSelectedCode(matchingCode.cptCode);
      }
    }
  }, [attributesData, awvCodes, commonDataContext.CARE_STUDIO_MLOV]);

  const fetchData = useCallback(async () => {
    if (contactCareProgramId) {
      setIsLoading(true);
      try {
        await Promise.all([
          getBillableCPTCodes({
            variables: {
              params: {contactCareProgramId},
            },
          }),
          getCareProgramAttributes({
            variables: {
              subjectId: contactCareProgramId,
              tenantId: tenantId,
              attributeKeys: ['awvType'],
            },
          }),
        ]);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [contactCareProgramId, tenantId]);

  useCPEventHandlers({
    listenCode: `awv-type-listen-${contactCareProgramId}`,
    syncCode: `awv-type-sync-${contactCareProgramId}`,
    onSync: (eventType) => {
      if (eventType === CARE_PROGRAM_EVENT_CODES.AWV_TYPE_CHANGED) {
        fetchData();
      }
    }
  }); 

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const acpCodes = useMemo(() => {
    return (
      billableData?.getBillableCPTCodes?.records?.filter(
        (code: CPTCodeRecord) => code.category === AWV_BILLING_CATEGORY_TITLES.ACP
      ) || []
    );
  }, [billableData]);

  const cognitiveImpairmentCodes = useMemo(() => {
    return (
      billableData?.getBillableCPTCodes?.records?.filter(
        (code: CPTCodeRecord) => code.category === AWV_BILLING_CATEGORY_TITLES.COGNITIVE_ASSESSMENT
      ) || []
    );
  }, [billableData]);

  const totalBillValue = useMemo(() => {
    if (selectedCode) {
      const allCodes = [...awvCodes, ...acpCodes, ...cognitiveImpairmentCodes];
      const selectedCodeRecord = allCodes.find(code => code.cptCode === selectedCode);
      return selectedCodeRecord?.amount || 0;
    }
    return 0;
  }, [selectedCode, awvCodes, acpCodes, cognitiveImpairmentCodes]);
  
  return {
    isLoading,
    error,
    billingCategories,
    totalBillValue,
    selectedCode,
  };
};
