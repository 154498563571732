import {Dimensions, StyleSheet} from 'react-native';
import { Colors } from '../../../../styles/Colors';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
  },
  mainTitleText: {
    fontSize: 20,
    marginLeft: 25,
    marginBottom: 10,
    marginTop: 20,
  },

  containerFlex: {
    flex: 1,
  },
  scrollViewContent: {
    flexGrow: 1,
  },
  scrollView: {
    height: Dimensions.get('window').height - 200,
  },
  vStackContainer: {
    paddingTop: 10,
    justifyContent: 'center',
  },
  dateHeader: {
    justifyContent: 'space-between',
  },
  dateHeaderText: {
    fontSize: 16,
    lineHeight: 24,
    fontWeight: '500',
    color: 'black',
    paddingTop: 8,
    paddingHorizontal: 16,
    marginBottom: 17,
    marginTop: 5,
  },
  loadMoreButtonContainer: {
    alignItems: 'center',
    marginBottom: 40,
  },
  noMoreActivityText: {
    color: Colors.FoldPixel.GRAY300,
    alignSelf: 'center',
    fontSize: 14,
  },
  loadMoreButton: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 4,
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: '#F5F0FF',
    paddingVertical: 6,
    paddingHorizontal: 8,
    position: 'absolute',
    bottom: -30,
  },
  loadMoreButtonText: {
    lineHeight: 21,
    size:'smBold',
    color: Colors.Custom.PurpleColor,
  },
  loadMoreSpinner: {
    marginLeft: 5,
  },
  careActivityFeedText: {
    color: Colors.FoldPixel.GRAY400,
  },
  tabHeaderStyle: {
    minWidth: 40,
    alignContent: 'center',
    marginRight: -5,
  },
  searchBarContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
  },
  searchBarInput: {
    backgroundColor: '#FFFFFF',
  },
  searchIconContainer: {
    margin: 3,
  },
  crossIconContainer: {
    height: '40px',
    width: '40px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchBarDivider: {
    marginTop: 10,
  },
  skeletonContainer: {
    minHeight: '300px',
    padding: 4,
  },
  timelineScrollView: {
    marginTop: 10,
    height: Dimensions.get('window').height - 230,
  },
  timelineFlatList: {
    marginTop: 0,
  },
  loadMoreButtonWrapper: {
    marginBottom: 40,
  },
  emptyFilterView: {
    flex: 1,
    height: '90%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 200,
  },
  emptyFilterText: {
    color: Colors.FoldPixel.GRAY200,
    fontSize: 12,
    marginTop: 4,
  },
  noActivityView: {
    flex: 1,
    height: '90%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 200,
  }
});
