import {Colors} from '../../../styles/Colors';
import {Collapse, Select as AntSelect} from 'antd';
import React from 'react';
import {useIntl} from 'react-intl';
import {
  Box,
  HStack,
  Text,
  View,
  Skeleton,
  VStack,
  Pressable,
  Input,
  TextArea,
  Tooltip,
  Spinner,
  useToast
} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {Radar} from '@ant-design/charts';
const {Option} = AntSelect;
const {Panel} = Collapse;
import CommonService from '../../../services/CommonService/CommonService';
import HealthMapSvg from '../../common/Svg/HealthMapSvg';
import RadialBarChart from '@ant-design/plots/es/components/radial-bar';
import HardRefreshIconSvg from '../../common/Svg/HardRefreshSvg';
import UnitySvg from '../../common/Svg/UnitySvg';
import {LLMSkeletonLoader} from './LLMSkeletonLoader';
import './Styles.css';
import { CommonDataContext } from '../../../context/CommonDataContext';
import NoDataFoundWithCustomIcon from '../../common/NoDataFound/NoDataFoundWithCustomIcon';
import InfoCircleSvg from '../../common/Svg/InfoCircleSvg';
import LikeSvg from '../../common/Svg/LikeSvg';
import DislikeSvg from '../../common/Svg/DislikeSvg';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { getUserUUID } from '../../../utils/commonUtils';
import CheckSvg from '../../common/Svg/CheckSvg';
import CloseSvg from '../../common/Svg/CloseSvg';
import { formatTimestamp } from '../../../utils/llmUtils';
import { FeedbackReasonItem, IFeedbackState, ITemplateState, SelectedTemplate, Template } from './interfaces';
import { showToast, ToastType } from '../../../utils/commonViewUtils';
import { TestIdentifiers, testID } from '../../../testUtils';
import { StyleSheet } from 'react-native';
import { debounce } from 'lodash';
import { getMlovId } from '../../../utils/mlovUtils';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../constants/Configs';
import { CreateOrUpdateUserPreference, GetUserPreferencesByTypeId } from '../../../services/UserPreferences/UserPreferencesQueries';
import { MLOV_CATEGORY } from '../../../constants';
import { USER_PREFERENCES_CODES } from '../../../constants/MlovConst';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

export const LLMMap = (props: {contactUUID ?: string ,widgetName : string}) => {
  const MAX_RETRIES = 2;
  const RETRY_DELAY = 1000;
  const toast = useToast();
  const intl = useIntl();
  const commonData = useContext(CommonDataContext);
  const isSidecarContext = commonData.sidecarContext?.isSidecar;
  const commonService = CommonService.getCommonServiceInstance();
  const llmflowService = commonService.llmService;
  const userUUID = getUserUUID();

  const [stateData, setStateData] = useState({
    chartData: {
      radarChartdata: [],
      barChartData: [],
    },
    loading: true,
    isExecuted: true,
    resultID: '',
    lastSync: '',
    changeChartFlag: true,
    showAnimation: true,
    isActive: false,
  });

  const [templateState, setTemplateState] = useState<ITemplateState>({
    templateList: [],
    selectedTemplate: { templateName: '', id: '' },
    searchQuery: '',
    loading: false,
  });

  const [searchQuery, setSearchQuery] = useState('')
  const [templateList, setTemplateList] = useState<Template[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<SelectedTemplate>({ templateName: '', id: '' });
  const [feedbackState, setFeedbackState] = useState<IFeedbackState>({
    showModal: false,
    modalLoading: false,
    score: undefined,
    comment: '',
    reasonCode: {
      all: [],
      thumpsUp: [],
      thumpsDown: [],
    },
    selectedTag: {
      index: '-1',
      selected: false,
      shortCode: '',
      textInputReason: '',
    },
  });

  const mlovData = useContext(CommonDataContext);

  const userPreferencesTypeId = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.USER_PREFERENCES_TYPE,
    USER_PREFERENCES_CODES.USER_PATIENT_HEALTHMAP_DEFAULT_TEMPLATE
  );

  const [getUserTaskPreferencesByTypeId] = useLazyQuery(GetUserPreferencesByTypeId, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });


  const fetchTemplateById = async (
    userPreferredTemplateId: string,
    firstTemplate: any
  ) => {
    llmflowService
      .get('healthmap/template/' + userPreferredTemplateId, {})
      .then((result: any) => {
        if (result?.data?.template?.id) {
          setTemplateList((prev) => {
            const newTemplateId = result.data.template.id;
            const isAlreadyPresent = prev.some(
              (template) => template.id === newTemplateId
            );
            if (!isAlreadyPresent) {
              return [
                ...prev,
                {
                  templateName: result.data.template.templateName,
                  id: newTemplateId,
                },
              ];
            }
            return prev;
          });
          setSelectedTemplate({
            templateName: result?.data?.template?.templateName,
            id: result?.data?.template?.id,
          });
          fetchData(true, result?.data?.template?.id || '');
        } else {
          fetchData(true, firstTemplate?.id);
          setSelectedTemplate({
            templateName: firstTemplate?.templateName,
            id: firstTemplate?.id,
          });
        }
      })
      .catch((err: any) => {});
  };

  const fetchTemplates = async () => {
    llmflowService
      .get('/healthmap/template/name?searchQuery=' + searchQuery, {})
      .then((result: any) => {
        setTemplateList((prev) => {
          const newTemplates = result?.data?.templateList || [];
          const uniqueNewTemplates = newTemplates.filter(
            (newTemplate: Template) =>
              !prev.some((template) => template.id === newTemplate.id)
          );
          return [...prev, ...uniqueNewTemplates];
        });
      })
      .catch((error: any) => {});
  };

  const fetchInitialTemplates = async () => {
    llmflowService
      .get('/healthmap/template/name?searchQuery=' + searchQuery, {})
      .then(async (result: any) => {
        setTemplateList((prev) => {
          const newTemplates = result?.data?.templateList || [];
          const uniqueNewTemplates = newTemplates.filter(
            (newTemplate: Template) =>
              !prev.some((template) => template?.id === newTemplate?.id)
          );
          return [...prev, ...uniqueNewTemplates];
        });
        try {
          const response = await getUserPreferenceFilterQuery({
            variables: {
              userId: userUUID,
              userPreferencesTypeId: userPreferencesTypeId,
            },
          });
          if (
            response &&
            response?.data?.userPreferences &&
            response?.data?.userPreferences?.length
          ) {
            const preferenceData = response?.data?.userPreferences?.[0];
            const userPreferredTemplateId =
              JSON.parse(preferenceData['preferencesJson'])?.templateId || '';
            const res = await fetchTemplateById(
              userPreferredTemplateId,
              result?.data?.templateList?.[0]
            );
          } else {
            setSelectedTemplate({
              templateName: result?.data?.templateList?.[0]?.templateName,
              id: result?.data?.templateList?.[0]?.id,
            });
            fetchData(true, result?.data?.templateList?.[0]?.id);
          }
        } catch (error) {
          console.error(error);
        }
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  const [getUserPreferenceFilterQuery] = useLazyQuery(
    GetUserPreferencesByTypeId,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    }
  );

  const [getUserTemplatePreferencesByTypeId] = useLazyQuery(
    GetUserPreferencesByTypeId,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    }
  );

  const [
    addUpdatePreferenceQuery,
    {loading: isAddUpdatePreferenceQueryLoading},
  ] = useMutation(CreateOrUpdateUserPreference, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });

  useEffect(() => {
    getFeedBackStatus(stateData.resultID, userUUID);
  }, [stateData.resultID, feedbackState.reasonCode.all]);

  useEffect(() => {
    setStateData(prev => ({ ...prev, showAnimation: false }));
  }, [feedbackState.showModal, feedbackState.score, feedbackState.selectedTag.index]);

  const loaderTemeplateValues = [
    intl.formatMessage({id: 'analyzingHealth'}),
    intl.formatMessage({id: 'analyzingReports'}),
    intl.formatMessage({id: 'synthesizingLearnings'}),
    intl.formatMessage({id: 'creatingHealthMap'}),
  ];

  useEffect(() => {
    fetchInitialTemplates()
  }, []);

  useEffect(() => {
    fetchTemplates();
  }, [searchQuery]);

  const BarChartConfig = {
    xField: 'key',
    yField: 'value',
    radius: 0.8,
    tooltip: {
      customContent: (title:any, items:any) => {
        return (
          <div style={reactStyles.padding10}>
            {title && <strong>{title}</strong>}
            {items.map((item:any, index:any) => (
              <>
              {item?.value != -1 ? <div key={index} style={reactStyles.paddingTopBottom5}>
                {'Score'}: {item?.value}
              </div> : <></>}
              <div key={index}>
                {'Inference'}: {item?.data?.reason || ''}
              </div> </>
            ))}
          </div>
        );
      },
    },
    scale: {
      y: {
        domain: [0, 200],
      },
    },
    innerRadius: 0.2,
    meta: {
      value: {
        max: 100,
      },
    },
  };

  const RadarChartConfig = {
    data: stateData.chartData.radarChartdata,
    xField: 'key',
    yField: 'value',
    area: {
      style: {
        fillOpacity: 0.2,
      },
    },
    tooltip: {
      customContent: (title:any, items:any) => {
        return (
          <div style={reactStyles.padding10}>
            {title && <strong>{title}</strong>}
            {items.map((item:any, index:any) => (
              <div key = {index}>
                <div key={index} >
                { item?.value != -1 ? <div key={index} style={reactStyles.paddingTop5}>
                {'Score'}: {item?.value}
               </div> : <></>}</div>
               <div key={index} style={reactStyles.paddingTop5}>
                {'Inference'}: {item?.data?.reason || ''}
               </div>
              </div>
            ))}
          </div>
        );
      },
    },
    meta: {
      value: {alias: 'reason'},
    },
    yAxis: {
      min: -1,
      max: 5,
      offset: 0,
      label: {
        formatter: (val: number) => {
          return val == -1 ? '' : val;
        },
      },
    },
    xAxis: {
      label: {
        offset: 10,
        position: 'center',
        style: {
          fontSize: 10,
          textAlign: 'center',
        },
      },
    },

    axis: {
      x: {
        title: false,
        grid: true,
      },
      y: {
        gridAreaFill: 'rgba(0, 0, 0, 0.04)',
        label: false,
        title: false,
      },
    },
  };

  const fetchReasonCodes = () => {
    llmflowService
      .get(`/v1/feedback/reason_code`)
      .then((result: any) => {
        const filteredReasonCodeThumbDown = result?.data?.reason_codes?.filter(
          (reason: any) => !reason.reaction_code || reason.reaction_code == null
        );
        const filteredReasonCodeThumbup = result?.data?.reason_codes?.filter(
          (reason: any) => reason.reaction_code || reason.reaction_code == null
        );
        setFeedbackState(prev => ({
          ...prev,
          reasonCode: {
            all: result?.data?.reason_codes,
            thumpsUp: filteredReasonCodeThumbup,
            thumpsDown: filteredReasonCodeThumbDown,
          },
        }));
      })
      .catch((error: any) => {

      });
  };

  const postFeedback = () => {
    llmflowService
      .post(`/v1/feedback`, {
        user_id: userUUID,
        result_id: stateData.resultID,
        score: feedbackState.score,
        comment:
          feedbackState.selectedTag.shortCode === 'Other'
            ? feedbackState.selectedTag.textInputReason
            : feedbackState.selectedTag.shortCode,
      })
      .then((result: any) => {
        setFeedbackState(prev => ({ ...prev, modalLoading: false, showModal: false }));
      })
      .catch((error: any) => {
        setFeedbackState(prev => ({ ...prev, modalLoading: false, showModal: false }));
        showToast(
          toast,
          'Unable to save the Feedback',
          ToastType.error,
          1000
        );
        getFeedBackStatus(stateData.resultID, userUUID)
      });
  };

  const getFeedBackStatus = (resultID: any, userID: any) => {
    resultID  &&
    llmflowService
      .get(`v1/feedback/status`, {
        params: {
          result_id: resultID,
          user_id: userID,
        },
      })
      .then((result: any) => {
        setFeedbackState(prev => ({
          ...prev,
          comment: result?.data?.data?.comment,
          score: result?.data?.data?.score,
        }));
        if (result?.data?.data?.score !== -1 && feedbackState.reasonCode.all.length) {
          const isShortCodePresent = feedbackState.reasonCode.all.some(
            (item: any) => item.short_code === result?.data?.data?.comment
          );
          if (isShortCodePresent) {
            setFeedbackState(prev => ({
              ...prev,
              selectedTag: {
                ...prev.selectedTag,
                index: result?.data?.data?.comment,
                selected: true,
                shortCode: result?.data?.data?.comment,
                textInputReason: '',
              },
            }));
          } else if (result?.data?.data?.comment) {
              setFeedbackState((prev: any) => ({
                ...prev,
                selectedTag: {
                  ...prev.selectedTag,
                  index: 'Other',
                  selected: true,
                  shortCode: 'Other',
                  textInputReason: result?.data?.data?.comment,
                },
              }));
          }
        } else {
          setFeedbackState(prev => ({
            ...prev,
            selectedTag: {
              index: '-1',
              selected: false,
              shortCode: '',
              textInputReason: '',
            },
          }));
        }
      })
  };

  const renderSelectElem = (
    item: any,
    isSelectedValueRender: boolean
  ) => {
    return (
      <View
        alignItems={'center'}
        my={1.5}
        maxW={isSelectedValueRender ? '100%' : undefined}
      >
        <Text
          size={'smRegular'}
          {...(isSelectedValueRender ? {maxW: '100%', isTruncated: true} : {})}
        >
          {item?.templateName}
        </Text>
     </View>
    );
  };
  const renderTags = (reasonCode: FeedbackReasonItem, index: string) => {
    const isSelected = feedbackState.selectedTag.index === index && feedbackState.selectedTag.selected;

    return (
          <Pressable
            key={reasonCode.id}
            onPress={() => {
              setFeedbackState(prev => ({
                ...prev,
                selectedTag: {
                  ...prev.selectedTag,
                  index: index,
                  selected: !isSelected,
                  shortCode: !isSelected? reasonCode.short_code :'',
                },
              }));
            }}
          >
            <View
              style={{
                marginTop: 4,
                borderRadius: 4,
                paddingHorizontal: 4,
                backgroundColor: isSelected
                  ? Colors.FoldPixel.PRIMARY100
                  : Colors.FoldPixel.GRAY50,
                marginRight: 4,
                borderWidth: 0.1,
                borderColor: Colors.FoldPixel.GRAY50,
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Text
                style={{
                  color: isSelected ? Colors.FoldPixel.PRIMARY300 : Colors.Custom.Gray500,
                  fontSize: 14,
                  marginRight: 5,
                }}
              >
                {reasonCode.short_code}
              </Text>
              {isSelected && (
                <Pressable
                  onPress={() => {
                    setFeedbackState(prev => ({
                      ...prev,
                      selectedTag: {
                        ...prev.selectedTag,
                        previndex:undefined,
                        selected: false,
                        shortCode: '',
                      },
                    }));
                  }}
                >
                  <AntIcon color={isSelected ? Colors.FoldPixel.PRIMARY300 : Colors.Custom.Gray500} name='close' />
                </Pressable>
              )}
            </View>
          </Pressable>
    );
  };

  useEffect(() => {
    fetchReasonCodes();
  }, []);

  const fetchData = (isCached: boolean , templateId : string) => {
    llmflowService
      .post(`/unity/healthmap`, {
        contactUuid: props?.contactUUID,
        templateId: templateId,
        isCatched: isCached,
      })
      .then((result: any) => {
        const filteredData = Array.isArray(result.data.list)
          ? result.data.list.map((item: any) => ({
              key: item.key,
              value: item.score == -1 ? 0 : (item.score * 100) / item.max,
              reason: item?.reason || ''
            }))
          : [];

        setStateData(prev => ({
          ...prev,
          chartData: {
            radarChartdata: Array.isArray(result?.data?.list) ? result?.data?.list.map((item:any) => ({
              ...item,
              value: item.score === -1 ? 0 : item.score
            })) : [],
            barChartData: filteredData,
          },
          loading: false,
          isExecuted: true,
          resultID: result?.data?.id,
          lastSync: formatTimestamp(result?.data?.syncDate),
          isActive: false,
        }));

        setFeedbackState(prev => ({
          ...prev,
          showModal: false,
          selectedTag: {
            index: '-1',
            selected: false,
            shortCode: '',
            textInputReason: '',
          },
        }));

        getFeedBackStatus(result?.data?.id, userUUID);
      })
      .catch((error: any) => {
        setStateData(prev => ({
          ...prev,
          chartData: {
            radarChartdata: [],
            barChartData: [],
          },
          loading: false,
          isExecuted: false,
          isActive: false,
        }));
      });
  };

  const callAddUpdateHealthMapTemplatePreference = async (templateId: string) => {
    const updateSynopsisTemplatePreferenceObject: any = {
      userId: userUUID,
      userPreferencesTypeId: userPreferencesTypeId,
      preferencesJson: JSON.stringify({ templateId: templateId }),
      preferencesModelVersion: 'v1',
    }
    const userPreferenceResponse = await getUserTaskPreferencesByTypeId({
      variables: {
        userId: userUUID,
        userPreferencesTypeId: userPreferencesTypeId,
      },
    });
    const data = userPreferenceResponse?.data;
    if (
      data?.userPreferences?.length > 0 &&
      data?.userPreferences?.[0]?.preferencesJson
    ) {
      updateSynopsisTemplatePreferenceObject.id =
        data?.userPreferences?.[0]?.id;
    }
    await addUpdatePreferenceQuery({
      variables: {
        object: updateSynopsisTemplatePreferenceObject,
      },
      onCompleted: (response) => {
        const preferenceData = response?.createUserPreference;
        const userPreferredTemplateId = JSON.parse(preferenceData['preferencesJson'])?.templateId || '';
        const userTemplatePreferenceId = JSON.parse(preferenceData['preferencesJson'])?.id || '';
      },
      onError: (error: any) => {
      }
    });
  }

  const lastSyncMessage = `Last Synced: ${stateData.lastSync}`
  const chartsList = [
    {
      key: 1,
      label: 'Radar',
    },
    {
      key: 2,
      label: 'Bar',
    },
  ];

  return (
    <Box
      mb={2}
      shadow='0px 4px 8px -2px #10182819, 0px 2px 4px -2px #101828f'
      borderRadius={8}
      borderColor={Colors.Custom.BorderColor}
      borderWidth={1}
    >
      <Collapse
        className='llm-collapse'
        defaultActiveKey={[1]}
        collapsible='header'
        style={{
          borderWidth: 0.5,
          ...(!isSidecarContext && {
            borderColor: Colors.Custom.ContainerBGColor,
            // backgroundImage:
            //   'linear-gradient(237.73deg, #D2EBEF 0.27%, #F5E4FE 102.44%)',
          }),
          borderRadius: 8
        }}
        expandIconPosition='end'
      >
        <Panel
          extra={
            <AntSelect
              placement='bottomRight'
              style={{ width: 150, flex: 1, textOverflow: 'ellipsis', overflow: 'hidden' }}
              loading={stateData.loading}
              disabled= {stateData.loading}
              allowClear={false}
              value={{
                key: selectedTemplate.id,
                label: renderSelectElem(
                  templateList.filter(
                    (item: any) => item?.id === selectedTemplate.id
                  )?.[0],
                  true
                ),
                value: selectedTemplate.id,
              }}
              showSearch
              onSearch={debounce((inputValue: string) => {
                setSearchQuery(inputValue)
              }, 500)}
              filterOption={(inputValue: string, option: any) => {
                return (option?.title || '')
                  .toLowerCase()
                  .includes(inputValue.toLowerCase());
              }}
              onChange={(templateId: any) => {
              setStateData(prev => ({
                ...prev,
                chartData: {
                  barChartData: [],
                  radarChartdata: [],
                },
                loading: true,
                isExecuted: true,
              }));
                const selectedTemplate = templateList.find((template) => template?.id == templateId);
                if (selectedTemplate) {
                  setSelectedTemplate({
                    id: selectedTemplate?.id || '',
                    templateName: selectedTemplate?.templateName || '',
                  });
                  fetchData( true,templateId);
                  callAddUpdateHealthMapTemplatePreference(selectedTemplate.id ||  '')
                }
              }}
              {...testID(TestIdentifiers.llm.patientSynopsis.patientSynopsisTemplateDropDown)}
            >
              !isTemplateListEmpty ?
              {templateList.map((template: any) => {
                return (
                  <Option
                    value={template?.id}
                    key={template?.id}
                    title={template?.templateName}
                  >
                    {template.templateName}
                  </Option>
                );
              })} : null;
            </AntSelect>
          }
          header={
            <HStack alignItems={'center'} space={3}>
             <span {...testID(TestIdentifiers.llm.healthMap.healthMapIcon)}><HealthMapSvg /></span>
              <VStack>
                <Text
                  color={Colors.FoldPixel.GRAY400}
                  size={'lgMedium'}
                  {...testID(TestIdentifiers.llm.healthMap.healthMapTitle)}
                >
                  {props.widgetName ? props.widgetName : intl.formatMessage({id: 'healthMap'})}
                </Text>
              </VStack>
            </HStack>
          }
          key={'1'}
        >
          <div className='llm-content'>
            <View padding={1}>
              {stateData.isExecuted ? (
                stateData.loading ? (
                  <View padding={4} height={200} justifyContent={'center'}>
                    <LLMSkeletonLoader texts={loaderTemeplateValues} iconKey={'llmmap' + 'skeleton'}/>
                  </View>
                ) : stateData.chartData.radarChartdata.length > 0 ? (
                    <View
                      width={isSidecarContext ? 220 : 350}
                      height={isSidecarContext ? 220 : 350}
                      justifyItems={'center'}
                      alignSelf={'center'}
                    >
                      <Radar {...RadarChartConfig} animation={false} />
                    </View>
                ) : (
                  <View padding={3}>
                    <NoDataFoundWithCustomIcon
                      icon={<InfoCircleSvg></InfoCircleSvg>}
                      displayString={intl.formatMessage({id: 'noDataFound'})}
                    ></NoDataFoundWithCustomIcon>
                  </View>
                )
              ) : (
                <Text
                  style={styles.text3}
                  color={Colors.Custom.Gray500}
                  padding={4}
                >
                  {intl.formatMessage({id: 'errorInFetchingData'})}
                </Text>
              )}
            </View>
            <HStack
              space={2}
              style={{
                flexDirection: 'row',
                paddingHorizontal: 12,
                display: 'flex',
                justifyContent: 'space-between',
                paddingVertical: 5,
                borderBottomLeftRadius: 9,
                borderBottomRightRadius: 9,
              }
              }
            >
              <View
                style={{flexDirection: 'row', alignItems: 'center', flex: 1}}
                {...testID(TestIdentifiers.llm.healthMap.healthMapPoweredByUnity)}
              >
                <UnitySvg iconKey={'UnitySvg' + 'UnitySvg'}/>
                <Text
                  marginLeft={2}
                  size={'xsNormal'}
                  fontWeight={600}
                  noOfLines={1}
                  style={styles.text2}
                >
                  {intl.formatMessage({id: 'poweredByUnity'})}
                </Text>

                <Text
                  marginLeft={2}
                  size={'xsNormal'}
                  fontWeight={550}
                  noOfLines={1}
                  style={styles.text}
                >
                  {intl.formatMessage({id: 'beta'})}
                </Text>
              </View>
              {!stateData.loading &&
                stateData.isExecuted &&
                stateData.chartData.barChartData.length > 0 && (
                  <>
                    <Tooltip
                      label={intl.formatMessage({id: 'dislike'})}
                      placement='top'
                      hasArrow
                    >
                      <Pressable
                        flexDirection={'row'}
                        alignItems={'center'}
                        onPress={() => {
                          setFeedbackState((prev: any) => ({ ...prev, score: 0, comment: '', showModal: true }));
                        }}
                        {...testID(TestIdentifiers.llm.healthMap.healthMapThumpsDown)}
                      >
                        <DislikeSvg
                          isActive={feedbackState.score === 0 ? true : false}
                          keyy={'alerttable'}
                        />
                      </Pressable>
                    </Tooltip>
                    <Tooltip
                      label={intl.formatMessage({id: 'like'})}
                      placement='top'
                      hasArrow
                    >
                      <Pressable
                        flexDirection={'row'}
                        alignItems={'center'}
                        onPress={() => {
                          setFeedbackState((prev: any) => ({ ...prev, score: 1, comment: '', showModal: true }));
                        }}
                        {...testID(TestIdentifiers.llm.healthMap.healthMapThumbsUp)}
                      >
                        <LikeSvg
                          isActive={feedbackState.score === 1 ? true : false}
                          keyy={'alerttable'}
                        />
                      </Pressable>
                    </Tooltip>
                  </>
                )}
              <Tooltip
                isDisabled={!stateData.lastSync}
                label={lastSyncMessage}
                placement='top right'
                hasArrow
              >
                <Pressable
                  flexDirection={'row'}
                  alignItems={'center'}
                  onPress={() => {
                    setStateData(prev => ({ ...prev, isActive: true }));
                    fetchData(false, selectedTemplate?.id || '');
                    setStateData(prev => ({
                      ...prev,
                      chartData: {
                        barChartData: [],
                        radarChartdata: [],
                      },
                      loading: true,
                      isExecuted: true,
                    }));
                  }}
                  {...testID(TestIdentifiers.llm.healthMap.healthMapHardRefresh)}
                >
                <HardRefreshIconSvg isActive={stateData.isActive} iconKey={'llmmap'}/>
              </Pressable>
              </Tooltip>
            </HStack>
            {!stateData.loading && feedbackState.showModal && feedbackState.reasonCode.all && (
              <VStack
                borderColor={Colors.Custom.Gray200}
                borderWidth={'1'}
                borderRadius={12}
                flex={1}
                margin={3}
              >
                <HStack padding={2} flex={1}>
                  <Text
                    fontWeight={500}
                    fontSize={14}
                    lineHeight={14.4}
                    color={Colors.FoldPixel.GRAY300}
                    flex={2}
                  >
                    {feedbackState.score === 0 ? 'Help us to Improve.' : null}
                    {feedbackState.score === 1 ? 'Thanks! Any more feedback?' : null}
                  </Text>
                  <Pressable
                    marginLeft={2}
                    onPress={() => {
                      postFeedback();
                      setFeedbackState(prev => ({ ...prev, modalLoading: true }));
                    }}
                    flex={0.1}
                  >
                    {feedbackState.modalLoading ? (
                      <Spinner></Spinner>
                    ) : (
                      <CheckSvg></CheckSvg>
                    )}
                  </Pressable>
                  <Pressable
                    marginLeft={2}
                    flex={0.1}
                    onPress={() => {
                      setFeedbackState(prev => ({ ...prev, showModal: false, score: undefined, comment: '' }));
                      getFeedBackStatus(stateData.resultID, userUUID);
                    }}
                  >
                    <CloseSvg></CloseSvg>
                  </Pressable>
                </HStack>
                <View
                  flexWrap={'wrap'}
                  flexDir={'row'}
                  paddingX={2}
                  paddingBottom={feedbackState.selectedTag.shortCode === 'Other' ? 0 : 2}
                >
                  <>
                    {feedbackState.score == 0 &&
                      feedbackState.reasonCode.thumpsDown.map((name: FeedbackReasonItem, index: number) =>
                        renderTags(name, name.short_code)
                      )}
                    {feedbackState.score == 1 &&
                      feedbackState.reasonCode.thumpsUp.map((name: FeedbackReasonItem, index: number) =>
                        renderTags(name, name.short_code)
                      )}
                  </>
                </View>
                {feedbackState.selectedTag.shortCode === 'Other' && feedbackState.selectedTag.index && (
                  <HStack flex={1} padding={2}>
                    <TextArea
                      value={feedbackState.selectedTag.textInputReason}
                      _focus={{
                        borderColor: Colors.Custom.Gray300,
                      }}
                      borderColor={Colors.Custom.Gray300}
                      placeholderTextColor={Colors.Custom.Gray300}
                      width={'100%'}
                      numberOfLines={5}
                      onChangeText={(input) => {
                        setFeedbackState(prev => ({
                          ...prev,
                          selectedTag: {
                            ...prev.selectedTag,
                            textInputReason: input,
                          },
                        }));
                      }}
                      placeholder='Provide Additional Feedback'
                    />
                  </HStack>
                )}
              </VStack>
            )}
          </div>
        </Panel>
      </Collapse>
    </Box>
  );
};

const styles = StyleSheet.create({
  text: {
    color: Colors.FoldPixel.GRAY200,
    fontFamily: 'Courier',
    textAlign: 'center',
  },
  text2: {
    backgroundImage:
      'linear-gradient(237.73deg, #1E9DAE 0.27%, #D478FF 102.44%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    textAlign: 'center',
  },
  text3: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  view: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  hStack: {
    flexDirection: 'row',
    paddingHorizontal: 12,
    justifyContent: 'space-between',
    paddingVertical: 5,
    borderBottomLeftRadius: 9,
    borderBottomRightRadius: 9,
  }
});

const reactStyles: Record<string, React.CSSProperties> = {
  padding10: {
    padding: '10px'
  },
  paddingTopBottom5: {
    paddingTop: '5px',
    paddingBottom: '5px'
  },
  paddingTop5: {
    paddingTop: '5px'
  }
};
