import {Drawer, Select, Spin} from 'antd';
import {Spinner, Text, View, useToast} from 'native-base';
import React, {useContext} from 'react';
import {useIntl} from 'react-intl';
import {BUTTON_TYPE} from '../../../../../constants';
import {Colors} from '../../../../../styles';
import {ToastType, showToast} from '../../../../../utils/commonViewUtils';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import AddOrUpdateCommunicationType from './AddOrUpdateCommunicationType';
import {
  createMessageCommunicationTypeInBulk,
  getCommunicationTypes,
  getCommunicationTypesByMessageUuids,
  removeMessageCommunicationType,
} from './CommunicationTypesUtils';
import {
  IAssignCommunicationTypeDrawerProps,
  IAssignCommunicationTypeDrawerState,
} from './interfaces';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {styles} from './CommunicationTypesStyles';

const AssignCommunicationTypeDrawer = (
  props: IAssignCommunicationTypeDrawerProps
) => {
  const [state, setState] = React.useState<IAssignCommunicationTypeDrawerState>(
    {
      loading: false,
      submitting: false,
      selectedIds: [],
      searchString: '',
      data: [],
      filteredData: [],
      isAssignDrawerOpen: false,
      currentIds: [],
    }
  );

  const {messageUuids, onClose, onSave} = props;
  const intl = useIntl();
  const toast = useToast();
  const commonData = useContext(CommonDataContext);
  const isSideCarContext = commonData.sidecarContext?.isSidecar;

  const getCommunicationTypeData = async () => {
    setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    try {
      const communicationTypePromise = getCommunicationTypes({pageSize: 100});
      const typesByMessageUuidPromise =
        getCommunicationTypesByMessageUuids(messageUuids);
      const response = await Promise.all([
        communicationTypePromise,
        typesByMessageUuidPromise,
      ]);
      const first = response[0];
      const second = response[1];
      const data = first?.data?.communicationTypes || [];
      const typesByMessageUuid = (second?.data || [])?.map(
        (item) => item.communicationType
      );
      const selectedIds = typesByMessageUuid.map((item) => item.id || '');
      setState((prev) => {
        return {
          ...prev,
          data: data,
          loading: false,
          filteredData: data,
          selectedIds: selectedIds,
          currentIds: selectedIds,
        };
      });
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          data: [],
          loading: false,
        };
      });
    }
  };

  const onSubmit = async () => {
    setState((prev) => {
      return {
        ...prev,
        submitting: true,
      };
    });
    try {
      const currentIds = state.currentIds;
      const promiseList = currentIds.map((item) => {
        return removeMessageCommunicationType({
          messageUuid: messageUuids[0],
          communicationTypeId: item,
        });
      });
      await Promise.all(promiseList);
      const selectedIds =
        typeof state.selectedIds === 'string'
          ? [state.selectedIds]
          : state.selectedIds;
      await createMessageCommunicationTypeInBulk({
        messageUuids,
        communicationTypeId: selectedIds[0],
        contactUuid: props.contactUuid,
      });
      showToast(
        toast,
        intl.formatMessage({id: 'addCommunicationTypeSuccess'}),
        ToastType.success
      );
      setState((prev) => {
        return {
          ...prev,
          submitting: false,
        };
      });
      onSave();
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          submitting: false,
        };
      });
    }
  };

  const handleOnSearch = (text: string) => {
    if (text && text.length > 0) {
      const result = state.data.filter((item) => {
        return item.name.toLowerCase().includes(text.toLowerCase());
      });
      setState((prev) => {
        return {
          ...prev,
          filteredData: result,
        };
      });
    } else {
      setState((prev) => {
        return {
          ...prev,
          filteredData: prev.data,
        };
      });
    }
  };

  React.useEffect(() => {
    getCommunicationTypeData();
  }, []);

  return (
    <Drawer
      open={props.isOpen}
      onClose={onClose}
      width={isSideCarContext ? '100%' : '33vw'}
      mask={isSideCarContext ? false : true}
      title={
        <ModalActionTitle
          title={'Assign Type'}
          titleColor={''}
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: isSideCarContext ? 'back' : 'close',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: onClose,
            },
            {
              show: true,
              id: 2,
              btnText: 'save',
              textColor: Colors.Custom.mainPrimaryPurple,
              isLoading: state.submitting,
              variant: BUTTON_TYPE.PRIMARY,
              isTransBtn: false,
              onClick: onSubmit,
            },
          ]}
        />
      }
    >
      {state.loading && (
        <View>
          <Spinner />
        </View>
      )}

      {!state.loading && (
        <>
          <View flex={1}>
            <Text size={'smMedium'} style={styles.assignTypeSubtitle}>
              {intl.formatMessage({id: 'assignTypeSubtitle'})}
            </Text>

            <Select
              size="large"
              showSearch
              allowClear
              filterOption={false}
              value={state.selectedIds}
              onSearch={(text) => {
                handleOnSearch(text);
              }}
              onChange={(value) => {
                setState((prev) => {
                  return {
                    ...prev,
                    selectedIds: value,
                  };
                });
              }}
              placeholder="Select Communication Types"
              loading={state.loading}
              notFoundContent={state.loading && <Spin size="small" />}
            >
              {state.filteredData.map((item) => {
                return (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                );
              })}
            </Select>
          </View>
        </>
      )}

      {state.isAssignDrawerOpen && (
        <AddOrUpdateCommunicationType
          isOpen={state.isAssignDrawerOpen}
          onClose={() => {
            setState((prev) => {
              return {
                ...prev,
                isAssignDrawerOpen: false,
              };
            });
          }}
          onSave={(type) => {
            setState((prev) => {
              return {
                ...prev,
                isAssignDrawerOpen: false,
                data: [...prev.data, type],
                filteredData: [...prev.filteredData, type],
              };
            });
          }}
        />
      )}
    </Drawer>
  );
};

export default AssignCommunicationTypeDrawer;
