import {Popover} from 'antd';
import {TooltipPlacement} from 'antd/lib/tooltip';
import {Divider, HStack, Pressable, Text, View, VStack} from 'native-base';
import React, {useState} from 'react';
import {GestureResponderEvent} from 'react-native';
import Feather from 'react-native-vector-icons/Feather';
import {Colors} from '../../../styles';

export interface IFHPopoverAction {
  code: string;
  name: string;
}

export interface IFHActionPopoverProps {
  actions: IFHPopoverAction[];
  placement?: TooltipPlacement;
  onActionClick: (actionCode: string, event?: GestureResponderEvent) => void;
  isPrimary?: boolean;
  customIconSize?: number
}

const FHActionPopover = (props: IFHActionPopoverProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleVisibleChange = (visible: any) => {
    setIsOpen(visible);
  };

  const content = (
    <VStack style={{padding: 0, borderRadius: 16}}>
      {props.actions.map((action: IFHPopoverAction, index: number) => {
        return (
          <Pressable
            key={index}
            onPress={(event) => {
              setIsOpen(!isOpen);
              props.onActionClick(action.code, event);
            }}
          >
            <HStack>
              <Text size={'smRegular'} color={Colors.FoldPixel.GRAY400}>{action.name}</Text>
            </HStack>
            {index < props.actions.length - 1 && <Divider my={1} />}
          </Pressable>
        );
      })}
    </VStack>
  );
  return (
    <View>
      <Popover
        overlayInnerStyle={{padding: 0, borderRadius: 16}}
        overlayStyle={{padding: 0}}
        placement={props.placement || 'bottomRight'}
        trigger="click"
        visible={isOpen}
        onVisibleChange={handleVisibleChange}
        content={content}
      >
        {props.isPrimary ? (
          <View
            padding={2}
            backgroundColor={Colors.Custom.Primary50}
            borderRadius={'50%'}
          >
            <Feather
              name="more-vertical"
              size={14}
              color={Colors.primary['300']}
            />
          </View>
        ) : (
          <View padding={2} cursor={'pointer'}>
            <Feather name="more-vertical" size={props?.customIconSize || 14} style={{marginRight: 14}} />
          </View>
        )}
      </Popover>
    </View>
  );
};

export default FHActionPopover;
