import {useCallback} from 'react';
import {useSelectedStepInCareProgram} from './useSelectedStepInCareProgram';
import {CARE_PROGRAM_STEP_TYPE} from '../../../../../../common/MemebersView/constant';
import {useContactCareProgramContext} from '../../../ContactCareProgram.context';
import {ContactCareProgramReducerAction} from '../../../reducer';

export const useCareProgramAdditionalAttribute = () => {
  const {dispatch, refetchMasterData} = useContactCareProgramContext();
  const {selectedStep} = useSelectedStepInCareProgram();
  const careProgramStepTypeCode = selectedStep?.careProgramStepType?.code;

  const handleUpdateCareProgram = useCallback(
    async (actionCode?: string, actionData?: any) => {
      switch (careProgramStepTypeCode) {
        case CARE_PROGRAM_STEP_TYPE.ANNUAL_VISIT:
        case CARE_PROGRAM_STEP_TYPE.APPOINTMENT:
          dispatch?.({
            type: actionCode as ContactCareProgramReducerAction,
            payload: {
              additionalAttribute: actionData,
              subjectTypeCode: 'CARE_PROGRAM_STEP',
            },
          });
          break;
      }
    },
    [careProgramStepTypeCode]
  );

  return {
    handleUpdateCareProgram,
  };
};
