import {Pressable, View} from 'react-native';
import React from 'react';
import {styles} from './TeamMemberEmailStyles';
import {ITeamMemberEmailProps} from '../..';
import { Text, Tooltip } from 'native-base';

const TeamMemberEmail = (props: ITeamMemberEmailProps) => {
  const {email, onProfileClick} = props;
  return (
    <View style={styles.emailView}>
      <Tooltip label={email} placement="top">
        <Pressable onPress={onProfileClick}>
          <Text size={'xsNormal'} style={styles.emailText} noOfLines={1} maxW={'175'} isTruncated> {email} </Text>
        </Pressable>
      </Tooltip>
    </View>
  );
};

export default TeamMemberEmail;
