import { Drawer } from 'antd';
import {
  HStack, Icon, Pressable, Text, useToast, View,
  VStack
} from 'native-base';
import React, { useEffect, useState } from 'react';
import {useIntl} from 'react-intl';
import { Dimensions } from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { Colors } from '../../../styles/Colors';
import { getAccountUUID, isLeadGroupsPage } from '../../../utils/commonUtils';
import { showToast, ToastType } from '../../../utils/commonViewUtils';
import { isWeb } from '../../../utils/platformCheckUtils';
import { capitalizeText } from '../../common/ContactRelationView/ContactRelationUtils';
import { DisplayText } from '../../common/DisplayText/DisplayText';
import { NoTagsSvg } from '../../common/Svg';
import GroupIcon from '../../common/Svg/GroupIcon';
import GroupIconOutline from '../../common/Svg/GroupIconOutline';
import GroupListIcon from '../../common/Svg/GroupListIcon';
import { CampaignSegmentForm } from '../../RightSideContainer/ContentManagement/CampaignManagement/CampaignSegmentForm';
import { ICampaignSegmentFormState } from '../../RightSideContainer/ContentManagement/CampaignManagement/interfaces';
import { getPreFilterMemberStatus, patientFilterForCreateSegment } from '../../RightSideContainer/MembersManagement/Helper';
import {
  createCampaignSegment, createOrUpdateLeadGroups, getCampaignSegmentRules, getGroupDetailById, getMemberGroupListByContactId
} from '../../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import {getFrequencyValue} from '../../RightSideContainer/ContentManagement/CampaignManagement/utils';
import { POPULATION_GROUP_TYPES } from '../../../constants';
import { StyleSheet } from 'react-native';

export const MemberGroupList = (props: any) => {
  const [groupList, setGroupList] = useState([] as any);
  const {width, height} = Dimensions.get('window');
  const finalWidth = width * 0.5;
  const [openModal, setOpenModal] = useState<{
    segmentData: any;
    isOpen: boolean;
    customDrawerWidth?: number;
  }>({
    segmentData: {} as any,
    isOpen: false,
    customDrawerWidth: undefined,
  });
  const intl = useIntl()
  const toast = useToast();
  const accountUuid = getAccountUUID();
  useEffect(() => {
    getGroupList();
  }, [props.contactId]);

  const getGroupList = async () => {
    const result = await getMemberGroupListByContactId({
      contactId: props.contactId,
      accountUuid: accountUuid
    });
    const groupList = (result?.data.filter((group: any) => !!group.name) || []);
    setGroupList(groupList);
  };

  const createOrUpdateGroupPromise = isWeb() && isLeadGroupsPage()
    ? createOrUpdateLeadGroups
    : createCampaignSegment;

  const updateGroupContact = (
    campaignSegmentFormData: ICampaignSegmentFormState,
    initialLocationIdsList?: string[],
  ) => {
    const selectedAccountLocations = campaignSegmentFormData?.selectedAccountLocations || [];
    let locationIdList = selectedAccountLocations.map((location) => {
      return { locationId: location?.uuid };
    });
    const selectedLocationIds = new Set(selectedAccountLocations.map(location => location?.uuid));
    const deletedLocations = initialLocationIdsList
      ?.filter(locationId => !selectedLocationIds.has(locationId))
      .map(locationId => ({ locationId, isDeleted: true }));

    locationIdList = locationIdList.concat(deletedLocations || []);
    createOrUpdateGroupPromise({
      name: campaignSegmentFormData?.audienceName || '',
      id: campaignSegmentFormData?.id || '',
      description: campaignSegmentFormData?.description || '',
      metadata: {
        patientFilter: patientFilterForCreateSegment(campaignSegmentFormData),
      },
      ...(!campaignSegmentFormData.patientList?.length && !(isWeb() && isLeadGroupsPage())) && (getPreFilterMemberStatus(campaignSegmentFormData)),
      executionFrequency: campaignSegmentFormData?.executionFrequency || '',
      popupationGroupId: campaignSegmentFormData?.populationGroupId || '',
      locationIdList: locationIdList,
      automationToUpdate: campaignSegmentFormData?.automationToUpdate || [],
    }).then(async (resp: any) => {
      if (resp?.id) {
        let popGroupResponse = resp;
        if (resp?.inputRuleId) {
          popGroupResponse = await getCampaignSegmentRules(resp?.inputRuleId);
        }
        showToast(toast,   (resp.type == 'DYNAMIC' || resp.type === "DYNAMIC_LEAD") ?
        `${intl.formatMessage({id: 'popGroupFrequencyMessage'})} ${getFrequencyValue(popGroupResponse)}.`
        : intl.formatMessage({id: 'createGroupSuccessMessage'}), ToastType.success);
        setOpenModal({
          segmentData: null,
          isOpen: false,
          customDrawerWidth: undefined,
        });
        getGroupList();
      }
    }).catch((error)=>{
      showToast(toast, (error?.response?.data?.message ||  intl.formatMessage({id: 'apiErrorMsg'})), ToastType.error);
    });
  };
  const renderContent = () => {
    return (
      <View
        style={[
          {
            backgroundColor: '#ffffff',
            marginHorizontal: 24,
            borderRadius: 8,
            // @ts-expect-error: Let's ignore a compile error like this unreachable code
            boxShadow: `0px 1px 2px rgba(16, 24, 40, 0.06)`,
            paddingHorizontal: 16,
            paddingVertical: 12,
          },
        ]}
      >
        {!groupList?.length && <NoTagsSvg titleId='noGroupsAvailable' />}
        {groupList?.map((data: any, index: number) => {
          return (
            <VStack key={`groupList_${data?.ruleId}_${index}`}
              borderTopColor={'gray.100'} borderTopWidth={!index ? 0 : 1}>
              <Pressable
                onPress={async () => {
                  if(!data.ruleId || !data.accountUuid){
                    return;
                  }
                  const groupDataResponse = await getGroupDetailById(data.ruleId);
                  setOpenModal({
                    segmentData: groupDataResponse?.data,
                    isOpen: true,
                    customDrawerWidth: undefined,
                  });
                }}
              >
                <HStack alignItems={'center'} flex={1} marginBottom={1} marginY={2}>
                  <GroupIconOutline />
                  <VStack style={styles.groupContainer}>
                    <Text
                      flex={1}
                      color={Colors.FoldPixel.GRAY400}
                      size={'smMedium'}
                      numberOfLines={1}
                    >
                      {data.name || ''}
                    </Text>
                    <Text
                      flex={1}
                      color={Colors.FoldPixel.GRAY300}
                      size={'smRegular'}
                    >
                      {'-'}
                    </Text>
                  </VStack>
                  {isWeb() && (
                    <VStack
                      marginRight={0}
                      justifySelf={'flex-end'}
                      alignItems={'flex-end'}
                      flex={1}
                    >
                      <Icon
                        as={AntIcon}
                        name="right"
                        size="3"
                        alignSelf={'flex-end'}
                        marginRight={0}
                      />
                    </VStack>
                  )}
                </HStack>
              </Pressable>
            </VStack>
          );
        })}
      </View>
    );
  };

  return (
    <>
      <View
        style={[
          {
            backgroundColor: Colors.Custom.Gray50,
            shadowColor: `rgba(16, 24, 40, 0.06)`,
            shadowRadius: 1,
            shadowOffset: {
              width: 1,
              height: 1,
            },
            shadowOpacity: 0.1,
          },
        ]}
      >
        <View style={styles.container}>
          <View style={styles.headerContainer}>
            <View style={styles.iconContainer}>
              <View style={styles.iconWrapper}>
                <GroupIcon />
              </View>
              <DisplayText size={'lgMedium'} textLocalId={'memberGroups'} extraStyles={{color: Colors.FoldPixel.GRAY300 || ''}} />
            </View>
          </View>
        </View>
        {renderContent()}
      </View>
      <>
        {isWeb() && openModal.isOpen && (
          <Drawer
            width={openModal.customDrawerWidth || finalWidth}
            visible={openModal?.isOpen}
            closeIcon={null}
            closable
            // title={onDrawerHeader(openModal?.type)}
            maskClosable={true}
            destroyOnClose
            onClose={() => {
              setOpenModal({
                segmentData: null,
                isOpen: false,
                customDrawerWidth: undefined,
              });
            }}
          >
            <CampaignSegmentForm
              // campaignDetailsError={campaignDetailsError}
              initData={{
                id: openModal?.segmentData?.id,
                description: openModal?.segmentData?.description,
                audienceName: openModal?.segmentData?.name || '',
                patientList:
                  openModal?.segmentData?.type === POPULATION_GROUP_TYPES.STATIC ||
                  openModal?.segmentData?.type === POPULATION_GROUP_TYPES.STATIC_LEAD
                    ? openModal?.segmentData?.rule.patientFilter.contactIds
                    : [],
                jsonTree:
                  openModal?.segmentData?.type === POPULATION_GROUP_TYPES.DYNAMIC ||
                  openModal?.segmentData?.type === POPULATION_GROUP_TYPES.DYNAMIC_LEAD
                    ? openModal?.segmentData?.displayJson
                    : {},
                executionFrequency:
                  openModal?.segmentData?.type === POPULATION_GROUP_TYPES.DYNAMIC ||
                  openModal?.segmentData?.type === POPULATION_GROUP_TYPES.DYNAMIC_LEAD
                    ? openModal?.segmentData?.executionFrequency
                    : '',
                populationGroupId: openModal?.segmentData?.inputRuleId,
                memberStatusCodeFromResponseWhenEditingGroup: openModal?.segmentData?.preFilter?.hintMembershipStatus?.params?.value
              }}
              onModalClose={() => {
                setOpenModal({
                  segmentData: null,
                  isOpen: false,
                  customDrawerWidth: undefined,
                });
              }}
              onColumnViewChange={(columns: number) => {
                setOpenModal((prev) => ({...prev, customDrawerWidth: columns > 1 ? width * 0.75 : undefined}));
              }}
              onSubmitForm={(
                campaignSegmentFormData: ICampaignSegmentFormState,
                callback?: any,
                initialLocationIdsList?: string[],
              ) => {
                updateGroupContact(campaignSegmentFormData, initialLocationIdsList);
              }}
              editing={true}
            />
          </Drawer>
        )}
      </>
    </>
  );
};


const styles = StyleSheet.create({
  container: {
    padding: 24,
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  iconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 4,
  },
  iconWrapper: {
    marginRight: 10,
  },
  groupContainer: {
    marginLeft: 16,
    width: '80%',
  },
});
