import { useLazyQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { GET_CARE_PROGRAM_STEP_LAST_ACTIVITY_LOGS } from '../../../../../../../services/ContactCareProgram/ContactCareProgram';
import { DATE_FORMATS } from '../../../../../../../constants/StringConst';
import { getDateStrFromFormat, isSameDay } from '../../../../../../../utils/DateUtils';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../../../constants/Configs';
import { getMlovObjectFromId, getMlovValueFromId } from '../../../../../../../utils/mlovUtils';
import useCareProgramStepStatus from '../../../hooks/useCareProgramStepStatus';
import {getUserUUID} from '../../../../../../../utils/commonUtils';
import {StepAction} from '../../../hooks/useCareProgramStepActions';
import {CARE_PROGRAM_STEP_STATUS_CODES} from '../../../../../../../constants/MlovConst';
import {DUMMY_OUTREACH_STEP_ID} from '../../../ContactCareProgramView.utils';
import { IActivityLog, IMlov } from '../../../../interface';

export enum ActivityActionTypes {
  SEND_TO_PATIENT = 'SEND_TO_PATIENT',
  AUTO_SAVE = 'AUTO_SAVE',
  STATUS_UPDATE = 'STATUS_UPDATE',
  FORM_RESPONSE_ACCEPTED = 'FORM_RESPONSE_ACCEPTED',
  REVIEWED = 'REVIEWED',
}

const StatusActionTextMap = {
  [CARE_PROGRAM_STEP_STATUS_CODES.SKIP] : 'Skipped',
  [CARE_PROGRAM_STEP_STATUS_CODES.DONE] : 'Reviewed',
  [ActivityActionTypes.FORM_RESPONSE_ACCEPTED] : 'Accepted',
  [ActivityActionTypes.SEND_TO_PATIENT] : 'Sent on',
}

const formatStatusActionText = (statusCode: string) => {
  if (!statusCode) return undefined;
  return StatusActionTextMap[statusCode as keyof typeof StatusActionTextMap];
};

const BY = 'by';
const ON = 'on';

export const useContentHeaderSubtitle = (params: {
  stepId?: string;
  userMap: Map<string, string>;
}) => {
  const {stepId, userMap} = params;
  const [activityLogData, setActivityLogData] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const {careProgramStepStatusList} = useCareProgramStepStatus();
  const loggedInUserId = getUserUUID();
  const [getCareProgramStepLastActivityLogs] = useLazyQuery(
    GET_CARE_PROGRAM_STEP_LAST_ACTIVITY_LOGS,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );
  
  const getActivityLogString = (
    log: IActivityLog,
  ) => {

    const statusId = log.data?.newData?.careProgramStepStatusId;
    const statusMlovObject = getMlovObjectFromId(
      statusId as string,
      careProgramStepStatusList,
    );

    const isLoggedInUser = log?.performedById === loggedInUserId;
    const actionUser = isLoggedInUser
      ? 'You'
      : userMap?.get(log?.performedById) || 'Automation';

    const isTodayDay = isSameDay(new Date(), new Date(log.timestamp));
    const timestamp = isTodayDay
      ? 'Today'
      : getDateStrFromFormat(
          log.timestamp,
          DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT
        );
    let activityLogDisplayString: string | null = null;
    if (log) {
      switch (log.actionCode) {
        case StepAction.AUTO_SAVE:
          activityLogDisplayString = `Last edited ${BY} ${actionUser}`;
          break;
        case ActivityActionTypes.STATUS_UPDATE:
          const statusActionText = formatStatusActionText(
            statusMlovObject?.code as string
          );
          if (statusActionText) {
            activityLogDisplayString = `${statusActionText} ${BY} ${actionUser}`;
          }
          break;
        case ActivityActionTypes.SEND_TO_PATIENT:
          activityLogDisplayString = `Sent to patient ${BY} ${actionUser}`;
          break;
        case ActivityActionTypes.FORM_RESPONSE_ACCEPTED:
          activityLogDisplayString = `Accepted ${BY} ${actionUser}`;
          break;
        default:
          activityLogDisplayString = null;
          break;
      }
    }
    if (activityLogDisplayString) {
      if (isTodayDay) {
        activityLogDisplayString = `${activityLogDisplayString}, ${timestamp}`;
      } else {
        activityLogDisplayString = `${activityLogDisplayString} ${ON} ${timestamp}`;
      }
    }
    return activityLogDisplayString;
  };

  useEffect(() => {
    const fetchActivityLogs = async () => {
      if (!stepId || stepId === DUMMY_OUTREACH_STEP_ID) {
        setActivityLogData([]);
        return;
      }

      setLoading(true);
      try {
        const response = await getCareProgramStepLastActivityLogs({
          variables: {
            stepId: stepId,
          },
        });

        const logs = response?.data?.getActivityLogs?.activityLogs as IActivityLog[];
        
        const activityLogStringList = logs?.map((log) => getActivityLogString(log)).filter((log) => log !== null);
        setActivityLogData(activityLogStringList as string[]);
      } catch (error) {
        setActivityLogData([]);
      } finally {
        setLoading(false);
      }
    };

    fetchActivityLogs();
  }, [stepId]);

  return {
    activityLogData,
    loading,
  };
}