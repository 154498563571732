import {Box, HStack, Text, View, VStack} from 'native-base';
import React from 'react';
import {getDisplayDateMessageSectionHeader} from '../../../utils/DateUtils';
import {styles} from './ActionMsgStyle';
import {IActionMsgProps} from './interface';
import { testID, TestIdentifiers } from '../../../testUtils';
import { Colors } from '../../../styles';

const SectionMsgHeader = (props: IActionMsgProps) => {
  const {msgString, dateStr} = props;
  return (
    <Box
      style={{
        alignItems: 'center',
      }}
      {...testID(TestIdentifiers.actionMsgHeader)}
    >
      <HStack style={styles.actionMsgBox} width={'100%'}>
        <VStack justifyContent={'flex-end'} width={'100%'}>
          <HStack style={{flexDirection: 'column', alignItems: 'center'}}>
            <Text {...testID(TestIdentifiers.actionMsgHeaderString)} mr={1} size={'xsMedium'} color={'coolGray.500'}
              style={{
                fontWeight: '400',
                fontSize: 12
              }}>
              {msgString}
            </Text>
            <HStack style={{alignItems: 'center'}}>
              <View borderColor={'gray.100'} borderWidth={0.6} width={200}></View>
              <View width={'max-content'}>
              <Text {...testID(TestIdentifiers.actionMsgHeaderDate)} mr={1} size={'xsNormal'} 
                style={{width: '100%', fontWeight: '400', fontSize: 12}} color={Colors.FoldPixel.GRAY250} paddingLeft={1}>
                {getDisplayDateMessageSectionHeader(dateStr)}
              </Text>
              </View>
              <View borderColor={'gray.100'} borderWidth={0.6} width={200}></View>
            </HStack>
          </HStack>
        </VStack>
      </HStack>
    </Box>
  );
};

export default React.memo(SectionMsgHeader);
