import { IMlov, IUser } from '../../../../Interfaces';
import {DATE_FORMATS, MLOV_CATEGORY} from '../../../../constants';
import {getFormattedDate} from '../../../../utils/DateUtils';
import {
  getMlovListFromCategory,
  getMlovObjectFromId,
} from '../../../../utils/mlovUtils';
import {
  IContactOutreachActivity,
  IFormattedContactOutreachActivity,
} from './interfaces';

export const formatOutreachData = (
  mlovData: any,
  outreachData: IContactOutreachActivity[],
  userMap: Record<string, IUser>
) => {
  const outreachCommunicationModes =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CONTACT_OUTREACH_COMMUNICATION_MODE
    ) || [];

  const outreachOutComes = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.OUT_REACH_STEP_OUTCOMES
  );


  const formattedOutreachActiities: IFormattedContactOutreachActivity[] = [];

  outreachData?.forEach((outreachData) => {
    const user = userMap[outreachData.createdBy]

    const outreachCommunicationMlov = getMlovObjectFromId(
      outreachData.communicationModeId,
      outreachCommunicationModes
    );

    const outreachOutcomeMlov = getMlovObjectFromId(
      outreachData.outcomeId,
      outreachOutComes
    ) as IMlov;

    const formattedDate = getFormattedDate(
      outreachData.outreachDateTime,
      DATE_FORMATS.BILLING_ACTIVITY_DATE
    );

    const formattedOutreachData: IFormattedContactOutreachActivity = {
      communicationMode: outreachCommunicationMlov?.code || '',
      outcomeText: outreachOutcomeMlov?.value || '',
      note: outreachData.note,
      formattedDate: formattedDate,
      userName: user?.name || 'Automation',
      outcomeMlov: outreachOutcomeMlov,
      outreachData: outreachData,
      communicationModeText: outreachCommunicationMlov?.value || '',
      id: outreachData.id,
      resourceId: outreachData.resourceId,
    };

    formattedOutreachActiities.push(formattedOutreachData);
  });
  return formattedOutreachActiities;
};
