import {SENDER_TYPE} from '../../../../constants/StringConst';
import {CHANNEL_TYPE_CODE} from '../../../RightSideContainer/TeamInbox/Conversations/ConversationConst';
import {MESSAGE_RECEIVED_DATA_ACTION} from '../../../RightSideContainer/TeamInbox/Conversations/ConversationContainer/ConversationList/ConversationListEventHook/ConversationListEventHookUtils';
import {getFormattedMsgDataForNewChange} from '../../../RightSideContainer/TeamInbox/Conversations/ConversationContainer/ConversationList/ConversationListUtils';
import {CONVERSATION_UNREAD_COUNT_TYPE, IO_MESSAGE_AND_MISS_CALL_TYPE_NUM} from '../../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingCustomCard/CustomCardConst';
import {IConversationData} from '../../../RightSideContainer/TeamInbox/Conversations/interfaces';
import {
  IAddOrUpdateConversationInConversationList,
  IUpdateConversationInMatchedConversation,
} from './interface';

const isIncreaseUnReadCountOnConversationList = (
  receivedMessageType: number
) => {
  const allowedMessageTypeForSelectedInboxType =
    CONVERSATION_UNREAD_COUNT_TYPE[CHANNEL_TYPE_CODE.CHANNEL_ALL];
  return allowedMessageTypeForSelectedInboxType?.includes(receivedMessageType);
};

const checkSenderIsCurrentUser = (
  senderId?: number,
  senderType?: string,
  userId?: number
) => {
  if (senderId && senderType) {
    return senderId === userId && senderType === SENDER_TYPE.USER;
  }
  return false;
};

export const updateMessageDataInMatchedConversation = (
  params: IUpdateConversationInMatchedConversation
) => {
  const {conversationList, selectedConversation, receivedMessageData, userId} =
    params;
  const finalConversationList = [...conversationList];
  const receivedMessageConversationUuid = receivedMessageData?.conversationUuid;
  const receivedMessageConversationIndex = conversationList?.findIndex(
    (item) => item?.uuid === receivedMessageConversationUuid
  );
  const updatedConversation =
    conversationList[receivedMessageConversationIndex];
  if (updatedConversation?.uuid === receivedMessageConversationUuid) {
    const msgData = getFormattedMsgDataForNewChange(receivedMessageData);
    let finalUpdatedConversation = {...updatedConversation};
    const messageType = receivedMessageData?.messageType;
    let unreadMessages = [...(updatedConversation.unreadMessages ?? [])];
    let unreadMsgCount = updatedConversation?.unreadMsgCount || 0;
    if (updatedConversation?.uuid === selectedConversation?.uuid) {
      unreadMessages = [];
      unreadMsgCount = 0;
    } else if (
      isIncreaseUnReadCountOnConversationList(messageType) &&
      !checkSenderIsCurrentUser(msgData?.senderId, msgData?.senderType, userId)
    ) {
      unreadMessages = [
        ...(finalUpdatedConversation?.unreadMessages ?? []),
        {
          id: msgData.id,
          messageType: messageType,
        },
      ];
      unreadMsgCount = (updatedConversation?.unreadMsgCount || 0) + 1;
    }
    finalUpdatedConversation = {
      ...finalUpdatedConversation,
      conversationMessages: [msgData],
      unreadMessages: [...unreadMessages] || [],
      unreadMsgCount: unreadMsgCount,
    };
    if (IO_MESSAGE_AND_MISS_CALL_TYPE_NUM.includes(receivedMessageData?.messageType)) {
      const [singleConversation] = finalConversationList.splice(receivedMessageConversationIndex, 1)
      finalConversationList.unshift(finalUpdatedConversation)
    } else {
      finalConversationList[receivedMessageConversationIndex] =
        finalUpdatedConversation;
    }

  }
  return finalConversationList;
};

export const updateConversationAssigneeDataInMatchedConversation = (
  params: IUpdateConversationInMatchedConversation
) => {
  const {conversationList, selectedConversation, receivedMessageData} = params;
  const finalConversationList = [...conversationList];
  const receivedMessageConversationUuid = receivedMessageData?.conversationUuid;
  const receivedMessageConversationIndex = conversationList?.findIndex(
    (item) => item?.uuid === receivedMessageConversationUuid
  );
  const updatedConversation =
    conversationList[receivedMessageConversationIndex];
  if (updatedConversation?.uuid === receivedMessageConversationUuid) {
    let finalUpdatedConversation = {...updatedConversation};
    if (
      receivedMessageData?.additionalDataAssigneeData?.assigneeToUserData?.uuid
    ) {
      updatedConversation.status =
        receivedMessageData?.conversationStatus as any;
      const assignedUseData = {
        ...updatedConversation.assignedUser,
        id: receivedMessageData?.additionalDataAssigneeData?.assigneeToUserData
          ?.id,
        uuid: receivedMessageData?.additionalDataAssigneeData
          ?.assigneeToUserData?.uuid,
        name: receivedMessageData?.additionalDataAssigneeData
          ?.assigneeToUserData?.name,
        email:
          receivedMessageData?.additionalDataAssigneeData?.assigneeToUserData
            ?.email,
      };
      finalUpdatedConversation = {
        ...finalUpdatedConversation,
        assignedUser: {
          ...assignedUseData,
        },
      };
    } else {
      const unAssignedUserData = {
        id: -1,
        name: '',
        uuid: '',
        email: '',
      };
      finalUpdatedConversation = {
        ...finalUpdatedConversation,
        assignedUser: {
          ...unAssignedUserData,
        },
      };
    }
    finalConversationList[receivedMessageConversationIndex] =
      finalUpdatedConversation;
  }
  return finalConversationList;
};

export const updateConversationStatusDataInMatchedConversation = (
  params: IUpdateConversationInMatchedConversation
) => {
  const {conversationList, selectedConversation, receivedMessageData} = params;
  const finalConversationList = [...conversationList];
  const receivedMessageConversationUuid = receivedMessageData?.conversationUuid;
  const receivedMessageConversationIndex = conversationList?.findIndex(
    (item) => item?.uuid === receivedMessageConversationUuid
  );
  const updatedConversation =
    conversationList[receivedMessageConversationIndex];
  if (updatedConversation?.uuid === receivedMessageConversationUuid) {
    let finalUpdatedConversation = {...updatedConversation};
    finalUpdatedConversation.status =
      receivedMessageData?.conversationStatus as any;
    if (
      receivedMessageData?.additionalDataAssigneeData?.assigneeToUserData?.uuid
    ) {
      const assignedUseData = {
        ...finalUpdatedConversation?.assignedUser,
        id: receivedMessageData?.additionalDataAssigneeData?.assigneeToUserData
          ?.id,
        uuid: receivedMessageData?.additionalDataAssigneeData
          ?.assigneeToUserData?.uuid,
        name: receivedMessageData?.additionalDataAssigneeData
          ?.assigneeToUserData?.name,
        email:
          receivedMessageData?.additionalDataAssigneeData?.assigneeToUserData
            ?.email,
      };
      finalUpdatedConversation = {
        ...finalUpdatedConversation,
        assignedUser: {
          ...assignedUseData,
        },
      };
    } else {
      const unAssignedUserData = {
        id: -1,
        name: '',
        uuid: '',
        email: '',
      };
      finalUpdatedConversation = {
        ...finalUpdatedConversation,
        assignedUser: {
          ...unAssignedUserData,
        },
      };
    }
    finalConversationList[receivedMessageConversationIndex] =
      finalUpdatedConversation;
  }
  return finalConversationList;
};

export const addOrUpdateConversationInConversationList = (
  params: IAddOrUpdateConversationInConversationList
) => {
  const {
    conversationList,
    selectedConversation,
    receivedMessageData,
    messageReceivedDataAction,
    userId,
  } = params;
  if (messageReceivedDataAction?.length) {
    let finalConversationList = [...conversationList];
    messageReceivedDataAction?.forEach((actionCode) => {
      switch (actionCode) {
        case MESSAGE_RECEIVED_DATA_ACTION.MESSAGE_UPDATE:
          finalConversationList = updateMessageDataInMatchedConversation({
            conversationList: finalConversationList,
            selectedConversation,
            receivedMessageData,
            userId,
          });
          break;

        case MESSAGE_RECEIVED_DATA_ACTION.ASSIGNEE_UPDATE:
          finalConversationList =
            updateConversationAssigneeDataInMatchedConversation({
              conversationList: finalConversationList,
              selectedConversation,
              receivedMessageData,
            });
          break;

        case MESSAGE_RECEIVED_DATA_ACTION.CONVERSATION_STATUS_UPDATE:
          finalConversationList =
            updateConversationStatusDataInMatchedConversation({
              conversationList: finalConversationList,
              selectedConversation,
              receivedMessageData,
            });
          break;
      }
    });
    return finalConversationList;
  }
};

