import React, { useMemo } from 'react';
import { Path, Svg } from 'react-native-svg';
import { Colors } from '../../styles';
import { ICommonSvgProps } from '../../components/common/Svg/interfaces';

const UpArrowFilledSvg = React.memo((props: ICommonSvgProps) => {
  const svgContent = useMemo(() => (
    <Svg width={props.size || "6"} height={props.size || "6"} viewBox="0 0 6 6" fill="none">
      <Path 
        d="M3 2L1 4L5 4L3 2Z"
        fill={props.customStrokeColor || Colors.FoldPixel.GRAY300}
        stroke={props.customStrokeColor || Colors.FoldPixel.GRAY300}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  ), [props.customStrokeColor]);

  return svgContent;
});

export default UpArrowFilledSvg;