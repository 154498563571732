import { capitalize } from 'lodash';
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Divider,
  HStack,
  Pressable,
  Spinner,
  Text,
  Tooltip,
  useToast,
  View,
  VStack,
} from 'native-base';
import RenderHTML from 'react-native-render-html';
import { Colors } from '../../styles';
import { ITask, ITaskActionParams } from '../common/CareDashboard/CareDashboardInterfaces';
import TaskCompleteSvgIcon from '../common/Svg/TaskCompleteSvgIcon';
import {
  BottomViewAction,
  descriptionDisplayModes,
  getCardTag,
  getPriorityIcon,
  getVitalCapturedDataForCompletedTask,
  renderResourceMapCountAndDeleteField,
} from '../TaskCard/TaskCardHelper';
import { checkIfTaskIsCompleted, shouldWrapWithTag } from './taskUtils';
import { getVitalListFromCapability } from '../../utils/capabilityUtils';
import { LabelDataListView } from '../common/CareDashboard/CareDashboardWidget/LabelDataListView';
import SubtasksSvg from '../common/Svg/SubtasksSvg';
import { getDateStrFromFormat, getDiffInDays, getMomentObj } from '../../utils/DateUtils';
import { TASK_STATUS_CODES } from '../../constants/MlovConst';
import { useIntl } from 'react-intl';
import { BUTTON_TYPE, DATE_FORMATS, EVENT_NAMES } from '../../constants';
import { getAssignedByType } from '../common/CareDashboard/CareDashboardTable/CareDashboardTableHelper';
import { getCurrentUserRole, getUserUUID } from '../../utils/commonUtils';
import { IUserRoleCode } from '../../Interfaces/CommonInterfaces';
import { useMemo, useState } from 'react';
import TaskQueries, { UPDATE_TASK } from '../../services/Task/TaskQueries';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../constants/Configs';
import { showToast, ToastType } from '../../utils/commonViewUtils';
import { isWeb } from '../../utils/platformCheckUtils';
import { EventBus } from '../../utils/EventBus';
import { Modal, notification } from 'antd';
import { useLazyQuery, useMutation } from '@apollo/client';
import { FoldButton } from '../CommonComponents/FoldButton/FoldButton';
import { FHTooltip } from '../common/FHTooltip';

interface ITaskDetailCardProps {
  task: ITask;
  updateTaskStatus?: any;
  getBottomView?: any;
  isDashboardView?: boolean;
  arrLength?: number;
  index?: number;
  isChecked?: boolean;
  handleCheckbox?: (isChecked: boolean) => void;
  isCheckboxLoading?: boolean;
  isHideCheckbox?: boolean;
  handleSelectTask?: (task: ITask) => void;
  hideDeleteAction?: boolean;
  onDeleteTaskHandler?: (
    task: ITask,
    action: BottomViewAction,
    data: any
  ) => void;
  handleDeleteOnCallback?: boolean;
}

const TaskDetailCard = ({
  task,
  getBottomView,
  onDeleteTaskHandler,
  handleDeleteOnCallback,
  isDashboardView = false,
  arrLength,
  index,
  isCheckboxLoading,
  isHideCheckbox,
  handleCheckbox,
  isChecked,
  updateTaskStatus,
  handleSelectTask,
}: ITaskDetailCardProps) => {

  const vitalList = getVitalListFromCapability(
    '',
    task?.taskLocations?.[0]?.locationId
  );
  const intl = useIntl();
  const toast = useToast();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showDeleteConfirmation, setDeleteConfirmation] = useState(false);
  const assignedBy = getAssignedByType(task, intl);
  const loginUserId = getUserUUID();
  const currentUserRoles: IUserRoleCode[] = getCurrentUserRole();


  let diffDays;
  if (task?.endDateTime) {
    diffDays = getDiffInDays(
      getMomentObj(task?.endDateTime),
      getMomentObj(new Date())
    );
  }
  const [ getSubTaskByIds ] = useLazyQuery(TaskQueries.GET_SUB_TASK_BY_ID, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });
  const isLastTask = () => {
    return index === (arrLength && arrLength - 1) ? true : false;
  };
  const isFirstTask = () => index === 0;
  const vitalCapturedData = getVitalCapturedDataForCompletedTask(task, vitalList);
  const journeyName = task?.patientCareJourney?.title || task?.patientCareJourney?.careJourney?.title;
  const displayDescription = useMemo(() => task?.description
    ? shouldWrapWithTag(task?.description)
      ? `<p>${task?.description}</p>`
      : task?.description
    : '', [task?.description]);
  const taskDescriptionVerticalMargin = task?.description ? -4 : 0;
  const isAttachmentAdded = task?.attachments && task?.attachments?.length > 0 ? true : false;
  const isNoteContextTask = task?.resourceMap?.notes;
  const isTaskMissed = task?.status?.code === TASK_STATUS_CODES.MISSED;
  const [updateTask] = useMutation(TaskQueries.UPDATE_TASK);

  const onDeleteTaskConfirmation = () => {
    setDeleteLoading(true);
    updateTask({
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      variables: {
        params: {
          id: task.id,
          data: {
            isDeleted: true
          }
        },
      },
      onCompleted: (data: any) => {
        showToast(
          toast,
          task?.parentId ? intl.formatMessage({
            id: 'subtaskDeletedSuccess',
          }) : intl.formatMessage({
            id: 'taskDeletedMsg',
          }) ,
          ToastType.success,
          2000
        );
        setDeleteLoading(false);
        setDeleteConfirmation(false);
        if (onDeleteTaskHandler && typeof onDeleteTaskHandler == 'function') {
          onDeleteTaskHandler(task, BottomViewAction.deleteTask, undefined);
        }
        setDeleteConfirmation(false);
      },
      onError: () => {
        setDeleteLoading(false);
        showToast(
          toast,
          intl.formatMessage({ id: 'apiErrorMsg' }),
          ToastType.error,
          1000
        );
        setDeleteConfirmation(false);
      },
    });
  };

  const handleTaskDelete = async () => {
    if (task?.subTasks && task?.subTasks?.length > 0) {
      setDeleteLoading(true);
      const response = await getSubTaskByIds({
        variables: {
          ids: task.subTasks?.map((task) => task?.id),
        },
      });
      if (response?.data?.getTasks?.tasks?.length > 0) {
        if (isWeb()) {
          notification.info({
            message: intl.formatMessage({
              id: 'TaskNotDeletedMsgForTaskHasSubtask',
            }),
            duration: 3.0,
            placement: 'top'
          });
          setDeleteLoading(false);
          return;
        } else {
          showToast(
            toast,
            intl.formatMessage({ id: 'TaskNotDeletedMsgForTaskHasSubtask' }),
            ToastType.info,
            1000
          );
          setDeleteLoading(false);
          return;
        }
      }
    } else {
      setDeleteConfirmation(true);
    }
    if (handleDeleteOnCallback) {
      if ((onDeleteTaskHandler && typeof onDeleteTaskHandler == 'function')) {
        onDeleteTaskHandler(task, BottomViewAction.deleteTask, undefined);
      }
      return;
    }
  }

  const memoizedRenderResourceMapCountAndDeleteField = useMemo(() => {
    const renderFieldParam: ITaskActionParams = {
      task: task,
      loginUserId: loginUserId,
      currentUserRoles: currentUserRoles || [],
      showDeleteField: true,
      isSideCarTaskCard: true,
      intl: intl,
      handleTaskDelete: handleTaskDelete
    };
    return renderResourceMapCountAndDeleteField(renderFieldParam);
  }, [task, loginUserId, currentUserRoles, handleTaskDelete]);

  const deleteModalButtons = [
    {
      textLocalId: 'cancel',
      buttonProps: {
        variant: BUTTON_TYPE.SECONDARY,
      },
      onPress: () => {
        setDeleteConfirmation(false);
      },
    },
    {
      textLocalId: 'delete',
      buttonProps: {
        variant: BUTTON_TYPE.PRIMARY,
        loading: deleteLoading,
      },
      loading: deleteLoading,
      onPress: onDeleteTaskConfirmation,
    },
  ];

  const memoizedRenderHTML = useMemo(() => (
    <RenderHTML
      source={{
        html: displayDescription || '',
      }}
      enableExperimentalMarginCollapsing={true}
      enableExperimentalBRCollapsing={true}
      baseStyle={{
        whiteSpace: 'pre',
        overflow: 'visible',
        width: '100%',
      }}
    />
  ), [displayDescription]);

  return (
    <Box
      bgColor={
        isDashboardView ? Colors.Custom.ContainerBGColor : Colors.Custom.Gray50
      }
      borderRadius={isDashboardView ? 0 : 8}
      borderTopRadius={isDashboardView && isFirstTask() ? 8 : 8}
      borderBottomRadius={isDashboardView && isLastTask() ? 8 : 8}
      borderWidth={isDashboardView ? 0 : 1}
      borderColor={Colors.Custom.Gray100}
      p={4}
    >
      <VStack flex={1} >
        <HStack justifyContent={'space-between'}>
          <Pressable width="90%"  disabled={!handleSelectTask} onPress={handleSelectTask ? () =>  handleSelectTask(task) : undefined}>
            <HStack alignItems="center" space={2} mb={1}>
              {!!task.priority?.id && (
                <View>{getPriorityIcon(task.priority, undefined, 0)}</View>
              )}
              {(
                <Badge
                  rounded={'full'}
                  bgColor={Colors.Custom.Indigo50}
                  color={Colors.Custom.Indigo700}
                >
                  <Text
                    textTransform="capitalize"
                    fontSize={12}
                    fontWeight={500}
                    color={Colors.Custom.Indigo700}
                  >
                    {capitalize(getCardTag(task))}
                  </Text>
                </Badge>
              )}
            </HStack>
            <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400}>
              {task?.title}
            </Text>
            <Text size={'smRegular'} color={Colors.FoldPixel.GRAY300} marginY={taskDescriptionVerticalMargin}>
              {vitalCapturedData ? (
                vitalCapturedData
              ) : task?.description ? memoizedRenderHTML : ''}
            </Text>
            {task?.subTasks?.length ? (
              <View flexDirection="row" mt={1}>
                <View size={18}>
                  <SubtasksSvg customStrokeColor={Colors.Custom.PrimaryColor} />
                </View>
                <Text
                  ml={1}
                  size={'smMedium'}
                  color={Colors.Custom.PrimaryColor}
                >
                  {`${task?.subTasks?.length} ${
                    task?.subTasks?.length > 1 ? 'Subtasks' : 'Subtask'
                  } `}
                </Text>
              </View>
            ) : (
              <></>
            )}
            <View width={380} flex={1}>
              <LabelDataListView
                key={task?.id}
                hideAddMoreButton={true}
                tagList={task?.labels || []}
                style={{width: 380}}
              />
            </View>
            {task?.endDateTime ? (
              <HStack mb={1}>
                <Text
                  size={'smRegular'}
                  fontWeight={400}
                  color={Colors.FoldPixel.GRAY300}
                >
                  {`Due:${
                    getDateStrFromFormat(
                      task?.endDateTime || new Date(),
                      DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT
                    ) || ''
                  }`}
                </Text>
              </HStack>
            ) : null}
            {
              task.assigneeUser?.name || task.patientName || task.contact?.name ? (
                <Text size={'smRegular'} mb={1} color={Colors.FoldPixel.GRAY300}>
                  {`Assignee: ${
                    task.assigneeUser?.name ||
                    task.patientName ||
                    task.contact?.name
                  }`}
                </Text>
              ) : null
            }
          </Pressable>
          <Box
            alignSelf={checkIfTaskIsCompleted(task) ? 'center' : 'flex-start'}
          >
            {checkIfTaskIsCompleted(task) ? (
              <HStack alignItems={'center'} space={1}>
                <TaskCompleteSvgIcon />
                <Text
                  fontSize={14}
                  fontWeight={500}
                  color={Colors.Custom.SuccessColor}
                >
                  Done
                </Text>
              </HStack>
            ) : getBottomView(task) ? (
              <></>
            ) : isDashboardView ? (
              <>
                {isCheckboxLoading && !isHideCheckbox && (
                  <Spinner />
                )}
                {!isCheckboxLoading && !isHideCheckbox && (
                  <div
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                   <Checkbox
                     value="complete"
                     isDisabled={checkIfTaskIsCompleted(task)}
                     isChecked={isChecked}
                     onChange={(value) => {
                       if (!checkIfTaskIsCompleted(task)) {
                         handleCheckbox?.(value);
                       }
                     }}
                   />
                 </div>
                )}
              </>

            ) : (
              <Button
                rounded={'full'}
                backgroundColor={Colors.Custom.Base50}
                py={1}
                px={3}
                size="xs"
                shadow="2"
                onPress={() => updateTaskStatus?.(task)}
              >
                <Text
                  fontSize={14}
                  fontWeight={700}
                  color={Colors.Custom.PurpleColor}
                >
                  Mark as Done
                </Text>
              </Button>
            )}
          </Box>
        </HStack>
        {!!assignedBy?.name && (
          <HStack alignItems={'center'} minW={'100%'}>
            <FHTooltip position='top' text={assignedBy?.name}>
            <Text size={'smRegular'} color={Colors.FoldPixel.GRAY300} maxW={'100%'} isTruncated>
              {intl.formatMessage({
                id: 'assignedBy',
              })}:
              <Text ml={1} alignItems={'center'} maxW={'70%'}>
                {assignedBy?.icon}
                <Text
                  ml={1}
                  size={'smRegular'}
                  color={Colors.FoldPixel.GRAY300}
                  isTruncated
                >
                  {assignedBy?.name}
                </Text>
              </Text>
            </Text>
            </FHTooltip>
          </HStack>
        )}
        <View alignItems={'flex-end'}>
          {checkIfTaskIsCompleted(task) ? null : getBottomView(task)}
        </View>
        {(isAttachmentAdded || isNoteContextTask || isTaskMissed) && (
          <Divider marginY={2}></Divider>
        )}
        {memoizedRenderResourceMapCountAndDeleteField}
        {isWeb() && showDeleteConfirmation && (
          <Modal
            open={showDeleteConfirmation}
            footer={null}
            closable={false}
          >
            <VStack>
              <Text
                mb={1}
                fontSize={'xl'}
                fontWeight={'bold'}
              >{`Delete Task?`}</Text>
              <Text my={1} fontSize={'md'}>
                {
                  task?.resourceMap?.notes ? intl.formatMessage({ id: 'deleteNoteLinkTaskConfirmation' }) : (task?.parentId ? intl.formatMessage({ id: 'deleteSubTaskConfirmation' }) : intl.formatMessage({ id: 'deleteTaskConfirmation' }))
                }
              </Text>
              <HStack mt={5} justifyContent={'flex-end'}>
                {deleteModalButtons.map((button: any, index: number) => {
                  return (
                    <FoldButton
                      key={index + button.btnText + task.id}
                      nativeProps={{
                        variant: button?.buttonProps?.variant,
                        onPress: () => {
                          button?.onPress();
                        },
                        ml: 2,
                        isLoading: button?.loading,
                        isDisabled: button?.loading
                      }}
                      customProps={{
                        btnText: intl.formatMessage({ id: button.textLocalId }),
                        withRightBorder: false,
                      }}
                    ></FoldButton>
                  );
                })}
              </HStack>
            </VStack>
          </Modal>
        )}
      </VStack>
    </Box>
  );
};
export default TaskDetailCard;
