import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ITaskCategory, ITaskMetaData, MultiSelectState } from '../../TaskInterfaces'
import { ITask } from '../../../common/CareDashboard/CareDashboardInterfaces';
import useTaskCountManager, { ITaskCount } from '../../../common/CareDashboard/CustomHook/useTaskCountManager';
import { Spinner, View, Text, VStack } from 'native-base';
import TaskTableView from './TaskTableView';
import { Colors } from '../../../../styles';
import { TASK_EVENTS, TASK_MODULE_CODE } from '../../../common/CareDashboard/CareDashboardConstants';
import { EventBus } from '../../../../utils/EventBus';
import { BoardType } from '../../../common/CareDashboard/CareDashboardTopBar/interfaces';
import { IUserPool } from '../../../common/CareDashboard/CareDashboardWidget/UserAutoComplete';
import { DisplayUserListDrawer } from '../../../common/DisplayUserList/DisplayUserListDrawer';
import { FlatList, StyleSheet } from 'react-native';
import './TaskListView.css'
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { TaskPanelTypeCode } from '../../../TaskCard/TaskEnum';
import TaskCategoryRow from './TaskCategoryRow';
import { isRenderTasksCompactView } from '../../../../utils/commonUtils';

interface ITaskNestedTableViewProps {
  categories: ITaskCategory[];
  metaData: ITaskMetaData;
  onTaskDetail: (data: ITask) => void;
  onMemberClick?: (task: ITask) => void;
  onActionPerformed?: (tabCode: string, rawData: any) => void;
  onTaskCountUpdate?: (taskCount: ITaskCount[]) => void;
}

const TaskNestedTableView = (props: ITaskNestedTableViewProps) => {
  const {categories, metaData, onActionPerformed, onTaskDetail, onMemberClick} = props;
  const commonData = useContext(CommonDataContext);
  const isSidecarContext = commonData.sidecarContext?.isSidecar;
  const eventBus = EventBus.getEventBusInstance();
  const isTaskPoolBoard = metaData.boardType === BoardType.taskPool;
  const isRenderCompactView = isRenderTasksCompactView();
  const accountUsers = React.useMemo(() => metaData.masterUsersList.map((item) => {
    return {
      id: parseInt(item.id),
      name: item.name,
      uuid: item.uuid,
      email: item.email,
    }
  }), [metaData?.masterUsersList?.length]);

  // States
  const [multiSelectState, setMultiSelectState] = useState<{[index: string]: MultiSelectState}>({});
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [viewTaskPoolUsersState, setViewTaskPoolUsersState] = useState<{
    userPool?: IUserPool;
    userIds: string[];
    isOpen: boolean;
    defaultUserId?: string;
  }>({
    userIds: [],
    isOpen: false,
    defaultUserId: undefined,
  });
  const { taskCount, todaysTaskCount, loadingFilterCodes, loading: taskCountLoading } = useTaskCountManager({
    params: categories,
    isFetchTodaysCount: true
  });

  // Lifecycle methods
  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(TASK_EVENTS.ON_MULTI_TASK_STATE_CHANGE, onCategoryMultiStateChange, { moduleCode: TASK_MODULE_CODE });

    return () => {
      eventBus.removeEventListenerByEventName(TASK_EVENTS.ON_MULTI_TASK_STATE_CHANGE, onCategoryMultiStateChange, { moduleCode: TASK_MODULE_CODE })
    }
  }, [])

  useEffect(() => {
    if (categories?.[0]?.filters?.searchString) {
      const keys = categories.map((item) => {
        return item.code
      });
      setExpandedRowKeys(keys);
    }
  }, [categories]);

  useEffect(() => {
    props?.onTaskCountUpdate?.(taskCount)
    // If any column is already expanded then do not update expandedRows
    const keys = categories.map((item) => item.code);
    const filteredExpandedRowKeys = expandedRowKeys.filter(key => keys.includes(key));
    if (filteredExpandedRowKeys.length) {
      return;
    }
    let findCode = '';
    (taskCount || []).find((countData, index: number) => {
      if (countData.count > 0) {
        findCode = countData.code;
        return true;
      }
    });
    const defaultRow = categories.find(item => item.code === findCode);
    if (defaultRow) {
      onTableRowExpand(true, defaultRow);
    }
  }, [taskCount]);

  const onAddTaskClick = (primaryId: string, record: ITaskCategory) => {
    eventBus.broadcastEvent(TASK_EVENTS.OPEN_TASK_DETAIL, { taskPool: { value: primaryId, key: primaryId, label: record.name } });
  }

  const onUserListClick = useCallback((userIds: string[], userPool?: IUserPool, defaultUserId?: string) => {
    setViewTaskPoolUsersState((prev) => ({
      ...prev,
      isOpen: true,
      userIds,
      userPool,
      defaultUserId,
    }));
  }, []);

  // Other methods
  const onCategoryMultiStateChange = useCallback((data: { categoryCode: string; state: MultiSelectState }) => {
    if (data?.categoryCode && data?.state) {
      setMultiSelectState((prev) => {
        return {
          ...prev,
          [data?.categoryCode]: data?.state,
        }
      })
    }
  }, []);

  const onTableRowExpand = (expanded: boolean, record: ITaskCategory) => {
    setExpandedRowKeys((prev) => {
      if (expanded && !prev.includes(record.code)) {
        prev.push(record.code);
      } else {
        prev = prev.filter(item => item !== record.code);
      }
      return [...prev];
    });
  }

  const expandedRowRender = useCallback((rawData: ITaskCategory) => {
    return (
      <TaskTableView
        category={rawData}
        metaData={metaData}
        onTaskDetail={onTaskDetail}
        onMemberClick={onMemberClick}
        onActionPerformed={onActionPerformed}
      />
    );
  }, [metaData, onTaskDetail, onMemberClick, onActionPerformed]);

  const getDisplayCategoriesForView = useMemo(() => {
    return categories.filter(item => (item.code !== TaskPanelTypeCode.INTERNAL) && (item.code !== TaskPanelTypeCode.PATIENT))
  }, [categories]);

  const onDisplayUserListDrawerClose = useCallback(() => {
    setViewTaskPoolUsersState((prev) => ({
      ...prev,
      isOpen: false,
      userIds: [],
      userPool: undefined,
    }));
  }, [setViewTaskPoolUsersState]);

  const renderItem = useCallback(({item}) => {
    const record = item;
    const primaryId = record?.id || record?.code;
    const countData = taskCount.filter(item => item.code === primaryId)?.[0];
    const todaysCountData = todaysTaskCount.filter(item => item.code === primaryId)?.[0];
    const multiState = multiSelectState?.[primaryId];

    // Pool specific code
    let userIds: string[] = [];
    let defaultUserId: string | undefined = undefined;
    const userPool = metaData.masterPoolList.find(item => item.id === primaryId);
    if (isTaskPoolBoard && userPool?.userPoolUsers?.length) {
      userIds = userPool?.userPoolUsers?.map((userPoolUser) => {
        if (userPoolUser.isDefault) {
          defaultUserId = userPoolUser.userId;
        }
        return userPoolUser.userId;
      });
    }
    const isExpanded = expandedRowKeys?.includes(record?.key);

    return (
      <div key={`CategoryRow-${primaryId}`} style={reactStyles.categoryContainer}>
        <div
          style={isRenderCompactView ? reactStyles.categoryHeaderCompactView : reactStyles.categoryHeader}
          onClick={(event) => {
            event.stopPropagation();
            onTableRowExpand(!isExpanded, record)
          }}
      >
        <TaskCategoryRow
          record={record}
          primaryId={primaryId}
          countData={countData}
          todaysCountData={todaysCountData}
          multiState={multiState}
          userIds={userIds}
          defaultUserId={defaultUserId}
          userPool={userPool}
          isExpanded={isExpanded}
          isSidecarContext={!!isSidecarContext}
          isRenderCompactView={isRenderCompactView}
          isTaskPoolBoard={isTaskPoolBoard}
          metaData={metaData}
          accountUsers={accountUsers}
          taskCountLoading={taskCountLoading}
          loadingFilterCodes={loadingFilterCodes}
          onUserListClick={onUserListClick}
          onAddTaskClick={onAddTaskClick}
        />
      </div>
      {isExpanded && expandedRowRender(record)}
      </div>
    );
  }, [getDisplayCategoriesForView, metaData, multiSelectState, expandedRowKeys, taskCountLoading, loadingFilterCodes, accountUsers, onUserListClick, onAddTaskClick]);

  return (
    <View>
      {taskCount.length > 0 ? (
        <View style={styles.tableContainer}>
          <FlatList
            data={getDisplayCategoriesForView}
            renderItem={renderItem}
            keyExtractor={(item: any): string => `${item?.code || item?.id}`}
            initialNumToRender={1}
            windowSize={5}
            maxToRenderPerBatch={1}
          />
      </View>
      ) : (
        <View
          flex={1}
          margin={4}
          style={styles.spinnerContainer}
        >
          <Spinner size={'lg'} />
        </View>
      )}
      {viewTaskPoolUsersState.isOpen && (
        <DisplayUserListDrawer
          userIds={viewTaskPoolUsersState.userIds}
          accountUsers={accountUsers}
          isOpen={viewTaskPoolUsersState.isOpen}
          defaultUserId={viewTaskPoolUsersState.defaultUserId}
          onClose={onDisplayUserListDrawerClose}
        >
          <VStack space={1}>
            <Text color={Colors.Custom.Gray500} fontSize={'xs'}>{`${viewTaskPoolUsersState.userIds?.length} user(s) under task pool`}</Text>
            <Text color={Colors.FoldPixel.GRAY400} fontSize={'lg'}>{viewTaskPoolUsersState.userPool?.name}</Text>
          </VStack>
        </DisplayUserListDrawer>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  categoryName: {
    color: Colors?.FoldPixel?.GRAY300,
    fontWeight: '600',
    fontSize: 14,
    lineHeight: 16.8,
  },
  badge: {
    paddingHorizontal: 6,
    paddingVertical: 2.5,
  },
  divider: {
    width: 1,
    height: 20,
    backgroundColor: Colors.Custom.Gray200,
    marginHorizontal: 8,
  },
  addTaskButton: {
    height: 36,
    marginLeft: 5,
    marginRight: 6,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableContainer: {
    overflow: "hidden",
  },
  spinnerContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  categoryContainer: {
    alignItems: "center",
  },
});

const reactStyles: Record<string, React.CSSProperties> = {
  checkbox: {
    marginTop: 1,
    color: Colors.Custom.SuccessColor,
  },
  addTaskButton: {
    height: 36,
    marginLeft: 5,
    marginRight: 6,
    alignItems: 'center',
    justifyContent: 'center',
  },
  categoryHeader: {
    alignItems: "center",
    padding: 16,
    borderBottom: "0.5px solid",
    borderBottomColor: Colors.Custom.Gray200,
    cursor: "pointer",
    width: "100%",
  },
  categoryHeaderCompactView: {
    alignItems: "center",
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 16,
    paddingRight: 16,
    borderBottom: "0.5px solid",
    borderBottomColor: Colors.Custom.Gray200,
    cursor: "pointer",
    width: "100%",
  },
  categoryContainer: {
    borderBottom: "0.5px solid",
    borderBottomColor: Colors.Custom.Gray200,
    width: "100%",
  },
};

export default TaskNestedTableView
