import React from 'react';
import {Checkbox, HStack, Text} from 'native-base';
import {useIntl} from 'react-intl';
import {isWeb} from '../../../utils/platformCheckUtils';
import {DisplayText} from '../DisplayText/DisplayText';
import {ModalActionCheckbox} from '../ModalActionCommonComponent/ModalActionCheckBox';
import {Colors} from '../../../styles';
import { isContactConsentRequired } from '../../../utils/commonUtils';

// Added web condition for now, as logic is very less, if in future need more complexity on the platform
// then need to make native file.
export const Consent = (props: {
  isChecked: boolean;
  setConsent: () => void;
  textLocaleId: string;
}) => {
  const intl = useIntl();
  const isConsentRequired = isContactConsentRequired()
  return (
    <>
      {isWeb() ? (
        <>
        <HStack>
          <ModalActionCheckbox
            isChecked={!isConsentRequired ? true : props?.isChecked}
            onChange={() => {
              props?.setConsent();
            }}
            value={'consents'}
            isDisabled={!isConsentRequired}
          />
          <DisplayText
            size={'smMedium'}
            extraStyles={{
              fontWeight: 400,
              marginLeft: 10,
              color: Colors.FoldPixel.GRAY400
            }}
            textLocalId={props.textLocaleId}
          />
        </HStack>
        </>
      ) : (
        <Checkbox
          colorScheme={'purple'}
          value={`${props?.isChecked ?? ''}`}
          style={{
            display: 'flex',
            marginLeft: 2,
            marginBottom: 10,
          }}
          isChecked={!isConsentRequired ? true : props?.isChecked}
          onChange={() => {
            props?.setConsent();
          }}
          isDisabled={!isConsentRequired}
        >
          <Text
            textAlign={'left'}
            size={'smMedium'}
            color={'#F37232'}
            marginLeft={2}
            marginTop={7}
          >
            {intl.formatMessage({id: props.textLocaleId})}
          </Text>
        </Checkbox>
      )}
    </>
  );
};
