import {Collapse} from 'antd';
import {HStack, IconButton, Tooltip, View} from 'native-base';
import React from 'react';
import {Colors} from '../../../../../styles';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import { ExpandIconPosition } from 'antd/lib/collapse/Collapse';
import CustomButton from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomButton/CustomButton';
import { CollapsibleType } from 'antd/lib/collapse/CollapsePanel';
import './DetailViewCollapseStyle.css'

const {Panel} = Collapse;

export interface IDetailViewCollapseBtn {
  icon?: JSX.Element;
  onIconBtnClick?: () => void;
  tooltipLabel: string;
  isCustomButton?: boolean;
  label?:  string
  hide?: boolean,
  disabled?: boolean
  placement?: string
}

export interface IDetailViewCollapseProps {
  extraPanelStyles?: any;
  textLocalId: string;
  children: JSX.Element;
  btnList: IDetailViewCollapseBtn[];
  style?: any;
  headerStyle?: any;
  extraTextStyles?: any;
  toggled: boolean;
  onToggled: () => void;
  headerRightView?: JSX.Element;
  subHeader?: JSX.Element;
  hideArrow?: boolean;
  expandIconPosition?: ExpandIconPosition;
  headerIcon?:JSX.Element
  collapsible?: CollapsibleType;
  headerSuffix?: JSX.Element;
  className?: string;
}

const DetailViewCollapse = (props: IDetailViewCollapseProps) => {
  const {
    children,
    textLocalId,
    btnList,
    headerRightView,
    headerStyle,
    extraPanelStyles,
    extraTextStyles,
    hideArrow,
    collapsible
  } = props;

  return (
    <>
      <Collapse
        className={`${props?.className || ''}`}
        defaultActiveKey={
          props.toggled ? [textLocalId.split(' ').join('_')] : []
        }
        collapsible={collapsible}
        activeKey={props.toggled ? textLocalId.split(' ').join('_') : '0'}
        expandIconPosition={props?.expandIconPosition || 'end'}
        style={{
          borderRadius: 10,
          borderWidth: 1,
          borderColor: '#D0D5DD',
          padding: 0,
          ...extraPanelStyles,
        }}
        onChange={() => {
          props.onToggled();
        }}
      >
        <Panel
          showArrow={!hideArrow}
          style={{borderColor: extraPanelStyles?.borderColor}}
          header={
            <div className='page-break'>
              <View style={headerStyle}>
                <HStack alignItems={'center'}>
                {props?.headerIcon}
                <DisplayText
                  size={'smMedium'}
                  extraStyles={{color: Colors.FoldPixel.GRAY400, ...extraTextStyles}}
                  textLocalId={textLocalId}
                />
                {props.headerSuffix}
                </HStack>
                {props.subHeader}
              </View>
            </div>
          }
          key={textLocalId.split(' ').join('_')}
          extra={
            <View>
              {headerRightView}
              {btnList.map((singleBtn, index) => {
                if (singleBtn?.hide) {
                  return null;
                }
                return (
                  <Tooltip
                    label={singleBtn.tooltipLabel}
                    placement={singleBtn.placement as any || 'top'}
                    key={index}
                  >
                    {singleBtn.isCustomButton ? (
                      <CustomButton
                        isDisabled={singleBtn.disabled}
                        leftIcon={singleBtn?.icon}
                        title={singleBtn?.label || singleBtn.tooltipLabel}
                        onPress={() => singleBtn?.onIconBtnClick?.()}
                        tooltipLabel={singleBtn.tooltipLabel}
                      />
                    ) : (
                      <IconButton
                        icon={singleBtn.icon}
                        onPress={singleBtn.onIconBtnClick}
                        _hover={{background: 'transparent'}}
                        padding={'0'}
                        marginTop={'1'}
                        marginLeft={'1.5'}
                      />
                    )}
                  </Tooltip>
                );
              })}
            </View>
          }
        >
          {children}
        </Panel>
      </Collapse>
    </>
  );
};

export default DetailViewCollapse;
