import {
  View,
  Text,
  VStack,
  useMediaQuery,
  HStack,
  Divider,
  Stack,
} from 'native-base';
import React, { useContext, useRef } from 'react';
import {Colors} from '../../../../../../../styles';
import { useContainerDimensions } from '../../../../../../CustomHooks/ContainerDimensionHook';
import { FormContext, IFormCommonData } from '../CustomWrapper';
import { CommonDataContext } from '../../../../../../../context/CommonDataContext';

export interface ICustomDetailColumn {
  title: string | JSX.Element;
  flex: number;
  render: (item: any, index: number) => void;
}

const CustomDetailTableView = (props: {
  dataSource: any[];
  columns: ICustomDetailColumn[];
  isPreviewMode?: boolean;
  renderAdditionalView?: (item: any, index: number) => void;
  isPatientNote?: boolean
  isBuilderMode?: boolean
}) => {
  const componentRef = useRef();
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const { width } = useContainerDimensions(componentRef);
  const [isMobileScreenWidth] = props.isPatientNote || props.isBuilderMode ? [width <= 1180] : [width <= 480];
  const isMobileScreen = props.isPreviewMode ? false : isMobileScreenWidth;
  const {dataSource, columns} = props;
  const isCarePlanMode = contextData.formContext === FormContext.patientCarePlan;
  const isCarePlanScreenWidth = isCarePlanMode &&  width <= 680

  return (
    <VStack ref={componentRef} paddingTop={0.5} paddingX={0.5} paddingBottom={0} space={2}>
      {(!isMobileScreen && !isCarePlanScreenWidth) && (
        <View>
          <HStack paddingY={4} marginLeft={3}>
            {columns.map((column, index) => {
              return (
                <View
                  flex={column.flex}
                  width="100%"
                  key={`${column.title}_${index}`}
                >
                  <Text flex={1} size='thMedium' color={Colors.FoldPixel.GRAY300}>
                    {column.title}
                  </Text>
                </View>
              );
            })}
          </HStack>
          <Divider />
        </View>
      )}
      {dataSource.map((item, dataSourceIndex) => {
        const key = `${item.id || item.uniqueId || item.name}_${dataSourceIndex}`;
        return (
          <View key={key}>
            <Stack
              direction={(isMobileScreen || isCarePlanScreenWidth) ? 'column' : 'row'}
              space={4}
              flex={1}
              alignItems={'flex-start'}
              justifyContent={(isMobileScreen || isCarePlanScreenWidth) ? 'center' : 'flex-start'}
              padding={3}
              marginX={(isMobileScreen || isCarePlanScreenWidth) ? 2 : 0}
              mb={2}
              style={
                isMobileScreen
                  ? {
                      borderColor: Colors.Custom.BorderColor,
                      borderWidth: 1,
                      borderRadius: 10,
                    }
                  : {}
              }
            >
              <VStack w="100%">
                <HStack
                  flex={1}
                  alignItems="flex-start"
                  h="100%"
                  direction={(isMobileScreen || isCarePlanScreenWidth) ? 'column' : 'row'}
                  space={4}
                  justifyContent={(isMobileScreen || isCarePlanScreenWidth) ? 'center' : 'flex-start'}
                >
                  {columns.map((column, index) =>
                    (isMobileScreen || isCarePlanScreenWidth) ? (
                      index !== columns.length - 1 && (
                        <HStack
                          key={`${key}_${column.title}`}
                          space={2}
                          flex={column.flex}
                          alignItems="flex-start"
                          width="100%"
                          mx={1}
                          my={1}
                        >
                          {(isMobileScreen || isCarePlanScreenWidth) && (
                            <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250} flex={1}>{column.title}</Text>
                          )}
                          <View flex={4}>{column.render(item, dataSourceIndex)}</View>
                        </HStack>
                      )
                    ) : (
                      <HStack
                        key={`${key}_${column.title}`}
                        space={1}
                        flex={column.flex}
                        alignItems="flex-start"
                        width="100%"
                        mx={1}
                        my={1}
                      >
                        {(isMobileScreen || isCarePlanScreenWidth) && <Text flex={1}>{column.title}</Text>}
                        <View flex={4}>{column.render(item, dataSourceIndex)}</View>
                      </HStack>
                    )
                  )}

                </HStack>
                {props.renderAdditionalView ? (
                  props.renderAdditionalView(item, dataSourceIndex)
                ) : (
                  <></>
                )}
                 {(isMobileScreen || isCarePlanScreenWidth) && (
                    <>
                    <Divider my={2} />
                    <HStack
                      key={`${key}_${columns[columns.length - 1].title}`}
                      space={2}
                      flex={columns[columns.length - 1].flex}
                      alignItems="flex-start"
                      width="100%"
                      mx={1}
                      my={1}
                      >
                      <View flex={4}>
                        {columns[columns.length - 1].render(item, dataSourceIndex)}
                      </View>
                    </HStack>
                      </>
                  )}
              </VStack>
            </Stack>
            {dataSourceIndex !== dataSource.length - 1 && !isMobileScreen && <Divider />}
          </View>
        );
      })}
    </VStack>
  );
};

export default CustomDetailTableView;
