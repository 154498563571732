import React from 'react';
import { Path, Svg } from 'react-native-svg';

const DownArrowSvg = () => {
  return (
    <Svg width="5" height="4" viewBox="0 0 5 4" fill="none">
    <Path d="M2.5 3L4.5 1H0.5L2.5 3Z" fill="#D0D6E1" stroke="#D0D6E1" stroke-linecap="round" stroke-linejoin="round"/>
    </Svg>   
  );
};

export default React.memo(DownArrowSvg);
