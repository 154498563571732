import { Select, Spin } from 'antd';
import { HStack, Text, View, useToast } from 'native-base';
import { useEffect, useState, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { ISearchFieldProps } from '../../../Interfaces';
import { ContentTypes } from '../../RightSideContainer/ContentManagement/ContentManagementConsts';
import {
  getTemplateCategories,
  getTemplateCategoryList,
  getTemplates
} from '../../RightSideContainer/ContentManagement/ContentManagementUtils';
import AddOrUpdateSMSTemplatesBody from '../../RightSideContainer/ContentManagement/SMSTemplates/AddOrUpdateSMSTemplates/AddOrUpdateSMSTemplatesBody';
import FormListConfigField from './FormListConfigField';
import { CaretDownOutlined, DownOutlined } from '@ant-design/icons';
import { Colors } from '../../../styles';
import {CloudTelephonyQueries} from '../../../services/';
import {CLOUD_TELEPHONY_APOLLO_CONTEXT} from '../../../constants/Configs';
import {getAccountUUID} from '../../../utils/commonUtils';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { useLazyQuery } from '@apollo/client';
import {ToastType, showToast } from '../../../utils/commonViewUtils';
import { filteredFormsCategoryList } from '../../../utils/commonUtils';
const { Option } = Select;

const SmsTemplateSearch = (props: ISearchFieldProps) => {
  const MULTIPLE_PATIENT_FORMS = 'MULTIPLE_PATIENT_FORMS';
  const { isShowError, onChange } = props;
  const [value, setValue] = useState<any>(props.value);
  const [loading, setLoading] = useState(false);
  const [templateDataList, setTemplateDataList] = useState<any[]>([]);
  const [categoryList, setCategoryList] = useState<any[]>([]);
  const [searchParams] = useSearchParams();
  const intl = useIntl();
  const [isSmsNumberPresent, setIsSmsNumberPresent] = useState<boolean>(true);
  const commonData = useContext(CommonDataContext);
  const accountUuid = getAccountUUID();
  const toast = useToast();

  const [checkDefaultNumber] = useLazyQuery(
    CloudTelephonyQueries.CHECK_DEFAULT_NUMBER,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );


  const checkDefaultNumberPresent = async () => {

    const CommunicationType = commonData.CLOUD_TELEPHONY_MLOV?.CommunicationType || [];

    const smsTypeId = CommunicationType.filter((communication) => {
      return communication?.code === 'SMS';
    });
    const response = await checkDefaultNumber({
      context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
      variables: {
        accountUuid: accountUuid,
        defaultCommunicationTypeId: smsTypeId[0]?.id || '',
        tenantId: accountUuid
      },
    }).catch((err) => {

      showToast(
        toast,
        'Could not check default SMS Number',
        ToastType.error
      )
    });

    if(response?.data?.numberDefaultCommunications && !response?.data?.numberDefaultCommunications?.length) {
      setIsSmsNumberPresent(false);
    }
    return;
  };

  useEffect(() => {
    checkDefaultNumberPresent();
  }, []);

  const setTemplateDefaultData = () => {
    if (value && value?.template?.id) {
      if (templateDataList.length === 0) {
        setTemplateDataList([
          {
            id: value?.template?.id,
            attributes: value?.template?.attributes,
            body: value?.body
          },
        ]);
      } else {
        const isPresent = templateDataList.some((templateData) => {
          return templateData.id === value?.template?.id;
        });
        if (!isPresent) {
          setTemplateDataList([
            {
              id: value?.template?.id,
              attributes: value?.template?.attributes,
              body: value?.body
            },
          ]);
        }
      }
    }
  };
  const setCategoryDefaultData = () => {
    if (value && value?.category?.id) {
      if (categoryList.length === 0) {
        setCategoryList([
          {
            id: value?.category?.id,
            name: value?.category?.name,
            code: value?.category?.code,
          },
        ]);
      } else {
        const isPresent = categoryList.some((category) => {
          return category.id === value?.category?.id;
        });
        if (!isPresent) {
          setCategoryList([
            {
              id: value?.category?.id,
              name: value?.category?.name,
              code: value?.category?.code,
            },
          ]);
        }
      }
    }
  };

  const DEFAULT_CATEGORY_CODE =
    props.defaultTemplateCategory || searchParams.get('DEFAULT_CATEGORY_CODE') || 'GENERAL';
  const DEFAULT_TEMPLATE_ID = searchParams.get('DEFAULT_TEMPLATE_ID');
  const [isInit, setIsInit] = useState(false)
  useEffect(() => {
    setTemplateDefaultData();
    setCategoryDefaultData();
    getTemplateCategories()
      .then((data) => {
        return getTemplateCategoryList(data);
      })
      .then((list) => {
        const filteredCategories = filteredFormsCategoryList(list)
        if (!value || DEFAULT_CATEGORY_CODE != value?.category?.code || !isInit) {
          setIsInit(true);
          filteredCategories.some((category) => {
            if (category.code === DEFAULT_CATEGORY_CODE) {
              searchTemplateList(category.code, (templateList: any) => {
                templateList.some((template: any) => {
                  if (
                    (template?.attributes?.isDefault && !DEFAULT_TEMPLATE_ID) ||
                    (DEFAULT_TEMPLATE_ID && DEFAULT_TEMPLATE_ID == template?.id)
                  ) {
                    const attributes: any = {};

                    if (template.attributes) {
                      attributes.name = template.attributes.name;
                      attributes.isDefault = template.attributes.isDefault;
                      attributes.body = template.attributes.body || '';
                    }
                    if (!value) {
                      setValue((value: any) => {
                        const newValue = {
                          category,
                          template: {
                            id: template.id,
                            attributes: attributes,
                          },
                          body: attributes.body || '',
                          smsBodyUpdatedAt : new Date()
                        };
                        onChange(newValue);
                        return newValue;
                      });
                    }
                    return true;
                  }
                });
              });
            }
          });
        }
        setCategoryList(filteredCategories);
      })
      .catch((error) => {});
  }, []);

  const searchTemplateList = (categoryCode?: string, callback?: any) => {
    setTemplateDataList([]);
    setLoading(true);
    const templateCategoryCode = value?.category?.code;
    const path = `${ContentTypes.textMessages.path}?category=${categoryCode || templateCategoryCode
      }`;
    getTemplates(path).then((response) => {
      setLoading(false);
      setTemplateDataList(response.data || []);
      callback ? callback(response.data) : [];
    });
  };
  const userInputFieldList = props?.userInputFieldList || [];
  const isFormListUserInputFieldPresent = userInputFieldList.some((userInputField:any)=>{ return userInputField?.key === 'formList'});
  const isAppointmentTypeUserInputFieldPresent = userInputFieldList.some((userInputField:any)=>{ return userInputField?.key === 'appointmentType'});
  const smsDefaultNotPresentError = `Please set a default phone number for sending SMS before proceeding.`;
  return (
    <>
      <FormListConfigField templateType='SMS' isAppointmentTypeUserInputFieldPresent={isAppointmentTypeUserInputFieldPresent} isFormListUserInputFieldPresent={isFormListUserInputFieldPresent} value={value} isShowError={false} isAutomationView={props?.isAutomationView} onChange={(newValue:any)=>{
        setValue((value:any)=>{
          const newValueMerge = {...(value||{}), ...(newValue||{})}
          props.onChange(newValueMerge);
          return newValueMerge
        });

      }}></FormListConfigField>
      {props.isHideTemplateCategory ? <></> : <HStack>

        <HStack flex={2.4}>
          <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400} flex={2} marginTop={1}>
            {'Category'}
          </Text>
        </HStack>
        <HStack flex={7}>
          <View marginY={1} flex={1}>
            <Select
              suffixIcon={ props.isAutomationView?
                <CaretDownOutlined
                  style={{
                    color: Colors.FoldPixel.GRAY300
                  }}
                /> :
                <DownOutlined/>
              }
              size="large"
              showSearch
              //allowClear
              value={value?.category ? value?.category?.name : undefined}
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(value: any[], data: any) => {
                if (data && data.value) {
                  setValue((value: any) => {
                    const selectedCategory = categoryList.find(
                      (item) => item.code === data.code
                    );
                    const mergeTags = selectedCategory?.mergeTags || {};
                    const newValue = {category: {...data, mergeTags}};
                    onChange(newValue);
                    setTemplateDataList([]);

                    searchTemplateList(data.code, (templateList: any) => {
                      templateList.some((template: any) => {
                        if (template?.attributes?.isDefault) {
                          setValue((value: any) => {
                            const newValue = {
                              ...value,
                              template: {
                                id: template.id,
                                attributes: {
                                  name: template?.attributes?.name,
                                },
                              },
                              body: template?.attributes?.body || '',
                              smsBodyUpdatedAt: new Date()
                            };
                            onChange(newValue);
                            return newValue;
                          });
                          return true;
                        }
                      });
                    });
                    return newValue;
                  });
                }
              }}
              placeholder="Select Category"
              //loading={loading}
              //notFoundContent={loading && <Spin size="small" />}
              style={{ height: '40px' }}
            //className={isShowError && !value ? 'field-error' : ''}
            >
              {categoryList.map((category: any) => {
                return (
                  <Select.Option
                    key={category.code}
                    id={category.id}
                    value={category.name}
                    name={category.name}
                    code={category.code}
                  >
                    {category?.name}
                  </Select.Option>
                );
              })}
            </Select>
          </View>
        </HStack>
      </HStack>}

      <View style={{ marginTop: 16 }}>
        <HStack>
          <HStack flex={2.4}>
            <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400} flex={2} marginTop={1}>
              {'Template'}
            </Text>
          </HStack>
          <HStack flex={7}>
            <View marginY={1} flex={1}>
              <Select
                suffixIcon={ props.isAutomationView?
                  <CaretDownOutlined
                    style={{
                      color: Colors.FoldPixel.GRAY300
                    }}
                  /> :
                  <DownOutlined/>
                }
                key={value?.category?.code}
                size="large"
                showSearch
                allowClear
                //filterOption={false}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                status={props.isShowError && !value?.template?.id ? 'error' : ''}
                value={
                  value?.template?.id ?
                    value?.template?.attributes?.name
                    :
                    undefined
                }
                onChange={(value: any[], data: any) => {
                  setValue((value: any) => {
                    const newValue: any = {
                      ...value,
                    };
                    const template = data?.id
                      ? {
                        id: data?.id,
                        attributes: {
                          name: data.name,
                        },
                      }
                      : undefined;

                    newValue.template = template;
                    newValue.body = data?.body || '';
                    newValue.smsBodyUpdatedAt = new Date()
                    onChange(newValue);
                    return newValue;
                  });
                }}
                placeholder="Search Template"
                loading={loading}
                notFoundContent={loading && <Spin size="small" />}
                style={{ height: '40px' }}
                className={isShowError && !value ? 'field-error' : ''}
              >
                {templateDataList.map((template) => {
                  return (
                    <Select.Option
                      key={template.id}
                      name={template?.attributes?.name}
                      id={template.id}
                      body={template.attributes.body}
                    >
                      {template?.attributes?.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </View>
          </HStack>
        </HStack>
      </View>

      <View style={{ marginTop: 16 }}>
        <HStack flex={1}>
          <HStack flex={2.4}>
            <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400} flex={2} marginTop={1}>
              {'Body'}
              {<Text color="error.500">*</Text>}
            </Text>
          </HStack>
          <HStack flex={7}>
            <AddOrUpdateSMSTemplatesBody
              isHideLabel={true}
              errors={props.isShowError && (!value || value?.body == undefined) ? { body: " " } : {}}
              smsTemplateData={value}
              setSmsTemplateData={(newValue: any) => {
                if(value?.body && value?.body == undefined){
                  setValue(null)
                }else{
                  if (props.isAllowEdit) {
                    setValue(newValue)
                    setValue((prev: any) => {
                      const newValue = {...prev, body: prev.body, formList: value?.formList };
                      onChange(newValue, true);
                      return newValue;
                    });
                  }
                }
              }}
              isAutomationView = {props.isAutomationView}
            ></AddOrUpdateSMSTemplatesBody>
          </HStack>
        </HStack>
      </View>
      <View >
        {!isSmsNumberPresent &&
          <Text style={{color: Colors.Custom.ErrorColor}}>{smsDefaultNotPresentError}</Text>
        }
      </View>
    </>
  );
};

export default SmsTemplateSearch;
