import React from 'react';
import { View } from 'native-base';
import { isWeb } from '../../../utils/platformCheckUtils';
export const DOCUMENT_CATEGORY_CODES = {
    OTHERS: "others",
    EXTERNAL_VISIT_SUMMARY: "external_visit_summary",
    CONSENT_FORMS: "consent_forms",
    HANDOUTS: "handouts",
    HEALTH_ASSESSMENT: "health_assessment",
    CARE_PLANS: "care_plans",
    REFERRALS: "referrals",
    REPORTS: "reports",
    FORMS: "forms",
    PERSONAL_IDS: "personal_ids",
    MEDICAL_RECORDS: "medical_records",
    IMAGING_REPORTS: "imaging_reports",
    LAB_REPORTS: "lab_reports",
    PHYSICAL_THERAPY_NOTES: "physical_therapy_notes",
    ALL_DOCUMENTS: "all_documents",
    JOURNEY_DOCUMENT_RENDER: "journey_document_render"
};
import { G, Path, Svg } from "react-native-svg";


const DocumentCategoryIcon = (props: { categoryCode: string, color?: string, size?: number }) => {
    switch (props?.categoryCode) {
        case DOCUMENT_CATEGORY_CODES.ALL_DOCUMENTS:
            if (isWeb()) {
                return (
                    <svg width={props?.size || 24} height={props?.size || 24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22 12H2M22 12V18C22 18.5304 21.7893 19.0391 21.4142 19.4142C21.0391 19.7893 20.5304 20 20 20H4C3.46957 20 2.96086 19.7893 2.58579 19.4142C2.21071 19.0391 2 18.5304 2 18V12M22 12L18.55 5.11C18.3844 4.77679 18.1292 4.49637 17.813 4.30028C17.4967 4.10419 17.1321 4.0002 16.76 4H7.24C6.86792 4.0002 6.50326 4.10419 6.18704 4.30028C5.87083 4.49637 5.61558 4.77679 5.45 5.11L2 12M6 16H6.01M10 16H10.01" stroke={props?.color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                )
            }
            else {
                return (
                    <Svg
                        width={props?.size || 24}
                        height={props?.size || 24}
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <Path
                            stroke={props?.color || "#667085"}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            d="M22 12H2m20 0v6a2 2 0 01-2 2H4a2 2 0 01-2-2v-6m20 0l-3.45-6.89A2 2 0 0016.76 4H7.24a2 2 0 00-1.79 1.11L2 12m4 4h.01M10 16h.01"
                        ></Path>
                    </Svg>
                )
            }
        case DOCUMENT_CATEGORY_CODES.OTHERS:
            if (isWeb()) {
                return (
                    <View>
                        <svg width={props?.size || 24} height={props?.size || 24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2 17L12 22L22 17M2 12L12 17L22 12M12 2L2 7L12 12L22 7L12 2Z" stroke={props?.color || "#667085"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </View>
                );
            }
            else return (
                <Svg
                    width={props?.size || 24}
                    height={props?.size || 25}
                    fill="none"
                    viewBox="0 0 24 25"
                >
                    <G>
                        <Path
                            stroke={props?.color || "#667085"}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            d="M2 17.5l10 5 10-5m-20-5l10 5 10-5m-10-10l-10 5 10 5 10-5-10-5z"
                        ></Path>
                    </G>
                </Svg>
            );

        case DOCUMENT_CATEGORY_CODES.EXTERNAL_VISIT_SUMMARY:
            if (isWeb()) {
                return (
                    <View>
                        <svg width={props?.size || "24"} height={props?.size || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14 23H5.57C3.05 23 1 20.95 1 18.43V5.57C1 3.05 3.05 1 5.57 1H18.43C20.96 1 23 3.05 23 5.57V14M10.01 6H18M6.01 6H7.01M6.01 10.01H18M6.01 14.01H10.01M14.05 17.63L13.93 14.1C13.93 14 14.01 13.92 14.11 13.92L17.64 14.04C17.73 14.04 17.82 14.08 17.89 14.15L22.26 18.52C23.1 19.36 23.1 20.72 22.26 21.56L21.58 22.24C20.74 23.08 19.38 23.08 18.54 22.24L14.17 17.87C14.1 17.8 14.06 17.71 14.06 17.62L14.05 17.63Z" stroke={props?.color || "#667085"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </View>
                );
            }
            else return (
                <Svg
                    width={props?.size || 24}
                    height={props?.size || 25}
                    fill="none"
                    viewBox="0 0 24 25"
                >
                    <G>
                        <Path
                            stroke={props?.color || "#667085"}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            d="M14 23.5H5.57C3.05 23.5 1 21.45 1 18.93V6.07C1 3.55 3.05 1.5 5.57 1.5h12.86C20.96 1.5 23 3.55 23 6.07v8.43m-12.99-8H18m-11.99 0h1m-1 4.01H18m-11.99 4h4m4.04 3.62l-.12-3.53c0-.1.08-.18.18-.18l3.53.12c.09 0 .18.04.25.11l4.37 4.37c.84.84.84 2.2 0 3.04l-.68.68c-.84.84-2.2.84-3.04 0l-4.37-4.37a.358.358 0 01-.11-.25l-.01.01z"
                        ></Path>
                    </G>
                </Svg>
            );
        case DOCUMENT_CATEGORY_CODES.CONSENT_FORMS:
            if (isWeb()) {
                return (
                    <View>
                        <svg width={props?.size || 24} height={props?.size || 24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8M14 2L20 8M14 2V8H20M16 13H8M16 17H8M10 9H8" stroke={props?.color || "#667085"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </View>
                );
            }
            else return (
                <Svg
                    width={props?.size || 24}
                    height={props?.size || 24}
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <Path
                        stroke={props?.color || "#667085"}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M14 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V8m-6-6l6 6m-6-6v6h6m-4 5H8m8 4H8m2-8H8"
                    ></Path>
                </Svg>
            );
        case DOCUMENT_CATEGORY_CODES.HANDOUTS:
            if (isWeb()) {
                return (
                    <View>
                        <svg width={props?.size || 24} height={props?.size || 24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V9M13 2L20 9M13 2V9H20" stroke={props?.color || "#667085"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </View>
                );
            }
            else return (
                <Svg
                    width={props?.size || 24}
                    height={props?.size || 25}
                    fill="none"
                    viewBox="0 0 24 25"
                >
                    <G>
                        <Path
                            stroke={props?.color || "#667085"}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            d="M13 2.5H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2v-11m-7-7l7 7m-7-7v7h7"
                        ></Path>
                    </G>
                </Svg>
            )
        case DOCUMENT_CATEGORY_CODES.HEALTH_ASSESSMENT:
            if (isWeb()) {
                return (
                    <View>
                        <svg width={props?.size || "24"} height={props?.size || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.7002 5.75H8.30024M15.7002 5.75H18.8877C20.6127 5.75 22.0002 7.1375 22.0002 8.8625V18.8875C22.0002 20.6125 20.6127 22 18.8877 22H5.11274C3.38774 22 2.00024 20.6125 2.00024 18.8875V8.8625C2.00024 7.1375 3.38774 5.75 5.11274 5.75H8.30024M15.7002 5.75V3.575C15.7002 2.7 14.9877 2 14.1252 2H9.87524C9.00024 2 8.30024 2.7125 8.30024 3.575V5.75M12.0002 17.675V13.875M12.0002 13.875V10.075M12.0002 13.875H15.8002M12.0002 13.875H8.20024" stroke={props?.color || "#667085"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </View>
                );
            }
            else return (
                <Svg
                    width={props?.size || 24}
                    height={props?.size || 25}
                    fill="none"
                    viewBox="0 0 24 25"
                >
                    <G>
                        <Path
                            stroke={props?.color || "#667085"}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            d="M15.7 6.25H8.3m7.4 0h3.188A3.105 3.105 0 0122 9.363v10.024a3.105 3.105 0 01-3.112 3.113H5.113A3.105 3.105 0 012 19.387V9.364A3.105 3.105 0 015.113 6.25H8.3m7.4 0V4.075c0-.875-.712-1.575-1.575-1.575h-4.25C9 2.5 8.3 3.212 8.3 4.075V6.25M12 18.175v-3.8m0 0v-3.8m0 3.8h3.8m-3.8 0H8.2"
                        ></Path>
                    </G>
                </Svg>
            );
        case DOCUMENT_CATEGORY_CODES.CARE_PLANS:
            if (isWeb()) {
                return (
                    <View>
                        <svg width={props?.size || "24"} height={props?.size || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.638 14.9858L16.6016 17.7482C16.6016 18.0572 16.4835 18.3479 16.2563 18.5751L13.0033 21.8282C12.6761 22.1553 12.1218 21.9917 12.0128 21.5374L11.2768 18.3934M9.00508 7.35296L6.24271 7.3893C5.93376 7.3893 5.64298 7.50743 5.41581 7.7346L2.17184 10.9786C1.84472 11.3057 2.00828 11.86 2.46262 11.969L5.60664 12.7051M3.80746 17.9299L2.42627 19.302M6.06097 20.1835L4.68887 21.5646M4.62527 14.5315C4.62527 14.5315 8.56892 6.6351 12.349 3.7546C16.12 0.883186 21.5902 2.40067 21.5902 2.40067C21.5902 2.40067 23.1168 7.86181 20.2363 11.6419C17.3558 15.422 9.45942 19.3656 9.45942 19.3656L4.62527 14.5315Z" stroke={props?.color || "#667085"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </View>
                );
            }
            else return (
                <Svg
                    width={props?.size || 24}
                    height={props?.size || 25}
                    fill="none"
                    viewBox="0 0 24 25"
                >
                    <Path
                        stroke={props?.color || "#667085"}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M16.638 15.486l-.036 2.762c0 .31-.119.6-.346.827l-3.253 3.253a.59.59 0 01-.99-.29l-.736-3.145M9.005 7.853l-2.762.036c-.31 0-.6.118-.827.346l-3.244 3.244a.59.59 0 00.29.99l3.145.736m-1.8 5.225l-1.38 1.372m3.634.881l-1.372 1.382m-.064-7.034S8.57 7.135 12.35 4.255C16.12 1.383 21.59 2.9 21.59 2.9s1.527 5.46-1.354 9.24c-2.88 3.781-10.777 7.725-10.777 7.725L4.625 15.03z"
                    ></Path>
                </Svg>
            );
        case DOCUMENT_CATEGORY_CODES.REFERRALS:
            if (isWeb()) {
                return (
                    <View>
                        <svg width={props?.size || "24"} height={props?.size || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5M3 5C3 3.9 3.9 3 5 3H19C20.1 3 21 3.9 21 5M3 5V16C3 17.1 3.9 18 5 18H19C20.1 18 21 17.1 21 16V5M8.36 10.5H15.65M15.65 10.5L12.01 14.14M15.65 10.5L12.01 6.86" stroke={props?.color || "#667085"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </View>
                );
            }
            else return (
                <Svg
                    width={props?.size || 24}
                    height={props?.size || 25}
                    fill="none"
                    viewBox="0 0 24 25"
                >
                    <Path
                        stroke={props?.color || "#667085"}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M3 5.5v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14m-18 0c0-1.1.9-2 2-2h14c1.1 0 2 .9 2 2m-18 0v11c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-11M8.36 11h7.29m0 0l-3.64 3.64M15.65 11l-3.64-3.64"
                    ></Path>
                </Svg>
            );
        case DOCUMENT_CATEGORY_CODES.REPORTS:
            if (isWeb()) {
                return (
                    <View>
                        <svg width={props?.size || "24"} height={props?.size || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7M15 2L20 7M15 2V7H20M17 12L12.6818 16.3182L10.4091 14.0455L7 17.4545M17 12H14.2725M17 12L16.9997 14.7273" stroke={props?.color || "#667085"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </View>
                );
            }
            else return (
                <Svg
                    width={props?.size || 24}
                    height={props?.size || 24}
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <Path
                        stroke={props?.color || "#667085"}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M15 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V7m-5-5l5 5m-5-5v5h5m-3 5l-4.318 4.318-2.273-2.273L7 17.456M17 12h-2.727M17 12v2.727"
                    ></Path>
                </Svg>
            );
        case DOCUMENT_CATEGORY_CODES.FORMS:
            if (isWeb()) {
                return (
                    <View>
                        <svg width={props?.size || "24"} height={props?.size || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8M14 2L20 8M14 2V8H20M16 13H8M16 17H8M10 9H8" stroke={props?.color || "#667085"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </View>
                );
            }
            else return (
                <Svg
                    width={props?.size || 24}
                    height={props?.size || 24}
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <Path
                        stroke={props?.color || "#667085"}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M14 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V8m-6-6l6 6m-6-6v6h6m-4 5H8m8 4H8m2-8H8"
                    ></Path>
                </Svg>
            );
        case DOCUMENT_CATEGORY_CODES.PERSONAL_IDS:
            if (isWeb()) {
                return (
                    <View>
                        <svg width={props?.size || "24"} height={props?.size || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.44 15.5V14.39C16.44 13.8 16.21 13.24 15.79 12.82C15.37 12.4 14.81 12.17 14.22 12.17H9.78C9.19 12.17 8.62 12.4 8.21 12.82C7.79 13.24 7.56 13.8 7.56 14.39V15.5M3 6V20C3 21.1 3.9 22 5 22H19C20.1 22 21 21.1 21 20V6M12 9.94C13.23 9.94 14.22 8.94 14.22 7.72C14.22 6.5 13.23 5.5 12 5.5C10.77 5.5 9.78 6.5 9.78 7.72C9.78 8.94 10.77 9.94 12 9.94ZM3 4C3 2.9 3.9 2 5 2H19C20.1 2 21 2.9 21 4V17C21 18.1 20.1 19 19 19H5C3.9 19 3 18.1 3 17V4Z" stroke={props?.color || "#667085"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </View>
                );
            }
            else return (
                <Svg
                    width={props?.size || 24}
                    height={props?.size || 24}
                    fill="none"
                    viewBox="0 0 24 25"
                >
                    <Path
                        stroke={props?.color || "#667085"}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M16.44 16v-1.11c0-.59-.23-1.15-.65-1.57a2.21 2.21 0 00-1.57-.65H9.78c-.59 0-1.16.23-1.57.65-.42.42-.65.98-.65 1.57V16M3 6.5v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14m-9 3.94c1.23 0 2.22-1 2.22-2.22C14.22 7 13.23 6 12 6c-1.23 0-2.22 1-2.22 2.22 0 1.22.99 2.22 2.22 2.22zM3 4.5c0-1.1.9-2 2-2h14c1.1 0 2 .9 2 2v13c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2v-13z"
                    ></Path>
                </Svg>
            );
        case DOCUMENT_CATEGORY_CODES.MEDICAL_RECORDS:
            if (isWeb()) {
                return (
                    <View>
                        <svg width={props?.size || "24"} height={props?.size || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 6V12L16 14M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke={props?.color || "#667085"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </View>
                );
            }
            else return (
                <Svg
                    width={props?.size || 24}
                    height={props?.size || 24}
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <Path
                        stroke={props?.color || "#667085"}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M12 6v6l4 2m6-2c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10z"
                    ></Path>
                </Svg>
            );
        case DOCUMENT_CATEGORY_CODES.IMAGING_REPORTS:
            if (isWeb()) {
                return (
                    <View>
                        <svg width={props?.size || "24"} height={props?.size || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.44737 7.25H15.5526M7.5 10.25H16.5M7.5 13.25H16.5M8.44737 16.25H15.5526M11.7632 5.75L11.7632 17.25M4.89474 23H19.1053C20.1517 23 21 22.1046 21 21V3C21 1.89543 20.1517 1 19.1053 1H4.89474C3.8483 1 3 1.89543 3 3V21C3 22.1046 3.8483 23 4.89474 23Z" stroke={props?.color || "#667085"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </View>
                );
            }
            else return (
                <Svg
                    width={props?.size || 24}
                    height={props?.size || 24}
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <Path
                        stroke={props?.color || "#667085"}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M8.447 7.25h7.106m-8.053 3h9m-9 3h9m-8.053 3h7.106m-3.79-10.5v11.5M4.895 23h14.21C20.152 23 21 22.105 21 21V3c0-1.105-.848-2-1.895-2H4.895C3.848 1 3 1.895 3 3v18c0 1.105.848 2 1.895 2z"
                    ></Path>
                </Svg>
            );
        case DOCUMENT_CATEGORY_CODES.LAB_REPORTS:
            if (isWeb()) {
                return (
                    <View>
                        <svg width={props?.size || "24"} height={props?.size || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.2276 4.91111H12.5975M11.323 8.9H8.693M17.9387 14.2222C17.51 15.8222 16.4094 16.4444 15.019 16.6111M10.7322 16.2333C10.2571 16.1556 9.78209 16.0778 9.33023 16.0111C9.1101 15.9778 8.90155 15.9444 8.693 15.9222C5.25191 15.5333 3.69937 17.1778 3.69937 17.1778M4.04695 22C2.37855 22 1.40531 20.1778 2.40172 18.8889L8.66982 10.6778V4.91111H8.48445C8.06734 4.91111 7.685 4.74444 7.40693 4.48889C7.12887 4.22222 6.96666 3.86667 6.96666 3.45556C6.96666 2.65556 7.65024 2 8.48445 2H15.4245C15.8416 2 16.224 2.16667 16.5021 2.42222C16.7801 2.68889 16.9423 3.05556 16.9423 3.45556C16.9423 4.25556 16.2587 4.91111 15.4245 4.91111H15.2392V10.6778L21.5073 18.8889C22.4921 20.1778 21.5304 22 19.862 22H4.04695ZM15.0306 16.5556C15.0306 17.7031 14.0606 18.6333 12.864 18.6333C11.6674 18.6333 10.6974 17.7031 10.6974 16.5556C10.6974 15.408 11.6674 14.4778 12.864 14.4778C14.0606 14.4778 15.0306 15.408 15.0306 16.5556Z" stroke={props?.color || "#667085"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </View>
                );
            }
            else return (
                <Svg
                    width={props?.size || 24}
                    height={props?.size || 24}
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <Path
                        stroke={props?.color || "#667085"}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M15.228 4.911h-2.63M11.322 8.9h-2.63m9.246 5.322c-.429 1.6-1.53 2.222-2.92 2.39m-4.287-.379c-.475-.077-.95-.155-1.402-.222-.22-.033-.428-.067-.637-.089-3.441-.389-4.994 1.256-4.994 1.256M4.047 22c-1.668 0-2.642-1.822-1.645-3.111l6.268-8.211V4.91h-.186a1.6 1.6 0 01-1.077-.422 1.404 1.404 0 01-.44-1.033c0-.8.683-1.456 1.517-1.456h6.94c.418 0 .8.167 1.078.422.278.267.44.634.44 1.034 0 .8-.683 1.455-1.518 1.455h-.185v5.767l6.268 8.21c.985 1.29.023 3.112-1.645 3.112H4.047zm10.984-5.444c0 1.147-.97 2.077-2.167 2.077-1.197 0-2.167-.93-2.167-2.077 0-1.148.97-2.078 2.167-2.078 1.197 0 2.167.93 2.167 2.078z"
                    ></Path>
                </Svg>
            );
        case DOCUMENT_CATEGORY_CODES.PHYSICAL_THERAPY_NOTES:
            if (isWeb()) {
                return (
                    <View>
                        <svg width={props?.size || 24} height={props?.size || 24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 22H4.5C3.84 22 3.2 21.79 2.73 21.41C2.26 21.03 2 20.53 2 20V4C2 3.47 2.26 2.96 2.73 2.59C3.2 2.22 3.83 2 4.5 2H19.5C20.16 2 20.8 2.21 21.27 2.59C21.74 2.97 22 3.47 22 4V16M16 22L22 16M16 22V16H22M16.3102 7.68988H7.7002M16.3102 11.6899H7.7002M10.3102 15.6899H7.7002" stroke={props?.color || "#667085"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </View>
                );
            }
            else {
                return (
                    <View>
                        <Svg
                            width={props?.size || 24}
                            height={props?.size || 24}
                            fill="none"
                            viewBox="0 0 24 25"
                        >
                            <Path
                                stroke={props?.color || "#667085"}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                                d="M16 22.5H4.5c-.66 0-1.3-.21-1.77-.59S2 21.03 2 20.5v-16c0-.53.26-1.04.73-1.41.47-.37 1.1-.59 1.77-.59h15c.66 0 1.3.21 1.77.59s.73.88.73 1.41v12m-6 6l6-6m-6 6v-6h6m-5.69-8.31H7.7m8.61 4H7.7m2.61 4H7.7"
                            ></Path>
                        </Svg>
                    </View>
                );
            };
        case DOCUMENT_CATEGORY_CODES.JOURNEY_DOCUMENT_RENDER:
            if (isWeb()) {
                return (
                    <View>
                        <svg width={props?.size || "12"} height={props?.size || "12"} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.31898 7.49292L8.3008 8.8741C8.3008 9.02858 8.24174 9.17397 8.12816 9.28755L6.50163 10.9141C6.33807 11.0776 6.06092 10.9959 6.0064 10.7687L5.63839 9.19668M4.50254 3.67648L3.12135 3.69465C2.96688 3.69465 2.82149 3.75372 2.70791 3.8673L1.08592 5.48929C0.922359 5.65285 1.00414 5.92999 1.23131 5.98451L2.80332 6.35253M1.90373 8.96497L1.21314 9.65102M3.03049 10.0917L2.34444 10.7823M2.31263 7.26575C2.31263 7.26575 4.28446 3.31755 6.1745 1.8773C8.06001 0.441593 10.7951 1.20034 10.7951 1.20034C10.7951 1.20034 11.5584 3.93091 10.1182 5.82095C8.67791 7.711 4.72971 9.68282 4.72971 9.68282L2.31263 7.26575Z" stroke={props?.color || "#98A2B3"} stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </View>
                );
            } else {
                return (
                    <View>
                        <Svg
                            width={props?.size || 12}
                            height={props?.size || 12}
                            fill="none"
                            viewBox="0 0 12 12"
                        >
                            <Path
                                stroke={props?.color || "#98A2B3"}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M8.319 7.493l-.018 1.381c0 .155-.06.3-.173.414l-1.626 1.626a.295.295 0 01-.496-.145l-.368-1.572m-1.135-5.52l-1.382.018c-.154 0-.3.059-.413.172L1.086 5.49a.295.295 0 00.145.496l1.572.368m-.9 2.612l-.69.686m1.817.44l-.686.691m-.031-3.516s1.971-3.948 3.861-5.389C8.06.442 10.796 1.2 10.796 1.2s.763 2.73-.677 4.621C8.678 7.71 4.73 9.683 4.73 9.683L2.313 7.266z"
                            ></Path>
                        </Svg>
                    </View>
                );
            }
        default:
            if (isWeb()) {
                return (
                    <View>
                        <Svg width={props?.size || "24"} height={props?.size || "24"} viewBox="0 0 24 24" fill="none" >
                            <Path d="M22 19C22 19.5304 21.7893 20.0391 21.4142 20.4142C21.0391 20.7893 20.5304 21 20 21H4C3.46957 21 2.96086 20.7893 2.58579 20.4142C2.21071 20.0391 2 19.5304 2 19V5C2 4.46957 2.21071 3.96086 2.58579 3.58579C2.96086 3.21071 3.46957 3 4 3H9L11 6H20C20.5304 6 21.0391 6.21071 21.4142 6.58579C21.7893 6.96086 22 7.46957 22 8V19Z" stroke={props?.color || "#667085"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </Svg>
                    </View>
                )
            }
            else {
                return (
                    <View>
                        <Svg
                            width={props?.size || 24}
                            height={props?.size || 24}
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <Path
                                stroke={props?.color || "#667085"}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                                d="M22 19a2 2 0 01-2 2H4a2 2 0 01-2-2V5a2 2 0 012-2h5l2 3h9a2 2 0 012 2v11z"
                            ></Path>
                        </Svg>
                    </View>
                )
            }
    }

}

export default DocumentCategoryIcon;