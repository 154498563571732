import {useLazyQuery, useMutation} from '@apollo/client';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {setOriginalNode} from 'typescript';
import ContactCareProgram, { GET_CARE_PROGRAM_STEPS_DATA_BY_STEP_ID } from '../../../../../services/ContactCareProgram/ContactCareProgram';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../constants/Configs';
import { AttributeKey, IContactCareProgram, IContactCareProgramStep } from '../../interface';
import { getAdditionalAttributeFromStep, getFormDetails } from '../ContactCareProgramView.utils';
import {
  APPOINTMENT_STATUS_CODES, FORM_SOURCE, CARE_PROGRAM_TYPE_CODES, MLOV_CATEGORY
} from '../../../../../constants/MlovConst';
import {
  getMlovIdFromCode,
  getMlovListFromCategory
} from '../../../../../utils/mlovUtils';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import { ADDITIONAL_ATTRIBUTE_KEY, CARE_PROGRAM_STEP_TYPE } from '../../../../common/MemebersView/constant';
import {FormsQueries} from '../../../../../services';
import {getApolloError, getAxiosError, getUserUUID} from '../../../../../utils/commonUtils';
import {v4 as uuidv4} from 'uuid';
import {useCustomToast} from '../../../../Toast/ToastProvider';
import {ToastType} from '../../../../../utils/commonViewUtils';
import {EVENT_NAMES} from '../../../../../constants/EntityAndEventsConst';
import {EventBus} from '../../../../../utils/EventBus';
import { GENERATE_BILL, PROCESS_BILLING, SEND_CLAIM_TCM_OR_AWV } from '../../../CareManagementBilling/BillingQueries';
import { useIntl } from 'react-intl';
import { GET_PREVIOUS_RUNNING_TIME, PERFORM_ACTION_ON_CARE_PROGRAM } from '../../../../../services/CareProgram/CareProgramQueries';
import {
  getPayLoadForOtherActions,
  getPayLoadForStatusAction,
  getSuccessMessageForAction,
} from './CareProgramStepActionUtils';
import { CARE_PROGRAM_EVENTS, useCPEventHandlers } from '../../useCPEventhandler';
import {IAdditionalAttribute} from '../../../../../services/ContactCareProgram/interface';
import { CARE_PROGRAM_EVENT_CODES } from '../../../Contacts/Leads/LeadView/LeadTableView/Helper/CareProgramConst';
import { GET_APPOINTMENT_BY_ID_AND_STATUS } from '../../../../../services/Appointment/AppointmentQueries';
import useCareProgramStepStatus from './useCareProgramStepStatus';

export enum StepAction {
  REVIEW = 'REVIEW',
  SKIP = 'SKIP',
  COMPLETE = 'COMPLETE',
  SEND_TO_PATIENT = 'SEND_TO_PATIENT',
  SEND_CLAIM = 'SEND_CLAIM',
  GENERATE_BILL = 'GENERATE_BILL',
  AUTO_SAVE = 'AUTO_SAVE',
  FORM_RESPONSE_ACCEPTED = 'FORM_RESPONSE_ACCEPTED',
  FORM_RESPONSE_DECLINED = 'FORM_RESPONSE_DECLINED',
}

export interface IUseCareProgramStepActionsParams {
  careProgramDetails?: IContactCareProgram;
  stepId: string;
}

const getErrorMessage = (error: any): string => {
  const possiblePaths = [
    error?.networkError?.result?.message?.extensions?.internal?.response?.body?.message,
    error?.networkError?.result?.message?.extensions?.error,
    error?.extensions?.error?.extensions?.internal?.response?.body?.message,
    error?.message?.extensions?.internal?.response?.body?.message,
    typeof error === 'string' ? error : null
  ];

  const message = possiblePaths.find(path => path !== null && path !== undefined);

  if (message) {
    return message;
  }

  return getAxiosError(error) || getApolloError(error) || 'Something went wrong';
};

export const useCareProgramStepActions = (props: {
  selectedStepCode: string;
  additionalAttributes: IAdditionalAttribute[];
  extraParams?: {
    contactId: string;
    patientId: string;
  };
  formResponseForAssessmentStep?: any;
}) => {
  const {selectedStepCode, extraParams, formResponseForAssessmentStep} = props;
  const {contactId, patientId} = extraParams || {};
  const [isLoading, setIsLoading] = useState<StepAction | undefined>(undefined);
  const intl = useIntl();
  const [selectedBillingActivityIds, setSelectedBillingActivityIds] = useState<string[]>([]);
  // const [showBillConfirmationModal, setShowBillConfirmationModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [pendingBillParams, setPendingBillParams] = useState<IUseCareProgramStepActionsParams | null>(null);
  const [hookState, setHookState] = useState ({
    totalMinutes: 0,
  });
  const {careProgramStepStatus} = useCareProgramStepStatus();
  // const [showSendClaimConfirmationModal, setShowSendClaimConfirmationModal] = useState<boolean>(false);

  const {sync} = useCPEventHandlers({
    syncCode: 'billing-activity-selection',
    listenCode: 'billing-activity-selection',
    onSync: (eventType, data) => {
      if (eventType === 'BILLING_SELECTION_CHANGED') {
        setSelectedBillingActivityIds(data.selectedIds);
      }
    }
  });

  useEffect(() => {
    sync(CARE_PROGRAM_EVENTS.BILLING_SELECTION_CHANGED as keyof typeof CARE_PROGRAM_EVENTS, {
      selectedIds: selectedBillingActivityIds,
      contactCareProgramId: pendingBillParams?.careProgramDetails?.id,
    });
  }, []);


useEffect(() => {
  const eventBus = EventBus.getEventBusInstance();
  eventBus.addEventListener(CARE_PROGRAM_EVENT_CODES.GENERATE_BILLING_DATA, handleGenerateBill);
  return () => {
    eventBus.removeEventListener(handleGenerateBill);
  };
}, [pendingBillParams]);

useEffect(() => {
  const eventBus = EventBus.getEventBusInstance();
  eventBus.addEventListener(CARE_PROGRAM_EVENT_CODES.SEND_CLAIM, handleSendClaim);
  return () => {
    eventBus.removeEventListener(handleSendClaim);
  };
}, []);


  const isAssessmentStep =
    selectedStepCode === CARE_PROGRAM_STEP_TYPE.ASSESSMENT ||
    selectedStepCode === CARE_PROGRAM_STEP_TYPE.USER_ASSESSMENT;

  const toast = useCustomToast();
  const currentUserUuid = getUserUUID();
  const contextData = useContext(CommonDataContext);
  const formSourceList = useMemo(
    () =>
      getMlovListFromCategory(
        contextData.CARE_STUDIO_MLOV,
        MLOV_CATEGORY.FORM_SOURCE
      ),
    [Object.keys(contextData.CARE_STUDIO_MLOV).length]
  );

  const careProgramSubStepList =
    getMlovListFromCategory(
      contextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CONTACT_CARE_PROGRAM_SUB_STEP
    ) || [];

  // API Call
  const [updateContactCareProgramStepStatus] = useMutation(
    ContactCareProgram.UPDATE_CONTACT_CARE_PROGRAM_STEP_STATUS,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );
  const [performCareProgramAction] = useMutation(
    PERFORM_ACTION_ON_CARE_PROGRAM,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );
  const [submitForm] = useMutation(FormsQueries.SUBMIT_FORM_RESPONSE, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });
  const [processBilling] = useMutation(PROCESS_BILLING, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });
  const [sendClaimTCMOrAWV] = useMutation(SEND_CLAIM_TCM_OR_AWV, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });
  const [generateBill] = useMutation(GENERATE_BILL, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });
  const [getAppointmentByIdAndStatus] = useLazyQuery(GET_APPOINTMENT_BY_ID_AND_STATUS, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });
  const [getPreviousRunningTime] = useLazyQuery(GET_PREVIOUS_RUNNING_TIME, {
    fetchPolicy: 'no-cache',
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
  });

  const [getCareProgramStepsDataByStepId] = useLazyQuery(GET_CARE_PROGRAM_STEPS_DATA_BY_STEP_ID, {
    fetchPolicy: 'no-cache',
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
  });

  const onAnyActionTaken = () => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent(CARE_PROGRAM_EVENTS.CARE_PROGRAM_ACTION_TAKEN, {
      syncCode: `care-program-action-taken`,
    });
  };

  const submitFormResponse = async (
    formLogId: string,
    formId: string
  ): Promise<string | undefined> => {
    try {
      const data = formResponseForAssessmentStep || {};
      data.isDraft = false;
      const submitFormResponse = await submitForm({variables: {data}});
      const id = submitFormResponse?.data?.submitFormResponse?.id;
      return id || undefined;
    } catch (error) {
      return undefined;
    }
  };

  const refreshBillingData = async (contactCareProgramId: string) => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent(
      CARE_PROGRAM_EVENTS.BILLING_DATA_CHANGED,
      {
        syncCode: `billing-sync-${contactCareProgramId}`,
      }
    );
  };

  const handleGenerateBill = async (params: {contactCareProgramId: string}) => {
    if (!params) return;

    try {
      const generateBillResponse = await generateBill({
        variables: {
          params: {
            contactCareProgramId: params.contactCareProgramId,
          },
        },
      });

      if (generateBillResponse?.data?.generateCPTCodesForBillableActivity?.success === 'true') {
        toast({
          message: intl.formatMessage({id: 'billGeneratedSuccessfully'}),
          toastType: ToastType.success,
        });
        refreshBillingData(params.contactCareProgramId);
        return generateBillResponse.data.data;
      }
    } catch (error: any) {
      const errorMessage = getErrorMessage(error);
      toast({
        message: errorMessage,
        toastType: ToastType.error,
      });
      return undefined;
    }
  };

  const handleSendClaim = async (params: { contactCareProgramId: string, careProgramType: string }) => {
    if (!params) return;
    const contactCareProgramId = params.contactCareProgramId;
    try {
      const careProgramType = params.careProgramType;
      let response;
      if ([CARE_PROGRAM_TYPE_CODES.CCM, CARE_PROGRAM_TYPE_CODES.ECM].includes(careProgramType)) {
        response = await processBilling({
          variables: {
            params: {
              contactCareProgramIds: contactCareProgramId,
              billableActivityIds: selectedBillingActivityIds,
            },
          },
        });
      } else if ([CARE_PROGRAM_TYPE_CODES.TCM, CARE_PROGRAM_TYPE_CODES.AWV].includes(careProgramType)) {
        response = await sendClaimTCMOrAWV({
          variables: {
            params: {
              contactCareProgramId: contactCareProgramId,
            },
          },
        });
      }
      if (response) {
        toast({
          message: intl.formatMessage({ id: 'claimProcessedSuccessfully' }),
          toastType: ToastType.success,
        });
        if (contactCareProgramId) {
          refreshBillingData(contactCareProgramId);
        }
        return (
          response?.data?.processContactCareProgramBillableActivity?.id ||
          response?.data?.processContactCareProgramBilling?.id
        );
      }
    } catch (error: any) {
      const errorMessage = getErrorMessage(error);
      toast({
        message: errorMessage,
        toastType: ToastType.error,
      });
      return undefined;
    } finally {
      setIsLoading(undefined);
      setShowModal(false);
    }
  }

  const checkAppointmentStepAndStatus = async (
    selectedStep: IContactCareProgramStep
  ) => {
    const appointmentId =
      selectedStep?.careProgramStepAdditionalAttributes?.find(
        (attribute) =>
          attribute.attributeKey === ADDITIONAL_ATTRIBUTE_KEY.APPOINTMENT_ID
      )?.attributeValue?.valueString;

    if (!appointmentId) {
      toast({
        message: intl.formatMessage({id: 'appointmentNotBooked'}),
        toastType: ToastType.info,
      });
      return false;
    }
    try {
      const appoinmentData = await getAppointmentByIdAndStatus({
        variables: {
        id: appointmentId,
      },
    });
    const isAppointmentStepCompleted =
      appoinmentData?.data?.appointments[0]?.status?.code ===
        APPOINTMENT_STATUS_CODES.CHECKED_OUT || false;
    if (!isAppointmentStepCompleted) {
      toast({
        message: intl.formatMessage({id: 'appointmentNotCompleted'}),
        toastType: ToastType.info,
      });
    }

    return isAppointmentStepCompleted;
    } catch (error) {
      toast({
        message: intl.formatMessage({id: 'apiErrorMsg'}),
        toastType: ToastType.error,
      });
      return false;
    }
  };

  const getSelectedStepData = async (contactCareProgramId: string,careProgramStepId: string) => {
    const careProgramStepsDataByStepId = await getCareProgramStepsDataByStepId({
      variables: {
        contactCareProgramId: contactCareProgramId,
        careProgramStepId: careProgramStepId,
      },
    });
    return careProgramStepsDataByStepId?.data?.contactCareProgramStepsLogs?.[0];
  };


  const handleContentAction = async (
    action: StepAction,
    params: IUseCareProgramStepActionsParams
  ) => {
    setIsLoading(action);
    try {
      switch (action) {
        // Status actions
        case StepAction.SKIP:
        case StepAction.REVIEW:
        case StepAction.COMPLETE:
          onAnyActionTaken();
          const payLoad = getPayLoadForStatusAction(action, params, {
            contactCareProgramSteps:
              params.careProgramDetails?.contactCareProgramSteps || [],
            careProgramSubStepList: careProgramSubStepList,
            isAssessmentStep: isAssessmentStep,
          });
          let formResponseId;
          const steps = params.careProgramDetails?.contactCareProgramSteps;
          const step = steps?.find((step) => step.id === params.stepId);
          const stepCode = step?.careProgramStepType?.code;
          if (
            action === StepAction.REVIEW &&
            stepCode &&
            [
              CARE_PROGRAM_STEP_TYPE.ASSESSMENT,
              CARE_PROGRAM_STEP_TYPE.USER_ASSESSMENT,
            ].includes(stepCode)
          ) {
            // form submit api call
            const {formId, formLogId} = getFormDetails(
              params.careProgramDetails as IContactCareProgram,
              params.stepId as string
            );
            if (formLogId && formId) {
              formResponseId = await submitFormResponse(formLogId, formId);
            } else {
              throw new Error('Form log id not found');
            }
          }
          if (stepCode && [CARE_PROGRAM_STEP_TYPE.APPOINTMENT, CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_14DAY, CARE_PROGRAM_STEP_TYPE.ANNUAL_VISIT].includes(stepCode)) {
            const isAppointmentStepCompleted = await checkAppointmentStepAndStatus(step);
            if (isAppointmentStepCompleted) {
              const selectedStepData = await getSelectedStepData(
                payLoad.contactCareProgramId,
                payLoad.careProgramStepId,
              );
              if (
                selectedStepData?.careProgramStepStatusId ===
                careProgramStepStatus.done
              ) {
                return careProgramStepStatus.done;
              }
            } else {
              return;
            }
          }
          const response = await updateContactCareProgramStepStatus({
            variables: {
              params: payLoad,
            },
          });

          const newStepLogStatusId =
            response.data?.updateContactCareProgramStatus
              ?.careProgramStepStatusId;

          return newStepLogStatusId;
        // Use this case for tasks, email etc
        case StepAction.FORM_RESPONSE_ACCEPTED:
        case StepAction.FORM_RESPONSE_DECLINED:
          const {formId, formLogId} = getFormDetails(
            params.careProgramDetails as IContactCareProgram,
            params.stepId as string
          );
          const payload = {
            params: {
              actionTypeCode: action,
              careProgramParams: {
                contactCareProgramId: params.careProgramDetails?.id,
                contactCareProgramStepId: params.stepId,
              },
              formResponseParams: {
                contactId: contactId,
                formId: formId,
                formLogId: formLogId,
              },
              formLogLinkParams: {
                contactId: contactId,
                formId: formId,
                sourceId: getMlovIdFromCode(formSourceList, FORM_SOURCE.CARE_PLAN),
                requestedByUserId: currentUserUuid,
                subjectId: crypto.randomUUID(),
              }
            }

          }
          const actionResponse = await performCareProgramAction({
            variables: payload,
          });
          return actionResponse;

        case StepAction.SEND_TO_PATIENT:
          onAnyActionTaken();
          const actionPayload = getPayLoadForOtherActions(action, params, {
            contactCareProgramSteps:
              params.careProgramDetails?.contactCareProgramSteps || [],
            careProgramSubStepList: careProgramSubStepList,
            isAssessmentStep: isAssessmentStep,
            contactId: params?.careProgramDetails?.contactId || '',
            formSourceList,
            currentUserUuid,
            stepId: params.stepId,
            careProgramDetails:
              params.careProgramDetails as IContactCareProgram,
          });
          const performActionResponse = await performCareProgramAction({
            variables: actionPayload,
          });
          if (action === StepAction.SEND_TO_PATIENT) {
            const eventBus = EventBus.getEventBusInstance();
            eventBus.broadcastEvent(EVENT_NAMES.REFRESH_TASK, {});
          }
          toast({
            message: getSuccessMessageForAction(action),
            toastType: ToastType.success,
          });
          return performActionResponse?.data?.performCareProgramAction?.sentToPatientResponse?.formId;

        case StepAction.SEND_CLAIM:
          setShowModal(true);
          setIsLoading(StepAction.SEND_CLAIM);
          break;
        case StepAction.GENERATE_BILL:
          setIsLoading(StepAction.GENERATE_BILL);
          const eventBus = EventBus.getEventBusInstance();
          eventBus.broadcastEvent(CARE_PROGRAM_EVENTS.STOP_TIMER, {
            syncCode: `unlogged-time-${params.careProgramDetails?.id}`,
          });
          setPendingBillParams(params);
          await new Promise(resolve => setTimeout(resolve, 1500));
          const billableTimeInMinutesResponse = await getPreviousRunningTime({
            variables: {
              where: {
                contactCareProgramId: {
                  _eq: params.careProgramDetails?.id
                }
              }
            },
          });
          let billableTimeInMinutes = 0;
          if (billableTimeInMinutesResponse?.data?.contactCareProgramTimerLoggings) {
            billableTimeInMinutes = Math.ceil(billableTimeInMinutesResponse?.data?.contactCareProgramTimerLoggings?.[0]?.timerSeconds / 60);
          }
          if (billableTimeInMinutes > 0) {
            setHookState((prev) => ({
              ...prev,
              totalMinutes: billableTimeInMinutes,
            }));
            setShowModal(true);
          } else if (params) {
            handleGenerateBill({contactCareProgramId: params.careProgramDetails?.id as string});
          }
          break;
      }
    } catch (error) {
      // handle error
      setIsLoading(undefined);
      toast({
        message: 'Something went wrong',
        toastType: ToastType.error,
      });
      return undefined;
    } finally {
      if (action !== StepAction.SEND_CLAIM && action !== StepAction.GENERATE_BILL) {
        setIsLoading(undefined);
      }
    }
  };

  const disabledExtraStepActions: string[] = useMemo(
    () =>
      getAdditionalAttributeFromStep(
        props?.additionalAttributes,
        AttributeKey.DISABLED_EXTRA_STEP_ACTIONS
      )?.attributeValue?.valueJson || [],
    [props?.additionalAttributes]
  );

  const dropdownActionList = useMemo(
    () =>
      getDropdownActionListBasedOnStepCode({
        selectedStepCode,
        disabledExtraStepActions,
      }),
    [selectedStepCode, disabledExtraStepActions]
  );

  return {
    handleContentAction,
    isLoading,
    dropdownActionList,
    isModalOpen: showModal,
    closeModal: () => {
      setShowModal(false);
      setIsLoading(undefined);
    },
    totalMinutes: hookState.totalMinutes
  };};


const getDropdownActionListBasedOnStepCode = (params: {
  selectedStepCode: string;
  disabledExtraStepActions: string[];
}) => {
  const {selectedStepCode, disabledExtraStepActions} = params;
  const actionList: {
    label: string;
    action: StepAction;
    icon?: JSX.Element;
  }[] = [];

  switch (selectedStepCode) {
    case CARE_PROGRAM_STEP_TYPE.ASSESSMENT:
    case CARE_PROGRAM_STEP_TYPE.USER_ASSESSMENT:
      actionList.push({
        label: 'Send to Patient',
        action: StepAction.SEND_TO_PATIENT,
      });
  }

  return actionList.filter((action) => !disabledExtraStepActions.includes(action.action));
};
