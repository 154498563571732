import * as React from "react";
import {Path, Svg} from "react-native-svg";

const BillingIcon = () => (
  <Svg
    width="20"
    height="22"
    fill="none"
    viewBox="0 0 20 22"
  >
    <Path
      fill="#6F7A90"
      d="m4.375 20.56.338-.37zm-.483-.442.337-.37zm12.216 0-.337-.37zm-.483.442-.338-.37zm1.767-.442-.338.369zm-14.784 0-.337-.37zM16.961 1.163l-.165.472zm1.881 1.936.475-.156zM3.039 1.163l.165.472zM1.158 3.099l.475.156zM8.5 9.5a.5.5 0 0 0 0 1zm6.5 1a.5.5 0 0 0 0-1zm-10-1a.5.5 0 0 0 0 1zm.5 1a.5.5 0 0 0 0-1zM5 6a.5.5 0 0 0 0 1zm.5 1a.5.5 0 0 0 0-1zM5 13a.5.5 0 0 0 0 1zm.5 1a.5.5 0 0 0 0-1zm3-8a.5.5 0 0 0 0 1zM15 7a.5.5 0 0 0 0-1zm-6.5 6a.5.5 0 0 0 0 1zm6.5 1a.5.5 0 0 0 0-1zM5.245 1.5h9.51v-1h-9.51zM18.5 5.37v14.004h1V5.37zm-17 14.004V5.37h-1v14.004zm3.213.817-.484-.442-.675.738.483.442zm11.058-.442-.484.442.675.738.484-.442zm1.958 0a1.446 1.446 0 0 0-1.958 0l.675.738a.446.446 0 0 1 .608 0zm-4.016.442a2.157 2.157 0 0 0-2.925 0l.675.738a1.16 1.16 0 0 1 1.575 0zm-4.5 0a2.157 2.157 0 0 0-2.926 0l.676.738a1.157 1.157 0 0 1 1.574 0zm-4.984-.442a1.446 1.446 0 0 0-1.958 0l.675.738a.446.446 0 0 1 .608 0zm-.192 1.18a2.157 2.157 0 0 0 2.926 0l-.676-.738a1.157 1.157 0 0 1-1.574 0zm9 0a2.157 2.157 0 0 0 2.925 0l-.675-.738a1.157 1.157 0 0 1-1.575 0zm-4.5 0a2.157 2.157 0 0 0 2.925 0l-.675-.738a1.157 1.157 0 0 1-1.574 0zM.5 19.374c0 1.263 1.48 1.996 2.446 1.113l-.675-.738c-.28.256-.771.079-.771-.375zm18 0c0 .454-.49.631-.77.375l-.676.738c.966.883 2.446.15 2.446-1.113zM14.755 1.5c1.2 0 1.674.007 2.041.135l.329-.945C16.558.493 15.872.5 14.755.5zM19.5 5.37c0-1.154.006-1.85-.183-2.427l-.95.312c.127.386.133.883.133 2.115zm-2.704-3.735a2.55 2.55 0 0 1 1.571 1.62l.95-.312A3.55 3.55 0 0 0 17.125.69zM5.245.5C4.128.5 3.442.493 2.875.69l.329.945c.367-.128.84-.135 2.041-.135zM1.5 5.37c0-1.232.006-1.729.133-2.115l-.95-.312C.493 3.52.5 4.216.5 5.37zM2.875.69A3.55 3.55 0 0 0 .683 2.943l.95.312a2.55 2.55 0 0 1 1.57-1.62zM8.5 10.5H15v-1H8.5zm-3.5 0h.5v-1H5zM5 7h.5V6H5zm0 7h.5v-1H5zm3.5-7H15V6H8.5zm0 7H15v-1H8.5z"
    ></Path>
  </Svg>
);

export default React.memo(BillingIcon);
