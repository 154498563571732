import React, {useMemo} from 'react';
import {ClinicalSectionFilterTabs} from '../../../../../../PatientOmniView/Sections/ClinicalSection/ClinicalSectionFilterTabs/ClinicalSectionFilterTabs';
import {
  DRILL_DOWN_FILTERS,
  FilterTypes,
  getDateFilterTitle,
} from '../utils';
import {HStack, Spacer, VStack, Text, View, Pressable, ScrollView} from 'native-base';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {Colors} from '../../../../../../../styles';
import {isWeb} from '../../../../../../../utils/platformCheckUtils';
import { IDateRange } from '../interface';
import { styles } from '../../../../Analytics/style';

const VitalDrillDownFilterView = (props: {
  onTabChange: (tab: any) => void;
  activeTab: string;
  onNextClick: () => void;
  onPrevClick: () => void;
  selectedDateRange?: IDateRange;
  isNextDisabled: boolean;
  isPrevDisabled: boolean;
  rightView?: JSX.Element
}) => {
  const {activeTab, isNextDisabled, isPrevDisabled, onTabChange, rightView} = props;
  const dateRangeString = useMemo(
    () =>
      getDateFilterTitle({
        currentView: activeTab as keyof typeof FilterTypes,
        startDate: props.selectedDateRange?.start,
        endDate: props.selectedDateRange?.end,
      }),
    [activeTab, props.selectedDateRange?.start, props.selectedDateRange?.end]
  );


  return (
    <View flex={isWeb()? 1 : undefined}>
      <View style={isWeb() ? {flexDirection:'row', justifyContent:'space-between'} : undefined}>
        <View alignSelf={isWeb() ? 'flex-start' : undefined}>
          <ClinicalSectionFilterTabs
            tabs={DRILL_DOWN_FILTERS}
            selectedTabCode={activeTab}
            onTabClick={onTabChange}
          />
        </View>
        {rightView ? rightView : null}
      </View>
      <HStack alignSelf={'stretch'} my={4}>
        <Pressable onPress={props.onPrevClick} disabled={isPrevDisabled}>
          <MaterialCommunityIcons
            name="menu-left"
            size={30}
            color={isPrevDisabled ? Colors.Custom.Gray200 : Colors.Custom.Gray700}
          />
        </Pressable>
        <Spacer />
        <Text
          size={'mdMedium'}
          style={styles.colorGray300}
        >
          {dateRangeString}
        </Text>
        <Spacer />
        <Pressable onPress={props.onNextClick} disabled={isNextDisabled}>
          <MaterialCommunityIcons
            name="menu-right"
            size={30}
            disabled={isNextDisabled}
            color={isNextDisabled ? Colors.Custom.Gray200 :  Colors.Custom.Gray700}
          />
        </Pressable>
      </HStack>
    </View>
  );
};

export default VitalDrillDownFilterView;
