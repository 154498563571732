import {
  HStack,
  IconButton,
  Pressable,
  Spinner,
  Text,
  Tooltip,
  useToast,
  View,
  VStack,
} from 'native-base';
import { Progress } from 'antd';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Colors} from '../../../styles';
import {DisplayText} from '../DisplayText/DisplayText';
import {useIntl} from 'react-intl';
import CareTeamIcon from '../Svg/CareTeamIcon';
import {AddActionView} from '../Buttons/AddActionView';
import Feather from 'react-native-vector-icons/Feather';
import CareJourneyDetailsIcon from '../Svg/CareJourneyDetailsIcon';
import {useLazyQuery, useQuery} from '@apollo/client';
import {CareJourneyQueries, UserQueries} from '../../../services';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../constants/Configs';
import {IPatientCareJourney} from '../../RightSideContainer/Journeys/JourneysOfCare/PatientCareJourney/PatientJourneyInterface';
import {
  getMlovCodeFromId,
  getMlovListFromCategory,
  getMlovValueFromCode,
  getMlovValueFromId,
} from '../../../utils/mlovUtils';
import {getCareJourneyColor, getCareJourneyProgress, getJourneyDaysText, getRecordStatus, getStatusBackground, getStatusFontColor, JOURNEYS_STATUS_CODE} from '../../RightSideContainer/Journeys/JourneysOfCare/PatientCareJourney/PatientCareJourneyHelper';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {DATE_FORMATS, MLOV_CATEGORY} from '../../../constants';
import {showToast, ToastType} from '../../../utils/commonViewUtils';
import {PATIENT_JOURNEY_ACTION_CODES} from '../../PersonOmniView/PersonHeaderBar/PersonAction/PersonActionPopover/ActionConst';
import CareTeamFlow from '../../PersonOmniView/LeftContainer/CareTeamDetails/CareTeamFlow';
import {getAccountUUID} from '../../../utils/commonUtils';
import PatientCareJourneyDetail from '../../RightSideContainer/Journeys/JourneysOfCare/PatientCareJourneyDetail/PatientCareJourneyDetail';
import { PATIENT_CARE_JOURNEY_REASON_CODE, PATIENT_CARE_JOURNEY_STATUS_CODE } from '../../../constants/MlovConst';
import { getDateStrFromFormat, getDiffInDays } from '../../../utils/DateUtils';
import { EventBus } from '../../../utils/EventBus';
import { CUSTOM_MESSAGE_EVENT_CODES, EVENT_MODULE_CODES } from '../../../constants/WebSocketConst';

const CareJourneyDetailsView = (props: any) => {
  const {contactData} = props;
  contactData['patientUuid'] = contactData?.patient?.patientUuid;
  const [journeyTableState, setJourneyTableState] = useState({
    loading: true,
    dataSource: [] as IPatientCareJourney[],
    showCareTeam: false,
    showJourneyTab: false,
    refetch: false,
    selectedJourney: undefined as IPatientCareJourney | undefined,
    offset: 0,
    showMoreLoading: false,
    totalJourneysCount: 0
  });
  const [accountUserList, setAccountUserList] = useState<any[]>([]);
  const LIMIT = 3;

  const intl = useIntl();
  const accountUuid = getAccountUUID();
  const commonContext = useContext(CommonDataContext);
  const toast = useToast();

  const patientJourneyStatusList =
    getMlovListFromCategory(
      commonContext.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.PATIENT_CARE_JOURNEY_STATUS
    ) || [];

  const formatCareJourneyResponse = (
    patientCareJourneys: IPatientCareJourney[]
  ) => {
    return (patientCareJourneys || []).filter((patientJourney) => {
      let journeyStatus = undefined;
      let journeyStatusCode = undefined;
      if (patientJourney?.statusId) {
        journeyStatus = getMlovValueFromId(
          patientJourneyStatusList,
          patientJourney?.statusId
        );
        journeyStatusCode = getMlovCodeFromId(
          patientJourneyStatusList,
          patientJourney?.statusId
        );
      } else {
        journeyStatus = getMlovValueFromCode(
          patientJourneyStatusList,
          JOURNEYS_STATUS_CODE.ACTIVE
        );
        journeyStatusCode = JOURNEYS_STATUS_CODE.ACTIVE;
      }
      patientJourney.status = journeyStatus;
      patientJourney.statusCode = journeyStatusCode;

      const journeyProgress = getCareJourneyProgress(patientJourney);
      const journeyProgressText = getJourneyProgressText(patientJourney);

      patientJourney.journeyProgressPercentage = journeyProgress;
      patientJourney.journeyProgressText = journeyProgressText;
      return patientJourney;
    });
  };

  const showErrorToast = () => {
    showToast(toast, intl.formatMessage({id: 'apiErrorMsg'}), ToastType.error);
  };

  const [getPatientJourneys] = useLazyQuery(
    CareJourneyQueries.GET_PATIENT_CARE_JOURNEYS_WITH_PAGINATION,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      onCompleted: (response) => {
        if (response &&
          response.patientCareJourneys &&
          response.patientCareJourneys.length > 0) {
          let patientJourneyList: IPatientCareJourney[] = [];
          let totalJourneysCount = 0;

          patientJourneyList = formatCareJourneyResponse(
            response.patientCareJourneys
          );

          totalJourneysCount = response.aggregatePatientCareJourneys?.aggregate?.count;

          setJourneyTableState((prev: any) => ({
            ...prev,
            dataSource: [...prev.dataSource, ...patientJourneyList],
            offset: [...prev.dataSource, ...patientJourneyList].length,
            loading: false,
            totalJourneysCount: totalJourneysCount,
            showMoreLoading: false,
          }));
        } else {
          setJourneyTableState((prev: any) => ({
            ...prev,
            loading: false,
            showMoreLoading: false,
          }));
        }
      },
      onError: (error: any) => {

        setJourneyTableState((prev: any) => ({...prev, loading: false, showMoreLoading: false}));
        showErrorToast();
      },
    }
  );

  const [getPatientJourneyById] = useLazyQuery(
    CareJourneyQueries.GET_PATIENT_CARE_JOURNEY_BY_ID_FOR_SUMMARY_VIEW,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      onCompleted: (response) => {
        if (response && response.patientCareJourney) {
          const formattedJourneys = formatCareJourneyResponse([
            response.patientCareJourney,
          ]);
          const updatedJourney = formattedJourneys[0];
          if (!updatedJourney) {
            return;
          }
          setJourneyTableState((prev: any) => {
            const udpatedJourneys = prev.dataSource.map(
              (journey: IPatientCareJourney) => {
                if (updatedJourney.id === journey.id) {
                  return updatedJourney;
                }
                return journey;
              }
            );
            return {
              ...prev,
              dataSource: udpatedJourneys,
            };
          });
        }
      },
      onError: (error: any) => {

        showErrorToast();
      },
    }
  );

  const getAccountUsers = useQuery(UserQueries.GET_USERS_FOR_CALENDAR, {
    fetchPolicy: 'no-cache',
    variables: {
      accountId: accountUuid,
    },
    onCompleted: (data: any) => {
      setAccountUserList(data.users);
    },
    onError: (error) => {

      setAccountUserList([]);
    },
  });

  const fetchPatientCareJourneys = useCallback(
    (offset?: number) => {
      getPatientJourneys({
        variables: {
          limit: LIMIT,
          offset: offset !== undefined ? offset : journeyTableState.offset,
          patientId: props.contactData.patient.patientUuid,
        },
      });
    },
    [journeyTableState.offset]
  );

  const journeyUpdateEventListener = useCallback(
    (updatedJourney: {id: string; journeyStartDateChanged: boolean; isDeleted: boolean}) => {
      if (updatedJourney?.isDeleted) {
        let shouldRefetch = false;

        setJourneyTableState((prev: any) => {
          const updatedJourneys = prev.dataSource.filter(
            (journey: IPatientCareJourney) => journey.id !== updatedJourney?.id
          );
          if (prev.offset - 1 === 0 && prev.totalJourneysCount - 1 !== 0) {
            shouldRefetch = true;
          }
          return {
            ...prev,
            offset: prev.offset - 1,
            totalJourneysCount: prev.totalJourneysCount - 1,
            dataSource: updatedJourneys,
          };
        });
        if (shouldRefetch) {
          setJourneyTableState((prev: any) => ({
            ...prev,
            offset: 0,
            dataSource: [],
          }));
          fetchPatientCareJourneys(0);
        }
      } else if (updatedJourney?.journeyStartDateChanged) {
        setJourneyTableState((prev: any) => ({
          ...prev,
          offset: 0,
          dataSource: [],
        }));
        fetchPatientCareJourneys(0);
      } else {
        getPatientJourneyById({
          variables: {
            id: updatedJourney?.id,
          },
        });
      }
    },
    [journeyTableState.offset]
  );

  useEffect(() => {
    const eventListener = journeyUpdateEventListener;
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(
      CUSTOM_MESSAGE_EVENT_CODES.JOURNEY_UPDATED,
      eventListener,
      {
        moduleCode: EVENT_MODULE_CODES.PATIENT_JOURNEY,
        disallowToAddMultipleEventsForModule: true,
      }
    );

    return () => {
      eventBus.removeEventListenerByEventName(
        CUSTOM_MESSAGE_EVENT_CODES.JOURNEY_UPDATED,
        eventListener,
        {
          moduleCode: EVENT_MODULE_CODES.PATIENT_JOURNEY,
          disallowToAddMultipleEventsForModule: true,
        }
      );
    };
  }, [ journeyUpdateEventListener ]);

  useEffect(() => {
    if (props?.contactData?.patient?.patientUuid) {
      setJourneyTableState((prev: any) => ({
        ...prev,
        loading: true,
        dataSource: []
      }));
      fetchPatientCareJourneys();
    } else {
      setJourneyTableState((prev: any) => ({
        ...prev,
        loading: false,
      }));
    }
  }, [props?.contactData?.uuid]);

  function dateDiff(first: any, second: any, isFloor?: boolean) {
    return getDiffInDays(first, second, isFloor);
  }

  const getJourneyProgressText = (record: IPatientCareJourney) => {
    const statusCode = record?.statusCode;
    const startDateTime = record?.startDateTime;
    const endDateTime = record?.endDateTime;

    if (statusCode !== PATIENT_CARE_JOURNEY_STATUS_CODE.ASSIGNED && (!startDateTime || !endDateTime)) {
      return '';
    }

    const startDateTimeString = startDateTime ? getDateStrFromFormat(record.startDateTime, DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT) : undefined;

    switch (statusCode) {
      case PATIENT_CARE_JOURNEY_STATUS_CODE.ASSIGNED: {
        if (startDateTime) {
          return `Starts on ${startDateTimeString}.`;
        } else {
          return `No start date assigned to this journey.`;
        }
      }
      default:
        return startDateTimeString ? `Started on ${startDateTimeString}` : record?.status || '';
    }
  }

  const getJourneyTooltipText = (record: IPatientCareJourney) => {
    const statusCode = record?.statusCode;
    const startDateTime = record?.startDateTime;
    const endDateTime = record?.endDateTime;

    if (statusCode !== PATIENT_CARE_JOURNEY_STATUS_CODE.ASSIGNED && (!startDateTime || !endDateTime)) {
      return '';
    }

    const pauseDateTimeString = record?.pauseDateTime ? getDateStrFromFormat(record.pauseDateTime, DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT) : undefined;
    const stopDateTimeString = record?.stopDateTime ? getDateStrFromFormat(record.stopDateTime, DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT) : undefined;
    const restartDateTimeString = record?.restartDateTime ? getDateStrFromFormat(record.restartDateTime, DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT) : undefined;
    const completedDateTimeString = (record?.completedDateTime || record?.endDateTime) ? getDateStrFromFormat(record.completedDateTime || record.endDateTime, DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT) : undefined;
    const endDateTimeString = record?.endDateTime ? getDateStrFromFormat(record.endDateTime, DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT) : undefined;

    switch (statusCode) {
      case PATIENT_CARE_JOURNEY_STATUS_CODE.ACTIVE:
        if (new Date(endDateTime) < new Date()) {
          return '';
        } else {
          let text = '';
          if (stopDateTimeString) {
            text += `Stops on: ${stopDateTimeString}`;
          } else if (record?.completedDateTime) {
            text += `Completes on: ${completedDateTimeString}`;
          }
          else if(endDateTimeString){
            text += `Completes on: ${endDateTimeString}`
          }
          return text;
        }

      case PATIENT_CARE_JOURNEY_STATUS_CODE.PAUSED:
        if (record.statusReasonCode === PATIENT_CARE_JOURNEY_REASON_CODE.PATIENT_OPTED_OUT && pauseDateTimeString) {
          return `Opted out on: ${pauseDateTimeString}`;
        }
        let text = '';
        if (pauseDateTimeString) {
          text += `Paused on: ${pauseDateTimeString}`;
        }
        if (restartDateTimeString && pauseDateTimeString) {
          text += `${pauseDateTimeString ? '\n' : ''}Restarts on: ${restartDateTimeString}`;
        }
        return text;
      case PATIENT_CARE_JOURNEY_STATUS_CODE.STOPPED:
        if(stopDateTimeString){
          return `Stopped on: ${stopDateTimeString}`;
        }
        return '';
      case PATIENT_CARE_JOURNEY_STATUS_CODE.COMPLETE:
        if(completedDateTimeString){
          return `Completed on: ${completedDateTimeString}`;
        }
        return '';
      default:
        return '';
    }
  }

  return (
    <View
      mb={1}
      backgroundColor={Colors.Custom.Gray50}
      style={{
        shadowColor: `rgba(16, 24, 40, 0.06)`,
        shadowRadius: 1,
        shadowOffset: {
          width: 1,
          height: 1,
        },
        shadowOpacity: 0.1,
      }}
    >
      <View p={6}>
        <View flexDirection={'row'} alignItems={'center'}>
          <View flexDirection={'row'} alignItems={'center'} ml={2}>
            <View style={{marginRight: 10}}>
              <CareJourneyDetailsIcon />
            </View>
            <DisplayText
              size={'lgMedium'}
              extraStyles={{color: Colors.FoldPixel.GRAY300 || ''}}
              textLocalId={'careJourneys'}
            />
          </View>
        </View>
      </View>
      <View>
        {journeyTableState?.dataSource?.length > 0 &&
          journeyTableState?.dataSource?.map(
            (selectedJourney, index: number) => (
              <View
                marginX={6}
                px={4}
                py={3}
                backgroundColor="white"
                borderRadius={8}
                style={{
                  // @ts-expect-error: Let's ignore a compile error like this unreachable code
                  boxShadow: `0px 1px 2px rgba(16, 24, 40, 0.06)`,
                }}
                key={selectedJourney.id}
                mb={2}
              >
                <VStack>
                  <HStack mb={2} justifyContent="space-between">
                    <View width="4/5">
                      <View
                        backgroundColor={getStatusBackground(
                          selectedJourney.statusCode
                        )}
                        style={{
                          paddingHorizontal: 4,
                          paddingVertical: 2,
                          borderRadius: 4,
                          width: 'fit-content',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <Text
                          color={getStatusFontColor(selectedJourney.statusCode)}
                          fontWeight={700}
                          size={'xsNormal'}
                          marginRight={1}
                          backgroundColor={getStatusBackground(
                            selectedJourney.statusCode
                          )}
                        >
                          {getRecordStatus(selectedJourney)}
                        </Text>
                        {getJourneyTooltipText(selectedJourney) && (
                          <Tooltip
                            label={getJourneyTooltipText(selectedJourney)}
                          >
                            <View>
                              <Feather
                                name="info"
                                style={{
                                  fontSize: 12,
                                  color: getStatusFontColor(
                                    selectedJourney.statusCode
                                  ),
                                  padding: 0,
                                }}
                              />
                            </View>
                          </Tooltip>
                        )}
                      </View>
                    </View>
                    <HStack space={2} alignItems="center">
                      <Tooltip label="Show Journey Flow">
                        <IconButton
                          size="sm"
                          onPress={() => {
                            setJourneyTableState((prev: any) => ({
                              ...prev,
                              selectedJourney,
                              showJourneyTab: true,
                            }));
                          }}
                          icon={
                            <Feather
                              name="trello"
                              style={{
                                fontSize: 18,
                                color: '#4F2D90',
                                padding: 0,
                              }}
                            />
                          }
                        />
                      </Tooltip>
                      <Tooltip label="Show Care Team">
                        <IconButton
                          size="sm"
                          onPress={() => {
                            setJourneyTableState((prev: any) => ({
                              ...prev,
                              selectedJourney,
                              showCareTeam: true,
                            }));
                          }}
                          icon={
                            <Feather
                              name="users"
                              style={{
                                fontSize: 18,
                                color: '#4F2D90',
                                padding: 0,
                              }}
                            />
                          }
                        />
                      </Tooltip>
                    </HStack>
                  </HStack>
                  <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400} lineHeight={24}>
                    {selectedJourney?.title ||
                      selectedJourney?.careJourney?.title}
                  </Text>
                  <VStack>
                    <HStack flex={1} alignItems={'center'}>
                      <View flex={0.6}>
                        <Progress
                          strokeWidth={6}
                          showInfo={false}
                          style={{
                            marginRight: 12,
                            color: Colors.primary['400'],
                            maxWidth: 240,
                          }}
                          strokeColor={getCareJourneyColor(
                            selectedJourney?.statusCode
                          )}
                          percent={selectedJourney.journeyProgressPercentage}
                        />
                      </View>
                      <View flex={0.4} alignItems={'flex-end'}>
                        <Text
                          color={Colors.FoldPixel.GRAY300}
                          lineHeight={20}
                          size={'smRegular'}
                        >
                          {getJourneyDaysText(selectedJourney)}
                        </Text>
                      </View>
                    </HStack>
                    <View>
                      <Text
                        color={Colors.FoldPixel.GRAY300}
                        lineHeight={20}
                        size={'smRegular'}
                      >
                        {selectedJourney.journeyProgressText}
                      </Text>
                    </View>
                  </VStack>
                </VStack>
              </View>
            )
          )}
        {!journeyTableState.showMoreLoading &&
          !journeyTableState.loading &&
          journeyTableState?.dataSource?.length <
          journeyTableState?.totalJourneysCount ? (
          <Pressable
            onPress={() => {
              setJourneyTableState((prev: any) => ({
                ...prev,
                showMoreLoading: true,
              }));
              fetchPatientCareJourneys();
            }}
            style={{
              paddingBottom: 20,
            }}
          >
            <Text
              size={'smSemibold'}
              style={{
                textAlign: 'center',
                color: Colors.primary[500],
                textDecorationLine: 'underline',
              }}
            >
              {`Show more`}
            </Text>
          </Pressable>
        ) : journeyTableState.showMoreLoading ? (
          <Spinner />
        ) : null}
        {journeyTableState?.showCareTeam && (
          <CareTeamFlow
            onClose={() => {
              setJourneyTableState((prev: any) => ({
                ...prev,
                showCareTeam: !prev.showCareTeam,
              }));
            }}
            personData={contactData}
            userList={accountUserList || []}
            careJourneyId={journeyTableState?.selectedJourney?.careJourneyId}
            drawerWidth={
              journeyTableState?.selectedJourney?.careJourneyId ? '50%' : '85%'
            }
            journeyTitle={
              journeyTableState?.selectedJourney?.title ||
              journeyTableState?.selectedJourney?.careJourney?.title
            }
          />
        )}
        <PatientCareJourneyDetail
          visible={journeyTableState?.showJourneyTab}
          patientCareJourney={journeyTableState?.selectedJourney}
          showJourneyTab={journeyTableState?.showJourneyTab}
          // showActivityTab={journeyTableState.showActivityTab}
          onClose={() => {
            setJourneyTableState((prev: any) => {
              return {
                ...prev,
                selectedJourney: undefined,
                showJourneyTab: false,
                showActivityTab: false,
              };
            });
          }}
        />
      </View>
    </View>
  );
};

export default CareJourneyDetailsView;
