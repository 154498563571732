import {useLazyQuery} from '@apollo/client';
import {Popover, Tooltip, notification} from 'antd';
import {HStack, useToast, View, Text} from 'native-base';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {getInActiveContactError} from '../../../../../constants';
import {CONFIG_CODES} from '../../../../../constants/AccountConfigConst';
import {COMMON_ACTION_CODES, CONVERSATION_ACTION_CODES} from '../../../../../constants/ActionConst';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {Colors} from '../../../../../styles/Colors';
import {getAccountId, getUserData, getUserUUID, isActiveContact, isConsentRequiredAndGiven, isContactConsentRequired} from '../../../../../utils/commonUtils';
import {isAccountConfigEnabled} from '../../../../../utils/configUtils';
import {EventBus} from '../../../../../utils/EventBus';
import OutboundCallSmsView from '../../../../common/OutboundCallSmsView/OutboundCallSmsView';
import {CHANNEL_TYPE, CHANNEL_TYPE_CODE} from '../ConversationConst';
import { IConversationData } from '../interfaces';
import { isBroadCastGroup, isInternalChat, isMentionTab } from '../MessagingWindow/MessagingUtils';
import {BROADCAST_MORE_OPTION_LIST, DISPLAY_CONVERSATION_MORE_OPTION_LIST, DISPLAY_CONVERSATION_MORE_OPTION_LIST_WITH_CONTAINER, DISPLAY_CONVERSATION_SIDEBAR_ICON, getFinalConversationList, INTERNAL_CONVERSATION_MORE_OPTION_LIST} from './ConversationSidebarUtils';
import {IConversationChannelList} from './Interfaces';
import {isCallsAllowed, isPatientDetailViewAllowed } from '../../../UserAccess/UserAccessPermission';
import EmployeeQueries from '../../../../../services/Employee/EmployeeQueries';
import ConversationActionView from '../ConversationActionView/ConversationActionView';
import { testID } from '../../../../../testUtils';
import AssignActionSvg from '../../../../common/Svg/AssignActionSvg';
import {getActionEventAndResourceCodeByOption} from '../../../Contacts/ContactsUtils';
import {usePermissions} from '../../../../CustomHooks/usePermissions';
import { Pressable, StyleSheet } from 'react-native';
import './CreateAllConversationPopup.css';

const CreateAllConversationPopup = (props: {
  openCount: number;
  selectedTabCode: string;
  selectedGroupCode?: string;
  isDetailsContainerVisible?: boolean,
  conversation?: IConversationData,
  onCreateGroupPopupActionPerformed: (
    actionCode: string,
    actionData?: any
  ) => void;
  displayTabCodes?: IConversationChannelList[];
  contactData?: any;
  isCompactView?: boolean;
  selectedInboxTypeCode?: string;
  getAssigneeName?: () => void;
  assignUserName?: string;
  isShowFinallyAssignee?: boolean
  isSingleContactPresentInSMSOrEmailInbox?: boolean
  isInstantChatView?: boolean;
  assignedUserData?: any
  accessToViewPatientProfile?: boolean
  onActionPerformed?: (code: string , data?: any) => void;
}) => {
  const createGroupToast = useToast();
  const intl = useIntl();
  const accountId = getAccountId();
  const userUuid = getUserUUID();
  const commonData = React.useContext(CommonDataContext);
  const isPatientSidecarContext = commonData.sidecarContext?.isPatient;
  const currentUserData = getUserData();
  const isConsentRequired = isContactConsentRequired();
  const consentCheck = isConsentRequiredAndGiven(props?.contactData);
  
  const {check} = usePermissions()
  let displayTabCodeList = isBroadCastGroup(props?.conversation || ({} as any)) ?
  BROADCAST_MORE_OPTION_LIST : (
    isInternalChat(props?.conversation || ({} as any))
    ? INTERNAL_CONVERSATION_MORE_OPTION_LIST
    : props?.isCompactView || isPatientSidecarContext ? DISPLAY_CONVERSATION_MORE_OPTION_LIST_WITH_CONTAINER : DISPLAY_CONVERSATION_MORE_OPTION_LIST
    ) as IConversationChannelList[];
    const [createGroupState, setCreateGroupState] = useState({
      selectedGroupCode: '',
      isLoading: false,
    });
  const [stateData, setStateData] = useState({
    visible: false,
    isConsentGiven: true,
    assignUserListPopup: false,
    assignUserName: '',
  })
  const {openCount, onCreateGroupPopupActionPerformed, contactData, accessToViewPatientProfile} = props;
  const contactActiveStatus = isActiveContact(contactData);
  const patientDetailsAllowed =  isPatientDetailViewAllowed();
  const isPatientMessagingEnabled = isAccountConfigEnabled(CONFIG_CODES.PATIENT_MESSAGING_ENABLED);
  const isCallAllowed = isCallsAllowed();
  if (!accessToViewPatientProfile) {
    const restrictedActionList = [
      CONVERSATION_ACTION_CODES.SCHEDULE_MESSAGE_LIST,
      CONVERSATION_ACTION_CODES.START_GROUP_CHAT,
      CONVERSATION_ACTION_CODES.START_VIDEO_MEET,
      CONVERSATION_ACTION_CODES.CREATE_APPOINTMENT
    ]
    displayTabCodeList = displayTabCodeList?.filter((item)=> {
      return !restrictedActionList?.includes(item.key)
    })
  }

  const [getEmployeeConsent] = useLazyQuery(
    EmployeeQueries.getEmployeeConsent,
    {
      fetchPolicy: 'no-cache',
    }
  );

  useEffect(() => {
    setCreateGroupState((prev) => {
      return {
        ...prev,
        selectedGroupCode: props?.selectedGroupCode || '',
      };
    });
    setStateData((prev) => {
      return {
        ...prev,
        assignUserName: props?.assignUserName || '',
      }
    })
  }, [openCount]);

  useEffect(()=> {
    if (props?.contactData?.uuid) {
      if (!props?.contactData?.hasOwnProperty('contactConsents')) {
        checkForConsent(props?.contactData);
      } else if (!consentCheck) {
        setStateData(prev => {
          return {
            ...prev,
            isConsentGiven: false,
          }
        })
      }
    }
  }, [props?.contactData]);

  const checkForConsent = async (contactData: any) => {
    if (!isConsentRequired) {
      setStateData((prev) => ({
        ...prev,
        isConsentGiven: true,
      }));
      return;
    }

    const checkPatientConsent = await getEmployeeConsent({
      variables: {
        contactUuid: contactData?.uuid,
      },
    });
    if (!checkPatientConsent?.data?.contactConsents.length) {
      setStateData((prev) => {
        return {
          ...prev,
          isConsentGiven: false,
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          isConsentGiven: true,
        };
      });
    }
  };

  const isScheduledSendEnabled = isAccountConfigEnabled(CONFIG_CODES.ENABLE_SCHEDULE_MESSAGE);
  if (!isScheduledSendEnabled) {
    displayTabCodeList = displayTabCodeList?.filter((item)=> {
      return item.key !== CONVERSATION_ACTION_CODES.SCHEDULE_MESSAGE_LIST
    })
  }
  if (!patientDetailsAllowed) {
    displayTabCodeList = displayTabCodeList?.filter((item)=> {
      return item.key !== CONVERSATION_ACTION_CODES.CHART;
    })
  }

  if (
    (props.conversation?.conversationInbox?.channelType !==
      CHANNEL_TYPE.CHANNEL_TWILIO_SMS &&
      props.conversation?.conversationInbox?.channelType !==
        CHANNEL_TYPE.CHANNEL_EMAIL) ||
    props?.isSingleContactPresentInSMSOrEmailInbox ||
    isMentionTab(props?.selectedInboxTypeCode || '')
  ) {
    displayTabCodeList = displayTabCodeList?.filter((item) => {
      return item.key !== CONVERSATION_ACTION_CODES.UPDATE_TO_CONTACT;
    });
  }

  const handleActions = (actionCode: string, rowData?: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.CLOSE_MODAL:
        setStateData((prev) => {
          return {
            ...prev,
            visible: false,
          };
        });
        break;
      case COMMON_ACTION_CODES.CALL:
        const eventBus = EventBus.getEventBusInstance();
        eventBus.broadcastEvent('CLOUD_CALL', {
          contactData: contactData ? {...contactData,fromNumber: contactData?.phoneNumber || ''} : {},
          fromNumber: rowData.fromNumber,
          toNumber: contactData?.phoneNumber,
        });
        setStateData((prev) => {
          return {
            ...prev,
            visible: false,
          };
        });
        break;
    }
  };
  const handleAssignVisibleChange = (visible: any) => {
    setStateData((prev) => {
      return {
        ...prev,
        assignUserListPopup: visible,
      };
    });
  };
  return (
    <>

      {getFinalConversationList(
        displayTabCodeList,
        props?.isDetailsContainerVisible,
        props?.conversation,
        isPatientMessagingEnabled,
        props?.selectedInboxTypeCode,
        props?.isShowFinallyAssignee,
        props?.assignUserName,
        props?.isInstantChatView,
        isPatientSidecarContext
      ).map((tabItem: IConversationChannelList, index) => {
        const eventAndResource = getActionEventAndResourceCodeByOption(tabItem.key);
        const isPermissionAllowed = check(eventAndResource?.actionEvent || '', eventAndResource?.resourceCode || '','') 
        if (eventAndResource?.resourceCode && !isPermissionAllowed) {
          return
        }
        if (tabItem.key === CHANNEL_TYPE_CODE.CALL_LOGS && !isCallAllowed) {
          return
        }
        return ![
          CHANNEL_TYPE_CODE.CALL_LOGS,
          CONVERSATION_ACTION_CODES.ASSIGN,
          CONVERSATION_ACTION_CODES.ASSIGNED,
        ].includes(tabItem.key) ? (
           <div className='pressable pressable-container'
             key={`CreateConversation_${index}`}
             onClick={() => {
               onCreateGroupPopupActionPerformed(tabItem.key, contactData);
             }}
             {...testID(tabItem.code || '')}
           >
             <HStack flex={1}>
               <View
                 justifyItems={'center'}
                 alignItems={'center'}
                 alignSelf={'center'}
                 justifySelf={'center'}
                 height={'16px'}
                 width={'16px'}
                 {...testID('image')}
               >
                 {DISPLAY_CONVERSATION_SIDEBAR_ICON(
                   tabItem?.key,
                   Colors.Custom.Gray500
                 )}
               </View>
               <Text
                 size={'smRegular'}
                 flex={0.9}
                 style={styles.tabItemText}
                 {...testID(tabItem.title || '')}
               >
                 {intl.formatMessage({id: tabItem.title})}
               </Text>
             </HStack>
           </div>
         ) : tabItem.key === CHANNEL_TYPE_CODE.CALL_LOGS ? (
           <OutboundCallSmsView
             isHideSms={true}
             showOnlyCallNumbers={true}
             visible={stateData.visible}
             selectedContactData={contactData}
             content={
               <Pressable
                 onPress={() => {
                  if (!isCallAllowed) {
                    return;
                  }
                   if (stateData.isConsentGiven) {
                     if (contactActiveStatus) {
                       if (contactData?.phoneNumber) {
                         setStateData((prev) => {
                           return {
                             ...prev,
                             visible: true,
                           };
                         });
                       } else {
                         onCreateGroupPopupActionPerformed(
                           COMMON_ACTION_CODES.EDIT,
                           contactData
                         );
                       }
                     } else {
                       const message = getInActiveContactError(contactData);
                       notification.error({
                         message,
                       });
                     }
                   } else {
                    const newContactData = {...contactData, showConsentError: true};
                    onCreateGroupPopupActionPerformed(
                      COMMON_ACTION_CODES.EDIT,
                      newContactData
                    );
                   }
                 }}
                 style={styles.callPopover}
                 {...testID('OutboundCallSmsViewClick')}
               >
                 <HStack flex={1}>
                   <View
                     justifyItems={'center'}
                     alignItems={'center'}
                     alignSelf={'center'}
                     justifySelf={'center'}
                     height={'20px'}
                     width={'20px'}
                     {...testID('image')}
                   >
                     {DISPLAY_CONVERSATION_SIDEBAR_ICON(
                       tabItem.key,
                       Colors.Custom.Gray500
                     )}
                   </View>
                   <Text
                     size={'smRegular'}
                     flex={0.9}
                     style={styles.tabItemText}
                     {...testID(tabItem.title || '')}
                   >
                     {intl.formatMessage({id: tabItem.title})}
                   </Text>
                 </HStack>
               </Pressable>
             }
             handleAction={(actionCode: string, rawData?: any) => {
               handleActions(actionCode, rawData);
             }}
           />
         ) : (
           <Popover
             key={`more-button-assigned-${props?.conversation?.id}`}
             overlayInnerStyle={reactStyles.popover}
             overlayStyle={reactStyles.padding0}
             overlay={reactStyles.padding0}
             style={reactStyles.popover}
             placement={'left'}
             trigger="click"
             visible={stateData.assignUserListPopup}
             onVisibleChange={handleAssignVisibleChange}
             content={
               <View width={300}>
                 <ConversationActionView
                   conversation={props.conversation}
                   selectedInboxTypeCode={props?.selectedInboxTypeCode}
                   style={styles.assignUserListPopup}
                   onSelectUser={(user: any) => {
                     if (props?.getAssigneeName) {
                       props?.getAssigneeName();
                     }
                     onCreateGroupPopupActionPerformed(tabItem.key);
                     setStateData((prev) => {
                       return {
                         ...prev,
                         assignUserListPopup: false,
                         assignUserName: user?.name || '',
                       };
                     });
                   }}
                   assignedUserData={props?.assignedUserData}
                   onActionPerformed={props?.onActionPerformed}
                 />
               </View>
             }
           >
             <Tooltip
               title={
                 props?.assignUserName?.length
                   ? props?.assignUserName
                   : 'Unassigned'
               }
               placement="right"
             >
               <div className='pressable pressable-container'
                 key={`CreateConversation_${index}`}
                 onClick={() => {
                   setStateData((prev) => {
                     return {
                       ...prev,
                       assignUserListPopup: !prev.assignUserListPopup,
                     };
                   });
                 }}
                 {...testID('AssignBtnClick')}
               >
                 <HStack flex={1}>
                   <View
                     justifyItems={'center'}
                     alignItems={'center'}
                     alignSelf={'center'}
                     justifySelf={'center'}
                     height={'16px'}
                     width={'16px'}
                     {...testID('image')}
                   >
                     <AssignActionSvg
                       customStrokeColor={
                         props?.assignUserName && props?.assignUserName.length
                           ? Colors.Custom.SuccessColor
                           : Colors.Custom.Gray500
                       }
                       width={21}
                       height={20}
                     />
                   </View>
                   <Text
                     flex={0.9}
                     style={[
                       styles.tabItemText,
                       {
                         color:
                           props?.assignUserName && props?.assignUserName.length
                             ? Colors.Custom.SuccessColor
                             : Colors.Custom.Gray500,
                       },
                     ]}
                     {...testID(tabItem.title)}
                   >
                     {intl.formatMessage({id: tabItem.title})}
                   </Text>
                 </HStack>
               </div>
             </Tooltip>
           </Popover>
         );
      })}
    </>
  );
};

const reactStyles: Record<string, React.CSSProperties> = {
  pressableContainer: {
    padding: 8,
    backgroundColor: 'white',
  },
  padding0: {
    padding: 0,
  },
  popover: {
    padding: 0,
    borderRadius: 12,
  }
}

const styles = StyleSheet.create({
  tabItemText: {
    color: Colors.FoldPixel.GRAY400,
    paddingStart: 8,
  },
  assignUserListPopup: {
    marginBottom: 10
  },
  callPopover: {
    padding: 8,
    backgroundColor: 'white',
  }
});

export default CreateAllConversationPopup;
