import { CaretDownOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Button, Divider, Drawer, Select, Skeleton, Space, Spin, Tag } from 'antd';
import { debounce } from 'lodash';
import { HStack, Text, useToast } from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../constants/Configs';
import { FormsQueries } from '../../../services';
import AddOrUpdateForm from '../../RightSideContainer/Forms/FormBuilderWidget/AddOrUpdateForm/AddOrUpdateForm';
import Feather from 'react-native-vector-icons/Feather';
import { Colors } from '../../../styles';
import { ANALYTICS_RESOURCE, PERSON_TYPES } from '../../../constants';
import { FORM_CATEGORY_TYPES } from '../../RightSideContainer/Forms/FormsConstants';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { isSendProviderRequestFormAllowed } from '../../RightSideContainer/Forms/FormsUtils';
import { ToastType, showPopupNotification, showToast } from '../../../utils/commonViewUtils';
import { useIntl } from 'react-intl';
import {ModuleCodes} from '../../CustomHooks/useModuleSpecificUserLocations';
import {getUserUUID, isMultiTenancyEnabled} from '../../../utils/commonUtils';
import {testID} from '../../../testUtils';
import {View} from 'react-native';
import {usePermissions} from '../../CustomHooks/usePermissions';
import {MAIN_MENU_CODES} from '../../SideMenuBar/SideBarConst';
import {USER_ACCESS_PERMISSION} from '../../RightSideContainer/UserAccess/UserAccessPermission';
// import './antMultiSelectCss.css';
export interface ISearchFieldProps {
  value?: any;
  isShowError: boolean;
  onChange: (value: any) => void;
  contactTypeCode?: string;
}

const formTagRender = (props: any, onSetIsVisible: any) => {
  const { label, value, closable, onClose, disableInlineFormEdit } = props;
  const commonData = useContext(CommonDataContext);
  const isSidecarContext = !!commonData?.sidecarContext?.isSidecar;
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <>
      <Tag
        color={'default'}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3, borderRadius:'0px', justifyContent:'center', alignItems:'center' }}

      >
        {label}
        {(!isSidecarContext && !disableInlineFormEdit) && (
          <Text
            margin={'5px'}
            fontSize="14px"
            color={'blue.400'}
            onPress={() => {
              onSetIsVisible(value);
            }}
          >
            {' Edit'}
          </Text>
        )}
      </Tag>
    </>
  );
};

const FormDrawer = (props: any) => {
  const { onClose, value, isEHRDisabled, currentUserAllowedLocations } = props;
  const [formDetails, setFormDetails] = useState<any>()
  const [loading,setLoading] = useState(false);
  const[ getFormById ] = useLazyQuery(
    FormsQueries.GET_FORM_BY_ID,
    {
      fetchPolicy: 'no-cache',
      variables: {
        id: value
      },
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      }
      ,
      onCompleted: (response) => {
        setFormDetails(response)
      }
    },

  );

  useEffect(() => {
    if (props.value) {
      setLoading(true);
      getFormById({
        variables: {
          id: props.value
        }
      }).finally(() => {
        setLoading(false);
      })
    }
  }, [props.value])


  if (props.value) {

    return (
      <>
        <Drawer
          open={!loading && formDetails}
          width={1200}
          onClose={() => {
            onClose();
          }}
          bodyStyle={{
            padding: 0,
            paddingLeft: 8,
          }}
          headerStyle={{
            display: 'none',
          }}
        >
          <AddOrUpdateForm
            nonRemovableLocations={new Set(props?.nonRemovableLocations)}
            allowedLocationList={currentUserAllowedLocations}
            isEHRDisabled={isEHRDisabled}
            isQuickForm={true}
            location={{
              state: {isUpdate: true, selectedForm: {...formDetails?.form}},
            }}
            onUpdate={props.onUpdate}
            onClose={() => {
              onClose();
            }}
          />
        </Drawer>
      </>
    );
  } else {
    return (
      <>
        <Drawer
          open={true}
          width={1200}
          onClose={() => {
            onClose()
          }}
          headerStyle={{
            display: 'none',
          }}
          bodyStyle={{
            padding: 0,
            paddingLeft: 8,
          }}
        >
          <AddOrUpdateForm
            nonRemovableLocations={new Set(props?.nonRemovableLocations)}
            allowedLocationList={currentUserAllowedLocations}
            isEHRDisabled={isEHRDisabled}
            isQuickForm={true}
            location={{ state: { isUpdate: false } }}
            onAdd={props.onAdd}
            onClose={() => { onClose() }}
          />
        </Drawer>

      </>
    );
  }
};

interface IMultipleFormSearch extends ISearchFieldProps {
  infoMessage?: string;
  isShowProviderRequestForm?: boolean;
  categoryIdsToSearchForms?: string[];
  formLocations?: string[];
  disabled?: boolean;
  disableInlineFormEdit?: boolean;
  useExactMatchForFormLocations?: boolean;
  disableAddNewForm?: boolean;
}
const MultipleFormSearch = (props: IMultipleFormSearch) => {
  const { value, isShowError, onChange, contactTypeCode, infoMessage, isShowProviderRequestForm, disableInlineFormEdit, useExactMatchForFormLocations, disableAddNewForm} = props;
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<any[]>(value ? value : []);
  const context = useContext(CommonDataContext);
  const userSettings = context.userSettings;
  const isEnabledMultiTenancy = isMultiTenancyEnabled(userSettings);
  const intl = useIntl();
  const userUUID = getUserUUID();
  const {check} = usePermissions();
  const permissionConfig = check(USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code, MAIN_MENU_CODES.FORMS);
  const currentUserAllowedLocations = permissionConfig?.allowedLocationIds || [];

  const isSidecarContext = context.sidecarContext?.isSidecar;

  useEffect(() => {
    if (value && value.id && formData.length === 0) {
      setFormData([
        {
          id: value.id,
          name: value.name,
          metadata: value.metadata,
          isHealthComponentIncluded: value.isHealthComponentIncluded,
        },
      ]);
    }
  }, []);

  const [searchForms] = useLazyQuery(FormsQueries.SEARCH_FORMS_WITH_LOCATION, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
    onCompleted: (response: any) => {
      if (response && response?.searchForms?.forms) {
        (value || []).forEach((form: any) => {
          const isPresent = response.searchForms.forms.some((responseForm: any) => {
            return responseForm.id == form.id;
          });
          if (!isPresent) {
            response.searchForms.forms.push(form);
          }
        });
        let forms = response.searchForms.forms;
        forms = forms?.filter((responseForm: any) => {
          const skipCategoryForm = responseForm?.formCategory?.code === FORM_CATEGORY_TYPES.NOTES || responseForm?.formCategory?.code === FORM_CATEGORY_TYPES.CARE_PLAN;
          if(isSendProviderRequestFormAllowed(userSettings) && !isShowProviderRequestForm){
            return (
              responseForm?.formCategory?.code !== FORM_CATEGORY_TYPES.PROVIDER_REQUEST_FORM && !skipCategoryForm
            );
          }
          return !skipCategoryForm;
        });
        if (
          contactTypeCode &&
          (contactTypeCode === PERSON_TYPES.LEAD ||
            contactTypeCode === PERSON_TYPES.CUSTOMER)
        ) {
          const formFilter = forms?.filter((responseForm: any) => {
            return (
              responseForm?.formCategory?.code !==
                ANALYTICS_RESOURCE.USER_SATISFACTION &&
              responseForm?.formCategory?.code !==
                ANALYTICS_RESOURCE.EMPLOYER_SATISFACTION
            );
          });
          if (formFilter?.length) {
            setFormData(formFilter);
          }
        } else {
          setFormData(forms);
        }
      } else {
        setFormData(value);
      }
      setLoading(false);
    },
    onError: (error) => {

      setLoading(false);
    },
  });

  const onSearch = (searchString: string) => {
    setFormData([]);
    if (searchString.length > 0) {
      setLoading(true);
      searchForms({
        variables: {
          params: {
            name: `%${searchString}%`,
            offset: 0,
            limit: 10,
            locationIds: isEnabledMultiTenancy? (props?.formLocations?.length? props?.formLocations : currentUserAllowedLocations) : [],
            categoryIds: props?.categoryIdsToSearchForms,
            useAbsoluteLocations: useExactMatchForFormLocations,
          }
        },
      });
    }
  };
  const [isVisible, setIsVisible] = useState(false);
  const [selectedFormId, setSelectedFormId] = useState<string>('')


  const canShowAddNewForm = () => {
    if (isSidecarContext) {
      return false;
    }
    return !disableAddNewForm;
  }

  return (
    <><Select
      {...testID("Search Form")}
      showSearch
      disabled={props?.disabled}
      mode="multiple"
      tagRender={(props) => {
        return formTagRender({...props, disableInlineFormEdit}, (value: any) => {
          setSelectedFormId(value);
          setIsVisible(true);
        });
      }}
      suffixIcon={<CaretDownOutlined width={10} height={10} />}
      allowClear
      filterOption={false}
      value={(value || []).map((data: any) => {
        return data?.id;
      })}
      dropdownRender={(menu) => (
        <>
          {menu}
          {canShowAddNewForm() && (
              <>
                <Divider style={{ margin: '8px 0' }} />
                <Space style={{padding: '0 8px 4px'}}>
                  <Button
                    type="text"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setSelectedFormId('');
                      setIsVisible(true);
                    }}
                  >
                    Add new content
                  </Button>
                </Space>
              </>
            )}
        </>
      )}
      onSearch={debounce(onSearch, 500)}
      onChange={(value: any[]) => {
        if (value) {
          onChange(
            formData
              .filter((formData) => {
                return value.some((id) => {
                  return id === formData.id;
                });
              })
              .map((form) => {
                return { id: form.id, name: form.name, isHealthComponentIncluded: form.isHealthComponentIncluded };
              })
          );
        } else {
          onChange(undefined);
        }
      }}
      placeholder="Search Form"
      loading={loading}
      notFoundContent={loading ? <Spin size="small" /> : <Text color={Colors.FoldPixel.GRAY400}>Type to search</Text>}
      className={
        isShowError && !value?.length
          ? 'ant-multi-select field-error'
          : 'ant-multi-select'
      }
      style={{ height: 'auto' }}

    >
      {formData.map((form) => {
        return (
          <Select.Option key={form.id} value={form.id} {...testID(form.name)}>
            {form.name}
          </Select.Option>
        );
      })}
    </Select>
    {!!infoMessage && (
      <HStack alignItems={'flex-start'} space={2} mt={1}>
        <Feather name="info" size={16} color={Colors.Custom.Gray500} style={{marginTop: 2}} />
        <Text fontSize={12} color={Colors.Custom.Gray500} width="full">
          {infoMessage}
        </Text>
      </HStack>
    )}
      {isVisible  && <FormDrawer
      nonRemovableLocations={props?.formLocations}
      currentUserAllowedLocations={currentUserAllowedLocations}
      isEHRDisabled={false}
      onAdd={(response: any) => {
        setIsVisible(false);
        const isNoteCategoryForm = response?.formCategory?.code === FORM_CATEGORY_TYPES.NOTES;
        if (isNoteCategoryForm) {
          showPopupNotification(
            intl.formatMessage({id: 'error'}),
            intl.formatMessage({id: 'sendNoteCategoryFormError'}),
            'error'
          );
          return;
        }
        if (
          isSendProviderRequestFormAllowed(userSettings) &&
          !isShowProviderRequestForm &&
          response?.formCategory?.code ===
            FORM_CATEGORY_TYPES.PROVIDER_REQUEST_FORM
        ) {
          showPopupNotification(
            intl.formatMessage({id: 'error'}),
            intl.formatMessage({id: 'sendProviderRequestFormError'}),
            'error'
          );
          return;
        }

        const newValue = value ? [...value, response] : [response];
        onChange(newValue);
        setFormData((prev:any)=>{
          return [...prev, response]
        });
      }} onUpdate={(response: any) => {
        const newValue = value ? [...value] : [];
        newValue.some((form)=>{
          if (response.id == form.id) {
            form.name = response.name;
            return true;
          }
        })
        onChange(newValue);
        setFormData((prev: any) => {
          const newData = [...prev];
          newData.some((form) => {
            if (response.id == form.id) {
              form.name = response.name;
              return true;
            }
          })
          return newData
        });
        setIsVisible(false)
      }} onClose={() => {
        setIsVisible(false)
      }} value={selectedFormId}></FormDrawer>}
    </>
  );
};

export default MultipleFormSearch;
