import { Dropdown, Menu, Select, Spin, Checkbox } from 'antd';
import { debounce } from 'lodash';
import { Button, VStack, Select as NativeSelect, Text, View, HStack, Input, Divider } from 'native-base';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ILocation } from '../../../../Interfaces';
import { Colors } from '../../../../styles';
import CustomDropdownButton from '../../Buttons/CustomDropdownButton';
import { ModalActionAntSelect } from '../../ModalActionCommonComponent/ModalActionAntSelect';
import '../UserAutoComplete/UserAutoCompleteStyles.css';
import { isWeb } from '../../../../utils/platformCheckUtils';
import { testID } from '../../../../testUtils';

export interface ILocationSearch {
  value: string;
  label: string;
  key: string;
}

export const autoCompleteTypeCodes = {
  DEFAULT: 'DEFAULT',
  MENU: 'MENU',
  INPUT_MENU: 'INPUT_MENU',
  INPUT_CHECKBOX_OPTIONS: 'INPUT_CHECKBOX_OPTIONS'
};

export const LocationAutoComplete = (props: {
  accountLocations: ILocation[];
  selectedValue?: ILocationSearch;
  selectedLocationName?: string;
  selectedLocationUUID?: string;
  selectedLocationUUIDList?: string[];
  isShowError?: boolean;
  autoCompleteType?: string;
  onChange: (participants: ILocationSearch | any) => void;
  labelText?: string;
  minWidth?: number;
  allowClear?: boolean;
  height?: number;
  isDisabled?: boolean;
  isRequired?: boolean;
  errors?: any;
  errorText?: string;
  isInvalid?: boolean;
  hideSelectAll?: boolean;
}) => {
  const intl = useIntl();
  const { errors, errorText, isInvalid } = props;
  const [value, setValue] = useState<ILocationSearch[]>(
    props.selectedValue ? [props.selectedValue] : []
  );
  const [selected, setSelected] = useState('');
  const [locationData, setLocationData] = useState<ILocationSearch[]>([]);
  const [originalData, setOriginalData] = useState<any[]>([]);

  const autoCompleteType =
    props.autoCompleteType || autoCompleteTypeCodes.DEFAULT;

  const locationDataPostProcessing = (locations: ILocation[]) => {
    const transformedLocations: ILocationSearch[] = [];
    if (locations && locations.length > 0) {
      locations.forEach(
        (location: ILocation) => {
          if (location?.id) {
            const temp = {
              label: location.name,
              value: location.uuid,
              key: location.uuid,
            };
            transformedLocations.push(temp)
          }
        }
      );
    }
    setLocationData(transformedLocations);
    setOriginalData(transformedLocations);
    if (
      (!value || !value.length) &&
      !props.selectedValue &&
      (props.selectedLocationName || props.selectedLocationUUID)
    ) {
      const location = transformedLocations.find(
        (item) =>
          item.label === props.selectedLocationName ||
          item.key === props.selectedLocationUUID
      );
      if (location) setValue([location]);
    }
    return transformedLocations;
  };

  useEffect(() => {
    if (props.accountLocations && props.accountLocations.length) {
      const location = (props.accountLocations || []).find(
        (item) =>
          item.name === props.selectedLocationName ||
          item.uuid === props.selectedLocationUUID
      );
      if (location) {
        setValue([
          { key: location.uuid, value: location.uuid, label: location.name },
        ]);
        setSelected(location.uuid);
      }
      locationDataPostProcessing(props.accountLocations);
    }
  }, [props.accountLocations, props.selectedLocationUUID, props.selectedLocationName]);

  const onSearch = (searchString: string) => {
    if (searchString.length > 0) {
      const filteredData = originalData.filter((item) => {
        return item.label.toLowerCase().includes(searchString.toLowerCase());
      });
      setLocationData([...filteredData]);
    } else {
      setLocationData([...originalData]);
    }
  };

  const getLocationDataFromId = (id: any): ILocationSearch => {
    const matchedData = locationData.filter((item) => item.key === id);
    return {
      label: matchedData[0].label,
      key: matchedData[0].key,
      value: matchedData[0].value,
    };
  };

  function MenuComponent() {
    return (
      <Menu
        onClick={(info) => {
          const location = locationData.find(
            (data) => data.key === info.key
          ) as ILocationSearch;
          setValue([location]);
          props.onChange(location);
        }}
        style={{
          overflow: 'scroll',
          maxHeight: 250,
        }}
        selectedKeys={value?.map((item) => item.key)}
        items={locationData?.map((item) => {
          return { label: item.label, key: item.key };
        })}
      />
    );
  }
  function MenuComponent2() {
    return (
      <VStack space={1}>
        {props.labelText && (
          <Text fontSize={14} color={'gray.700'} fontWeight={500} ml={0}>
            {props.labelText ? props.labelText : 'Member'} {props.isRequired && <Text color={'error.500'}>*</Text>}
          </Text>
        )}
        <NativeSelect
          fontSize={15}
          fontWeight={400}
          selectedValue={selected || locationData[0].key}
          backgroundColor={'white'}
          height={10}
          width={props.minWidth ? props.minWidth : 335}
          borderRadius={8}
          borderColor={Colors.Custom.Gray300}
          onValueChange={(value) => {
            setSelected(value);
            const location = locationData.find(
              (data) => data.key === value
            ) as ILocationSearch;
            setValue([location]);
            props.onChange(location);
          }}
        >
          {locationData.map((item, index) => {
            return (
              <NativeSelect.Item
                label={item.label}
                value={item.key}
                key={index}
              />
            );
          })}
        </NativeSelect>
      </VStack>
    );
  }

  if (!isWeb()) {
    return <></>
  }

  if (autoCompleteType === autoCompleteTypeCodes.INPUT_CHECKBOX_OPTIONS) {
    const rawEmptyData = props?.selectedLocationUUIDList ? [...props.selectedLocationUUIDList] : [];
    const rawUserData = locationData?.map((item) => item.value);

    return (
      <View style={{
        width: 300
      }}>
        {(locationData?.length && !props.hideSelectAll) ? <><Checkbox
          value={"select all"}
          checked={props?.selectedLocationUUIDList?.length == locationData?.length ? true : false}
          onChange={(event) => {
            if (event.target.checked) {
              props.onChange(rawUserData);
            } else {
              props.onChange([]);
            }
          }}
        >
          <HStack
            width={'100%'}
            alignItems="flex-start"
            paddingY={2}
            paddingX={4}
          >
            <VStack
              alignSelf="stretch"
              flex={1}
              justifyContent={'flex-start'}
            >
              <Text fontSize={16}>
                {"Select All"}
              </Text>
            </VStack>
          </HStack>
        </Checkbox><Divider marginX={-2} /></> : null}
        <View overflowY={'scroll'} style={{
          maxHeight: 400,
        }}>
          {locationData?.length ?
            locationData?.map((item: ILocationSearch, index: number) => {
              return (
                <>
                <Checkbox
                  key={item.key}
                  value={item.value}
                  checked={props?.selectedLocationUUIDList?.includes(item.value) ? true : false}
                  data-testid={item.label}
                  style={{ marginLeft: 0 }}
                  onChange={(event) => {
                    if (event.target.checked) {
                      rawEmptyData?.push(item.value);
                    } else {
                      const index = rawEmptyData?.findIndex(subitem => subitem == item.value);
                      if (index !== -1 && index != undefined) { rawEmptyData?.splice(index, 1) };
                    }
                    if (item && item.value) {
                      props.onChange(rawEmptyData || []);
                    }
                  }}
                >
                  <HStack
                    width={'100%'}
                    alignItems="flex-start"
                    paddingY={3}
                    paddingX={4}
                  >
                    <VStack
                      alignSelf="stretch"
                      flex={1}
                      justifyContent={'flex-start'}
                    >
                      <Text size={'dtNormal'} color={Colors.FoldPixel.GRAY400}>
                        {item?.label}
                      </Text>
                    </VStack>
                  </HStack>
                </Checkbox>
                {index !== locationData.length - 1 && <Divider marginX={-2} />}
                </>
              )
            }) :
            <Text style={{
              marginTop: 8
            }} size={'smMedium'}
              color={Colors.Custom.Gray700}>
              {intl.formatMessage({
                id: 'noDataFound',
              })}
            </Text>
          }
        </View>
      </View>
    )
  }
  return (
    <>
      {autoCompleteType === autoCompleteTypeCodes.DEFAULT && (
        <>
          <ModalActionAntSelect
            isRequired={props.isRequired || false}
            isDisabled={props.isDisabled}
            disabled={props.isDisabled}
            label={props.labelText}
            allowClear={props.allowClear !== undefined ? props.allowClear : true}
            showSearch={true}
            leftMargin={'0'}
            onSearch={debounce(onSearch, 500)}
            labelInValue={true}
            filterOption={false}
            value={value}
            notFoundContent={intl.formatMessage({
              id: 'NoData',
            })}
            loading={locationData.length < 0}
            placeholder={intl.formatMessage({ id: 'searchLocation' })}
            onChange={(value: any[], data: any) => {
              setValue(data);
              if (data && data.value) {
                props.onChange(getLocationDataFromId(data.key));
              }
            }}
            data={locationData}
            optionProps={{ key: 'key', value: 'value', label: 'label' }}
            extraStyle={{ flex: 1 }}
            errors={errors}
            errorText={errorText}
            isInvalid={isInvalid}
            customStyle={{
              flex: 1,
              fontSize: 15,
              fontWeight: 400,
              width: props.minWidth || 320,
              borderColor: Colors.Custom.Gray300,
            }}
          />
        </>
      )}
      {autoCompleteType === autoCompleteTypeCodes.MENU &&
        locationData.length > 0 && (
          <Dropdown overlay={MenuComponent} placement={'bottomRight'}>
            <Button h={8} variant="transparent">
              <CustomDropdownButton
                title={
                  value && value.length && value[0].label ? value[0].label : ''
                }
              />
            </Button>
          </Dropdown>
        )}
      {autoCompleteType === autoCompleteTypeCodes.INPUT_MENU &&
        locationData.length && <MenuComponent2 />}
    </>
  );
};

export default LocationAutoComplete;
