import {useLazyQuery, useQuery} from '@apollo/client';
import {Divider, HStack, Pressable, Skeleton, Spinner, Text, View, VStack} from 'native-base';
import React, {useContext, useState} from 'react';
import {FlatList, Platform} from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {GROUP_MEMBER_TYPE, MLOV_CATEGORY, RIGHT_SIDE_CONTAINER_CODE, getInActiveContactError} from '../../../constants';
import {COMMON_ACTION_CODES, PATIENT_QUICK_PROFILE_PARENT_CODES} from '../../../constants/ActionConst';
import {CommonDataContext} from '../../../context/CommonDataContext';
import ContactRelation from '../../../services/ContactRelation/ContactRelation';
import {IContactRelations} from '../../../services/ContactRelation/interface';
import {Colors} from '../../../styles/Colors';
import {getMlovListFromCategory} from '../../../utils/mlovUtils';
import {isWeb} from '../../../utils/platformCheckUtils';
import {IContact} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import RelationWithContactsList from '../RelationWithContacts/RelationWithContactsList';
import {NoSupportingMemberSvg} from '../Svg';
import {getEmergencyContactFromPatientFHIR, getPatientContactName, getRelationValue} from './ContactRelationUtils';
import {styles} from './ContactRelationViewStyles';
import DeleteRelativeView from './DeleteRelativeView';
import { getPrimaryGenderCode, isEmployerRole } from '../../../utils/commonUtils';
import { Patient, PatientContact } from 'fhir/r4';
import { PERSON_ACTION_CODES } from '../../PersonOmniView/PersonHeaderBar/PersonAction/PersonActionPopover/ActionConst';

import Stack from '../LayoutComponents/Stack';

import { ContactRelationCommunication } from './ContactRelationCommunication';
import { DisplayCardAvatar } from '../DisplayCard/DisplayCardAvatar';
import { MLOV_CODES } from '../../../constants/MlovConst';
interface RelativeContactListProps {
  contactData?: IContact;
  contactId?: string | number | undefined;
  onClickAction?: (contactId: any, contact: any) => void;
  contactRelations: IContactRelations[];
  patientDetails?: Patient;
  onActionPerformedOnContactRelative: (actionCode:string, actionData?: any) => void;
  isCompactView?: boolean;
}
const RelativeListView = (props: RelativeContactListProps) => {
  const {onActionPerformedOnContactRelative} = props;
  const {contactRelations, patientDetails} = props;
  const contactData = props.contactData;
  const contactId = props?.contactId || contactData?.id;
  const contactUuid = contactData?.uuid;
  const emergencyContacts = getEmergencyContactFromPatientFHIR(patientDetails);
  const accountLocationUuid = contactData?.contactPracticeLocations && contactData?.contactPracticeLocations?.length > 0 ? contactData.contactPracticeLocations[0]?.accountLocation?.uuid || '' : ''
  const [selectedEmergencyContactDetails, setSelectedEmergencyContactDetails] = useState<any>(undefined);
  const mlovData = useContext(CommonDataContext);
  const isEmployer = isEmployerRole();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [removeRelationObj, setRemoveRelationObj] = useState({} as any);
  const personRelation = getMlovListFromCategory(
    mlovData.MLOV,
    MLOV_CATEGORY.RELATIONS
  );  

  const onViewChangeActionPerformed = (actionCode: string): any => {
    setSelectedEmergencyContactDetails(undefined);
    switch (actionCode) {
      case COMMON_ACTION_CODES.SAVE:
        setShowDeleteModal(false);
        onActionPerformedOnContactRelative(COMMON_ACTION_CODES.DELETED);
        break;
    }
  };
  const onDeleteAction = (contactData: any) => {
    setShowDeleteModal(true);
    const removequery = {
      contactId: contactData.contactId,
      relativeContactId: contactData.relationContactId,
    };
    setRemoveRelationObj({
      ...removeRelationObj,
      removequery,
    });
  };


  const onActionPerformed = (
    action: string,
    isEmergencyContact: boolean,
    contactInfo: IContact | PatientContact
  ) => {
    switch (action) {
      case PERSON_ACTION_CODES.DELETE:
        if(isEmergencyContact){
          setSelectedEmergencyContactDetails(contactInfo);
          setShowDeleteModal(true);
        }
        else {
          onDeleteAction(contactInfo);
        }
        break;   
    }
  };    

  return (
    <>
      <View paddingTop={4} paddingBottom={2}>
        {(
          <>
            {emergencyContacts?.length > 0 && (
              emergencyContacts?.map(
                (emergencyContact, index: number) => {
                  return (
                    <HStack
                      key={`EmergencyContact_${emergencyContact?.id}_${index}`}
                      style={[styles.contactListItem]}
                      flex={1}
                      justifyContent={'center'}
                      alignItems={'center'}
                    >
                      <VStack flex={1} justifyContent={'center'}>
                        <HStack flex={1} alignItems={'center'}>
                          <VStack flex={0.6}>
                            <Stack direction='row'>
                              <DisplayCardAvatar
                                  avatarStyle={{
                                    avatarSize: '10',
                                    textStyle: {
                                      fontSize: 14
                                    }
                                  }}
                                  isLetterAvatarShow={true}
                                  userData={{
                                    userId: emergencyContact?.id || '',
                                    userType: GROUP_MEMBER_TYPE.CONTACT,
                                    userName: getPatientContactName(emergencyContact),
                                  }}
                              />
                     
                              <Stack direction='column' style={{marginLeft: 12}}>
                                <Text>                              
                                  {getPatientContactName(emergencyContact)}
                                </Text>
                                {emergencyContact.relationship && emergencyContact.relationship?.length > 0 && (
                                  <VStack
                                    flex={isWeb() ? 0.9 : 1}>
                                    <Text
                                     color="gray.500" size="xsLight"
                                    >
                                      {emergencyContact.relationship?.map((relationShip) =>
                                        relationShip.text ?? (relationShip.coding?.[0]?.display || ''
                                      )).join(', ')}
                                      <Text ml={1} color={Colors.Custom.ErrorColor}>{'(Emergency)'}</Text>
                                    </Text>
                                  </VStack>
                                )}
                              </Stack>                            
                            </Stack>                          
                          </VStack>
                          <VStack flex={0.4} alignItems={'flex-end'}>
                            <HStack
                              alignItems={'center'}
                              flex={1}
                              justifyContent={'space-between'}
                            >
                              <ContactRelationCommunication
                                emergencyContact={emergencyContact}
                                isEmergencyContact={true}
                                handleActions={(action)=> {
                                  onActionPerformed(action, true, emergencyContact)
                                }}
                                isCompactView={props?.isCompactView}
                              />                             
                            </HStack>
                          </VStack>
                        </HStack>
                      </VStack>
                    </HStack>
                  );
                }
              )
            )}
            {contactRelations?.length > 0 && (
              contactRelations?.map(
                (contactRelation: any, index: number) => {
                  const userContact = contactRelation.realatedContact;
                  const genderProfileCode = getPrimaryGenderCode(userContact);

                  return (  
                    <Stack direction='column' style={{marginVertical: 8}}>      
                      <HStack
                        style={[styles.contactListItem]}
                        flex={1}
                        justifyContent={'center'}
                        alignItems={'center'}                        
                      >
                        <Stack direction='row' style={{flex: 0.6}}>                         
                          <Pressable
                            key={`RelationList_${contactRelation.relationId}_${index}`}
                            onPress={() => {
                              if (!isEmployer && props.onClickAction) {
                                props.onClickAction(
                                  contactRelation.relationContactId,
                                  contactRelation.realatedContact
                                );
                              }
                            }}
                          >
                            <Stack direction='row' style={{alignItems:'center'}}>                           
                              <DisplayCardAvatar
                                avatarStyle={{
                                  avatarSize: '10',
                                  textStyle: {
                                    fontSize: 14
                                  }
                                }}
                                isLetterAvatarShow={true}
                                userData={{
                                  userId: userContact?.id || '',
                                  userType: GROUP_MEMBER_TYPE.CONTACT,
                                  genderCode: genderProfileCode as any,
                                  contactType: userContact?.contactType?.contactType?.code || '',
                                  name: userContact?.person,
                                  imgSrc:
                                    userContact?.contactProfilePhotos &&
                                    userContact?.contactProfilePhotos.length
                                        ? userContact?.contactProfilePhotos[0]?.profilePhotoUrl
                                        : undefined,
                                  userName: userContact?.name || '',
                                }}
                              />
                              <Stack direction='column' style={{marginLeft: 12}}>
                                  <Text size={'smRegular'} color={Colors.FoldPixel.GRAY400}>
                                    {contactRelation?.realatedContact?.name}
                                  </Text>
                                  <Text color={Colors.FoldPixel.GRAY300} size={'smRegular'}>
                                    {
                                      `${getRelationValue(
                                        contactRelation?.relationId,
                                        personRelation
                                      ).value} ${contactRelation?.isCaregiver ? '(Caregiver)' : ''}`
                                    }
                                  </Text>
                              </Stack>
                              </Stack>
                          </Pressable>
                        </Stack>
                        <VStack flex={0.4} alignItems={'flex-end'}>
                          <ContactRelationCommunication
                            contact={contactRelation.realatedContact}
                            handleActions={(action)=> {
                              onActionPerformed(action, false, contactRelation)
                            }}
                            isCompactView={props?.isCompactView}
                          />
                        </VStack>                                                       
                      </HStack>
                    </Stack>   
                  );
                }
              )
            )}
            {!contactRelations?.length && !emergencyContacts?.length && (
              <NoSupportingMemberSvg titleId={'noSupportingMembers'} />
            )}
            <View style={{paddingVertical: 10}}>
              <RelationWithContactsList
                contactId={contactId}
              />
              </View>
          </>
        )}
      </View>
      {Platform.OS === 'web' && showDeleteModal && (
        <DeleteRelativeView
          onFormActionPerformed={onViewChangeActionPerformed}
          removeRelationObj={removeRelationObj.removequery}
          isEmergencyContact={!!selectedEmergencyContactDetails}
          additionalDetails={{
            contactUuid: contactUuid,
            patientId: patientDetails?.id,
            accountLocationUuid: accountLocationUuid,
          }}
        />
      )}
    </>
  );
};

export default RelativeListView;
