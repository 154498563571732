// import {Collapse} from 'antd';
// import {Content} from 'antd/lib/layout/layout';
import React from 'react';
import { HStack, Text, View, VStack, Image } from 'native-base';
import { Dimensions, TouchableOpacity, StyleSheet } from 'react-native';
import { DATE_FORMATS } from '../../../../../../../../constants';
import { Colors } from '../../../../../../../../styles/Colors';
import { getDateStrFromFormat } from '../../../../../../../../utils/DateUtils';
import { FEEDABLE_RESOURCES, FEED_RESOURCE_TYPE, SOURCE_TYPES, TIME_LINE_SOURCE_TYPES } from '../FeedConst';
import { IFeedData } from '../interface';
import { isWeb } from '../../../../../../../../utils/platformCheckUtils';
import { testID } from '../../../../../../../../testUtils';
import { COMPONENT_TEST_IDENTIFIER } from '../../../../../../../../testUtils/ComponentTestIdentifiers';
import { DocStatus } from '../../../../../../../PersonOmniView/MiddleContainer/PatientNotes/interfaces';
import AllergyIconSvg from '../../../../../../../common/Svg/TimelineSvg/AllergyIconSvg';
import ConditionIconSvg from '../../../../../../../common/Svg/TimelineSvg/ConditionIconSvg';
import DiagnosticReportIconSvg from '../../../../../../../common/Svg/TimelineSvg/DiagnosticReportIconSvg';
import DocumentReferenceIconSvg from '../../../../../../../common/Svg/TimelineSvg/DocumentReferenceIconSvg';
import ImmunizationIconSvg from '../../../../../../../common/Svg/TimelineSvg/ImmunizationIconSvg';
import MedicationRequestIconSvg from '../../../../../../../common/Svg/TimelineSvg/MedicationRequestIconSvg';
import MedicationStatementIconSvg from '../../../../../../../common/Svg/TimelineSvg/MedicationStatementIconSvg';
import ObservationIconSvg from '../../../../../../../common/Svg/TimelineSvg/ObservationIconSvg';
import ProcedureIconSvg from '../../../../../../../common/Svg/TimelineSvg/ProcedureIconSvg';
import RequestGroupIconSvg from '../../../../../../../common/Svg/TimelineSvg/RequestGroupIconSvg';
import ServiceRequestIconSvg from '../../../../../../../common/Svg/TimelineSvg/ServiceRequestIconSvg';
import AppointmentIconSvg from '../../../../../../../common/Svg/TimelineSvg/AppointmentIconSvg';
import NoteDocumentIconSvg from '../../../../../../../common/Svg/TimelineSvg/NoteDocumentIconSvg';
import { isImagingReport } from '../../../../../../../PersonOmniView/MiddleContainer/TimelineView/TimelineViewHelper';
import ImagingReportIconSvg from '../../../../../../../common/Svg/TimelineSvg/ImagingReportIconSvg';
import { APPOINTMENT_STATUS_CODES } from '../../../../../../../../constants/MlovConst';
import { FHIR_RESOURCE } from '../../../../../../../../constants/FhirConstant';
import { IUserSettingsByCode } from '../../../../../../../../services/UserSettings/interfaces';
import { swapTimelineSourceFoldWithHie } from '../../../../../../../../utils/commonUtils';
import LabTestTubeSvg from '../../../../../../../common/Svg/LabTestTubeSvg';
import DollorIcon from '../../../../../../../common/Svg/DollorIocn';

const { height, width } = Dimensions.get('window');
const getIconName = (singleFeedData: IFeedData) => {
  const resourceType = singleFeedData?.resourceType;
  if (resourceType === 'MESSAGE') {
    return (
      <Image
        style={styles.iconImage}
        source={require('../../../../../../../../assets/images/CRMPipeline/Messages_CRM_Activity.png')}
        size={'32px'}
        alt="image"
      ></Image>
    );
  }
  if (resourceType === 'MEMBERSHIP') {
    return (
      <Image
        style={styles.iconImage}
        source={require('../../../../../../../../assets/images/CRMPipeline/membership.png')}
        size={'32px'}
        alt="image"
      ></Image>
    );
  }
  if (resourceType === 'APPOINTMENT') {
    return (
      <Image
        style={styles.iconImage}
        source={require('../../../../../../../../assets/images/CRMPipeline/AppointmentAction.png')}
        size={'32px'}
        alt="image"
      ></Image>
    );
  }
  if (resourceType === 'AUTOMATION') {
    return (
      <Image
        style={styles.iconImage}
        source={require('../../../../../../../../assets/images/CRMPipeline/automation.png')}
        size={'32px'}
        alt="image"
      ></Image>
    );
  }
  if (resourceType === 'CALL') {
    return (
      <Image
        style={styles.iconImage}
        source={require('../../../../../../../../assets/images/CRMPipeline/call.png')}
        size={'32px'}
        alt="image"
      ></Image>
    );
  }
  if (resourceType === 'FORM_RESPONSE') {
    return (
      <Image
        style={styles.iconImage}
        source={require('../../../../../../../../assets/images/CRMPipeline/Form_Response.png')}
        size={'32px'}
        alt="image"
      ></Image>
    );
  }
  if (resourceType === 'TASK') {
    return (
      <Image
        style={styles.iconImage}
        source={require('../../../../../../../../assets/images/CRMPipeline/Task.png')}
        size={'32px'}
        alt="image"
      ></Image>
    );
  }
  if (resourceType === 'VIDEO_CALL') {
    return (
      <Image
        style={styles.iconImage}
        source={require('../../../../../../../../assets/images/MemberActions/video_call.png')}
        size={'32px'}
        alt="image"
      ></Image>
    );
  }
  return (
    <Image
      style={styles.iconImage}
      source={require('../../../../../../../../assets/images/ProspectPatientActions/addNote.png')}
      size={'32px'}
      alt="image"
    ></Image>
  );

};

// fetch description from code.coding.display
export const getTimeLineItemDescription = (data: any, keyToTakeTitleFrom: string) => {
  const resourceData = data?.resourceData || {};
  let description = data[keyToTakeTitleFrom] ||  resourceData[keyToTakeTitleFrom] || '';
  if (resourceData.resourceType === FHIR_RESOURCE.DIAGNOSTIC_REPORT && resourceData?.source == SOURCE_TYPES.HIE) {
    const coding = resourceData?.code?.coding || [];
    coding.some((itemCode: any) => {
      if (!itemCode?.code || itemCode?.code !== 'UNK' && itemCode?.display) {
        description = itemCode?.display;
      }
    });
  }
  if(resourceData?.resourceType === FHIR_RESOURCE.CLAIM) {
      const identifier = resourceData?.identifier || [];
      identifier.some((identifier: any) => {
        if(identifier.system && identifier.system === 'https://foldhealth.io/structure/claim-number') {
          description = identifier?.value;
        }
      });
  }
  if (resourceData?.resourceType && resourceData?.resourceType === FHIR_RESOURCE.DOCUMENT_REFERENCE && resourceData?.source == SOURCE_TYPES.HIE) {
    const resourceDataContent = resourceData?.content || [];
    for (const content of resourceDataContent) {
      const contentType = content?.attachment?.contentType;
      if ((allowContentTypeToViewHieSourceReportButton(contentType)) && (content?.attachment?.data?.length || content?.attachment?.url?.length)) {
        description = description || 'Note';
      }
    }
  }
  return description;
}

export const getPanelHeaderText = (singleFeedData: IFeedData, keyToTakeTitleFrom = 'search_tags') => {
  let panelHeaderText = getTimeLineItemDescription(singleFeedData, keyToTakeTitleFrom);
  if (!panelHeaderText && (singleFeedData?.schema_type?.trim() === 'CRM' || singleFeedData?.schema_type?.trim() === 'FHIR-R4')) {
    panelHeaderText = `${singleFeedData[keyToTakeTitleFrom as keyof IFeedData]}`;
    if ((!panelHeaderText || panelHeaderText === "null") && singleFeedData?.customTitle) {
      panelHeaderText = singleFeedData?.customTitle;
    }
  }
  return panelHeaderText?.trim();
};

export const getConversationMessageElem = (singleFeedData: IFeedData, firstEntryID: string, onClick: (actionCode: string, singleFeedData: IFeedData) => void) => {
  const dateStr = singleFeedData?.time_stamp
    ? getDateStrFromFormat(
      singleFeedData?.time_stamp,
      DATE_FORMATS.CRM_ACTIVITY_DATE_FORMAT
    )
    : '';
  return (
    <View key={singleFeedData?.id}>
      <HStack style={styles.conversationContainer} flex={1} alignItems={'center'}>
        <View marginLeft={5} marginRight={3}>
          <View style={styles.verticalLine} />
          <View
            {...testID(COMPONENT_TEST_IDENTIFIER.feedDataCollapseIcon)}
            style={styles.iconContainer}
            backgroundColor={isWeb() ? 'none' : '#FFFFFF'}
          >
            {getIconName(singleFeedData)}
          </View>
          {singleFeedData?.id !== firstEntryID ? (
            <View style={styles.verticalLine} />
          ) : (
            <View style={styles.emptyVerticalLine} />
          )}
        </View>
        <VStack
          flex={1}
          style={styles.contentContainer}
          borderRadius={10}
          backgroundColor={isWeb() ? 'none' : '#FFFFFF'}
          marginRight={isWeb() ? 0 : 4}
          py={2}
          paddingLeft={2}
        >
          <HStack display={'flex'} flexWrap={'wrap'}>
            <HStack
              alignItems={'flex-start'}
              width={{
                base: '100%',
                sm: '100%',
                md: '57.5%',
                lg: '57.5%',
                xl: '57.5%',
              }}
            >
              <Text
                {...testID(COMPONENT_TEST_IDENTIFIER.feedDataCollapsePanelHeader)}
                style={styles.headerText}
                fontSize={isWeb() ? 14 : 16}
                lineHeight={isWeb() ? 21 : 24}
                paddingRight={2}
                numberOfLines={2}
                textOverflow={'ellipsis'}
              >
                {getPanelHeaderText(singleFeedData)}
              </Text>
            </HStack>
            <HStack alignContent={'flex-end'}>
              <Text
                {...testID(COMPONENT_TEST_IDENTIFIER.feedDataCollapseDate)}
                color="gray.400"
                style={styles.dateText}
                fontSize={12}
              >
                {dateStr}
              </Text>
            </HStack>
          </HStack>
          <HStack {...testID(COMPONENT_TEST_IDENTIFIER.viewBtn)} style={styles.viewButtonContainer}>
            <Text
              width={8}
              height={25}
              fontSize={14}
              lineHeight={21}
              fontWeight={700}
              fontFamily={'Manrope'}
              color={Colors.Custom.PurpleColor}
              onPress={() => {
                onClick(singleFeedData.resourceType || '', singleFeedData);
              }}
            >
              View
            </Text>
            <TouchableOpacity
              onPress={() => {
                onClick(singleFeedData.resourceType || '', singleFeedData);
              }}
            >
              <Image
                style={styles.arrowIcon}
                source={require('../../../../../../../../assets/images/PNGImages/TabsPngIcons/arrowRight_purple.png')}
                size={'32px'}
                alt="image"
              ></Image>
            </TouchableOpacity>
          </HStack>
        </VStack>
      </HStack>
    </View>
  );
}

export const getStatusFontColor = (status: string) => {
  switch (status) {
    case DocStatus.PRELIMINARY:
      return Colors.Custom.AppointmentStatusRed
    case DocStatus.FINAL:
      return Colors.Custom.SuccessColor
    case DocStatus.AMENDED:
      return Colors.Custom.PendingTaskFontColor
  }
}

export const getAppointmentStatusFontColor = (status: string) => {
  switch (status) {
    case APPOINTMENT_STATUS_CODES.PROPOSED:
    case APPOINTMENT_STATUS_CODES.PENDING:
    case APPOINTMENT_STATUS_CODES.WAIT_LIST:
    case APPOINTMENT_STATUS_CODES.NEEDS_ACTION:
      return Colors.Custom.PendingTaskFontColor;
    case APPOINTMENT_STATUS_CODES.ARRIVED:
    case APPOINTMENT_STATUS_CODES.FULFILLED:
    case APPOINTMENT_STATUS_CODES.CHECKED_IN:
    case APPOINTMENT_STATUS_CODES.BOOKED:
    case APPOINTMENT_STATUS_CODES.SCHEDULED:
    case APPOINTMENT_STATUS_CODES.CHECKED_OUT:
    case APPOINTMENT_STATUS_CODES.RESCHEDULED:
    case APPOINTMENT_STATUS_CODES.ACCEPTED:
      return Colors.Custom.SuccessColor;
    case APPOINTMENT_STATUS_CODES.CANCELLED:
    case APPOINTMENT_STATUS_CODES.NO_SHOW:
    case APPOINTMENT_STATUS_CODES.ENTERED_IN_ERROR:
    case APPOINTMENT_STATUS_CODES.DECLINED:
      return Colors.Custom.AppointmentStatusRed;
    default:
      return Colors.Custom.Gray300;
  }
}


export const getResourceTypeIcon = (resourceType: string, resourceData: any) => {
  switch (resourceType) {
    case FEEDABLE_RESOURCES.ALLERGY_INTOLERANCE:
      return <AllergyIconSvg />
    case FEEDABLE_RESOURCES.CONDITION:
      return <ConditionIconSvg />
    case FEEDABLE_RESOURCES.DIAGNOSTIC_REPORT:
      const isImaging = isImagingReport(resourceData);
      if(isImaging){
        return <ImagingReportIconSvg />
      }
      return <LabTestTubeSvg customStrokeColor={Colors.Custom.GRAY350} height={20} width={20} />
    case FEEDABLE_RESOURCES.DOCUMENT_REFERENCE:
      return <NoteDocumentIconSvg />
    case FEEDABLE_RESOURCES.IMMUNIZATION:
      return <ImmunizationIconSvg />
    case FEEDABLE_RESOURCES.MEDICATION_REQUEST:
      return <MedicationRequestIconSvg />
    case FEEDABLE_RESOURCES.MEDICATION_STATEMENT:
      return <MedicationStatementIconSvg />
    case FEEDABLE_RESOURCES.OBSERVATION:
      return <ObservationIconSvg />
    case FEEDABLE_RESOURCES.PROCEDURE:
      return <ProcedureIconSvg />
    case FEEDABLE_RESOURCES.REQUEST_GROUP:
      return <RequestGroupIconSvg />
    case FEEDABLE_RESOURCES.SERVICE_REQUEST:
      return <ServiceRequestIconSvg />
    case FEEDABLE_RESOURCES.APPOINTMENT:
      return <AppointmentIconSvg />
    case FEEDABLE_RESOURCES.CLAIM:
      return <DollorIcon/>
  }
}

export const getTagColor = (tag: string) => {
  switch (tag.trim()) {
    case SOURCE_TYPES.EHR:
    case SOURCE_TYPES.FOLD:
      return Colors.FoldPixel.GRAY50
    case SOURCE_TYPES.HIE:
      return Colors.Custom.PrimaryColor100
  }
}

export const getTagTextColor = (tag: string) => {
  switch (tag.trim()) {
    case SOURCE_TYPES.EHR:
    case SOURCE_TYPES.FOLD:
      return Colors.FoldPixel.GRAY300;
    case SOURCE_TYPES.HIE:
      return Colors.Custom.Primary300;
  }
}

export const getTagText = (tag: string, userSettings?: IUserSettingsByCode) => {
  switch (tag.trim()) {
    case SOURCE_TYPES.EHR:
      return TIME_LINE_SOURCE_TYPES.EHR
    case SOURCE_TYPES.FOLD:
      if (userSettings && swapTimelineSourceFoldWithHie(userSettings)) {
        return TIME_LINE_SOURCE_TYPES.HIE;
      }
      return TIME_LINE_SOURCE_TYPES.FOLD
    case SOURCE_TYPES.HIE:
      return TIME_LINE_SOURCE_TYPES.HIE
    case SOURCE_TYPES.CLAIM:
      return TIME_LINE_SOURCE_TYPES.CLAIM
    default:
      return TIME_LINE_SOURCE_TYPES.EHR
  }
}

export const getFeedCommonHeaderElem = (singleFeedData: IFeedData, firstEntryID: string, isViewButtonVisible: boolean, onClick: (actionCode: string, singleFeedData: IFeedData) => void) => {
  const dateStr = singleFeedData?.time_stamp
    ? getDateStrFromFormat(
      singleFeedData?.time_stamp,
      DATE_FORMATS.CRM_ACTIVITY_DATE_FORMAT
    )
    : '';
  return (
    <HStack flex={1} alignItems={'center'} minH={102} paddingLeft={3}>
      <VStack marginX={2} justifyContent={'center'}>
        <View style={styles.verticalLine2} />
        <View
          {...testID(COMPONENT_TEST_IDENTIFIER.feedDataCollapseIcon)}
          style={styles.iconContainer2}
          backgroundColor={isWeb() ? 'none' : '#FFFFFF'}
        >
          {getIconName(singleFeedData)}
        </View>
        {singleFeedData?.id !== firstEntryID ? (
          <View style={styles.verticalLine2} />
        ) : (
          <View style={styles.emptyVerticalLine2} />
        )}
      </VStack>
      <VStack
        flex={1}
        style={styles.contentContainer2}
        borderRadius={10}
        backgroundColor={isWeb() ? 'none' : '#FFFFFF'}
        marginRight={isWeb() ? 0 : 4}
      >
        <HStack display={'flex'} flexWrap={'wrap'}>
          <HStack
            alignItems={'flex-start'}
            width={{
              base: '100%',
              sm: '100%',
              md: '57.5%',
              lg: '57.5%',
              xl: '57.5%',
            }}
          >
            <Text
              {...testID(COMPONENT_TEST_IDENTIFIER.feedDataCollapsePanelHeader)}
              size={'smRegular'}
              style={styles.headerText}
              fontSize={isWeb() ? 14 : 16}
              lineHeight={isWeb() ? 21 : 24}
              paddingRight={2}
              fontWeight={500}
              numberOfLines={2}
              textOverflow={'ellipsis'}
            >
              {getPanelHeaderText(singleFeedData)}
            </Text>
          </HStack>
          <HStack alignContent={'flex-start'}>
            <Text
              size={'smRegular'}
              {...testID(COMPONENT_TEST_IDENTIFIER.feedDataCollapseDate)}
              color={Colors.FoldPixel.GRAY300}
              style={styles.dateText2}
            >
              {dateStr}
            </Text>
          </HStack>
        </HStack>
        <HStack style={styles.viewButtonContainer}>
          {isViewButtonVisible && singleFeedData.resourceType !== FEED_RESOURCE_TYPE.TASK && isWeb() && (
            <HStack {...testID(COMPONENT_TEST_IDENTIFIER.viewBtn)}>
              <Text
                width={8}
                height={25}
                fontSize={14}
                lineHeight={21}
                fontWeight={700}
                fontFamily={'Manrope'}
                color={Colors.Custom.PurpleColor}
                onPress={() => {
                  onClick(singleFeedData.resourceType || '', singleFeedData);
                }}
              >
                View
              </Text>
              <TouchableOpacity
                onPress={() => {
                  onClick(singleFeedData.resourceType || '', singleFeedData);
                }}
              >
                <Image
                  style={styles.arrowIcon}
                  source={require('../../../../../../../../assets/images/PNGImages/TabsPngIcons/arrowRight_purple.png')}
                  size={'32px'}
                  alt="image"
                ></Image>
              </TouchableOpacity>
            </HStack>
          )}
        </HStack>
      </VStack>
    </HStack>
  );
};

export const isAllowToViewReportButtonForHIE = (resourceData: any) => {
  let allowToViewReportButton = false;
  if (resourceData?.resourceType && resourceData?.resourceType === FHIR_RESOURCE.DOCUMENT_REFERENCE && resourceData?.source == SOURCE_TYPES.HIE) {
    const resourceDataContent = resourceData?.content || [];
    for (const content of resourceDataContent) {
      const contentType = content?.attachment?.contentType;
      if ((allowContentTypeToViewHieSourceReportButton(contentType)) && (content?.attachment?.data?.length || content?.attachment?.url?.length)) {
        allowToViewReportButton = true
      }
    }
  }
  if (resourceData.resourceType === FHIR_RESOURCE.DIAGNOSTIC_REPORT && resourceData?.source == SOURCE_TYPES.HIE && (resourceData?.presentedForm || [])?.length) {
    allowToViewReportButton = true;
  }
  return allowToViewReportButton;
}

export const allowContentTypeToViewHieSourceReportButton = (contentType: string) => {
  if (contentType == 'text/xml' || contentType == 'application/pdf' ||  contentType == 'image/jpeg') {
    return true;
  }
  return false;
}

const styles = StyleSheet.create({
  iconImage: {
    height: 20,
    width: 20,
  },
  conversationContainer: {
    minWidth: width * 0.25,
  },
  verticalLine: {
    width: 2,
    height: isWeb() ? 32 : 45,
    backgroundColor: Colors.Custom.Gray100,
    alignSelf: 'center',
    transform: [{ rotate: '180deg' }],
  },
  verticalLine2: {
    width: 2,
    height: 32,
    backgroundColor: Colors.Custom.Gray100,
    alignSelf: 'center',
    transform: [{ rotate: '180deg' }],
  },
  emptyVerticalLine: {
    width: 2,
    height: isWeb() ? 32 : 45,
    alignSelf: 'center',
    transform: [{ rotate: '180deg' }],
  },
  emptyVerticalLine2: {
    width: 2,
    height: 32,
    alignSelf: 'center',
    transform: [{ rotate: '180deg' }],
  },
  iconContainer: {
    borderRadius: 88,
    padding: 10,
    borderWidth: 1,
    borderColor: Colors.Custom.Gray200,
    alignContent: 'center',
  },
  iconContainer2: {
    borderRadius: 88,
    padding: 10,
    borderWidth: 1,
    borderColor: Colors.Custom.Gray200,
  },
  contentContainer: {
    marginTop: 15,
  },
  contentContainer2: {
    marginTop: 20,
    paddingLeft: 10,
  },
  headerText: {
    color: Colors.FoldPixel.GRAY400
  },
  dateText: {
    lineHeight: 20,
    fontWeight: '600',
    letterSpacing: 1,
    fontFamily: 'Manrope',
  },
  dateText2: {
    lineHeight: 20,
    letterSpacing: 1
  },
  viewButtonContainer: {
    paddingBottom: 4,
    paddingTop: 4,
  },
  arrowIcon: {
    height: 18,
    width: 18,
    marginTop: 2,
  },
});
