import React from 'react';
import {ICommonSvgProps} from '../interfaces';

const CriteriaNotMatchSvg = (props: ICommonSvgProps) => {

  return (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.66665 5.83333L6.33331 9.16665M6.3333 5.83331L9.66665 9.16665M14.6666 7.49998C14.6666 11.1819 11.6819 14.1666 7.99998 14.1666C4.31808 14.1666 1.33331 11.1819 1.33331 7.49998C1.33331 3.81808 4.31808 0.833313 7.99998 0.833313C11.6819 0.833313 14.6666 3.81808 14.6666 7.49998Z" stroke="#D92D20" stroke-linecap="round"/>
    </svg>

  );
};

export default CriteriaNotMatchSvg;
