import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {
  HStack,
  View,
  VStack,
  Text,
  Pressable,
  Spacer,
  Button,
  Box,
  Divider,
  FlatList,
  Spinner,
} from 'native-base';
import { Collapse, Tooltip } from 'antd';

import PAMISearch, {
  PAMISearchType,
} from '../../../../../../common/PAMISearch/PAMISearch';
import {
  IAddOrUpdatePastMedicalHistoriesState,
  IPastMedicalHistory,
  IPastMedicalHistoryComponentValue,
} from '../interfaces';
import {useIntl} from 'react-intl';
import {CommonDataContext} from '../../../../../../../context/CommonDataContext';
import {
  FormContext,
  FormViewType,
  IFormCommonData,
  IFormComponentProps,
} from '../../CustomWrapper/CustomWrapper';
import {
  acceptIgnorePatientReportedRecords,
  deleteResource,
  fhirAddOrUpdate,
  getResourceContentWithHeaders,
} from '../../../../../../../services/CommonService/AidBoxService';
import {
  getPastMedicalHistoryName,
  getFHIRTemplate,
  getRecordListFormatted,
  getSampleData,
  isInvalid,
} from './AddOrUpdatePastMedicalHistoryHelper';
import FormComponentLoader from '../../CustomWrapper/FormComponentLoader/FormComponentLoader';
import {IKeyOperation} from '../../../../../../../Interfaces';
import {FHIR_RESOURCE, PatientRecordActions} from '../../../../../../../constants/FhirConstant';
import {
  ADD_UPDATE_EVENTS,
  componentKeys,
  FormError,
  getHeaderButtons,
  getHeaderViewAllButton,
  tooltipInnerOverlayStyle,
  tooltipOverlayStyle,
  tooltipPlacement,
} from '../../CustomWrapper/CustomComponentHelper';
import FormComponentError from '../../CustomWrapper/FormComponentError/FormComponentError';
import {DetailViewCollapse} from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailViewCollapse';
import {v4 as uuidv4} from 'uuid';
import SectionLoadingIndicator from '../../CustomWrapper/SectionLoadingIndicator/SectionLoadingIndicator';
import {Colors} from '../../../../../../../styles';
import {Drawer, Popover, notification} from 'antd';
import CustomDetailTableView, {
  ICustomDetailColumn,
} from '../../CustomWrapper/CustomDetailTableView/CustomDetailTableView';
import {cloneDeep} from 'lodash';
import {BUTTON_TYPE} from '../../../../../../../constants/StringConst';
import {NoProblemsSvg} from '../../../../../../common/Svg';
import {EventBus} from '../../../../../../../utils/EventBus';
import CompactListViewMoreDetails from '../../CustomWrapper/CompactListView/CompactListViewMoreDetails';
import CustomButton from '../../CustomWrapper/CustomButton/CustomButton';
import { DisplayText } from '../../../../../../common/DisplayText/DisplayText';
import PatientReportedTableView from '../../CustomWrapper/PatientReportedTableView/PatientReportedTableView';
import { InputType } from '../../../../../../../utils/capabilityUtils';
import { PastMedicalHistoryFields, filterAndSkipDuplicates } from '../../CustomComponentUtils';
import { useContainerDimensions } from '../../../../../../CustomHooks/ContainerDimensionHook';
import Feather from 'react-native-vector-icons/Feather';
import { ModalActionTitle } from '../../../../../../common/ModalActionTitle/ModalActionTitle';
import { EHRName } from '../../../../../../PersonOmniView/MiddleContainer/PatientNotes/interfaces';
import {FoldButton} from '../../../../../../CommonComponents/FoldButton/FoldButton';
import DetailPreview from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailPreview/DetailPreview';
import { RightOutlined } from '@ant-design/icons';
import { IDeleteConfirmation, IFormValidationOutput } from '../../CustomWrapper/interfaces';
import PatientIntakeDeleteModal from '../../../../../../common/PatientIntakeDeleteModal/PatientIntakeDeleteModal';
import { getOrganizationCapabilites } from '../../../../../TeamInbox/Conversations/MessagingContactDetails/ContactDetailsTabs/ActivityTimeLine/ActivityAdditionalInfo/CommonComponent/FormResponse/FormUtils';
import useBulkActionPatientReportedRecords from '../../../../../../CustomHooks/useBulkActionPatientReportedRecords';
import PatientIntakeConfirm from '../../../../../../common/PatientIntakeConfirm/PatientIntakeConfirm';
import RecentReportedViewHeader from '../../../../../../common/RecentReportedViewHeader/RecentReportedViewHeader';
import { getAcceptedAndIgnoredRecordNames } from '../../../../../../common/PatientIntakeConfirm/utils';
import { renderSectionBulletList } from '../../../../../../common/CommonUtils/CommonUtils';
import { reactStyles } from '../../../../Analytics/style';

const AddOrUpdatePastMedicalHistory = (props: IFormComponentProps) => {
  const intl = useIntl();
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const isPatientForm = contextData.formContext === FormContext.patientForm;
  const isPatientProfile = contextData.formContext === FormContext.patientProfile;
  const isCompactView = contextData.componentView === FormViewType.compact;
  const isRecentReported = contextData.formContext === FormContext.recentReportedView;
  const isPatientNote = contextData.formContext === FormContext.patientNotes;
  const organizationCapability = getOrganizationCapabilites(
    contextData.organizationCapability,
    FHIR_RESOURCE.CLINICAL_DATA
  );
  const bulkAction = useBulkActionPatientReportedRecords();
  const componentRef = useRef();
  const drawerDetailRef = useRef();
  const { width } = useContainerDimensions(componentRef);
  const { width: drawerDetailWidth, resetDimension } = useContainerDimensions(drawerDetailRef);
  const isMobileScreen = isCompactView ? drawerDetailWidth <= 480 : width <= 480;
  const isPreviewMode = contextData.isPreviewMode || false;
  const isAllowToShare = props?.component?.allowToShare != undefined &&  props?.component?.allowToShare === false ? false : true;
  // Temporary pastMedicalHistory
  const isAthena = contextData.capabilities?.ehrName === EHRName.ATHENA;
  const [componentValue, setComponentValue] =
    useState<IPastMedicalHistoryComponentValue>(
      props.component?.selectedValue || {pastMedicalHistories: []}
    );
  const isCanvas = contextData.capabilities?.ehrName === EHRName.CANVAS;
  const [componentState, setComponentState] =
    useState<IAddOrUpdatePastMedicalHistoriesState>({
      loading: false,
      formError: contextData.formError,
      collapsed: contextData.defaultCollapse,
      showSavingIndicator: false,
      editModalVisible: false,
      editModalCollapse: false,
      showPatientReportedRecords: false,
      selectedPatientReportedRecord: undefined,
      selectedItemToMap: undefined,
      patientReported: [],
      activePanels: [],
    });
    const [importFromChartLoading, setImportFromChartLoading] = useState<boolean>(false);

  const getFieldCapabilities = (): IKeyOperation | undefined => {
    return contextData.capabilities?.abilities?.keyAllowedOperations;
  };
  const [deleteConfirmation, setDeleteConfirmation] = useState<IDeleteConfirmation>({
    modalVisible:false,
    selectedEntry: undefined,
  });
  const cancelDeletePrompt = () =>
    setDeleteConfirmation((prev) => ({
      ...prev,
      modalVisible: false,
      selectedEntry: undefined,
    }));

  const { Panel } = Collapse;

  const validateData = (currentData: IPastMedicalHistoryComponentValue): IFormValidationOutput => {
    // If no patient id is not found means it's lead and for lead, the section is not applicable. So validation should always return true
    if (componentState.formError === FormError.noPatientIdFound) {
      return {isValid: true, message: ''};
    }
    const hasMissingData = currentData?.pastMedicalHistories?.some((problem) => {
      return isInvalidRecord(problem);
    });
    return { isValid: !hasMissingData, message: hasMissingData ? `${props.component.label}: Please fill all the mandatory fields` : '' };
  };

  const isInvalidRecord = (pastMedicalHistory: IPastMedicalHistory) => {
    const config = getFieldCapabilities();
    return isInvalid(PastMedicalHistoryFields.code, pastMedicalHistory, true, config)
};

  const canAddNew = (): boolean => {
    if(isPatientProfile && organizationCapability?.canCreate === false){
      return false
    }
    return (
      contextData.isBuilderMode ||
      ((contextData.capabilities?.abilities?.allowedOperations?.canCreate ||
        false) &&
        !props.disabled)
    );
  };
  const canDelete = (): boolean => {
    if(isPatientProfile && organizationCapability?.canDelete === false){
      return false
    }
    return (
      contextData.capabilities?.abilities?.allowedOperations?.canDelete || false
    );
  };
  const canUpdate = (): boolean => {
    if(isPatientProfile && organizationCapability?.canUpdate === false){
      return false
    }
    return (
      (contextData.capabilities?.abilities?.allowedOperations?.canUpdate ||
        false) &&
      !props.disabled &&
      !isPatientForm
    );
  };

  const onDetailChange = (data: IPastMedicalHistory, index: number,isLocalOnly?: boolean) => {
    if(!isLocalOnly){
      onRecordChange(data);
    }
    setComponentValue((prev) => {
      const list = prev.pastMedicalHistories;
      list[index] = data;
      return {
        ...prev,
        pastMedicalHistories: list,
      };
    });
  };
 const removeFromLocal = (uniqueId?: string) => {
   const index = componentValue.pastMedicalHistories.findIndex(
     (item) => item?.uniqueId === uniqueId
   );
   componentValue.pastMedicalHistories.splice(index, 1);
   setComponentValue((prev) => ({
     ...prev,
     pastMedicalHistories: [...componentValue.pastMedicalHistories],
   }));
 };

 const onDelete = (data: IPastMedicalHistory, index: number, isLocalDelete: boolean) => {
   if (!isLocalDelete && data.id) {
     deleteResource(
       FHIR_RESOURCE.CONDITION,
       `${data.id}`,
       !contextData.hasLoggedInContext,
       contextData.headers,
       {
        patientId: contextData.patientId,
        locationId: contextData.locationId,
        primarySource: false,
        type: 'medical-history',
      },
       (response) => {
         if (response?.success) {
          if(isAthena){
           onDetailChange(data,index,true);
          } else {
            removeFromLocal(data?.uniqueId);
          }
          broadcastUpdateEvent();
          cancelDeletePrompt();
         }
       },
       (error) => {

         cancelDeletePrompt();
         setComponentState((prev) => ({
           ...prev,
           loading: false,
           showSavingIndicator: false,
           formError: FormError.existingDataAPIFail,
         }));
       },
     );
   } else {
     removeFromLocal(data?.uniqueId);
   }
 };

 const addNewItem = (item: any) => {
   const isFreeTextItem = item.coding.length === 0;
   const pastMedicalHistoryData: IPastMedicalHistory = {
     pastMedicalHistory: item,
     name: getPastMedicalHistoryName(item),
     uniqueId: uuidv4(),
     isFreeTextRecord: isFreeTextItem,
   };
   componentValue.pastMedicalHistories.splice(0, 0, pastMedicalHistoryData);
   onRecordChange(pastMedicalHistoryData);
   setComponentValue((prev) => ({
     ...prev,
     pastMedicalHistories: [...componentValue.pastMedicalHistories],
   }));
 };

 const onRecordChange = (record: IPastMedicalHistory) => {
    record?.pastMedicalHistory?.coding?.forEach((codingData: any) => {
        delete codingData?.code;
        delete codingData?.system;
    });
   if (contextData.backgroundSaveEnabled) {
     addOrUpdateRecord(record);
   }
 };

 const isOpenChoice = () => {
   const fields = getFieldCapabilities();
   return fields?.code?.inputType == InputType.openChoice;
 };

 const getSearchComponent = (fullWidth?: boolean): JSX.Element | undefined => {
   return (
     <Pressable
       width={'100%'}
       paddingRight={fullWidth ? 6 : 0}
       onPress={(event) => event.stopPropagation()}
     >
       <View
         flex={1}
         style={!isMobileScreen ? {width: fullWidth ? '100%' : 350} : {}}
       >
         <PAMISearch
           showAdditionalDetails
           enablePaginationOnScroll
           addNewOptionEnabled={isPatientForm || contextData.isBuilderMode || isOpenChoice()}
           clearOnSelection
           searchType={PAMISearchType.problem}
           additionalHeaders={contextData.headers}
           placeholder={`Search and add new medical history`}
           isShowError={false}
           onChange={(value) => {
             if (value) {
               addNewItem(value);
             }
           }}
           locationId={contextData.locationId}
         />
       </View>
     </Pressable>
   );
 };

 const getHeaderRightView = (isCompact: boolean): JSX.Element | undefined => {
  if (!canAddNew()) {
    if (isCompact) {
      return getHeaderViewAllButton(() => {
        setComponentState((prev) => ({...prev, editModalVisible: true}));
      }, 'View all');
    } else {
      return;
    }
  }


   if (isCompact) {
     return getHeaderButtons(() => {
       setComponentState((prev) => ({...prev, editModalVisible: true}));
     });
   } else if (!isMobileScreen) {
     return (
       <HStack space={2} alignItems="center">
         <Spacer />
         {componentState.showSavingIndicator && <SectionLoadingIndicator />}
       </HStack>
     );
   }
 };

 const canShowDeleteBtn = (item?: IPastMedicalHistory): boolean => {
  if (isAthena) {
    return false;
  }
   if (isPatientForm || isPatientNote) {
     return !item?.id;
   }
   // Diagnosis associated with orders cannot be deleted
   if (item?.isAssociatedToOrder) {
    return false;
   }
   return canDelete() || contextData.isBuilderMode;
 };

   const invokeDeleteConfirmation = (item:IPastMedicalHistory,index:number) => {
    setDeleteConfirmation(prev => ({
      ...prev,
      modalVisible: true,
      selectedEntry: {
        item: item,
        index: index
      }
    }))
   }

  const getDeleteFunction = (item: IPastMedicalHistory, index: number) => {
    const isLocalDelete =
      (isPatientForm && !item.id) ||
      contextData.isBuilderMode ||
      !contextData.backgroundSaveEnabled;

    if (isLocalDelete) {
      onDelete(item, index, true);
    } else if (canDelete()) {
      invokeDeleteConfirmation(item,index);
  };
  }

  const getColumns = (): ICustomDetailColumn[] => {
    const fields = getFieldCapabilities();
    const columns: ICustomDetailColumn[] = [
      {
        title: intl.formatMessage({id: 'name'}),
        flex: 9,
        render: (item: IPastMedicalHistory) => {
          return <Text>{item.name}</Text>;
        },
      },
    ];

    const actionsAvailable = canShowDeleteBtn();
    if (!props.disabled && actionsAvailable) {
      columns.push({
        title:  intl.formatMessage({id: 'actions'}),
        flex: 1,
        render: (item: IPastMedicalHistory, index: number) => {
          return (
            <HStack alignItems="center" justifyContent="center" h="100%">
              {isMobileScreen && <Spacer />}
              {canShowDeleteBtn(item) ? (
                <Pressable
                  onPress={() => getDeleteFunction(item, index)}
                  borderWidth={0.3}
                  backgroundColor={Colors.Custom.Base50}
                  borderColor={Colors.Custom.ActionBtnBorder}
                  px={2}
                  py={1}
                  rounded="full"
                  shadow={2}
                >
                  <Feather
                    name={
                      canDelete() || contextData.isBuilderMode ? 'trash-2' : 'x'
                    }
                    size={20}
                    color={Colors.Custom.PurpleColor}
                  />
                </Pressable>
              ) : null}
            </HStack>
          );
        },
      });
    }
    return columns;
  };

  const getDetailView = (isCompact: boolean, isPatientReported: boolean): JSX.Element => {
    if (!componentValue?.pastMedicalHistories?.length && !isCompact && !isPatientReported) {
      return (
        <VStack paddingBottom={5} alignItems={'center'}>
          <NoProblemsSvg titleId="noPastMedicalHistory" />
          <HStack space={2} alignItems="center">
            <Spacer />
            {componentState.showSavingIndicator && <SectionLoadingIndicator />}
            {canAddNew() && getSearchComponent()}
          </HStack>
        </VStack>
      );
    }

    if (isCompact) {
      return (
        <>
        <CompactListViewMoreDetails
          hasPatientReportedRecords={componentState.patientReported.length > 0}
          onViewPatientReportedClick={() => {
            setComponentState((prev) => ({...prev, showPatientReportedRecords: true}));
          }}
          noDataTextLabel={'noPastMedicalHistory'}
          noDataComponent={<NoProblemsSvg titleId="noPastMedicalHistory" />}
          currentStatusValue={''}
          showStatusValue={''}
          viewDataText={''}
          dataSource={componentValue.pastMedicalHistories}
          onViewMoreClick={() => {
            setComponentState((prev) => ({...prev, editModalVisible: true}));
          }}
          showMoreMaxCount={contextData.showMoreMaxCount}
          rowViewForItem={(item: IPastMedicalHistory) => {
            return (
              <VStack key={item.id}>
                <HStack space={1} alignItems="start">
                  <Text color={Colors.Custom['Gray900']}>
                    {item.name}
                  </Text>
                  <Spacer />
                </HStack>
              </VStack>
            );
          }}
        />
      </>
      );
    } else if (isPatientReported) {
      return (
        <PatientReportedTableView
          dataSource={componentState.patientReported}
          rowViewForItem={(item: IPastMedicalHistory, index: number) => {

            return (
              <VStack key={item.id}>
                <HStack space={1} alignItems="start">
                  <VStack space={1} flex={1}>
                    <Text color={Colors.Custom['Gray900']} width="full">
                      {item.name}
                    </Text>
                  </VStack>
                  {organizationCapability?.canCreate && (
                    <HStack space={2}>
                      {item.isFreeTextRecord && (
                        <Popover
                          key={item.id || index}
                          overlayInnerStyle={{
                            bottom: 3,
                            borderRadius: 16,
                            padding: 0,
                          }}
                          content={
                            <View minWidth={300} maxW={400} padding={2}>
                              <VStack space={8}>
                                <Text size="xlMedium">
                                  Match to Medical History
                                </Text>
                                <VStack space={1}>
                                  <DisplayText
                                    textLocalId={'medicalHistory'}
                                    size="smMedium"
                                  />
                                  <PAMISearch
                                    showAdditionalDetails
                                    enablePaginationOnScroll
                                    searchType={PAMISearchType.problem}
                                    additionalHeaders={contextData.headers}
                                    placeholder="Search Medical History"
                                    isShowError={false}
                                    onChange={(value) => {
                                      setComponentState((prev) => ({
                                        ...prev,
                                        selectedItemToMap: value,
                                      }));
                                    }}
                                    locationId={contextData.locationId}
                                  />
                                </VStack>
                                <HStack>
                                  <Spacer />
                                  <Button.Group space={2}>
                                    <FoldButton
                                      nativeProps={{
                                        variant: BUTTON_TYPE.PRIMARY,
                                        onPress: () => {
                                          setComponentState((prev) => {
                                            const list = prev.patientReported;
                                            const index = list.findIndex(
                                              (value) =>
                                                value.uniqueId === item.uniqueId
                                            );
                                            if (index > -1) {
                                              list[index].pastMedicalHistory =
                                                componentState.selectedItemToMap;
                                              list[index].name =
                                                getPastMedicalHistoryName(
                                                  componentState.selectedItemToMap
                                                );
                                              list[index].isFreeTextRecord =
                                                false;
                                            }
                                            return {
                                              ...prev,
                                              patientReported: list,
                                              selectedItemToMap: undefined,
                                              selectedPatientReportedRecord:
                                                undefined,
                                            };
                                          });
                                        },
                                        isDisabled:
                                          !componentState.selectedItemToMap,
                                      }}
                                      customProps={{
                                        btnText: intl.formatMessage({
                                          id: 'continue',
                                        }),
                                        withRightBorder: false,
                                      }}
                                    ></FoldButton>
                                  </Button.Group>
                                </HStack>
                              </VStack>
                            </View>
                          }
                          title=""
                          trigger="click"
                          visible={
                            componentState.selectedPatientReportedRecord ==
                            item.id
                          }
                          onVisibleChange={(visible) => {
                            setComponentState((prev) => ({
                              ...prev,
                              selectedPatientReportedRecord: visible
                                ? item.id
                                : undefined,
                            }));
                          }}
                          placement={'bottom'}
                        >
                          <CustomButton
                            title="Match to Medical History"
                            isDisabled={componentState.showSavingIndicator}
                            onPress={() => {
                              setComponentState((prev) => ({
                                ...prev,
                                selectedPatientReportedRecord: item.id,
                              }));
                            }}
                          />
                        </Popover>
                      )}
                      <CustomButton
                        title="Accept"
                        isDisabled={componentState.showSavingIndicator}
                        onPress={() => {
                          const isCurrentFreeText =
                            !item.pastMedicalHistory?.coding?.length;
                          if (isCurrentFreeText && !isOpenChoice()) {
                            notification.error({
                              message:
                                'This free text medical history must be matched to database before it can be added to EHR',
                            });
                          } else {
                            handlePatientReportedActions(
                              PatientRecordActions.accept,
                              item
                            );
                          }
                        }}
                      />
                      <CustomButton
                        title="Discard"
                        isDisabled={componentState.showSavingIndicator}
                        onPress={() => {
                          handlePatientReportedActions(
                            PatientRecordActions.deny,
                            item
                          );
                        }}
                      />
                    </HStack>
                  )}
                </HStack>
              </VStack>
            );
          }}
        />
      );
    }

    return (
      <VStack flex={1} space={4}>
        <VStack space={2}>
          {canAddNew() && !isMobileScreen && (
            <HStack width={'100%'} margin={3} space={2} alignItems="center">
              {getSearchComponent(true)}
            </HStack>
          )}

          <CustomDetailTableView
            dataSource={componentValue.pastMedicalHistories}
            columns={getColumns()}
            isBuilderMode={contextData.isBuilderMode}
          />
        </VStack>
      </VStack>
    );
  };

  const addOrUpdateRecord = (record: IPastMedicalHistory) => {
    if (!isInvalidRecord(record)) {
      setComponentState((prev) => ({...prev, showSavingIndicator: true}));
      const fhirData = getFHIRTemplate({
        ...record,
        patientId: contextData.patientId || '',
        tenantId: contextData.tenantId || '',
      });
      fhirAddOrUpdate(
        FHIR_RESOURCE.CONDITION,
        record.id,
        fhirData,
        {
          patientId: contextData.patientId,
          locationId: contextData.locationId,
          isMedicalHistory: true,
        },
        (response) => {
          broadcastUpdateEvent();
          let keepShowingSavingIndicator = false;
          if (!record.id) {
            keepShowingSavingIndicator = true;
            getExistingData({patientId: contextData.patientId || '', skipLoader: true});
          }
          if (response?.data?.id) {
            updateDataFromAPIToList(response.data, record);
          }
          setComponentState((prev) => ({
            ...prev,
            ...(!keepShowingSavingIndicator && {showSavingIndicator: false}),
            nonBlockingFormError: undefined,
            customError: undefined,
          }));
        },
        (error) => {
          const errorMessage = error?.response?.data?.message;
          setComponentState((prev) => ({
            ...prev,
            showSavingIndicator: false,
            ...(errorMessage && { customError: errorMessage }),
            ...(!errorMessage && { nonBlockingFormError: FormError.addUpdateFail }),
          }));
          // If new record is failed which is not saved before then only remove from local list
          if (!record.id) {
            removeFromLocal(record.uniqueId);
          }
        },
      );
    }
  };


  const handleSubmitAllAction = async () => {
    await bulkAction.handleBulkAction({
       resource: FHIR_RESOURCE.CONDITION,
       action: PatientRecordActions.accept,
       getFilteredRecords: () => {
         const structuredRecords = componentState.patientReported.filter(
           (record) => !record.isFreeTextRecord
         );
         const FHIRformatedRecords = structuredRecords.map((record) => {
          record?.pastMedicalHistory?.coding?.forEach((codingData: any) => {
            delete codingData?.code;
            delete codingData?.system;
          });
          return getFHIRTemplate({
            ...record,
            patientId: contextData.patientId || '',
            tenantId: contextData.tenantId || '',
          });
         }

         );
         return FHIRformatedRecords;
       },
       patientId: contextData.patientId || '',
       accountLocationUuid: contextData.locationId || '',
       isMedicalHistory: true,
       onComplete: (response) => {
        broadcastUpdateEvent();
        if (response?.data?.id || response?.data?.success) {
          getExistingData({patientId: contextData.patientId || '', skipLoader: true});
        }
       },
       onError: () => {
         setComponentState((prev) => ({
           ...prev,
           nonBlockingFormError: FormError.addUpdateFail,
         }));
       },
     });
   };

  const handlePatientReportedActions = (action: PatientRecordActions, record: IPastMedicalHistory) => {
    setComponentState((prev) => ({...prev, showSavingIndicator: true}));
    record?.pastMedicalHistory?.coding?.forEach((codingData: any) => {
        delete codingData?.code;
        delete codingData?.system;
    });
    const fhirData = getFHIRTemplate({
      ...record,
      patientId: contextData.patientId || '',
      tenantId: contextData.tenantId || '',
    });
    acceptIgnorePatientReportedRecords(
      FHIR_RESOURCE.CONDITION,
      action,
      record.id,
      fhirData,
      {
        patientId: contextData.patientId,
        locationId: contextData.locationId,
        isMedicalHistory: true,
      },
      (response) => {
        // NOTE: This is done because when we send event from `Recent Clinical Event` the component view is not same as the current view. And is same when send event from PAMI component as well to handle this, we are sending the view type from the event itself in case of PAMI component so that the api call is made.
        const viewType = isRecentReported ? undefined : FormViewType.compact;
        broadcastUpdateEvent(viewType);
        let keepShowingSavingIndicator = false;
        if (response?.data?.id || response?.data?.success) {
          if (action === PatientRecordActions.accept) {
            keepShowingSavingIndicator = true;
            // Here we only remove the specific Accepted entry so that it doesnot un-match the other matched records.
            setComponentState((prev) => ({
              ...prev,
              patientReported: prev.patientReported.filter(
                (item) => item.id !== record.id
              ),
              showSavingIndicator: false
            }));
          } else {
            updatePatientReportedDataFromAPIToList(response?.data, record, action);
          }
        }
        setComponentState((prev) => ({
          ...prev,
          ...(!keepShowingSavingIndicator && {showSavingIndicator: false}),
          nonBlockingFormError: undefined,
          customError: undefined,
        }));
      },
      (error) => {
        const errorMessage = error?.response?.data?.message;
        setComponentState((prev) => ({
          ...prev,
          showSavingIndicator: false,
          ...(errorMessage && { customError: errorMessage }),
          ...(!errorMessage && { nonBlockingFormError: FormError.addUpdateFail }),
        }));
      },
    );
  }

  const updateDataFromAPIToList = (data: any, record: IPastMedicalHistory) => {
    if (record.id) return;

    setComponentValue((prev) => {
      const list = prev.pastMedicalHistories;
      const index = list.findIndex(
        (value) => value.uniqueId === record.uniqueId
      );
      if (index > -1) {
        list[index].id = data.id;
      }
      return {
        ...prev,
        pastMedicalHistories: list,
      };
    });
  };

  const updatePatientReportedDataFromAPIToList = (data: any, record: IPastMedicalHistory, action: PatientRecordActions) => {
    setComponentState((prev) => {
      const list = prev.patientReported;
      const index = list.findIndex(
        (value) => value.uniqueId === record.uniqueId
      );
      if (index > -1) {
        list.splice(index, 1);
      }
      return {
        ...prev,
        patientReported: list,
        showPatientReportedRecords: list.length > 0
      };
    });
    if (action === PatientRecordActions.accept && data?.id) {
      setComponentValue((prev) => {
        const list = prev.pastMedicalHistories;
        record.id = data.id;
        list.splice(0, 0, record);
        return {
          ...prev,
          pastMedicalHistories: list,
        };
      });
    }
  };

  const getExistingData = (params: {patientId: string, skipLoader?: boolean, manualImport?: boolean}) => {
    const {patientId, skipLoader, manualImport} = params;
    if (!skipLoader) {
      setComponentState((prev) => ({...prev, loading: true}));
    }
    getResourceContentWithHeaders(
      FHIR_RESOURCE.CONDITION,
      `patient=${patientId}&type=${'medical-history'}${isRecentReported ? `&patientReportedOnly=true` : ''}`,
      !contextData.hasLoggedInContext,
      contextData.headers,
      (response) => {
        handleExistingDataResponse(response,getFieldCapabilities(), manualImport);
      },
      (error) => {

        setComponentState((prev) => ({
          ...prev,
          loading: false,
          showSavingIndicator: false,
          formError: FormError.existingDataAPIFail,
        }));
      },
      contextData.locationId,
      !isPatientForm
    );
  };

  const handleExistingDataResponse = (response: any,fields?:IKeyOperation, manualImport?: boolean) => {
    if (isPatientForm) {
      if (response?.entry) {
        const formattedData = getRecordListFormatted(response.entry, fields);
        const newRecordsAddedInForm = props.component?.selectedValue?.pastMedicalHistories || [];
        setComponentValue((prev) => ({
          ...prev,
          pastMedicalHistories: [...newRecordsAddedInForm, ...formattedData],
        }));

        contextData?.updateFormPrefilledByFoldProgress?.(componentKeys.PAST_MEDICAL_HISTORY, { pastMedicalHistories: [...formattedData] });

        setComponentState((prev) => ({
          ...prev,
          loading: false,
          showSavingIndicator: false,
          activePanels: formattedData?.length ? [componentKeys.PAST_MEDICAL_HISTORY] : [],
          formError: !response?.entry
            ? FormError.existingDataAPIFail
            : prev.formError,
        }));
      }
    } else {
      const patientReportedRecords = response?.patientReported?.data?.entry || [];
      const ehrRecords = response?.resource?.data?.entry || [];
      let patientReportedFormattedRecords: IPastMedicalHistory[] = [];
      if (patientReportedRecords || ehrRecords) {
        let ehrFormattedRecords: IPastMedicalHistory[] = [];
        if (patientReportedRecords?.length) {
          patientReportedFormattedRecords = getRecordListFormatted(
            patientReportedRecords,
            fields
          );
        }
        if (ehrRecords?.length) {
          ehrFormattedRecords = getRecordListFormatted(ehrRecords, fields);
          if (manualImport) {
            ehrFormattedRecords = filterAndSkipDuplicates<IPastMedicalHistory>(
              componentValue.pastMedicalHistories,
              ehrFormattedRecords
            );
          };
        }
        setComponentValue((prev) => ({
          ...prev,
          pastMedicalHistories: [...ehrFormattedRecords]
        }));

        contextData?.updateFormPrefilledByFoldProgress?.(componentKeys.PAST_MEDICAL_HISTORY, { pastMedicalHistories: [...ehrFormattedRecords] });
      }
      setImportFromChartLoading(false);
      setComponentState((prev) => ({
        ...prev,
        loading: false,
        showSavingIndicator: false,
        ...(!patientReportedFormattedRecords.length && {showPatientReportedRecords: false}),
        patientReported: patientReportedFormattedRecords,
        activePanels: ehrRecords?.length || patientReportedFormattedRecords?.length ? [componentKeys.PAST_MEDICAL_HISTORY] : [],
        formError: !ehrRecords || !patientReportedRecords
          ? FormError.existingDataAPIFail
          : prev.formError,
      }));
    }
  }

  const broadcastUpdateEvent = (viewType?: FormViewType) => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent(
      ADD_UPDATE_EVENTS.PAST_MEDICAL_HISTORY,
      viewType || contextData.componentView
    );
  };

  const onEventReceived = useCallback(
    (data) => {
      if (data != contextData.componentView && contextData?.patientId && !isPatientNote) {
        getExistingData({patientId: contextData.patientId, skipLoader: true});
      }
    },
    [contextData]
  );

  const fetchImportData = () => {
    if (isPatientNote && !isPreviewMode && contextData.patientId) {
      setImportFromChartLoading(true);
      getExistingData({patientId: contextData.patientId, skipLoader: true, manualImport: true});
    }
  }


  useEffect(() => {
    if (isRecentReported) {
      contextData?.updateLoadingStatus?.(componentKeys.PAST_MEDICAL_HISTORY, componentState.loading, !!componentState.patientReported.length);
      return;
    }
    const isDataAvailable = !!componentValue.pastMedicalHistories.length || !!componentState.patientReported.length;
    contextData?.updateLoadingStatus?.(componentKeys.PAST_MEDICAL_HISTORY, componentState.loading, isDataAvailable);
  }, [componentState.loading]);

  useEffect(() => {
    if (componentState.editModalVisible) {
      resetDimension();
    }
  }, [componentState.editModalVisible]);

  useEffect(() => {
    if (contextData.isBuilderMode) {
      setComponentValue(getSampleData());
      return;
    }

    const patientId = contextData?.patientId;
    if (patientId && !isPatientNote && (!props.component?.selectedValue || (isPatientForm && !props.disabled))) {
      getExistingData({patientId});
    }
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(ADD_UPDATE_EVENTS.PAST_MEDICAL_HISTORY, onEventReceived);
    return () => {
      eventBus.removeEventListener(onEventReceived);
    };
  }, []);

  useEffect(() => {
    let list = componentValue.pastMedicalHistories;
    if (!canUpdate()) {
      list = componentValue.pastMedicalHistories.filter((item) => {
        return !item.id;
      });
    }
    props.onChange(cloneDeep({pastMedicalHistories: list, total: componentValue.pastMedicalHistories.length}));
  }, [componentValue]);

  useEffect(() => {
    const isDataAvailable = !!componentValue.pastMedicalHistories?.length;
    contextData?.updateLoadingStatus?.(componentKeys.PAST_MEDICAL_HISTORY, componentState.loading, isDataAvailable);
  }, [componentState.loading]);

  props.validateRef.current = validateData;

  const {acceptRecord, ignoredRecords} =
  getAcceptedAndIgnoredRecordNames(componentState.patientReported);

 const renderSubHeader = (isCompactView:boolean):JSX.Element => {
   return isCanvas ? (
     <HStack alignItems={isCompactView ? "start" :"center"} maxWidth='100%' >
      <Box mt={isCompactView ? 1 : 0}>
       <Feather name="info" color={Colors.Custom.Gray500} />
        </Box>
       <Text ml={1} color={Colors.Custom.Gray500} fontSize="xs" w={isCompactView ? 320 : '100%'}>
        Data added under Past Surgical History and Past Medical History section in canvas will show up here
       </Text>
     </HStack>
   ) : (
     <></>
   );
 };
 if (isPreviewMode) {
   return (
     <>
      {componentState.loading && (
        <View padding={4}>
          <FormComponentLoader />
        </View>
      )}
       {!componentState.loading && !componentState.formError && (
         <>
           {componentValue.pastMedicalHistories.length > 0 && (
            <div className={` ${isAllowToShare ? '' : 'disallow-to-share'}`}>
             <DetailPreview titleLocalId={props.component.label}>
               <FlatList
                 initialNumToRender={componentValue.pastMedicalHistories?.length}
                 data={componentValue.pastMedicalHistories}
                 keyExtractor={(item) => item.id || JSON.stringify(item)}
                 renderItem={({item}) => (
                  <div className="page-break">
                    <Text fontWeight={600}>{item.name}</Text>
                   </div>
                 )}
               />
             </DetailPreview>
             </div>
           )}
         </>
       )}
     </>
   );
 }

  return (
    <View ref={componentRef}>
      {isCompactView ? (
        <VStack background={Colors.Custom.BackgroundColor}>
          <Collapse
            activeKey={componentState.activePanels}
            onChange={(activePanels) => {
              setComponentState((prev) => ({...prev, activePanels: activePanels as string[]}));
            }}
            expandIconPosition="end" expandIcon={({ isActive }) => {
            const tooltipText = isActive ? intl.formatMessage({id: 'collapse'}) : intl.formatMessage({id:'expand'});
            return (
              <Tooltip title={tooltipText} placement={tooltipPlacement} showArrow={false} overlayStyle={tooltipOverlayStyle} overlayInnerStyle={tooltipInnerOverlayStyle}>
                <RightOutlined rotate={isActive ? 90 : 0} />
              </Tooltip>
              )}}
            style={reactStyles.collapseStyle}>
            <Panel forceRender key={componentKeys.PAST_MEDICAL_HISTORY} header={
              <HStack
                alignItems={'center'}
                marginRight={4}
                justifyContent="space-between"
              >
                <HStack alignItems={'center'} space={2}>
                  <VStack>
                    <Text
                      color={Colors.FoldPixel.GRAY400}
                      fontWeight={500}
                      size={'lgMedium'}
                    >
                      {props.component.leftSidebarLabel || props.component.label}
                    </Text>
                    {renderSubHeader(isCompactView)}
                  </VStack>
                </HStack>
                <View>{getHeaderRightView(isCompactView)}</View>
              </HStack>}>
              {componentState.loading && (
                <View padding={4}>
                  <FormComponentLoader />
                </View>
              )}
              {!componentState.loading && componentState.customError && (
                <FormComponentError error={FormError.custom} customError={componentState.customError} />
              )}
              {!componentState.loading && componentState.nonBlockingFormError && (
                <FormComponentError error={componentState.nonBlockingFormError} />
              )}
              {!componentState.loading && componentState.formError && (
                <FormComponentError error={componentState.formError} />
              )}
              {!componentState.loading &&
                !componentState.formError &&
                getDetailView(isCompactView, false)}
              <Divider />
            </Panel>
          </Collapse>
        </VStack>
      ) : (
        <div className='page-break'>
         {isRecentReported ? (
          <RecentReportedViewHeader
            loading={componentState.loading}
            noData={!componentState.patientReported.length}
            title={props.component.label}
              buttonOnPress={() => {
                setComponentState((prev) => ({
                  ...prev,
                  showPatientReportedRecords: true,
                }));
              }}
            />
          ) :
        <DetailViewCollapse
          btnList={[{
            icon: importFromChartLoading ? (
              <Spinner mr={1.5} />
            ) : (
              <Feather
                name="download"
                size={20}
                color={Colors.Custom.mainPrimaryPurple}
              />
            ),
            onIconBtnClick: fetchImportData,
            label: intl.formatMessage({id: 'importFromChart'}),
            tooltipLabel: intl.formatMessage({id: 'importFromChartOverrideInfo'}),
            isCustomButton: true,
            hide: !(isPatientNote && !isPreviewMode),
            disabled: importFromChartLoading,
          }]}
          subHeader={renderSubHeader(false)}
          textLocalId={props.component.label}
          toggled={!componentState.collapsed}
          headerRightView={getHeaderRightView(isCompactView)}
          headerStyle={{marginTop: 6}}
          onToggled={() => {
            setComponentState((prev) => ({
              ...prev,
              collapsed: !prev.collapsed,
            }));
          }}
        >
          <VStack>
            {canAddNew() &&
              isMobileScreen &&
              componentValue?.pastMedicalHistories?.length > 0 && (
                <VStack marginY={4} space={2} flex={1} marginX={2}>
                  {getSearchComponent()}
                  {componentState.showSavingIndicator && (
                    <SectionLoadingIndicator />
                  )}
                </VStack>
              )}
            {componentState.loading && (
              <View padding={4}>
                <FormComponentLoader />
              </View>
            )}
            {!componentState.loading && componentState.customError && (
              <FormComponentError error={FormError.custom} customError={componentState.customError} />
            )}
            {!componentState.loading && componentState.nonBlockingFormError && (
              <FormComponentError
                error={componentState.nonBlockingFormError!}
              />
            )}
            {!componentState.loading && componentState.formError && (
              <FormComponentError error={componentState.formError!} />
            )}
            {!componentState.loading &&
              !componentState.formError &&
              getDetailView(isCompactView, false)}
          </VStack>
        </DetailViewCollapse>
        }
        </div>
      )}
      <Drawer
        title={
          <>
          <ModalActionTitle
            isHeadNotSticky
            title={'Medical History'}
            buttonList={[
              {
                onClick: () => {
                  setComponentState((prev) => ({
                    ...prev,
                    editModalVisible: false,
                  }));
                },
                show: true,
                id: 1,
                btnText: intl.formatMessage({
                  id: 'close',
                }),
                size: 'sm',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
              },
            ]}
          />
          </>
        }
        destroyOnClose
        placement="right"
        onClose={() => {
          setComponentState((prev) => ({...prev, editModalVisible: false}));
        }}
        open={componentState.editModalVisible}
        closable
        width="50%"
      >
        <DetailViewCollapse
          btnList={[]}
          subHeader={renderSubHeader(false)}
          textLocalId={props.component.label}
          toggled={!componentState.editModalCollapse}
          headerRightView={getHeaderRightView(false)}
          onToggled={() => {
            setComponentState((prev) => ({
              ...prev,
              editModalCollapse: !prev.editModalCollapse,
            }));
          }}
        >
          <VStack ref={drawerDetailRef}>
            {canAddNew() &&
              isMobileScreen &&
              componentValue?.pastMedicalHistories?.length > 0 && (
                <VStack marginY={4} space={2} flex={1} marginX={2}>
                  {getSearchComponent()}
                  {componentState.showSavingIndicator && (
                    <SectionLoadingIndicator />
                  )}
                </VStack>
              )}
            {componentState.loading && <FormComponentLoader />}
            {!componentState.loading && componentState.customError && (
              <FormComponentError error={FormError.custom} customError={componentState.customError} />
            )}
            {!componentState.loading && componentState.nonBlockingFormError && (
              <FormComponentError error={componentState.nonBlockingFormError} />
            )}
            {!componentState.loading && componentState.formError && (
              <FormComponentError error={componentState.formError} />
            )}
            {!componentState.loading &&
              !componentState.formError &&
              getDetailView(false, false)}
          </VStack>
        </DetailViewCollapse>
      </Drawer>
      <Drawer
        title={
          <ModalActionTitle
            isHeadNotSticky
            title={'Patient Reported Medical History'}
            buttonList={[
              {
                isDisabled: componentState.showSavingIndicator,
                isLoading: componentState.showSavingIndicator,
                onClick: () => {
                  setComponentState((prev) => ({
                    ...prev,
                    showPatientReportedRecords: false,
                    selectedVitalsForBulkActions:
                      componentState.patientReported.map(
                        (item) => item.id || ''
                      ),
                  }));
                },
                show: true,
                id: 1,
                btnText: intl.formatMessage({
                  id: componentState.showSavingIndicator ? 'Saving' : 'close',
                }),
                size: 'sm',
                textColor: componentState.showSavingIndicator
                  ? Colors.primary[800]
                  : Colors.danger[800],
                variant: componentState.showSavingIndicator
                  ? BUTTON_TYPE.TERTIARY
                  : BUTTON_TYPE.SECONDARY,
              },
              {
                isDisabled: componentState.showSavingIndicator || bulkAction.loading,
                isLoading: bulkAction.loading,
                onClick: () => bulkAction.handleConfirmation(true),
                show:
                  organizationCapability?.canCreate &&
                  componentState.patientReported.filter(
                    (item) => !item.isFreeTextRecord
                  ).length > 0,
                id: 2,
                btnText: intl.formatMessage({
                  id: 'acceptAll'
                }),
                size: 'sm',
                textColor: componentState.showSavingIndicator || bulkAction.loading
                  ? Colors.primary[800]
                  : Colors.danger[800],
                variant: BUTTON_TYPE.PRIMARY,
              },
            ]}
          />
        }
        destroyOnClose
        placement="right"
        onClose={() => {
          setComponentState((prev) => ({
            ...prev,
            showPatientReportedRecords: false,
          }));
        }}
        open={componentState.showPatientReportedRecords}
        closable
        width="50%"
      >
        <VStack>
          {componentState.loading && <FormComponentLoader />}
          {!componentState.loading && componentState.customError && (
            <FormComponentError error={FormError.custom} customError={componentState.customError} />
          )}
          {!componentState.loading && componentState.nonBlockingFormError && (
            <FormComponentError error={componentState.nonBlockingFormError} />
          )}
          {!componentState.loading && componentState.formError && (
            <FormComponentError error={componentState.formError} />
          )}
          {!componentState.loading &&
            !componentState.formError &&
            getDetailView(false, true)}
        </VStack>
      </Drawer>
      {deleteConfirmation.modalVisible && (
       <PatientIntakeDeleteModal
        isDeleteHistorical={isAthena}
        type={'Medical History'}
        visible={deleteConfirmation.modalVisible}
        onCancel={cancelDeletePrompt}
        onDelete={() => {
          onDelete(
            deleteConfirmation.selectedEntry?.item,
            deleteConfirmation.selectedEntry?.index as number,
            false
          );
        }}
        />
      )}
       {bulkAction.confirm && (
        <PatientIntakeConfirm
          title='Accept Patient Reported Data'
          onOk={() => handleSubmitAllAction()}
          onCancel={() => bulkAction.handleConfirmation(false)}
          acceptCount={acceptRecord.length}
          renderRecords={() => {
            return (
              <>
                {ignoredRecords.length > 0 && (
                  <View>
                    <Text mt={2.5}>
                      Following data reported by patient is unstructured and has
                      not been matched to a structured record. These will not be
                      updated in the EHR.
                    </Text>
                    {renderSectionBulletList(
                      `Unmatched records (${ignoredRecords.length} / ${componentState.patientReported.length})`,
                      ignoredRecords,
                      undefined,
                      {
                        backgroundColor: Colors.Custom.Red100,
                        borderColor: Colors.Custom.ErrorColor,
                      }
                    )}
                  </View>
                )}
                {acceptRecord.length > 0 && (
                  <View>
                    <Text mt={2.5}>Following records will be updated in the EHR.</Text>
                    {renderSectionBulletList(
                      `Matched records (${acceptRecord.length} / ${componentState.patientReported.length}) `,
                      acceptRecord
                    )}
                  </View>
                )}
              </>
            );
          }}
        />
      )}
    </View>
  );
};

export default AddOrUpdatePastMedicalHistory;
