export default [
    {
        type: 'checkbox',
        key: 'allowToChangeNoteTaskTitle',
        label: 'Use selection as task title',
        input: true,
        defaultValue: false,
        tooltip: 'Please note that only one component can be selected for populating the task title for that note.',
        customConditional: function customConditional(context: any) {
          return isAllowToShowNoteTaskTitleField(context.options?.userSettings);
        },
    },
];

export const isAllowToShowNoteTaskTitleField = (userSettings: any) => {
    return (
      userSettings['allow_to_change_note_task_title_field_enabled']?.value === 'True' || false
    );
};