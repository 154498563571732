import {Tooltip} from 'antd';
import {Badge, HStack, Text, View} from 'native-base';
import FeatherIcon from 'react-native-vector-icons/Feather';

import AppointmentIconSvg from '../../../common/Svg/TimelineSvg/AppointmentIconSvg';
import {testID, TestIdentifiers} from '../../../../testUtils';
import {
  CARE_PROGRAM_STEP_TYPE,
  TOOLTIP_STATUS_TEXT,
} from '../../../common/MemebersView/constant';
import {CARE_PROGRAM_STEP_STATUS_CODES} from '../../../../constants/MlovConst';
import {Colors} from '../../../../styles';
import {getDiffInHours} from '../../../../utils/DateUtils';
import EcmOutreachSvg from '../../../common/Svg/CareProgramSteps/EcmOutreachSvg';

const getTimerColorByRemainingTime = (
  hours: number,
  stepTypeCode?: string
): {backgroundColor: string; fontColor: string} => {
  if (hours <= 5) {
    return {
      fontColor: Colors.Custom.MissedTaskFontColor,
      backgroundColor: Colors.Custom.MissedTaskBackgroundColor,
    };
  } else if (hours <= 15 && hours > 5) {
    return {
      fontColor: Colors.Custom.PendingTaskFontColor,
      backgroundColor: Colors.Custom.PendingTaskBackgroundColor,
    };
  } else {
    return {
      fontColor: Colors.Custom.Gray500,
      backgroundColor: Colors.FoldPixel.GRAY100,
    };
  }
};

const getStepColor = (
  stepCode: string
): {icon: string; background: string; tooltipStatusText?: string} => {
  switch (stepCode) {
    case CARE_PROGRAM_STEP_STATUS_CODES.TO_DO:
      return {
        icon: Colors.Custom.Gray500,
        background: Colors.Custom.Gray100,
        tooltipStatusText: TOOLTIP_STATUS_TEXT.PENDING,
      };

    case CARE_PROGRAM_STEP_STATUS_CODES.SKIP:
      return {
        icon: Colors.Custom.PendingTaskFontColor,
        background: Colors.Custom.PendingTaskBackgroundColor,
      };

    case CARE_PROGRAM_STEP_STATUS_CODES.FAIL:
      return {
        icon: Colors.Custom.MissedTaskFontColor,
        background: Colors.Custom.MissedTaskBackgroundColor,
      };

    case CARE_PROGRAM_STEP_STATUS_CODES.DONE:
      return {
        icon: Colors.Custom.CompletedTaskFontColor,
        background: Colors.Custom.CompletedTaskBackgroundColor,
        tooltipStatusText: TOOLTIP_STATUS_TEXT.COMPLETED,
      };

    case CARE_PROGRAM_STEP_STATUS_CODES.EXPIRE:
      return {
        icon: Colors.Custom.MissedTaskFontColor,
        background: Colors.Custom.MissedTaskBackgroundColor,
      };

    case CARE_PROGRAM_STEP_STATUS_CODES.IN_PROGRESS:
      return {
        icon: Colors.Custom.PendingTaskFontColor,
        background: Colors.Custom.PendingTaskBackgroundColor,
        tooltipStatusText: TOOLTIP_STATUS_TEXT.IN_PROGRESS,
      };

    default:
      return {icon: Colors.Custom.Gray300, background: Colors.Custom.Gray100};
  }
};

export const getExpiryTimerElement = (
  expiresOn: Date,
  toolTipText: string,
  stepTypeCode?: string
): JSX.Element => {
  let timerText = '';
  const remainingHours = Math.floor(getDiffInHours(new Date(), expiresOn));
  const {icon, background} = getStepColor(
    CARE_PROGRAM_STEP_STATUS_CODES.EXPIRE
  );
  if (remainingHours <= 0) {
    return (
      <View
        style={{
          backgroundColor: background,
          padding: 4,
          borderRadius: 4,
          overflow: 'hidden',
        }}
      >
        {stepTypeCode === CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_14DAY ? (
          <AppointmentIconSvg customStrokeColor={icon} size={14} />
        ) : (
          <EcmOutreachSvg customStrokeColor={icon} size={14} />
        )}
      </View>
    );
  }

  if (remainingHours > 48) {
    const days: number = Math.floor(remainingHours / 24);
    timerText = `${days}d`;
  } else {
    timerText = `${remainingHours} hrs`;
  }
  const timerColor: {backgroundColor: string; fontColor: string} =
    getTimerColorByRemainingTime(remainingHours, stepTypeCode);
  return (
    <Badge backgroundColor={timerColor?.backgroundColor} borderRadius={8}>
      <HStack flex={1} space={1} alignItems="center">
        {stepTypeCode !== CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_14DAY && (
          <FeatherIcon
            name="clock"
            size={14}
            color={timerColor.fontColor}
            {...testID(TestIdentifiers.deleteBtn)}
          />
        )}
        <Text color={timerColor.fontColor} fontWeight={'400'} fontSize={12}>
          {timerText}
        </Text>
      </HStack>
    </Badge>
  );
};
