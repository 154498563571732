import ReactDOM from 'react-dom';
import {ReactComponent} from '@foldhealth/formio-react';
import {Formio} from '@foldhealth/formiojs';
import CustomWrapper from '../CustomWrapper/CustomWrapper';
import KeyField from '../../EditFormFields/KeyField';
import ConditionalComponents from '../../EditFormFields/ConditionalFields';
import CustomClassComponent from '../CustomWrapper/CustomClassComponent';
import CareProgramAppointmentWrapper from './CareProgramAppointmentComponent/CareProgramAppointmentWrapper';
import {HashRouter} from 'react-router-dom';

export default class CareProgramAppointments extends CustomClassComponent {
  static get builderInfo() {
    return {
      title: 'Care Program Appointments',
      icon: 'filetext1',
      group: 'advanced',
      documentation: '',
      schema: CareProgramAppointments.schema(),
    };
  }

  static schema() {
    return ReactComponent.schema({
      type: 'careProgramAppointments',
      label: 'Care Program Appointments',
      key: 'careProgramAppointments',
    });
  }

  attachReact(element: any) {
    return ReactDOM.render(
      <CustomWrapper formOptionData={this.options} capabilityList={[]}>
        <HashRouter>
          <CareProgramAppointmentWrapper
            component={this.component}
            onChange={this.updateValue}
            validateRef={this.validateRef}
            options={this.options}
            disabled={this.disabled}
          />
        </HashRouter>
      </CustomWrapper>,
      element
    );
  }

  static editForm = function () {
    return {
      key: 'display',
      components: [
        {
          type: 'oldtextfield',
          key: 'label',
          label: 'Label',
          input: true,
          validate: {
            required: true,
          },
        },
        ...ConditionalComponents,
        ...KeyField,
      ],
    };
  };
}

Formio.Components.addComponent(
  'careProgramAppointments',
  CareProgramAppointments
);
