import React from 'react';
import ReactDOM from 'react-dom';
import {ReactComponent} from '@foldhealth/formio-react';
import {Formio} from '@foldhealth/formiojs';
import CustomWrapper from '../CustomWrapper/CustomWrapper';
import KeyField from '../../EditFormFields/KeyField';
import CustomClassComponent from '../CustomWrapper/CustomClassComponent';
import AwvHistoryComponent from './AwvHistory';

export default class AwvHistory extends CustomClassComponent {
  static get builderInfo() {
    return {
      title: 'Annual Wellness Visit History',
      icon: 'filetext1',
      group: 'advanced',
      documentation: '',
      schema: AwvHistory.schema(),
    };
  }

  static schema() {
    return ReactComponent.schema({
      type: 'awvHistory',
      label: 'Annual Wellness Visit History',
      key: 'awvHistory',
    });
  }

  attachReact(element: any) {
    return ReactDOM.render(
      <CustomWrapper
        formOptionData={this.options}
      >
        <AwvHistoryComponent
          options={this.options}
          disabled={this.disabled}
          component={this.component}
          validateRef={this.validateRef}
          onChange={this.updateValue}
        />
      </CustomWrapper>,
      element
    );
  }

  static editForm = function () {
    return {
      key: 'display',
      components: [
        {
          type: 'oldtextfield',
          key: 'label',
          label: 'Label',
          input: true,
          validate: {
            required: true,
          },
        },
        // ...ConditionalComponents,
        ...KeyField,
      ],
    };
  };
}

Formio.Components.addComponent('awvHistory', AwvHistory);
