import {StyleSheet} from 'react-native';
import { Colors } from '../../../../../../styles';

export const styles = StyleSheet.create({
  emailView: {
    flex: 0.2,
    alignItems: 'flex-start',
    // backgroundColor: 'yellow',
  },
  emailText: {
    fontSize: 14,
    color: Colors.FoldPixel.GRAY250
  },
});
