import React, { useState, useEffect, useContext } from 'react';
import { Select, Space, Checkbox } from 'antd';
import { useLazyQuery } from '@apollo/client';
import { Text, View, Button, HStack, Divider, Spinner, ScrollView, Icon, Input, VStack, IconButton, Center } from 'native-base';
import { Colors } from '../../../styles';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { TouchableOpacity, StyleSheet, Pressable } from 'react-native';
import ContactsQueries from '../../../services/Contacts/ContactsQueries';
import { getContactTypeId } from '../../../utils/mlovUtils';
import CustomAttributeQueries from '../../RightSideContainer/Contacts/CustomField/CustomAttributeQueries';
import AlphabetAvatar from '../../common/Avatar/AlphabetAvatar';
import EFaxActionSvg from '../../common/Svg/PersonActionSvgIcons/EFaxActionSvg';
import { Provider } from '../../RightSideContainer/TeamInbox/Conversations/ConversationChannelTabs/CreateEFaxConversationDrawer/interface';
import { useIntl } from 'react-intl';
import { CommonDataContext } from '../../../context/CommonDataContext';
import SearchActionSvg from '../../common/Svg/PersonActionSvgIcons/SearchActionSvg';
import '../SideCar.css';
import { NoDataFound } from '../../common/NoDataFound';

interface ProviderSelectionViewProps {
  onSelect: (provider: Provider) => void;
}

const CustomCheckbox: React.FC<{ checked: boolean; onChange: () => void; label: any }> = ({ checked, onChange, label }) => {
  return (
    <TouchableOpacity onPress={onChange} style={styles.checkboxContainer}>
      <View style={[styles.checkbox, checked && styles.checkedCheckbox]}>
        {checked && <View style={styles.innerCircle} />}
      </View>
      <Text style={styles.label}>{label}</Text>
    </TouchableOpacity>
  );
};

const CustomSquareCheckbox: React.FC<{ checked: boolean; onChange: () => void; label: any }> = ({ checked, onChange, label }) => {
  return (
    <View style={styles.checkboxContainer}>
      <Checkbox 
        checked={checked}
        onChange={onChange}
        style={{ marginBottom: 8 }}
      >
        {label}
      </Checkbox>
    </View>
  );
};

const styles = StyleSheet.create({
  checkboxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 4,
  },
  checkbox: {
    width: '16px',
    height: '16px',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#8A94A8',
    marginRight: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkedCheckbox: {
    backgroundColor: Colors.Custom.Primary300,
    borderColor: Colors.Custom.Primary300,
  },
  innerCircle: {
    width: '8px',
    height: '8px',
    borderRadius: 3.5,
    backgroundColor: 'white'
  },
  label: {
    color: '#333333',
    marginLeft: 5,
  },
});

const ProviderSelectionView = ({ onSelect }: ProviderSelectionViewProps) => {
  const [searchText, setSearchText] = useState<string>('');
  const [selectedSpecialties, setSelectedSpecialties] = useState<string[]>([]);
  const [tempSelectedSpecialties, setTempSelectedSpecialties] = useState<string[]>([]);
  const [specialties, setSpecialties] = useState<any[]>([]);
  const [selectedProviders, setSelectedProviders] = useState<number>();
  const [contacts, setContacts] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const customerContactType = getContactTypeId('CUSTOMER');
  const intl = useIntl();
  const commonData = useContext(CommonDataContext);
  const isSidecarContext = commonData?.sidecarContext?.isSidecar;

  const getEFaxCustomAttributeId = async () => {
    const response = await getCustomAttributeId({
      variables: {
        key: 'e_fax_number',
      },
    });

    return response?.data?.customAttributes?.[0]?.id;
  };

  const getSpecialityCustomAttributeId = async () => {
    const response = await getCustomAttributeIdForSpeciality({
      variables: {
        key: 'speciality',
      },
    });

    return response?.data?.customAttributes?.[0]?.id;
  };

  const getDistictSpecialities = async () => {
    const customAttributeId = await getSpecialityCustomAttributeId()
    const variables = {
      customAttributeBooleanExpression: {
        customAttributeId: { _eq: customAttributeId }
      }
    };

    const response = await getSpecialityByCustomAttributeId({
      variables
    });
    setSpecialties(response?.data?.customAttributeValues || [])
  }

  const [getCustomAttributeId] = useLazyQuery(
    CustomAttributeQueries.GET_CUSTOM_ATTRIBUTE_ID,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [getCustomAttributeIdForSpeciality] = useLazyQuery(
    CustomAttributeQueries.GET_CUSTOM_ATTRIBUTE_ID,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [getSpecialityByCustomAttributeId] = useLazyQuery(
    ContactsQueries.GET_SPECIALITY_BY_CUSTOM_ATTRIBUTE_ID_FOR_REFERRAL,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [getContactByCustomAttributeId] = useLazyQuery(
    ContactsQueries.GET_CONTACT_BY_CUSTOM_ATTRIBUTE_ID_FOR_REFERRAL,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const getReceiversContact = async () => {
    setLoading(true);
    try {
      const customAttributeId = await getEFaxCustomAttributeId();

      const variables: any = {
        customAttributeBooleanExpression: {
          customAttributeValues: { customAttributeId: { _eq: customAttributeId } },
          contactType: { typeId: { _neq: customerContactType } }
        }
      };

      if (searchText) {
        variables.customAttributeBooleanExpression = {
          ...variables.customAttributeBooleanExpression,
          name: { _ilike: `%${searchText}%` }
        };
      }

      if (selectedSpecialties?.length) {
        const customAttributeIdForSpeciality = await getSpecialityCustomAttributeId();
        variables.customAttributeBooleanExpression = {
          _and: [
            {
              ...variables.customAttributeBooleanExpression,
            },
            {
              customAttributeValues: {
                customAttributeId: { _eq: customAttributeIdForSpeciality },
                value: { _in: selectedSpecialties }
              }
            }
          ]
        };
      }
      const response = await getContactByCustomAttributeId({
        variables
      });
      setContacts(response?.data?.contacts || []);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDistictSpecialities()
  }, [])

  useEffect(() => {
    getReceiversContact();
  }, [searchText, selectedSpecialties]);

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const getReceiversOption = () => {
    if (!contacts || contacts.length === 0) {
      return (
        <Center>
          <NoDataFound displayString={intl.formatMessage({ id: 'noMatchingProvidersFound' })} />
        </Center>
      );
    }

    return (
      <ScrollView maxHeight={500}>
        {contacts?.map((provider: any, index: number) => {
          if (index < (!isSidecarContext ? 8 : 5) || searchText) {
            const isSelected = selectedProviders == (provider?.id);
            const isHovered = hoveredIndex === index;

            return (
              <Pressable
                key={provider?.id}
                onPress={() => handleSelect(provider)}
                style={{
                  backgroundColor: isSelected ? '#fdf9ff' : (isHovered ? Colors.Custom.Gray50 : Colors.Custom.ConversationBgColor),
                  borderColor: isSelected ? Colors.Custom.Primary200 : (isHovered ? Colors.Custom.Gray100 : Colors.Custom.ConversationBgColor),
                  borderWidth: 1,
                  borderRadius: 5,
                  marginTop: 2,
                }}
              >
                <HStack
                  style={{
                    alignItems: 'center',
                    padding: 2,
                  }}
                  justifyContent='space-between'
                >
                  <CustomCheckbox
                    checked={isSelected}
                    onChange={() => handleSelect(provider?.id)}
                    label={
                      <HStack space={5} alignItems={'center'}>
                        <AlphabetAvatar
                          size={12}
                          style={{
                            alignItems: 'center',
                            borderRadius: 8,
                            borderColor: '#FBCEB7',
                            borderWidth: 1,
                          }}
                          disableTop
                          name={provider?.name}
                          textStyle={{
                            fontSize: 14,
                            textAlign: 'center',
                            padding: 1,
                          }}
                          userType={'USER'}
                        />
                        <VStack>
                          <Text fontSize={14} color={'#3A485F'}>{provider?.name}</Text>

                          <HStack space={1}>
                            {(provider?.personAddress?.[0]?.line1 ||
                              provider?.personAddress?.[0]?.cities?.name ||
                              provider?.personAddress?.[0]?.zipcodes?.code) && (
                                <Text color={Colors.FoldPixel.GRAY200}>
                                  • {provider?.personAddress?.[0]?.line1
                                    ? `${provider?.personAddress?.[0]?.line1}, `
                                    : ''}
                                  {provider?.personAddress?.[0]?.cities?.name
                                    ? `${provider?.personAddress?.[0]?.cities?.name}, `
                                    : ''}
                                  {provider?.personAddress?.[0]?.zipcodes?.code || ''}
                                </Text>
                              )}

                            {provider?.eFaxNo?.[0]?.value && (
                              <Text fontSize={12} color={Colors.FoldPixel.GRAY200}>
                                <EFaxActionSvg
                                  width={12}
                                  height={12}
                                  customStrokeColor={Colors.Custom.Gray500}
                                /> {provider?.eFaxNo?.[0]?.value}
                              </Text>
                            )}
                          </HStack>
                        </VStack>
                      </HStack>
                    }
                  />
                </HStack>
              </Pressable>
            );
          }
          return null;
        })}
      </ScrollView>
    );
  };

  const handleSelect = (value: any) => {
    setSelectedProviders(value);

    const providerData = contacts?.find((contact: any) => contact?.id === value);
    if (providerData) {
      const provider: Provider = {
        id: providerData?.id,
        name: providerData?.name,
        uuid: providerData?.uuid,
        phoneNumber: providerData?.eFaxNo?.[0]?.value || '',
        speciality: providerData?.speciality?.[0]?.value || '',
        cityName: providerData?.personAddress?.[0]?.cities?.name || '',
        zipCode: providerData?.personAddress?.[0]?.zipcodes?.code || ''
      };
      onSelect(provider);
    }
  };

  const handleSpecialtyChange = (checkedValues: string[]) => {
    setTempSelectedSpecialties(checkedValues);
  };

  const handleSelectAll = () => {
    if (tempSelectedSpecialties?.length === specialties?.length) {
      setTempSelectedSpecialties([]);
    } else {
      setTempSelectedSpecialties(specialties.map(specialty => specialty.textValue));
    }
  };

  const handleClearAll = () => {
    setTempSelectedSpecialties([]);
  };

  const handleApply = () => {
    setSelectedSpecialties(tempSelectedSpecialties);
    setIsOpen(false);
  };

  const handleRemoveSpecialty = (specialty: string) => {
    const updatedSpecialties = selectedSpecialties.filter(s => s !== specialty);
    setSelectedSpecialties(updatedSpecialties);
    setTempSelectedSpecialties(updatedSpecialties);
  };

  return (
    <View>
      <Space direction='vertical' size={16} style={{ width: '100%' }}>
        <Input
          placeholder='Search provider'
          InputLeftElement={
            <IconButton
              icon={
                <SearchActionSvg
                  width={16}
                  height={16}
                  customStrokeColor={Colors.FoldPixel.GRAY250}
                />
              }
              variant="unstyled"
            />
          }
          _hover={{
            backgroundColor: Colors.Custom.White,
          }}
          onChangeText={handleSearch}
          borderRadius={4}
          borderWidth={0.5}
          borderColor={Colors.Custom.Gray300}
          backgroundColor={Colors.Custom.White}
          paddingX={'2px'}
          height={'36px'}
        />
        <VStack space={1}>
          <Text fontSize={'14px'} fontWeight={400} color={Colors.FoldPixel.GRAY250}>Speciality</Text>
          <Select
            mode='multiple'
            placeholder='Select specialty'
            value={selectedSpecialties}
            onDropdownVisibleChange={(visible) => {
              setIsOpen(visible);
              if (visible) {
                setTempSelectedSpecialties(selectedSpecialties);
              }
            }}
            style={{ width: '100%', height: '32px' }}
            open={isOpen}
            onDeselect={handleRemoveSpecialty}
            dropdownRender={(menu) => (
              <>
                <VStack space={2} p={2}>
                  <HStack justifyContent={'space-between'} alignItems={'center'}>
                    <CustomSquareCheckbox
                      checked={tempSelectedSpecialties?.length === specialties?.length && specialties.length > 0}
                      onChange={handleSelectAll}
                      label="Select All"
                    />
                    <Button size='sm' variant='ghost' colorScheme='secondary' onPress={handleClearAll}>
                      Clear All
                    </Button>
                  </HStack>
                  <Divider />
                  <ScrollView maxHeight={212}>
                    {specialties?.map((specialty: any) => (
                      <CustomSquareCheckbox
                        key={specialty?.id}
                        checked={tempSelectedSpecialties.includes(specialty?.textValue)}
                        onChange={() => {
                          const newValues = tempSelectedSpecialties.includes(specialty?.textValue)
                            ? tempSelectedSpecialties.filter(s => s !== specialty?.textValue)
                            : [...tempSelectedSpecialties, specialty?.textValue];
                          handleSpecialtyChange(newValues);
                        }}
                        label={specialty?.textValue}
                      />
                    ))}
                  </ScrollView>
                  <Divider />
                  <HStack justifyContent={'space-between'}>
                    <Button
                      style={{
                        borderColor: Colors.FoldPixel.GRAY300,
                      }}
                      _text={{
                        color: Colors.FoldPixel.GRAY300,
                        fontWeight: '500',
                      }}
                      _hover={{
                        backgroundColor: Colors.FoldPixel.GRAY100
                      }}
                      variant='outline'
                      width={'48%'}
                      onPress={() => setIsOpen(false)}>Close</Button>
                    <Button
                      variant='subtle'
                      _hover={{
                        backgroundColor: '#7749c2'
                      }}
                      _text={{
                        color: Colors.Custom.MonochromeWhite,
                        fontWeight: '500',
                      }}
                      backgroundColor={Colors.Custom.Primary300}
                      width={'48%'}
                      onPress={handleApply}
                    >
                      {intl.formatMessage({ id: 'Apply' })}
                    </Button>
                  </HStack>
                </VStack>
              </>
            )}
          >
            {specialties?.map((specialty: any) => (
              <Select.Option key={specialty?.id} value={specialty?.textValue}>
                {specialty?.textValue}
              </Select.Option>
            ))}
          </Select>
        </VStack>
        <Divider />
        <VStack space={3} maxHeight={500}>
          {loading ? (
            <Spinner />
          ) : (
            getReceiversOption()
          )}
        </VStack>

      </Space>
    </View>
  );
};

export default ProviderSelectionView;