import React, {useCallback} from 'react';
import {useOutreach} from './hooks/useOutreach';
import {useIntl} from 'react-intl';
import InsightsPatientNotesView from '../../../../../SideCar/components/InsightsPatientNotesView';
import {usePersonOmniViewContext} from '../../../../../PersonOmniView/PersonOmniView.context';
import {useContactCareProgramContext} from '../../ContactCareProgram.context';
import {AddOrUpdateContactOutreachActivityInput} from '../../../interface';
import {ContactCareProgramReducerAction} from '../../reducer';
import {useMutation} from '@apollo/client';
import {CreateContactOutreachActivity} from '../../../../../../services/ContactOutreach/ContactOutreachQueries';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../../constants/Configs';
import {DUMMY_OUTREACH_STEP_ID} from '../../ContactCareProgramView.utils';
import useOutreachStatus from '../../../../../common/CareProgramStepOutreach/useOutreachStatus';
import useOutreachStepOutComes from '../../../../../common/CareProgramStepOutreach/useOutreachStepOutComes';
import {OUT_REACH_OUTCOME_CATEGORY_CODES} from '../../../../../../constants/MlovConst';

const OutreachView: React.FC = () => {
  const {enabledContactModes, outreachStepOutComesCategoryWiseList} =
    useOutreach();
  const intl = useIntl();
  const outreachStatus = useOutreachStatus();
  const {categoryWiseList} = useOutreachStepOutComes();
  const {data: contactData} = usePersonOmniViewContext();
  const {
    state: {selectedStepId, contactCareProgramDetails},
    dispatch,
    scrollContentViewToTop,
    refetchMasterData
  } = useContactCareProgramContext();
  const [addOrUpdateContactOutreachActivity] = useMutation(
    CreateContactOutreachActivity,
    {
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
    }
  );
  const careProgramTypeId = contactCareProgramDetails?.payerCareProgram?.careProgramTypeId;


  const getStatusIdByOutComeId = (outComeId: string) => {
    const outComeCategory = Object.keys(categoryWiseList).find((key) => {
      return categoryWiseList[key].some((mlov) => mlov.key === outComeId);
    });

    if (!outComeCategory) {
      return '';
    }

    if (
      [
        OUT_REACH_OUTCOME_CATEGORY_CODES.OUTREACH_COMPLETED,
        OUT_REACH_OUTCOME_CATEGORY_CODES.FOLLOW_UP_NEEDED,
      ].includes(outComeCategory)
    ) {
      return outreachStatus?.success
    }
    return outreachStatus?.failed;
  };

  const handleOutreachSave = useCallback(
    async (outreachData: AddOrUpdateContactOutreachActivityInput) => {
      const isUpdateOutreach = Boolean(outreachData?.id);
      const statusId = getStatusIdByOutComeId(outreachData?.outcomeId || '');
      outreachData.careProgramStepPayload = {
        contactCareProgramId: contactCareProgramDetails?.id as string,
        ...(isUpdateOutreach && {
          careProgramStepId:
            selectedStepId === DUMMY_OUTREACH_STEP_ID
              ? undefined
              : selectedStepId,
        }),
      };
      outreachData.statusId = statusId || outreachData?.statusId;
      outreachData.careProgramTypeId = careProgramTypeId as string;
      const response = await addOrUpdateContactOutreachActivity({
        variables: {data: outreachData},
      });
      const newOutreachStepId = response?.data?.addOrUpdateContactOutreachActivity?.outreachStepId;
      refetchMasterData && (await refetchMasterData());
      if (newOutreachStepId) {
        dispatch?.({type: ContactCareProgramReducerAction.SET_SELECTED_STEP_ID, payload: newOutreachStepId});
      }
      return response;
    },
    [
      addOrUpdateContactOutreachActivity,
      contactCareProgramDetails?.id,
      selectedStepId,
    ]
  );

  const selectStepWhenEditingOldActivity = useCallback(
    (stepId: string) => {
      scrollContentViewToTop();
    },
    [dispatch, scrollContentViewToTop]
  );

  return (
    <div className="content-view">
        <InsightsPatientNotesView
          contactData={contactData}
          hiddenFields={['careProgramTypeId']}
          isCareManagement
          customOnSaveHandler={handleOutreachSave}
          selectStepWhenEditingOldActivity={selectStepWhenEditingOldActivity}
          careProgramTypeId={careProgramTypeId}
          contactCareProgramId={contactCareProgramDetails?.id}
          macroTemplatesEnabled
        />
    </div>
  );
};

export default OutreachView;
