import React from 'react';

interface ErrorBoundaryProps {
  children: React.ReactNode;
  fallback: React.ReactNode | ((error: string) => React.ReactNode);
}

interface ErrorBoundaryState {
  error: Error | null;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {error: null};
  }

  componentDidCatch(error: Error) {
    this.setState({error});
  }

  render() {
    const {error} = this.state;
    const {fallback, children} = this.props;

    if (error) {
      return typeof fallback === 'function'
        ? fallback(error?.toString() || 'Something went wrong')
        : fallback;
    }

    return <>{children}</>;
  }
}

export default ErrorBoundary;
