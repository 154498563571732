import {createContext, useContext} from "react";

export interface IMainContentContextState {
  headerSavingIndicator: boolean;
  formResponseForAssessmentStep: any;
}

export interface IMainContentContext {
  mainContentContextState: IMainContentContextState;
  updateMainContentContextState: (newState: Partial<IMainContentContextState>) => void;
}

const MainContentContext = createContext<IMainContentContext>({
  mainContentContextState: {
    headerSavingIndicator: false,
    formResponseForAssessmentStep: undefined,
  },
  updateMainContentContextState: () => {},
});

export const MainContentProvider = MainContentContext.Provider;

export const useMainContentContext = () => {
  return useContext(MainContentContext);
};
