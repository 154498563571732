import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {
  HStack,
  View,
  VStack,
  Text,
  Pressable,
  Spacer,
  Button,
  Select,
  FormControl,
  Input,
  Icon,
  IconButton,
  TextArea,
  Box,
  Divider,
  FlatList,
  Spinner,
} from 'native-base';
import { Collapse, Modal, Tooltip } from 'antd';

import PAMISearch, {
  PAMISearchType,
} from '../../../../../../common/PAMISearch/PAMISearch';
import {
  IAddOrUpdateConditionsState,
  ICondition,
  IConditionComponentValue,
} from '../interfaces';
import {CONDITION_CLINICAL_STATUS_CODE} from '../../../../../../../constants/MlovConst';
import {
  getDateStrFromFormat,
  getDisplayMonths,
  getMomentObj,
  getMomentObjectWithDateStringAndFormat,
  getMomentObjFromFormat,
  isFutureDate,
} from '../../../../../../../utils/DateUtils';
import {useIntl} from 'react-intl';
import {CommonDataContext} from '../../../../../../../context/CommonDataContext';
import {
  FormContext,
  FormViewType,
  IFormCommonData,
  IFormComponentProps,
} from '../../CustomWrapper/CustomWrapper';
import {
  acceptIgnorePatientReportedRecords,
  deleteResource,
  fhirAddOrUpdate,
  getResourceContentWithHeaders,
} from '../../../../../../../services/CommonService/AidBoxService';
import {
  getConditionName,
  getFHIRTemplate,
  getRecordListFormatted,
  getSampleData,
  isInvalid,
} from './AddOrUpdateConditionsHelper';
import FormComponentLoader from '../../CustomWrapper/FormComponentLoader/FormComponentLoader';
import {IKeyOperation} from '../../../../../../../Interfaces';
import {FHIR_RESOURCE, PatientRecordActions, RESOURCE_BLOCK_MESSAGE_ID} from '../../../../../../../constants/FhirConstant';
import {
  ADD_UPDATE_EVENTS,
  componentKeys,
  FormError,
  getHeaderButtons,
  tooltipInnerOverlayStyle,
  tooltipOverlayStyle,
  tooltipPlacement,
  getSectionNoteDetails,
  getHeaderViewAllButton,
} from '../../CustomWrapper/CustomComponentHelper';
import FormComponentError from '../../CustomWrapper/FormComponentError/FormComponentError';
import {DetailViewCollapse} from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailViewCollapse';
import {v4 as uuidv4} from 'uuid';
import SectionLoadingIndicator from '../../CustomWrapper/SectionLoadingIndicator/SectionLoadingIndicator';
import {Colors} from '../../../../../../../styles';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {capitalize, getStatusElementByStatus} from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailTables/DetailTableUtils';
import {DatePicker, Drawer, Popover, notification} from 'antd';
import CustomDetailTableView, {
  ICustomDetailColumn,
} from '../../CustomWrapper/CustomDetailTableView/CustomDetailTableView';
import {cloneDeep, debounce} from 'lodash';
import {BUTTON_TYPE, DISPLAY_DATE_FORMAT, HTTP_ERROR_CODE} from '../../../../../../../constants/StringConst';
import {NoProblemsSvg} from '../../../../../../common/Svg';
import {EventBus} from '../../../../../../../utils/EventBus';
import ProblemIconSvg from '../../../../../../common/Svg/ProblemIconSvg';
import CompactListViewMoreDetails from '../../CustomWrapper/CompactListView/CompactListViewMoreDetails';
import CustomButton from '../../CustomWrapper/CustomButton/CustomButton';
import { DisplayText } from '../../../../../../common/DisplayText/DisplayText';
import ModalActionBtn from '../../../../../../common/ModalActionBtn/ModalActionBtn';
import PatientReportedTableView from '../../CustomWrapper/PatientReportedTableView/PatientReportedTableView';
import { InputType, getCapability, getOrgAbilites } from '../../../../../../../utils/capabilityUtils';
import { ConditionFields, filterAndSkipDuplicates } from '../../CustomComponentUtils';
import { useContainerDimensions } from '../../../../../../CustomHooks/ContainerDimensionHook';
import Feather from 'react-native-vector-icons/Feather';
import { ComponentType } from '../../Diagnosis/interfaces';
import { ModalActionTitle } from '../../../../../../common/ModalActionTitle/ModalActionTitle';
import { CUSTOM_COMPONENT_STYLE, HISTORICAL_STATUS_CODE } from '../../constants';
import { EHRName } from '../../../../../../PersonOmniView/MiddleContainer/PatientNotes/interfaces';
import {FoldButton} from '../../../../../../CommonComponents/FoldButton/FoldButton';
import DetailPreview, { ICustomComponentPreviewData } from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailPreview/DetailPreview';
import { RightOutlined } from '@ant-design/icons';
import SectionLevelDetails from '../../CustomWrapper/SectionLevelDetails/SectionLevelDetails';
import { IDeleteConfirmation, IFormValidationOutput } from '../../CustomWrapper/interfaces';
import PatientIntakeDeleteModal from '../../../../../../common/PatientIntakeDeleteModal/PatientIntakeDeleteModal';
import { getOrganizationCapabilites } from '../../../../../TeamInbox/Conversations/MessagingContactDetails/ContactDetailsTabs/ActivityTimeLine/ActivityAdditionalInfo/CommonComponent/FormResponse/FormUtils';
import useBulkActionPatientReportedRecords from '../../../../../../CustomHooks/useBulkActionPatientReportedRecords';
import PatientIntakeConfirm from '../../../../../../common/PatientIntakeConfirm/PatientIntakeConfirm';
import RecentReportedViewHeader from '../../../../../../common/RecentReportedViewHeader/RecentReportedViewHeader';
import { getAcceptedAndIgnoredRecordNames } from '../../../../../../common/PatientIntakeConfirm/utils';
import { renderSectionBulletList } from '../../../../../../common/CommonUtils/CommonUtils';
import CustomTextAreaField from '../../TextAreaComponent/CustomTextAreaField';
import { reactStyles } from '../../../../Analytics/style';

const AddOrUpdateConditions = (props: IFormComponentProps) => {
  const intl = useIntl();
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const isPatientForm = contextData.formContext === FormContext.patientForm;
  const isPatientNote = contextData.formContext === FormContext.patientNotes;
  const isPatientProfile = contextData.formContext === FormContext.patientProfile;
  const isRecentReported = contextData.formContext === FormContext.recentReportedView;
  const isCompactView = contextData.componentView === FormViewType.compact;
  const organizationCapability = getOrganizationCapabilites(
    contextData.organizationCapability,
    FHIR_RESOURCE.CLINICAL_DATA
  );
  const componentRef = useRef();
  const drawerDetailRef = useRef();
  const { width } = useContainerDimensions(componentRef);
  const { width: drawerDetailWidth, resetDimension } = useContainerDimensions(drawerDetailRef);
  const isMobileScreen = isCompactView ? drawerDetailWidth <= 480 : width <= 480;
  const isPreviewMode = contextData.isPreviewMode || false;
  const isAllowToShare = props?.component?.allowToShare != undefined &&  props?.component?.allowToShare === false ? false : true;
  // Temporary condition
  const isAthena = contextData.capabilities?.ehrName === EHRName.ATHENA;
  const [componentValue, setComponentValue] =
    useState<IConditionComponentValue>(
      props.component?.selectedValue || {conditions: []}
    );
  const isCanvas = contextData.capabilities?.ehrName === EHRName.CANVAS;
  const [componentState, setComponentState] =
    useState<IAddOrUpdateConditionsState>({
      loading: false,
      formError: contextData.formError,
      collapsed: contextData.defaultCollapse,
      showSavingIndicator: false,
      editModalVisible: false,
      editModalCollapse: false,
      showPatientReportedRecords: false,
      selectedPatientReportedRecord: undefined,
      selectedItemToMap: undefined,
      patientReported: [],
      sectionNote: '',
      activePanels: [],
    });
  const [importFromChartLoading, setImportFromChartLoading] = useState<boolean>(false);
  const [invalidRecordMap, setInvalidRecordMap] = useState(new Map());
  const {componentType} = props;
  const bulkAction = useBulkActionPatientReportedRecords();
  const isCondition = componentType === ComponentType.Condition;
  const getFieldCapabilities = (): IKeyOperation | undefined => {
    return contextData.capabilities?.abilities?.keyAllowedOperations;
  };
  const [deleteConfirmation, setDeleteConfirmation] = useState<IDeleteConfirmation>({
    modalVisible:false,
    selectedEntry: undefined,
  });
  const cancelDeletePrompt = () =>
    setDeleteConfirmation((prev) => ({
      ...prev,
      modalVisible: false,
      selectedEntry: undefined,
    }));

  const { Panel } = Collapse;

  const recordedDateFormat = getFieldCapabilities()?.recodedDateTime?.format;
  const onsetDateFormat = getFieldCapabilities()?.onsetDateTime?.format;

  const validateData = (currentData: IConditionComponentValue): IFormValidationOutput => {
    // If no patient id is not found means it's lead and for lead, the section is not applicable. So validation should always return true
    if (componentState.formError === FormError.noPatientIdFound) {
      return {isValid: true, message: ''};
    }
    const hasMissingData = currentData?.conditions?.some((problem) => {
      return isInvalidRecord(problem);
    });
    return { isValid: !hasMissingData, message: hasMissingData ? `${props.component.label}: Please fill all the mandatory fields` : '' };
  };

  const isInvalidRecord = (condition: ICondition) => {
    const config = getFieldCapabilities();
    return (
      isInvalid(ConditionFields.clinicalStatus, condition, true, config) ||
      isInvalid(ConditionFields.onSetDateTime, condition, true, config) ||
      isInvalid(ConditionFields.conditionType, condition, true, config) ||
      isInvalid(ConditionFields.note, condition, true, config)
    );
  };

  const getConditionStatusList = () => {
    return getFieldCapabilities()?.clinicalStatus?.possibleValues || [];
  };

  const historicalStatus = getConditionStatusList().find(
    item => item.code === HISTORICAL_STATUS_CODE
  )

  const getConditionTypeList = () => {
    return getFieldCapabilities()?.type?.possibleValues || [];
  };

  const getClinicalStatusFromCode = (code: string) => {
    if(!isCondition){
      return {code: 'active', display: 'Active'};
    }
    const list = getConditionStatusList();
    return list.find((status: any) => {
      return status.code === code;
    });
  };

  const canAddNew = (): boolean => {
    // GLOBAL CHECK FROM EHR SETTINGS
    if(isPatientProfile && organizationCapability?.canCreate === false){
      return false
    }
    return (
      contextData.isBuilderMode ||
      ((contextData.capabilities?.abilities?.allowedOperations?.canCreate ||
        false) &&
        !props.disabled)
    );
  };
  const canDelete = (): boolean => {
    // GLOBAL CHECK FROM EHR SETTINGS
    if(isPatientProfile && organizationCapability?.canDelete === false){
      return false
    }
    return (
      contextData.capabilities?.abilities?.allowedOperations?.canDelete || false
    );
  };
  const canUpdate = (): boolean => {
    // GLOBAL CHECK FROM EHR SETTINGS
    if(isPatientProfile && organizationCapability?.canUpdate === false){
      return false
    }
    return (
      (contextData.capabilities?.abilities?.allowedOperations?.canUpdate ||
        false) &&
      !props.disabled &&
      !isPatientForm
    );
  };

  const onDetailChange = (data: ICondition, index: number,isLocalOnly?: boolean) => {
    if(!isLocalOnly){
      onRecordChange(data);
    }
    setComponentValue((prev) => {
      const list = prev.conditions;
      list[index] = data;
      return {
        ...prev,
        conditions: list,
      };
    });
  };
 const removeFromLocal = (uniqueId?: string) => {
   const index = componentValue.conditions.findIndex(
     (item) => item?.uniqueId === uniqueId
   );
   componentValue.conditions.splice(index, 1);
   setComponentValue((prev) => ({
     ...prev,
     conditions: [...componentValue.conditions],
   }));
 };
 const handleShowNoteClick = (id: string) => {
    //find the record with id and toogle showNote flag as true
    const record = componentValue.conditions.find((item) => item.uniqueId === id);
    if (record) {
      record.showNote = !record.showNote;
      setComponentValue((prev) => ({
        ...prev,
        conditions: [...componentValue.conditions],
      }));
    }
 }
 const onDelete = (data: ICondition, index: number, isLocalDelete: boolean) => {
   if (!isLocalDelete && data.id) {
     deleteResource(
       FHIR_RESOURCE.CONDITION,
       `${data.id}`,
       !contextData.hasLoggedInContext,
       contextData.headers,
       {
        patientId: contextData.patientId,
        locationId: contextData.locationId,
        primarySource: false,
        type: data.type || '',
      },
       (response) => {
         if (response?.success) {
          if(isAthena){
            // make status as historical
           data.clinicalStatus = historicalStatus;
           onDetailChange(data,index,true);
          } else {
            removeFromLocal(data?.uniqueId);
          }
          broadcastUpdateEvent();
          cancelDeletePrompt();
         }
       },
       (error) => {

         cancelDeletePrompt();
         setComponentState((prev) => ({
           ...prev,
           loading: false,
           showSavingIndicator: false,
           formError: FormError.existingDataAPIFail,
         }));
       },
     );
   } else {
     removeFromLocal(data?.uniqueId);
     broadcastUpdateEvent();
     cancelDeletePrompt();
   }
 };

 const addNewItem = (item: any) => {
   const isFreeTextItem = item.coding.length === 0;
   const onsetDate = onsetDateFormat
     ? getDateStrFromFormat(new Date(), onsetDateFormat)
     : getMomentObj(new Date()).toISOString();
   const recordedDate = recordedDateFormat
     ? getDateStrFromFormat(new Date(), recordedDateFormat)
     : getMomentObj(new Date()).toISOString();
   const conditionData: ICondition = {
     condition: item,
     name: getConditionName(item),
     clinicalStatus: getClinicalStatusFromCode(
      getFieldCapabilities()?.clinicalStatus?.defaultValue || CONDITION_CLINICAL_STATUS_CODE.ACTIVE
     ),
     onSetDateTime: onsetDate,
     recordedDate,
     uniqueId: uuidv4(),
     isFreeTextRecord: isFreeTextItem,
     showNote: getFieldCapabilities()?.note?.isRequired,
   };
   componentValue.conditions.splice(0, 0, conditionData);
   onRecordChange(conditionData);
   setComponentValue((prev) => ({
     ...prev,
     conditions: [...componentValue.conditions],
   }));
 };

 const onRecordChange = (record: ICondition) => {
   if (contextData.backgroundSaveEnabled) {
     addOrUpdateRecord(record);
   }
 };

 const isFieldDisabled = (item: ICondition) => {
   return props.disabled || (!canUpdate() && !!item.id);
 };

 const isInvalidFieldData = (field: ConditionFields, item: ICondition) => {
   return isInvalid(field, item, true, getFieldCapabilities());
 };

 const isOpenChoice = () => {
   const fields = getFieldCapabilities();
   return fields?.code?.inputType == InputType.openChoice;
 };

 const getSearchComponent = (fullWidth?: boolean): JSX.Element | undefined => {
   return (
     <Pressable
       width={'100%'}
       paddingRight={fullWidth ? 6 : 0}
       onPress={(event) => event.stopPropagation()}
     >
       <View
         flex={1}
         style={!isMobileScreen ? {width: fullWidth ? '100%' : 350} : {}}
       >
         <PAMISearch
           showAdditionalDetails
           enablePaginationOnScroll
           addNewOptionEnabled={isPatientForm || contextData.isBuilderMode || isOpenChoice()}
           clearOnSelection
           searchType={isCondition ? PAMISearchType.problem : PAMISearchType.diagnosis}
           additionalHeaders={contextData.headers}
           placeholder={`Search and add new ${
             isCondition ? 'problem' : 'diagnosis'
           }`}
           isShowError={false}
           onChange={(value) => {
             if (value) {
               addNewItem(value);
             }
           }}
           locationId={contextData.locationId}
         />
       </View>
     </Pressable>
   );
 };

 const getHeaderRightView = (isCompact: boolean): JSX.Element | undefined => {
  if (!canAddNew()) {
    if (isCompact) {
      return getHeaderViewAllButton(() => {
        setComponentState((prev) => ({...prev, editModalVisible: true}));
      }, 'View all');
    } else {
      return;
    }
  }


   if (isCompact) {
     return getHeaderButtons(() => {
       setComponentState((prev) => ({...prev, editModalVisible: true}));
     });
   } else if (!isMobileScreen) {
     return (
       <HStack space={2} alignItems="center">
         <Spacer />
         {componentState.showSavingIndicator && <SectionLoadingIndicator />}
       </HStack>
     );
   }
 };

 const isFieldEnabled = (field: ConditionFields) => {
   if (props.component?.enabledFields) {
     return props.component?.enabledFields[field];
   }
   return IsFieldVisible(field);
 };

 const IsFieldVisible = (field: ConditionFields) => {
   const config = getFieldCapabilities();
   let isDisplayField = false;
   switch (field) {
     case ConditionFields.clinicalStatus:
       if (config && config['clinicalStatus']) {
         isDisplayField = config['clinicalStatus']?.isHidden ? false : true;
       }
       break;
     case ConditionFields.note:
       if (config && config['note']) {
         isDisplayField = config['note']?.isHidden ? false : true;
       }
       break;
     case ConditionFields.onSetDateTime:
       if (config && config['onsetDateTime']) {
         isDisplayField = config['onsetDateTime']?.isHidden ? false : true;
       }
       break;
    case ConditionFields.conditionType:
      if (config && config['type']) {
        isDisplayField = config['type']?.isHidden ? false : true;
      }
      break;
   }
   return isDisplayField;
 };
 const canShowDeleteBtn = (item?: ICondition): boolean => {
  if (isAthena && item?.clinicalStatus?.code === HISTORICAL_STATUS_CODE) {
    return false;
  }
    if(isPatientNote){
      return true;
    }
   if (isPatientForm) {
     return !item?.id;
   }
   // Diagnosis associated with orders cannot be deleted
   if (item?.isAssociatedToOrder) {
    return false;
   }
   return canDelete() || contextData.isBuilderMode;
 };

   const invokeDeleteConfirmation = (item:ICondition,index:number) => {
    setDeleteConfirmation(prev => ({
      ...prev,
      modalVisible: true,
      selectedEntry: {
        item: item,
        index: index
      }
    }))
   }

  const getDeleteFunction = (item: ICondition, index: number) => {
    const isLocalDelete =
      (isPatientForm && !item.id) ||
      contextData.isBuilderMode ||
      !contextData.backgroundSaveEnabled;

    if (isLocalDelete) {
      onDelete(item, index, true);
    } else if (canDelete()) {
      invokeDeleteConfirmation(item,index);
  };
  }

  const getColumns = (): ICustomDetailColumn[] => {
    const fields = getFieldCapabilities();
    const columns: ICustomDetailColumn[] = [
      {
        title: intl.formatMessage({id: 'name'}),
        flex:
          isFieldEnabled(ConditionFields.clinicalStatus)
            ? 2.5
            : 4,
        render: (item: ICondition) => {
          return <Text color={Colors.FoldPixel.GRAY400}>{item.name}</Text>;
        },
      },
    ];
    if (isFieldEnabled(ConditionFields.onSetDateTime)) {
      const isOnlyYearFormat = onsetDateFormat === 'YYYY';
      const label = intl.formatMessage({
        id: isOnlyYearFormat ? 'sinceYear' : 'sinceDate',
      });
      columns.push({
        title: label,
        flex: 2,
        render: (item: ICondition, index: number) => {
          if (item.isFreeTextRecord && fields?.code?.hideFieldsForFreeText) {
            return <Text>{'-'}</Text>;
          }
          const isDisabled = isFieldDisabled(item);
          const isInvalidOnSetDateTime =
            invalidRecordMap.get(item.uniqueId) &&
            getFieldCapabilities()?.onsetDateTime?.isRequired &&
            isInvalidFieldData(ConditionFields.onSetDateTime, item);
          return (
            <DatePicker
              picker={isOnlyYearFormat ? 'year' : 'date'}
              disabled={isDisabled}
              placeholder={label}
              format={isOnlyYearFormat ? onsetDateFormat : DISPLAY_DATE_FORMAT}
              disabledDate={(current: any) => {
                return current && isFutureDate(current);
              }}
              value={
                item.onSetDateTime
                  ? getMomentObjectWithDateStringAndFormat(
                      item.onSetDateTime,
                      onsetDateFormat || ''
                    )
                  : undefined
              }
              style={reactStyles.height40px}
              className={isInvalidOnSetDateTime && !isDisabled ? 'field-error' : ''}
              onChange={(value) => {
                item.onSetDateTime = onsetDateFormat
                  ? value?.format(onsetDateFormat)
                  : value?.toISOString();
                onDetailChange(item, index);
              }}
            />
          );
        },
      });
    }
    if (isFieldEnabled(ConditionFields.clinicalStatus)) {
      const statusList = getConditionStatusList();
      const label = intl.formatMessage({id: 'status'});
      columns.push({
        title: label,
        flex: 2,
        render: (item: ICondition, index: number) => {
          if (item.isFreeTextRecord && fields?.code?.hideFieldsForFreeText) {
            return <Text>{'-'}</Text>;
          }
          const canUpdateStatus = getFieldCapabilities()?.clinicalStatus?.canUpdate || false;
          const statusValue = statusList.find((status: any) => {
            return status.code === item.clinicalStatus?.code;
          })
          if (!canUpdateStatus && isAthena) {
            return <Text>{statusValue?.display || ''}</Text>;
          }
          const isDisabled = isFieldDisabled(item);
          const isInvalidClinicalStatus =
            invalidRecordMap.get(item.uniqueId) &&
            getFieldCapabilities()?.clinicalStatus?.isRequired &&
            isInvalidFieldData(ConditionFields.clinicalStatus, item);
          return (
            <FormControl isInvalid={isInvalidClinicalStatus && !isDisabled}>
              <Select
                placeholder={label}
                selectedValue={item.clinicalStatus?.code}
                isDisabled={isDisabled}
                onValueChange={(value: string) => {
                  item.clinicalStatus = statusList.find((status: any) => {
                    return status.code === value;
                  });
                  onDetailChange(item, index);
                }}
              >
                {statusList.map((option: any, index: number) => {
                  return (
                    <Select.Item
                      key={`${option.id}_${index}`}
                      value={option.code}
                      label={option.display}
                    />
                  );
                })}
              </Select>
            </FormControl>
          );
        },
      });
    }
    if (isFieldEnabled(ConditionFields.conditionType)) {
      const statusList = getConditionTypeList();
      const label = intl.formatMessage({id: 'type'});
      const extensionUrl = fields?.type?.extensionUrl || '';
      columns.push({
        title: label,
        flex: 2,
        render: (item: ICondition, index: number) => {
          if (item.isFreeTextRecord && fields?.code?.hideFieldsForFreeText) {
            return <Text>{'-'}</Text>;
          }
          const isDisabled = isFieldDisabled(item);
          const isInvalidConditionType =
            invalidRecordMap.get(item.uniqueId) &&
            getFieldCapabilities()?.conditionType?.isRequired &&
            isInvalidFieldData(ConditionFields.conditionType, item);
          return (
            <FormControl isInvalid={isInvalidConditionType && !isDisabled}>
              <Select
                placeholder={label}
                selectedValue={item.conditionType?.code}
                isDisabled={isDisabled}
                onValueChange={(value: string) => {
                  item.conditionType = statusList.find((status: any) => {
                    return status.code === value;
                  });
                  if (value && extensionUrl) {
                    let hasExtension = false;
                    item.extension?.forEach((extension) => {
                      if (extension.url === extensionUrl) {
                        hasExtension = true;
                        extension.valueString = value;
                      }
                    })
                    if (!hasExtension) {
                      item.extension?.push({
                        url: extensionUrl,
                        valueString: value,
                      })
                    }
                  }
                  onDetailChange(item, index);
                }}
              >
                {statusList.map((option: any, index: number) => {
                  return (
                    <Select.Item
                      key={`${option.id}_${index}`}
                      value={option.code}
                      label={option.display}
                    />
                  );
                })}
              </Select>
            </FormControl>
          );
        },
      });
    }
    // if (isFieldEnabled(ConditionFields.note)) {
    //   const label = intl.formatMessage({id: 'notes'});
    //   columns.push({
    //     title: label,
    //     flex: 2,
    //     render: (item: ICondition, index: number) => {
    //       if (item.isFreeTextRecord && fields?.code?.hideFieldsForFreeText) {
    //         return <Text>{'-'}</Text>;
    //       }
    //       const isDisabled = isFieldDisabled(item);
    //       const isInvalid = isInvalidFieldData(ConditionFields.note, item);
    //       return (
    //         <FormControl isInvalid={isInvalid && !isDisabled}>
    //           <Input
    //             placeholder={label}
    //             isReadOnly={isDisabled}
    //             defaultValue={item.note}
    //             onChangeText={debounce((text: string) => {
    //               item.note = text;
    //               onDetailChange(item, index);
    //             }, 500)}
    //           />
    //         </FormControl>
    //       );
    //     },
    //   });
    // }
    const actionsAvailable =
      (canUpdate() || canDelete()) &&
      (isFieldEnabled(ConditionFields.note) || canShowDeleteBtn());
    if (!props.disabled && actionsAvailable) {
      columns.push({
        title:  intl.formatMessage({id: 'actions'}),
        flex: 1.5,
        render: (item: ICondition, index: number) => {
          const isDisabled = isFieldDisabled(item);
          const bgColor = isDisabled ? Colors.Custom.Gray50 : Colors.Custom.Base50;
          const accentColor = isDisabled ? Colors.Custom.Gray500 : Colors.Custom.PurpleColor;
          const borderColor = isDisabled ? Colors.Custom.Gray50 : Colors.Custom.ActionBtnBorder;
          return (
            <HStack alignItems="center" justifyContent="flex-end" h="100%">
              <HStack>
                {!item.showNote &&
                  !item.isFreeTextRecord &&
                  isFieldEnabled(ConditionFields.note) && (
                    <Pressable
                      flexDirection="row"
                      alignItems="center"
                      onPress={() => handleShowNoteClick(item.uniqueId || '')}
                      borderWidth={0.3}
                      backgroundColor={bgColor}
                      borderColor={borderColor}
                      px={2}
                      py={1}
                      rounded="2xl"
                      shadow={2}
                      mr={2}
                      isDisabled={isDisabled}
                      cursor={isDisabled ? 'not-allowed' : undefined}
                    >
                      <Feather
                        name="plus"
                        size={14}
                        color={accentColor}
                      />
                      <Text fontWeight="bold" color={accentColor}>
                        Note
                      </Text>
                    </Pressable>
                  )}
              </HStack>
              {isMobileScreen && <Spacer />}
              {canShowDeleteBtn(item) ? (
                <Pressable
                  onPress={() => getDeleteFunction(item, index)}
                  borderWidth={0.3}
                  backgroundColor={Colors.Custom.Base50}
                  borderColor={Colors.Custom.ActionBtnBorder}
                  px={2}
                  py={1}
                  rounded="full"
                  shadow={2}
                >
                  <Feather
                    name={
                      canDelete() || contextData.isBuilderMode ? 'trash-2' : 'x'
                    }
                    size={20}
                    color={Colors.Custom.PurpleColor}
                  />
                </Pressable>
              ) : null}
            </HStack>
          );
        },
      });
    }
    return columns;
  };
  const renderAdditionalView = (item: ICondition, index: number) => {
          const isDisabled = isFieldDisabled(item);
          const isInvalidNote =
            invalidRecordMap.get(item.uniqueId) &&
            getFieldCapabilities()?.note?.isRequired &&
            isInvalidFieldData(ConditionFields.note, item);
          const label = intl.formatMessage({id: 'singleNote'});
          return (
            item.showNote &&
            !item.isFreeTextRecord && (
              <FormControl isInvalid={isInvalidNote && !isDisabled}>
                <HStack justifyContent={'flex-start'} mt={1}>
                  <Text
                    mr={2}
                    size={'smRegular'}
                    color={Colors.FoldPixel.GRAY250}
                    ml={1}
                  >
                    {label}
                  </Text>
                  <CustomTextAreaField
                    disableAutoManageCannedResponsesPosition
                    showError={(isInvalidNote && !isDisabled)}
                    placeholder={label}
                    defaultValue={item.note}
                    macroTemplatesEnabled={!isPatientForm}
                    onValueChange={debounce((text: string) => {
                      item.note = text;
                      onDetailChange(item, index);
                    }, 500)}
                    additionalProps={{
                      totalLines: 2,
                      flex: 1,
                      isReadOnly: isDisabled,
                    }}
                  />
                </HStack>
              </FormControl>
            )
          );
        }

  const getSectionAdditionalView = (isCompactView: boolean) => {
    const fieldCapabilities = getFieldCapabilities();
    if (fieldCapabilities?.sectionNote) {
      return (
        <VStack paddingX={isCompactView ? 4 : 0} paddingTop={isCompactView ? 3 : 1} paddingBottom={isCompactView ? 2 : 0}>
          <SectionLevelDetails
            organizationCapabilites={organizationCapability}
            patientId={contextData.patientId || ''}
            accountLocationId={contextData.locationId}
            fieldCapabilities={fieldCapabilities}
            resource={FHIR_RESOURCE.CONDITION}
            note={componentState.sectionNote || ''}
            onChange={(text) => setComponentState(prev => ({...prev, sectionNote: text}))}
          />
        </VStack>
      );
    }
    return;
  }

  const getDetailView = (isCompact: boolean, isPatientReported: boolean): JSX.Element => {
    if (!componentValue?.conditions?.length && !isCompact && !isPatientReported) {
      return (
        <VStack paddingBottom={5} alignItems={'center'}>
          <NoProblemsSvg titleId="noProblemItems" />
          <HStack space={2} alignItems="center">
            <Spacer />
            {componentState.showSavingIndicator && <SectionLoadingIndicator />}
            {canAddNew() && getSearchComponent()}
          </HStack>
        </VStack>
      );
    }

    if (isCompact) {
      return (
        <>
        <CompactListViewMoreDetails
          hasPatientReportedRecords={componentState.patientReported.length > 0}
          onViewPatientReportedClick={() => {
            setComponentState((prev) => ({...prev, showPatientReportedRecords: true}));
          }}
          noDataTextLabel={'noActiveProblemItems'}
          noDataComponent={<NoProblemsSvg titleId="noActiveProblemItems" />}
          currentStatusValue={'active'}
          showStatusValue={'resolved'}
          viewDataText={'Resolved/Controlled Problems'}
          dataSource={componentValue.conditions}
          onViewMoreClick={() => {
            setComponentState((prev) => ({...prev, editModalVisible: true}));
          }}
          showMoreMaxCount={contextData.showMoreMaxCount}
          rowViewForItem={(item: ICondition) => {
            let displayDate: JSX.Element | undefined;
            let displayStatus: JSX.Element | undefined;
            let displayDateItem = '';

            if (item.onSetDateTime) {
              const date = getMomentObjectWithDateStringAndFormat(
                item.onSetDateTime,
                onsetDateFormat || ''
              );
              /**
               * Difference in Months
               */
              displayDateItem = getDisplayMonths(
                getMomentObjFromFormat(date, DISPLAY_DATE_FORMAT),
                getMomentObjFromFormat(new Date(), DISPLAY_DATE_FORMAT)
              );

              displayDate = (
                <Text size={'smMedium'} color={Colors.Custom['Gray500']}>
                  {displayDateItem}
                </Text>
              );
            }

            if (item.clinicalStatus?.code) {
              displayStatus = getStatusElementByStatus(
                item.clinicalStatus.code
              );
            }
            const typeList = getConditionTypeList();
            let conditionType = '';
            if (item.conditionType?.code) {
              conditionType = typeList.find((type) => type.code == item.conditionType?.code)?.display || '';
            }

            return (
              <VStack key={item.id}>
                <HStack space={1} alignItems="start">
                  <Text color={Colors.FoldPixel.GRAY400}>
                    {!displayDateItem
                      ? `${item.name}`
                      : `${item.name} (${displayDateItem})`}
                  </Text>
                  <Spacer />
                </HStack>
                {!!conditionType && <Text fontSize={12} color={Colors.Custom.Gray500}>{conditionType}</Text>}
              </VStack>
            );
          }}
        />
        {getSectionAdditionalView(true)}
      </>
      );
    } else if (isPatientReported) {
      return (
        <PatientReportedTableView
          dataSource={componentState.patientReported}
          rowViewForItem={(item: ICondition, index: number) => {
            const displayStatus = item.clinicalStatus?.display || item.clinicalStatus?.code;
            let displayDateItem = '';

            if (item.onSetDateTime) {
              const date = getMomentObjectWithDateStringAndFormat(
                item.onSetDateTime,
                onsetDateFormat || ''
              );
              /**
               * Difference in Months
               */
              displayDateItem = getDisplayMonths(
                getMomentObjFromFormat(date, DISPLAY_DATE_FORMAT),
                getMomentObjFromFormat(new Date(), DISPLAY_DATE_FORMAT)
              );
            }

            return (
              <VStack key={item.id}>
                <HStack space={1} alignItems="start">
                  <VStack space={1} flex={1}>
                    <Text color={Colors.Custom['Gray900']} width="full">
                      {!displayDateItem
                        ? `${item.name}`
                        : `${item.name} (${displayDateItem})`}
                    </Text>
                    {displayStatus && (
                      <Text color={Colors.Custom['Gray500']}>
                        {displayStatus}
                      </Text>
                    )}
                  </VStack>
                  {organizationCapability?.canCreate && (
                    <HStack space={2}>
                      {item.isFreeTextRecord && (
                          <Popover
                            key={item.id || index}
                            overlayInnerStyle={{
                              bottom: 3,
                              borderRadius: 16,
                              padding: 0,
                            }}
                            content={
                              <View minWidth={300} maxW={400} padding={2}>
                                <VStack space={8}>
                                  <Text size="xlMedium">Match to Problem</Text>
                                  <VStack space={1}>
                                    <DisplayText
                                      textLocalId={'problem'}
                                      size="smMedium"
                                    />
                                    <PAMISearch
                                      showAdditionalDetails
                                      enablePaginationOnScroll
                                      searchType={PAMISearchType.problem}
                                      additionalHeaders={contextData.headers}
                                      placeholder="Search Problem"
                                      isShowError={false}
                                      onChange={(value) => {
                                        setComponentState((prev) => ({
                                          ...prev,
                                          selectedItemToMap: value,
                                        }));
                                      }}
                                      locationId={contextData.locationId}
                                    />
                                  </VStack>
                                  <HStack>
                                    <Spacer />
                                    <Button.Group space={2}>
                                      <FoldButton
                                        nativeProps={{
                                          variant: BUTTON_TYPE.PRIMARY,
                                          onPress: () => {
                                            setComponentState((prev) => {
                                              const list = prev.patientReported;
                                              const index = list.findIndex(
                                                (value) =>
                                                  value.uniqueId ===
                                                  item.uniqueId
                                              );
                                              if (index > -1) {
                                                list[index].condition =
                                                  componentState.selectedItemToMap;
                                                list[index].name =
                                                  getConditionName(
                                                    componentState.selectedItemToMap
                                                  );
                                                list[index].isFreeTextRecord = false;
                                              }
                                              return {
                                                ...prev,
                                                patientReported: list,
                                                selectedItemToMap: undefined,
                                                selectedPatientReportedRecord:
                                                  undefined,
                                              };
                                            });
                                          },
                                          isDisabled:
                                            !componentState.selectedItemToMap,
                                        }}
                                        customProps={{
                                          btnText: intl.formatMessage({
                                            id: 'continue',
                                          }),
                                          withRightBorder: false,
                                        }}
                                      ></FoldButton>
                                    </Button.Group>
                                  </HStack>
                                </VStack>
                              </View>
                            }
                            title=""
                            trigger="click"
                            visible={
                              componentState.selectedPatientReportedRecord ==
                              item.id
                            }
                            onVisibleChange={(visible) => {
                              setComponentState((prev) => ({
                                ...prev,
                                selectedPatientReportedRecord: visible
                                  ? item.id
                                  : undefined,
                              }));
                            }}
                            placement={'bottom'}
                          >
                            <CustomButton
                              title="Match to Problem"
                              isDisabled={componentState.showSavingIndicator}
                              onPress={() => {
                                setComponentState((prev) => ({
                                  ...prev,
                                  selectedPatientReportedRecord: item.id,
                                }));
                              }}
                            />
                          </Popover>
                        )}
                      <CustomButton
                        title="Accept"
                        isDisabled={componentState.showSavingIndicator}
                        onPress={() => {
                          const isCurrentFreeText =
                            !item.condition?.coding?.length;
                          if (isCurrentFreeText && !isOpenChoice()) {
                            notification.error({
                              message:
                                'This free text problem must be matched to database before it can be added to EHR',
                            });
                          } else {
                            handlePatientReportedActions(
                              PatientRecordActions.accept,
                              item
                            );
                          }
                        }}
                      />
                      <CustomButton
                        title="Discard"
                        isDisabled={componentState.showSavingIndicator}
                        onPress={() => {
                          handlePatientReportedActions(
                            PatientRecordActions.deny,
                            item
                          );
                        }}
                      />
                    </HStack>
                  )}
                </HStack>
              </VStack>
            );
          }}
        />
      );
    }

    return (
      <VStack flex={1} space={4}>
        <VStack space={2}>
          {canAddNew() && !isMobileScreen && (
            <HStack width={'100%'} margin={3} space={2} alignItems="center">
              {getSearchComponent(true)}
            </HStack>
          )}

          <CustomDetailTableView
            dataSource={componentValue.conditions}
            columns={getColumns()}
            isPatientNote={isPatientNote}
            isBuilderMode={contextData.isBuilderMode}
            renderAdditionalView={renderAdditionalView}
          />
        </VStack>
      </VStack>
    );
  };

  const onAddOrUpdateError = (error: any) => {
    const errorMessage =
      error?.response?.status === HTTP_ERROR_CODE.METHOD_NOT_ALLOWED
        ? intl.formatMessage({
            id: RESOURCE_BLOCK_MESSAGE_ID,
          })
        : error?.response?.data?.message;
    setComponentState((prev) => ({
      ...prev,
      showSavingIndicator: false,
      ...(errorMessage && {customError: errorMessage}),
      ...(!errorMessage && {nonBlockingFormError: FormError.addUpdateFail}),
    }));
  }

  const addOrUpdateRecord = (record: ICondition) => {
    if (!isInvalidRecord(record)) {
      setComponentState((prev) => ({...prev, showSavingIndicator: true}));
      const fhirData = getFHIRTemplate({
        ...record,
        patientId: contextData.patientId || '',
        tenantId: contextData.tenantId || '',
        componentType: componentType,
      });
      fhirAddOrUpdate(
        FHIR_RESOURCE.CONDITION,
        record.id,
        fhirData,
        {
          patientId: contextData.patientId,
          locationId: contextData.locationId,
        },
        (response) => {
          broadcastUpdateEvent();
          let keepShowingSavingIndicator = false;
          if (!record.id) {
            keepShowingSavingIndicator = true;
            getExistingData({patientId: contextData.patientId || '', skipLoader: true});
          }
          if (response?.data?.id) {
            updateDataFromAPIToList(response.data, record);
          }
          setComponentState((prev) => ({
            ...prev,
            ...(!keepShowingSavingIndicator && {showSavingIndicator: false}),
            nonBlockingFormError: undefined,
            customError: undefined,
          }));
        },
        (error) => {
          onAddOrUpdateError(error);
          // If new record is failed which is not saved before then only remove from local list
          if (!record.id) {
            removeFromLocal(record.uniqueId);
          }
        }
      );
    }
  };

  const handlePatientReportedActions = (action: PatientRecordActions, record: ICondition) => {
    setComponentState((prev) => ({...prev, showSavingIndicator: true}));
    const fhirData = getFHIRTemplate({
      ...record,
      patientId: contextData.patientId || '',
      tenantId: contextData.tenantId || '',
    });
    acceptIgnorePatientReportedRecords(
      FHIR_RESOURCE.CONDITION,
      action,
      record.id,
      fhirData,
      {
        patientId: contextData.patientId,
        locationId: contextData.locationId,
      },
      (response) => {
        // NOTE: This is done because when we send event from `Recent Clinical Event` the component view is not same as the current view. And is same when send event from PAMI component as well to handle this, we are sending the view type from the event itself in case of PAMI component so that the api call is made.
        const viewType = isRecentReported ? undefined : FormViewType.compact;
        broadcastUpdateEvent(viewType);
        let keepShowingSavingIndicator = false;
        if (response?.data?.id || response?.data?.success) {
          if (action === PatientRecordActions.accept) {
            keepShowingSavingIndicator = true;
            // getExistingData(contextData.patientId || '', true);
            setComponentState((prev) => ({
              ...prev,
              patientReported: prev.patientReported.filter(
                (item) => item.id !== record.id
              ),
              showSavingIndicator: false
            }));
          } else {
            updatePatientReportedDataFromAPIToList(response?.data, record, action);
          }
        }
        setComponentState((prev) => ({
          ...prev,
          ...(!keepShowingSavingIndicator && {showSavingIndicator: false}),
          nonBlockingFormError: undefined,
          customError: undefined,
        }));
      },
      onAddOrUpdateError
    );
  }

  const handleSubmitAllAction = async () => {
    await bulkAction.handleBulkAction({
       resource: FHIR_RESOURCE.CONDITION,
       action: PatientRecordActions.accept,
       getFilteredRecords: () => {
         const structuredRecords = componentState.patientReported.filter(
           (record) => !record.isFreeTextRecord
         );
         const FHIRformatedRecords = structuredRecords.map((record) =>
           getFHIRTemplate({
             ...record,
             patientId: contextData.patientId || '',
             tenantId: contextData.tenantId || '',
           })
         );
         return FHIRformatedRecords;
       },
       patientId: contextData.patientId || '',
       accountLocationUuid: contextData.locationId || '',
       onComplete: (response) => {
        broadcastUpdateEvent();
        if (response?.data?.id || response?.data?.success) {
          getExistingData({patientId: contextData.patientId || '', skipLoader: true});
        }
       },
       onError: onAddOrUpdateError
     });
   };

  const updateDataFromAPIToList = (data: any, record: ICondition) => {
    if (record.id) return;

    setComponentValue((prev) => {
      const list = prev.conditions;
      const index = list.findIndex(
        (value) => value.uniqueId === record.uniqueId
      );
      if (index > -1) {
        list[index].id = data.id;
      }
      return {
        ...prev,
        conditions: list,
      };
    });
  };

  const updatePatientReportedDataFromAPIToList = (data: any, record: ICondition, action: PatientRecordActions) => {
    setComponentState((prev) => {
      const list = prev.patientReported;
      const index = list.findIndex(
        (value) => value.uniqueId === record.uniqueId
      );
      if (index > -1) {
        list.splice(index, 1);
      }
      return {
        ...prev,
        patientReported: list,
        showPatientReportedRecords: list.length > 0
      };
    });
    if (action === PatientRecordActions.accept && data?.id) {
      setComponentValue((prev) => {
        const list = prev.conditions;
        record.id = data.id;
        list.splice(0, 0, record);
        return {
          ...prev,
          conditions: list,
        };
      });
    }
  };

  const getExistingData = (params: {patientId: string, skipLoader?: boolean, manualImport?: boolean}) => {
    const {patientId, skipLoader, manualImport} = params;
    if (!skipLoader) {
      setComponentState((prev) => ({...prev, loading: true}));
    }
    getResourceContentWithHeaders(
      FHIR_RESOURCE.CONDITION,
      `patient=${patientId}&category=${componentType}${isRecentReported ? `&patientReportedOnly=true` : ''}`,
      !contextData.hasLoggedInContext,
      contextData.headers,
      (response) => {
        handleExistingDataResponse(response, getFieldCapabilities(), manualImport);
      },
      (error) => {

        setComponentState((prev) => ({
          ...prev,
          loading: false,
          showSavingIndicator: false,
          formError: FormError.existingDataAPIFail,
        }));
      },
      contextData.locationId,
      !isPatientForm
    );
  };

  const handleExistingDataResponse = (response: any, fields?:IKeyOperation, manualImport?: boolean) => {
    if (isPatientForm) {
      if (response?.entry) {
        const formattedData = getRecordListFormatted(response.entry, fields);
        const sectionNote = getSectionNoteDetails(response.meta?.extension || []).sectionNote;
        const newRecordsAddedInForm = props.component?.selectedValue?.conditions || [];
        setComponentValue((prev) => ({
          ...prev,
          conditions: [...newRecordsAddedInForm, ...formattedData],
        }));

        contextData?.updateFormPrefilledByFoldProgress?.(componentKeys.CONDITIONS, { conditions: [...formattedData] });

        setComponentState((prev) => ({
          ...prev,
          loading: false,
          sectionNote: sectionNote,
          showSavingIndicator: false,
          activePanels: formattedData?.length ? [componentKeys.CONDITIONS] : [],
          formError: !response?.entry
            ? FormError.existingDataAPIFail
            : prev.formError,
        }));
      }
    } else {
      const patientReportedRecords = response?.patientReported?.data?.entry || [];
      const ehrRecords = response?.resource?.data?.entry || [];
      const sectionNote = getSectionNoteDetails(response?.resource?.data?.meta?.extension || []).sectionNote;
      let patientReportedFormattedRecords: ICondition[] = [];
      if (patientReportedRecords || ehrRecords) {
        let ehrFormattedRecords: ICondition[] = [];
        if (patientReportedRecords?.length) {
          patientReportedFormattedRecords = getRecordListFormatted(
            patientReportedRecords,
            fields
          );
        }
        if (ehrRecords?.length) {
          ehrFormattedRecords = getRecordListFormatted(ehrRecords, fields);
          if (manualImport) {
            ehrFormattedRecords = filterAndSkipDuplicates<ICondition>(
              componentValue.conditions,
              ehrFormattedRecords
            );
          };
        }
        setComponentValue((prev) => ({
          ...prev,
          conditions: [...ehrFormattedRecords]
        }));

        contextData?.updateFormPrefilledByFoldProgress?.(componentKeys.CONDITIONS, { conditions: [...ehrFormattedRecords] });
      }
      setImportFromChartLoading(false);
      setComponentState((prev) => ({
        ...prev,
        loading: false,
        sectionNote: sectionNote,
        showSavingIndicator: false,
        ...(!patientReportedFormattedRecords.length && {showPatientReportedRecords: false}),
        patientReported: patientReportedFormattedRecords,
        activePanels: ehrRecords?.length || patientReportedFormattedRecords?.length ? [componentKeys.CONDITIONS] : [],
        formError: !ehrRecords || !patientReportedRecords
          ? FormError.existingDataAPIFail
          : prev.formError,
      }));
    }
  }

  const broadcastUpdateEvent = (viewType?: FormViewType) => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent(
      ADD_UPDATE_EVENTS.PROBLEM,
      viewType || contextData.componentView
    );
  };

  const onEventReceived = useCallback(
    (data) => {
      if (data != contextData.componentView && contextData?.patientId && !isPatientNote) {
        getExistingData({patientId: contextData.patientId, skipLoader: true});
      }
    },
    [contextData]
  );

  const fetchImportData = () => {
    if (isPatientNote && !isPreviewMode && contextData.patientId) {
      setImportFromChartLoading(true);
      getExistingData({patientId: contextData.patientId, skipLoader: true, manualImport: true});
    }
  };


  useEffect(() => {
    if (isRecentReported) {
      contextData?.updateLoadingStatus?.(componentKeys.CONDITIONS, componentState.loading, !!componentState.patientReported.length);
      return;
    }
    const isDataAvailable = !!componentValue.conditions.length || !!componentState.patientReported.length;
    contextData?.updateLoadingStatus?.(componentKeys.CONDITIONS, componentState.loading, isDataAvailable);
  }, [componentState.loading]);

  useEffect(() => {
    if (componentState.editModalVisible) {
      resetDimension();
    }
  }, [componentState.editModalVisible]);

  useEffect(() => {
    if (contextData.isBuilderMode) {
      setComponentValue(getSampleData(componentType));
      return;
    }

    const patientId = contextData?.patientId;
    if (patientId && !isPatientNote && (!props.component?.selectedValue || (isPatientForm && !props.disabled))) {
      getExistingData({patientId});
    }
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(ADD_UPDATE_EVENTS.PROBLEM, onEventReceived);
    return () => {
      eventBus.removeEventListener(onEventReceived);
    };
  }, []);

  useEffect(() => {
    let list = componentValue.conditions;
    if (!canUpdate()) {
      list = componentValue.conditions.filter((item) => {
        return !item.id;
      });
    }
    props.onChange(cloneDeep({conditions: list, total: componentValue.conditions.length}));
  }, [componentValue]);

  props.validateRef.current = validateData;

  const {acceptRecord, ignoredRecords} =
              getAcceptedAndIgnoredRecordNames(componentState.patientReported);

 const renderSubHeader = (isCompactView:boolean):JSX.Element => {
   return isCanvas ? (
     <HStack alignItems={isCompactView ? "start" :"center"} maxWidth='100%' >
      <Box mt={isCompactView ? 1 : 0}>
       <Feather name="info" color={Colors.Custom.Gray500} />
        </Box>
       <Text ml={1} color={Colors.Custom.Gray500} fontSize="xs" w={isCompactView ? 320 : '100%'}>
        Data added under Past Surgical History and Condition section in canvas will show up here
       </Text>
     </HStack>
   ) : (
     <></>
   );
 };
  const isDataInvalid = () => {
    const { conditions } = componentValue;
    const tempInvalidRecordMap = new Map();
    conditions.forEach(record => {
      const isAnyFieldInvalid = isInvalidRecord(record);
      if (isAnyFieldInvalid) {
        tempInvalidRecordMap.set(record.uniqueId, true);
      }
    });
    setInvalidRecordMap(tempInvalidRecordMap);
    return (tempInvalidRecordMap.size > 0);
  }

  const onClose = () => {
    if (isDataInvalid()) {
      notification.destroy();
      notification.error({
        message: intl.formatMessage({
          id: 'mandatoryFieldError',
        }),
        duration: 2.0,
        placement: 'top'
      });
    }
    else {
      setComponentState((prev) => ({
        ...prev,
        editModalVisible: false,
      }));
    }
  }

 if (isPreviewMode) {
   return (
     <>
      {componentState.loading && (
        <View padding={4}>
          <FormComponentLoader />
        </View>
      )}
       {!componentState.loading && !componentState.formError && (
         <>
           {componentValue.conditions.length > 0 && (
            <div className={` ${isAllowToShare ? '' : 'disallow-to-share'}`}>
             <DetailPreview titleLocalId={props.component.label}>
               <FlatList
                 initialNumToRender={componentValue.conditions?.length}
                 data={componentValue.conditions}
                 keyExtractor={(item) => item.id || JSON.stringify(item)}
                 renderItem={({item}) => (
                  <div className="page-break">
                    <Text fontWeight={600}>{item.name}</Text>
                    <div style={reactStyles.displayFlex}>
                         <Text>
                           {item.onSetDateTime &&
                             `${intl.formatMessage({
                               id: onsetDateFormat === 'YYYY' ? 'sinceYear' : 'sinceDate',
                             })}: ${getDateStrFromFormat(
                               item.onSetDateTime,
                               onsetDateFormat || DISPLAY_DATE_FORMAT
                             )}`}
                         </Text>
                         {item.clinicalStatus && item.onSetDateTime && (
                           <Text> &#8226; </Text>
                         )}
                         <Text>
                           {item.clinicalStatus &&
                             `${intl.formatMessage({
                               id: 'status',
                             })}: ${capitalize(item.clinicalStatus.display || item.clinicalStatus.code || '')}`}
                         </Text>
                    </div>
                    {item.showNote && item.note && (
                      <Text>{`Note: ${item.note}`}</Text>
                    )}
                   </div>
                 )}
               />
             </DetailPreview>
             </div>
           )}
         </>
       )}
     </>
   );
 }

  return (
    <View ref={componentRef}>
      {isCompactView ? (
        <VStack background={Colors.Custom.BackgroundColor}>
          <Collapse
            activeKey={componentState.activePanels}
            onChange={(activePanels) => {
              setComponentState((prev) => ({...prev, activePanels: activePanels as string[]}));
            }}
            expandIconPosition="end" expandIcon={({ isActive }) => {
            const tooltipText = isActive ? intl.formatMessage({id: 'collapse'}) : intl.formatMessage({id:'expand'});
            return (
              <Tooltip title={tooltipText} placement={tooltipPlacement} showArrow={false} overlayStyle={tooltipOverlayStyle} overlayInnerStyle={tooltipInnerOverlayStyle}>
                <RightOutlined rotate={isActive ? 90 : 0} />
              </Tooltip>
              )}}
          style={reactStyles.collapseStyle4}>
            <Panel forceRender key={componentKeys.CONDITIONS} header={
              <HStack
                alignItems={'center'}
                marginRight={4}
                // paddingBottom={4}
                justifyContent="space-between"
              >
                <HStack alignItems={'center'} space={2}>
                  <View>
                    <ProblemIconSvg />
                  </View>
                  <VStack>
                    <Text
                      color={Colors.FoldPixel.GRAY300}
                      fontWeight={500}
                      size={'lgMedium'}
                    >
                      {props.component.leftSidebarLabel || props.component.label}
                    </Text>
                    {renderSubHeader(isCompactView)}
                  </VStack>
                </HStack>
                <View>{getHeaderRightView(isCompactView)}</View>
              </HStack>}>
              {componentState.loading && (
                <View padding={4}>
                  <FormComponentLoader />
                </View>
              )}
              {!componentState.loading && componentState.customError && (
                <FormComponentError error={FormError.custom} customError={componentState.customError} />
              )}
              {!componentState.loading && componentState.nonBlockingFormError && (
                <FormComponentError error={componentState.nonBlockingFormError} />
              )}
              {!componentState.loading && componentState.formError && (
                <FormComponentError error={componentState.formError} />
              )}
              {!componentState.loading &&
                !componentState.formError &&
                getDetailView(isCompactView, false)}
              <Divider />
            </Panel>
          </Collapse>
        </VStack>
      ) : (
        <div className='page-break'>
        {isRecentReported ? (
            <RecentReportedViewHeader
              loading={componentState.loading}
              noData={!componentState.patientReported.length}
              title={props.component.label}
              buttonOnPress={() => {
                setComponentState((prev) => ({
                  ...prev,
                  showPatientReportedRecords: true,
                }));
              }}
            />
          ) :
             <DetailViewCollapse
             btnList={[
              {
                icon: importFromChartLoading ? (
                  <Spinner mr={1.5} />
                ) : (
                  <Feather
                    name="download"
                    size={20}
                    color={Colors.Custom.mainPrimaryPurple}
                  />
                ),
                onIconBtnClick: fetchImportData,
                label: intl.formatMessage({id: 'importFromChart'}),
                tooltipLabel: intl.formatMessage({id: 'importFromChartOverrideInfo'}),
                isCustomButton: true,
                hide: !(isPatientNote && !isPreviewMode),
                disabled: importFromChartLoading,
              }
            ]}
             subHeader={renderSubHeader(false)}
             textLocalId={props.component.label}
             toggled={!componentState.collapsed}
             headerRightView={getHeaderRightView(isCompactView)}
             headerStyle={{marginTop: 6}}
             extraTextStyles={
               isPatientNote ? {fontWeight: 'bold', fontSize: 16} : {}
             }
             extraPanelStyles={
               isPatientNote
                 ? {
                     borderColor: '#D0D5DD',
                     ...CUSTOM_COMPONENT_STYLE,
                   }
                 : {}
             }
             onToggled={() => {
               setComponentState((prev) => ({
                 ...prev,
                 collapsed: !prev.collapsed,
               }));
             }}
           >
             <VStack>
               {canAddNew() &&
                 isMobileScreen &&
                 componentValue?.conditions?.length > 0 && (
                   <VStack marginY={4} space={2} flex={1} marginX={2}>
                     {getSearchComponent()}
                     {componentState.showSavingIndicator && (
                       <SectionLoadingIndicator />
                     )}
                   </VStack>
                 )}
               {componentState.loading && (
                 <View padding={4}>
                   <FormComponentLoader />
                 </View>
               )}
               {!componentState.loading && componentState.customError && (
                 <FormComponentError error={FormError.custom} customError={componentState.customError} />
               )}
               {!componentState.loading && componentState.nonBlockingFormError && (
                 <FormComponentError
                   error={componentState.nonBlockingFormError!}
                 />
               )}
               {!componentState.loading && componentState.formError && (
                 <FormComponentError error={componentState.formError!} />
               )}
               {!componentState.loading &&
                 !componentState.formError &&
                 getDetailView(isCompactView, false)}
             </VStack>
           </DetailViewCollapse>
          }

        </div>
      )}
      <Drawer
        title={
          <>
          <ModalActionTitle
            isHeadNotSticky
            title={'Problems'}
            buttonList={[
              {
                onClick: onClose,
                show: true,
                id: 1,
                btnText: intl.formatMessage({
                  id: 'close',
                }),
                size: 'sm',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
              },
            ]}
          />
          {getSectionAdditionalView(false)}
          </>
        }
        destroyOnClose
        placement="right"
        onClose={onClose}
        open={componentState.editModalVisible}
        closable
        width="50%"
      >
        <DetailViewCollapse
          btnList={[]}
          subHeader={renderSubHeader(false)}
          textLocalId={props.component.label}
          toggled={!componentState.editModalCollapse}
          headerRightView={getHeaderRightView(false)}
          onToggled={() => {
            setComponentState((prev) => ({
              ...prev,
              editModalCollapse: !prev.editModalCollapse,
            }));
          }}
        >
          <VStack ref={drawerDetailRef}>
            {canAddNew() &&
              isMobileScreen &&
              componentValue?.conditions?.length > 0 && (
                <VStack marginY={4} space={2} flex={1} marginX={2}>
                  {getSearchComponent()}
                  {componentState.showSavingIndicator && (
                    <SectionLoadingIndicator />
                  )}
                </VStack>
              )}
            {componentState.loading && <FormComponentLoader />}
            {!componentState.loading && componentState.customError && (
              <FormComponentError error={FormError.custom} customError={componentState.customError} />
            )}
            {!componentState.loading && componentState.nonBlockingFormError && (
              <FormComponentError error={componentState.nonBlockingFormError} />
            )}
            {!componentState.loading && componentState.formError && (
              <FormComponentError error={componentState.formError} />
            )}
            {!componentState.loading &&
              !componentState.formError &&
              getDetailView(false, false)}
          </VStack>
        </DetailViewCollapse>
      </Drawer>
      <Drawer
        title={
          <ModalActionTitle
            isHeadNotSticky
            title={'Patient Reported Problems'}
            buttonList={[
              {
                isDisabled: componentState.showSavingIndicator,
                isLoading: componentState.showSavingIndicator,
                onClick: () => {
                  setComponentState((prev) => ({
                    ...prev,
                    showPatientReportedRecords: false,
                    selectedVitalsForBulkActions:
                      componentState.patientReported.map(
                        (item) => item.id || ''
                      ),
                  }));
                },
                show: true,
                id: 1,
                btnText: intl.formatMessage({
                  id: componentState.showSavingIndicator ? 'Saving' : 'close',
                }),
                size: 'sm',
                textColor: componentState.showSavingIndicator
                  ? Colors.primary[800]
                  : Colors.danger[800],
                variant: componentState.showSavingIndicator
                  ? BUTTON_TYPE.TERTIARY
                  : BUTTON_TYPE.SECONDARY,
              },
              {
                isDisabled: componentState.showSavingIndicator || bulkAction.loading,
                isLoading: bulkAction.loading,
                onClick: () => bulkAction.handleConfirmation(true),
                show: organizationCapability?.canCreate && componentState.patientReported.filter(item => !item.isFreeTextRecord).length > 0,
                id: 2,
                btnText: intl.formatMessage({
                  id: 'acceptAll'
                }),
                size: 'sm',
                textColor: componentState.showSavingIndicator || bulkAction.loading
                  ? Colors.primary[800]
                  : Colors.danger[800],
                variant: BUTTON_TYPE.PRIMARY,
              },
            ]}
          />
        }
        destroyOnClose
        placement="right"
        onClose={() => {
          setComponentState((prev) => ({
            ...prev,
            showPatientReportedRecords: false,
          }));
        }}
        open={componentState.showPatientReportedRecords}
        closable
        width="50%"
      >
        <VStack>
          {componentState.loading && <FormComponentLoader />}
          {!componentState.loading && componentState.customError && (
            <FormComponentError error={FormError.custom} customError={componentState.customError} />
          )}
          {!componentState.loading && componentState.nonBlockingFormError && (
            <FormComponentError error={componentState.nonBlockingFormError} />
          )}
          {!componentState.loading && componentState.formError && (
            <FormComponentError error={componentState.formError} />
          )}
          {!componentState.loading &&
            !componentState.formError &&
            getDetailView(false, true)}
        </VStack>
      </Drawer>
      {deleteConfirmation.modalVisible && (
       <PatientIntakeDeleteModal
        isDeleteHistorical={isAthena}
        type={'Problem'}
        visible={deleteConfirmation.modalVisible}
        onCancel={cancelDeletePrompt}
        onDelete={() => {
          onDelete(
            deleteConfirmation.selectedEntry?.item,
            deleteConfirmation.selectedEntry?.index as number,
            false
          );
        }}
        />
      )}
       {bulkAction.confirm && (
        <PatientIntakeConfirm
          acceptCount={acceptRecord.length}
          title='Accept Patient Reported Data'
          onOk={() => handleSubmitAllAction()}
          onCancel={() => bulkAction.handleConfirmation(false)}
          renderRecords={() => {
            return (
              <>
                {ignoredRecords.length > 0 && (
                  <View>
                    <Text mt={2.5}>
                      Following data reported by patient is unstructured and has
                      not been matched to a structured record. These will not be
                      updated in the EHR.
                    </Text>
                    {renderSectionBulletList(
                      `Unmatched records (${ignoredRecords.length} / ${componentState.patientReported.length})`,
                      ignoredRecords,
                      undefined,
                      {
                        backgroundColor: Colors.Custom.Red100,
                        borderColor: Colors.Custom.ErrorColor,
                      }
                    )}
                  </View>
                )}
                {acceptRecord.length > 0 && (
                  <View>
                    <Text mt={2.5}>Following records will be updated in the EHR.</Text>
                    {renderSectionBulletList(
                      `Matched records (${acceptRecord.length} / ${componentState.patientReported.length}) `,
                      acceptRecord
                    )}
                  </View>
                )}
              </>
            );
          }}
        />
      )}
    </View>
  );
};

export default AddOrUpdateConditions;
