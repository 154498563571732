import {useLazyQuery} from '@apollo/client';
import {AutoComplete, Spin, Drawer} from 'antd';
import {debounce} from 'lodash';
import {FormControl, HStack, Pressable, Spinner, View, Text} from 'native-base';
import React, {useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import EmployerQueries from '../../../services/Employer/EmployerQueries';
import {Colors} from '../../../styles';
import {
  getAccountUUID,
  getDrawerWidthByScreens,
} from '../../../utils/commonUtils';
import {EmployerQuickCreate} from '../../RightSideContainer/EmployerManagement/EmployerQuickCreate';
import {DisplayText} from '../DisplayText/DisplayText';
import { getAccountConfigResourceAbilities, getResourceAbilities } from '../../../utils/capabilityUtils';
import { FHIR_RESOURCE } from '../../../constants/FhirConstant';
import { SM_CONFIG } from '../../../theme/FontConfig/TextFontSizeConfig';
const {Option} = AutoComplete;
const EmployerSelectOrAdd = (props: any) => {
  const {customStyle, onChange, selectedId, value, labelText, locationId} = props;
  const accountUuid = getAccountUUID();
  const hintCapability = getAccountConfigResourceAbilities(FHIR_RESOURCE.HINT);
  const openInHint = hintCapability?.openInHint || false;
  const [stateData, setStateData] = useState({
    loading: false,
    value: value,
    dataList: [] as any,
    selectedId: selectedId,
    addEmployee: false,
    hideAddButton: false,
  });
  const [GET_EMPLOYER_BY_SEARCH] = useLazyQuery(
    EmployerQueries.getEmployedBySearch,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );
  const searchContacts = async (searchString: string) => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const response = await GET_EMPLOYER_BY_SEARCH({
      variables: {
        accountUuid: accountUuid,
        searchString: `%${searchString}%`,
        limit: searchString.length ? null : 10,
      },
    }).catch(() => {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    });
    if (response?.data?.employers?.length) {
      const employersrList = response?.data?.employers;
      setStateData((prev) => {
        return {
          ...prev,
          dataList: [...employersrList],
          loading: false,
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
  };

  const getHintConfig = async () => {
    setStateData((prev) => {
      return {
        ...prev,
        hideAddButton: openInHint,
      };
    });
  };

  useEffect(() => {
    searchContacts('');
    getHintConfig();
  }, []);
  const addContact = () => {
    return (
      <Pressable
        onPress={() => {
          // props.onFormCompleteAction(COMMON_ACTION_CODES.EDIT, {});
          setStateData((prev) => {
            return {
              ...prev,
              addEmployee: true,
            };
          });
        }}
      >
        <Text>
          <DisplayText textLocalId={'addEmployer'} />
        </Text>
      </Pressable>
    );
  };
  const {height, width} = Dimensions.get('window');
  const finalWidth = getDrawerWidthByScreens({screenWidth: width, divider: 3});
  const getWidth = () => {
    if (customStyle?.flex) {
      return '100%';
    } else {
      return 300;
    }
  };
  return (
    <View flex={1}>
      <FormControl>
        <HStack>
          <FormControl.Label marginLeft={0} style={{}}>
            <DisplayText
              // label={'seltEmployer'}
              textLocalId={'selectEmployer'}
              extraStyles={{
                color: Colors.FoldPixel.GRAY250,
                ...SM_CONFIG.smRegular
              }}
            />
          </FormControl.Label>
          {stateData.loading && (
            <View>
              <Spinner />
            </View>
          )}
        </HStack>
        <AutoComplete
          size="large"
          style={{
            width: getWidth(),
            fontSize: 12,
            borderRadius: 8,
            borderWidth: 1,
            borderColor: Colors.Custom.Gray200
          }}
          placeholder="Select Employer"
          // value={stateData.value}
          defaultValue={stateData.value}
          allowClear={true}
          notFoundContent={stateData.loading && <Spin size="small" />}
          onSearch={debounce(searchContacts, 500)}
          onSelect={(value: any, options: any) => {
            setStateData((prev) => {
              return {
                ...prev,
                selectedId: options?.key,
                value: value,
              };
            });
            onChange(options?.key);
          }}
          onClear={() => {
            setStateData((prev) => {
              return {
                ...prev,
                selectedId: '',
                value: '',
              };
            });
            onChange(null);
          }}
        // onChange={(value, options) => {
        //   setStateData(prev=> {
        //     return {
        //       ...prev,
        //       selectedId: options?.key,
        //       value: value
        //     }
        //   })
        // }}
        // onSelect={debounce((value: any, options: any) => {
        //   props.onAutoCompleteChange(
        //     COMMON_ACTION_CODES.ITEM_SELECT,
        //     parseInt(options?.key) || ''
        //   );
        // }, 500)}
        >
          {stateData.dataList?.length > 2 ? (
            stateData.dataList.map((option: any, index: any) => {
              return (
                <Option key={option.id} value={option.name}>
                  {option.name}
                </Option>
              );
            })
          ) : (
            <>
              {stateData?.dataList?.map((option: any, index: any) => {
                return (
                  <Option key={option.id} value={option.name}>
                    {option.name}
                  </Option>
                );
              })}
              {!stateData.hideAddButton && <Option>{addContact()}</Option>}
            </>
          )}
        </AutoComplete>
      </FormControl>
      {stateData.addEmployee ? (
        <Drawer
          className="headerBorder"
          width={finalWidth}
          visible={stateData.addEmployee}
          closeIcon={null}
          closable
          //title={onDrawerHeader(openModal?.type)}
          maskClosable={true}
          onClose={() => {
            setStateData((prev) => {
              return {
                ...prev,
                addEmployee: false,
              };
            });
          }}
        >
          <EmployerQuickCreate
            isModalOpen={stateData.addEmployee}
            onModalClose={(shouldRefetch?: boolean) => {
              setStateData((prev) => {
                return {
                  ...prev,
                  addEmployee: false,
                };
              });
            }}
            title="quickCreate"
          />
        </Drawer>
      ) : (
        <></>
      )}
    </View>
  );
};
export default EmployerSelectOrAdd;
