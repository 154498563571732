import {IConversationTypes} from "../RightSideContainer/TeamInbox/Conversations/ConversationChannelNew/ConversationSidebarUtils";

export const taskCountCategory: any = [{
  key: 'count', code: 'count', name: 'count',
}]

export const CONVERSATION_TYPES_ARRAY: IConversationTypes[] = [
  'GROUP_CHAT',
  'EMAIL_CHAT',
  'SMS_CHAT',
  'MISSED_CALL_CHAT',
  'INTERNAL_CHAT',
  'FAX_CHAT'
];

export const CONVERSATION_CONTACT_TYPES_ARRAY: IConversationTypes[] = [
  'GROUP_CHAT',
  'EMAIL_CHAT',
  'SMS_CHAT',
];
