import {SENDER_TYPE} from '../../../constants';
import {CONVERSATION_LOCAL_EVENT_CODES, SUPPORTED_EVENT_CODE} from '../../../constants/WebSocketConst';
import {getConversationUnreadCountAllV2, getPatientContextConversationUnreadCountV2} from '../../RightSideContainer/TeamInbox/Conversations/ConversationChannelNew/ConversationSidebarUtils';

export class MessageUnreadCount {
  private unReadConversationCount: number;
  private eventBus: any;
  private conversationTypesArray: any;
  private conversationUuidsSet: any;
  private userPreferenceContactType: any;
  private userId: any;
  private contactId: any;
  private isSidecarPatientContext: boolean;
  private userUuid: any;
  private updateMessageCountCallback: any;
  private selectedConversationUuid: any;
  constructor(args: {
    eventBus: any;
    conversationTypesArray: any;
    userPreferenceContactType?: any;
    userUuid: string;
    userId: any;
    contactId?: any;
    updateMessageCountCallback: any;
    selectedConversationUuid: any;
  }) {
    this.unReadConversationCount = 0;
    this.conversationTypesArray = args.conversationTypesArray;
    this.conversationUuidsSet = new Set();
    this.userPreferenceContactType = args.userPreferenceContactType;
    this.eventBus = args.eventBus;
    this.userId = args.userId;
    this.contactId = args.contactId;
    this.isSidecarPatientContext = !!args.contactId;
    this.userUuid = args.userUuid;
    this.updateMessageCountCallback = args.updateMessageCountCallback;
    this.selectedConversationUuid = args.selectedConversationUuid
    this.onMsgReceivedListenerFn = this.onMsgReceivedListenerFn.bind(this);
    this.onMsgReadListenerFn = this.onMsgReadListenerFn.bind(this);
    this.onConversationOpened = this.onConversationOpened.bind(this);
    this.onMemberAdded = this.onMemberAdded.bind(this);
    this.onMemberRemove = this.onMemberRemove.bind(this);
    this.onConversationOrGroupCreated = this.onConversationOrGroupCreated.bind(this);
    this.onConversationChatClick = this.onConversationChatClick.bind(this);
  }
  async fetchInitialMessageCount() {
    const response = await getConversationUnreadCountAllV2({
      type: this.conversationTypesArray,
      userPreferenceContactType: {}
    } as any);
    if (response) {
      let totalMessageCount = 0;
      const uuids = new Set();
      const ids = new Set();
      Object.values(response).forEach((chatType: any) => {
        totalMessageCount = totalMessageCount + (chatType?.data?.count || 0);
        chatType.data.conversationIdList.forEach((conversation: any) => {
          uuids.add(conversation.uuid);
          ids.add(conversation.id);
        });
      });
      this.unReadConversationCount = totalMessageCount;

      this.conversationUuidsSet = uuids;
      this.updateMessageCountCallback(this.unReadConversationCount);
    }
  }
  
  async fetchInitialMessageCountForPatientContext() {
    if (this.contactId) {
      const response = await getPatientContextConversationUnreadCountV2({
        type: this.conversationTypesArray,
        contactId: this.contactId
      } as any);
      if (response) {
        let totalMessageCount = 0;
        const uuids = new Set();
        const ids = new Set();
        Object?.values(response)?.forEach((chatType: any) => {
          totalMessageCount = totalMessageCount + (chatType?.data?.count || 0);
          chatType?.data?.conversationIdList?.forEach((conversation: any) => {
            uuids.add(conversation.uuid);
            ids.add(conversation.id);
          });
        });
        this.unReadConversationCount = totalMessageCount;

        this.conversationUuidsSet = uuids;
        this.updateMessageCountCallback(this.unReadConversationCount);
      }
    }
  }

  getSenderType = (data: any) => {
    return data?.sender_type || data?.senderType;
  };

  getSenderId = (data: any) => {
    return data?.sender_id || data?.senderId;
  };

  getMessageCount() {
    return this.unReadConversationCount;
  }

  increment() {
    this.unReadConversationCount = this.unReadConversationCount + 1;
    this.updateMessageCountCallback(this.conversationUuidsSet.size);
  }
  deIncrement() {
    this.unReadConversationCount = this.unReadConversationCount - 1;
    this.updateMessageCountCallback(this.conversationUuidsSet.size);
  }

  onMsgReceivedListenerFn(eventInfo: any) {
    const data = eventInfo?.data || eventInfo;
    const senderType = this.getSenderType(data);
    const senderId = this.getSenderId(data);
    const conversationUuid = data.conversation_uuid || data.conversationUuid;
    if (
      (conversationUuid && senderId == this.userId && senderType == SENDER_TYPE.USER) ||
      conversationUuid === this.selectedConversationUuid || !senderType
    ) {
      return;
    }
    this.conversationUuidsSet.add(conversationUuid);
    this.increment();
  }
  onMsgReadListenerFn(eventInfo: any) {
    const data = eventInfo?.data || eventInfo;
    const conversationUuid = data.conversation_uuid || data.conversationUuid;
    const isSameUserReadMessage = this.userUuid === data?.userUuid;
    if (
      conversationUuid &&
      this.conversationUuidsSet.has(conversationUuid) &&
      isSameUserReadMessage
    ) {
      this.conversationUuidsSet.delete(conversationUuid);

      this.deIncrement();
    }
  }
  onConversationOpened(eventInfo: any) {
    const data = eventInfo?.data || eventInfo;
    if (data.userUuid === this.userUuid) {
      if (this.isSidecarPatientContext) {
        this.fetchInitialMessageCountForPatientContext();
      } else {
        this.fetchInitialMessageCount();
      }
    }
  }
  onMemberAdded(eventInfo: any) {
    const data = eventInfo?.data || eventInfo;
    const conversationUuid = data.conversation_uuid || data.conversationUuid;
    this.conversationUuidsSet.add(conversationUuid);
    this.increment();
  }
  onMemberRemove(eventInfo: any) {
    const data = eventInfo?.data || eventInfo;
    const conversationUuid = data.conversation_uuid || data.conversationUuid;
    this.conversationUuidsSet.delete(conversationUuid);
    this.deIncrement();
  }
  onConversationOrGroupCreated(eventInfo: any) {
    const data = eventInfo?.data || eventInfo;
    const conversationUuid = data.uuid;
    this.conversationUuidsSet.add(conversationUuid);
    this.increment();
  }
  onConversationChatClick(eventInfo: any) {
    const data = eventInfo?.data || eventInfo;
    const conversationUuid = data.uuid;
    if (this.conversationUuidsSet.has(conversationUuid)) {
      this.conversationUuidsSet.delete(conversationUuid);
      this.deIncrement();
    }
  }
  public addEventListener() {
    const eventBus = this.eventBus;
    eventBus.addEventListener(
      SUPPORTED_EVENT_CODE.MESSAGE_CREATED,
      this.onMsgReceivedListenerFn
    );

    eventBus.addEventListener(
      SUPPORTED_EVENT_CODE.MESSAGE_READ,
      this.onMsgReadListenerFn
    );
    eventBus.addEventListener(
      SUPPORTED_EVENT_CODE.CONVERSATION_CREATED,
      this.onConversationOrGroupCreated
    );
    eventBus.addEventListener(
      SUPPORTED_EVENT_CODE.GROUP_CONVERSATION_CREATED,
      this.onConversationOrGroupCreated
    );
    eventBus.addEventListener(
      SUPPORTED_EVENT_CODE.SELECTED_CONVERSATION_READ,
      this.onConversationOpened
    );
    eventBus.addEventListener(
      SUPPORTED_EVENT_CODE.CONVERSATION_OPENED,
      this.onConversationOpened
    );
    eventBus.addEventListener(
      SUPPORTED_EVENT_CODE.CONVERSATION_UNREAD_COUNT,
      this.onConversationOpened
    );

    eventBus.addEventListener(
      SUPPORTED_EVENT_CODE.GROUP_MEMBER_ADDED,
      this.onMemberAdded
    );
    eventBus.addEventListener(
      SUPPORTED_EVENT_CODE.GROUP_MEMBER_REMOVED,
      this.onMemberRemove
    );
    eventBus.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.MESSAGE_READ_FROM_NOTIFICATION,
      this.onConversationChatClick
    );

    return () => {
      eventBus.removeEventListener(this.onConversationOpened);
      eventBus.removeEventListener(this.onConversationOrGroupCreated);
      eventBus.removeEventListener(this.onMsgReceivedListenerFn);
      eventBus.removeEventListener(this.onMsgReadListenerFn);
      eventBus.removeEventListener(this.onMemberAdded);
      eventBus.removeEventListener(this.onMemberRemove);
      eventBus.removeEventListener(this.onConversationChatClick);
    };
  }
}
