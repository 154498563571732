import { useLazyQuery } from '@apollo/client';
import { useContext } from 'react';
import { GET_CARE_PROGRAM_BILLABLE_CODES } from '../../../../../CareManagementBilling/BillingQueries';
import { getMonthRangeFromDate } from '../../../../../../../utils/DateUtils';
import { CommonDataContext } from '../../../../../../../context/CommonDataContext';
import { getMlovIdFromCode, getMlovListFromCategory } from '../../../../../../../utils/mlovUtils';
import { MLOV_CATEGORY } from '../../../../../../../constants';
import { BILLING_ACTIVITY_STATUS } from '../../../../../../../constants/MlovConst';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../../../constants/Configs';

export const useCareProgramBillingCheck = () => {
  const contextData = useContext(CommonDataContext);
  const billableActivityStatusMlov = getMlovListFromCategory(
    contextData.CARE_STUDIO_MLOV, 
    MLOV_CATEGORY.CONTACT_CARE_PROGRAM_BILLABLE_ACTIVITY_STATUS
  ) || [];
  const completedStatusId = getMlovIdFromCode(billableActivityStatusMlov, BILLING_ACTIVITY_STATUS.COMPLETED);

  const [getBillableActivities] = useLazyQuery(GET_CARE_PROGRAM_BILLABLE_CODES, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
  });

  const checkBillingStatus = async (careProgramId: string) => {
    const monthRange = getMonthRangeFromDate();
    const response = await getBillableActivities({
      variables: {
        where: {
          isDeleted: { _eq: false },
          statusId: { _eq: completedStatusId },
          contactCareProgramId: { _eq: careProgramId },
          timestamp: {
            _gte: monthRange.startDate,
            _lte: monthRange.endDate
          }
        }
      }
    });
    
    return (response.data?.contactCareProgramBillableActivities || []).length > 0;
  };

  return { checkBillingStatus };
};