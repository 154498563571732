import React from 'react';
import {Colors} from '../../../../../../../styles/Colors';
import {
  getDateAndTimeOnLocalFormate,
  getDateStrFromFormat,
  getDiffInMonths,
  getMomentObj,
} from '../../../../../../../utils/DateUtils';
import Stack from '../../../../../../common/LayoutComponents/Stack';
import {ICondition} from '../../../../../Forms/FHFormio/CustomComponents/Conditions/interfaces';
import {StyleProp, Text, TextStyle} from 'react-native';
import { DATE_FORMATS } from '../../../../../../../constants';

export const chronicCondtionsView = (params: {
  conditionNametextStyles: StyleProp<TextStyle>;
  monthTextStyles: StyleProp<TextStyle>;
  chronicConditions?: ICondition[];
  careProgramEnrollmentDate?: string | Date;
}) => {
  const {conditionNametextStyles, monthTextStyles, chronicConditions,careProgramEnrollmentDate} = params;
  const careProgramEnrollmentDateAndTime = careProgramEnrollmentDate ? getDateStrFromFormat(
    careProgramEnrollmentDate.toString(),
      DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT
    ) : '';
  return (
    <Stack direction={'row'} style={{justifyContent: 'flex-start', maxWidth: '100%'}}>
      <Stack direction={'column'} style={{maxWidth: '100%'}}>
      {careProgramEnrollmentDate && (
        <Text
          style={{color: Colors.Custom.Gray400, fontSize: 12, marginBottom: 4}}
        >{`Enrolled On: ${careProgramEnrollmentDateAndTime}`}</Text>
      )}
      {chronicConditions && chronicConditions?.length > 0 ? (
        <Stack direction={'column'} style={{maxWidth: '100%'}}>
          {chronicConditions?.map((condition: ICondition, index: number) => {
            const diffInMonth = condition?.onSetDateTime
              ? Math.floor(
                  getDiffInMonths(
                    condition?.onSetDateTime,
                    getMomentObj(new Date())
                  )
                )
              : 0;
            return (
            <Stack direction={'row'} key={index} style={{maxWidth: '100%'}}>
                <Text style={{maxWidth: '100%',color: Colors.FoldPixel.GRAY250}}>
                  {condition?.name}
                  {!!diffInMonth && (
                    <Text style={monthTextStyles}>
                      {`(${diffInMonth}m)`}{' '}
                    </Text>
                  )}
                  {index < chronicConditions?.length - 1 ? ',' : '.'}
                </Text>
              </Stack>
            );
          })}
        </Stack>
      ) : (
        <Text style={{color: Colors.Custom.Gray900}}>{'-'}</Text>
      )}
      </Stack>
    </Stack>
  );
};
