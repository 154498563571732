import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {
  Button,
  HStack,
  Icon,
  View,
  VStack,
  Text,
  Pressable,
  Spacer,
  FormControl,
  Select,
  IconButton,
  TextArea,
  Divider,
  FlatList,
  Spinner,
} from 'native-base';
import { Collapse, Tooltip } from 'antd';
import PAMISearch, {
  PAMISearchType,
} from '../../../../../../common/PAMISearch/PAMISearch';
import {
  IAddOrUpdateMedicationState,
  IMedicationComponentValue,
  IMedicationStatement,
} from '../interfaces';
import {MEDICATION_STATUS_CODE} from '../../../../../../../constants/MlovConst';
import Feather from 'react-native-vector-icons/Feather';
import {useIntl} from 'react-intl';
import {CommonDataContext} from '../../../../../../../context/CommonDataContext';
import {
  FormContext,
  FormViewType,
  IFormCommonData,
  IFormComponentProps,
} from '../../CustomWrapper/CustomWrapper';
import {
  fhirAddOrUpdate,
  getResourceContentWithHeaders,
  acceptIgnorePatientReportedRecords,
  deleteResource,
} from '../../../../../../../services/CommonService/AidBoxService';
import FormComponentLoader from '../../CustomWrapper/FormComponentLoader/FormComponentLoader';
import {
  getRecordListFormatted,
  getFHIRTemplate,
  getMedicationName,
  isInvalid,
  getSampleData,
} from './AddOrUpdateMedicationsHelper';
import {IKeyOperation, PossibleValue} from '../../../../../../../Interfaces';
import {FHIR_RESOURCE, PatientRecordActions, RESOURCE_BLOCK_MESSAGE_ID} from '../../../../../../../constants/FhirConstant';
import FormComponentError from '../../CustomWrapper/FormComponentError/FormComponentError';
import {
  ADD_UPDATE_EVENTS,
  componentKeys,
  FormError,
  getHeaderButtons,
  getHeaderViewAllButton,
  tooltipInnerOverlayStyle,
  tooltipOverlayStyle,
  tooltipPlacement,
  getSectionNoteDetails,
} from '../../CustomWrapper/CustomComponentHelper';
import {v4 as uuidv4} from 'uuid';
import {Colors} from '../../../../../../../styles';
import SectionLoadingIndicator from '../../CustomWrapper/SectionLoadingIndicator/SectionLoadingIndicator';
import CustomDetailTableView, {
  ICustomDetailColumn,
} from '../../CustomWrapper/CustomDetailTableView/CustomDetailTableView';
import FHIRPractitionerSearch from '../../../../../../common/FHIRPractitionerSearch/FHIRPractitionerSearch';
import {DatePicker, Drawer, Popover, notification} from 'antd';
import {
  getDateStrFromFormat,
  getDisplayMonths,
  getMomentObjectWithDateStringAndFormat,
  getMomentObjFromFormat,
  isAfter,
  isBeforeDate,
  isBeforeOrSameDate,
  isFutureDate,
} from '../../../../../../../utils/DateUtils';
import {getStatusElementByStatus} from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailTables/DetailTableUtils';
import {DetailViewCollapse} from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailViewCollapse';
import {BUTTON_TYPE, DATE_FORMATS, DISPLAY_DATE_FORMAT, HTTP_ERROR_CODE} from '../../../../../../../constants';
import {NoMedicationsSvg} from '../../../../../../common/Svg';
import {EventBus} from '../../../../../../../utils/EventBus';
import MedicationIconSvg from '../../../../../../common/Svg/MedicationIconSvg';
import CompactListViewMoreDetails from '../../CustomWrapper/CompactListView/CompactListViewMoreDetails';
import CustomButton from '../../CustomWrapper/CustomButton/CustomButton';
import { DisplayText } from '../../../../../../common/DisplayText/DisplayText';
import PatientReportedTableView from '../../CustomWrapper/PatientReportedTableView/PatientReportedTableView';
import { InputType } from '../../../../../../../utils/capabilityUtils';
import { ATHENA_STATUS, MedicationFields, filterAndSkipDuplicates } from '../../CustomComponentUtils';
import { useContainerDimensions } from '../../../../../../CustomHooks/ContainerDimensionHook';
import { cloneDeep, debounce } from 'lodash';
import { ModalActionTitle } from '../../../../../../common/ModalActionTitle/ModalActionTitle';
import { ACTIVE_STATUS_CODE, CUSTOM_COMPONENT_STYLE, HISTORICAL_STATUS_CODE } from '../../constants';
import { SUPPORTED_EVENT_CODE } from '../../../../../../../constants/WebSocketConst';
import {FoldButton} from '../../../../../../CommonComponents/FoldButton/FoldButton';
import { getMedicationRequestByPatientId, getMedicationStatementAndPatientReportedOrder } from '../../../../../../../services/CommonService/OrderService';
import DetailPreview, { ICustomComponentPreviewData } from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailPreview/DetailPreview';
import { EHRName } from '../../../../../../PersonOmniView/MiddleContainer/PatientNotes/interfaces';
import { RightOutlined } from '@ant-design/icons';
import { Extension } from 'fhir/r4';
import SectionLevelDetails from '../../CustomWrapper/SectionLevelDetails/SectionLevelDetails';
import { IDeleteConfirmation, IFormValidationOutput } from '../../CustomWrapper/interfaces';
import PatientIntakeDeleteModal from '../../../../../../common/PatientIntakeDeleteModal/PatientIntakeDeleteModal';
import { getOrganizationCapabilites } from '../../../../../TeamInbox/Conversations/MessagingContactDetails/ContactDetailsTabs/ActivityTimeLine/ActivityAdditionalInfo/CommonComponent/FormResponse/FormUtils';
import useBulkActionPatientReportedRecords from '../../../../../../CustomHooks/useBulkActionPatientReportedRecords';
import PatientIntakeConfirm from '../../../../../../common/PatientIntakeConfirm/PatientIntakeConfirm';
import RecentReportedViewHeader from '../../../../../../common/RecentReportedViewHeader/RecentReportedViewHeader';
import { getAcceptedAndIgnoredRecordNames } from '../../../../../../common/PatientIntakeConfirm/utils';
import { renderSectionBulletList } from '../../../../../../common/CommonUtils/CommonUtils';
import usePractitioners from '../../../../../../common/FHIRPractitionerSearch/usePractitioners';
import AddOrUpdateCarePlanMedications from '../../CareplanMedications/AddorUpdateCarePlanMedications';
import CustomTextAreaField from '../../TextAreaComponent/CustomTextAreaField';
import { reactStyles } from '../../../../Analytics/style';

const AddOrUpdateMedications = (props: IFormComponentProps) => {
  const intl = useIntl();
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const isPatientForm = contextData.formContext === FormContext.patientForm;
  const isPatientNote = contextData.formContext === FormContext.patientNotes;
  const isCompactView = contextData.componentView === FormViewType.compact;
  const isRecentReported = contextData.formContext === FormContext.recentReportedView;
  const isPatientCarePlan = contextData.formContext === FormContext.patientCarePlan;

  const isMedOrderEnabled = contextData.ordering?.isMedEnabled || false;
  const isPreviewMode = contextData.isPreviewMode || false;
  const isAllowToShare = props?.component?.allowToShare != undefined &&  props?.component?.allowToShare === false ? false : true;
  const componentRef = useRef();
  const drawerDetailRef = useRef();
  const { width } = useContainerDimensions(componentRef);
  const { width: drawerDetailWidth, resetDimension } = useContainerDimensions(drawerDetailRef);
  const isMobileScreen = isCompactView ? drawerDetailWidth <= 480 : width <= 480;
  const isSmallerDesktopScreen = isCompactView ? drawerDetailWidth <= 620 : width <= 620;
  const isNotesScreenWidth = isPatientNote &&  width <= 680

  const sigAlternateText = 'Dosage';
  // Temporary condition
  const isAthena = contextData.capabilities?.ehrName === EHRName.ATHENA;
  const isCerner = contextData.capabilities?.ehrName === EHRName.CERNER;
  const [componentValue, setComponentValue] =
    useState<IMedicationComponentValue>(
      props.component?.selectedValue || {medicationStatements: []}
    );
    const organizationCapability = getOrganizationCapabilites(
      contextData.organizationCapability,
      FHIR_RESOURCE.CLINICAL_DATA
      );
  const bulkAction = useBulkActionPatientReportedRecords();
  const [error, setError] = useState('')
  const isPatientProfile = contextData.formContext === FormContext.patientProfile;
  const [componentState, setComponentState] =
    useState<IAddOrUpdateMedicationState>({
      loading: false,
      formError: contextData.formError,
      collapsed: contextData.defaultCollapse,
      showSavingIndicator: false,
      editModalVisible: false,
      editModalCollapse: false,
      showPatientReportedRecords: false,
      selectedPatientReportedRecord: undefined,
      selectedItemToMap: undefined,
      patientReported: [],
      showInActiveMedication: false,
      sectionNote: '',
      activePanels: [],
    });
  const [importFromChartLoading, setImportFromChartLoading] = useState<boolean>(false);
  const [deleteConfirmation, setDeleteConfirmation] =                         useState<IDeleteConfirmation>({
    modalVisible:false,
    selectedEntry: undefined,
  });
  const cancelDeletePrompt = () =>
    setDeleteConfirmation((prev) => ({
      ...prev,
      modalVisible: false,
      selectedEntry: undefined,
    }));


  const getFieldCapabilities = (): IKeyOperation | undefined => {
    return contextData.capabilities?.abilities?.keyAllowedOperations;
  };
  const getFieldObject = getFieldCapabilities();
  const { Panel } = Collapse;

  const dateFormat = getFieldCapabilities()?.effectivePeriod?.format;

  const getFieldObjectForHistoricStatus = (matchingObject: IMedicationStatement | undefined) => {
    const fieldObject = { ...getFieldObject };
    if (matchingObject?.status?.code === HISTORICAL_STATUS_CODE) {
      fieldObject.stopDate.isRequired = true;
    }
    else {
      fieldObject.stopDate.isRequired = false;
    }
    return fieldObject;
  };

  const validateData = (currentData: IMedicationComponentValue): IFormValidationOutput => {
    // If no patient id is not found means it's lead and for lead, the section is not applicable. So validation should always return true
    if (componentState.formError === FormError.noPatientIdFound) {
      return {isValid: true, message: ''};
    }
    const hasMissingData = currentData?.medicationStatements?.some((item) => {
      return isInvalidRecord(item);
    });
    return { isValid: !hasMissingData, message: hasMissingData ? `${props.component.label}: Please fill all the mandatory fields` : '' };
  };

  const isInvalidRecord = (item: IMedicationStatement) => {
    const config = getFieldCapabilities();
    const { medicationStatements } = componentValue;
    const matchingObject = medicationStatements.find(statement => statement.id === item.id);
    const fieldObjectForHistoric = isAthena ? getFieldObjectForHistoricStatus(matchingObject) : undefined
    return (
      isInvalid(MedicationFields.status, item, true, config, isMedOrderEnabled) ||
      isInvalid(MedicationFields.effectivePeriod, item, true, config, isMedOrderEnabled) ||
      isInvalid(MedicationFields.informationSource, item, true, config, isMedOrderEnabled) ||
      isInvalid(MedicationFields.statusReason, item, true, config, isMedOrderEnabled) ||
      isInvalid(MedicationFields.stopDate, item, true, fieldObjectForHistoric || config, isMedOrderEnabled) ||
      isInvalid(MedicationFields.note, item, true, config, isMedOrderEnabled)
    );
  };

  const getStatusList = (): PossibleValue[] => {
    return getFieldCapabilities()?.status?.possibleValues || [];
  };

  const historicalStatus = getStatusList().find(
    item => item.code === HISTORICAL_STATUS_CODE
  )

  const getStatusFromCode = (code: string) => {
    const list = getStatusList();
    return list.find((status: any) => {
      return status.code === code;
    });
  };

  const canAddNew = (): boolean => {
     // GLOBAL CHECK FROM EHR SETTINGS
     if(isPatientProfile && organizationCapability?.canCreate === false){
      return false
    }
    return (
      contextData.isBuilderMode ||
      ((contextData.capabilities?.abilities?.allowedOperations?.canCreate ||
        false) &&
        !props.disabled)
    );
  };
  const canDelete = (): boolean => {
     // GLOBAL CHECK FROM EHR SETTINGS
     if(isPatientProfile && organizationCapability?.canDelete === false){
      return false
    }
    return (
      contextData.capabilities?.abilities?.allowedOperations?.canDelete || false
    );
  };
  const canUpdate = (): boolean => {
    // GLOBAL CHECK FROM EHR SETTINGS
    if(isPatientProfile && organizationCapability?.canUpdate === false){
        return false
      }
    return (
      (contextData.capabilities?.abilities?.allowedOperations?.canUpdate ||
        false) &&
      !props.disabled &&
      !isPatientForm
    );
  };

  const onDetailChange = (data: IMedicationStatement, index: number, isLocalOnly?: boolean) => {
    if(!isLocalOnly){
      onRecordChange(data);
    }
    setComponentValue((prev) => {
      const list = prev.medicationStatements;
      list[index] = data;
      return {
        ...prev,
        medicationStatements: list,
      };
    });
  };
  const removeFromLocal = (uniqueId?:string) => {
    const index = componentValue.medicationStatements.findIndex(
      (item) => item?.uniqueId === uniqueId
    );
    componentValue.medicationStatements.splice(index, 1);
    setComponentValue((prev) => ({
      ...prev,
      medicationStatements: [...componentValue.medicationStatements],
    }));
  }
  const onDelete = (data: IMedicationStatement, index: number,isLocalDelete: boolean) => {
    if (!isLocalDelete && data.id) {
      deleteResource(
        FHIR_RESOURCE.MEDICATION_STATEMENT,
        `${data.id}`,
        !contextData.hasLoggedInContext,
        contextData.headers,
        {
          patientId: contextData.patientId,
          locationId: contextData.locationId,
        },
        (response) => {
          if (response?.success) {
            if(isAthena){         
              getExistingData({patientId: contextData.patientId || ''});            
            } else {
              removeFromLocal(data?.uniqueId);
            }
            broadcastUpdateEvent();
            cancelDeletePrompt();
          }
        },
        (error) => {

          cancelDeletePrompt();
          setComponentState((prev) => ({
            ...prev,
            loading: false,
            showSavingIndicator: false,
            formError: FormError.existingDataAPIFail,
          }));
        },
      );
    } else {
     removeFromLocal(data?.uniqueId);
    }
  };

  const addNewItem = (item: any) => {
    const data: IMedicationStatement = {
      medicationStatement: item,
      name: getMedicationName(item),
      status: getStatusFromCode(getFieldCapabilities()?.status?.defaultValue || MEDICATION_STATUS_CODE.ACTIVE),
      uniqueId: uuidv4(),
      isFreeTextRecord: false,
      showNote: getFieldCapabilities()?.note?.isRequired || false,
    };
    componentValue.medicationStatements.splice(0, 0, data);
    setComponentValue(prev => {
      return {
        ...prev,
        medicationStatements: [...componentValue.medicationStatements]
      }
    });
    onRecordChange(data);
  };

  const onRecordChange = (record: IMedicationStatement) => {
    const isUpdateOperation = !!record.id;
    const shouldAllowAddUpdate = isUpdateOperation ? canUpdate() : canAddNew();
    if (contextData.backgroundSaveEnabled && shouldAllowAddUpdate) {
      addOrUpdateRecord(record);
    }
  };

  const isFieldDisabled = (item: IMedicationStatement) => {
    return (
      props.disabled ||
      (!canUpdate() && !!item.id) ||
      (!canUpdate() && !canAddNew())
    );
  };

  const isInvalidFieldData = (
    field: MedicationFields,
    item: IMedicationStatement,
    fieldObjectForHistoricStatus?: IKeyOperation | undefined
  ) => {
    return isInvalid(field, item, true, fieldObjectForHistoricStatus || getFieldCapabilities(), isMedOrderEnabled);
  };

  const isOpenChoice = () => {
    const fields = getFieldCapabilities();
    return fields?.medicationCodeableConcept?.inputType == InputType.openChoice;
  }

  const getSearchComponent = (fullWidth?: boolean): JSX.Element | undefined => {
    return (
      <Pressable
        width={'100%'}
        paddingRight={fullWidth ? 6 : 0}
        onPress={(event) => event.stopPropagation()}
      >
        <View
          flex={1}
          style={!isMobileScreen ? {width: fullWidth ? '100%' : 350} : {}}
        >
          <PAMISearch
            addNewOptionEnabled={isPatientForm || contextData.isBuilderMode || isOpenChoice()}
            clearOnSelection
            searchType={PAMISearchType.medication}
            additionalHeaders={contextData.headers}
            placeholder="Search and add new medication"
            isShowError={false}
            onChange={(value) => {
              if (value) {
                addNewItem(value);
              }
            }}
            locationId={contextData.locationId}
          />
        </View>
      </Pressable>
    );
  };

  const getHeaderRightView = (isCompact: boolean): JSX.Element | undefined => {
    if (!canAddNew()) {
      if (isCompact) {
        return getHeaderViewAllButton(() => {
          setComponentState((prev) => ({...prev, editModalVisible: true, showInActiveMedication: false}));
        }, 'View all medications');
      } else {
        return;
      }
    }

    if (isCompact) {
      return getHeaderButtons(() => {
        setComponentState((prev) => ({...prev, editModalVisible: true}));
      });
    } else if (!isMobileScreen) {
      return (
        <HStack space={2} alignItems="center">
          <Spacer />
          {componentState.showSavingIndicator && <SectionLoadingIndicator />}
        </HStack>
      );
    }
  };

  const isFieldEnabled = (field: MedicationFields) => {
    if (props.component?.enabledFields) {
      return props.component?.enabledFields[field];
    }
    return IsFieldVisible(field);
  };

  const IsFieldVisible = (field: MedicationFields) => {
    const config = getFieldCapabilities();
    let isDisplayField = false;
    switch(field) {
      case MedicationFields.sig:
        if (config && config['sig']) {
          isDisplayField = config['sig']?.isHidden ? false : true;
        }
        break;
      case MedicationFields.medicationCodeableConcept:
        if (config && config['medicationCodeableConcept']) {
          isDisplayField = config['medicationCodeableConcept']?.isHidden ? false : true;
        }
        break;
      case MedicationFields.status:
        if (config && config['status']) {
          isDisplayField = config['status']?.isHidden ? false : true;
        }
        break;
      case MedicationFields.effectivePeriod:
        if (config && config['effectivePeriod']) {
          isDisplayField = config['effectivePeriod']?.isHidden ? false : true;
        }
        break;
      case MedicationFields.informationSource:
        if (config && config['informationSource']) {
          isDisplayField = config['informationSource']?.isHidden ? false : true;
        }
        break;
      case MedicationFields.discontinuedDate:
        if (config && config['discontinuedDate']) {
          isDisplayField = config['discontinuedDate']?.isHidden ? false : true;
        }
        break;
      case MedicationFields.stopDate:
        if (config && config['stopDate']) {
          isDisplayField = config['stopDate']?.isHidden ? false : true;
        }
        break;
      case MedicationFields.statusReason:
        if (config && config['statusReason']) {
          isDisplayField = config['statusReason']?.isHidden ? false : true;
        }
        break;
      case MedicationFields.note:
        if (config && config['note']) {
          isDisplayField = config['note']?.isHidden ? false : true;
        }
        break;
    }
    return isDisplayField;

  }

  const isPractitionerEnabled = !!isFieldEnabled(MedicationFields.informationSource);
  const practitionerData = usePractitioners({
    skip: !isPractitionerEnabled || !!isPreviewMode,
    hasLoggedInContext: contextData.hasLoggedInContext,
    additionalHeaders: contextData.headers,
    locationId: contextData?.locationId
  });

  const canShowDeleteBtn = (item?: IMedicationStatement): boolean => {
    if (isAthena && item?.status?.code === HISTORICAL_STATUS_CODE) {
      return false;
    }
    if(isPatientNote){
      return true;
    }
    if (isPatientForm) {
      return !item?.id;
    }
    return canDelete() || contextData.isBuilderMode;
  };
  const getDeleteFunction = (item: IMedicationStatement, index: number) => {
    const isLocalDelete =
      (isPatientForm && !item.id) ||
      contextData.isBuilderMode ||
      !contextData.backgroundSaveEnabled;

    if (isLocalDelete) {
      onDelete(item, index, true);
    } else if (canDelete()) {
      invokeDeleteConfirmation(item, index);
    }
  };

  const invokeDeleteConfirmation = (item:IMedicationStatement,index:number) => {
    setDeleteConfirmation(prev => ({
      ...prev,
      modalVisible: true,
      selectedEntry: {
        item: item,
        index: index
      }
    }))
   }

  const handleShowNoteClick = (id: string) => {
    //find the record with id and toogle showNote flag as true
    const record = componentValue.medicationStatements.find((item) => item.uniqueId === id);
    if (record) {
      record.showNote = !record.showNote;
      setComponentValue((prev) => ({
        ...prev,
        medicationStatements: [...componentValue.medicationStatements],
      }));
    }
 }

  const getColumns = (): ICustomDetailColumn[] => {
    const fields = getFieldCapabilities();
    const columns: ICustomDetailColumn[] = [
      {
        title: intl.formatMessage({id: 'name'}),
        flex: isAthena ? 2 : 2.5,
        render: (item: IMedicationStatement) => {
          return <VStack>
            <Text color={Colors.FoldPixel.GRAY400}>{item.name}</Text>
          </VStack>;
        },
      },
    ];
    if (isFieldEnabled(MedicationFields.status)) {
      const statusList = getStatusList();
      columns.push({
        title: intl.formatMessage({id: 'status'}),
        flex: 1,
        render: (item: IMedicationStatement, index: number) => {
          const isReadOnly = item.isReadOnly || false;
          const isDisabled = isFieldDisabled(item);
          const isInvalid = isInvalidFieldData(MedicationFields.status, item);
          const canUpdateStatus = (getFieldCapabilities()?.status?.canUpdate || false);
          const statusValue = item.status?.code === ATHENA_STATUS.HISTORICAL ? 'Historical' : 'Active';
          if ((!canUpdateStatus && isAthena) || isReadOnly) {
            return <Text>{statusValue || ''}</Text>;
          }
          return (
            <FormControl isInvalid={isInvalid && !isDisabled}>
              <Select
                placeholder={intl.formatMessage({id: 'status'})}
                selectedValue={item.status?.code}
                isDisabled={isDisabled}
                onValueChange={(value: string) => {
                  item.status = statusList.find((status: any) => {
                    return status.code === value;
                  });
                  item.reason = undefined;
                  onDetailChange(item, index);
                }}
              >
                {statusList.map((option: any, index: number) => {
                  return (
                    <Select.Item
                      key={`${option.id}_${index}`}
                      value={option.code}
                      label={option.display}
                    />
                  );
                })}
              </Select>
            </FormControl>
          );
        },
      });
    }
    if (isFieldEnabled(MedicationFields.informationSource)) {
      columns.push({
        title: intl.formatMessage({id: 'practitioner'}),
        flex: 2,
        render: (item: IMedicationStatement, index: number) => {
          const isReadOnly = item.isReadOnly || false;
          const isDisabled = isFieldDisabled(item) || isReadOnly;
          const isInvalid = isInvalidFieldData(
            MedicationFields.informationSource,
            item
          );
          return (
            <FHIRPractitionerSearch
              value={item.informationSource}
              data={practitionerData?.data}
              loading={practitionerData?.loading}
              isShowError={isInvalid && !isDisabled}
              disabled={isDisabled}
              onChange={(value) => {
                item.informationSource = value;
                onDetailChange(item, index);
              }}
            />
          );
        },
      });
    }
    if (isFieldEnabled(MedicationFields.effectivePeriod)) {
      const label = intl.formatMessage({id: 'effectiveDate'});
      columns.push({
        title: label,
        flex: 1.8,
        render: (item: IMedicationStatement, index: number) => {
          const isReadOnly = item.isReadOnly || false;
          const isDisabled = isFieldDisabled(item);
          const stopDate = item.stopDate ? getDateStrFromFormat(item.stopDate, DATE_FORMATS.API_DATE_FORMAT) : ''
          const isInvalid = isInvalidFieldData(
            MedicationFields.effectivePeriod,
            item
          );
          if (isReadOnly) {
            const date = item.effectivePeriod ? getMomentObjectWithDateStringAndFormat(
              item.effectivePeriod,
              dateFormat || ''
            ).format(DISPLAY_DATE_FORMAT) : '';
            return <Text>{date || '-'}</Text>;
          }
          return (
            <DatePicker
              disabled={isDisabled}
              placeholder={label}
              format={DISPLAY_DATE_FORMAT}
              disabledDate={(current: any) => {
                if (isAthena && stopDate && item.status?.code === HISTORICAL_STATUS_CODE) {
                  return current && isAfter(current, stopDate) || isFutureDate(current)
                } else if (isAthena && item.status?.code === ACTIVE_STATUS_CODE) {
                  return current && isAfter(current, stopDate)
                } else {
                  return current && isFutureDate(current)
                }
              }}
              value={
                item.effectivePeriod
                  ? getMomentObjectWithDateStringAndFormat(
                      item.effectivePeriod,
                      dateFormat || ''
                    )
                  : undefined
              }
              style={reactStyles.height40px}
              className={isInvalid && !isDisabled ? 'field-error' : ''}
              onChange={(value) => {
                item.effectivePeriod = dateFormat
                  ? value?.format(dateFormat)
                  : value?.toISOString();
                onDetailChange(item, index);
              }}
            />
          );
        },
      });
    }
    if (isFieldEnabled(MedicationFields.discontinuedDate)) {
      const label = intl.formatMessage({id: 'discontinuedDate'});
      columns.push({
        title: label,
        flex: 1.5,
        render: (item: IMedicationStatement, index: number) => {
          const isReadOnly = item.isReadOnly || false;
          const isDisabled = true;
          const isInvalid = false;
          if (isReadOnly) {
            const date = item.discontinuedDate ? getMomentObjectWithDateStringAndFormat(
              item.discontinuedDate,
              dateFormat || ''
            ).format(DISPLAY_DATE_FORMAT) : '';
            return <Text>{date || '-'}</Text>;
          }
          return (
            <DatePicker
              disabled={isDisabled}
              placeholder={label}
              format={DISPLAY_DATE_FORMAT}
              disabledDate={(current: any) => {
                return current && isFutureDate(current);
              }}
              value={
                item.discontinuedDate
                  ? getMomentObjectWithDateStringAndFormat(
                      item.discontinuedDate,
                      dateFormat || ''
                    )
                  : undefined
              }
              style={reactStyles.height40px}
              className={isInvalid && !isDisabled ? 'field-error' : ''}
            />
          );
        },
      });
    }
    if (isFieldEnabled(MedicationFields.stopDate)) {
      const stopDateFormat = getFieldCapabilities()?.stopDate?.format || dateFormat;
      const label = intl.formatMessage({id: 'stopDate'});
      columns.push({
        title: label,
        flex: 1.5,
        render: (item: IMedicationStatement, index: number) => {
          const isReadOnly = item.isReadOnly || false;
          const isDisabled = isFieldDisabled(item);
          const { medicationStatements } = componentValue;
          const matchingObject = medicationStatements.find(statement => statement.id === item.id);
          const fieldObjectForHistoric = isAthena ? getFieldObjectForHistoricStatus(matchingObject) : undefined
          const isInvalid = isInvalidFieldData(MedicationFields.stopDate, item, fieldObjectForHistoric);
          const startDate = item.effectivePeriod ? getDateStrFromFormat(item.effectivePeriod, DATE_FORMATS.API_DATE_FORMAT) : ''
          if (isReadOnly) {
            const date = item.stopDate ? getMomentObjectWithDateStringAndFormat(
              item.stopDate,
              stopDateFormat || ''
            ).format(DISPLAY_DATE_FORMAT) : '';
            return <Text>{date || '-'}</Text>;
          }
          if (item.status?.code === ACTIVE_STATUS_CODE) {
            return <Text>{'-'}</Text>;
          }
          return (
            <DatePicker
              onChange={(value) => {
                item.stopDate = !!stopDateFormat
                  ? value?.format(stopDateFormat)
                  : value?.toISOString();
                onDetailChange(item, index);
                }
              }
              disabled={isDisabled}
              placeholder={label}
              format={DISPLAY_DATE_FORMAT}
              disabledDate={(current: any) => {
                if (startDate) {
                  return current && isBeforeDate(current, startDate)
                }
                else {
                  return current && isFutureDate(current);
                }
              }}
              value={
                item.stopDate
                  ? getMomentObjectWithDateStringAndFormat(
                      item.stopDate,
                      stopDateFormat || ''
                    )
                  : undefined
              }
              style={reactStyles.height40px}
              className={isInvalid && !isDisabled ? 'field-error' : ''}
            />
          );
        },
      });
    }
    if (isFieldEnabled(MedicationFields.statusReason) && getFieldCapabilities()?.statusReason?.type === 'codeable') {
      const statusReasonList = getFieldCapabilities()?.statusReason?.possibleValues || [];
      columns.push({
        title: intl.formatMessage({id: 'statusReason'}),
        flex: isAthena ? 2 : 1,
        render: (item: IMedicationStatement, index: number) => {
          const isReadOnly = item.isReadOnly || false;
          const isDisabled = isFieldDisabled(item);
          const isInvalid = isInvalidFieldData(MedicationFields.statusReason, item);
          const isHistorical = item.status?.code === ATHENA_STATUS.HISTORICAL;
          if (!isHistorical || isReadOnly) {
            const reasonValue = statusReasonList.find((reason) => {
              return reason.code === item.reason?.coding?.[0]?.code;
            })
            if (reasonValue?.display) {
              return <Text>{reasonValue.display}</Text>;
            }
            return <Text>{'-'}</Text>;
          }
          return (
            <FormControl isInvalid={isInvalid && !isDisabled}>
              <Select
                placeholder={intl.formatMessage({id: 'statusReason'})}
                selectedValue={item.reason?.coding?.[0]?.display}
                isDisabled={isDisabled}
                onValueChange={(value: string) => {
                  const reason = statusReasonList.find((status: any) => {
                    return status.display === value;
                  });
                  item.reason = {
                    coding: [
                      {
                        display: reason?.code
                      }
                    ]
                  };
                  onDetailChange(item, index);
                }}
              >
                {statusReasonList.map((option: any, index: number) => {
                  return (
                    <Select.Item
                      key={`${option.id}_${index}`}
                      value={option.code}
                      label={option.display}
                    />
                  );
                })}
              </Select>
            </FormControl>
          );
        },
      });
    }
    if (isFieldEnabled(MedicationFields.sig)) {
      const extensionURL = getFieldCapabilities()?.sig?.extensionUrl || '';
      columns.push({
        title: (isPatientForm || isPreviewMode || contextData.isBuilderMode) ? sigAlternateText : intl.formatMessage({id: 'sig'}),
        flex: 2,
        render: (item: IMedicationStatement, index: number) => {
          const isReadOnly = item.isReadOnly || false;
          const isDisabled = isFieldDisabled(item);
          const handleSigUpdate = (text:string) => {
            item.sig = text;
            let hasExtension = false;
            if (!item.extension) {
              item.extension = [];
            }
            item.extension?.forEach((extension: Extension) => {
              if (extension.url === extensionURL) {
                hasExtension = true;
                extension.valueString = text
              }
            });
            if (!hasExtension) {
              item.extension?.push({
                url: extensionURL,
                valueString: text
              });
            }
            onDetailChange(item, index);
          };
          if (isReadOnly) {
            return <Text>{item.sig || '-'}</Text>;
          }
          return (
            <TextArea
              isDisabled={isDisabled}
              totalLines={2}
              defaultValue={item.sig}
              onChangeText={debounce(handleSigUpdate, 750)}
            />
          );
        },
      });
    }
    const actionsAvailable =  (canUpdate() || canDelete()) && (canShowDeleteBtn() || isFieldEnabled(MedicationFields.note));
    if (!props.disabled && actionsAvailable) {
      columns.push({
        title:  intl.formatMessage({id: 'actions'}),
        flex: isAthena ? 1.5 : 0.5,
        render: (item: IMedicationStatement, index: number) => {
          const isReadOnly = item.isReadOnly || false;
          const isDisabled = isFieldDisabled(item);
          const bgColor = isDisabled ? Colors.Custom.Gray50 : Colors.Custom.Base50;
          const accentColor = isDisabled ? Colors.Custom.Gray500 : Colors.Custom.PurpleColor;
          const borderColor = isDisabled ? Colors.Custom.Gray50 : Colors.Custom.ActionBtnBorder;
          return (
            <HStack>
              <Spacer />
              {/* Patient Profile => API DELETE  => trash-btn*/}
              {isFieldEnabled(MedicationFields.note) && !item?.showNote && !isReadOnly &&  (
                <Pressable
                  flexDirection="row"
                  alignItems="center"
                  onPress={() => handleShowNoteClick(item?.uniqueId || '')}
                  borderWidth={0.3}
                  backgroundColor={bgColor}
                  borderColor={borderColor}
                  px={2}
                  py={1}
                  rounded="2xl"
                  shadow={2}
                  isDisabled={isDisabled}
                  cursor={isDisabled ? 'not-allowed' : undefined}
                  mr={isSmallerDesktopScreen && !isMobileScreen && !isNotesScreenWidth ? 0 : 2}
                >
                  <Feather
                    name="plus"
                    size={14}
                    color={accentColor}
                  />
                  <Text fontWeight="bold" color={accentColor}>
                    Note
                  </Text>
                </Pressable>
              )}
              {canShowDeleteBtn(item) && !isReadOnly ? (
                <Pressable
                  marginTop={-1}
                  onPress={() => getDeleteFunction(item, index)}
                  borderWidth={0.3}
                  backgroundColor={Colors.Custom.Base50}
                  borderColor={Colors.Custom.ActionBtnBorder}
                  px={2}
                  py={1}
                  rounded="full"
                  shadow={2}
                >
                  <Feather
                    name={
                      canDelete() || contextData.isBuilderMode ? 'trash-2' : 'x'
                    }
                    size={20}
                    color={Colors.Custom.PurpleColor}
                  />
                </Pressable>
              ) : null}

            </HStack>
          );
        },
      });
    }
    return columns;
  };

  const renderAdditionalView = (item: IMedicationStatement, index: number) => {
    const isDisabled = isFieldDisabled(item);
    const canShowStatusReason = getFieldCapabilities()?.statusReason?.type == 'string';
    if (canShowStatusReason) {
      const isInvalid = isInvalidFieldData(MedicationFields.statusReason, item);
      const label = intl.formatMessage({id: 'reason'});
      const showNote = (item.status?.code === 'stopped' || item.status?.code === 'completed') && isMedOrderEnabled;
      if (showNote) {
        return (
          <HStack justifySelf="stretch" my={1}>
            <VStack flex={1}>
              <FormControl isInvalid={isInvalid && !isDisabled}>
                <HStack justifyContent={'flex-start'} mt={2}>
                  <Text mr={3} ml={1} fontSize='sm' color={Colors.Custom.Gray500} flex={1}>
                    {label}
                  </Text>
                  <CustomTextAreaField
                    disableAutoManageCannedResponsesPosition
                    placeholder={label}
                    defaultValue={item.reason?.text}
                    macroTemplatesEnabled={!isPatientForm}
                    flex={8.2}
                    onValueChange={debounce((text: string) => {
                      if (!item.reason) item.reason = {};
                      item.reason.text = text;
                      onDetailChange(item, index);
                    }, 500)}
                    additionalProps={{
                      totalLines: 2,
                      flex: 1,
                      isReadOnly: isDisabled,
                    }}
                  />
                </HStack>
              </FormControl>
            </VStack>
          </HStack>
        );
      }
    }
    const canShowNote = IsFieldVisible(MedicationFields.note);
    if (canShowNote) {
      const isInvalid = isInvalidFieldData(MedicationFields.note, item);
      const label = intl.formatMessage({id: 'notes'});
      if (item.showNote) {
        return (
          <HStack justifySelf="stretch" my={1}>
            <VStack flex={1}>
              <FormControl isInvalid={isInvalid && !isDisabled}>
                <HStack justifyContent={'flex-start'} mt={2}>
                  <Text mr={3} ml={1} fontSize='sm' color={Colors.Custom.Gray500} flex={isAthena ? 0.5 : 1}>
                    {label}
                  </Text>
                  <CustomTextAreaField
                    disableAutoManageCannedResponsesPosition
                    placeholder={label}
                    defaultValue={item.note}
                    macroTemplatesEnabled={!isPatientForm}
                    flex={isAthena ? 9.5 : 9}
                    onValueChange={debounce((text: string) => {
                      item.note = text;
                      onDetailChange(item, index);
                    }, 500)}
                    additionalProps={{
                      totalLines: 2,
                      flex: 1,
                      isReadOnly: isDisabled,
                    }}
                  />
                </HStack>
              </FormControl>
            </VStack>
          </HStack>
        );
      }
    }
    return <></>;
  }


  const getMedicationsForDetailTableView = () => {
    return (componentValue.medicationStatements || []).filter(medication => {
      if (componentState.showInActiveMedication) {
        return medication?.status?.code !== 'active';
      } else {
        return true;
      }
    })
  }

  const getSectionAdditionalView = (isCompactView: boolean) => {
    const fieldCapabilities = getFieldCapabilities();
    if (fieldCapabilities?.sectionNote) {
      return (
        <VStack paddingX={isCompactView ? 4 : 0} paddingTop={isCompactView ? 3 : 1} paddingBottom={isCompactView ? 2 : 0}>
          <SectionLevelDetails
            organizationCapabilites={organizationCapability}
            patientId={contextData.patientId || ''}
            accountLocationId={contextData.locationId}
            fieldCapabilities={fieldCapabilities}
            resource={FHIR_RESOURCE.MEDICATION_STATEMENT}
            note={componentState.sectionNote || ''}
            onChange={(text) => setComponentState(prev => ({...prev, sectionNote: text}))}
          />
        </VStack>
      );
    }
    return;
  }

  const filterActiveData = (dataItem: any) =>
    (dataItem?.clinicalStatus?.code || dataItem?.status?.code || '').toLowerCase() !== 'historical';

  const filterInactiveData = (dataItem: any) =>
    (dataItem?.clinicalStatus?.code || dataItem?.status?.code || '').toLowerCase() === 'historical';


  const getDetailView = (isCompact: boolean, isPatientReported: boolean): JSX.Element => {
    if (!componentValue.medicationStatements.length && !isCompact && !isPatientReported) {
      return (
        <VStack paddingBottom={5} alignItems={'center'}>
          <NoMedicationsSvg titleId="noMedicationList" />
          <HStack space={2} alignItems="center">
            <Spacer />
            {componentState.showSavingIndicator && <SectionLoadingIndicator />}
            {canAddNew() && getSearchComponent()}
          </HStack>
        </VStack>
      );
    }

    if (isCompact) {
      return (
        <>
        <CompactListViewMoreDetails
          hasPatientReportedRecords={componentState.patientReported.length > 0}
          onViewPatientReportedClick={() => {
            setComponentState((prev) => ({...prev, showPatientReportedRecords: true}));
          }}
          noDataTextLabel={'noActiveMedicationList'}
          noDataComponent={<NoMedicationsSvg titleId="noMedicationList" />}
          currentStatusValue={'active'}
          showStatusValue={'resolved'}
          viewDataText={'Non Active Medication'}
          dataSource={componentValue.medicationStatements}
          filterActiveDataFunction={filterActiveData}
          filterInactiveDataFunction={filterInactiveData}
          onViewMoreClick={() => {
            setComponentState((prev) => ({...prev, editModalVisible: true, showInActiveMedication: true}));
          }}
          showMoreMaxCount={contextData.showMoreMaxCount}
          rowViewForItem={(item: IMedicationStatement) => {
            let displayDate: JSX.Element | undefined;
            let displayStatus: JSX.Element | undefined;
            let displayDateItem = '';

            if (item.effectivePeriod) {
              const date = getMomentObjectWithDateStringAndFormat(
                item.effectivePeriod,
                dateFormat || ''
              );
              /**
               * Difference in Months
               */
              // displayDateItem = getDisplayMonths(
              //   getMomentObjFromFormat(date, DISPLAY_DATE_FORMAT),
              //   getMomentObjFromFormat(new Date(), DISPLAY_DATE_FORMAT)
              // );
              displayDateItem = date.format(DISPLAY_DATE_FORMAT);

              displayDate = (
                <Text size={'smMedium'} color={Colors.Custom['Gray500']}>
                  {displayDateItem}
                </Text>
              );
            }

            if (item.status?.code) {
              displayStatus = getStatusElementByStatus(item.status.code);
            }

            return (
              <VStack key={item.id}>
                <HStack space={1} alignItems="start">
                  <Text color={Colors.FoldPixel.GRAY400}>
                    {!displayDateItem
                      ? `${item.name}`
                      : `${item.name} (${displayDateItem})`}
                  </Text>
                  <Spacer />
                  {/* {displayStatus} */}
                </HStack>
                {/* {displayDate} */}
              </VStack>
            );
          }}
          />
          {getSectionAdditionalView(true)}
        </>
      );
    } else if (isPatientReported) {
      return (
        <PatientReportedTableView
          dataSource={componentState.patientReported}
          rowViewForItem={(item: IMedicationStatement, index: number) => {
            const displayStatus = item.status?.display || item.status?.code;
            let displayDateItem = '';

            if (item.effectivePeriod) {
              const date = getMomentObjectWithDateStringAndFormat(
                item.effectivePeriod,
                dateFormat || ''
              );
              /**
               * Difference in Months
               */
              displayDateItem = getDisplayMonths(
                getMomentObjFromFormat(date, DISPLAY_DATE_FORMAT),
                getMomentObjFromFormat(new Date(), DISPLAY_DATE_FORMAT)
              );
            }

            return (
              <VStack key={item.id}>
                <HStack space={1} alignItems="start">
                  <VStack space={1} flex={1}>
                    <Text color={Colors.Custom['Gray900']} width="full">
                      {!displayDateItem
                        ? `${item.name}`
                        : `${item.name} (${displayDateItem})`}
                    </Text>
                    {displayStatus && (
                      <Text color={Colors.Custom['Gray500']}>
                        {displayStatus}
                      </Text>
                    )}
                  </VStack>
                  {organizationCapability?.canCreate && (
                    <HStack space={2}>
                      {item.isFreeTextRecord && (
                        <Popover
                          key={item.id || index}
                          overlayInnerStyle={{
                            bottom: 3,
                            borderRadius: 16,
                            padding: 0,
                          }}
                          content={
                            <View minWidth={300} maxW={400} padding={2}>
                              <VStack space={8}>
                                <Text size="xlMedium">Match to Medication</Text>
                                <VStack space={1}>
                                  <DisplayText
                                    textLocalId={'medication'}
                                    size="smMedium"
                                  />
                                  <PAMISearch
                                    searchType={PAMISearchType.medication}
                                    additionalHeaders={contextData.headers}
                                    placeholder="Search Medication"
                                    isShowError={false}
                                    onChange={(value) => {
                                      setComponentState((prev) => ({
                                        ...prev,
                                        selectedItemToMap: value,
                                      }));
                                    }}
                                    locationId={contextData.locationId}
                                  />
                                </VStack>
                                <HStack>
                                  <Spacer />
                                  <Button.Group space={2}>
                                    <FoldButton
                                      nativeProps={{
                                        variant: BUTTON_TYPE.PRIMARY,
                                        onPress: () => {
                                          setComponentState((prev) => {
                                            const list = prev.patientReported;
                                            const index = list.findIndex(
                                              (value) =>
                                                value.uniqueId === item.uniqueId
                                            );
                                            if (index > -1) {
                                              list[index].medicationStatement =
                                                componentState.selectedItemToMap;
                                              list[index].name =
                                                getMedicationName(
                                                  componentState.selectedItemToMap
                                                );
                                               list[index].isFreeTextRecord = false;
                                            }
                                            return {
                                              ...prev,
                                              patientReported: list,
                                              selectedItemToMap: undefined,
                                              selectedPatientReportedRecord:
                                                undefined,
                                            };
                                          });
                                        },
                                        isDisabled:
                                          !componentState.selectedItemToMap,
                                      }}
                                      customProps={{
                                        btnText: intl.formatMessage({
                                          id: 'continue',
                                        }),
                                        withRightBorder: false,
                                      }}
                                    ></FoldButton>
                                  </Button.Group>
                                </HStack>
                              </VStack>
                            </View>
                          }
                          title=""
                          trigger="click"
                          visible={
                            componentState.selectedPatientReportedRecord ==
                            item.id
                          }
                          onVisibleChange={(visible) => {
                            setComponentState((prev) => ({
                              ...prev,
                              selectedPatientReportedRecord: visible
                                ? item.id
                                : undefined,
                            }));
                          }}
                          placement={'bottom'}
                        >
                          <CustomButton
                            title="Match to Medication"
                            isDisabled={componentState.showSavingIndicator}
                            onPress={() => {
                              setComponentState((prev) => ({
                                ...prev,
                                selectedPatientReportedRecord: item.id,
                              }));
                            }}
                          />
                        </Popover>
                      )}
                      <CustomButton
                        title="Accept"
                        isDisabled={componentState.showSavingIndicator}
                        onPress={() => {
                          const isCurrentFreeText =
                            !item.medicationStatement?.coding?.length;
                          if (isCurrentFreeText && !isOpenChoice()) {
                            notification.error({
                              message:
                                'This free text medication must be matched to database before it can be added to EHR',
                            });
                          } else {
                            handlePatientReportedActions(
                              PatientRecordActions.accept,
                              item
                            );
                          }
                        }}
                      />
                      <CustomButton
                        title="Discard"
                        isDisabled={componentState.showSavingIndicator}
                        onPress={() => {
                          handlePatientReportedActions(
                            PatientRecordActions.deny,
                            item
                          );
                        }}
                      />
                    </HStack>
                  )}
                </HStack>
              </VStack>
            );
          }}
        />
      );
    }
    return (
      <VStack flex={1} space={4}>
        <VStack space={2}>
          {canAddNew() && !isMobileScreen && (
            <HStack width={'100%'} margin={3} space={2} alignItems="center">
              {getSearchComponent(true)}
            </HStack>
          )}
          <CustomDetailTableView
            dataSource={getMedicationsForDetailTableView()}
            columns={getColumns()}
            isPatientNote={isPatientNote}
            isBuilderMode={contextData.isBuilderMode}
            renderAdditionalView={renderAdditionalView}
          />
        </VStack>
      </VStack>
    );
  };

  const onAddOrUpdateError = (error: any) => {
    const errorMessage =
      error?.response?.status === HTTP_ERROR_CODE.METHOD_NOT_ALLOWED
        ? intl.formatMessage({
            id: RESOURCE_BLOCK_MESSAGE_ID,
          })
        : error?.response?.data?.message;
    setComponentState((prev) => ({
      ...prev,
      showSavingIndicator: false,
      ...(errorMessage && {customError: errorMessage}),
      ...(!errorMessage && {nonBlockingFormError: FormError.addUpdateFail}),
    }));
  }

  const addOrUpdateRecord = (record: IMedicationStatement) => {
    if (!isInvalidRecord(record)) {
      setComponentState((prev) => ({...prev, showSavingIndicator: true}));
      const fhirData = getFHIRTemplate({
        ...record,
        patientId: contextData.patientId || '',
        tenantId: contextData.tenantId || '',
      });
      fhirAddOrUpdate(
        FHIR_RESOURCE.MEDICATION_STATEMENT,
        record.id,
        fhirData,
        {
          patientId: contextData.patientId,
          locationId: contextData.locationId,
        },
        (response) => {
          broadcastUpdateEvent();
          let keepShowingSavingIndicator = false;
          if (!record.id) {
            keepShowingSavingIndicator = true;
            getExistingData({patientId: contextData.patientId || '', skipLoader: true});
          }
          if (response?.data?.id) {
            updateDataFromAPIToList(response.data, record);
          }
          setComponentState((prev) => ({
            ...prev,
            ...(!keepShowingSavingIndicator && {showSavingIndicator: false}),
            nonBlockingFormError: undefined,
          }));
        },
        (error) => {
          onAddOrUpdateError(error);
          // If new record is failed which is not saved before then only remove from local list
          if (!record.id) {
            removeFromLocal(record.uniqueId);
          }
        }
      );
    }
  };

  const handleSubmitAllAction = async () => {
    await bulkAction.handleBulkAction({
       resource: FHIR_RESOURCE.MEDICATION_STATEMENT,
       action: PatientRecordActions.accept,
       getFilteredRecords: () => {
         const structuredRecords = componentState.patientReported.filter(
           (record) => !record.isFreeTextRecord
         );
         const FHIRformatedRecords = structuredRecords.map((record) =>
           getFHIRTemplate({
             ...record,
             patientId: contextData.patientId || '',
             tenantId: contextData.tenantId || '',
           })
         );
         return FHIRformatedRecords;
       },
       patientId: contextData.patientId || '',
       accountLocationUuid: contextData.locationId || '',
       onComplete: (response) => {
        broadcastUpdateEvent();
        if (response?.data?.id || response?.data?.success) {
          getExistingData({patientId: contextData.patientId || '', skipLoader: true});
        }
       },
       onError: onAddOrUpdateError
     });
   };

  const handlePatientReportedActions = (action: PatientRecordActions, record: IMedicationStatement) => {
    setComponentState((prev) => ({...prev, showSavingIndicator: true}));
    const fhirData = getFHIRTemplate({
      ...record,
      patientId: contextData.patientId || '',
      tenantId: contextData.tenantId || '',
    });
    acceptIgnorePatientReportedRecords(
      FHIR_RESOURCE.MEDICATION_STATEMENT,
      action,
      record.id,
      fhirData,
      {
        patientId: contextData.patientId,
        locationId: contextData.locationId,
      },
      (response) => {
        // NOTE: This is done because when we send event from `Recent Clinical Event` the component view is not same as the current view. And is same when send event from PAMI component as well to handle this, we are sending the view type from the event itself in case of PAMI component so that the api call is made.
        const viewType = isRecentReported ? undefined : FormViewType.compact;
        broadcastUpdateEvent(viewType);
        let keepShowingSavingIndicator = false;
        if (response?.data?.id || response?.data?.success) {
          if (action === PatientRecordActions.accept) {
            keepShowingSavingIndicator = true;
            // Here we only remove the specific Accepted entry so that it doesnot un-match the other matched records.
            setComponentState((prev) => ({
              ...prev,
              patientReported: prev.patientReported.filter(
                (item) => item.id !== record.id
              ),
              showSavingIndicator: false
            }));
          } else {
            updatePatientReportedDataFromAPIToList(response?.data, record, action);
          }
        }
        setComponentState((prev) => ({
          ...prev,
          ...(!keepShowingSavingIndicator && {showSavingIndicator: false}),
          nonBlockingFormError: undefined,
        }));
      },
      onAddOrUpdateError
    );
  }

  const updateDataFromAPIToList = (data: any, record: IMedicationStatement) => {
    if (record.id) return;

    setComponentValue((prev) => {
      const list = prev.medicationStatements;
      const index = list.findIndex(
        (value) => value.uniqueId === record.uniqueId
      );
      if (index > -1) {
        list[index].id = data.id;
      }
      return {
        ...prev,
        medicationStatements: list,
      };
    });
  };

  const updatePatientReportedDataFromAPIToList = (data: any, record: IMedicationStatement, action: PatientRecordActions) => {
    setComponentState((prev) => {
      const list = prev.patientReported;
      const index = list.findIndex(
        (value) => value.uniqueId === record.uniqueId
      );
      if (index > -1) {
        list.splice(index, 1);
      }
      return {
        ...prev,
        patientReported: list,
        showPatientReportedRecords: list.length > 0
      };
    });
    if (action === PatientRecordActions.accept && data?.id) {
      setComponentValue((prev) => {
        const list = prev.medicationStatements;
        record.id = data.id;
        list.splice(0, 0, record);
        return {
          ...prev,
          medicationStatements: list,
        };
      });
    }
  };

  const getExistingData = (params: {patientId: string, skipLoader?: boolean, manualImport?: boolean}) => {
    const {patientId, skipLoader, manualImport} = params;

    if (isPatientCarePlan) {
      return;
    }

    if (!skipLoader) {
      setComponentState((prev) => ({...prev, loading: true}));
    }
    try {
      Promise.all([
        getMedicationStatementAndPatientReportedOrder(patientId, contextData.locationId, !contextData.hasLoggedInContext, true, contextData.headers),
        getMedicationRequestByPatientId(patientId, contextData.locationId, !contextData.hasLoggedInContext, contextData.headers)
      ]).then((responses) => {
        const medicationRequestData= responses[1]?.data;
        const mergeResponse = cloneDeep(responses[0]?.data);
        const responseEntry = cloneDeep(mergeResponse.resource?.data?.entry || []);
        if (medicationRequestData?.entry?.length) {
          (medicationRequestData?.entry).forEach((resource: any) => {
            responseEntry.push(resource);
          });
        }
        mergeResponse.resource.data['entry'] = responseEntry;
        if (mergeResponse?.resource?.data?.entry?.length > 0 || mergeResponse?.patientReported?.data?.entry?.length > 0) {
          handleExistingDataResponse(mergeResponse,manualImport);
        } else {
          const sectionNote = getSectionNoteDetails(mergeResponse?.resource?.data?.meta?.extension || []).sectionNote;
          setComponentState((prev) => ({
            ...prev,
            loading: false,
            sectionNote: sectionNote,
            showSavingIndicator: false,
            activePanels: [],
            formError : undefined,
          }));
          setComponentValue((prev) => ({
            ...prev,
            medicationStatements: [],
          }));
          setImportFromChartLoading(false);
          return;
        }

      }).catch((error) => {
        setComponentState((prev) => ({
          ...prev,
          loading: false,
          showSavingIndicator: false,
          formError: FormError.existingDataAPIFail,
        }));
      });
    } catch (error) {
      setComponentState((prev) => ({
        ...prev,
        loading: false,
        showSavingIndicator: false,
        formError: FormError.existingDataAPIFail,
      }));
    }
  };

  const handleExistingDataResponse = (response: any, manualImport?: boolean) => {
    if (isPatientForm) {
      const ehrRecords = response?.resource?.data?.entry;
      const sectionNote = getSectionNoteDetails(response?.resource?.data?.meta?.extension || []).sectionNote;
      if (ehrRecords) {
        const formattedData = getRecordListFormatted(ehrRecords, getFieldCapabilities(), contextData.capabilities?.ehrName);
        const newRecordsAddedInForm = props.component?.selectedValue?.medicationStatements || [];
        setComponentValue((prev) => ({
          ...prev,
          medicationStatements: [...newRecordsAddedInForm, ...formattedData],
        }));
        contextData?.updateFormPrefilledByFoldProgress?.(componentKeys.MEDICATIONS, { medicationStatements: [ ...formattedData ]});
      }
      setComponentState((prev) => ({
        ...prev,
        loading: false,
        sectionNote: sectionNote,
        showSavingIndicator: false,
        activePanels: ehrRecords?.length ? [componentKeys.MEDICATIONS] : [],
        formError: !ehrRecords
          ? FormError.existingDataAPIFail
          : prev.formError,
      }));
    } else {
      const patientReportedRecords = response?.patientReported?.data?.entry || [];
      const ehrRecords = response?.resource?.data?.entry || [];
      const sectionNote = getSectionNoteDetails(response?.resource?.data?.meta?.extension || []).sectionNote;
      let patientReportedFormattedRecords: IMedicationStatement[] = [];
      if (patientReportedRecords || ehrRecords) {
        let ehrFormattedRecords: IMedicationStatement[] = [];
        if (patientReportedRecords?.length) {
          patientReportedFormattedRecords = getRecordListFormatted(patientReportedRecords, getFieldCapabilities(), contextData.capabilities?.ehrName);
        }
        if (ehrRecords?.length) {
          ehrFormattedRecords = getRecordListFormatted(ehrRecords, getFieldCapabilities(), contextData.capabilities?.ehrName);
          if (manualImport) {
            ehrFormattedRecords = filterAndSkipDuplicates<IMedicationStatement>(
              componentValue.medicationStatements,
              ehrFormattedRecords
            );
          };
        }
        setComponentValue((prev) => ({
          ...prev,
          medicationStatements: [...ehrFormattedRecords]
        }));

        contextData?.updateFormPrefilledByFoldProgress?.(componentKeys.MEDICATIONS, { medicationStatements: [ ...ehrFormattedRecords ]});
      }
      setImportFromChartLoading(false);
      setComponentState((prev) => ({
        ...prev,
        loading: false,
        sectionNote: sectionNote,
        showSavingIndicator: false,
        ...(!patientReportedFormattedRecords.length && {showPatientReportedRecords: false}),
        patientReported: patientReportedFormattedRecords,
        activePanels: ehrRecords?.length || patientReportedFormattedRecords?.length ? [componentKeys.MEDICATIONS] : [],
        formError: !ehrRecords || !patientReportedRecords
          ? FormError.existingDataAPIFail
          : prev.formError,
      }));
    }
  }

  const broadcastUpdateEvent = (viewType?: FormViewType) => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent(
      ADD_UPDATE_EVENTS.MED,
      viewType || contextData.componentView
    );
  };

  const onEventReceived = useCallback(
    (data) => {
      if (data != contextData.componentView && contextData?.patientId  && !isPatientNote) {
        getExistingData({patientId: contextData.patientId, skipLoader: true});
      }
    },
    [contextData]
  );

  const fetchImportData = () => {
    if (isPatientNote && !isPreviewMode && contextData.patientId) {
      setImportFromChartLoading(true);
      getExistingData({patientId: contextData.patientId, skipLoader: true, manualImport: true});
    }
  }

  useEffect(() => {
    if (isRecentReported) {
      contextData?.updateLoadingStatus?.(componentKeys.MEDICATIONS, componentState.loading, !!componentState.patientReported.length);
      return;
    }
    const isDataAvailable = !!componentValue.medicationStatements.length || !!componentState.patientReported.length;
    contextData?.updateLoadingStatus?.(componentKeys.MEDICATIONS, componentState.loading, isDataAvailable);
  }, [componentState.loading]);

  useEffect(() => {
    if (componentState.editModalVisible) {
      resetDimension();
    }
  }, [componentState.editModalVisible]);

  useEffect(() => {
    if (contextData.isBuilderMode) {
      setComponentValue(getSampleData());
      return;
    }
    const patientId = contextData?.patientId;
    if (patientId && !isPatientNote  && (!props.component?.selectedValue || (isPatientForm && !props.disabled))) {
      getExistingData({patientId});
    }
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(ADD_UPDATE_EVENTS.MED, onEventReceived);
    eventBus.addEventListener(SUPPORTED_EVENT_CODE.ORDER_CREATED, onEventReceived);
    return () => {
      eventBus.removeEventListener(onEventReceived);
    };
  }, []);

  useEffect(() => {
    let list = componentValue.medicationStatements;
    if (!canUpdate()) {
      list = componentValue.medicationStatements.filter((item) => {
        return !item.id;
      });
    }
    props.onChange(cloneDeep({medicationStatements: list, total: componentValue.medicationStatements.length}));
  }, [componentValue]);

  const {acceptRecord, ignoredRecords} =
  getAcceptedAndIgnoredRecordNames(componentState.patientReported);

  const onClose = () => {
    const { medicationStatements } = componentValue;
    if (isAthena && medicationStatements.length > 0) {
    const matchinghistoricStatusArray = medicationStatements.filter(statement => statement?.status?.code === HISTORICAL_STATUS_CODE);
    const isNotStopDateForHistoricStatus = matchinghistoricStatusArray.some(statement => statement.stopDate === undefined || statement.stopDate === null);
    const isNotValid = medicationStatements.every(statement => {
      const effectivePeriod = statement?.effectivePeriod ? getDateStrFromFormat(statement.effectivePeriod, DATE_FORMATS.API_DATE_FORMAT) : null;
      const stopDate = statement?.stopDate ? getDateStrFromFormat(statement?.stopDate, DATE_FORMATS.API_DATE_FORMAT) : null;
      return (effectivePeriod && stopDate) ? stopDate <= effectivePeriod : false;
    });
    if (isNotStopDateForHistoricStatus) {
      notification.destroy();
      setError('Please fill the mandatory field')
      notification.error({
        message: intl.formatMessage({
          id: 'mandatoryFieldError',
        }),
        duration: 2.0,
        placement: 'top'
      });
      return false
    }
    if (isNotValid) {
      setError('Medication stop date cannot be less than start date')
      notification.destroy();
      notification.error({
        message: intl.formatMessage({
          id: 'stopDateError',
        }),
        duration: 2.0,
        placement: 'top'
      });

      return false
    }
    }
    setComponentState((prev) => ({
      ...prev,
      editModalVisible: false,
      showInActiveMedication: false,
    }));
    return true;
  }

  props.validateRef.current = validateData;
  if (isPreviewMode) {
    return (
      <>
        {componentState.loading && (
          <View padding={4}>
            <FormComponentLoader />
          </View>
        )}
        {!componentState.loading && !componentState.formError && (
          <>
            {componentValue.medicationStatements.length > 0 && (
              <div className={` ${isAllowToShare ? '' : 'disallow-to-share'}`}>
              <DetailPreview titleLocalId={props.component.label}>
                <FlatList
                  data={componentValue.medicationStatements}
                  initialNumToRender={(componentValue.medicationStatements || [])?.length}
                  keyExtractor={(item) => JSON.stringify(item)}
                  renderItem={({item}) => (
                    <div className="page-break">
                      <VStack my={0.5}>
                        <Text fontWeight={600}>{item.name}</Text>
                        <div style={reactStyles.displayFlex}>
                          <Text>
                            {item.status &&
                              `${intl.formatMessage({id: 'status'})}: ${
                                item.status.display
                              }`}
                          </Text>
                          {item.effectivePeriod && item.status && (
                            <Text> &#8226; </Text>
                          )}
                          <Text>
                            {item.effectivePeriod &&
                              `${intl.formatMessage({
                                id: 'effectiveDate',
                              })}: ${getDateStrFromFormat(
                                item.effectivePeriod,
                                DISPLAY_DATE_FORMAT
                              )}`}
                          </Text>
                        </div>
                        {item.showNote && item.note && (
                          <Text>{`Note: ${item.note}`}</Text>
                        )}
                        {item.sig && (
                          <Text>{`${sigAlternateText}: ${item.sig}`}</Text>
                        )}
                      </VStack>
                    </div>
                  )}
                />
              </DetailPreview>
              </div>
            )}
          </>
        )}
      </>
    );
  }

  if (isPatientCarePlan) {
    return <AddOrUpdateCarePlanMedications {...props} />;
  }

  return (
    <View ref={componentRef}>
      {isCompactView ? (
        <VStack background={Colors.Custom.BackgroundColor}>
          <Collapse
            activeKey={componentState.activePanels}
            onChange={(activePanels) => {
              setComponentState((prev) => ({...prev, activePanels: activePanels as string[]}));
            }}
            expandIconPosition="end"
            expandIcon={({ isActive }) => {
            const tooltipText = isActive ? intl.formatMessage({id: 'collapse'}) : intl.formatMessage({id:'expand'});
            return (
              <Tooltip title={tooltipText} placement={tooltipPlacement} showArrow={false} overlayStyle={tooltipOverlayStyle} overlayInnerStyle={tooltipInnerOverlayStyle}>
                <RightOutlined rotate={isActive ? 90 : 0} />
              </Tooltip>
              )}}
          style={reactStyles.collapseStyle}>
            <Panel forceRender key={componentKeys.MEDICATIONS} header={<HStack
              alignItems={'center'}
              marginRight={4}
              justifyContent="space-between"
              borderTopRadius={0}
            >
              <HStack alignItems={'center'} space={2}>
                <View>
                  <MedicationIconSvg />
                </View>
                <Text
                  color={Colors.FoldPixel.GRAY300}
                  fontWeight={500}
                  size={'lgMedium'}
                >
                  {props.component.leftSidebarLabel || props.component.label}
                </Text>
              </HStack>
              <View>{getHeaderRightView(isCompactView)}</View>
            </HStack>}>
              {componentState.loading && (
                <View padding={4}>
                  <FormComponentLoader />
                </View>
              )}
              {!componentState.loading && componentState.formError && (
                <FormComponentError error={componentState.formError} />
              )}
              {!componentState.loading && componentState.customError && (
                <FormComponentError error={FormError.custom} customError={componentState.customError} />
               )}
              {!componentState.loading && componentState.nonBlockingFormError && (
                <FormComponentError error={componentState.nonBlockingFormError} />
              )}
              {!componentState.loading &&
                !componentState.formError &&
                getDetailView(isCompactView, false)}
              <Divider />
            </Panel>
          </Collapse>
        </VStack>
      ) : (
        <div className='page-break'>
        {isRecentReported ? (
        <RecentReportedViewHeader
          loading={componentState.loading}
          noData={!componentState.patientReported.length}
          title={props.component.label}
          buttonOnPress={() => {
            setComponentState((prev) => ({
              ...prev,
              showPatientReportedRecords: true,
            }));
          }}
        />
        ) :
        <DetailViewCollapse
          btnList={[
            {
              icon: importFromChartLoading ? (
                <Spinner mr={1.5} />
              ) : (
                <Feather
                  name="download"
                  size={20}
                  color={Colors.Custom.mainPrimaryPurple}
                />
              ),
              onIconBtnClick: fetchImportData,
              label: intl.formatMessage({id: 'importFromChart'}),
              tooltipLabel: intl.formatMessage({id: 'importFromChartOverrideInfo'}),
              isCustomButton: true,
              hide: !(isPatientNote && !isPreviewMode),
              disabled: importFromChartLoading,
            }
          ]}
          textLocalId={props.component.label}
          toggled={!componentState.collapsed}
          headerRightView={getHeaderRightView(isCompactView)}
          headerStyle={{marginTop: 3}}
          extraTextStyles={
            isPatientNote ? {fontWeight: 'bold', fontSize: 16} : {}
          }
          extraPanelStyles={
            isPatientNote
              ? {borderColor: '#D0D5DD', ...CUSTOM_COMPONENT_STYLE}
              : {}
          }
          onToggled={() => {
            setComponentState((prev) => ({
              ...prev,
              collapsed: !prev.collapsed,
            }));
          }}
        >
          <VStack>
            {canAddNew() &&
              isMobileScreen &&
              componentValue.medicationStatements.length > 0 && (
                <VStack marginY={4} space={2} flex={1} marginX={2}>
                  {getSearchComponent()}
                  {componentState.showSavingIndicator && (
                    <SectionLoadingIndicator />
                  )}
                </VStack>
              )}
            {componentState.loading && (
              <View padding={4}>
                <FormComponentLoader />
              </View>
            )}
            {!componentState.loading && componentState.customError && (
              <FormComponentError error={FormError.custom} customError={componentState.customError} />
            )}
            {!componentState.loading && componentState.formError && (
              <FormComponentError error={componentState.formError} />
            )}
            {!componentState.loading && componentState.nonBlockingFormError && (
              <FormComponentError error={componentState.nonBlockingFormError} />
            )}
            {!componentState.loading &&
              !componentState.formError &&
              getDetailView(isCompactView, false)}
          </VStack>
        </DetailViewCollapse>
        }
        </div>
      )}
      <Drawer
        title={
          <>
          <ModalActionTitle
            isHeadNotSticky
            title={'Medications'}
            buttonList={[
              {
                onClick: () => {
                  return onClose();
                },

                show: true,
                id: 1,
                btnText: intl.formatMessage({
                  id: 'close',
                }),
                size: 'sm',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
              },
            ]}
          />
          {getSectionAdditionalView(false)}
          </>
        }
        destroyOnClose
        placement="right"
        onClose={onClose}
        visible={componentState.editModalVisible}
        closable
        width={(isAthena || isCerner) ? '85%' : '60%'}
      >
        <DetailViewCollapse
          btnList={[]}
          textLocalId={props.component.label}
          toggled={!componentState.editModalCollapse}
          headerRightView={getHeaderRightView(false)}
          onToggled={() => {
            setComponentState((prev) => ({
              ...prev,
              editModalCollapse: !prev.editModalCollapse,
            }));
          }}
        >
          <VStack ref={drawerDetailRef}>
            {isAthena && !isPatientForm && (
              <HStack alignItems="center" px={2} pt={2} pb={1}>
                <Feather name="info" color={Colors.Custom.Gray500} />
                <Text ml={1} color={Colors.Custom.Gray500} fontSize="xs">
                  Modifying sig here will update structured sig in underlying EHR, if present for that prescription.
                </Text>
              </HStack>
            )}
            {canAddNew() &&
              isMobileScreen &&
              componentValue.medicationStatements.length > 0 && (
                <VStack marginY={4} space={2} flex={1} marginX={2}>
                  {getSearchComponent()}
                  {componentState.showSavingIndicator && (
                    <SectionLoadingIndicator />
                  )}
                </VStack>
              )}
            {componentState.loading && <FormComponentLoader />}
            {!componentState.loading && componentState.customError && (
              <FormComponentError error={FormError.custom} customError={componentState.customError} />
            )}
            {!componentState.loading && componentState.formError && (
              <FormComponentError error={componentState.formError} />
            )}
            {!componentState.loading && componentState.nonBlockingFormError && (
              <FormComponentError error={componentState.nonBlockingFormError} />
            )}
            {!componentState.loading &&
              !componentState.formError &&
              getDetailView(false, false)}
          </VStack>
        </DetailViewCollapse>
      </Drawer>
      <Drawer
        title={
          <ModalActionTitle
            isHeadNotSticky
            title={'Patient Reported Medications'}
            buttonList={[
              {
                isDisabled: componentState.showSavingIndicator,
                isLoading: componentState.showSavingIndicator,
                onClick: () => {
                  setComponentState((prev) => ({
                    ...prev,
                    showPatientReportedRecords: false,
                    selectedVitalsForBulkActions:
                      componentState.patientReported.map(
                        (item) => item.id || ''
                      ),
                  }));
                },
                show: true,
                id: 1,
                btnText: intl.formatMessage({
                  id: componentState.showSavingIndicator ? 'Saving' : 'close',
                }),
                size: 'sm',
                textColor: componentState.showSavingIndicator
                  ? Colors.primary[800]
                  : Colors.danger[800],
                variant: componentState.showSavingIndicator
                  ? BUTTON_TYPE.TERTIARY
                  : BUTTON_TYPE.SECONDARY,
              },
              {
                isDisabled: componentState.showSavingIndicator || bulkAction.loading,
                isLoading: bulkAction.loading,
                onClick: () =>  bulkAction.handleConfirmation(true),
                show:
                  organizationCapability?.canCreate &&
                  componentState.patientReported.filter(
                    (item) => !item.isFreeTextRecord
                  ).length > 0,
                id: 2,
                btnText: intl.formatMessage({
                  id: 'acceptAll'
                }),
                size: 'sm',
                textColor: componentState.showSavingIndicator || bulkAction.loading
                  ? Colors.primary[800]
                  : Colors.danger[800],
                variant: BUTTON_TYPE.PRIMARY,
              },
            ]}
          />
        }
        destroyOnClose
        placement="right"
        onClose={() => {
          setComponentState((prev) => ({
            ...prev,
            showPatientReportedRecords: false,
          }));
        }}
        open={componentState.showPatientReportedRecords}
        closable
        width="50%"
      >
        <VStack>
          {componentState.loading && <FormComponentLoader />}
          {!componentState.loading && componentState.nonBlockingFormError && (
            <FormComponentError error={componentState.nonBlockingFormError} />
          )}
          {!componentState.loading && componentState.customError && (
            <FormComponentError error={FormError.custom} customError={componentState.customError} />
          )}
          {!componentState.loading && componentState.formError && (
            <FormComponentError error={componentState.formError} />
          )}
          {!componentState.loading &&
            !componentState.formError &&
            getDetailView(false, true)}
        </VStack>
      </Drawer>
      {deleteConfirmation.modalVisible && (
       <PatientIntakeDeleteModal
        type={'Medication'}
        isDeleteHistorical={false}
        visible={deleteConfirmation.modalVisible}
        onCancel={cancelDeletePrompt}
        onDelete={() => {
          onDelete(
            deleteConfirmation.selectedEntry?.item,
            deleteConfirmation.selectedEntry?.index as number,
            false
          );
        }}
        />
      )}
       {bulkAction.confirm && (
        <PatientIntakeConfirm
          acceptCount={acceptRecord.length}
          title='Accept Patient Reported Data'
          onOk={() => handleSubmitAllAction()}
          onCancel={() => bulkAction.handleConfirmation(false)}
          renderRecords={() => {
            return (
              <>
                {ignoredRecords.length > 0 && (
                  <View>
                    <Text mt={2.5}>
                      Following data reported by patient is unstructured and has
                      not been matched to a structured record. These will not be
                      updated in the EHR.
                    </Text>
                    {renderSectionBulletList(
                      `Unmatched records (${ignoredRecords.length} / ${componentState.patientReported.length})`,
                      ignoredRecords,
                      undefined,
                      {
                        backgroundColor: Colors.Custom.Red100,
                        borderColor: Colors.Custom.ErrorColor,
                      }
                    )}
                  </View>
                )}
                {acceptRecord.length > 0 && (
                  <View>
                    <Text mt={2.5}>Following records will be updated in the EHR.</Text>
                    {renderSectionBulletList(
                      `Matched records (${acceptRecord.length} / ${componentState.patientReported.length}) `,
                      acceptRecord
                    )}
                  </View>
                )}
              </>
            );
          }}
        />
      )}
    </View>
  );
};

export default AddOrUpdateMedications;
