import {Text} from 'native-base';
import Stack from '../../../common/LayoutComponents/Stack';
import {ContactOutreachActivityItem} from './ContactOutreachActivityItem';
import {reactStyles, styles} from './ContactOutreachActivitiesStyles';
import {FlatList, Pressable} from 'react-native';
import {Skeleton} from 'antd';
import {IFormattedContactOutreachActivity} from './interfaces';
import React from 'react';

interface IContactOutreachActivitiesProps {
  outreachActivities: IFormattedContactOutreachActivity[];
  fetchMoreActivities: () => void;
  loading: boolean;
  showMoreActivity: boolean;
  handleEditOutreachActivity?: (outreachActivity: IFormattedContactOutreachActivity) => void;
}

const ContactOutreachActivities = (
  props: IContactOutreachActivitiesProps
) => {
  const {outreachActivities, fetchMoreActivities, loading, showMoreActivity} =
    props;

  return (
    <Stack direction="column" style={styles.outreachActivityContainer}>
      <Text style={styles.outreachTitleText}>{'Outreach Activity'}</Text>
      <FlatList
        contentContainerStyle={styles.outreachActivityListView}
        data={outreachActivities}
        scrollEnabled={false}
        keyExtractor={(item, index) => `contact_outreach_activity_${item.id}`}
        ListFooterComponent={
          <>
            {loading && (
              <Stack direction="column">
                <Skeleton.Input
                  active={true}
                  style={reactStyles.skeletonText1}
                />
                <Skeleton.Input
                  active={true}
                  style={reactStyles.skeletonText2}
                />
              </Stack>
            )}
            {!loading && showMoreActivity && (
              <Pressable
                style={styles.loadMoreButtonContainer}
                onPress={() => {
                  fetchMoreActivities();
                }}
              >
                <Text style={styles.loadMoreButtonText}>{'Load More'}</Text>
              </Pressable>
            )}

            {!loading && outreachActivities.length === 0 && (
              <Pressable
                style={styles.loadMoreButtonContainer}
                onPress={() => {
                  fetchMoreActivities();
                }}
              >
                <Text style={styles.noDataFoundText}>
                  {'No Activity Found'}
                </Text>
              </Pressable>
            )}
          </>
        }
        renderItem={({item, index}) => {
          return (
            <ContactOutreachActivityItem
              isLastItem={index === outreachActivities?.length - 1}
              outreachActivity={item}
              onEdit={props?.handleEditOutreachActivity}
            />
          );
        }}
      ></FlatList>
    </Stack>
  );
};

export default React.memo(ContactOutreachActivities);