import React from 'react';
import {Badge, Drawer, Skeleton} from 'antd';
import {FlatList, HStack, Pressable, Text, useMediaQuery, View, VStack} from 'native-base';
import {Dimensions} from 'react-native';
import {
  BUTTON_TYPE,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
  SMALL_WINDOW_1700,
} from '../../../constants/StringConst';
import {Colors} from '../../../styles/Colors';
import {ModalActionTitle} from '../ModalActionTitle/ModalActionTitle';
import {useEffect, useState} from 'react';
import {useLazyQuery} from '@apollo/client';
import ContactsQueries from '../../../services/Contacts/ContactsQueries';
import {IContact} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import InfoSection, {IInfoItem} from './components/InfoSection';
import {GET_CONTACT_CARE_PROGRAM_STEP_OUTREACH} from '../../../services/ContactCareProgram/ContactCareProgram';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../constants/Configs';
import CareProgramStepOutreachUtils from './CareProgramStepOutreachUtils';
import {
  IContactCareProgramResponse,
  IContactCareProgramStepOutreach,
  StepOutreachLogModeOfContact,
} from '../../../services/ContactCareProgram/interface';
import {useIntl} from 'react-intl';
import useOutreachStatus from './useOutreachStatus';
import {DetailViewCollapse} from '../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailViewCollapse';
import OutreachStep from './components/OutreachStep';
import {GET_USERS_WITH_IDS} from '../../../services/User/UserQueries';
import {IUser} from '../../../Interfaces';
import {FontSize} from '@foldhealth/easy-email-extensions';
import {LeadQueries} from '../../../services';
import {PersonActions} from '../PersonActions/PersonActions';
import {
  getAccountUUID,
  getSecondaryValue,
  getUserData,
  getUserUUID,
  isContactConsentRequired,
} from '../../../utils/commonUtils';
import {PATIENT_QUICK_PROFILE_PARENT_CODES} from '../../../constants/ActionConst';
import {ContactRelationCommunication} from './components/Communication';
import { getPatientEnrollmentWorkflows } from '../../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import { FlowType } from '../../../context/WorkflowContext';
import OutreachAutomationPanel from './components/OutreachAutomationPanel';
import { useToast as useCustomToast } from '../../Toast/ToastProvider';
import { ToastType, showToast } from '../../../utils/commonViewUtils';
import useOutreachStepOutComes from './useOutreachStepOutComes';
import { OUT_REACH_OUTCOME_CODES } from '../../../constants/MlovConst';

interface ICareProgramConsentProps {
  isVisible: boolean;
  contactId: string;
  contactCareProgramId: string;
  onClose: (action: boolean) => void;
  outreachStepId?: string | undefined;
}

interface IWorkflow {
  id: string;
  name: string;
}

export const CareProgramStepOutreach = (props: ICareProgramConsentProps) => {
  const {contactId, isVisible, contactCareProgramId, onClose, outreachStepId} =
    props;
  const accountUuid = getAccountUUID();
  const outReachStatus = useOutreachStatus();
  const userData = getUserData();
  const customToast = useCustomToast();
  const isConsentRequired = isContactConsentRequired();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const intl = useIntl();
  const screenDimensions = Dimensions.get('window');
  const smallWindow = screenDimensions?.width < SMALL_WINDOW_1700;
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);
  const { outreachStepOutComes } = useOutreachStepOutComes();
  const declineOutcomeId = outreachStepOutComes.find(outcome => outcome.code === OUT_REACH_OUTCOME_CODES.PATIENT_DECLINED);
  const [loading, setLoading] = useState<boolean>(true);
  const [enabledContactModes, setEnabledContactModes] = useState<StepOutreachLogModeOfContact[]>([]);
  const [noteEditState, setNoteEditState] = useState<{
    anyNoteInEditMode: string | undefined;
    isCloseButtonClicked: boolean;
  }>({
    anyNoteInEditMode: undefined,
    isCloseButtonClicked: false
  });

  const [workflowData, setWorkflowData] = useState<{
    workflowList: IWorkflow[];
    show: boolean;
  }>({
    workflowList: [],
    show: false
  });

  const toggleWorkflows = () => {
    setWorkflowData(prev => ({
      ...prev,
      show: !prev.show
    }));
  };

  const handleEditNoteChange = (value: string|undefined) => {
    setNoteEditState(prev => ({
      ...prev,
      anyNoteInEditMode: value
    }));
  };

  const handleCloseButtonClicked = (value: boolean) => {
    setNoteEditState(prev => ({
      ...prev,
      isCloseButtonClicked: value
    }))
  }

  const [componentState, setComponentState] = useState<{
    contactData: IContact | undefined;
    attributes: IInfoItem[];
    outreachStep: IContactCareProgramStepOutreach[];
    userMap: Record<string, IUser>;
    consentData?: any[];
    isSomethingChanged?: boolean;
  }>({
    contactData: undefined,
    attributes: [],
    outreachStep: [],
    userMap: {},
    consentData: [],
  });

  const [getContactsFromIds] = useLazyQuery(
    ContactsQueries.GET_CONTACTS_BY_CONTACT_IDS,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [getContactConsentByIds] = useLazyQuery(
    LeadQueries.GetConsentsByContactIds,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [getUserByIds] = useLazyQuery(GET_USERS_WITH_IDS, {
    fetchPolicy: 'no-cache',
  });
  const [getContactCareProgramStepOutreach] = useLazyQuery(
    GET_CONTACT_CARE_PROGRAM_STEP_OUTREACH,
    {
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
    }
  );

  const fetchComponentData = async () => {
    setLoading(true);
    try {
      const promiseList: Promise<any>[] = [];
      // const contactDataResponse =
      promiseList.push(
        getContactsFromIds({
          variables: {
            contactIds: [contactId],
          },
        })
      );

      promiseList.push(
        getContactCareProgramStepOutreach({
          variables: {
            contactCareProgramId: contactCareProgramId,
            contactCareProgramStepId: outreachStepId,
          },
        })
      );

      promiseList.push(
        getPatientEnrollmentWorkflows(
          FlowType.patients,
          'OnPatientEnrolledInProgram'
        )
      );

      promiseList.push(
        getContactConsentByIds({
          variables: {
            accountUuid: accountUuid,
            contactIds: {
              _in: [contactId],
            }
          }
        })
      );

      const [contactDataResponse, contactCareProgramOutReachStepResponse, workflowDataResponse, consentResponseObj] =
        await Promise.all(promiseList);

      setWorkflowData(prev => ({
        ...prev,
        workflowList: workflowDataResponse
      }));

      const consentData = consentResponseObj?.data?.contactConsents;
      setComponentState((prev) => ({
        ...prev,
        consentData: consentData,
      }));

      const attributes =
        contactCareProgramOutReachStepResponse?.data?.contactCareProgram
          ?.additionalAttributes;

      const formattedAttributes = CareProgramStepOutreachUtils.formatAttributes(
        attributes,
        {
          additionalAttributes: attributes,
        } as IContactCareProgramResponse,
        intl
      );
      const outreachStep = contactCareProgramOutReachStepResponse?.data
        .contactCareProgram.contactCareProgramSteps[0]
        ?.careProgramStepOutreaches as IContactCareProgramStepOutreach[];

      const sortedOutreachStep =
        CareProgramStepOutreachUtils.sortOutreachStepBySequence(
          outreachStep || []
        );

      const availableUsers = sortedOutreachStep
        .filter(
          (step) =>
            step.stepOutreachLog.outreachCompletedBy !== null &&
            step.stepOutreachLog.outreachCompletedBy !== '' &&
            step.stepOutreachLog.outreachCompletedBy !== undefined
        )
        ?.map((item) => item.stepOutreachLog.outreachCompletedBy);

      const userResponse = await getUserByIds({
        variables: {
          userIds: availableUsers,
        },
      });

      const userMap = CareProgramStepOutreachUtils.getUserMapByUUID(
        userResponse?.data?.users,
        userData
      );

      const contactInfo = contactDataResponse?.data?.contacts?.[0];
      setComponentState((prev) => ({
        ...prev,
        contactData: contactInfo,
        attributes: formattedAttributes,
        outreachStep: sortedOutreachStep,
        userMap: userMap,
      }));
      const contact: IContact = {
        ...contactInfo,
        contactConsents: consentData,
      }

      const consentCheck = CareProgramStepOutreachUtils.isConsentRequiredAndGiven(contact, isConsentRequired);
      const enabledContactModes = CareProgramStepOutreachUtils.getEnabledContactModes(contact, consentCheck);
      setEnabledContactModes(enabledContactModes);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchComponentData();
  }, [contactId, props.outreachStepId]);

  const checkIfAllPreviousRequiredStepsAreCompleted = (
    currentStepSequence: number
  ) => {
    const outreachStep = componentState.outreachStep;
    const previousSteps = outreachStep.filter(
      (step) => step.sequence < currentStepSequence
    );
    const requiredSteps = previousSteps.filter((step) => step.isRequired);

    // Here we dont need to check if currentStep is allowed to check/uncheck if there are none required steps
    // before it
    if (requiredSteps.length === 0) {
      return true;
    }

    const completedSteps = previousSteps.filter(
      (step) => outReachStatus.success === step.stepOutreachLog.statusId
    );
    return requiredSteps.length === completedSteps.length;
  };

  const syncList = (newItem: IContactCareProgramStepOutreach) => {
    const updatedList = componentState.outreachStep.map((item) => {
      if (item.id === newItem.id) {
        return newItem;
      }
      return item;
    });
    setComponentState((prev) => ({
      ...prev,
      outreachStep: updatedList,
      isSomethingChanged: true,
    }));
  };

  const declineIndex = componentState.outreachStep.find(step => step.stepOutreachLog.outcomeId === declineOutcomeId?.id)?.sequence;

  const renderMainView = () => {
    if (loading) {
      return (
        <VStack>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </VStack>
      );
    }

    return (
      <VStack>
        <InfoSection
          contactData={componentState.contactData}
          infoItems={componentState.attributes}
          actionView={
            <View ml={12}>
              <ContactRelationCommunication
                handleActions={() => null}
                contact={
                  {
                    ...componentState.contactData,
                    contactConsents: componentState.consentData,
                  } as IContact
                }
              />
            </View>
          }
        />
        {!!workflowData.workflowList && workflowData.workflowList.length > 0 && (
          <View
            style={{
              paddingHorizontal: 10,
              paddingTop: 15,
              paddingBottom: 10,
              marginBottom: 12,
              marginTop: -18,
              borderWidth: 1,
              borderTopWidth: 0,
              borderBottomRightRadius: 12,
              borderBottomLeftRadius: 12,
              borderColor: Colors.FoldPixel.GRAY150,
            }}
          >
            <HStack alignItems="center" flex={1}>
              <Text
                paddingTop={2}
                flex={9.5}
              >
                {`${workflowData.workflowList.length} ${intl.formatMessage({ id: 'linkedAutomation' })}`}
              </Text>
              <Pressable
                marginTop={0.5}
                alignSelf={'flex-end'}
                justifyContent={'flex-end'}
                onPress={toggleWorkflows}
              >
                <Text flex={1} color={Colors.Custom.mainPrimaryPurple}>
                  {workflowData.show ? 'Hide' : 'Show'}
                </Text>
              </Pressable>
            </HStack>
            {workflowData.show &&
              workflowData.workflowList.map((data: IWorkflow) => {
                return (
                  <OutreachAutomationPanel
                    key={data.id}
                    name={data.name}
                  />
                );
              })}
          </View>
        )}
        <View
          borderWidth={0.5}
          borderColor={Colors.FoldPixel.GRAY150}
          borderRadius={8}
        >
          <HStack
            borderBottomWidth={0.5}
            borderColor={Colors.FoldPixel.GRAY150}
            style={{padding: 12}}
          >
            <Text color={Colors.FoldPixel.GRAY400}>
              {intl.formatMessage({ id: 'patientOutReachAttempts' })}
            </Text>
            <View
              ml={2}
              backgroundColor={Colors.FoldPixel.GRAY50}
              borderColor={Colors.FoldPixel.GRAY200}
              borderWidth={0.5}
              borderRadius={4}
              alignSelf={'center'}
              justifyContent={'center'}
              px={1}
              color={Colors.FoldPixel.GRAY200}
            >
              {componentState.outreachStep.length}
            </View>
          </HStack>
          <FlatList
            data={componentState.outreachStep}
            contentContainerStyle={{
              padding: 12,
            }}
            renderItem={({item}) => (
              <OutreachStep
                disableCompleteButton={declineIndex ? (item.sequence > declineIndex) : false}
                syncParent={syncList}
                step={item}
                checkIfAllPreviousRequiredStepsAreCompleted={
                  checkIfAllPreviousRequiredStepsAreCompleted
                }
                anyNoteInEditMode={noteEditState.anyNoteInEditMode}
                onNoteEditModeChange={handleEditNoteChange}
                isCloseButtonClicked={noteEditState.isCloseButtonClicked}
                onCloseButtonClicked={handleCloseButtonClicked}
                userMap={componentState.userMap}
                enabledContactModes={enabledContactModes}
              />
            )}
            keyExtractor={(item) => item.id}
          />
        </View>
      </VStack>
    );
  };

  const handleOnClose = () => {
    handleCloseButtonClicked(true);
    if (!noteEditState.anyNoteInEditMode) {
      onClose(!!componentState?.isSomethingChanged);
    }
  }

  return (
    <Drawer
      destroyOnClose
      placement="right"
      className="custom-drawer-styles"
      open={true}
      closable
      width={'32%'}
      onClose={handleOnClose}
      title={
        <ModalActionTitle
          title={'Patient Outreach'}
          titleColor={''}
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: 'cancel',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              isDisabled: false,
              onClick: handleOnClose,
            },
          ]}
        />
      }
    >
      <View mx={6} mt={2}>
        {renderMainView()}
      </View>
    </Drawer>
  );
};
