import {Divider, View, useToast} from 'native-base';
import React, {useCallback, useEffect, useState} from 'react';
import {IConversationData} from '../RightSideContainer/TeamInbox/Conversations/interfaces';
import {useIntl} from 'react-intl';
import {getContactDataAndType} from '../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingUtils';
import {ToastType, showToast} from '../../utils/commonViewUtils';
import {EventBus} from '../../utils/EventBus';
import {
  COMMON_ACTION_CODES,
  CONVERSATION_ACTION_CODES,
} from '../../constants/ActionConst';
import {isWeb} from '../../utils/platformCheckUtils';
import OverrideAntdModal from '../RightSideContainer/ContentManagement/OverrideModal/OverrideAntdModal';
import {getInboxTypeCode} from '../../utils/commonUtils';
import MessagingWindow from '../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingWindow';
import {CONVERSATION_LOCAL_EVENT_CODES} from '../../constants/WebSocketConst';
import Stack from '../common/LayoutComponents/Stack';
import {Dimensions, ScrollView} from 'react-native';
import {Colors} from '../../styles';
import {NoConversationIconSvg} from './assets/images/NoConversationIconSvg';
import {DisplayText} from '../common/DisplayText/DisplayText';
import {IGroupMember} from '../../services/Conversations/interfaces';
import {SidecarPatientInfoBanner} from './SidecarPatientInfoBanner';
import GroupListByMemberV2 from '../common/GroupList/V2/GroupListByMemberV2';
import GroupListByMember from '../common/GroupList/GroupListByMember';
import InstantChatView from '../common/ChatDrawer/InstantChatView';
import { localBroadcastEvent } from '../../utils/CustomEventHandler';

interface ISidecarPatientChatManagerProps {
  groupMemberData: IGroupMember;
  onSelect?: (selectedConversation: IConversationData) => void;
  parentCode?: string;
  isDefaultClose?: boolean;
  style?: any;
  shouldDisableLimit?: boolean;
  contactData?: any;
  conversationData?: IConversationData;
  onGroupConversationListLoad?: (conversationList: any[]) => void;
  selectedConversation?: IConversationData;
}

const SidecarPatientChatManager = (props: ISidecarPatientChatManagerProps) => {
  const {groupMemberData, parentCode, conversationData} = props;
  const [groupListPanelData, setGroupListPanelData] = useState({
    isMessageWindowVisible: false,
    selectedConversation: {} as IConversationData,
    msgText: '',
    openConfirmationModal: false,
    noConversationsFound: false,
  });
  const toast = useToast();
  const intl = useIntl();
  const {height} = Dimensions.get('window');

  const contactInfo = getContactDataAndType(
    groupListPanelData?.selectedConversation || ({} as any)
  );
  const contactData: any = contactInfo?.contactData || ({} as any);
  const currentContactData: any = props?.contactData || ({} as any);

  const onSelect = (selectedConversation?: IConversationData) => {
    if (conversationData?.uuid !== selectedConversation?.uuid) {
      setGroupListPanelData((prev) => {
        return {
          ...prev,
          isMessageWindowVisible: false,
        };
      });
      setGroupListPanelData((prev) => {
        return {
          ...prev,
          isMessageWindowVisible: true,
          selectedConversation: selectedConversation || ({} as any),
          noConversationsFound: false,
        };
      });
    } else {
      showToast(
        toast,
        intl.formatMessage({id: 'aviodDrawerForSameConversation'}),
        ToastType.info,
        4000
      );
    }
  };

  const onClose = () => {
    setGroupListPanelData((prev) => {
      return {
        ...prev,
        isMessageWindowVisible: false,
        selectedConversation: {} as any,
      };
    });
    localBroadcastEvent(CONVERSATION_LOCAL_EVENT_CODES.LOCAl_UPDATE_SELECTED_CONVERSATION, {
      conversation: groupListPanelData.selectedConversation?.uuid
    });
    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent(
      CONVERSATION_ACTION_CODES.MESSAGE_READ_FROM_NOTIFICATION,
      {
        conversation: groupListPanelData.selectedConversation?.uuid,
      }
    );
  };

  const onMsgSendOnArchiveConversationListenerFn = useCallback(
    (data: {conversationData: IConversationData}) => {
      const conversationData = data?.conversationData;
      if (conversationData?.id && conversationData?.status === 1) {
        setGroupListPanelData((prev) => {
          return {
            ...prev,
            selectedConversation: {
              ...prev.selectedConversation,
              status: 0,
            },
          };
        });
      }
    },
    [groupListPanelData]
  );

  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.NEW_MESSAGE_CREATED,
      onMsgSendOnArchiveConversationListenerFn
    );
    return () => {
      eventBus.removeEventListener(onMsgSendOnArchiveConversationListenerFn);
    };
  }, [groupListPanelData?.selectedConversation?.id]);

  const onConversationActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      case CONVERSATION_ACTION_CODES.MESSAGE_READ: {
        setGroupListPanelData((prev) => {
          return {
            ...prev,
            selectedConversation: {
              ...prev.selectedConversation,
              unreadMessages: [],
              unreadMsgCount: 0,
            },
          };
        });
        break;
      }
      case COMMON_ACTION_CODES.ITEM_CLICKED:
        setGroupListPanelData((prev) => {
          return {
            ...prev,
            selectedConversation: actionData,
          };
        });
        break;
      case CONVERSATION_ACTION_CODES.DRAWER_CLOSE:
      case COMMON_ACTION_CODES.CLOSE_MODAL:
        onClose();
    }
  };

  const getInstantChatView = () => {
    return (
      <InstantChatView
        selectedConversation={groupListPanelData.selectedConversation}
        contactData={contactData}
        onActionPerformed={onConversationActionPerformed}
        isDrawerVisible={groupListPanelData.isMessageWindowVisible}
        isLoading={false}
        isInstantChatView={true}
        selectedInboxTypeCode={getInboxTypeCode(
          groupListPanelData?.selectedConversation?.conversationInbox
            ?.channelType || ''
        )}
        instantChatFromDetailPanel={true}
        moduleCode={`MessagingWindow/GroupListPanel`}
     />
    )
  }
  return (
    <Stack style={{height: '100%'}} direction="row">
      <View
        style={{
          marginBottom: 0,
          flex: 1,
          overflow: 'visible',
          borderRightWidth: 1,
          borderRightColor: Colors.Custom.Gray200,
        }}
      >
        <ScrollView>
          <GroupListByMember
            groupMemberData={groupMemberData}
            onSelect={onSelect}
            parentCode={parentCode}
            shouldDisableLimit={props?.shouldDisableLimit}
            contactData={currentContactData}
            selectedConversation={groupListPanelData?.selectedConversation}
            renderNewUI={true}            
          />
        </ScrollView>
      </View>

      {isWeb() &&
        groupListPanelData.isMessageWindowVisible &&
        !groupListPanelData.noConversationsFound &&
        getInstantChatView()}

      {isWeb() &&
        groupListPanelData.isMessageWindowVisible &&
        groupListPanelData.openConfirmationModal && (
          <OverrideAntdModal
            isOpen={groupListPanelData.openConfirmationModal}
            onClose={() => {
              setGroupListPanelData((prev) => {
                return {
                  ...prev,
                  openConfirmationModal: false,
                };
              });
            }}
            onConfirm={() => {
              setGroupListPanelData((prev) => {
                return {
                  ...prev,
                  openConfirmationModal: false,
                };
              });
              onClose();
            }}
            closeButtonText="cancel"
            textContent={{
              headerText: 'Confirmation',
              message: `Do you want to close the conversation?`,
            }}
          />
        )}
    </Stack>
  );
};
export default SidecarPatientChatManager;
