import React, {useState} from 'react';
import {
  IProps,
  useTimeZoneSelect,
} from '../../../../../../../TimezoneSelect/useTimeZoneSelect';
import Feather from 'react-native-vector-icons/Feather';
import {Popover} from 'antd';
import {Divider, FlatList, HStack, ScrollView, Stack, Text, Pressable} from 'native-base';
import {Colors} from '../../../../../../../../../styles';
import {CaretDownOutlined} from '@ant-design/icons';
import { testID, TestIdentifiers } from '../../../../../../../../../testUtils';

const TimeZoneSelectV2 = (props: IProps) => {
  const {
    showLabel,
    isRequired,
    memorizeTimezone,
    showErrors,
    showSelectBorder,
    selectColor,
    fontColor,
    width,
    label,
  } = props;
  const {
    componentState,
    setComponentState,
    getTimezonesQuery,
    getLastSavedTimezone,
    updateMemorizeTimezone,
    initializeSelectedTimezone,
  } = useTimeZoneSelect(props);
  const [showPopover, setShowPopover] = useState(false);

  return (
    <Popover
      open={showPopover}
      onOpenChange={(visible) => setShowPopover(visible)}
      trigger="click"
      content={
        <ScrollView maxH={200} overflow="scroll">
          <FlatList
            key={componentState.timezoneList.length}
            scrollEnabled={true}
            data={componentState.timezoneList}
            ItemSeparatorComponent={Divider}
            renderItem={({item}) => (
              <Pressable
                p={2}
                onPress={() => {
                  setComponentState((prev) => ({
                    ...prev,
                    selectedTimezone: item,
                  }));
                  setShowPopover(false);
                  updateMemorizeTimezone(item);
                }}
              >
                <Text
                  fontSize={12}
                  fontWeight={
                    item.uuid === componentState.selectedTimezone?.uuid
                      ? 'bold'
                      : undefined
                  }
                >
                  {item.displayValue}
                </Text>
              </Pressable>
            )}
          />
        </ScrollView>
      }
    >
      <Stack direction="row" alignItems="center">
        <Feather name="globe" size={14} color={Colors.FoldPixel.GRAY300} />
        <Pressable
          style={{
            marginLeft: 4,
            flexDirection: 'row',
            alignItems: 'center',
          }}
          disabled={props.isDisabled || componentState.isLoading}
          onPress={() => setShowPopover(!showPopover)}
        >
          <Text fontSize={12} color={Colors.FoldPixel.GRAY300} mr={1} {...testID(
            componentState.selectedTimezone?.displayValue 
              ? `Timezone_${componentState.selectedTimezone.displayValue.replace(/\s+/g, '_')}` 
              : TestIdentifiers.selectTimeZone
          )}>
            {componentState.selectedTimezone?.displayValue || 'Select Timezone'}
          </Text>
          <CaretDownOutlined
            size={12}
            style={{
              color: Colors.FoldPixel.GRAY300,
            }}
          />
        </Pressable>
      </Stack>
    </Popover>
  );
};

export default TimeZoneSelectV2;
