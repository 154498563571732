import {useMutation} from '@apollo/client';
import {Alert, Drawer} from 'antd';
import {Spinner, Text, useMediaQuery, View} from 'native-base';
import React, {useContext, useState} from 'react';
import {Dimensions} from 'react-native';
import {BUTTON_TYPE, IPAD_MINI_WIDTH, IPAD_WIDTH, MLOV_CATEGORY} from '../../../constants';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {CommonDataContext} from '../../../context/CommonDataContext';
import ContactRelation from '../../../services/ContactRelation/ContactRelation';
import { IContactRelations } from '../../../services/ContactRelation/interface';
import {Colors} from '../../../styles';
import {getMlovListFromCategory} from '../../../utils/mlovUtils';
import {ModalActionTitle} from '../ModalActionTitle/ModalActionTitle';
import {
  checkDuplicate,
  isValidArray,
  validateEmptyKey,
} from './AddContactRelationUtils';
import {styles} from './AddContactRelationViewStyles';
import ContactRelationInput from './ContactRelationInput';

const AddContactRelationView = (props: any) => {
  const { contactRelations } = props;
  const [showModal, setShowModal] = useState(true);
  const [loading, setLoading] = useState(false);
  const [validationObj, setValidationObj] = useState({
    isValid: true,
    isDuplicate: false,
    error: '',
  });
  const [CreateBatchContactRelation] = useMutation(
    ContactRelation.CreateBatchContactRelation
  );
  const [UpdateContactRelations] = useMutation(
    ContactRelation.UpdateContactRelations
  );
  const contactData = props.contactData;
  const contactId = props.contactId || contactData?.id || '';
  const [contactRelationData, setContactRelationData] = useState([] as any);
  const mlovData = useContext(CommonDataContext);
  const personRelation = getMlovListFromCategory(
    mlovData.MLOV,
    MLOV_CATEGORY.RELATIONS
  ).filter(
    (relation, index, self) =>
      index ===
      self.findIndex((rel) => rel.value === relation.value)
  );
  const closeModal = () => {
    setShowModal(false);
    props.onFormCompleteAction(COMMON_ACTION_CODES.CANCEL);
  };

  const onSubmit = async () => {
    setValidationObj({...validationObj, isValid: true});
    setValidationObj({...validationObj, isDuplicate: false});
    setLoading(true);
    if (!contactRelationData.length && contactRelationData.length === 0) {
      setValidationObj({...validationObj, isValid: false});
      setLoading(false);
      return;
    }
    if (isValidArray(contactRelationData)) {
      setLoading(false);
      setValidationObj({...validationObj, isValid: false});
      return;
    }
    if (checkDuplicate(contactRelationData)) {
      setLoading(false);
      setValidationObj({...validationObj, isValid: true, isDuplicate: true});
      return;
    }
    if (validateEmptyKey(contactRelationData)) {
      setLoading(false);
      setValidationObj({...validationObj, isValid: false});
      return;
    }
    setLoading(true);
    setValidationObj({...validationObj, isValid: true, isDuplicate: false});
    let careGiverContact: any = null;
    contactRelationData.forEach((item: any) => {
      if (item?.isCaregiver) {
        careGiverContact = item;
      }
      item.contactId = contactId;
      delete item.key;
      delete item.name;
    });

    const updatedRelatedContactIds: string[] = [];
    (contactRelations || []).forEach((contactRelation: IContactRelations) => {
      const isCaregiverOfContact = !!contactRelation?.isCaregiver;
      const matchWithNewlyAddedCareGiver = (careGiverContact && careGiverContact?.relationContactId == contactRelation?.relationContactId);
      if (isCaregiverOfContact && !matchWithNewlyAddedCareGiver && contactRelation.id) {
        updatedRelatedContactIds.push(contactRelation.id);
      }
    });

    if (updatedRelatedContactIds?.length && careGiverContact) {
      try {
         await UpdateContactRelations({
          variables: {
            idList: updatedRelatedContactIds,
            contactRelationData: {
              isCaregiver: false
            }
          }
        });
      } catch(error) {
      }
    }


    CreateBatchContactRelation({
      variables: {
        contactRelation: contactRelationData,
      },
      onCompleted: () => {
        setLoading(false);
      },
    })
      .then(() => {
        closeModal();
        props?.onRelativeAdded && props?.onRelativeAdded();
      })
      .catch(() => {
        setValidationObj({
          ...validationObj,
          error: 'Error in adding relatives',
        });
        setLoading(false);
        // closeModal();
      });
    // closeModal();
  };

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const {width} = Dimensions.get('window');

  const finalWidth = isIPadMiniScreen || isIPadScreen ? width / 2 : width / 2;

  return (
    <>
      <Drawer
        visible={showModal}
        width={finalWidth}
        onClose={() => closeModal()}
        closable={false}
        title={
          <ModalActionTitle
          title={'addContactRelation'}
          titleColor={''}
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: 'cancel',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                closeModal();
              },
            },
            {
              show: true,
              id: 2,
              btnText: 'save',
              textColor: Colors.Custom.mainPrimaryPurple,
              variant: BUTTON_TYPE.PRIMARY,
              isTransBtn: false,
              onClick: () => {
                onSubmit();
              },
            },
          ]}
        />
        }
      >
        {loading ? <Spinner size="lg" style={styles.spinnerStyle} /> : <></>}
        <View ml={0}>
          {validationObj.error && (
            <Alert message={validationObj.error} closable type="error" />
          )}
          <ContactRelationInput
            contactId={contactId}
            onFormCompleteAction={props.onFormCompleteAction}
            personRelation={personRelation}
            getInputValue={(data: any) => {
              const relativeObj = data;
              setContactRelationData(relativeObj);
            }}
          />
          {!validationObj.isValid ? (
            <Text color={Colors.Custom.ErrorColor}>Check All Input</Text>
          ) : (
            ''
          )}
          {validationObj.isDuplicate ? (
            <Text color={Colors.Custom.ErrorColor}>Duplicate Entry</Text>
          ) : (
            ''
          )}
        </View>
        {/* <Button.Group space={2} justifyContent={'flex-end'} width={'100%'}>
          <ModalActionBtn
            btnText="cancel"
            btnStype={BUTTON_TYPE.SECONDARY}
            onClickAction={() => {
              closeModal();
            }}
            customStyle={{marginRight: 20}}
          />
          <ModalActionBtn
            btnText="save"
            btnStype={BUTTON_TYPE.PRIMARY}
            onClickAction={() => {
              onSubmit();
            }}
          />
        </Button.Group> */}
      </Drawer>
    </>
  );
};

export default AddContactRelationView;
