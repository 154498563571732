import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../../styles';

export const styles = StyleSheet.create({
  awvTitle: {
    fontSize: 16,
    color: Colors.Custom.Gray900,
    fontWeight: '500',
  },
  questionsContainer: {
    margin: 12,
    marginTop: 16
  },
  awvHistoryAnswerText: {
    color: Colors.FoldPixel.GRAY300,
  }
});
