import React from 'react';
import {Pressable} from 'react-native';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import Stack from '../../../../common/LayoutComponents/Stack';
import {styles} from './ClinicalSectionFilterTabsStyles';
import Feather from 'react-native-vector-icons/Feather';
import {Colors} from '../../../../../styles';
import {IClinicalSectionFilterToggleTab} from './interfaces';
import {View} from 'react-native';

interface IClinicalSectionViewToggleProps {
  onTabClick: (codde: string) => void;
  selectedTabCode?: string;
  tabs: IClinicalSectionFilterToggleTab[];
  marginTop?: number;
  marginBottom?: number;
  isHeaderView?: boolean;
}

export const ClinicalSectionViewToggle = (
  props: IClinicalSectionViewToggleProps
) => {
  const pressableStyle = React.useMemo(() => ({
    marginBottom: props.marginBottom,
  }), [props.marginBottom]);

  const renderTab = (code: string, label?: string, icon?: string) => {
    return (
      <Pressable
        onPress={() => {
          props.onTabClick(code);
        }}
        style={pressableStyle}
      >
        <Stack
          direction="column"
          style={
            code === props.selectedTabCode ? styles.selectedView : styles.tab
          }
        >
          {label && <DisplayText textLocalId={label} />}
          {icon && (
            <Feather
              name={icon || ''}
              size={16}
              color={Colors.Custom.foldIconColor}
            />
          )}
        </Stack>
      </Pressable>
    );
  };

  return (
    <Stack direction="row" style={props?.isHeaderView ? {alignItems: 'center'} : {}}>
      {props.tabs.map((filterTab) => {
        return (
          <View key={`toggle_views_${filterTab.code}`}>
            {renderTab(filterTab.code, filterTab.label, filterTab.icon)}
          </View>
        );
      })}
    </Stack>
  );
};
