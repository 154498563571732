import {FONT_CONFIG} from '../../styles/FontConst';
import {
  CUSTOM_TABS_TEXT_CONFIG,
  DROPDOWN_OPTIONS_TEXT_CONFIG,
  EXS_CONFIG,
  INPUT_TEXT_CONFIG,
  LG_CONFIG,
  MD_CONFIG,
  SM_CONFIG,
  TABLE_HEADER_TEXT_CONFIG,
  XL_CONFIG,
  XSL_CONFIG,
  XS_CONFIG,
  XXL_CONFIG,
} from '../FontConfig/TextFontSizeConfig';

export const getTextComponentThemeConfig = (
  baseStyle?: Record<string, any>
) => {
  return {
    baseStyle: {
      color: baseStyle?.color || FONT_CONFIG.TEXT.textColor,
    },
    sizes: {
      ...XL_CONFIG,
      ...LG_CONFIG,
      ...MD_CONFIG,
      ...SM_CONFIG,
      ...XS_CONFIG,
      ...XXL_CONFIG,
      ...XSL_CONFIG,
      ...EXS_CONFIG,
      ...TABLE_HEADER_TEXT_CONFIG,
      ...DROPDOWN_OPTIONS_TEXT_CONFIG,
      ...CUSTOM_TABS_TEXT_CONFIG,
      ...INPUT_TEXT_CONFIG
    },
    defaultProps: {
      size: 'smMedium',
    },
  };
};
