import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { GET_PREVIOUS_RUNNING_TIME } from "../../services/CareProgram/CareProgramQueries";
import { CARESTUDIO_APOLLO_CONTEXT } from "../../constants/Configs";
import { ToastType, showToast } from "../../utils/commonViewUtils";
import { useToast } from "native-base";

export interface IUseTimeLogForBilling {
  contactCareProgramId?: string;
}

export interface ITimeLogForBillingState {
  contactCareProgramId: string;
  previousRecordedtime?: {
    minutes: number;
    seconds: number;
  }
  loadingPreviousRecordedTime: boolean;
}

const UseTimeLogForBilling = (params: IUseTimeLogForBilling) => {
  const toast = useToast();
  const [timeLogForBillingState, setTimeLogForBillingState] = useState<ITimeLogForBillingState>({
    contactCareProgramId: params.contactCareProgramId || '',
    loadingPreviousRecordedTime: true,
  })

  const [getPreviousRunningTime] = useLazyQuery(
    GET_PREVIOUS_RUNNING_TIME,
    {
      fetchPolicy: 'no-cache',
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      onCompleted: (response) => {
        if(response?.contactCareProgramTimerLoggings) {
          const minutes = Math.floor(response?.contactCareProgramTimerLoggings?.[0]?.timerSeconds / 60);
          const seconds = response?.contactCareProgramTimerLoggings?.[0]?.timerSeconds % 60;
          setTimeLogForBillingState((prev) => {
            return {
              ...prev,
              loadingPreviousRecordedTime: false,
              previousRecordedtime: {
                minutes: minutes,
                seconds: seconds,
              },
            };
          });
        }
      },
      onError: (err: any) => {
        setTimeLogForBillingState((prev) => {
          return {
            ...prev,
            loadingPreviousRecordedTime: false,
          };
        });
        showToast(
          toast,
          'Error in fetching previous recorded time',
          ToastType.error,
          4000
        );
      }
    }
  )

  const fetchOtherData = (contactCareProgramId: string) => {
    setTimeLogForBillingState((prev) => {
      return {
        ...prev,
        contactCareProgramId: contactCareProgramId,
        loadingPreviousRecordedTime: true,
      }
    })
    getPreviousRunningTime({
      variables: {
        where: {
          contactCareProgramId: {
            _eq: contactCareProgramId
          }
        }
      },
    })
  }

  useEffect(() => {
    if(params.contactCareProgramId) {
      fetchOtherData(params.contactCareProgramId);
    }
  }, [params.contactCareProgramId])

  return {
    contactCareProgramId: timeLogForBillingState.contactCareProgramId,
    previousRecordedTime: timeLogForBillingState.previousRecordedtime,
    timeLoggingComponentLoaders: {
      previousRecordedTimeLoading: timeLogForBillingState.loadingPreviousRecordedTime
    },
  }
}

export default UseTimeLogForBilling;
