import React from 'react';
import { Svg, Path } from 'react-native-svg';

interface IAppointmentIconSvgProps {
  backgroundColor?: string;
  customStrokeColor?: string;
  size?: number;
}

const AppointmentIconSvg = (props?: IAppointmentIconSvgProps) => {
  const size = props?.size || 16;
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 21 21"
      {...(props?.backgroundColor ? {fill: props?.backgroundColor} : {fill: "none"})}
    >
      <Path
        {...(props?.customStrokeColor ? {fill: props?.customStrokeColor} : {fill: "#6F7A90"})}
        d="M17.842 3.024l.358-.35-.358.35zm-16.263 0l-.358-.35.358.35zm14.17 12.018a.5.5 0 10-.707.707l.707-.707zm1.27 2.685a.5.5 0 10.708-.707l-.707.707zm-3.955 0a.5.5 0 00.707-.707l-.707.707zm.024-.683l-.353.353.353-.353zm3.956-3.956l.353-.353-.353.353zm-.024.684a.5.5 0 00.707-.708l-.707.707zm2.685 1.27a.5.5 0 10-.707.707l.707-.707zm-3.956 3.956a.5.5 0 00-.707.707l.707-.707zM7.87 2.418h3.684v-1H7.867v1zM18.42 9.473v1.89h1v-1.89h-1zm-6.868 8.947H7.867v1h3.684v-1zM1 11.364v-1.89H0v1.89h1zm6.868 7.057c-1.75 0-3.013-.001-3.974-.134-.945-.13-1.525-.38-1.957-.822l-.716.698c.647.664 1.475.969 2.536 1.115 1.046.144 2.39.143 4.111.143v-1zM0 11.364c0 1.767-.001 3.142.14 4.21.141 1.083.435 1.927 1.081 2.59l.716-.699c-.433-.444-.678-1.044-.806-2.02C1 14.456 1 13.159 1 11.364H0zm11.553-8.947c1.75 0 3.012.001 3.974.134.945.13 1.525.38 1.957.822l.716-.698c-.648-.664-1.476-.969-2.537-1.115-1.045-.144-2.388-.143-4.11-.143v1zm7.868 7.057c0-1.767 0-3.142-.14-4.21-.141-1.083-.436-1.927-1.081-2.59l-.716.699c.433.444.678 1.044.806 2.02.13.99.13 2.286.13 4.081h1zM7.868 1.417c-1.722 0-3.065-.001-4.11.143-1.062.146-1.89.45-2.537 1.115l.716.698C2.37 2.93 2.95 2.68 3.894 2.55c.961-.133 2.223-.134 3.974-.134v-1zM1 9.474c0-1.795.001-3.092.13-4.08.129-.977.374-1.577.807-2.021l-.716-.698C.575 3.337.281 4.18.14 5.263 0 6.333 0 7.707 0 9.474h1zm4.605-7.557V.5h-1v1.417h1zm9.21 0V.5h-1v1.417h1zM.962 7.14h17.5v-1H.96v1zm14.081 8.61l1.978 1.977.707-.707-1.978-1.978-.707.707zm-1.27 1.27l-.33-.33-.707.707.33.33.706-.707zm2.918-3.578l.33.33.707-.708-.33-.33-.707.708zm-3.955-.707a3.297 3.297 0 000 4.662l.707-.707a2.297 2.297 0 010-3.248l-.707-.707zm.707.707a2.297 2.297 0 013.248 0l.707-.707a3.297 3.297 0 00-4.662 0l.707.707zm5.556 2.307l.33.33.706-.707-.33-.33-.706.707zm-2.92 3.578l-.329-.33-.707.708.33.33.707-.708zm3.25 0a2.297 2.297 0 01-3.25 0l-.706.707a3.297 3.297 0 004.662 0l-.707-.707zm.706.707a3.297 3.297 0 000-4.662l-.707.707a2.297 2.297 0 010 3.248l.707.707z"
      ></Path>
    </Svg>
  );
};


export default AppointmentIconSvg;
