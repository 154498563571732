import {Box, Divider, HStack, Text, View} from 'native-base';
import React from 'react';
import {Colors} from '../../../../../styles';
import { GENERAL_RISK_SCORE_GROUP_NAME } from '../RiskScoreConfiguration/SectionWiseRiskScoreView';
import Stack from '../../../../common/LayoutComponents/Stack';
import { Tag } from 'antd';
import { StyleSheet } from 'react-native';

const RiskScoreDisplay = (props: {
  interpretation?: string;
  score?: number;
  name?: string;
  borderColor?: string;
  customMarginBottom?: number;
}) => {
  const {interpretation, score} = props;
  return (
    <Box
      marginX={4}
      padding={4}
      borderColor={
        props.borderColor ? props.borderColor : Colors.Custom.BorderColor
      }
      rounded="lg"
      borderWidth={1}
      marginBottom={props.customMarginBottom ? props.customMarginBottom : -4}
    >
       <HStack space={4} alignItems="center">
        <Text fontSize="sm" color={Colors.Custom.Gray500} width={150}>
          Score group
        </Text>
        <Text fontSize="md" color={Colors.Custom.TitleColor}>
          {props.name || GENERAL_RISK_SCORE_GROUP_NAME}
        </Text>
      </HStack>
      {interpretation && (
        <HStack space={4} alignItems="center">
          <Text fontSize="sm" color={Colors.Custom.Gray500} width={150}>
            Score interpretation
          </Text>
          <Text fontSize="md" color={Colors.Custom.TitleColor}>
            {interpretation}
          </Text>
        </HStack>
      )}
      {(score || score === 0) && (
        <HStack space={4} alignItems="center">
          <Text fontSize="sm" color={Colors.Custom.Gray500} width={150}>
            Score
          </Text>
          <Text fontSize="md" color={Colors.Custom.TitleColor}>
            {score}
          </Text>
        </HStack>
      )}
    </Box>
  );
};

const RiskScoreDisplayPopoverView = (props: {
  interpretation?: string;
  score?: number;
  name?: string;
}) => {
  return (
    <Stack direction='column' space={4} style={styles.container}>
      <Text fontSize='sm' color={Colors.FoldPixel.GRAY250}>{props.name}</Text>
      <Stack direction='row' space={4}>
        <Text fontSize='sm'>{props.score}</Text>
        <Tag 
          style={{border: 'none', fontSize: 12, color: Colors.Custom.GRAY350, backgroundColor: Colors.Custom.Gray50, width: 'fit-content'}}
        >
          {props.interpretation}
        </Tag>
      </Stack>
    </Stack>
  );
}

export const RiskScoreDisplayPopoverListView = (props: {
  formScores: Array<{
    metadata: {
      state?: string;
      score?: number;
      name?: string;
    }
  }>;
}) => {
  return (
    <View>
      {props.formScores.map((formScore, index) => (
        <React.Fragment key={index}>
          <RiskScoreDisplayPopoverView
            interpretation={formScore.metadata.state}
            score={formScore.metadata.score}
            name={formScore.metadata.name}
          />
          {index < props.formScores.length - 1 && (
            <View 
              style={{
                height: 0.5,
                backgroundColor: Colors.FoldPixel.GRAY150,
                marginVertical: 6
              }}
            />
          )}
        </React.Fragment>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  tag: {
    borderRadius: 4,
    fontSize: 12,
    backgroundColor: Colors.FoldPixel.GRAY50,
    color: Colors.FoldPixel.GRAY300,
    width: 'fit-content',
    border: 'none'
  },
  container: {
    paddingHorizontal: 0,
    paddingVertical: 0,
    justifyContent: 'flex-start',
    alignItems:'flex-start'
  }
});

export default RiskScoreDisplay;
