
import {useContactCareProgramContext} from '../ContactCareProgram.context';
import {useCareProgramStatus} from './useCareProgramStatus';

export const useSelectedCareProgramStatus = () => {
  const {state} = useContactCareProgramContext();
  const {contactCareProgramDetails} = state;
  const {careProgramStatusList} = useCareProgramStatus();

  const statusId = contactCareProgramDetails?.statusId as string;

  const statusObject = careProgramStatusList.find((item) => item.id === statusId)
 
  if (!statusObject) {
    throw new Error('Care program status object not found');
  }
  return {
    programStatusId: statusId,
    programStatusCode: statusObject?.code as string,
    programStatusValue: statusObject?.value as string,
  };
};
