import {Pressable, Text, View , Image, Checkbox, FormControl, Badge} from "native-base"
import Stack from "../../../../common/LayoutComponents/Stack"
import { IComparisonOperator, IComparisonOperatorElement, IInputElement } from "./FlowNodeInterface"
import ZipCodeAutoComplete from "../../../../common/ZipCodeComponent/ZipCode"
import { IZipCodeData } from "../../../../common/ZipCodeComponent/interfaces"
import React,{ useState, useEffect } from "react"
import { IFormattedPractitioner, IPractitioner, IPractitionerAPIResponse, IPractitionerEntry, IProviderDirectorySearchState } from "./interface"
import { getClinicalProviderByFilter } from "../../../../../services/CommonService/AidBoxService"
import { Spin, Select, Tag, Tooltip, } from "antd"
const { Option } = Select;
import { useIntl } from "react-intl"
import { Colors } from "../../../../../styles/Colors"
import AlphabetAvatar from "../../../../common/Avatar/AlphabetAvatar"
import Feather from 'react-native-vector-icons/Feather';
import { testID } from "../../../../../testUtils/Utils"
import { TestIdentifiers } from "../../../../../testUtils/TestIdentifiers"
import { clipText } from "../../../../PersonOmniView/MiddleContainer/PatientNotes/components/AddOrUpdateTemplate/helper"
import { styles } from "./ProviderDirectorySearchStyle"
import { CaretDownOutlined } from '@ant-design/icons';
import '../styles.css';
import CloseSvg from "../../../../common/Svg/CloseSvg"
import { capitalizeWords } from "../FlowSidebarHelper"

export const ProviderDirectorySearch = (props: IInputElement) => {
  const intl = useIntl();
  const { value, onChange, userInputField } = props;
  const [comparisonOperator, setComparisonOperator] = useState<
    IComparisonOperator | undefined
  >(
    userInputField?.selectedComparisonOperator ||
      (userInputField?.defaultOperator &&
      (userInputField?.value != undefined || userInputField?.defaultValue)
        ? {displayName: '', value: userInputField.defaultOperator}
        : undefined)
  );
  const [componentState, setComponentState] = useState<IProviderDirectorySearchState>({
    zipCode: '',
    searchValue: '',
    practitionerList: [],
    masterPractitionerList: value?.masterPractitionerList || [],
    practionerIds: value?.referralList || [],
    loading: false,
  });


  const getPractitioners = async (params: { searchString: string, practitionerIds?: string[] }) => {
    try {
      const { searchString, practitionerIds } = params;
      if (practitionerIds && practitionerIds.length > 0) {
        const promiseList: any[] = [];
        (practitionerIds || []).forEach(practitionerId => {
          promiseList.push(getClinicalProviderByFilter({ providerReferralId: practitionerId }));
        });
        const promiseResponse = await Promise.all(promiseList);
        const formattedPractitionersList: IFormattedPractitioner[] = [];
        promiseResponse.forEach((response) => {
          const formattedPractitioners = filterPractitionerResponse(response?.data);
          formattedPractitionersList.push(...formattedPractitioners);
        });
        setComponentState(prevState => ({
          ...prevState,
          practitionerList: formattedPractitionersList,
          loading: false
        }));
        return;
      }
      if (searchString && componentState?.zipCode) {
        const response = await getClinicalProviderByFilter({ zipCode: componentState?.zipCode, name: searchString });
        const formattedPractitioners = filterPractitionerResponse(response?.data);
        setComponentState(prevState => ({
          ...prevState,
          practitionerList: formattedPractitioners,
          masterPractitionerList: [...prevState.masterPractitionerList, ...formattedPractitioners],
          loading: false
        }));
      }
    } catch (error) {
      setComponentState(prevState => ({
        ...prevState,
        practitionerList: [],
        loading: false
      }));
    }
  }

  const filterPractitionerResponse = <T extends IPractitionerAPIResponse | IPractitioner>(response: T) => {
    const formattedPractitioners: IFormattedPractitioner[] = [];
    if ('entry' in response) {
      (response.entry || []).forEach((entry: IPractitionerEntry) => {
        const formattedPractitioner = formatResponse(entry.resource);
        formattedPractitioners.push(formattedPractitioner);
      });
      return formattedPractitioners;
    }
    const formattedPractitioner = formatResponse(response);
    formattedPractitioners.push(formattedPractitioner);
    return formattedPractitioners;
  }

  const formatResponse = (resource: IPractitioner) => {
    const practionerName = (resource?.name || []).map((resourceName) => resourceName.text).join(' ');
    const address: string[] = resource.address.map((address) => {
      return address.line.join(',') + ',' + address.city + ',' + address.state + ',' + address.postalCode;
    });
    const formattedPractitioner: IFormattedPractitioner = {
      id: resource.id,
      name: practionerName,
      referralAddress: address.join(','),
      isDeleted: false,
    }
    return formattedPractitioner;
  }

  const getOptions = () => {
    if (componentState.loading) {
      return (
        <Option key={`search_practitioner_loading`} disabled>
          <Spin size="small" />
        </Option>
      )
    }
    if (componentState?.practitionerList?.length) {
      return (componentState?.practitionerList || []).map((practitioner: IFormattedPractitioner) => {
        return (
          <Option key={practitioner.id} value={practitioner.id}>
            <View key={practitioner.id}>
              <Stack direction={'row'} style={styles.avatarContainer}>
                <Checkbox
                  isChecked={componentState.practionerIds.includes(practitioner.id)}
                  value=""
                  isDisabled={false}
                  background={Colors.Custom.White}
                  _hover={{value: '', shadow: 'none'}}
                  size={'md'}
                  borderWidth={1}
                  borderColor={Colors.FoldPixel.GRAY150}
                  _checked={{
                    value: '',
                    borderColor: Colors.FoldPixel.PRIMARY300,
                    backgroundColor: Colors.FoldPixel.PRIMARY300,
                  }}
                  style={styles.checkbox}
                />
                <Tooltip title={practitioner?.name} placement={'top'}>
                  <AlphabetAvatar
                    size={10}
                    style={styles.avatar}
                    disableTop
                    name={clipText(`${practitioner?.name}`, 40)}
                    textStyle={styles.avatarText}
                    userType={'USER'}
                  />
                </Tooltip>
                <Stack direction={'column'}>
                  <Text>{capitalizeWords(practitioner.name)}</Text>
                  <Text style={styles.addressText}>
                    {capitalizeWords(practitioner.referralAddress)}
                  </Text>
                </Stack>
              </Stack>
            </View>
          </Option>
        );
      });
    } else {
      return <Option key={'noData'} value={'noData'} disabled>
        {intl.formatMessage({ id: 'providerNoDataSelect' })}
      </Option>
    }
  }

  useEffect(() => {
    if (value && value?.referralList && value?.referralList?.length > 0 && !componentState?.loading) {
      setComponentState(prevState => ({
        ...prevState,
        loading: true
      }));
      getPractitioners({ practitionerIds: value?.referralList, searchString: '' });
    }
  }, []);

  const onSerachProviderDirectory = (searchString: string) => {
    const isApiCallNeeded = searchString && searchString?.length > 2 && componentState?.zipCode;
    setComponentState(prevState => ({
      ...prevState,
      searchValue: searchString,
      ...((isApiCallNeeded) && { loading: true })
    }));
    if (isApiCallNeeded) {
      getPractitioners({ practitionerIds: [], searchString: searchString });
    }
  }

  const ComparisonOperatorElement = (props: IComparisonOperatorElement) => {
    const isShowError = !!props.isShowError;
    const [operatorList] = useState(props.operatorList || []);
    const elementProperty = props?.elementProperty || {};
    if (props.value?.value && !props.value?.displayName) {
      operatorList.some((item) => {
        if (props.value?.value == item?.value) {
          props.onChange({ ...item })
        }
      })
    }
    return (
      <FormControl
        isInvalid={isShowError && !props.value}
        pointerEvents={props.isViewOnly ? 'none' : 'auto'}
      >
        <Select
          style={{textOverflow: 'ellipsis'}}
          className="comparison-operator-select"
          suffixIcon={
            <CaretDownOutlined style={{color: Colors.FoldPixel.GRAY300}} />
          }
          status={props.isShowError && !props.value ? 'error' : ''}
          allowClear={true}
          onClear={() => {
            props.onChange(undefined);
          }}
          placeholder={'Select'}
          {...elementProperty}
          value={props?.value?.value}
          showSearch={true}
          filterOption={(input, option: any) => {
            return (
              option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          }}
          onChange={(itemValue) => {
            let displayValue = itemValue;
            if (itemValue) {
              operatorList.some((unit) => {
                if (unit.value === itemValue) {
                  displayValue = unit.displayName;
                }
              });
              props.onChange({displayName: displayValue, value: itemValue});
            } else {
              props.onChange(undefined);
            }
          }}
        >
          {operatorList.map((data) => {
            return (
              <Option
                value={data.value}
                key={data.value}
                title={data.displayName}
              >
                {data.displayName}
              </Option>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  const renderZipCodeSearch = (): JSX.Element => {
    return (
      <Stack space={2} direction={'row'} style={styles.zipCodeContainer}>
        <ZipCodeAutoComplete
          isRequired={false}
          flex={1}
          name={''}
          onAutoCompleteChange={(selectedZipCode: IZipCodeData) => {
            setComponentState(prevState => ({
              ...prevState,
              zipCode: selectedZipCode?.code || '',
            }));
          }}
          onValueChange={(value: string) => {
            setComponentState(prevState => ({
              ...prevState,
              zipCode: value || '',
            }));
          }}
          showAdd={false}
        />
      </Stack>
    );
  }

  const onProviderDirectoryChange = (selectedPractionerIds: string[]) => {
    setComponentState(prevState => ({
      ...prevState,
      searchValue: '',
      practionerIds: selectedPractionerIds,
      ...(!selectedPractionerIds?.length && { practitionerList: [] }),
    }));
    onChange({ referralList: selectedPractionerIds || [], masterPractitionerList: componentState?.masterPractitionerList || [] });
  }

  const getUsersName = (ids: string[]) => {
    return (componentState.masterPractitionerList || []).filter((item) => ids.includes(item?.id)).map((item) => item?.name);
  }

  const tagRender = (tagProps: any) => {
    const onPreventMouseDown = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const label = tagProps.value && capitalizeWords(getUsersName([tagProps.value])?.[0]);
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={tagProps.closable}
        onClose={tagProps.onClose}
        className='custom-tag'
        style={{ display: 'none' }} 
        {...testID(TestIdentifiers.providerDirectorySearchTag)}
        icon={
          <Feather
            name="user"
            size={12}
            style={styles.tagIcon}
            color={Colors.Custom.Gray400}
          />
        }
      >
        {label}
      </Tag>
    );
  };

  const onUserRemove = (userId: string) => {
    const filteredIds = componentState.practionerIds?.filter((id) => id !== userId);
    setComponentState(prevState => ({
      ...prevState,
      practionerIds: filteredIds,
    }));
    onChange({ referralList: filteredIds || [], masterPractitionerList: componentState?.masterPractitionerList || [] });
  }

  const renderProviderDirectorySearch = () => {
    return (
      <Stack space={2} direction={'column'} style={styles.providerDirectoryContainer}>
        <Select
          disabled={componentState.practionerIds ? false: !componentState.zipCode}
          mode="multiple"
          //allowClear
          placeholder={componentState.loading ? "Loading..." : "Search provider (min. 3 characters)"}
          showSearch={true}
          value={componentState.practionerIds}
          searchValue={componentState.searchValue}
          filterOption={false}
          className="comparison-operator-select"
          bordered={true}
          dropdownStyle={{
            borderRadius: 8,
            width: '100%'
          }}
          tagRender={tagRender}
          onChange={onProviderDirectoryChange}
          onSearch={onSerachProviderDirectory}
          onInputKeyDown={(e) => {
            if (e.key === "Enter") {
              e.stopPropagation()
            }
          }}
          loading={componentState.loading}
          notFoundContent={intl.formatMessage({
            id: 'NoDataSelect',
          })}
          menuItemSelectedIcon={null}
          {...testID(TestIdentifiers.providerDirectorySearch)}
        >
          {getOptions()}
        </Select>
        <Stack
          direction="row"
          space={2}
          style={{
            marginTop: 2,
            flexWrap: 'wrap',
          }}
        >
          {componentState.practionerIds?.length &&
            componentState.practionerIds.map((userId) => {
              return (
                <Badge style={styles.userBadge} borderRadius={4}>
                  <Stack direction={'row'} style={{alignItems: 'center'}}>
                    <Text style={styles.userName}>
                      {capitalizeWords(getUsersName([userId])?.[0])}
                    </Text>
                    <Pressable
                      onPress={() => {
                        onUserRemove(userId);
                      }}
                    >
                      <CloseSvg/>
                    </Pressable>
                  </Stack>
                </Badge>
              );
            })}
        </Stack>
      </Stack>
    );
  }

  return (
    <>
      <View style={styles.container}>
        <Stack space={2} direction={'column'}>
          <Stack direction="row" space={4}>
            <Stack space={1} direction={'column'} style={styles.inclusionExclusionContainer}>
              <Text style={styles.labelText}>Inclusion/Exclusion</Text>
              <ComparisonOperatorElement
                isViewOnly={props.isViewOnly}
                isShowError={props.isShowError}
                operatorList={userInputField?.operatorList || []}
                value={comparisonOperator}
                onChange={(value: any) => {
                  setComparisonOperator(value);
                }}
              />
            </Stack>
            <View flex={.5}>
              {renderZipCodeSearch()}
            </View>
          </Stack>
          {renderProviderDirectorySearch()}
        </Stack>
      </View>
    </>
  );
}