import {View, VStack} from 'native-base';
import React from 'react';
import {useIntl} from 'react-intl';
import {INoDataFoundProps} from '.';
import {DisplayText} from '../DisplayText/DisplayText';
import {styles} from './NoDataFoundStyles';
import EmptyStateIconSvg from '../Svg/EmptyStateIconSvg';
const NoDataFoundWithCustomIcon = (props: INoDataFoundProps) => {
  const {icon, iconSize, iconContainerSize, isHideDisplayString, customActionElement} = props;
  const textStyle = props.textStyle || {};
  const displayTextStyle = {
    ...styles.container,
    ...styles.noDataText,
    ...{textAlign: 'center'},
    ...textStyle,
  };
  const intl = useIntl();
  return (
    <VStack
      style={props?.containerStyles}
      justifyContent={'center'}
      alignItems={'center'}
      flex={1}
      px={10}
    >
      {icon ? (
        <View
          style={{
            width: iconContainerSize || 84,
            height: iconContainerSize || 84,
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 16,
          }}
        >
          <EmptyStateIconSvg size={iconSize || 112} />
          <View style={{position: 'absolute'}}>{icon}</View>
        </View>
      ) : null}
      <VStack style={{alignItems: 'center'}}>
        {!isHideDisplayString ? (
          <View height={50}>
            <DisplayText
              textLocalId={props.displayString || 'noDataFound'}
              extraStyles={displayTextStyle || styles.noDataText}
            />
          </View>
        ) : null}
      {customActionElement ? customActionElement : null}
      </VStack>
    </VStack>
  );
};

export default NoDataFoundWithCustomIcon;
