import {useLazyQuery, useMutation} from '@apollo/client';
import {
  FIELD_TO_ATTRIBUTE_KEY_MAP,
  IComponentState,
  IDischargeDetailsComponentValue,
  IDiagnosisCodes,
} from './interface';
import {GET_ADDITIONAL_ATTRIBUTES_DATA_BY_CONTACT_CARE_PROGRAM_ID} from '../../../../../../../services/ContactCareProgram/ContactCareProgram';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../../../constants/Configs';
import {
  calculateLengthOfStay,
  getFormattedDiagnosisCodes,
} from './DischargeDetailsFormUtils';
import {getFormttedAdditionalAttributes} from './DischargeDetailsFormUtils';
import {ADDITIONAL_ATTRIBUTE_KEY} from '../../../../../../common/MemebersView/constant';
import {
  ADMISSION_TYPE,
  CARE_PROGRAM_ADDITIONAL_ATTRIBUTES_SOURCE,
} from '../../../../FormsConstants';
import {useCallback, useEffect} from 'react';
import {ADD_OR_UPDATE_STEP_ADDITIONAL_ATTRIBUTES} from '../../../../../../../services/CareProgram/CareProgramQueries';
import {IAddOrUpdateCareProgramAttributesPayload} from '../../../../../ContactCareProgram/interface';
import debounce from 'lodash/debounce';

export const useDischargeDetailsView = (params: {
  componentValue: IDischargeDetailsComponentValue;
  setComponentValue: React.Dispatch<
    React.SetStateAction<IDischargeDetailsComponentValue>
  >;
  setComponentState: React.Dispatch<React.SetStateAction<IComponentState>>;
  contactCareProgramId: string;
  componentSelectedValue?: any;
}) => {
  const {
    componentValue,
    setComponentValue,
    setComponentState,
    contactCareProgramId,
    componentSelectedValue,
  } = params;

  const [getCareProgramAdditionalAttributes] = useLazyQuery(
    GET_ADDITIONAL_ATTRIBUTES_DATA_BY_CONTACT_CARE_PROGRAM_ID,
    {
      variables: {
        contactCareProgramId,
      },
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const [addOrUpdateCareProgramAttributes] = useMutation(
    ADD_OR_UPDATE_STEP_ADDITIONAL_ATTRIBUTES,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    }
  );

  const debouncedMutation = useCallback(
    debounce(async (attributeCode: string, value: any) => {
      const attributeKey = FIELD_TO_ATTRIBUTE_KEY_MAP[attributeCode];
      if (!attributeKey) {
        return;
      }

      const params: IAddOrUpdateCareProgramAttributesPayload = {
        careProgramAttributes: [
          {
            contactCareProgramId: contactCareProgramId,
            subjectId: contactCareProgramId,
            subjectTypeCode: 'CARE_PROGRAM',
            additionalAttributes: [
              {
                attributeKey: attributeKey,
                attributeValueDataTypeCode: 'string',
                attributeValue: {
                  valueString: value,
                },
                isDeleted: false,
              },
            ],
          },
        ],
      };

      await addOrUpdateCareProgramAttributes({
        variables: {params},
      });
    }, 500),
    [contactCareProgramId, addOrUpdateCareProgramAttributes]
  );

  const addOrUpdateCareProgramAttributesMutation = (
    attributeCode: string,
    value: any
  ) => {
    debouncedMutation(attributeCode, value);
  };

  const fetchCareProgramAdditionalAttributes = async () => {
    const response = await getCareProgramAdditionalAttributes();
    const careProgramAdditionalAttributes =
      response?.data?.contactCarePrograms?.[0]?.additionalAttributes;
    if (careProgramAdditionalAttributes) {
      const formattedAdditionalAttributes = getFormttedAdditionalAttributes(
        careProgramAdditionalAttributes
      );
      const lengthOfStay = calculateLengthOfStay(
        formattedAdditionalAttributes?.[ADDITIONAL_ATTRIBUTE_KEY.DISCHARGE_DATE]
          ?.value,
        formattedAdditionalAttributes?.[ADDITIONAL_ATTRIBUTE_KEY.DISCHARGE_DATE]
          ?.value
      );
      setComponentValue((prev) => {
        return {
          ...prev,
          dischargeDetails: {
            ...prev.dischargeDetails,
            hospitalName: {
              value:
                formattedAdditionalAttributes?.[
                  ADDITIONAL_ATTRIBUTE_KEY.FACILITY
                ]?.value || '',
              source:
                formattedAdditionalAttributes?.[
                  ADDITIONAL_ATTRIBUTE_KEY.FACILITY
                ]?.source || CARE_PROGRAM_ADDITIONAL_ATTRIBUTES_SOURCE.FOLD,
            },
            primaryDiagnosis: {
              value:
                formattedAdditionalAttributes?.[
                  ADDITIONAL_ATTRIBUTE_KEY.PRIMARY_DIAGNOSIS_CODE
                ]?.value || [],
              source:
                formattedAdditionalAttributes?.[
                  ADDITIONAL_ATTRIBUTE_KEY.PRIMARY_DIAGNOSIS_CODE
                ]?.source || CARE_PROGRAM_ADDITIONAL_ATTRIBUTES_SOURCE.FOLD,
            },
            admissionDate: {
              value:
                formattedAdditionalAttributes?.[
                  ADDITIONAL_ATTRIBUTE_KEY.ADMITTED_DATE
                ]?.value || '',
              source:
                formattedAdditionalAttributes?.[
                  ADDITIONAL_ATTRIBUTE_KEY.ADMITTED_DATE
                ]?.source || CARE_PROGRAM_ADDITIONAL_ATTRIBUTES_SOURCE.FOLD,
            },
            dischargeDate: {
              value:
                formattedAdditionalAttributes?.[
                  ADDITIONAL_ATTRIBUTE_KEY.DISCHARGE_DATE
                ]?.value || '',
              source:
                formattedAdditionalAttributes?.[
                  ADDITIONAL_ATTRIBUTE_KEY.DISCHARGE_DATE
                ]?.source || CARE_PROGRAM_ADDITIONAL_ATTRIBUTES_SOURCE.FOLD,
            },
            admissionType: {
              value:
                formattedAdditionalAttributes?.[
                  ADDITIONAL_ATTRIBUTE_KEY.ADMISSION_TYPE
                ]?.value || ADMISSION_TYPE.OBSERVATION,
              source:
                formattedAdditionalAttributes?.[
                  ADDITIONAL_ATTRIBUTE_KEY.ADMISSION_TYPE
                ]?.source || CARE_PROGRAM_ADDITIONAL_ATTRIBUTES_SOURCE.FOLD,
            },
            lengthOfStay: {
              value: lengthOfStay,
              source: CARE_PROGRAM_ADDITIONAL_ATTRIBUTES_SOURCE.FOLD,
            },
            lengthOfStayUnit: {
              value: 'Days',
              source: CARE_PROGRAM_ADDITIONAL_ATTRIBUTES_SOURCE.FOLD,
            },
          },
        };
      });
      setComponentState((prev) => ({
        ...prev,
        loadingStatus: 'loaded',
      }));
    }
  };

  const updateDischargeDetails = (
    field: keyof IDischargeDetailsComponentValue['dischargeDetails'],
    value: any,
    source: string = CARE_PROGRAM_ADDITIONAL_ATTRIBUTES_SOURCE.FOLD
  ) => {
    setComponentValue((prev) => {
      const updatedDischargeDetails = {
        ...prev.dischargeDetails,
        [field]: {
          value,
          source,
        },
      };
      if (
        (field === 'admissionDate' || field === 'dischargeDate') &&
        updatedDischargeDetails.admissionDate.value &&
        updatedDischargeDetails.dischargeDate.value
      ) {
        const lengthOfStay = calculateLengthOfStay(
          updatedDischargeDetails.admissionDate.value,
          updatedDischargeDetails.dischargeDate.value
        );
        updatedDischargeDetails.lengthOfStay = {
          value: lengthOfStay,
          source: CARE_PROGRAM_ADDITIONAL_ATTRIBUTES_SOURCE.FOLD,
        };
      }
      return {
        ...prev,
        dischargeDetails: updatedDischargeDetails,
      };
    });
    addOrUpdateCareProgramAttributesMutation(field, value);
  };

  const fetchInitialData = async () => {
    if (contactCareProgramId && !componentSelectedValue?.dischargeDetails) {
      fetchCareProgramAdditionalAttributes();
    } else {
      setComponentState((prev) => ({
        ...prev,
        loadingStatus: 'loaded',
      }));
    }
    const diagnosisCodes = await getFormattedDiagnosisCodes();
    setComponentState((prev) => ({
      ...prev,
      primaryDiagnosisOptions: diagnosisCodes,
      dischargeDiagnosisOptions: diagnosisCodes,
    }));
  };
  


  const onSearchDiagnosisCodes = async (value: string, diagnosisCodes: keyof IDiagnosisCodes) => {
    try {
      setComponentState((prev: IComponentState) => ({
        ...prev,
        diagnosisCodesLoading: true,
        [diagnosisCodes]: []
      }));

      const results = await getFormattedDiagnosisCodes(value);
      
      setComponentState((prev: IComponentState) => ({
        ...prev,
        diagnosisCodesLoading: false,
        [diagnosisCodes]: results
      }));
    } catch (error) {
      setComponentState(prev => ({
        ...prev,
        diagnosisCodesLoading: false
      }));
    }
  };

  const debouncedSearchDiagnosisCodes = useCallback(
    debounce((value: string, diagnosisCodes: keyof IDiagnosisCodes) => 
      onSearchDiagnosisCodes(value, diagnosisCodes), 
    500),
    []
  );

  const validateData = () => {
    // Add your validation logic here
    return {
      isValid: true,
      message: '',
    };
  };

  useEffect(() => {
    fetchInitialData();
  }, [contactCareProgramId]);

  return {
    updateDischargeDetails,
    validateData,
    addOrUpdateCareProgramAttributesMutation,
    debouncedSearchDiagnosisCodes,
    onSearchDiagnosisCodes
  };
};
