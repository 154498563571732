import React from 'react';
import {Box, HStack, Text} from 'native-base';
import { Colors } from '../../../../styles';

const AddOrUpdateAvailabilityFormLabels = () => {
  return (
    <HStack mb={2} space={2}>
      <HStack flex={3} space={2}>
        <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250} flex={5}>From time</Text>
        <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250} flex={5}>To time</Text>
      </HStack>
      <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250} ml={10} flex={3}>
        Location
      </Text>
      {/* <Text ml={10} flex={2}>Timezone</Text> */}
      <Text flex={1}></Text>
      <Box paddingX={4}></Box>
    </HStack>
  );
};

export default AddOrUpdateAvailabilityFormLabels;
