import React from 'react';
import styles from './ProgramCompletionView.module.css';

interface StatusItemProps {
  icon: React.ReactNode;
  text: string;
  alt: string;
}

const StatusItem: React.FC<StatusItemProps> = ({icon, text, alt}) => {
  return (
    <div className={styles.statusItem}>
      {icon}
      <div className={styles.statusText}>{text}</div>
    </div>
  );
};

export default StatusItem;
