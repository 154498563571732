import React, {useState, useEffect} from 'react';
import {
  ITaskViewContext,
  TaskViewContext,
} from '../context/TaskViewContext';

export const TaskViewProvider = ({
  children,
  isRenderCompactView,
  isShowGrayBackground
}: {
  children: React.ReactNode;
  isRenderCompactView: boolean;
  isShowGrayBackground?: boolean;
}) => {
  const [taskdata] = useState<ITaskViewContext>({
    isRenderCompactView: isRenderCompactView,
    isShowGrayBackground: isShowGrayBackground
  });

  return (
    <TaskViewContext.Provider
      value={{...taskdata}}
      key={`patient-task-context-provider`}
    >
      {children}
    </TaskViewContext.Provider>
  );
};

export default TaskViewProvider;
