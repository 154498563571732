import {useLazyQuery} from '@apollo/client';
import {AutoComplete, Select, Spin} from 'antd';
import {debounce} from 'lodash';
import {Pressable, Text, View} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {LeadQueries} from '../../../services';
import {Colors} from '../../../styles';
import {getPatientSearchORCondition} from '../../RightSideContainer/Contacts/ContactsUtils';
import {DisplayText} from '../DisplayText/DisplayText';
import MemberInfoListItem from '../MemberInfoListItem/MemberInfoListItem';
import {ModalActionAutoComplete} from '../ModalActionCommonComponent/ModalActionAutoComplete';
import {filterExistingContacts} from './ContactRelationUtils';
import {EventBus} from '../../../utils/EventBus';
import {IContact} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import { CommonDataContext } from '../../../context/CommonDataContext';
import {USER_ACCESS_PERMISSION} from '../../RightSideContainer/UserAccess/UserAccessPermission';
import {MAIN_MENU_CODES} from '../../SideMenuBar/SideBarConst';
import {getAllowedUserPracticeLocationUuids, getCaslAbility, getUserUUID} from '../../../utils/commonUtils';
import FoldPermitCan from '../../CommonComponents/FoldPermitCan/FoldPermitCan';
import { CONFIG_CODES } from '../../../constants/AccountConfigConst';
import { isAccountConfigEnabled } from '../../../utils/configUtils';
import UserPracticeLocationQueries from '../../../services/Location/UserPracticeLocationQueries';
import { getLocationUuidFromPracticeLocations } from '../CustomUserSearch/CustomUserSearchUtils';
import { getEhrConfig } from '../../../utils/capabilityUtils';
const {Option} = AutoComplete;

const ContactAutoComplete = (props: any) => {
  const allowedUserPracticeLocationUuids = getAllowedUserPracticeLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.INBOX
  );
  const isCommunicationLocationHandlingEnabled = isAccountConfigEnabled(
    CONFIG_CODES.ENABLE_COMMUNICATION_LOCATION_HANDLING
  );
  const userUuid = getUserUUID();
  const [contacts, setContacts] = useState<any>([]);
  const [isClicked, setIsClicked] = useState(false);
  const ehrConfig = getEhrConfig('', '');
  const isAthena = ehrConfig.isAthena;
  const [stateData,setStateData] = useState({
    index: -1,
    value: props.contactData?.[props.index || 0]?.name,
    searchValue: props.contactData?.[props.index || 0]?.name,
    locationUuids: [] as string[],
  })
  const commonData = useContext(CommonDataContext);
  const isSideCarContext = commonData.sidecarContext?.isSidecar;

  const [getContacts] = useLazyQuery<{contacts: any[]}, any>(
    props?.contactId ? LeadQueries.SearchContacts : LeadQueries.SearchContacts3,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const [loadingStates, setLoadingStates] = useState(false);

  const searchContacts = (searchString: string) => {
    setContacts([]);
    if (searchString.length > 1) {
      setLoadingStates(true);
      let searchStringArray = searchString?.split(' ');
      searchStringArray = searchStringArray?.filter((char: any) => {
        return char.length >= 1;
      });
      let where = {};
      if (isCommunicationLocationHandlingEnabled) {
        where = {
          id: {_neq: props.contactId},
          _or: getPatientSearchORCondition(searchString, searchStringArray, '', isAthena),
          contactPracticeLocations: {
            practiceLocationUuid: {
              _in: allowedUserPracticeLocationUuids,
            },
          },
        };
      } else {
        where = {
          id: {_neq: props.contactId},
          _or: getPatientSearchORCondition(searchString, searchStringArray, '', isAthena),
        };
      }
      getContacts({
        variables: {
          searchString: '%' + searchString + '%',
          limit: 50,
          contactId: props?.contactId,
          where: where
        },
      })
        .then((contactRespData) => {
          const contactFilterData = filterExistingContacts(
            contactRespData?.data?.contacts || [],
            props?.contactId || ''
          );
          setContacts(contactFilterData || []);
          setLoadingStates(false);
        })
        .catch(() => {
          setContacts([]);
          setLoadingStates(false);
        });
    }
  };

  const addContact = () => {
    return (
      <Pressable
        onPress={() => {
          setIsClicked(true)
          setStateData((prev)=>{
            return {
              ...prev,
              index: props?.index
            }
          })
          props.onFormCompleteAction(COMMON_ACTION_CODES.EDIT, {});
        }}
      >
        <Text size={'smRegular'} color={Colors.FoldPixel.GRAY400}>
          <DisplayText textLocalId={'addContact'} />
        </Text>
      </Pressable>
    );
  };

  const autoFillContact = (data:any) => {
    if(props?.index === stateData.index) {
      setStateData((prev)=>{
        return {
          ...prev,
          name: data?.contactName,
          searchValue: data?.contactName
        }
      })
      const options: any = {
        key: data?.contactId || -1,
        value: data?.contactId || -1,
        name: data?.contactName,
      };
      props.onAutoCompleteChange(
        COMMON_ACTION_CODES.ITEM_SELECT,
        {...options}
      );
    }
  }

  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(
      COMMON_ACTION_CODES.ADDED_OR_UPDATED,
      autoFillContact
    );
    return () => {
      eventBus.removeEventListener(autoFillContact);
    };
  }, [stateData.index]);


  return (
    <View>
      <ModalActionAutoComplete
        placeholder={
          <DisplayText
            extraStyles={{color: Colors.Custom.IconColorGray}}
            textLocalId={'searchContacts'}
          />
        }
        onSearch={debounce(searchContacts, 500)}
        value={stateData?.searchValue}
        defaultValue={stateData?.searchValue}
        onChange={(value: any, options: any)=> {
          setStateData(prev=> {
            return {
              ...prev,
              searchValue: value,
            }
          })
        }}
        onSelect={(value: any, options: any) => {
          const selectedItem = contacts?.find((contact: IContact)=> {
            return contact.id == options.key
          })
          if (selectedItem?.id) {
            options = {...options, name: selectedItem?.name}
            setStateData(prev=> {
              return {
                ...prev,
                searchValue: selectedItem?.name,
                value: selectedItem?.name,
              }
            })
          }
          props.onAutoCompleteChange(
            COMMON_ACTION_CODES.ITEM_SELECT,
            options || ''
          );
        }}
        notFoundContent={loadingStates && <Spin size="small" />}
        customStyle={{flex: 1}}
        data={contacts}
        onFocus={()=>{
          setIsClicked(false)
        }}
        alternateOption={
          <FoldPermitCan 
            resource={MAIN_MENU_CODES.CONSUMER} 
            action={USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code}
            component={isSideCarContext ? <></> : addContact()}
          />
        }
        showAlternateOption={contacts.length <= 0 && !isClicked ? true : false}
        optionProps={{id: 'id', code: 'id', value: 'name'}}
        isContactCustomDropdown={(contactData: any) => {
          return (
            <MemberInfoListItem
              showDateOfBirth={true}
              contactData={contactData}
              isPatientSearchCard
              showPhoneNumber={true}
              showEmail={true}
              showTagging
            />
          )
        }}
      />
    </View>
  );
};

export default ContactAutoComplete;
