import { HStack, Pressable, Text, View, useToast } from "native-base"
import { Colors } from "../../../../styles"
import AntIcon from 'react-native-vector-icons/AntDesign';
import { useState } from "react";
import { Popover } from "antd";
import { Dimensions, StyleSheet } from 'react-native';
import {BoardType, TaskFilterType} from "./interfaces";
import {ToastType, showToast} from "../../../../utils/commonViewUtils";
import {useIntl} from "react-intl";
import Stack from "../../LayoutComponents/Stack";
import React from "react";
import SelectDownArrowSvg from "../../Svg/SelectDownArrowSvg";
import { testID, TestIdentifiers } from "../../../../testUtils";


export const FilterWrapperComponent = (props: {
    label?: string;
    children?: any;
    width?: number | string;
    popoverMinHeight?: number;
    popoverMaxHeight?: number;
    onClose?: () => void;
    value?: any[] | any;
    textWeight?:number;
    maxWidth?: number | string;
    minWidthDropdown?:number;
    dropdownMarginLeft?:number;
    filterType?: string;
    selectDefaultUser?: ()=> void;
    overlayClassName?: string
    marginBottom?: number;
    maxNumberOfAppliedFiltersToshow?:number;
    showFilterAsDropDown?: boolean;
    filterTextStyle?: any;
    filterValueTextStyle?: any;
    contentStyle?: any
    contentMinHeight?: number;
    noJustify?: boolean;
    isMultiSelect?: boolean;
    isControlledPopover?: boolean;
    open?: boolean;
    onOpenChange?: (visible: boolean) => void;
    popoverStyle?: any;
    isDayOptimizerCalender?: boolean;
}) => {
    const [showChild, setShowChild] = useState(false);
    const {width} = Dimensions.get('window');
    const toast = useToast();
    const intl = useIntl();

    const isControlled = props.isControlledPopover && props.open !== undefined;
    const isOpen = isControlled ? props.open : showChild;

    const checkPopoverCloseAllowed = ()=> {
      switch(props?.filterType) {
        case TaskFilterType?.Assignee:
        case TaskFilterType?.CreatedBy:
        case TaskFilterType?.Mention:
          if (!props?.value?.length) {
            return false;
          } else {
            return true;
          }
        default:
          return true;
      }
    }

    const getPopoverCloseMessage = (action?: string)=> {
      switch(action) {
        case TaskFilterType?.Assignee:
          return intl.formatMessage({
            id: 'assigneeNotSelectedInfo',
          });
        case TaskFilterType?.Mention:
          return intl.formatMessage({
            id: 'mentionNotSelectedInfo',
          });
        case TaskFilterType?.CreatedBy:
          return intl.formatMessage({
            id: 'createdByNotSelectedInfo',
          });
        default:
          return '';
      }
    }

    const showPopoverCloseMessage = ()=> {
      const message = getPopoverCloseMessage(props?.filterType || '')
      showToast(
        toast,
        message,
        ToastType.info,
        2500,
      );
      props?.selectDefaultUser?.();
    }

    const getAppliedFiltersText = () => {
      if (!!props?.maxNumberOfAppliedFiltersToshow) {
        return props?.value
          ?.slice(0, props.maxNumberOfAppliedFiltersToshow)
          ?.map((item: any) => item?.toString())
          ?.join(', ');
      } else return props?.value?.[0]?.toString();
    }

    return (
      <Stack space={4} direction="column" style={props.showFilterAsDropDown ? styles.flexOne : {}}>
      {props.showFilterAsDropDown && props.label ? (
        <Text
          style={[
            styles.filterLabel,
            props?.filterTextStyle,
            { color: Colors.Custom.Gray400 }
          ]}
        >
          {props.label}
        </Text>
      ) : null}
      <Popover
        content={
          <View
            style={[styles.popoverContent, props?.popoverStyle, {
              minHeight: props?.popoverMinHeight || 50,
              maxHeight: props?.popoverMaxHeight || 'auto',
            }]}
            backgroundColor={Colors.Custom.ContainerBGColor}
          >
            {props.children}
          </View>
        }
        overlayInnerStyle={props?.popoverStyle ? props?.popoverStyle : {}}
        overlayClassName={props?.overlayClassName || ''}
        trigger={'click'}
        placement="bottomLeft"
        open={isOpen}
        onOpenChange={(visible: boolean) => {
          const isPopoverCloseAllowed = checkPopoverCloseAllowed();
          if (isPopoverCloseAllowed) {
            if (isControlled) {
              props.onOpenChange?.(visible);
            } else {
              setShowChild(visible);
            }
            if (!visible) {
              props?.onClose?.();
            }
          } else {
            showPopoverCloseMessage();
          }
        }}
      >
        <Pressable
          onPress={() => {
            const visible = !showChild;
            if (isControlled) {
              props.onOpenChange?.(visible);
            } else {
              setShowChild(visible);
            }
            if (!visible) {
              props?.onClose?.();
            }
          }}
          flexDirection={'row'}
          {...testID(props?.label ? props.label : TestIdentifiers.customFilterWrapper)}
          alignItems="center"
          style={StyleSheet.flatten([
            styles.pressableContainer,
            {
              minHeight: props?.contentMinHeight ?? 40,
              marginLeft: props?.dropdownMarginLeft != undefined ? props?.dropdownMarginLeft : 8,
              minWidth: props?.minWidthDropdown || 'auto',
              marginBottom: props.marginBottom,
            },
            props?.contentStyle,
            props?.showFilterAsDropDown ? styles.fullWidth : {},
            props?.isDayOptimizerCalender ? {
              minHeight: undefined,
              borderWidth: 0.5,
              borderColor: Colors.FoldPixel.GRAY250,
              paddingHorizontal: 6,
              paddingVertical: 5,
              borderRadius: 4,
            } : {},
          ])}
        >
          {!props.showFilterAsDropDown && props.label ? (
            <Text size={'smRegular'} style={[styles.filterLabel, props?.filterTextStyle]}>
              {props.label}
              {props.value?.length ? ': ' : ''}{' '}
            </Text>
          ) : null}
          {props.value?.length ? (

            props.showFilterAsDropDown ? (
              <View style={styles.filterValuesContainer}>
                {props.value?.map((item: string, index: number) => {
                  return (
                    <Text
                      key={`Filter_Value_${item}_${index}`}
                      style={props.isMultiSelect ? styles.multiSelectFilterValue : styles.singleSelectFilterValue}>
                      {item}
                    </Text>
                  )
                })}
              </View>
            ) :( <>
              <View
                style={{
                  maxWidth: props.maxWidth || width / 16,
                }}
              >
                <Text
                  size={'smMedium'}
                  overflow={'hidden'}
                  textOverflow={'ellipsis'}
                  whiteSpace={'nowrap'}
                  fontWeight={props?.textWeight || 500}
                  style={[
                    styles.filterValueText,
                    props?.filterValueTextStyle,
                    props?.isDayOptimizerCalender ? {
                      fontSize: 12,
                      color: Colors.FoldPixel.GRAY250,
                    } : {},
                  ]}
                >
                  {props.value[0]?.toString()}
                </Text>
              </View>
              {props.value?.length > 1 ? (
                <Text
                  size={'smMedium'}
                  fontWeight={props?.textWeight || 500}
                  style={[styles.filterValueText, props?.filterValueTextStyle]}
                >
                  {'• + ' + (props.value.length - 1)}{' '}
                </Text>
              ) : null}{' '}
            </>)
          ) :
          (props.showFilterAsDropDown && props.label ? (
            <Text style={styles.placeholderText}>
              {`Select ${props.label}`}
            </Text>
          ) : null)}
          {props?.isDayOptimizerCalender ? (
              <View marginLeft={'8px'}>
                <SelectDownArrowSvg
                  customStrokeColor={Colors.FoldPixel.GRAY300}
                />
              </View>
            ) : (
              <AntIcon
                name="caretdown"
                color={Colors.Custom.Gray400}
                size={8}
              />
          )}
        </Pressable>
      </Popover>
      </Stack>
    );
}

const styles = StyleSheet.create({
  flexOne: {
    flex: 1,
  },
  filterLabel: {
    marginRight: 2,
    color: Colors.FoldPixel.GRAY300
  },
  popoverContent: {
    // backgroundColor: Colors.Custom.ContainerBGColor,
    position: 'relative',
    overflow: 'scroll',
  },
  pressableContainer: {
    borderWidth: 1,
    borderRadius: 5,
    borderColor: Colors.Custom.Gray200,
    justifyContent: 'space-between',
    backgroundColor: Colors.Custom.ContainerBGColor,
    paddingHorizontal: 10,
  },
  fullWidth: {
    width: '100%',
  },
  filterValuesContainer: {
    flex: 1,
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  multiSelectFilterValue: {
    backgroundColor: Colors.Custom.Gray100,
    color: Colors.Custom.Gray500,
    paddingHorizontal: 6,
    marginVertical: 4,
    marginRight: 4,
    borderRadius: 4,
  },
  singleSelectFilterValue: {
    color: Colors.Custom.Gray700,
  },
  filterValueText: {
    color: Colors.FoldPixel.GRAY400,
    marginRight: 2,
  },
  placeholderText: {
    fontSize: 14,
    color: Colors.Custom.Gray400,
  },
});

export default React.memo(FilterWrapperComponent);
