import {useIntl} from 'react-intl';
import CustomConfirmationModal from '../../../../../../common/CustomConfirmationModal/CustomConfirmationModal';
import {StyleSheet, View} from 'react-native';
import WarningSvgV2 from '../../../../../../common/Svg/WarningSvgV2';
import {Colors} from '../../../../../../../styles';
import {Text} from 'native-base';
import {EventBus} from '../../../../../../../utils/EventBus';
import {CARE_PROGRAM_EVENT_CODES} from '../../../../../Contacts/Leads/LeadView/LeadTableView/Helper/CareProgramConst';
import React from 'react';
import { replaceHashValueToString } from '../../../../../../../utils/commonUtils';
const GenerateBillActionView = React.memo((props: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onTimeLog: () => void;
  totalMinutes: number;
  contactCareProgramId?: string;
  onIgnore?: () => Promise<void>;
}) => {
  const intl = useIntl();

  const handleIgnore = async () => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent(CARE_PROGRAM_EVENT_CODES.SAVE_RECORDED_TIME, {
      contactCareProgramId: props.contactCareProgramId,
    });

    if (props.onIgnore) {
      await props.onIgnore();
    }
    props.onClose();
  };

  return (
    <CustomConfirmationModal
      isOpen={props.isOpen}
      onCancel={handleIgnore}
      onConfirm={props.onConfirm}
      headerText={intl.formatMessage({id: 'unloggedTimeConfirmationTitle'})}
      customIcon={<WarningSvgV2 strokeColor={Colors.Custom.Gray400} />}
      modalContent={
        <View style={styles.confirmationModalContent}>
          <Text style={styles.confirmationModalText}>
            {replaceHashValueToString(
            {totalMinutes: props.totalMinutes.toString()},
            intl.formatMessage({id: 'unloggedTimeConfirmationMessage'})
          )}
          </Text>
        </View>
      }
      customCancelBtnText={intl.formatMessage({id: 'ignore'})}
      customOkBtnText={intl.formatMessage({id: 'logTime'})}
      customOkBtnStyle={{
        backgroundColor: Colors.Custom.mainPrimaryPurple,
        borderWidth: 0,
      }}
      customOkBtnTextStyle={{
        color: Colors.Custom.White,
      }}
      customCancelBtnStyle={{
        backgroundColor: 'transparent',
        borderColor: Colors.Custom.Gray400,
      }}
      customCancelBtnTextStyle={{
        color: Colors.Custom.Gray400,
      }}
    />
  );
});

export default GenerateBillActionView;

const styles = StyleSheet.create({
  confirmationModalContent: {
    marginTop: 2,
  },
  confirmationModalText: {
    color: Colors.Custom.Gray400,
    fontSize: 14,
    lineHeight: 16.8,
    fontWeight: '400',
    textAlign: 'center',
  },
});
