import {Drawer, Switch} from 'antd';
import {
  Center,
  HStack,
  Icon,
  Pressable,
  Spinner,
  Text,
  VStack,
  View,
  useMediaQuery,
  useToast,
} from 'native-base';
import React, {useEffect, useState} from 'react';
import {IPAD_MINI_WIDTH, IPAD_WIDTH, NOTIFICATION_SETTING} from '../../../constants/StringConst';
import {ModalActionTitle} from '../ModalActionTitle/ModalActionTitle';
import AntIcon from 'react-native-vector-icons/AntDesign';
import NotificationSvg from '../Svg/NotificationSvg';
import {Colors} from '../../../styles/Colors';
import SmsActionSvg from '../Svg/PersonActionSvgIcons/SmsActionSvg';
import ChatActionSvg from '../Svg/PersonActionSvgIcons/ChatActionSvg';
import CallActionSvg from '../Svg/PersonActionSvgIcons/CallActionSvg';
import MentionIcon from '../Svg/ConversationAllSvg/MentionIcon';
import MissedCallSvg from '../Svg/ConversationAllSvg/MissedCallSvg';
import PracticeInvite from '../Svg/GlobalActionSvg/PracticeInvite';
import Feather from 'react-native-vector-icons/Feather';
import Fontisto from 'react-native-vector-icons/Fontisto';
import BaseService from '../../../services/CommonService/BaseService';
import {getAccountUUID, getUserUUID} from '../../../utils/commonUtils';
import {getFormatedNotification} from './NotificationsUtils';
import NotificationResourceSetting from './NotificationResourceSetting';
import { useIntl } from 'react-intl';
import { ToastType, showToast } from '../../../utils/commonViewUtils';
import EFaxActionSvg from '../Svg/PersonActionSvgIcons/EFaxActionSvg';
import { isAccountConfigEnabled } from '../../../utils/configUtils';
import { CONFIG_CODES } from '../../../constants/AccountConfigConst';
import {StyleSheet} from 'react-native';

export const getNotificationSettingApi = async () => {
  const baseService = BaseService.getSharedInstance().axios;
  return await baseService.get('crm-nest/api/v1/notification-setting');
};

export const updateNotificationSettingApi = async (notificationArray: any) => {
  const baseService = BaseService.getSharedInstance().axios;
  const body = notificationArray;
  return await baseService.post('crm-nest/api/v1/notification-setting', {
    ...body,
  });
};

enum sourceCode {
  chat = 'CHAT',
  sms = 'SMS',
  call = 'CALL',
  email = 'EMAIL',
  e_fax = "E_FAX"
}

enum sourceLabel {
  chat = 'When I receive new chat',
  sms = 'When I receive an SMS',
  call = 'When I miss a call',
  email= 'When I receive an Email',
  e_fax = "When I receive an eFax"
}

const NotificationSettings = (props: any) => {
  const {user, onClose} = props;
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);
  const toast = useToast();
  const intl = useIntl();
  const drawerWidth = isIPadMiniScreen || isIPadScreen ? '50vw' : '40%';
  const accountId = getAccountUUID();
  const userUUID = getUserUUID();
  const [state, setState] = useState({
    showDrawer: false,
    isAllNotificationOn: false,
    notificationObject: [],
    allNotification: [],
    isShowOption: false,
    isApiCall: false,
    loading: false,
  });
  const isEFaxMessagingEnabled = isAccountConfigEnabled(
    CONFIG_CODES.ENABLE_E_FAX_COMMUNICATION
  );

  const gatAllNotificationData = async () => {
    const notificationResponse = await getNotificationSettingApi();
    if (notificationResponse?.data?.length > 0) {
      const formatedNotification = getFormatedNotification(
        notificationResponse?.data,
        notificationArray
      );
      setState((prev) => {
        return {
          ...prev,
          notificationObject: formatedNotification,
        };
      });
      isCheckSwitchValue(formatedNotification);
    }
  };
  let indexCount = 0;
  const updateNotificationAllData = (
    newData: any,
    result: any,
    pushValue: boolean
  ) => {
    newData?.forEach((notificationData: any) => {
      updateNotificationData(
        notificationData,
        result?.data?.userNotificationSettings,
        pushValue
      );
    });
  };

  const updateNotificationData = (
    notificationData: any,
    result: any,
    pushValue: boolean
  ) => {
    notificationData?.notificationObject?.forEach((data: any) =>
      updateNotificationobject(data, result, pushValue)
    );
  };

  const updateNotificationobject = (
    data: any,
    result: any,
    pushValue: boolean
  ) => {
    const isFindData = onFindData(data, result);
    if (data.userNotificationSettings?.length > 0 && isFindData) {
      data.userNotificationSettings[0].pushValue = pushValue;
    } else if (data.userNotificationSettings?.length === 0) {
      data.userNotificationSettings.push({
        crmNotificationSettingId: result[indexCount].crmNotificationSettingId,
        id: result[indexCount].userNotificationSettingId,
        filterValue: result[indexCount].filterValue,
        pushValue: result[indexCount].pushValue,
        userId: result[indexCount].userId,
      });
    }
    indexCount = indexCount + 1;
  };

  const onFindData = (data: any, result: any) => {
    return (
      result[indexCount].crmNotificationSettingId ===
      data?.userNotificationSettings?.[0]?.crmNotificationSettingId
    );
  };

  const onSwitchChange = async (pushValue: boolean) => {
    setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const notificationObj: any = [];
    state?.notificationObject.map((notificationData: any) => {
      notificationData?.notificationObject?.forEach?.((item: any) => {
        notificationObj.push({
          crmNotificationSettingId: item?.id,
          userId: userUUID,
          userNotificationSettingId: item?.userNotificationSettings[0]?.id,
          filterValue: {
            BUSINESS_HOURS:
              item?.userNotificationSettings[0]?.filterValue?.BUSINESS_HOURS ||
              item?.filter?.BUSINESS_HOURS,
          },
          pushValue: pushValue,
          accountUuid: accountId,
          createdBy: userUUID,
          updatedBy: userUUID,
        });
      });
    });
    try {
      setState((prev) => {
        return {
          ...prev,
          isAllNotificationOn: !prev.isAllNotificationOn,
        };
      });
      const data = {
        userNotificationSettings: notificationObj,
      };
      const result = await updateNotificationSettingApi(data);
      const newData = state?.notificationObject;
      updateNotificationAllData(newData, result, pushValue);
      setState((prev) => {
        return {
          ...prev,
          notificationObject: newData,
          isApiCall: !prev.isApiCall,
          loading: false,
        };
      });
    } catch (error) {
        setState((prev)=>{
          return{
            ...prev,
            isAllNotificationOn: !prev.isAllNotificationOn,
            loading: false,
          }
        })
        showToast(
          toast,
          intl.formatMessage({id: 'notificationSettingApiErrorMsg'}),
          ToastType.error
        );
    }
  };

  const notificationArray = [
    {code: sourceCode.chat, label: sourceLabel.chat},
    {code: sourceCode.sms, label: sourceLabel.sms},
    {code: sourceCode.call, label: sourceLabel.call},
    {code: sourceCode.email, label: sourceLabel.email},
    ...(isEFaxMessagingEnabled
      ? [{code: sourceCode.e_fax, label: sourceLabel.e_fax}]
      : []),
  ];
  const iconMap: any = {
    CHAT: [
      <ChatActionSvg
        width={22}
        height={22}
        customStrokeColor={Colors.Custom.Gray500}
      />,
    ],
    SMS: [
      <SmsActionSvg
        width={22}
        height={22}
        customStrokeColor={Colors.Custom.Gray500}
      />,
    ],
    CALL: [
      <CallActionSvg
        width={22}
        height={22}
        customStrokeColor={Colors.Custom.Gray500}
      />,
    ],
    MENTION: [
      <MentionIcon
        width={22}
        height={22}
        customStrokeColor={Colors.Custom.Gray500}
      />,
    ],
    INCOMING: [
      <Feather
        name="arrow-down-left"
        style={styles.heightWidthFontSize}
        color={Colors.Custom.Gray500}
      />,
    ],
    MISSED_CALL: [
      <MissedCallSvg
        width={22}
        height={22}
        customStrokeColor={Colors.Custom.Gray500}
      />,
    ],
    ASSIGNED: [
      <PracticeInvite
        width={22}
        height={22}
        customStrokeColor={Colors.Custom.Gray500}
      />,
    ],
    EMAIL: [
      <Fontisto
        name="email"
        style={styles.heightWidthFontSize}
        color={Colors.Custom.Gray500}
      />,
    ],
    E_FAX: [
      <EFaxActionSvg
        width={22}
        height={22}
        customStrokeColor={Colors.Custom.Gray500}
      />,
    ],
  };

  const onCancel = () => {
    setState((prev) => {
      return {
        ...prev,
        showDrawer: false,
      };
    });
    setTimeout(() => {
      onClose();
    }, 200);
  };

  useEffect(() => {
    gatAllNotificationData();
    setTimeout(() => {
      setState((prev) => {
        return {
          ...prev,
          showDrawer: !prev.showDrawer,
        };
      });
    }, 200);
  }, []);

  const isCheckSwitchValue = (newData: any) => {
    let isCheck = false;
    newData.forEach((content: any) => {
      content?.notificationObject.forEach((item: any) => {
        if (item?.userNotificationSettings?.length > 0) {
          isCheck = isCheck
            ? isCheck
            : item?.userNotificationSettings[0]?.pushValue;
        } else {
          isCheck = isCheck ? isCheck : item?.pushDefaultValue;
        }
      });
    });
    setState((prev) => {
      return {
        ...prev,
        isAllNotificationOn: isCheck,
        isApiCall: isCheck ? true : false,
      };
    });
  };

  const memorizedSwitchStyles = React.useMemo<React.CSSProperties>(() => {
    return {
      alignSelf: 'center',
      backgroundColor: state.isAllNotificationOn
        ? Colors.primary[300]
        : Colors.Custom.Gray200,
    };
  }, [state.isAllNotificationOn]);

  return (
    <View>
      {state.loading ? (
        <View
          height={'full'}
          width={'full'}
          position="absolute"
          zIndex={1000}
          bg={'coolGray.50:alpha.70'}
        >
          <Center
            top={'30%'}
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <Spinner size={'lg'} zIndex={1000} />
          </Center>
        </View>
      ) : (
        <View marginTop={2}>
          <View width={'100%'}>
            <HStack>
              <HStack>
                <View
                  borderRadius={'full'}
                  background={'gray.100'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  style={styles.viewBox}
                >
                  <NotificationSvg />
                </View>
                <View paddingLeft={5} paddingX={2}>
                  <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400} lineHeight={27}>
                    All Notifications
                  </Text>
                  <Text
                    size={'smRegular'}
                    lineHeight={21}
                    color={Colors.FoldPixel.GRAY300}
                  >
                    {state.isAllNotificationOn ? intl.formatMessage({id: 'on'}) : intl.formatMessage({id: 'off'})}
                  </Text>
                </View>
              </HStack>
              <HStack flex={1} justifyContent={'flex-end'}>
                <Switch
                  style={memorizedSwitchStyles}
                  checked={state.isAllNotificationOn}
                  onChange={(value) => {
                    onSwitchChange(value);
                  }}
                />
              </HStack>
            </HStack>
          </View>
          {state.isAllNotificationOn && state.isApiCall && (
            <View>
              <VStack
                marginTop={6}
                borderWidth={1}
                borderColor={Colors.Custom.Gray200}
                borderRadius={12}
              >
                {state.notificationObject.map((item: any, index: any) => {
                  return (
                    <NotificationResourceSetting
                      allNotification={state.notificationObject}
                      notificationData={item}
                      iconMap={iconMap}
                      index={index}
                      isMissCallSetting={item?.code === sourceCode.call}
                      onActionPerformed={() => {
                        isCheckSwitchValue(state.notificationObject);
                      }}
                      onLoading={(loading: boolean) => {
                        setState((prev) => {
                          return {
                            ...prev,
                            loading: loading,
                          };
                        });
                      }}
                    />
                  );
                })}
              </VStack>
            </View>
          )}
        </View>
      )}
    </View>
  );
};

export default NotificationSettings;

const styles = StyleSheet.create({
  heightWidthFontSize: {
    height: 22,
    width: 22,
    fontSize: 22,
  },
  viewBox: {
    height: 40,
    width: 40,
  },
});
