import {useEffect} from 'react';
import {EventBus} from '../../../utils/EventBus';
import {CARE_PROGRAM_EVENT_CODES} from '../Contacts/Leads/LeadView/LeadTableView/Helper/CareProgramConst';

export const CARE_PROGRAM_EVENTS = {
  COMPLEXITY_CHANGED: CARE_PROGRAM_EVENT_CODES.COMPLEXITY_CHANGED,
  AWV_TYPE_CHANGED: CARE_PROGRAM_EVENT_CODES.AWV_TYPE_CHANGED,
  BILLING_DATA_CHANGED: CARE_PROGRAM_EVENT_CODES.BILLING_DATA_CHANGED,
  BILLING_SELECTION_CHANGED: CARE_PROGRAM_EVENT_CODES.BILLING_SELECTION_CHANGED,
  STOP_TIMER: CARE_PROGRAM_EVENT_CODES.STOP_TIMER,
  CARE_PROGRAM_ACTION_TAKEN: CARE_PROGRAM_EVENT_CODES.CARE_PROGRAM_ACTION_TAKEN,
  SAVE_RECORDED_TIME: CARE_PROGRAM_EVENT_CODES.SAVE_RECORDED_TIME,
  OPEN_TIME_LOGGING_DRAWER: CARE_PROGRAM_EVENT_CODES.OPEN_TIME_LOGGING_DRAWER,
  GENERATE_BILLING_DATA: CARE_PROGRAM_EVENT_CODES.GENERATE_BILLING_DATA,
};

interface UseCPEventHandlersProps {
  syncCode: string;
  listenCode: string;
  onSync?: (
    eventType: keyof typeof CARE_PROGRAM_EVENT_CODES,
    data?: any
  ) => void;
}

export const useCPEventHandlers = (props: UseCPEventHandlersProps) => {
  const {syncCode, listenCode, onSync} = props;

  const sync = (eventType: keyof typeof CARE_PROGRAM_EVENTS, data?: any) => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent(CARE_PROGRAM_EVENTS[eventType], {
      syncCode,
      data,
    });
  };

  const handleEvent = (
    event: any,
    eventType: keyof typeof CARE_PROGRAM_EVENTS
  ) => {
    if (event.syncCode === syncCode && onSync) {
      onSync(eventType, event.data);
    }
  };

  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();

    const handlers = Object.keys(CARE_PROGRAM_EVENTS).map((eventType) => {
      const handler = (event: any) =>
        handleEvent(event, eventType as keyof typeof CARE_PROGRAM_EVENTS);
      eventBus.addEventListener(
        CARE_PROGRAM_EVENTS[eventType as keyof typeof CARE_PROGRAM_EVENTS],
        handler
      );
      return handler;
    });

    return () => {
      handlers.forEach((handler) => {
        eventBus.removeEventListener(handler);
      });
    };
  }, [syncCode, onSync]);

  return {sync};
};
