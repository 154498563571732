import ReactDOM from 'react-dom';
import {ReactComponent} from '@foldhealth/formio-react';
import {Formio} from '@foldhealth/formiojs';
import CustomWrapper from '../CustomWrapper/CustomWrapper';
import KeyField from '../../EditFormFields/KeyField';
import ConditionalComponents from '../../EditFormFields/ConditionalFields';
import CustomClassComponent from '../CustomWrapper/CustomClassComponent';
import DischargeDetailsWrapper from './DischargeDetailsComponents/DischargeDetailsWrapper';

export default class DischargeDetailsForm extends CustomClassComponent {
  static get builderInfo() {
    return {
      title: 'Discharge Details',
      icon: 'filetext1',
      group: 'advanced',
      documentation: '',
      schema: DischargeDetailsForm.schema(),
    };
  }

  static schema() {
    return ReactComponent.schema({
      type: 'dischargeDetails',
      label: 'Discharge Details',
      key: 'dischargeDetails',
    });
  }

  attachReact(element: any) {
    return ReactDOM.render(
      <CustomWrapper formOptionData={this.options} capabilityList={[]}>
        <DischargeDetailsWrapper
          validateRef={this.validateRef}
          disabled={this.disabled}
          component={this.component}
          options={this.options}
          onChange={this.updateValue}
        />
      </CustomWrapper>,
      element
    );
  }

  static editForm = function () {
    return {
      key: 'display',
      components: [
        {
          type: 'oldtextfield',
          key: 'label',
          label: 'Label',
          input: true,
          validate: {
            required: true,
          },
        },
        ...ConditionalComponents,
        ...KeyField,
      ],
    };
  };
}

Formio.Components.addComponent('dischargeDetails', DischargeDetailsForm);
