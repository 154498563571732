import {Formio} from '@foldhealth/formiojs';
import {
  BasicComponents,
  BasicComponentsKeys,
  HealthComponents,
} from './SupportedComponents';

// Sequence
let index = 500;

[...BasicComponentsKeys, ...HealthComponents].forEach((name) => {
  let component = Formio.Components.components[name]?.builderInfo;
  if (!component) {
    return;
  }
  const componentBuilderInfo = BasicComponents[name]
  if (componentBuilderInfo) {
    component = {...component, ...componentBuilderInfo}
  }
  component.weight = index++;
  Object.defineProperty(Formio.Components.components[name], 'builderInfo', {
    get() {
      return component;
    },
  });
});
