import React, {useEffect, useMemo} from 'react';
import {useLazyQuery} from '@apollo/client';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../constants/Configs';
import ContactCareProgram from '../../../../../services/ContactCareProgram/ContactCareProgram';
import {CPTCode} from '../../interface';
import {CPTCodeRecord} from '../MainContentView/components/BillingView/BillingInterface';
import {getComplexityValue} from '../../../CareManagementView/CareManagementUtils';
import { COMPLEXITY_MAP } from '../../../CareManagementBilling/CareManagementConstants';
import { useCPEventHandlers } from '../../useCPEventhandler';
import { CARE_PROGRAM_EVENT_CODES } from '../../../Contacts/Leads/LeadView/LeadTableView/Helper/CareProgramConst';

interface UseTCMBillingProps {
  contactCareProgramId: string;
  commonDataContext: any;
}

export const useTCMBilling = ({
  contactCareProgramId,
  commonDataContext,
}: UseTCMBillingProps) => {
  const [getBillableCPTCodes, {loading: cptLoading, error: cptError, data}] =
    useLazyQuery(ContactCareProgram.GET_BILLABLE_CPT_CODES, {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    });

  const [getcontactCareProgramData, {loading: complexityLoading, error: complexityError, data: complexityData}] = useLazyQuery(ContactCareProgram.GET_CONTACT_CARE_PROGRAM_DATA, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache'
  });

  useCPEventHandlers({
    listenCode: `complexity-listen-${contactCareProgramId}`,
    syncCode: `complexity-sync-${contactCareProgramId}`, // Add this line
    onSync: (eventType) => {
      if (eventType === CARE_PROGRAM_EVENT_CODES.COMPLEXITY_CHANGED) {
        getcontactCareProgramData({
          variables: {id: contactCareProgramId},
        });
      }
    }
  });

  useEffect(() => {
    if (contactCareProgramId) {
      Promise.all([
        getBillableCPTCodes({
          variables: {
            params: {contactCareProgramId},
          },
        }),
        getcontactCareProgramData({
          variables: {id: contactCareProgramId},
        }),
      ]);
    }
  }, [contactCareProgramId]);


  const {tcmCodes, selectedCode} = useMemo(() => {
    const filteredCodes =
      data?.getBillableCPTCodes?.records?.filter(
        (code: CPTCode) => code.category === 'TCM'
      ) || [];

    const complexityValue = getComplexityValue(
      complexityData?.contactCarePrograms?.[0]?.complexity || '',
      commonDataContext.CARE_STUDIO_MLOV
    );
    const mappedComplexity = COMPLEXITY_MAP[complexityValue] || complexityValue;
    const selected = filteredCodes.find((code: CPTCode) =>
      code.title.includes(mappedComplexity)
    );

    return {
      tcmCodes: filteredCodes,
      selectedCode: selected?.cptCode || null,
    };
  }, [data, complexityData]);

  const totalBillValue = useMemo(() => {
    const selectedCodeData = tcmCodes.find((code: CPTCode) => code.cptCode === selectedCode);
    return selectedCodeData?.amount || 0;
  }, [tcmCodes, selectedCode]);

   return {
    isLoading: cptLoading || complexityLoading,
    error: cptError || complexityError,
    tcmCodes,
    selectedCode,
    totalBillValue,
  };
};
