import {cloneDeep} from 'lodash';
import {IContactCareProgramStep} from '../ContactCareProgram/interface';

const findTargetParentStepId = (
  stepsToSearch: IContactCareProgramStep[],
  targetStepId: string
): string | null => {
  for (const step of stepsToSearch) {
    // Check subSteps recursively
    if (step.subSteps && step.subSteps.length > 0) {
      const foundInSubSteps = step.subSteps.some((subStep) => subStep.id === targetStepId);
      if (foundInSubSteps) {
        return step.id;
      }
    }
  }
  return null;
};

const filterStepsAfterTarget = (
  originalSteps: IContactCareProgramStep[],
  targetParentStepId: string
): IContactCareProgramStep[] => {
  const targetParentStepIndex = originalSteps.findIndex(
    (step) => step.id === targetParentStepId
  );

  // If target step not found, return empty array
  if (!targetParentStepId) {
    return [];
  }

  // Return a new array with steps after the target step including the target step
  return originalSteps.slice(targetParentStepIndex);
};

export function getStepsForCalculatingNextActiveStep(
  steps: IContactCareProgramStep[],
  targetStepId: string
): IContactCareProgramStep[] {
  // Find the target step
  const targetParentStepId = findTargetParentStepId(steps, targetStepId);

  if (!targetParentStepId) {
    return []; // Return empty array if no matching step found
  }

  // Create a deep copy of the original steps
  const filteredSteps = cloneDeep(steps) as IContactCareProgramStep[];

  // Filter steps after the target step in the main array
  const stepsAfterTarget = filterStepsAfterTarget(filteredSteps, targetParentStepId);

  // filter the substeps in the parentStep which are after the targetStepId
  const result = stepsAfterTarget.map((step) => {
    if (step.id === targetParentStepId && step.subSteps) {
      return {
        ...step,
        subSteps: step.subSteps.slice(
          step.subSteps.findIndex((subStep) => subStep.id === targetStepId) + 1
        ),
      };
    }
    return step;
  });

  return result;
}

