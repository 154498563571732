import {View, StyleSheet, Dimensions} from 'react-native';
import React, {useContext, useState} from 'react';
import {IContact} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {Colors} from '../../../styles';
import SidecarPatientTaskManager from '../SidecarPatientTaskManager';
import {TaskPanelType} from '../../TaskCard/TaskEnum';
import {ITopBarData} from '../../common/CareDashboard/CareDashboardTopBar/CareDashboardTopBar';
import {getDateStrFromFormat} from '../../../utils/DateUtils';
import {DATE_FORMATS, MLOV_CATEGORY} from '../../../constants';
import {
  getMlovId,
  getMlovListByCategory,
  getMlovObjectFromCode,
  getMlovValue,
} from '../../../utils/mlovUtils';
import {TASK_STATUS, TASK_STATUS_CODES} from '../../../constants/MlovConst';
import {TaskViewType} from '../../TaskModule/TaskInterfaces';
import { TaskViewProvider } from '../../../context-provider/TaskViewProvider';

const InsightsTasksView = (props: {
  contactData?: IContact;
  formattedContactData?: IContact;
  onTaskPanelChanged?: (taskPanelType: TaskPanelType) => void
}) => {
  const {contactData, formattedContactData} = props;
  const commonData = useContext(CommonDataContext);
  const isSidecarContext = commonData?.sidecarContext?.isSidecar;
  const [topBarData, setTopBarData] = useState<ITopBarData>({
    start: getDateStrFromFormat(new Date(), DATE_FORMATS.DISPLAY_DATE_FORMAT),
    end: getDateStrFromFormat(new Date(), DATE_FORMATS.DISPLAY_DATE_FORMAT),
    addTask: false,
    panelType: TaskPanelType.INTERNAL,
  });
  const {height} = Dimensions.get('window');  
  const patientTaskScrollHeight = isSidecarContext ? height - 240 : height - 336;

  const mlovData = useContext(CommonDataContext);
  const taskStatusMlovs = getMlovListByCategory(MLOV_CATEGORY.TASK_STATUS);

  const initialPriorityType =
    getMlovListByCategory(MLOV_CATEGORY.TASK_PRIORITY) || [];

  const initialTaskEntityTypes =
    getMlovListByCategory(MLOV_CATEGORY.TASK_ENTITY_TYPE) || [];

  const acceptedStatusMlov = getMlovObjectFromCode(
    TASK_STATUS.ACCEPTED,
    taskStatusMlovs
  );

  const missedStatusMlov = getMlovObjectFromCode(
    TASK_STATUS_CODES.MISSED,
    taskStatusMlovs
  );

  const allStatusValue = getMlovValue(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'all'
  );

  const allStatusUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'all'
  );

  const [filter, setFilter] = useState({
    priorityTypeList: initialPriorityType,
    taskEntityTypeList: initialTaskEntityTypes,
    selectedStatusCodes: [acceptedStatusMlov?.code, missedStatusMlov?.code],
    formData: {
      selectedStatusList: [
        {...acceptedStatusMlov, value: 'Not Completed'},
        {...missedStatusMlov, value: 'missed'},
      ],
      selectedDueDate: allStatusValue,
      selectedDueDateUuid: allStatusUuid,
      start: getDateStrFromFormat(new Date(), DATE_FORMATS.DISPLAY_DATE_FORMAT),
      end: getDateStrFromFormat(new Date(), DATE_FORMATS.DISPLAY_DATE_FORMAT),
    },
  });

  const updatePanelType = (panelType: TaskPanelType) => {
    setTopBarData((prev) => ({
      ...prev,
      panelType: panelType,
    }));
    props?.onTaskPanelChanged?.(panelType);
  };

  return (
    <>
      {contactData && (
      <TaskViewProvider isRenderCompactView isShowGrayBackground={true}>
        <View style={styles.container}>         
          <SidecarPatientTaskManager
            contactData={contactData}
            updatePanelType={updatePanelType}
            taskTopBarData={topBarData}
            contactType={formattedContactData?.contactType}
            formattedContactData={formattedContactData}
            filters={filter}
            onClose={undefined}
            hidePatientInfoBanner={true}
            viewType={TaskViewType.list}
            patientTaskScrollHeight={patientTaskScrollHeight}
          />
        </View>        
      </TaskViewProvider>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.Custom.White
  },
});

export default InsightsTasksView;
