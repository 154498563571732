import React, {useContext} from 'react';
import {Stack, Text, Divider, VStack, HStack} from 'native-base';
import {StyleSheet} from 'react-native';
import {Radio} from 'antd';
import {Colors} from '../../../../../../../styles/Colors';
import {CPTCode} from '../../../../interface';
import {useAWVBilling} from '../../../hooks/useAWVBilling';
import {CommonDataContext} from '../../../../../../../context/CommonDataContext';
import { CategoryData } from './BillingInterface';
import { AWV_BILLING_CATEGORY_TITLES } from '../../../../../CareManagementBilling/CareManagementConstants';

interface AWVBillingViewProps {
  contactCareProgramId: string;
}


const AWVBillingView: React.FC<AWVBillingViewProps> = ({
  contactCareProgramId,
}) => {
  const commonDataContext = useContext(CommonDataContext);
  const {
    billingCategories,
    totalBillValue,
    isLoading,
    selectedCode,
  } = useAWVBilling({contactCareProgramId, commonDataContext});

  if (isLoading) {
    return (
      <Stack style={styles.outerContainer}>
        <Stack style={styles.container}>
          <Text>Loading...</Text>
        </Stack>
      </Stack>
    );
  }

  const renderCategorySection = (category: string, categoryData: CategoryData) => {
    if (!categoryData.codes.length) return null;
    
    return (
      <VStack key={category} space={3} marginTop={category !== 'AWV' ? 6 : 0}>
        <Text style={styles.title}>{AWV_BILLING_CATEGORY_TITLES[category as keyof typeof AWV_BILLING_CATEGORY_TITLES]}</Text>
        {categoryData.codes.map((option: CPTCode) => (
          <VStack key={option.cptCode} style={styles.radioOption}>
            <Radio
              value={option.cptCode}
              disabled={Boolean(selectedCode && selectedCode !== option.cptCode)}
            >
              <HStack space={2} alignItems="center">
                <Text style={styles.cptCode}>{option.cptCode}:</Text>
                <Text style={styles.value}>${option.amount}</Text>
              </HStack>
            </Radio>
            <Text style={styles.description}>{option.description}</Text>
          </VStack>
        ))}
      </VStack>
    );
  };

  return (
    <Stack style={styles.outerContainer}>
      <Stack style={styles.container}>
        <Text style={styles.totalBill}>Total Bill: ${totalBillValue}</Text>
        <Divider style={styles.divider} />

        <Radio.Group
          name="cptCode"
          value={selectedCode}
          style={antStyles.radioGroup}
        >
          {(Object.entries(billingCategories) as [string, CategoryData][]).map(
            ([category, categoryData]) => renderCategorySection(category, categoryData)
          )}
        </Radio.Group>
      </Stack>
    </Stack>
  );
};

const styles = StyleSheet.create({
  outerContainer: {
    width: '60%',
    alignSelf: 'center',
    marginTop: 16,
  },
  container: {
    padding: 16,
    backgroundColor: Colors.Custom.White,
    borderRadius: 8,
    width: '100%',
  },
  totalBill: {
    fontSize: 24,
    fontWeight: '600',
    color: Colors.Custom.Gray900,
    marginBottom: 16,
  },
  divider: {
    marginVertical: 16,
  },
  title: {
    fontSize: 14,
    fontWeight: '600',
    color: Colors.Custom.Gray700,
    marginBottom: 16,
  },
  radioOption: {
    marginBottom: 12,
  },
  cptCode: {
    fontSize: 14,
    color: Colors.Custom.Gray900,
    fontWeight: '500',
  },
  value: {
    fontSize: 14,
    color: Colors.Custom.Gray700,
  },
  description: {
    fontSize: 12,
    color: Colors.Custom.Gray500,
    marginTop: 4,
    marginLeft: 24,
  },
});

const antStyles: Record<string, React.CSSProperties> = {
  radioGroup: {
    width: '100%',
  },
};

export default AWVBillingView;
