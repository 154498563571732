//import { Content } from 'antd/lib/layout/layout';
import {
  Button,
  CheckIcon,
  FormControl,
  HStack,
  Image,
  Input,
  ScrollView,
  Select,
  Text,
  VStack,
  TextArea,
  Icon,
  View,
  Skeleton
} from 'native-base';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { useIntl } from 'react-intl';
import { BUTTON_TYPE, PARENT_CODE, POPULATION_GROUP_TYPES } from '../../../../../constants';
import { Colors } from '../../../../../styles';
import { DisplayText } from '../../../../common/DisplayText/DisplayText';
import TitleSubtitleView from '../../../../common/TitleSubtitleView/TitleSubtitleView';
import { FoldButton } from '../../../../CommonComponents/FoldButton/FoldButton';
import PatientDropdownList from '../../PatientDropdownList';
import { CampaignSegmentTableContext, ICampaignErrors, ICampaignSegmentFormState, IPopulationGroupItem } from '../interfaces';
import { RuleEngineInputField } from '../RuleEngineInputField';
import { styles } from './CampaignSegmentFormStyles';
import { FilterType, FrequencyType, ICampaignSegmentFormProps } from './interfaces';
import { getAccountUUID, getBooleanFeatureFlag, isLeadGroupsPage } from '../../../../../utils/commonUtils';
import { getAccountConfigResourceAbilities, getResourceAbilities } from '../../../../../utils/capabilityUtils';
import { FHIR_RESOURCE } from '../../../../../constants/FhirConstant';
import { Select as AntdSelect, Modal, Spin, Tag, Tooltip } from 'antd';
import { isStaticGroup } from '../Helper';
import { getCampaignSegmentRules, getGroupsList, getLeadGroups } from '../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import { cloneDeep, debounce } from 'lodash';
import { IMembersDatas } from '../../../MembersManagement/RulesInterface';
import { CONFIG_CODES } from '../../../../../constants/AccountConfigConst';
import { isAccountConfigEnabled } from '../../../../../utils/configUtils';
import { ModalActionAntSelect } from '../../../../common/ModalActionCommonComponent/ModalActionAntSelect';
import { USER_ACCESS_PERMISSION } from '../../../UserAccess/UserAccessPermission';
import { usePermissions } from '../../../../CustomHooks/usePermissions';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import { MAIN_MENU_CODES } from '../../../../SideMenuBar/SideBarConst';
import { EHRWiseLocations } from '../../../../../Interfaces/CommonInterfaces';
import { IContact } from '../../../TeamInbox/Conversations/interfaces';
import { LOCATION_SPECIFIC_FIELDS } from '../constants';
import PopGroupAutomation from '../PopGroupAutomation/PopGroupAutomation';
import { IAutomation } from '../../../Forms/FHFormio/CustomComponents/ContactRules/interfaces';
import {v4 as uuidv4} from 'uuid';
import Stack from '../../../../common/LayoutComponents/Stack';
import { useWindowDimensions } from 'react-native';
import FeatureFlags from '../../../../../constants/FeatureFlags.enums';
import { InputStyle } from '../../../Contacts/Leads/LeadView/LeadViewStyle';

interface IComponentState {
  showRemovingPatientAlert: boolean,
  showRemovingFieldsInRuleAlert: boolean,
  selectedPatientsWithContactData: IContact[],
  deselectedLocations: EHRWiseLocations[],
  showMinimumLocationSupportText: boolean,
  locationTagHandler?: {
    selectedTagProp: any,
    tagEvent: React.MouseEvent<HTMLElement, MouseEvent>
  }
}

export const memberStatusOptions = [
  {
    code: 'active',
    value: 'Active'
  },
  {
    code: 'inactive',
    value: 'Inactive'
  }
]

export const frequencyOptions = [
  {
    code: '1d',
    value: '1 day'
  },
  {
    code: '1w',
    value: '1 week'
  },
  {
    code: '1M',
    value: '1 month'
  }
]

export const NODE_TYPES = {
  RULE_GROUP: 'rule_group',
  GROUP: 'group',
  RULE: 'rule',
}

const CampaignSegmentForm = (props: ICampaignSegmentFormProps) => {
  const {initData, isPatientGroup, parentCode,isFromLeads, contactTypeUuid} = props
  const context = props?.context || CampaignSegmentTableContext.POPULATION_GROUP;
  const accountUUID = getAccountUUID();
  const [furtherFormFieldsLoading, setFurtherFormFieldsLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isInitialPopulationSelected, setIsInitialPopulationSelected] = useState(false);
  const [selectedFrequencyType, setSelectedFrequencyType] = useState<FrequencyType>(props.initData?.executionFrequency as FrequencyType || FrequencyType.NONE);
  const isFromContactList = parentCode === PARENT_CODE.PATIENT_LIST
  const [populationGroupState, setPopulationGroupState] = useState<{
    list: IPopulationGroupItem[];
    searchLoading: boolean;
    searchText: string | undefined;
    selectedPopulationGroupName: string | undefined;
  }>(
    {
    list: [],
    searchLoading: false,
    searchText: '',
    selectedPopulationGroupName: undefined,
  });
  const isMsoEnabled = isAccountConfigEnabled( CONFIG_CODES.IS_MSO_ENABLED );
  const mlovData = useContext(CommonDataContext);
  const {accountLocationListWithEHR, popGroupCapabilities} = mlovData;
  const {height} = useWindowDimensions();
  const isOnLeadGroups = (isLeadGroupsPage() || isFromLeads) && !isPatientGroup;
  const {check} = usePermissions();
  const accessLevel = isOnLeadGroups ? MAIN_MENU_CODES.LEAD : MAIN_MENU_CODES.CONSUMER;
  const permissionConfig = check(USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code, accessLevel);
  const isMultiTenancyEnabled = getBooleanFeatureFlag(mlovData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const hintCapability = getAccountConfigResourceAbilities(FHIR_RESOURCE.HINT);
  const openInHint = hintCapability?.openInHint || false;
  const allowedAccountLocations = accountLocationListWithEHR?.filter((location) => {
    return permissionConfig?.allowedLocationIds?.includes(location?.uuid)
  })?.map((location) => {
    return {
      ...location,
      uuid: location?.uuid,
      name: location?.practiceLocation?.name,
    }
  }) || [];
  const formattedAccountLocationListWithEHR = accountLocationListWithEHR?.map((location) => {
    return {
      ...location,
      uuid: location?.uuid,
      name: location?.practiceLocation?.name,
    }
  }) || [];
  const allowedAccountLocationIds = allowedAccountLocations?.map(location => location.uuid);
  const initialNonDeletedLocationIdList = props?.initData?.ruleLocations?.filter((location) =>
    !location?.isDeleted
  )?.map((location) => location?.locationId) || [];
  const [campaignSegmentFormData, setCampaignSegmentFormData] =
    useState<ICampaignSegmentFormState>({
      audienceName: props?.initData?.audienceName || '',
      patientList: props.initData?.patientList || [],
      notAccessPatientList: [],
      description: props.initData?.description || '',
      jsonTree: props.initData?.jsonTree,
      id: props.initData?.id || uuidv4(),
      automationToUpdate: [],
      ...((props.initData?.id || (isMultiTenancyEnabled && isFromContactList)) && {
        memberStatus: memberStatusOptions?.find(memberStatus =>
          memberStatus?.code === props.initData?.memberStatusCodeFromResponseWhenEditingGroup?.[0]),
        executionFrequency: props.initData?.executionFrequency,
        populationGroupId: props.initData?.populationGroupId || '',
        ishintMembershipStatus: openInHint,
        selectedAccountLocations: isMultiTenancyEnabled ? accountLocationListWithEHR?.filter((location) => initialNonDeletedLocationIdList?.includes(location.uuid)) || [] : [],
      })
    });
    const [validation, setValidation] = useState({
      segmentName: '',
      selectedFilter: '',
      selectedFrequency: '',
      filter: {} as any,
      jsonTree: '',
      patientList: '',
      selectedLocations: ''
    })
  const [componentState, setComponentState] = useState<IComponentState>({
    showRemovingPatientAlert: false,
    showRemovingFieldsInRuleAlert: false,
    selectedPatientsWithContactData: [],
    deselectedLocations: [],
    showMinimumLocationSupportText: false,
  })
    const scrollableHeight = height - 80;

  const intl = useIntl();
  const [selectedFilterType, setSelectedFilterType] = useState<FilterType>(FilterType.NONE);
  const isLocationSelected = campaignSegmentFormData?.selectedAccountLocations && campaignSegmentFormData?.selectedAccountLocations?.length > 0;
  const shouldShowBasedOnLocationOrTenancy = !isMultiTenancyEnabled || isLocationSelected;

  const showChooseFilterField = (context === CampaignSegmentTableContext.POPULATION_GROUP) &&
    !props.isAddGroupMemberOnly && shouldShowBasedOnLocationOrTenancy;

  const showLocationField = isMultiTenancyEnabled &&
    (context === CampaignSegmentTableContext.SCHEDULE_SUGGESTION_RULES || context === CampaignSegmentTableContext.POPULATION_GROUP);

  const showInitialPopulationField = context === CampaignSegmentTableContext.POPULATION_GROUP &&
    selectedFilterType === FilterType.SELECT_BY_OTHER && shouldShowBasedOnLocationOrTenancy;

  const showFrequencyField = (selectedFilterType === FilterType.SELECT_BY_OTHER ||
    (context === CampaignSegmentTableContext.SCHEDULE_SUGGESTION_RULES)) &&
    !props.isAddGroupMemberOnly && shouldShowBasedOnLocationOrTenancy;

  const showRuleEngineInputField = shouldShowBasedOnLocationOrTenancy && selectedFilterType === FilterType.SELECT_BY_OTHER;

  const searchGroups = async (searchString: string) => {
    try {
      setPopulationGroupState((prev) => ({
        ...prev,
        searchLoading: true,
        searchText: searchString,
      }));
      const selectedAccountLocationsIds = campaignSegmentFormData?.selectedAccountLocations?.map((location) => location?.uuid)
      const response = isOnLeadGroups
        ? await getLeadGroups({ accountUuid: accountUUID, name: searchString || '', selectedAccountLocationIds: campaignSegmentFormData?.selectedAccountLocations?.map((location) => location?.uuid) })
        : await getGroupsList(accountUUID, 0, searchString || '', 0, undefined, undefined, undefined, undefined, undefined, selectedAccountLocationsIds)

      const populationGroupResponseList: IMembersDatas[] = response?.data?.data;
      const filteredGroupsList = populationGroupResponseList.filter((group) => {
        const isDynamicGroup = group.type === POPULATION_GROUP_TYPES.DYNAMIC;
        const isDynamicLeadGroup = group.type === POPULATION_GROUP_TYPES.DYNAMIC_LEAD;
        return group.id !== campaignSegmentFormData.id &&
          !group.inputRuleId &&
          (isOnLeadGroups ? isDynamicLeadGroup : isDynamicGroup);
      });
      const formattedGroups: IPopulationGroupItem[] = filteredGroupsList.map((item: any) => ({
        id: item.id,
        name: item.name,
      }));
      setPopulationGroupState((prev) => ({
        ...prev,
        list: formattedGroups,
        searchLoading: false,
      }));
    } catch (error) {
      setPopulationGroupState((prev) => ({
        ...prev,
        list: [],
        searchLoading: false,
      }));
    }
  };

  const findMemberStatusOption = (statusCode: string | undefined) => {
    return memberStatusOptions.find((status) => status.code === statusCode) || undefined;
  };

    const fetchSelectedPopulationGroupDetails = async (groupId: string) => {
      try {
        const groupDetails = await getCampaignSegmentRules(groupId);
        const name = groupDetails?.name || '';
        const executionFrequency = groupDetails?.executionFrequency || '';
        const memberStatusCode = groupDetails?.preFilter?.hintMembershipStatus?.params?.value[0];
        const memberStatus = findMemberStatusOption(memberStatusCode);
        return {
          name,
          executionFrequency,
          memberStatus,
        };
      } catch (error) {
        return {
          name: '',
          executionFrequency: '',
          memberStatus: undefined
        };
      }
    };

    const initializeSelectedPopulationGroup = async () => {
      const populationGroupId = campaignSegmentFormData.populationGroupId;
      if (!!populationGroupId) {
        const groupDetails = await fetchSelectedPopulationGroupDetails(populationGroupId);
        setPopulationGroupState((prev) => ({
          ...prev,
          selectedPopulationGroupName: groupDetails.name,
        }));
        setCampaignSegmentFormData((prev) => ({
          ...prev,
          executionFrequency: groupDetails.executionFrequency,
          memberStatus: groupDetails.memberStatus
        }));
        setIsInitialPopulationSelected(true);
      }
    };


  useEffect(() => {
    initializeSelectedPopulationGroup();
  }, []);

  useEffect(() => {
    if ((context === CampaignSegmentTableContext.SCHEDULE_SUGGESTION_RULES) || ((context === CampaignSegmentTableContext.POPULATION_GROUP) && !props.initData)) {
      setSelectedFilterType(FilterType.SELECT_BY_OTHER);
      return;
    }
    if (props.initData?.patientList?.length) {
      setSelectedFilterType(FilterType.SELECT_BY_PATIENT);
    } else if (props.initData?.jsonTree) {
      setSelectedFilterType(FilterType.SELECT_BY_OTHER);
    } else {
      setSelectedFilterType(FilterType.NONE);
    }
  }, [initData?.patientList?.length,initData?.jsonTree]);

  useEffect(() => {
    if (!isOnLeadGroups && showRuleEngineInputField && selectedFilterType === FilterType.SELECT_BY_OTHER) {
      props.onColumnViewChange?.(2);
    } else {
      props.onColumnViewChange?.(1);
    }
  }, [showRuleEngineInputField, selectedFilterType]);

  const handleJSONTreeStateChange = useCallback((newJsonTree: any) => {
    setCampaignSegmentFormData((prev: any) => {
      return {...prev, jsonTree: newJsonTree};
    });
  }, []);

  const removeOldData = () => {
    setCampaignSegmentFormData((prev) => {
      return {
        ...prev,
        patientList: [],
        jsonTree: '',
        memberStatus: undefined,
        executionFrequency: undefined,
        populationGroupId: '',
        automationToUpdate: [],
      };
    });
    setPopulationGroupState((prev) => ({
      ...prev,
      searchText: undefined,
      list: [],
      selectedPopulationGroupName: undefined,
    }));
  };

  const isValidForm = (data:any)=> {
    let isValid = true;
    setValidation(prev=> {
      return {
        ...prev,
        segmentName: '',
        selectedFilter: '',
        ...(isInitialPopulationSelected ? {} : { selectedFrequency: '' }),
        patientList: '',
        jsonTree: '',
        selectedLocations: '',
      }
    })

    const  segmentName = !data?.audienceName?.slice().length ? 'Segment name mandatory' : ''
    const selectedFilter = !selectedFilterType.slice().length ? 'Choose filter' : '';
    const selectedFrequency = isStaticGroup(selectedFilterType) ? '' : !selectedFrequencyType.slice().length ? 'Choose Frequency' : '';
    const patientList =
      !data?.patientList?.length &&
      (!isMultiTenancyEnabled ||
        !campaignSegmentFormData.notAccessPatientList?.length) &&
      !data.jsonTree
        ? 'Mandatory Field'
        : '';
    const selectedLocations = isMultiTenancyEnabled && (!data?.selectedAccountLocations?.length) ? 'Mandatory Field' : '';
    const jsonTree =  !data.jsonTree && !data?.patientList?.length ? "Mandatory Field" : '';

    setValidation(prev=> {
      return {
        ...prev,
        segmentName: segmentName,
        selectedFilter: selectedFilter,
        selectedFrequency: selectedFrequency,
        patientList: patientList,
        jsonTree: jsonTree,
        selectedLocations: selectedLocations,

      }
    })
    isValid = !(segmentName || selectedFilter || patientList || jsonTree || (selectedFrequency && !isInitialPopulationSelected) || selectedLocations);

    return isValid;
  }

  const geSearchMemberLabel = ()=> {
    if (isOnLeadGroups) {
      return 'leadContacts';
    } else if (props?.showLeadAndPatient) {
      return 'members';
    }
    return 'patients';
  }

  const handleSearch = debounce((value) => {
    if (!!value) {
      setPopulationGroupState((prev) => ({
        ...prev,
        searchText: value,
      }));
      searchGroups(value);
    }
    else {
      setPopulationGroupState((prev) => ({
        ...prev,
        searchText: undefined,
        list: [],
        selectedPopulationGroupName: undefined,
      }));
    }
  }, 500);

  const handleSubmitDebounce = debounce(() => {
    const updatedCampaignSegmentFormData = {
      ...campaignSegmentFormData,
      patientList: [
        ...(campaignSegmentFormData.patientList ?? []),
        ...(campaignSegmentFormData.notAccessPatientList ?? [])
      ],
    }
    props?.onSubmitForm?.(updatedCampaignSegmentFormData, () => {
      removeOldData();
      setCampaignSegmentFormData((prev) => ({
        ...prev,
        selectedAccountLocations: [],
      }));
      setSubmitLoading(false);
    }, initialNonDeletedLocationIdList);
    setSubmitLoading(false);
  }, 300);

  const renderNotFoundContent = () => {
    if (populationGroupState.searchLoading) {
      return <Spin size="small" />;
    } else {
      return populationGroupState.searchText ? (
        intl.formatMessage({ id: 'noDataFound' })
      ) : (
        intl.formatMessage({ id: 'NoDataSelect' })
      );
    }
  };

  const handleSelectChange = async (value: string, data: any) => {
    if (value) {
      setCampaignSegmentFormData((prev) => {
        return {
          ...prev,
          populationGroupId: data?.ruleId || undefined,
        };
      });
      setPopulationGroupState((prev) => {
        return {
          ...prev,
          selectedPopulationGroupName: value || undefined,
        }
      });
      setIsInitialPopulationSelected(true);
      setFurtherFormFieldsLoading(true);
      const res = await getCampaignSegmentRules(data.ruleId);
      setCampaignSegmentFormData((prev) => {
        return {
          ...prev,
          memberStatus: findMemberStatusOption(res?.preFilter?.hintMembershipStatus?.params?.value[0]) || undefined,
          executionFrequency: res?.executionFrequency,
          patientList:
            res.type === POPULATION_GROUP_TYPES.STATIC ||
            res.type === POPULATION_GROUP_TYPES.STATIC_LEAD
              ? res.rule.patientFilter.contactIds
              : [],
        };
      });
      setSelectedFrequencyType(res?.executionFrequency as FrequencyType);
      setFurtherFormFieldsLoading(false);
    } else {
      setCampaignSegmentFormData((prev) => {
        return {
          ...prev,
          populationGroupId: undefined,
          memberStatus: undefined,
          executionFrequency: undefined,
          patientList: [],
        };
      });
      setPopulationGroupState((prev) => {
        return {
          ...prev,
          selectedPopulationGroupName: undefined,
        }
      });
      setSelectedFrequencyType(FrequencyType.NONE);
      setIsInitialPopulationSelected(false);
      setFurtherFormFieldsLoading(false);
    }
  }

  const tagRender = (tagProps: any) => {
    const onPreventMouseDown = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={ tagProps.closable}
        onClose={(e) => {
          const deselectedLocation = campaignSegmentFormData?.selectedAccountLocations?.find((location) => location?.uuid === tagProps.value);
          const updatedSelectedLocations =campaignSegmentFormData.selectedAccountLocations?.filter(location => location.uuid !== tagProps.value) || [];
          e.preventDefault();
          if (campaignSegmentFormData.selectedAccountLocations?.length === 1) {
            // Prevent the tag from closing if there's only one location selected
            setComponentState((prev) => ({
              ...prev,
              showMinimumLocationSupportText: true,
            }));
          } else {
            // Otherwise, allow the default close action
            setComponentState((prev) => ({
              ...prev,
              locationTagHandler: {
                selectedTagProp: tagProps,
                tagEvent: e
              }
            }));
            handleDeselectedLocation(deselectedLocation ? [deselectedLocation] : [], updatedSelectedLocations)
          }
        }}
        style={{marginRight: 3}}
        className={'custom-tag'}
      >
        {tagProps.label}
      </Tag>
    );
  };

  const selectedLocationsIds = useMemo(() => {
    if (!isMultiTenancyEnabled) {
      return [];
    }
    const locationIds: string[] = props?.locationOptionList?.length > 0
      ? props.locationOptionList
      : campaignSegmentFormData.selectedAccountLocations?.map(location => location.uuid) || [];

    return locationIds.filter(id => allowedAccountLocationIds.includes(id));
  }, [props?.locationOptionList?.length, campaignSegmentFormData.selectedAccountLocations?.length, isMultiTenancyEnabled])

    const removeLocationSpecificFields = (jsonTree: any) => {
      function filterTree(children: any) {
          return children.filter((node: any) => {
              if (node.type === NODE_TYPES.RULE && LOCATION_SPECIFIC_FIELDS.includes(node.properties.field)) {
                  return false;
              } else if (node.type === NODE_TYPES.GROUP || node.type === NODE_TYPES.RULE_GROUP) {
                  node.children1 = filterTree(node.children1 || []);
                  return node.children1.length > 0;
              }
              return true;
          });
      }

      jsonTree.children1 = filterTree(jsonTree?.children1);
      return jsonTree;
  }

  const needsOperation = (jsonTree: any) => {
    function checkTree(children: any) {
      if (!children || !Array.isArray(children)) {
        return false;
      }
        for (const node of children) {
            if (node.type === 'rule' && LOCATION_SPECIFIC_FIELDS.includes(node.properties.field)) {
                return true;
            } else if ((node.type === 'group' || node.type === 'rule_group') && node.children1) {
                if (checkTree(node.children1)) {
                    return true;
                }
            }
        }
        return false;
    }

    return checkTree(jsonTree?.children1);
}

const resetLocationTagHandler = () => {
  setComponentState(prev => ({
    ...prev,
    locationTagHandler: undefined,
  }));
};

const handleDeselectedLocation = (
  deselectedLocations: EHRWiseLocations[],
  selectedLocations: EHRWiseLocations[]
) => {
  const { selectedTagProp: tagProps, tagEvent } = componentState?.locationTagHandler || {};

  const updateSelectedLocations = () => {
    setCampaignSegmentFormData(prev => ({
      ...prev,
      selectedAccountLocations: selectedLocations,
    }));
  };

  const handleTagClosure = () => {
    if (tagProps && tagEvent) {
      tagProps?.onClose?.(tagEvent);
      resetLocationTagHandler();
    }
  };

  const showAlertAndSetDeselectedLocations = (alertKey: string) => {
    setComponentState(prev => ({
      ...prev,
      [alertKey]: true,
      deselectedLocations: deselectedLocations,
    }));
  };

  if (selectedFilterType === FilterType.SELECT_BY_PATIENT) {
    const isPatientInDeselectedLocation = deselectedLocations.some(location =>
      componentState?.selectedPatientsWithContactData?.some(patient =>
        patient?.contactPracticeLocations?.some(practiceLocation =>
          practiceLocation?.accountLocation?.uuid === location.uuid
        )
      )
    );

    if (isPatientInDeselectedLocation) {
      showAlertAndSetDeselectedLocations('showRemovingPatientAlert');
    } else {
      updateSelectedLocations();
      handleTagClosure();
      resetLocationTagHandler();
    }
  } else if (selectedFilterType === FilterType.SELECT_BY_OTHER) {
    if (campaignSegmentFormData?.populationGroupId || needsOperation(campaignSegmentFormData?.jsonTree)) {
      showAlertAndSetDeselectedLocations('showRemovingFieldsInRuleAlert');
    } else {
      handleTagClosure();
      updateSelectedLocations();
      resetLocationTagHandler();
    }
  }
};

  const isLocationEditable = (selectedLocations: EHRWiseLocations[]) => {
    if (!props.initData) return true;
    return selectedLocations?.every(location =>
      allowedAccountLocationIds.includes(location.uuid)
    );
  };

  const locationsDataList = (!!props.initData && campaignSegmentFormData.selectedAccountLocations?.some(location =>
    !allowedAccountLocationIds.includes(location.uuid)
  )) ? formattedAccountLocationListWithEHR : allowedAccountLocations;

  return (
    // <PageBodyContainer>
    <VStack height={scrollableHeight} overflow="scroll">
      <HStack alignItems={'center'} justifyContent={'center'}>
        <TitleSubtitleView
          isTitleSubtitleOldView={true}
          isHideCommonButton={true}
          containerStyle={{marginLeft: 0, marginBottom: 5}}
          titleLabelId={
            props.isAddGroupMemberOnly
              ? 'Update Group Member'
              : props.editing ? 'updateAudienceGroup' : 'createAudienceGroup'
          }
          subtitleLabelId=""
          titleStyle={{ paddingBottom: 10}}
        />
        <HStack
          style={{
            justifyContent: 'flex-end',
            alignItems: 'center',
            flex: 1,
          }}
        >
          <Button.Group justifyContent={'flex-end'}>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                onPress: () => {
                  props.onModalClose(false);
                },
                style: {marginRight: 12, paddingHorizontal: 16},
                isDisabled: submitLoading,
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'cancel',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                isDisabled: submitLoading,
                isLoading: submitLoading,
                onPress: () => {
                  if (!isValidForm(campaignSegmentFormData)) {
                    return;
                  }
                  if (campaignSegmentFormData.audienceName?.slice().length && !submitLoading) {
                    setSubmitLoading(true);
                    handleSubmitDebounce();
                  }
                },
                style: {paddingHorizontal: 16}
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: props.isAddGroupMemberOnly ? 'Add' : 'submit',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
          </Button.Group>
        </HStack>
      </HStack>
      <ScrollView>
        <VStack>
          <HStack flex={1} justifyContent={'center'}>
            <Stack direction={isOnLeadGroups ? 'column': 'row'} space={isOnLeadGroups ? 0 : 20} style={{flex: 1}}>
            <Stack direction={'column'} style={{flex: 2, height: '100%'}}>
              {!props.isAddGroupMemberOnly && (
                <FormControl
                  style={[styles.formElement]}
                  isRequired={true}
                  isInvalid={
                    props.campaignDetailsError?.audienceName ||
                    validation.segmentName
                      ? true
                      : false
                  }
                >
                  <FormControl.Label style={styles.formLabel}>
                    <DisplayText
                      size={'smRegular'}
                      extraStyles={{color: Colors.FoldPixel.GRAY250}}
                      textLocalId={'segmentName'}
                    />
                  </FormControl.Label>
                  <Input
                  _focus={{borderColor: '#D0D5DD'}}
                    isReadOnly={props.isReadOnly}
                    defaultValue={campaignSegmentFormData?.audienceName}
                    isInvalid={
                      props.campaignDetailsError?.audienceName ||
                      validation.segmentName
                        ? true
                        : false
                    }
                    value={campaignSegmentFormData?.audienceName}
                    onChangeText={(value: string) => {
                      setCampaignSegmentFormData((prev) => {
                        return {
                          ...prev,
                          audienceName: value,
                        };
                      });
                    }}
                    isFullWidth={true}
                    placeholder="Enter Segment Name"
                    placeholderTextColor={InputStyle.placeholderTextColor}
                    fontSize={InputStyle.placeHolderFontsize}
                  />
                  {props.campaignDetailsError?.audienceName ||
                  validation.segmentName ? (
                    <FormControl.ErrorMessage
                      _text={{
                        fontSize: 'xs',
                        color: 'error.500',
                        fontWeight: 500,
                      }}
                    >
                      {props.campaignDetailsError?.audienceName ||
                        validation?.segmentName}
                    </FormControl.ErrorMessage>
                  ) : null}
                </FormControl>
              )}

              {!props.isAddGroupMemberOnly && (
                <FormControl style={[styles.formElement]}>
                  <FormControl.Label style={styles.formLabel}>
                    <DisplayText
                      size={'smRegular'}
                      extraStyles={{color: Colors.FoldPixel.GRAY250}}
                      textLocalId={'description'}
                    />
                  </FormControl.Label>
                  <TextArea
                  _focus={{borderColor: '#D0D5DD'}}
                    isReadOnly={props.isReadOnly}
                    defaultValue={campaignSegmentFormData?.description}
                    value={campaignSegmentFormData?.description}
                    onChangeText={(value: string) => {
                      setCampaignSegmentFormData((prev) => {
                        return {
                          ...prev,
                          description: value,
                        };
                      });
                    }}
                    isFullWidth={true}
                    placeholder="Enter Description for Audience Group"
                    placeholderTextColor={InputStyle.placeholderTextColor}
                    fontSize={InputStyle.placeHolderFontsize}
                  />
                </FormControl>
              )}

              {showLocationField && (
                <FormControl
                  style={[styles.formElement]}
                  key={'filter'}
                  isRequired
                  isInvalid={
                      validation.selectedLocations
                      ? true
                      : false
                  }
                >
                  <FormControl.Label style={styles.formLabel} isRequired>
                    <DisplayText
                      textLocalId={'Choose Location'}
                      size={'smRegular'}
                      extraStyles={{color: Colors.FoldPixel.GRAY250}}
                    />
                  </FormControl.Label>
                  <AntdSelect
                    mode="multiple"
                    size="large"
                    allowClear
                    value={campaignSegmentFormData.selectedAccountLocations?.map((location) => location.uuid)}
                    disabled={!isLocationEditable(campaignSegmentFormData.selectedAccountLocations || [])}
                    optionFilterProp="label"
                    optionLabelProp="label"
                    tagRender={tagRender}
                    placeholder="Select location"
                    dropdownStyle={
                      selectedFilterType === FilterType.SELECT_BY_PATIENT
                        ? {}
                        : Array.isArray(campaignSegmentFormData.selectedAccountLocations) &&
                          campaignSegmentFormData.selectedAccountLocations.length > 0
                            ? { minWidth: '20%' }
                            : {}
                    }
                    onChange={(value: string[]) => {
                      if (value.length === 0) {
                        setComponentState((prev) => ({
                          ...prev,
                          showMinimumLocationSupportText: true,
                        }));
                        return;
                      } else {
                        setComponentState((prev) => ({
                          ...prev,
                          showMinimumLocationSupportText: false,
                        }));
                      }
                      const selectedLocations: EHRWiseLocations[] = [];
                      const deselectedLocations: EHRWiseLocations[] = [];
                      const selectedLocationIds = new Set(value);
                      allowedAccountLocations?.forEach((location: any) => {
                        if (selectedLocationIds.has(location.uuid)) {
                          selectedLocations.push(location);
                        } else if (campaignSegmentFormData?.selectedAccountLocations?.some((loc) => loc.uuid === location.uuid)) {
                          deselectedLocations.push(location);
                        }
                      });
                      if (deselectedLocations.length === 0) {
                        setCampaignSegmentFormData((prev) => ({
                          ...prev,
                          selectedAccountLocations: selectedLocations,
                        }));
                      }
                      else {
                        handleDeselectedLocation(deselectedLocations, selectedLocations)
                      }
                    }
                  }
                    maxTagCount={'responsive'}
                  >
                    {locationsDataList?.map((record: any) => {
                      return (
                        <AntdSelect.Option
                          key={record?.uuid}
                          userId={record?.uuid}
                          value={record?.uuid}
                          label={record?.name}
                        >
                          {record?.name}
                        </AntdSelect.Option>
                      );
                    })}
                  </AntdSelect>
                  {validation?.selectedLocations ? (
                    <FormControl.ErrorMessage
                      _text={{
                        fontSize: 'xs',
                        color: 'error.500',
                        fontWeight: 500,
                      }}
                    >
                      {validation?.selectedLocations}
                    </FormControl.ErrorMessage>
                  ) : null}
                  {componentState?.showMinimumLocationSupportText &&
                    <DisplayText
                      extraStyles={{
                        color: Colors?.Custom?.Gray400,
                        fontSize: 12,
                      }}
                      textLocalId={'At least one location is required'}
                    />}
                </FormControl>)}
              {showChooseFilterField && (
                <FormControl
                  style={[styles.formElement]}
                  key={'filter'}
                  isRequired
                  isInvalid={
                    props.campaignDetailsError?.campaignTime ||
                    validation.selectedFilter
                      ? true
                      : false
                  }
                >
                  <FormControl.Label style={styles.formLabel} isRequired>
                    <DisplayText
                      textLocalId={'Choose Filter'}
                      size={'smRegular'}
                      extraStyles={{color: Colors.FoldPixel.GRAY250}}
                    />
                  </FormControl.Label>
                  <Select
                    minWidth="150"
                    isDisabled={isFromContactList}
                    selectedValue={selectedFilterType || undefined}
                    accessibilityLabel="Choose Filter"
                    placeholder="Choose Filter"
                    _selectedItem={{
                      bg: 'teal.600',
                      endIcon: <CheckIcon size={5} />,
                    }}
                    onValueChange={(value) => {
                      setSelectedFilterType(value as FilterType);
                      removeOldData();
                    }}
                  >
                    <Select.Item
                      key={1}
                      label={intl.formatMessage({id:'staticSearchAndAddMembers'})}
                      value="selectPatient"
                    />
                    <Select.Item
                      key={2}
                      label={intl.formatMessage({id:'dynamicAddByPatientChars'})}
                      value="selectOther"
                    />
                  </Select>
                  {props.campaignDetailsError?.campaignTime ||
                  validation.selectedFilter ? (
                    <FormControl.ErrorMessage
                      _text={{
                        fontSize: 'xs',
                        color: 'error.500',
                        fontWeight: 500,
                      }}
                    >
                      {props.campaignDetailsError?.campaignTime ||
                        validation.selectedFilter}
                    </FormControl.ErrorMessage>
                  ) : (
                    <></>
                  )}
                </FormControl>
              )}

              {showInitialPopulationField && (
                  <FormControl
                    style={[styles.formElement]}
                    key={'initialPopulation'}
                  >
                    <FormControl.Label>
                    <HStack>
                      <DisplayText
                        textLocalId={intl.formatMessage({id:'initialPopulation'})}
                        size={'smRegular'}
                        extraStyles={{color: Colors.FoldPixel.GRAY250}}
                      />
                      <Tooltip
                        title={intl.formatMessage({id:'initialPopulationInfoMsg'})}
                        placement={'top'}
                        destroyTooltipOnHide={true}
                      >
                        <Icon
                          mt={1}
                          mx={2}
                          as={AntIcon}
                          color={Colors.Custom.Gray500}
                          name="infocirlceo"
                          size="smMedium"
                        />
                      </Tooltip>
                    </HStack>
                  </FormControl.Label>
                    <AntdSelect
                      style={{ minWidth: 150 }}
                      allowClear={true}
                      value={populationGroupState?.selectedPopulationGroupName}
                      placeholder={"Search and Add Initial Population"}
                      showSearch={true}
                      loading={populationGroupState.searchLoading}
                      onSearch={handleSearch}
                      notFoundContent={renderNotFoundContent()}
                      onChange={handleSelectChange}
                    >
                      {populationGroupState.list.map((item: any, index: any) => (
                        <AntdSelect.Option key={index} value={item?.name} label={item?.name} ruleId={item?.id}>
                          {item.name}
                        </AntdSelect.Option>
                      ))}
                    </AntdSelect>
                  </FormControl>
              )}
              {furtherFormFieldsLoading ? (
                <Skeleton.Text lines={4}></Skeleton.Text>
              ) : (
                <>
                  {showFrequencyField && (
                      <>
                        <FormControl
                          style={[styles.formElement]}
                          key={'frequency'}
                          isRequired
                          isInvalid={
                            validation.selectedFrequency && !isInitialPopulationSelected
                              ? true
                              : false
                          }
                        >
                          {validation.selectedFrequency && !isInitialPopulationSelected && (
                            <FormControl.ErrorMessage
                              _text={{
                                fontSize: 'xs',
                                color: 'error.500',
                                fontWeight: 500,
                              }}
                            >
                              {validation.selectedFrequency}
                            </FormControl.ErrorMessage>
                          )}
                          <FormControl.Label style={styles.formLabel} isRequired>
                            <DisplayText
                              textLocalId={'Choose Frequency'}
                              size={'smRegular'}
                              extraStyles={{color: Colors.FoldPixel.GRAY250}}
                            />
                          </FormControl.Label>
                          <AntdSelect
                            style={{ minWidth: 150 }}
                            allowClear={true}
                            value={campaignSegmentFormData?.executionFrequency}
                            disabled={isInitialPopulationSelected}
                            placeholder={"Choose Frequency"}
                            onChange={(value: string, data: any) => {
                              setSelectedFrequencyType(value as FrequencyType);
                              setCampaignSegmentFormData((prev) => {
                                return {
                                  ...prev,
                                  executionFrequency: value,
                                };
                              });
                            }}
                          >
                            {frequencyOptions.map((item: any, index: any) => (
                              <AntdSelect.Option key={index} value={item?.code} label={item?.value}>
                                {item.value}
                              </AntdSelect.Option>
                            ))}
                          </AntdSelect>
                        </FormControl>
                        {isInitialPopulationSelected && (
                          <View>
                            <Text
                              paddingTop={-1}
                              fontSize={12}
                              color={Colors.Custom.Gray400}
                            >
                              {intl.formatMessage({ id: 'disabledExecutionFrequencyMsg' })}
                            </Text>
                          </View>
                        )}
                        {!isOnLeadGroups && (
                          <>
                            <FormControl
                              style={[styles.formElement]}
                              key={'membershipStatus'}
                            >
                              <FormControl.Label style={styles.formLabel}>
                                <DisplayText
                                  textLocalId={openInHint ? 'Current Hint Membership Status' : 'Fold Membership Status'}
                                  size={'smRegular'}
                                  extraStyles={{ color: Colors.FoldPixel.GRAY250 }}
                                />
                              </FormControl.Label>
                              <AntdSelect
                                style={{ minWidth: 150 }}
                                allowClear={true}
                                value={campaignSegmentFormData?.memberStatus?.code}
                                disabled={isInitialPopulationSelected}
                                placeholder={openInHint ? "Choose Hint Membership Status" : "Choose Fold Membership Status"}
                                onChange={(value: string, data: any) => {
                                  const selectedMemberStatus = memberStatusOptions?.find(memberStatus => memberStatus?.code === value);
                                  setCampaignSegmentFormData((prev) => {
                                    const updatedMemberStatus = prev.memberStatus?.code === value ? undefined : selectedMemberStatus;
                                    return {
                                      ...prev,
                                      memberStatus: updatedMemberStatus,
                                      ishintMembershipStatus: openInHint,
                                    };
                                  });
                                }}
                              >
                                {memberStatusOptions.map((item: any, index: any) => (
                                  <AntdSelect.Option key={index} value={item?.code} label={item?.value}>
                                    {item.value}
                                  </AntdSelect.Option>
                                ))}
                              </AntdSelect>

                            </FormControl>
                            {(isInitialPopulationSelected) && (
                              <View>
                                <Text
                                  paddingTop={-1}
                                  fontSize={12}
                                  color={Colors.Custom.Gray400}
                                >
                                  {intl.formatMessage({ id: 'disabledMemberStatusMsg' })}
                                </Text>
                              </View>
                            )}
                          </>
                        )}
                        {isOnLeadGroups && showRuleEngineInputField && (
                          <FormControl isInvalid={validation.jsonTree ? true : false}>
                            {validation.patientList && (
                              <FormControl.ErrorMessage
                                _text={{
                                  fontSize: 'xs',
                                  color: 'error.500',
                                  fontWeight: 500,
                                }}
                              >
                                {validation.jsonTree}
                              </FormControl.ErrorMessage>
                            )}
                            <RuleEngineInputField
                              isPatientGroup={isPatientGroup}
                              jsonTree={campaignSegmentFormData.jsonTree}
                              handleJSONTreeStateChange={handleJSONTreeStateChange}
                              selectedAccountLocations={campaignSegmentFormData.selectedAccountLocations}
                            />
                          </FormControl>
                        )}
                      </>
                  )}
                </>
                )
              }
              {((context === CampaignSegmentTableContext.POPULATION_GROUP) &&
                (selectedFilterType === FilterType.SELECT_BY_PATIENT)) && (
                  <>
                    <FormControl
                      style={[styles.formLastElement, {paddingBottom: 16}]}
                      isInvalid={
                        campaignSegmentFormData.errors || validation.patientList
                          ? true
                          : false
                      }
                    >
                      <FormControl.Label isRequired style={styles.formLabel}>
                        <Image
                          width={'20px'}
                          height={'20px'}
                          style={{ marginRight: 5 }}
                          resizeMode={'contain'}
                          // style={styles.logoImg}
                          source={{
                            uri: 'https://i.imgur.com/rapJbNT.png',
                            cache: 'force-cache',
                          }}
                          alt={'selectAppointmentPatients'}
                        />
                        <DisplayText
                          textLocalId={geSearchMemberLabel()}
                          size={'smMedium'}
                          extraStyles={{ color: Colors.Custom.Gray700 }}
                        />
                      </FormControl.Label>
                      <PatientDropdownList
                        contactTypeUuid={contactTypeUuid}
                        notShowPatient={!!isOnLeadGroups}
                        showLeadAndPatient={props?.showLeadAndPatient}
                        defaultValue={campaignSegmentFormData.patientList}
                        onSelectValue={(patientIds: any, contactData: any) => {
                          setCampaignSegmentFormData((prev) => {
                            return {
                              ...prev,
                              patientList: patientIds,
                            };
                          });
                          setComponentState((prev) => ({
                            ...prev,
                            selectedPatientsWithContactData: contactData,
                          }));
                        }}
                        returnNonLocationAccesPatientIds={(contactIds: number[])=> {
                          setCampaignSegmentFormData(prev=> {
                            return {
                              ...prev,
                              notAccessPatientList: contactIds
                            }
                          })
                        }}
                        selectedPatientsWithContactData={componentState?.selectedPatientsWithContactData}
                        selectedMembers={componentState?.selectedPatientsWithContactData?.map(contact => {
                          return {
                            label: contact.name, value: contact.id, key: contact.id
                          }
                        })}
                        locationOptionList={selectedLocationsIds}
                        isMsoEnabled={isMsoEnabled}
                        useActionForPatientQuery={context === CampaignSegmentTableContext.POPULATION_GROUP}
                      />
                      {(campaignSegmentFormData.errors ||
                        validation.patientList) && (
                          <FormControl.ErrorMessage
                            _text={{
                              fontSize: 'xs',
                              color: 'error.500',
                              fontWeight: 500,
                            }}
                          >
                            {campaignSegmentFormData.errors ||
                              validation.patientList}
                          </FormControl.ErrorMessage>
                        )}
                    </FormControl>
                  </>
                )}
                {!isOnLeadGroups && context === CampaignSegmentTableContext.POPULATION_GROUP && selectedFilterType === FilterType.SELECT_BY_PATIENT && (
                  <Stack direction='column' style={{flex: 5}}>
                    <Text style={{ color: Colors.Custom.Gray500, fontSize: 14, marginTop: 12 }}>{'Below automation(s) will execute for this population group'}</Text>
                    <PopGroupAutomation
                      popGroupDetails={{
                        name: campaignSegmentFormData.audienceName || '',
                        id: campaignSegmentFormData.id || '',
                        locationIds: campaignSegmentFormData.selectedAccountLocations?.map(location => location?.uuid) || [],
                        isUpdate: !!props.initData?.id,
                      }}
                      onChange={(selectedList: IAutomation[], updatedList: IAutomation[]) => {
                        setCampaignSegmentFormData((prev) => ({
                          ...prev,
                          automationToUpdate: updatedList,
                        }));
                      }}
                    />
                  </Stack>
                )}
            </Stack>

                {!isOnLeadGroups && showRuleEngineInputField && (
                  <Stack direction='column' style={{flex: 5}}>
                    {!isOnLeadGroups && showRuleEngineInputField && (
                      <FormControl isInvalid={validation.jsonTree ? true : false} style={{ marginLeft: -8, marginBottom: -8, marginRight: -16, maxHeight: height * 0.5, marginTop: 8, overflow: 'scroll' }}>
                        <Text style={{ color: Colors.Custom.Gray500, fontSize: 14 }}>{'If below condition matches'}</Text>
                        <RuleEngineInputField
                          isPatientGroup={isPatientGroup}
                          jsonTree={campaignSegmentFormData.jsonTree}
                          handleJSONTreeStateChange={handleJSONTreeStateChange}
                          selectedAccountLocations={campaignSegmentFormData.selectedAccountLocations}
                        />
                        {validation.patientList && (
                          <FormControl.ErrorMessage
                            _text={{
                              fontSize: 'xs',
                              color: 'error.500',
                              fontWeight: 500,
                            }}
                          >
                            {validation.jsonTree}
                          </FormControl.ErrorMessage>
                        )}
                      </FormControl>
                    )}
                    <Text style={{ color: Colors.Custom.Gray500, fontSize: 14, marginTop: 12 }}>{'then execute below automation(s)'}</Text>
                    <PopGroupAutomation
                      popGroupDetails={{
                        name: campaignSegmentFormData.audienceName || '',
                        id: campaignSegmentFormData.id || '',
                        locationIds: campaignSegmentFormData.selectedAccountLocations?.map(location => location?.uuid) || [],
                        isUpdate: !!props.initData?.id,
                      }}
                      onChange={(selectedList: IAutomation[], updatedList: IAutomation[]) => {
                        setCampaignSegmentFormData((prev) => ({
                          ...prev,
                          automationToUpdate: updatedList,
                        }));
                      }}
                    />
                  </Stack>
                )}
            </Stack>
              {
                <Modal
                  title={componentState.showRemovingFieldsInRuleAlert ? "Remove Rule Filter Values?" : "Remove Members? "}
                  open={componentState.showRemovingPatientAlert || componentState.showRemovingFieldsInRuleAlert}
                  onCancel={() => {
                    setComponentState((prev) => ({
                      ...prev,
                      showRemovingPatientAlert: false,
                      showRemovingFieldsInRuleAlert: false,
                    }));
                  }}
                  footer={null}
                  bodyStyle={{ paddingTop: 12 }}
                >
                  <View>
                    <Text size={'mdMedium'} >{componentState.showRemovingFieldsInRuleAlert ? 'Going ahead will remove the rule filter values associated with the deselected locations. Do you wish to continue?' : 'Going ahead will remove the members belonging to the deselected locations. Do you wish to continue?'}</Text>
                    <Button.Group style={{
                      marginTop: 12, display: 'flex',
                      justifyContent: 'flex-end',
                    }}>
                      <FoldButton
                        nativeProps={{
                          variant: BUTTON_TYPE.SECONDARY,
                          style: { margin: 3 },
                          onPress: () => {
                            const tagEvent = componentState?.locationTagHandler?.tagEvent;
                            if (tagEvent) {
                              tagEvent?.preventDefault?.();
                            }
                            setComponentState((prev) => ({
                              ...prev,
                              showRemovingPatientAlert: false,
                              showRemovingFieldsInRuleAlert: false,
                              locationTagHandler: undefined,
                          }));
                        }}}
                        customProps={{
                          btnText: intl.formatMessage({
                            id: 'No',
                          }),
                          withRightBorder: false,
                        }}
                      ></FoldButton>
                      <FoldButton
                        nativeProps={{
                          variant: BUTTON_TYPE.PRIMARY,
                          style: { margin: 3 },
                          onPress: () => {
                            const deselectedLocationIds = new Set(componentState?.deselectedLocations?.map(location => location?.uuid));
                            const { selectedTagProp: tagProps, tagEvent } = componentState?.locationTagHandler || {};
                            const resetPopulationGroupState = () => setPopulationGroupState(prev => ({ ...prev, selectedPopulationGroupName: undefined }));
                            const updateCampaignSegmentFormData = (patientList: IContact[]) => {
                              setCampaignSegmentFormData(prev => ({
                                ...prev,
                                patientList: patientList.map(contact => contact.id),
                                populationGroupId: undefined,
                                executionFrequency: undefined,
                                selectedAccountLocations: prev?.selectedAccountLocations?.filter(location => !deselectedLocationIds.has(location.uuid)),
                              }));
                              setIsInitialPopulationSelected(false);
                            };
                            const handleTagClosure = () => {
                              if (tagProps && tagEvent) {
                                tagProps.onClose(tagEvent);
                                resetLocationTagHandler();
                              }
                            };
                            const filteredPatientList = componentState?.selectedPatientsWithContactData?.filter(contact =>
                              !contact?.contactPracticeLocations?.some(location =>
                                location?.accountLocation?.uuid && deselectedLocationIds.has(location?.accountLocation?.uuid)
                              )
                            ) || [];

                            if (componentState?.showRemovingFieldsInRuleAlert) {
                              const filteredJsonTree = removeLocationSpecificFields(cloneDeep(campaignSegmentFormData?.jsonTree));
                              handleJSONTreeStateChange(filteredJsonTree);
                            } else {
                              setComponentState(prev => ({
                                ...prev,
                                selectedPatientsWithContactData: filteredPatientList,
                              }));
                            }
                            updateCampaignSegmentFormData(filteredPatientList);
                            handleTagClosure();
                            resetPopulationGroupState();
                            setComponentState((prev) => ({
                              ...prev,
                              showRemovingPatientAlert: false,
                              showRemovingFieldsInRuleAlert: false,
                            }));
                          },
                        }}
                        customProps={{
                          btnText: intl.formatMessage({
                            id: 'Yes',
                          }),
                          withRightBorder: false,
                        }}
                      ></FoldButton>
                    </Button.Group>
                  </View>
                </Modal>
              }
          </HStack>
        </VStack>
      </ScrollView>
    </VStack>
    // </PageBodyContainer>
  );
};

export default  CampaignSegmentForm;
