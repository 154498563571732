import {StyleSheet} from 'react-native';
import {Colors} from '../../../../styles';

export const reasonForPrioritizationStyles = StyleSheet.create({
  reasonForPrioritizationHeaderView: {
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 8,
  },
  reasonForPrioritizationView: {
    padding: 12,
  },
  poweredByUnityText: {
    fontSize: 14,
    color: Colors.Custom.Primary200,
  },
  reasonForPrioritizationText: {
    fontSize: 16,
    textAlign: 'center',
    marginLeft: 6,
  },
  reasonForPrioritizationContainer: {
    justifyContent: 'space-between',
    borderRadius: 8,
    borderColor: Colors.FoldPixel.GRAY100,
    borderWidth: 1,
    backgroundColor: Colors.Custom.ContainerBGColor,
    marginBottom: 10,
  },
  moreText: {
    fontSize: 12,
    fontWeight: '400',
    color: Colors.Custom.Primary300,
  },
  reasonText: {
    fontSize: 14,
    fontWeight: '400',
    color: Colors.FoldPixel.GRAY400,
  },
});
