import { Circle, ClipPath, Defs, G, Stop, RadialGradient, Path, Svg, Rect, LinearGradient } from "react-native-svg";

const SideCarReferralOrderNoDataSvg = () => {
  return (
    <Svg width="122" height="121" viewBox="0 0 122 121" fill="none">
    <Circle cx="61.001" cy="60.5" r="60" stroke="url(#paint0_radial_8624_300448)" strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="1.75 1.75"/>
    <Circle cx="61.0018" cy="60.4998" r="51.2" stroke="url(#paint1_radial_8624_300448)" strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="1.75 1.75"/>
    <Path d="M18.8271 60.6211C18.8271 37.4251 37.6312 18.6211 60.8271 18.6211V18.6211C84.0231 18.6211 102.827 37.4251 102.827 60.6211V60.6211C102.827 83.8171 84.0231 102.621 60.8271 102.621V102.621C37.6312 102.621 18.8271 83.8171 18.8271 60.6211V60.6211Z" fill="url(#paint2_linear_8624_300448)"/>
    <Path d="M79.7217 62.3607C81.0606 60.8962 81.0606 58.652 79.7217 57.1875L66.8855 43.1479C66.2954 42.5024 65.2199 42.92 65.2199 43.7946V50.5501C65.2199 51.6087 64.3654 52.461 63.3073 52.4947C54.8905 52.7631 41.2648 55.3825 40.0128 77.2214C39.9577 78.1816 41.2071 78.5698 41.758 77.7814C48.2955 68.4251 53.4209 67.2029 63.2997 67.0911C64.3582 67.0791 65.2199 67.9395 65.2199 68.998V75.7536C65.2199 76.6282 66.2954 77.0457 66.8855 76.4002L79.7217 62.3607Z" stroke="#8A94A8" stroke-width="1.91667" stroke-linecap="round" stroke-linejoin="round"/>
    <Defs>
    <RadialGradient id="paint0_radial_8624_300448" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(61.0011 87.6298) rotate(-90.639) scale(94.1607)">
    <Stop stopColor="#A1AABA"/>
    <Stop offset="1" stopColor="#E4E7EC"/>
    </RadialGradient>
    <RadialGradient id="paint1_radial_8624_300448" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(61.0018 83.6506) rotate(-90.639) scale(80.3504)">
    <Stop stopColor="#A1AABA"/>
    <Stop offset="1" stopColor="#E4E7EC"/>
    </RadialGradient>
    <LinearGradient id="paint2_linear_8624_300448" x1="31.002" y1="28.0992" x2="88.602" y2="100.999" gradientUnits="userSpaceOnUse">
    <Stop stopColor="#F9FAFC"/>
    <Stop offset="1" stopColor="#F1F2F3"/>
    </LinearGradient>
    </Defs>
    </Svg>
  );
};

export default SideCarReferralOrderNoDataSvg;


