import { Select as AntSelect} from 'antd';
import { HStack, Text, View, VStack } from 'native-base';
import { useContext, useState } from 'react';
import { BUTTON_TYPE } from '../../../../../constants';
import { WorkflowContext } from '../../../../../context/WorkflowContext';
import AlertPopUp from './AlertPopUp';
import { IConditionState, IUserActionState } from './FlowNodeInterface';
import { CaretDownOutlined } from '@ant-design/icons';
import { Colors } from '../../../../../styles';
import { reactStyles } from '../../workflowStyles';

// eslint-disable-next-line @typescript-eslint/ban-types
const SideMenuHeader = (props: {isViewOnly?: boolean, nodeId: string, replaceNodeById: Function, previousImmediateNodeId: string, stateData: IUserActionState | IConditionState, data: any, parentOutputNodeTypeList: Array<string> }) => {
  const workflowContext = useContext(WorkflowContext);
  const nodeMasterDataMap = workflowContext.nodeMasterDataMap;
  const parentOutputNodeTypeList = props.parentOutputNodeTypeList;
  const [stateData] = useState(props.stateData)
  const [isShowNodeTypeChangeAlert, setIsShowNodeTypeChangeAlert] = useState(false)
  const [newNodeType, setNewNodeType] = useState('')


  let outputNodeListCopy: Array<string> = [];
  if (parentOutputNodeTypeList && parentOutputNodeTypeList.length) {
    outputNodeListCopy = parentOutputNodeTypeList.filter((nodeType: string, index: number, currentArray: Array<string>) => {
      return nodeMasterDataMap[nodeType] && nodeMasterDataMap[nodeType]?.category?.code == stateData?.category?.code &&
      !nodeMasterDataMap[nodeType]?.isDeprecated && currentArray.indexOf(nodeType) == index;
    });
  }
  if(stateData?.type && outputNodeListCopy.indexOf(stateData?.type) === -1){
    outputNodeListCopy.push(stateData.type)
  }
  outputNodeListCopy = outputNodeListCopy.map((nodeType) => {
    return { ...nodeMasterDataMap[nodeType] }
  })

  const [outputNodeList] = useState(outputNodeListCopy)
  return (
    <div className='custom-new-workflow-trigger'>
    <VStack flex={1} borderWidth={1} borderColor={'#E4E7EC'} paddingX={4} backgroundColor={'#F9FAFB'} borderRadius={8}>
    <HStack flex={1} marginY={2}>
      {props.previousImmediateNodeId && outputNodeList.length || true?
        <HStack flex={1}>
          <HStack flex={2.4} alignItems={'center'}>
            <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400} flex={2}>
              {stateData?.category?.display}
            </Text>
          </HStack>
          <HStack flex={7}  >
          <View marginY={1} flex={1} pointerEvents={props.isViewOnly ? 'none' : 'auto'}>
              <AntSelect
                suffixIcon={
                  <CaretDownOutlined
                    style={reactStyles.colorGray300}
                  />
                }
                size="large"
                placeholder={'Select ' + (stateData?.category?.display || '')}
                value={stateData?.type || ''}
                onChange={(value: string) => {
                  const selected: any = outputNodeList.filter((node: any) => {
                    return node?.type === value;
                  });
                  if (selected.length > 0) {
                    setIsShowNodeTypeChangeAlert(true);
                    setNewNodeType(value)
                  }
                }}
              >
                {outputNodeList.map((node: any) => {
                  return (
                    <AntSelect.Option
                     // label={node.displayName}
                      value={node.type || ''}
                      key={node.type || ''}
                    >
                      {node?.displayName || node?.type || ''}
                    </AntSelect.Option>
                  );
                })}
              </AntSelect>
            </View>
          </HStack>
        </HStack>
        :

       stateData.displayName
        }
    </HStack >
    <AlertPopUp buttonList={
      [{
        text: 'No',
        type: BUTTON_TYPE.PRIMARY,
        onClick: () => {
          setIsShowNodeTypeChangeAlert(false)
        }
      },
      {
        text: 'Yes',
        type: BUTTON_TYPE.SECONDARY,
        onClick: () => {
          props.replaceNodeById ? props.replaceNodeById(props.nodeId, newNodeType) : ''
          setIsShowNodeTypeChangeAlert(false)
        }
      }]
    }
      isVisible={isShowNodeTypeChangeAlert}
      OnCloseButtonPress={() => {
        setIsShowNodeTypeChangeAlert(false)
      }} messageTextLocalId={'Are you sure, you want to replace this ' + (stateData?.category?.display || '') + ' to option?'} headerTextLocalId={'Change ' + (stateData?.category?.display || '') + ' to'}></AlertPopUp>
  </VStack>
  </div>
  )
};

export default SideMenuHeader;
