import {HStack, VStack, View, Text, useToast} from 'native-base';
import {Switch, Tooltip} from 'antd';
import React, {useEffect, useState} from 'react';
import {NotificationOptions} from './NotificationOptions';
import {Colors} from '../../../styles/Colors';
import {updateNotificationSettingApi} from './NotificationSettings';
import {getAccountUUID, getUserUUID} from '../../../utils/commonUtils';
import { ToastType, showToast } from '../../../utils/commonViewUtils';
import { useIntl } from 'react-intl';
import {StyleSheet} from 'react-native';

const NotificationResourceSetting = (props: any) => {
  const {notificationData, allNotification, iconMap, index, onActionPerformed, onLoading} = props;
  const toast = useToast();
  const intl = useIntl();
  const accountId = getAccountUUID();
  const userUUID = getUserUUID();
  const [state, setState] = useState({
    switchValue: false,
    isApiCall: false,
  });

  const isCheckSwitchValue = () => {
    let isCheck = false;
    notificationData?.notificationObject.map((item: any) => {
      if (item?.userNotificationSettings?.length > 0) {
        isCheck = isCheck
          ? isCheck
          : item?.userNotificationSettings[0]?.pushValue;
      } else {
        isCheck = isCheck ? isCheck : item?.pushDefaultValue;
      }
    });
    setState((prev) => {
      return {
        ...prev,
        switchValue: isCheck,
        isApiCall: isCheck ? true : false,
      };
    });
  };

  const onSwitchChange = async (pushValue: boolean) => {
    onLoading(true);
    const notificationObj: any = [];
    notificationData?.notificationObject?.forEach?.((item: any) => {
      notificationObj.push({
        crmNotificationSettingId: item?.id,
        userId: userUUID,
        userNotificationSettingId: item?.userNotificationSettings[0]?.id,
        filterValue: {
          BUSINESS_HOURS:
            item?.userNotificationSettings[0]?.filterValue?.BUSINESS_HOURS ||
            item?.filter?.BUSINESS_HOURS,
        },
        pushValue: pushValue,
        accountUuid: accountId,
        createdBy: userUUID,
        updatedBy: userUUID,
      });
    });
    try {
      const data = {
        userNotificationSettings: notificationObj,
      };
      setState((prev) => {
        return {
          ...prev,
          switchValue: !prev.switchValue,
        };
      });
      const result = await updateNotificationSettingApi(data);
      result?.data?.userNotificationSettings.forEach(
        (item: any, index: number) => {
          const data = notificationData?.notificationObject;
          if (
            data[index].userNotificationSettings?.length > 0 &&
            item.crmNotificationSettingId ===
              data[index].userNotificationSettings?.[0]
                ?.crmNotificationSettingId
          ) {
            data[index].userNotificationSettings[0].pushValue = item.pushValue;
          } else if (data[index].userNotificationSettings?.length === 0) {
            data[index].userNotificationSettings.push({
              crmNotificationSettingId: item.crmNotificationSettingId,
              id: item.userNotificationSettingId,
              filterValue: item.filterValue,
              pushValue: item.pushValue,
              userId: item.userId,
            });
          }
          // });
        }
      );
      setState((prev) => {
        return {
          ...prev,
          isApiCall: !prev.isApiCall,
        };
      });
      onLoading(false);
      onActionPerformed();
    } catch (error) {
        setState((prev)=>{
          return{
            ...prev,
            switchValue: !prev.switchValue
          }
        })
        onLoading(false);
        showToast(
          toast,
          intl.formatMessage({id: 'notificationSettingApiErrorMsg'}),
          ToastType.error
        );
    }
  };

  const getTooltipAndTextElement = (label: string) => {
    if (props?.isMissCallSetting) {
      return (
        <Tooltip
          title={intl.formatMessage({
            id: 'missedCallNotificationTooltipText',
          })}
        >
          <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400} lineHeight={27}>
            {label}
          </Text>
        </Tooltip>
      );
    } else {
      return (
        <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400} lineHeight={27}>
          {label}
        </Text>
      );
    }
  };
  useEffect(() => {
    isCheckSwitchValue();
  }, []);

  const memorizedSwitchStyles = React.useMemo<React.CSSProperties>(() => {
    return {
      alignSelf: 'center',
      backgroundColor: state.switchValue
        ? Colors.primary[300]
        : Colors.Custom.Gray200,
    };
  }, [state.switchValue]);

  return (
    <VStack
      key={index}
      paddingX={6}
      paddingY={3}
      borderBottomWidth={index === allNotification.length - 1 ? 0 : 1}
      borderBottomColor={Colors.Custom.Gray200}
    >
      <VStack>
        <HStack
          paddingY={1.5}
          borderBottomWidth={(state?.switchValue && state.isApiCall) ? 1 : 0}
          borderBottomColor={Colors.Custom.Gray200}
        >
          <View
            borderRadius={'full'}
            background={'gray.100'}
            justifyContent={'center'}
            alignItems={'center'}
            style={styles.boxView}
          >
            {iconMap[notificationData.code]}
          </View>
          <View paddingLeft={4}>
            {getTooltipAndTextElement(notificationData?.label)}
            <Text
              size={'smRegular'}
              lineHeight={21}
              color={Colors.FoldPixel.GRAY300}
            >
              {state.switchValue ? intl.formatMessage({id: 'on'}) : intl.formatMessage({id: 'off'})}
            </Text>
          </View>
          <HStack flex={1} justifyContent={'flex-end'}>
            <Switch
              style={memorizedSwitchStyles}
              checked={state.switchValue}
              onChange={(value) => {
                onSwitchChange(value);
              }}
            />
          </HStack>
        </HStack>
      </VStack>
      {state.switchValue && state.isApiCall && (
        <VStack>
          {notificationData?.notificationObject.map(
            (content: any, index1: number) => {
              return (
                <NotificationOptions
                  iconMap={iconMap}
                  newData={notificationData?.notificationObject}
                  content={content}
                  index={index1}
                  onActionPerformed={()=>{
                    isCheckSwitchValue()
                    onActionPerformed()
                  }}
                  onLoading = {(loading: boolean) => {
                    onLoading(loading)
                  }}
                />
              );
            }
          )}
        </VStack>
      )}
    </VStack>
  );
};

export default NotificationResourceSetting;

const styles = StyleSheet.create({
  boxView: {width: 40, height: 40},
});
