import {Button, Collapse} from 'antd';
import {
  FlatList,
  FormControl,
  Hidden,
  HStack,
  Skeleton,
  Spacer,
  Spinner,
  Stack,
  Text,
  View,
  VStack,
} from 'native-base';
import React, {useCallback, useContext} from 'react';
import {useIntl} from 'react-intl';
import {Pressable} from 'react-native';
import {
  DATE_FORMATS,
  GROUP_MEMBER_TYPE,
} from '../../../../../../../../../constants';
import {Colors} from '../../../../../../../../../styles';
import {
  getCurrentTimeZone,
  getDateStrFromFormat,
  getMomentObj,
  getMomentObjFromDateStrAndFormat,
  isBetweenDate,
  isSameDay,
} from '../../../../../../../../../utils/DateUtils';
import Slot from '../../../../Slot';
import {useAppointmentDataContext} from '../../../contexts/AppointmentDataContext';
import Feather from 'react-native-vector-icons/Feather';
import {DisplayText} from '../../../../../../../DisplayText/DisplayText';
import {testID, TestIdentifiers} from '../../../../../../../../../testUtils';
import SlotsGroup from '../../../SlotsGroup';
import {ModalActionTimePicker} from '../../../../../../../ModalActionCommonComponent/ModalActionTimePicker';
import {useNavigate} from 'react-router-dom';
import {DisplayCardAvatar} from '../../../../../../../DisplayCard/DisplayCardAvatar';
import HiddenView from '../../../components/HiddenView';
import {SelectSlotsBy} from '../../../AppointmentBookingEnums';
import { CommonDataContext } from '../../../../../../../../../context/CommonDataContext';
import { getBooleanFeatureFlag, navigateToNewSettingTab } from '../../../../../../../../../utils/commonUtils';
import FeatureFlags from '../../../../../../../../../constants/FeatureFlags.enums';
import {IUserPracticeLocation} from '../../../AppointmentBookingIntefaces';
const DayWiseSlots = () => {
  const navigateToScreen = useNavigate();
  const {
    appointmentBookingState,
    accountUserList,
    rescheduleData,
    handleSlotSelection,
    handleSpecificTimeAndSlotClick,
    updateSlotDateAndTimeForSpecificTime,
    renderPrimaryUser,
    renderSecondaryUsers,
    getSelectedLocationName
  } = useAppointmentDataContext();
  const intl = useIntl();
  const {userWiseSlotMap, primaryUserList, selectedDate} =
    appointmentBookingState;
  const dateKey = getMomentObj(selectedDate).format(
    DATE_FORMATS.DISPLAY_DATE_FORMAT
  );
  const isByDate =
    appointmentBookingState.selectSlotByView === SelectSlotsBy.Date;
  const allDayList = Array.from(
    appointmentBookingState.dayWiseSlotMap.keys() || []
  ).filter((item) => {
    const list = appointmentBookingState.dayWiseSlotMap.get(item) || [];
    return list?.length > 0;
  });

  const [currentList, setCurrentList] = React.useState(allDayList.slice(0, 3));

  const commonData = useContext(CommonDataContext);
  const isNewSettingTabEnabled = getBooleanFeatureFlag(commonData.userSettings, FeatureFlags.IS_NEW_SETTING_TAB_ENABLED);

  function getSelectedTimezone() {
    return (
      appointmentBookingState.selectedTimezone?.timezone || getCurrentTimeZone()
    );
  }

  const getDay = useCallback((date: string) => {
    const momentDate = getMomentObjFromDateStrAndFormat(
      date,
      DATE_FORMATS.AVAILABILITY_DATE_FORMAT
    );
    return `(${momentDate.tz(getSelectedTimezone()).format('dddd')})`;
  }, []);

  const handleShowMore = useCallback(() => {
    setCurrentList((prev) => [
      ...prev,
      ...allDayList.slice(currentList.length, currentList.length + 3),
    ]);
  }, [currentList]);

  const getAvailabilityOfSelectedSlot = (): boolean => {
    const dayWiseAvailability = appointmentBookingState.dayWiseAvailability;
    if (dayWiseAvailability?.size) {
      const momentSelectedDate = getMomentObj(
        appointmentBookingState.selectedDate
      );
      if (appointmentBookingState.selectedSlot?.startDateTime) {
        const momentSlotStartDate = getMomentObj(
          appointmentBookingState.selectedSlot.startDateTime
        );
        momentSelectedDate.set('hour', momentSlotStartDate.get('hour'));
        momentSelectedDate.set('minutes', momentSlotStartDate.get('minutes'));
        momentSelectedDate.set('seconds', momentSlotStartDate.get('seconds'));
      }
      const slotStartDate = momentSelectedDate.toDate();
      const slotEndDate = appointmentBookingState.selectedSlot?.endDateTime
        ? getMomentObj(
            appointmentBookingState.selectedSlot.endDateTime
          ).toDate()
        : undefined;
      const currentSelectDateStr = getDateStrFromFormat(
        slotStartDate,
        DATE_FORMATS.DISPLAY_DATE_FORMAT
      );
      if (currentSelectDateStr) {
        const availableDaySlots =
          dayWiseAvailability?.get(currentSelectDateStr) || [];
        if (slotEndDate && !isSameDay(slotStartDate, slotEndDate)) {
          let isAvailableInSlot = availableDaySlots?.some((slot: any) => {
            return isBetweenDate(
              slotStartDate,
              slot.startDateTime,
              slot.endDateTime,
              '[)'
            );
          });
          const nextDateStr = getDateStrFromFormat(
            slotEndDate,
            DATE_FORMATS.DISPLAY_DATE_FORMAT
          );
          const nextDateAvailableDaySlots =
            dayWiseAvailability?.get(nextDateStr) || [];
          isAvailableInSlot =
            isAvailableInSlot &&
            nextDateAvailableDaySlots?.some((slot: any) => {
              return isBetweenDate(
                slotEndDate,
                slot.startDateTime,
                slot.endDateTime,
                '(]'
              );
            });
          return isAvailableInSlot;
        }
        const isAvailableInSlot = availableDaySlots?.some((slot: any) => {
          return (
            isBetweenDate(
              slotStartDate,
              slot.startDateTime,
              slot.endDateTime,
              '[)'
            ) &&
            slotEndDate &&
            isBetweenDate(
              slotEndDate,
              slot.startDateTime,
              slot.endDateTime,
              '(]'
            )
          );
        });
        return isAvailableInSlot;
      }
    }
    return false;
  };

  const renderTimeSlotErrors = (): JSX.Element => {

    if (!isByDate && !appointmentBookingState.selectedPrimaryUserId) {
      return (
        <DisplayText
          textLocalId={'noUserSelected'}
          extraStyles={{
            color: Colors.Custom.Gray700,
            fontWeight: 500,
            fontSize: 14,
          }}
        />
      );
    }

    if (
      appointmentBookingState?.isPracticeAvailabilitySchedulePresent &&
      !appointmentBookingState?.slots?.length
    ) {
      return (
        <DisplayText
          textLocalId={!isByDate ? 'noSlotsAvailable' : 'noUsersAvailable'}
          extraStyles={{
            color: Colors.Custom.Gray700,
            fontWeight: 500,
            fontSize: 14,
          }}
        />
      );
    }
    if (
      !appointmentBookingState?.isPracticeAvailabilitySchedulePresent &&
      !appointmentBookingState?.isLocationNotMatchWithAppointmentType
    ) {
      return (
        <Text fontSize="xs" color="error.500">
          Looks like you have't created practice availability schedule for this
          location.
          <Text>
            {'\n'}Please click{' '}
            <Pressable
              onPress={onSettingPress}
            >
              <Text fontStyle="italic" color="primary.500">
                here
              </Text>
            </Pressable>{' '}
            to create the schedule.
          </Text>
        </Text>
      );
    }
    if (appointmentBookingState?.isLocationNotMatchWithAppointmentType) {
      return (
        <Text fontSize="xs" color="error.500">
          {intl.formatMessage({
            id: 'chooseLocationWithTaggedAppointmentType',
          })}
        </Text>
      );
    }
    return <></>;
  };
  const hasAvailability = getAvailabilityOfSelectedSlot();
  const renderMainSlotView = () => {
    if (
      isByDate &&
      !appointmentBookingState.slotLoading &&
      userWiseSlotMap.size > 0 &&
      primaryUserList?.length > 0 
    ) {
      return (
        <Collapse
          accordion
          className="custom-collapse"
          style={{width: '100%'}}
          expandIconPosition="end"
        >
          {primaryUserList.map((item) => {
            const userSlotMap = userWiseSlotMap?.get(item.uuid) || {};
            const slotsForSelectedDate = userSlotMap[dateKey] || [];
            const isSelectedInSecondaryUser = appointmentBookingState.selectedSecondaryUserIds.includes(item.uuid);
           
            const isSelectedInPrimaryUser = appointmentBookingState.selectedPrimaryUserId === item.uuid;
            const locations =
              item.userPracticeLocations as IUserPracticeLocation[];
            const isAvailableAtSelectedLocation = locations.some(
              (location) =>
                location.accountLocation?.uuid ===
                appointmentBookingState.selectedAccountLocationId
            );

            if (!isAvailableAtSelectedLocation || isSelectedInSecondaryUser) {
              return <></>
            }
            
            return (
              <Collapse.Panel
                key={item.uuid}
                collapsible={
                  slotsForSelectedDate.length > 0 ? undefined : 'disabled'
                }
                header={
                  <HStack alignItems={'center'}>
                    <DisplayCardAvatar
                      avatarStyle={{
                        avatarSize: '6',
                        textStyle: {fontSize: 12, padding: '0'},
                        disableTop: true,
                      }}
                      isLetterAvatarShow
                      userData={{
                        userId: item?.id,
                        userType: GROUP_MEMBER_TYPE.USER,
                        userName: item?.name,
                      }}
                    />
                    <Text fontSize={14} ml={1} color={Colors.FoldPixel.GRAY400}>
                      {item.name}
                    </Text>
                  </HStack>
                }
                extra={
                  <View
                    backgroundColor={Colors.FoldPixel.GRAY50}
                    paddingX={1}
                    borderRadius={4}
                    borderColor={Colors.FoldPixel.GRAY100}
                    borderWidth={0.5}
                  >
                    <Text
                      fontSize={12}
                      color={
                        slotsForSelectedDate.length > 0
                          ? Colors.FoldPixel.GRAY200
                          : Colors.FoldPixel.GRAY150
                      }
                    >
                      {slotsForSelectedDate.length > 0
                        ? `${slotsForSelectedDate.length} Slots available`
                        : 'No slots available'}
                    </Text>
                  </View>
                }
              >
                {slotsForSelectedDate.length > 0 ? (
                  <SlotsGroup
                    skipGrouping
                    selectedSlotDate={appointmentBookingState.selectedDate}
                    selectedSlot={isSelectedInPrimaryUser ? appointmentBookingState.selectedSlot : null}
                    selectedTimezone={getSelectedTimezone()}
                    userSlots={slotsForSelectedDate}
                    handleSlotSelection={(slot) =>
                      handleSlotSelection(slot, item)
                    }
                  />
                ) : (
                  <Text p={2} fontSize={14} color={Colors.FoldPixel.GRAY400}>
                    No slots available
                  </Text>
                )}
              </Collapse.Panel>
            );
          })}
        </Collapse>
      );
    }
    if (!isByDate){  
      return (
      <>
        {appointmentBookingState.slots &&
          // appointmentBookingState.selectedSlot &&
          appointmentBookingState.slots.length > 0 && (
            <SlotsGroup
              selectedSlotDate={appointmentBookingState.selectedDate}
              selectedEndDate={appointmentBookingState?.selectedEndDate}
              selectedSlot={appointmentBookingState.selectedSlot}
              selectedTimezone={getSelectedTimezone()}
              userSlots={appointmentBookingState.slots}
              handleSlotSelection={handleSlotSelection}
            />
          )}
      </>
      );
    }
    return <></>;
  };

  const isSlotsAvailable =
    !appointmentBookingState.slotLoading &&
    appointmentBookingState.slots.length > 0;
  const isUserWiseSlotsAvailable =
    !appointmentBookingState.slotLoading &&
    appointmentBookingState.userWiseSlotMap.size > 0;

  const onSettingPress = () => {
    const settingsPath = '/admin/schedule/types';
    if (isNewSettingTabEnabled) {
      navigateToNewSettingTab(settingsPath);
    } else {
      navigateToScreen(settingsPath);
    }
  }
  
  const selectedLocationName = getSelectedLocationName();

  return (
    <>
      <Stack w="100%" space={4}>
        <FormControl isInvalid={appointmentBookingState.errors.slots}>
          <HStack alignItems={'center'} w="100%" pb={2}>
            <Text
              color={Colors.FoldPixel.GRAY300}
              fontSize={14}
              fontWeight={'600'}
            >
              {intl.formatMessage({
                id: appointmentBookingState.isSpecificTimeView
                  ? 'specificTime'
                  : 'availableSlots',
              })}
            </Text>
            <Spacer />
            <HStack space={1} alignItems={'center'} justifyContent={'flex-end'}>
              <Pressable onPress={handleSpecificTimeAndSlotClick}>
                <HStack alignItems={'center'} space={1}>
                  <Feather
                    name="clock"
                    color={Colors.Custom.mainPrimaryPurple}
                    size={12}
                  />
                  <Text color={Colors.Custom.mainPrimaryPurple} fontSize={12} {...testID(
                    appointmentBookingState.isSpecificTimeView
                      ? TestIdentifiers.seeTimeSlots
                      : TestIdentifiers.pickSpecificTime
                  )}>
                    {appointmentBookingState.isSpecificTimeView
                      ? 'See time slots'
                      : 'Pick a specific time'}
                  </Text>
                </HStack>
              </Pressable>
            </HStack>
          </HStack>
          <HStack marginTop={2} space={2} alignItems={'center'}>
              <Feather name="alert-circle" size={16} color={Colors.Custom.Gray500}  />
              {selectedLocationName && (
                <Text
                  fontSize="xs"
                  color="gray.500"
                  flexShrink={1}
                >
                  {appointmentBookingState.isSpecificTimeView
                    ? ''
                    : `Showing slots for ${selectedLocationName}`}
                </Text>
              )}
            </HStack>
          {!appointmentBookingState.isSpecificTimeView &&
            appointmentBookingState.selectedSecondaryUserIds.length > 0 && (
              <HStack alignItems={'center'} space={2} my={2}>
                <Feather
                  name="alert-circle"
                  size={16}
                  color={Colors.Custom.Gray500}
                  style={{marginTop: 2}}
                />
                <Text fontSize={12} color={Colors.Custom.Gray500} width="full">
                  {
                    'Showing time slots as per availability of all selected providers'
                  }
                </Text>
              </HStack>
            )}
          {!appointmentBookingState.isSpecificTimeView && (
            <View flex={1} px={0} flexDirection="row" flexWrap="wrap">
              {renderMainSlotView()}
              {(!isByDate && !isSlotsAvailable) ||
              (isByDate && !isUserWiseSlotsAvailable)
                ? renderTimeSlotErrors()
                : null}
            </View>
          )}
          {appointmentBookingState.isSpecificTimeView && (
            <VStack space={2}>
              {isByDate && (
                <>
                  <View>{renderPrimaryUser()}</View>
                  <HiddenView
                    title={'Add Secondary Users'}
                    defaultOpen={
                      appointmentBookingState.selectedSecondaryUserIds &&
                      appointmentBookingState.selectedSecondaryUserIds?.length >
                        0
                    }
                    icon={
                      <Feather
                        name="user-plus"
                        size={16}
                        color={Colors.FoldPixel.PRIMARY100}
                      />
                    }
                  >
                    {renderSecondaryUsers()}
                  </HiddenView>
                </>
              )}
              <View height={4} />
              <ModalActionTimePicker
                label={''}
                className="custom-date-picker"
                customStyle={{marginTop: 8, height: 32}}
                isHideOkButton={true}
                value={
                  appointmentBookingState.selectedSlot?.startDateTime
                    ? getMomentObj(
                        appointmentBookingState.selectedSlot?.startDateTime
                      ).tz(getSelectedTimezone())
                    : undefined
                }
                format={DATE_FORMATS.TIME_SELECT_FORMAT}
                onSelect={(date: any) => {
                  updateSlotDateAndTimeForSpecificTime(
                    appointmentBookingState.selectedDate,
                    appointmentBookingState.duration,
                    {
                      startDateTime: date
                        .tz(getSelectedTimezone())
                        .toISOString(),
                      endDateTime: date
                        .add(appointmentBookingState.duration, 'minutes')
                        .tz(getSelectedTimezone())
                        .toISOString(),
                    }
                  );
                }}
              />

              {appointmentBookingState.slotLoading && (
                <HStack space={2} alignItems={'center'}>
                  <Spinner size={'sm'} color={Colors.Custom.Gray500} />
                  <Text
                    fontSize={12}
                    color={Colors.Custom.Gray500}
                    width="full"
                  >
                    {intl.formatMessage({id: 'checkingSlotAvailabilityMsg'})}
                  </Text>
                </HStack>
              )}
              {!appointmentBookingState.slotLoading && !hasAvailability && (
                <HStack alignItems={'flex-start'} space={2}>
                  <Feather
                    name="info"
                    size={16}
                    color={Colors.Custom.Gray500}
                    style={{marginTop: 2}}
                  />
                  <Text
                    fontSize={12}
                    color={Colors.Custom.Gray500}
                    width="full"
                  >
                    {intl.formatMessage({id: 'specificTimeOverlapMsg'})}
                  </Text>
                </HStack>
              )}
            </VStack>
          )}
          {!appointmentBookingState.slotLoading &&
            appointmentBookingState.errors.slots && (
              <Text fontSize="xs" color="error.500">
                {appointmentBookingState.errors.slots}
              </Text>
            )}
        </FormControl>
      </Stack>
    </>
  );
};

export default React.memo(DayWiseSlots);
